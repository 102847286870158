import React from 'react';

import {
  CriticalMetricGoalFragment,
  CriticalMetricGoalUpdateInput,
} from 'data/graphql/generated';
import { Collaboration } from 'components/shared';
import FocusDropdown from 'components/shared/FocusDropdown';

import { GoalFocusRationaleModal } from '../../../../shared-ui-goals/src';
import { GoalFrequencyDropdownOptionType } from '../../../../shared-ui-goals/src';
import { GoalInputDropdownOptionType } from '../../../../shared-ui-goals/src';
import { DeleteGoalsTableRow } from './DeleteGoalsTableRow';
import {
  ColumnContainer,
  DesktopContainer,
  LastColumnContainer,
} from './DesktopGoalsTableRow.styles';
import { GoalField } from '../ui/GoalField';
import { GoalTargetField } from '../ui/GoalTargetField';
import { GoalFrequencyField } from '../ui/GoalFrequencyField';
import { GoalInputField } from '../ui/GoalInputField';
import { useGoalTableRowValidation } from '../util/useGoalTableRowValidation';

interface DesktopGoalsTableRowProps {
  closeModal: () => void;
  modalSlideIdx: number | null;
  setModalSlideIdx: React.Dispatch<React.SetStateAction<number | null>>;
  setShowSingleSlide: React.Dispatch<React.SetStateAction<boolean>>;
  data: CriticalMetricGoalFragment;
  onUpdate: (data: CriticalMetricGoalUpdateInput) => Promise<void>;
  isFocused: boolean;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  toggleFocus: () => Promise<void>;
  autoFocus: boolean;
  isLead: boolean;
  isContributor: boolean;
  canUpdate: (value: string | number | null) => boolean;
  selectedFrequency: GoalFrequencyDropdownOptionType | null;
  setSelectedFrequency: React.Dispatch<
    React.SetStateAction<GoalFrequencyDropdownOptionType | null>
  >;
  selectedInput: GoalInputDropdownOptionType | null;
  setSelectedInput: React.Dispatch<
    React.SetStateAction<GoalInputDropdownOptionType | null>
  >;
  isDeleting: boolean;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete: () => void;
  onLockedFieldClick: () => void;
}

export const DesktopGoalsTableRow: React.FC<DesktopGoalsTableRowProps> = ({
  closeModal,
  modalSlideIdx,
  setModalSlideIdx,
  setShowSingleSlide,
  data,
  onUpdate,
  isFocused,
  setIsFocused,
  toggleFocus,
  autoFocus,
  isLead,
  isContributor,
  canUpdate,
  selectedFrequency,
  setSelectedFrequency,
  selectedInput,
  setSelectedInput,
  isDeleting,
  setIsDeleting,
  onDelete,
  onLockedFieldClick,
}) => {
  const {
    isAllowedToEdit,
    isFocusButtonDisabled,
    focusButtonDisabledText,
  } = useGoalTableRowValidation({
    isLead,
    isContributor,
    data: {
      goal: data.goal,
      target: data.target,
      frequency: data.frequency,
      input: data.input,
    },
  });

  return (
    <>
      <GoalFocusRationaleModal
        closeModal={closeModal}
        modalSlideIdx={modalSlideIdx}
        setModalSlideIdx={setModalSlideIdx}
        data={data}
        onUpdate={onUpdate}
        setIsFocused={setIsFocused}
      />
      <DesktopContainer isFocused={data.isFocused}>
        <ColumnContainer>
          <GoalField
            isDesktop
            autoFocus={autoFocus}
            editEnabled={isAllowedToEdit}
            initialValue={data.goal || ''}
            canUpdate={canUpdate}
            onUpdate={onUpdate}
          />

          <GoalTargetField
            isDesktop
            editEnabled={isAllowedToEdit}
            initialValue={data.target}
            canUpdate={canUpdate}
            onUpdate={onUpdate}
          />

          <GoalFrequencyField
            isDesktop
            editEnabled={isAllowedToEdit}
            selectedFrequency={selectedFrequency}
            setSelectedFrequency={setSelectedFrequency}
            onUpdate={onUpdate}
            hasTracking={data.hasTracking}
            onLockedFieldClick={onLockedFieldClick}
          />

          <LastColumnContainer>
            <GoalInputField
              isDesktop
              editEnabled={isAllowedToEdit}
              selectedInput={selectedInput}
              setSelectedInput={setSelectedInput}
              onUpdate={onUpdate}
              hasTracking={data.hasTracking}
              onLockedFieldClick={onLockedFieldClick}
            />

            <FocusDropdown
              onClick={toggleFocus}
              checked={isFocused}
              setModalSlideIdx={setModalSlideIdx}
              setShowSingleSlide={setShowSingleSlide}
              rationaleText={data.focusRationale}
              disabledTooltipText={focusButtonDisabledText}
              disabled={isFocusButtonDisabled}
            />

            <Collaboration collaboration={data.collaboration} />

            <DeleteGoalsTableRow
              canEdit={isAllowedToEdit}
              isDeleting={isDeleting}
              setIsDeleting={setIsDeleting}
              onDelete={onDelete}
            />
          </LastColumnContainer>
        </ColumnContainer>
      </DesktopContainer>
    </>
  );
};
