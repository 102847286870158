import { colors } from 'constants/index';
import React from 'react';
import styled from 'styled-components/macro';
import { BlurredBGImage, Icon } from '.';

const DefinitionWrapper = styled.div<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 100px;
  overflow: hidden;
  background-size: cover;

  background-color: ${colors.purple10};
  display: flex;
  align-items: center;
  flex: none;
`;

interface Props {
  size?: number;
  imageURL?: string | null;
  className?: string;
  style?: React.CSSProperties;
}

export const PatientDefinitionImage = ({
  size = 40,
  imageURL,
  className,
  style,
  ...rest
}: Props) => {
  return (
    <DefinitionWrapper
      {...rest}
      className={className}
      style={style}
      size={size}
    >
      {!imageURL ? (
        <Icon
          name="Patient"
          size={size}
          color={colors.purple}
          style={{
            background: colors.purple10,
            borderRadius: '50%',
          }}
        />
      ) : (
        <BlurredBGImage imageURL={imageURL} blurAmount={2} />
      )}
    </DefinitionWrapper>
  );
};
