import { colors, polling } from 'constants/index';
import useMobile from 'hooks/useMobile';
import React, {
  createRef,
  RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Content, ModalLarge, Scroll } from './ModalLarge';
import {
  BodySmall,
  Caption,
  Icon,
  PatientDefinitionImage,
  Subtitle2,
  Tooltip,
} from './shared';
import styled, { css } from 'styled-components/macro';
import {
  Maybe,
  Stakeholder,
  useStakeholderDefinitionSummaryQuery,
} from 'data/graphql/generated';
import { device } from 'utils/breakpoints';
import { Bar, BarRow } from './PatientFlow/FiguresDistributionModal/BarRow';
import { Loading } from './Loading';
import { stakeholderAliases } from 'utils/getStakeholderAlias';
import { useWidth } from 'hooks/useWidth';
import { Link } from 'react-router-dom';

const StyledBarRow = styled(BarRow)<{ value: Maybe<string> | undefined }>`
  ${({ value }) => {
    if (value === null) {
      return css`
        ${Bar} {
          background: ${colors.greyMedium};
        }
      `;
    }
  }}
`;

const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  height: 100px;
  width: 100%;
  background: ${colors.yellow20};
`;

const BulletPoint = styled.div`
  width: 15px;
  height: 15px;
  border: 5px solid ${colors.purple};
  border-radius: 50%;
  margin-right: 10px;
  flex: none;
`;

const StakeholdersWrapper = styled.div`
  display: flex;
  margin-top: 15px;
  flex-wrap: wrap;
  row-gap: 10px;
`;

const StrategicTextWrapper = styled.div`
  width: 265px;
  padding: 15px;
`;

const StyledModalLarge = styled(ModalLarge)`
  > ${Scroll} {
    > ${Content} {
      @media ${device.tabletMin} {
        max-width: 481px;
      }

      @media ${device.desktopMin} {
        max-width: 639px;
      }
    }
  }
`;

const roundedBorder = css`
  border: 1px solid ${colors.black10a};
  background: ${colors.white};
  border-radius: 5px;
  width: 100%;
  padding: 15px;
`;

const StrategyTexts = styled.div`
  ${roundedBorder}
  display:flex;
  padding: 0px;
`;
const RelevantStakeholders = styled.div`
  ${roundedBorder}
`;
const LeveragePoints = styled.div`
  ${roundedBorder}
  display:flex;
  flex-direction: column;
  gap: 15px;
`;
const Insights = styled.div`
  ${roundedBorder}
`;

const PatientDefinitionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const SummaryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 40px;
  gap: 5px;

  @media ${device.tabletMax} {
    padding: 15px;
  }
`;

function stakeholderToIcon(stakeholder: string) {
  switch (stakeholder) {
    case 'Patient':
      return <Icon name="Patient" size={30} color={colors.purple} />;

    case 'Healthcare':
      return <Icon name="HCP" size={30} color={colors.green} />;

    case 'Payor':
      return <Icon name="Dollar" size={30} color={colors.blue} />;

    case 'Provider':
      return <Icon name="Provider" size={30} color={colors.pink} />;

    case 'Policymaker':
      return <Icon name="Policymaker" size={30} color={colors.pink} />;
  }
}

interface Props {
  handleClose(): void;
  visible: boolean;
  stakeholderDefId: number | undefined;
  strategyId: number;
  drugId: number;
}

export const StakeholderDefinitionSummaryModal = ({
  handleClose,
  visible,
  stakeholderDefId,
  strategyId,
  drugId,
}: Props) => {
  const isMobile = useMobile();
  const screenWidth = useWidth();
  const stakeholdersWrapperRef = useRef<HTMLDivElement>(null);
  const [stakeholderBorderRight, setStakeholderBorderRight] = useState({
    Patient: true,
    Healthcare: true,
    Payor: true,
    Provider: false,
    Policymaker: false,
  });
  const [stakeholdersSectionRefs] = React.useState<
    Record<keyof typeof Stakeholder, RefObject<HTMLDivElement>>
  >({
    Patient: createRef(),
    Healthcare: createRef(),
    Payor: createRef(),
    Provider: createRef(),
    Policymaker: createRef(),
  });

  const {
    data,
    loading: summaryLoading,
    refetch,
    startPolling,
    stopPolling,
  } = useStakeholderDefinitionSummaryQuery({
    fetchPolicy: 'network-only',
    variables: {
      stakeholderDefinitionId: Number(stakeholderDefId),
    },
  });

  useEffect(() => {
    startPolling(polling.default);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const summaryData = data?.stakeholderDefinitionSummary;

  const leveragePoints = summaryData?.leveragePoints || [];

  useEffect(() => {
    if (!!stakeholderDefId) {
      refetch();
    }
  }, [stakeholderDefId, refetch]);

  useLayoutEffect(() => {
    const patientSection = stakeholdersSectionRefs.Patient.current;
    const healthCareSection = stakeholdersSectionRefs.Healthcare.current;
    const payorSection = stakeholdersSectionRefs.Payor.current;
    const providerSection = stakeholdersSectionRefs.Provider.current;
    const policymakerSection = stakeholdersSectionRefs.Policymaker.current;
    //Compare offset tops to determine if stakeholder blocks are on the same row
    //If they are not, disable the border for the previous block

    if (
      patientSection &&
      healthCareSection &&
      payorSection &&
      providerSection &&
      policymakerSection
    ) {
      const newState = {
        Patient: true,
        Healthcare: true,
        Payor: true,
        Provider: false,
        Policymaker: false,
      } as Record<keyof typeof Stakeholder, boolean>;
      if (patientSection?.offsetTop !== healthCareSection?.offsetTop) {
        newState.Patient = false;
      }

      if (healthCareSection?.offsetTop !== payorSection?.offsetTop) {
        newState.Healthcare = false;
      }

      if (payorSection?.offsetTop !== providerSection?.offsetTop) {
        newState.Payor = false;
      }

      if (providerSection?.offsetTop !== policymakerSection?.offsetTop) {
        newState.Provider = false;
      }

      setStakeholderBorderRight(newState);
    }
  }, [
    stakeholdersSectionRefs.Healthcare,
    stakeholdersSectionRefs.Patient,
    stakeholdersSectionRefs.Payor,
    stakeholdersSectionRefs.Provider,
    stakeholdersSectionRefs.Policymaker,
    summaryData,
    visible,
    screenWidth,
  ]);

  if (!stakeholderDefId) {
    return null;
  }

  const stakeholders = Object.keys(Stakeholder) as (keyof typeof Stakeholder)[];
  const leveragePointsMaxValue = !!summaryData?.leveragePoints
    ? Math.max(
        ...summaryData?.leveragePoints.map((point) => Number(point.value))
      )
    : 0;

  return (
    <StyledModalLarge
      size={'small'}
      handleClose={handleClose}
      visible={visible}
      headerColor={isMobile ? colors.black05 : colors.cream}
    >
      {summaryLoading ? (
        <Loading style={{ marginTop: 20 }} />
      ) : (
        <SummaryWrapper>
          <PatientDefinitionWrapper>
            <PatientDefinitionImage
              imageURL={summaryData?.stakeholderDefinitionImage}
            />
            <Subtitle2>{summaryData?.stakeholderDefinitionTitle}</Subtitle2>
          </PatientDefinitionWrapper>
          <StrategyTexts>
            <div
              style={{
                padding: 15,
                display: 'flex',
                gap: 15,
              }}
            >
              <BodySmall>Winning aspiration</BodySmall>
              <BodySmall color={colors.greyDark}>
                {summaryData?.winningAspiration || 'Not defined yet'}
              </BodySmall>
            </div>
          </StrategyTexts>
          <StrategyTexts>
            <StrategicTextWrapper style={{ paddingRight: 0 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  borderRight: `1px solid ${colors.greyLight}`,
                }}
              >
                <BodySmall>Strategic problem</BodySmall>
                <BodySmall color={colors.greyDark}>
                  {summaryData?.strategicProblem || 'Not defined yet'}
                </BodySmall>
              </div>
            </StrategicTextWrapper>
            <StrategicTextWrapper>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                }}
              >
                <BodySmall>Strategic question</BodySmall>
                <BodySmall color={colors.greyDark}>
                  {summaryData?.strategicQuestion || 'Not defined yet'}
                </BodySmall>
              </div>
            </StrategicTextWrapper>
          </StrategyTexts>
          <RelevantStakeholders>
            <BodySmall>Relevant stakeholders</BodySmall>
            <StakeholdersWrapper ref={stakeholdersWrapperRef}>
              {stakeholders.map((stakeholder) => {
                //remove current stakeholderDefinition
                const relevantStakeholderDefs = summaryData?.relevantStakeholders[
                  stakeholder
                ]?.filter(
                  (stakeholderDefinition) =>
                    stakeholderDefinition.id !== stakeholderDefId
                );

                return (
                  <div
                    key={stakeholder}
                    ref={stakeholdersSectionRefs[stakeholder]}
                    style={{
                      display: 'flex',
                      borderRight: !stakeholderBorderRight[stakeholder]
                        ? 'none'
                        : `1px solid ${colors.greyLight}`,
                      paddingRight: 15,
                    }}
                  >
                    {stakeholderToIcon(stakeholder)}

                    <div
                      style={{
                        display: 'flex',
                        gap: 5,
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      {!!relevantStakeholderDefs?.length ? (
                        relevantStakeholderDefs?.map(
                          (stakeholderDefinition) => {
                            return (
                              <PatientDefinitionImage
                                data-offset="{ 'right': -14, 'top':18 }"
                                data-for={'SummaryModal_RelevantStakeholders'}
                                data-tip={stakeholderDefinition.title}
                                key={stakeholderDefinition.id}
                                imageURL={stakeholderDefinition.image}
                              />
                            );
                          }
                        )
                      ) : (
                        <BodySmall
                          color={colors.greyDark}
                          style={{ marginLeft: 5 }}
                        >
                          No {stakeholderAliases[stakeholder].plural}
                        </BodySmall>
                      )}
                    </div>
                  </div>
                );
              })}
              <Tooltip
                id={'SummaryModal_RelevantStakeholders'}
                effect="float"
                place="right"
                multiline
              />
            </StakeholdersWrapper>
          </RelevantStakeholders>
          <LeveragePoints>
            <BodySmall>Global population across leverage points</BodySmall>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
              {!!leveragePoints.length ? (
                leveragePoints.map((point, idx) => {
                  return (
                    <div key={idx}>
                      <Caption color={colors.greyDark}>{point.title}</Caption>

                      <StyledBarRow
                        fill
                        preferValuesInside
                        showAsPercentage={false}
                        maxValue={leveragePointsMaxValue}
                        value={point.value}
                        starred={false}
                        overrideValue={() => {
                          if (point.value === null) {
                            return 'Not quantified';
                          }

                          return Number(point.value);
                        }}
                      />
                    </div>
                  );
                })
              ) : (
                <EmptyStateWrapper>
                  <Subtitle2 color={colors.greyDark}>
                    No Leverage Points
                  </Subtitle2>
                  <div style={{ textAlign: 'center' }}>
                    <BodySmall
                      style={{ display: 'inline' }}
                      color={colors.greyDark}
                    >
                      Starred blocks from the
                    </BodySmall>

                    <Link
                      to={`/d/${drugId}/strategy/${strategyId}/2_2`}
                      style={{
                        color: colors.greyDark,
                        display: 'inline',
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      {' '}
                      Patient Flow{' '}
                    </Link>

                    <BodySmall
                      style={{ display: 'inline' }}
                      color={colors.greyDark}
                    >
                      will be shown here
                    </BodySmall>
                  </div>
                </EmptyStateWrapper>
              )}
            </div>
          </LeveragePoints>
          <Insights>
            <BodySmall style={{ marginBottom: 15 }}>Key Insights</BodySmall>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
              {!!summaryData?.keyInsights.length ? (
                summaryData?.keyInsights.map((insight) => {
                  return (
                    <div key={insight.id} style={{ display: 'flex' }}>
                      <BulletPoint />
                      <BodySmall
                        style={{
                          wordBreak: 'break-word',
                        }}
                        color={colors.greyDark}
                      >
                        {insight.title}
                      </BodySmall>
                    </div>
                  );
                })
              ) : (
                <EmptyStateWrapper>
                  <Subtitle2 color={colors.greyDark}>No Key Insights</Subtitle2>
                  <div style={{ textAlign: 'center' }}>
                    <Link
                      to={`/d/${drugId}/strategy/${strategyId}/1_5`}
                      style={{
                        color: colors.greyDark,
                        display: 'inline',
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      Key Insights{' '}
                    </Link>

                    <BodySmall
                      style={{ display: 'inline' }}
                      color={colors.greyDark}
                    >
                      from the same stage as leverage points will be shown here
                    </BodySmall>
                  </div>
                </EmptyStateWrapper>
              )}
            </div>
          </Insights>
        </SummaryWrapper>
      )}
    </StyledModalLarge>
  );
};
