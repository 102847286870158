import { colors } from 'constants/colors';
import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { Country } from 'types';
import { CountryFlag } from './CountryFlag';
import FormField, { ErrorMessage } from './FormField';
import { Icon } from './Icon';
import { BodyNormal, ButtonLabel } from './TextStyles';
import {
  SelectedValueContainer,
  DisplayedOption,
  DropdownOption,
} from 'components/shared/FormDropdown';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { countryPhoneCode } from 'utils/countryPhoneCode';

const DisplayedOptionText = styled(BodyNormal)`
  margin-left: 5px;
  text-transform: capitalize;
`;

const CountryDropdownOption = styled(DropdownOption)`
  padding: 5px;
`;

const DropdownOptionText = styled(ButtonLabel)`
  margin-left: 5px;
  text-transform: capitalize;
  span {
    color: ${colors.greyDark};
  }
`;

export const CountryDropdownList = styled.ul<{ phoneCode?: boolean }>`
  z-index: 2;
  margin: 0;
  padding: 0;
  border: 0.5px solid ${colors.black};
  border-radius: 0 0 5px 5px;
  padding: 5px;
  max-height: 230px;
  background: ${colors.white};
  overflow-x: auto;
  overflow-y: scroll;
  position: absolute;
  right: 0;
  left: 0;
  border-top: none;
  ${({ phoneCode }) => {
    if (phoneCode) {
      return css`
        width: fit-content;
        white-space: nowrap;
        overflow-x: hidden;
      `;
    }
  }}

  :hover {
    cursor: pointer;
  }
`;

interface Props {
  name: string;
  title: string;
  disabled?: boolean;
  errorMessage?: string;
  placeholder: string;
  options: Country[];
  onClick: () => void;
  selectedItem?: Country;
  dropdownActive: boolean;
  setSelectedItem(country: Country): void;
  setDropdownActive: React.Dispatch<React.SetStateAction<boolean>>;
  phoneCode?: boolean;
  className?: string;
}

export const FormCountryDropdown: React.FC<Props> = ({
  name,
  title,
  disabled,
  errorMessage,
  placeholder,
  options,
  onClick,
  selectedItem,
  dropdownActive,
  setSelectedItem,
  setDropdownActive,
  phoneCode,
  className,
}) => {
  const handleOptionClick = (option: any) => {
    setSelectedItem(option);
    setDropdownActive(false);
  };
  const dropdownRef = useRef(null);

  useClickOutsideComponent(
    dropdownRef,
    () => {
      setDropdownActive(false);
    },
    ['selectedValueContainer']
  );

  return (
    <FormField
      className={className}
      name={name}
      title={title}
      disabled={disabled}
    >
      <SelectedValueContainer
        className="selectedValueContainer"
        error={!!errorMessage}
        disabled={disabled}
        dropdownActive={phoneCode ? false : dropdownActive}
        isSelected={!!selectedItem}
        onClick={disabled ? null : onClick}
      >
        <DisplayedOption>
          {selectedItem && (
            <CountryFlag size={20} country={selectedItem} disableTooltip />
          )}
          <DisplayedOptionText>
            {phoneCode
              ? selectedItem
                ? countryPhoneCode(selectedItem)
                : null
              : selectedItem || placeholder}
          </DisplayedOptionText>
        </DisplayedOption>
        <Icon
          name={dropdownActive ? 'Chevron-up' : 'Chevron-down'}
          color={colors.black}
          size={30}
        />
      </SelectedValueContainer>
      {errorMessage && <ErrorMessage message={errorMessage} />}

      {dropdownActive && (
        <CountryDropdownList phoneCode={phoneCode} ref={dropdownRef}>
          {options.map((option) => (
            <React.Fragment key={option}>
              <CountryDropdownOption
                onClick={() => handleOptionClick(option)}
                isSelected={option === selectedItem}
              >
                <CountryFlag disableTooltip size={20} country={option} />
                {phoneCode ? (
                  <DropdownOptionText>
                    <span>{option}</span>
                    {` ${countryPhoneCode(option)}`}
                  </DropdownOptionText>
                ) : (
                  <DropdownOptionText>{option}</DropdownOptionText>
                )}
              </CountryDropdownOption>
            </React.Fragment>
          ))}
        </CountryDropdownList>
      )}
    </FormField>
  );
};

export default FormCountryDropdown;
