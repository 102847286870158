import { TacticFragment } from 'data/graphql/generated';

export function getBudgetSum(tactics: { budget?: TacticFragment['budget'] }[]) {
  return tactics.reduce((total: number, curr) => {
    const budget = curr?.budget ?? 0;
    // remove stray comma separated thousand values
    const convertedToNumber = parseInt(String(budget).replace(/\D/g, ''));
    return (total += convertedToNumber);
  }, 0);
}
