import {
  PostItCardFragment,
  PostItCardType,
  User,
} from 'data/graphql/generated';

export default function showTyping(card: PostItCardFragment, user: User) {
  const isOwner = card.user.id === user.id;
  const noTitleYet = card.type !== PostItCardType.Image && !card?.title.length;
  const noImageYet = card.type === PostItCardType.Image && !card?.image;

  return !isOwner && (noTitleYet || noImageYet);
}
