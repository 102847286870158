import { ButtonRound } from 'components/shared';

interface DeleteButtonProps {
  isHidden: boolean;
  className?: string;
  onClick: () => void;
}

export const DeleteButton = ({
  isHidden,
  className,
  onClick,
}: DeleteButtonProps) => {
  if (isHidden) {
    return null;
  }

  return (
    <ButtonRound
      level="secondary"
      iconName="Trash"
      size="small"
      className={className}
      onClick={onClick}
    />
  );
};
