import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { CTAWrapper } from './ModalForm';
import { ButtonPill } from './shared';
import { ButtonLevel } from './shared/ButtonPill';
import { WarningModalLink } from './WarningModalLink';

const ButtonWrapper = styled(CTAWrapper)`
  gap: 15px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-top: 15px;

  @media ${device.tabletMin} {
    flex-direction: row;
  }
`;

interface Props {
    onClick?: () => void;
    rightButtonOnClick?: () => void;
    text?: string;
    buttonLevel?: ButtonLevel;
    disabled?: boolean;
    title: string;
    body?: string;
    modalOpen: boolean;
    buttonLoading?: boolean;
    handleClose: () => void;
    className?: string;
    children?: React.ReactNode;
}

export default function OneButtonModalLink({
    onClick,
    text = 'OK',
    buttonLevel = 'primary',
    title,
    body,
    modalOpen,
    handleClose,
    buttonLoading,
    className,
    children,
}: Props) {
    return ReactDOM.createPortal(
        <WarningModalLink
            handleClose={handleClose}
            visible={modalOpen}
            heading={title}
            message={body}
            className={className}
        >
            {children}
            <ButtonWrapper>
                <ButtonPill
                    width="180px"
                    text={text}
                    level={buttonLevel}
                    onClick={onClick}
                    loading={buttonLoading}
                />
            </ButtonWrapper>
        </WarningModalLink>,
        document.body
    );
}
