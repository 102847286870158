import { useEffect } from 'react';
import {
  BodyNormal,
  ContributionTab,
  StepHeaderBar,
  Subtitle2,
  TabGroup,
} from 'components/shared';
import { colors } from 'constants/colors';
import {
  CompetitiveAdvantageRowFragment,
  SubStep,
} from 'data/graphql/generated';
import React from 'react';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { useGlobalContext } from 'contexts/GlobalContext';
import {
  StyledKeyEventsButton,
} from 'containers/MedicalStrategy/index.style';
import useMobile from 'hooks/useMobile';

export const StyledStepHeaderBar = styled(StepHeaderBar)`
  margin-top: 160px;
  padding: 15px 15px 0 15px;
  min-height: unset;
  width: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${device.desktopMin} {
    margin-top: 110px;
  }
`;

const TabGroupWrapper = styled.div`
  width: 100%;
`;

const StepHeaderText = styled.div`
  display: flex;
  width: 100%;
`;

export const StepHeader = styled(StepHeaderBar)`
  margin-top: 0;
  display: block;
  padding: 15px 20px 0px;
  border-bottom: 0.5px solid ${colors.greyMedium};
  min-height: auto;
  overflow: visible;
`;

interface URLParams {
  drugId: string;
  strategyId: string;
  strategicImperativeId: string;
}

interface Props {
  substep: SubStep;
  imperatives: CompetitiveAdvantageRowFragment[] | undefined;
  region?: string;
  setKeyEventsSidebarOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CommercialStrategyStepHeader: React.FC<Props> = ({
  substep,
  imperatives,
  region,
  setKeyEventsSidebarOpen,
}) => {
  const { drugId, strategyId, strategicImperativeId }: URLParams = useParams();
  const competitiveAdvantageRows = imperatives;
  const history = useHistory();
  const { path } = useRouteMatch();
  const isMobile = useMobile();

  // handle global context path
  const [ strategicImperativeIdGlobal, setStrategicImperativeId ] = useGlobalContext(
    'strategicImperativeId'
  );

  useEffect(() => {

    if (strategicImperativeIdGlobal) {
      const generatedPath = generatePath(path, {
        drugId: drugId,
        strategyId: strategyId,
        strategicImperativeId: strategicImperativeIdGlobal,
        region: region,
      });
  
      history.replace(generatedPath);
    }
    
  }, [drugId, history,strategyId, strategicImperativeIdGlobal, strategicImperativeId, path, region ]);


  function getTitle(substep: SubStep) {
    switch (substep) {
      case SubStep.BigIdeas:
        return 'Develop big ideas that drive the desired behaviour change';
      case SubStep.WinningActions:
        return 'Suggest and prioritise commercial initiatives for the strategic objectives';
      case SubStep.ThePlan:
        return 'Discuss and align on the details behind all big ideas and winning actions';
      default:
        return '';
    }
  }

  function getBody(substep: SubStep) {
    switch (substep) {
      case SubStep.BigIdeas:
        return 'Consider who the idea is for, give the idea a name and bring it to life with visuals and words.';
      case SubStep.WinningActions:
        return "Add and position ideas on the grid. A Lead should finalise the initiatives to invest in. These will be referred to as 'winning actions'. Initiatives may also be re-considered from a country perspective.";
      case SubStep.ThePlan:
        return 'A Lead should make the final decision.';
      default:
        return '';
    }
  }

  return (
    <StyledStepHeaderBar>
      <StepHeaderText>
        <div>
          <Subtitle2 style={{ marginBottom: 5 }}>{getTitle(substep)}</Subtitle2>
          <BodyNormal color={colors.greyDark} style={{ marginBottom: 15 }}>
            {getBody(substep)}
          </BodyNormal>
        </div>

        {setKeyEventsSidebarOpen && (
          <StyledKeyEventsButton
            plusIcon
            buttonText="Key events"
            mobile={isMobile}
            onClick={() => setKeyEventsSidebarOpen(true)}
          />
        )}
      </StepHeaderText>

      {!!competitiveAdvantageRows && competitiveAdvantageRows?.length > 0 && (
        <TabGroupWrapper>
          <TabGroup componentName="CompetitorTab">
            <>
              {competitiveAdvantageRows?.map((c) => {
                return (
                  <ContributionTab
                    key={c.id}
                    id={`contribution-tab-${c.strategicPossibility?.id}`}
                    secondary=""
                    text={c.strategicPossibility?.name || ''}
                    active={+strategicImperativeId === c.id}
                    disableSidebar
                    onClick={() => {
                      const generatedPath = generatePath(path, {
                        drugId: drugId,
                        strategyId: strategyId,
                        strategicImperativeId: c.id,
                        region: region,
                      });

                      history.push(generatedPath);
                      setStrategicImperativeId(c.id)
                    }}
                    sidebarOpen={false}
                    displaySummary={() => {}}
                    revealTruncatedText={false}
                  />
                );
              })}

            </>
          </TabGroup>
        </TabGroupWrapper>
      )}
    </StyledStepHeaderBar>
  );
};
