import { Subtitle2, BodySmall, Icon } from 'components/shared';
import { colors } from 'constants/index';
import React from 'react';
import styled from 'styled-components/macro';

const EmptyStateWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.white50};
  border-radius: 5px;
`;

export const EmptyState = () => {
  return (
    <EmptyStateWrapper>
      <Icon name="GenericEmptyState" size={115} height={115} color="initial" />
      <Subtitle2 color={colors.greyDark}>No key events yet</Subtitle2>
      <BodySmall color={colors.greyDark}>
        Add key events worth keeping in mind
      </BodySmall>
    </EmptyStateWrapper>
  );
};
