import { PatientJourneyBlockFragment } from 'data/graphql/generated';
import { Stage } from 'konva/types/Stage';
import { connectionDotHideAll, connectionDotShowAll } from './connectionDot';

export function hideBlockLayers(
  stageRef: { current: Stage },
  blocks: PatientJourneyBlockFragment[],
  excludeOldBlock: boolean = false
) {
  blocks.forEach((block) => {
    connectionDotHideAll(stageRef, block.id);

    const blockShadows = stageRef.current.findOne(`#block-${block.id}-shadow`);

    const blockDelete = stageRef.current.findOne(`#block-${block.id}-delete`);

    const blockDots = stageRef.current.findOne(`#block-${block.id}-dots`);

    // hide blocks
    blockShadows.opacity(0);
    blockDelete.opacity(0);
    blockDots.opacity(0);

    if (!excludeOldBlock) {
      const blockOld = stageRef.current.findOne(`#block-${block.id}-old`);
      blockOld.opacity(0);
    }
  });
}

export function showBlockLayers(
  stageRef: { current: Stage },
  blocks: PatientJourneyBlockFragment[],
  excludeOldBlock: boolean = false
) {
  blocks.forEach((block) => {
    connectionDotShowAll(stageRef, block.id);

    const blockShadows = stageRef.current.findOne(`#block-${block.id}-shadow`);

    const blockDelete = stageRef.current.findOne(`#block-${block.id}-delete`);

    const blockDots = stageRef.current.findOne(`#block-${block.id}-dots`);

    // show blocks
    blockShadows.opacity(1);
    blockDelete.opacity(1);
    blockDots.opacity(1);

    if (!excludeOldBlock) {
      const blockOld = stageRef.current.findOne(`#block-${block.id}-old`);
      blockOld.opacity(0.5);
    }
  });
}
