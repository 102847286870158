import { CriticalMetricGoalUpdateInput } from 'data/graphql/generated';
import { BodySmall, EditableTextarea } from 'components/shared';
import { colors } from 'constants/colors';
import { GoalFieldConstants } from '../../../../shared-util-goals-constants/src';

interface BaseGoalFieldProps {
  autoFocus: boolean;
  editEnabled: boolean;
  initialValue: string;
  onBlur: (goal: string, resetValue?: () => void) => void;
}

const DesktopGoalField = ({
  autoFocus,
  editEnabled,
  initialValue,
  onBlur,
}: BaseGoalFieldProps) => {
  return (
    <EditableTextarea
      autoFocus={autoFocus}
      style={{ background: 'transparent' }}
      editable={editEnabled}
      placeholder={GoalFieldConstants.PLACEHOLDER_TEXT}
      initialValue={initialValue}
      onBlur={onBlur}
    />
  );
};

const MobileGoalField = ({
  autoFocus,
  editEnabled,
  initialValue,
  onBlur,
}: BaseGoalFieldProps) => {
  return (
    <>
      <BodySmall color={colors.greyDark}>
        {GoalFieldConstants.FIELD_LABEL}
      </BodySmall>
      <DesktopGoalField
        autoFocus={autoFocus}
        editEnabled={editEnabled}
        initialValue={initialValue}
        onBlur={onBlur}
      />
    </>
  );
};

interface GoalFieldProps {
  isDesktop?: boolean;
  autoFocus: boolean;
  editEnabled: boolean;
  initialValue: string;
  canUpdate: (value: string | number | null) => boolean;
  onUpdate: (data: CriticalMetricGoalUpdateInput) => Promise<void>;
}

export const GoalField = ({
  isDesktop = false,
  autoFocus,
  editEnabled,
  initialValue,
  canUpdate,
  onUpdate,
}: GoalFieldProps) => {
  const handleOnBlur = (goal: string, resetValue?: () => void) => {
    if (canUpdate(goal)) {
      return onUpdate({ goal });
    }

    if (resetValue) {
      return resetValue();
    }
  };

  if (isDesktop) {
    return (
      <DesktopGoalField
        autoFocus={autoFocus}
        editEnabled={editEnabled}
        initialValue={initialValue}
        onBlur={handleOnBlur}
      />
    );
  }

  return (
    <MobileGoalField
      autoFocus={autoFocus}
      editEnabled={editEnabled}
      initialValue={initialValue}
      onBlur={handleOnBlur}
    />
  );
};
