import '../styles/index.css';
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useCollaborationContext } from 'contexts/CollaborationContext';

import { useKeypress } from 'hooks/useKeypress';

import { Stage } from 'konva/types/Stage';
import { Layer } from 'konva/types/Layer';
import {
  changeCursor as changeCursorSetup,
  makeGetColumnIdxForX,
  removeByClassName,
} from '../utils';
import { ActionTypeEnum, SharedPatientJourneyTypes } from '../types/shared';
import { PatientJourneyStage } from 'components/PatientJourney/src/lib/components/PatientJourneyStage';
import { PatientJourneyComponentType } from '../types';

export const PatientJourney = ({
  highLevelStageRef,
  isPreview,
  inRecapSidebar,

  blocks,
  updateBlock,
  updateBlockLocal,
  createBlock,
  removeBlock,
  //
  connections,
  createConnection,
  updateConnection,
  updateConnectionLocal,
  removeConnection,
  //
  columns,
  updateColumn,
  updateColumnLocal,
  //
  updateMany,
  //
  setDisableActions,
  actionType,

  columnStartingPoints,
  columnTotalWidth,

  handleBlockIconClick,
}: PatientJourneyComponentType) => {
  const [newConnection, setNewConnection] = useState<
    SharedPatientJourneyTypes['newConnection']
  >({
    toBlockId: undefined,
    toSide: undefined,
    fromBlockId: undefined,
    fromSide: undefined,
    path: [],
  });
  const [selectedConnection, setSelectedConnection] = useState<
    undefined | number
  >();
  const [activeColumn, setActiveCol] = useState<undefined | number>();

  const collaborationContextData = useCollaborationContext();

  const stageRef = React.useRef<null | Stage>(null);

  const isDrag = actionType === ActionTypeEnum.DRAG;

  const changeCursor = useCallback(
    (type) =>
      changeCursorSetup({
        type,
        isDrag,
        isPreview,
        newConnectionLength: newConnection.path.length,
        stageRef,
      }),
    [isDrag, isPreview, newConnection.path.length]
  );

  const setActiveColumn = useCallback(
    (idx?: number) => {
      if (idx === undefined) {
        setActiveCol(undefined);
        return;
      }
      const col = columns.find((col) => col.idx === idx);
      if (col) setActiveCol(col.id);
    },
    [columns]
  );

  // Hide all textareas on page change
  const location = useLocation();
  useEffect(() => {
    return () => {
      removeByClassName(`block-textarea`);
      removeByClassName(`block-background`);
      removeByClassName(`block-delete`);
      removeByClassName(`column-textarea`);
      removeByClassName(`column-background`);
    };
  }, [location]);

  const [create, setCreate] = useState<
    { x: number; y: number; column: number } | undefined
  >();
  const [editing, setEditing] = useState<number[]>([]);
  const [blockBeingDeleted, setBlockBeingDeleted] = useState<
    number | undefined
  >(); // blockId

  const layerRef = React.useRef<Layer | null>(null);

  useKeypress('Backspace', () => {
    if (isDrag) return;
    if (selectedConnection) {
      removeConnection(selectedConnection);
      setSelectedConnection(undefined);
    }
  });
  useKeypress('Escape', () => {
    if (isDrag) return;
    setSelectedConnection(undefined);
  });

  const getColumnIdxForX = makeGetColumnIdxForX(columnStartingPoints);

  function adjustFromScreenX(x: number) {
    if (!stageRef.current) return x;
    return x - stageRef.current.attrs.x;
  }
  function adjustFromScreenY(y: number) {
    if (!stageRef.current) return y;
    return y - stageRef.current.attrs.y;
  }

  function createShow() {
    const layer = layerRef.current;
    if (!layer) return;
    const block = layer.findOne(`#create`);
    block.show();
    layer.batchDraw();
  }

  const createHide = useCallback(() => {
    const layer = layerRef.current;
    if (!layer) return;
    const block = layer.findOne(`#create`);
    block.hide();
    setActiveColumn();
    layer.batchDraw();
  }, [setActiveColumn]);

  function showDot(layer: Layer, id: string) {
    const dot = layer.findOne(id);
    if (dot?.attrs?.opacity !== 1) {
      dot.opacity(0.5);
    }
  }

  useEffect(() => {
    if (isDrag) {
      changeCursor('grab');
      createHide();
    } else {
      changeCursor('default');
    }
  }, [isDrag, changeCursor, createHide]);

  function showDotsForBlock(blockId: number) {
    const layer = layerRef.current;
    if (!layer) return;
    showDot(layer, `#block-${blockId}-dot-top`);
    showDot(layer, `#block-${blockId}-dot-bottom`);
    showDot(layer, `#block-${blockId}-dot-left`);
    showDot(layer, `#block-${blockId}-dot-right`);
    layer.batchDraw();
  }

  function hideDotsForBlock(blockId: number) {
    const layer = layerRef.current;
    if (!layer) return;
    layer.findOne(`#block-${blockId}-dot-top`).opacity(0);
    layer.findOne(`#block-${blockId}-dot-bottom`).opacity(0);
    layer.findOne(`#block-${blockId}-dot-left`).opacity(0);
    layer.findOne(`#block-${blockId}-dot-right`).opacity(0);
    layer.batchDraw();
  }

  const props = {
    stageRef,
    isDrag,
    columnTotalWidth,
    changeCursor,
    createShow,
    createHide,
    layerRef,
    adjustFromScreenY,
    columns,
    adjustFromScreenX,
    create,
    newConnection,
    selectedConnection,
    setActiveColumn,
    getColumnIdxForX,
    setSelectedConnection,
    setNewConnection: (val: SharedPatientJourneyTypes['newConnection']) =>
      setNewConnection(val),
    collaborationContextData,
    blocks,
    connections,
    columnStartingPoints,
    activeColumn,
    updateColumn,
    updateColumnLocal,
    updateMany,
    setDisableActions,
    setCreate: (val: { x: number; y: number; column: number } | undefined) =>
      setCreate(val),
    blockBeingDeleted,
    updateBlock,
    updateBlockLocal,
    updateConnection,
    updateConnectionLocal,
    removeBlock,
    showDotsForBlock,
    hideDotsForBlock,
    editing,
    setEditing: (val: number[]) => setEditing(val),
    setBlockBeingDeleted,
    createConnection,
    createBlock,
    removeConnection,
    handleBlockIconClick,
  };

  if (isPreview) {
    return (
      <PatientJourneyStage
        {...props}
        isDrag={true}
        isPreview
        inRecapSidebar={inRecapSidebar}
        highLevelStageRef={highLevelStageRef}
      />
    );
  }

  return (
    <PatientJourneyStage {...props} highLevelStageRef={highLevelStageRef} />
  );
};
