import {
  Caption,
  Subtitle2,
  CountryFlag,
  MoreTextIcon,
} from 'components/shared';
import { colors } from 'constants/index';
import { ArchetypeCountryFragment } from 'data/graphql/generated';
import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { Country } from 'types';
import { device } from 'utils/breakpoints';
import { startCase } from 'utils/startCase';

const HeaderCountries = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-bottom: 30px;

  @media ${device.tabletMax} {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 0;

    ${HeaderCountries} {
      align-self: flex-start;
    }
  }
`;

const HeaderInnerWrapper = styled.div`
  padding-left: 15px;
  display: flex;
  align-items: center;
  position: relative;

  @media ${device.tabletMax} {
    justify-content: space-between;
    width: 100%;
    padding-left: 0;

    ${Caption} {
      display: none;
    }
  }
`;

export const AccessStrategyTacticsTableHeader = ({
  countries,
  title,
  includesUserCountry,
  setIsActive,
  isActive,
}: {
  countries?: ArchetypeCountryFragment[];
  title?: string;
  includesUserCountry?: boolean;
  isActive?: boolean;
  setIsActive?(): void;
}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });
  return (
    <HeaderWrapper>
      <HeaderInnerWrapper>
        {includesUserCountry && (
          <Caption
            style={{
              position: 'absolute',
              top: -15,
              color: colors.greyDark,
            }}
          >
            Your archetype
          </Caption>
        )}
        <Subtitle2>{title}</Subtitle2>
        {!!setIsActive && (
          <MoreTextIcon
            active={isActive}
            style={{ marginLeft: 5 }}
            onClick={setIsActive}
            size={30}
            color={colors.greyDark}
          />
        )}
      </HeaderInnerWrapper>
      {!!countries?.length && (
        <HeaderCountries>
          {countries?.map(({ country }) => (
            <div
              key={country}
              data-for={'AccessStrategyTactics__Tooltip'}
              data-tip={startCase(country || '')}
            >
              <CountryFlag
                size="sm"
                disableTooltip
                country={country as Country}
              />
            </div>
          ))}
        </HeaderCountries>
      )}
    </HeaderWrapper>
  );
};
