import { ModalLarge, Content } from 'components/ModalLarge';
import { colors } from 'constants/colors';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import DateTextInput from 'components/shared/DateTextInput';
import { BodyNormal, ButtonPill, Subtitle1 } from 'components/shared';
import {
  LongTermStrategyTimelineFragment,
  useLongTermStrategyTimelineUpdateMutation,
} from 'data/graphql/generated';
import { getISOString, getTimestamp } from 'utils/dateStringToMonthYear';

const StyledModal = styled(ModalLarge)`
  ${Content} {
    padding-bottom: 0;
  }
`;

const CTAWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
  margin-top: 30px;
  > * {
    flex: 1;
  }
`;

type Props = {
  data: LongTermStrategyTimelineFragment;
  closeModal(): void;
  visible: boolean;
  enableGraphTransitions(): void;
};

export const KeyDatesModal = ({
  data,
  closeModal,
  visible,
  enableGraphTransitions,
}: Props) => {
  const [showDatePicker, setShowDatePicker] = useState<string>('');
  const [
    updateLongTermStrategyTimeline,
  ] = useLongTermStrategyTimelineUpdateMutation();

  const {
    handleSubmit,
    values,
    errors,
    setSubmitting,
    isSubmitting,
    setFieldValue,
    isValid,
    touched,
    setTouched,
    validateField,
  } = useFormik({
    initialValues: {
      approval: getTimestamp(data.approval),
      launch: getTimestamp(data.launch),
      peakSales: getTimestamp(data.peakSales),
      lossOfExclusivity: getTimestamp(data.lossOfExclusivity),
    },
    onSubmit: async ({ approval, launch, peakSales, lossOfExclusivity }) => {
      setSubmitting(true);
      enableGraphTransitions();

      await updateLongTermStrategyTimeline({
        variables: {
          id: data.id,
          data: {
            approval: getISOString(approval),
            launch: getISOString(launch),
            peakSales: getISOString(peakSales),
            lossOfExclusivity: getISOString(lossOfExclusivity),
          },
        },
      });
      setSubmitting(false);
      closeModal();
    },
    validate: (values) => {
      const errors = {} as {
        approval: string;
        launch: string;
        peakSales: string;
        lossOfExclusivity: string;
      };

      if (!values.approval && touched.approval) {
        errors.approval = 'Required';
      }
      if (!values.launch && touched.launch) {
        errors.launch = 'Required';
      }
      if (
        !!values.approval &&
        !!values.launch &&
        +values.approval > +values.launch
      ) {
        errors.launch = 'Cannot be before approval date';
      }

      if (!values.peakSales && touched.peakSales) {
        errors.peakSales = 'Required';
      }

      if (
        !!values.peakSales &&
        !!values.launch &&
        +values.launch > +values.peakSales
      ) {
        errors.peakSales = 'Cannot be before launch date';
      }

      if (
        !!values.peakSales &&
        !!values.launch &&
        !!values.approval &&
        +values.launch < +values.peakSales &&
        +values.approval > +values.peakSales
      ) {
        errors.peakSales = 'Cannot be before approval date';
      }

      if (!values.lossOfExclusivity && touched.lossOfExclusivity) {
        errors.lossOfExclusivity = 'Required';
      }

      if (
        !!values.lossOfExclusivity &&
        !!values.peakSales &&
        +values.peakSales > +values.lossOfExclusivity
      ) {
        errors.lossOfExclusivity = 'Cannot be before peak sales date';
      }

      if (
        !!values.lossOfExclusivity &&
        !!values.launch &&
        +values.launch > +values.lossOfExclusivity
      ) {
        errors.lossOfExclusivity = 'Cannot be before launch date';
      }

      if (
        !!values.lossOfExclusivity &&
        !!values.launch &&
        !!values.approval &&
        +values.launch < +values.lossOfExclusivity &&
        +values.approval > +values.lossOfExclusivity
      ) {
        errors.lossOfExclusivity = 'Cannot be before approval date';
      }

      return errors;
    },
  });

  const vals = Object.values(values).filter((v) => !!v === true);
  const allFieldsPopulated = vals.length === 4;

  return (
    <StyledModal
      size="small"
      hideX
      headerColor={colors.cream}
      visible={visible}
      handleClose={closeModal}
    >
      <div style={{ padding: 40 }}>
        <Subtitle1 style={{ textAlign: 'center' }}>Key dates</Subtitle1>
        <BodyNormal style={{ textAlign: 'center', marginTop: 10 }}>
          The key dates determine the shape of the plot
        </BodyNormal>
        <form onSubmit={handleSubmit}>
          <DateTextInput
            name={'approval'}
            title={'Approval'}
            date={values.approval}
            onUpdate={function (date: string): void {
              setFieldValue('approval', date, true);
              validateField('approval');
            }}
            setShowDatePicker={setShowDatePicker}
            onInputClick={() => {
              setTouched({ ...touched, approval: true });
            }}
            showDatePicker={showDatePicker === 'approval'}
            errorMessage={errors.approval}
          />
          <DateTextInput
            name={'launch'}
            title={'Launch'}
            date={values.launch}
            onUpdate={function (date: string): void {
              setFieldValue('launch', date, true);
              validateField('launch');
            }}
            setShowDatePicker={setShowDatePicker}
            showDatePicker={showDatePicker === 'launch'}
            errorMessage={errors.launch}
            onInputClick={() => {
              setTouched({ ...touched, launch: true });
            }}
          />
          <DateTextInput
            name={'peakSales'}
            title={'Peak sales'}
            date={values.peakSales}
            onUpdate={function (date: string): void {
              setFieldValue('peakSales', date, true);
              validateField('peakSales');
            }}
            setShowDatePicker={setShowDatePicker}
            showDatePicker={showDatePicker === 'peakSales'}
            errorMessage={errors.peakSales}
            onInputClick={() => {
              setTouched({ ...touched, peakSales: true });
            }}
          />
          <DateTextInput
            name={'lossOfExclusivity'}
            title={'Loss of exclusivity'}
            date={values.lossOfExclusivity}
            onUpdate={function (date: string): void {
              setFieldValue('lossOfExclusivity', date, true);
              validateField('lossOfExclusivity');
            }}
            setShowDatePicker={setShowDatePicker}
            showDatePicker={showDatePicker === 'lossOfExclusivity'}
            errorMessage={errors.lossOfExclusivity}
            onInputClick={() => {
              setTouched({ ...touched, lossOfExclusivity: true });
            }}
          />
          <CTAWrapper>
            <ButtonPill
              type="button"
              text="Cancel"
              level="secondary"
              onClick={() => closeModal()}
            />
            <ButtonPill
              type="submit"
              disabled={isSubmitting || !isValid || !allFieldsPopulated}
              text="Save"
            />
          </CTAWrapper>
        </form>
      </div>
    </StyledModal>
  );
};
