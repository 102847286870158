import { Step } from 'data/graphql/generated';

export function mapDBStepToReadableStep(step: Step) {
  switch (step) {
    // 1_1
    case Step.Keystakeholders:
      return 'Key Stakeholder Analysis';
    // 1_2
    case Step.Competitivelandscape:
      return 'Competitive Landscape';
    // 1_3
    case Step.Patientjourney:
      return 'Patient Journey';
    // 1_4
    case Step.KeyTrendsAnalysis:
      return 'Key Trends Analysis';
    // 1_5
    case Step.Keyinsights:
      return 'Key Insights';
    // 2_1
    case Step.Strategicquestion:
      return 'Strategic Question';
    // 2_2
    case Step.Patientflow:
      return 'Patient Flow';
    // 2_3
    case Step.CreatingValue:
      return 'Evidence Generation Ideas';
    // 2_4
    case Step.Options:
      return 'Strategic Possibilitites';
    // 2_5
    case Step.DriversAndBarriers:
      return 'Drivers and Barriers';
    // 3_1
    case Step.Positioning:
      return 'Brand Positioning';
    // 3_2
    case Step.ValueProposition:
      return 'Core Value Messages';
    // 3_3
    case Step.Competitiveadvantage:
      return 'Strategic Imperatives';
    // 3_4
    case Step.DistinctiveCapabilities:
      return 'Objectives and Distinctive Capabilities';
    // 3_5
    case Step.CriticalMetrics:
      return 'Lead and Lag Indicators';
    // 4_1
    case Step.AccessStrategy:
      return 'Access Strategy';
    // 4_2
    case Step.MedicalStrategy:
      return 'Medical Strategy';
    // 4_3
    case Step.CommercialStrategy:
      return 'Commercial Strategy';
    // 4_4
    case Step.RiskMitigation:
      return 'Risk Mitigation';
    // 4_5
    case Step.LongTermStrategy:
      return 'Long-term Strategy';
  }
}
