import React from 'react';
import styled from 'styled-components/macro';

import { colors } from 'constants/colors';
import { StrategicPossibility } from 'data/graphql/generated';

import { BodySmall, Icon, ReusableSidebar } from '../..';
import { CollapseIcon } from '../ImperativeSummarySidebar';
import { ContentSummary } from './ContentSummary';
import { useScrollContentBelowHeader } from '../useScrollContentBelowHeader';

const Header = styled.header`
  height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
`;

const SidebarTitle = styled.div`
  display: flex;
  align-items: center;
`;

const FadeContainer = styled.div<{ applyFade: boolean }>`
  position: relative;
  overflow: hidden;
  height: calc(100% - 55px);

  &::after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    z-index: 1;

    box-shadow: ${({ applyFade }) =>
      applyFade && `inset 0px 35px 45px -52px rgba(0, 0, 0, 0.5)`};
  }
`;

const Content = styled.div`
  padding: 0 15px 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  overflow-y: auto;
`;

interface LeadAndLagIndicatorsSummarySidebarProps {
  isOpen: boolean;
  heightOffset: number;
  onClose: () => void;

  strategicPossibility?: Pick<
    StrategicPossibility,
    'name' | 'driverOfTheBehaviour' | 'behaviourToDrive'
  > | null;
  indicators: {
    lead?: string[];
    lag?: string[];
  };
}

export const LeadAndLagIndicatorsSummarySidebar: React.FC<LeadAndLagIndicatorsSummarySidebarProps> = ({
  isOpen,
  onClose,
  heightOffset,
  strategicPossibility,
  indicators,
}) => {
  const fadeContent = useScrollContentBelowHeader();

  const handleClose = () => onClose();

  const leadIndicators = indicators.lead;
  const lagIndicators = indicators.lag;

  return (
    <ReusableSidebar isOpen={isOpen} heightOffset={heightOffset}>
      <Header>
        <SidebarTitle>
          <Icon
            name="MoreText"
            size={30}
            color={colors.greyDark}
            style={{ marginRight: 5 }}
          />
          <BodySmall>Strategic Imperative</BodySmall>
        </SidebarTitle>
        <CollapseIcon
          onClick={handleClose}
          name="Collapse right"
          size={30}
          color={colors.purple}
        />
      </Header>

      <FadeContainer applyFade={fadeContent}>
        <Content id="sidebar-content">
          <ContentSummary
            isLoading={false}
            strategicImperative={strategicPossibility || undefined}
            leadIndicators={leadIndicators}
            lagIndicators={lagIndicators}
          />
        </Content>
      </FadeContainer>
    </ReusableSidebar>
  );
};
