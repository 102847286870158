import React, { useEffect, useRef, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { ButtonLabel, Caption, Icon, Subtitle1 } from 'components/shared';
import { colors } from 'constants/colors';
import {
  ArchetypeFragment,
  ArchetypeWhereInput,
  useArchetypesQuery,
} from 'data/graphql/generated';
import useMobile from 'hooks/useMobile';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { polling } from 'constants/polling';
import { useAuthContext } from 'contexts/AuthContext';
import { sortBy } from 'lodash';
import useDesktop from 'hooks/useDesktop';
import { IconContainer } from 'components/shared/Icon';
import { Background, Modal } from 'components/Modal';
import ArchetypeAndCountryFlags from './ArchetypeAndCountryFlags';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import orderArchetypeCountries from 'utils/orderArchetypeCountries';
import { Wrapper as LoadingWrapper } from 'components/Loading';
import { verifyUserRole } from 'utils/verifyUserRole';
import OverlappingFlags from './OverlappingFlags';

const CreamTag = styled.div`
  background-color: ${colors.cream};
  border-radius: 5px;
  border: 0;
  padding: 5px;
`;

const Wrapper = styled(CreamTag)<{ hideSwitcher?: boolean }>`
  cursor: pointer;

  display: ${({ hideSwitcher }) => (hideSwitcher ? 'none' : 'flex')};
  align-items: center;
  width: 89px;
  height: 30px;

  order: -1;
  text-align: left;
  margin-right: 5px;
  margin-left: -5px;

  ${IconContainer} {
    margin: -7px;
  }

  ${LoadingWrapper} {
    margin: 0;
    flex-direction: row;
  }

  @media ${device.tabletMin} {
    width: fit-content;
  }

  @media ${device.desktopMin} {
    height: 40px;
    order: 1;
    margin-left: auto;
    margin-right: 0;
    width: 190px;
  }
`;

const NoneAssigned = styled.div<{ hideButton: boolean }>`
  display: ${({ hideButton }) => (hideButton ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ArchetypeAndChevron = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ArchetypeFlags = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const SelectedArchetype = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;

  ${ArchetypeFlags} {
    width: 50px;
  }

  ${Caption} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media ${device.desktopMin} {
    flex-direction: column;
    gap: 0;
  }
`;

const ArchetypeModal = styled(Modal)`
  ${Background} {
    background: ${colors.black40};
  }

  max-height: -webkit-fill-available;
  height: fit-content;
  background: ${colors.cream};
  margin: 60px auto 15px;
  width: calc(100% - 30px);

  ${IconContainer} {
    cursor: pointer;
  }
`;

const ModalHeader = styled.header`
  height: 40px;
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
`;

const ScrollableList = styled.div`
  height: fit-content;
  padding: 0 15px;

  max-height: calc(100vh - 170px);
  overflow: auto;
`;

const Title = styled.div`
  text-align: center;
  border-bottom: 1px solid ${colors.greyMedium};
  padding-bottom: 10px;
`;

const ModalFooter = styled.footer`
  padding: 9px 15px;
  border-top: 1px solid ${colors.greyMedium};
`;

const SwitcherDropdown = styled.div`
  position: absolute;
  width: 375px;
  max-height: 265px;
  height: fit-content;
  top: 55px;
  right: 15px;

  background: ${colors.cream};
  border: 1px solid ${colors.greyMedium};
  border-radius: 5px;
  padding: 0 5px;
`;

const OptionsContainer = styled.ul`
  margin: 0;
  padding: 0;
  overflow: auto;
  max-height: 224px;
`;

const StyledOverlappingFlags = styled(OverlappingFlags)`
  width: 50px;
  margin-right: 5px;

  @media ${device.desktopMin} {
    width: unset;
  }
`;

interface Props {
  drugId: string;
  strategyId: string;
  globalView?: boolean;
}

interface URLParams {
  archetypeId: string;
}

export const ArchetypeSwitcher: React.FC<Props> = ({
  drugId,
  strategyId,
  globalView = true,
}) => {
  const { archetypeId } = useParams<URLParams>();
  const isMobile = useMobile();
  const isDesktop = useDesktop();
  const history = useHistory();
  const [{ user }] = useAuthContext();
  const { isLead, isGlobalContributor } = verifyUserRole(
    user?.role,
    user?.country
  );
  const [switcherOpen, setSwitcherOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  useClickOutsideComponent(dropdownRef, () => setSwitcherOpen(false), [
    'switcher-toggle',
  ]);

  // Fetch archetypes
  const archetypesQueryVars: Record<'where', ArchetypeWhereInput> = {
    where: { strategyId: Number(strategyId), countriesAssigned: true },
  };

  const {
    data: archetypeData,
    loading: archetypesLoading,
    error: archetypesError,
    startPolling: archetypeStartPolling,
    stopPolling: archetypeStopPolling,
  } = useArchetypesQuery({
    variables: {
      ...archetypesQueryVars,
    },
    fetchPolicy: 'network-only',
  });

  const archetypes = archetypeData?.archetypes?.items;

  useEffect(() => {
    archetypeStartPolling(polling.default);
    return () => {
      archetypeStopPolling();
    };
  }, [archetypeStartPolling, archetypeStopPolling]);

  const [
    selectedArchetype,
    setSelectedArchetype,
  ] = useState<ArchetypeFragment | null>(archetypes?.[0] || null);

  const archetypeList = sortBy(archetypes, [
    (a) => a.stakeholderDefinition.title,
  ]);

  const userNotAssignedToArchetype = !archetypes?.some((a) =>
    a.archetypeCountries.some((ac) => ac.country === user?.country || '')
  );

  /* Default to the URL archetype first, else the user's own archetype, else the first archetype in the list */
  useEffect(() => {
    if (!archetypes) return;

    const urlArchetype = archetypes.find((a) => a.id === +archetypeId);

    const ownArchetype =
      isLead || isGlobalContributor
        ? undefined
        : archetypes?.find((a) =>
            a.archetypeCountries.some((ac) => ac.country === user?.country)
          );

    if (urlArchetype) {
      setSelectedArchetype(urlArchetype);
    } else if (ownArchetype) {
      setSelectedArchetype(ownArchetype);
    } else {
      setSelectedArchetype(archetypeList[0]);
    }
  }, [
    user,
    archetypes,
    archetypeList,
    archetypeId,
    isLead,
    isGlobalContributor,
  ]);

  // If user selects another archetype, display it first in the list
  useEffect(() => {
    if (globalView) return;

    if (selectedArchetype) {
      history.push(
        `/d/${drugId}/strategy/${strategyId}/4_1/prioritisation/${selectedArchetype.id}`
      );
    }
  }, [selectedArchetype, archetypes, drugId, history, strategyId, globalView]);

  const selectedArchetypeCountries = orderArchetypeCountries(
    selectedArchetype?.archetypeCountries,
    user
  );

  const hiddenCountries =
    selectedArchetypeCountries && selectedArchetypeCountries?.length > 4
      ? selectedArchetypeCountries.length - 3
      : 0;

  const showHiddenCountryCount = !!hiddenCountries && !isDesktop;

  const handleArchetypeClick = useCallback(
    (archetypeId: number) => {
      history.push(
        `/d/${drugId}/strategy/${strategyId}/4_1/prioritisation/${archetypeId}`
      );
      setSwitcherOpen(false);
    },
    [drugId, history, strategyId]
  );

  if (globalView)
    return (
      <Wrapper
        style={{
          width: isDesktop ? '164px' : 'auto',
          height: 'auto',
          gap: 2,
          flexDirection: isDesktop ? 'column' : 'row',
          alignItems: isDesktop ? 'flex-start' : 'center',
          cursor: 'default',
        }}
      >
        <Caption>Global {!isMobile && 'view'} only</Caption>
        {!isMobile && (
          <Caption color={colors.greyDark}>
            {isDesktop ? 'Across all' : 'All'} archetypes
          </Caption>
        )}
      </Wrapper>
    );

  return (
    <Wrapper
      hideSwitcher={
        userNotAssignedToArchetype && !(isLead || isGlobalContributor)
      }
    >
      <ErrorWrapper
        errors={[archetypesError]}
        isLoading={archetypesLoading}
        dataMissing={!archetypeData}
      >
        {!!archetypes?.length ? (
          <ArchetypeAndChevron
            data-cy="archetype-switcher"
            className="switcher-toggle"
            onClick={() => setSwitcherOpen(switcherOpen ? false : true)}
          >
            <SelectedArchetype>
              {!isMobile && (
                <Caption>
                  {selectedArchetype?.stakeholderDefinition?.title}
                </Caption>
              )}

              <StyledOverlappingFlags
                archetypeCountries={selectedArchetypeCountries}
                flagLimit={!!showHiddenCountryCount ? 3 : undefined}
              />
            </SelectedArchetype>
            <Icon name="Chevron-down" size={30} color={colors.black} />
          </ArchetypeAndChevron>
        ) : (
          <NoneAssigned
            data-cy="archetype-switcher"
            hideButton={!isLead}
            onClick={() => {
              history.push(`/d/${drugId}/strategy/${strategyId}/4_1/setup`);
            }}
          >
            {isDesktop ? (
              <div>
                <Caption>No archetypes assigned yet</Caption>
                <Caption color={colors.greyDark}>
                  Click to assign countries
                </Caption>
              </div>
            ) : (
              <Caption>
                {isMobile ? 'Assign archetypes' : 'No archetypes assigned yet'}
              </Caption>
            )}
            <Icon name={'Arrow-right'} size={20} color={colors.black} />
          </NoneAssigned>
        )}
        {switcherOpen && isDesktop ? (
          <SwitcherDropdown ref={dropdownRef}>
            <OptionsContainer>
              {archetypeList.map((a) => {
                return (
                  <ArchetypeAndCountryFlags
                    key={a.id}
                    selected={a === selectedArchetype}
                    archetype={a}
                    onClick={() => {
                      handleArchetypeClick(a.id);
                    }}
                  />
                );
              })}
            </OptionsContainer>
            {isLead && (
              <ModalFooter>
                <ButtonLabel
                  onClick={() => {
                    history.push(
                      `/d/${drugId}/strategy/${strategyId}/4_1/setup`
                    );
                  }}
                  color={colors.purple}
                >
                  Manage archetypes
                </ButtonLabel>
              </ModalFooter>
            )}
          </SwitcherDropdown>
        ) : (
          ReactDOM.createPortal(
            <ArchetypeModal
              handleClose={() => setSwitcherOpen(false)}
              visible={switcherOpen}
              padding={false}
              size="large"
            >
              <ModalHeader>
                <Icon
                  className="archetype-modal-close-icon"
                  name="Close"
                  size={30}
                  color={colors.black}
                  onClick={() => setSwitcherOpen(false)}
                />
              </ModalHeader>
              <Title>
                <Subtitle1 style={{ marginBottom: 5 }}>Archetypes</Subtitle1>
                <Caption color={colors.greyDark}>Choose an archetype</Caption>
              </Title>
              <ScrollableList>
                {archetypeList.map((a) => {
                  return (
                    <ArchetypeAndCountryFlags
                      key={a.id}
                      selected={a === selectedArchetype}
                      archetype={a}
                      onClick={() => {
                        handleArchetypeClick(a.id);
                      }}
                    />
                  );
                })}
              </ScrollableList>
              {isLead && (
                <ModalFooter>
                  <ButtonLabel
                    onClick={() => {
                      history.push(
                        `/d/${drugId}/strategy/${strategyId}/4_1/setup`
                      );
                    }}
                    color={colors.purple}
                  >
                    Manage archetypes
                  </ButtonLabel>
                </ModalFooter>
              )}
            </ArchetypeModal>,
            document.body
          )
        )}
      </ErrorWrapper>
    </Wrapper>
  );
};
