import { CountryGlobalRegional } from 'types';

// codes from Google & Wikipedia - https://en.wikipedia.org/wiki/List_of_country_calling_codes

export function countryPhoneCode(country: CountryGlobalRegional): string {
  switch (country) {
    // case 'abkhazia':
    // return '**';
    case 'afghanistan':
      return '+93';
    // case 'aland islands':
    // return '**';
    case 'albania':
      return '+355';
    case 'algeria':
      return '+213';
    case 'american samoa':
      return '+1';
    case 'andorra':
      return '+376';
    case 'angola':
      return '+244';
    case 'anguilla':
      return '+1';
    case 'antigua and barbuda':
      return '+1';
    case 'argentina':
      return '+54';
    case 'armenia':
      return '+374';
    case 'aruba':
      return '+297';
    case 'australia':
      return '+61';
    case 'austria':
      return '+43';
    case 'azerbaijan':
      return '+994';
    // case 'azores islands':
    // return '**';
    case 'bahamas':
      return '+1';
    case 'bahrain':
      return '+973';
    // case 'balearic islands':
    // return '**';
    case 'bangladesh':
      return '+880';
    case 'barbados':
      return '+1 246';
    // case 'basque country':
    // return '';
    case 'belarus':
      return '+375';
    case 'belgium':
      return '+32';
    case 'belize':
      return '+501';
    case 'benin':
      return '+229';
    case 'bermuda':
      return '+1';
    case 'bhutan':
      return '+975';
    case 'bolivia':
      return '+591';
    case 'bonaire':
      return '+599';
    case 'bosnia and herzegovina':
      return '+387';
    case 'botswana':
      return '+267';
    case 'brazil':
      return '+55';
    // case 'british columbia':
    // return '**';
    case 'british indian ocean territory':
      return '+246';
    // case 'british virgin islands':
    // return '**';
    case 'brunei':
      return '+673';
    case 'bulgaria':
      return '+359';
    case 'burkina faso':
      return '+226';
    case 'burundi':
      return '+257';
    case 'cambodia':
      return '+855';
    case 'cameroon':
      return '+237';
    case 'canada':
      return '+1';
    case 'canary islands':
      return '+34';
    case 'cape verde':
      return '+238';
    case 'cayman islands':
      return '+1';
    case 'central african republic':
      return '+236';
    // case 'ceuta':
    // return '**';
    case 'chad':
      return '+235';
    case 'chile':
      return '+56';
    case 'china':
      return '+86';
    case 'christmas island':
      return '+61';
    case 'cocos island':
      return '+61';
    case 'colombia':
      return '+57';
    case 'comoros':
      return '+269';
    case 'cook islands':
      return '+682';
    // case 'corsica':
    // return '**';
    case 'costa rica':
      return '+506';
    case 'croatia':
      return '+385';
    case 'cuba':
      return '+53';
    case 'curacao':
      return '+599';
    case 'cyprus':
      return '+357';
    // case 'czech republic':
    // return '**';
    // case 'democratic republic of congo':
    // return '**';
    case 'denmark':
      return '+45';
    case 'djibouti':
      return '+253';
    case 'dominican republic':
      return '+1';
    case 'dominica':
      return '+1';
    // case 'east timor':
    // return '**';
    case 'ecuador':
      return '+593';
    case 'egypt':
      return '+20';
    case 'england':
      return '+44';
    case 'equatorial guinea':
      return '240';
    case 'eritrea':
      return '+291';
    case 'estonia':
      return '+372';
    case 'ethiopia':
      return '+251';
    // case 'european union':
    //   return 'EU';
    case 'falkland islands':
      return '+500';
    case 'faroe islands':
      return '+298';
    case 'fiji':
      return '+679';
    case 'finland':
      return '+358';
    case 'france':
      return '+33';
    case 'french polynesia':
      return '+689';
    case 'gabon':
      return '+241';
    // case 'galapagos islands':
    // return '**';
    case 'gambia':
      return '+220';
    case 'georgia':
      return '+995';
    case 'germany':
      return '+49';
    case 'ghana':
      return '+233';
    case 'gibraltar':
      return '+350';
    case 'greece':
      return '+30';
    case 'greenland':
      return '+299';
    case 'grenada':
      return '+1';
    case 'guam':
      return '+1';
    case 'guatemala':
      return '+502';
    case 'guernsey':
      return '+44';
    case 'guinea bissau':
      return '+224';
    case 'guinea':
      return '+225';
    case 'guyana':
      return '+592';
    case 'haiti':
      return '+509';
    // case 'hawaii':
    // return '**';
    case 'honduras':
      return '+504';
    case 'hong kong':
      return '+852';
    case 'hungary':
      return '+36';
    case 'iceland':
      return '+354';
    case 'india':
      return '+91';
    case 'indonesia':
      return '+62';
    case 'iran':
      return '+98';
    case 'iraq':
      return '+964';
    case 'ireland':
      return '+353';
    case 'isle of man':
      return '+44';
    case 'israel':
      return '+972';
    case 'italy':
      return '+39';
    // case 'ivory coast':
    // return '**';
    case 'jamaica':
      return '+1';
    case 'japan':
      return '+81';
    case 'jersey':
      return '+44';
    case 'jordan':
      return '+962';
    case 'kazakhstan':
      return '+7';
    case 'kenya':
      return '+254';
    case 'kiribati':
      return '+686';
    // case 'kosovo':
    // return '**';
    case 'kuwait':
      return '+965';
    case 'kyrgyzstan':
      return '+996';
    // case 'laos':
    // return '**';
    case 'latvia':
      return '+371';
    case 'lebanon':
      return '+961';
    case 'lesotho':
      return '+266';
    case 'liberia':
      return '+231';
    case 'libya':
      return '+218';
    case 'liechtenstein':
      return '+423';
    case 'lithuania':
      return '+370';
    case 'luxembourg':
      return '+352';
    case 'macao':
      return '+853';
    case 'madagascar':
      return '+261';
    // case 'madeira':
    // return '**';
    case 'malawi':
      return '+265';
    case 'malaysia':
      return '+60';
    case 'maldives':
      return '+960';
    case 'mali':
      return '+223';
    case 'malta':
      return '+356';
    case 'marshall island':
      return '+692';
    case 'martinique':
      return '+596';
    case 'mauritania':
      return '+222';
    case 'mauritius':
      return '+230';
    // case 'melilla':
    // return '**';
    case 'mexico':
      return '+52';
    case 'micronesia':
      return '+691';
    case 'moldova':
      return '+373';
    case 'monaco':
      return '+377';
    case 'mongolia':
      return '+976';
    case 'montenegro':
      return '+382';
    case 'montserrat':
      return '+1';
    case 'morocco':
      return '+212';
    case 'mozambique':
      return '+258';
    case 'myanmar':
      return '+95';
    case 'namibia':
      return '+264';
    // case 'nato':
    // return '**';
    case 'nauru':
      return '+674';
    case 'nepal':
      return '+977';
    case 'netherlands':
      return '+31';
    case 'new zealand':
      return '+64';
    case 'nicaragua':
      return '+505';
    case 'nigeria':
      return '+234';
    case 'niger':
      return '+227';
    case 'niue':
      return '+683';
    case 'norfolk island':
      return '+672';
    // case 'northen cyprus':
    // return '**';
    case 'northern marianas islands':
      return '+1';
    case 'north korea':
      return '+850';
    case 'norway':
      return '+47';
    case 'oman':
      return '+968';
    // case 'orkney islands':
    // return '**';
    // case 'ossetia':
    // return '**';
    case 'pakistan':
      return '+92';
    case 'palau':
      return '+680';
    case 'palestine':
      return '+970';
    case 'panama':
      return '+507';
    case 'papua new guinea':
      return '+675';
    case 'paraguay':
      return '+595';
    case 'peru':
      return '+51';
    case 'philippines':
      return '+63';
    case 'pitcairn islands':
      return '+64';
    case 'portugal':
      return '+351';
    case 'puerto rico':
      return '+1';
    case 'qatar':
      return '+974';
    // case 'rapa nui':
    // return '**';
    case 'republic of macedonia':
      return '+389';
    case 'republic of poland':
      return '+48';
    // case 'republic of the congo':
    // return '**';
    case 'romania':
      return '+40';
    case 'russia':
      return '+7';
    case 'rwanda':
      return '+250';
    // case 'saba island':
    // return '**';
    case 'saint kitts and nevis':
      return '+1';
    // case 'salvador':
    // return '**';
    case 'samoa':
      return '+685';
    case 'san marino':
      return '+378';
    case 'sao tome and principe':
      return '+239';
    // case 'sardinia':
    // return '**';
    case 'saudi arabia':
      return '+966';
    case 'scotland':
      return '+44';
    case 'senegal':
      return '+221';
    case 'serbia':
      return '+381';
    case 'seychelles':
      return '+248';
    case 'sierra leone':
      return '+232';
    case 'singapore':
      return '+65';
    case 'sint eustatius':
      return '+599';
    case 'sint maarten':
      return '+1';
    case 'slovakia':
      return '+421';
    case 'slovenia':
      return '+386';
    case 'solomon islands':
      return '+677';
    case 'somalia':
      return '+252';
    // case 'somaliland':
    // return '**';
    case 'south africa':
      return '+27';
    case 'south korea':
      return '+82';
    case 'south sudan':
      return '+211';
    case 'spain':
      return '+34';
    case 'sri lanka':
      return '+94';
    // case 'st barts':
    // return '**';
    // case 'st lucia':
    // return '**';
    // case 'st vincent and the grenadines':
    // return '**';
    case 'sudan':
      return '+249';
    case 'suriname':
      return '+597';
    // case 'swaziland':
    // return '**';
    case 'sweden':
      return '+46';
    case 'switzerland':
      return '+41';
    case 'syria':
      return '+963';
    case 'taiwan':
      return '+886';
    case 'tajikistan':
      return '+992';
    case 'tanzania':
      return '+255';
    case 'thailand':
      return '+66';
    // case 'tibet':
    // return '**';
    case 'togo':
      return '+228';
    case 'tokelau':
      return '+690';
    case 'tonga':
      return '+676';
    // case 'transnistria':
    // return '**';
    case 'trinidad and tobago':
      return '+1';
    case 'tunisia':
      return '+216';
    case 'turkey':
      return '+90';
    case 'turkmenistan':
      return '+993';
    case 'turks and caicos':
      return '+1';
    case 'tuvalu':
      return '+688';
    case 'uganda':
      return '+256';
    case 'ukraine':
      return '+380';
    case 'united arab emirates':
      return '+971';
    case 'united kingdom':
      return '+44';
    case 'united states of america':
      return '+1';
    case 'uruguay':
      return '+598';
    case 'uzbekistan':
      return '+998';
    case 'vanuatu':
      return '+678';
    // case 'vatican city':
    // return '**';
    case 'venezuela':
      return '+58';
    case 'vietnam':
      return '+84';
    case 'virgin islands':
      return '+1';
    // case 'wales':
    // return 'GB';
    case 'western sahara':
      return '+212';
    case 'yemen':
      return '+967';
    case 'zambia':
      return '+260';
    case 'zimbabwe':
      return '+263';
  }
  return '';
}
