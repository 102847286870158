import { colors } from 'constants/index';
import { Stakeholder } from 'data/graphql/generated';
import React from 'react';
import styled from 'styled-components';
import { Icon, ButtonLabel } from '..';

const DropdownItemWrapper = styled.div<{
  bgColor: string;
  active: boolean;
  borderColor: string;
  bgHover: string;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  background: ${({ bgColor }) => bgColor};
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
  padding-right: 15px;
  &:hover {
    background: ${({ bgHover }) => bgHover};
  }
  transition: all 0.3s;
  border: 1.5px solid
    ${({ active, borderColor }) => (active ? borderColor : 'transparent')};

  & > p {
    margin-left: 5px;
  }
`;

export const DropdownItem: React.FC<{
  stakeholder: Stakeholder;
  onClick?(): void;
  active?: boolean;
}> = ({ stakeholder, onClick, active = false }) => {
  return stakeholder === Stakeholder.Patient ? (
    <DropdownItemWrapper
      bgHover={colors.purple10}
      borderColor={colors.purple}
      active={active}
      onClick={(e) => {
        onClick?.();
      }}
      bgColor={colors.purple05}
    >
      <Icon name="Patient" size={30} color={colors.purple} />
      <ButtonLabel>Patient</ButtonLabel>
    </DropdownItemWrapper>
  ) : stakeholder === Stakeholder.Healthcare ? (
    <DropdownItemWrapper
      bgHover={colors.green10}
      borderColor={colors.green}
      active={active}
      onClick={(e) => {
        onClick?.();
      }}
      bgColor={colors.green05}
    >
      <Icon name="HCP" size={30} color={colors.green} />
      <ButtonLabel>Healthcare professional</ButtonLabel>
    </DropdownItemWrapper>
  ) : stakeholder === Stakeholder.Payor ? (
    <DropdownItemWrapper
      bgHover={colors.blue10}
      borderColor={colors.blue}
      active={active}
      onClick={(e) => {
        onClick?.();
      }}
      bgColor={colors.blue05}
    >
      <Icon name="Dollar" size={30} color={colors.blue} />
      <ButtonLabel>Payor</ButtonLabel>
    </DropdownItemWrapper>
  ) : stakeholder === Stakeholder.Provider ? (
    <DropdownItemWrapper
      bgHover={colors.pink10}
      borderColor={colors.pink}
      active={active}
      onClick={(e) => {
        onClick?.();
      }}
      bgColor={colors.pink05}
    >
      <Icon name="Provider" size={30} color={colors.pink} />
      <ButtonLabel>Provider</ButtonLabel>
    </DropdownItemWrapper>
  ) : stakeholder === Stakeholder.Policymaker ? (
    <DropdownItemWrapper
      bgHover={colors.red10}
      borderColor={colors.red}
      active={active}
      onClick={(e) => {
        onClick?.();
      }}
      bgColor={colors.red05}
    >
      <Icon name="Policymaker" size={30} color={colors.red} />
      <ButtonLabel>Policymaker</ButtonLabel>
    </DropdownItemWrapper>
  ) : null;
};
