import { debounce, throttle } from 'lodash';
import React, {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components/macro';
import { TabTypes } from 'types';
import { TabNav } from './TabNav';
import { observeResize } from 'utils/observeResize';

interface Props {
  componentName: TabTypes;
  scrollContainerRef?: MutableRefObject<HTMLDivElement | null>;
  expand?: boolean;
  hideScrollTabs?: boolean;
  className?: string;
}

const TabsOuterContainerWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  overflow-y: visible;
  overflow-x: inherit;
`;

const TabsContainerWrapper = styled.div<{
  componentName: TabTypes;
  expand?: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: flex-start;

  > div {
    flex-shrink: 0;
  }
  scroll-behavior: smooth;
  overflow-y: visible;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  //Fix for contribution tab overflow
  ${({ componentName, expand }) =>
    componentName === 'ContributionTab' &&
    css`
      pointer-events: none;
      margin-bottom: 0;

      &:hover {
        pointer-events: auto;
        margin-bottom: ${() => !!expand && '-400px'};
      }
    `}
`;

const NavWrapper = styled.div<{ width: number; direction: string }>`
  width: ${({ width }) => width}px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: ${({ direction }) =>
    direction === 'l' ? 'flex-start' : 'flex-end'};
  overflow: hidden;
  transition: width 0.2s linear;
  flex-shrink: 0;
`;

const navWidth = 35;

export const TabGroup: React.FC<Props> = ({
  componentName,
  children,
  scrollContainerRef,
  expand,
  hideScrollTabs,
  className,
}) => {
  const tabsContainerRef = useRef<HTMLDivElement | null>(null);
  const tabsOuterContainerRef = useRef<HTMLDivElement | null>(null);
  const [arrows, setArrows] = useState({ left: false, right: false });

  const truncate = useCallback(
    (element: RefObject<HTMLDivElement>, scrollPosition: number) => {
      const ele = element.current;
      if (ele) {
        const hasScrollableContent =
          ele.offsetWidth <
          ele.scrollWidth -
            // safari seems to give items a scroll width of 1 for some reason, so this -1 adds a threshold
            1;

        if (hasScrollableContent) {
          const isScrolledPastStart = scrollPosition > 0;

          const isNotScrolledToEnd =
            scrollPosition + ele.offsetWidth + navWidth < ele.scrollWidth;

          if (isScrolledPastStart && isNotScrolledToEnd) {
            setArrows({ left: true, right: true });
            return;
          }

          setArrows({ left: isScrolledPastStart, right: !isScrolledPastStart });
        } else {
          setArrows({ left: false, right: false });
        }
      }
    },
    [setArrows]
  );

  const debouncedTruncate = useMemo(() => debounce(truncate, 200), [truncate]);

  useEffect(() => {
    const throttledScrollHandler = throttle(
      (event) => {
        const element = event.target as HTMLDivElement;

        if (!!element) {
          let scrollVal = element?.scrollLeft;
          truncate(tabsContainerRef, scrollVal);
        }
      },
      300,
      { trailing: true, leading: false }
    );

    tabsContainerRef.current?.addEventListener(
      'scroll',
      throttledScrollHandler
    );

    const element = tabsContainerRef.current;
    return () => {
      element?.removeEventListener('scroll', throttledScrollHandler);
    };
  }, [truncate]);

  useEffect(() => {
    debouncedTruncate(
      tabsContainerRef,
      tabsContainerRef.current?.scrollLeft || 0
    );
    const observer = observeResize(tabsContainerRef.current, () => {
      debouncedTruncate(
        tabsContainerRef,
        tabsContainerRef.current?.scrollLeft || 0
      );
    });

    return () => {
      observer?.disconnect();
    };
  }, [debouncedTruncate]);

  function calcValueToScrollBy() {
    switch (componentName) {
      case 'CompetitveLandscapeCompetitor':
        return 242;
      case 'ContributionTab':
        return 245;
      case 'KeyStakeholderCreator':
        return 290;
      case 'KeyStatement':
        return 260;
      default:
        return 150;
    }
  }

  return (
    <TabsOuterContainerWrapper
      className={`tabsOuterContainerRef ${className}`}
      ref={(e) => {
        if (scrollContainerRef) {
          scrollContainerRef.current = e;
        }
        tabsOuterContainerRef.current = e;
      }}
    >
      {!hideScrollTabs && (
        <NavWrapper direction="l" width={arrows.left ? navWidth : 0}>
          <TabNav
            tabType={componentName}
            onClick={() => {
              if (tabsContainerRef.current) {
                let scrollPosition =
                  tabsContainerRef.current.scrollLeft - calcValueToScrollBy();
                //Scroll to end if there is still a chunk left to scroll
                if (scrollPosition < navWidth) {
                  scrollPosition = 0;
                }
                truncate(tabsContainerRef, scrollPosition);
                tabsContainerRef.current.scrollLeft = scrollPosition;
              }
            }}
            direction="l"
          />
        </NavWrapper>
      )}
      <TabsContainerWrapper
        expand={expand}
        componentName={componentName}
        className={`tabsContainerRef ${
          componentName === 'ContributionTab'
            ? ' tabsContainerRef__contributionTab'
            : ''
        }`}
        ref={tabsContainerRef}
      >
        {children}
      </TabsContainerWrapper>
      {!hideScrollTabs && (
        <NavWrapper direction="r" width={arrows.right ? navWidth : 0}>
          <TabNav
            tabType={componentName}
            onClick={() => {
              if (tabsContainerRef.current) {
                const amountLeftToScroll =
                  tabsContainerRef.current.scrollWidth -
                  (tabsContainerRef.current.scrollLeft +
                    tabsContainerRef.current.offsetWidth);

                let scrollPosition =
                  tabsContainerRef.current.scrollLeft + calcValueToScrollBy();
                //Scroll to end if there is still a chunk left to scroll
                if (amountLeftToScroll < navWidth) {
                  scrollPosition = tabsContainerRef.current.scrollWidth;
                }
                truncate(tabsContainerRef, scrollPosition);
                tabsContainerRef.current.scrollLeft = scrollPosition;
              }
            }}
            direction="r"
          />
        </NavWrapper>
      )}
    </TabsOuterContainerWrapper>
  );
};
