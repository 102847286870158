import { useState } from 'react';
import { DraggablePin } from 'components/GridPages/DraggablePin';
import { DragObjectWithType, DropTargetMonitor } from 'react-dnd';
import {
  calculateDropCoordinates,
  DropzoneGrid,
} from 'components/GridPages/DropzoneGrid';
import {
  LongTermSolutionFragment,
  LongTermSolutionOrderByInput,
  LongTermSolutionsDocument,
  LongTermSolutionsQuery,
  LongTermSolutionWhereInput,
  Exact,
  FutureTrendFragment,
  Maybe,
  useLongTermSolutionUpdateMutation,
} from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import { ApolloQueryResult } from '@apollo/client';
import { DraggableCardType, PreviewOrExistingSolution } from 'types';
import { apolloUpdateHelper } from 'utils/apolloQueryHelpers';
import { longTermSolutionsQueryVars } from 'containers/LongTermStrategySolutions';
import { HoverCalloutWrapper } from 'components/GridPages/HoverCalloutWrapper';
import { SolutionCallout } from './SolutionCallout';
import useHandleCalloutHover from 'hooks/useHandleCalloutHover';
import TwoButtonModal from 'components/TwoButtonModal';

interface Props {
  futureTrends: FutureTrendFragment[];
  placedSolutions: LongTermSolutionFragment[];
  refetchSolutions: (
    variables?:
      | Partial<
          Exact<{
            where: LongTermSolutionWhereInput;
            orderBy?: Maybe<LongTermSolutionOrderByInput> | undefined;
            skip?: Maybe<number> | undefined;
            take?: Maybe<number> | undefined;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<LongTermSolutionsQuery>>;
  setTouchPositioningView: React.Dispatch<React.SetStateAction<string | null>>;
  touchPositioningView: string | null;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  strategyId: string;
}

export default function LongTermSolutionsGrid({
  futureTrends,
  placedSolutions,
  refetchSolutions,
  setTouchPositioningView,
  touchPositioningView,
  setSidebarOpen,
  strategyId,
}: Props) {
  const [updateLongTermSolution] = useLongTermSolutionUpdateMutation();
  const isDesktop = useDesktop();
  const [hoveredPin, setHoveredPin] = useState<number>(-1);
  const [confirmationModal, setConfirmationModal] = useState<string | null>(
    null
  );
  const queryVars = longTermSolutionsQueryVars(strategyId);
  const [removePinLoading, setRemovePinLoading] = useState(false);

  // Handle callout card hover states
  const { showingCallout } = useHandleCalloutHover({ hoveredPin });

  async function updateSolutionPosition({
    data,
    localUid,
    x,
    y,
    closeTouchView,
  }: {
    data: PreviewOrExistingSolution | null;
    localUid: string;
    x: number;
    y: number;
    closeTouchView: () => void;
  }) {
    try {
      if (data && 'id' in data) {
        // Update existing solution
        const { localUid } = data;

        await updateLongTermSolution({
          variables: {
            localUid,
            data: { x, y },
          },
          optimisticResponse: {
            longTermSolutionUpdate: {
              ...data,
              x,
              y,
            },
          },
          update: apolloUpdateHelper({
            responseField: 'longTermSolutionUpdate',
            query: LongTermSolutionsDocument,
            queryVars,
            queryName: 'longTermSolutions',
          }),
        });
      } else {
        // Update preview
        await updateLongTermSolution({
          variables: {
            localUid,
            data: { x, y },
          },
        });
      }

      refetchSolutions();
    } catch (error) {
      alert('Something went wrong');
    } finally {
      closeTouchView();
    }
  }

  async function handleRemove(localUid: string, focused: boolean) {
    if (focused) {
      setConfirmationModal(localUid);
    } else {
      try {
        const solutionToUpdate = placedSolutions.find(
          (item) => item.localUid === localUid
        );
        if (!solutionToUpdate) return;

        await updateLongTermSolution({
          variables: {
            localUid,
            data: { x: null, y: null },
          },
          update: apolloUpdateHelper({
            responseField: 'longTermSolutionUpdate',
            query: LongTermSolutionsDocument,
            queryVars,
            queryName: 'longTermSolutions',
          }),
          optimisticResponse: {
            longTermSolutionUpdate: {
              ...solutionToUpdate,
              x: null,
              y: null,
            },
          },
        });
      } catch (err) {
        alert('Something went wrong');
      }
    }
  }

  return (
    <DropzoneGrid
      xAxisTitle={'Risk'}
      yAxisTitle={'Reward'}
      setTouchPositioningView={setTouchPositioningView}
      touchPositioningView={touchPositioningView}
      setSidebarOpen={setSidebarOpen}
      gridClickHandler={(
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        closeTouchView: () => void
      ) => {
        if (isDesktop || !touchPositioningView) return;

        e.stopPropagation();

        const { clientX: clickXCoord, clientY: clickYCoord } = e;

        const grid = e.currentTarget as HTMLDivElement;
        const {
          height,
          width,
          x: gridX,
          y: gridY,
        } = grid.getBoundingClientRect();
        const xPosition = ((clickXCoord - gridX) / width) * 100;
        const yPosition = ((clickYCoord - gridY) / height) * 100;
        const [x, y] = [Math.round(xPosition), Math.round(yPosition)];

        // Update the solution with its grid coordinates
        (async () =>
          await updateSolutionPosition({
            data: null,
            localUid: touchPositioningView,
            x,
            y,
            closeTouchView,
          }))();
      }}
      gridDropHandler={(
        item: DragObjectWithType,
        monitor: DropTargetMonitor,
        closeTouchView: () => void
      ) => {
        const squareContainer = document.getElementById('square-container');
        if (!squareContainer) return;

        const coords = calculateDropCoordinates(monitor, squareContainer);
        if (!coords) return;

        const { data } = item as DraggableCardType;
        if (!data?.localUid) return;

        // Update the solution with its grid coordinates
        updateSolutionPosition({
          data,
          localUid: data.localUid,
          x: coords.x,
          y: coords.y,
          closeTouchView,
        });
      }}
    >
      {placedSolutions?.map((sol) => {
        const futureTrend = futureTrends?.find(
          (ft) => ft.id === sol.futureTrendId
        );

        if (!futureTrend) return null;

        const onMouseOver = () => setHoveredPin(sol.id);
        const onMouseOut = () => setHoveredPin(-1);

        return (
          <DraggablePin
            key={sol.id}
            data={sol}
            style={{
              top: `calc(${sol.y}%)`,
              left: `calc(${sol.x}%)`,
            }}
            showCallout={showingCallout === sol.id}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            setTouchPositioningView={setTouchPositioningView}
            selected={touchPositioningView === sol.localUid}
            removeFromGrid={() => handleRemove(sol.localUid, sol.focused)}
          >
            <HoverCalloutWrapper
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              pinID={sol.id}
            >
              <SolutionCallout data={sol} futureTrend={futureTrend} />
            </HoverCalloutWrapper>
          </DraggablePin>
        );
      })}
      <TwoButtonModal
        leftButtonLevel="secondary"
        rightButtonOnClick={async () => {
          if (!confirmationModal) return;
          setRemovePinLoading(true);

          const solutionToUpdate = placedSolutions.find(
            (item) => item.localUid === confirmationModal
          );
          if (!solutionToUpdate) return;

          try {
            await updateLongTermSolution({
              variables: {
                localUid: confirmationModal,
                data: { x: null, y: null, focused: false },
              },
              update: apolloUpdateHelper({
                responseField: 'longTermSolutionUpdate',
                query: LongTermSolutionsDocument,
                queryVars,
                queryName: 'longTermSolutions',
              }),
              optimisticResponse: {
                longTermSolutionUpdate: {
                  ...solutionToUpdate,
                  x: null,
                  y: null,
                  focused: false,
                },
              },
            });
          } catch (err) {
            alert('Something went wrong');
          } finally {
            setConfirmationModal(null);
            setRemovePinLoading(false);
          }
        }}
        rightButtonText="Remove"
        rightButtonLevel="danger"
        leftButtonOnClick={() => setConfirmationModal(null)}
        leftButtonText="Cancel"
        handleClose={() => setConfirmationModal(null)}
        modalOpen={!!confirmationModal}
        title="Remove this item from your plan?"
        body="Removing this item from the prioritisation grid will also remove it from your plan"
        rightButtonLoading={removePinLoading}
      />
    </DropzoneGrid>
  );
}
