import { colors } from 'constants/colors';
import { User, useUserInviteResendMutation } from 'data/graphql/generated';
import React from 'react';
import styled from 'styled-components/macro';
import { Country } from 'types';
import { device } from 'utils/breakpoints';
import { countryPhoneCode } from 'utils/countryPhoneCode';
import EmailAndInviteButton from './EmailAndInviteButton';
import { Icon } from './Icon';
import { BodySmall, Subtitle2 } from './TextStyles';

export const Wrapper = styled.div`
  margin-bottom: 10px;

  @media ${device.tabletMin} {
    margin-bottom: 0;
  }

  @media ${device.desktopMin} {
    margin-bottom: 0;

    ${BodySmall} {
      margin-bottom: 0;
    }
  }
`;

const NameAndStatus = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: flex-start;
`;

const TickIcon = styled(Icon)`
  height: 20px;
`;

const ClockIcon = styled(Icon)`
  svg {
    margin: -5px;
  }
`;

const PhoneNumber = styled(BodySmall)`
  margin-top: 5px;
`;

interface Props {
  displayStatus: boolean;
  user: User;
  drugId: number;
  companyId?: number;
  strategyId: number;
}

export const UserDetails = ({
  displayStatus,
  user,
  drugId,
  companyId,
  strategyId,
}: Props) => {
  const [userInviteResend] = useUserInviteResendMutation();
  let {
    name,
    verifiedEmail,
    email,
    phoneNumber,
    phoneCountry,
    id: userId,
  } = user;

  async function handleResendInvite() {
    if (!email) return console.error('No email provided');
    if (!companyId) return console.error('No companyId provided');
    if (!strategyId) return console.error('No strategyId provided');

    try {
      await userInviteResend({
        variables: {
          data: {
            companyId,
            drugId,
            userId,
            strategyId,
          },
        },
      });

      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  return (
    <Wrapper>
      <NameAndStatus>
        <Subtitle2>{name}</Subtitle2>
        {displayStatus &&
          (verifiedEmail ? (
            <TickIcon name="Tick" size={26} color={colors.greenDark} />
          ) : (
            <ClockIcon name="Clock" size={30} color={colors.orange} />
          ))}
      </NameAndStatus>
      <EmailAndInviteButton
        verifiedEmail={verifiedEmail}
        displayStatus={displayStatus}
        resendInvite={handleResendInvite}
        email={email}
      />
      {displayStatus && phoneNumber && phoneCountry && (
        <PhoneNumber color={colors.greyDark}>
          {countryPhoneCode(phoneCountry as Country) + ' ' + phoneNumber}
        </PhoneNumber>
      )}
    </Wrapper>
  );
};
