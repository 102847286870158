import {
  useKeyEventsQuery,
  Sort,
  KeyEventWhereInput,
  KeyEventEnabledInput,
} from 'data/graphql/generated';
import { useMemo } from 'react';

export const useGetKeyEvents = ({
  where = {},
  enabledConditions = {},
  skip = false,
}: {
  where?: KeyEventWhereInput;
  enabledConditions?: KeyEventEnabledInput;
  skip?: boolean;
}) => {
  const { data, error, startPolling, stopPolling, loading } = useKeyEventsQuery(
    {
      skip,
      variables: {
        where,
        enabledConditions,
        orderBy: { date: Sort.Asc },
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const itemData = useMemo(() => {
    return data?.keyEvents?.items || [];
  }, [data?.keyEvents?.items]);

  return {
    keyEvents: itemData,
    error,
    startPolling,
    stopPolling,
    loading,
  };
};
