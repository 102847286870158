import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import {
  SubStep,
  StrategicQuestionsDocument,
  useStrategicQuestionsQuery,
  useStrategicQuestionUpdateMutation,
  useStrategyQuery,
  StrategicQuestionUpdateInput,
} from 'data/graphql/generated';
import { BodyNormal, Subtitle2 } from 'components/shared';
import useDesktop from 'hooks/useDesktop';
import { colors, polling } from 'constants/index';
import { useAuthContext } from 'contexts/AuthContext';
import { useSidebar } from 'hooks';

import { LeadAndLagIndicatorsSummarySidebar } from 'components/shared/ImperativeSummarySidebar';
import { verifyUserRole } from 'utils/verifyUserRole';
import {
  CriticalMetricsNavbar,
  MissingStrategicPossibilities,
  StyledStepHeader,
} from '../../../../shared/shared-ui-critical-metrics/src';
import { CriticalMetricsStrategicImperatives } from '../../../../shared/shared-ui-critical-metrics/src/lib/CriticalMetricsStrategicImperatives';

import { GoalsTable } from '../../../feature-goals-table-row/src';
import { useCreateCriticalMetricGoal } from '../../../data-access-critical-metrics-goals/src/lib/hooks/useCreateCriticalMetricGoal';
import { useUpdateCriticalMetricGoal } from '../../../data-access-critical-metrics-goals/src/lib/hooks/useUpdateCriticalMetricGoal';
import { useGetCriticalMetricGoals } from '../../../data-access-critical-metrics-goals/src/lib/hooks/useGetCriticalMetricGoals';
import { useDeleteCriticalMetricGoal } from '../../../data-access-critical-metrics-goals/src/lib/hooks/useDeleteCriticalMetricGoal';
import { useCriticalMetricsCompetitiveAdvantage } from '../../../../shared/shared-data-access-critical-metrics/src/lib/hooks/useCriticalMetricsCompetitiveAdvantage';
import { CriticalMetricsParams } from '../../../../shared/shared-data-access-critical-metrics/src';
import { PageWrapper } from 'containers/RiskMitigationIdeas';
import { useGetNavHeight } from 'hooks/useGetNavHeight';
import { useGlobalContext } from 'contexts/GlobalContext';

export const GoalsPage = () => {
  const {
    drugId,
    strategyId,
    competitiveAdvantageRowUrlParam,
  } = useParams<CriticalMetricsParams>();

  const [{ user }] = useAuthContext();
  const { isLead, isContributor } = verifyUserRole(user?.role, user?.country);
  const isDesktop = useDesktop();
  const {
    isOpen: sidebarOpen,
    close: closeSidebar,
    open: openSidebar,
  } = useSidebar();
  const navHeight = useGetNavHeight();

  const handleCreateRow = useCreateCriticalMetricGoal();
  const handleUpdateRow = useUpdateCriticalMetricGoal();
  const handleDeleteRow = useDeleteCriticalMetricGoal();

  // handle global context path
  const [ strategicImperativeId, setStrategicImperativeId] = useGlobalContext(
    'strategicImperativeId'
  );

  console.log(strategicImperativeId)
  const {
    data: strategyData,
    loading: strategyLoading,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
    error: strategyError,
  } = useStrategyQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'network-only',
  });

  const {
    selectedRowImperative,
    competitiveAdvantageRows,
    competitiveAdvantageRowsError,
    competitiveAdvantageRowsLoading,
    startCompetitiveAdvantageRowsPolling,
    stopCompetitiveAdvantageRowsPolling,
  } = useCriticalMetricsCompetitiveAdvantage(SubStep.Goals);

  const {
    goals: criticalMetricGoals,
    error: criticalMetricGoalsError,
    loading: criticalMetricGoalsLoading,
    startPolling: startCriticalMetricGoalsPolling,
    stopPolling: stopCriticalMetricGoalsPolling,
  } = useGetCriticalMetricGoals(strategyId, competitiveAdvantageRowUrlParam);

  const {
    data: strategicQuestionsData,
    error: strategicQuestionsError,
    loading: strategicQuestionsLoading,
    startPolling: startStrategicQuestionsPolling,
    stopPolling: stopStrategicQuestionsPolling,
  } = useStrategicQuestionsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        competitiveAdvantageRowId: +competitiveAdvantageRowUrlParam,
        field: 'leadIndicators\nlagIndicators',
      },
    },
    fetchPolicy: 'network-only',
  });

  const leadLagStrategicQuestion =
    strategicQuestionsData?.strategicQuestions?.items?.[0];

  const [updateLeadAndLagIndicators] = useStrategicQuestionUpdateMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: StrategicQuestionsDocument,
        variables: {
          where: {
            strategyId: +strategyId,
            field: 'leadIndicators\nlagIndicators',
            competitiveAdvantageRowId: +competitiveAdvantageRowUrlParam,
          },
        },
      },
    ],
  });

  useEffect(() => {
    startCompetitiveAdvantageRowsPolling(polling.default);
    startStrategyPolling(polling.default);
    startStrategicQuestionsPolling(polling.default);
    startCriticalMetricGoalsPolling(polling.default);

    return () => {
      stopCompetitiveAdvantageRowsPolling();
      stopStrategyPolling();
      stopStrategicQuestionsPolling();
      stopCriticalMetricGoalsPolling();
    };
  }, [
    startCompetitiveAdvantageRowsPolling,
    startStrategyPolling,
    startStrategicQuestionsPolling,
    stopCompetitiveAdvantageRowsPolling,
    stopStrategyPolling,
    stopStrategicQuestionsPolling,
    startCriticalMetricGoalsPolling,
    stopCriticalMetricGoalsPolling,
  ]);

  return (
    <>
      <CriticalMetricsNavbar substep={SubStep.Goals} />

      <StyledStepHeader>
        <Subtitle2 style={{ marginBottom: '5px' }}>
          Consider goals based on the lead and lag indicators.
        </Subtitle2>
        <BodyNormal
          color={colors.greyDark}
          style={{ display: 'inline-block', marginBottom: 15 }}
        >
          Add suggestions. Leads choose which goals to focus on for tracking
          KPIs.
        </BodyNormal>

        <CriticalMetricsStrategicImperatives
          subStep={SubStep.Goals}
          isLead={isLead}
          sidebarOpen={sidebarOpen}
          onOpenSidebar={openSidebar}
          setStrategicImperativeId={setStrategicImperativeId}
          strategicQuestionsLoading={strategicQuestionsLoading}
          competitiveAdvantageRows={competitiveAdvantageRows}
          totalUsers={strategyData?.strategy?.users.length}
          leadLagStrategicQuestion={{
            title: leadLagStrategicQuestion?.title || '',
            type: leadLagStrategicQuestion?.type || undefined,
            lists: undefined,
            collaboration: leadLagStrategicQuestion?.collaboration || undefined,
          }}
          onSave={async (val: StrategicQuestionUpdateInput) => {
            if (!!leadLagStrategicQuestion?.id && isLead) {
              await updateLeadAndLagIndicators({
                variables: {
                  id: leadLagStrategicQuestion?.id,
                  data: {
                    ...val,
                  },
                },
              });
            }
          }}
        />
      </StyledStepHeader>

      <ErrorWrapper
        isLoading={
          strategyLoading ||
          strategicQuestionsLoading ||
          criticalMetricGoalsLoading ||
          competitiveAdvantageRowsLoading
        }
        errors={[
          strategyError,
          strategicQuestionsError,
          criticalMetricGoalsError,
          competitiveAdvantageRowsError,
        ]}
        dataMissing={false}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <PageWrapper paddingTop={false} fullWidthMobile>
            {!competitiveAdvantageRows?.length ? (
              <MissingStrategicPossibilities
                drugId={drugId}
                strategyId={strategyId}
              />
            ) : (
              <GoalsTable
                isLead={isLead}
                isContributor={isContributor}
                isDesktop={isDesktop}
                data={criticalMetricGoals}
                onCreateRow={handleCreateRow}
                onUpdateRow={handleUpdateRow}
                onDeleteRow={handleDeleteRow}
              />
            )}
          </PageWrapper>
          <LeadAndLagIndicatorsSummarySidebar
            strategicPossibility={selectedRowImperative}
            indicators={{
              lead: leadLagStrategicQuestion?.lists?.[0]?.list || undefined,
              lag: leadLagStrategicQuestion?.lists?.[1]?.list || undefined,
            }}
            isOpen={sidebarOpen && !!competitiveAdvantageRowUrlParam}
            onClose={closeSidebar}
            heightOffset={navHeight}
          />
        </div>
      </ErrorWrapper>
    </>
  );
};
