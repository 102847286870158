import { colors } from 'constants/index';

export const getBorderGradientColors = (rating: number | null | undefined) => {
  if (!rating || rating === 3) {
    return [0, colors.purple, 1, colors.pink];
  }

  if (rating < 3) {
    return [0, colors.darkRed, 1, colors.purple];
  }

  return [0, colors.greenDark, 1, colors.purple];
};
