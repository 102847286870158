import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { colors } from 'constants/colors';

const DISABLED_COLOR = '#E8E8EA';
const InvisibleInput = styled.span`
  margin: 0;
  padding: 0;
  position: absolute;
  height: 0;
  overflow: hidden;
  white-space: pre;
`;

export const Input = styled.input<{
  disabled?: boolean;
  invalid?: boolean;
  borderless?: boolean;
}>`
  border: none;
  outline: none;
  font-size: 18px;
  padding: 5px;

  font-size: 14px;
  font-weight: 400;

  border: 0.5px solid
    ${({ disabled, invalid, borderless }) => {
      if (disabled) return '#D7D7DB';
      if (invalid) return colors.red;
      if (borderless) return 'transparent';
      return colors.greyDark;
    }};

  color: ${({ disabled }) => (disabled ? colors.black50 : colors.black)};

  background: ${({ disabled, borderless }) => {
    if (disabled && borderless) return colors.white;
    return 'transparent';
  }};

  &:hover {
    border-color: ${({ disabled, invalid, borderless }) => {
      if (disabled) return DISABLED_COLOR;
      if (invalid) return colors.red;
      if (borderless) return 'transparent';
      return colors.black;
    }};
  }

  &:focus {
    outline: none;
    border-color: ${({ disabled, invalid, borderless }) => {
      if (disabled) return DISABLED_COLOR;
      if (invalid) return colors.red;
      if (borderless) return colors.blue;
      return colors.blue;
    }};
  }

  transition: background 0.3s, color 0.3s, border 0.3s;
`;

interface Props {
  value?: string;
  disabled?: boolean;
  invalid?: boolean;
  borderless?: boolean;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  placeholder?: string;
  size?: number;
  maxLength?: number;
  handleRef?: React.MutableRefObject<null>;
  expand?: boolean;
}
export type InputProps = Props &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
export const TextInput: React.FC<InputProps> = ({
  value = '',
  onChange,
  disabled = false,
  invalid = false,
  borderless = false,
  placeholder = '',
  size,
  maxLength,
  expand,
  ...rest
}) => {
  const spanRef = useRef<HTMLSpanElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    function getCorrectWidth() {
      if (spanRef.current !== null && inputRef.current) {
        spanRef.current.style.fontFamily = window
          .getComputedStyle(inputRef.current, null)
          .getPropertyValue('font-family');
        spanRef.current.style.fontWeight = window
          .getComputedStyle(inputRef.current, null)
          .getPropertyValue('font-weight');
        spanRef.current.style.fontSize = window
          .getComputedStyle(inputRef.current, null)
          .getPropertyValue('font-size');
        spanRef.current.style.lineHeight = window
          .getComputedStyle(inputRef.current, null)
          .getPropertyValue('line-height');

        resize(spanRef.current, inputRef.current);
      }
    }
    if (expand) {
      //@ts-ignore
      if (document.fonts) {
        //@ts-ignore
        document.fonts.ready.then(() => {
          getCorrectWidth();
        });
      } else {
        getCorrectWidth();
      }
    }
  }, [value, expand]);
  return (
    <div>
      <InvisibleInput ref={spanRef}></InvisibleInput>
      <Input
        {...rest}
        ref={inputRef}
        maxLength={maxLength}
        placeholder={placeholder}
        borderless={borderless}
        value={value}
        size={size}
        onChange={onChange}
        disabled={disabled}
        invalid={invalid}
      />
    </div>
  );
};

function resize(span: any, input: any) {
  span.textContent = input.value;
  input.style.width = (span.offsetWidth || 108) + 'px';
}
