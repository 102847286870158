import { PostItCardType } from 'data/graphql/generated';
import { IconName } from 'types';

export function getIconFromNoteType(level: PostItCardType): IconName {
  switch (level) {
    case 'advantage':
      return 'Arrow-up';
    case 'limitation':
      return 'Arrow-down';
    case 'trigger':
      return 'Trigger';
    case 'unexpected':
    case PostItCardType.Thoughts:
      return 'Unexpected behaviour';
    case PostItCardType.Feelings:
      return 'Heart';
    case 'adaptation':
      return 'Adaptation';
    case 'pattern':
      return 'Pattern';
    case 'leadIndicator':
      return 'Lead';
    case 'lagIndicator':
      return 'Lag';
    case PostItCardType.Hurdle:
      return 'Hurdle';
    case PostItCardType.Gap:
      return 'Gap';
    default:
      return 'Arrow-up';
  }
}
