import format from 'date-fns/format';
import getQuarter from 'date-fns/getQuarter';
import getYear from 'date-fns/getYear';
import { CriticalMetricGoalFrequency } from 'data/graphql/generated';

export const formatDateByFrequency = (
  date: Date,
  frequency: CriticalMetricGoalFrequency
) => {
  if (frequency === CriticalMetricGoalFrequency.Quarterly) {
    return `Q${getQuarter(date)} ${getYear(date)}`;
  }

  if (frequency === CriticalMetricGoalFrequency.Annually) {
    return `${getYear(date)}`;
  }

  return format(date, 'MMMM yyyy');
};
