import { ReusableLargeSidebar } from 'components/shared';
import { colors } from 'constants/index';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PJourneyWrapper = styled.div`
  height: 100%;
`;

export const Header = styled.div`
  height: 60px;
  width: 100%;
  background-color: ${colors.white};
  padding: 15px;
  display: flex;
  align-items: center;
`;

export const StyledSidebar = styled(ReusableLargeSidebar)`
  overflow: hidden;
  @media ${device.mobile} {
    left: 45px;
  }
`;
