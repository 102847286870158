import React from 'react';
import styled from 'styled-components';
import { clamp } from 'utils/clamp';

const PillContainer = styled.div<{ maxValue: number; $value: number }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: fit-content;

  height: 25px;
  padding: 0px 5px;
  border-radius: 15px;
  background: ${({ $value, maxValue }) => {
    const maxTransparancy = 0.2;
    const transparancy = (1 - $value / maxValue) * maxTransparancy;

    return `rgba(120, 0, 255, ${clamp(transparancy, 0.05, 1)})`;
  }};
`;

interface Props {
  value: number;
  maxValue?: number;
  children: React.ReactNode;
  className?: string;
}

export const ScorePillContainer = ({
  value,
  maxValue = 5,
  children,
  className,
}: Props) => {
  return (
    <PillContainer className={className} $value={value} maxValue={maxValue}>
      {children}
    </PillContainer>
  );
};
