import { TacticCategory } from 'data/graphql/generated';

export const mapTacticCategoryEnumToString = {
  [TacticCategory.MedicalLeadership]: 'Medical Leadership',
  [TacticCategory.DataAndEvidenceGeneration]: 'Data & Evidence Generation',
  [TacticCategory.DataAndEvidenceDissemination]:
    'Data & Evidence Dissemination',
  [TacticCategory.MedicalKnowledge]: 'Medical Knowledge',
  [TacticCategory.MedicalCommunityIntegration]: 'Medical Community Integration',
  [TacticCategory.PatientAdvocacy]: 'Patient Advocacy',
};
