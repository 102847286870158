import { ModalLarge } from 'components/ModalLarge';
import { BodyNormal, ButtonPill, Subtitle1 } from 'components/shared';
import { colors } from 'constants/index';
import { useAuthContext } from 'contexts/AuthContext';
import {
  DismissedModals,
  useUserSafeUpdateMutation,
} from 'data/graphql/generated';
import { useState } from 'react';
import styled from 'styled-components';

const GotItButton = styled(ButtonPill)`
  width: 180px;
`;

const BodyWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Content = styled.div`
  padding: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModeInfoModal = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [updateUser] = useUserSafeUpdateMutation();
  const [{ user }] = useAuthContext();
  const [saving, setSaving] = useState(false);

  async function handleClose() {
    if (
      !user ||
      !user?.dismissedModals ||
      user?.dismissedModals.includes(DismissedModals.PatientJourneyNavMode)
    ) {
      setVisible(false);
      return;
    }

    const newDismissedGoals = [
      ...user?.dismissedModals,
      DismissedModals.PatientJourneyNavMode,
    ];

    setSaving(true);

    await updateUser({
      variables: {
        data: {
          dismissedModals: newDismissedGoals,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: ['userAuthed'],
    });

    setVisible(false);
  }

  return (
    <ModalLarge
      handleClose={handleClose}
      visible={visible}
      size="small"
      headerColor={colors.cream}
      hideX
    >
      <Content>
        <Subtitle1>Navigate easily with your keyboard</Subtitle1>
        <BodyWrapper>
          <BodyNormal>V = Select tool</BodyNormal>
          <BodyNormal>H = Hand tool</BodyNormal>
          <BodyNormal>SPACE + drag = pan canvas</BodyNormal>
        </BodyWrapper>
        <GotItButton text="Got it" onClick={handleClose} loading={saving} />
      </Content>
    </ModalLarge>
  );
};

export default ModeInfoModal;
