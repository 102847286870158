import React, { useRef, useState, useEffect, ReactNode } from 'react';
import { useWidthHeight } from 'hooks/useWidthHeight';
import styled from 'styled-components/macro';

const FadeContainer = styled.div<{
  fadeTop: boolean;
  fadeBottom: boolean;
}>`
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;

  &::after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    z-index: 1;

    box-shadow: ${({ fadeTop, fadeBottom }) =>
      fadeTop && fadeBottom
        ? `inset 0px 39px 9px -36px #b2b2b2,
      inset 0px -39px 9px -36px #b2b2b2
      `
        : fadeTop
        ? `inset 0px 39px 9px -36px #b2b2b2`
        : fadeBottom
        ? `inset 0px -39px 9px -36px #b2b2b2`
        : 'unset'};
  }
`;

export const ScrollableContent = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 17.5px;
  overflow-y: auto;
  width: 100%;
`;

export function ScrollableFadeContainer({ children }: { children: ReactNode }) {
  const ref = useRef<HTMLUListElement | null>(null);
  const [fadeTop, setFadeTop] = useState(false);
  const [fadeBottom, setFadeBottom] = useState(false);
  const { height } = useWidthHeight();

  function handleFadeChange(listElement: HTMLUListElement | Event) {
    let el = listElement as HTMLUListElement;
    if ('target' in listElement && listElement.target) {
      el = listElement.target as HTMLUListElement;
    }
    const { offsetHeight, scrollHeight, scrollTop } = el;
    setFadeTop(scrollTop > 0);
    setFadeBottom(scrollTop < scrollHeight - offsetHeight);
  }

  useEffect(() => {
    let ul = ref.current as HTMLUListElement;
    if (!ul) return;

    const { offsetHeight, scrollHeight, scrollTop } = ul;
    setFadeBottom(scrollTop < scrollHeight - offsetHeight);

    ul?.addEventListener('scroll', handleFadeChange);

    // The height of the children might change after mount, so this listens for that
    let observerHandle: ResizeObserver | null = null;

    let observer = new ResizeObserver(function () {
      handleFadeChange(ul);
      observer.disconnect();
    });

    observer.observe(ul);
    observerHandle = observer;

    return () => {
      observerHandle?.disconnect();
      ul?.removeEventListener('scroll', handleFadeChange);
    };
  }, [height]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100vh';

    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.height = 'unset';
    };
  }, []);

  return (
    <FadeContainer fadeTop={fadeTop} fadeBottom={fadeBottom}>
      <ScrollableContent ref={ref}>{children}</ScrollableContent>
    </FadeContainer>
  );
}
