import React from 'react';
import styled from 'styled-components/macro';

import { CriticalMetricGoalUpdateInput } from 'data/graphql/generated';
import { BodySmall } from 'components/shared';
import { colors } from 'constants/colors';

import {
  GoalFrequencyDropdownOptionType,
  GoalFrequencyDropdown,
} from '../../../../shared-ui-goals/src';
import { GoalFrequencyFieldConstants } from '../../../../shared-util-goals-constants/src';
import { LockedField } from './LockedField';

interface BaseGoalFrequencyFieldProps {
  editEnabled: boolean;
  hasTracking?: boolean;
  selectedFrequency: GoalFrequencyDropdownOptionType | null;
  setSelectedFrequency: (
    value: React.SetStateAction<GoalFrequencyDropdownOptionType | null>
  ) => void;
  onUpdate: (values: CriticalMetricGoalUpdateInput) => void;
  onLockedFieldClick: () => void;
}

const DesktopGoalFrequencyField = ({
  editEnabled,
  selectedFrequency,
  setSelectedFrequency,
  onUpdate,
  hasTracking = false,
  onLockedFieldClick,
}: BaseGoalFrequencyFieldProps) => {
  if (hasTracking && selectedFrequency) {
    return (
      <LockedField
        text={selectedFrequency.value}
        onClick={onLockedFieldClick}
      />
    );
  }

  return (
    <GoalFrequencyDropdown
      editable={editEnabled}
      selectedOption={selectedFrequency}
      setSelectedOption={setSelectedFrequency}
      onUpdate={onUpdate}
    />
  );
};

const MobileLockedGoalFrequency = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;
`;

const MobileGoalFrequencyField = ({
  editEnabled,
  selectedFrequency,
  setSelectedFrequency,
  onUpdate,
  hasTracking = false,
  onLockedFieldClick,
}: BaseGoalFrequencyFieldProps) => {
  if (hasTracking && selectedFrequency) {
    return (
      <MobileLockedGoalFrequency>
        <BodySmall color={colors.greyDark}>
          {GoalFrequencyFieldConstants.FIELD_LABEL}
        </BodySmall>
        <LockedField
          text={selectedFrequency.value}
          onClick={onLockedFieldClick}
        />
      </MobileLockedGoalFrequency>
    );
  }

  return (
    <>
      <BodySmall color={colors.greyDark}>
        {GoalFrequencyFieldConstants.FIELD_LABEL}
      </BodySmall>
      <DesktopGoalFrequencyField
        editEnabled={editEnabled}
        selectedFrequency={selectedFrequency}
        setSelectedFrequency={setSelectedFrequency}
        onUpdate={onUpdate}
        onLockedFieldClick={onLockedFieldClick}
      />
    </>
  );
};

interface GoalFrequencyFieldProps extends BaseGoalFrequencyFieldProps {
  isDesktop?: boolean;
  onUpdate: (data: CriticalMetricGoalUpdateInput) => Promise<void>;
}

export const GoalFrequencyField = ({
  isDesktop = false,
  editEnabled,
  selectedFrequency,
  setSelectedFrequency,
  onUpdate,
  hasTracking = false,
  onLockedFieldClick,
}: GoalFrequencyFieldProps) => {
  const handleSetSelectFrequency = React.useCallback<
    (
      value: React.SetStateAction<GoalFrequencyDropdownOptionType | null>
    ) => void
  >(
    (e) => {
      if (!editEnabled) {
        return;
      }

      setSelectedFrequency(e);
    },
    [editEnabled, setSelectedFrequency]
  );

  const handleOnUpdate = React.useCallback(
    (e) => {
      if (!editEnabled) {
        return;
      }
      onUpdate(e);
    },
    [editEnabled, onUpdate]
  );

  if (isDesktop) {
    return (
      <DesktopGoalFrequencyField
        editEnabled={editEnabled}
        selectedFrequency={selectedFrequency}
        setSelectedFrequency={handleSetSelectFrequency}
        onUpdate={handleOnUpdate}
        hasTracking={hasTracking}
        onLockedFieldClick={onLockedFieldClick}
      />
    );
  }

  return (
    <MobileGoalFrequencyField
      editEnabled={editEnabled}
      selectedFrequency={selectedFrequency}
      setSelectedFrequency={handleSetSelectFrequency}
      onUpdate={handleOnUpdate}
      hasTracking={hasTracking}
      onLockedFieldClick={onLockedFieldClick}
    />
  );
};
