import { Button } from 'components/Button';
import { Navbar } from 'components/Navbar';
import { BodyNormal, ButtonPill, Heading3 } from 'components/shared';
import { colors } from 'constants/colors';
import useDesktop from 'hooks/useDesktop';
import useMobile from 'hooks/useMobile';
import useTablet from 'hooks/useTablet';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';
import { useUserAuthedQuery } from 'data/graphql/generated';
import { Icon } from 'components/shared/Icon';

import { AddCollaboratorButton } from 'components/CreateStrategy/StrategyCollaboratorsCreate';
interface Props {
  brandName: string;
  title: string;
  subtext?: string;
  children?: React.ReactNode;
  strategyId: number;
  nextButtonDisabled: boolean;
  handleSave: () => void;
  footerLeftButtonHandler: () => void;
  footerNextButtonHandler: () => void | Promise<any>;
  addCollaboratorButtonHandler?: () => void;
}


const DrugWrapper = styled.div`
margin-top: 30px;
background: white;
  // overflow: hidden;
  // min-height: 240px;

  // width: 928px;
  border-radius: 5px;
  // margin: 0 auto 10px;
  position: relative;
  // padding-bottom: 30px;
  // margin-bottom: 30px;
  
  @media ${device.tabletMax} {
    width: 100%;
  }

  @media ${device.mobile} {
    border: none;
    border-radius: 0;
  }
`;


const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 0;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const WelcomeText = styled.p`
  color: ${colors.greyDark};
  font-size: 22px;
  margin: 0;
`;

export const PageWrapper = styled.div`
background: url('/fill-gradient.png') ${colors.black};
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - 50px);
  padding-top: 50px;
  height: 100vh;
  width: 100vw;
  position: fixed;

  @media ${device.desktopMin} {
    padding: 50px 138px;
    overflow: scroll;
  }
`;

export const ModalStrategy = styled.div`
  height: 100%;
  padding: 30px 15px 101px;
  background: ${colors.white};
  position: relative;
  overflow: scroll;

  @media ${device.desktopMin} {
    margin: 20px auto;
    height: fit-content;
    padding: 30px 30px 101px 30px;
    border-radius: 5px;
    overflow: hidden;
  }
`;

const StepTitle = styled(Heading3)`
  line-height: 45px;
`;

const Description = styled(BodyNormal)`
  max-width: 693px;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: ${colors.white};

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid ${colors.greyLight};

  @media ${device.desktopMin} {
    padding: 30px;
    border: none;
  }
`;

const FooterButton = styled(Button)`
  padding: 0;
  cursor: pointer;

  span {
    color: ${colors.black};
  }
`;

const SaveButton = styled(FooterButton)`
  padding: 0 15px 0 0;
`;

const SaveAndNext = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
`;

const NextButton = styled(ButtonPill)`
  width: 145px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;

  @media ${device.tabletMin} {
    margin-bottom: 10px;
  }
`;


const DrugWrapperTop = styled.div`
  position: relative;
  z-index: 100;
  height: 10px;
  border-radius: 5px 5px 0px 0px;
  background: radial-gradient(
    100% 57600% at 0% 100%,
    #49129b 0%,
    #4f72c9 50%,
    #bc2eaa 100%
  );

  overflow: hidden;
  &::before {
    content: '';
    background: url(/noise.png) repeat;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 60px;
    opacity: 0.3;
    pointer-events: none;
    z-index: 2;
  }
`;

const StrategyCreationPage: React.FC<Props> = ({
  children,
  title,
  subtext,
  brandName,
  strategyId,
  nextButtonDisabled,
  handleSave,
  footerLeftButtonHandler,
  footerNextButtonHandler,
  addCollaboratorButtonHandler,
}) => {
  const { data: user } = useUserAuthedQuery({
    fetchPolicy: 'network-only',
  });
  const [loading, setLoading] = useState(false);

  const isMobile = useMobile();
  const history = useHistory();
  const isDesktop = useDesktop();
  const isTablet = useTablet();
  const isPeriodPage = history.location.pathname.includes('period');
  const isCollaboratorsPage = history.location.pathname.includes(
    'collaborators'
  );

  const footerLeftButtonText = isPeriodPage
    ? isMobile
      ? 'Discard'
      : 'Discard'
    : 'Back';

  const footerNextButtonText = isCollaboratorsPage ? 'Publish' : 'Next';

  return (
    <PageWrapper>
      <Navbar
        disableSecondary
        exclude={['drugLink', 'filesLink', 'strategyLink']}
      />


        {isDesktop && isPeriodPage && (
              <DrugWrapper>
              <DrugWrapperTop />
              <CenteredContainer>
                <ColumnContainer>
                  <WelcomeText>{user?.userAuthed?.name}, welcome to</WelcomeText>
                  <Icon name="FullLogo2" size={240} />
                  <WelcomeText>Set up your workspace for {brandName}</WelcomeText>
                </ColumnContainer>
              </CenteredContainer>
            </DrugWrapper>
        )}

        <ModalStrategy>
          {/* <Heading>Create a new strategy for {brandName}</Heading> */}
          <TitleContainer>
            <StepTitle>{title}</StepTitle>
            {isTablet && isCollaboratorsPage && (
              <AddCollaboratorButton
                onClick={() => addCollaboratorButtonHandler?.()}
              />
            )}
          </TitleContainer>
          <TitleContainer>
            <Description>{subtext}</Description>
            {isDesktop && isCollaboratorsPage && (
              <AddCollaboratorButton
                onClick={() => addCollaboratorButtonHandler?.()}
              />
            )}
          </TitleContainer>
          {children}
          {isDesktop && (
            <Footer>
              <FooterButton
                text={footerLeftButtonText}
                onClick={footerLeftButtonHandler}
              />
              <SaveAndNext>
                <SaveButton text="Save and exit" onClick={handleSave} />
                <NextButton
                  loading={loading}
                  text={footerNextButtonText}
                  disabled={nextButtonDisabled}
                  onClick={async () => {
                    setLoading(true);
                    await footerNextButtonHandler();
                    setLoading(false);
                  }}
                  className="cypress-strategy-next"
                />
              </SaveAndNext>
            </Footer>
          )}
        </ModalStrategy>

        {!isDesktop && (
          <Footer>
            <FooterButton
              text={footerLeftButtonText}
              onClick={footerLeftButtonHandler}
            />
            <SaveAndNext>
              <SaveButton text="Save and exit" onClick={handleSave} />
              <NextButton
                text={footerNextButtonText}
                disabled={nextButtonDisabled}
                onClick={footerNextButtonHandler}
              />
            </SaveAndNext>
          </Footer>
        )}
    </PageWrapper>
  );
};

export default StrategyCreationPage;
