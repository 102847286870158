import { BodySmall } from 'components/shared';
import { colors } from 'constants/colors';
import { UnidentifiedText } from './UnidentifiedText';

export const CollaborationCardField: React.FC<{
  title: string;
  text?: string | null;
}> = ({ title, text }) => {
  if (!text)
    return (
      <div>
        <BodySmall color={colors.greyDark}>{title}</BodySmall>
        <UnidentifiedText />
      </div>
    );

  return (
    <div>
      <BodySmall color={colors.greyDark}>{title}</BodySmall>
      <BodySmall>{text}</BodySmall>
    </div>
  );
};
