import ModalBrand from 'components/Admin/ModalBrand';
import { ModalForm } from 'components/ModalForm';
import { ButtonPill, Icon } from 'components/shared';
import { AddUserModal } from 'components/shared/AddUserModal';
import { AddProductModal } from 'components/shared/AddProductModal';
import FormTextInput from 'components/shared/FormTextInput';
import { colors } from 'constants/colors';
import useDesktop from 'hooks/useDesktop';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { Navbar } from '../components/Navbar';
import { Page, Wrapper } from '../components/Page';
import {
  Role,
  useAddCollaboratorMutation,
  useCompanyQuery,
  useCompanyUsersQuery,
  useDrugCreateMutation,
  useDrugUpdateMutation,
  useRemoveCollaboratorMutation,
} from '../data/graphql/generated';

const BackIcon = styled(Icon)`
  background-color: ${colors.black50a};
  border-radius: 5px;
  cursor: pointer;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const AdminPage = styled(Page)`
  ${Wrapper} {
    width: 100%;
    padding: 0 15px;
    max-width: 1800px;
  }

  @media ${device.desktopMin} {
    padding-top: 65px;
  }
`;

const CreateRenameBrandModal = styled(ModalForm)``;
const RemoveLeadModal = styled(ModalForm)``;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  width: 100%;
  margin-top: 20px;
`;
export const ModalButton = styled(ButtonPill)`
  width: 181px;
  flex: 1;
`;

export const EditIcon = styled(Icon)`
  background-color: ${colors.cream};
  border-radius: 50%;
  height: 30px;
  width: 30px;
  cursor: pointer;

  svg {
    fill: ${colors.black};
  }

  @media ${device.tabletMin} {
    margin-top: 5px;
  }
`;
export interface AddEditLead {
  userId: number;
  name: string;
  email: string;
  drugId: number | null;
  nameErrorMessage: string;
  emailErrorMessage: string;
  otherErrorMessage: string;
  modalType: 'ADD' | 'EDIT';
  emailChanged: boolean;
}
export interface RemoveLead {
  name: string;
  userId: number;
  drugId: number;
}
export interface CreateRenameBrandModalShape {
  drugId: number | null;
  name: string;
  companyId: number | null;
  modalType: 'CREATE' | 'RENAME';
  errorMessage: string;
}

const brandLeadDefaultState: AddEditLead = {
  userId: -1,
  name: '',
  email: '',
  drugId: null,
  nameErrorMessage: '',
  emailErrorMessage: '',
  otherErrorMessage: '',
  modalType: 'ADD',
  emailChanged: false,
};
const removeLeadDefaultState: RemoveLead = {
  userId: -1,
  name: '',
  drugId: -1,
};
const createRenameBrandModalDefaultState: CreateRenameBrandModalShape = {
  drugId: null,
  name: '',
  companyId: null,
  modalType: 'CREATE',
  errorMessage: '',
};

export const AdminCompany: React.FC = () => {
  const history = useHistory();
  const isDesktop = useDesktop();
  const [drugCreate] = useDrugCreateMutation();
  const [drugUpdate] = useDrugUpdateMutation();
  const [addCollaborator] = useAddCollaboratorMutation();
  const [removeCollaborator] = useRemoveCollaboratorMutation();
  const [showProduct, setShowProduct] = React.useState(false);
  const [dropdownVisible, setDropdownVisible] = React.useState(false);
  const [showUser, setShowUser] = React.useState(false);
  const { id: companyId } = useParams<{ id: string }>();
  const [removeLeadModal, setRemoveLeadModal] = React.useState<RemoveLead>(
    removeLeadDefaultState
  );

  const [brandLead, setLead] = React.useState<AddEditLead>(
    brandLeadDefaultState
  );
  const [
    createRenameBrandModal,
    setCreateRenameBrandModal,
  ] = React.useState<CreateRenameBrandModalShape>(
    createRenameBrandModalDefaultState
  );
  const closeLeadModal = () => {
    setDropdownVisible(true)
    setShowUser(false);
    setShowProduct(false);
    setLead(brandLeadDefaultState);
  };
 
  const closeRemoveLeadModal = () => setRemoveLeadModal(removeLeadDefaultState);

  const { data: company, loading, refetch } = useCompanyQuery({
    variables: { id: Number(companyId) },
  });

  const { data: companyUsers } = useCompanyUsersQuery({
    variables: { id: Number(companyId) },
  });

  const currentDrugUsers =
    company?.company?.Drug?.filter(
      (drug) => drug?.id && drug?.id === brandLead.drugId
    )
      .map((drug) => drug?.users)
      .flat() || [];

  const drugList = company?.company?.Drug;

  async function handleAddSubmit({
    name,
    email,
    drugId,
  }: {
    name: string;
    email: string;
    drugId: number;
  }) {
    if (!drugId) return;

    try {
      // userInviteCreate also creates the user
      await addCollaborator({
        variables: {
          data: {
            name,
            email: email.toLocaleLowerCase(),
            role: Role.Lead,
            drugId,
          },
        },
      });

      refetch();
    } catch (err) {
      console.error(err);

      throw err;
    }
  }

  async function handleBrandCreate() {
    if (createRenameBrandModal.name.length === 0)
      return setCreateRenameBrandModal({
        ...createRenameBrandModal,
        errorMessage: 'Name cannot be blank',
      });

    try {
      await drugCreate({
        variables: {
          data: {
            name: createRenameBrandModal.name,
            company: Number(companyId),
          },
        },
      });
      refetch();
    } catch (err) {
      console.error(err);
      alert(`Something went wrong`);
    }

    closeCreateRenameBrandModal();
  }

  async function handleBrandUpdate() {
    const { name, drugId } = createRenameBrandModal;

    if (createRenameBrandModal.name.length === 0)
      return setCreateRenameBrandModal({
        ...createRenameBrandModal,
        errorMessage: 'Name cannot be blank',
      });

    if (!drugId) return alert('Must pass a brand id');

    try {
      await drugUpdate({
        variables: {
          id: drugId,
          data: { name },
        },
      });
      refetch();
    } catch (err) {
      console.error(err);
      alert(`Something went wrong`);
    }

    closeCreateRenameBrandModal();
  }

  async function handleRemoveLead() {
    try {
      await removeCollaborator({
        variables: {
          data: {
            userId: removeLeadModal.userId,
            drugId: removeLeadModal.drugId,
          },
        },
      });

      refetch();
      console.log(
        `Successfully deleted lead with id ${removeLeadModal.userId}`
      );
      closeRemoveLeadModal();
    } catch (err) {
      console.error(err);
      alert(`Something went wrong`);
    }
  }

  const closeCreateRenameBrandModal = () =>
    setCreateRenameBrandModal(createRenameBrandModalDefaultState);

  return (
    <>
      <Navbar
        prev={{ title: '', url: '' }}
        next={{ title: '', url: '' }}
        title={company?.company?.name}
        hideArrows={true}
        exclude={['notifications', 'drugLink', 'filesLink', 'strategyLink']}
        disableSecondary={isDesktop}
      />

      <AdminPage>
        {loading || !company ? (
          <span>Loading...</span>
        ) : (
          <>
            {!!brandLead.drugId ? (
              <AddUserModal
                handleClose={closeLeadModal}
                visible={!!brandLead.drugId && showUser}
                heading={'Add Lead'}
                message="Leads can manage collaborators and make strategic decisions."
                usersForDropdown={companyUsers?.companyUsers?.items}
                handleSubmit={handleAddSubmit}
                drugId={brandLead.drugId}
                usersToExclude={currentDrugUsers.filter(
                  (user) => user?.role === 'LEAD'
                )}
                excludedUsersMessage={'Already Lead'}
              />
            ) : null}
              <AddProductModal
                handleClose={closeLeadModal}
                visible={showProduct}
                heading={'Choose Product'}
                message=""
                drugId={brandLead.drugId}
              />

            <RemoveLeadModal
              handleClose={closeRemoveLeadModal}
              visible={removeLeadModal.userId >= 0}
              heading={`Remove ${removeLeadModal.name} as a lead?`}
              message="This user will no longer be able to manage collaborators and make strategic decisions."
              className="cypress-remove-lead-modal"
            >
              <ModalButtonContainer>
                <ModalButton
                  onClick={closeRemoveLeadModal}
                  text="Cancel"
                  level="secondary"
                />
                <ModalButton
                  onClick={handleRemoveLead}
                  text="Remove lead"
                  level="danger"
                  className="cypress-remove-lead-btn"
                />
              </ModalButtonContainer>
            </RemoveLeadModal>
            <CreateRenameBrandModal
              visible={!!createRenameBrandModal.companyId}
              handleClose={closeCreateRenameBrandModal}
              heading={
                createRenameBrandModal.modalType === 'CREATE'
                  ? 'Create brand'
                  : 'Rename brand'
              }
            >
              <FormTextInput
                name="Brand name"
                title="Brand name"
                onChange={(e) =>
                  setCreateRenameBrandModal({
                    ...createRenameBrandModal,
                    name: e.target.value,
                  })
                }
                value={createRenameBrandModal.name}
                type="text"
                errorMessage={createRenameBrandModal.errorMessage}
              />
              <ModalButtonContainer>
                <ModalButton
                  onClick={closeCreateRenameBrandModal}
                  text="Cancel"
                  level="secondary"
                />
                <ModalButton
                  className="Cypress-modal-submit"
                  onClick={
                    createRenameBrandModal.modalType === 'CREATE'
                      ? handleBrandCreate
                      : handleBrandUpdate
                  }
                  text={
                    createRenameBrandModal.modalType === 'CREATE'
                      ? 'Create'
                      : 'Save'
                  }
                />
              </ModalButtonContainer>
            </CreateRenameBrandModal>

            <ButtonsContainer>
              <div onClick={() => history.push('/admin')}>
                <BackIcon
                  name="Back"
                  size={30}
                  color={colors.cream}
                  className="cypress-admin-company-back-btn"
                />
              </div>
              <ButtonPill
                className="cypress-create-brand"
                onClick={() =>
                  setCreateRenameBrandModal({
                    ...createRenameBrandModal,
                    companyId: Number(companyId),
                  })
                }
                text="Create brand"
                iconName="Plus"
              />
            </ButtonsContainer>

            {drugList &&
              drugList.map((brand) => {
                if (!brand) return null;

                return (
                  <ModalBrand
                    key={brand.id}
                    brand={brand}
                    brandLead={brandLead}
                    setLead={setLead}
                    dropdownVisible={dropdownVisible}
                    setShowProduct={setShowProduct}
                    setShowUser={setShowUser}
                    createRenameBrandModal={createRenameBrandModal}
                    setCreateRenameBrandModal={setCreateRenameBrandModal}
                    setRemoveLeadModal={setRemoveLeadModal}
                  />
                );
              })}
          </>
        )}
      </AdminPage>
    </>
  );
};
