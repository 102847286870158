import React from 'react';
import styled, { css } from 'styled-components/macro';

import { colors } from 'constants/colors';
import { Icon } from 'components/shared';

const AddCircle = styled.div`
  width: 54px;
  height: 54px;
  border-radius: 50%;
  margin-left: 142px;
  margin-bottom: 15px;
  border: 1px solid ${colors.black20};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  cursor: pointer;

  path {
    transition: all 0.3s;
    color: ${colors.black70};
  }

  &:hover {
    background: ${colors.purple05};
    border-color: ${colors.purple50};

    path {
      color: ${colors.purple};
    }
  }
`;

const Wrapper = styled.div<{ amount: number; sidebarOpen: boolean }>`
  width: 339px;
  min-width: 339px;
  max-width: 339px;
  ${({ sidebarOpen, amount }) =>
    sidebarOpen && amount > 2
      ? css`
          padding-top: 15px;
          height: 119px;
          @media (min-width: 1287px) {
            padding-top: 189px;
            height: 467px;
          }
        `
      : css`
          padding-top: 15px;
          height: 119px;
          @media (min-width: 730px) {
            padding-top: 189px;
            height: 467px;
          }
        `}
  background: ${colors.white};
  border: 1px solid ${colors.greyMedium};
  border-radius: 5px;
  display: inline-block;
  float: left;
  vertical-align: top;
  position: relative;
`;

const CreateText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.purple};
  text-align: center;
  display: block;
  cursor: pointer;
`;

interface Props {
  onClick(): void;
  amount: number;
  sidebarOpen: boolean;
}

export const BigIdeaCreate: React.FC<Props> = ({
  onClick,
  amount,
  sidebarOpen,
}) => {
  return (
    <Wrapper amount={amount} sidebarOpen={sidebarOpen}>
      <AddCircle
        onClick={onClick}
        className="cypress-bigidea-create bigidea-create"
      >
        <Icon name="Plus" color={colors.purple} size={30} />
      </AddCircle>
      <CreateText onClick={onClick}>Add a Big Idea</CreateText>
    </Wrapper>
  );
};
