import { colors, zIndex } from 'constants/index';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Icon } from '.';
import { BodySmall, Subtitle2 } from './TextStyles';
import { device } from 'utils/breakpoints';
import ReactDOM from 'react-dom';
import { Stakeholder, StakeholderDefinitionFragment, Step } from 'data/graphql/generated';
import { usePostItCardsPayload } from 'hooks/usePostItCards';
import { PostItGroupAndCards } from 'types';
import { OutcomesPostIts } from 'components/OutcomesPostIts';
import { getTitle } from 'containers/LongTermStrategyFutureTrends';

interface Props {
  open: boolean;
  hasNoTargetPopulation: boolean;
  hasNoSelectedCompetitor: boolean;
  setOpen(state: boolean): void;
  groups: PostItGroupAndCards[] | any;
  loading: boolean;
  offsetTopOverride?: number;
  readonly?: boolean;
  addCard: usePostItCardsPayload['createCard'];
  removeCard: usePostItCardsPayload['removeCard'];
  updateCard: usePostItCardsPayload['updateCard'];
  onChange(
    sourceId: string,
    sourceIndex: number,
    targetIndex: number,
    targetId?: string
  ): void;
  refetch(): void;
  selectedStakeholderDefinition?: StakeholderDefinitionFragment;
  selectedPostItGroupId: number;
  movingOrSwapping: boolean;
}

const Wrapper = styled.div<{
  open: boolean;
  offsetTop: number;
  offsetTopOverride: number | undefined;
}>`
  display: flex;
  user-select: none;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: ${({ offsetTopOverride = 0 }) =>
    `calc(100vh -  50px - ${offsetTopOverride}px)`};
  top: 50px;
  background: linear-gradient(
      0deg,
      rgba(20, 20, 39, 0.05),
      rgba(20, 20, 39, 0.05)
    ),
    #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  right: ${({ open }) => (open ? '0px' : '-100%')};
  bottom: ${({ offsetTopOverride }) => (offsetTopOverride ? 0 + 'px' : 'auto')};

  z-index: ${zIndex.observationsMenu};

  transition: all 0.3s;

  @media ${device.tabletMin} {
    width: 430px;
  }
`;

export const GradientBG = styled.div<{
  selected?: boolean;
}>`
  background: ${({ selected }) =>
    selected ? colors.purplePinkGradient : colors.black30a};
  padding: 1px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: auto;
  position: relative;
`;

const Header = styled.div`
  width: 100%;
  height: 61px;
  background: ${colors.white};
  padding: 15px;
  display: flex;

  ${BodySmall} {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 320px;
  overflow: hidden;
  }
  
  > * + * {
    margin-left: 5px;
  }
  align-items: center;
  > div:last-child {
    cursor: pointer;
    margin-left: auto;
  }
  > p > span {
    color: ${colors.black50};
  }
`;

interface URLParams {
  drugId: string;
  strategyId: string;
  stakeholder: Stakeholder;
  stakeholderDefinitionId: string;
  competitorId: string;
}

export const OutcomesMenu = ({
  open,
  setOpen,
  groups,
  loading,
  refetch,
  onChange,
  addCard,
  hasNoSelectedCompetitor,
  hasNoTargetPopulation,
  selectedPostItGroupId,
  removeCard,
  updateCard,
  offsetTopOverride,
  selectedStakeholderDefinition,
  movingOrSwapping,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const {
    // drugId,
    // strategyId,
    stakeholder,
    stakeholderDefinitionId,
  }: URLParams = useParams();

  return ReactDOM.createPortal(
    <Wrapper
      ref={ref}
      offsetTopOverride={offsetTopOverride}
      offsetTop={ref.current?.offsetTop || 0}
      open={open}
    >
      <Header>
        <Icon name="Target" color={colors.black} size={30} />
        <div>
          <Subtitle2 style={{ width: 'auto' }}>Outcomes</Subtitle2>
          <BodySmall style={{ width: 'auto' }}>{getTitle(stakeholder)}</BodySmall>
        </div>

        <div
          className="cypress-outcomes-close"
          onClick={() => {
            setOpen(false);
          }}
        >
          <Icon name="Collapse right" size={30} color={colors.purple} />
        </div>
      </Header>
    
      <OutcomesPostIts
        step={Step.Keystakeholders}
        stakeholder={stakeholder}
        hasNoSelectedCompetitor={hasNoSelectedCompetitor}
        hasNoTargetPopulation={hasNoTargetPopulation}
        groups={groups}
        loading={loading}
        addCard={addCard}
        removeCard={removeCard}
        updateCard={updateCard}
        refetch={refetch}
        handleCardChange={onChange}
        stakeholderDefinitionId={+stakeholderDefinitionId}
        selectedPostItGroupId={selectedPostItGroupId}
        movingOrSwapping={movingOrSwapping}
      />
    </Wrapper>,
    document.body
  );
};