import { colors } from 'constants/index';
import styled from 'styled-components';

export const Card = styled.div`
  background: ${colors.white94};
  border: 1px solid ${colors.greyMedium};
  border-radius: 5px;

  display: flex;
  justify-content: center;
`;
