import styled from 'styled-components/macro';

import { PostItGroupAndCards } from 'types';
import { Step, SubStep } from 'data/graphql/generated';
import { usePostItCardMove } from 'hooks/usePostItCardMove';
import { usePostItCards } from 'hooks/usePostItCards';
import { usePostItGroups } from 'hooks/usePostItGroups';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { PostIts } from 'components/PostIts';
import { ButtonPill } from 'components/shared';
import { sortPostIts } from '../../hooks';

const StyledButtonPill = styled(ButtonPill)`
  margin: 0 auto;
`;

interface MedicalObjectivesProps {
  drugId: number;
  strategyId: number;
  competitiveAdvantageRowId: number;
}

export const MedicalObjectives = ({
  drugId,
  strategyId,
  competitiveAdvantageRowId,
}: MedicalObjectivesProps) => {
  const {
    items: groupItems,
    loading: groupsLoading,
    createGroup,
    updateGroup,
    removeGroup,
    error: groupsError,
  } = usePostItGroups(
    {
      where: {
        strategyId: strategyId,
        step: Step.MedicalStrategy,
        substep: SubStep.MedicalObjectives,
        competitiveAdvantageRowId: competitiveAdvantageRowId,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.MedicalStrategy,
      substep: SubStep.MedicalObjectives,
      competitiveAdvantageRowId: competitiveAdvantageRowId,
    }
  );

  const {
    items: cardItems,
    loading: cardsLoading,
    createCard,
    updateCard,
    removeCard,
    error: cardsError,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.MedicalStrategy,
        competitiveAdvantageRowId: competitiveAdvantageRowId,
        substep: SubStep.MedicalObjectives,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.MedicalStrategy,
      competitiveAdvantageRowId: competitiveAdvantageRowId,
      substep: SubStep.MedicalObjectives,
    }
  );

  const groups: PostItGroupAndCards[] = sortPostIts(groupItems, cardItems);
  const onChange = usePostItCardMove(groups, updateCard);

  return (
    <ErrorWrapper
      isLoading={groupsLoading || cardsLoading}
      errors={[groupsError, cardsError]}
      dataMissing={!groupItems || !cardItems}
    >
      <PostIts
        step={Step.MedicalStrategy}
        subStep={SubStep.MedicalObjectives}
        groups={groups}
        addCard={createCard}
        removeCard={removeCard}
        updateCard={updateCard}
        handleCardChange={onChange}
        createGroup={createGroup}
        updateGroup={updateGroup}
        removeGroup={removeGroup}
        createGroupHide
      />
      <StyledButtonPill
        iconName="Plus"
        text="Add theme"
        level="secondary"
        onClick={() => {
          createGroup({ title: 'New Theme' });
        }}
      />
    </ErrorWrapper>
  );
};
