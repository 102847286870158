import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import * as Sentry from '@sentry/react';
import { RowTitle } from 'components/Options/LeveragePointTable';
import { Collaboration, EditableTextarea } from 'components/shared';
import { DeleteIcon } from 'components/shared/DeleteIcon';
import {
  FadedGradientContainer,
  GradientCard,
} from 'components/shared/GradientCard';
import {
  PicoSuggestionFragment,
  PicoSuggestionsDocument,
  usePicoSuggestionDeleteMutation,
  usePicoSuggestionFocusMutation,
  usePicoSuggestionUpdateMutation,
} from 'data/graphql/generated';

import { apolloDeleteHelper } from 'utils/apolloQueryHelpers';
import {
  DeleteConfirmCancel,
  DeleteConfirmConfirm,
  DeleteConfirmWrapper,
} from './EvidenceTableRow';
import { picoSuggestionsQueryVars } from './AccessStrategyPico';
import { useParams } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthContext';
import { device } from 'utils/breakpoints';
import { picoSuggestionColumns, PicoSuggestionUpdateField } from './PicoTable';
import { picoTableColumnSettings } from 'constants/index';
import FocusDropdown from 'components/shared/FocusDropdown';
import { verifyUserRole } from 'utils/verifyUserRole';
import { PicoSuggestionKeyInformation } from 'components/4-1-access-strategy/feature-pico-suggestion';
import { FocusPicoSuggestionModal } from 'components/4-1-access-strategy/feature-pico-suggestion-modals';
import { useModalWithSteps } from 'contexts';
import useDesktop from 'hooks/useDesktop';

const Wrapper = styled(GradientCard)<{ focus: boolean }>`
  background: ${({ focus }) => !focus && 'transparent'};

  ${FadedGradientContainer} {
    background: ${({ focus }) => !focus && 'transparent'};

    @media ${device.desktopMin} {
      padding: 15px;
    }
  }
`;

const FocusAndDelete = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;

const RowContentMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media ${device.desktopMin} {
    display: none;
  }
`;

const RowContentDesktop = styled.div`
  display: none;

  @media ${device.desktopMin} {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;

const RowColumnsDesktop = styled.div`
  display: grid;
  ${picoTableColumnSettings}
`;

const CollabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

const StyledDeleteConfirm = styled(DeleteConfirmWrapper)`
  position: relative;
  transform: none;
  cursor: pointer;

  @media ${device.desktopMin} {
    transform: translateX(calc(-100% + 34px));
  }
`;

const CollabAndFocusDesktop = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

interface Props {
  picoSuggestion: PicoSuggestionFragment;
  setPicoModalOpen: React.Dispatch<React.SetStateAction<number | null>>;
  refetch(): Promise<void>;
  autoFocus?: boolean;
}

interface URLParams {
  strategyId: string;
  drugId: string;
}

export default function PicoSuggestionRow({
  picoSuggestion,
  setPicoModalOpen,
  autoFocus,
}: Props) {
  const { strategyId }: URLParams = useParams();
  const [updateSuggestion] = usePicoSuggestionUpdateMutation();
  const [deleteSuggestion] = usePicoSuggestionDeleteMutation();
  const [showTrashBtn, setShowTrashBtn] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [focused, setFocused] = useState(false);
  const [{ user }] = useAuthContext();
  const { setModalSlideIdx, setShowSingleSlide } = useModalWithSteps();
  const isDesktop = useDesktop();
  const [focusPicoSuggestionMutation] = usePicoSuggestionFocusMutation();

  const { isLead } = verifyUserRole(user?.role, user?.country);

  useEffect(() => {
    setFocused(picoSuggestion.focus);
  }, [picoSuggestion]);

  async function handleDelete() {
    try {
      await deleteSuggestion({
        variables: { id: picoSuggestion.id },
        update: apolloDeleteHelper({
          responseField: 'picoSuggestionDelete',
          query: PicoSuggestionsDocument,
          queryVars: picoSuggestionsQueryVars(strategyId),
          queryName: 'picoSuggestions',
        }),
        optimisticResponse: {
          picoSuggestionDelete: {
            ...picoSuggestion,
          },
        },
      });
    } catch (error) {
      Sentry.captureException(error);
      alert('Something went wrong');
    } finally {
      setShowDeleteConfirm(false);
    }
  }

  async function toggleFocus() {
    if (!focused) {
      setModalSlideIdx(0);
      return;
    } else {
      try {
        setFocused(false);
        await focusPicoSuggestionMutation({
          variables: {
            id: picoSuggestion.id,
            data: {
              isFocused: false,
            },
          },
        });
      } catch (error) {
        setFocused(true);
        Sentry.captureException(error);
        alert('Something went wrong');
      }
    }
  }

  const openEditKeyInformationModal = () => {
    setShowSingleSlide(true);
    setModalSlideIdx(0);
  };

  return (
    <>
      <FocusPicoSuggestionModal
        strategyId={+strategyId}
        pico={{
          id: picoSuggestion.id,
          name: picoSuggestion.name ?? undefined,
          focusRationale: picoSuggestion.focusRationale,
          outcome: picoSuggestion.outcome ?? undefined,
          strategicImperativeId: picoSuggestion?.strategicImperative?.id,
        }}
      />
      <Wrapper
        focus={focused}
        onMouseEnter={() => setShowTrashBtn(true)}
        onMouseLeave={() => setShowTrashBtn(false)}
      >
        <RowContentMobile>
          <FocusAndDelete>
            <FocusDropdown
              onClick={toggleFocus}
              checked={focused}
              disabled={!isLead}
              rationaleText={picoSuggestion.focusRationale}
              setModalSlideIdx={setModalSlideIdx}
              setShowSingleSlide={setShowSingleSlide}
            />

            {showDeleteConfirm ? (
              <StyledDeleteConfirm>
                <DeleteConfirmCancel
                  onClick={() => setShowDeleteConfirm(false)}
                >
                  Cancel
                </DeleteConfirmCancel>
                <DeleteConfirmConfirm onClick={handleDelete}>
                  Delete row
                </DeleteConfirmConfirm>
              </StyledDeleteConfirm>
            ) : (
              <DeleteIcon onClick={() => setShowDeleteConfirm(true)} />
            )}
          </FocusAndDelete>

          {picoSuggestionColumns.map((field) => {
            const capitalized = capitalize(field);

            return (
              <div key={field}>
                <RowTitle text={capitalized} />
                <EditableTextarea
                  autoFocus={!!autoFocus && field === 'population'}
                  editable
                  placeholder={capitalized}
                  initialValue={
                    picoSuggestion[field as PicoSuggestionUpdateField] || ''
                  }
                  onBlur={async (newValue) => {
                    try {
                      updateSuggestion({
                        variables: {
                          id: picoSuggestion.id,
                          data: { [field]: newValue },
                        },
                      });
                    } catch (error) {}
                  }}
                  style={{ background: 'transparent' }}
                />
              </div>
            );
          })}

          <PicoSuggestionKeyInformation
            pico={{
              name: picoSuggestion?.name ?? undefined,
              strategicPossibilityName:
                picoSuggestion?.strategicImperative?.strategicPossibility?.name,
            }}
            displayIcon={isLead}
            onEdit={openEditKeyInformationModal}
            isDesktop={isDesktop}
            isFocused={focused}
          />

          <CollabContainer>
            <div />
            <Collaboration collaboration={picoSuggestion.collaboration} />
          </CollabContainer>
        </RowContentMobile>

        <RowContentDesktop data-cy="pico-desktop">
          <RowColumnsDesktop>
            {picoSuggestionColumns.map((field) => {
              const capitalized = capitalize(field);

              return (
                <EditableTextarea
                  key={field}
                  autoFocus={!!autoFocus && field === 'population'}
                  editable
                  placeholder={capitalized}
                  initialValue={
                    picoSuggestion[field as PicoSuggestionUpdateField] || ''
                  }
                  onBlur={async (newValue) => {
                    try {
                      updateSuggestion({
                        variables: {
                          id: picoSuggestion.id,
                          data: { [field]: newValue },
                        },
                      });
                    } catch (error) {
                      Sentry.captureException(error);
                      alert('Something went wrong');
                    }
                  }}
                  style={{ background: 'transparent' }}
                />
              );
            })}

            <CollabAndFocusDesktop>
              <Collaboration collaboration={picoSuggestion.collaboration} />
              <FocusAndDelete>
                <FocusDropdown
                  onClick={toggleFocus}
                  checked={focused}
                  disabled={!isLead}
                  rationaleText={picoSuggestion.focusRationale}
                  setModalSlideIdx={setModalSlideIdx}
                  setShowSingleSlide={setShowSingleSlide}
                />
                {showDeleteConfirm ? (
                  <StyledDeleteConfirm>
                    <DeleteConfirmCancel
                      onClick={() => setShowDeleteConfirm(false)}
                    >
                      Cancel
                    </DeleteConfirmCancel>
                    <DeleteConfirmConfirm onClick={handleDelete}>
                      Delete row
                    </DeleteConfirmConfirm>
                  </StyledDeleteConfirm>
                ) : showTrashBtn ? (
                  <DeleteIcon onClick={() => setShowDeleteConfirm(true)} />
                ) : null}
              </FocusAndDelete>
            </CollabAndFocusDesktop>
          </RowColumnsDesktop>

          <PicoSuggestionKeyInformation
            pico={{
              name: picoSuggestion?.name ?? undefined,
              strategicPossibilityName:
                picoSuggestion?.strategicImperative?.strategicPossibility?.name,
            }}
            displayIcon={isLead}
            onEdit={openEditKeyInformationModal}
            isDesktop={isDesktop}
            isFocused={focused}
          />
        </RowContentDesktop>
      </Wrapper>
    </>
  );
}
