import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';

export const PageAndSidebarContainer = styled.div`
  width: 100%;
  position: relative;
  overflow-x: hidden;

  @media ${device.desktopMin} {
    overflow: hidden;
    height: 100vh;
  }
`;

export const PageContainer = styled.div<{ pageHeaderHeight: number }>`
  overflow: auto;

  @media ${device.desktopMin} {
    display: flex;
    overflow: hidden;
    height: ${({ pageHeaderHeight }) => `calc(100vh - ${pageHeaderHeight}px)`};
  }
`;

export const Content = styled.div`
  position: relative;
  max-width: 486px;
  margin: auto;
  padding: 15px;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  justify-content: center;
  flex-direction: column;
  @media ${device.tabletMin} {
    padding: 15px 20px;
  }

  @media ${device.desktopMin} {
    max-width: unset;
    padding: 20px;
    width: 100%;
    margin: 0 auto;

    height: 100%;
    overflow: hidden;
    flex-direction: row;
  }
`;
