import { CriticalMetricGoalFrequency } from 'data/graphql/generated';
import { useEffect, useState } from 'react';
import { GoalTrackingConstants } from '../constants-goal-tracking';
import { getCurrentPeriodByFrequencyAndOffset } from '../util-goal-tracking';

export const useGoalTrackingPeriod = (
  goalfrequency: CriticalMetricGoalFrequency
) => {
  const [frequency] = useState(goalfrequency);
  const [offset, setOffset] = useState(
    GoalTrackingConstants.DEFAULT_OFFSET_VALUE
  );
  const [offsetPeriod, setOffsetPeriod] = useState(new Date());
  const [period, setPeriod] = useState('');

  useEffect(() => {
    const calculated = getCurrentPeriodByFrequencyAndOffset(frequency, offset);

    setOffsetPeriod(calculated.date);
    setPeriod(calculated.formatted);
  }, [frequency, offset]);

  const isLatestPeriod = offset === GoalTrackingConstants.LATEST_OFFSET_VALUE;

  const next = () => {
    /** prevent user from accessing future dates */
    if (isLatestPeriod) {
      return;
    }

    const newOffset = offset + GoalTrackingConstants.OFFSET_CHANGE_VALUE;
    setOffset(newOffset);
  };

  const previous = () => {
    const newOffset = offset - GoalTrackingConstants.OFFSET_CHANGE_VALUE;
    setOffset(newOffset);
  };

  return {
    next,
    period,
    offset,
    date: offsetPeriod,
    previous,
    isLatestPeriod,
  };
};
