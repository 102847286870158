import { BlurredBGImage, BodySmall, Subtitle2 } from 'components/shared';
import { colors } from 'constants/index';
import { ImperativeWithSuccessConditionType } from 'containers/CompetitiveAdvantage';
import {
  PostItCardFragment,
  useCompetitiveAdvantageRowUpdateMutation,
} from 'data/graphql/generated';
import { useWidth } from 'hooks/useWidth';
import { cloneDeep } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
} from 'react';
import { Flipper, Flipped } from 'react-flip-toolkit';

import styled from 'styled-components';
import { device } from 'utils/breakpoints';
import { Card } from './Card';
import { DraggableSummaryColumn } from './DraggableSummaryColumn';
import { SummaryColumn } from './SummaryColumn';
import { DraggableSummaryColumnDragLayer } from './DraggableSummaryColumnDragLayer';
import { summaryBottomScrollBar } from 'components/Strategy/scrollbar';

const MainWrapper = styled.main`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const StyledColumnsSection = styled.section<{
  offsetTop: number;
  canScroll: boolean;
}>`
  display: flex;
  gap: 15px;

  width: 100%;
  overflow: auto;
  overflow-y: hidden;
  height: ${({ offsetTop }) => `calc(100vh - ${offsetTop + 5}px)`};
  position: relative;

  padding-left: 20px;
  padding-right: ${({ canScroll }) => !canScroll && '20px'};

  @media ${device.tabletMax} {
    padding-left: 15px;
    padding-right: ${({ canScroll }) => !canScroll && '15px'};
  }

  ${summaryBottomScrollBar}

  .StyledColumnsSection__Flipper {
    display: flex;
    gap: 15px;
    width: 100%;
    position: relative;

    :after {
      content: '';
      display: ${({ canScroll }) => (canScroll ? 'block' : 'none')};
      flex: none;
      width: 5px;
      height: 1px;

      @media ${device.tabletMax} {
        right: -1px;
        width: 1px;
      }
    }
  }
`;

const Divider = styled.div`
  background: ${colors.greyMedium};
  width: 1px;
  height: 100%;
  min-height: 70px;
  margin: 0 10px;
`;

const AlignmentImage = styled.div`
  margin-left: 10px;
  height: 100%;
  max-width: 91px;
  position: absolute;
  right: -91px;
  display: flex;

  @media ${device.tabletMax} {
    position: static;
    min-height: 100%;
    height: auto;
  }
  @media ${device.mobile} {
    display: none;
  }
`;

const WinningAspirationCardWrapper = styled(Card)<{ hasImage: boolean }>`
  min-height: ${({ hasImage }) => hasImage && '100px'};
  padding: 15px;
  margin: 0 20px;

  @media ${device.tabletMax} {
    margin: 0 15px;
  }
`;

const WinningAspirationWrapper = styled.div`
  display: flex;

  height: fit-content;
  width: 640px;
  align-items: center;
  justify-content: center;
  position: relative;

  .WinningAspirationContent {
    width: 640px;
    max-width: 640px;
  }
  @media ${device.tabletMax} {
    max-width: 100%;

    .WinningAspirationContent {
      min-width: auto;
      position: relative;
      left: 0;
    }
  }
`;

interface Props {
  columnData?: ImperativeWithSuccessConditionType;
  updateCompetitiveAdvantageRow: ReturnType<
    typeof useCompetitiveAdvantageRowUpdateMutation
  >[0];
  mostAlignedImage?: Omit<PostItCardFragment, 'image'> & {
    image: string;
  };
  winningAspiration: string;
  isLead: boolean;
}

export const Summary = ({
  columnData,
  updateCompetitiveAdvantageRow,
  mostAlignedImage,
  winningAspiration,
  isLead,
}: Props) => {
  const [data, setData] = useState(columnData);
  const [, setDragging] = useState(false);
  const [cardToUpdate, setCardToUpdate] = useState<null | {
    id: number;
    idx: number;
  }>(null);
  const [canScroll, setCanScroll] = useState(false);

  useEffect(() => {
    setData(columnData);
  }, [columnData]);

  const animId = useRef<number>(0);
  const mouseUp = useRef<boolean>(true);
  const scrollContainer = useRef<HTMLDivElement>(null);
  const scrollContainerRect = useRef<DOMRect | null>(null);

  useEffect(() => {
    if (!!scrollContainer.current) {
      scrollContainerRect.current = scrollContainer.current.getBoundingClientRect();
    }
  }, [data]);

  const mouseMoveHandler = (e: MouseEvent | TouchEvent) => {
    if (animId.current) {
      window.cancelAnimationFrame(animId.current);
    }
    function scrollPage() {
      let canDrag = false;

      setDragging((dragging) => {
        canDrag = dragging;
        return dragging;
      });

      if (!scrollContainer.current || !canDrag) return;

      let dx: number;
      if ('clientX' in e) {
        dx = e?.clientX;
      } else {
        dx = e?.targetTouches?.[0]?.pageX;
      }

      const containerPosition = scrollContainerRect.current;

      if (!containerPosition) return;

      const scrollLeft =
        dx - containerPosition.left > 0 && dx - containerPosition.left < 200;
      const scrollRight =
        dx < containerPosition.left + containerPosition.width &&
        dx > containerPosition.left + containerPosition.width - 200;

      switch (true) {
        case scrollRight:
          scrollContainer.current.scrollLeft += 10;
          break;
        case scrollLeft:
          scrollContainer.current.scrollLeft -= 10;
          break;
      }

      if (!mouseUp.current) {
        animId.current = window.requestAnimationFrame(scrollPage);
      }
    }

    animId.current = window.requestAnimationFrame(scrollPage);
  };

  const scrollPageOnDragHandler = function (
    e:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.TouchEvent<HTMLDivElement>
  ) {
    const app = document.querySelector('.app') as HTMLElement;
    if (app) {
      mouseUp.current = false;
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('touchmove', mouseMoveHandler);

      document.addEventListener(
        'mouseup',
        () => {
          mouseUp.current = true;

          window.cancelAnimationFrame(animId.current);
          document.removeEventListener('mousemove', mouseMoveHandler);
          document.removeEventListener('touchmove', mouseMoveHandler);
        },
        { once: true }
      );
    }
  };

  const moveColumnHandler = useCallback(
    async (id: number, dragIndex: number, hoverIndex: number) => {
      if (!!data) {
        const currentItem = cloneDeep(data[dragIndex]);

        const copy = data.slice();
        copy.splice(dragIndex, 1);
        copy.splice(hoverIndex, 0, currentItem);

        let newIdx = dragIndex;

        //if dragged to start
        if (hoverIndex === 0) {
          newIdx = Math.ceil(
            (copy[hoverIndex + 1].competitiveAdvantageRow?.idx || 0) + 5000
          );
        }
        //if dragged to end
        else if (hoverIndex + 1 === copy.length) {
          newIdx = Math.floor(
            (copy[hoverIndex - 1].competitiveAdvantageRow?.idx || 0) / 2
          );
        } else {
          const previousItemIdx =
            copy[hoverIndex - 1].competitiveAdvantageRow?.idx || 0;

          const nextItemIdx =
            copy[hoverIndex + 1].competitiveAdvantageRow?.idx || 0;

          newIdx = Math.ceil((previousItemIdx + nextItemIdx) / 2);
        }

        if (
          !!currentItem?.competitiveAdvantageRow &&
          'idx' in currentItem?.competitiveAdvantageRow
        ) {
          let item = currentItem.competitiveAdvantageRow;

          if (item?.idx) {
            currentItem.competitiveAdvantageRow.idx = newIdx;
          }
        }

        setData(copy);

        setCardToUpdate({ id, idx: newIdx });
      }
    },
    [data]
  );

  const flipKey = data?.map((v) => v.competitiveAdvantageRow?.id).join('');
  const windowWidth = useWidth();

  const [sectionOffsetTop, setSectionOffsetTop] = useState(0);

  useEffect(() => {
    if (scrollContainer?.current) {
      setSectionOffsetTop(scrollContainer?.current?.offsetTop);
    }
  }, [windowWidth]);

  useLayoutEffect(() => {
    setCanScroll(
      scrollContainer.current?.scrollWidth !==
        scrollContainer.current?.offsetWidth
    );
  }, [data, windowWidth]);

  return (
    <>
      <DraggableSummaryColumnDragLayer
        setDragging={setDragging}
        onDrop={async () => {
          if (cardToUpdate) {
            await updateCompetitiveAdvantageRow({
              variables: {
                id: cardToUpdate.id,
                data: {
                  idx: cardToUpdate?.idx,
                },
              },
            });
          }
        }}
      />

      <MainWrapper>
        <section>
          <WinningAspirationCardWrapper
            hasImage={!!winningAspiration && !!mostAlignedImage?.image}
          >
            <WinningAspirationWrapper>
              <div
                style={{
                  display: 'flex',
                  position: 'relative',
                  width: '100%',
                  maxWidth: 640,
                }}
              >
                <div className="WinningAspirationContent">
                  {winningAspiration ? (
                    <>
                      <BodySmall color={colors.greyDark}>
                        Winning aspiration
                      </BodySmall>
                      <Subtitle2>{winningAspiration}</Subtitle2>
                    </>
                  ) : (
                    <Subtitle2
                      style={{ color: colors.greyDark, textAlign: 'center' }}
                    >
                      Winning aspiration not defined yet
                    </Subtitle2>
                  )}
                </div>

                {!!mostAlignedImage?.image && !!winningAspiration && (
                  <AlignmentImage>
                    <Divider />
                    <div
                      style={{
                        borderRadius: 5,
                        overflow: 'hidden',
                        width: 70,
                        height: 70,
                      }}
                    >
                      <BlurredBGImage
                        imageURL={mostAlignedImage?.image || ''}
                        blurAmount={2}
                      />
                    </div>
                  </AlignmentImage>
                )}
              </div>
            </WinningAspirationWrapper>
          </WinningAspirationCardWrapper>
        </section>

        <StyledColumnsSection
          ref={scrollContainer}
          offsetTop={sectionOffsetTop}
          canScroll={canScroll}
        >
          {!!data && !!data.length ? (
            <Flipper
              className="StyledColumnsSection__Flipper"
              flipKey={flipKey}
            >
              {data.map((a, idx) => (
                <Flipped flipId={a.id} key={a.id}>
                  <div
                    style={{ flex: 1, height: '100%' }}
                    onTouchStart={(e) => {
                      mouseUp.current = false;

                      scrollPageOnDragHandler(e);
                    }}
                    onMouseDown={(e) => {
                      mouseUp.current = false;

                      scrollPageOnDragHandler(e);
                    }}
                    onMouseUp={() => {
                      mouseUp.current = true;
                    }}
                  >
                    {isLead ? (
                      <DraggableSummaryColumn
                        data={a}
                        arrayIdx={idx}
                        moveColumn={moveColumnHandler}
                        arr={data}
                      />
                    ) : (
                      <SummaryColumn data={a} />
                    )}
                  </div>
                </Flipped>
              ))}
            </Flipper>
          ) : (
            <Card
              style={{
                flex: 1,
                height: 55,
                display: 'grid',
                placeItems: 'center',
              }}
            >
              <Subtitle2 color={colors.greyDark}>
                No Strategic Imperatives yet
              </Subtitle2>
            </Card>
          )}
        </StyledColumnsSection>
      </MainWrapper>
    </>
  );
};
