import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  Sort,
  SubStep,
  useCompetitiveAdvantageRowsQuery,
} from 'data/graphql/generated';
import { CriticalMetricsParams } from '../../types/CriticalMetricsParams';
import URLHelper from 'utils/URLHelper';

export const useCriticalMetricsCompetitiveAdvantage = (subStep: SubStep) => {
  const history = useHistory();
  const {
    drugId,
    strategyId,
    competitiveAdvantageRowUrlParam,
  } = useParams<CriticalMetricsParams>();

  const {
    data: competitiveAdvantageRowsData,
    error: competitiveAdvantageRowsError,
    loading: competitiveAdvantageRowsLoading,
    startPolling: startCompetitiveAdvantageRowsPolling,
    stopPolling: stopCompetitiveAdvantageRowsPolling,
  } = useCompetitiveAdvantageRowsQuery({
    variables: {
      orderBy: {
        idx: Sort.Desc,
      },
      where: {
        strategyId: +strategyId,
        focus: true,
      },
      include: {
        strategicPossibility: true,
        successConditions: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  const competitiveAdvantageRows =
    competitiveAdvantageRowsData?.competitiveAdvantageRows?.items;

  const selectedCompetitiveAdvantageRow = competitiveAdvantageRows?.find(
    (row) => row.id === +competitiveAdvantageRowUrlParam
  );

  const selectedRowImperative =
    selectedCompetitiveAdvantageRow?.strategicPossibility;

  useEffect(() => {
    if (competitiveAdvantageRows?.[0]?.id && !selectedCompetitiveAdvantageRow) {
      const url = URLHelper.getCriticalMetricsUrlBySubStep(subStep, {
        drugId,
        strategyId,
        competitiveAdvantageRowId: competitiveAdvantageRows?.[0]?.id,
      });
      history.replace(url);
    }
  }, [
    selectedCompetitiveAdvantageRow,
    competitiveAdvantageRows,
    drugId,
    history,
    strategyId,
    competitiveAdvantageRowUrlParam,
    subStep,
  ]);

  return {
    competitiveAdvantageRows,
    competitiveAdvantageRowsError,
    competitiveAdvantageRowsLoading,
    selectedRowImperative,
    startCompetitiveAdvantageRowsPolling,
    stopCompetitiveAdvantageRowsPolling,
  };
};
