import ErrorPage from 'components/ErrorPage';
import { ModalConfirmDelete } from 'components/ModalConfirmDelete';
import { Radio, BodySmall } from 'components/shared';
import { currencies } from 'constants/index';
import { colors } from 'constants/colors';
import {
  DrugsDocument,
  Stakeholder,
  useDrugQuery,
  useStakeholderDefinitionCreateMutation,
  useStrategyDeleteMutation,
  useStrategyQuery,
  useStrategyUpdateMutation,
} from 'data/graphql/generated';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';
import StrategyCreationPage, {
  ModalStrategy,
} from '../../containers/StrategyCreationPage';
import FormTextInput from 'components/shared/FormTextInput';

const StrategyPeriodPage = styled.div`
background: url('/strat-gradient.png') ${colors.black};
min-height: calc(100vh - 50px);
  @media ${device.desktopMin} {
    ${ModalStrategy} {
      // height: 470px;
    }
  }
`;


const TimeLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 450px;
  margin-top: 20px;
`;

const StratCurrency = styled.div`
  margin-top: 30px;
`;

const StratCurrencies = styled.div`

  @media (min-width: 555px) {
    display: flex;
    flex-direction: row;
  }

  @media ${device.mobile} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
  }
`;

const CurrencyOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 35px;
  cursor: pointer;
  margin: 0px 0px 15px 0px;
  @media (min-width: 555px) {
    margin: 0px 35px 0px 0px;
  }
`;

const StrategyPeriodCreate = () => {
  const [targetPopulation, setTargetPopulation] = React.useState<string>('');
  const [currency, setCurrency] = React.useState<string>('');
  const history = useHistory();
  const [strategyUpdate] = useStrategyUpdateMutation();
  const { drugId, strategyId } = useParams<{
    drugId: string;
    strategyId: string;
  }>();
  const [strategyDelete] = useStrategyDeleteMutation();
  const [
    discardStrategyModal,
    setDiscardStrategyModal,
  ] = React.useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'targetPopulation') {
      setTargetPopulation(e.target.value);
    }
  };

  const openDiscardStrategyModal = () => setDiscardStrategyModal(true);

  const [
    createStakeholderDefinition,
  ] = useStakeholderDefinitionCreateMutation();

  async function handleSave() {
    try {
      await strategyUpdate({
        variables: {
          id: +strategyId,
          data: {
            startYear: null,
            endYear: null,
            currency,
          },
        },
        refetchQueries: [{ query: DrugsDocument }],
      });

      history.push('/');
    } catch (err) {
      console.error(err);
    }
  }

  const { data: strategyData, error: strategyError, loading: loadingStrategy } = useStrategyQuery({
    variables: {
      id: Number(strategyId),
    },
  });

  const { data: drugData, loading, error: drugError } = useDrugQuery({
    variables: { id: Number(drugId) },
  });

  async function discardStrategy() {
    try {
      await strategyDelete({
        variables: { id: +strategyId },
        refetchQueries: [{ query: DrugsDocument }],
      });

      console.info(`Strategy with id ${strategyId} successfully deleted`);
      history.push('/');
    } catch (err) {
      console.error(err);
    }
  }

  async function saveAndContinue() {
    try {
      const response = await createStakeholderDefinition({
        variables: {
            data: {
            stakeholder: Stakeholder.Patient,
            image: '',
            title: targetPopulation,
            strategy: Number(strategyId),
            drug: Number(drugId),
            },
        },
    });
      await strategyUpdate({
        variables: {
          id: +strategyId,
          data: {
            startYear: null,
            stakeholderDefinitionId: response.data?.stakeholderDefinitionCreate.id,
            endYear: null,
            currency,
          },
        },
        refetchQueries: [{ query: DrugsDocument }],
      });

      history.push(`/d/${drugId}/strategy/${strategyId}/create/collaborators`);
    } catch (err) {
      console.error(err);
    }
  }

  React.useEffect(() => {
    if (strategyData?.strategy?.isDraft === false) history.push('/');
  }, [strategyData, history]);

  const stepValid =
    targetPopulation !== '' &&
    currency !== '';

  if (loading || loadingStrategy) return <span>Loading...</span>;
  if (strategyError || drugError) return <ErrorPage errorType="generic" />;
  if (!strategyData || !drugData) return <ErrorPage errorType="unpermitted" />;

  return (
    <StrategyPeriodPage>
      <ModalConfirmDelete
        title="Discard strategy?"
        visible={discardStrategyModal}
        text="This strategy and details you’ve provided for it will be lost."
        leftButtonText="Cancel"
        rightButtonText="Discard"
        handleClose={() => setDiscardStrategyModal(false)}
        handleConfirm={discardStrategy}
      />

      <StrategyCreationPage
        title="Target Population and Currency"
        brandName={drugData?.drug?.name || ''}
        strategyId={Number(strategyId)}
        nextButtonDisabled={!stepValid}
        handleSave={handleSave}
        footerLeftButtonHandler={openDiscardStrategyModal}
        footerNextButtonHandler={saveAndContinue}
      >
        <TimeLine>
          <FormTextInput
            type='text'
            title="Target population"
            name="targetPopulation"
            value={targetPopulation}
            onChange={(e) => handleChange(e)}
          />
        </TimeLine>

        <StratCurrency>
          <BodySmall style={{ color: colors.black, marginBottom: 10 }}>
            Global currency
          </BodySmall>

          <StratCurrencies>
            {currencies.map((c) => (
              <CurrencyOption
                className="cypress-strategy-currency"
                onClick={() => setCurrency(c)}
                key={c}
              >
                <Radio checked={c === currency} />
                <BodySmall style={{ marginLeft: 6 }}>{c}</BodySmall>
              </CurrencyOption>
            ))}
          </StratCurrencies>
        </StratCurrency>
      </StrategyCreationPage>
    </StrategyPeriodPage>
  );
};

export default StrategyPeriodCreate;
