import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';

import { BodySmall, Icon } from 'components/shared';
import { TooltipCSS } from 'components/shared/Tooltip';
import { Collaboration } from 'components/shared';
import { FutureTrendBrandModal } from './FutureTrendBrandModal';
import { useAuthContext } from 'contexts/AuthContext';
import {
  Stakeholder,
  FutureTrendFragment,
  useFutureTrendUpdateMutation,
} from 'data/graphql/generated';
import { colors } from 'constants/colors';
import { ImpactYear } from './ImpactYear';
import FocusDropdown from 'components/shared/FocusDropdown';
import { verifyUserRole } from 'utils/verifyUserRole';

const Wrapper = styled.div<{ isFocused: boolean }>`
  position: relative;
  border-radius: 4px;
  padding: 1px;
  background: ${colors.greyMedium};
  margin-bottom: 15px;
  &:last-of-type {
    margin-bottom: 0px;
  }

  > .FutureTrend__wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 3px;

    &::before {
      content: '';
      border-radius: 3px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${colors.black02OnWhite};
      opacity: 1;
      pointer-events: none;
      z-index: 1;
    }
  }

  ${({ isFocused }) => {
    if (isFocused) {
      return css`
        background: linear-gradient(315deg, #ff00c8 -83%, #7800ff 93.75%);
        > .FutureTrend__wrapper {
          background: white;
          &::before {
            background: ${colors.fadedGradient};
          }
        }
      `;
    }
  }}
`;

const Content = styled.div`
  padding: 65px 15px 55px 15px;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

const Section = styled.section`
  margin-bottom: 15px;
`;

const Title = styled(BodySmall)`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${colors.greyDark};
`;

const FocusWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const Focus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 92px;
  height: 40px;
  padding: 7px 5px;
  border-radius: 5px;

  span {
    margin-left: 5px;
  }
`;

const StyledCollaboration = styled(Collaboration)`
  position: absolute;
  bottom: 15px;
  right: 15px;
`;

interface Props {
  trend: FutureTrendFragment;
  stakeholder: Stakeholder;
}

export const FutureTrend: React.FC<Props> = ({ trend, stakeholder }) => {
  const [futureTrendUpdate] = useFutureTrendUpdateMutation();
  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);

  const [modalSlideIdx, setModalSlideIdx] = useState<number | null>(null);
  const [showSingleSlide, setShowSingleSlide] = useState<boolean>(false);

  async function updateYear(year: number) {
    try {
      await futureTrendUpdate({
        variables: {
          id: trend.id,
          data: {
            year,
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  }
  async function onFocusClick() {
    if (!trend.focus) {
      setShowSingleSlide(false);
      setModalSlideIdx(0);
    } else {
      try {
        await futureTrendUpdate({
          variables: {
            id: trend.id,
            data: {
              focus: false,
            },
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  return (
    <>
      <FutureTrendBrandModal
        trend={trend}
        modalSlideIdx={modalSlideIdx}
        setModalSlideIdx={setModalSlideIdx}
        showSingleSlide={showSingleSlide}
      />

      <Wrapper isFocused={trend.focus}>
        <div
          className="FutureTrend__wrapper"
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <Content>
            <FocusWrapper>
              <TooltipCSS
                text="Only Leads can edit"
                disabled={isLead}
                direction="top"
                tooltipStyle={{
                  top: 40,
                  left: -50,
                }}
              >
                <Focus>
                  <FocusDropdown
                    onClick={onFocusClick}
                    checked={trend.focus}
                    disabled={!isLead}
                    rationaleText={trend.focusRationale}
                    setModalSlideIdx={setModalSlideIdx}
                    setShowSingleSlide={setShowSingleSlide}
                    smallerDropdown
                  />
                </Focus>
              </TooltipCSS>
            </FocusWrapper>
            <Section>
              <Title>Emerging trend / Change</Title>
              <BodySmall>{trend.title}</BodySmall>
            </Section>
            <Section>
              <Title>Year of impact</Title>
              <ImpactYear
                amount={trend.year}
                updateYear={(v) => updateYear(v)}
                stakeholder={stakeholder}
              />
            </Section>
            {trend.focus ? (
              <Section
                onClick={() => {
                  if (!isLead) return;
                  setModalSlideIdx(0);
                }}
                style={{ cursor: isLead ? 'pointer' : 'auto' }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Title>Opportunity / Risk</Title>
                  {isLead ? (
                    <Icon name="Pencil" size={30} color={colors.greyDark} />
                  ) : null}
                </div>
                <BodySmall>{trend.risk}</BodySmall>
              </Section>
            ) : null}
            <StyledCollaboration collaboration={trend.collaboration} />
          </Content>
        </div>
      </Wrapper>
    </>
  );
};
