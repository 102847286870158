import {
  DataList,
  DropOverlay,
  ListContainer,
  SidebarEmptyState,
} from 'components/GridPages/GridPageSidebar';
import {
  BodyNormal,
  BodySmall,
  CategoryTab,
  EmptyState,
  Subtitle1,
  Subtitle2,
} from 'components/shared';
import { GridPageSidebar } from 'components/GridPages/GridPageSidebar';
import { colors } from 'constants/colors';
import { capitalize } from 'lodash';
import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import {
  gridPageSidebarSidePaddingMobile,
  gridPageSidebarSidePaddingDesktop,
} from 'constants/index';
import {
  BigIdeaDetailFragment,
  FutureTrendFragment,
  LongTermSolutionFragment,
  SolutionFragment,
} from 'data/graphql/generated';
import { monthNames } from 'utils/monthNames';
import { DragNDropItems } from 'types';
import { useDragLayer } from 'react-dnd';
import { SolutionGroup } from './SolutionGroup';
import { Wrapper as CategoryTabWrapper } from 'components/shared/CategoryTab';
import { Link } from 'react-router-dom';

function formatDate(dateStr: string): string {
  if (!dateStr) return '';

  const date = new Date(+dateStr);
  const year = date.getFullYear();
  const month = monthNames[date.getMonth()];

  return `${month} ${year}`;
}

const columnSettings = css`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 65px;
  padding: 0 15px;

  @media ${device.tabletMin} {
    grid-gap: none;
  }
`;

const SidebarHeader = styled.div`
  margin-bottom: 20px;
`;

const Tabs = styled.div`
  display: flex;
  gap: 5px;

  ${CategoryTabWrapper} {
    padding: 0;
  }
`;

const GreyDivider = styled.div`
  background: ${colors.greyMedium};
  height: 1px;
  margin: 0 -${gridPageSidebarSidePaddingMobile}px 20px -${gridPageSidebarSidePaddingMobile}px;

  @media ${device.desktopMin} {
    margin-left: -${gridPageSidebarSidePaddingDesktop}px;
    margin-right: -${gridPageSidebarSidePaddingDesktop}px;
  }
`;

const PurpleDivider = styled.div`
  background: ${colors.purple};
  height: 1px;
`;

const ExistingSolutions = styled.div``;

const TableHeaders = styled.div`
  ${columnSettings}
  margin-bottom: 10px;
`;

const ExistingSolution = styled.li`
  ${columnSettings}
  padding: 15px;
  border-bottom: 1px solid ${colors.greyLight};
`;
const ExistingSolutionTextField = styled(BodySmall)`
  display: inline;
`;

const StyledSidebarEmptyState = styled(SidebarEmptyState)`
  top: 180px;
`;

const StyledEmptyState = styled(EmptyState)`
  text-align: center;
  border-radius: 5px;
`;

interface Props {
  existingSolutions: (BigIdeaDetailFragment | SolutionFragment)[];
  futureTrends: FutureTrendFragment[];
  unplacedSolutions: LongTermSolutionFragment[];
  sidebarOpen: boolean;
  offsetTop: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTouchPositioningView: React.Dispatch<React.SetStateAction<string | null>>;
  drugId: string;
  strategyId: string;
}

enum TabOptions {
  ADD = 'ADD',
  EXISTING = 'EXISTING',
}

export const LongTermStrategySidebar: React.FC<Props> = ({
  existingSolutions,
  futureTrends,
  unplacedSolutions,
  sidebarOpen,
  offsetTop,
  setSidebarOpen,
  setTouchPositioningView,
  drugId,
  strategyId,
}) => {
  const [selectedTab, setSelectedTab] = useState<TabOptions>(TabOptions.ADD);
  const noFutureTrends = !futureTrends.length;
  const noExistingSolutions = !existingSolutions.length;
  const tabsRef = useRef<HTMLDivElement | null>(null);
  const tabsTop = tabsRef.current?.offsetTop;

  const { isDragging, itemType } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    itemType: monitor.getItemType(),
  }));

  return (
    <GridPageSidebar
      sidebarOpen={sidebarOpen}
      offsetTop={offsetTop}
      setSidebarOpen={setSidebarOpen}
    >
      <SidebarHeader>
        <BodyNormal>
          Think about product and beyond product solutions that could address
          the emerging opportunities or risks.
        </BodyNormal>
        <BodySmall color={colors.greyDark} style={{ fontWeight: 500 }}>
          Remind yourself of the existing solutions so you don't duplicate them
        </BodySmall>
      </SidebarHeader>

      <Tabs ref={tabsRef}>
        {[TabOptions.ADD, TabOptions.EXISTING].map((tabOption) => {
          return (
            <CategoryTab
              onClick={() => setSelectedTab(tabOption)}
              text={capitalize(tabOption) + ' solutions'}
              active={selectedTab === tabOption}
              fivePxRadius={true}
              key={tabOption}
              spreadEvenly={true}
            />
          );
        })}
      </Tabs>
      <GreyDivider />

      {selectedTab === TabOptions.EXISTING && (
        <ExistingSolutions>
          <TableHeaders>
            <BodySmall color={colors.greyDark}>Solution</BodySmall>
            <BodySmall color={colors.greyDark}>Due</BodySmall>
          </TableHeaders>

          {noExistingSolutions ? (
            <StyledEmptyState includeIcon={false}>
              <Subtitle2 color={colors.greyDark}>
                No existing solutions
              </Subtitle2>
              <BodySmall color={colors.greyDark}>
                Risk Mitigation plans and Big Ideas will appear here
              </BodySmall>
            </StyledEmptyState>
          ) : (
            <>
              <PurpleDivider />

              {existingSolutions.map((solution) => {
                return solution.__typename === 'BigIdeaDetail' ? (
                  <ExistingSolution key={solution.__typename + solution.id}>
                    <div>
                      <ExistingSolutionTextField color={colors.greyDark}>
                        Big idea:{' '}
                      </ExistingSolutionTextField>
                      <ExistingSolutionTextField>
                        {solution.text}
                      </ExistingSolutionTextField>
                    </div>
                    <BodySmall>
                      {solution.due ? formatDate(solution.due) : '–'}
                    </BodySmall>
                  </ExistingSolution>
                ) : solution.__typename === 'Solution' ? (
                  <ExistingSolution key={solution.__typename + solution.id}>
                    <div>
                      <ExistingSolutionTextField color={colors.greyDark}>
                        Risk Mitigation:{' '}
                      </ExistingSolutionTextField>
                      <ExistingSolutionTextField>
                        {solution.solutionText}
                      </ExistingSolutionTextField>
                    </div>
                    <BodySmall>
                      {monthNames[solution.dueDate[0]]} {solution.dueDate[1]}
                    </BodySmall>
                  </ExistingSolution>
                ) : null;
              })}
            </>
          )}
        </ExistingSolutions>
      )}

      {selectedTab === TabOptions.ADD &&
        (noFutureTrends ? (
          <StyledSidebarEmptyState title="No Opportunities / Risks">
            <div>
              <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
                Ideas can only be added when a Lead chooses Future Trends of
                focus in{' '}
              </BodySmall>
              <Link
                to={`/d/${drugId}/strategy/${strategyId}/1_4`}
                style={{
                  color: colors.greyDark,
                  display: 'inline',
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                Part 1.
              </Link>
            </div>
          </StyledSidebarEmptyState>
        ) : (
          <>
            {isDragging && itemType === DragNDropItems.PIN && (
              <DropOverlay top={tabsTop || 0}>
                <Subtitle1>Drop to remove</Subtitle1>
              </DropOverlay>
            )}
            <DataList>
              {futureTrends?.map((ft) => {
                const solutions = unplacedSolutions.filter(
                  (sol) => sol.futureTrendId === ft.id
                );

                return (
                  <ListContainer key={ft.id}>
                    <BodySmall
                      style={{ display: 'inline' }}
                      color={colors.greyDark}
                    >
                      Opportunity / Risk:{' '}
                    </BodySmall>
                    <BodySmall style={{ display: 'inline' }}>
                      {ft.risk}
                    </BodySmall>

                    <SolutionGroup
                      data={solutions}
                      isDragging={isDragging}
                      setTouchPositioningView={setTouchPositioningView}
                      futureTrendId={ft.id}
                    />
                  </ListContainer>
                );
              })}
            </DataList>
          </>
        ))}
    </GridPageSidebar>
  );
};
