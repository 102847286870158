import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { Page, Wrapper } from 'components/Page';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { device } from 'utils/breakpoints';
import { LongTermStrategyNav } from 'components/LongTermStrategy/LongTermStrategyNav';
import {
  BigIdeaFragment,
  CommercialTacticFragment,
  LongTermSolutionDetailFragment,
  Sort,
  SubStep,
  useLongTermSolutionDetailsQuery,
  useLongTermSolutionDetailUpdateMutation,
  useStrategyQuery,
} from 'data/graphql/generated';
import { StepHeader } from 'components/shared/SidebarPageComponents';
import { Subtitle2, BodyNormal, BodySmall } from 'components/shared';
import { colors, polling } from 'constants/index';
import { SolutionRow } from 'components/LongTermStrategy/SolutionRow';
import { useWidth } from 'hooks/useWidth';
import { PlanTable } from 'components/PlanTable';
import { Link } from 'react-router-dom';

const PageWrapper = styled(Page)`
  padding: 0px 0 60px;
  overflow: visible; // for the dropdown components

  margin-top: 15px;

  ${Wrapper} {
    width: 100%;
  }

  @media ${device.tabletMin} {
    margin-top: 15px;

    ${Wrapper} {
      width: auto;
      padding: 0 15px;
    }
  }

  @media ${device.desktopMin} {
    margin-top: 20px;

    ${Wrapper} {
      padding: 0 20px;
    }
  }
`;

const StyledStepHeaderBar = styled(StepHeader)`
  margin-top: 160px;
  padding-bottom: 15px;
  @media ${device.desktopMin} {
    margin-top: 110px;
  }
`;

interface URLParams {
  drugId: string;
  strategyId: string;
}

const headerColumns = [
  'Solution',
  'Actions required',
  'Timing',
  'Due',
  'Responsibility',
  'Budget',
];

export const LongTermStrategyThePlan = () => {
  const { drugId, strategyId }: URLParams = useParams();
  const width = useWidth();
  const smallTable = width < 1014;

  const {
    data: strategyData,
    loading: strategyLoading,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
    error: strategyError,
  } = useStrategyQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'network-only',
  });

  const {
    data: longTermSolutionDetailsData,
    error: longTermSolutionDetailsError,
    loading: longTermSolutionDetailsLoading,
    startPolling: startTacticsPolling,
    stopPolling: stopTacticsPolling,
  } = useLongTermSolutionDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        strategyId: +strategyId,
      },
      orderBy: {
        createdAt: Sort.Desc,
      },
    },
  });

  const solutions = useMemo(() => {
    return (
      longTermSolutionDetailsData?.longTermSolutionDetails?.items.reduce(
        (acc, curr) => {
          if (!curr?.longTermSolution.futureTrend) {
            return acc;
          }
          if (curr?.longTermSolution.futureTrend.year < 6) {
            acc['0-5'].push(curr);
          } else {
            acc['6-10'].push(curr);
          }
          return acc;
        },
        {
          '0-5': [],
          '6-10': [],
        } as Record<'0-5' | '6-10', LongTermSolutionDetailFragment[]>
      ) || {
        '0-5': [],
        '6-10': [],
      }
    );
  }, [longTermSolutionDetailsData?.longTermSolutionDetails?.items]);

  const [
    updateLongTermSolutionDetails,
  ] = useLongTermSolutionDetailUpdateMutation();

  function handleRowUpdate(
    id: number,
    vals: Partial<CommercialTacticFragment | BigIdeaFragment>
  ) {
    const mappedVals = {
      ...vals,
      actionsRequired: vals.audience,
      audience: undefined,
    };
    updateLongTermSolutionDetails({
      variables: { id, data: { ...mappedVals } },
    });
  }

  useEffect(() => {
    startStrategyPolling(polling.default);
    startTacticsPolling(polling.default);

    return () => {
      stopStrategyPolling();
      stopTacticsPolling();
    };
  }, [
    startStrategyPolling,
    startTacticsPolling,
    stopStrategyPolling,
    stopTacticsPolling,
  ]);

  return (
    <>
      <LongTermStrategyNav substep={SubStep.ThePlan} />
      <StyledStepHeaderBar>
        <Subtitle2 style={{ marginBottom: '5px' }}>
          Decide on the details behind each solution
        </Subtitle2>
        <BodyNormal color={colors.greyDark} style={{ display: 'inline-block' }}>
          Discuss the details for each solution. A Lead should make the final
          decisions.
        </BodyNormal>
      </StyledStepHeaderBar>
      <ErrorWrapper
        isLoading={strategyLoading || longTermSolutionDetailsLoading}
        errors={[strategyError, longTermSolutionDetailsError]}
        dataMissing={!(strategyData || longTermSolutionDetailsData)}
      >
        <PageWrapper paddingTop={false}>
          <PlanTable
            isDesktop={!smallTable}
            headerColumns={headerColumns}
            headerTitle={'0 - 5 years'}
            emptyTitle="No solutions yet"
            rows={(solution) => (
              <SolutionRow
                collaboration={solution.collaboration}
                isDesktop={!smallTable}
                currency={strategyData?.strategy?.currency[0] || '¥'}
                text={solution.longTermSolution.text}
                audience={solution.actionsRequired}
                from={solution.from || undefined}
                to={solution.to || undefined}
                due={solution.due || undefined}
                responsibility={solution.responsibility}
                budget={solution.budget}
                updateRow={(vals) => {
                  handleRowUpdate(solution.id, vals);
                }}
                audiencePlaceholder="Actions required"
              />
            )}
            data={solutions['0-5']}
            EmptySubHeading={
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <BodySmall
                  style={{
                    display: 'inline',
                    color: colors.greyDark,
                  }}
                >
                  A Lead should choose solutions to focus on in
                </BodySmall>
                <Link
                  to={`/d/${drugId}/strategy/${strategyId}/4_5/solutions`}
                  style={{
                    color: colors.greyDark,
                    display: 'inline',
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  {' '}
                  Part 2{' '}
                </Link>
              </div>
            }
          />
          <PlanTable
            isDesktop={!smallTable}
            headerColumns={headerColumns}
            headerTitle={'6 - 10 years'}
            emptyTitle="No solutions yet"
            rows={(solution) => (
              <SolutionRow
                collaboration={solution.collaboration}
                isDesktop={!smallTable}
                currency={strategyData?.strategy?.currency[0] || '¥'}
                text={solution.longTermSolution.text}
                audience={solution.actionsRequired}
                from={solution.from || undefined}
                to={solution.to || undefined}
                due={solution.due || undefined}
                responsibility={solution.responsibility}
                budget={solution.budget}
                updateRow={(vals) => {
                  handleRowUpdate(solution.id, vals);
                }}
                audiencePlaceholder="Actions required"
              />
            )}
            data={solutions?.['6-10']}
            EmptySubHeading={
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <BodySmall
                  style={{
                    display: 'inline',
                    color: colors.greyDark,
                  }}
                >
                  A Lead should choose solutions to focus on in
                </BodySmall>
                <Link
                  to={`/d/${drugId}/strategy/${strategyId}/4_5/solutions`}
                  style={{
                    color: colors.greyDark,
                    display: 'inline',
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  {' '}
                  Part 2{' '}
                </Link>
              </div>
            }
          />
        </PageWrapper>
      </ErrorWrapper>
    </>
  );
};
