import React from 'react';
import styled, { css } from 'styled-components/macro';
import { IconName } from 'types';
import { ReactComponent as IconAction } from 'assets/icons/Action.svg';
import { ReactComponent as IconAlert } from 'assets/icons/Alert.svg';
import { ReactComponent as IconAdaptation } from 'assets/icons/Adaptation.svg';
import { ReactComponent as IconAddPatient } from 'assets/icons/Add patient.svg';
import { ReactComponent as IconArrowDown } from 'assets/icons/Arrow-down.svg';
import { ReactComponent as IconArrowRight } from 'assets/icons/Arrow-right.svg';
import { ReactComponent as IconArrowLeft } from 'assets/icons/Arrow-left.svg';
import { ReactComponent as IconArrowUp } from 'assets/icons/Arrow-up.svg';
import { ReactComponent as IconAttachment } from 'assets/icons/Attachment.svg';
import { ReactComponent as IconBack } from 'assets/icons/Back.svg';
import { ReactComponent as IconCamera } from 'assets/icons/Camera.svg';
import { ReactComponent as IconChart } from 'assets/icons/Chart.svg';
import { ReactComponent as IconCheckcircleOn } from 'assets/icons/Checkcircle-on.svg';
import { ReactComponent as IconCheckCircleOnWhite } from 'assets/icons/Checkcircle-on-white.svg';
import { ReactComponent as IconChevronDown } from 'assets/icons/Chevron-down.svg';
import { ReactComponent as IconChevronUp } from 'assets/icons/Chevron-up.svg';
import { ReactComponent as IconChevronRightFull } from 'assets/icons/Chevron-right-full.svg';
import { ReactComponent as IconChevronDownFull } from 'assets/icons/Chevron-down-full.svg';
import { ReactComponent as IconClose } from 'assets/icons/Close.svg';
import { ReactComponent as IconCollapseRight } from 'assets/icons/Collapse right.svg';
import { ReactComponent as IconComment } from 'assets/icons/Comment.svg';
import { ReactComponent as IconComplete } from 'assets/icons/Complete.svg';
import { ReactComponent as IconDollar } from 'assets/icons/Dollar.svg';
import { ReactComponent as IconEllipse } from 'assets/icons/Ellipse.svg';
import { ReactComponent as IconExport } from 'assets/icons/Export.svg';
import { ReactComponent as IconEdit } from 'assets/icons/Edit.svg';
import { ReactComponent as IconFiles } from 'assets/icons/Files.svg';
import { ReactComponent as IconFrown } from 'assets/icons/Frown.svg';
import { ReactComponent as IconGap } from 'assets/icons/Gap.svg';
import { ReactComponent as IconGrip } from 'assets/icons/GripVertical.svg';
import { ReactComponent as IconHash } from 'assets/icons/Hash.svg';
import { ReactComponent as IconHCP } from 'assets/icons/HCP.svg';
import { ReactComponent as IconHeart } from 'assets/icons/Heart.svg';
import { ReactComponent as IconHelp } from 'assets/icons/Help.svg';
import { ReactComponent as IconHome } from 'assets/icons/Home.svg';
import { ReactComponent as IconImage } from 'assets/icons/Image.svg';
import { ReactComponent as IconIssue } from 'assets/icons/Issue.svg';
import { ReactComponent as IconStepIncomplete } from 'assets/icons/Step Incomplete.svg';
import { ReactComponent as IconStepComplete } from 'assets/icons/Step Complete.svg';
import { ReactComponent as IconJobs } from 'assets/icons/Jobs.svg';
import { ReactComponent as IconLag } from 'assets/icons/Lag.svg';
import { ReactComponent as IconLead } from 'assets/icons/Lead.svg';
import { ReactComponent as IconLightning } from 'assets/icons/Lightning.svg';
import { ReactComponent as IconLoadingBar } from 'assets/icons/LoadingBar.svg';
import { ReactComponent as IconLoadingBarGradient } from 'assets/icons/LoadingBarGradient.svg';
import { ReactComponent as IconMenu } from 'assets/icons/Menu.svg';
import { ReactComponent as IconMinus } from 'assets/icons/Minus.svg';
import { ReactComponent as IconMoreText } from 'assets/icons/MoreText.svg';
import { ReactComponent as IconMoreTextActive } from 'assets/icons/MoreTextActive.svg';
import { ReactComponent as IconMoreDetail } from 'assets/icons/MoreDetail.svg';
import { ReactComponent as IconMoreDetailActive } from 'assets/icons/MoreDetailActive.svg';
import { ReactComponent as IconNotificationsOff } from 'assets/icons/Notifications OFF.svg';
import { ReactComponent as IconNotificationsOn } from 'assets/icons/Notifications ON.svg';
import { ReactComponent as IconNotification } from 'assets/icons/Notification.svg';
import { ReactComponent as IconObservation } from 'assets/icons/Observation.svg';
import { ReactComponent as IconOverlayChevron } from 'assets/icons/OverlayChevron.svg';
import { ReactComponent as IconPatient } from 'assets/icons/Patient.svg';
import { ReactComponent as IconPeople } from 'assets/icons/People.svg';
import { ReactComponent as IconPercent } from 'assets/icons/Percent.svg';
import IconPin from 'assets/icons/Pin.svg';
import IconBluePin from 'assets/icons/BluePin.svg';
import { ReactComponent as IconPinSolid } from 'assets/icons/PinSolid.svg';
import { ReactComponent as IconPlus } from 'assets/icons/Plus.svg';
import { ReactComponent as IconSad } from 'assets/icons/Sad.svg';
import { ReactComponent as IconStar } from 'assets/icons/Star.svg';
import { ReactComponent as IconStarPurple } from 'assets/icons/StarPurple.svg';
import { ReactComponent as IconStarOutline } from 'assets/icons/Star Outline.svg';
import { ReactComponent as IconStarOutlinePurple } from 'assets/icons/StarOutlinePurple.svg';
import { ReactComponent as IconStrategy } from 'assets/icons/Strategy.svg';
import { ReactComponent as IconGradientStrategy } from 'assets/icons/GradientStrategy.svg';
import { ReactComponent as IconText } from 'assets/icons/Text.svg';
import { ReactComponent as IconThumbsUpActive } from 'assets/icons/Thumbs up Active.svg';
import { ReactComponent as IconThumbsUp } from 'assets/icons/Thumbs up.svg';
import { ReactComponent as IconTick } from 'assets/icons/Tick.svg';
import { ReactComponent as IconTickSmall } from 'assets/icons/TickSmall.svg';
import { ReactComponent as IconTouchpoint } from 'assets/icons/Touchpoint.svg';
import { ReactComponent as IconTrash } from 'assets/icons/Trash.svg';
import { ReactComponent as IconTreatment } from 'assets/icons/Treatment.svg';
import { ReactComponent as IconTrigger } from 'assets/icons/Trigger.svg';
import { ReactComponent as IconUpload } from 'assets/icons/Upload.svg';
import { ReactComponent as IconWaveSegment } from 'assets/icons/Wave segment.svg';
import { ReactComponent as IconKeySparks } from 'assets/icons/Key-sparks.svg';
import GradientKeySparks from 'assets/icons/Gradient-Key-sparks.png';
import GradientFileEmptyState from 'assets/icons/Gradient-file-empty-state.png';
import { ReactComponent as IconPattern } from 'assets/icons/Pattern.svg';
import { ReactComponent as IconUnexpectedBehaviour } from 'assets/icons/Unexpected behaviour.svg';
import { ReactComponent as IconProduct } from 'assets/icons/Product.svg';
import { ReactComponent as IconProvider } from 'assets/icons/Provider.svg';
import { ReactComponent as IconPolicymaker } from 'assets/icons/Policymaker.svg';
import { ReactComponent as IconLeadGlobe } from 'assets/icons/LeadGlobe.svg';
import { ReactComponent as IconGlobal } from 'assets/icons/Global.svg';
import { ReactComponent as FullLogo } from 'assets/icons/nmblr-logo.svg';
import { ReactComponent as FullLogo2 } from 'assets/icons/nmblr-logo2.svg';
import { ReactComponent as FullLogoWhite } from 'assets/icons/nmblr-logo-white.svg';
import CheckCircleOff from 'assets/icons/Checkcircle-off.png';
import { ReactComponent as IconSend } from 'assets/icons/Send.svg';
import { ReactComponent as IconPencil } from 'assets/icons/Pencil.svg';
import { ReactComponent as IconTrashOutline } from 'assets/icons/TrashOutline.svg';
import { ReactComponent as IconClock } from 'assets/icons/Clock.svg';
import { ReactComponent as IconMore } from 'assets/icons/More.svg';
import { ReactComponent as GradientTick } from 'assets/icons/Gradient Tick.svg';
import GradientBlueTick from 'assets/icons/GradientBlueTick.svg';
import { ReactComponent as IconStarSelected } from 'assets/icons/StarSelected.svg';
import { ReactComponent as IconStarUnselected } from 'assets/icons/StarUnselected.svg';
import { ReactComponent as IconDollarSelected } from 'assets/icons/DollarSelected.svg';
import { ReactComponent as IconDollarUnselected } from 'assets/icons/DollarUnselected.svg';
import { ReactComponent as IconXCross } from 'assets/icons/X-Cross.svg';
import { ReactComponent as IconMedicalLeadership } from 'assets/icons/Medical leadership.svg';
import { ReactComponent as IconDataGeneration } from 'assets/icons/Data generation.svg';
import { ReactComponent as IconDataDissemination } from 'assets/icons/Data dissemination.svg';
import { ReactComponent as IconMedicalCommunityIntegration } from 'assets/icons/Medical community integration.svg';
import { ReactComponent as IconMedicalKnowledge } from 'assets/icons/Medical knowledge.svg';
import { ReactComponent as IconPatientAdvocacy } from 'assets/icons/Patient advocacy.svg';
import { ReactComponent as IconCalendar } from 'assets/icons/Calendar.svg';
import { ReactComponent as IconCalendarPlus } from 'assets/icons/CalendarPlus.svg';
import { ReactComponent as IconTarget } from 'assets/icons/Target.svg';
import { ReactComponent as Atom } from 'assets/icons/Atom.svg';

// editor
import { ReactComponent as IconUndo } from 'assets/icons/editor/undo.svg';
import { ReactComponent as IconRedo } from 'assets/icons/editor/redo.svg';
import { ReactComponent as IconUnderline } from 'assets/icons/editor/underline.svg';
import { ReactComponent as IconEditorText } from 'assets/icons/editor/text.svg';
import { ReactComponent as IconOrderedList } from 'assets/icons/editor/ordered-list.svg';
import { ReactComponent as IconList } from 'assets/icons/editor/list.svg';
import { ReactComponent as IconLink } from 'assets/icons/editor/link.svg';
import { ReactComponent as IconLeft } from 'assets/icons/editor/left.svg';
import { ReactComponent as IconItalic } from 'assets/icons/editor/italic.svg';
import { ReactComponent as IconEditorImage } from 'assets/icons/editor/image.svg';
import { ReactComponent as IconHeadingOne } from 'assets/icons/editor/heading-one.svg';
import { ReactComponent as IconHeadingTwo } from 'assets/icons/editor/heading-two.svg';
import { ReactComponent as IconCenter } from 'assets/icons/editor/center.svg';
import { ReactComponent as IconBold } from 'assets/icons/editor/bold.svg';
import { ReactComponent as IconInfo } from 'assets/icons/Info.svg';
import { ReactComponent as IconHighPriority } from 'assets/icons/High priority.svg';
import { ReactComponent as IconCopy } from 'assets/icons/Copy.svg';
import { ReactComponent as IconDownload } from 'assets/icons/Download.svg';
import AlignEmptyState from 'assets/icons/Align-Empty-State.svg';
import CommentEmptyState from 'assets/icons/Comment-Empty-State.svg';
import FileEmptyState from 'assets/icons/File-Empty-State.svg';
import GenericEmptyState from 'assets/icons/Generic-Empty-State.svg';
import NotificationEmptyState from 'assets/icons/Notification-Empty-State.svg';
import { ReactComponent as HelpCircle } from 'assets/icons/Help circle.svg';
import { ReactComponent as Stage } from 'assets/icons/Stage.svg';
import { ReactComponent as IconSpinOff } from 'assets/icons/SpinOff.svg';
import { ReactComponent as IconLock } from 'assets/icons/Lock.svg';

function getIcon(name: IconName) {
  switch (name) {
    case 'Action':
      return <IconAction />;
    case 'Alert':
      return <IconAlert />;
    case 'Adaptation':
      return <IconAdaptation />;
    case 'Unexpected behaviour':
      return <IconUnexpectedBehaviour />;
    case 'Add patient':
      return <IconAddPatient />;
    case 'Arrow-down':
      return <IconArrowDown />;
    case 'Arrow-right':
      return <IconArrowRight />;
    case 'Arrow-left': 
      return <IconArrowLeft />;
    case 'Atom':
      return <Atom />;
    case 'Arrow-up':
      return <IconArrowUp />;
    case 'Attachment':
      return <IconAttachment />;
    case 'Back':
      return <IconBack />;
    case 'Camera':
      return <IconCamera />;
    case 'Chart':
      return <IconChart />;
    case 'Checkcircle-on':
      return <IconCheckcircleOn />;
    case 'Checkcircle-on-white':
      return <IconCheckCircleOnWhite />;
    case 'Checkcircle-off':
      return (
        <svg viewBox="0 0 30 30">
          <image href={CheckCircleOff} />
        </svg>
      );
    case 'Chevron-down':
      return <IconChevronDown />;
    case 'Chevron-up':
      return <IconChevronUp />;
    case 'Chevron-down-full':
      return <IconChevronDownFull />;
    case 'Chevron-right-full':
      return <IconChevronRightFull />;
    case 'Close':
      return <IconClose />;
    case 'Collapse right':
      return <IconCollapseRight />;
    case 'Comment':
      return <IconComment />;
    case 'Complete':
      return <IconComplete />;
    case 'Dollar':
      return <IconDollar />;
    case 'Download':
      return <IconDownload />;
    case 'Ellipse':
      return <IconEllipse />;
    case 'Export':
      return <IconExport />;
    case 'Edit':
      return <IconEdit />;
    case 'Files':
      return <IconFiles />;
    case 'Frown':
      return <IconFrown />;
    case 'GradientTick':
      return <GradientTick />;

    /* There can be a clash when multiple instances of an svg gradient are being used on the same page,
     * which can cause some or all instances of that gradient to not render correctly
     * or just not display at all. So this using the svg or png as an image is the solution to that
     */
    case 'GradientBlueTick':
      return (
        <svg viewBox="0 0 30 30">
          <image href={GradientBlueTick} />
        </svg>
      );
    case 'Gap':
      return <IconGap />;
      case 'Grip':
        return <IconGrip />;
    case 'Hash':
      return <IconHash />;
    case 'HCP':
      return <IconHCP />;
    case 'Heart':
      return <IconHeart />;
    case 'Help':
      return <IconHelp />;
    case 'Home':
      return <IconHome />;
    case 'Image':
      return <IconImage />;
    case 'Hurdle':
      return <IconIssue />;
    case 'Step Complete':
      return <IconStepComplete />;
    case 'Step Incomplete':
      return <IconStepIncomplete />;
    case 'Jobs':
      return <IconJobs />;
    case 'Lag':
      return <IconLag />;
    case 'Lead':
      return <IconLead />;
    case 'LoadingBar':
      return <IconLoadingBar />;
    case 'LoadingBarGradient':
      return <IconLoadingBarGradient />;
    case 'Lightning':
      return <IconLightning />;
    case 'Menu':
      return <IconMenu />;
    case 'Minus':
      return <IconMinus />;
    case 'MoreText':
      return <IconMoreText />;
    case 'MoreTextActive':
      return <IconMoreTextActive />;
    case 'MoreDetail':
      return <IconMoreDetail />;
    case 'MoreDetailActive':
      return <IconMoreDetailActive />;
    case 'Notifications OFF':
      return <IconNotificationsOff />;
    case 'Notifications ON':
      return <IconNotificationsOn />;
    case 'Notification':
      return <IconNotification />;
    case 'Observation':
      return <IconObservation />;
    case 'OverlayChevron':
      return <IconOverlayChevron />;
    case 'Patient':
      return <IconPatient />;
    case 'Pattern':
      return <IconPattern />;
    case 'Percent':
      return <IconPercent />;
    case 'People':
      return <IconPeople />;
    case 'PinSolid':
      return <IconPinSolid />;
    case 'Product':
      return <IconProduct />;
    case 'Provider':
      return <IconProvider />;
    case 'Policymaker':
      return <IconPolicymaker />;
    case 'Plus':
      return <IconPlus />;
    case 'Sad':
      return <IconSad />;
    case 'SpinOff':
      return <IconSpinOff />;
    case 'Star':
      return <IconStar />;
    case 'StarPurple':
      return <IconStarPurple />;
    case 'StarOutline':
      return <IconStarOutline />;
    case 'StarOutlinePurple':
      return <IconStarOutlinePurple />;
    case 'Strategy':
      return <IconStrategy />;
    case 'GradientStrategy':
      return <IconGradientStrategy />;
    case 'Target':
      return <IconTarget />;
    case 'Text':
      return <IconText />;
    case 'Thumbs up Active':
      return <IconThumbsUpActive />;
    case 'Thumbs up':
      return <IconThumbsUp />;
    case 'Tick':
      return <IconTick />;
    case 'Tick Small':
      return <IconTickSmall />;
    case 'Touchpoint':
      return <IconTouchpoint />;
    case 'Trash':
      return <IconTrash />;
    case 'Treatment':
      return <IconTreatment />;
    case 'Trigger':
      return <IconTrigger />;
    case 'Upload':
      return <IconUpload />;
    case 'Wave segment':
      return <IconWaveSegment />;
    case 'Key-sparks':
      return <IconKeySparks />;
    case 'Gradient-Key-sparks':
      return (
        <svg viewBox="0 0 115 115">
          <image href={GradientKeySparks} />
        </svg>
      );

    case 'Global':
      return <IconGlobal />;
    case 'LeadGlobe':
      return <IconLeadGlobe />;
    case 'FullLogo':
      return <FullLogo />;
    case 'FullLogo2':
      return <FullLogo2 />;
    case 'FullLogoWhite':
      return <FullLogoWhite />;
    case 'Send':
      return <IconSend />;
    case 'TrashOutline':
      return <IconTrashOutline />;
    case 'Pencil':
      return <IconPencil />;
    case 'Clock':
      return <IconClock />;
    case 'More':
      return <IconMore />;
    case 'StarSelected':
      return <IconStarSelected />;
    case 'StarUnselected':
      return <IconStarUnselected />;
    case 'DollarSelected':
      return <IconDollarSelected />;
    case 'DollarUnselected':
      return <IconDollarUnselected />;
    case 'X-Cross':
      return <IconXCross />;
    case 'MedicalLeadership':
      return <IconMedicalLeadership />;
    case 'DataGeneration':
      return <IconDataGeneration />;
    case 'DataDissemination':
      return <IconDataDissemination />;
    case 'MedicalCommunityIntegration':
      return <IconMedicalCommunityIntegration />;
    case 'MedicalKnowledge':
      return <IconMedicalKnowledge />;
    case 'PatientAdvocacy':
      return <IconPatientAdvocacy />;
    case 'Calendar':
      return <IconCalendar />;
    case 'CalendarPlus':
      return <IconCalendarPlus />;
    // editor icons
    case 'Undo':
      return <IconUndo />;
    case 'Redo':
      return <IconRedo />;
    case 'Underline':
      return <IconUnderline />;
    case 'EditorText':
      return <IconEditorText />;
    case 'OrderedList':
      return <IconOrderedList />;
    case 'List':
      return <IconList />;
    case 'Lock':
      return <IconLock />;
    case 'Link':
      return <IconLink />;
    case 'Left':
      return <IconLeft />;
    case 'Italic':
      return <IconItalic />;
    case 'EditorImage':
      return <IconEditorImage />;
    case 'HeadingOne':
      return <IconHeadingOne />;
    case 'HeadingTwo':
      return <IconHeadingTwo />;
    case 'Center':
      return <IconCenter />;
    case 'Bold':
      return <IconBold />;
    case 'Info':
      return <IconInfo />;
    case 'HighPriority':
      return <IconHighPriority />;
    case 'Copy':
      return <IconCopy />;
    case 'HelpCircle':
      return <HelpCircle />;
    case 'Stage':
      return <Stage />;

    case 'CommentEmptyState':
      return (
        <svg viewBox="0 0 363 363">
          <image href={CommentEmptyState} />
        </svg>
      );

    case 'AlignEmptyState':
      return (
        <svg viewBox="0 0 363 363">
          <image href={AlignEmptyState} />
        </svg>
      );

    case 'NotificationEmptyState':
      return (
        <svg viewBox="0 0 363 363">
          <image href={NotificationEmptyState} />
        </svg>
      );

    case 'GenericEmptyState':
      return (
        <svg viewBox="0 0 363 363">
          <image href={GenericEmptyState} />
        </svg>
      );

    case 'FileEmptyState':
      return (
        <svg viewBox="0 0 363 363">
          <image href={FileEmptyState} />
        </svg>
      );
    case 'GradientFileEmptyState':
      return (
        <svg viewBox="0 0 115 115">
          <image href={GradientFileEmptyState} />
        </svg>
      );

    case 'Pin':
      // We use this method instead of the usual icon svg as ReactComponent to overcome a bug where the pin's gradient edge
      // was not rendering on safari when a pin had been dragged
      return (
        <svg viewBox="0 0 30 30" fill="currentColor">
          <path
            d="M0.75 0.75H15C22.8701 0.75 29.25 7.12994 29.25 15C29.25 22.8701 22.8701 29.25 15 29.25C7.12994 29.25 0.75 22.8701 0.75 15V0.75Z"
            fill="currentColor"
          />

          <image href={IconPin} />
        </svg>
      );

    case 'BluePin':
      return (
        <svg viewBox="0 0 30 30" fill="currentColor">
          <path
            d="M0.75 0.75H15C22.8701 0.75 29.25 7.12994 29.25 15C29.25 22.8701 22.8701 29.25 15 29.25C7.12994 29.25 0.75 22.8701 0.75 15V0.75Z"
            fill="white"
          />
          <path
            d="M0.75 0.75H15C22.8701 0.75 29.25 7.12994 29.25 15C29.25 22.8701 22.8701 29.25 15 29.25C7.12994 29.25 0.75 22.8701 0.75 15V0.75Z"
            fill="currentColor"
          />

          <image href={IconBluePin} />
        </svg>
      );

    default:
      return null;
  }
}

interface Props {
  name: IconName;
  size: number;
  color?: string;
  fillColor?: string;
  className?: string;
  height?: number;
  width?: number;
  style?: React.CSSProperties;
  onClick?(e: React.MouseEvent<HTMLDivElement>): void;
}

export const IconContainer = styled.div<{
  size: number;
  color: string;
  fillColor: string;
  height?: number;
  width?: number;
}>`
  height: ${({ height }) =>
    typeof height === 'number' ? height + 'px' : 'auto'};
  width: ${({ size, width }) => (width ? `${width}px` : `${size}px`)};
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: ${({ size }) => `${size}px`};

    ${({ fillColor }) => {
    if (fillColor) {
      return css`
          fill: ${fillColor};
        `;
    }
  }}
  }

  transition: color 0.3s ease;
`;

export const Icon = ({
  name,
  size,
  height,
  width,
  color = 'transparent',
  fillColor = 'transparent',
  className,
  style,
  onClick,
}: Props) => {
  return (
    <IconContainer
      className={className}
      size={size}
      height={height}
      width={width}
      color={color}
      fillColor={fillColor}
      style={style}
      onClick={onClick}
    >
      {getIcon(name)}
    </IconContainer>
  );
};
