import { ErrorModal } from 'components/ErrorModal';

interface UpdateRowErrorModalProps {
  isVisible: boolean;
  onClose(): void;
}

export const UpdateRowErrorModal = ({
  isVisible,
  onClose,
}: UpdateRowErrorModalProps) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <ErrorModal
      title={'Cannot be blank'}
      text={`This field is part of a contribution with "Focus".
    "Focus" must be removed before any fields can be cleared.`}
      visible={isVisible}
      handleClose={handleClose}
    />
  );
};
