import { orderBy } from 'lodash';
import { CommercialTacticDetailsQuery, User } from 'data/graphql/generated';
import { verifyUserRole } from '../../../utils/verifyUserRole';

export function getTacticDetails(
  localCountryTacticsEnabled: boolean,
  showGlobalPlan: boolean,
  tacticsData: CommercialTacticDetailsQuery | undefined,
  userRole: ReturnType<typeof verifyUserRole>,
  user: User | undefined
) {
  if (!localCountryTacticsEnabled) {
    return tacticsData?.commercialTacticDetails?.items.filter(
      (b) => b.region === 'global'
    );
  }

  const tacticWithAdditionalCountries =
    tacticsData?.commercialTacticDetails?.items.map(
      (item, _, originalArray) => {
        if (item.region === 'global') {
          const additional = originalArray.filter((arrItem) => {
            return (
              arrItem.commercialTacticId === item.commercialTacticId &&
              arrItem.region !== 'global'
            );
          }).length;

          return { ...item, additionalCountries: additional };
        }
        return item;
      }
    ) || [];

  const data = tacticWithAdditionalCountries.filter((detail) => {
    if (userRole.isCountryContributor) {
      return detail.region === 'global' || detail.region === user?.country;
    }
    return detail;
  });

  const isCountryContributor = userRole.isCountryContributor;

  if (isCountryContributor) {
    return (
      (showGlobalPlan
        ? orderBy(data, (item) => {
            const countryOnTop = [item.region === 'global', item.region];

            return countryOnTop;
          })
        : tacticsData?.commercialTacticDetails?.items?.filter(
            (b) => b.region === user?.country
          )) || []
    );
  }

  return showGlobalPlan
    ? orderBy(data, (item) => {
        const globalOnTop = [item.region !== 'global', item.region];

        return globalOnTop;
      })
    : tacticWithAdditionalCountries.filter((b) => b.region === 'global');
}
