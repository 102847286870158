import { ButtonLabel, CountryFlag, Icon } from 'components/shared';
import { colors } from 'constants/index';
import React from 'react';
import styled from 'styled-components/macro';
import { Country, CountryGlobal } from 'types';
import { startCase } from 'utils/startCase';

const Wrapper = styled.div<{ showDropdown: boolean }>`
  position: absolute;
  top: 41px;
  left: 0;
  min-width: 127px;
  max-height: 230px;
  padding: 5px;
  background: ${colors.white};
  border-radius: 5px;
  border: 0.5px solid rgba(20, 20, 39, 0.3);

  display: ${({ showDropdown }) => (showDropdown ? 'flex' : 'none')};
  flex-direction: column;
  > * + * {
    margin-top: 5px;
  }

  overflow-y: auto;
`;

interface Props {
  innerRef?: React.MutableRefObject<HTMLDivElement | null>;
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  showDropdown: boolean;
  activeCountry?: CountryGlobal;
  setCountry?: (country: CountryGlobal) => void;
  allCountries: Country[];
  className?: string;
  globalOptionText?: string;
  multiSelect?: boolean;
  selectedRegions?: CountryGlobal[];
}

export const CountriesDropdown = ({
  innerRef,
  showDropdown,
  activeCountry,
  setCountry,
  allCountries,
  className,
  globalOptionText,
  multiSelect,
  selectedRegions,
}: Props) => {
  return (
    <Wrapper
      showDropdown={showDropdown}
      ref={innerRef}
      className={`${className} cypress-countries-dropdown`}
    >
      <DropDownItem
        isActive={
          multiSelect
            ? selectedRegions?.includes('global')
            : 'global' === activeCountry
        }
        onClick={() => {
          setCountry && setCountry('global');
        }}
      >
        {multiSelect &&
          (selectedRegions?.includes('global') ? (
            <Icon
              name="Tick Small"
              size={20}
              color={colors.white}
              style={{
                backgroundColor: colors.purple,
                borderRadius: '50%',
                color: colors.white,
              }}
            />
          ) : (
            <GreyCircle />
          ))}
        <Icon name="LeadGlobe" size={20} color={colors.black} />
        <ButtonLabel>{globalOptionText || 'Global'}</ButtonLabel>
      </DropDownItem>

      {allCountries.map((country) =>
        country ? (
          <DropDownItem
            className="cypress-countries-dropdown-item"
            key={country}
            isActive={
              multiSelect
                ? selectedRegions?.includes(country)
                : country === activeCountry
            }
            onClick={() => setCountry && setCountry(country)}
          >
            {multiSelect &&
              (selectedRegions?.includes(country) ? (
                <Icon
                  name="Tick Small"
                  size={20}
                  color={colors.white}
                  style={{
                    backgroundColor: colors.purple,
                    borderRadius: '50%',
                    color: colors.white,
                  }}
                />
              ) : (
                <GreyCircle />
              ))}
            <CountryFlag size={20} country={country} />
            <ButtonLabel style={{ whiteSpace: 'nowrap' }}>
              {startCase(country)}
            </ButtonLabel>
          </DropDownItem>
        ) : null
      )}
    </Wrapper>
  );
};

const DropDownItem = styled.div<{ isActive?: boolean }>`
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  > * + * {
    margin-left: 5px;
  }
  cursor: pointer;
  border: 1.5px solid
    ${({ isActive }) => (isActive ? colors.purple : 'transparant')};
  border-radius: 5px;
  padding: 5px;
  &:hover {
    background-color: ${colors.greyLight};
  }
  transition: all 0.3s;
`;

const GreyCircle = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid ${colors.greyDark};
  background-color: ${colors.white};
  flex: none;
`;
