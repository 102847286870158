import { BodyNormal } from 'components/shared';
import { colors } from 'constants/colors';
import { ProgressDonut } from '../feature-progress-donut';
import {
  GoalTrackingCard,
  GoalTrackingCardHeader,
  GoalTrackingCardTargetSubtitle,
  GoalTrackingCardTitle,
  GoalTrackingCardDetails,
  GoalTrackingCardInputTrackingTitle,
} from '../TrackingGoals.styles';

interface NoGoalTrackingAvailableProps {
  title: string;
  subtitle: string;
  target: number;
  period: string;
  isExpanded?: boolean;
  isPreview?: boolean;
  isActive?: boolean;
  trackingTitle?: string;
  onClick?: () => void;
}

export const NoGoalTrackingAvailable = ({
  title,
  subtitle,
  target,
  period,
  onClick,
  trackingTitle,
  isExpanded = false,
  isPreview = false,
  isActive = false,
}: NoGoalTrackingAvailableProps) => {
  return (
    <GoalTrackingCard
      isExpanded={isExpanded}
      isPreview={isPreview}
      isActive={isActive}
      onClick={onClick}
    >
      <GoalTrackingCardHeader>
        <GoalTrackingCardTitle>{title}</GoalTrackingCardTitle>
        <GoalTrackingCardDetails>
          <GoalTrackingCardTargetSubtitle>
            {subtitle}
          </GoalTrackingCardTargetSubtitle>
          <GoalTrackingCardInputTrackingTitle>
            {trackingTitle}
          </GoalTrackingCardInputTrackingTitle>
        </GoalTrackingCardDetails>
      </GoalTrackingCardHeader>
      <ProgressDonut size={180} target={target}>
        <BodyNormal color={colors.black}>No data</BodyNormal>
        <BodyNormal color={colors.greyDark}>{period}</BodyNormal>
      </ProgressDonut>
    </GoalTrackingCard>
  );
};
