import { formatDistanceToNowStrict } from 'date-fns';

export function formatCreatedDate(date: number | Date) {
  let distance = formatDistanceToNowStrict(date);

  if (distance === '1 day') {
    distance = 'Yesterday';
  }

  return distance
    .replace(/^\d{1,2}\sseconds?/, 'Just now')
    .replace('minute', 'min')
    .replace('hour', 'hr');
}
