import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import {
  BodyNormal,
  BodySmall,
  ButtonLabel,
  Caption,
  Collaboration,
  Icon,
  TooltipWrapper,
} from 'components/shared';
import { DeleteIcon } from 'components/shared/DeleteIcon';
import { colors } from 'constants/colors';
import {
  LongTermSolutionFragment,
  useLongTermSolutionDeleteMutation,
  useLongTermSolutionUpdateMutation,
  LongTermSolutionsDocument,
  FutureTrendFragment,
} from 'data/graphql/generated';
import {
  DeleteConfirmation,
  DeleteConfirmationBody,
  DeleteConfirmationFooter,
} from 'components/Insight';
import {
  apolloDeleteHelper,
  apolloUpdateHelper,
} from 'utils/apolloQueryHelpers';
import { useParams } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthContext';
import useDesktop from 'hooks/useDesktop';
import { Loading } from 'components/Loading';
import { longTermSolutionsQueryVars } from 'containers/LongTermStrategySolutions';
import FocusDropdown from 'components/shared/FocusDropdown';
import { RationaleModal } from 'components/shared/RationaleModal';
import { verifyUserRole } from 'utils/verifyUserRole';

export const Wrapper = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.greyMedium};
  border-radius: 5px;
  width: 325px;
  padding: 15px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PinAndCaption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextContainer = styled.div`
  margin: 5px 0;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`;

const StyledDeleteConfirmation = styled(DeleteConfirmation)``;

interface URLParams {
  strategyId: string;
}

interface Props {
  data?: LongTermSolutionFragment;
  futureTrend?: FutureTrendFragment;
}

export const SolutionCallout: React.FC<Props> = ({ data, futureTrend }) => {
  const { strategyId } = useParams<URLParams>();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [deleteLongTermSolution] = useLongTermSolutionDeleteMutation();
  const [updateLongTermSolution] = useLongTermSolutionUpdateMutation();
  const [focused, setFocused] = useState(data?.focused || false);
  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);
  const isDesktop = useDesktop();
  const queryVars = longTermSolutionsQueryVars(strategyId);

  const [modalSlideIdx, setModalSlideIdx] = useState<number | null>(null);
  const [, setShowSingleSlide] = useState<boolean>(false);

  async function toggleFocus() {
    if (!data?.id) return;

    try {
      if (focused) {
        setFocused(false);

        await updateLongTermSolution({
          variables: {
            localUid: data.localUid,
            data: {
              focused: false,
            },
          },
          optimisticResponse: {
            longTermSolutionUpdate: {
              ...data,
              focused: false,
            },
          },
          update: apolloUpdateHelper({
            responseField: 'longTermSolutionUpdate',
            query: LongTermSolutionsDocument,
            queryVars,
            queryName: 'longTermSolutions',
          }),
        });
      } else {
        setModalSlideIdx(1);
      }
    } catch (error) {
      setFocused(focused);
      alert('Something went wrong');
    }
  }

  async function handleSubmit(rationale: string) {
    if (!data?.id) return;

    try {
      await updateLongTermSolution({
        variables: {
          localUid: data.localUid,
          data: {
            focused: true,
            focusRationale: rationale,
          },
        },
        optimisticResponse: {
          longTermSolutionUpdate: {
            ...data,
            focused: true,
            focusRationale: rationale,
          },
        },
        update: apolloUpdateHelper({
          responseField: 'longTermSolutionUpdate',
          query: LongTermSolutionsDocument,
          queryVars,
          queryName: 'longTermSolutions',
        }),
      });
    } catch (error) {}
  }

  useEffect(() => {
    setFocused(data?.focused || false);
  }, [data]);

  if (!futureTrend || !data)
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    );

  return (
    <>
      <RationaleModal
        modalSlideIdx={modalSlideIdx}
        setModalSlideIdx={setModalSlideIdx}
        handleClose={() => {
          setModalSlideIdx(null);
        }}
        handleSubmit={handleSubmit}
        rationaleText={data?.focusRationale || ''}
        showSingleSlide={true}
        modalDetails={{ 'Opportunity / Risk': futureTrend?.risk || '' }}
      />

      <Wrapper className="callout">
        {showConfirmDelete && (
          <StyledDeleteConfirmation show={true}>
            <div>
              <BodyNormal color={colors.black}>
                Delete this solution?
              </BodyNormal>
            </div>
            <DeleteConfirmationBody>
              <BodyNormal color={colors.black60}>
                Any discussion and files will be lost.
              </BodyNormal>
            </DeleteConfirmationBody>
            <DeleteConfirmationFooter>
              <ButtonLabel onClick={() => setShowConfirmDelete(false)}>
                Cancel
              </ButtonLabel>
              <ButtonLabel
                color={colors.red}
                onClick={async (e) => {
                  try {
                    await deleteLongTermSolution({
                      variables: { id: data.id },
                      update: apolloDeleteHelper({
                        responseField: 'longTermSolutionDelete',
                        query: LongTermSolutionsDocument,
                        queryVars,
                        queryName: 'longTermSolutions',
                      }),
                    });
                  } catch (error) {
                    alert('Something went wrong');
                  }
                }}
              >
                Delete
              </ButtonLabel>
            </DeleteConfirmationFooter>
          </StyledDeleteConfirmation>
        )}

        <FlexContainer>
          {isDesktop ? (
            <Caption color={colors.greyDark}>{futureTrend.risk}</Caption>
          ) : (
            <PinAndCaption>
              <Icon
                name="Pin"
                size={30}
                color={colors.purple10}
                style={{ marginRight: 5 }}
              />
              <Caption color={colors.greyDark}>Tap grid to position</Caption>
            </PinAndCaption>
          )}
          <FocusDropdown
            onClick={toggleFocus}
            checked={data.focused}
            disabled={!isLead}
            rationaleText={data.focusRationale}
            setModalSlideIdx={setModalSlideIdx}
            setShowSingleSlide={setShowSingleSlide}
          />
        </FlexContainer>

        <TextContainer>
          {!isDesktop && (
            <Caption color={colors.greyDark} style={{ marginRight: 15 }}>
              {futureTrend.risk}
            </Caption>
          )}
          <BodySmall>{data.text}</BodySmall>
        </TextContainer>
        <FlexContainer>
          {!showConfirmDelete && isDesktop ? (
            <TooltipWrapper text="Delete solution" effect="solid">
              <DeleteIcon onClick={() => setShowConfirmDelete(true)} />
            </TooltipWrapper>
          ) : (
            <div />
          )}
          <Collaboration collaboration={data.collaboration} />
        </FlexContainer>
      </Wrapper>
    </>
  );
};
