import { useEffect, useRef, useState } from 'react';
import { Group, Rect, Text } from 'react-konva';

import { Collaboration } from 'data/graphql/generated';
import { colors } from 'constants/index';
import { useCollaborationContext } from 'contexts/CollaborationContext';
import { BlockIcon } from 'components/PatientJourney/src';
import { Text as KonvaText } from 'konva/types/shapes/Text';

interface Props {
  x: number;
  y: number;
  onMouseEnter(): void;
  onMouseLeave(): void;
  color?: string;
  collaboration?:
    | Pick<
        Collaboration,
        'id' | 'commentCount' | 'attachmentCount' | 'alignmentCount'
      >
    | null
    | undefined;
}
const defaultColor = colors.black10;

export const KonvaCollaboration = ({
  x,
  y,
  onMouseEnter,
  onMouseLeave,
  collaboration,
  color = defaultColor,
}: Props) => {
  const [collaborationHover, setCollaborationHover] = useState(false);
  const [, setCollaboration] = useCollaborationContext();
  const [collaborationTextWidths, setCollaborationTextWidths] = useState(0);

  const likeTextRef = useRef<KonvaText | null>(null);
  const commentTextRef = useRef<KonvaText | null>(null);
  const attachmentTextRef = useRef<KonvaText | null>(null);

  useEffect(() => {
    setCollaborationTextWidths(
      Number(likeTextRef?.current?.textWidth || 0) +
        Number(commentTextRef?.current?.textWidth || 0) +
        Number(attachmentTextRef?.current?.textWidth || 0)
    );
  }, []);

  const ICON_WIDTH = 20;
  const TEXT_MARGIN = 6;
  const PADDING = 5;

  const commentsX =
    //left padding
    PADDING +
    //Thumbsup icon width
    ICON_WIDTH +
    //Text margin
    TEXT_MARGIN +
    //Text Width
    Number(likeTextRef?.current?.textWidth || 0);

  const attachmentX =
    commentsX +
    ICON_WIDTH +
    //Text margin
    TEXT_MARGIN +
    Number(commentTextRef?.current?.textWidth || 0);

  const collaborationWidth =
    ICON_WIDTH * 3 + PADDING * 2 + TEXT_MARGIN * 2 + collaborationTextWidths;

  return (
    <Group
      x={x - collaborationWidth}
      y={y}
      onMouseEnter={() => {
        setCollaborationHover(true);
        onMouseEnter();
      }}
      onMouseLeave={() => {
        setCollaborationHover(false);
        onMouseLeave();
      }}
      onClick={(e) => {
        e.cancelBubble = true;
        setCollaboration(collaboration?.id);
      }}
      onTap={(e) => {
        e.cancelBubble = true;
        setCollaboration(collaboration?.id);
      }}
    >
      {/* BG */}
      <Rect
        height={22}
        width={collaborationWidth}
        sides={4}
        cornerRadius={30}
        fill={collaborationHover ? color : 'transparent'}
      />
      <Group x={5} y={3}>
        <BlockIcon type="ThumbsUp" />
        <Text
          ref={likeTextRef}
          x={19}
          y={3}
          text={String(collaboration?.alignmentCount || 0)}
          fontFamily="ABCFavoritMedium"
          fontSize={12}
          lineHeight={0.93}
          align="center"
          fill={colors.black70}
        />
      </Group>
      <Group x={commentsX} y={3}>
        <BlockIcon type="Comment" />
        <Text
          ref={commentTextRef}
          x={20}
          y={3}
          text={String(collaboration?.commentCount || 0)}
          fontFamily="ABCFavoritMedium"
          fontSize={12}
          lineHeight={0.93}
          align="center"
          fill={colors.black70}
        />
      </Group>
      <Group x={attachmentX} y={3}>
        <BlockIcon type="Attachment" />
        <Text
          ref={attachmentTextRef}
          x={20}
          y={3}
          text={String(collaboration?.attachmentCount || 0)}
          fontFamily="ABCFavoritMedium"
          fontSize={12}
          lineHeight={0.93}
          align="center"
          fill={colors.black70}
        />
      </Group>
    </Group>
  );
};
