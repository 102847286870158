import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import {
  StrategicQuestionUpdateInput,
  SubStep,
  useStrategicQuestionsQuery,
  useStrategyCountriesQuery,
} from 'data/graphql/generated';
import { device } from 'utils/breakpoints';
import { polling } from 'constants/polling';
import { LeadAndLagIndicatorsSummarySidebar } from 'components/shared/ImperativeSummarySidebar';
import {
  CriticalMetricsParams,
  useCriticalMetricsCompetitiveAdvantage,
} from 'components/3-5-critical-metrics/shared/shared-data-access-critical-metrics/src';
import {
  CriticalMetricsStrategicImperatives,
  RequireStrategicPossibilities,
} from 'components/3-5-critical-metrics/shared/shared-ui-critical-metrics/src';
import { useAuthContext } from 'contexts/AuthContext';
import { verifyUserRole } from 'utils/verifyUserRole';

import { TrackedGoals } from './TrackedGoal/TrackedGoals';
import {
  useCustomTotalNavHeight,
  useSelectedTrackedGoal,
} from './data-access-goal-tracking';
import { CriticalMetricsPage } from './CriticalMetricsPage';
import { useSidebar } from 'hooks';
import { GoalTrackingSidebar } from './feature-goal-tracking-sidebar/GoalTrackingSidebar';
import { useGetAllTrackedGoals } from './data-access-goal-tracking';
import { LoadingComponent } from 'components/Loading';
import { RequireMissingTrackedGoals } from './ui-goal-tracking-empty-states';
import useMobile from 'hooks/useMobile';
import { globalContributor } from 'constants/index';
import { useGlobalContext } from 'contexts/GlobalContext';

const sidebarWidth = 300;
const sidebarTransition = {
  timingFunction: 'linear',
  duration: 200,
};

const MainContent = styled.div<{
  sidebarOpen: boolean;
  overlapWidth?: number;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;

  width: 90%;
  height: fit-content;
  margin: 0 auto;
  padding: 15px 0;

  @media ${device.desktopMin} {
    padding: 20px;
  }

  ${({ overlapWidth }) =>
    overlapWidth ? `(min-width: ${overlapWidth}px)` : '(min-width: 945px)'} {
    width: ${({ sidebarOpen }) =>
      sidebarOpen && `calc(100% - ${sidebarWidth}px)`};
    transition: ${() =>
      `width ${sidebarTransition.duration}ms ${sidebarTransition.timingFunction}`};
  }
`;

const overlapWidth = 675;

interface GoalsTrackingPageProps {}

export const GoalsTrackingPage: React.FC<GoalsTrackingPageProps> = () => {
  const {
    drugId,
    strategyId,
    competitiveAdvantageRowUrlParam,
  } = useParams<CriticalMetricsParams>();
  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);
  const isMobile = useMobile();
  const {
    isOpen: isSidebarOpen,
    open: openSidebar,
    close: closeSidebar,
  } = useSidebar();

  const {
    isOpen: isTrackingSidebarOpen,
    open: openTrackingSidebar,
    close: closeTrackingSidebar,
  } = useSidebar();

  const {
    trackedGoal: selectedTrackedGoal,
    selectTrackedGoal,
    deselectTrackedGoal,
  } = useSelectedTrackedGoal();

  const handleOpenLeadAndLagIndicatorsSidebar = () => {
    closeTrackingSidebar();
    openSidebar();
  };

  const handleOpenTrackingSidebar = () => {
    closeSidebar();
    openTrackingSidebar();
  };

  // handle global context path
  const [ strategicImperativeId, setStrategicImperativeId] = useGlobalContext(
    'strategicImperativeId'
  );

  console.log(strategicImperativeId)

  const { totalNavHeight } = useCustomTotalNavHeight();
  const {
    data: supportedCountries,
    loading: supportedCountriesLoading,
  } = useStrategyCountriesQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'network-only',
  });
  const {
    selectedRowImperative,
    competitiveAdvantageRows,
    competitiveAdvantageRowsLoading,
    startCompetitiveAdvantageRowsPolling,
    stopCompetitiveAdvantageRowsPolling,
  } = useCriticalMetricsCompetitiveAdvantage(SubStep.Tracking);
  const {
    data: strategicQuestionsData,
    loading: strategicQuestionsLoading,
    startPolling: startStrategicQuestionsPolling,
    stopPolling: stopStrategicQuestionsPolling,
  } = useStrategicQuestionsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        competitiveAdvantageRowId: +competitiveAdvantageRowUrlParam,
        field: 'leadIndicators\nlagIndicators',
      },
    },
    fetchPolicy: 'network-only',
  });

  const leadLagStrategicQuestion =
    strategicQuestionsData?.strategicQuestions?.items?.[0];
  const leadLagStrategicQuestionList =
    leadLagStrategicQuestion?.lists ?? undefined;
  const leadIndicators = leadLagStrategicQuestionList?.[0].list ?? undefined;
  const lagIndicators = leadLagStrategicQuestionList?.[1].list ?? undefined;

  const isRequiredDataMissing =
    !competitiveAdvantageRows || competitiveAdvantageRows.length === 0;

  const siderbarOffset = useMemo(
    () => ({
      offset: 430,
      isHidden: !isTrackingSidebarOpen,
    }),
    [isTrackingSidebarOpen]
  );

  const {
    goals,
    loading: getAllTrackedGoalsLoading,
    startPolling: startGetAllTrackedGoalsPolling,
    stopPolling: stopGetAllTrackedGoalsPolling,
  } = useGetAllTrackedGoals();

  React.useEffect(() => {
    startCompetitiveAdvantageRowsPolling(polling.default);
    startStrategicQuestionsPolling(polling.default);
    startGetAllTrackedGoalsPolling(polling.default);
    return () => {
      stopCompetitiveAdvantageRowsPolling();
      stopStrategicQuestionsPolling();
      stopGetAllTrackedGoalsPolling();
    };
  }, [
    startCompetitiveAdvantageRowsPolling,
    startGetAllTrackedGoalsPolling,
    startStrategicQuestionsPolling,
    stopCompetitiveAdvantageRowsPolling,
    stopGetAllTrackedGoalsPolling,
    stopStrategicQuestionsPolling,
  ]);

  const supportedCountriesWithoutGC = useMemo(
    () =>
      supportedCountries?.strategyCountries?.countries.filter(
        (x) => x !== globalContributor
      ) ?? [],
    [supportedCountries?.strategyCountries?.countries]
  );

  return (
    <CriticalMetricsPage
      subStep={SubStep.Tracking}
      title="Goal tracking dashboard"
      description="Track goals when they're due and review the overall performance."
      TabsComponent={
        <CriticalMetricsStrategicImperatives
          subStep={SubStep.Tracking}
          isLead={isLead}
          setStrategicImperativeId={setStrategicImperativeId}
          sidebarOpen={isSidebarOpen}
          onOpenSidebar={handleOpenLeadAndLagIndicatorsSidebar}
          strategicQuestionsLoading={false}
          competitiveAdvantageRows={competitiveAdvantageRows}
          totalUsers={undefined}
          leadLagStrategicQuestion={{} as any}
          onSave={async (val: StrategicQuestionUpdateInput) => {}}
        />
      }
      sidebarOffset={siderbarOffset}
    >
      {selectedTrackedGoal && (
        <GoalTrackingSidebar
          key={selectedTrackedGoal.id}
          isOpen={isTrackingSidebarOpen}
          heightOffset={totalNavHeight}
          onClose={closeTrackingSidebar}
          trackedGoal={selectedTrackedGoal}
          supportedCountries={supportedCountriesWithoutGC}
        />
      )}

      <MainContent sidebarOpen={isSidebarOpen} overlapWidth={overlapWidth}>
        <LoadingComponent
          isLoading={
            supportedCountriesLoading ||
            competitiveAdvantageRowsLoading ||
            strategicQuestionsLoading ||
            competitiveAdvantageRowsLoading ||
            strategicQuestionsLoading ||
            getAllTrackedGoalsLoading
          }
        >
          <RequireStrategicPossibilities
            drugId={drugId}
            strategyId={strategyId}
            showEmptyState={isRequiredDataMissing}
          >
            <RequireMissingTrackedGoals
              drugId={drugId}
              strategyId={strategyId}
              competitiveAdvantageRowId={competitiveAdvantageRowUrlParam}
              isEmpty={goals.length === 0}
            >
              <TrackedGoals
                goals={goals.map((tracked) => ({
                  id: tracked.id,
                  goal: tracked.title,
                  target: tracked.target,
                  input: tracked.input,
                  frequency: tracked.frequency,
                  averageProgress: tracked.averageProgress ?? undefined,
                  numberOfCountriesWithTracking:
                    tracked.numberOfCountriesWithTracking,
                  supportedCountries: supportedCountriesWithoutGC,
                  isActive: selectedTrackedGoal?.id === tracked.id,
                  onClick: () => {
                    deselectTrackedGoal();
                    selectTrackedGoal(tracked);
                    if (!isTrackingSidebarOpen) {
                      handleOpenTrackingSidebar();
                    }
                  },
                }))}
              />
            </RequireMissingTrackedGoals>
          </RequireStrategicPossibilities>
        </LoadingComponent>
      </MainContent>

      <LeadAndLagIndicatorsSummarySidebar
        isOpen={isSidebarOpen && !!competitiveAdvantageRowUrlParam}
        onClose={closeSidebar}
        heightOffset={isMobile ? 317 : 0}
        strategicPossibility={selectedRowImperative}
        indicators={{
          lead: leadIndicators,
          lag: lagIndicators,
        }}
      />
    </CriticalMetricsPage>
  );
};
