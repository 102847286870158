import { Icon, Caption } from 'components/shared';
import React from 'react';
import styled from 'styled-components/macro';
import { colors } from 'constants/index';
import { device } from 'utils/breakpoints';

const ContributorCountryPlansContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.bluePurpleFaded};
  border-radius: 5px;
  min-width: min-content;
  width: fit-content;
  max-width: fit-content;

  padding-right: 10px;

  p {
    max-height: 30px;
    overflow: hidden;
    display: -webkit-box;
  }

  @media ${device.tabletMin} {
    order: 1;
    flex: auto;
  }

  @media ${device.tabletMax} {
    order: -1;
    margin-right: 5px;
    width: min-content;
  }
`;

export const ContributorCountryPlansStatus = () => {
  return (
    <ContributorCountryPlansContainer>
      <Icon name="GradientBlueTick" size={30} />
      <Caption>Country plans enabled</Caption>
    </ContributorCountryPlansContainer>
  );
};
