import { BodySmall, ButtonLabel, TooltipWrapper } from 'components/shared';
import { colors } from 'constants/index';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
`;

const Option = styled.div<{ disabled: boolean }>`
  margin-left: 15px;
  display: flex;
  align-items: center;

  * {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

const Title = styled(BodySmall)`
  color: ${colors.greyDark};
  text-align: right;
`;

const Options = styled.div<{ disabled: boolean }>`
  display: flex;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const Circle = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const OuterCircle = styled(Circle)<{
  color: string;
  purpleHover: boolean;
  selected: boolean;
  disabled: boolean;
}>`
  width: 20px;
  height: 20px;
  background: ${({ color }) => color && color};
  margin-right: 5px;


  :hover {
    background: ${({ purpleHover, selected, disabled }) =>
      !disabled && purpleHover && !selected && colors.purple60a};

    ::after {
      content: '';
      position: absolute;
      top: 0:
      left: 0;
      width: 100%;
      height: 100%;
      background: ${({ selected, purpleHover, disabled }) =>
        !disabled && !selected && !purpleHover ? colors.black20a : 'none'};
      border-radius: 50%;
    }
  }
  `;

const MidCircle = styled(Circle)`
  width: 16px;
  height: 16px;
  background: ${colors.white};
  z-index: 2;
`;
const InnerCircle = styled(Circle)<{
  color: string;
  selected: boolean;
  purpleHover: boolean;
}>`
  width: 10px;
  height: 10px;
  background: ${({ color, selected }) =>
    color && selected ? color : colors.white};
`;

interface Props {
  selected?: string;
  onClick(val: string): void;
  disabled: boolean;
}

function getColor(successConditionType: string, isSelected: boolean) {
  return successConditionType === 'Barrier'
    ? colors.redPurpleGradient
    : successConditionType === 'Driver'
    ? colors.greenPurpleGradient
    : isSelected
    ? colors.purple
    : colors.greyDark;
}

export const CategoryButtons = ({ selected, onClick, disabled }: Props) => {
  return (
    <Wrapper>
      <Title>Choose category: </Title>

      <TooltipWrapper
        text={disabled ? 'Only Leads can edit' : ''}
        effect="float"
      >
        <Options disabled={disabled}>
          {['Barrier', 'Driver', 'None'].map((item) => {
            const isSelected = item === selected;
            const color = getColor(item, isSelected);
            const purpleHover = item === 'None';

            return (
              <Option
                className={`cypress-success-condition-category-option-${item.toLowerCase()}`}
                key={item}
                onClick={() => !disabled && onClick(item)}
                disabled={disabled}
              >
                <OuterCircle
                  color={color}
                  purpleHover={purpleHover}
                  selected={isSelected}
                  disabled={disabled}
                >
                  <MidCircle>
                    <InnerCircle
                      color={color}
                      selected={isSelected}
                      purpleHover={purpleHover}
                    />
                  </MidCircle>
                </OuterCircle>

                <ButtonLabel>{item}</ButtonLabel>
              </Option>
            );
          })}
        </Options>
      </TooltipWrapper>
    </Wrapper>
  );
};
