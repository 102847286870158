import styled from 'styled-components/macro';
import { colors } from 'constants/colors';
import { BodySmall } from 'components/shared';

const StyledContainer = styled.div`
  height: 100%;
  background: ${colors.black05};
  padding: 10px;
`;

const StyledName = styled(BodySmall)`
  word-wrap: break-word;
`;

interface PicoFieldNameProps {
  name: string;
}

export const PicoFieldName = ({ name }: PicoFieldNameProps) => {
  return (
    <StyledContainer>
      <StyledName>{name}</StyledName>
    </StyledContainer>
  );
};
