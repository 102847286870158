import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { BodySmall, Subtitle2 } from 'components/shared';
import { device, size } from 'utils/breakpoints';
import { colors } from 'constants/colors';

const DescriptionContent = styled.span`
  display: flex;
  margin: 0 auto;
  width: 100%;
  white-space: pre;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: center;
`;

const EmptyImage = styled.img`
  margin: 0 auto;
  width: 115px;
  height: 115px;
`;

const Container = styled.div`
  width: 100%;
  margin: 20;
  background: ${colors.white};
  border: 1px solid ${colors.black30};
  text-align: center;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
  height: 255px;

  @media ${device.tabletMin} {
    padding-top: 32px;
  }

  @media ${device.desktopMin} {
    padding-top: 77px;
    height: 345px;
  }

  @media (min-width: ${size.desktop}) {
    padding-top: 87px;
    height: 345px;
  }
`;

const Title = styled(Subtitle2)``;

const Description = styled(BodySmall)`
  display: inline;
`;

const RedirectLink = styled(Link)`
  display: inline;
  color: ${colors.greyDark};
  font-size: 14;
  font-weight: 500;
`;

const TITLE = 'Nothing to discuss';
const DESCRIPTION =
  'Leads must first choose Strategic Imperatives to focus on in ';
const STRATEGIC_IMPERATIVES_TEXT = '3.3 Strategic Imperatives';

export interface MissingStrategicPossibilitiesProps {
  drugId: string;
  strategyId: string;
}

export const MissingStrategicPossibilities: React.FC<MissingStrategicPossibilitiesProps> = ({
  drugId,
  strategyId,
}) => {
  const redirectURL = `/d/${drugId}/strategy/${strategyId}/3_1`;
  return (
    <Container>
      <EmptyImage src="/Generic-Empty-State.svg" />
      <Title color={colors.greyDark}>{TITLE}</Title>
      <DescriptionContent>
        <Description color={colors.greyDark}>{DESCRIPTION}</Description>{' '}
        <RedirectLink to={redirectURL}>
          {STRATEGIC_IMPERATIVES_TEXT}
        </RedirectLink>
      </DescriptionContent>
    </Container>
  );
};
