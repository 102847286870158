import { colors } from 'constants/colors';
import { Icon } from 'components/shared';

export const AllSetStatusIcon = ({ isComplete }: { isComplete: boolean }) => {
  if (!isComplete) {
    return null;
  }

  return <Icon size={30} name="Tick Small" color={colors.purple} />;
};
