import React, { useState, useEffect, useCallback } from 'react';
import { Rect, Text, Group, Circle, Path, Label } from 'react-konva';
import { colors } from 'constants/colors';
import { DistributionIcon } from '../DistributionIcon';
import {
  RECT_WIDTH,
  BLOCK_SIDE_PADDING,
  BLOCK_PADDING_TOP,
  BLOCK_BORDER_WIDTH,
} from '../constants';

import { ValueText } from '../ValueText';
import { renderBlockValueTextInput } from '../renderBlockValueTextInput';
import { KonvaCollaboration } from 'components/shared/KonvaCollaboration';
import { BlockStar } from './BlockStar';
import { DeleteBlock } from './DeleteBlock';
import { Arrows } from './Arrows';
import { renderTextArea, textAreaWidth } from './renderTextArea';
import { BlockProps } from './types';
import { IndependentToggle } from './IndependentToggle';
import { Portal } from 'react-konva-utils';

export const BlockView: React.FC<BlockProps> = ({
  blockTreeForAllBlocks,
  calculateBlockPositions,
  allBlocks,
  previousStageId,
  topStage,
  bottomStage,
  handleBlockMouseEnter,
  handleBlockMouseLeave,
  blockHover,
  country,
  canEditValue,
  isLead,
  block,
  column,
  x,
  y,
  changeCursor,
  stageRef,
  patientFlowBlockCreate,
  patientFlowBlockUpdate,
  setConfirmDeleteModalId,
  nextStageId,
  patientFlowBlockDelete,
  patientFlowValue,
  upsertPatientFlowValue,
  sumOfChildrenValues,
  showPercentage,
  parentBlockValue,
  onDistributionClick,
  globalLayoutCompleted,
  forceEdit,
  setEditing,
  currentCountryIsComplete,
  disableCollaboration = false,
  allCountriesCompleted,
  showStarringModal,
  leftOffset,
  showWarningModal,
  onIndependentClick,
}) => {
  const [addHover, setAddHover] = useState(false);
  const [addingBlock, setAddingBlock] = useState(false);

  const [deleteCardVisible, setDeleteCardVisible] = useState(false);

  const [starred, setStarred] = useState(
    () =>
      !!block?.stars?.find((star) =>
        country === 'global' ? star.global : country === star?.region
      )?.stakeholderDefinitions?.length
  );

  const [
    amountOfStarsExcludingGlobal,
    setAmountOfStarsExcludingGlobal,
  ] = useState(() => block?.stars?.filter((star) => !star.global).length || 0);

  const [moveBlockUpHover, setMoveBlockUpHover] = useState(false);
  const [moveBlockDownHover, setMoveBlockDownHover] = useState(false);

  useEffect(() => {
    setStarred(
      !!block?.stars?.find((star) => {
        return country === 'global' ? star.global : country === star?.region;
      })?.stakeholderDefinitions.length
    );

    setAmountOfStarsExcludingGlobal(() => {
      const stars = block?.stars?.filter((star) => !star.global);
      const starredRegions = stars?.filter(
        (star) => !!star?.stakeholderDefinitions?.length || false
      );
      return starredRegions?.length || 0;
    });
  }, [block?.stars, country]);

  // Show text input if forceEdit is truthy
  useEffect(() => {
    if (forceEdit && isLead && country === 'global') {
      renderTextArea(
        block,
        stageRef,
        patientFlowBlockUpdate,
        setConfirmDeleteModalId,
        setDeleteCardVisible,
        setEditing,
        column,
        allBlocks,
        calculateBlockPositions,
        blockTreeForAllBlocks,
        leftOffset
      );
      setEditing(undefined);
    }
  }, [
    country,
    isLead,
    forceEdit,
    block,
    stageRef,
    setConfirmDeleteModalId,
    patientFlowBlockUpdate,
    setEditing,
    column,
    allBlocks,
    calculateBlockPositions,
    blockTreeForAllBlocks,
    leftOffset,
  ]);

  const blockFillStyle = starred
    ? {
        fillLinearGradientStartPoint: { x: -50, y: 150 },
        fillLinearGradientEndPoint: { x: 150, y: -50 },
        fillLinearGradientColorStops: [
          0.2,
          '#F4F0F9',
          0.55,
          '#F4F7FE',
          0.7,
          '#FDF3FC',
          1,
          '#FEFCFE',
        ],
      }
    : {
        fill: 'white',
      };

  const handleDelete = () => {
    isLead && country === 'global' && patientFlowBlockDelete(block.id);

    setDeleteCardVisible(false);
  };

  const percentageValue =
    ((sumOfChildrenValues - (patientFlowValue?.value || 0)) /
      (patientFlowValue?.value || 1)) *
    100;

  const childrenSumDifference = showPercentage
    ? percentageValue
    : ((patientFlowValue?.value || 0) - sumOfChildrenValues) * -1;

  const handleTextClick = useCallback(() => {
    if (!globalLayoutCompleted && isLead && country === 'global') {
      handleBlockMouseEnter(block.id);

      renderTextArea(
        block,
        stageRef,

        patientFlowBlockUpdate,
        setConfirmDeleteModalId,
        setDeleteCardVisible,
        setEditing,
        column,
        allBlocks,
        calculateBlockPositions,
        blockTreeForAllBlocks,
        leftOffset
      );
    }
  }, [
    allBlocks,
    block,
    blockTreeForAllBlocks,
    calculateBlockPositions,
    column,
    country,
    globalLayoutCompleted,
    handleBlockMouseEnter,
    isLead,
    leftOffset,
    patientFlowBlockUpdate,
    setConfirmDeleteModalId,
    setEditing,
    stageRef,
  ]);

  const handleAddBlockClick = async () => {
    if (addingBlock) {
      return;
    }
    //disable adding
    setAddingBlock(true);

    if (!nextStageId) {
      await patientFlowBlockCreate(block.columnId, block.id);
    } else {
      await patientFlowBlockCreate(nextStageId, block.id);
    }
    //enableAdding
    setAddingBlock(false);
  };

  const handleUpArrowClick = () => {
    if (previousStageId) {
      patientFlowBlockUpdate(block.id, {
        columnId: previousStageId,
      });
      upsertPatientFlowValue(
        patientFlowValue?.id,
        {
          columnId: previousStageId,
          patientFlowBlockId: block.id,
        },
        showPercentage
      );
      setMoveBlockUpHover(false);
    }
  };

  const handleDownArrowClick = () => {
    if (nextStageId) {
      patientFlowBlockUpdate(block.id, {
        columnId: nextStageId,
      });
      upsertPatientFlowValue(
        patientFlowValue?.id,
        {
          columnId: nextStageId,
          patientFlowBlockId: block.id,
        },
        showPercentage
      );
      setMoveBlockDownHover(false);
    }
  };

  const parentIsIndependent = useCallback(() => {
    let parent = block.parent;
    let canBeIndependent = false;
    while (parent && !canBeIndependent) {
      if (parent?.isIndependent) {
        canBeIndependent = true;
      }
      parent = parent?.parent;
    }
    return canBeIndependent;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block.parent, allBlocks]);

  const isIndependent = block.isIndependent || parentIsIndependent();

  const handleIndependentValueClick = useCallback(async () => {
    if (starred || block.hasLeveragePointSibling) {
      showWarningModal({
        heading: 'Cannot make a leverage point’s values independent',
        message:
          'This block or a linked block below is starred as a leverage point. Remove leverage points from the branch to make it independent.',
      });
      return;
    }

    patientFlowBlockUpdate(block.id, {
      isIndependent: !block.isIndependent,
    });
    onIndependentClick();
  }, [
    block,
    starred,
    onIndependentClick,
    showWarningModal,
    patientFlowBlockUpdate,
  ]);

  const isOriginationBlock = !block.parent;

  const notLeadHoveringOnIndependentBlock =
    !isLead && blockHover && block.isIndependent;

  const showIndependentToggle =
    !parentIsIndependent() &&
    !isOriginationBlock &&
    ((isLead && blockHover) || notLeadHoveringOnIndependentBlock);

  const independentToggleTooltip = block.isIndependent
    ? isLead
      ? 'Re-connect value to parent total'
      : 'Value independent of parent'
    : isLead
    ? 'Make value independent of parent'
    : 'Value independent of parent';

  if (deleteCardVisible) {
    return (
      <DeleteBlock
        block={block}
        column={column}
        x={x}
        y={y}
        setDeleteCardVisible={setDeleteCardVisible}
        isLead={isLead}
        country={country}
        changeCursor={changeCursor}
        handleDelete={handleDelete}
      />
    );
  }

  return (
    <Group
      id={`flowblock-${block.id}`}
      name={`flowblock-col-${column.idx}`}
      x={x}
      y={y}
      onMouseEnter={() => {
        handleBlockMouseEnter(block.id);
      }}
      onMouseLeave={() => {
        handleBlockMouseLeave();
      }}
    >
      <Group>
        {/* Border */}
        <Rect
          x={-1}
          y={-1}
          height={block.height}
          width={RECT_WIDTH}
          sides={4}
          cornerRadius={6}
          fillLinearGradientStartPoint={{
            x: -RECT_WIDTH,
            y: -block.height,
          }}
          fillLinearGradientEndPoint={{
            x: RECT_WIDTH,
            y: block.height,
          }}
          fillLinearGradientColorStops={[
            0.5,
            starred ? '#7901FF' : colors.black50,
            1,
            starred ? '#BF01E3' : colors.black50,
          ]}
          id={`block-${block.id}-rect-gradient`}
        />

        {/* Block */}
        <Rect
          x={1}
          y={1}
          height={Math.max(block.height - BLOCK_BORDER_WIDTH * 2, 0)}
          width={Math.max(RECT_WIDTH - BLOCK_BORDER_WIDTH * 2, 0)}
          sides={5}
          cornerRadius={4}
          {...blockFillStyle}
          id={`block-${block.id}-rect`}
        />
      </Group>
      {!isIndependent && (
        <BlockStar
          showStarringModal={showStarringModal}
          block={block}
          changeCursor={changeCursor}
          country={country}
          starred={starred}
          amountOfStarsExcludingGlobal={amountOfStarsExcludingGlobal}
        />
      )}
      {disableCollaboration ? null : (
        <KonvaCollaboration
          x={
            RECT_WIDTH -
            // margin-right
            5
          }
          y={8}
          collaboration={block?.collaboration}
          onMouseEnter={() => {
            changeCursor('pointer');
          }}
          onMouseLeave={() => {
            changeCursor('default');
          }}
        />
      )}

      <Label
        x={BLOCK_SIDE_PADDING + BLOCK_BORDER_WIDTH}
        y={BLOCK_PADDING_TOP + BLOCK_BORDER_WIDTH}
      >
        <Text
          id={`block-${block.id}-text`}
          text={block.text}
          fontFamily="sans-serif"
          fontSize={16}
          lineHeight={1.2}
          fontWeight={400}
          align="center"
          fill={globalLayoutCompleted ? colors.greyDark : colors.black}
          width={textAreaWidth}
          onMouseEnter={() =>
            !globalLayoutCompleted &&
            isLead &&
            country === 'global' &&
            changeCursor('text')
          }
          onMouseLeave={() =>
            !globalLayoutCompleted &&
            isLead &&
            country === 'global' &&
            changeCursor('default')
          }
          onTap={() => {
            handleTextClick();
          }}
          onClick={() => {
            handleTextClick();
          }}
        />
      </Label>

      {globalLayoutCompleted ? (
        <ValueText
          canEditValue={
            canEditValue && (country === 'global' || !currentCountryIsComplete)
          }
          block={block}
          patientFlowValue={patientFlowValue}
          parentBlockValue={parentBlockValue}
          changeCursor={changeCursor}
          renderBlockValueTextInput={renderBlockValueTextInput}
          stageRef={stageRef}
          upsertPatientFlowValue={upsertPatientFlowValue}
          stageId={block.columnId}
          showPercentage={showPercentage}
          globalLayoutCompleted={globalLayoutCompleted}
        />
      ) : null}

      {/* sum difference */}
      {!isIndependent &&
        globalLayoutCompleted &&
        !!block.children.length &&
        (childrenSumDifference !== 0 ? (
          <Label x={RECT_WIDTH / 2 + 3} y={block.height + 3}>
            <Text
              fill="#E51D5B"
              id={`block-${block.id}-value`}
              text={
                (childrenSumDifference < 0 ? '' : '+') +
                // The plus symbol removes any "extra" zeroes at the end.
                +childrenSumDifference.toFixed(2) +
                (showPercentage ? '%' : '')
              }
              fontFamily="ABCFavorit"
              fontSize={12}
              lineHeight={1.25}
              fontWeight={500}
              wrap="word"
              align="right"
            />
          </Label>
        ) : (
          <Path
            x={RECT_WIDTH / 2 - 3}
            y={block.height - 6}
            data="M10.5 15L14.0355 18.5355L20.3995 12.1716"
            stroke="#078766"
          />
        ))}

      <Portal selector=".block-layer" enabled>
        <Group
          x={x}
          y={y}
          onMouseLeave={() => {
            changeCursor('default');
            handleBlockMouseLeave();
          }}
        >
          {!globalLayoutCompleted && country === 'global' ? (
            !block.default && (
              <Arrows
                block={block}
                blockHover={blockHover}
                topStage={topStage}
                changeCursor={changeCursor}
                handleBlockMouseEnter={handleBlockMouseEnter}
                setMoveBlockUpHover={setMoveBlockUpHover}
                handleUpArrowClick={handleUpArrowClick}
                moveBlockUpHover={moveBlockUpHover}
                bottomStage={bottomStage}
                setMoveBlockDownHover={setMoveBlockDownHover}
                handleDownArrowClick={handleDownArrowClick}
                moveBlockDownHover={moveBlockDownHover}
              />
            )
          ) : (
            <DistributionIcon
              onClick={() =>
                onDistributionClick({
                  title: block.text,
                  type: 'block',
                  id: block.id,
                })
              }
              xPosition={RECT_WIDTH}
              yPosition={block.height}
              changeCursor={changeCursor}
            />
          )}
          {/* Add block Icon */}
          <Group
            visible={
              !!(
                !globalLayoutCompleted &&
                (nextStageId || bottomStage) &&
                isLead &&
                country === 'global' &&
                ((block.default && block.children.length === 0) || blockHover)
              )
            }
            id={`block-${block.id}-rect-add-icon`}
            x={RECT_WIDTH / 2}
            y={block.height}
            onTap={handleAddBlockClick}
            onClick={handleAddBlockClick}
            onMouseEnter={() => {
              changeCursor('pointer');
              handleBlockMouseEnter(block.id);
              setAddHover(true);
            }}
            onMouseLeave={() => {
              changeCursor('default');
              setAddHover(false);
            }}
          >
            <Circle
              radius={12.5}
              fill={addHover ? '#F8F2FF' : colors.white}
              stroke={addHover ? '#B879FF' : colors.black20}
            />
            <Path
              x={-15}
              y={-15}
              data="M16 9H14V14H9V16H14V21H16V16H21V14H16V9Z"
              fill={addHover ? colors.purple : '#5B5B68'}
            />
          </Group>

          {/* fix mouse over events */}

          <Group
            x={5}
            y={
              block.height -
              // toggle height
              (20 +
                // gap from bottom
                8)
            }
            id={`block-${block.id}-independent-toggle`}
          >
            {showIndependentToggle && (
              <IndependentToggle
                disableHoverStates={!isLead}
                tooltip={independentToggleTooltip}
                on={block.isIndependent}
                onMouseEnter={() => {
                  if (!isLead) {
                    changeCursor('default');
                  } else {
                    changeCursor('pointer');
                  }
                  handleBlockMouseEnter(block.id);
                }}
                onMouseLeave={() => {
                  changeCursor('default');
                }}
                onClick={() => {
                  if (!isLead) {
                    return;
                  }

                  handleIndependentValueClick();
                }}
              />
            )}
          </Group>
        </Group>
      </Portal>
    </Group>
  );
};
