import { colors } from 'constants/colors';
import React from 'react';
import styled from 'styled-components';
import { ButtonPill } from './ButtonPill';
import { Icon } from './Icon';

const KeyEventsMobileButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.purple10};
  border-radius: 100px;
  padding: 5px;
  height: 40px;
  width: 40px;
  border: none;
  cursor: pointer;
  :hover {
    background: ${colors.purple15};
  }
  transition: background 0.3s ease;
`;

const KeyEventsDesktopButton = styled(ButtonPill)`
  button {
    gap: 5px;
  }
`;

type Props = {
  mobile?: boolean;
  onClick(): void;
  className?: string;
  buttonText: string;
  plusIcon?: boolean;
};

export const KeyEventsButton = ({
  mobile,
  onClick,
  className,
  buttonText = '',
  plusIcon = false,
}: Props) => {
  if (mobile) {
    return (
      <KeyEventsMobileButton onClick={onClick} className={className}>
        <Icon
          name={plusIcon ? 'CalendarPlus' : 'Calendar'}
          size={30}
          color={colors.purple}
        />
      </KeyEventsMobileButton>
    );
  }
  return (
    <KeyEventsDesktopButton
      className={className}
      iconName="Calendar"
      text={buttonText}
      level="secondary"
      onClick={onClick}
    />
  );
};
