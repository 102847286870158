import { colors } from 'constants/index';
import {
  PatientFlowValue,
  PatientFlowValueUpsertInput,
} from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import React, { useCallback, useEffect, useState } from 'react';
import { Text, Label } from 'react-konva';
import { createGlobalStyle, css } from 'styled-components';
import { BlockTree, Cursor } from 'types';
import { thousandSeparator } from 'utils/thousandSeparator';
import { BLOCK_SIDE_PADDING, RECT_WIDTH } from './constants';

const StyleValueTextArea = createGlobalStyle<{
  type: string;
  block: { id: number };
}>`
.block-textarea-span{
  font-family: ABCFavorit;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;
color: ${colors.greyDark};
}

${({ type, block }) => {
  return css`
    .block-${type}-textarea-${block.id} {
      &:before {
        content: '%';
        position: absolute;
        top: 0;
        right: 0;
        background: red;
      }
    }
  `;
}}

`;

interface Props {
  canEditValue: boolean;
  stageRef: any;
  block: BlockTree;
  patientFlowValue: PatientFlowValue | null | undefined;
  parentBlockValue: number;
  changeCursor(cursor: Cursor): void;
  renderBlockValueTextInput(
    initialValue: string,
    block: BlockTree,
    stageRef: any,
    type: string,
    update: (text: string | null) => void,
    showPercentage: boolean,
    spanValue?: string
  ): void;
  upsertPatientFlowValue: (
    id: number | undefined,
    data: Omit<PatientFlowValueUpsertInput, 'drugId' | 'strategyId' | 'region'>,
    isPercentage: boolean
  ) => Promise<void>;
  stageId: number | undefined;
  showPercentage: boolean;
  globalLayoutCompleted: boolean | undefined;
}

export const ValueText = ({
  canEditValue,
  block,
  patientFlowValue,
  parentBlockValue,
  changeCursor,
  renderBlockValueTextInput,
  stageRef,
  upsertPatientFlowValue,
  stageId,
  showPercentage,
  globalLayoutCompleted,
}: Props) => {
  const defaultBlock = block.default;
  const isDesktop = useDesktop();
  const [localValue, setLocalValue] = useState<number | string | null>('');

  useEffect(() => {
    if (defaultBlock) {
      //If no data/On created state
      if (typeof patientFlowValue?.value !== 'number') {
        setLocalValue(canEditValue ? 'Enter value' : '');
      } else {
        setLocalValue(patientFlowValue.value);
      }
    } else {
      //If no data/On created state
      if (typeof patientFlowValue?.value !== 'number') {
        setLocalValue(
          canEditValue ? (showPercentage ? 'No data' : 'Enter value') : ''
        );
      } else if (showPercentage) {
        //Percentage against parent
        setLocalValue((patientFlowValue?.value / parentBlockValue) * 100);
      } else {
        setLocalValue(patientFlowValue.value);
      }
    }
  }, [
    canEditValue,
    defaultBlock,
    parentBlockValue,
    patientFlowValue?.value,
    showPercentage,
  ]);

  const textUpdateHandler = useCallback(
    (inputValue: string | null) => {
      if (typeof stageId === 'number') {
        const value =
          showPercentage && !defaultBlock
            ? inputValue === null
              ? inputValue
              : parseInt(
                  Math.round(
                    (Number(inputValue) / 100) * parentBlockValue
                  ).toString()
                )
            : Number(inputValue);

        setLocalValue(value);
        upsertPatientFlowValue(
          patientFlowValue?.id,
          {
            columnId: stageId,
            value,
            patientFlowBlockId: block.id,
          },
          showPercentage
        );
      }
    },
    [
      block.id,
      defaultBlock,
      parentBlockValue,
      patientFlowValue?.id,
      showPercentage,
      stageId,
      upsertPatientFlowValue,
    ]
  );

  function clickHandler() {
    !showPercentage &&
      globalLayoutCompleted &&
      canEditValue &&
      renderBlockValueTextInput(
        typeof localValue === 'number'
          ? (+localValue).toString()
          : localValue?.toString() || '',
        block,
        stageRef,
        `value`,
        textUpdateHandler,
        showPercentage,
        defaultBlock ? '#' : showPercentage ? '%' : '#'
      );
  }

  return (
    // y = Block Height - bottom padding(7) - height of text element
    <Label x={BLOCK_SIDE_PADDING} y={block.height - 7 - 16}>
      <StyleValueTextArea type={'value'} block={block} />
      <Text
        id={`block-${block.id}-value`}
        text={
          typeof localValue === 'number'
            ? showPercentage && !defaultBlock
              ? thousandSeparator(+localValue.toFixed(2)) + '%'
              : thousandSeparator(localValue)
            : !isDesktop
            ? ''
            : localValue || ''
        }
        fontFamily="ABCFavorit"
        fontSize={12}
        lineHeight={1.25}
        fontWeight={500}
        fill={showPercentage ? colors.greyDark : colors.black}
        wrap="word"
        align="center"
        width={RECT_WIDTH - 2 * BLOCK_SIDE_PADDING}
        onMouseEnter={() =>
          !!isDesktop &&
          !showPercentage &&
          globalLayoutCompleted &&
          canEditValue &&
          changeCursor('text')
        }
        onMouseLeave={() =>
          !!isDesktop &&
          !showPercentage &&
          globalLayoutCompleted &&
          canEditValue &&
          changeCursor('default')
        }
        onTap={clickHandler}
        onClick={clickHandler}
      />
    </Label>
  );
};
