import { zIndex } from 'constants/index';
import { Maybe, ObservationFragment } from 'data/graphql/generated';
import React, { useEffect, useRef, useState } from 'react';
import { useDragDropManager, useDragLayer, XYCoord } from 'react-dnd';
import styled, { createGlobalStyle, css } from 'styled-components';
import { Observation } from '.';

const SetDraggingCursor = createGlobalStyle`
  * {
    cursor: grabbing !important;
  }
`;

interface Props {
  data:
    | Array<Maybe<{ __typename?: 'Observation' } & ObservationFragment>>
    | undefined;
}

const Wrapper = styled.div<{
  isDragging: boolean;
  display: 'block' | 'none';
}>`
  display: ${({ display }) => display};
  position: fixed;
  pointer-events: none;
  z-index: ${zIndex.observationsDragLayer};
  left: 0;
  top: 0;
  width: 515px;
  height: 117px;
  filter: ${({ isDragging }) =>
    isDragging
      ? 'drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.05)) drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1))'
      : 'none'};
  cursor: grabbing;
`;

const RotationWrapper = styled.div<{ isDragging: boolean }>`
  transform: rotate(0deg);
  ${({ isDragging }) => {
    if (isDragging) {
      return css`
        transform: rotate(5deg);
      `;
    }
  }};
  transition: transform 0.2s;
`;

export const ObservationDragLayer = ({ data }: Props) => {
  const WrapperRef = useRef<HTMLDivElement>(null);
  const [display, setDisplay] = useState<'block' | 'none'>('none');
  const { currentOffset, initialOffset, item, isDragging } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    })
  );

  const dragDropManager = useDragDropManager();

  const [previouslyDraggedItem, setPreviouslyDraggedItem] = useState<{
    initialOffset: XYCoord | null;
    item: any;
  }>();
  const draggedItem = useRef({ isFromInsight: false });

  useEffect(() => {
    if (isDragging) {
      setDisplay('block');
      setPreviouslyDraggedItem({
        initialOffset,
        item,
      });
    }

    function mouseUpHandler(event: MouseEvent) {
      draggedItem.current.isFromInsight && setDisplay('none');
    }

    if (isDragging) {
      const itemSource = dragDropManager.getMonitor().getItem()?.source;

      //if dragging an item, check what the source is
      //Only replace the source if we have a new itemSource
      if (!!itemSource) {
        draggedItem.current.isFromInsight = itemSource === 'Insight';
      }

      document.removeEventListener('mouseup', mouseUpHandler);
      document.addEventListener('mouseup', mouseUpHandler, { once: true });
    }

    //Makes it vanish 0.4s after being dropped. This is based on the time it takes DraggableObservation to appear after a drop
    //This prevents it from staying on the screen after the DraggableObservation has been displayed
    let displayTimeout: NodeJS.Timeout;

    if (!isDragging && !draggedItem.current.isFromInsight) {
      displayTimeout = setTimeout(() => setDisplay('none'), 400);
    }

    return () => {
      clearTimeout(displayTimeout);
    };
  }, [isDragging, initialOffset, item, dragDropManager, currentOffset]);

  const filteredData = data?.find(
    (observation) =>
      observation?.id ===
      (isDragging ? item?.id : previouslyDraggedItem?.item?.id)
  );

  if (!filteredData || !previouslyDraggedItem?.initialOffset) {
    return null;
  }

  let { x: currentX, y: currentY } =
    currentOffset || previouslyDraggedItem?.initialOffset;

  return (
    <Wrapper
      display={display}
      ref={WrapperRef}
      isDragging={isDragging}
      style={{
        transform: `${`translate(${currentX}px,${currentY}px)`}`,
        transition: `${isDragging ? 'all 0s' : 'all 0.2s'}`,
      }}
    >
      <RotationWrapper isDragging={isDragging}>
        <Observation readonly data={filteredData} />
      </RotationWrapper>
      {isDragging && <SetDraggingCursor />}
    </Wrapper>
  );
};
