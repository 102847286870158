import { BodySmall } from 'components/shared';
import { colors } from 'constants/colors';
import { useWidth } from 'hooks/useWidth';
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import { thousandSeparator } from 'utils/thousandSeparator';

const Wrapper = styled.div`
  padding: 1px;
`;

const BarBorder = styled.div<{ widthPercentage: number }>`
  height: 30px;
  background: ${colors.purplePinkGradient};
  border-radius: 0 5px 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${({ widthPercentage }) => widthPercentage}%;
  min-width: 5px;
`;

const Bar = styled.div`
  height: calc(100% - 2.2px);
  background: linear-gradient(
    48deg,
    rgba(244, 246, 254, 1) 0%,
    rgba(244, 247, 254, 1) 25%,
    rgba(246, 247, 254, 1) 50%,
    rgba(253, 243, 252, 1) 75%,
    rgba(254, 248, 253, 1) 100%
  );
  border-radius: 0 4px 4px 0;
  width: calc(100% - 2.2px);
`;

const Value = styled(BodySmall)<{ positionInside: boolean }>`
  position: absolute;
  right: 0;
  transform: ${({ positionInside }) =>
    `translateX(${positionInside ? '0' : '100'}%)`};
  padding: 5px;
`;

interface Props {
  value: number;
  maxValue: number;
  currency?: string;
}

export const BudgetBar: React.FC<Props> = ({ value, currency, maxValue }) => {
  const [valuePositionedInside, setValuePositionedInside] = useState(false);
  const barWrapperRef = useRef<HTMLDivElement | null>(null);
  const barBorderRef = useRef<HTMLDivElement | null>(null);
  const valueRef = useRef<HTMLDivElement | null>(null);
  const parsedValue = thousandSeparator(value);
  const text = currency ? `${currency[0] + parsedValue}` : parsedValue;
  const percentageOfMax = (value / maxValue) * 100;
  const width = useWidth();

  useEffect(() => {
    // Position the value inside of the bar if it would otherwise overflow the container
    const wrapper = barWrapperRef.current;
    const barBorder = barBorderRef.current;
    const value = valueRef.current;

    if (!wrapper || !barBorder || !value) return;

    if (
      barBorder.offsetWidth + value.offsetWidth > wrapper.offsetWidth &&
      !(value.offsetWidth > barBorder.offsetWidth)
    ) {
      setValuePositionedInside(true);
    } else {
      setValuePositionedInside(false);
    }
  }, [value, maxValue, width]);

  return (
    <Wrapper ref={barWrapperRef}>
      <BarBorder ref={barBorderRef} widthPercentage={percentageOfMax}>
        <Bar>
          <Value ref={valueRef} positionInside={valuePositionedInside}>
            {text}
          </Value>
        </Bar>
      </BarBorder>
    </Wrapper>
  );
};
