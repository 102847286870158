import styled from 'styled-components/macro';
import { uid } from 'uid';
import { CheckCircle, CheckCircleWrapper } from 'components/shared/CheckCircle';
import { BodySmall, Tooltip } from 'components/shared';
import { useAuthContext } from 'contexts/AuthContext';
import { Role } from 'data/graphql/generated';

export const FocusButtonContainer = styled.div`
  display: flex;
  align-items: center;

  ${CheckCircleWrapper} {
    margin-right: 5px;
  }
`;

interface FocusButtonProps {
  onClick(e: React.MouseEvent<HTMLDivElement>): void;
  disabled?: boolean;
  checked: boolean;
  title?: string;
  disabledTooltipText?: string;
  localTactic?: boolean;
  region?: string;
}

export const FocusButton = ({
  onClick,
  disabled,
  checked,
  title = 'Focus',
  disabledTooltipText,
  localTactic,
  region,
}: FocusButtonProps) => {
  const key = uid();
  const [{ user }] = useAuthContext();
  const isContributor = user?.role === Role.Contributor;

  return (
    <FocusButtonContainer
      data-offset="{ 'top': -10 }"
      data-for={key}
      data-tip={disabledTooltipText || 'Only Leads can edit'}
    >
      <CheckCircle
        complete={checked}
        className={`cypress-option-focus cypress-option-focus-${!!checked}`}
        onClick={onClick}
        isDisabled={disabled === false ? false : disabled || isContributor}
        localTactic={localTactic}
        region={region}
      />
      <BodySmall>{title}</BodySmall>
      {disabled && <Tooltip effect="solid" id={key} />}
    </FocusButtonContainer>
  );
};
