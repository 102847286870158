import { BodySmall, TooltipWrapper } from 'components/shared';
import { colors } from 'constants/colors';
import { CompetitorSimpleFragment, SubStep } from 'data/graphql/generated';
import React from 'react';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';

const StyledBodySmall = styled(BodySmall)`
  display: inline;

  word-break: break-word;
  hyphens: auto;
  border-radius: 5px;

  -webkit-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  box-decoration-break: clone;
`;

export const Wrapper = styled.div`
  padding: 15px;
  margin: 0 15px 15px 15px;

  background: ${colors.white};
  border-radius: 5px;
  border: 1px solid ${colors.black10};
  order: 1;
  height: fit-content;
  min-width: 315px;

  @media ${device.desktopMin} {
    padding: 30px;
    width: 315px;
  }
`;

const BulletPointList = styled.ul<{ allowChange: boolean }>`
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: fit-content;
  cursor: ${({ allowChange }) => (!allowChange ? 'not-allowed' : 'pointer')};

  .bullet-point-with-text:first-child > .bullet-point-text {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .bullet-point-with-text:last-child > .bullet-point-text {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  ${BodySmall} {
    background: ${colors.purple10};
  }
`;

function getTitleText(substep?: SubStep, competitorTitle?: String) {
  switch (substep) {
    case 'competitorPositioning':
      return `Space occupied by ${competitorTitle || 'competitor'}`;
  }
}

function getPlaceholder(substep?: SubStep) {
  switch (substep) {
    case 'competitorPositioning':
      return 'A strong brand association';
    default:
      return '';
  }
}

interface Props {
  onClick(): void;
  allowChange: boolean;
  substep?: SubStep;
  selectedCompetitor?: CompetitorSimpleFragment | null | undefined;
}
export const Statement: React.FC<Props> = ({
  onClick,
  allowChange,
  substep,
  selectedCompetitor,
}) => {
  const brandAssociations = selectedCompetitor?.brandAssociations.filter(
    (v) => !!v
  );
  const noAssociations = !brandAssociations?.length;

  return (
    <Wrapper onClick={() => (allowChange ? onClick() : null)}>
      <TooltipWrapper
        text={allowChange ? '' : 'Only Leads can edit'}
        effect="float"
        place="bottom"
      >
        <BodySmall color={colors.greyDark} style={{ marginBottom: 5 }}>
          {getTitleText(substep, selectedCompetitor?.title)}
        </BodySmall>

        {substep === SubStep.CompetitorPositioning &&
          (noAssociations ? (
            <BulletPointList allowChange={allowChange}>
              <BulletPointWithText
                textStyle={{
                  background: colors.purple10,
                  borderRadius: 5,
                  padding: '0 2px',
                }}
                text={getPlaceholder(substep)}
                color={colors.greyDark}
              />
            </BulletPointList>
          ) : (
            <BulletPointList allowChange={allowChange}>
              {brandAssociations?.map((text, idx) => {
                return (
                  <BulletPointWithText
                    key={idx}
                    text={text || 'A strong brand association'}
                    textStyle={{ flexGrow: 1 }}
                    color={colors.black}
                  />
                );
              })}
            </BulletPointList>
          ))}
      </TooltipWrapper>
    </Wrapper>
  );
};

export const BulletPointWithText: React.FC<{
  text: string;
  textStyle?: React.CSSProperties;
  color: string;
  className?: string;
  onClick?(): void;
  numbered?: boolean;
  idx?: number;
}> = ({
  text,
  textStyle,
  color,
  className,
  onClick = () => {},
  numbered,
  idx,
}) => (
  <div
    data-cy="bullet-point-with-text"
    className={(className || '') + ' bullet-point-with-text'}
    style={{ display: 'flex', alignItems: 'baseline', width: '100%' }}
    onClick={onClick}
  >
    {numbered && typeof idx === 'number' ? (
      <>
        <StyledBodySmall
          style={{
            marginRight: 5,
            wordBreak: 'normal',
            width: 16,
            flexShrink: 0,
            display: 'block',
          }}
          color={colors.greyDark}
        >
          {idx}.
        </StyledBodySmall>
      </>
    ) : (
      <GreyBulletPoint color={color} />
    )}
    <StyledBodySmall
      className="bullet-point-text"
      color={color}
      style={{
        ...textStyle,
      }}
    >
      {text}
    </StyledBodySmall>
  </div>
);

const GreyBulletPoint: React.FC<{ color: string }> = ({ color }) => (
  <div
    style={{
      color,
      display: 'inline',
      marginRight: '8px',
      marginLeft: '9px',
      fontSize: 10,
    }}
  >
    •
  </div>
);
