import { removeByClassName } from 'components/PatientJourney/src/lib/utils/removeByClassName';
import { PatientFlowBlock, PatientJourneyColumn } from 'data/graphql/generated';
import { Arrow } from 'konva/types/shapes/Arrow';
import { Text } from 'konva/types/shapes/Text';
import { Stage } from 'konva/types/Stage';
import { Collection } from 'konva/types/Util';
import { cloneDeep } from 'lodash';
import { BlockTree } from 'types';
import { handleClickOutside } from 'utils/handleClickOutside';
import { calcStageHeights } from '..';
import { positionConnections } from '../Connection';
import {
  BLOCK_BORDER_WIDTH,
  BLOCK_PADDING_BOTTOM,
  BLOCK_PADDING_TOP,
  BLOCK_SIDE_PADDING,
  NAV_HEIGHT,
  RECT_WIDTH,
} from '../constants';
import { BlockProps } from './types';

export const textAreaWidth =
  RECT_WIDTH - BLOCK_SIDE_PADDING * 2 - BLOCK_BORDER_WIDTH * 2;

export function renderTextArea(
  block: BlockTree,
  stageRef: React.RefObject<Stage>,
  patientFlowBlockUpdate: (
    id: number,
    patientFlowBlock: Partial<PatientFlowBlock>
  ) => void,
  setConfirmDeleteModalId: (id: number) => void,
  setDeleteCardVisible: (input: boolean) => void,
  setEditing: (val: number | undefined) => void,
  column: PatientJourneyColumn,
  allBlocks: BlockProps['allBlocks'],
  calculateBlockPositions: BlockProps['calculateBlockPositions'],
  blockTreeForAllBlocks: BlockProps['blockTreeForAllBlocks'],
  leftOffset: BlockProps['leftOffset']
) {
  function removeElements() {
    removeByClassName('block-textarea');
    removeByClassName('block-delete');
  }
  removeElements();

  if (!stageRef.current) return;

  const textNode = stageRef.current.findOne(
    `#block-${block.id}-text`
  ) as Text | null;

  if (!textNode) return;

  const textPosition = textNode.getAbsolutePosition();
  const stageBox = stageRef.current.container().getBoundingClientRect();

  const areaPosition = {
    x: stageBox.left + textPosition.x,
    y: NAV_HEIGHT() + textPosition.y + 1,
  };

  textNode.hide();

  // create textarea and style it
  const textarea = document.createElement('textarea');
  document.body.appendChild(textarea);

  textarea.value = textNode.text();
  textarea.maxLength = 200;
  textarea.className = `block-textarea block-textarea-${block.id}`;
  textarea.style.top = areaPosition.y + 'px';
  textarea.style.left = areaPosition.x - 1 + 'px';
  textarea.style.padding = '0';
  textarea.style.lineHeight = '19px';
  textarea.style.fontFamily = 'sans-serif';

  textarea.style.height = `${textNode.height()}px`;
  textarea.style.width = textAreaWidth + 'px';
  textarea.style.maxWidth = textAreaWidth + 'px';
  textarea.style.minWidth = textAreaWidth + 'px';

  const isDesktop = window.innerWidth >= 1440;

  if (isDesktop) {
    textarea.select();
  } else {
    const end = textarea.value.length;
    textarea.setSelectionRange(end, end);
  }

  // with scroll, the position of a newly created block would be incorrect
  textarea.focus({ preventScroll: true });

  textarea.addEventListener('keydown', (e) => {
    // stop enter key from breaking layout
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  });

  const setRectHeightHandler = () =>
    setRectHeight(
      stageRef,
      textNode,
      block,
      column,
      allBlocks,
      calculateBlockPositions,
      blockTreeForAllBlocks,
      leftOffset
    );

  textarea.addEventListener('keyup', (e) => {
    // hide on enter
    if (e.keyCode === 13) {
      textarea.style.height = textarea.scrollHeight + 'px';
      updateTextNode(textNode, textarea.value);
      setRectHeightHandler();
      saveText(textNode);
    }
  });

  textarea.addEventListener('input', () => {
    // resize textarea as user pastes text
    textarea.style.height = textarea.scrollHeight + 'px';
    updateTextNode(textNode, textarea.value);
    setRectHeightHandler();
  });

  function saveAndHide() {
    if (!textNode) return;
    saveText(textNode);
    removeTextarea(textNode);
  }

  handleClickOutside(textarea, saveAndHide, false, ['block-delete']);

  function saveText(textNode: Text) {
    if (!stageRef.current) return;
    if (!textarea.value || textarea.value === block.text) {
      // reset to default
      textNode.text(block.text);
      removeTextarea(textNode);
      setRectHeightHandler();
    } else {
      const rectNode = stageRef.current.findOne(
        `#block-${block.id}-rect-gradient`
      );
      textNode.text(textarea.value);
      removeTextarea(textNode);
      patientFlowBlockUpdate(block.id, {
        height: Math.round(rectNode.height()),
        text: textarea.value.trim(),
      });
    }
  }

  // create delete btn
  function displayDeleteButton(textNode: Text) {
    const deleteBtn = document.createElement('div');
    document.body.appendChild(deleteBtn);
    deleteBtn.className = `block-delete block-delete-${block.id}`;
    deleteBtn.style.top = areaPosition.y - 50 + 'px';
    deleteBtn.style.left = areaPosition.x - 20 + 'px';
    deleteBtn.addEventListener('click', () => {
      if (block.children.length > 0) {
        // display the child delete warning modal
        setConfirmDeleteModalId(block.id);
      } else {
        // display the normal delete warning card
        setDeleteCardVisible(true);
      }

      removeTextarea(textNode);
    });
  }

  if (!block.default) displayDeleteButton(textNode);

  function updateTextNode(textNode: Text, text: string) {
    textNode.text(text);
  }

  function removeTextarea(textNode: Text) {
    removeElements();
    setEditing(undefined);
    // remove focus on close
    textNode.show();
  }
}

function setRectHeight(
  stageRef: React.RefObject<Stage>,
  textNode: Text,
  block: BlockTree,
  column: PatientJourneyColumn,
  allBlocks: BlockProps['allBlocks'],
  calculateBlockPositions: BlockProps['calculateBlockPositions'],
  blockTreeForAllBlocks: BlockProps['blockTreeForAllBlocks'],
  leftOffset: BlockProps['leftOffset']
) {
  if (!textNode || !stageRef.current) return;
  const rectNode = stageRef.current.findOne(`#block-${block.id}-rect`);
  const rectGradientNode = stageRef.current?.findOne(
    `#block-${block.id}-rect-gradient`
  );

  const rectAddIcon = stageRef.current?.findOne(
    `#block-${block.id}-rect-add-icon`
  );
  const rectIndependentToggle = stageRef.current?.findOne(
    `#block-${block.id}-independent-toggle`
  );

  const rectDownArrow = stageRef.current?.findOne(
    `#block-${block.id}-rect-down-arrow`
  );

  if (!rectNode || !rectGradientNode) {
    return;
  }

  const newRectHeight =
    BLOCK_PADDING_TOP + textNode.height() + BLOCK_PADDING_BOTTOM;

  rectNode.height(Math.max(newRectHeight - BLOCK_BORDER_WIDTH * 2, 0));
  rectGradientNode.height(newRectHeight);
  rectAddIcon?.y(newRectHeight);
  rectDownArrow?.y(newRectHeight + 25);
  rectIndependentToggle?.y(
    newRectHeight - // toggle height
      (20 +
        // gap from bottom
        8)
  );
  // update stage height
  const stageBgNode = stageRef.current.findOne(`#stage-${column.idx}-bg`);

  const allBlocksCopy = cloneDeep(allBlocks);

  // update the height of the current block in the data to use in the calcStageHeights function to calculate the stage height
  const blockIndex = allBlocksCopy.findIndex(
    (blockInStage) => blockInStage.id === block.id
  );
  allBlocksCopy[blockIndex].height = newRectHeight;

  const stageNewHeight = calcStageHeights(allBlocksCopy, [column])[0];
  stageBgNode.height(stageNewHeight);

  const blockTreeForAllBlocksCopy = cloneDeep(blockTreeForAllBlocks);

  let prevStageHeight = 0;
  // update starting position of other stages
  for (let i = 0; i < 5; i++) {
    const currentStage = i === column.idx;

    const prevStageBgNode = stageRef.current.findOne(
      `#stage-${Math.max(0, i)}-bg`
    );

    let stageYStartingPosition = prevStageHeight;

    if (currentStage) {
      prevStageHeight += stageNewHeight;
    } else {
      prevStageHeight += prevStageBgNode.height();
    }

    const stageNode = stageRef.current.findOne(`#stage-${i}`);

    // only move the position of stages that include or come after the current stage containing the editing block
    if (i >= column.idx) {
      // update stage y position
      stageNode.position({ y: stageYStartingPosition, x: 0 });
    }

    // update block positions for blocks in current stage
    const blockTreeForAllBlocksCopyFilter = blockTreeForAllBlocksCopy.filter(
      (v) => v.columnId === stageNode.attrs.dataId
    );

    const indexOfBlockInTree = blockTreeForAllBlocksCopyFilter.findIndex(
      (blockInStage) => blockInStage.id === block.id
    );

    if (indexOfBlockInTree >= 0) {
      //Update this blocks height in array
      blockTreeForAllBlocksCopyFilter[
        indexOfBlockInTree
      ].height = newRectHeight;
    }

    for (const stageBlock of blockTreeForAllBlocksCopyFilter) {
      const blockY = calculateBlockPositions(
        stageYStartingPosition,
        stageBlock,
        allBlocksCopy
      );

      // Only update the position for blocks in the editing blocks stages and later
      if (i >= column.idx) {
        const blockNode = stageRef.current.findOne(
          `#flowblock-${stageBlock.id}`
        );
        const pos = blockNode.position();
        blockNode.position({ y: blockY, x: pos.x });
      }
    }
  }

  for (let i = 0; i < 5; i++) {
    // update connections

    const stageConnections = stageRef.current.find(
      `.connection-col-${i}`
    ) as Collection<Arrow> | null;
    if (!stageConnections) return;

    const stageConnectionsArr = Array.from(stageConnections) as Arrow[];

    for (const connectionNode of stageConnectionsArr) {
      const points = positionConnections(
        stageRef,
        connectionNode.attrs.parentId,
        connectionNode.attrs.childId,
        leftOffset
      );
      if (points) {
        connectionNode.setAttr('points', points);
      }
    }
  }
  stageRef.current.batchDraw();
}
