import { PatientJourneyContainerType } from 'components/PatientJourney/src/lib/types';
import {
  calcColumnStartingPoints,
  calcColumnTotalWidth,
} from 'components/PatientJourney/src/lib/utils';
import {
  usePatientJourneyBlockCreateMutation,
  usePatientJourneyBlockUpdateMutation,
  usePatientJourneyConnectionCreateMutation,
  usePatientJourneyConnectionDeleteMutation,
  usePatientJourneyConnectionUpdateMutation,
  usePatientJourneyColumnUpdateMutation,
  usePatientJourneyUpdateManyMutation,
  usePatientJourneyDeleteManyMutation,
  PatientJourneyDocument,
  PatientJourneyBlockFragmentDoc,
  PatientJourneyQuery,
  PatientJourneyQueryVariables,
  PatientJourneyState,
} from 'data/graphql/generated';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type Props = {
  pageState: PatientJourneyState;
  patientJourneyQueryVars: PatientJourneyQueryVariables;
  data: PatientJourneyQuery | undefined;
  onUpdateBlockError?(err: unknown): void;
  onDeleteBlockError?(err: unknown): void;
};

const usePatientJourney = ({
  patientJourneyQueryVars,
  data,
  pageState,
  onUpdateBlockError,
  onDeleteBlockError,
}: Props) => {
  const { drugId, strategyId } = useParams<{
    drugId: string;
    strategyId: string;
  }>();

  const [disableActions, setDisableActions] = useState(false);

  const [blockCreate] = usePatientJourneyBlockCreateMutation();
  const [blockUpdate] = usePatientJourneyBlockUpdateMutation();

  const [connectionCreate] = usePatientJourneyConnectionCreateMutation();
  const [connectionDelete] = usePatientJourneyConnectionDeleteMutation();
  const [connectionUpdate] = usePatientJourneyConnectionUpdateMutation();

  const [columnUpdate] = usePatientJourneyColumnUpdateMutation();

  const [updatePatientJourney] = usePatientJourneyUpdateManyMutation();
  const [deleteManyPatientJourney] = usePatientJourneyDeleteManyMutation();

  const createBlock: PatientJourneyContainerType['createBlock'] = async (
    block
  ) => {
    setDisableActions(true);
    const updatedBlock = await blockCreate({
      variables: {
        data: {
          ...block,
          strategy: Number(strategyId),
          column: Number(block.column),
          drug: Number(drugId),
          pageState,
        },
      },
      refetchQueries: [
        {
          query: PatientJourneyDocument,
          variables: patientJourneyQueryVars,
        },
      ],
    });
    setDisableActions(false);
    return updatedBlock;
  };

  const updateBlock: PatientJourneyContainerType['updateBlock'] = async (
    block,
    updateData
  ) => {
    if (!block) return;

    try {
      return await blockUpdate({
        variables: {
          id: block.id,
          data: {
            ...updateData,
          },
        },
        optimisticResponse: {
          patientJourneyBlockUpdate: {
            ...block,
            ...updateData,
          },
        },

        update: (cache, { data }) => {
          if (!data) {
            return;
          }

          cache.updateFragment(
            {
              id: `PatientJourneyBlock:${block.id}`,
              fragment: PatientJourneyBlockFragmentDoc,
              fragmentName: 'patientJourneyBlock',
            },
            (data) => {
              return {
                ...data,
              };
            }
          );
        },
        refetchQueries: [
          {
            query: PatientJourneyDocument,
            variables: patientJourneyQueryVars,
          },
        ],
      });
    } catch (err) {
      console.error(err);
      onUpdateBlockError?.(err);
      if (err instanceof Error) {
        throw new Error(err.message);
      }
    }
  };

  async function deleteMany({
    blockIds,
    connectionIds,
  }: {
    blockIds: number[];
    connectionIds: number[];
  }) {
    setDisableActions(true);
    try {
      await deleteManyPatientJourney({
        variables: {
          data: {
            blocks: blockIds,
            connections: connectionIds,
          },
        },
        refetchQueries: [
          {
            query: PatientJourneyDocument,
            variables: patientJourneyQueryVars,
          },
        ],
      });
      setDisableActions(false);
    } catch (err) {
      setDisableActions(false);
      console.error(err);
      onDeleteBlockError?.(err);
    }
  }

  const removeBlock: PatientJourneyContainerType['removeBlock'] = async (
    blockId
  ) => {
    setDisableActions(true);
    if (!blockId) {
      console.error('no block id');
      setDisableActions(false);
      return;
    }
    try {
      // find blocks connections
      const connectionIds =
        (data?.patientJourneyConnections?.items
          .filter((c) => {
            return c?.fromBlockId === blockId || c?.toBlockId === blockId;
          })
          .map((c) => c?.id)
          .filter(Boolean) as number[]) || [];

      // delete block
      await deleteMany({
        blockIds: [blockId],
        connectionIds,
      });
    } catch (err) {
      console.error(err);
      onDeleteBlockError?.(err);
    }
    setDisableActions(false);
  };

  // connections
  const createConnection: PatientJourneyContainerType['createConnection'] = async (
    value
  ) => {
    setDisableActions(true);
    const connection = await connectionCreate({
      variables: {
        data: {
          ...value,
          strategy: Number(strategyId),
        },
      },
      refetchQueries: [
        {
          query: PatientJourneyDocument,
          variables: patientJourneyQueryVars,
        },
      ],
    });
    setDisableActions(false);
    return connection;
  };

  const updateConnection: PatientJourneyContainerType['updateConnection'] = async (
    value
  ) => {
    setDisableActions(true);
    const data = {
      toBlockId: value.toBlockId,
      toSide: value.toSide,
      fromBlockId: value.fromBlockId,
      fromSide: value.fromSide,
      path: value?.path?.map((p) => Math.round(p)),
    };

    try {
      await connectionUpdate({
        variables: {
          id: Number(value.id),
          data,
        },
        refetchQueries: [
          {
            query: PatientJourneyDocument,
            variables: patientJourneyQueryVars,
          },
        ],
      });
    } catch (err) {
      console.error(err);
    }
    setDisableActions(false);
  };

  const removeConnection: PatientJourneyContainerType['removeConnection'] = async (
    valueId
  ) => {
    setDisableActions(true);
    if (!valueId) {
      console.error('no connection id');
      setDisableActions(false);
      return;
    }
    try {
      await connectionDelete({
        variables: {
          id: Number(valueId),
        },
        refetchQueries: [
          {
            query: PatientJourneyDocument,
            variables: patientJourneyQueryVars,
          },
        ],
      });
    } catch (err) {
      console.error(err);
    }
    setDisableActions(false);
  };

  // columns
  const updateColumn: PatientJourneyContainerType['updateColumn'] = async (
    value
  ) => {
    const data = {
      name: value.name,
      idx: value.idx,
      width: value.width,
    };

    try {
      await columnUpdate({
        variables: {
          id: Number(value.id),
          data,
        },
        refetchQueries: [
          {
            query: PatientJourneyDocument,
            variables: patientJourneyQueryVars,
          },
        ],
      });
    } catch (err) {
      console.error(err);
    }
  };

  const updateMany: PatientJourneyContainerType['updateMany'] = async ({
    blocks,
    columns,
    connections,
  }) => {
    setDisableActions(true);
    const data = {
      variables: {
        data: {
          state: pageState,
          blocks: blocks.map((b) => {
            return {
              id: b.id,
              text: b.text,
              x: Math.trunc(b.x),
              y: Math.trunc(b.y),
              height: Math.trunc(b.height),
              columnId: b.columnId,
            };
          }),
          columns: columns.map((c) => {
            return {
              id: c.id,
              name: c.name,
              idx: c.idx,
              width: Math.trunc(c.width),
            };
          }),
          connections: connections.map((c) => {
            return {
              id: c.id,
              path: c.path.map((p) => Math.round(p)),
            };
          }),
        },
      },
      refetchQueries: [
        {
          query: PatientJourneyDocument,
          variables: patientJourneyQueryVars,
        },
      ],
    };

    try {
      await updatePatientJourney(data);
      setDisableActions(false);
    } catch (err) {
      setDisableActions(false);
      console.error(err);
      onUpdateBlockError?.(err);
      if (err instanceof Error) {
        throw new Error(err.message);
      }
    }
  };

  const [columnStartingPoints, setColumnStartingPoints] = useState(
    calcColumnStartingPoints(data?.patientJourneyColumns?.items || [])
  );

  const [columnTotalWidth, setColumnTotalWidth] = useState(
    calcColumnTotalWidth(data?.patientJourneyColumns?.items || [])
  );

  // updates on columns changes
  useEffect(() => {
    setColumnTotalWidth(
      calcColumnTotalWidth(data?.patientJourneyColumns?.items || [])
    );
    setColumnStartingPoints(
      calcColumnStartingPoints(data?.patientJourneyColumns?.items || [])
    );
  }, [data?.patientJourneyColumns?.items]);

  return {
    setDisableActions,
    disableActions,
    block: {
      createBlock,
      updateBlock,
      removeBlock,
    },
    connection: {
      createConnection,
      updateConnection,
      removeConnection,
    },
    column: {
      updateColumn,
      columnTotalWidth,
      columnStartingPoints,
    },
    updateMany,
  };
};

export default usePatientJourney;
