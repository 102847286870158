import styled from 'styled-components/macro';

import { CriticalMetricGoalFragment } from 'data/graphql/generated';
import { BodySmall, CountryFlag } from 'components/shared';
import { colors } from 'constants/index';
import { getUserTitleOrCountryAndName } from 'utils/getUserTitleOrCountryAndName';
import { FocusIcon } from './CollaborationFocusIcon';
import { CollaborationCardField } from './CollaborationCardField';
import {
  convertGoalInputEnumToText,
  convertGoalFrequencyEnumToText,
  convertNumberToPercentage,
} from '../../../../../shared/shared-utils-critical-metrics/src';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const WhiteCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 10px 15px;
  border-radius: 5px;
  background: ${colors.white};
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: auto;
`;

const ADDED_BY_TEXT = 'Added by';

type CollaborationCriticalMetricsGoalProps = CriticalMetricGoalFragment;

export const CollaborationCriticalMetricsGoal = ({
  goal,
  target,
  frequency,
  input,
  isFocused,
  user,
}: CollaborationCriticalMetricsGoalProps) => {
  return (
    <Container>
      <WhiteCardContainer>
        <FocusIcon isFocused={isFocused} />
        <CollaborationCardField title="Goal" text={goal} />
        <CollaborationCardField
          title="Target %"
          text={convertNumberToPercentage(target)}
        />
        <CollaborationCardField
          title="Frequency"
          text={convertGoalFrequencyEnumToText(frequency)}
        />
        <CollaborationCardField
          title="Input"
          text={convertGoalInputEnumToText(input)}
        />
      </WhiteCardContainer>
      <Footer>
        <BodySmall color={colors.greyDark}>{ADDED_BY_TEXT}</BodySmall>
        <CountryFlag size="sm" user={user} disableTooltip />
        <BodySmall>{getUserTitleOrCountryAndName(user)}</BodySmall>
      </Footer>
    </Container>
  );
};
