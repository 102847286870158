import { colors } from 'constants/colors';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import ReactDOM from 'react-dom';
import { zIndex } from 'constants/zIndex';
import {
  BodyNormal,
  Caption,
  Heading3,
  Icon,
  Subtitle2,
  Toggle,
} from 'components/shared';
import useMobile from 'hooks/useMobile';
import { device } from 'utils/breakpoints';
import { KeyInsight } from 'data/graphql/generated';
import { IconContainer } from 'components/shared/Icon';
import waveSVG from 'assets/icons/BubbleSummaryWave.svg';
import { BubbleSummaryOneContent } from './BubbleSummaryOneContent';
import { BubbleSummaryTwoContent } from './BubbleSummaryTwoContent';
import { ToggleWrapper } from 'components/shared/Toggle';
import useDesktop from 'hooks/useDesktop';
import useTablet from 'hooks/useTablet';
import { BubbleSummaryThreeContent } from './BubbleSummaryThreeContent';
import { summaryScrollbar } from './scrollbar';
import { ScrollableFadeContainer } from 'components/shared/ScrollableFadeContainer';
import { BubbleSummaryFourContent } from './BubbleSummaryFourContent';
import { useWidth } from 'hooks/useWidth';
import { DownloadButtonWrapper as DownloadButtonComponent } from '../shared/DownloadButton';

const Wrapper = styled.div`
  position: fixed;
  top: 65px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  z-index: ${zIndex.bubbleSummary};

  @media ${device.tabletMin} {
    left: 15px;
    right: 15px;
  }

  @media ${device.desktopMin} {
    top: 70px;
    left: 20px;
    right: 20px;
    bottom: 20px;
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndex.bubbleSummaryBG};
`;

const Container = styled.div<{ bubble: number }>`
  height: 100%;
  border: 5px solid ${colors.white};
  border-radius: 10px;
  background: ${colors.bubbleSummaryGradient};
  overflow: hidden;
  overflow-y: ${({ bubble }) => bubble === 3 && 'scroll'};
  padding: 15px 0;
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media ${device.tabletMin} {
    padding-bottom: ${({ bubble }) => bubble === 3 && '0'};
  }
`;

const Header = styled.header`
  display: flex;
  gap: 15px;
  justify-content: space-between;
  margin-bottom: 15px;
  z-index: 5;
  padding: 0 15px;
`;

const HeaderIcon = styled(Icon)`
  height: 30px;
  background: ${colors.cream};
  border-radius: 50%;
  color: ${colors.black};
  cursor: pointer;
  z-index: 5;
`;

const Heading = styled(Heading3)<{ bubble: number }>`
  color: ${colors.white};
  text-align: center;
  line-height: 45px;
  margin-top: 15px;
  margin-bottom: ${({ bubble }) => bubble === 3 && '15px'};

  @media ${device.tabletMin} {
    margin-bottom: ${({ bubble }) => bubble === 3 && '0'};
    margin-top: 0;
  }
`;

const Content = styled.section<{ bubble: number; canScroll: boolean }>`
  display: flex;
  justify-content: flex-start;
  flex-direction: ${({ bubble }) => bubble === 3 && 'column'};
  gap: ${({ bubble }) => (bubble === 3 ? '5px' : '15px')};
  position: ${({ bubble }) => bubble !== 3 && 'absolute'};
  bottom: 5px;
  top: ${({ bubble }) => (bubble === 3 ? '120px' : '165px')};
  right: 0;
  overflow-y: hidden;
  padding: 0 15px;
  z-index: 1;
  width: 100%;

  ${summaryScrollbar}

  @media ${device.tabletMin} {
    top: ${({ bubble }) => (bubble === 2 ? `` : '120px')};
    gap: ${({ bubble }) => bubble === 3 && '15px'};
    margin-right: ${({ bubble }) => bubble === 3 && '-15px'};
    padding: ${({ bubble }) => (bubble === 1 || bubble === 2 ? `0 15px` : '0')};
  }

  @media (min-width: 575px) {
    top: ${({ bubble }) => bubble === 2 && '120px'};
  }

  @media (min-width: 630px) {
    top: ${({ bubble }) => (bubble === 2 ? `` : '75px')};
  }

  @media ${device.desktopMin} {
    top: 80px;
  }

  @media (min-width: 1440px) {
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
  }

  ${({ bubble }) => {
    return (
      bubble === 4 &&
      css`
        overflow: hidden;
        @media ${device.mobile} {
          padding: 0;
          height: calc(100% - 164px);
        }
      `
    );
  }}
`;

const BubbleThreeContent = styled.section<{
  canScroll: boolean;
}>`
  display: flex;
  justify-content: ${({ canScroll }) => {
    return canScroll ? 'flex-start' : 'center';
  }};
  flex-direction: column;
  gap: 5px;
  overflow-y: hidden;
  padding: 0 15px;
  z-index: 1;
  width: fit-content;
  min-width: 480px;
  max-width: 100%;
  margin: auto;

  ${summaryScrollbar}

  @media ${device.tabletMin} {
    gap: 15px;
    padding: 0;
  }

  @media (min-width: 1440px) {
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 5px 5px;
  height: fit-content;
  width: 480px;
  min-width: 260px;
  background: ${colors.white94};
  border: 1px solid ${colors.greyMedium};
  border-radius: 5px;
  max-height: calc(100% - 5px);
  cursor: text;
`;

export const ColumnTitle = styled.div`
  align-self: center;
  text-align: center;
  margin-bottom: 17.5px;
`;

const StakeholderSection = styled.section`
  width: 100%;
`;

export const LeveragePointIndicator = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;

  ${IconContainer} {
    margin-top: -3px;
    margin-left: -3px;

    svg {
      height: 20px;
    }
  }
`;

const TitleAndIcon = styled.div`
  display: flex;
  gap: 3px;
  margin-bottom: 7.5px;
`;

const StakeholderTitle = styled(Subtitle2)`
  align-self: center;
  color: ${colors.greyDark};
`;

export const EmptyStateText = styled(StakeholderTitle)`
  margin-bottom: 10px;
`;

export const BubbleTwoScrollableContent = styled(ScrollableFadeContainer)`
  gap: 30px;
`;

const InsightList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const KeyInsightItem = styled.li`
  list-style-type: none;
  width: 100%;
  padding: 15px;

  background: ${colors.white};
  border: 1px solid ${colors.greyMedium};
  border-radius: 5px;
`;

const WaveContainer = styled.div`
  position: absolute;
  left: 0;
  width: 2000px;
  min-width: 100%;
  top: 0;
  bottom: 0;
  pointer-events: none;
`;

const Wave = styled.div`
  background: url(${waveSVG});
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
`;

export const StrategicImperative = styled.div`
  background: ${colors.purplePinkGradient};
  border-radius: 5px;
  padding: 1px;
  padding: 15px;
  display: flex;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background: ${colors.lightPinkGradient};
    border-radius: 5px;
    pointer-events: none;
  }

  .content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;

const ToggleContainer = styled.div`
  position: absolute;
  left: 60px;

  ${ToggleWrapper} {
    background: ${colors.yellow20};
    border-radius: 5px;
    width: fit-content;
    padding: 5px 10px 5px 5px;

    ${BodyNormal} {
      margin-left: 5px;
      margin-right: 0;
    }
  }

  @media ${device.tabletMin} {
    position: relative;
    left: 15px;
  }

  @media ${device.desktopMin} {
    position: absolute;
    left: 60px;
  }
`;

interface Props {
  closeBubbleSummary: () => void;
  strategyId: number;
  bubble: number | null;
}

export const BubbleSummary: React.FC<Props> = ({
  closeBubbleSummary,
  strategyId,
  bubble,
}) => {
  const windowWidth = useWidth();
  const isMobile = useMobile();
  const isDesktop = useDesktop();
  const isTablet = useTablet();
  const [showOptions, setShowOptions] = useState(true);
  const contentRef = useRef<HTMLElement | null>(null);
  const [canScroll, setCanScroll] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    return () => {
      setContentLoaded(false);
    };
  });

  useEffect(() => {
    setCanScroll(
      contentRef.current?.scrollWidth !== contentRef.current?.offsetWidth
    );
  }, [contentLoaded, windowWidth, bubble]);

  if (!bubble) return null;

  return ReactDOM.createPortal(
    <>
      <Wrapper>
        <Container bubble={bubble} id="bubble-summary-container">
          <Header>
            <div style={{ visibility: 'hidden' }}>
              {/* Note: we will show this button once the export feature is built */}
              <HeaderIcon name="Export" size={30} />
            </div>

            {bubble === 2 && (isMobile || isDesktop) && (
              <ToggleContainer>
                <Toggle
                  value={showOptions}
                  onChange={() => setShowOptions(!showOptions)}
                  label="Show options"
                />
              </ToggleContainer>
            )}

            {!isMobile && <Heading bubble={bubble}>{getTitle(bubble)}</Heading>}
            <HeaderIcon name="Close" size={30} onClick={closeBubbleSummary} />
          </Header>
          {isMobile && <Heading bubble={bubble}>{getTitle(bubble)}</Heading>}

          {bubble === 2 && isTablet && (
            <ToggleContainer>
              <Toggle
                value={showOptions}
                onChange={() => setShowOptions(!showOptions)}
                label="Show options"
              />
            </ToggleContainer>
          )}
          {bubble === 3 ? (
            <BubbleThreeContent ref={contentRef} canScroll={canScroll}>
              <BubbleSummaryThreeContent strategyId={strategyId} />
            </BubbleThreeContent>
          ) : (
            <Content ref={contentRef} bubble={bubble} canScroll={canScroll}>
              {bubble === 1 ? (
                <BubbleSummaryOneContent
                  dataLoaded={() => {
                    setContentLoaded(true);
                  }}
                  strategyId={strategyId}
                />
              ) : bubble === 2 ? (
                <BubbleSummaryTwoContent
                  showOptions={showOptions}
                  strategyId={strategyId}
                  dataLoaded={() => {
                    setContentLoaded(true);
                  }}
                />
              ) : bubble === 4 ? (
                <BubbleSummaryFourContent strategyId={strategyId} />
              ) : null}
            </Content>
          )}
          <WaveContainer>
            <Wave />
          </WaveContainer>
        </Container>
      </Wrapper>
      <Background onClick={closeBubbleSummary} />
    </>,
    document.body
  );
};

export const KeyInsightsList: React.FC<{
  title: string;
  insights: Pick<KeyInsight, 'text' | 'id' | 'stakeholder'>[];
}> = ({ title, insights }) => (
  <StakeholderSection>
    <TitleAndIcon>
      {getIcon(title)}
      <StakeholderTitle>{title}</StakeholderTitle>
    </TitleAndIcon>
    <InsightList>
      {insights?.map((k) => (
        <KeyInsightItem key={k.id}>{k.text}</KeyInsightItem>
      ))}
    </InsightList>
  </StakeholderSection>
);

const getIcon = (title: string) => {
  switch (title) {
    case 'Patients':
      return <Icon name="Patient" size={30} color={colors.purple} />;
    case 'Healthcare Professionals':
      return <Icon name="HCP" size={30} color={colors.green} />;
    case 'Payors':
      return <Icon name="Dollar" size={30} color={colors.blue} />;
    case 'Providers':
      return <Icon name="Provider" size={30} color={colors.pink} />;
    case 'Policymakers':
      return <Icon name="Policymaker" size={30} color={colors.orange} />;
  }
};

const getTitle = (bubble: number) => {
  switch (bubble) {
    case 1:
      return 'Key Stakeholder Insights';
    case 2:
      return 'Strategic Possibilities';
    case 3:
      return 'The Strategy';
    case 4:
      return 'Planning for Success';
    default:
      return '';
  }
};

export const LeveragePointIndicatorComponent = () => (
  <LeveragePointIndicator>
    <Icon name="StarPurple" size={20} />
    <Caption color={colors.greyDark}>Leverage Point</Caption>
  </LeveragePointIndicator>
);

export const DownloadButton = styled(DownloadButtonComponent)`
  position: fixed;
  top: 86px;
  left: 25px;

  visibility: hidden;
  @media ${device.tabletMin} {
    left: 35px;
    visibility: visible;
  }
`;
