import { Caption } from 'components/shared';
import { colors } from 'constants/colors';
import React, { ReactElement } from 'react';
import styled from 'styled-components/macro';
import { MOBILE_LEFT_SIDE_PADDING, YEAR_COLUMN_WIDTH } from './constants';
import { DueDateDiamond } from './DueDateDiamond';
import { RowProps } from './SummaryTimelineRestyled';
import { getTacticMeasurementsForTimeline } from './utils/getTacticMeasurementsForTimeline';

const Duration = styled.div`
  height: 5px;
  background: ${colors.purplePinkGradient};
  border-radius: 5px;
`;

const Tactic = styled.div`
  position: relative;
  height: 15px;
  display: flex;
  align-items: center;
  width: fit-content;
`;

const TacticWrapper = styled.div`
  margin-bottom: 30px;
`;

const TacticTextWrapper = styled.div`
  max-width: 374px;

  @media (max-width: 373px) {
    max-width: calc(100vw - ${MOBILE_LEFT_SIDE_PADDING + 5}px);
  }
`;

const TacticText = styled(Caption)``;

export type IndividualTacticsProps = {
  imperativeTactics: RowProps[];
  minDate: number;
  quarterColumnWidth: number;
  prependDetailText?: string | ReactElement;
};

export const IndividualTactics = ({
  imperativeTactics,
  minDate,
  quarterColumnWidth,
  prependDetailText,
}: IndividualTacticsProps) => {
  return (
    <>
      {imperativeTactics.map((tactic) => {
        const measurements = getTacticMeasurementsForTimeline({
          tactic,
          minDate,
          quarterColumnWidth,
        });

        const hasStartAndEndDates = Boolean(
          tactic.timingStart.length && tactic.timingEnd.length
        );

        const hasDueDate = Boolean(tactic.dueDate.length);

        const allDatesPresent = hasStartAndEndDates && hasDueDate;

        // Individual Tactics
        return (
          <TacticWrapper key={tactic.id + '' + tactic.prependText}>
            <Tactic>
              {hasStartAndEndDates && (
                <Duration
                  style={{
                    position: 'absolute',
                    width: measurements.width,
                    minWidth: 33.3,
                    transform: `translateX(${measurements.x}px)`,
                  }}
                />
              )}
              {hasDueDate && (
                <DueDateDiamond
                  style={{
                    inset: 0,
                    marginLeft: `${
                      // If it's landing on a year column, adjust its position so it sits in the middle of the column
                      measurements.diamondMargin
                    }px`,
                  }}
                />
              )}
            </Tactic>

            <TacticTextWrapper
              style={{
                display: 'flex',
                flexDirection: 'column',
                transform: `translateX(${
                  allDatesPresent
                    ? measurements.textX
                    : hasDueDate
                    ? measurements.diamondMargin
                    : YEAR_COLUMN_WIDTH
                }px)`,
              }}
            >
              {!(hasStartAndEndDates || hasDueDate) && (
                <Caption color={colors.darkRed}>From/To date missing</Caption>
              )}
              {!!tactic.tacticText && (
                <div
                  style={{
                    display: 'flex',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <TacticText>
                    {tactic.prependText}
                    {tactic.tacticText}
                  </TacticText>
                </div>
              )}
            </TacticTextWrapper>
          </TacticWrapper>
        );
      })}
    </>
  );
};
