import { Stage } from 'konva/types/Stage';
import { Cursor } from 'types';

type Props = {
  type: Cursor;
  isDrag: boolean;
  isPreview: boolean;
  newConnectionLength: number;
  stageRef: React.MutableRefObject<Stage | null>;
};
export const changeCursor = ({
  type,
  isDrag,
  isPreview,
  newConnectionLength,
  stageRef,
}: Props) => {
  if (!stageRef?.current) return;
  if ((isDrag || isPreview) && type === 'grabbing') {
    stageRef.current.container().style.cursor = 'grabbing';
  } else if (isDrag || isPreview) {
    stageRef.current.container().style.cursor = 'grab';
  } else if (newConnectionLength && type !== 'pointer') {
    stageRef.current.container().style.cursor = 'crosshair';
  } else {
    stageRef.current.container().style.cursor = type;
  }
};
