import { differenceInCalendarYears, differenceInMonths } from 'date-fns';
import useDesktop from 'hooks/useDesktop';
import { debounce, sortBy } from 'lodash';
import React from 'react';
import styled from 'styled-components/macro';
import { CalloutMobile } from './CalloutMobile';
import { CombinedData, DIVIDER_WIDTH } from './LongTermStrategySummaryTimeline';
import Pin from './Pin';
import { getTag } from './utils';

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  height: 30px;
`;

type Props = {
  data: CombinedData;
  approvalDate: string;
  containerDimensions: DOMRect | undefined;
};

export const ChartItems = ({
  data,
  approvalDate,
  containerDimensions,
}: Props) => {
  const containerWidth = containerDimensions?.width || 0;
  const yearWidth = (containerWidth - DIVIDER_WIDTH * 3) / 11;
  const monthWidth = yearWidth / 12;
  const isDesktop = useDesktop();
  const [hoveredIdx, setHoveredIdx] = React.useState<null | number>(null);

  const sortedTimestampedItems = sortBy(Object.entries(data), (v) => v[0]);
  const allItems = sortedTimestampedItems.map((v) => v[1]);

  const handleMouseEnter = (v: number) => {
    if (isDesktop) {
      setHoveredIdx(v);
    }
  };
  const debouncedHandleOnMouseLeave = debounce(() => {
    if (isDesktop) {
      setHoveredIdx(null);
    }
  }, 1000);

  return (
    <Wrapper>
      {!isDesktop && (
        <CalloutMobile
          idx={hoveredIdx}
          setHoveredIdx={(v: number | null) => setHoveredIdx(v)}
          handleClose={() => {
            setHoveredIdx(null);
          }}
          data={allItems}
        />
      )}
      {sortedTimestampedItems.map((v, idx, orig) => {
        const due = +v[0];
        const pins = v[1];
        const monthDifference = differenceInMonths(due, +approvalDate) - 1;
        const yearDifference = differenceInCalendarYears(due, +approvalDate);
        const columnOffset =
          yearDifference > 7
            ? DIVIDER_WIDTH * 3
            : yearDifference > 3
            ? DIVIDER_WIDTH * 2
            : yearDifference > 0
            ? DIVIDER_WIDTH
            : 0;

        const groupedPins = pins.length > 1;

        return (
          <Pin
            zIndex={orig.length - idx}
            onClick={() => {
              setHoveredIdx(idx);
            }}
            setIsHovered={() => setHoveredIdx(idx)}
            containerWidth={containerWidth}
            data={pins}
            key={idx}
            pinTitle={groupedPins ? pins.length : getTag(pins[0].type)}
            x={Math.min(
              Math.max(monthDifference, 0) * monthWidth + columnOffset,
              containerWidth - 30
            )}
            handleMouseEnter={() => {
              handleMouseEnter(idx);
            }}
            debouncedHandleOnMouseLeave={debouncedHandleOnMouseLeave}
            isHovered={hoveredIdx === idx}
          />
        );
      })}
    </Wrapper>
  );
};
