import { colors } from 'constants/colors';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import React, { useRef } from 'react';
import styled, { css } from 'styled-components/macro';
import FormField, { ErrorMessage } from './FormField';
import { Icon } from './Icon';
import { ButtonLabel } from './TextStyles';

export const SelectedValueContainer = styled.div<{
  dropdownActive: boolean;
  disabled?: boolean;
  error?: boolean;
  isSelected?: boolean;
  onClick: any;
}>`
  padding: 0 15px 0 10px;
  line-height: 20px;
  border-width: 1px;
  border-style: solid;
  background: ${colors.white};
  border-radius: ${({ dropdownActive }) =>
    dropdownActive ? '5px 5px 0px 0px' : '5px'};
  color: ${({ disabled, isSelected }) =>
    disabled ? colors.black50 : isSelected ? colors.black : colors.black50};

  border-color: ${({ disabled, error }) =>
    disabled ? colors.black50 : error ? colors.darkRed : colors.greyDark};

  :focus {
    outline: none;
    border-color: ${({ error }) => (error ? colors.darkRed : colors.black)};
  }

  :hover {
    cursor: pointer;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DisplayedOption = styled.div<{ fade?: boolean }>`
  display: flex;
  height: 48px;
  align-items: center;
  opacity: ${({ fade }) => (fade ? 0.6 : 1)};
  transition: opacity 0.2s;
`;

export const DropdownList = styled.ul`
  z-index: 2;
  margin: 0;
  padding: 0;
  border: 1px solid ${colors.black};
  border-top: none;
  border-radius: 0 0 5px 5px;
  padding: 5px;
  max-height: 230px;
  background: ${colors.white};
  position: absolute;
  right: 0;
  left: 0;
  :hover {
    cursor: pointer;
  }
  overflow: auto;
`;

export const DropdownOption = styled.li<{
  isSelected: boolean;
  disableHover?: boolean;
}>`
  height: 30px;
  width: 100%;
  list-style-type: none;
  margin-bottom: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: ${({ isSelected }) => isSelected && `2px solid ${colors.purple}`};
  padding: 5px 15px;

  ${({ disableHover }) => {
    if (!disableHover) {
      return css`
        :hover {
          background-color: ${colors.greyLight};
        }
      `;
    } else {
      return css`
        cursor: not-allowed;
        * {
          pointer-events: none;
        }
      `;
    }
  }}

  :last-child {
    margin-bottom: 0;
  }
`;

type Option = string | number;

interface BasicFormDropdownProps {
  name: string;
  title: string;
  disabled?: boolean;
  errorMessage?: string;
  placeholder: string;
  options: Option[];
  onClick: () => void;
  selectedItem: Option;
  dropdownActive: boolean;
  setSelectedItem: (val: Option) => void;
  setDropdownActive: (val: boolean) => void;
}

const BasicFormDropdown: React.FC<BasicFormDropdownProps> = ({
  name,
  title,
  disabled,
  errorMessage,
  placeholder,
  options,
  onClick,
  selectedItem,
  dropdownActive,
  setSelectedItem,
  setDropdownActive,
}) => {
  const handleOptionClick = (option: any) => {
    setSelectedItem(option);
    setDropdownActive(false);
  };
  const containerRef = useRef(null);

  useClickOutsideComponent(containerRef, (event) => {
    setDropdownActive(false);
  });

  return (
    <FormField
      innerRef={containerRef}
      name={name}
      title={title}
      disabled={disabled}
    >
      <SelectedValueContainer
        error={!!errorMessage}
        disabled={disabled}
        dropdownActive={dropdownActive}
        isSelected={!!selectedItem}
        onClick={disabled ? null : onClick}
      >
        <DisplayedOption>
          <p>{selectedItem || placeholder}</p>
        </DisplayedOption>
        <Icon
          name={dropdownActive ? 'Chevron-up' : 'Chevron-down'}
          color={colors.black}
          size={30}
        />
      </SelectedValueContainer>
      {errorMessage && <ErrorMessage message={errorMessage} />}

      {dropdownActive && (
        <DropdownList>
          {options.map((option) => (
            <DropdownOption
              key={option}
              onClick={() => handleOptionClick(option)}
              isSelected={option === selectedItem}
            >
              <ButtonLabel>{option}</ButtonLabel>
            </DropdownOption>
          ))}
        </DropdownList>
      )}
    </FormField>
  );
};

interface FormDropdownProps {
  name: string;
  title: string;
  disabled?: boolean;
  errorMessage?: string;
  placeholder: string;
  options: { id: string; text: string }[];
  onClick: () => void;
  selectedItem?: { id: string; text: string };
  dropdownActive: boolean;
  setSelectedItem: (val: { id: string; text: string }) => void;
  setDropdownActive: (val: boolean) => void;
}

export const FormDropdown: React.FC<FormDropdownProps> = ({
  name,
  title,
  disabled,
  errorMessage,
  placeholder,
  options,
  onClick,
  selectedItem,
  dropdownActive,
  setSelectedItem,
  setDropdownActive,
}) => {
  const handleOptionClick = (option: { id: string; text: string }) => {
    setSelectedItem(option);
    setDropdownActive(false);
  };
  const containerRef = useRef(null);

  useClickOutsideComponent(containerRef, (event) => {
    setDropdownActive(false);
  });

  return (
    <FormField
      innerRef={containerRef}
      name={name}
      title={title}
      disabled={disabled}
    >
      <SelectedValueContainer
        data-cy="dropdown-container"
        error={!!errorMessage}
        disabled={disabled}
        dropdownActive={dropdownActive}
        isSelected={!!selectedItem}
        onClick={disabled ? null : onClick}
      >
        <DisplayedOption>
          <p>{selectedItem?.text || placeholder}</p>
        </DisplayedOption>
        <Icon
          name={dropdownActive ? 'Chevron-up' : 'Chevron-down'}
          color={colors.black}
          size={30}
        />
      </SelectedValueContainer>

      {errorMessage && <ErrorMessage message={errorMessage} />}

      {dropdownActive && (
        <DropdownList>
          {options.map(({ id, text }) => (
            <DropdownOption
              key={id}
              data-cy="dropdown-option"
              onClick={() => handleOptionClick({ id, text })}
              isSelected={id === selectedItem?.id}
            >
              <ButtonLabel>{text}</ButtonLabel>
            </DropdownOption>
          ))}
        </DropdownList>
      )}
    </FormField>
  );
};

export default BasicFormDropdown;
