import { Role } from 'data/graphql/generated';

export const mapRoleToEnum = {
  Lead: Role.Lead,
  Contributor: Role.Contributor,
};

export const mapRoleEnumToReadableString = {
  [Role.Lead]: 'Lead',
  [Role.Contributor]: 'Contributor',
};
