import { BodySmall, Caption, CheckCircle } from 'components/shared';
import { colors } from 'constants/index';
import React from 'react';
import styled from 'styled-components';

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  min-width: 132px;
  height: 40px;
  padding: 0px 20px 0px 5px;
  background-color: ${colors.white};
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  > * + * {
    margin-left: 10px;
  }
  cursor: pointer;
  user-select: none;
`;

interface Props {
  onClick(): void;
  complete?: boolean;
  messaging: string[];
  isDisabled?: boolean;
  className?: string;
}

export const FlowProgressCheckbox: React.FC<Props> = ({
  onClick,
  messaging,
  complete,
  isDisabled,
  className,
}) => {
  return (
    <Wrapper onClick={onClick} className={className}>
      <CheckCircle complete={!!complete} isDisabled={!!isDisabled} />

      <TextWrapper>
        <BodySmall>{messaging[0]}</BodySmall>
        <Caption color={colors.greyDark}>{messaging[1]}</Caption>
      </TextWrapper>
    </Wrapper>
  );
};
