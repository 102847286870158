import { BodyNormal, ButtonLabel, Subtitle2 } from 'components/shared';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TheWho } from '../../components/Positioning/TheWho';
import { colors, polling } from '../../constants';
import { PageWrapper, StepHeader } from '.';
import {
  StatementsDocument,
  Step,
  SubStep,
  useStatementUpdateMutation,
  useStatementsQuery,
  useStrategyQuery,
} from '../../data/graphql/generated';
import { sortPostIts } from '../../hooks';
import useDesktop from '../../hooks/useDesktop';
import { usePostItCardMove } from '../../hooks/usePostItCardMove';
import { usePostItCards } from '../../hooks/usePostItCards';
import { usePostItGroups } from '../../hooks/usePostItGroups';
import { PositioningTabKebabCase } from '../../types';
import { ErrorWrapper } from '../../components/ErrorLoadingComponent';
import { WhatToConsiderModal } from '../../components/Positioning/WhatToConsiderModal';
import client from '../../data/apollo';

type Props = {};

interface URLParams {
  drugId: string;
  strategyId: string;
  positioningTab: PositioningTabKebabCase;
  competitorId: string;
}

export const TheWhoPage = (props: Props) => {
  const [whatToConsiderModal, setWhatToConsiderModal] = useState(false);

  const { drugId, strategyId }: URLParams = useParams();

  const {
    data: strategyData,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
  } = useStrategyQuery({
    variables: { id: +strategyId },
  });

  const {
    data: statementsData,
    loading: statementsLoading,
    startPolling: statementsStartPolling,
    stopPolling: statementsStopPolling,
    error: statementsError,
  } = useStatementsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        step: Step.Positioning,
        substep: [SubStep.TheWho],
      },
    },
    nextFetchPolicy: 'cache-and-network',
  });

  const statementRefetch = {
    refetchQueries: [
      {
        query: StatementsDocument,
        variables: {
          where: {
            strategyId: +strategyId,
            step: Step.Positioning,
            substep: [SubStep.TheWho],
          },
        },
      },
    ],
  };

  const [updateStatement] = useStatementUpdateMutation({
    ...statementRefetch,
  });

  const {
    items: groupItems,
    loading: groupsLoading,
    createGroup,
    updateGroup,
    removeGroup,
    error: groupsError,
  } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.TheWho,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Positioning,
      substep: SubStep.TheWho,
    }
  );

  const {
    items: cardItems,
    loading: cardsLoading,
    createCard,
    updateCard,
    removeCard,
    error: cardsError,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.TheWho,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Positioning,
      substep: SubStep.TheWho,
    }
  );

  const groups = sortPostIts(groupItems, cardItems);

  const onChange = usePostItCardMove(groups, updateCard);
  const noCardsData = !groups;
  const isDesktop = useDesktop();

  useEffect(() => {
    statementsStartPolling(polling.default);
    startStrategyPolling(polling.default);
    return () => {
      statementsStopPolling();
      stopStrategyPolling();
    };
  }, [
    startStrategyPolling,
    statementsStartPolling,
    statementsStopPolling,
    stopStrategyPolling,
  ]);

  return (
    <>
      <WhatToConsiderModal
        visible={whatToConsiderModal}
        handleClose={() => setWhatToConsiderModal(false)}
        strategyId={+strategyId}
        drugId={+drugId}
      />

      <StepHeader isDesktop={isDesktop}>
        <Subtitle2 style={{ marginBottom: '5px' }}>
          Who is our brand for and what is their motivation?
        </Subtitle2>
        <div
          style={{
            whiteSpace: 'pre-wrap',
            marginBottom: '15px',
          }}
        >
          <BodyNormal color={colors.greyDark} style={{ display: 'inline' }}>
            Be specific in you choice. Leads will choose one point of focus.
          </BodyNormal>
          <ButtonLabel
            color={colors.purple}
            style={{ display: 'inline', whiteSpace: 'nowrap' }}
            onClick={() => setWhatToConsiderModal(true)}
          >
            View what to consider
          </ButtonLabel>
        </div>
      </StepHeader>
      <ErrorWrapper
        isLoading={groupsLoading || cardsLoading || statementsLoading}
        errors={[groupsError, cardsError, statementsError]}
        dataMissing={noCardsData || !statementsData}
      >
        <PageWrapper paddingTop={false} fullWidthMobile>
          <TheWho
            strategyData={strategyData?.strategy}
            statements={statementsData?.statements?.items}
            updateStatement={updateStatement}
            postIts={{
              step: Step.Positioning,
              subStep: SubStep.TheWho,
              groups: groups,
              addCard: createCard,
              removeCard: removeCard,
              updateCard: updateCard,
              handleCardChange: onChange,
              createGroup: async (group) => {
                const newGroup = await createGroup(
                  group,
                  statementRefetch.refetchQueries
                );

                return newGroup;
              },
              updateGroup: updateGroup,
              removeGroup: async (group) => {
                const groupStatement = statementsData?.statements?.items?.find(
                  (statement) => {
                    return statement.postItGroupId === group;
                  }
                );
                if (!groupStatement) return;

                const newGroup = await removeGroup(group);

                client.cache.evict({
                  id: `Statement:${groupStatement.id}`,
                });

                client.cache.gc();

                return newGroup;
              },
            }}
          />
        </PageWrapper>
      </ErrorWrapper>
    </>
  );
};
