import React from 'react';
import styled from 'styled-components/macro';
import { colors } from 'constants/colors';
import { Icon, IconContainer } from './Icon';
import { ButtonLabel } from './TextStyles';
import { device } from 'utils/breakpoints';

const GradientBG = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  width: 114px;
  border-radius: 5px;
  background: ${colors.bluePurple};
  cursor: pointer;
  height: 40px;

  @media ${device.desktopMin} {
    height: 30px;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0px;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background: ${colors.bluePurpleFaded};

  &:hover {
    background: ${colors.bluePurpleFadedHover};
  }

  ${IconContainer} {
    width: 25px;
  }
`;

interface Props {
  onClick(): void;
}

export function AddSpinOffButton({ onClick }: Props) {
  return (
    <GradientBG onClick={onClick}>
      <InnerWrapper>
        <Icon name="SpinOff" size={30} />
        <ButtonLabel color={colors.blue}>Add spin-off</ButtonLabel>
      </InnerWrapper>
    </GradientBG>
  );
}
