import React, { useContext, useState } from 'react';
import { createContext } from 'react';

export const HelpContext = createContext<[boolean, any]>([
  false,
  () => {},
]);

export const HelpProvider: React.FC = ({ children }) => {
  const [showHelp, setHelp] = useState<boolean>(
    false
  );

  return (
    <HelpContext.Provider value={[showHelp, setHelp]}>
      {children}
    </HelpContext.Provider>
  );
};

export function useHelpContext() {
  return useContext(HelpContext);
}
