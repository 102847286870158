import { ModalForm } from 'components/ModalForm';
import {
  BodySmall,
  ButtonPill,
  ButtonRound,
  CountryFlag,
  Heading1,
  Heading2,
  Heading3,
  Icon,
  Subtitle1,
  Subtitle2,
  BodyNormal,
  Radio,
} from 'components/shared';
import FormTextInput from 'components/shared/FormTextInput';
import { colors, globalContributor } from 'constants/index';
import { useAuthContext } from 'contexts/AuthContext';
import { currencies } from 'constants/index';
import {
  DrugFragment,
  DrugsDocument,
  StrategyFragment,
  useDrugsQuery,
  useDrugUpdateMutation,
  useStakeholderDefinitionsQuery,
  useStrategyCreateMutation,
  useStrategyUpdateMutation,
} from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import { startCase, uniqBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { Navbar } from '../components/Navbar';
import { Page } from '../components/Page';
import { ModalButton, ModalButtonContainer } from './AdminCompany';
import { ErrorWrapper } from '../components/ErrorLoadingComponent';
import { NotPublishedButton } from 'components/CreateStrategy/NotPublishedButton';

const EmptyStrategyStateWrapper = styled.div`
  background: ${colors.white};
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: -15px;
  border-radius: 5px;
`;

export const Divider = styled.div`
  width: 1px;
  background-color: ${colors.black10};
`;

const SelectButton = styled(ButtonPill)`
  min-width: 140px;
  width: 140px;
  @media ${device.mobile} {
    width: 100%;
  }
`;

const PageWrapper = styled(Page)`
  background: url('/fill-gradient.png') ${colors.black};
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - 50px);
  padding-top: 80px;
  > div {
    width: auto;
  }
  @media ${device.tabletMax} {
    > div {
      width: 100%;
      padding: 0 5px;
    }
  }
  @media ${device.mobile} {
    padding-top: 50px;
    > div {
      width: 100%;
      padding: 0;
    }
  }
`;

const DrugWrapperTop = styled.div`
  position: relative;
  z-index: 100;
  height: 10px;
  background: radial-gradient(
    100% 57600% at 0% 100%,
    #49129b 0%,
    #4f72c9 50%,
    #bc2eaa 100%
  );

  overflow: hidden;
  &::before {
    content: '';
    background: url(/noise.png) repeat;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 60px;
    opacity: 0.3;
    pointer-events: none;
    z-index: 2;
  }
`;

const DrugWrapper = styled.div`
background: white;
  overflow: hidden;

  width: 928px;
  // border: 1px solid rgba(20, 20, 39, 0.1);
  border-radius: 5px;
  margin: 0 auto 15px;
  padding-bottom: 30px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        rgb(255 255 255 / 88%) 0%,
        rgb(255 255 255 / 82%) 100%
      ),
      radial-gradient(
        circle at bottom left,
        rgba(10, 3, 18, 1) 0%,
        rgba(72, 16, 167, 1) 10%,
        rgba(75, 126, 235, 1) 20%,
        rgba(83, 123, 234, 1) 50%,
        rgba(234, 78, 209, 1) 60%,
        rgba(254, 247, 253, 1) 80% 100%
      );
    background-attachment: fixed;
    opacity: 0.5;
  }
  @media ${device.tabletMax} {
    width: 100%;
  }

  @media ${device.mobile} {
    border: none;
    border-radius: 0;
  }
`;

const DrugInnerWrapper = styled.div`
  padding: 0 15px;
  position: relative;
  @media ${device.mobile} {
    padding: 0 30px;

  }
`;

const CountriesWrapper = styled.div`
  min-width: 305px;
  margin-top: 5px;
  @media ${device.tabletMax} {
    position: relative;
    margin-top: 20px;
    margin-bottom: 0px;
    min-width: auto;
  }

  transition: 0.3s ease;
`;

const FlagsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 25px);
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  @media ${device.tabletMax} {
    width: 100%;
    flex-direction: column;
  }
`;

const NoDrugsWrapper = styled.div`
  margin: 25px 15px 15px;
  width: 896px;
  min-height: 200px;
  position: relative;
  background: ${colors.white};
  border: 1px solid ${colors.black10};
  box-sizing: border-box;
  border-radius: 5px;
  display: grid;
  place-content: center;

  > div {
    width: 322px;
    height: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  @media ${device.tabletMax} {
    width: 90%;
    padding-bottom: 30px;

    > div {
      width: 100%;
      text-align: center;
    }
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 7px;

  ${Heading1} {
    min-height: 80px;
  }
  ${Heading2} {
    min-height: 50px;
  }
  ${Heading1},${Heading2} {
    word-break: break-word;
    background-image: linear-gradient(134deg, #3c4ec5, #be40ae);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }

  @media ${device.tablet} {
    padding-bottom: 30px;
  }

  @media ${device.mobile} {
    // padding-bottom: 35px;
  }
`;

const StyledEditIcon = styled(ButtonRound)`
  margin-left: 15px;
  margin-right: 15px;
`;

export const DrugDetailWrapper = styled.div`
  width: 559px;
  min-height: 70px;
  background: ${colors.white};
  border-radius: 4px;
  margin-bottom: 5px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  > p {
    width: 88px;
    margin-right: 15px;
    align-self: flex-start;
  }

  .DrugDetailWrapper__textarea {
    display: block;
    border: none;
    padding: 0;
    overflow-y: hidden;
    resize: none;
    flex-grow: 1;
    font-family: ABCFavorit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

    color: ${colors.greyDark};
    outline: 0.5px solid transparent;
    &:focus {
      outline: 0.5px solid ${colors.blue};
    }
    transition: outline 0.3s;
  }

  @media ${device.tabletMax} {
    width: 100%;
  }

  @media ${device.mobile} {
    flex-direction: column;
    margin-bottom: 2px;
    > p {
      width: 100%;
    }
  }
`;

const CurrencyOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
`;

export const StrategicDrugDetailsWrapper = styled(DrugDetailWrapper)`
  align-items: flex-start;

  & > div {
    width: 50%;
    @media ${device.mobile} {
      width: 100%;
    }
  }
  ${Divider} {
    width: 1px;
    margin: 0 22px;

    @media ${device.mobile} {
      width: 100%;
      height: 1px;
      margin: 15px 0;
    }
  }
  ${BodySmall} {
    margin-bottom: 5px;
  }
  textarea {
    width: 100%;
  }

  transition: 0.3s ease;
`;

export const DrugDetails = styled.div`
  margin-left: auto;
`;

export const DrugDetailBorderWrapper = styled.div`
  padding: 1px;
  border-radius: 5px;
  margin: 18px 0;
  background: ${colors.purplePinkGradient};
  ${DrugDetailWrapper} {
    margin-bottom: 0px;
  }
  opacity: 1;
  max-height: 1000px;
  transition: 0.3s ease;
`;

export const BottomSection = styled.div<{ hide: boolean }>`
  width: 100%;
  margin-top: -118px;
  display: flex;
  ${CountriesWrapper} {
    order: -1;
    align-self: flex-end;
  }

  @media ${device.tabletMax} {
    margin-top: 0px;
    flex-direction: column;
    & > div {
      width: 100%;
    }
    ${CountriesWrapper} {
      order: 0;
    }
  }
  .hide,
  ${CountriesWrapper} {
    transition: 0.3s ease;
    ${({ hide }) =>
    hide
      ? css`
            opacity: 0;
            max-height: 0px;
            min-height: 0px;
            margin: 0;
          `
      : css`
            opacity: 1;
            max-height: 1000px;
          `};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
`;

export const checkIfFullNmblr = (featureSet: string[]): boolean => {
  const fullNmblr = new Set([
    '1.1',
    '1.2',
    '1.3',
    '1.4',
    '1.5',
    '2.1',
    '2.2',
    '2.3',
    '2.4',
    '2.5',
    '3.1',
    '3.2',
    '3.3',
    '3.4',
    '3.5',
    '4.1',
    '4.2',
    '4.3',
    '4.4',
    '4.5',
  ]);
  return featureSet.length === fullNmblr.size && featureSet.every(feature => fullNmblr.has(feature));
};

const StyledModalButtonPill = styled(ButtonPill)`
  min-width: 160px;
  width: 180px;
`;

const StrategyDetails: React.FC<{
  strategy: StrategyFragment;
  drug: DrugFragment;
}> = ({ strategy, drug }) => {
  const [strategyUpdate] = useStrategyUpdateMutation();
  const [values] = useState(strategy);
  const [currencyModalVisible, setCurrencyModalVisible] = useState(false);
  const [currency, setCurrency] = useState(strategy.currency || currencies[0]);
  const [currencySaving, setCurrencySaving] = useState(false);
  const history = useHistory();

  const { data: drugData } = useDrugsQuery({
    variables: { where: { id: drug.id } },
    fetchPolicy: 'network-only',
  });

  const ProductOnDrugs = drugData?.drugs?.items?.filter(
    (e) => e.id === drug.id
  )[0]?.ProductOnDrugs;

  const miniFeatureSet = useMemo(() => {
    if (ProductOnDrugs) {
      return ProductOnDrugs?.filter(i => i.drugId === drug.id).flatMap((f: any) => {
        if (f.Product?.features)
          return f.Product?.features?.map((g: any) => g.featureSet);
        return [];
      });
    }
    return [];
  }, [ProductOnDrugs, drug.id]);

  const {
    data: stakeholderDefinitions,
  } = useStakeholderDefinitionsQuery({
    fetchPolicy: 'network-only',
    variables: { where: { id: strategy.stakeholderDefinitionId, strategyId: +strategy.id } },
  });

  const targetPopulation = stakeholderDefinitions?.stakeholderDefinitions?.items?.filter(
    (e) => e.id === strategy.stakeholderDefinitionId
  )[0]?.title;


  return strategy ? (
    <>
      <ModalForm visible={currencyModalVisible} handleClose={() => { }}>
        <Subtitle1 style={{ marginTop: -20 }}>
          Choose a global currency
        </Subtitle1>
        <BodyNormal>This is the currency to use for global budgets.</BodyNormal>

        <div>
          {currencies.map((c) => (
            <CurrencyOption onClick={() => setCurrency(c)} key={c}>
              <Radio checked={c === currency} />
              <BodySmall style={{ marginLeft: 6 }}>{c}</BodySmall>
            </CurrencyOption>
          ))}
        </div>

        <ButtonsContainer>
          <StyledModalButtonPill
            onClick={() => setCurrencyModalVisible(false)}
            text="Cancel"
            level="secondary"
          />
          <StyledModalButtonPill
            onClick={() => {
              setCurrencySaving(true);
              try {
                strategyUpdate({
                  variables: {
                    id: Number(values.id),
                    data: {
                      currency,
                    },
                  },
                });
                setCurrencySaving(false);
                setCurrencyModalVisible(false);
              } catch (err) {
                console.error(err);
                alert('Something went wrong');
                setCurrencySaving(false);
              }
            }}
            text="Save"
            loading={currencySaving}
            disabled={currencySaving}
          />
        </ButtonsContainer>
      </ModalForm>

      <Content>
        {strategy.isDraft ? (
          <NotPublishedButton
            onClick={() =>
              history.push(
                `/d/${drug.id}/strategy/${values.id}/create/period`
              )
            }
          />
        ) : (
          <>
            {targetPopulation && (
              <div style={{ paddingTop: 15, paddingBottom: 15 }}>
                <Heading3 color={colors.black}>{targetPopulation}</Heading3>
              </div>
            )}
            <SelectButton
              text="Select"
              onClick={() => {
                if (!checkIfFullNmblr(miniFeatureSet)) {
                  history.push(`/d/${drug.id}/strategy/${values.id}/welcome`);
                } else history.push(`/d/${drug.id}/strategy/${values.id}`);
              }}
              className="cypress-develop-strategy" /></>
        )}
        {strategy?.users?.length ? (
          <CountriesWrapper>
            <>
              <FlagsWrapper>
                {uniqBy(strategy.users, 'country')
                  .filter((user) => user.role === 'CONTRIBUTOR')
                  .filter(
                    (user) =>
                      user?.country && user?.country !== globalContributor
                  )
                  .map((user) => {
                    if (!user) return null;
                    return (
                      <CountryFlag
                        user={user}
                        key={user.id}
                        tooltip={startCase(user?.country || '')}
                        size={25}
                      />
                    );
                  })}
              </FlagsWrapper>
            </>
          </CountriesWrapper>
        ) : (
          <></>
        )}

      </Content>
    </>
  ) : null;
};

const Strategies: React.FC<{ drug: DrugFragment }> = ({ drug }) => {
  const strategies = drug?.Strategy;

  if (strategies && strategies?.length < 1) {
    return <></>
  }

  return (
    <>
      {strategies ? (
        strategies?.length < 1 ? (
          <EmptyStrategyStateWrapper>
            <Icon
              name="GenericEmptyState"
              size={115}
              height={115}
              color="initial"
            />
            <Subtitle2 color={colors.greyDark}>No strategies yet</Subtitle2>
          </EmptyStrategyStateWrapper>
        ) : (
          strategies.map((strategy, index, originalArr) =>
            strategy ? (
              <div key={strategy?.id}>
                <StrategyDetails drug={drug} strategy={strategy} />
                {originalArr.length > 1 && index !== originalArr.length - 1 ? (
                  //Divider
                  <div
                    style={{
                      width: '100%',
                      height: 1,
                      background: colors.greyMedium,
                      marginTop: 30,
                    }}
                  />
                ) : null}
              </div>
            ) : null
          )
        )
      ) : (
        'loading...'
      )}
    </>
  );
};

export const Drugs: React.FC = () => {
  const history = useHistory();
  const [page] = useState<number>(0);
  const [{ user }] = useAuthContext();
  const [drugUpdate] = useDrugUpdateMutation();
  const [strategyCreate] = useStrategyCreateMutation();
  const pageSize = 20;
  const queryVars = {
    where: {},
    skip: page * pageSize,
    take: pageSize,
  };

  const { data: drugData, loading, error } = useDrugsQuery({
    variables: queryVars,
    //When navigating back to this page, the query attempts to fetch from the cache but the cache does not return any strategies for some reason
    //So forcing a network fetch is the safest solution
    fetchPolicy: 'network-only',
  });

  async function handleBrandUpdate(drugId: number) {
    const { name } = renameBrandModal;

    if (!name.length)
      return setRenameBrandModal({
        ...renameBrandModal,
        errorMessage: 'Name cannot be blank',
      });

    try {
      await drugUpdate({
        variables: {
          id: drugId,
          data: { name },
        },
        refetchQueries: [
          {
            query: DrugsDocument,
            variables: queryVars,
          },
        ],
      });

      closeRenameBrandModal();
    } catch (err) {
      console.error(err);
    }
  }

  const possibleDrugs = drugData?.drugs?.items;
  const isDesktop = useDesktop();
  const renameBrandModalDefaultState = {
    visible: false,
    name: '',
    errorMessage: '',
    drugId: null,
  };
  const [renameBrandModal, setRenameBrandModal] = React.useState<{
    visible: boolean;
    name: string;
    errorMessage: string;
    drugId: number | null;
  }>(renameBrandModalDefaultState);
  const closeRenameBrandModal = () =>
    setRenameBrandModal(renameBrandModalDefaultState);

  async function handleStrategyCreate(drugId: number) {
    setStrategyCreateLoading(true);
    try {
      if (typeof user?.id === 'number') {
        const { data } = await strategyCreate({
          variables: {
            data: {
              isDraft: true,
              drug: drugId,
            },
          },
        });

        const strategyId = data?.strategyCreate.id;

        history.push(`/d/${drugId}/strategy/${strategyId}/create/period`);
      } else {
        throw new Error('User Id not found');
      }
    } catch (err) {
      setStrategyCreateLoading(false);
      console.error(err);
      alert('Something went wrong');
    }
  }

  const [
    strategyCreateLoading,
    setStrategyCreateLoading,
  ] = React.useState<boolean>(false);

  return (
    <>
      <ModalForm
        handleClose={closeRenameBrandModal}
        visible={renameBrandModal.visible}
        heading="Rename brand"
      >
        <FormTextInput
          name="name"
          title="Brand name"
          onChange={(e) =>
            setRenameBrandModal({ ...renameBrandModal, name: e.target.value })
          }
          value={renameBrandModal.name}
          type="text"
          errorMessage={renameBrandModal.errorMessage}
        />
        <ModalButtonContainer>
          <ModalButton
            onClick={closeRenameBrandModal}
            text="Cancel"
            level="secondary"
          />
          <ModalButton
            onClick={() =>
              renameBrandModal.drugId
                ? handleBrandUpdate(renameBrandModal.drugId)
                : null
            }
            text="Save name"
          />
        </ModalButtonContainer>
      </ModalForm>

      <Navbar
        prev={{ title: '', url: '' }}
        next={{ title: '', url: '' }}
        disableSecondary
        title="Drugs"
      />

      <ErrorWrapper
        isLoading={loading}
        errors={[error]}
        dataMissing={!drugData}
      >
        <PageWrapper>
          {!possibleDrugs?.length ? (
            <DrugWrapper>
              <DrugWrapperTop />
              <NoDrugsWrapper>
                <div>
                  <Icon
                    name="GenericEmptyState"
                    size={115}
                    height={115}
                    color="initial"
                  />
                  <Subtitle2 color={colors.greyDark}>
                    You don’t have access to any strategies
                  </Subtitle2>
                  <BodySmall color={colors.greyDark}>
                    Contact a Lead for help
                  </BodySmall>
                </div>
              </NoDrugsWrapper>
            </DrugWrapper>
          ) : (
            possibleDrugs.map((d) => {
              if (!d) return null;
              const hasStrategies = !!d?.Strategy?.length;

              return (
                <DrugWrapper key={d.id}>
                  <DrugWrapperTop />
                  <DrugInnerWrapper>
                    <HeadingWrapper>
                      {isDesktop ? (
                        <Heading1>{d.name}</Heading1>
                      ) : (
                        <Heading2>{d.name}</Heading2>
                      )}

                      {user?.role === 'LEAD' && (
                        <>
                          <StyledEditIcon
                            iconName="Pencil"
                            level="edit"
                            size={30}
                            onClick={() =>
                              setRenameBrandModal({
                                ...renameBrandModal,
                                visible: true,
                                name: d.name,
                                drugId: d.id,
                              })
                            }
                          />
                        </>
                      )}
                    </HeadingWrapper>

                    {hasStrategies &&
                      <div style={{ fontSize: 18, color: colors.greyDark, marginLeft: 3 }}>
                        {d.ProductOnDrugs?.map((prod) => (
                          <div>{prod.Product.productName}</div>
                        ))}
                      </div>
                    }
                    <Strategies drug={d} />
                    <div>
                      {!hasStrategies && (
                        <div>
                          {isDesktop ? (
                            <ButtonPill
                              text="Get started"
                              onClick={() => handleStrategyCreate(d.id)}
                              loading={strategyCreateLoading}
                              className="cypress-strategy-create"
                              color={
                                hasStrategies ? colors.purple : colors.white
                              }
                              level={hasStrategies ? 'secondary' : 'primary'}
                            />
                          ) : (
                            <ButtonRound
                              level="primary"
                              size={40}
                              iconName="Plus"
                              onClick={() => handleStrategyCreate(d.id)}
                              loading={strategyCreateLoading}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </DrugInnerWrapper>
                </DrugWrapper>
              );
            })
          )}
        </PageWrapper>
      </ErrorWrapper>
    </>
  );
};