import {
  useBigIdeaDetailsQuery,
  useLongTermSolutionDetailsQuery,
  useLongTermStrategyTimelinesQuery,
  useSolutionsQuery,
} from 'data/graphql/generated';
import { useMemo } from 'react';
import { monthYearToDateTimestamp } from 'utils/dateStringToMonthYear';
import { CombinedData } from '../components/LongTermStrategy/Summary/LongTermStrategySummaryTimeline';

export const useTimelineData = ({ strategyId }: { strategyId: string }) => {
  const {
    data: timelineDatesData,
    loading: longTermStrategyTimelineLoading,
    startPolling: startLongTermStrategyTimelinePolling,
    stopPolling: stopLongTermStrategyTimelinePolling,
    error: longTermStrategyTimelineError,
  } = useLongTermStrategyTimelinesQuery({
    variables: {
      where: {
        strategyId: +strategyId,
      },
    },
  });

  // Big idea
  const {
    data: bigIdeasData,
    error: bigIdeasError,
    loading: bigIdeasLoading,
    startPolling: bigIdeasStartPolling,
    stopPolling: bigIdeasStopPolling,
  } = useBigIdeaDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        strategyId: +strategyId,
      },
    },
  });

  // risk mitigation
  const {
    data: solutionsData,
    startPolling: startSolutionsPolling,
    stopPolling: stopSolutionsPolling,
    loading: solutionsLoading,
    error: solutionsError,
  } = useSolutionsQuery({
    variables: {
      where: { strategyId: +strategyId },
      include: { successCondition: true },
    },
    fetchPolicy: 'network-only',
  });

  // long term solutions
  const {
    data: longTermSolutionDetailsData,
    error: longTermSolutionDetailsError,
    loading: longTermSolutionDetailsLoading,
    startPolling: startLongTermSolutionPolling,
    stopPolling: stopLongTermSolutionPolling,
  } = useLongTermSolutionDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        strategyId: +strategyId,
      },
    },
  });

  const combinedData = useMemo(
    () =>
      [
        bigIdeasData?.bigIdeaDetails?.items,
        solutionsData?.solutions?.items,
        longTermSolutionDetailsData?.longTermSolutionDetails?.items,
      ]
        .flat()
        .reduce((acc, curr) => {
          let due = 0;
          if (!!curr && 'due' in curr && curr.due) {
            due = +curr.due;
          }

          if (!!curr && 'dueDate' in curr && !!curr.dueDate) {
            const date = monthYearToDateTimestamp(curr.dueDate);
            if (date) {
              due = date;
            }
          }

          let text = '';

          if (curr?.__typename === 'BigIdeaDetail') {
            text = curr.text;
          }

          if (curr?.__typename === 'LongTermSolutionDetail') {
            text = curr.longTermSolution.text;
          }

          if (curr?.__typename === 'Solution') {
            text = curr.solutionText;
          }

          if (!!due && !!curr) {
            if (acc[due]) {
              acc[due].push({
                type: curr.__typename,
                text,
              });
            } else {
              acc[due] = [
                {
                  type: curr.__typename,
                  text,
                },
              ];
            }
          }

          return acc;
        }, {} as CombinedData),
    [
      bigIdeasData?.bigIdeaDetails?.items,
      longTermSolutionDetailsData?.longTermSolutionDetails?.items,
      solutionsData?.solutions?.items,
    ]
  );

  return {
    data: combinedData,
    timelineDatesData,
    loading:
      bigIdeasLoading &&
      solutionsLoading &&
      longTermSolutionDetailsLoading &&
      longTermStrategyTimelineLoading,
    error: [
      bigIdeasError,
      solutionsError,
      longTermSolutionDetailsError,
      longTermStrategyTimelineError,
    ],
    startPolling: (time: number) => {
      bigIdeasStartPolling(time);
      startSolutionsPolling(time);
      startLongTermSolutionPolling(time);
      startLongTermStrategyTimelinePolling(time);
    },
    stopPolling: () => {
      bigIdeasStopPolling();
      stopSolutionsPolling();
      stopLongTermSolutionPolling();
      stopLongTermStrategyTimelinePolling();
    },
  };
};
