import { colors } from 'constants/index';
import styled from 'styled-components';

export const SectionContainer = styled.div`
  background: ${colors.white};
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid ${colors.black10a};
`;
