import { FocusButton } from 'components/Options/FocusButton';
import { colors } from 'constants/colors';
import { zIndex } from 'constants/index';
import { useAuthContext } from 'contexts/AuthContext';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import useDesktop from 'hooks/useDesktop';
import { useWidthHeight } from 'hooks/useWidthHeight';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import { Country } from 'types';
import { verifyUserRole } from 'utils/verifyUserRole';
import { CountryFlag } from './CountryFlag';
import { Icon } from './Icon';
import { BodySmall, ButtonLabel } from './TextStyles';
import { startCase } from 'lodash';
import { Tooltip } from './Tooltip';

const Container = styled.div`
  position: relative;
`;
const MAX_COUNTRY_FLAGS = 5;
const DROPDOWN_WIDTH = 375;
const DISTANCE_FROM_EDGE = 15; // The dropdown must be at least this distance in px from either edge of the viewport

export const Wrapper = styled.div<{ checked: boolean; localTactic?: boolean }>`
  height: 40px;
  background: ${({ checked, localTactic }) =>
    checked ? (localTactic ? colors.blue10 : colors.cream) : colors.white};
  border: 0.5px solid ${colors.greyMedium};
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;

  width: fit-content;
  position: relative;
  z-index: 1;
`;

const RationaleDropdown = styled.div<{
  smallerDropdown?: boolean;
  isLocal?: boolean;
}>`
  background: ${({ isLocal }) => (isLocal ? colors.blue10 : colors.cream)};
  border: 0.5px solid ${colors.greyMedium};
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  max-width: ${({ smallerDropdown }) =>
    !smallerDropdown ? `calc(100% - ${2 * DISTANCE_FROM_EDGE}px)` : `216px`};

  width: ${DROPDOWN_WIDTH}px;
  z-index: ${zIndex.rationaleDropdown};
`;

const TitleAndEditButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Chevron = styled(Icon)<{ dropdownOpen: boolean; isTouchDevice: boolean }>`
  transform: ${({ isTouchDevice, dropdownOpen }) =>
    dropdownOpen ? (isTouchDevice ? 'rotate(180deg)' : 'translateY(3px)') : ''};
  transition: transform 200ms ease-out;
`;

const SpinOffFlags = styled.div`
  width: 100%;
  height: 29px;
  background: ${colors.greyLight};
  border-radius: 0px 0px 5px 5px;
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 7px 4px 2px;
  margin-top: -5px;
`;

const HiddenFlagCount = styled.div`
  width: 25px;
  height: 25px;
  background: ${colors.greyLight};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  onClick(e: React.MouseEvent<HTMLDivElement>): void;
  disabled?: boolean;
  checked: boolean;
  title?: string;
  disabledTooltipText?: string;
  rationaleText?: string;
  setModalSlideIdx:
    | React.Dispatch<React.SetStateAction<number | null>>
    | ((val: number | null) => void);
  setShowSingleSlide(v: boolean): void;
  smallerDropdown?: boolean;
  isLocal?: boolean;
  region?: string;
  spinOffRegions?: Country[];
}

export default function FocusDropdown({
  onClick,
  disabled = false,
  checked,
  title = 'Focus',
  disabledTooltipText,
  rationaleText,
  setModalSlideIdx,
  setShowSingleSlide,
  smallerDropdown,
  isLocal,
  region,
  spinOffRegions,
}: Props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);
  const isDesktop = useDesktop();
  const isTouchDevice = !isDesktop;
  const { width, height } = useWidthHeight();
  const [dropdownCoords, setDropdownCoords] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  useEffect(() => {
    const getPosition = () => {
      const wrapper = wrapperRef.current;
      if (!wrapper || !dropdownOpen) return;
      const {
        x,
        y,
        height: wrapperHeight,
        width: wrapperWidth,
        right,
        left,
      } = wrapper.getBoundingClientRect();

      const windowWidth = window.innerWidth;

      const dropdownWidth = dropdownRef.current?.offsetWidth || 0;
      const onLeftEdge = x + wrapperWidth - dropdownWidth < 0;
      const onRightEdge = x + dropdownWidth > windowWidth;

      const gapOnRight = windowWidth - right;
      // If using a touch device, the dropdown left edge aligns with the parent left edge
      // Else the dropdown right edge aligns with the parent right edge

      if (dropdownRef.current) {
        if (smallerDropdown) {
          dropdownRef.current.style.maxWidth = `216px`;
        } else if (width <= 944) {
          const gap = onRightEdge ? gapOnRight : left;

          // When on a small screen, the dropdown needs to align to an edge but it must be 15px away from the screen edge
          dropdownRef.current.style.maxWidth = `calc(100vw - ${
            gap + DISTANCE_FROM_EDGE
          }px)`;
        }
      }

      // get new width after max width change
      const updatedDropdownWidth = dropdownRef.current?.offsetWidth || 0;

      setDropdownCoords({
        x:
          onLeftEdge && onRightEdge
            ? x + wrapperWidth - updatedDropdownWidth + window.scrollX
            : onLeftEdge
            ? x + window.scrollX
            : onRightEdge
            ? x + wrapperWidth - dropdownWidth + window.scrollX
            : x + window.scrollX,

        y: y + wrapperHeight,
      });
    };

    getPosition();
    window.addEventListener('resize', getPosition);
    window.addEventListener('scroll', getPosition, true);

    return () => {
      window.removeEventListener('resize', getPosition);
      window.removeEventListener('scroll', getPosition, true);
    };
  }, [
    dropdownOpen,
    width,
    height,
    smallerDropdown,
    dropdownCoords.x,
    dropdownCoords.y,
  ]);

  useClickOutsideComponent(dropdownRef, () => setDropdownOpen(false), [
    'cypress-focus-dropdown-wrapper',
  ]);
  const spinOffGreaterThan5 =
    spinOffRegions && spinOffRegions?.length > MAX_COUNTRY_FLAGS;

  return (
    <Container className="FocusDropdownContainer">
      <Wrapper
        checked={checked}
        className={`cypress-focus-dropdown-wrapper-${Boolean(checked)}`}
        onClick={(e) => {
          if (!checked) return;
          const target = e.target as HTMLElement;
          const dropdown = dropdownRef.current;
          if (dropdown?.contains(target)) return;

          setDropdownOpen(!dropdownOpen);
        }}
        ref={wrapperRef}
        onMouseOver={(e) => {
          const targetClassList = (e.target as HTMLElement).classList;

          const mousedOverFlags =
            targetClassList.contains('cypress-country-flag') ||
            targetClassList.contains('spin-off-flags') ||
            targetClassList.contains('cypress-country-flag-icon');

          if (!checked || dropdownOpen || isTouchDevice || mousedOverFlags)
            return;

          setDropdownOpen(true);
        }}
        onMouseOut={(e) => {
          if (isTouchDevice) return;

          const relatedTarget = e.relatedTarget as HTMLElement;
          if (!relatedTarget) return;
          const mousedOntoDropdown = relatedTarget.classList.contains(
            'cypress-rationale-dropdown-element'
          );

          if (mousedOntoDropdown) return;

          setDropdownOpen(false);
        }}
        localTactic={isLocal}
      >
        <FocusButton
          onClick={(e) => {
            e.stopPropagation();
            onClick?.(e);
          }}
          disabled={disabled}
          checked={checked}
          title={title}
          disabledTooltipText={disabledTooltipText}
          localTactic={isLocal}
          region={region}
        />
        <Chevron
          name={'Chevron-down'}
          size={30}
          color={checked ? colors.black : colors.greyMedium}
          dropdownOpen={checked && dropdownOpen}
          isTouchDevice={isTouchDevice}
        />
        {dropdownOpen &&
          checked &&
          ReactDOM.createPortal(
            <RationaleDropdown
              smallerDropdown={smallerDropdown}
              style={{
                left: dropdownCoords.x,
                top: dropdownCoords.y,
              }}
              className="rationale-dropdown cypress-rationale-dropdown cypress-rationale-dropdown-element"
              ref={dropdownRef}
              isLocal={isLocal}
            >
              <TitleAndEditButton
                onMouseEnter={(e) => {
                  e.stopPropagation();
                  return;
                }}
                className="cypress-rationale-dropdown-element"
              >
                <BodySmall color={colors.greyDark}>Rationale</BodySmall>
                {(disabled === false || (isLead && !disabled)) && (
                  <ButtonLabel
                    color={colors.purple}
                    onClick={() => {
                      setShowSingleSlide(true);
                      setModalSlideIdx(1);
                    }}
                    className="cypress-rationale-dropdown-element"
                  >
                    Edit
                  </ButtonLabel>
                )}
              </TitleAndEditButton>
              <BodySmall style={{ width: '100%', wordBreak: 'break-word' }}>
                {rationaleText || 'None provided yet'}
              </BodySmall>
            </RationaleDropdown>,
            document.body
          )}
      </Wrapper>
      {!!spinOffRegions?.length && (
        <SpinOffFlags className="spin-off-flags">
          {(spinOffGreaterThan5
            ? spinOffRegions.slice(0, 4)
            : spinOffRegions
          ).map((region) => (
            <CountryFlag
              country={region}
              key={region}
              size={20}
              tooltip={startCase(region)}
              tooltipId={'focusDropdown'}
            />
          ))}
          {spinOffGreaterThan5 && (
            <HiddenFlagCount>
              <BodySmall>+{spinOffRegions.length - 4}</BodySmall>
            </HiddenFlagCount>
          )}
        </SpinOffFlags>
      )}
      <Tooltip id="focusDropdown" effect="float" />
    </Container>
  );
}
