import React from 'react';
import {
  MissingStrategicPossibilitiesProps,
  MissingStrategicPossibilities,
} from './MissingStrategicPossibilities';

interface RequireStrategicPossibilitiesProps
  extends MissingStrategicPossibilitiesProps {
  showEmptyState: boolean;
  children: React.ReactNode | React.ReactNode[];
}

export const RequireStrategicPossibilities: React.FC<RequireStrategicPossibilitiesProps> = ({
  drugId,
  strategyId,
  showEmptyState,
  children,
}) => {
  if (showEmptyState) {
    return (
      <MissingStrategicPossibilities drugId={drugId} strategyId={strategyId} />
    );
  }

  return <>{children}</>;
};
