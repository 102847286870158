import React from 'react';

import { RationaleModal } from 'components/shared/RationaleModal';
import {
  CriticalMetricGoalUpdateInput,
  CriticalMetricGoalFragment,
} from 'data/graphql/generated';

interface GoalFocusRationaleModalProps {
  modalSlideIdx: number | null;
  data: CriticalMetricGoalFragment;
  setModalSlideIdx?:
    | React.Dispatch<React.SetStateAction<number | null>>
    | ((val: number | null) => void);
  closeModal(): void;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdate: (data: CriticalMetricGoalUpdateInput) => Promise<void>;
}

export const GoalFocusRationaleModal: React.FC<GoalFocusRationaleModalProps> = ({
  modalSlideIdx,
  setModalSlideIdx,
  closeModal,
  data,
  setIsFocused,
  onUpdate,
}) => {
  const handleModalSubmit = React.useCallback(
    async (focusRationale: string) => {
      try {
        setIsFocused(true);
        await onUpdate({ isFocused: true, focusRationale });
      } catch (error) {
        setIsFocused(false);
      }
    },
    [onUpdate, setIsFocused]
  );

  return (
    <RationaleModal
      modalSlideIdx={modalSlideIdx}
      handleClose={closeModal}
      handleSubmit={handleModalSubmit}
      rationaleText={data.focusRationale || ''}
      setModalSlideIdx={setModalSlideIdx}
      showSingleSlide={true}
      modalDetails={{ Goal: data.goal || '' }}
    />
  );
};
