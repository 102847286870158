import { useCallback } from 'react';
import {
  useKeyEventStepCreateMutation,
  KeyEventFragment,
  useKeyEventStepDeleteMutation,
  Step,
} from 'data/graphql/generated';

type StepInputType = {
  step: Step;
  strategy: number;
} & {
  keyEvent: KeyEventFragment;
};

export const useCreateDeleteKeyEventsStep = () => {
  const [createKeyEventStep] = useKeyEventStepCreateMutation();
  const [deleteKeyEventStep] = useKeyEventStepDeleteMutation();

  const handleCreateKeyEventStep = useCallback(
    ({ step, strategy, keyEvent }: StepInputType) => {
      createKeyEventStep({
        variables: {
          data: {
            keyEventLocalUid: keyEvent.localUid,
            step,
            strategy,
          },
        },
        optimisticResponse: {
          keyEventStepCreate: { ...keyEvent, enabledForRequestRegion: true },
        },
      });
    },
    [createKeyEventStep]
  );

  const handleDeleteKeyEventStep = useCallback(
    ({ step, strategy, keyEvent }: StepInputType) => {
      deleteKeyEventStep({
        variables: {
          data: {
            keyEventLocalUid: keyEvent.localUid,
            step,
            strategy,
          },
        },
        optimisticResponse: {
          keyEventStepDelete: { ...keyEvent, enabledForRequestRegion: false },
        },
      });
    },
    [deleteKeyEventStep]
  );

  const toggleKeyEventStep = useCallback(
    ({ keyEvent, step, strategy }: StepInputType) => {
      if (keyEvent.enabledForRequestRegion === true) {
        handleDeleteKeyEventStep({ keyEvent, step, strategy });
      } else {
        handleCreateKeyEventStep({ keyEvent, step, strategy });
      }
    },
    [handleCreateKeyEventStep, handleDeleteKeyEventStep]
  );

  return {
    handleCreateKeyEventStep,
    handleDeleteKeyEventStep,
    toggleKeyEventStep,
  };
};
