import FocusDropdown from 'components/shared/FocusDropdown';
import {
  BigIdeaFragment,
  User,
  useBigIdeaCountryFocusUpdateMutation,
} from 'data/graphql/generated';
import React from 'react';
import { Country } from '../../types';

import { RationaleModal } from 'components/shared/RationaleModal';
import {
  BigIdeaCountryFocusUpdateInput,
  useBigIdeaUpdateMutation,
} from 'data/graphql/generated';
import useManageFocusRationale from 'hooks/useManageFocusRationale';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { verifyUserRole } from 'utils/verifyUserRole';
import { StyledTwoButtonModal } from '../../containers/MedicalStrategy/index.style';

const FocusButtons = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

interface Props {
  bigIdea: BigIdeaFragment;
  user: User | undefined;
  countryTacticsEnabled: boolean;
  bigIdeasRefetch: () => void;
}

export function BigIdeaFocusDropdowns({
  bigIdea,
  user,
  countryTacticsEnabled,
  bigIdeasRefetch,
}: Props) {
  const [
    showCountryAlreadyFocusedModal,
    setShowCountryAlreadyFocusedModal,
  ] = useState(false);

  const [bigIdeaCountryFocusUpdate] = useBigIdeaCountryFocusUpdateMutation();
  const [bigIdeaUpdate] = useBigIdeaUpdateMutation();
  const isOwner = user && user.id === bigIdea.user.id;
  const { isLead, isCountryContributor } = verifyUserRole(
    user?.role,
    user?.country
  );
  const canEdit = isOwner || isLead;

  const {
    modalSlideIdx,
    setModalSlideIdx,
    setShowSingleSlide,
  } = useManageFocusRationale();

  const focusedCountries = useMemo(
    () => bigIdea.BigIdeaCountryFocus.map((focus) => focus.country),
    [bigIdea.BigIdeaCountryFocus]
  );

  const contributorCountryIsFocused = useMemo(
    () =>
      Boolean(
        isCountryContributor &&
          user?.country &&
          focusedCountries.includes(user?.country)
      ),
    [focusedCountries, isCountryContributor, user?.country]
  );

  const userCountryRationale = useMemo(
    () =>
      (isCountryContributor &&
        bigIdea.BigIdeaCountryFocus.find(
          (focus) => focus.country === user?.country
        )?.rationale) ||
      '',
    [bigIdea.BigIdeaCountryFocus, isCountryContributor, user?.country]
  );

  const onCountryFocusClick = useCallback(async () => {
    setShowCountryAlreadyFocusedModal(false);
    if (!bigIdea) return;

    if (isCountryContributor) {
      const userCountry = user?.country as Country;
      if (!userCountry) return;

      const alreadyFocused = bigIdea.BigIdeaCountryFocus.find(
        (focus) => focus.country === userCountry
      );

      if (alreadyFocused) {
        const data: BigIdeaCountryFocusUpdateInput = alreadyFocused
          ? {
              removeFocusCountry: userCountry,
            }
          : {
              addFocusCountry: { focusRationale: '', country: userCountry },
            };

        try {
          await bigIdeaCountryFocusUpdate({
            variables: {
              id: bigIdea.id,
              data,
            },
          });
          bigIdeasRefetch();
        } catch (error) {
          console.error(error);
          alert('Something went wrong');
        }
      } else {
        setModalSlideIdx(1);
      }
    }
  }, [
    bigIdea,
    bigIdeaCountryFocusUpdate,
    bigIdeasRefetch,
    isCountryContributor,
    setModalSlideIdx,
    user?.country,
  ]);

  const onFocusClick = useCallback(
    async (e: React.MouseEvent<HTMLDivElement>) => {
      if (!canEdit || !bigIdea) return;

      if (isLead) {
        if (bigIdea.focus) {
          try {
            await bigIdeaUpdate({
              variables: {
                id: bigIdea.id,
                data: {
                  focus: !bigIdea.focus,
                },
              },
            });
            bigIdeasRefetch();
          } catch (error) {
            console.error(error);
            alert('Something went wrong');
          }
        } else {
          setModalSlideIdx(1);
        }
      }
    },
    [bigIdea, bigIdeaUpdate, bigIdeasRefetch, canEdit, isLead, setModalSlideIdx]
  );

  const handleRationaleSubmit = useCallback(
    async (rationale: string) => {
      if (!!bigIdea) {
        if (isCountryContributor) {
          await bigIdeaCountryFocusUpdate({
            variables: {
              id: bigIdea.id,
              data: {
                addFocusCountry: {
                  focusRationale: rationale,
                  country: user?.country as Country,
                },
              },
            },
          });
          return;
        }

        if (isLead) {
          try {
            await bigIdeaUpdate({
              variables: {
                id: bigIdea?.id,
                data: {
                  focus: true,
                  focusRationale: rationale,
                },
              },
            });
            bigIdeasRefetch();
          } catch (error) {
            console.error(error);
            alert('Something went wrong');
          }
        }
      }
    },
    [
      bigIdea,
      bigIdeaCountryFocusUpdate,
      bigIdeaUpdate,
      bigIdeasRefetch,
      isCountryContributor,
      isLead,
      user?.country,
    ]
  );

  const showContributorFocusDropdown =
    isCountryContributor && countryTacticsEnabled;

  return (
    <>
      <RationaleModal
        modalSlideIdx={modalSlideIdx}
        setModalSlideIdx={setModalSlideIdx}
        handleClose={() => {
          setModalSlideIdx(null);
        }}
        handleSubmit={handleRationaleSubmit}
        rationaleText={
          (isCountryContributor
            ? userCountryRationale
            : bigIdea?.focusRationale) || ''
        }
        showSingleSlide={true}
        modalDetails={{ 'Big idea': bigIdea?.title || '' }}
      />

      <StyledTwoButtonModal
        modalOpen={showCountryAlreadyFocusedModal}
        handleClose={() => setShowCountryAlreadyFocusedModal(false)}
        title="Already focused at the global level"
        body="Adding country focus may duplicate effort. Are you sure?"
        rightButtonText="Add country focus"
        leftButtonOnClick={() => setShowCountryAlreadyFocusedModal(false)}
        rightButtonOnClick={onCountryFocusClick}
      />

      <FocusButtons
        className="FocusButtons"
        onClick={(e) => {
          // prevent clicks on focus button from affecting the big idea card beneath
          e.stopPropagation();
        }}
      >
        <FocusDropdown
          onClick={onFocusClick}
          checked={bigIdea.focus}
          disabled={!isLead}
          rationaleText={bigIdea.focusRationale}
          setModalSlideIdx={setModalSlideIdx}
          setShowSingleSlide={setShowSingleSlide}
          isLocal={false}
          spinOffRegions={
            isCountryContributor || !countryTacticsEnabled
              ? []
              : (focusedCountries as Country[])
          }
        />
        {showContributorFocusDropdown && (
          <FocusDropdown
            title=""
            onClick={
              bigIdea.focus && !contributorCountryIsFocused
                ? () => setShowCountryAlreadyFocusedModal(true)
                : onCountryFocusClick
            }
            region={user?.country as Country}
            checked={contributorCountryIsFocused}
            disabled={!isCountryContributor}
            rationaleText={userCountryRationale}
            setModalSlideIdx={setModalSlideIdx}
            setShowSingleSlide={setShowSingleSlide}
            isLocal={true}
          />
        )}
      </FocusButtons>
    </>
  );
}
