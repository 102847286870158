import { colors } from 'constants/colors';
import styled from 'styled-components/macro';

export const CreateButtonWrapper = styled.div`
  padding-inline: 15px;
  padding-top: 15px;
`;

export const ContentWrapper = styled.div<{ topMargin: boolean }>`
  padding: 15px;
  border-radius: 5px;

  &,
  .KeyEvent_Sidebar__Flipper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
  }

  overflow: auto;

  // Reduces gap that accommodates delete button when we scroll
  margin-top: ${({ topMargin }) => (topMargin ? '15px' : 0)};
  padding-top: ${({ topMargin }) => (topMargin ? '0px' : '15px')};
`;

export const Header = styled.div`
  display: flex;
  padding: 15px 20px;
  background: ${colors.white};
  justify-content: space-between;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
