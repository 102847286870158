import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { FetchResult } from '@apollo/client';

import {
  Step,
  SubStep,
  CriticalMetricGoalsDocument,
  useCriticalMetricGoalUpdateMutation,
  CriticalMetricGoalUpdateInput,
  CriticalMetricGoalUpdateMutation,
} from 'data/graphql/generated';
import { CriticalMetricsParams } from '../../../../../shared/shared-data-access-critical-metrics/src';

export type UpdateCriticalMetricGoalResponse = FetchResult<
  CriticalMetricGoalUpdateMutation,
  Record<string, any>,
  Record<string, any>
>;

export type UpdateCriticalMetricGoalFn = (
  id: number,
  data: CriticalMetricGoalUpdateInput
) => Promise<UpdateCriticalMetricGoalResponse>;

export const useUpdateCriticalMetricGoal = () => {
  const {
    strategyId,
    competitiveAdvantageRowUrlParam,
  } = useParams<CriticalMetricsParams>();

  const [updateRow] = useCriticalMetricGoalUpdateMutation();

  const handleUpdateRow = useCallback<UpdateCriticalMetricGoalFn>(
    async (id, data) => {
      return await updateRow({
        variables: { id, data },
        refetchQueries: [
          {
            query: CriticalMetricGoalsDocument,
            variables: {
              where: {
                strategyId: +strategyId,
                competitiveAdvantageRowId: +competitiveAdvantageRowUrlParam,
                step: Step.CriticalMetrics,
                subStep: SubStep.Goals,
              },
            },
            fetchPolicy: 'cache-only',
          },
        ],
      });
    },
    [strategyId, competitiveAdvantageRowUrlParam, updateRow]
  );

  return handleUpdateRow;
};
