import React, { useEffect } from 'react';
import { Icon, Subtitle2 } from 'components/shared';
import {
  Sort,
  Stakeholder,
  useKeyInsightsQuery,
  usePatientJourneyColumnsForBubbleSummaryQuery,
  useStrategyQuery,
} from 'data/graphql/generated';
import { polling } from '../../constants';
import { colors } from 'constants/colors';
import { Loading } from 'components/Loading';
import {
  Column,
  ColumnTitle,
  EmptyStateText,
  KeyInsightsList,
  DownloadButton,
} from './BubbleSummary';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { ScrollableFadeContainer } from 'components/shared/ScrollableFadeContainer';
import exportSummaryOne from 'utils/export/summaryOne';

interface Props {
  strategyId: number;
  dataLoaded(): void;
}

export const BubbleSummaryOneContent: React.FC<Props> = ({
  strategyId,
  dataLoaded,
}) => {
  const {
    data,
    loading,
    error,
    startPolling,
    stopPolling,
  } = usePatientJourneyColumnsForBubbleSummaryQuery({
    variables: { where: { strategyId }, orderBy: { idx: Sort.Asc } },
    fetchPolicy: 'network-only',
  });

  const {
    data: keyInsightsData,
    loading: keyInsightsLoading,
    error: keyInsightsError,
    startPolling: keyInsightsStartPolling,
    stopPolling: keyInsightsStopPolling,
  } = useKeyInsightsQuery({
    variables: { where: { strategyId, inValidPatientJourneyColumn: true } },
    fetchPolicy: 'network-only',
  });

  const {
    data: strategyData,
    loading: strategyLoading,
    error: strategyError,
  } = useStrategyQuery({
    variables: { id: Number(strategyId) },
  });

  const columns = data?.patientJourneyColumns.items;

  useEffect(() => {
    startPolling(polling.default);
    keyInsightsStartPolling(polling.default);

    return () => {
      stopPolling();
      keyInsightsStopPolling();
    };
  }, [
    keyInsightsStartPolling,
    keyInsightsStopPolling,
    startPolling,
    stopPolling,
  ]);

  useEffect(() => {
    if (!loading) {
      dataLoaded();
    }
  }, [loading, dataLoaded]);

  if (loading || strategyLoading) {
    return (
      <Loading style={{ margin: '50px auto auto' }} textColor={colors.white} />
    );
  }

  const keyInsights = keyInsightsData?.keyInsights?.items;

  const columnData = columns?.map((c) => {
    const insights = keyInsights?.filter((insight) => {
      return insight.columnId === c.id;
    });

    return {
      ...c,
      patientInsights: insights?.filter(
        (k) => k.stakeholder === Stakeholder.Patient
      ),
      healthcareInsights: insights?.filter(
        (k) => k.stakeholder === Stakeholder.Healthcare
      ),
      payorInsights: insights?.filter(
        (k) => k.stakeholder === Stakeholder.Payor
      ),
      providerInsights: insights?.filter(
        (k) => k.stakeholder === Stakeholder.Provider
      ),
      policyMakersInsights: insights?.filter(
        (k) => k.stakeholder === Stakeholder.Policymaker
      ),
    };
  });

  return (
    <>
      <DownloadButton
        onClick={() =>
          exportSummaryOne({
            brand: strategyData?.strategy?.drug?.name || '',
            startYear: strategyData?.strategy?.startYear || 0,
            endYear: strategyData?.strategy?.endYear || 0,
            data: columnData,
          })
        }
      >
        <Icon name="Download" size={18} color={colors.black} />
      </DownloadButton>

      <ErrorWrapper
        errors={[error, strategyError || keyInsightsError]}
        isLoading={loading || strategyLoading || keyInsightsLoading}
        dataMissing={!columns || !keyInsightsData}
      >
        {columnData?.map((c) => {
          return (
            <Column key={c.id}>
              <ColumnTitle>
                <Subtitle2>{c.name}</Subtitle2>
              </ColumnTitle>

              {c.KeyInsight?.length ? (
                <ScrollableFadeContainer>
                  {!!c.patientInsights?.length && (
                    <KeyInsightsList
                      title="Patients"
                      insights={c.patientInsights}
                    />
                  )}
                  {!!c.healthcareInsights?.length && (
                    <KeyInsightsList
                      title="Healthcare Professionals"
                      insights={c.healthcareInsights}
                    />
                  )}
                  {!!c.payorInsights?.length && (
                    <KeyInsightsList
                      title="Payors"
                      insights={c.payorInsights}
                    />
                  )}
                  {!!c.providerInsights?.length && (
                    <KeyInsightsList
                      title="Providers"
                      insights={c.providerInsights}
                    />
                  )}
                  {!!c.policyMakersInsights?.length && (
                    <KeyInsightsList
                      title="Policymakers"
                      insights={c.policyMakersInsights}
                    />
                  )}
                </ScrollableFadeContainer>
              ) : (
                <EmptyStateText>No insights at this stage</EmptyStateText>
              )}
            </Column>
          );
        })}
      </ErrorWrapper>
    </>
  );
};
