import { ApolloCache, DocumentNode } from '@apollo/client';

interface ApolloCreateHelper {
  responseField: string;
  query: DocumentNode;
  queryVars: any;
  queryName: string;
}

export function apolloCreateHelper({
  responseField,
  query,
  queryVars,
  queryName,
}: ApolloCreateHelper) {
  if (!responseField || !query || !queryVars || !queryName) {
    console.log('missing prop');
  }

  // @ts-ignore
  return function (proxy: ApolloCache, data: any) {
    if (!data || !query || !queryVars || !queryName) return;
    const d = data?.data[responseField];
    const queryData = proxy.readQuery({
      query: query,
      variables: queryVars,
    });
    if (!d || !queryData || !queryData[queryName]) return;
    const newItems = [...queryData[queryName].items, d];
    const newTotal = queryData[queryName].total + 1;
    // TODO do we need to remove the card with .id === 99999999
    proxy.writeQuery({
      query,
      variables: queryVars,
      data: {
        [queryName]: {
          ...queryData[queryName],
          items: newItems,
          total: newTotal,
        },
      },
    });
  };
}

export function apolloUpdateHelper({
  responseField,
  query,
  queryVars,
  queryName,
}: ApolloCreateHelper) {
  if (!responseField || !query || !queryVars || !queryName) {
    console.log('missing prop');
  }

  // @ts-ignore
  return function (proxy: ApolloCache, data: any) {
    if (!data || !query || !queryVars || !queryName) return;
    const d = data?.data[responseField];
    const queryData = proxy.readQuery({
      query: query,
      variables: queryVars,
    });

    if (!d || !queryData || !queryData[queryName]) return;

    if (queryData[queryName]?.items) {
      const newItems = queryData[queryName]?.items.map((i: any) => {
        if (i?.id === d.id) return { ...i, ...d };
        return i;
      });

      proxy.writeQuery({
        query,
        variables: queryVars,
        data: {
          [queryName]: {
            ...queryData[queryName],
            items: newItems,
          },
        },
      });
    } else {
      proxy.writeQuery({
        query,
        variables: queryVars,
        data: {
          [queryName]: {
            ...queryData[queryName],
          },
        },
      });
    }
  };
}

export function apolloUpsertHelper({
  responseField,
  query,
  queryVars,
  queryName,
}: ApolloCreateHelper) {
  if (!responseField || !query || !queryVars || !queryName) {
    console.log('missing prop');
  }

  // @ts-ignore
  return function (proxy: ApolloCache, data: any) {
    if (!data || !query || !queryVars || !queryName) return;
    const d = data?.data[responseField];
    const queryData = proxy.readQuery({
      query: query,
      variables: queryVars,
    });

    if (!d || !queryData || !queryData[queryName]) return;

    const isUpdate = queryData[queryName].items.find(
      (item: any) => item.id === d.id
    );

    let newItems;

    if (isUpdate) {
      newItems = queryData[queryName].items.map((i: any) => {
        if (i?.id === d.id) return { ...i, ...d };
        return i;
      });
    } else {
      newItems = [...queryData[queryName].items, d];
    }

    proxy.writeQuery({
      query,
      variables: queryVars,
      data: {
        [queryName]: {
          ...queryData[queryName],
          items: newItems,
          total: newItems.length,
        },
      },
    });
  };
}

export function apolloDeleteHelper({
  responseField,
  query,
  queryVars,
  queryName,
}: ApolloCreateHelper) {
  if (!responseField || !query || !queryVars || !queryName) {
    console.log('missing prop');
  }

  // @ts-ignore
  return function (proxy: ApolloCache, data: any) {
    if (!data || !query || !queryVars || !queryName) return;
    const d = data?.data[responseField];

    const queryData = proxy.readQuery({
      query: query,
      variables: queryVars,
    });
    if (!d || !queryData[queryName]) return;
    const newItems = queryData[queryName].items.filter((x: any) => {
      return x?.id !== d.id;
    });

    const newTotal = queryData[queryName].total - 1;
    proxy.writeQuery({
      query,
      variables: queryVars,
      data: {
        [queryName]: {
          ...queryData[queryName],
          items: newItems,
          total: newTotal,
        },
      },
    });
  };
}
