import { PostItsEmpty } from 'components/PostItsEmpty';
import { colors } from 'constants/index';
import { LongTermStrategyTimelinesQuery } from 'data/graphql/generated';
import { useEffect, useRef, useState } from 'react';
import { KeyDatesModal } from './KeyDatesModal';
import styled from 'styled-components/macro';
import { BodyNormal, BodySmall, Caption } from 'components/shared';
import { formatTimestamp } from 'utils/formatTimestamp';
import ChartGraph from './ChartGraph';
import { ChartItems } from './ChartItems';

export const DIVIDER_WIDTH = 5;

const KeyDateDetails = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.greyLight};
  border-radius: 5px;

  width: 112px;
  position: absolute;
  top: 5px;
  margin: 0 5px;
  padding: 4px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  grid-column: 3/3;
  grid-row: 1/1;
  gap: 5px;
`;

const GraphWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  padding-top: 9px;
`;

const Column = styled.div<{ flex: string }>`
  flex: ${({ flex }) => flex};
`;

const ChartWrapper = styled.div<{ columnSectionWidth: number }>`
  grid-column: 3/3;
  grid-row: 2/2;
  width: 100%;
  height: 100vh;
  display: flex;
  background: ${colors.lightPinkGradient};
  min-width: 882px;
  // borders
  position: relative;
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: -2px;
    width: 2px;
    height: calc(100% + 2px);
    background: ${colors.purple};
  }

  ::after {
    content: '';
    position: absolute;
    bottom: -2px;
    right: 0;
    height: 2px;
    width: calc(100% + 2px);
    background: ${colors.purple};
  }

  ${Column}:not(:first-of-type) {
    // BG
    background-image: ${({ columnSectionWidth }) => `repeating-linear-gradient(
    to right,
    
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) ${columnSectionWidth}px,
    ${colors.black10a} ${columnSectionWidth}px,
    ${colors.black10a} ${columnSectionWidth + 1}px
  );`};
  }
`;

const ColumnHeader = styled.div<{ flex: string }>`
  flex: ${({ flex }) => flex};
  height: 30px;
  background-color: ${colors.purple15};
  border-radius: 5px 5px 0px 0px;
  display: grid;
  place-items: center;
`;

const RightLabel = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 25px;
  grid-column: 3/3;
  grid-row: 4/4;
`;

const LeftLabel = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  transform: scale(-1);
  writing-mode: vertical-rl;

  grid-column: 1/1;
  grid-row: 2/2;
`;

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 30px 2px 1fr;
  grid-template-rows: 30px 1fr 2px 30px;
`;

export type TimelineData = {
  type: TypeNames;
  text: string;
};

export type CombinedData = Record<number, TimelineData[]>;

export type TypeNames =
  | 'BigIdeaDetail'
  | 'Solution'
  | 'LongTermSolutionDetail'
  | undefined;

type Props = {
  showKeyDatesModal: boolean;
  closeKeyDatesModal(): void;
  data: CombinedData;
  timelineDatesData: LongTermStrategyTimelinesQuery | undefined;
};

export const LongTermStrategySummaryTimeline = ({
  data,
  timelineDatesData,
  showKeyDatesModal,
  closeKeyDatesModal,
}: Props) => {
  const [wrapperDimensions, setWrapperDimensions] = useState<DOMRect>();
  const chartWrapper = useRef<HTMLDivElement | null>(null);

  const [enableGraphTransitions, setEnableGraphTransitions] = useState(false);

  useEffect(() => {
    function setDimensions() {
      setWrapperDimensions(chartWrapper.current?.getBoundingClientRect());
    }

    setDimensions();
    window.addEventListener('resize', setDimensions);
    return () => {
      window.removeEventListener('resize', setDimensions);
    };
  }, [timelineDatesData, data, wrapperDimensions?.width]);

  const longTermStrategyTimeline =
    timelineDatesData?.longTermStrategyTimelines?.items?.[0];

  if (!longTermStrategyTimeline) {
    return null;
  }

  return (
    <div style={{ paddingTop: 5 }}>
      {showKeyDatesModal && (
        <KeyDatesModal
          enableGraphTransitions={() => {
            setEnableGraphTransitions(true);
          }}
          closeModal={() => {
            closeKeyDatesModal();
          }}
          visible={showKeyDatesModal}
          data={longTermStrategyTimeline}
        />
      )}

      {!longTermStrategyTimeline.approval ? (
        <PostItsEmpty
          title="No key dates set yet"
          subtitle="A Brand Lead should set key dates to generate the timeline"
        />
      ) : (
        <Wrapper>
          <LeftLabel>Market share</LeftLabel>
          <RightLabel>Time</RightLabel>
          <HeaderWrapper>
            <ColumnHeader flex="0.090909090909091">
              <BodyNormal>Year 0</BodyNormal>
            </ColumnHeader>
            <ColumnHeader flex="0.27272727272727">
              <BodyNormal>Years 1 - 3</BodyNormal>
            </ColumnHeader>
            <ColumnHeader flex="0.36363636363636">
              <BodyNormal>Years 4 - 7</BodyNormal>
            </ColumnHeader>
            <ColumnHeader flex="0.27272727272727">
              <BodyNormal>Years 8 - 10</BodyNormal>
            </ColumnHeader>
          </HeaderWrapper>
          <ChartWrapper
            columnSectionWidth={
              ((wrapperDimensions?.width || 0) - 15) * 0.090909090909091
            }
            ref={chartWrapper}
            style={{
              maxHeight: `calc(100vh - ${
                wrapperDimensions?.top || 0
              }px - 60px)`,
            }}
          >
            <Column flex="0.090909090909091">
              <KeyDateDetails>
                <span>
                  <Caption color={colors.greyDark}>Approval</Caption>
                  <BodySmall>
                    {getTimestamp(longTermStrategyTimeline.approval)}
                  </BodySmall>
                </span>
                <span>
                  <Caption color={colors.greyDark}>Launch</Caption>
                  <BodySmall>
                    {getTimestamp(longTermStrategyTimeline.launch)}
                  </BodySmall>
                </span>
                <span>
                  <Caption color={colors.greyDark}>Peak sales</Caption>

                  <BodySmall>
                    {getTimestamp(longTermStrategyTimeline.peakSales)}
                  </BodySmall>
                </span>
                <span>
                  <Caption color={colors.greyDark}>Loss of exclusivity</Caption>
                  <BodySmall>
                    {getTimestamp(longTermStrategyTimeline.lossOfExclusivity)}
                  </BodySmall>
                </span>
              </KeyDateDetails>
            </Column>
            <Divider />
            <Column flex="0.27272727272727"></Column>
            <Divider />
            <Column flex="0.36363636363636"></Column>
            <Divider />
            <Column flex="0.27272727272727"></Column>
            <GraphWrapper>
              <ChartGraph
                enableGraphTransitions={enableGraphTransitions}
                dates={longTermStrategyTimeline}
                containerDimensions={wrapperDimensions}
              />
            </GraphWrapper>
            <ChartItems
              data={data}
              approvalDate={longTermStrategyTimeline.approval}
              containerDimensions={wrapperDimensions}
            />
          </ChartWrapper>
        </Wrapper>
      )}
    </div>
  );
};

const Divider = () => {
  return <div style={{ width: DIVIDER_WIDTH, background: colors.black10 }} />;
};

const getTimestamp = (value: string | null | undefined) => {
  return value ? formatTimestamp(value) : '';
};
