import {
  CriticalMetricGoalFrequency,
  CriticalMetricGoalInput,
} from 'data/graphql/generated';
import { getFocusDisabledText } from './getFocusDisabledText';
import { validateRowData } from './validateRowData';

interface GoalTableRowValidationProps {
  isLead: boolean;
  isContributor: boolean;
  data: {
    goal?: string | null;
    target?: number | null;
    frequency?: CriticalMetricGoalFrequency | null;
    input?: CriticalMetricGoalInput | null;
  };
}

export const useGoalTableRowValidation = ({
  isLead,
  isContributor,
  data,
}: GoalTableRowValidationProps) => {
  const isAllowedToEdit = isLead || isContributor;
  const isFormValid = validateRowData({
    goal: data.goal,
    target: data.target,
    frequency: data.frequency,
    input: data.input,
  });

  const isAllowedToFocus = isLead && isFormValid;
  const isFocusButtonDisabled = !isAllowedToFocus;
  const focusButtonDisabledText = getFocusDisabledText(isLead, isFormValid);

  return {
    isAllowedToEdit,
    isAllowedToFocus,
    isFocusButtonDisabled,
    focusButtonDisabledText,
  };
};
