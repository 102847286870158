import { colors } from 'constants/index';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { IconContainer } from './Icon';
import { MoreTextIcon } from './MoreTextIcon';
import { ButtonLabel, Caption } from './TextStyles';

const StyledMoreTextIcon = styled(MoreTextIcon)`
  margin-right: -10px;
`;

interface Props {
  id: string;
  text: string;
  secondary: string;
  active?: boolean;
  onClick(v: string): void;
  displaySummary: () => void;
  revealTruncatedText?: boolean;
  sidebarOpen: boolean;
  disableSidebar?: boolean;
}

interface ActiveProps {
  active: boolean;
}

const BottomBar = styled.div<ActiveProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: ${({ active }) => (active ? colors.purple : colors.white)};
`;

const PaddedWrapper = styled.div`
  padding: 0 2px;
`;

const Wrapper = styled.div<{ active: boolean, sidebarOpen: boolean, disableSidebar: boolean}>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  width: 240px;

  padding: 5px 15px 7px;
  
  ${({ sidebarOpen, active , disableSidebar }) =>
    !sidebarOpen && active && !disableSidebar &&
    css`
      padding-right: 40px;
    `}
  overflow-y: visible;

  cursor: pointer;
  pointer-events: all;
  background: ${colors.white};
  border-radius: 5px 5px 0px 0px;

  .caption {
    position: absolute;
    bottom: 5px;
  }

  ${BottomBar} {
    transition-property: background-color, color;
    transition-duration: 0.3s;
  }

  &:hover {
    ${BottomBar} {
      background-color: ${({ active }) =>
        active ? colors.purple : colors.purple30};
    }
  }
  transition: background-color 0.3s ease;
`;

const LabelWrapper = styled.div<{ revealTruncatedText: boolean }>`
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  background: ${colors.white};
  z-index: 1;
  transition: height 0.3s ease;
  ${({ revealTruncatedText }) =>
    revealTruncatedText &&
    css`
      &:hover {
        overflow: visible;
        -webkit-line-clamp: 1000;
        height: auto;
      }
    `}
  align-self: self-start;
`;

const IconFadeContainer = styled.div`
  position: absolute;
  right: 0;
  z-index: 1;
  width: 45px;
  top: 3px;
  bottom: 3px;
  display: flex;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.01) 0%,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 1) 100%
  );

  ${IconContainer} {
    transform: translateX(10px);
  }
`;

export const ContributionTab = ({
  id,
  text,
  active = false,
  onClick,
  secondary,
  displaySummary,
  revealTruncatedText = true,
  sidebarOpen,
  disableSidebar = false,
}: Props) => {
  return (
    <PaddedWrapper
      id={id}
      onClick={() => onClick(id.replace('contribution-tab-', ''))}
      className="contribution-tab"
    >
      <Wrapper active={active} sidebarOpen={sidebarOpen} disableSidebar={disableSidebar}>
        <LabelWrapper revealTruncatedText={revealTruncatedText}>
          <ButtonLabel>{text}</ButtonLabel>
        </LabelWrapper>
        {!disableSidebar && active && !sidebarOpen && (
          <IconFadeContainer data-cy="tab-sidebar-btn">
            <StyledMoreTextIcon
              size={30}
              color={colors.greyDark}
              onClick={displaySummary}
            />
          </IconFadeContainer>
        )}
        <Caption className="caption" color={colors.greyDark}>
          {secondary}
        </Caption>
        <BottomBar active={active} />
      </Wrapper>
    </PaddedWrapper>
  );
};
