import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { Navbar } from 'components/Navbar';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Link,
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { StepBarsWrapper, TabGroupWrapper } from './StrategicQuestion';

import { LoadingComponent } from 'components/Loading';
import { PostIts } from 'components/PostIts';
import { PostItsEmpty } from 'components/PostItsEmpty';
import {
  BodyNormal,
  BodySmall,
  ContributionTab,
  KeyStatements,
  StepTab,
  Subtitle2,
  TabGroup,
} from 'components/shared';
import { CountriesDropdownSmall } from 'components/shared/CountriesDropdownSmall';
import { ImperativeSummarySidebar } from 'components/shared/ImperativeSummarySidebar';
import {
  MainContent,
  PostItsPageWrapper,
  SidebarDesktopWrapper,
  SidebarMobileWrapper,
  StepHeader,
} from 'components/shared/SidebarPageComponents';
import { colors, polling } from 'constants/index';
import { useAuthContext } from 'contexts/AuthContext';
import {
  KeyStatementsDocument,
  Sort,
  Step,
  SubStep,
  SuccessConditionType,
  useCommercialTacticsQuery,
  useCompetitiveAdvantageRowsQuery,
  useGlobalKeyStatementsQuery,
  useKeyStatementDeleteMutation,
  useKeyStatementUpdateMutation,
  useKeyStatementUpsertMutation,
  useKeyStatementsQuery,
  useStrategyQuery,
} from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import useGetElementTop from 'hooks/useGetElementTop';
import useHandleRegionDropdown from 'hooks/useHandleRegionDropdown';
import { usePostItCardMove } from 'hooks/usePostItCardMove';
import { usePostItCards } from 'hooks/usePostItCards';
import { usePostItGroups } from 'hooks/usePostItGroups';
import { camelCase, kebabCase } from 'lodash';
import { CountryGlobal } from 'types';
import URLHelper from 'utils/URLHelper';
import {
  apolloCreateHelper,
  apolloDeleteHelper,
} from 'utils/apolloQueryHelpers';
import { getUniqueRegions } from 'utils/getUniqueRegions';
import useSidePaneTopOffset from 'utils/useSidePaneTopOffset';
import { verifyUserRole } from 'utils/verifyUserRole';
import { sortPostIts, useCommercialStrategyNav } from '../hooks';
import { useGlobalContext } from 'contexts/GlobalContext';

const overlapWidth = 675;

interface URLParams {
  drugId: string;
  strategyId: string;
  substep: string;
  competitiveAdvantageRowUrlParam: string;
  region: string;
}

export const CriticalSuccessFactors = () => {
  const {
    drugId,
    strategyId,
    substep,
    competitiveAdvantageRowUrlParam,
    region,
  }: URLParams = useParams();

  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);
  const {
    selectedRegion,
    setSelectedRegion,
    viewingOwnRegion,
    countryDropdownActive,
    setCountryDropdownActive,
    countryDropDownRef,
  } = useHandleRegionDropdown({ region, user, strategyId: +strategyId });
  const isDesktop = useDesktop();
  const history = useHistory();
  const totalNavHeight = isDesktop ? 110 : 160;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const sidebarDesktopRef = useRef<HTMLDivElement | null>(null);
  const desktopWrapperTop = useGetElementTop(sidebarDesktopRef);
  const step = Step.DistinctiveCapabilities;
  const topOffset = useSidePaneTopOffset(sidebarDesktopRef, totalNavHeight);
  const { path } = useRouteMatch();

  const [activeSubstep, setActiveSubstep] = useState(
    camelCase(substep) || SubStep.StrategicObjectives
  );
  const activeSubstepCamelCase = camelCase(activeSubstep) as SubStep;
  const isStrategicObjectivesStep =
    substep === kebabCase(SubStep.StrategicObjectives);
  const isCapabilitiesStep = substep === SubStep.Capabilities;

  // handle global context path
  const [objectiveAndDistinctive, setObjectiveAndDistinctive] = useGlobalContext(
    'objectiveAndDistinctive'
  );

  // handle global context path
  const [strategicImperativeId, setStrategicImperativeId] = useGlobalContext(
    'strategicImperativeId'
  );


  const {
    data: strategyData,
    loading: strategyLoading,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
    error: strategyError,
  } = useStrategyQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'network-only',
  });

  const strategy = strategyData?.strategy;
  const uniqueRegions = getUniqueRegions(strategy?.users || [], false).sort();

  const {
    data: competitiveAdvantageRowsData,
    error: competitiveAdvantageRowsError,
    loading: competitiveAdvantageRowsLoading,
    startPolling: startCompetitiveAdvantageRowsPolling,
    stopPolling: stopCompetitiveAdvantageRowsPolling,
  } = useCompetitiveAdvantageRowsQuery({
    variables: {
      orderBy: {
        idx: Sort.Desc,
      },
      where: {
        strategyId: +strategyId,
        focus: true,
      },
      include: {
        strategicPossibility: true,
        successConditions: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  const competitiveAdvantageRows =
    competitiveAdvantageRowsData?.competitiveAdvantageRows?.items;

  const selectedCompetitiveAdvantageRow = competitiveAdvantageRows?.find(
    (row) => row.id === +competitiveAdvantageRowUrlParam
  );
  const selectedRowImperative =
    selectedCompetitiveAdvantageRow?.strategicPossibility;
  const selectedRowConditions = selectedRowImperative?.successConditions;
  const selectedRowDrivers = selectedRowConditions
    ?.filter((c) => c.type === SuccessConditionType.Driver)
    .map((el) => el.text);

  const selectedRowBarriers = selectedRowConditions
    ?.filter((c) => c.type === SuccessConditionType.Barrier)
    .map((el) => el.text);

  const keyStatementsQueryVars = {
    where: {
      strategyId: +strategyId,
      step,
      subStep: activeSubstepCamelCase,
      competitiveAdvantageRowId: +competitiveAdvantageRowUrlParam,
      region: selectedRegion,
    },
  };

  const {
    data: keyStatementsData,
    error: keyStatementsError,
    loading: keyStatementsLoading,
    startPolling: startKeyStatementsPolling,
    stopPolling: stopKeyStatementsPolling,
  } = useKeyStatementsQuery({
    variables: keyStatementsQueryVars,
  });

  const {
    data: objectivesData,
    loading: objectivesDataLoading,
    startPolling: keyStatementsObjectivesPollingStart,
    stopPolling: keyStatementsObjectivesPollingStop,
    // NOTE need to call the same query twice with different props for the strategic objectives
  } = useGlobalKeyStatementsQuery({
    variables: {
      strategyId: +strategyId,
      step: Step.DistinctiveCapabilities,
      subStep: SubStep.StrategicObjectives,
      region: selectedRegion === 'global' ? undefined : selectedRegion,
    },
  });

  const keyStatements =
    keyStatementsData?.keyStatements?.items.filter((k) => {
      // NOTE when clicking fast through imperatives, keystatements are shown for wrong one
      return k.competitiveAdvantageRowId === +competitiveAdvantageRowUrlParam;
    }) || [];

  const selectedStrategicObjectives = useMemo(() => {
    if (selectedRegion === 'global') {
      return (
        objectivesData?.global?.items.filter(
          (v) =>
            v.competitiveAdvantageRowId === +competitiveAdvantageRowUrlParam
        ) || []
      ).map((k) => k.text);
    }

    const globalStrategicObjectives = (
      objectivesData?.global?.items.filter(
        (v) =>
          v.competitiveAdvantageRowId === +competitiveAdvantageRowUrlParam &&
          v.region === 'global'
      ) || []
    ).map((k) => k.text);

    const regionalStrategicObjectives = (
      objectivesData?.regional?.items.filter(
        (v) =>
          v.competitiveAdvantageRowId === +competitiveAdvantageRowUrlParam &&
          v.region === selectedRegion
      ) || []
    ).map((k) => k.text);

    return {
      global: globalStrategicObjectives,
      regional: regionalStrategicObjectives,
    };
  }, [
    competitiveAdvantageRowUrlParam,
    objectivesData?.global?.items,
    objectivesData?.regional?.items,
    selectedRegion,
  ]);

  const [upsertKeyStatement] = useKeyStatementUpsertMutation();
  const [updateKeyStatement] = useKeyStatementUpdateMutation();
  const [deleteKeyStatement] = useKeyStatementDeleteMutation();

  const {
    items: groupItems,
    loading: groupsLoading,
    createGroup,
    updateGroup,
    removeGroup,
    error: groupsError,
  } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.DistinctiveCapabilities,
        substep:
          substep === SubStep.Capabilities
            ? SubStep.Capabilities
            : SubStep.StrategicObjectives,
        competitiveAdvantageRowId: +competitiveAdvantageRowUrlParam,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.DistinctiveCapabilities,
      substep:
        substep === SubStep.Capabilities
          ? SubStep.Capabilities
          : SubStep.StrategicObjectives,
      competitiveAdvantageRowId: +competitiveAdvantageRowUrlParam,
    }
  );
  // Fetch commercial tactics
  const commercialTacticsQueryVars = {
    where: {
      strategyId: +strategyId,
    },
    orderBy: {
      createdAt: Sort.Desc,
    },
  };

  const {
    data: tacticsData,
  } = useCommercialTacticsQuery({
    variables: commercialTacticsQueryVars,
    fetchPolicy: 'network-only',
  });

  const commercialTactics =
    tacticsData?.commercialTactics?.items.filter((k) => {
      return k.strategyId === +strategyId;
    }).map((t) => {
      return {
        keyStatementId: t.keyStatementId,
        focused: t.focused,
        text: t.text,
        id: t.id
      }
    }) || [];

  // get country tactics status in 4.2 or 4.3
  const {
    localCountryTacticsEnabled,
  } = useCommercialStrategyNav({ strategyId: strategyId ? +strategyId : 0 });

  const {
    items: cardItems,
    loading: cardsLoading,
    createCard,
    updateCard,
    removeCard,
    error: cardsError,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.DistinctiveCapabilities,
        substep:
          substep === SubStep.Capabilities
            ? SubStep.Capabilities
            : SubStep.StrategicObjectives,
        competitiveAdvantageRowId:
          +competitiveAdvantageRowUrlParam || undefined,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.DistinctiveCapabilities,
      substep:
        substep === SubStep.Capabilities
          ? SubStep.Capabilities
          : SubStep.StrategicObjectives,
      competitiveAdvantageRowId: +competitiveAdvantageRowUrlParam || undefined,
    }
  );

  const groups = sortPostIts(groupItems, cardItems);

  const onChange = usePostItCardMove(groups, updateCard);

  useEffect(() => {
    const compAdvantageRowExists = competitiveAdvantageRows?.[0]?.id;
    //set url on mount
    if (compAdvantageRowExists) {
      const generatedPath = generatePath(path, {
        drugId,
        strategyId,
        substep: kebabCase(activeSubstep),
        competitiveAdvantageRowUrlParam: !strategicImperativeId ? !selectedCompetitiveAdvantageRow
          ? compAdvantageRowExists
          : selectedCompetitiveAdvantageRow.id : strategicImperativeId,

        region: selectedRegion,
      });

      history.push(generatedPath, '');
    }
  }, [
    strategicImperativeId,
    activeSubstep,
    competitiveAdvantageRows,
    drugId,
    history,
    path,
    selectedCompetitiveAdvantageRow,
    selectedRegion,
    strategyId,
  ]);

  useEffect(() => {
    // update state based on URL
    if (!!region) {
      setSelectedRegion(
        (region?.replaceAll('-', ' ') as CountryGlobal) || 'global'
      );
    }

    if (objectiveAndDistinctive) {
      setActiveSubstep(camelCase(objectiveAndDistinctive));
    } else if (!!substep) {
      setActiveSubstep(camelCase(substep));
    }
  }, [substep, region, setSelectedRegion, objectiveAndDistinctive]);

  useEffect(() => {
    startCompetitiveAdvantageRowsPolling(polling.default);
    startKeyStatementsPolling(polling.default);
    keyStatementsObjectivesPollingStart(polling.default);
    startStrategyPolling(polling.default);

    return () => {
      stopCompetitiveAdvantageRowsPolling();
      stopKeyStatementsPolling();
      keyStatementsObjectivesPollingStop();
      stopStrategyPolling();
    };
  }, [
    startCompetitiveAdvantageRowsPolling,
    startKeyStatementsPolling,
    stopCompetitiveAdvantageRowsPolling,
    stopKeyStatementsPolling,
    keyStatementsObjectivesPollingStart,
    keyStatementsObjectivesPollingStop,
    startStrategyPolling,
    stopStrategyPolling,
  ]);

  const isLoading =
    competitiveAdvantageRowsLoading ||
    keyStatementsLoading ||
    groupsLoading ||
    cardsLoading ||
    objectivesDataLoading ||
    strategyLoading;
  return (
    <>
      <Navbar
        stepNumber="3.4"
        title="Goals and Distinctive Capabilities"
        prev={{
          url: `/d/${drugId}/strategy/${strategyId}/3_3`,
          title: 'Core Value Messages',
        }}
        next={{
          url: URLHelper.getCriticalMetricsLeadAndLagIndicatorsUrl(
            drugId,
            strategyId
          ),
          title: 'Lead and Lag Indicators',
        }}
      >
        <TabGroupWrapper>
          <TabGroup componentName="StepTab">
            <StepBarsWrapper>
              {[
                {
                  title: 'Part 1: Goals',
                  substep: SubStep.StrategicObjectives,
                },
                {
                  title: 'Part 2: Distinctive Capabilities',
                  substep: SubStep.Capabilities,
                },
              ].map((v) => {
                return (
                  <StepTab
                    key={v.substep}
                    active={activeSubstep === v.substep}
                    text={v.title}
                    onClick={() => {
                      setActiveSubstep(v.substep);
                      setObjectiveAndDistinctive(v.substep)
                    }}
                  />
                );
              })}
            </StepBarsWrapper>
          </TabGroup>
        </TabGroupWrapper>
      </Navbar>

      <SidebarMobileWrapper totalNavHeight={totalNavHeight}>
        <StepHeader>
          <Subtitle2 style={{ marginBottom: '5px' }}>
            {activeSubstep === SubStep.StrategicObjectives
              ? 'Consider the strategic objectives for each strategic imperative.'
              : 'Consider the capabilities that will ensure your strategy is distinctive'}{' '}
          </Subtitle2>
          <BodyNormal
            color={colors.greyDark}
            style={{ display: 'inline-block', marginBottom: 15 }}
          >
            {activeSubstep === SubStep.StrategicObjectives
              ? 'Refer to drivers and barriers to identify themes. Add notes with suggestions. Leads will summarise the key goals.'
              : 'Refer to the goals to identify themes. Add notes with suggestions. Leads will summarise the key capabilities.'}
          </BodyNormal>
          {!!competitiveAdvantageRows?.length && (
            <>
              <TabGroup componentName="ContributionTab">
                {competitiveAdvantageRows?.map((competitiveAdvantageRow) => {
                  return (
                    <ContributionTab
                      key={competitiveAdvantageRow.id}
                      id={`contribution-tab-${competitiveAdvantageRow.strategicPossibility?.id}`}
                      secondary=""
                      text={
                        competitiveAdvantageRow.strategicPossibility?.name || ''
                      }
                      active={
                        +competitiveAdvantageRowUrlParam ===
                        competitiveAdvantageRow.id
                      }
                      onClick={() => {
                        const generatedPath = generatePath(path, {
                          drugId,
                          strategyId,
                          substep: kebabCase(activeSubstep),
                          competitiveAdvantageRowUrlParam:
                            competitiveAdvantageRow.id,
                          region: selectedRegion,
                        });

                        history.push(generatedPath);
                        setStrategicImperativeId(competitiveAdvantageRow.id)
                      }}
                      displaySummary={() => setSidebarOpen(true)}
                      revealTruncatedText={false}
                      sidebarOpen={sidebarOpen}
                    />
                  );
                })}
              </TabGroup>
              <div
                style={{
                  borderBottom: `1px solid ${colors.greyMedium}`,
                  position: 'absolute',
                  width: '100%',
                  left: 0,
                }}
              />
            </>
          )}
        </StepHeader>

        <SidebarDesktopWrapper
          ref={sidebarDesktopRef}
          sidebarOpen={sidebarOpen}
          isRelative={desktopWrapperTop > totalNavHeight}
          totalNavHeight={topOffset}
        >
          <ImperativeSummarySidebar
            activeImperative={selectedRowImperative}
            activeImperativeId={selectedRowImperative?.id}
            drivers={isStrategicObjectivesStep ? selectedRowDrivers : undefined}
            barriers={
              isStrategicObjectivesStep ? selectedRowBarriers : undefined
            }
            keyIssue={selectedCompetitiveAdvantageRow?.text}
            selectedRegion={selectedRegion}
            strategicObjectives={
              isCapabilitiesStep ? selectedStrategicObjectives : undefined
            }
            sidebarOpen={sidebarOpen && !!competitiveAdvantageRowUrlParam}
            setSidebarOpen={setSidebarOpen}
            desktopWrapperTop={desktopWrapperTop}
            totalNavHeight={totalNavHeight}
          />

          <ErrorWrapper
            isLoading={isLoading}
            errors={[
              keyStatementsError,
              groupsError,
              cardsError,
              competitiveAdvantageRowsError,
              strategyError,
            ]}
            dataMissing={false}
          >
            {!!competitiveAdvantageRows?.length && !!keyStatements && (
              <LoadingComponent isLoading={keyStatementsLoading}>
                <KeyStatements
                  key={+competitiveAdvantageRowUrlParam}
                  data={keyStatements}
                  commercialTactics={commercialTactics}
                  isLead // isLead is true to override KeyStatement permissions logic tied to user role
                  style={{ width: '100%' }}
                  overrideTooltip={
                    !isLead && region === 'global'
                      ? 'Only Leads can edit'
                      : !viewingOwnRegion
                        ? 'Only local contributors can edit'
                        : ''
                  }
                  placeholder={
                    viewingOwnRegion
                      ? isStrategicObjectivesStep
                        ? 'Type a strategic objective'
                        : 'Type a distinctive capability'
                      : 'None identified yet'
                  }
                  marketRepPlaceholder={
                    isStrategicObjectivesStep
                      ? 'None yet. A Lead will add strategic objectives here'
                      : 'None yet. A Lead will add distinctive capabilities here.'
                  }
                  title={
                    isStrategicObjectivesStep
                      ? 'Goals'
                      : 'Distinctive Capabilities'
                  }
                  addStatement={({ text, localUid }) => {
                    const itemData = {
                      localUid,
                      text,
                      title: isStrategicObjectivesStep
                        ? 'Strategic Objectives'
                        : 'Distinctive Capabilities',
                      strategyId: Number(strategyId),
                      step,
                      subStep: activeSubstepCamelCase,
                      competitiveAdvantageRowId: +competitiveAdvantageRowUrlParam,
                      tactics: [],
                      region: selectedRegion,
                    };
                    const id = Math.ceil(Math.random() * 20000);

                    upsertKeyStatement({
                      variables: {
                        data: {
                          ...itemData,
                        },
                      },
                      optimisticResponse: {
                        keyStatementUpsert: {
                          ...itemData,
                          id,
                          lastUpdated: new Date().toISOString(),
                          createdAt: new Date().toISOString(),
                          __typename: 'KeyStatement',
                          focus: false,
                          focusRationale: '',
                          collaboration: null,
                          accessStrategyPrioritiseSupportingMessages: [],
                          accessStrategyEndpointTargets: [],
                        },
                      },
                      update: apolloCreateHelper({
                        responseField: 'keyStatementUpsert',
                        query: KeyStatementsDocument,
                        queryName: 'keyStatements',
                        queryVars: keyStatementsQueryVars,
                      }),
                    });
                  }}
                  deleteStatement={(keyStatement) => {
                    deleteKeyStatement({
                      variables: { localUid: keyStatement.localUid },
                      optimisticResponse: {
                        keyStatementDelete: keyStatement,
                      },
                      update: apolloDeleteHelper({
                        responseField: 'keyStatementDelete',
                        query: KeyStatementsDocument,
                        queryVars: keyStatementsQueryVars,
                        queryName: 'keyStatements',
                      }),
                    });
                  }}
                  updateStatement={({ localUid, text }) => {
                    updateKeyStatement({
                      variables: { localUid, data: { text } },
                    });
                  }}
                  viewingOtherRegion={!viewingOwnRegion}
                  showCountryDropdown={Boolean(localCountryTacticsEnabled)}
                  CountryDropdown={
                    <CountriesDropdownSmall
                      country={selectedRegion}
                      active={countryDropdownActive}
                      onClick={() =>
                        setCountryDropdownActive(!countryDropdownActive)
                      }
                      viewOnly={false}
                      hideCountryName={true}
                      largeIcon
                      globalOptionText={'Global plan'}
                      dropDownRef={countryDropDownRef}
                      showDropdown={countryDropdownActive}
                      setShowDropdown={setCountryDropdownActive}
                      setCountry={(country) => {
                        const generatedPath = generatePath(path, {
                          drugId,
                          strategyId,
                          substep,
                          competitiveAdvantageRowUrlParam,
                          region: country,
                        });

                        history.push(generatedPath);
                      }}
                      allCountries={uniqueRegions}
                    />
                  }
                />
              </LoadingComponent>
            )}

            <PostItsPageWrapper
              paddingTop={false}
              fullWidthMobile
              totalNavHeight={totalNavHeight}
              sidebarOpen={sidebarOpen}
              overlapWidth={overlapWidth}
            >
              <MainContent
                sidebarOpen={sidebarOpen}
                overlapWidth={overlapWidth}
              >
                {!competitiveAdvantageRows?.length ? (
                  <PostItsEmpty
                    style={{ margin: 20 }}
                    title="Nothing to discuss"
                  >
                    <BodySmall
                      color={colors.greyDark}
                      style={{ display: 'inline' }}
                    >
                      Leads must first choose Strategic Imperatives to focus on
                      in{' '}
                    </BodySmall>
                    <Link
                      to={`/d/${drugId}/strategy/${strategyId}/3_3`}
                      style={{
                        color: colors.greyDark,
                        display: 'inline',
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      3.3 Strategic Imperatives
                    </Link>
                  </PostItsEmpty>
                ) : (
                  <PostIts
                    step={Step.DistinctiveCapabilities}
                    groups={groups}
                    addCard={createCard}
                    removeCard={removeCard}
                    updateCard={updateCard}
                    handleCardChange={onChange}
                    createGroup={createGroup}
                    updateGroup={updateGroup}
                    removeGroup={removeGroup}
                    sidebarOpen={sidebarOpen}
                  />
                )}
              </MainContent>
            </PostItsPageWrapper>
          </ErrorWrapper>
        </SidebarDesktopWrapper>
      </SidebarMobileWrapper>
    </>
  );
};
