import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import {
  SolutionFragment,
  Sort,
  SubStep,
  SuccessConditionFragment,
  SuccessConditionType,
  useSuccessConditionsQuery,
  useBigIdeaDetailsQuery,
  useFutureTrendsQuery,
  useLongTermSolutionsQuery,
  BigIdeaDetailFragment,
} from 'data/graphql/generated';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { device } from 'utils/breakpoints';
import {
  BodyNormal,
  ButtonPill,
  StepHeaderBar,
  Subtitle2,
} from 'components/shared';
import { colors } from 'constants/colors';
import { LongTermStrategyNav } from 'components/LongTermStrategy/LongTermStrategyNav';
import {
  PageAndSidebarContainer,
  PageContainer,
  Content,
} from 'components/GridPages/GridPageComponents';
import { LongTermStrategySidebar } from 'components/LongTermStrategy/LongTermStrategySidebar';
import { pageHeaderHeight_4_5_2, polling } from 'constants/index';
import useDesktop from 'hooks/useDesktop';
import {
  GridHeader,
  TouchPositioningContent,
  TouchPositioningOverlay,
} from 'components/GridPages/DropzoneGrid';
import LongTermSolutionsGrid from 'components/LongTermStrategy/LongTermSolutionsGrid';
import { SolutionCallout } from 'components/LongTermStrategy/SolutionCallout';

function getDueDate(item: BigIdeaDetailFragment | SolutionFragment) {
  if (item.__typename === 'BigIdeaDetail') {
    if (!item.due) return null;

    const date = new Date(+item.due);
    const parsedDate = [date.getMonth(), date.getFullYear()];
    return parsedDate;
  } else if (item.__typename === 'Solution') {
    return item.dueDate;
  } else {
    return null;
  }
}

const StyledStepHeaderBar = styled(StepHeaderBar)`
  margin-top: 160px;
  padding: 15px;
  min-height: unset;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${device.desktopMin} {
    margin-top: 110px;
  }
`;

export const longTermSolutionsQueryVars = (strategyId: string) => ({
  where: {
    strategyId: +strategyId,
  },
  orderBy: {
    createdAt: Sort.Desc,
  },
});

interface URLParams {
  drugId: string;
  strategyId: string;
}

export const LongTermStrategySolutions = () => {
  const { strategyId, drugId }: URLParams = useParams();
  const isDesktop = useDesktop();
  const [sidebarOpen, setSidebarOpen] = useState(isDesktop ? true : false);
  const [touchPositioningView, setTouchPositioningView] = useState<
    string | null
  >(null); // holds the solution localUID

  // Fetch Conditions For Success with solutions attached - for Risk Mitigation solutions
  const successConditionsQueryVars = {
    where: {
      strategyId: Number(strategyId),
      type: [SuccessConditionType.Barrier],
      /* Because these were created against strategic possibilities rather than competitiveAdvantageRows,
       we need to use this method to match it against the correct strategicPossibility */
    },
    orderBy: { createdAt: Sort.Desc },
    include: { solutions: true },
  };

  const {
    data: successConditionsData,
    loading: successConditionsLoading,
    error: successConditionsError,
    startPolling: startSuccessConditionsStartPolling,
    stopPolling: stopSuccessConditionsStopPolling,
  } = useSuccessConditionsQuery({
    variables: {
      ...successConditionsQueryVars,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const successConditions = useMemo(
    () => successConditionsData?.successConditions?.items || [],
    [successConditionsData?.successConditions?.items]
  ) as (SuccessConditionFragment & { solutions: SolutionFragment[] })[];

  // Fetch Big Ideas - for Big Idea Solutions
  const {
    data: bigIdeaDetailsData,
    error: bigIdeaDetailsError,
    loading: bigIdeaDetailsLoading,
    startPolling: bigIdeaDetailsStartPolling,
    stopPolling: bigIdeaDetailsStopPolling,
  } = useBigIdeaDetailsQuery({
    variables: {
      orderBy: {
        createdAt: Sort.Desc,
      },
      where: {
        strategyId: +strategyId,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  // Fetch Future Trends
  const {
    data: futureTrendsData,
    loading: futureTrendsLoading,
    error: futureTrendsError,
    startPolling: futureTrendsStartPolling,
    stopPolling: futureTrendsStopPolling,
  } = useFutureTrendsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
      },
    },
  });

  // Fetch long term solutions - these will appear in the sidebar groups under the "Add solutions" tab
  const {
    data: solutionsData,
    error: solutionsError,
    loading: solutionsLoading,
    startPolling: startTacticsPolling,
    stopPolling: stopTacticsPolling,
    refetch: refetchSolutions,
  } = useLongTermSolutionsQuery({
    variables: longTermSolutionsQueryVars(strategyId),
  });

  useEffect(() => {
    startSuccessConditionsStartPolling(polling.default);
    bigIdeaDetailsStartPolling(polling.default);
    futureTrendsStartPolling(polling.default);
    startTacticsPolling(polling.default);

    return () => {
      stopSuccessConditionsStopPolling();
      bigIdeaDetailsStopPolling();
      futureTrendsStopPolling();
      stopTacticsPolling();
    };
  }, [
    startSuccessConditionsStartPolling,
    stopSuccessConditionsStopPolling,
    bigIdeaDetailsStartPolling,
    bigIdeaDetailsStopPolling,
    futureTrendsStartPolling,
    futureTrendsStopPolling,
    startTacticsPolling,
    stopTacticsPolling,
  ]);

  const riskMitigationSolutions =
    successConditions?.reduce(
      (solutions: SolutionFragment[], successCondition, idx) => {
        if (!successCondition?.solutions) return solutions;
        const focusedSolutions = successCondition.solutions.filter(
          (sol) => sol.focused
        );

        const updatedSolutions = [...solutions, ...focusedSolutions];
        return updatedSolutions;
      },
      []
    ) || [];

  const bigIdeaDetails = bigIdeaDetailsData?.bigIdeaDetails?.items || [];
  const existingSolutions = [
    ...bigIdeaDetails,
    ...riskMitigationSolutions,
  ].sort((a, b) => {
    const aDueDate = getDueDate(a);
    if (!aDueDate) return 1;
    const bDueDate = getDueDate(b);
    if (!bDueDate) return -1;

    // both dates now in array format e.g. [0, 2023]
    const [monthA, yearA] = aDueDate;
    const [monthB, yearB] = bDueDate;

    if (yearB < yearA) {
      return 1;
    } else if (yearB > yearA) {
      return -1;
    } else {
      // same year
      return monthA - monthB;
    }
  });

  const dataLoading =
    successConditionsLoading ||
    bigIdeaDetailsLoading ||
    futureTrendsLoading ||
    solutionsLoading;
  const dataMissing =
    !successConditionsData ||
    !bigIdeaDetailsData ||
    !futureTrendsData ||
    !solutionsData;
  const dataErrors = [
    successConditionsError,
    bigIdeaDetailsError,
    futureTrendsError,
    solutionsError,
  ];

  const futureTrends = futureTrendsData?.futureTrends?.items;
  const focusedFutureTrends = futureTrends?.filter((ft) => ft.focus);
  const longTermSolutions = solutionsData?.longTermSolutions?.items;
  const placedSolutions = longTermSolutions?.filter((s) => !!s.x);
  const unplacedSolutions = longTermSolutions?.filter((s) => !s.x);
  const activeSolution = longTermSolutions?.find(
    (sol) => sol.localUid === touchPositioningView
  );

  useEffect(() => {
    if (isDesktop) {
      setTouchPositioningView(null);
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  }, [isDesktop]);

  const solutionsGrid = useMemo(
    () => (
      <LongTermSolutionsGrid
        futureTrends={focusedFutureTrends || []}
        placedSolutions={placedSolutions || []}
        refetchSolutions={refetchSolutions}
        setTouchPositioningView={setTouchPositioningView}
        touchPositioningView={touchPositioningView}
        setSidebarOpen={setSidebarOpen}
        strategyId={strategyId}
      />
    ),
    [
      focusedFutureTrends,
      placedSolutions,
      refetchSolutions,
      setTouchPositioningView,
      touchPositioningView,
      setSidebarOpen,
      strategyId,
    ]
  );

  const solutionsSidebar = useMemo(
    () => (
      <LongTermStrategySidebar
        drugId={drugId}
        strategyId={strategyId}
        existingSolutions={existingSolutions}
        futureTrends={focusedFutureTrends || []}
        unplacedSolutions={unplacedSolutions || []}
        sidebarOpen={sidebarOpen}
        offsetTop={false}
        setSidebarOpen={setSidebarOpen}
        setTouchPositioningView={setTouchPositioningView}
      />
    ),
    [
      drugId,
      strategyId,
      existingSolutions,
      focusedFutureTrends,
      unplacedSolutions,
      sidebarOpen,
    ]
  );

  return (
    <>
      <LongTermStrategyNav substep={SubStep.Solutions} />

      <PageAndSidebarContainer>
        <StyledStepHeaderBar>
          <Subtitle2 style={{ marginBottom: '5px' }}>
            Suggest and prioritise solutions that address the emerging
            opportunities or risks
          </Subtitle2>
          <BodyNormal
            color={colors.greyDark}
            style={{ display: 'inline-block' }}
          >
            Add and position ideas on the grid. A Lead should finalise the
            solutions to work on.
          </BodyNormal>
        </StyledStepHeaderBar>

        {!isDesktop && touchPositioningView && (
          <TouchPositioningOverlay>
            <TouchPositioningContent>
              <SolutionCallout
                data={activeSolution}
                futureTrend={focusedFutureTrends?.find(
                  (ft) => ft.id === activeSolution?.futureTrendId
                )}
              />
              {solutionsGrid}
            </TouchPositioningContent>
          </TouchPositioningOverlay>
        )}

        <ErrorWrapper
          isLoading={dataLoading}
          errors={dataErrors}
          dataMissing={dataMissing}
        >
          <PageContainer pageHeaderHeight={pageHeaderHeight_4_5_2}>
            <Content>
              {!isDesktop && (
                <GridHeader>
                  <ButtonPill
                    text="Add solutions"
                    iconName="Plus"
                    onClick={() => {
                      setSidebarOpen(true);
                    }}
                    disabled={sidebarOpen}
                  />
                </GridHeader>
              )}

              {!touchPositioningView && solutionsGrid}
            </Content>
            {!touchPositioningView && isDesktop && solutionsSidebar}
          </PageContainer>
        </ErrorWrapper>

        {!touchPositioningView && !isDesktop && solutionsSidebar}
      </PageAndSidebarContainer>
    </>
  );
};
