import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { BodySmall, Subtitle2, ButtonPill, Caption } from 'components/shared';
import { colors } from 'constants/colors';
import {
  AccessStrategyEndpointTargetDocument,
  AccessStrategyEndpointTargetFragment,
  KeyStatementFragment,
  useAccessStrategyEndpointTargetCreateMutation,
  AccessStrategyEndpointTargetsQueryVariables,
} from 'data/graphql/generated';
import { apolloCreateHelper } from 'utils/apolloQueryHelpers';
import { TableRow } from './EvidenceTableRow';
import {
  ColumnTitle,
  ColumnMeasurement,
  ColumnFeasibility,
  ColumnImpact,
  ColumnCost,
} from '../CreatingValue/TableRow';
import { device } from 'utils/breakpoints';
import { mapDBStepToReadableSubStep } from 'utils/mapDBStepToReadableSubStep';

export const Group = styled.div`
  padding: 15px;
  background: ${colors.white};
  margin-bottom: 15px;
  max-width: 575px;
  margin: 0 auto 15px auto;

  @media (min-width: 480px) {
    border: 1px solid rgba(20, 20, 39, 0.1);
    border-radius: 5px;
  }

  @media (min-width: 945px) {
    max-width: 905px;
  }

  @media (min-width: 1200px) {
    max-width: 1400px;
  }
`;

export const GroupHeader = styled.header`
  padding: 0px 15px 10px 15px;
  position: relative;
  display: flex;
  flex-direction: row;

  @media (max-width: 945px) {
    display: none;
  }
`;

export const GroupTitle = styled(BodySmall)`
  color: ${colors.greyDark};
  font-weight: 400;
  display: inline-block;
`;

export const GroupItems = styled.div`
  border-top: 1px solid rgba(232, 232, 233, 1);
`;

export const GroupEmpty = styled.div`
  background: ${colors.yellow20};
  text-align: center;
  padding: 11px;
  margin: 0px 15px;
`;

export const ReviewStimulus = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.purple};
  cursor: pointer;
`;

export const Footer = styled.div`
  @media ${device.tabletMin} {
    display: flex;
    align-items: center;
  }
`;

export const FooterButton = styled.div`
  margin: 15px 0px;
  @media ${device.tabletMin} {
    margin: 15px 15px 15px 0px;
  }
`;

export const EvidenceTable: React.FC<{
  keyStatement: KeyStatementFragment;
  endpointTargets: AccessStrategyEndpointTargetFragment[];
  strategyId: number;
  refetch: any;
  reviewKeyStatement(id: number): void;
  queryVars: AccessStrategyEndpointTargetsQueryVariables;
  drugId: number;
}> = ({
  keyStatement,
  endpointTargets,
  strategyId,
  refetch,
  reviewKeyStatement,
  queryVars,
  drugId,
}) => {
  const [creatingEndpoint, setCreatingEndpoint] = useState(false);
  const [
    createEndpointTarget,
  ] = useAccessStrategyEndpointTargetCreateMutation();
  const [autoFocus, setAutoFocus] = useState(false);

  return (
    <Group>
      <div style={{ margin: '15px 15px 20px 15px' }}>
        <Caption color={colors.greyDark}>
          {mapDBStepToReadableSubStep(keyStatement?.subStep || undefined)}
        </Caption>
        <Subtitle2>{keyStatement?.text}</Subtitle2>
      </div>
      <div>
        <GroupHeader>
          <ColumnTitle>
            <GroupTitle>Endpoint target</GroupTitle>
          </ColumnTitle>
          <ColumnMeasurement>
            <GroupTitle>Measurement</GroupTitle>
          </ColumnMeasurement>
          <ColumnFeasibility>
            <GroupTitle>Feasibility</GroupTitle>
          </ColumnFeasibility>
          <ColumnImpact>
            <GroupTitle>Impact</GroupTitle>
          </ColumnImpact>
          <ColumnCost>
            <GroupTitle>Cost</GroupTitle>
          </ColumnCost>
        </GroupHeader>
      </div>

      {(endpointTargets.length || 0) > 0 ? (
        <GroupItems>
          {endpointTargets.map((endpointTarget) => (
            <TableRow
              strategyId={strategyId}
              drugId={drugId}
              autoFocus={autoFocus}
              accessStrategyEndpointTarget={endpointTarget}
              refetch={refetch}
              key={endpointTarget.id}
              queryVars={queryVars}
            />
          ))}
        </GroupItems>
      ) : (
        <GroupEmpty>
          <Subtitle2 style={{ color: colors.greyDark }}>
            No evidence generation ideas yet
          </Subtitle2>
          <BodySmall style={{ color: colors.greyDark }}>
            Add ideas to this supporting statement
          </BodySmall>
        </GroupEmpty>
      )}

      <Footer>
        <FooterButton>
          <ButtonPill
            iconName="Plus"
            text="Add evidence generation idea"
            level="secondary"
            clickClassName="cypress-endpoint-add"
            loading={creatingEndpoint}
            onClick={async () => {
              setCreatingEndpoint(true);
              setAutoFocus(true);
              await createEndpointTarget({
                variables: {
                  data: {
                    strategyId: +strategyId,
                    keyStatementId: keyStatement.id,
                    title: '',
                    measurement: '',
                    feasibility: 0,
                    impact: 0,
                    cost: 0,
                  },
                },
                update: apolloCreateHelper({
                  responseField: 'accessStrategyEndpointTargetCreate',
                  query: AccessStrategyEndpointTargetDocument,
                  queryVars,
                  queryName: 'accessStrategyEndpointTargets',
                }),
              });
              await refetch();
              setCreatingEndpoint(false);
            }}
          />
        </FooterButton>
        <ReviewStimulus onClick={() => reviewKeyStatement(keyStatement.id)}>
          Review stimulus
        </ReviewStimulus>
      </Footer>
    </Group>
  );
};
