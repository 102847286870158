import { BodySmall, Caption } from 'components/shared';
import { colors } from 'constants/index';
import styled, { css } from 'styled-components/macro';
import { TimelineData } from './LongTermStrategySummaryTimeline';
import { getTitle } from './utils';

const Wrapper = styled.div<{ offsetX?: number | undefined }>`
  background: ${colors.white};

  border: 1px solid ${colors.greyLight};
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  position: absolute;
  bottom: 40px;

  width: 325px;

  ${({ offsetX }) => {
    if (typeof offsetX === 'number') {
      return css`
        left: ${offsetX}px;
      `;
    } else {
      return css`
        // center above pin
        transform: translateX(calc(-50% + 15px));
      `;
    }
  }}
`;

const ListWrapper = styled.div<{ offsetX: number | undefined }>`
  position: relative;
  background: ${colors.white};

  border: 1px solid ${colors.greyMedium};

  border-radius: 5px;

  background: rgba(255, 255, 255, 0.94);

  display: flex;
  flex-direction: column;
  gap: 5px;

  position: absolute;
  bottom: 40px;
  padding: 5px;
  max-height: 335px;
  overflow: auto;

  ${Wrapper} {
    position: relative;
    bottom: auto;
    transform: none;
  }
  ${({ offsetX }) => {
    if (typeof offsetX === 'number') {
      return css`
        left: ${offsetX}px;
      `;
    } else {
      return css`
        // center above pin
        transform: translateX(calc(-50% + 15px));
      `;
    }
  }}
`;

type Props = {
  data: TimelineData[];

  onMouseEnter(): void;
  onMouseLeave(): void;
  offsetX: number | undefined;
};

export const Callout = ({
  data,
  onMouseEnter,
  onMouseLeave,
  offsetX,
}: Props) => {
  return (
    <>
      {data.length > 1 ? (
        <ListWrapper
          offsetX={offsetX}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {data.map((val, idx) => {
            return (
              <Wrapper key={idx}>
                <Caption color={colors.greyDark}>{getTitle(val.type)}</Caption>
                <BodySmall>{val.text || 'Not identified yet'}</BodySmall>
              </Wrapper>
            );
          })}
        </ListWrapper>
      ) : (
        <>
          <Wrapper
            offsetX={offsetX}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Caption color={colors.greyDark}>{getTitle(data[0].type)}</Caption>
            <BodySmall>{data[0].text || 'Not identified yet'}</BodySmall>
          </Wrapper>
        </>
      )}
    </>
  );
};
