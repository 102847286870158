import Konva from 'konva';
import { Line as LineType } from 'konva/types/shapes/Line';
import { Text as TextType } from 'konva/types/shapes/Text';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Group, Line, Path, Rect, Text } from 'react-konva';

type Props = {
  on: boolean;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
  onClick?(): void;
  tooltip?: string;
  disableHoverStates?: boolean;
};

export const IndependentToggle = ({
  on,
  onMouseEnter,
  onMouseLeave,
  onClick,
  tooltip,
  disableHoverStates,
}: Props) => {
  const leftLineRef = useRef<LineType | null>(null);
  const rightLineRef = useRef<LineType | null>(null);

  const [isHovered, setIsHovered] = useState(false);

  useLayoutEffect(() => {
    // add transform matrix from original svg to konva line shapes
    if (leftLineRef.current && rightLineRef.current) {
      const leftLineMatrix = new Konva.Transform([
        -0.82945,
        0.558581,
        0.558581,
        0.82945,
        8.13281,
        10.5979,
      ]);

      leftLineMatrix.copyInto(leftLineRef.current.getTransform());

      const rightLineMatrix = new Konva.Transform([
        0.82945,
        -0.558581,
        -0.558581,
        -0.82945,
        12.8672,
        9.4021,
      ]);

      rightLineMatrix.copyInto(rightLineRef.current.getTransform());
    }
  }, []);

  const bgColor = on
    ? isHovered && !disableHoverStates
      ? '#B9B9BE'
      : '#5B5B68'
    : isHovered && !disableHoverStates
    ? '#E7E7E9'
    : 'transparent';

  const iconColor = on
    ? isHovered && !disableHoverStates
      ? '#464654'
      : '#ffffff'
    : isHovered && !disableHoverStates
    ? '#535361'
    : '#5B5B68';

  return (
    <Group
      onMouseEnter={() => {
        setIsHovered(true);
        onMouseEnter?.();
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        onMouseLeave?.();
      }}
      onClick={onClick}
    >
      {/* BG */}
      <Rect height={20} width={20} sides={4} cornerRadius={5} fill={bgColor} />
      <Path
        data={`M9 5.5C9 5.22386 9.22386 5 9.5 5H11.5C11.7761 5 12 5.22386 12 5.5V7.73241C12 7.89958 11.9164 8.0557 11.7774 8.14843L9.77735 9.48177C9.44507 9.70329 9 9.46509 9 9.06574V5.5Z`}
        fill={iconColor}
        strokeEnabled={false}
      />
      <Path
        data={`M12 14.5C12 14.7761 11.7761 15 11.5 15H9.5C9.22386 15 9 14.7761 9 14.5V12.2676C9 12.1004 9.08355 11.9443 9.22265 11.8516L11.2227 10.5182C11.5549 10.2967 12 10.5349 12 10.9343V14.5Z`}
        fill={iconColor}
        strokeEnabled={false}
      />
      <Line
        ref={leftLineRef}
        points={[0.75, -0.75, 2.25, -0.75]}
        stroke={iconColor}
        strokeWidth={1.5}
        lineCap="round"
      />
      <Line
        ref={rightLineRef}
        points={[0.75, -0.75, 2.25, -0.75]}
        stroke={iconColor}
        strokeWidth={1.5}
        lineCap="round"
      />
      {tooltip && isHovered && <ToolTip y={34} text={tooltip} />}
    </Group>
  );
};

function ToolTip({
  bgColor = '#2C2C3D',
  textColor = '#FFFFFF',
  text,
  x = 0,
  y = 0,
}: {
  text: string;
  textColor?: string;
  bgColor?: string;
  y?: number;
  x?: number;
}) {
  const ref = useRef<TextType | null>(null);

  const [bgSize, setBgSize] = useState({ w: 0, h: 0 });
  const [offsetX, setOffsetX] = useState(0);

  useEffect(() => {
    const textItem = ref.current;

    if (!textItem) return;

    const textWidth = textItem.getSize();

    setBgSize(() => ({
      w: Math.ceil(+textWidth.width) + 10,
      h: Math.ceil(textWidth.height) + 10,
    }));

    setOffsetX((Math.ceil(+textWidth.width) + 10) / 2);
  }, []);

  return (
    <Group y={y} x={x - offsetX}>
      <Rect
        cornerRadius={2}
        height={bgSize.h}
        width={bgSize.w}
        fill={bgColor}
      />
      <Text
        x={5}
        y={5}
        ref={ref}
        fill={textColor}
        text={text}
        fontFamily="ABCFavorit"
        fontSize={12}
        lineHeight={1.25}
        fontWeight={500}
        wrap="word"
        align="right"
      />
    </Group>
  );
}
