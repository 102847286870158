import React, { ComponentProps } from 'react';
import styled, { css } from 'styled-components/macro';
import { Icon, BodySmall } from 'components/shared';
import { DropdownList } from 'components/shared/FormDropdown';
import { colors } from 'constants/colors';
import { IconContainer } from 'components/shared/Icon';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { Wrapper as ImageAndTitleWrapper } from 'components/shared/ImageAndTitle';
import { device } from 'utils/breakpoints';
import { DropdownEmptyState } from 'components/shared/UserDropdown';
import { SuccessConditionPerspective } from 'data/graphql/generated';

const Wrapper = styled.div`
  position: relative;
`;

const IconDeselect = styled(Icon)`
  visibility: hidden;
  background: ${colors.greyDark};

  &:hover {
    background: ${colors.black};
  }
`;

export const SelectedOption = styled.div<{
  dropdownActive: boolean;
  selectedOption: boolean;
}>`
  max-width: 250px;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 4px 5px;
  border-radius: 5px;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  border: 1px solid transparent;

  &:hover {
    background: ${({ dropdownActive, selectedOption }) =>
      !dropdownActive && !selectedOption && colors.purple15};
    ${IconDeselect} {
      visibility: visible;
    }
  }

  ${BodySmall} {
    color: ${colors.purple};
  }

  ${({ dropdownActive, selectedOption }) =>
    dropdownActive && selectedOption
      ? css`
          background: none;
          border: 1px solid ${colors.black};
          ${BodySmall} {
            color: ${colors.black};
          }
        `
      : dropdownActive && !selectedOption
      ? css`
          background: ${colors.purple10};
        `
      : !dropdownActive && selectedOption
      ? css`
          background: ${colors.cream};
          ${BodySmall} {
            color: ${colors.black};
          }
        `
      : css`
          background: ${colors.purple10};
        `}
`;

const NonEditable = styled.div<{ selectedOption: boolean }>`
  max-width: ${({ selectedOption }) => (selectedOption ? '215px' : '250px')};
  padding: 5px;
  border-radius: 5px;
  background: ${({ selectedOption }) =>
    selectedOption ? colors.cream : colors.black05};

  ${BodySmall} {
    color: ${({ selectedOption }) =>
      selectedOption ? colors.black : colors.greyDark};
  }
`;

const StyledDropdownList = styled(DropdownList)`
  border: 1px solid ${colors.black30};
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 5px;
  }

  position: absolute;
  height: auto;
  max-height: 220px;
  width: 100%;

  @media ${device.desktopMin} {
    width: 450px;
  }

  ${ImageAndTitleWrapper} {
    max-width: none;
  }
`;

const DropdownOption = styled.li<{
  isSelected: boolean;
  disableHover?: boolean;
}>`
  width: 100%;
  list-style-type: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: ${({ isSelected }) => isSelected && `2px solid ${colors.purple}`};
  padding: 5px;
  min-height: 30px;

  ${({ disableHover }) => {
    if (!disableHover) {
      return css`
        :hover {
          background-color: ${colors.greyLight};
        }
      `;
    } else {
      return css`
        cursor: not-allowed;
        * {
          pointer-events: none;
        }
      `;
    }
  }}

  :last-child {
    margin-bottom: 0;
  }

  ${IconContainer} {
    align-self: flex-start;
  }
`;

interface Props {
  options: SuccessConditionPerspective[];
  selectedOption?: SuccessConditionPerspective;
  selectOption: (option: SuccessConditionPerspective) => void;
  clearSelection: () => void;
  editable?: boolean;
  emptyState?: ComponentProps<typeof DropdownEmptyState>;
}

export const SuccessConditionsDropdown: React.FC<Props> = ({
  options,
  selectedOption,
  selectOption,
  clearSelection,
  editable = true,
  emptyState,
}) => {
  const [dropdownActive, setDropdownActive] = React.useState<boolean>(false);
  const containerRef = React.useRef(null);

  useClickOutsideComponent(containerRef, (event) => {
    setDropdownActive(false);
  });

  const convertText = (perspective: SuccessConditionPerspective): string =>
    perspective + ' perspective';

  const selectedOptionText = selectedOption ? convertText(selectedOption) : '';

  if (!editable)
    return (
      <Wrapper ref={containerRef}>
        <NonEditable selectedOption={!!selectedOption}>
          <BodySmall>
            {selectedOptionText || 'Perspective not specified'}
          </BodySmall>
        </NonEditable>
      </Wrapper>
    );

  return (
    <Wrapper ref={containerRef}>
      <SelectedOption
        className="cypress-success-condition-choose-perspective"
        dropdownActive={dropdownActive}
        onClick={() => setDropdownActive(true)}
        onBlur={() => setDropdownActive(false)}
        selectedOption={!!selectedOption}
      >
        {selectedOption ? (
          <>
            <BodySmall
              style={{
                maxWidth: '215px',
              }}
            >
              {selectedOptionText}
            </BodySmall>
            <IconDeselect
              name="X-Cross"
              size={20}
              color={colors.white}
              style={{
                borderRadius: '50%',
                height: '20px',
                alignSelf: 'flex-start',
              }}
              onClick={(e) => {
                e.stopPropagation();
                clearSelection();
              }}
            />
          </>
        ) : (
          <BodySmall style={{ fontWeight: 500 }}>Choose perspective</BodySmall>
        )}
      </SelectedOption>

      {dropdownActive &&
        (options?.length ? (
          <StyledDropdownList>
            {options.map((option) => (
              <DropdownOption
                className={`cypress-success-condition-perspective-option-${option.toLowerCase()}`}
                key={option}
                onClick={() => {
                  setDropdownActive(false);
                  selectOption(option);
                }}
                isSelected={option === selectedOption}
              >
                <BodySmall>{convertText(option)}</BodySmall>
              </DropdownOption>
            ))}
          </StyledDropdownList>
        ) : (
          <DropdownEmptyState
            header={emptyState?.header}
            text={emptyState?.text}
          />
        ))}
    </Wrapper>
  );
};
