import { BodySmall, ButtonPill, Subtitle2 } from 'components/shared';
import { colors } from 'constants/colors';
import {
  PicoSuggestionFragment,
  PicoSuggestionsDocument,
  PicoSuggestionUpdateInput,
  usePicoSuggestionCreateMutation,
} from 'data/graphql/generated';
import React, { useState } from 'react';
import { apolloCreateHelper } from 'utils/apolloQueryHelpers';
import { picoSuggestionsQueryVars } from './AccessStrategyPico';
import styled from 'styled-components/macro';
import {
  Footer,
  FooterButton,
  GroupEmpty,
  GroupItems,
  GroupTitle,
  ReviewStimulus,
} from './EvidenceTable';
import PicoSuggestionRow from './PicoSuggestionRow';
import { device } from 'utils/breakpoints';
import { capitalize } from 'lodash';
import { picoTableColumnSettings } from 'constants/index';
import { ModalProvider } from 'contexts';
import { fadeFromPurpleBGCSS } from 'utils/keyframes';

const StyledGroupItems = styled(GroupItems)``;

const StyledGroup = styled.div`
  padding: 15px;
  background: ${colors.white};
  margin-bottom: 15px;
  border-radius: 5px;

  @media ${device.tabletMin} {
    max-width: 575px;
    margin: auto;
  }

  @media ${device.desktopMin} {
    padding: 30px 15px;
    max-width: unset;
  }
`;

const BorderWrapper = styled.div`
  padding: 5px 0;
  border-bottom: 1px solid ${colors.greyLight};

  @media ${device.desktopMin} {
    padding: 15px 0;
  }

  position: relative;

  > * {
    // allows it to sit above before psuedo class
    isolation: isolate;
  }
  &.originalPico::before {
    ${fadeFromPurpleBGCSS};
    z-index: 0;
  }
`;

const PicoTableHeader = styled.header`
  ${picoTableColumnSettings}
  margin-bottom: 10px;
  display: none;

  @media ${device.desktopMin} {
    display: grid;
    padding: 0 15px;
  }
`;

export type PicoSuggestionUpdateField = keyof Pick<
  PicoSuggestionUpdateInput,
  'population' | 'intervention' | 'comparison' | 'outcome'
>;

export const picoSuggestionColumns: PicoSuggestionUpdateField[] = [
  'population',
  'intervention',
  'comparison',
  'outcome',
];

interface Props {
  picoSuggestions: PicoSuggestionFragment[];
  drugId: string;
  strategyId: string;
  setPicoModalOpen: React.Dispatch<React.SetStateAction<number | null>>;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: any;
}

export default function PicoTable({
  picoSuggestions,
  strategyId,
  setSidebarOpen,
  setPicoModalOpen,
  refetch,
}: Props) {
  const [createSuggestion] = usePicoSuggestionCreateMutation();
  const [creatingSuggestion, setCreatingSuggestion] = useState(false);
  const [autoFocus, setAutoFocus] = useState(false);

  async function addPicoSuggestion() {
    setCreatingSuggestion(true);
    setAutoFocus(true);
    await createSuggestion({
      variables: {
        data: {
          strategyId: +strategyId,
        },
      },
      update: apolloCreateHelper({
        responseField: 'picoSuggestionCreate',
        query: PicoSuggestionsDocument,
        queryVars: picoSuggestionsQueryVars(strategyId),
        queryName: 'picoSuggestions',
      }),
    });
    await refetch();
    setCreatingSuggestion(false);
  }

  return (
    <StyledGroup>
      <div>
        <PicoTableHeader>
          {picoSuggestionColumns.map((colTitle) => (
            <GroupTitle key={colTitle}>{capitalize(colTitle)}</GroupTitle>
          ))}
          <div style={{ minWidth: 135 }} />
        </PicoTableHeader>
      </div>

      {!!picoSuggestions.length ? (
        <StyledGroupItems>
          {picoSuggestions.map((p) => {
            return (
              <BorderWrapper id={String(p.id)} key={p.id}>
                <ModalProvider key={p.id}>
                  <PicoSuggestionRow
                    picoSuggestion={p}
                    setPicoModalOpen={setPicoModalOpen}
                    refetch={refetch}
                    autoFocus={autoFocus}
                  />
                </ModalProvider>
              </BorderWrapper>
            );
          })}
        </StyledGroupItems>
      ) : (
        <GroupEmpty>
          <Subtitle2 color={colors.greyDark}>No PICO suggestions</Subtitle2>
          <BodySmall color={colors.greyDark}>
            Add suggestions for consideration
          </BodySmall>
        </GroupEmpty>
      )}

      <Footer>
        <FooterButton>
          <ButtonPill
            iconName="Plus"
            text="Add PICO suggestion"
            level="secondary"
            clickClassName="cypress-pico-add"
            loading={creatingSuggestion}
            onClick={addPicoSuggestion}
          />
        </FooterButton>
        <ReviewStimulus onClick={() => setSidebarOpen(true)}>
          Review stimulus
        </ReviewStimulus>
      </Footer>
    </StyledGroup>
  );
}
