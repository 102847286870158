import { useEffect, useMemo, useState } from 'react';
import {
  Link,
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components/macro';

import { FetchResult, MutationFunctionOptions } from '@apollo/client';
import { CommercialStrategyStepHeader } from 'components/CommercialStrategy/CommercialStrategyStepHeader';
import { CommercialTacticsSidebar } from 'components/CommercialStrategy/CommercialTacticsSidebar';
import KeyTacticsGrid from 'components/CommercialStrategy/KeyTacticsGrid';
import { TacticCallout } from 'components/CommercialStrategy/TacticCallout';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import {
  GridHeader,
  TouchPositioningContent,
  TouchPositioningOverlay,
} from 'components/GridPages/DropzoneGrid';
import {
  Content,
  PageAndSidebarContainer,
  PageContainer,
} from 'components/GridPages/GridPageComponents';
import { PostItsEmpty } from 'components/PostItsEmpty';
import { BodySmall, ButtonPill } from 'components/shared';
import { colors } from 'constants/colors';
import { pageHeaderHeight_4_3_2, polling } from 'constants/index';
import {
  Exact,
  Sort,
  Step,
  SubStep,
  TacticDeleteMutation,
  useCommercialTacticsQuery,
  useCompetitiveAdvantageRowsQuery,
} from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import { CommercialStrategyNav } from '../../components/4-3-commercial-strategy/shared/ui/feature-navbar/CommercialStrategyNav';
import { globalCountryTabs } from '../../components/CommercialStrategy/constants';
import { getKeyTacticsQueryVars } from '../../components/CommercialStrategy/utils';
import { CountriesDropdownSmall } from '../../components/shared/CountriesDropdownSmall';
import { useAuthContext } from '../../contexts/AuthContext';
import { useCommercialStrategyNav } from '../../hooks/CommercialStrategy/useCommercialStrategyNav';
import useHandleRegionDropdown from '../../hooks/useHandleRegionDropdown';
import { CountryGlobal } from '../../types';
import { verifyUserRole } from '../../utils/verifyUserRole';
import {
  getGlobalAndRegionTactics,
  getGlobalTactics,
} from './utils/getTactics';

const GridOuterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
`;

const AddTacticsButton = styled(ButtonPill)``;

const StyledEmptyState = styled(PostItsEmpty)`
  margin: 20px auto;
  max-width: 1330px;
`;

interface URLParams {
  drugId: string;
  strategyId: string;
  strategicImperativeId: string;
  region: string;
}

export type DeleteTacticType = (
  options?:
    | MutationFunctionOptions<TacticDeleteMutation, Exact<{ id: number }>>
    | undefined
) => Promise<FetchResult<any> | void>;

export const CommercialStrategyKeyTactics = () => {
  const {
    drugId,
    strategyId,
    strategicImperativeId,
    region,
  }: URLParams = useParams();
  let { path } = useRouteMatch();
  const history = useHistory();
  const isDesktop = useDesktop();
  const [sidebarOpen, setSidebarOpen] = useState(isDesktop ? true : false);
  const [tacticLocalUID, setTacticLocalUID] = useState<string | null>(null); // holds the tactic localUID

  const [{ user }] = useAuthContext();
  const { isLead, isCountryContributor } = verifyUserRole(
    user?.role,
    user?.country
  );

  const {
    viewingOwnRegion,
    countryDropdownActive,
    setCountryDropdownActive,
    countryDropDownRef,
    uniqueRegions,
  } = useHandleRegionDropdown({ region, user, strategyId: +strategyId });

  const isGlobalView = region === 'global';
  const activeCountryTabState = useState(globalCountryTabs[0]);
  const activeTabIsGlobal =
    !isGlobalView && activeCountryTabState[0] === globalCountryTabs[0];

  const {
    localCountryTacticsEnabled,
    setCountryTacticsEnabled,
  } = useCommercialStrategyNav({ strategyId: +strategyId });

  const canAddTactic = isGlobalView
    ? isLead || !localCountryTacticsEnabled
    : isCountryContributor &&
      region === user?.country &&
      activeCountryTabState[0] === globalCountryTabs[1];

  const canDrag = viewingOwnRegion || !localCountryTacticsEnabled;

  // Fetch imperative tabs
  const {
    data: competitiveAdvantageRowsData,
    error: competitiveAdvantageRowsError,
    loading: competitiveAdvantageRowsLoading,
    startPolling: startCompetitiveAdvantageRowsPolling,
    stopPolling: stopCompetitiveAdvantageRowsPolling,
  } = useCompetitiveAdvantageRowsQuery({
    variables: {
      orderBy: {
        idx: Sort.Desc,
      },
      where: {
        strategyId: +strategyId,
        focus: true,
      },
      include: {
        strategicPossibility: true,
        successConditions: true,
        keyStatements: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  // Fetch commercial tactics
  const tacticsQueryVars = getKeyTacticsQueryVars(
    strategyId,
    strategicImperativeId
  );

  const {
    data: tacticsData,
    error: tacticsError,
    loading: tacticsLoading,
    startPolling: startTacticsPolling,
    stopPolling: stopTacticsPolling,
    refetch: refetchCommercialTactics,
  } = useCommercialTacticsQuery({
    variables: tacticsQueryVars,
  });

  useEffect(() => {
    startCompetitiveAdvantageRowsPolling(polling.default);
    startTacticsPolling(polling.default);
    return () => {
      stopCompetitiveAdvantageRowsPolling();
      stopTacticsPolling();
    };
  }, [
    startCompetitiveAdvantageRowsPolling,
    stopCompetitiveAdvantageRowsPolling,
    startTacticsPolling,
    stopTacticsPolling,
  ]);

  const competitiveAdvantageRows =
    competitiveAdvantageRowsData?.competitiveAdvantageRows?.items;
  const activeRow = competitiveAdvantageRows?.find(
    (r) => r.id === +strategicImperativeId
  );
  const noStrategicImperativesEmptyState = !competitiveAdvantageRows?.length;

  const activeObjectives = activeRow?.keyStatements?.filter(
    (k) =>
      k.step === Step.DistinctiveCapabilities &&
      k.subStep === SubStep.StrategicObjectives
  );

  const tacticItems = tacticsData?.commercialTactics?.items;

  const tactics = useMemo(
    () =>
      isGlobalView
        ? getGlobalTactics(tacticItems || [])
        : getGlobalAndRegionTactics(tacticItems || [], region),
    [isGlobalView, region, tacticItems]
  );

  const placedTactics = tactics?.filter((t) => !!t.x);
  const activeTactic = tactics?.find((t) => t.localUid === tacticLocalUID);

  useEffect(() => {
    const rowsStrategicImperativeId =
      !!competitiveAdvantageRows?.length && competitiveAdvantageRows[0].id;
    if (!rowsStrategicImperativeId) return;

    const regionViewsNotAvailable =
      !localCountryTacticsEnabled && region !== 'global';

    // Default url
    if (!strategicImperativeId || !region || regionViewsNotAvailable) {
      const correctRegion =
        (localCountryTacticsEnabled && isCountryContributor && user?.country) ||
        'global';

      const generatedPath = generatePath(path, {
        drugId: drugId,
        strategyId: strategyId,
        strategicImperativeId: rowsStrategicImperativeId,
        region: correctRegion,
      });

      history.replace(generatedPath);
    }
  }, [
    drugId,
    history,
    strategyId,
    competitiveAdvantageRowsData,
    competitiveAdvantageRows,
    strategicImperativeId,
    path,
    region,
    localCountryTacticsEnabled,
    isCountryContributor,
    user?.country,
  ]);

  useEffect(() => {
    if (isDesktop) {
      setTacticLocalUID(null);
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  }, [isDesktop]);

  const tacticsGrid = useMemo(
    () => (
      <KeyTacticsGrid
        activeObjectives={activeObjectives || []}
        placedTactics={placedTactics || []}
        refetchCommercialTactics={refetchCommercialTactics}
        setSidebarOpen={setSidebarOpen}
        setTouchPositioningView={setTacticLocalUID}
        touchPositioningView={tacticLocalUID}
        strategyId={strategyId}
        strategicImperativeId={strategicImperativeId}
        isGlobalView={isGlobalView}
        canDrag={canDrag}
        region={region}
        canEdit={isGlobalView}
        isViewingOwnRegion={viewingOwnRegion}
        activeTabIsGlobal={activeTabIsGlobal}
        canAddTactic={canAddTactic}
      />
    ),
    [
      activeObjectives,
      placedTactics,
      refetchCommercialTactics,
      tacticLocalUID,
      strategyId,
      strategicImperativeId,
      isGlobalView,
      canDrag,
      region,
      viewingOwnRegion,
      activeTabIsGlobal,
      canAddTactic,
    ]
  );

  const tacticsSidebar = useMemo(
    () => (
      <CommercialTacticsSidebar
        localCountryTacticsEnabled={!!localCountryTacticsEnabled}
        sidebarOpen={sidebarOpen}
        strategicObjectives={activeObjectives}
        tactics={tactics}
        offsetTop={!!competitiveAdvantageRows?.length}
        setSidebarOpen={setSidebarOpen}
        setTouchPositioningView={setTacticLocalUID}
        strategicImperativeId={strategicImperativeId}
        canDrag={canDrag}
        isGlobalView={isGlobalView}
        activeCountryTabState={activeCountryTabState}
        region={region}
        canAddTactic={canAddTactic}
      />
    ),
    [
      localCountryTacticsEnabled,
      sidebarOpen,
      activeObjectives,
      tactics,
      competitiveAdvantageRows?.length,
      strategicImperativeId,
      canDrag,
      isGlobalView,
      activeCountryTabState,
      region,
      canAddTactic,
    ]
  );

  return (
    <>
      <CommercialStrategyNav
        drugId={drugId}
        strategyId={strategyId}
        subStep={SubStep.WinningActions}
        strategicImperativeId={+strategicImperativeId}
        countryTacticsEnabled={Boolean(localCountryTacticsEnabled)}
        setCountryTacticsEnabled={setCountryTacticsEnabled}
        countryContributionsExist={false}
      />
      <PageAndSidebarContainer>
        <CommercialStrategyStepHeader
          substep={SubStep.WinningActions}
          imperatives={competitiveAdvantageRows}
          region={region}
        />

        {!isDesktop && tacticLocalUID && (
          <TouchPositioningOverlay>
            <TouchPositioningContent>
              <TacticCallout
                tacticData={activeTactic}
                strategicObjective={activeObjectives?.find(
                  (obj) => obj.id === activeTactic?.keyStatementId
                )}
                isLocalView={!isGlobalView}
                region={region}
                isViewingOwnRegion={viewingOwnRegion}
              />

              {tacticsGrid}
            </TouchPositioningContent>
          </TouchPositioningOverlay>
        )}

        {noStrategicImperativesEmptyState &&
        !competitiveAdvantageRowsLoading ? (
          <StyledEmptyState title="Nothing to discuss">
            <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
              Leads must first choose Strategic Imperatives to focus on in{' '}
            </BodySmall>
            <Link
              to={`/d/${drugId}/strategy/${strategyId}/3_3`}
              style={{
                color: colors.greyDark,
                display: 'inline',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              3.3 Strategic Imperatives
            </Link>
          </StyledEmptyState>
        ) : (
          <ErrorWrapper
            isLoading={competitiveAdvantageRowsLoading || tacticsLoading}
            errors={[competitiveAdvantageRowsError, tacticsError]}
            dataMissing={!competitiveAdvantageRowsData || !tacticsData}
          >
            <PageContainer pageHeaderHeight={pageHeaderHeight_4_3_2}>
              <Content>
                {!isDesktop && (
                  <GridHeader>
                    {localCountryTacticsEnabled && (
                      <CountriesDropdownSmall
                        country={(region || 'global') as CountryGlobal}
                        active={countryDropdownActive}
                        onClick={() =>
                          setCountryDropdownActive(!countryDropdownActive)
                        }
                        viewOnly={false}
                        hideCountryName={true}
                        largeIcon
                        globalOptionText={'Global plan'}
                        dropDownRef={countryDropDownRef}
                        showDropdown={countryDropdownActive}
                        setShowDropdown={setCountryDropdownActive}
                        setCountry={(country) => {
                          const generatedPath = generatePath(path, {
                            drugId: drugId,
                            strategyId: strategyId,
                            strategicImperativeId: strategicImperativeId,
                            region: country,
                          });

                          history.replace(generatedPath);
                        }}
                        allCountries={uniqueRegions}
                      />
                    )}
                    {isGlobalView && (
                      <AddTacticsButton
                        text="Add initiatives"
                        iconName="Plus"
                        onClick={() => {
                          setSidebarOpen(true);
                        }}
                        disabled={sidebarOpen}
                      />
                    )}
                  </GridHeader>
                )}
                <GridOuterWrapper>
                  {!tacticLocalUID && (
                    <>
                      {isDesktop && localCountryTacticsEnabled && (
                        <CountriesDropdownSmall
                          country={(region || 'global') as CountryGlobal}
                          active={countryDropdownActive}
                          onClick={() =>
                            setCountryDropdownActive(!countryDropdownActive)
                          }
                          viewOnly={false}
                          hideCountryName={true}
                          largeIcon
                          globalOptionText={'Global plan'}
                          dropDownRef={countryDropDownRef}
                          showDropdown={countryDropdownActive}
                          setShowDropdown={setCountryDropdownActive}
                          setCountry={(country) => {
                            const generatedPath = generatePath(path, {
                              drugId: drugId,
                              strategyId: strategyId,
                              strategicImperativeId: strategicImperativeId,
                              region: country,
                            });

                            history.replace(generatedPath);
                          }}
                          allCountries={uniqueRegions}
                        />
                      )}
                      {tacticsGrid}
                    </>
                  )}
                </GridOuterWrapper>
              </Content>

              {!tacticLocalUID && isDesktop && tacticsSidebar}
            </PageContainer>
          </ErrorWrapper>
        )}

        {!tacticLocalUID &&
          !isDesktop &&
          !noStrategicImperativesEmptyState &&
          tacticsSidebar}
      </PageAndSidebarContainer>
    </>
  );
};
