import { colors, zIndex } from 'constants/index';
import useDesktop from 'hooks/useDesktop';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';
import { ButtonPill } from '.';
import { BodyNormal, Subtitle1 } from './TextStyles';

const CTAWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  @media ${device.mobile} {
    flex-direction: column-reverse;
    > * + * {
      margin-bottom: 15px;
    }

    > div {
      width: 315px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  @media ${device.mobile} {
    padding: 30px 15px;
  }
  > p:first-child {
    margin-bottom: 10px;
  }
  > p:nth-child(2) {
    margin-bottom: 30px;
  }
`;

const TopBar = styled.div`
  height: 40px;
  width: 100%;
  background: ${colors.cream};
  @media ${device.mobile} {
    height: 30px;
  }
`;

const Modal = styled.div`
  width: 456px;
  height: 270px;
  background: ${colors.white};
  border: 0.5px solid rgba(20, 20, 39, 0.3);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  @media ${device.mobile} {
    width: 345px;
    height: 325px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: ${zIndex.overlay};
  background: rgba(20, 20, 39, 0.4);
  p {
    text-align: center;
  }
`;

interface Props {
  heading?: string;
  message?: string;
}

export const RotateScreenTip = ({ heading, message }: Props) => {
  const hideInSession = sessionStorage.getItem('@nmblr/rotateScreenTip');
  const hideForever = localStorage.getItem('@nmblr/rotateScreenTip');
  const isDesktop = useDesktop();

  const [hide, setHide] = useState(
    () => !!hideForever || !!hideInSession || isDesktop
  );

  const deviceIsTabletOrMobile = /Mobi|Android/i.test(navigator.userAgent);
  const maxWidth = Math.max(window.innerHeight, window.innerWidth);
  const rotateTabletDevice = deviceIsTabletOrMobile && maxWidth >= 945;

  useEffect(() => {
    if (hide === false) {
      document.body.style.overflow = 'hidden';
    }
    if (hide) {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [hide]);

  if (hide || isDesktop) {
    return null;
  }

  const defaultHeading = !isDesktop
    ? rotateTabletDevice
      ? 'Rotate for more contribution features'
      : 'Use a larger screen for more contribution features'
    : '';

  const defaultMessage = !isDesktop
    ? rotateTabletDevice
      ? 'You can do more in landscape orientation.'
      : 'You can still view content, show agreement and leave comments.'
    : '';

  return (
    <Wrapper>
      <Modal>
        <TopBar />
        <Content>
          <Subtitle1>{!heading ? defaultHeading : heading}</Subtitle1>
          <BodyNormal>{!message ? defaultMessage : message}</BodyNormal>
          <CTAWrapper>
            <ButtonPill
              width="180px"
              level="secondary"
              text="Don't show this again"
              onClick={() => {
                localStorage.setItem('@nmblr/rotateScreenTip', 'hide');
                setHide(true);
              }}
            />
            <ButtonPill
              width="180px"
              text="Dismiss"
              onClick={() => {
                sessionStorage.setItem('@nmblr/rotateScreenTip', 'hide');
                setHide(true);
              }}
            />
          </CTAWrapper>
        </Content>
      </Modal>
    </Wrapper>
  );
};
