import { BodySmall } from 'components/shared';
import { colors } from 'constants/index';
import { useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { KEY_EVENT_ICON_WIDTH } from './constants';

const Container = styled.div<{ changeEdgeOffset: boolean }>`
  position: relative;
  top: 24px;
  width: fit-content;
  transform: ${({ changeEdgeOffset }) =>
    changeEdgeOffset
      ? `translate(calc(-100% + ${KEY_EVENT_ICON_WIDTH}px))`
      : 'none'};
`;

const Wrapper = styled.div<{ offsetX?: number | undefined }>`
  background: ${colors.white};

  border: 1px solid ${colors.greyLight};
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  position: relative;

  width: 325px;

  ${({ offsetX }) => {
    if (typeof offsetX === 'number') {
      return css`
        left: ${offsetX}px;
      `;
    } else {
      return css`
        // center above pin
        transform: translateX(calc(-50% + 15px));
      `;
    }
  }}
`;

const ListWrapper = styled.div<{ offsetX: number | undefined }>`
  background: ${colors.cream};

  border: 1px solid ${colors.greyMedium};

  border-radius: 5px;

  display: flex;
  flex-direction: column;
  gap: 5px;


  padding: 5px;
  max-height: 335px;
  overflow: auto;

  ${Wrapper} {
    position: relative;
    bottom: auto;
    transform: none;
  }
  ${({ offsetX }) => {
    if (typeof offsetX === 'number') {
      return css`
        left: ${offsetX}px;
      `;
    } else {
      return css`
        // center above pin
        transform: translateX(calc(-50% + 15px));
      `;
    }
  }}
`;

type Props = {
  data: { text: string }[];
  onMouseEnter(): void;
  onMouseLeave(): void;
  offsetX: number | undefined;
};

export const TimelineKeyEventCallOut = ({
  data,
  onMouseEnter,
  onMouseLeave,
  offsetX,
}: Props) => {
  const containerRef = useRef<null | HTMLDivElement>(null);

  const [changeEdgeOffset, setChangeEdgeOffset] = useState(false);

  // Render on left side if near right edge
  useLayoutEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    const { right } = container.getBoundingClientRect();

    const pageWidth = window.innerWidth;

    setChangeEdgeOffset(right >= pageWidth);
  }, [data]);

  if (data.length > 1) {
    return (
      <Container ref={containerRef} changeEdgeOffset={changeEdgeOffset}>
        <ListWrapper
          offsetX={offsetX}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {data.map((val, idx) => {
            return (
              <Wrapper key={idx}>
                <BodySmall>{val.text || 'Not identified yet'}</BodySmall>
              </Wrapper>
            );
          })}
        </ListWrapper>
      </Container>
    );
  }

  return (
    <Container ref={containerRef} changeEdgeOffset={changeEdgeOffset}>
      <Wrapper
        offsetX={offsetX}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <BodySmall>{data[0].text || 'Not identified yet'}</BodySmall>
      </Wrapper>
    </Container>
  );
};
