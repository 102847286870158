import { colors } from 'constants/colors';
import { EditIcon } from 'containers/AdminCompany';
import { useAuthContext } from 'contexts/AuthContext';
import { User } from 'data/graphql/generated';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { CountryFlag, StyledDiv, StyledText } from './CountryFlag';
import { BodySmall, Subtitle2 } from './TextStyles';
import orderBy from 'lodash/orderBy';
import { UserDetails } from './UserDetails';

interface TableProps {
  users: User[];
  editUser(user: User): void;
  deleteUser(user: User): void;
  displayVerifiedStatus: boolean;
  companyId?: number;
  drugId: number;
  strategyId: number;
}

const EmptyTable = styled.div`
  background: ${colors.yellow20};
  width: 100%;
  height: 197px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${Subtitle2} {
    color: ${colors.greyDark};
  }

  @media ${device.tabletMin} {
    height: 127px;
  }

  @media ${device.desktopMin} {
    height: 82px;
  }
`;

const mobileColumns = css`
  grid-template-columns: 6fr 4fr;
`;
const desktopColumns = css`
  grid-template-columns: 4fr 2.5fr 2.9fr;
`;

export const TableTitles = styled.div`
  display: none;
  margin-bottom: 10px;

  ${BodySmall} {
    color: ${colors.greyDark};
  }

  @media ${device.tabletMin} {
    display: grid;
    ${mobileColumns}
    align-items: center;
  }

  @media ${device.desktopMin} {
    ${desktopColumns}
    padding-left: 10px;
  }
`;

const MobileTitle = styled(BodySmall)`
  @media ${device.desktopMin} {
    display: none;
  }
`;

const DesktopTitle = styled(BodySmall)`
  display: none;

  @media ${device.desktopMin} {
    display: block;
  }
`;

const PopulatedTable = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid ${colors.greyLight};
`;

export const UserRow = styled.li`
  padding: 15px 0;
  position: relative;
  border-top: 1px solid ${colors.greyLight};

  @media ${device.tabletMin} {
    display: grid;
    ${mobileColumns}
  }

  @media ${device.desktopMin} {
    padding: 15px;
    ${desktopColumns}
  }

  ${StyledText} {
    margin-left: 5px;
  }
`;

const RoleAndResponsibility = styled.div`
  ${BodySmall} {
    margin-bottom: 5px;
  }

  ${StyledDiv} {
    justify-content: flex-start;
  }

  ${StyledText} {
    margin: 0 0 0 5px;
  }

  @media ${device.tabletMin} {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media ${device.desktopMin} {
    ${StyledDiv} {
      display: none;
    }

    ${BodySmall} {
      margin-left: 5px;
      margin-bottom: 0;
    }
  }
`;

const DesktopCountryFlag = styled.div`
  display: none;
  margin-left: 5px;

  @media ${device.desktopMin} {
    display: flex;
  }
`;

const EditIcons = styled.div`
  display: flex;
  width: 70px;
  margin-top: 20px;

  div:first-child {
    margin-right: 10px;
  }

  ${EditIcon} {
    margin-top: 0;
  }

  @media ${device.tabletMin} {
    margin-top: 15px;
  }

  @media ${device.desktopMin} {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;
  }
`;

const CollaboratorsTable: React.FC<TableProps> = ({
  users,
  editUser,
  deleteUser,
  displayVerifiedStatus,
  drugId,
  companyId,
  strategyId,
}) => {
  const [{ user: loggedInUser }] = useAuthContext();
  const isEmpty = !users.length;

  const getSanitisedRole = (role: any) => {
    switch (role) {
      case 'LEAD':
        return 'Lead';
      default:
        return 'Contributor';
    }
  };

  const sortedUsers = orderBy(
    users,
    [
      (user) => {
        return user.name.toLowerCase();
      },
    ],
    ['asc']
  );

  return (
    <>
      <TableTitles>
        <BodySmall>Name</BodySmall>
        <MobileTitle>{'Role & Responsibility'}</MobileTitle>
        <DesktopTitle>Role</DesktopTitle>
        <DesktopTitle>Responsibility</DesktopTitle>
      </TableTitles>
      {isEmpty ? (
        <EmptyTable>
          <Subtitle2>No collaborators yet</Subtitle2>
        </EmptyTable>
      ) : (
        <PopulatedTable>
          {sortedUsers.map((user) => {
            let { role, verifiedEmail } = user;

            return (
              <UserRow key={user.id}>
                <UserDetails
                  user={user}
                  displayStatus={displayVerifiedStatus}
                  drugId={drugId}
                  companyId={companyId}
                  strategyId={strategyId}
                />
                <RoleAndResponsibility>
                  <BodySmall>{getSanitisedRole(role)}</BodySmall>
                  <CountryFlag size={25} user={user} showLabel />
                </RoleAndResponsibility>
                <DesktopCountryFlag>
                  <CountryFlag size={25} user={user} showLabel />
                </DesktopCountryFlag>
                {loggedInUser?.role === 'LEAD' && loggedInUser.id !== user.id && (
                  <EditIcons>
                    <EditIcon
                      size={30}
                      name="Pencil"
                      onClick={() => editUser(user)}
                    />
                    {role === 'LEAD' && verifiedEmail ? null : (
                      <EditIcon
                        size={30}
                        name="Trash"
                        color={'red'}
                        onClick={() => deleteUser(user)}
                      />
                    )}
                  </EditIcons>
                )}
              </UserRow>
            );
          })}
        </PopulatedTable>
      )}
    </>
  );
};

export default CollaboratorsTable;
