export function thousandSeparator(x: number | string = '') {
  // Splits large numbers by commas to indicate thousand values
  if (typeof x === 'number') {
    return Math.round(x)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    if (isNaN(parseInt(x))) {
      return '';
    }

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
