import { colors } from 'constants/index';
import React from 'react';
import styled from 'styled-components';
import { uid } from 'uid';
import { Icon } from '.';
import { BodySmall } from './TextStyles';
import { Tooltip } from './Tooltip';

const OverlayWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.purple10};
`;

export interface AvatarWithoutTooltipProps {
  src?: string;
  size: number;
  text?: string;
  tooltip?: never;
  disableTooltip: boolean;
}

export interface AvatarWithTooltipProps {
  src?: string;
  size: number;
  text?: string;
  tooltip?: string;
  disableTooltip?: never;
}

const Border = styled.div<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50px;
  padding: 2px;
  overflow: hidden;
  background: ${`linear-gradient(315deg, ${colors.pink} -83%, ${colors.purple} 93.75%)`};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  overflow: hidden;
  img {
    width: 100%;
  }
`;

export const Avatar = ({
  tooltip,
  src,
  size,
  text,
  disableTooltip,
}: AvatarWithoutTooltipProps | AvatarWithTooltipProps) => {
  const key = uid();
  return (
    <>
      <Border size={size}>
        <Wrapper
          data-offset="{ 'right': -14, 'top':18 }"
          data-for={key}
          data-tip={tooltip}
        >
          <OverlayWrapper>
            {src ? (
              <img src={src} alt={tooltip} />
            ) : text ? (
              <BodySmall color={colors.purple}>{text}</BodySmall>
            ) : (
              //Empty State
              <Icon name={'Patient'} size={40} color={colors.purple} />
            )}
          </OverlayWrapper>
        </Wrapper>
      </Border>
      {disableTooltip ? null : (
        <Tooltip id={key} effect="float" place="right" multiline />
      )}
    </>
  );
};
