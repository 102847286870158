import { getCriticalMetricsGoalUrl } from './getCriticalMetricsGoalUrl';
import { getCriticalMetricsLeadAndLagIndicatorsUrl } from './getCriticalMetricsLeadAndLagIndicatorsUrl';
import { getCriticalMetricsTrackingUrl } from './getCriticalMetricsTrackingUrl';
import { getCriticalMetricsUrlBySubStep } from './getCriticalMetricsUrlBySubStep';
import { getStrategyUrl } from './getStrategyUrl';
import { useSetPatientJourneyURL } from './useSetPatientJourneyURL';
import { getPatientJourneyUrl } from './getPatientJourneyUrl';

const URLHelper = {
  getStrategyUrl,
  getCriticalMetricsLeadAndLagIndicatorsUrl,
  getCriticalMetricsGoalUrl,
  getCriticalMetricsTrackingUrl,
  getCriticalMetricsUrlBySubStep,
  useSetPatientJourneyURL,
  getPatientJourneyUrl,
};

export default URLHelper;
