import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import Textarea from 'react-expanding-textarea';

import {
  CollaborationDocument,
  useCommentCreateMutation,
  useCommentUpdateMutation,
  Attachment,
  Comment,
} from 'data/graphql/generated';
import { Icon } from './shared';
import { colors } from 'constants/colors';
import { useAttachmentCreate } from 'hooks/useAttachmentCreate';
import { AttachmentPreview } from './Collaboration/Attachment';
import { CommentRefetchQueryVarsType } from './Comment';
import useDesktop from 'hooks/useDesktop';

export const Container = styled.div<{
  disabled?: boolean;
  invalid?: boolean;
}>`
  margin-bottom: 5px;
  margin-top: 15px;
  border: 1px solid
    ${({ disabled, invalid }) => {
      if (disabled) return colors.black30a;
      if (invalid) return colors.red;
      return colors.greyDark;
    }};
  border-radius: 3px;

  &:hover {
    border-color: ${({ disabled, invalid }) => {
      if (disabled) return colors.black30a;
      if (invalid) return colors.red;
      return colors.black;
    }};
  }

  &:focus {
    border-color: ${({ disabled, invalid }) => {
      if (disabled) return colors.black30a;
      if (invalid) return colors.red;
      return colors.black;
    }};
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px;
  gap: 8px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TextInput = styled(Textarea)<{
  disabled?: boolean;
  invalid?: boolean;
}>`
  width: 100%;

  display: block;
  overflow-y: hidden;
  resize: none;
  width: 100%;

  font-family: ABCFavorit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;

  border: none;
  outline: none;
  color: ${({ disabled }) => (disabled ? colors.black50 : colors.black)};

  transition: background 0.3s, color 0.3s, border 0.3s;
  border-radius: 3px;
`;

const Attachments = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  width: 100%;
  padding-right: 10px;
`;

const UploadIcons = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 5px;
`;

const AttachmentWrapper = styled.div`
  cursor: pointer;
`;

const SendWrapper = styled.div<{ text: string }>`
  cursor: pointer;
  padding: 7px 8px;
  display: block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid
    ${({ text }) => (text.length === 0 ? 'transparent' : colors.purple50)};

  &:hover {
    background: ${({ text }) =>
      text.length === 0 ? 'transparent' : colors.purple05};
  }
`;

interface Props {
  comment?: Comment;
  collaborationId: number;
  commentId?: number;
  commentText?: string;
  onBlur?(e: React.ChangeEvent<HTMLTextAreaElement>, text: string): void;
  saveOnBlur?: boolean;
  autoFocus?: boolean;
  replyTo?: number;
  queryVars: CommentRefetchQueryVarsType;
}

export const CommentForm: React.FC<Props> = ({
  comment,
  collaborationId,
  commentId,
  commentText,
  onBlur,
  saveOnBlur = false,
  autoFocus = false,
  queryVars,
  replyTo,
}) => {
  const [text, setText] = useState(commentText || '');
  const [sendHover, setSendHover] = useState(false);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isDesktop = useDesktop();

  useEffect(() => {
    if (commentText) setText(commentText);
  }, [commentText]);

  const {
    dropzone: { getInputProps, inputRef },
    uploading,
    uploadingName,
    failedUpload,
    errMsg,
    clearErr,
  } = useAttachmentCreate({
    collaborationId,
    disableNotification: true,
    isDraft: true,
    onSuccess: (attachment) => {
      setAttachments([...attachments, attachment]);
      const hiddenInput = inputRef.current;
      if (!hiddenInput) return;
      hiddenInput.value = '';
    },
  });

  const [commentCreate] = useCommentCreateMutation();
  const [commentUpdate] = useCommentUpdateMutation();

  const sendIconHover =
    text.length === 0
      ? colors.greyMedium
      : sendHover
      ? colors.purple
      : colors.greyDark;

  async function submit() {
    if (!collaborationId || !text.length || text === commentText) {
      return;
    }

    if (comment?.id) {
      await commentUpdate({
        variables: {
          id: comment?.id,
          data: {
            text,
            resolved: false,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: CollaborationDocument,
            ...queryVars,
          },
        ],
        optimisticResponse: {
          commentUpdate: {
            ...comment,
            text,
            resolved: false,
          },
        },
        update: (cache, { data }) => {
          cache.modify({
            id: cache.identify({
              __ref: `Comment:${comment.id}`,
            }),
            fields: {
              text() {
                return text;
              },
              resolved() {
                return false;
              },
            },
          });
        },
      });
    } else {
      const commentText = text;
      setText('');
      await commentCreate({
        variables: {
          data: {
            replyTo,
            text: commentText,
            collaborationId,
            resolved: false,
            attachments: attachments.map((a) => a.id),
          },
        },
        refetchQueries: [
          {
            query: CollaborationDocument,
            ...queryVars,
          },
        ],
      });
      setAttachments([]);
    }
  }

  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!isDesktop) return;

    // put cursor at the end of text when editing
    ref?.current?.focus();
    ref?.current?.setSelectionRange(
      ref?.current?.value?.length,
      ref?.current?.value?.length
    );
  }, [isDesktop]);

  return (
    <Container>
      <Content>
        <TextInput
          ref={ref}
          value={text}
          placeholder="Add a comment"
          autoFocus={autoFocus}
          onChange={(e) => {
            const target = e.target as HTMLTextAreaElement;
            setText(target.value);
          }}
          onBlur={(e) => {
            if (saveOnBlur) submit();
            if (onBlur) {
              onBlur(e, text);
            }
          }}
        />

        <Attachments visible={uploading || attachments.length > 0}>
          {uploading ? (
            <AttachmentPreview
              file={
                {
                  id: 99999,
                  lastUpdated: new Date().toISOString(),
                  createdAt: new Date().toISOString(),
                  title: uploadingName || '',
                  collaborationId,
                } as Attachment
              }
              uploading
              failedUpload={failedUpload}
              errMsg={errMsg}
              clearErr={clearErr}
              onDelete={() => {}}
            />
          ) : null}
          <input id="fileUploadInputRef" type="file" {...getInputProps()} />

          {attachments.map((attachment) => (
            <AttachmentPreview
              key={attachment.id}
              file={attachment as Attachment}
              clearErr={() => {}}
              onDelete={(attachment) => {
                setAttachments(
                  attachments.filter((a) => a.id !== attachment.id)
                );
              }}
              canBeDeleted
            />
          ))}
        </Attachments>
      </Content>

      <UploadIcons>
        <AttachmentWrapper
          onClick={async () => {
            const hiddenInput = inputRef.current;
            if (hiddenInput) hiddenInput.click();
          }}
        >
          <Icon size={20} name="Attachment" color="rgba(105, 105, 117, 1)" />
        </AttachmentWrapper>

        <SendWrapper
          onClick={async () => {
            if (!isSubmitting) {
              setIsSubmitting(true);
              await submit();
              setIsSubmitting(false);
            }
          }}
          onMouseEnter={() => {
            setSendHover(true);
          }}
          onMouseLeave={() => {
            setSendHover(false);
          }}
          text={text}
        >
          <Icon size={15} name="Send" color={sendIconHover} />
        </SendWrapper>
      </UploadIcons>
    </Container>
  );
};
