import { ModalForm } from 'components/ModalForm';
import React from 'react';
import styled from 'styled-components';
import { ButtonPill } from 'components/shared';
import { TooltipWrapper } from 'components/shared/ButtonPill';
import {
  CompetitorFragment,
  StakeholderDefinitionsDocument,
  StakeholderDefinitionsQueryVariables,
  useCompetitorDeleteMutation,
} from 'data/graphql/generated';

export const deleteCompetitorModalDefaultState = {
  competitor: null,
  hasPostIts: null,
};
interface Props {
  deleteCompetitorModal: DeleteCompetitorModalShape;
  setDeleteCompetitorModal: React.Dispatch<
    React.SetStateAction<DeleteCompetitorModalShape>
  >;
  refetchCompetitors(): Promise<void>;
  stakeholderDefinitionsQueryVars: StakeholderDefinitionsQueryVariables;
}
export interface DeleteCompetitorModalShape {
  competitor: CompetitorFragment | null;
  hasPostIts: boolean | null;
}

const Wrapper = styled.div``;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 10px;

  ${TooltipWrapper} {
    width: 180px;
  }
`;

export const DeleteCompetitorModal: React.FC<Props> = ({
  deleteCompetitorModal,
  setDeleteCompetitorModal,
  refetchCompetitors,
  stakeholderDefinitionsQueryVars,
}) => {
  const [competitorDelete] = useCompetitorDeleteMutation();
  const [loading, setLoading] = React.useState(false);

  function closeModal() {
    setDeleteCompetitorModal(deleteCompetitorModalDefaultState);
  }

  if (!deleteCompetitorModal.competitor) return null;
  let { id, title } = deleteCompetitorModal.competitor;

  const hasPostIts = deleteCompetitorModal.hasPostIts;

  async function handleDelete() {
    try {
      setLoading(true);

      await competitorDelete({
        variables: { id: +id },
        refetchQueries: [
          {
            query: StakeholderDefinitionsDocument,
            variables: stakeholderDefinitionsQueryVars,
          },
        ],
      });

      await refetchCompetitors();
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
      closeModal();
    }
  }

  return (
    <Wrapper>
      {hasPostIts ? (
        <ModalForm
          handleClose={closeModal}
          heading={`Cannot delete ${title}`}
          message={
            'You can only delete a competitor when it has no notes against it. Check it has no notes against it for all stakeholders and try again.'
          }
          visible={!!deleteCompetitorModal.competitor}
        >
          <ButtonsContainer>
            <ButtonPill onClick={closeModal} text="Okay" />
          </ButtonsContainer>
        </ModalForm>
      ) : (
        <ModalForm
          handleClose={closeModal}
          heading={`Delete ${title}?`}
          message={
            'You won’t be able to assess this competitor for any stakeholder if you delete it.'
          }
          visible={!!deleteCompetitorModal.competitor}
        >
          <ButtonsContainer>
            <ButtonPill onClick={closeModal} text="Cancel" level="secondary" />

            <ButtonPill
              onClick={handleDelete}
              text="Delete competitor"
              loading={loading}
              level="danger"
            />
          </ButtonsContainer>
        </ModalForm>
      )}
    </Wrapper>
  );
};
