import { colors } from 'constants/index';
import React, { useEffect, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ButtonLabel, ButtonRound } from '.';
import { zIndex } from 'constants/zIndex';

const zIndexBackToDefault = keyframes`
  0% { z-index: auto; }
  100% { z-index: ${zIndex.btnExpandOpen}; }
`;

const zIndexBackToDefault2 = keyframes`
  0% { z-index: ${zIndex.btnExpandOpen}; }
  100% { z-index: auto; }
`;

const Wrapper = styled.div<{ open: boolean }>`
  position: absolute;
  top: -5px;
  right: -15px;
  user-select: none;
  display: inline-flex;
  align-items: center;
  border: none;
  background-color: ${colors.yellow75};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 40px;
  max-width: 40px;
  padding: 15px;
  opacity: 0;
  animation: ${zIndexBackToDefault2} 0s 0.3s both;

  ${({ open }) =>
    open
      ? css`
          animation: ${zIndexBackToDefault} 0s 0s both;
          max-height: 1000px;
          border-radius: 5px;
          max-width: 1000px;
          border: 0.5px solid ${colors.black30};
          opacity: 1;
          > * {
            opacity: 1;
            transition: opacity 0.1s;
          }
        `
      : css`
          max-height: 30px;
          border-radius: 25px;
          border: 0px solid transparent;
          > * {
            opacity: 0;
            transition: opacity 0.1s;
          }
        `}

  font-family: ABCFavorit;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  transition: all 0.3s;
  &:focus {
    outline: none;
  }

  > p {
    width: 100%;
  }
`;
const ButtonWrapper = styled.div<{
  open: boolean;
  positionAbsolute?: boolean;
}>`
  position: ${({ positionAbsolute }) =>
    positionAbsolute ? 'absolute' : 'relative'};
  button {
    transition: background-color 0.1s, transform 0.1s;
  }
  ${({ open }) => {
    if (open)
      return css`
        z-index: ${zIndex.btnExpandOpenText};
        button {
          transform: rotate(-45deg);
          background-color: transparent;
        }
        button:hover {
          background-color: transparent;
        }
      `;
  }}
`;

const InnerBtnWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  > * + * {
    margin-top: 10px;
  }
`;

interface Props {
  open: boolean;
  setOpen(state: boolean): void;
  positionAbsolute?: boolean;
  labelText?: string;
}

export const ButtonRoundExpand: React.FC<Props> = ({
  children,
  open,
  setOpen,
  positionAbsolute,
  labelText,
}) => {
  const pillRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closePill = () => {
      setOpen(false);
    };
    window.addEventListener('click', closePill);
    return () => {
      window.removeEventListener('click', closePill);
    };
  }, [setOpen]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{ position: 'relative' }}
    >
      <Wrapper open={open}>
        <ButtonLabel>{labelText || 'Add note'}</ButtonLabel>
        <InnerBtnWrapper>{children}</InnerBtnWrapper>
      </Wrapper>
      <ButtonWrapper
        ref={pillRef}
        open={open}
        positionAbsolute={positionAbsolute}
      >
        <ButtonRound
          level="add"
          iconName="Plus"
          size={40}
          onClick={() => {
            setOpen(!open);
          }}
        />
      </ButtonWrapper>
    </div>
  );
};
