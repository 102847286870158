import { BlockType } from 'data/graphql/generated';
import React from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';
import { SharedPatientJourneyTypes } from '../types';

interface Props {
  type: BlockType | 'ThumbsUp' | 'Comment' | 'Attachment' | 'ActionPurple';
  canEdit?: boolean;
  listening?: boolean;
  isActiveInSidebar?: boolean;
  inRecapSidebar?: SharedPatientJourneyTypes['inRecapSidebar'];
}

interface ImageProps {
  src: string;
  width: number;
  height: number;
}

function getBlockImage(
  type: Props['type'],
  canEdit: boolean,
  isActiveInSidebar: boolean,
  inRecapSidebar: boolean
): ImageProps {
  switch (true) {
    case type === BlockType.Patient:
      return { src: '/patient-journey/Patient.png', width: 30, height: 30 };
    case type === BlockType.Healthcareprofessional:
      return { src: '/patient-journey/HCP.png', width: 30, height: 30 };
    case type === BlockType.Payor:
      return { src: '/patient-journey/Payor.png', width: 30, height: 30 };
    case type === BlockType.Treatment:
      return { src: '/patient-journey/Treatment.png', width: 30, height: 30 };
    case type === BlockType.Provider:
      return { src: '/patient-journey/Provider.png', width: 30, height: 30 };
    case type === BlockType.Policymaker:
      return { src: '/patient-journey/Policymaker.png', width: 30, height: 30 };
    case type === BlockType.Step && inRecapSidebar:
      return {
        src: '/patient-journey/External-link.png',
        width: 30,
        height: 30,
      };
    case type === BlockType.Step && isActiveInSidebar && canEdit:
      return {
        src: '/patient-journey/MoreDetailActive.png',
        width: 30,
        height: 30,
      };
    case type === BlockType.Step && canEdit:
      return {
        src: '/patient-journey/MoreDetail.png',
        width: 30,
        height: 30,
      };
    case type === BlockType.Step && isActiveInSidebar:
      return {
        src: '/patient-journey/MoreTextActive.png',
        width: 30,
        height: 30,
      };
    case type === BlockType.Step:
      return {
        src: '/patient-journey/MoreText.png',
        width: 30,
        height: 30,
      };
    case type === 'ThumbsUp':
      return {
        src: '/icons/Thumbs-up.png',
        width: 15,
        height: 15,
      };
    case type === 'Comment':
      return {
        src: '/icons/Comment.png',
        width: 15,
        height: 14,
      };
    case type === 'Attachment':
      return {
        src: '/icons/Attachment.png',
        width: 14,
        height: 16,
      };
    default:
      return { src: '', width: 0, height: 0 };
  }
}

export const BlockIcon: React.FC<Props> = ({
  type,
  canEdit = false,
  listening = true,
  isActiveInSidebar = false,
  inRecapSidebar = false,
}) => {
  const image = getBlockImage(type, canEdit, isActiveInSidebar, inRecapSidebar);
  const [imageSrc] = useImage(image.src);

  return (
    <Image
      listening={listening}
      image={imageSrc}
      width={image.width}
      height={image.height}
    />
  );
};
