import { colors } from 'constants/colors';
import { css, keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const fadeFromPurpleBG = keyframes`
  0% {
    background:${colors.purple20};
  }
  80% {
    background:${colors.purple20};
  }
 
  100% {
    background:transparent;
  }
`;

export const fadeFromPurpleBGCSS = css`
  content: '';
  background: transparent;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  animation: ${fadeFromPurpleBG} 1s;
  animation-delay: 0.2s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
`;
