import styled from 'styled-components/macro';

import { Collaboration } from 'components/shared';
import { colors } from 'constants/colors';

export const MobileContainer = styled.div<{ isFocused: boolean }>`
  position: relative;
  background: ${({ isFocused }) =>
    isFocused ? colors.purplePinkGradient : colors.white};
  border-radius: 5px;
  border: 1px solid transparent;
  ::before {
    display: ${({ isFocused }) => (isFocused ? 'block' : 'none')};
    position: absolute;
    inset: 0;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: ${colors.fadedGradient}, white;
    z-index: 0;
  }
`;

export const MobileRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  position: relative;
`;

export const MobileRowOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledCollaboration = styled(Collaboration)`
  margin-left: auto;
`;
