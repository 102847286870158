import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { zIndex } from 'constants/zIndex';

export const Wrapper = styled.div`
  position: absolute;
  z-index: ${zIndex.tacticCallout};
`;

interface Props {
  onMouseOver?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onMouseOut?: React.MouseEventHandler<HTMLDivElement> | undefined;
  pinID?: number;
  children: React.ReactNode;
}

export const HoverCalloutWrapper: React.FC<Props> = ({
  onMouseOver,
  onMouseOut,
  pinID,
  children,
}) => {
  const calloutCardRef = useRef<HTMLDivElement | null>(null);
  const [positionCardAbove, setPositionCardAbove] = useState(false);
  const [calloutCardHeight, setCalloutCardHeight] = useState(0);
  const [opacity, setOpacity] = useState(0);
  const hoveredPin = document.getElementById(`draggable-pin-${pinID}`);
  const pinRect = hoveredPin?.getBoundingClientRect();
  const factoringForScroll = pinRect?.top ? pinRect.top + window.scrollY : 0;

  // If callout card is off edge of viewport, position it above the pin
  useEffect(() => {
    setOpacity(0); // Hide card until position has been calculated to prevent the card flickering from below to above the pin

    const viewportHeight = window.innerHeight;
    const calloutCard = calloutCardRef.current;
    if (!calloutCard || !hoveredPin || !pinRect) return;

    if (factoringForScroll + 35 + calloutCard.offsetHeight > viewportHeight) {
      setPositionCardAbove(true);
      setOpacity(1);
      setCalloutCardHeight(calloutCard.offsetHeight);
    } else {
      setPositionCardAbove(false);
      setOpacity(1);
    }
  }, [calloutCardHeight, hoveredPin, pinRect, factoringForScroll]);

  if (!pinRect) return null;

  return (
    <Wrapper
      ref={calloutCardRef}
      style={{
        opacity,
        top: positionCardAbove
          ? factoringForScroll - calloutCardHeight - 5
          : factoringForScroll + 35,
        left: pinRect.left,
      }}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
    >
      {children}
    </Wrapper>
  );
};
