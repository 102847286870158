import { ReactNode, useRef } from 'react';
import styled from 'styled-components/macro';

import { zIndex } from 'constants/zIndex';
import { colors } from 'constants/index';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { useGetNavHeight } from 'hooks/useGetNavHeight';

const InnerWrapper = styled.div`
  width: 555px;
  height: 100%;
  max-width: 100%;
`;

const Wrapper = styled.div<{ heightOffset: number; isOpen: boolean }>`
  background: ${colors.black05};
  overflow-y: auto;
  bottom: 0;
  left: 15px;
  right: 0;
  z-index: ${zIndex.reusableSidebarDefault};
  order: 1;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  transform: translate3d(${({ isOpen }) => (isOpen ? 0 : '100%')}, 0, 0);
  transition: transform 200ms linear;

  top: ${({ heightOffset }) => heightOffset}px;
  height: calc(100vh - ${({ heightOffset }) => heightOffset}px);
  width: auto;
  position: fixed;
  will-change: transform;
  @media (min-width: 583px) {
    left: auto;
    width: 555px;
    min-width: 555px;
    height: calc(100vh - ${({ heightOffset }) => heightOffset}px);
  }
`;

type Props = {
  heightOffset?: number;
  children?: ReactNode;
  isOpen: boolean;
  onClickOutside?(event?: MouseEvent): void;
  ignoreClickClasses?: string[];
  className?: string;
};

export const ReusableLargeSidebar = ({
  children,
  isOpen,
  onClickOutside,
  ignoreClickClasses,
  className,
  heightOffset,
}: Props) => {
  const containerRef = useRef(null);
  const navHeight = useGetNavHeight();

  useClickOutsideComponent(
    containerRef,
    (event) => {
      onClickOutside?.(event);
    },
    ignoreClickClasses
  );

  return (
    <Wrapper
      className={className}
      ref={containerRef}
      isOpen={isOpen}
      heightOffset={heightOffset ?? navHeight}
    >
      <InnerWrapper>{children}</InnerWrapper>
    </Wrapper>
  );
};
