import { ConnectionSide, Position } from 'types';
import { RECT_WIDTH } from '../../../constants';

export function getDotPostionFromBlockXY(
  blockX: number,
  blockY: number,
  side: ConnectionSide,
  blockHeight: number
): Position {
  switch (side) {
    case 'top':
      return { x: blockX + RECT_WIDTH / 2, y: blockY + 0 };
    case 'bottom':
      return { x: blockX + RECT_WIDTH / 2, y: blockY + blockHeight };
    case 'right':
      return { x: blockX + RECT_WIDTH, y: blockY + blockHeight / 2 };
    case 'left':
      return { x: blockX + 0, y: blockY + blockHeight / 2 };
    default:
      return { x: blockX, y: blockY + 0 };
  }
}
