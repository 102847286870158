import React from 'react';
import { Navbar } from 'components/Navbar';
import styled from 'styled-components';
import InformationModal from 'components/shared/InformationModal';
import { Icon } from '../components/shared/Icon';
import { colors } from 'constants/colors';
import { Subtitle1, ButtonPill, BodySmall, Subtitle2 } from 'components/shared';
import { device } from 'utils/breakpoints';

const StyledSubtitle2 = styled(Subtitle2)`
  margin-top: 15px;
`;

export const PageWrapper = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  padding-bottom: 50px;
  min-height: fit-content;
`;

export const FullLogo = styled(Icon)``;

const Logo = styled.div`
  margin: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media ${device.mobile} {
    display: none;
  }
`;

const MobileLogo = styled.div`
  display: none;

  @media ${device.mobile} {
    display: block;
    margin: 40px auto 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

const SignInModal = styled(InformationModal)`
  min-height: 220px;
  width: 456px;

  @media ${device.mobile} {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;

    .wrapperTop {
      display: none;
    }
  }
`;

export const Title = styled(Subtitle1)`
  margin-bottom: 15px;
`;

export const Form = styled.form`
  padding: 30px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  align-items: center;

  @media ${device.mobile} {
    padding: 0 15px;
  }
`;

export const ErrorBox = styled.div`
  width: 376px;
  width: 100%;
  padding: 15px;
  background: ${colors.red10};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: inline;
`;

export const ErrorMessage = styled(BodySmall)`
  width: 346px;
  display: inline;
`;

export const SubmitButton = styled(ButtonPill)`
  margin: 15px;
`;

export const Signin: React.FC<{
  children: React.ReactNode;
  showWelcome?: boolean;
}> = ({ children, showWelcome }) => {
  return (
    <>
      <Navbar
        exclude={[
          'drugLink',
          'strategyLink',
          'filesLink',
          'profile',
          'notifications',
          'navItems',
          'navContent',
          'sidebarTrigger',
        ]}
        disableSecondary
      />
      <PageWrapper>
        <Logo>
          <Icon name="FullLogo" height={40} size={125} />
          {showWelcome ? (
            <StyledSubtitle2 color={colors.greyDark}>
              Welcome to Nmblr
            </StyledSubtitle2>
          ) : null}
        </Logo>

        <SignInModal>
          <MobileLogo>
            <Icon name="FullLogo" height={40} size={125} />
            {showWelcome ? (
              <StyledSubtitle2 color={colors.greyDark}>
                Welcome to Nmblr
              </StyledSubtitle2>
            ) : null}
          </MobileLogo>

          {children}
        </SignInModal>
      </PageWrapper>
    </>
  );
};
