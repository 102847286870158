import React, { useState } from 'react';
import styled from 'styled-components';

import { colors } from 'constants/colors';
import { useCollaborationContext } from 'contexts/CollaborationContext';
import {
  Alignment,
  Collaboration,
  useAlignmentCreateMutation,
  useAlignmentDeleteMutation,
  useCollaborationQuery,
  User,
} from 'data/graphql/generated';
import { Icon } from './Icon';
import { useAuthContext } from 'contexts/AuthContext';

const Wrapper = styled.div<{
  lightBG: boolean;
  disabled?: boolean;
  isIconHovered: boolean;
}>`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  border-radius: 30px;
  height: 25px;
  padding: 0px 4px;
  > div {
    opacity: 0.7;
  }

  &:hover {
    background: ${({ isIconHovered }) =>
      !isIconHovered ? colors.black10 : 'unset'};
  }
`;

/* commenting first
 &:hover {
   background: ${({ lightBG, isIconHovered }) =>
     lightBG ? 'rgba(350, 350, 350, 0.3)' : colors.black10};
 }
*/

const IconHoverWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  border-radius: 30px;
  height: 25px;
  padding: 0px 4px;

  &:hover {
    background: ${colors.black10};
  }
`;

const Amount = styled.span`
  font-family: ABCFavorit;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin: 0px 6px 0px 2px;
  padding-top: 2px;
  color: ${colors.black70a};
`;

interface Props {
  collaboration?:
    | Pick<
        Collaboration,
        'id' | 'commentCount' | 'attachmentCount' | 'alignmentCount'
      >
    | null
    | undefined;
  lightBG?: boolean;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

export const CollaborationPreview: React.FC<Props> = ({
  collaboration,
  lightBG = false,
  className,
  style,
  disabled,
}) => {
  const [, setCollaboration] = useCollaborationContext();
  const [deleteAlignment] = useAlignmentDeleteMutation();
  const [createAlignment] = useAlignmentCreateMutation();
  const strategyId = +window.location.pathname.split('/')[4];
  const [auth] = useAuthContext();
  const user = auth.user;

  const collaborationQueryVars = {
    variables: {
      id: collaboration?.id,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  } as Parameters<typeof useCollaborationQuery>[0];

  const { data } = useCollaborationQuery(collaborationQueryVars);
  const [isIconHovered, setIsIconHovered] = useState(false);

  const alignments = data?.collaboration?.alignments;

  const userAlignment = alignments?.find((alignment) => {
    return alignment?.user.id === user?.id;
  });

  return (
    <Wrapper
      isIconHovered={isIconHovered}
      disabled={disabled}
      className={className + ' cypress-collaboration-open'}
      lightBG={lightBG}
      style={style}
      onClick={(event) => {
        event.stopPropagation();
        if (collaboration?.id) {
          setCollaboration(collaboration.id);
        } else {
          alert('Collaboration not setup yet');
        }
      }}
    >
      <IconHoverWrapper
        onMouseEnter={() => setIsIconHovered(true)}
        onMouseLeave={() => setIsIconHovered(false)}
      >
        <Icon
          name={
            collaboration?.alignmentCount ? 'Thumbs up Active' : 'Thumbs up'
          }
          onClick={async (event) => {
            event.stopPropagation();
            if (!collaboration?.id) {
              return;
            }
            if (userAlignment) {
              if (!userAlignment?.id) {
                return;
              }
              deleteAlignment({
                variables: { id: userAlignment?.id },
                optimisticResponse: {
                  alignmentDelete: {
                    ...userAlignment,
                  },
                },
                update: (cache, { data }) => {
                  cache.modify({
                    id: cache.identify({
                      __ref: `Collaboration:${data?.alignmentDelete.collaborationId}`,
                    }),
                    fields: {
                      alignmentCount(alignment) {
                        return alignment === 0 ? 0 : alignment - 1;
                      },
                      alignments(
                        alignments: Pick<
                          Alignment,
                          'id' | 'createdAt' | 'collaborationId'
                        >[],
                        { readField }
                      ) {
                        return alignments.filter((alignment) => {
                          const alignUser = readField(
                            'user',
                            alignment
                          ) as User;

                          return alignUser?.id === userAlignment.id;
                        });
                      },
                    },
                  });
                },
              });
            } else {
              await createAlignment({
                variables: {
                  data: { collaborationId: collaboration.id, strategyId },
                },
                update: (cache, { data }) => {
                  cache.modify({
                    id: cache.identify({
                      __ref: `Collaboration:${data?.alignmentCreate.collaborationId}`,
                    }),
                    fields: {
                      alignmentCount(alignment) {
                        return alignment + 1;
                      },
                      alignments(
                        alignments: Pick<
                          Alignment,
                          'id' | 'createdAt' | 'collaborationId'
                        >[]
                      ) {
                        return [...alignments, data?.alignmentCreate];
                      },
                    },
                  });
                },
              });
            }
          }}
          size={16}
          color={colors.black}
        />
      </IconHoverWrapper>
      <Amount>{collaboration?.alignmentCount || 0}</Amount>
      <Icon name="Comment" size={16} color={colors.black} />
      <Amount>{collaboration?.commentCount || 0}</Amount>
      <Icon name="Attachment" size={16} color={colors.black} />
      <Amount>{collaboration?.attachmentCount || 0}</Amount>
    </Wrapper>
  );
};
