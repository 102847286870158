import styled from 'styled-components/macro';
import { colors } from 'constants/colors';
import { device } from 'utils/breakpoints';
import { Icon } from 'components/shared/Icon';
import { TitleLabel, TitleText } from '../ui/PicoSuggestionRowTitle';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.desktopMin} {
    flex-direction: row;
  }
`;

const TitleAndIcon = styled.div`
  display: flex;
  gap: 5px;
`;

interface PicoSuggestionKeyInformationProps {
  pico: {
    name?: string;
    strategicPossibilityName?: string;
  };
  onEdit: () => void;
  displayIcon?: boolean;
  isDesktop?: boolean;
  isFocused?: boolean;
}
export const PicoSuggestionKeyInformation = ({
  pico: { name = '', strategicPossibilityName = '' },
  onEdit,
  displayIcon = false,
  isDesktop = false,
  isFocused = false,
}: PicoSuggestionKeyInformationProps) => {
  if (!isFocused) {
    return null;
  }

  return (
    <Wrapper>
      <TitleAndIcon>
        <TitleLabel title="Name" isDesktop={isDesktop} />
        <TitleText value={name} />
        <TitleLabel title="SI" isDesktop={isDesktop} />
        <TitleText value={strategicPossibilityName} />
        {displayIcon && (
          <Icon
            style={{ cursor: 'pointer' }}
            size={20}
            name="Pencil"
            color={colors.black}
            onClick={onEdit}
          />
        )}
      </TitleAndIcon>
    </Wrapper>
  );
};
