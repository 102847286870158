import { Subtitle1 } from 'components/shared';
import { Table, TableDivHeaders } from 'components/shared/Table';
import { colors } from 'constants/colors';
import { Maybe } from 'data/graphql/generated';
import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { dateStringToMonthYear } from 'utils/dateStringToMonthYear';
import { thousandSeparator } from 'utils/thousandSeparator';
import SummaryRow, { columnGridStyle } from './SummaryRow';

const StyledTable = styled(Table)`
  padding: 20px;

  .table__footer__wrapper {
    display: none;
  }
  ${TableDivHeaders} {
    ${columnGridStyle}
  }

  .mobile__table__top__divider {
    border-color: ${colors.purple};
  }
  .table__top__divider {
    background-color: ${colors.purple};
  }
` as typeof Table;

const TotalInvestment = styled.div`
  margin-bottom: 20px;

  @media (max-width: 375px) {
    margin-left: 15px;
  }
`;

type Props = {
  combinedData: {
    id: string;
    text: string;
    budget: Maybe<string> | undefined;
    due: number | Maybe<string> | undefined;
    responsibility: string;
    type: 'LongTermSolutionDetail' | 'Solution' | 'BigIdeaDetail' | undefined;
  }[];
  currency: string;
  isDesktop: boolean;
};

export const FinancialsSummary = ({
  combinedData,
  currency,
  isDesktop,
}: Props) => {
  const totalSums = useMemo(
    () =>
      combinedData.reduce((acc, curr) => {
        const budget = curr?.budget ? +curr?.budget : 0;
        acc = budget + acc;
        return acc;
      }, 0),
    [combinedData]
  );

  return (
    <>
      <TotalInvestment>
        <Subtitle1 color={colors.greyDark} style={{ display: 'inline' }}>
          Total investment required:{' '}
        </Subtitle1>
        <Subtitle1 style={{ display: 'inline' }}>
          {currency + (totalSums ? thousandSeparator(totalSums) : '-')}
        </Subtitle1>
      </TotalInvestment>

      <StyledTable
        showTopMobileDivider
        isDesktop={isDesktop}
        useDiv
        cols={['', 'Due', 'Responsibility', 'Budget']}
        rows={({ budget, due, responsibility, text, type, id }) => (
          <SummaryRow
            isDesktop={isDesktop}
            id={id + ''}
            text={text}
            budget={budget || ''}
            due={!!due ? dateStringToMonthYear(due) : undefined}
            responsibility={responsibility}
            type={type}
            currency={currency[0]}
          />
        )}
        data={combinedData}
        emptyStateText={{
          header: undefined,
          subHeading: undefined,
        }}
        isAddingRow={false}
      />
    </>
  );
};
