import { useEffect, useState } from 'react';

import {
  PatientJourneyColumn,
  PatientJourneyConnection,
  PatientJourneyBlockFragment,
} from 'data/graphql/generated';
import { PatientJourneyContainerType } from './src/lib/types';
import { PatientJourney } from './src';

export const PatientJourneyContainer = ({
  highLevelStageRef,
  isPreview = false,
  inRecapSidebar,

  dbShapes = [],
  createBlock,
  updateBlock,
  removeBlock,

  dbConnections = [],
  createConnection,
  updateConnection,
  removeConnection,

  updateMany,

  dbColumns = [],
  updateColumn,

  setDisableActions,
  actionType,

  columnTotalWidth,
  columnStartingPoints,

  handleBlockIconClick,
}: PatientJourneyContainerType) => {
  const [shapes, setShapes] = useState(dbShapes);
  const [connections, setConnections] = useState(dbConnections);
  const [columns, setColumns] = useState(dbColumns);

  useEffect(() => {
    if (!dbShapes) return;
    setShapes(dbShapes);
  }, [dbShapes]);

  useEffect(() => {
    if (!dbConnections) return;
    setConnections(dbConnections);
  }, [dbConnections]);

  useEffect(() => {
    if (!dbColumns) return;
    const colsToSort = [...dbColumns];
    setColumns(colsToSort.sort((a, b) => (a.idx > b.idx ? 1 : -1)));
  }, [dbColumns]);

  async function updateBlockLocal(value: Partial<PatientJourneyBlockFragment>) {
    setShapes((state) => {
      return state.map((s) => {
        if (s.id === value.id) {
          return {
            ...s,
            ...value,
          };
        }
        return s;
      });
    });
  }

  const updateConnectionLocal = (value: Partial<PatientJourneyConnection>) => {
    setConnections((state) => {
      return state.map((c) => {
        if (c?.id === value.id) {
          return {
            ...c,
            ...value,
          };
        }
        return c;
      });
    });
  };

  const updateColumnLocal = (value: Partial<PatientJourneyColumn>) => {
    setColumns((state) => {
      return state.map((c) => {
        if (c.id === value.id) {
          return {
            ...c,
            ...value,
          };
        }
        return c;
      });
    });
  };

  return (
    <PatientJourney
      highLevelStageRef={highLevelStageRef}
      isPreview={isPreview}
      inRecapSidebar={inRecapSidebar}
      //
      blocks={shapes}
      createBlock={createBlock}
      updateBlock={updateBlock}
      updateBlockLocal={updateBlockLocal}
      removeBlock={removeBlock}
      //
      connections={connections}
      createConnection={createConnection}
      updateConnection={updateConnection}
      updateConnectionLocal={updateConnectionLocal}
      removeConnection={removeConnection}
      //
      columns={columns}
      updateColumn={updateColumn}
      updateColumnLocal={updateColumnLocal}
      //
      updateMany={updateMany}
      //
      setDisableActions={setDisableActions}
      actionType={actionType}
      columnTotalWidth={columnTotalWidth}
      columnStartingPoints={columnStartingPoints}
      handleBlockIconClick={handleBlockIconClick}
    />
  );
};
