import { BodySmall, Caption, Icon } from 'components/shared';
import { colors, zIndex } from 'constants/index';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components/macro';
import { TypeNames } from './LongTermStrategySummaryTimeline';
import { getTitle } from './utils';

const ListWrapper = styled.div`
  width: 100%;
  max-width: 515px;
  height: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 5px;
`;

const NavWrapper = styled.div`
  max-height: calc(100vh - 95px);
  height: 100%;
  width: calc(100% - 30px);
  max-width: 515px;

  position: fixed;

  // As it's above the cards, it'll attempt to take all pointer events, which prevents scrolling
  pointer-events: none;
  * {
    pointer-events: all;
  }
`;

const BOTTOM_PADDING = 50;
const ARROW_HEIGHT = 30;

const Arrows = styled.div`
  display: flex;
  justify-content: space-between;

  position: absolute;
  bottom: ${BOTTOM_PADDING - ARROW_HEIGHT - 5}px;
  width: 100%;
`;

const CloseIconBG = styled.button`
  // css resets
  border: none;
  padding: 0;

  width: 30px;
  height: 30px;
  background: ${colors.white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${colors.black20};
`;

const CardsContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 515px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  padding-bottom: ${BOTTOM_PADDING}px;
  max-height: 100vh;
`;

const Wrapper = styled.div`
  position: fixed;
  inset: 0;

  width: 100vw;
  padding: 0 15px;

  display: flex;
  justify-content: center;

  overflow: auto;

  z-index: ${zIndex.timelineCalloutCard};
`;

const BG = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${colors.black40a};
  position: fixed;
  inset: 0;

  z-index: ${zIndex.timelineCalloutBG};
`;

const CardWrapper = styled.div`
  background: ${colors.white};

  border: 1px solid ${colors.greyLight};
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  width: 100%;
  max-width: 515px;
`;

type Props = {
  data: {
    type?: string | undefined;
    text: string;
  }[][];
  idx: number | null | undefined;
  handleClose(): void;
  setHoveredIdx(v: number): void;
};

export const CalloutMobile = ({
  data,
  idx,
  handleClose,
  setHoveredIdx,
}: Props) => {
  const cardContainerRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<HTMLDivElement | null>(null);
  const [justify, setJustify] = useState('center');
  const [listHeight, setListHeight] = useState(0);

  useEffect(() => {
    const cardContainer = cardContainerRef.current;

    if (!!cardContainer) {
      const scrollable =
        cardContainer.scrollHeight > cardContainer.clientHeight;

      setJustify(scrollable ? 'flex-start' : 'center');
    }

    const list = listRef.current;

    if (list) {
      setListHeight(list.offsetHeight);
    }
  }, [data, justify]);

  const open = typeof idx === 'number';

  useEffect(() => {
    const html = document.querySelector('html');
    if (html) {
      if (open) {
        html.style.overflow = 'hidden';
      } else {
        html.style.overflow = 'auto';
      }
    }
  }, [open]);

  if (typeof idx !== 'number' || !data) return null;

  const value = data[idx];

  return createPortal(
    <div>
      <BG />
      <Wrapper onClick={handleClose}>
        <CardsContainer
          ref={cardContainerRef}
          style={{ justifyContent: justify }}
        >
          <div style={{ alignSelf: 'start', minHeight: 100 }}></div>

          <ListWrapper
            ref={listRef}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <NavWrapper style={{ height: listHeight + 50 }}>
              <CloseIcon
                onClick={() => {
                  handleClose();
                }}
                style={{
                  position: 'absolute',
                  right: 0,
                  top: -35,
                }}
              />
              <Arrows>
                <Arrow
                  dir="l"
                  onClick={() => {
                    if (idx > 0) setHoveredIdx(idx - 1);
                  }}
                />
                <Arrow
                  dir="r"
                  onClick={() => {
                    if (idx < data.length - 1) setHoveredIdx(idx + 1);
                  }}
                />
              </Arrows>
            </NavWrapper>
            {value?.map((val, idx) => (
              <CardWrapper key={idx}>
                {!!val?.type && (
                  <Caption color={colors.greyDark}>
                    {getTitle(val?.type as TypeNames)}
                  </Caption>
                )}
                <BodySmall>{val.text || 'Not identified yet'}</BodySmall>
              </CardWrapper>
            ))}
            {justify === 'flex-start' && (
              <div style={{ alignSelf: 'end', width: 1, minHeight: 100 }}></div>
            )}
          </ListWrapper>
        </CardsContainer>
      </Wrapper>
    </div>,
    document.body
  );
};

function CloseIcon({
  style,
  onClick,
}: {
  style?: React.CSSProperties;
  onClick(): void;
}) {
  return (
    <CloseIconBG style={style} onClick={onClick}>
      <Icon name="Close" size={30} color={colors.black} />
    </CloseIconBG>
  );
}
function Arrow({
  dir,
  style,
  onClick,
}: {
  dir?: 'r' | 'l';
  style?: React.CSSProperties;
  onClick(): void;
}) {
  const direction = dir === 'l' ? { transform: 'scaleX(-1)' } : {};

  return (
    <CloseIconBG onClick={onClick} style={{ ...style, ...direction }}>
      <Icon name={'Arrow-right'} size={30} color="black" />
    </CloseIconBG>
  );
}
