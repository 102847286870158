import { useMemo } from 'react';
import { BodySmall, CountryFlag, Icon, Subtitle2 } from 'components/shared';
import { colors } from 'constants/colors';
import {
  CompetitiveAdvantageRowFragment,
  TacticFragment,
} from 'data/graphql/generated';
import { Budget, BudgetChart } from './BudgetChart';
import { PostItsEmpty } from 'components/PostItsEmpty';
import {
  getCategoryBudgets,
  groupTacticsByImperative,
  getYearBudgets,
  getImperativeBudgets,
  getReadableBudget,
  FinancialSummary,
  BudgetGroups,
  FinancialTables,
  TitleAndBudget,
  PurpleSeparator,
  ImperativeBudgets,
  SubTableHeaders,
  TacticAndBudget,
  StyledEmptyState,
  sortTacticsByBudget,
} from './MedicalSummary';
import { CountryGlobal, CountryGlobalRegional } from 'types';
import styled from 'styled-components';

const BudgetRegions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const FlagAndBudget = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

interface Props {
  currency: string;
  tactics: TacticFragment[];
  rows: CompetitiveAdvantageRowFragment[];
  totalInvestment: number;
  selectedRegions: CountryGlobal[];
  countryTacticsEnabled: boolean;
}

export function MedicalFinancialSummary({
  currency,
  tactics,
  rows,
  totalInvestment,
  selectedRegions,
  countryTacticsEnabled,
}: Props) {
  // Note: the tactics prop should be all tactics, unfiltered by region or 'focused'
  const selectedRegionTactics = useMemo(
    () =>
      tactics.filter(
        (t) => t.focused && selectedRegions.includes(t.region as CountryGlobal)
      ),
    [tactics, selectedRegions]
  );

  const imperativeBudgets: Budget[] = useMemo(
    () => getImperativeBudgets(selectedRegionTactics, rows),
    [rows, selectedRegionTactics]
  );
  const categoryBudgets: Budget[] = useMemo(
    () => getCategoryBudgets(selectedRegionTactics),
    [selectedRegionTactics]
  );
  const yearBudgets: Budget[] = useMemo(
    () => getYearBudgets(selectedRegionTactics),
    [selectedRegionTactics]
  );
  const imperativeIdGroups: Map<
    number,
    TacticFragment[]
  > = groupTacticsByImperative(rows, tactics);

  return !!selectedRegions.length ? (
    !!totalInvestment ? (
      <FinancialSummary>
        <BudgetGroups>
          <BudgetChart
            title="Strategic Imperative"
            budgets={imperativeBudgets.sort((a, b) => b.amount - a.amount)}
            currency={currency}
          />

          <BudgetChart
            title="Year"
            budgets={yearBudgets}
            currency={currency}
            emptyStateTitle="No budget timings yet"
          />

          <BudgetChart
            title="Category"
            budgets={categoryBudgets}
            currency={currency}
            emptyStateTitle="No budget categorised yet"
          />
        </BudgetGroups>

        <FinancialTables>
          {imperativeBudgets.map((imperativeBudget) => {
            const imperative = rows.find((r) => r.id === imperativeBudget.id);
            if (!imperative) return null;

            const readableBudget = getReadableBudget(
              currency,
              imperativeBudget.amount
            );

            const imperativeTactics = imperativeIdGroups.get(
              imperative.id as number
            );

            const separateRows = imperativeTactics?.filter(
              (t) => !t.parentTacticId
            );

            const sortedByBudget = sortTacticsByBudget(
              separateRows || [],
              'desc',
              true,
              selectedRegions
            );

            return (
              <ImperativeBudgets key={imperative.id}>
                <TitleAndBudget>
                  <Subtitle2>
                    {imperative?.strategicPossibility?.name}
                  </Subtitle2>
                  <Subtitle2>{readableBudget}</Subtitle2>
                </TitleAndBudget>

                <SubTableHeaders>
                  <BodySmall color={colors.greyDark}>Medical Tactics</BodySmall>
                  <BodySmall color={colors.greyDark}>Budget</BodySmall>
                </SubTableHeaders>

                <PurpleSeparator />
                {!!sortedByBudget?.length ? (
                  sortedByBudget?.map((t) => {
                    const tacticRowGroup = [
                      t,
                      ...(t?.childTactics || []),
                    ].filter(
                      (t) =>
                        t?.focused &&
                        selectedRegions.includes(t.region as CountryGlobal)
                    );

                    if (!tacticRowGroup.length) return null;

                    const tacticAndSpinOffsSorted = sortTacticsByBudget(
                      tacticRowGroup as TacticFragment[],
                      'desc'
                    );

                    return (
                      <TacticAndBudget key={t?.id}>
                        <BodySmall
                          color={
                            !!t?.tacticText ? colors.black : colors.greyDark
                          }
                        >
                          {t?.tacticText || 'Not identified yet'}
                        </BodySmall>

                        <BudgetRegions>
                          {tacticAndSpinOffsSorted.map((tac) => {
                            return (
                              <FlagAndBudget key={tac?.id}>
                                {countryTacticsEnabled &&
                                  tacticFlag(tac!.region)}

                                <BodySmall>
                                  {getReadableBudget(
                                    currency,
                                    tac?.budget ? +tac.budget : 0
                                  )}
                                </BodySmall>
                              </FlagAndBudget>
                            );
                          })}
                        </BudgetRegions>
                      </TacticAndBudget>
                    );
                  })
                ) : (
                  <StyledEmptyState includeIcon={false}>
                    <Subtitle2 color={colors.greyDark}>
                      No medical tactics yet
                    </Subtitle2>
                  </StyledEmptyState>
                )}
              </ImperativeBudgets>
            );
          })}
        </FinancialTables>
      </FinancialSummary>
    ) : (
      <PostItsEmpty
        title="No budget assigned yet"
        subtitle="Financials will be displayed here by strategic imperative, year and category"
      />
    )
  ) : (
    <PostItsEmpty
      title="No region selected"
      subtitle="Choose a region using the dropdown above"
    />
  );
}

const tacticFlag = (region: string) =>
  region === 'global' ? (
    <Icon name="LeadGlobe" size={20} color={colors.black} />
  ) : (
    <CountryFlag country={region as CountryGlobalRegional} size={20} />
  );
