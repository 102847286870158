import React, { useContext } from 'react';
import { createContext } from 'react';
import { useDropzone } from 'react-dropzone';

const DropZoneContext = createContext<{
  isDragActive: boolean;
  draggedFiles: File[];
}>({ isDragActive: false, draggedFiles: [] });

export const DropZoneProvider: React.FC = ({ children }) => {
  const { getRootProps, isDragActive, draggedFiles } = useDropzone();
  return (
    <DropZoneContext.Provider value={{ isDragActive, draggedFiles }}>
      <div {...getRootProps()}>{children}</div>
    </DropZoneContext.Provider>
  );
};

export function useDropZoneContext() {
  return useContext(DropZoneContext);
}
