import { BodySmall, Caption, CountryFlag, Icon } from 'components/shared';
import { colors } from 'constants/index';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { CountryGlobal } from 'types';
import { thousandSeparator } from 'utils/thousandSeparator';

const Star = styled(Icon)<{ marginRight?: string; marginLeft?: string }>`
  margin-right: ${({ marginRight }) => marginRight || 0};
  margin-left: ${({ marginLeft }) => marginLeft || 0};
`;

export const Bar = styled.div<{
  isGlobal: boolean;
  barWidth: number;
  $fill: boolean;
}>`
  width: ${({ barWidth, isGlobal }) =>
    isGlobal && barWidth ? '100' : barWidth}%;
  min-width: 5px;
  height: 30px;
  border: ${({ isGlobal, $fill }) =>
    isGlobal || $fill ? 'none' : ' 1px solid purple'};
  border-radius: 0 5px 5px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  background: ${({ isGlobal, $fill }) =>
    isGlobal || $fill
      ? 'linear-gradient(315deg, #FF00C8 -83%, #7800FF 93.75%)'
      : 'none'};
`;

const BarWrapper = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 5px;
  }

  > div:first-child {
    align-self: flex-start;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface Props {
  value?: number | null | string;
  starred: boolean;
  country?: CountryGlobal;
  maxValue?: number;
  showAsPercentage: boolean;
  isGlobal?: boolean;
  fill?: boolean;
  preferValuesInside?: boolean;
  className?: string;
  style?: React.CSSProperties;
  overrideValue?: () => (string | number) | string | number;
}

export const BarRow = ({
  value,
  overrideValue,
  starred,
  country,
  maxValue,
  showAsPercentage,
  isGlobal,
  fill = false,
  preferValuesInside = false,
  className,
  style,
}: Props) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const barRef = useRef<HTMLDivElement | null>(null);
  const valuesRef = useRef<HTMLParagraphElement | null>(null);
  const [valuesOutsideBar, setValuesOutsideBar] = useState(false);
  const valuesLeftMargin = 5;

  useEffect(() => {
    const wrapperWidth = wrapperRef?.current?.offsetWidth;
    const barWidth = barRef?.current?.offsetWidth;
    const valuesWidth = valuesRef?.current?.offsetWidth;
    if (wrapperWidth && barWidth && valuesWidth) {
      let spaceForValues = false;

      if (preferValuesInside) {
        spaceForValues =
          barWidth < valuesWidth + (starred ? 20 : 0) + valuesLeftMargin;
      } else {
        spaceForValues =
          wrapperWidth - barWidth >
          valuesWidth + (starred ? 20 : 0) + valuesLeftMargin;
      }
      setValuesOutsideBar(spaceForValues);
    }
  }, [value, starred, maxValue, showAsPercentage, preferValuesInside]);

  const barWidth = ((Number(value) || 0) / (maxValue || 0)) * 100 || 0;

  const valueUndefined = typeof value === 'undefined';

  return (
    <BarWrapper style={style} className={className}>
      {!country ? null : country === 'global' ? (
        <Icon name="LeadGlobe" size={30} color={colors.black} />
      ) : (
        <CountryFlag country={country} size={30} />
      )}
      <Wrapper ref={wrapperRef}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            marginBottom: 2,
          }}
        >
          {!valueUndefined ? (
            <Bar
              ref={barRef}
              isGlobal={!!isGlobal}
              $fill={!!fill}
              barWidth={barWidth}
            >
              {starred && barWidth > 20 ? (
                <Star
                  marginRight="auto"
                  marginLeft="5px"
                  name="Star"
                  size={20}
                  color={isGlobal ? colors.white : colors.black}
                />
              ) : null}
              {/* Values Inside */}
              <Values
                fill={fill}
                visible={(isGlobal && !!barWidth) || !valuesOutsideBar}
                valuesRef={valuesRef}
                isGlobal={isGlobal}
                showAsPercentage={showAsPercentage}
                value={value}
                maxValue={maxValue}
                overrideValue={overrideValue}
              />
            </Bar>
          ) : null}

          {starred && barWidth < 20 ? (
            <Star
              marginRight="2px"
              name="Star"
              size={20}
              color={isGlobal && barWidth ? colors.white : colors.black}
            />
          ) : null}

          {valueUndefined ||
          (isGlobal && !barWidth) ||
          (valuesOutsideBar && !isGlobal) ? (
            <div
              style={{
                marginLeft: valueUndefined ? 0 : valuesLeftMargin,
              }}
            >
              {/* Values Outside */}

              <Values
                isGlobal={isGlobal && !!barWidth}
                showAsPercentage={showAsPercentage}
                value={value}
                maxValue={maxValue}
                overrideValue={overrideValue}
              />
            </div>
          ) : null}
        </div>
        <Caption color={colors.greyDark}>{_.capitalize(country)}</Caption>
      </Wrapper>
    </BarWrapper>
  );
};

interface ValuesProps {
  valuesRef?: React.MutableRefObject<HTMLParagraphElement | null>;
  value?: Props['value'];
  maxValue?: number;
  showAsPercentage: boolean;
  isGlobal?: boolean;
  visible?: boolean;
  fill?: boolean;
  overrideValue?: Props['overrideValue'];
}

const Values = ({
  valuesRef,
  isGlobal,
  showAsPercentage,
  value,
  maxValue,
  visible = true,
  fill,
  overrideValue,
}: ValuesProps) => {
  const percentValue = (Number(value) / (maxValue || 0)) * 100;

  const showOverriddenValue = (overrideValue: ValuesProps['overrideValue']) => {
    if (typeof overrideValue === 'function') {
      return overrideValue();
    } else return overrideValue;
  };

  return (
    <BodySmall
      style={{
        paddingRight: 5,
        visibility: visible ? 'visible' : 'hidden',
      }}
      ref={valuesRef || null}
      color={isGlobal || fill ? colors.white : colors.black}
    >
      {!!overrideValue
        ? showOverriddenValue(overrideValue)
        : typeof value !== 'number'
        ? 'No data'
        : !showAsPercentage || isGlobal
        ? thousandSeparator(value)
        : (percentValue < 1 ? 'Less than 1' : percentValue.toFixed(1)) + '%'}
    </BodySmall>
  );
};
