import { colors } from 'constants/colors';
import { CheckCircle } from 'components/shared';
import Textarea from 'react-expanding-textarea';
import styled from 'styled-components/macro';

export const StyledCheckCircle = styled(CheckCircle)`
  margin-left: auto;
`;

export const TextareaWrapper = styled(Textarea)<{
  $text: boolean;
  $textValue: boolean;
}>`
  width: 100%;
  margin-top: 9px;
  margin-bottom: 5px;

  border: none;
  padding: 0;
  overflow-y: hidden;
  resize: none;

  font-family: ABCFavorit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.black};
  outline: 0.5px solid
    ${({ $textValue }) => ($textValue ? 'transparent' : colors.red)};
  &:focus {
    outline: 0.5px solid
      ${({ $text, $textValue }) =>
        $textValue ? colors.blue : $text ? colors.red : colors.blue};
  }
`;
export const Header = styled.div`
  display: flex;
`;

export const DateDisplayWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

export const DateDisplay = styled.div`
  background: ${colors.cream};
  border-radius: 5px;
  height: 30px;
  width: fit-content;
  padding-right: 15px;
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  min-height: 90px;
  background: ${colors.white};
  border-radius: 5px;
  padding: 15px;
  position: relative;
`;
