import Konva from 'konva';
import { Stage } from 'konva/types/Stage';
import { Node } from 'konva/types/Node';
import throttle from 'lodash/throttle';

import { trimSpaces } from 'utils/trimSpaces';
import { removeByClassName } from './removeByClassName';
import { RECT_WIDTH, RECT_OUTER_DIFFERENCE } from '../../../constants';
import { generatePathFromConnection } from './generatePath';
import {
  PatientJourneyBlock,
  PatientJourneyBlockFragment,
  PatientJourneyConnection,
} from 'data/graphql/generated';
import { ConnectionSide } from 'types';
import { zIndex } from 'constants/index';

const BLOCK_PADDING_TOP = 40;
const BLOCK_PADDING_BOTTOM = 5;
const TEXTAREA_MIN_HEIGHT = 38;
const TEXTAREA_LIMIT = 200;

function updateNodeHeight(
  stageRef: React.MutableRefObject<Stage | null>,
  id: string,
  height: number
) {
  const rectNode = stageRef?.current?.findOne(id);
  if (!rectNode) return;
  rectNode.height(height);
}

function updateNodePosition(
  stageRef: React.MutableRefObject<Stage | null>,
  id: string,
  { x, y }: { x: number; y: number }
) {
  const rectNode = stageRef?.current?.findOne(id);
  if (!rectNode) return;
  rectNode.position({ x, y });
}

function setRectHeight(
  stageRef: React.MutableRefObject<Stage | null>,
  textNode: Node,
  block: PatientJourneyBlockFragment,
  height: number,
  connections: PatientJourneyConnection[],
  adjustFromScreenX: (x: number) => number,
  adjustFromScreenY: (x: number) => number
) {
  if (!textNode || !height) return;
  updateNodeHeight(stageRef, `#block-${block.id}-shadow`, height);
  updateNodeHeight(stageRef, `#block-${block.id}-rect`, height);
  updateNodeHeight(
    stageRef,
    `#block-${block.id}-rect-outer`,
    height + RECT_OUTER_DIFFERENCE
  );
  updateNodeHeight(stageRef, `#block-${block.id}-text`, height);
  updateNodeHeight(stageRef, `#block-${block.id}-rect-old`, height);
  updateNodeHeight(
    stageRef,
    `#block-${block.id}-rect-old-outer`,
    height + RECT_OUTER_DIFFERENCE
  );
  updateNodeHeight(stageRef, `#block-${block.id}-text-old`, height);

  // update block dots x,y
  updateNodePosition(stageRef, `#block-${block.id}-dot-top-pos`, {
    x: RECT_WIDTH / 2,
    y: 0,
  });
  updateNodePosition(stageRef, `#block-${block.id}-dot-bottom-pos`, {
    x: RECT_WIDTH / 2,
    y: height,
  });
  updateNodePosition(stageRef, `#block-${block.id}-dot-left-pos`, {
    x: 0,
    y: height / 2,
  });
  updateNodePosition(stageRef, `#block-${block.id}-dot-right-pos`, {
    x: RECT_WIDTH,
    y: height / 2,
  });
  stageRef?.current?.batchDraw();

  connections
    .filter(
      (c) =>
        Number(c.toBlockId) === Number(block.id) ||
        Number(c.fromBlockId) === Number(block.id)
    )
    .forEach((c) => {
      const path = generatePathFromConnection(
        stageRef,
        adjustFromScreenX,
        adjustFromScreenY,
        {
          toBlockId: c.toBlockId,
          toSide: c.toSide as ConnectionSide,
          fromBlockId: c.fromBlockId,
          fromSide: c.fromSide as ConnectionSide,
        },
        false
      );

      const connectionNode = stageRef?.current?.findOne(`#connection-${c.id}`);
      if (connectionNode) {
        connectionNode.attrs.points = path;
      }
      // Update dot at the start of connection
      if (c.fromBlockId === block.id) {
        const connectionDotNode = stageRef?.current?.findOne(
          `.connection-dot-c-${c.id}`
        );
        if (connectionDotNode) {
          connectionDotNode.position({ x: path[0], y: path[1] });
        }
      }
    });

  stageRef?.current?.batchDraw();
}

export function showBlockEdit({
  block,
  stageRef,
  setDeleting,
  updateBlock,
  setForceEditing,
  connections,
  adjustFromScreenX,
  adjustFromScreenY,
}: {
  block: PatientJourneyBlockFragment;
  stageRef: React.MutableRefObject<Stage | null>;
  setDeleting(val: boolean): void;
  updateBlock(block: Partial<PatientJourneyBlock>): void;
  setForceEditing(val: number[]): void;
  connections: PatientJourneyConnection[];
  columnTotalWidth: number;
  adjustFromScreenX: (x: number) => number;
  adjustFromScreenY: (x: number) => number;
}): void {
  if (!stageRef.current) return;
  // Scroll screen to view block
  const blockNode = stageRef?.current?.findOne(`#block-${block.id}`);
  if (!blockNode) return;
  const blockPosition = blockNode.position();
  const stagePos = stageRef?.current?.position();
  if (!stagePos) return;

  let stagePosX = stagePos.x;

  const viewableStartX = -stagePos.x;
  const viewableEndX = -stagePos.x + stageRef.current.attrs.width;

  const isBlockViewableOnXLeft = blockPosition.x > viewableStartX;
  const isBlockViewableOnXRight = blockPosition.x + RECT_WIDTH < viewableEndX;

  if (!isBlockViewableOnXLeft) {
    stagePosX = -(blockPosition.x - 20);
  }
  if (!isBlockViewableOnXRight) {
    stagePosX =
      stageRef.current.attrs.width - (blockPosition.x + RECT_WIDTH + 20);
  }

  let stagePosY = stagePos.y;

  stageRef?.current?.position({
    x: stagePosX,
    y: stagePosY,
  });

  // check if there is already a textarea created for this block
  removeByClassName(`block-textarea`);
  removeByClassName(`block-background`);
  removeByClassName(`block-delete`);

  if (!stageRef) return;
  const stage = stageRef.current;
  if (!stage) return;
  const textNode: Konva.Text = stage?.findOne(`#block-${block.id}-text`);
  const oldTextNode: Konva.Text = stage?.findOne(`#block-${block.id}-text-old`);
  if (!textNode) return;
  // create textarea over canvas with absolute position
  // at first lets find position of text node relative to the stage:
  const textPosition = textNode.getAbsolutePosition();
  // then lets find position of stage container on the page:
  const stageBox = stage?.container().getBoundingClientRect();
  if (!stageBox) return;
  // so position of textarea will be the sum of positions above:
  const areaPosition = {
    x: stageBox.left + textPosition.x,
    // this is needed due to the nav height changing on breakpoint (NAV_HEIGHT)
    y:
      stage.attrs.container.getBoundingClientRect().top +
      textPosition.y +
      // address bar on mobile messes up the position, so this fixes it
      window.scrollY,
  };

  // hide text and old card nodes
  textNode.hide();
  const oldCardNode: Konva.Text = stage?.findOne(`#block-${block.id}-old`);
  oldCardNode.hide();
  const shadowNode: Konva.Text = stage?.findOne(`#block-${block.id}-shadow`);
  shadowNode.hide();

  stage.draw();
  // create textarea and style it
  const textarea = document.createElement('span');
  textarea.setAttribute('contenteditable', 'true');
  document.body.appendChild(textarea);
  textarea.innerHTML = textNode.text();
  textarea.className = `block-textarea block-textarea-${block.id}`;
  textarea.style.top = areaPosition.y + 'px';
  textarea.style.left = areaPosition.x + 2 + 'px';
  textarea.style.width = RECT_WIDTH - 10 + 'px';
  textarea.style.maxWidth = RECT_WIDTH - 10 + 'px';
  textarea.style.minWidth = RECT_WIDTH - 10 + 'px';
  textarea.style.zIndex = String(zIndex.patientJourneyTextArea);

  // @ts-ignore
  textarea.style['-webkit-nbsp-mode'] = 'normal';

  // This replace textarea.select() since it's not supported with contenteditable
  textarea.addEventListener('focus', () => {
    const range = document.createRange();
    range.selectNodeContents(textarea);
    const sel = window.getSelection();
    if (!sel) return;
    sel.removeAllRanges();
    sel.addRange(range);
  });

  textarea.focus();

  textarea.addEventListener('keydown', (e) => {
    const userPressedEnter =
      e.key === 'Enter' || e.code === 'Enter' || e.keyCode === 13;

    // stop enter key from breaking layout
    if (userPressedEnter) {
      e.preventDefault();
    }
  });

  function calcHeight(height: number) {
    const newHeight = BLOCK_PADDING_TOP + height + BLOCK_PADDING_BOTTOM;
    const roundedHeight = Math.ceil(newHeight / 20) * 20 - 20;
    return roundedHeight > 80 ? roundedHeight + 20 : 80;
  }

  textarea.addEventListener('paste', (event) => {
    const text = event?.clipboardData?.getData('text/plain');
    const selection = window.getSelection();
    if (!selection || !text || !selection.rangeCount) return;
    selection.deleteFromDocument();
    selection
      .getRangeAt(0)
      .insertNode(document.createTextNode(text.replace(/(\r\n|\n|\r)/gm, '')));
  });

  function updateHeightPreview() {
    textarea.style.height = 'auto';
    const value = trimSpaces(textarea.innerHTML.slice(0, TEXTAREA_LIMIT));
    const height =
      textarea.clientHeight > TEXTAREA_MIN_HEIGHT
        ? textarea.clientHeight
        : TEXTAREA_MIN_HEIGHT;

    textNode.height(height);
    textNode.text(value);

    // NOTE we only update block's height and dot position
    // since macbook pro can't handle running whole setRectHeight fn
    const newHeight = calcHeight(height);
    updateNodeHeight(stageRef, `#block-${block.id}-rect`, newHeight);
    updateNodeHeight(
      stageRef,
      `#block-${block.id}-rect-outer`,
      newHeight + RECT_OUTER_DIFFERENCE
    );
    updateNodePosition(stageRef, `#block-${block.id}-dot-bottom-pos`, {
      x: RECT_WIDTH / 2,
      y: newHeight,
    });

    // update only the connections that are linked to this card
    connections
      .filter(
        (c) =>
          Number(c.toBlockId) === Number(block.id) ||
          Number(c.fromBlockId) === Number(block.id)
      )
      .forEach((c) => {
        const path = generatePathFromConnection(
          stageRef,
          adjustFromScreenX,
          adjustFromScreenY,
          {
            toBlockId: c.toBlockId,
            toSide: c.toSide as ConnectionSide,
            fromBlockId: c.fromBlockId,
            fromSide: c.fromSide as ConnectionSide,
          },
          false
        );

        const connectionNode = stageRef?.current?.findOne(
          `#connection-${c.id}`
        );
        if (connectionNode) {
          connectionNode.attrs.points = path;
        }
        // Update dot at the start of connection
        if (c.fromBlockId === block.id) {
          const connectionDotNode = stageRef?.current?.findOne(
            `.connection-dot-c-${c.id}`
          );
          if (connectionDotNode) {
            connectionDotNode.position({ x: path[0], y: path[1] });
          }
        }
      });

    stageRef?.current?.draw();
  }

  const throttledUpdateHeightPreview = throttle(updateHeightPreview, 100);

  textarea.addEventListener('input', () => {
    throttledUpdateHeightPreview();
  });

  // create delete btn
  const deleteBtn = document.createElement('div');
  document.body.appendChild(deleteBtn);
  deleteBtn.className = `block-delete block-delete-${block.id}`;
  deleteBtn.style.top = areaPosition.y - 50 + 'px';
  deleteBtn.style.left = areaPosition.x - 20 + 'px';
  deleteBtn.style.zIndex = String(zIndex.patientJourneyTextArea);
  deleteBtn.addEventListener('click', () => {
    removeTextarea();
    setDeleting(true);
  });
  // background to capture outside click
  const textareaBG = document.createElement('div');
  document.body.appendChild(textareaBG);
  textareaBG.className = `block-background block-background-${block.id}`;
  textareaBG.style.zIndex = String(zIndex.patientJourneyTextArea - 1);

  function handleSave() {
    const value = trimSpaces(textarea.innerHTML.slice(0, TEXTAREA_LIMIT));
    const height =
      textarea.clientHeight > TEXTAREA_MIN_HEIGHT
        ? textarea.clientHeight
        : TEXTAREA_MIN_HEIGHT;

    if (!value) {
      textNode.height(80);
      textNode.text(block.type);
      oldTextNode.text(block.type);
      updateBlock({
        id: block.id,
        text: block.type,
        height: 80,
      });
    } else {
      if (value !== block.text) {
        textNode.height(height);
        textNode.text(value);
        oldTextNode.text(value);
        updateBlock({
          id: block.id,
          text: value,
          height: calcHeight(height),
        });
      }
    }
    removeTextarea(height);
  }

  textareaBG.addEventListener('click', () => {
    handleSave();
  });

  textarea.addEventListener('keydown', (e) => {
    const allowedKeys = [8, 37, 38, 39, 40, 46];

    if (
      !allowedKeys.includes(e.keyCode) &&
      textarea.innerHTML.length >= TEXTAREA_LIMIT
    ) {
      e.preventDefault();
    }
  });

  textarea.addEventListener('keyup', (e) => {
    // hide on enter
    if (e.keyCode === 13) {
      setTimeout(() => {
        handleSave();
      }, 200);
    }
  });

  function removeTextarea(height?: number) {
    if (height) {
      setRectHeight(
        stageRef,
        textNode,
        block,
        calcHeight(height),
        connections,
        adjustFromScreenX,
        adjustFromScreenY
      );
    }
    removeByClassName(`block-textarea`);
    removeByClassName(`block-background`);
    removeByClassName(`block-delete`);
    setForceEditing([]);
    textNode.show();
    oldCardNode.show();
    shadowNode.show();
    stage?.draw();
  }
}
