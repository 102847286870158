import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { colors } from 'constants/colors';
import { Icon } from './Icon';

const Dollar = styled.div`
  height: 20px;
  position: relative;

  .dollar__non-hover,
  .dollar__hover {
    position: absolute;
    top: 0px;
    display: flex;
    gap: 1px;
  }

  .dollar__hover {
    display: none;
  }

  &:hover .dollar__hover {
    display: block;
    position: absolute;
    top: 0px;
    display: flex;
    gap: 1px;
  }
  &:hover .dollar__non-hover {
    display: none;
  }
`;

interface Props {
  rating: number;
  onUpdate?(val: number): void;
  style?: React.CSSProperties;
  readOnly?: boolean;
}

export const RatingDollar: React.FC<Props> = ({
  rating,
  onUpdate,
  style,
  readOnly = false,
}) => {
  const [tempRating, setTempRating] = useState(rating);
  if (rating < 0 || rating > 3) console.error('rating must be between 0 and 3');

  useEffect(() => {
    if (rating !== tempRating) setTempRating(rating);
    // eslint-disable-next-line
  }, [rating]);

  return (
    <Dollar>
      <div className="dollar__non-hover">
        {[...Array(3)].map((_, idx) => {
          const unselected = idx >= rating;
          return (
            <div style={style} key={idx}>
              <Icon
                name={unselected ? 'DollarUnselected' : 'DollarSelected'}
                size={20}
                color={colors.purple}
                onClick={() => !readOnly && onUpdate && onUpdate(idx + 1)}
                style={{ cursor: readOnly ? 'auto' : 'pointer' }}
              />
            </div>
          );
        })}
      </div>
      <div className="dollar__hover">
        {[...Array(3)].map((_, idx) => {
          const unselected = idx >= tempRating;
          return (
            <div
              style={style}
              key={idx}
              onMouseEnter={() => !readOnly && setTempRating(idx + 1)}
              onMouseLeave={() => setTempRating(rating)}
            >
              <Icon
                name={unselected ? 'DollarUnselected' : 'DollarSelected'}
                size={20}
                color={colors.purple}
                onClick={() => !readOnly && onUpdate && onUpdate(idx + 1)}
                style={{ cursor: readOnly ? 'auto' : 'pointer' }}
              />
            </div>
          );
        })}
      </div>
    </Dollar>
  );
};
