import React from 'react';
import { uid } from 'uid';

import { Tooltip } from '../../Tooltip';
import { Size } from '../types/Size';
import { StyledDiv, StyledText } from './CountryFlag.styles';
import { FlagIcon } from './FlagIcon';

interface CountryFlagProps {
  isLead: boolean;
  size: Size;
  tooltipId?: string;
  tooltip: string;
  showTooltip: boolean;
  countryImage?: string;
  label?: string;
  showLabel?: boolean;
}

export const CountryFlag: React.FC<CountryFlagProps> = ({
  isLead,
  tooltipId = uid(),
  size,
  tooltip,
  showTooltip,
  label = '',
  showLabel = false,
  countryImage = 'afghanistan.png',
}) => {
  return (
    <StyledDiv className="cypress-country-flag">
      <FlagIcon
        data-offset="{ 'right': -14, 'top':18 }"
        data-place="right"
        data-for={tooltipId}
        data-tip={tooltip}
        brandlead={isLead}
        imageName={`/country-flags/${countryImage}`}
        size={size}
        className="cypress-country-flag-icon"
      />

      {showLabel && <StyledText>{label}</StyledText>}
      {showTooltip && <Tooltip effect="float" capitalize id={tooltipId} />}
    </StyledDiv>
  );
};
