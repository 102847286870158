import React, { ComponentProps } from 'react';
import styled, { css } from 'styled-components/macro';
import { Icon } from 'components/shared';
import { DropdownList } from 'components/shared/FormDropdown';
import { colors } from 'constants/colors';
import { IconContainer } from 'components/shared/Icon';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { Wrapper as ImageAndTitleWrapper } from 'components/shared/ImageAndTitle';
import { device } from 'utils/breakpoints';
import { DropdownEmptyState } from './UserDropdown';
import { useHandleDropdownBottomOverlap } from 'hooks/useHandleDropdownBottomOverlap';

const Wrapper = styled.div`
  position: relative;
`;

const IconDeselect = styled(Icon)`
  visibility: hidden;
  background: ${colors.greyDark};

  &:hover {
    background: ${colors.black};
  }
`;

export const SelectedOption = styled.div<{ dropdownActive: boolean }>`
  max-width: 250px;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 4px 5px;
  border-radius: 5px;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  border: 1px solid transparent;

  &:hover {
    background: ${({ dropdownActive }) => !dropdownActive && colors.greyLight};
    ${IconDeselect} {
      visibility: visible;
    }
  }

  ${({ dropdownActive }) =>
    dropdownActive &&
    css`
      background: none;
      border: 1px solid ${colors.black};
    `}
`;

const NonEditable = styled.div`
  max-width: 250px;
  padding: 5px;
`;

const StyledDropdownList = styled(DropdownList)<{ showAbove: null | number }>`
  border: 1px solid ${colors.black30};
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  // lobotomized owl - https://css-tricks.com/lobotomized-owls/
  > * + * {
    margin-top: 5px;
  }

  position: absolute;
  height: auto;
  width: 100%;

  @media ${device.desktopMin} {
    width: max-content;
  }

  ${ImageAndTitleWrapper} {
    max-width: none;
  }

  transform: ${({ showAbove }) =>
    showAbove ? `translateY(calc(-100% + ${showAbove}px))` : 'unset'};
`;

const DropdownOption = styled.li<{
  isSelected: boolean;
  disableHover?: boolean;
}>`
  width: 100%;
  list-style-type: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: ${({ isSelected }) => isSelected && `2px solid ${colors.purple}`};
  padding: 5px;
  min-height: 30px;

  ${({ disableHover }) => {
    if (!disableHover) {
      return css`
        :hover {
          background-color: ${colors.greyLight};
        }
      `;
    } else {
      return css`
        cursor: not-allowed;
        * {
          pointer-events: none;
        }
      `;
    }
  }}

  :last-child {
    margin-bottom: 0;
  }

  ${IconContainer} {
    align-self: flex-start;
  }
`;

interface Props<T> {
  options: T[];
  selectedOption: T | null;
  selectOption: (option: T) => void;
  clearSelection: () => void;
  editable?: boolean;
  emptyState?: ComponentProps<typeof DropdownEmptyState>;
  dropdownItem: (option: T) => React.ReactElement;
  selectedDropdownItem: (option: T) => React.ReactElement;
  noSelectionItem: () => React.ReactElement;
  className?: string;
  placeholder?: string;
}

export const ReusableDropdown = <DataType extends { id?: string | number }>({
  options,
  selectedOption,
  selectOption,
  clearSelection,
  editable = true,
  emptyState,
  dropdownItem,
  selectedDropdownItem,
  noSelectionItem,
  className,
  placeholder,
}: Props<DataType>) => {
  const [dropdownActive, setDropdownActive] = React.useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const dropdownRef = React.useRef<HTMLUListElement | null>(null);

  useClickOutsideComponent(containerRef, (event) => {
    setDropdownActive(false);
  });

  const translateYVal = useHandleDropdownBottomOverlap({
    containerRef,
    dropdownRef,
    isActive: dropdownActive,
  });

  if (!editable)
    return (
      <Wrapper ref={containerRef} className={className}>
        <NonEditable>
          {!!selectedOption
            ? selectedDropdownItem(selectedOption)
            : noSelectionItem()}
        </NonEditable>
      </Wrapper>
    );

  return (
    <Wrapper ref={containerRef} className={className}>
      <SelectedOption
        dropdownActive={dropdownActive}
        onClick={() => setDropdownActive(!dropdownActive)}
        onBlur={() => setDropdownActive(false)}
      >
        {selectedOption ? (
          <>
            {selectedDropdownItem(selectedOption)}
            <IconDeselect
              name="X-Cross"
              size={20}
              color={colors.white}
              style={{
                borderRadius: '50%',
                height: '20px',
                alignSelf: 'flex-start',
              }}
              onClick={(e) => {
                e.stopPropagation();
                clearSelection();
              }}
            />
          </>
        ) : (
          noSelectionItem()
        )}
      </SelectedOption>

      {dropdownActive &&
        (options?.length ? (
          <StyledDropdownList ref={dropdownRef} showAbove={translateYVal}>
            {options.map((option) => (
              <DropdownOption
                key={option.id}
                data-cy="dropdown-option"
                onClick={() => {
                  setDropdownActive(false);
                  selectOption(option);
                }}
                isSelected={option?.id === selectedOption?.id}
              >
                {dropdownItem(option)}
              </DropdownOption>
            ))}
          </StyledDropdownList>
        ) : (
          <DropdownEmptyState
            header={emptyState?.header}
            text={emptyState?.text}
          />
        ))}
    </Wrapper>
  );
};
