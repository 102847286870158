import { useCallback, useMemo, useState } from 'react';

import useManageFocusRationale from 'hooks/useManageFocusRationale';

export const useFocusGoalModal = ({
  isFocused: initial,
  onUpdate,
}: {
  isFocused: boolean;
  onUpdate: (data: any) => void;
}) => {
  const handleUpdate = useCallback((data: any) => onUpdate(data), [onUpdate]);
  const isFocusedMemo = useMemo(() => initial, [initial]);
  const [isFocused, setIsFocused] = useState(isFocusedMemo);

  const {
    modalSlideIdx,
    setModalSlideIdx,
    setShowSingleSlide,
  } = useManageFocusRationale();

  const closeModal = useCallback(() => {
    setModalSlideIdx(null);
  }, [setModalSlideIdx]);

  const openModal = useCallback(() => setModalSlideIdx(1), [setModalSlideIdx]);

  const toggleFocus = useCallback(async () => {
    if (!isFocused) {
      openModal();
      return;
    }

    try {
      setIsFocused(false);
      await handleUpdate({ isFocused: false });
    } catch (error) {
      setIsFocused(true);
      alert('Something went wrong');
    }
  }, [isFocused, handleUpdate, openModal]);

  return {
    isFocused,
    setIsFocused,
    closeModal,
    toggleFocus,
    modalSlideIdx,
    setModalSlideIdx,
    setShowSingleSlide,
  };
};
