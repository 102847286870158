import styled from 'styled-components/macro';
import { AreaToExploreButtons } from '../components/AreaToExploreButtons';
import { AreaToExploreViews } from '../components/AreaToExploreViews';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

interface HurdlesAndGapsSubstepViewProps {
  area: string;
  drugId: number;
  strategyId: number;
  competitiveAdvantageRowId: number;
  region: string;
}

export const HurdlesAndGapsSubstepView = ({
  area,
  drugId,
  strategyId,
  competitiveAdvantageRowId,
  region,
}: HurdlesAndGapsSubstepViewProps) => {
  return (
    <Container>
      <AreaToExploreButtons
        drugId={drugId}
        strategyId={strategyId}
        competitiveAdvantageRowId={competitiveAdvantageRowId}
        area={area}
        region={region}
      />
      <AreaToExploreViews
        drugId={drugId}
        strategyId={strategyId}
        competitiveAdvantageRowId={competitiveAdvantageRowId}
        area={area}
      />
    </Container>
  );
};
