import { ButtonPill, StepTab, TooltipWrapper } from 'components/shared';
import { PatientJourneyState } from 'data/graphql/generated';
import { Stage } from 'konva/types/Stage';
import React from 'react';
import { ActionTypeEnum } from '../types';
import { exportAsSvg } from '../utils';
import {
  ActionType,
  PatientJourneyNav,
  VerticalLine,
} from './PatientJourney.style';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';

const ExportContainer = styled.div`
  align-self: flex-end;

  @media ${device.mobile} {
    visibility: none;
  }
`;

const Steps = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const Cursors = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;

  @media ${device.tabletMin} {
    margin-right: auto;
  }
`;

type Props = {
  actionType: ActionTypeEnum;
  setActionType: React.Dispatch<React.SetStateAction<ActionTypeEnum>>;
  showModeInfoModal: () => void;
  stageRef: React.MutableRefObject<Stage | null>;
  columnTotalWidth: number;
  canvasWidth: number;
  canvasHeight: number;
  pageState: PatientJourneyState;
  setPageState: (val: PatientJourneyState) => void;
};

export const PatientJourneySecondNav = ({
  actionType,
  setActionType,
  showModeInfoModal,
  stageRef,
  columnTotalWidth,
  canvasWidth,
  canvasHeight,
  pageState,
  setPageState,
}: Props) => {
  return (
    <PatientJourneyNav>
      <Steps>
        <StepTab
          active={pageState === PatientJourneyState.Current}
          text="Current state"
          onClick={() => {
            setPageState(PatientJourneyState.Current);
          }}
        />
        <StepTab
          active={pageState === PatientJourneyState.Desired}
          text="Desired state"
          onClick={() => {
            setPageState(PatientJourneyState.Desired);
          }}
        />
        <VerticalLine />
      </Steps>
      <Cursors>
        <TooltipWrapper place="bottom" text="Select (V)">
          <ActionType
            type={ActionTypeEnum.INTERACTIVE}
            active={actionType === ActionTypeEnum.INTERACTIVE}
            onClick={() => {
              setActionType(ActionTypeEnum.INTERACTIVE);
              showModeInfoModal();
            }}
          />
        </TooltipWrapper>
        <TooltipWrapper place="bottom" text="Hand (H)">
          <ActionType
            type={ActionTypeEnum.DRAG}
            active={actionType === ActionTypeEnum.DRAG}
            onClick={() => {
              setActionType(ActionTypeEnum.DRAG);
              showModeInfoModal();
            }}
          />
        </TooltipWrapper>
        <VerticalLine />
      </Cursors>
      <ExportContainer>
        <ButtonPill
          iconName="Export"
          text={'Export'}
          level="export"
          onClick={exportAsSvg(
            stageRef,
            columnTotalWidth,
            canvasWidth,
            canvasHeight,
            'Patient Journey'
          )}
        />
      </ExportContainer>
    </PatientJourneyNav>
  );
};
