import { Card } from 'components/CompetitiveAdvantage/Summary/Card';
import { colors } from 'constants/index';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { monthNames } from 'utils/monthNames';
import { BodySmall, ButtonLabel, Icon } from '.';

const MonthsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 220px;
`;

const MonthItem = styled(Card)<{ selected: boolean; isDisabled?: boolean }>`
  width: 70px;
  height: 40px;
  display: grid;
  place-content: center;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  border-color: ${colors.purple};
  background: ${({ selected, isDisabled }) =>
    isDisabled ? 'transparent' : selected ? colors.purple : colors.white};

  ${ButtonLabel} {
    color: ${({ selected }) => (selected ? colors.white : colors.black)};
    cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  }
  &:hover {
    background: ${({ selected, isDisabled }) =>
      isDisabled
        ? 'transparent'
        : selected
        ? colors.purple70a
        : colors.purple10};
  }

  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`;

const StyledCard = styled(Card)`
  flex-direction: column;
  gap: 10px;
  width: 230px;
  flex: none;
  padding: 4px;
  user-select: none;
  background: ${colors.white};
`;

export type DateType = { month: number; year: number } | undefined;

interface Props {
  date: DateType;
  lowerLimit?: DateType;
  /**
   * The date you see when the calendar opens
   */
  startDate?: DateType;
  visible: boolean;
  onUpdate?: (date: DateType | null) => void;
  className?: string;
  innerRef?: React.MutableRefObject<HTMLDivElement | null>;
}

export const DatePicker = ({
  date,
  lowerLimit,
  visible,
  onUpdate,
  className,
  startDate,
  innerRef,
}: Props) => {
  const [localDate, setLocalDate] = useState(
    date || {
      year:
        // Add an extra year to the initially displayed date if there is no lower limit
        typeof lowerLimit?.year === 'number'
          ? lowerLimit.year
          : startDate?.year || new Date().getFullYear() + 1,
      month: startDate?.month || new Date().getMonth(),
    }
  );

  if (!visible) return null;

  return (
    <StyledCard ref={innerRef} className={className}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Icon
          name="Chevron-down"
          size={30}
          color={colors.black}
          style={{
            transform: 'rotate(90deg)',
            cursor: 'pointer',
            marginRight: 'auto',
          }}
          onClick={() => {
            setLocalDate({ ...localDate, year: localDate.year - 1 });
          }}
        />
        <BodySmall
          style={{
            flex: 1,
            textAlign: 'center',
          }}
        >
          {localDate.year}
        </BodySmall>
        <Icon
          name="Chevron-down"
          size={30}
          color={colors.black}
          style={{
            transform: 'rotate(-90deg)',
            cursor: 'pointer',
            marginLeft: 'auto',
          }}
          onClick={() => {
            setLocalDate({ ...localDate, year: localDate.year + 1 });
          }}
        />
      </div>
      <MonthsWrapper>
        {monthNames.map((month, idx) => {
          const isDisabled =
            !!lowerLimit &&
            (lowerLimit?.year > localDate.year ||
              (lowerLimit?.year === localDate.year && lowerLimit.month > idx));

          return (
            <MonthItem
              data-cy={`date-picker-month-item-${month}`}
              className="cypress-date-picker-month-item"
              key={idx}
              isDisabled={isDisabled}
              selected={date?.year === localDate.year && date.month === idx}
              onClick={() => {
                if (isDisabled) return;

                const toggleDate =
                  localDate.month === idx && localDate.year === date?.year;

                if (toggleDate) {
                  onUpdate?.(null);
                  return;
                }
                setLocalDate({ ...localDate, month: idx });
                onUpdate?.({ ...localDate, month: idx });
              }}
            >
              <ButtonLabel>{month}</ButtonLabel>
            </MonthItem>
          );
        })}
      </MonthsWrapper>
    </StyledCard>
  );
};
