import { BodySmall, Icon } from 'components/shared';
import { IconContainer } from 'components/shared/Icon';
import { colors } from 'constants/colors';
import { SuccessConditionType } from 'data/graphql/generated';
import React from 'react';
import styled from 'styled-components/macro';

const TagContainer = styled.div`
  padding-left: 10px;
  height: 30px;
  border-radius: 5px;
  width: 148px;
  display: flex;
  align-items: center;
  color: ${colors.white};

  ${IconContainer} {
    background: ${colors.white};
    border-radius: 50%;
    margin-right: 5px;
  }
`;

const Driver = styled(TagContainer)`
  background: ${colors.greenPurpleGradient};

  ${IconContainer} {
    color: #107d72;
  }
`;

const Barrier = styled(TagContainer)`
  background: ${colors.redPurpleGradient};

  ${IconContainer} {
    background: transparent;
  }
`;

const NoCategory = styled(TagContainer)`
  background: ${colors.greyDark};

  ${IconContainer} {
    background: transparent;
  }
`;

const NotCategorised = styled(TagContainer)`
  background: ${colors.black05};
`;

interface Props {
  successConditionType?: SuccessConditionType;
}

export const CategoryTag: React.FC<Props> = ({ successConditionType }) => {
  switch (successConditionType) {
    case SuccessConditionType.Driver:
      return (
        <Driver>
          <Icon name="Tick Small" size={20} />
          Driver
        </Driver>
      );
    case SuccessConditionType.Barrier:
      return (
        <Barrier>
          <Icon name="Minus" size={20} />
          Barrier
        </Barrier>
      );
    case SuccessConditionType.None:
      return (
        <NoCategory>
          <Icon name="Ellipse" size={20} />
          No category
        </NoCategory>
      );
    default:
      return (
        <NotCategorised>
          <BodySmall color={colors.greyDark}>Not categorised yet</BodySmall>
        </NotCategorised>
      );
  }
};
