import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { ArchetypeCountryFragment } from 'data/graphql/generated';
import { CountryFlag } from 'components/shared';
import { Country } from 'types';
import { useWidthHeight } from 'hooks/useWidthHeight';
import { colors } from 'constants/colors';

const FLAG_WIDTH = 15;

const Wrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`;

const OverlapContainer = styled.div<{ overlapSpacing: number }>`
  margin-right: ${({ overlapSpacing }) =>
    !!overlapSpacing && `${overlapSpacing}px`};
`;

const MoreCountries = styled.div`
  background: ${colors.greyLight};
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 9px;
  line-height: 15px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
`;

interface Props {
  archetypeCountries: ArchetypeCountryFragment[];
  flagLimit?: number;
  className?: string;
}

export function calculateFlagSpacing(
  numCountries: number,
  containerWidth: number,
  flagWidth: number
) {
  const totalFlagWidth = numCountries * flagWidth;
  const diff = containerWidth - totalFlagWidth;
  const spacing = diff / (numCountries - 1); // -1 because we don't reposition the first flag

  return spacing > 5 ? 5 : spacing;
}

export default function OverlappingFlags({
  archetypeCountries,
  flagLimit,
  className,
}: Props) {
  const flagsContainerRef = useRef<HTMLUListElement | null>(null);
  const [overlapSpacing, setOverlapSpacing] = useState(0);
  const { width } = useWidthHeight();

  useEffect(() => {
    const flagsContainer = flagsContainerRef.current;
    if (!flagsContainer) return;

    const containerWidth = flagsContainer.offsetWidth;

    setOverlapSpacing(
      calculateFlagSpacing(
        flagLimit ? flagLimit + 1 : archetypeCountries?.length || 0,
        containerWidth,
        FLAG_WIDTH
      )
    );
  }, [archetypeCountries, width, flagLimit]);

  const numHiddenFlags = flagLimit ? archetypeCountries.length - flagLimit : 0;

  return (
    <Wrapper ref={flagsContainerRef} className={className}>
      {archetypeCountries
        .slice(0, flagLimit || archetypeCountries.length)
        .map((ac) => (
          <OverlapContainer key={ac.id} overlapSpacing={overlapSpacing}>
            <CountryFlag
              size={FLAG_WIDTH}
              country={ac.country as Country}
              key={ac.id}
            />
          </OverlapContainer>
        ))}
      {numHiddenFlags > 0 && (
        <OverlapContainer overlapSpacing={overlapSpacing}>
          <MoreCountries>+{numHiddenFlags}</MoreCountries>
        </OverlapContainer>
      )}
    </Wrapper>
  );
}
