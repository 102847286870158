import React from 'react';
import styled, { css } from 'styled-components/macro';

const BG = styled.div<{ paddingTop: boolean; height?: string | number }>`
  padding-top: ${({ paddingTop }) => (paddingTop ? 120 : 30)}px;
  display: block;
  overflow-x: hidden;
  padding-bottom: 50px;
  height: ${({ height }) => (height ? String(height) : 'auto')};
`;

export const Wrapper = styled.div<{ fullWidthMobile: boolean }>`
  max-width: 1400px;
  width: 90%;
  display: block;
  margin: 0px auto;

  ${({ fullWidthMobile }) =>
    fullWidthMobile
      ? css`
          @media (max-width: 480px) {
            width: 100%;
          }
        `
      : ``}
`;

interface Props {
  paddingTop?: boolean;
  className?: string;
  height?: string | number;
  fullWidthMobile?: boolean;
}

export const Page: React.FC<Props> = ({
  className,
  children,
  paddingTop = true,
  height,
  fullWidthMobile = false,
}) => {
  return (
    <BG className={className} paddingTop={paddingTop} height={height}>
      <Wrapper fullWidthMobile={fullWidthMobile}>{children}</Wrapper>
    </BG>
  );
};
