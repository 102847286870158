import { colors } from 'constants/colors';
import React, { CSSProperties, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { BodySmall, ButtonPill, EmptyState, Subtitle2 } from '../shared';

export const Divider = styled.span`
  width: 100%;
  height: 1px;
  margin-bottom: 5px;
  margin-top: 10px;
  background: ${colors.greyLight};
`;

export const TableBodyDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const TableDivHeaders = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(119px, 285px)) minmax(443px, auto);
  column-gap: 15px;
  padding: 0 15px;
`;

const StyledTableDiv = styled.div`
  display: grid;
`;

const TableWrapperDiv = styled.div`
  background: ${colors.white94};
  border: 1px solid ${colors.black10a};
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 15px;
  padding-top: 30px;
`;

export const MobileContainer = styled.div``;

export const MobileContentWrapper = styled.div`
  padding: 10px 0;
`;

export const MobileContentDivider = styled.div`
  border-bottom: 1px solid ${colors.greyLight};
  padding: 0 10px;
  margin-top: 15px;
`;

export const MobileWrapper = styled.div`
  padding: 15px 5px;
  background: ${colors.white};
  margin: auto;
  width: 100%;

  border: none;
  border-radius: 0px;
  max-width: 100%;

  @media ${device.tabletMin} {
    border-radius: 5px;
    max-width: 451px;
    border-radius: 5px;
    border: 1px solid ${colors.black10a};
  }
`;

export const StyledTbody = styled.tbody`
  > tr.StyledTbody__row {
    //divider
    border-top: 1px solid ${colors.greyLight};

    > td.StyledTbody__td:first-of-type {
      padding: 15px;
    }

    > td.StyledTbody__td:last-of-type {
      padding: 15px;
      padding-left: 0;
    }
  }
`;

export const StyledTfoot = styled.tfoot<{ hideBorder: boolean }>`
  border-top: ${({ hideBorder }) =>
    hideBorder ? 'none' : `1px solid ${colors.greyLight}`};
  > tr td {
    padding-top: 15px;
  }
`;

export const StyledThead = styled.thead`
  text-align: left;
  > tr th:first-of-type {
    padding: 15px;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableWrapper = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.black10a};
  border-radius: 5px;
  width: 100%;
  padding: 15px;
`;

interface Props<T> {
  cols: string[];
  rows: (data: T, idx: number) => ReactElement | null;
  data: T[];
  addRow?(): Promise<void>;
  isDesktop?: boolean;
  addButtonText?: string;
  emptyStateText: {
    header?: string | ReactElement;
    subHeading?: string | ReactElement;
  };
  isAddingRow?: boolean;
  className?: string;
  style?: CSSProperties;
  header?: ReactElement;
  useDiv?: boolean;
  keyField?: keyof T;
  showTopDesktopDivider?: boolean;
  showTopMobileDivider?: boolean;
  hideAddButton?: boolean;
  tableRef?: React.RefObject<HTMLDivElement>;
}

export type TableProps<T> = Props<T>;

/**
 * Reusable Table component
 *
 * If useDiv is false - it defaults to using Table elements rather than divs
 * To add dividers on desktop, you can either manually add your own or add StyledTbody__row as a className to the tr you use to house your row
 *
 */

export const Table = <DataType extends { id?: string | number }>({
  cols,
  rows,
  data,
  addRow,
  isDesktop,
  addButtonText,
  emptyStateText,
  isAddingRow = false,
  className,
  style,
  header,
  useDiv,
  keyField = 'id',
  showTopDesktopDivider,
  showTopMobileDivider,
  tableRef,
  hideAddButton,
}: Props<DataType>) => {
  const noData = !data || !data.length;

  if (!isDesktop) {
    return (
      <MobileWrapper ref={tableRef} style={style} className={className}>
        {header}
        <MobileContainer>
          {noData ? (
            <div>
              <EmptyState
                includeIcon={false}
                className={'Table__Mobile__EmptyState'}
              >
                {typeof emptyStateText.header === 'string' ? (
                  <Subtitle2 color={colors.greyDark}>
                    {emptyStateText.header}
                  </Subtitle2>
                ) : (
                  emptyStateText.header
                )}

                {typeof emptyStateText.subHeading === 'string' ? (
                  <BodySmall
                    color={colors.greyDark}
                    style={{ textAlign: 'center' }}
                  >
                    {emptyStateText.subHeading}
                  </BodySmall>
                ) : (
                  emptyStateText.subHeading
                )}
              </EmptyState>
            </div>
          ) : (
            <>
              {!!data && showTopMobileDivider && (
                <MobileContentDivider className="mobile__table__top__divider" />
              )}
              {data.map((item, idx) => {
                const Rows = rows(item, idx);
                const key = (typeof item[keyField] === 'string' ||
                typeof item[keyField] === 'number'
                  ? item[keyField]
                  : item.id || idx) as string | number;
                return (
                  <MobileContentWrapper key={key}>
                    {Rows}
                    <MobileContentDivider />
                  </MobileContentWrapper>
                );
              })}
            </>
          )}
        </MobileContainer>
        {!hideAddButton && !!addButtonText && (
          <div style={{ marginTop: 15 }}>
            <ButtonPill
              loading={false}
              iconName="Plus"
              text={addButtonText}
              level="secondary"
              onClick={() => {
                addRow?.();
              }}
            />
          </div>
        )}
      </MobileWrapper>
    );
  }

  if (!!useDiv) {
    return (
      <TableWrapperDiv ref={tableRef} className={className} style={style}>
        {header}

        <StyledTableDiv>
          <TableDivHeaders>
            {cols.map((col) => (
              <BodySmall key={col} color={colors.greyDark}>
                {col}
              </BodySmall>
            ))}
          </TableDivHeaders>
          {!!data && (showTopDesktopDivider || data.length) ? (
            <Divider className="table__top__divider" />
          ) : null}
          <TableBodyDiv>
            {!!data.length ? (
              data.map((item, idx) => {
                const Rows = rows(item, idx);
                const key = (typeof item[keyField] === 'string' ||
                typeof item[keyField] === 'number'
                  ? item[keyField]
                  : item.id || idx) as string | number;

                return (
                  <React.Fragment key={key}>
                    {Rows}
                    <Divider
                      className="table__row__divider"
                      style={{ margin: 0 }}
                    />
                  </React.Fragment>
                );
              })
            ) : (
              <EmptyState
                style={{ marginTop: 10 }}
                className={'Table__EmptyState'}
                includeIcon={false}
              >
                {typeof emptyStateText.header === 'string' ? (
                  <Subtitle2 color={colors.greyDark}>
                    {emptyStateText.header}
                  </Subtitle2>
                ) : (
                  emptyStateText.header
                )}

                {typeof emptyStateText.subHeading === 'string' ? (
                  <BodySmall color={colors.greyDark}>
                    {emptyStateText.subHeading}
                  </BodySmall>
                ) : (
                  emptyStateText.subHeading
                )}
              </EmptyState>
            )}
          </TableBodyDiv>
          {!hideAddButton && (
            <div className="table__footer__wrapper" style={{ marginTop: 15 }}>
              {!!addButtonText && (
                <ButtonPill
                  className={'table__tfoot__button'}
                  clickClassName="cypress-table-add"
                  loading={isAddingRow}
                  iconName="Plus"
                  text={addButtonText}
                  level="secondary"
                  onClick={() => {
                    addRow?.();
                  }}
                />
              )}
            </div>
          )}
        </StyledTableDiv>
      </TableWrapperDiv>
    );
  } else
    return (
      <TableWrapper ref={tableRef} style={style} className={className}>
        {header}
        <StyledTable>
          <StyledThead>
            <tr>
              {cols.map((col) => (
                <th key={col}>
                  <BodySmall color={colors.greyDark}>{col}</BodySmall>
                </th>
              ))}
            </tr>
          </StyledThead>
          {noData ? (
            <StyledTbody>
              <tr>
                <td colSpan={100}>
                  <EmptyState
                    className={'Table__EmptyState'}
                    includeIcon={false}
                  >
                    {typeof emptyStateText.header === 'string' ? (
                      <Subtitle2 color={colors.greyDark}>
                        {emptyStateText.header}
                      </Subtitle2>
                    ) : (
                      emptyStateText.header
                    )}

                    {typeof emptyStateText.subHeading === 'string' ? (
                      <BodySmall color={colors.greyDark}>
                        {emptyStateText.subHeading}
                      </BodySmall>
                    ) : (
                      emptyStateText.subHeading
                    )}
                  </EmptyState>
                </td>
              </tr>
            </StyledTbody>
          ) : (
            <StyledTbody>
              {data.map((item, idx) => {
                const Rows = rows(item, idx);
                return (
                  <React.Fragment key={item.id || idx}>{Rows}</React.Fragment>
                );
              })}
            </StyledTbody>
          )}
          <StyledTfoot hideBorder={noData}>
            {!hideAddButton && (
              <tr className="table__footer__wrapper">
                {!!addButtonText && (
                  <td rowSpan={cols.length || 1}>
                    <ButtonPill
                      className={'table__tfoot__button'}
                      clickClassName="cypress-table-add"
                      loading={isAddingRow}
                      iconName="Plus"
                      text={addButtonText}
                      level="secondary"
                      onClick={() => {
                        addRow?.();
                      }}
                    />
                  </td>
                )}
              </tr>
            )}
          </StyledTfoot>
        </StyledTable>
      </TableWrapper>
    );
};
