import { removeByClassName } from 'components/PatientJourney/src/lib/utils/removeByClassName';
import { BlockTree } from 'types';
import { thousandSeparator } from 'utils/thousandSeparator';
import { NAV_HEIGHT, RECT_WIDTH } from './constants';

export function renderBlockValueTextInput(
  initialValue: string,
  block: BlockTree,
  stageRef: any,
  type: string,
  update: (text: string | null) => void,
  showPercentage: boolean,
  spanValue?: string
) {
  removeByClassName(`block-textarea flow-block-${type}-textarea`);
  removeByClassName(`block-background`);
  removeByClassName(`block-delete`);
  removeByClassName(`block-textarea-span`);
  const textNode = stageRef.current.findOne(`#block-${block.id}-${type}`);

  const textPosition = textNode.getAbsolutePosition();
  const stageBox = stageRef.current.container().getBoundingClientRect();

  const areaPosition = {
    x: stageBox.left + textPosition.x,
    y: NAV_HEIGHT() + textPosition.y + 3,
  };

  textNode.hide();
  stageRef.current.draw();

  //create span

  const span = document.createElement('span');
  document.body.appendChild(span);

  span.className = `block-textarea-span`;
  span.style.position = 'absolute';

  span.style.top = areaPosition.y - 1 + 'px';
  span.style.left = areaPosition.x + RECT_WIDTH - 25 + 'px';
  span.style.pointerEvents = 'none';
  span.textContent = spanValue || '';

  // create textarea and style it
  const textInput = document.createElement('input');
  document.body.appendChild(textInput);
  const valueWithRemovedCommas = initialValue.replace(/,/g, '');

  textInput.value = initialValue.includes('Enter')
    ? ''
    : typeof Number(valueWithRemovedCommas) === 'number'
    ? +Number(valueWithRemovedCommas).toFixed(2) + ''
    : valueWithRemovedCommas;

  textInput.className = `block-textarea block-${type}-textarea-${block.id}`;
  textInput.style.top = areaPosition.y - 2 + 'px';
  textInput.style.left = areaPosition.x + 'px';
  textInput.style.padding = '0';

  textInput.style.height = `${textNode.height()}px`;
  textInput.style.width = RECT_WIDTH - 10 + 'px';
  textInput.style.maxWidth = RECT_WIDTH - 10 + 'px';
  textInput.style.minWidth = RECT_WIDTH - 10 + 'px';

  textInput.style.lineHeight = '1.25';
  textInput.style.fontWeight = '500';
  textInput.style.fontSize = '12px';

  textInput.focus();
  textInput.select();

  textInput.addEventListener('keydown', (e) => {
    // hide on enter
    if (e.keyCode === 13) {
      if (!!textInput.value.length) {
        saveText();
      }
    }
  });

  textInput.oninput = () => {
    if (!textInput.value.length) {
      return;
    }
    //Only numbers allowed
    let filtredValue = textInput.value
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1');

    textInput.value = showPercentage
      ? filtredValue
      : thousandSeparator(Number(filtredValue));
  };

  // background to capture outside click
  const textareaBG = document.createElement('div');
  document.body.appendChild(textareaBG);
  textareaBG.className = `block-background block-${type}-background-${block.id}`;
  textareaBG.addEventListener('click', saveText);

  function saveText() {
    if (!textInput.value.length) {
      // reset to default

      update(null);

      removeTextarea();
      return;
    } else {
      // textNode.text(Number(textInput.value));

      removeTextarea();

      if (typeof Number(textInput.value) !== 'number') {
        return;
      }
      //remove commas
      update(textInput.value.replace(/,/g, ''));
    }
  }

  function removeTextarea() {
    removeByClassName(`block-textarea`);
    removeByClassName(`block-background`);
    removeByClassName(`block-delete`);
    removeByClassName(`block-textarea-span`);

    textNode.show();
    stageRef.current.draw();
  }
}
