import React from 'react';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';

interface Props {
  active: number;
  mobileView?: boolean;
}

const ProgressDesktop = styled.div`
  position: absolute;
  display: block;
  background-size: contain;
  z-index: 4;
  top: 400px;
  left: 50%;
  transform: translate(-380px, 20px);
  width: 778px;

  svg {
    max-width: 100%;
  }

  @media ${device.tabletMax} {
    display: none;
  }
`;

const ProgressMobile = styled.div<{ mobileView?: boolean }>`
  display: none;
  position: absolute;
  background-size: contain;
  z-index: 4;
  top: 225px;
  width: 213px;
  left: 50%;
  transform: translate(-50%, 0px);

  svg {
    max-width: 100%;
  }

  @media ${device.tabletMax} {
    display: ${({ mobileView }) => (mobileView ? 'none' : 'block')};
  }
`;

export const StrategyProgress: React.FC<Props> = ({ mobileView }) => {
  return (
    <>
      <ProgressMobile mobileView={mobileView}>
        <svg
          width="227"
          height="963"
          viewBox="0 0 227 963"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M174.077 0C174.077 195.391 1 105.369 1 321C1 557.566 226 404.739 226 642C226 879.261 1 725.739 1 963"
            stroke="white"
            strokeOpacity="0.8"
            strokeWidth="2"
          />
        </svg>
      </ProgressMobile>

      <ProgressDesktop>
        <svg
          width="782"
          height="1380"
          viewBox="0 0 782 1380"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M601 0C601 280 1 150.996 1 460C1 799.004 781 580 781 920C781 1260 1 1040 1 1380"
            stroke="white"
            strokeOpacity="0.8"
            strokeWidth="2"
          />
        </svg>
      </ProgressDesktop>
    </>
  );
};
