import { RefObject, useEffect, useState } from 'react';

export default function useSidePaneTopOffset(
  ref: RefObject<any>,
  totalNavHeight: number
) {
  const [value, setValue] = useState(totalNavHeight);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setValue((ref.current?.offsetTop || 0) + totalNavHeight);
  });

  return value;
}
