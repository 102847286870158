import React, { useState } from 'react';
import { Rect, Circle, Group } from 'react-konva';
import { get } from 'lodash';

import { BlockIcon } from '../ui/BlockIcon';
import { BlockType } from 'data/graphql/generated';
import { colors } from 'constants/index';
import { Cursor, Block } from 'types';
import { Tooltip } from '../ui/Tooltip';
import { SharedPatientJourneyTypes } from '../types';

interface Props {
  block: { x: number; y: number; column: number };
  createBlock: SharedPatientJourneyTypes['createBlock'];
  changeCursor(type: Cursor): void;
  onSuccess(block: Block): void;
}

export const BlockCreate: React.FC<Props> = ({
  block,
  createBlock,
  onSuccess,
  changeCursor,
}) => {
  const [active, setActive] = useState<BlockType | null>(null);

  async function handleCreateBlock(type: BlockType) {
    const res = await createBlock({
      ...block,
      type,
      text: type,
      keyStakeHolderPatient: false,
      keyStakeHolderHealthcare: false,
      keyStakeHolderPayor: false,
      keyStakeHolderProvider: false,
    });
    onSuccess(get(res, 'data.patientJourneyBlockCreate'));
  }
  return (
    <Group x={block.x} y={block.y}>
      <Rect
        width={160}
        height={80}
        cornerRadius={5}
        sides={4}
        fill="rgba(255, 255, 255, 0.7)"
      />
      <Rect
        x={5}
        y={5}
        width={145}
        height={70}
        cornerRadius={5}
        sides={4}
        fill="rgba(255, 255, 255, 1)"
        shadowColor="#000000"
        shadowBlur={20}
        shadowOpacity={0.1}
      />

      <Group x={12} y={10}>
        {/* Patient */}
        <Group
          x={0}
          onClick={() => handleCreateBlock(BlockType.Patient)}
          onMouseEnter={() => {
            changeCursor('pointer');
            setActive(BlockType.Patient);
          }}
          onMouseLeave={() => {
            changeCursor('default');
            setActive(null);
          }}
        >
          <Circle
            x={15}
            y={15}
            fill={colors.purple05}
            radius={30}
            width={30}
            height={30}
            visible={active === BlockType.Patient}
          />
          <Group>
            <BlockIcon type={BlockType.Patient} />
          </Group>
          <Tooltip
            text="Patient"
            width={55}
            visible={active === BlockType.Patient}
          />
        </Group>

        {/* HCP */}
        <Group
          x={35}
          onClick={() => handleCreateBlock(BlockType.Healthcareprofessional)}
          onMouseEnter={() => {
            changeCursor('pointer');
            setActive(BlockType.Healthcareprofessional);
          }}
          onMouseLeave={() => {
            changeCursor('default');
            setActive(null);
          }}
        >
          <Circle
            x={14}
            y={14}
            fill={colors.green05}
            radius={30}
            width={30}
            height={30}
            visible={active === BlockType.Healthcareprofessional}
          />
          <Group>
            <BlockIcon type={BlockType.Healthcareprofessional} />
          </Group>
          <Tooltip
            text="Healthcare Professional"
            width={145}
            visible={active === BlockType.Healthcareprofessional}
          />
        </Group>

        {/* Payor */}
        <Group
          x={70}
          onClick={() => handleCreateBlock(BlockType.Payor)}
          onMouseEnter={() => {
            changeCursor('pointer');
            setActive(BlockType.Payor);
          }}
          onMouseLeave={() => {
            changeCursor('default');
            setActive(null);
          }}
        >
          <Circle
            x={14}
            y={14}
            fill={colors.blue05}
            radius={30}
            width={30}
            height={30}
            visible={active === BlockType.Payor}
          />
          <Group>
            <BlockIcon type={BlockType.Payor} />
          </Group>
          <Tooltip
            text="Payor"
            width={45}
            visible={active === BlockType.Payor}
          />
        </Group>

        {/* Provider */}
        <Group
          x={105}
          onClick={() => handleCreateBlock(BlockType.Provider)}
          onMouseEnter={() => {
            changeCursor('pointer');
            setActive(BlockType.Provider);
          }}
          onMouseLeave={() => {
            changeCursor('default');
            setActive(null);
          }}
        >
          <Circle
            x={14}
            y={14}
            fill={colors.orange05}
            radius={30}
            width={30}
            height={30}
            visible={active === BlockType.Provider}
          />
          <Group>
            <BlockIcon type={BlockType.Provider} />
          </Group>
          <Tooltip
            text="Provider"
            width={60}
            visible={active === BlockType.Provider}
          />
        </Group>
      </Group>

      <Group x={30} y={40}>
        {/* Policymaker */}
        <Group
          x={0}
          onClick={() => handleCreateBlock(BlockType.Policymaker)}
          onMouseEnter={() => {
            changeCursor('pointer');
            setActive(BlockType.Policymaker);
          }}
          onMouseLeave={() => {
            changeCursor('default');
            setActive(null);
          }}
        >
          <Circle
            x={14}
            y={14}
            fill={colors.orange05}
            radius={30}
            width={30}
            height={30}
            visible={active === BlockType.Policymaker}
          />
          <Group>
            <BlockIcon type={BlockType.Policymaker} />
          </Group>
          <Tooltip
            text="Policymaker"
            width={80}
            visible={active === BlockType.Policymaker}
          />
        </Group>

        {/* Treatment */}
        <Group
          x={35}
          onClick={() => handleCreateBlock(BlockType.Treatment)}
          onMouseEnter={() => {
            changeCursor('pointer');
            setActive(BlockType.Treatment);
          }}
          onMouseLeave={() => {
            changeCursor('default');
            setActive(null);
          }}
        >
          <Circle
            x={14}
            y={14}
            fill={colors.orange05}
            radius={30}
            width={30}
            height={30}
            visible={active === BlockType.Treatment}
          />
          <Group>
            <BlockIcon type={BlockType.Treatment} />
          </Group>
          <Tooltip
            text="Treatment"
            width={72}
            visible={active === BlockType.Treatment}
          />
        </Group>

        {/* Step */}
        <Group
          x={75}
          onClick={() => handleCreateBlock(BlockType.Step)}
          onMouseEnter={() => {
            changeCursor('pointer');
            setActive(BlockType.Step);
          }}
          onMouseLeave={() => {
            changeCursor('default');
            setActive(null);
          }}
        >
          <Circle
            x={14}
            y={14}
            fill={colors.black05}
            radius={30}
            width={30}
            height={30}
            visible={active === BlockType.Step}
          />
          <Rect
            x={7.5}
            y={7.5}
            width={14}
            height={14}
            cornerRadius={2}
            stroke={colors.black50}
            strokeWidth={1.5}
          />
          <Tooltip text="Step" width={40} visible={active === BlockType.Step} />
        </Group>
      </Group>
    </Group>
  );
};
