import { HurdlesAndGapsConstants } from '../constants/HurdlesAndGapsConstants';

export const findAreaToExplore = (area: string) => {
  const areaToExplore = (Object.keys(
    HurdlesAndGapsConstants
  ) as (keyof typeof HurdlesAndGapsConstants)[])
    .map((key) => HurdlesAndGapsConstants[key])
    .find((ate) => ate.id === area);

  return areaToExplore;
};
