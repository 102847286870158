import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { Link, useParams } from 'react-router-dom';
import { BodySmall } from 'components/shared';
import {
  SubStep,
  useKeyStatementsQuery,
  useAccessStrategyEndpointTargetsQuery,
  AccessStrategyEndpointTargetDocument,
  useAccessStrategyEndpointTargetCreateMutation,
  useEndpointTargetsQuery,
} from 'data/graphql/generated';
import { apolloCreateHelper } from 'utils/apolloQueryHelpers';
import { polling } from 'constants/index';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import AccessStrategyNav from './AccessStrategyNav';
import AccessStrategyStepHeader from './AccessStrategyStepHeader';
import { EvidenceTable } from './EvidenceTable';
import { EvidenceSideBar } from './EvidenceSideBar';
import { Page } from 'components/Page';
import useDesktop from 'hooks/useDesktop';
import { PostItsEmpty } from 'components/PostItsEmpty';
import { colors } from 'constants/colors';
import { useQuery } from 'hooks/useQuery';

const PageWrapper = styled(Page)`
  overflow: hidden;
  padding-top: 30px;
`;

interface Props {}

export interface URLParams {
  drugId: string;
  strategyId: string;
}

export const AccessStrategyEvidence: React.FC<Props> = () => {
  const { drugId, strategyId }: URLParams = useParams();
  const returnToId = useQuery().get('returnToId');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarKeyStatementId, setSidebarKeyStatement] = useState<
    number | null
  >(null);
  // sidebar logic
  const isDesktop = useDesktop();
  const totalNavHeight = isDesktop ? 122 : 160;

  const queryVars = {
    strategyId: +strategyId,
    focus: true,
  };
  const endpointQueryVars = {
    where: { strategyId: +strategyId },
    include: { opportunity: true },
  };

  const {
    data: keyStatementsData,
    error: keyStatementsError,
    loading: keyStatementsLoading,
    startPolling: startKeyStatementsPolling,
    stopPolling: stopKeyStatementsPolling,
  } = useKeyStatementsQuery({
    variables: {
      where: queryVars,
    },
    fetchPolicy: 'network-only',
  });

  const keyStatements = useMemo(
    () => keyStatementsData?.keyStatements?.items || [],
    [keyStatementsData?.keyStatements?.items]
  );

  const {
    data: endpointTargetsData,
    error: endpointTargetsError,
    loading: endpointTargetsLoading,
    startPolling: startEndpointTargetsPolling,
    stopPolling: stopEndpointTargetsPolling,
    refetch,
  } = useAccessStrategyEndpointTargetsQuery({
    variables: {
      ...endpointQueryVars,
    },
    fetchPolicy: 'no-cache',
  });

  const {
    data: endpointTargetData,
    startPolling: endpointStartPolling,
    stopPolling: endpointStopPolling,
    loading: endpointTargetDataLoading,
  } = useEndpointTargetsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
      },
      strategyId: +strategyId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    startKeyStatementsPolling(polling.default);
    startEndpointTargetsPolling(polling.default);
    endpointStartPolling(polling.default);
    return () => {
      stopKeyStatementsPolling();
      stopEndpointTargetsPolling();
      endpointStopPolling();
    };
  }, [
    startKeyStatementsPolling,
    startEndpointTargetsPolling,
    stopKeyStatementsPolling,
    stopEndpointTargetsPolling,
    endpointStartPolling,
    endpointStopPolling,
  ]);

  const endpointTargets =
    endpointTargetsData?.accessStrategyEndpointTargets?.items || [];

  const usedEndpointTargetIds: number[] = endpointTargets.reduce((acc, val) => {
    if (val && val?.endpointTargetId) return [...acc, val.endpointTargetId];
    return acc;
  }, [] as number[]);

  const [
    createEndpointTarget,
  ] = useAccessStrategyEndpointTargetCreateMutation();

  function reviewKeyStatement(id: number) {
    setSidebarKeyStatement(id);
    setSidebarOpen(true);
  }

  async function createLinkedAccessStratEndpointTarget(
    endpointTargetId: number
  ) {
    if (sidebarKeyStatementId) {
      await createEndpointTarget({
        variables: {
          data: {
            strategyId: +strategyId,
            keyStatementId: sidebarKeyStatementId,
            endpointTargetId,
            title: '',
            measurement: '',
            feasibility: 0,
            impact: 0,
            cost: 0,
          },
        },
        update: apolloCreateHelper({
          responseField: 'accessStrategyEndpointTargetCreate',
          query: AccessStrategyEndpointTargetDocument,
          queryVars: endpointQueryVars,
          queryName: 'accessStrategyEndpointTargets',
        }),
      });
      await refetch();
    } else {
      alert('No keystatement defined');
    }
  }

  const scrolledToItem = useRef(false);

  useEffect(() => {
    if (
      typeof returnToId !== 'string' ||
      keyStatementsLoading ||
      endpointTargetsLoading ||
      endpointTargetDataLoading
    )
      return;

    const endpointId = returnToId.split('_')[0];
    const keyStatementId = returnToId.split('_')[1];

    const getOriginalIdea = (prefix: string) =>
      document.querySelector(`#${prefix}${endpointId}`);

    const inSidebar =
      !!usedEndpointTargetIds.length &&
      !usedEndpointTargetIds.includes(+endpointId);

    const prefix = inSidebar ? 'sidebar_endpoint_' : 'endpoint_';

    if (!scrolledToItem.current) {
      const originalIdea = getOriginalIdea(prefix);

      if (!!originalIdea) {
        if (inSidebar && keyStatementId) {
          reviewKeyStatement(+keyStatementId);
        }
        originalIdea.scrollIntoView({ block: 'center' });
        originalIdea.classList.add('originalIdea');
        scrolledToItem.current = true;
      }
    }
  }, [
    returnToId,
    usedEndpointTargetIds,
    keyStatementsLoading,
    endpointTargetsLoading,
    endpointTargetDataLoading,
  ]);

  return (
    <>
      <AccessStrategyNav substep={SubStep.Evidence} />
      <AccessStrategyStepHeader substep={SubStep.Evidence} />
      <PageWrapper paddingTop={false} fullWidthMobile>
        <ErrorWrapper
          isLoading={keyStatementsLoading || endpointTargetsLoading}
          errors={[endpointTargetsError, keyStatementsError]}
          dataMissing={false}
        >
          {keyStatements.length > 0 ? (
            keyStatements.map((keyStatement) => (
              <EvidenceTable
                key={keyStatement.id}
                keyStatement={keyStatement}
                endpointTargets={
                  endpointTargets?.filter(
                    ({ keyStatementId }) => keyStatementId === keyStatement.id
                  ) || []
                }
                strategyId={+strategyId}
                refetch={refetch}
                reviewKeyStatement={reviewKeyStatement}
                queryVars={endpointQueryVars}
                drugId={+drugId}
              />
            ))
          ) : (
            <PostItsEmpty title={'No evidence gaps identified yet'}>
              <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
                A Lead should identify supporting messages to focus on for
                evidence generation in{' '}
              </BodySmall>
              <Link
                to={`/d/${drugId}/strategy/${strategyId}/4_1/gaps`}
                style={{
                  color: colors.greyDark,
                  display: 'inline',
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                Part 2
              </Link>
            </PostItsEmpty>
          )}
        </ErrorWrapper>
      </PageWrapper>

      <EvidenceSideBar
        endpointTargetData={endpointTargetData}
        sidebarKeyStatementId={sidebarKeyStatementId}
        sidebarOpen={sidebarOpen}
        closeSidebar={() => {
          setSidebarOpen(false);
          setSidebarKeyStatement(null);
        }}
        strategyId={+strategyId}
        drugId={+drugId}
        totalNavHeight={totalNavHeight}
        createLinkedAccessStratEndpointTarget={
          createLinkedAccessStratEndpointTarget
        }
        usedEndpointTargetIds={usedEndpointTargetIds}
      />
    </>
  );
};
