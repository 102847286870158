import React, { useState, useEffect, useCallback } from 'react';
import { Rect, Text, Group } from 'react-konva';

import { ConnectionSide, Cursor } from '../../../../../types';
import { colors, TEMP_ID } from 'constants/index';
import { BlockIcon } from '../ui/BlockIcon';
import { RECT_OUTER_DIFFERENCE, RECT_WIDTH } from '../../../constants';
import {
  getBlockShadow,
  getBlockBG,
  getBlockBorder,
  getBlockCollaborationBG,
  snapToGrid,
  generatePathFromConnection,
  showBlockEdit,
  hideBlockLayers,
  showBlockLayers,
} from '../utils';
import {
  BlockType,
  PatientJourneyBlock,
  PatientJourneyBlockFragment,
  PatientJourneyColumn,
  PatientJourneyConnection,
} from 'data/graphql/generated';
import { KonvaCollaboration } from 'components/shared/KonvaCollaboration';
import CardIconBackground from '../ui/CardIconBackground';
import {
  PatientJourneyContainerType,
  SharedPatientJourneyTypes,
} from '../types';
import { getBorderGradientColors } from '../utils/getBorderGradientColors';
import { KonvaEventObject } from 'konva/types/Node';
import { usePatientJourneyPageContext } from 'contexts';

const BLOCK_PADDING_TOP = 40;

interface Props {
  block: PatientJourneyBlockFragment;
  blocks?: PatientJourneyBlockFragment[];
  changeCursor(type: Cursor): void;
  showDotsForBlock(blockId: number): void;
  hideDotsForBlock(blockId: number): void;
  removeBlock(id: number): void;
  updateBlockLocal(block: Partial<PatientJourneyBlock>): void;
  updateConnectionLocal(connection: Partial<PatientJourneyConnection>): void;
  columnStartingPoints?: number[];
  isDrag: boolean;
  stageRef: any;
  connections: PatientJourneyConnection[];
  forceEdit: boolean;
  setEditing(val: number[]): void;
  getColumnIdxForX: any;
  columns: PatientJourneyColumn[];
  setActiveColumn(colIdx: number): void;
  disableCollaboration?: boolean;
  updateMany?: PatientJourneyContainerType['updateMany'];
  setBlockBeingDeleted(blockId: number | undefined): void;
  columnTotalWidth: number;
  adjustFromScreenX: (x: number) => number;
  adjustFromScreenY: (x: number) => number;
  isCollaborationPreview?: boolean;
  canEdit?: boolean;
  hideStepIcon?: boolean;
  inRecapSidebar?: SharedPatientJourneyTypes['inRecapSidebar'];
  handleBlockIconClick?: SharedPatientJourneyTypes['handleBlockIconClick'];
}

interface Block {
  id: number;
  x: number;
  y: number;
  columnId?: number;
  height: number;
  width?: number; 
}
export const BlockCard: React.FC<Props> = ({
  block,
  blocks,
  changeCursor,
  showDotsForBlock,
  hideDotsForBlock,
  removeBlock,
  updateBlockLocal,
  updateConnectionLocal,
  isDrag,
  stageRef,
  connections,
  forceEdit,
  setEditing,
  columnStartingPoints,
  getColumnIdxForX,
  columns,
  setActiveColumn,
  disableCollaboration = false,
  updateMany,
  setBlockBeingDeleted,
  columnTotalWidth,
  adjustFromScreenX,
  adjustFromScreenY,
  isCollaborationPreview = false,
  canEdit = false,
  hideStepIcon,
  inRecapSidebar,
  handleBlockIconClick,
}) => {
  const { openSidebar, sidebarBlockId } = usePatientJourneyPageContext();

  const showBlockSidebar = useCallback(
    (blockId: number | undefined) => {
      if (!!blockId && Number(sidebarBlockId) !== block?.id) {
        openSidebar(blockId);
        return;
      }
    },
    [block?.id, sidebarBlockId, openSidebar]
  );

  const [isDeleting, setDeleting] = useState<boolean>(false);

  const updateBlockWithConnections = useCallback(
    async (updatedBlock: Partial<PatientJourneyBlock>) => {
      // update all connections that join the this block
      const connectionsToUpdate = connections
        .filter((c) => {
          return c.fromBlockId === block.id || c.toBlockId === block.id;
        })
        .map((c) => {
          const path = generatePathFromConnection(
            stageRef,
            adjustFromScreenX,
            adjustFromScreenY,
            {
              toBlockId: c.toBlockId,
              toSide: c.toSide as ConnectionSide,
              fromBlockId: c.fromBlockId,
              fromSide: c.fromSide as ConnectionSide,
            },
            false
          );
          return {
            ...c,
            path,
          };
        });

      updateMany?.({
        blocks: [{ ...block, ...updatedBlock }],
        columns: [],
        connections: connectionsToUpdate,
      });
    },
    [
      adjustFromScreenX,
      adjustFromScreenY,
      block,
      connections,
      stageRef,
      updateMany,
    ]
  );

  useEffect(() => {
    if (setBlockBeingDeleted) {
      if (isDeleting) {
        setBlockBeingDeleted(block.id);
      } else {
        setBlockBeingDeleted(undefined);
      }
    }
  }, [isDeleting, block.id, setBlockBeingDeleted]);

  // Show textinput if forceEdit is truthy
  useEffect(() => {
    if (forceEdit && canEdit) {
      showBlockEdit({
        block,
        stageRef,
        setDeleting,
        updateBlock: updateBlockWithConnections,
        setForceEditing: setEditing,
        connections,
        columnTotalWidth,
        adjustFromScreenX,
        adjustFromScreenY,
      });
    }
  }, [
    forceEdit,
    block,
    stageRef,
    setDeleting,
    updateBlockWithConnections,
    setEditing,
    connections,
    columnTotalWidth,
    adjustFromScreenX,
    adjustFromScreenY,
    canEdit,
  ]);

  const onDragStart = (e: any) => {
    changeCursor('grabbing');

    const blockNode = stageRef.current.findOne(`#block-${block.id}`);

    // style block & shadow on drag
    blockNode.rotate(5);
    const card = blockNode.findOne(`#block-${block.id}-card`);
    card.y(card.y() - 10);
    stageRef.current.batchDraw();
    hideBlockLayers(stageRef, [block], true);
  };

  const onDragMove = (e: any) => {
    const shadowNode = stageRef.current.findOne(`#block-${block.id}-shadow`);

    const targetRect = e.target.getClientRect();

    const x = snapToGrid(adjustFromScreenX(targetRect.x));
    const y = snapToGrid(adjustFromScreenY(targetRect.y));

    shadowNode.position({
      x,
      y,
    });
    const dotsNode = stageRef.current.findOne(`#block-${block.id}-dots`);
    dotsNode.position({
      x,
      y,
    });

    setActiveColumn(
      getColumnIdxForX(
        snapToGrid(adjustFromScreenX(targetRect.x + RECT_WIDTH / 2))
      )
    );

    // update block's connections
    // TODO we should try and remove this so no state is updated while moving
    updateBlockLocal({
      x,
      y,
    });

    connections
      .filter(
        (c) =>
          Number(c.toBlockId) === Number(block.id) ||
          Number(c.fromBlockId) === Number(block.id)
      )
      .forEach((c) => {
        const path = generatePathFromConnection(
          stageRef,
          adjustFromScreenX,
          adjustFromScreenY,
          {
            toBlockId: c.toBlockId,
            toSide: c.toSide as ConnectionSide,
            fromBlockId: c.fromBlockId,
            fromSide: c.fromSide as ConnectionSide,
          },
          false
        );

        const connectionNode = stageRef.current.findOne(`#connection-${c.id}`);
        if (connectionNode) {
          connectionNode.attrs.points = path;
        }
      });

    stageRef.current.batchDraw();
  };


  const isOverlapping = (block1: Block, block2: Block): boolean => {
    return !(
      block1.x + RECT_WIDTH <= block2.x || 
      block1.x >= block2.x + RECT_WIDTH ||
      block1.y + block1.height <= block2.y ||
      block1.y >= block2.y + block2.height
    );
  }

  const findNonOverlappingPosition = (draggedBlock: Block, blocks: PatientJourneyBlockFragment[] | undefined): { x: number, y: number } => {
    const gridStep = 20; // Define a step to increment position to find the nearest non-overlapping position
    let newX = draggedBlock.x;
    let newY = draggedBlock.y;
    let overlap = false;
    const maxIterations = 1000; // Prevent infinite loops by limiting iterations
    let iterations = 0;

    if (!blocks) {
      return { x: draggedBlock.x, y: draggedBlock.y };
    }

    do {
        overlap = false;
        for (const block of blocks) {
            if (block.id !== draggedBlock.id && isOverlapping({ ...draggedBlock, x: newX, y: newY }, block)) {
                overlap = true;
                iterations++;

                // Determine the new position based on the location of the overlapping block
                const overlapOnX = Math.abs(block.x - newX);
                const overlapOnY = Math.abs(block.y - newY);

                if (overlapOnX > overlapOnY) {
                    // Adjust horizontally
                    if (newX < block.x) {
                        newX = block.x - RECT_WIDTH - gridStep;
                    } else {
                        newX = block.x + RECT_WIDTH + gridStep;
                    }
                } else {
                    // Adjust vertically
                    if (newY < block.y) {
                        newY = block.y - draggedBlock.height - gridStep;
                    } else {
                        newY = block.y + block.height + gridStep;
                    }
                }

                // Check for window boundaries
                if (newY < 0)
                  newY = 40;
                if (newX < 0)
                  newX = 0;
                break;
            }
        }
    } while (overlap && iterations < maxIterations);
    return { x: newX, y: newY };
  }


  const onDragEnd = async (e: any) => {
    changeCursor('pointer');
    const layer = stageRef.current;

    try {
      const blockNode = layer.findOne(`#block-${block.id}`);

      // style block & shadow on drag
      blockNode.rotate(-5);
      const card = layer.findOne(`#block-${block.id}-card`);
      card.y(card.y() + 10);

      const shadowNode = layer.findOne(`#block-${block.id}-shadow`);
      let id = block.id;
      let columnId = block.columnId;
      let x = shadowNode.x();
      let y = shadowNode.y();
      let height = shadowNode.height();
      const colIdx = getColumnIdxForX(x + RECT_WIDTH / 2);

      const newPosition = findNonOverlappingPosition({id, x, y, height, columnId, width: RECT_WIDTH}, blocks);
      x = newPosition.x
      y = newPosition.y

      const column = columns.find((c) => {
        return c.idx === colIdx;
      });

      if (!column) {
        console.error('no column');
        return;
      }

      if (columnStartingPoints) {
        const xCurrent = columnStartingPoints[column?.idx + 1];
        const xPrev = columnStartingPoints[column?.idx];

        if ((x + RECT_WIDTH > xCurrent && block.columnId === column?.id)
          || (x + (RECT_WIDTH / 2) > xCurrent - (RECT_WIDTH / 2) && block.columnId !== column?.id)) {
          x = xCurrent - RECT_WIDTH - 10;
          y = shadowNode.y()
        }

        if (x < xPrev) {
          x = xPrev; 
          y = shadowNode.y()
        }
        
        if (x + RECT_WIDTH > xCurrent) {
          x = xCurrent;
          y = shadowNode.y()
        }
      }

      layer.findOne(`#block-${block.id}`).position({
        x,
        y,
      });
      layer.findOne(`#block-${block.id}-shadow`).position({
        x,
        y,
      });
      layer.findOne(`#block-${block.id}-old`).position({
        x,
        y,
      });
      layer.findOne(`#block-${block.id}-delete`).position({
        x,
        y,
      });
      layer.findOne(`#block-${block.id}-dots`).position({
        x,
        y,
      });
      layer.batchDraw();
      
      blockNode.position({
        x,
        y,
      });
      const oldNode = layer.findOne(`#block-${block.id}-old`);
      oldNode.position({
        x,
        y,
      });
      const deleteNode = layer.findOne(`#block-${block.id}-delete`);
      deleteNode.position({
        x,
        y,
      });
     
      showBlockLayers(stageRef, [block], true);

      const connectionsToUpdate = connections
        .filter(
          (c) =>
            Number(c.toBlockId) === Number(block.id) ||
            Number(c.fromBlockId) === Number(block.id)
        )
        .map((c) => {
          const path = generatePathFromConnection(
            stageRef,
            adjustFromScreenX,
            adjustFromScreenY,
            {
              toBlockId: c.toBlockId,
              toSide: c.toSide as ConnectionSide,
              fromBlockId: c.fromBlockId,
              fromSide: c.fromSide as ConnectionSide,
            },
            false
          );

          updateConnectionLocal({
            ...c,
            path,
          });

          return {
            ...c,
            path,
          };
        });

      await updateMany?.({
        blocks: [
          {
            id: block.id,
            x,
            y,
            height: block.height,
            columnId: column.id,
          },
        ],
        columns: [],
        connections: connectionsToUpdate,
      });

      //
      layer.batchDraw();
    } catch (err) {
      // Reset block locally
      const x = block.x;
      const y = block.y;
      layer.findOne(`#block-${block.id}`).position({
        x,
        y,
      });
      layer.findOne(`#block-${block.id}-shadow`).position({
        x,
        y,
      });
      layer.findOne(`#block-${block.id}-old`).position({
        x,
        y,
      });
      layer.findOne(`#block-${block.id}-delete`).position({
        x,
        y,
      });
      layer.findOne(`#block-${block.id}-dots`).position({
        x,
        y,
      });
      layer.batchDraw();

      // IF updateBlock fails let's reset the connection paths
      connections
        .filter(
          (c) =>
            Number(c.toBlockId) === Number(block.id) ||
            Number(c.fromBlockId) === Number(block.id)
        )
        .forEach((c) => {
          const connectionNode = stageRef.current.findOne(
            `#connection-${c.id}`
          );
          if (connectionNode) {
            connectionNode.attrs.points = c.path;
          }
          if (c.fromBlockId === block.id) {
            const connectionDotNode = stageRef.current.findOne(
              `.connection-dot-c-${c.id}`
            );
            if (connectionDotNode) {
              connectionDotNode.position({ x: c.path[0], y: c.path[1] });
            }
          }
        });
    }
  };

  const starred =
    block.keyStakeHolderHealthcare ||
    block.keyStakeHolderPayor ||
    block.keyStakeHolderPatient ||
    block.keyStakeHolderProvider ||
    block.keyStakeHolderPolicymaker ||
    undefined;

  const isStepBlock = block.type === BlockType.Step;

  const gradientBorder = starred
    ? {
        strokeLinearGradientStartPoint: {
          x: 0,
          y: 0,
        },
        strokeLinearGradientEndPoint: {
          x: RECT_WIDTH * 2,
          y: block.height * 2,
        },
        strokeLinearGradientColorStops: getBorderGradientColors(
          block.addressingIssueRating
        ),
      }
    : {};

  const cardIconClickHandler = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      if (inRecapSidebar) {
        handleBlockIconClick?.(block.id);

        return;
      }
      if (isDrag) return;

      if (isStepBlock && TEMP_ID !== block.id) {
        e.cancelBubble = true;
        showBlockSidebar(block.id);

        changeCursor('default');
        hideDotsForBlock(block.id);
      }
    },
    [
      inRecapSidebar,
      isDrag,
      isStepBlock,
      block.id,
      handleBlockIconClick,
      showBlockSidebar,
      changeCursor,
      hideDotsForBlock,
    ]
  );

  const cardClickHandler = useCallback(() => {
    if (isDrag || !canEdit) return;

    showBlockEdit({
      block,
      stageRef,
      setDeleting,
      updateBlock: updateBlockWithConnections,
      setForceEditing: setEditing,
      connections,
      columnTotalWidth,
      adjustFromScreenX,
      adjustFromScreenY,
    });
  }, [
    adjustFromScreenX,
    adjustFromScreenY,
    block,
    canEdit,
    columnTotalWidth,
    connections,
    isDrag,
    setEditing,
    stageRef,
    updateBlockWithConnections,
  ]);

  const cancelDeleteHandler = () => setDeleting(false);
  const deleteClickHandler = () => {
    if (TEMP_ID !== block.id) removeBlock(block.id);
  };

  return (
    <>
      <Rect
        id={`block-${block.id}-shadow`}
        x={block.x}
        y={block.y}
        width={RECT_WIDTH}
        height={block.height}
        sides={4}
        cornerRadius={5}
        fill={getBlockShadow(block.type)}
      />
      {/* old card */}
      <Group
        id={`block-${block.id}-old`}
        x={block.x}
        y={block.y}
        opacity={0.5}
        listening={false}
      >
        <Group>
          {block.type === BlockType.Step && !!starred && (
            <Rect
              x={-3}
              y={-3}
              width={RECT_WIDTH + RECT_OUTER_DIFFERENCE}
              height={block.height + RECT_OUTER_DIFFERENCE}
              sides={4}
              {...gradientBorder}
              cornerRadius={8}
              fill={getBlockBG(block.type as BlockType)}
              stroke={getBlockBorder(block.type as BlockType, !!starred)}
              id={`block-${block.id}-rect-old-outer`}
            />
          )}
          <Rect
            width={RECT_WIDTH}
            height={block.height}
            sides={4}
            cornerRadius={5}
            fill={getBlockBG(block.type as BlockType)}
            stroke={getBlockBorder(block.type as BlockType)}
            id={`block-${block.id}-rect-old`}
            {...gradientBorder}
          />
          <Text
            text={block.text}
            fontFamily="ABCFavorit"
            fontSize={16}
            lineHeight={1.2}
            fontWeight={400}
            wrap="word"
            align="center"
            id={`block-${block.id}-text-old`}
            width={RECT_WIDTH - 8}
            height={block.height - 40}
            y={40}
            x={3}
          />
          <Group x={8} y={8}>
            <BlockIcon
              canEdit={canEdit}
              listening={false}
              type={block.type as BlockType}
            />
          </Group>
        </Group>
      </Group>

      <Group>
        {/* Card */}
        <Group
          key={`block-${block.id}`}
          id={`block-${block.id}`}
          x={block.x}
          y={block.y}
          draggable={!isDrag}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDragMove={onDragMove}
          onClick={cardClickHandler}
          onTap={cardClickHandler}
        >
          <Group
            id={`block-${block.id}-card`}
            onMouseEnter={() => {
              if (isDrag) return;
              changeCursor('pointer');
              showDotsForBlock(block.id);
            }}
            onMouseLeave={() => {
              changeCursor('default');
              hideDotsForBlock(block.id);
            }}
          >
            {/*  starred outer border bg */}
            {block.type === BlockType.Step && !!starred && (
              <Rect
                id={`block-${block.id}-rect-outer`}
                x={-3}
                y={-3}
                width={RECT_WIDTH + RECT_OUTER_DIFFERENCE}
                height={block.height + RECT_OUTER_DIFFERENCE}
                sides={4}
                {...gradientBorder}
                cornerRadius={8}
                fill={getBlockBG(block.type as BlockType)}
                stroke={getBlockBorder(block.type as BlockType, !!starred)}
              />
            )}
            <Rect
              width={RECT_WIDTH}
              height={block.height}
              id={`block-${block.id}-rect`}
              sides={4}
              cornerRadius={5}
              fill={getBlockBG(block.type as BlockType)}
              stroke={getBlockBorder(block.type as BlockType, !!starred)}
              {...gradientBorder}
            />
            <Text
              id={`block-${block.id}-text`}
              text={block.text}
              fontFamily="ABCFavorit"
              fontSize={16}
              lineHeight={1.18}
              fontWeight={400}
              wrap="word"
              align="center"
              width={RECT_WIDTH - 8}
              height={block.height - BLOCK_PADDING_TOP}
              x={3}
              y={40}
            />

            {!(isStepBlock && hideStepIcon) && (
              <Group
                x={2}
                y={2}
                onTap={cardIconClickHandler}
                onClick={cardIconClickHandler}
                onMouseEnter={() => {
                  changeCursor('pointer');
                }}
                onMouseLeave={() => {
                  changeCursor('default');
                }}
              >
                <CardIconBackground
                  inRecapSidebar={inRecapSidebar}
                  enableHover={isStepBlock}
                />
                <BlockIcon
                  listening={false}
                  type={block.type as BlockType}
                  isActiveInSidebar={Number(sidebarBlockId) === block.id}
                  canEdit={canEdit}
                  inRecapSidebar={inRecapSidebar}
                />
              </Group>
            )}
          </Group>

          {!isCollaborationPreview && !disableCollaboration && (
            <KonvaCollaboration
              x={RECT_WIDTH - 5}
              y={6}
              collaboration={block?.collaboration}
              color={getBlockCollaborationBG(block?.type as BlockType)}
              onMouseEnter={() => {
                changeCursor('pointer');
              }}
              onMouseLeave={() => {
                changeCursor('default');
              }}
            />
          )}
        </Group>
      </Group>

      {/* Delete Warning */}
      <Group
        key={`block-${block.id}-delete`}
        id={`block-${block.id}-delete`}
        x={block.x}
        y={block.y}
        visible={!!isDeleting}
      >
        <Group id={`block-${block.id}-card`}>
          <Rect
            width={RECT_WIDTH}
            height={block.height > 120 ? block.height : 120}
            sides={4}
            cornerRadius={5}
            fill={getBlockBG(block.type as BlockType)}
            stroke={getBlockBorder(block.type as BlockType)}
          />
          <Text
            text="Delete this block?"
            fontFamily="ABCFavorit"
            fontSize={16}
            fontWeight={400}
            wrap="word"
            align="center"
            width={RECT_WIDTH - 10}
            y={20}
            x={5}
          />
          <Text
            text="Any discussion and files will be lost"
            fill="#6F6D7D"
            fontFamily="ABCFavorit"
            fontSize={16}
            fontWeight={400}
            lineHeight={1.2}
            align="center"
            width={RECT_WIDTH - 10}
            y={44}
            x={5}
          />
          <Text
            text="Cancel"
            fill={colors.black}
            fontFamily="ABCFavorit"
            fontSize={14}
            fontWeight={500}
            align="center"
            width={RECT_WIDTH / 2}
            onClick={cancelDeleteHandler}
            onTap={cancelDeleteHandler}
            y={92}
            x={5}
            onMouseEnter={() => changeCursor('pointer')}
            onMouseLeave={() => changeCursor('default')}
          />
          <Text
            text="Delete"
            fill={colors.red}
            fontFamily="ABCFavorit"
            fontSize={14}
            fontWeight={500}
            align="center"
            width={RECT_WIDTH / 2}
            onClick={deleteClickHandler}
            onTap={deleteClickHandler}
            y={92}
            x={RECT_WIDTH / 2}
            onMouseEnter={() => changeCursor('pointer')}
            onMouseLeave={() => changeCursor('default')}
          />
        </Group>
      </Group>
    </>
  );
};
