import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { PostIts } from 'components/PostIts';
import { PostItGroupType, Step, SubStep } from 'data/graphql/generated';
import { usePostItCardMove } from 'hooks/usePostItCardMove';
import { FC } from 'react';
import { sortPostIts } from '../../../../hooks';
import { usePostItCards } from '../../../../hooks/usePostItCards';
import { usePostItGroups } from '../../../../hooks/usePostItGroups';

interface AreaToExploreViewProps {
  drugId: number;
  strategyId: number;
  competitiveAdvantageRowId: number;
  groupType: PostItGroupType;
}

export const AreaToExploreView: FC<AreaToExploreViewProps> = ({
  drugId,
  strategyId,
  competitiveAdvantageRowId,
  groupType,
}) => {
  const {
    items: groupItems,
    loading: groupsLoading,
    createGroup,
    updateGroup,
    removeGroup,
    error: groupsError,
  } = usePostItGroups(
    {
      where: {
        strategyId: strategyId,
        competitiveAdvantageRowId,
        step: Step.MedicalStrategy,
        substep: SubStep.HurdlesAndGaps,
        type: groupType,
      },
    },
    {
      drugId: drugId,
      strategyId: strategyId,
      competitiveAdvantageRowId: competitiveAdvantageRowId,
      step: Step.MedicalStrategy,
      substep: SubStep.HurdlesAndGaps,
      type: groupType,
    }
  );

  const {
    items: cardItems,
    loading: cardsLoading,
    createCard,
    updateCard,
    removeCard,
    error: cardsError,
  } = usePostItCards(
    {
      where: {
        strategyId: strategyId,
        competitiveAdvantageRowId,
        step: Step.MedicalStrategy,
        substep: SubStep.HurdlesAndGaps,
      },
    },
    {
      strategyId: strategyId,
      drugId,
      competitiveAdvantageRowId,
      step: Step.MedicalStrategy,
      substep: SubStep.HurdlesAndGaps,
    }
  );
  const groups = sortPostIts(groupItems, cardItems);

  const onChange = usePostItCardMove(groups, updateCard);

  return (
    <ErrorWrapper
      isLoading={cardsLoading || groupsLoading}
      errors={[cardsError, groupsError]}
      dataMissing={!groupItems || !cardItems}
    >
      <PostIts
        step={Step.MedicalStrategy}
        subStep={SubStep.HurdlesAndGaps}
        groups={groups}
        addCard={createCard}
        removeCard={removeCard}
        updateCard={updateCard}
        handleCardChange={onChange}
        createGroup={createGroup}
        updateGroup={updateGroup}
        removeGroup={removeGroup}
      />
    </ErrorWrapper>
  );
};
