import React, { useState, createContext, useContext, useEffect } from 'react';
import { get } from 'lodash';
import { User, useUserAuthedQuery } from '../data/graphql/generated';
interface iAuthContext {
  loading: boolean;
  user: User | undefined;
  redirectPath?: string;
  refetch: ReturnType<typeof useUserAuthedQuery>['refetch'] | undefined;
}

export type iAuthContextProps = [iAuthContext, any];

const AuthContext = createContext<iAuthContextProps>([
  {
    loading: true,
    user: undefined,
    redirectPath: undefined,
    refetch: undefined,
  },
  null,
]);

const AuthProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<iAuthContext>({
    loading: true,
    user: undefined,
    redirectPath: undefined,
    refetch: undefined,
  });

  const { data, error, loading, refetch } = useUserAuthedQuery({
    variables: {},
    fetchPolicy: 'network-only',
  });

  if (error) console.error(JSON.stringify(error, null, 2));

  useEffect(() => {
    if (loading) return;

    if (get(data, 'userAuthed')) {
      setState((st) => ({
        ...st,
        user: data?.userAuthed,
        loading: false,
        refetch,
      }));
    }
    if (!get(data, 'userAuthed')) {
      setState((st) => ({
        ...st,
        user: undefined,
        loading: false,
        refetch: undefined,
      }));
    }
  }, [loading, data, state.loading, refetch]);

  function updateAuth(state: Partial<iAuthContext>) {
    setState((s) => ({ ...s, ...state }));
  }

  return (
    <AuthContext.Provider value={[state, updateAuth]}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuthContext() {
  return useContext(AuthContext);
}

export { useAuthContext, AuthContext, AuthProvider };
