import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { Budget, BudgetChart } from 'components/MedicalStrategy/BudgetChart';
import {
  BudgetGroups,
  FinancialSummary,
  FinancialTables,
  getReadableBudget,
  getYearBudgets,
  ImperativeBudgets,
  PurpleSeparator,
  StyledEmptyState,
  SubTableHeaders,
  TacticAndBudget,
  TitleAndBudget,
} from 'components/MedicalStrategy/MedicalSummary';
import { BodySmall, Subtitle1, Subtitle2 } from 'components/shared';
import { colors, polling } from 'constants/index';
import {
  useArchetypesQuery,
  useStrategyQuery,
  useSupportingMessageTacticsQuery,
} from 'data/graphql/generated';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { dateStringToMonthYear } from 'utils/dateStringToMonthYear';
import { thousandSeparator } from 'utils/thousandSeparator';
import sortBy from 'lodash/sortBy';
import toNumber from 'lodash/toNumber';

const StyledFinancialTables = styled(FinancialTables)`
  @media ${device.tabletMin} {
    max-width: 451px;
  }

  @media ${device.desktopMin} {
    max-width: 1400px;
  }
`;

const StyledBudgetChart = styled(BudgetChart)`
  @media ${device.tabletMin} {
    max-width: 451px;
  }

  @media ${device.desktopMin} {
    max-width: 692px;
  }
`;

const TotalInvestment = styled.div`
  margin-bottom: 20px;
  @media ${device.mobile} {
    margin-left: 20px;
  }
`;

type Props = {
  strategyId: string;
  drugId: string;
};

export const AccessStrategySummaryFinancials = ({
  strategyId,
  drugId,
}: Props) => {
  const {
    data: strategyData,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
    loading: strategyLoading,
    error: strategyError,
  } = useStrategyQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'network-only',
  });

  const {
    data: archetypeData,
    loading: archetypeLoading,
    error: archetypeError,
    startPolling: archetypesStartPolling,
    stopPolling: archetypesStopPolling,
  } = useArchetypesQuery({
    variables: {
      where: { strategyId: Number(strategyId), countriesAssigned: true },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const {
    data: supportingMessageTacticsData,
    loading: supportingMessageTacticsLoading,
    error: supportingMessageTacticsError,
    startPolling: supportingMessageTacticsStartPolling,
    stopPolling: supportingMessageTacticsStopPolling,
  } = useSupportingMessageTacticsQuery({
    variables: { where: { strategyId: Number(strategyId), focus: true } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    archetypesStartPolling(polling.default);
    supportingMessageTacticsStartPolling(polling.default);
    startStrategyPolling(polling.default);

    return () => {
      supportingMessageTacticsStopPolling();
      stopStrategyPolling();
      archetypesStopPolling();
    };
  }, [
    startStrategyPolling,
    stopStrategyPolling,
    archetypesStartPolling,
    archetypesStopPolling,
    supportingMessageTacticsStartPolling,
    supportingMessageTacticsStopPolling,
  ]);

  const sortedArchetypes = useMemo(() => {
    const sortedByStakeholderDefinitionTitle = sortBy(
      archetypeData?.archetypes?.items,
      (v) => v.stakeholderDefinition.title
    );

    const sortedByArchetypeBudgetDescending = sortBy(
      sortedByStakeholderDefinitionTitle,
      (archetype) => {
        // Get total budget of supportingMessageTactics so we can sort archetypes
        const archetypeBudget = supportingMessageTacticsData?.supportingMessageTactics?.items.reduce(
          (acc, curr) => {
            if (curr.archetypeId === archetype.id) {
              return +curr.budget + acc;
            }

            return acc;
          },
          0
        );

        // Sort in descending order
        return -(archetypeBudget || 0);
      }
    );

    return sortedByArchetypeBudgetDescending;
  }, [
    archetypeData?.archetypes?.items,
    supportingMessageTacticsData?.supportingMessageTactics?.items,
  ]);

  const initialArchetypeBudgets = sortedArchetypes.reduce(
    (a, b) => {
      a[b.id] = {
        subtitle: b.stakeholderDefinition.title,
        amount: 0,
        id: b.id,
      };

      return a;
    },
    {
      0: {
        subtitle: 'Global (Across all archetypes)',
        amount: 0,
        id: 0,
      },
    } as Record<number, Budget>
  );

  const budgets = useMemo(
    () =>
      Object.values(
        supportingMessageTacticsData?.supportingMessageTactics?.items.reduce(
          // Create an object to calculate all sums, then convert values into an array
          (acc, curr) => {
            // Global (Across all archetypes) has an id of 0, which is defined as the default value
            if (!!curr.archetypeId) {
              if (curr.archetypeId in acc) {
                acc[curr.archetypeId].amount += +curr.budget;
              } else {
                acc[curr.archetypeId] = {
                  subtitle: sortedArchetypes?.find(
                    (archetype) => archetype.id === curr.archetypeId
                  )?.stakeholderDefinition.title,
                  amount: +curr.budget,
                  id: curr.archetypeId,
                };
              }
            } else {
              acc[0].amount += +curr.budget;
            }
            return acc;
          },
          {
            ...initialArchetypeBudgets,
          } as Record<number, Budget>
        ) || {}
      ),
    [
      initialArchetypeBudgets,
      sortedArchetypes,
      supportingMessageTacticsData?.supportingMessageTactics?.items,
    ]
  );

  const sortedByBudgets = [
    budgets[0],
    ...sortBy(budgets.slice(1), 'subtitle').sort((a, b) => b.amount - a.amount),
  ];

  const totalBudget = sortedByBudgets.reduce((acc, curr) => {
    return acc + (curr?.amount || 0);
  }, 0);

  const yearBudgets: Budget[] = useMemo(
    () =>
      getYearBudgets(
        supportingMessageTacticsData?.supportingMessageTactics?.items.map(
          (item) => ({
            ...item,
            timingStart: dateStringToMonthYear(item.from),
            timingEnd: dateStringToMonthYear(item.to),
          })
        ) || []
      ),
    [supportingMessageTacticsData?.supportingMessageTactics?.items]
  );

  const currency = strategyData?.strategy?.currency[0] || '¥';
  const archetypesWithGlobal = [
    {
      id: 0,
      stakeholderDefinition: { title: 'Global (Across all archetypes)' },
    },
    ...(sortedArchetypes || []),
  ];

  return (
    <ErrorWrapper
      isLoading={
        archetypeLoading || supportingMessageTacticsLoading || strategyLoading
      }
      errors={[archetypeError, supportingMessageTacticsError, strategyError]}
      dataMissing={
        !supportingMessageTacticsData || !archetypeData || !strategyData
      }
    >
      <FinancialSummary>
        <div>
          <TotalInvestment>
            <Subtitle1 color={colors.greyDark} style={{ display: 'inline' }}>
              Total investment required:{' '}
            </Subtitle1>
            <Subtitle1 style={{ display: 'inline' }}>
              {currency + (totalBudget ? thousandSeparator(totalBudget) : '-')}
            </Subtitle1>
          </TotalInvestment>
          <BudgetGroups>
            <StyledBudgetChart
              title="Archetype"
              budgets={sortedByBudgets || []}
              currency={currency}
            />
            <StyledBudgetChart
              title="Annual Spend"
              budgets={yearBudgets}
              currency={currency}
            />
          </BudgetGroups>
          <div>
            <StyledFinancialTables>
              {archetypesWithGlobal?.map((archetype) => {
                const archetypeBudget = supportingMessageTacticsData?.supportingMessageTactics?.items.reduce(
                  (acc, curr) => {
                    if (curr.archetypeId === archetype.id) {
                      return +curr.budget + acc;
                    }
                    if (!archetype.id && !curr.archetypeId) {
                      // Global (Across all archetypes)
                      return +curr.budget + acc;
                    }

                    return acc;
                  },
                  0
                );
                const readableBudget = getReadableBudget(
                  currency,
                  archetypeBudget
                );

                const imperativeTactics = supportingMessageTacticsData?.supportingMessageTactics?.items.filter(
                  (tactic) => {
                    if (!archetype.id && !tactic.archetypeId) {
                      // Global (Across all archetypes)
                      return true;
                    }

                    if (tactic.archetypeId) {
                      return tactic.archetypeId === archetype.id;
                    }
                    return false;
                  }
                );

                const sortedByBudget = imperativeTactics
                  ?.sort((a, b) => {
                    if (!a.budget && !b.budget) return 0;
                    if (!a.budget) return 1;
                    if (!b.budget) return -1;

                    return +b.budget > +a.budget ? 1 : -1;
                  })
                  .sort(
                    (a, b) =>
                      (Boolean(b.budget) ? toNumber(b.budget) : 0) -
                      (Boolean(a.budget) ? toNumber(a.budget) : 0)
                  );

                return (
                  <ImperativeBudgets key={archetype.id}>
                    <TitleAndBudget>
                      <Subtitle2>
                        {archetype.stakeholderDefinition.title}
                      </Subtitle2>
                      <Subtitle2>{readableBudget}</Subtitle2>
                    </TitleAndBudget>

                    <SubTableHeaders>
                      <BodySmall color={colors.greyDark}>
                        Access initiative
                      </BodySmall>
                      <BodySmall color={colors.greyDark}>Budget</BodySmall>
                    </SubTableHeaders>

                    <PurpleSeparator />
                    {!!sortedByBudget?.length ? (
                      sortedByBudget.map((t) => {
                        const readableTacticBudget = getReadableBudget(
                          currency,
                          t?.budget ? +t.budget : 0
                        );

                        return (
                          <TacticAndBudget key={t.id}>
                            <BodySmall>{t.text}</BodySmall>
                            <BodySmall>{readableTacticBudget}</BodySmall>
                          </TacticAndBudget>
                        );
                      })
                    ) : (
                      <StyledEmptyState includeIcon={false}>
                        <Subtitle2 color={colors.greyDark}>
                          No access initiatives yet
                        </Subtitle2>
                      </StyledEmptyState>
                    )}
                  </ImperativeBudgets>
                );
              })}
            </StyledFinancialTables>
          </div>
        </div>
      </FinancialSummary>
    </ErrorWrapper>
  );
};
