import { colors } from 'constants/index';
import React, { useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '.';

const ArrowWrapper = styled.div<{ visible: boolean }>`
  border-radius: 1000px;
  padding: 5px;
  background: ${colors.white70};
  width: 30px;
  height: 30px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  opacity: 1;
  transition: opacity 0.2s, background 0.2s;
  ${({ visible }) => {
    if (!visible) {
      return css`
        opacity: 0;
        pointer-events: none;
      `;
    }
  }}

  &:hover {
    background: ${colors.white};
  }
`;

const Wrapper = styled.div`
  position: relative;
`;
export const ScrollWrapper = styled.div<{ centerItems: boolean }>`
  overflow: hidden;

  display: flex;
  gap: 14px;

  justify-content: ${({ centerItems }) =>
    centerItems ? 'center' : 'flex-start'};

  > * {
    flex: none;
  }

  scroll-behavior: smooth;
`;

interface Props {
  scrollAmount?: number;
  calculateScroll?: boolean;
  className?: string;
}

export const ImageCarousel: React.FC<Props> = ({
  children,
  scrollAmount = 192,
  calculateScroll,
  className,
}) => {
  const [scrollAvailable, setScrollAvailable] = useState({
    left: false,
    right: false,
  });
  const scrollWrapperRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const div = scrollWrapperRef.current;
    if (!!div && calculateScroll) {
      setScrollAvailable({
        left: div.scrollLeft > 0,
        right: div.offsetWidth + div.scrollLeft !== div.scrollWidth,
      });
    }
  }, [calculateScroll]);

  const centerItems = () => {
    if (!!scrollWrapperRef?.current) {
      return (
        scrollWrapperRef?.current?.offsetWidth >=
        scrollWrapperRef?.current?.scrollWidth
      );
    }
  };

  return (
    <Wrapper className={className}>
      <ArrowWrapper
        visible={!!scrollAvailable.left}
        style={{
          position: 'absolute',
          transform: 'translateY(-50%)',
          top: '50%',
          left: '5px',
        }}
        onClick={(e) => {
          if (!!scrollWrapperRef.current) {
            scrollWrapperRef.current.scrollLeft -= scrollAmount;
          }
        }}
      >
        <Icon name={'Arrow-left'} size={20} color="black" />
      </ArrowWrapper>

      <ArrowWrapper
        visible={!!scrollAvailable.right}
        style={{
          position: 'absolute',
          transform: 'translateY(-50%)',
          top: '50%',
          right: '5px',
        }}
        onClick={(e) => {
          if (!!scrollWrapperRef.current) {
            scrollWrapperRef.current.scrollLeft += scrollAmount;
          }
        }}
      >
        <Icon name={'Arrow-right'} size={20} color="black" />
      </ArrowWrapper>

      <ScrollWrapper
        ref={scrollWrapperRef}
        centerItems={!!centerItems()}
        onScroll={(e) => {
          const div = e.target as HTMLDivElement;

          setScrollAvailable({
            left: div.scrollLeft > 0,
            right: div.offsetWidth + div.scrollLeft !== div.scrollWidth,
          });
        }}
      >
        {children}
      </ScrollWrapper>
    </Wrapper>
  );
};
