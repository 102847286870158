export function makeGetColumnIdxForX(columnStartingPoints: number[]) {
  return function getColumnIdxForX(x: number) {
    const reverseIndex = [...columnStartingPoints]
      .reverse()
      .findIndex((start) => {
        return x >= start;
      });
    return columnStartingPoints.length - 1 - reverseIndex;
  };
}
