import * as React from 'react';
import styled from 'styled-components/macro';
import * as Sentry from '@sentry/react';
import {
  usePicoSuggestionFocusMutation,
  usePicoSuggestionStrategicImperativesLazyQuery,
} from 'data/graphql/generated';
import { useModalWithSteps } from 'contexts';
import { LoadingComponent } from 'components/Loading';
import { BodyNormal, Subtitle1 } from 'components/shared';
import { DropdownList } from 'components/shared/FormDropdown';
import FormTextInput from 'components/shared/FormTextInput';
import { RationaleModal } from 'components/shared/RationaleModal';
import { PicoSuggestionModalInformation } from 'components/4-1-access-strategy/shared-pico-suggestion';
import { focusPicoSuggestionReducer } from '../reducers/focusPicoSuggestionReducer';
import { StrategicImperativeDropdown } from './StrategicImperativeDropdown';

const StyledRationaleModal = styled(RationaleModal)`
  .rationale__sliderWindow {
    z-index: 1;
    overflow: visible;
  }

  .ModalWithDetail__ChildrenContainer {
    overflow: visible;
  }
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${DropdownList} {
    z-index: 3;
  }
`;

const StyledFormTextInput = styled(FormTextInput)``;

const INVALID_NAME_MESSAGE = 'Please enter a valid name';

interface FocusPicoSuggestionModalProps {
  strategyId: number;
  pico: PicoSuggestionModalInformation;
}

export const FocusPicoSuggestionModal = ({
  strategyId,
  pico: {
    id,
    name = '',
    outcome = '',
    focusRationale = '',
    strategicImperativeId,
  },
}: FocusPicoSuggestionModalProps) => {
  const {
    modalSlideIdx,
    setModalSlideIdx,
    showSingleSlide,
  } = useModalWithSteps();
  const [state, dispatch] = React.useReducer(focusPicoSuggestionReducer, {
    name: name,
    isNameTouched: false,
    strategicImperativeId: strategicImperativeId,
    isStrategicImperativeTouched: false,
  });
  const nameIsValid = !!state?.name && state.name.length > 0;
  const strategicImperativeIsValid = !!state?.strategicImperativeId;
  const formIsValid = nameIsValid && strategicImperativeIsValid;

  const [
    fetchStrategicImperatives,
    { data, loading, error },
  ] = usePicoSuggestionStrategicImperativesLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [focusPicoSuggestionMutation] = usePicoSuggestionFocusMutation();

  const strategicImperatives = (data?.strategicImperatives?.items || []).map(
    ({ id, strategicPossibility: { name } }) => ({
      id: `${id}`,
      text: name,
    })
  );

  const handleSubmit = async (rationale: string) => {
    try {
      if (!state?.name) {
        Sentry.captureMessage('Focus Pico Suggestion Modal: No name', {
          level: 'warning',
          extra: state,
        });
        return;
      }

      if (!state?.strategicImperativeId) {
        Sentry.captureMessage(
          'Focus Pico Suggestion Modal: No strategicImperativeId',
          {
            level: 'warning',
            extra: state,
          }
        );
        return;
      }

      await focusPicoSuggestionMutation({
        variables: {
          id: id,
          data: {
            isFocused: true,
            name: state.name,
            rationale: rationale,
            strategicImperativeId: state.strategicImperativeId,
          },
        },
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  React.useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  React.useEffect(() => {
    if (modalSlideIdx === 0) {
      fetchStrategicImperatives({
        variables: {
          where: { strategyId, focus: true },
          include: { strategicPossibility: true },
        },
      });
    }
  }, [fetchStrategicImperatives, modalSlideIdx, strategyId]);

  if (modalSlideIdx === null) {
    return null;
  }

  return (
    <StyledRationaleModal
      modalDetails={{ Outcome: outcome }}
      modalSlideIdx={modalSlideIdx}
      setModalSlideIdx={setModalSlideIdx}
      handleClose={() => {
        setModalSlideIdx(null);
      }}
      handleSubmit={handleSubmit}
      rationaleText={focusRationale}
      showSingleSlide={showSingleSlide}
      disableNext={!formIsValid}
      firstSlideContent={
        <LoadingComponent isLoading={loading}>
          <div>
            <Subtitle1 style={{ textAlign: 'left', marginBottom: 10 }}>
              1. Delivery
            </Subtitle1>
            <BodyNormal style={{ textAlign: 'left' }}>
              Enter a short name and assign the PICO to a relevant strategic
              imperative.
            </BodyNormal>
            <FormContent>
              <StyledFormTextInput
                className="cypress-rationale-modal-textarea"
                name="Name"
                type="text"
                onChange={(e) => {
                  dispatch({ type: 'TOUCHED_NAME', isTouched: true });
                  dispatch({ type: 'SET_NAME', name: e.target.value });
                }}
                value={state.name}
                title={'Name'}
                errorMessage={
                  state.isNameTouched && !nameIsValid
                    ? INVALID_NAME_MESSAGE
                    : undefined
                }
              />

              <StrategicImperativeDropdown
                strategicImperativeId={
                  strategicImperativeId ? `${strategicImperativeId}` : undefined
                }
                strategicImperatives={strategicImperatives}
                isTouched={state.isStrategicImperativeTouched}
                onTouch={() =>
                  dispatch({
                    type: 'TOUCHED_STRATEGIC_IMPERATIVE',
                    isTouched: true,
                  })
                }
                onChange={(id: string) => {
                  dispatch({
                    type: 'TOUCHED_STRATEGIC_IMPERATIVE',
                    isTouched: true,
                  });
                  dispatch({
                    type: 'SET_STRATEGIC_IMPERATIVE_ID',
                    id: parseInt(id),
                  });
                }}
              />
            </FormContent>
          </div>
        </LoadingComponent>
      }
    />
  );
};
