import { uid } from 'uid';
import {
  StrategicImperativePicoSuggestionsQueryHookResult,
  useStrategicImperativePicoSuggestionsQuery,
} from 'data/graphql/generated';
import { PicoSuggestionRowData } from '../types/PicoSuggestionRowData';
import { DEFAULT_PICO_TEXT } from '../constants';

export const useFetchPicoSuggestionWithTactics = (
  strategicImperativeId: number,
  focused?: boolean
) => {
  const { data, loading, error } = useStrategicImperativePicoSuggestionsQuery({
    variables: {
      id: strategicImperativeId,
      picoSuggestionWhere: { picoSuggestionFocused: focused },
    },
    fetchPolicy: 'cache-and-network',
  });

  const picoSuggestions = sanitizePicoSuggestionWithTactic(data);

  return {
    isLoading: loading,
    error: error,
    picoSuggestions: picoSuggestions,
  };
};

const sanitizePicoSuggestionWithTactic = (
  data: StrategicImperativePicoSuggestionsQueryHookResult['data']
): PicoSuggestionRowData[] => {
  const items = !data?.strategicImperative?.picoSuggestions?.items;

  if (items) {
    return [];
  }

  const picoSuggestions: PicoSuggestionRowData[] = (
    data?.strategicImperative?.picoSuggestions?.items ?? []
  ).map((x) => ({
    id: x.id,
    name: x.name ?? DEFAULT_PICO_TEXT,
    population: x.population ?? DEFAULT_PICO_TEXT,
    intervention: x.intervention ?? DEFAULT_PICO_TEXT,
    comparison: x.comparison ?? DEFAULT_PICO_TEXT,
    outcome: x.outcome ?? DEFAULT_PICO_TEXT,
    tactic:
      (x.tactic && {
        id: x.tactic.id,
        strategyId: x.tactic.strategyId,
        competitiveAdvantageRowId: x.tactic.competitiveAdvantageRowId,
        localUid: x.tactic.localUid ?? uid(),
        region: x.tactic.region,
        tacticText: x.tactic.tacticText,
        audienceText: x.tactic.audienceText,
        timingStart: x.tactic.timingStart,
        timingEnd: x.tactic.timingEnd,
        dueDate: x.tactic.dueDate,
        responsibility: x.tactic.responsibility,
        category: x.tactic.category,
        budget: x.tactic.budget,
        collaboration: x.tactic.collaboration,
      }) ||
      undefined,
  }));

  return picoSuggestions;
};
