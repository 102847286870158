import React from 'react';
import styled from 'styled-components';

import { colors } from '../constants/';

const Wrapper = styled.button`
  position: relative;
  background: ${colors.white};
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 10px 20px 10px 20px;
`;

const Icon = styled.img`
  position: absolute;
  top: 5px;
  left: 8px;
`;

const Text = styled.span<{ icon?: string }>`
  color: ${colors.purple};
  font-family: ABCFavorit;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-left: ${({ icon }) => (icon ? 20 : 0)}px;
`;

interface Props {
  text: string;
  onClick(): void;
  disabled?: boolean;
  icon?: string;
  className?: string;
}

export const Button: React.FC<Props> = ({
  text,
  onClick,
  disabled = false,
  icon,
  className,
}) => {
  return (
    <Wrapper
      className={className}
      onClick={() => {
        if (!disabled && onClick) onClick();
      }}
      disabled={disabled}
    >
      {icon ? <Icon src={icon} /> : null}
      <Text icon={icon}>{text}</Text>
    </Wrapper>
  );
};
