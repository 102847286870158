// function from https://stackoverflow.com/a/15832662/512042
// https://stackoverflow.com/questions/21326053/javascript-trigger-click-a-link-in-ios-browser-not-working
export function downloadURI(uri: string, name: string) {
  const link = document.createElement('a');
  link.download = name;
  link.href = uri;
  link.target = '_blank';
  document.body.appendChild(link);

  // NOTE: This is for iOS support
  setTimeout(() => {
    const clickEvt = document.createEvent('MouseEvent');
    clickEvt.initEvent('click', false, true);
    link.dispatchEvent(clickEvt);
    // link.click();
    document.body.removeChild(link);
  }, 0);
}
