import { useEffect, useState } from 'react';

export function useVisibility() {
  const [visible, setVisible] = useState<DocumentVisibilityState>(
    document.visibilityState
  );

  useEffect(() => {
    function onVisibility(): void {
      setVisible(document.visibilityState);
    }
    document.addEventListener('visibilitychange', onVisibility);
    return () => document.removeEventListener('visibilitychange', onVisibility);
  }, []);

  return visible;
}
