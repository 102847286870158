import styled from 'styled-components/macro';
import { colors } from 'constants/colors';
import { BodySmall } from 'components/shared';

interface TitleLabelProps {
  title: string;
  isDesktop?: boolean;
}

export const TitleLabel = ({ title, isDesktop = false }: TitleLabelProps) => (
  <BodySmall color={colors.greyDark}>
    {title}
    {isDesktop && ':'}
  </BodySmall>
);

const StyledEmptyTitleText = styled.div`
  height: 20px;
`;

export const EmptyTitleText = () => <StyledEmptyTitleText />;

interface TitleTextProps {
  value: string;
}

export const TitleText = ({ value }: TitleTextProps) => {
  if (!value) {
    return <EmptyTitleText />;
  }

  return <BodySmall>{value}</BodySmall>;
};
