import { BodySmall } from 'components/shared';
import { colors } from 'constants/index';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';

const Labels = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Container = styled.div<{ $readOnly: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 2.5px;
  pointer-events: ${({ $readOnly }) => $readOnly && 'none'};
`;

const BG = styled.div`
  position: absolute;
  inset: 0;
  display: flex;

  overflow: hidden;
  padding: 0 3px;
  border-radius: 10px;
  height: fit-content;

  div {
    height: 5px;
    width: 100%;
  }
  div:first-child {
    background: linear-gradient(
      313.69deg,
      ${colors.purple} -83%,
      ${colors.darkRed} 76.34%
    );
  }
  div:last-child {
    background: linear-gradient(
      313.69deg,
      ${colors.purple} -83%,
      ${colors.greenDark} 76.34%
    );
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: calc(100% - 15px);
  margin: 0 auto;
`;

const Dots = styled.div`
  display: flex;
  justify-content: space-between;

  position: absolute;
  inset: 0;
  width: 100%;

  > div {
    background: ${colors.purple20};
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
`;

const SliderThumb = css<{ color: string }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: ${colors.purple};
`;

const SliderInput = styled.input<{ color: string }>`
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  z-index: 2;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  // thumb
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    ${SliderThumb}
    margin-top: -5px;
  }

  &::-moz-range-thumb {
    ${SliderThumb}
    transform: translate3d(0, -5px, 0);
    width: 15px;
    height: 13px;
  }

  &::-ms-thumb {
    ${SliderThumb}
  }
`;

type Props = {
  className?: string;
  value?: number | null | undefined;
  onMouseUp(value: number): void;
  readOnly?: boolean;
};

const Slider = ({ className, value, onMouseUp, readOnly = false }: Props) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    /* This should only update the local state if the previous value was null or undefined.
       This should prevent any jumping introduced by polling */

    setLocalValue((prevValue) => {
      if (!prevValue) {
        return value;
      }
      return prevValue;
    });
  }, [value]);

  return (
    <Container $readOnly={readOnly} className={className}>
      <BodySmall color={colors.greyDark} style={{ textAlign: 'center' }}>
        How close to addressing issue?
      </BodySmall>
      <Wrapper>
        <SliderInput
          onPointerUp={(e) => {
            if (readOnly) return;

            const element = e.target as HTMLInputElement;
            onMouseUp(Number(element.value));
          }}
          onChange={(e) => {
            if (readOnly) return;

            const element = e.target as HTMLInputElement;
            setLocalValue(Number(element.value));
          }}
          type="range"
          min="1"
          max="5"
          color="red"
          value={localValue || 3}
        />
        <BG>
          <div />
          <div />
        </BG>
        <Dots>
          <div />
          <div />
          <div />
          <div />
          <div />
        </Dots>
      </Wrapper>
      <Labels>
        <BodySmall color={colors.greyDark}>Far</BodySmall>
        <BodySmall color={colors.greyDark}>Close</BodySmall>
      </Labels>
    </Container>
  );
};

export default Slider;
