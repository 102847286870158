import { colors, zIndex } from 'constants/index';
import { useAuthContext } from 'contexts/AuthContext';
import {
  ObservationsQuery,
  Stakeholder,
  useStakeholderDefinitionsQuery,
  StakeholderDefinitionsQueryVariables
} from 'data/graphql/generated';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { Icon } from '.';
import { BodySmall, Subtitle2 } from './TextStyles';
import { device } from 'utils/breakpoints';
import ReactDOM from 'react-dom';
import { GradientBG } from './Observation';
import { verifyUserRole } from 'utils/verifyUserRole';
import { PatientList } from 'components/PatientList';
import { mapStakeholderEnumToSingularStakeholder } from 'utils/mapStakeholderEnumToSingularStakeholder';
import { polling } from 'constants/index';

interface Props {
  open: boolean;
  setOpen(state: boolean): void;
  refetchStakeholderDefinitions: ()=>void;
  data: ObservationsQuery | undefined;
  loading: boolean;
  stakeholder: Stakeholder;
  offsetTopOverride?: number;
  readonly?: boolean;
}

const Wrapper = styled.div<{
  open: boolean;
  offsetTop: number;
  offsetTopOverride: number | undefined;
}>`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: ${({ offsetTopOverride = 0 }) =>
    `calc(100vh -  50px - ${offsetTopOverride}px)`};
  top: 50px;
  background: linear-gradient(
      0deg,
      rgba(20, 20, 39, 0.05),
      rgba(20, 20, 39, 0.05)
    ),
    #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  right: ${({ open }) => (open ? '0px' : '-100%')};
  bottom: ${({ offsetTopOverride }) => (offsetTopOverride ? 0 + 'px' : 'auto')};

  z-index: ${zIndex.observationsMenu};

  transition: all 0.3s;

  @media ${device.tabletMin} {
    width: 430px;
  }

  ${GradientBG} {
    background: ${colors.white};
  }
`;

const Header = styled.div`
  width: 100%;
  height: 61px;
  background: ${colors.white};
  padding: 15px 15px 16px 20px;
  display: flex;
  > * + * {
    margin-left: 5px;
  }
  align-items: center;
  > div:last-child {
    cursor: pointer;
    margin-left: auto;
  }
  > p > span {
    color: ${colors.black50};
  }
`;

export const PatientsMenu = ({
  open,
  setOpen,
  data,
  refetchStakeholderDefinitions,
  stakeholder,
  offsetTopOverride,
  readonly,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const { drugId, strategyId } = useParams<{
    drugId: string;
    strategyId: string;
  }>();

  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);


  const stakeholderDefinitionsQueryVars: StakeholderDefinitionsQueryVariables = {
    where: { strategyId: Number(strategyId), stakeholder },
  };

  const {
    data: stakeholderDefinitionsData,
    loading: stakeholderDefinitionsLoading,
    startPolling,
    stopPolling,
  } = useStakeholderDefinitionsQuery({
    variables: {
      ...stakeholderDefinitionsQueryVars,
    },
  });
  
  useEffect(() => {
    startPolling(polling.default);
    return () => {
      stopPolling();
    };
  }, [
    startPolling,
    stopPolling,
  ]);
  
  useClickOutsideComponent(
    ref,
    (e) => {
      const selection = window.getSelection();
      const containsChild =
        selection?.focusNode && ref.current?.contains(selection.focusNode);

      !containsChild && setOpen(false);
    },
    ['stakeholderTab', 'modal']
  );


  if (!data) {
    return null;
  }

  const singularStakeHolder =
  mapStakeholderEnumToSingularStakeholder[stakeholder];

  return ReactDOM.createPortal(
    <Wrapper
      ref={ref}
      offsetTopOverride={offsetTopOverride}
      offsetTop={ref.current?.offsetTop || 0}
      open={open}
    >
      <Header>
     
        <Icon name="People" color={colors.black} size={30} />
        <Subtitle2>Target Population</Subtitle2>
        <div
          className="cypress-observations-close"
          onClick={() => {
            setOpen(false);
          }}
        >
          <Icon name="Collapse right" size={30} color={colors.purple} />
        </div>
      </Header>
      {readonly ? (
        <div style={{ width: '100%', padding: '15px', paddingBottom: 0 }}>
          <BodySmall color={colors.black}>
            
          </BodySmall>
        </div>
      ) : isLead ? (
        <div
          className="add-observation"
          style={{ width: 172, padding: '15px 0 0px 15px' }}
        >
          {/* <ButtonPill
            iconName="Plus"
            text="Add patient"
            clickClassName="cypress-observation-create"
            onClick={() => {
              if (!observations.includes(null))
                setObservations(() => {
                  const newArr = observations.slice();
                  newArr.unshift(null);
                  return newArr;
                });
            }}
          /> */}
        </div>
      ) : (
        <div style={{ width: '100%', padding: '15px', paddingBottom: 0 }}>
          <BodySmall color={colors.black}>
            Leads create observations to summarise contributions
          </BodySmall>
        </div>
      )}


    {!stakeholderDefinitionsLoading && stakeholderDefinitionsData ? (
        <PatientList
        drugId={drugId}
        refetchStakeholderDefinitions={refetchStakeholderDefinitions}
        strategyId={strategyId}
        data={stakeholderDefinitionsData}
        stakeholder={stakeholder}
        stakeholderTab={singularStakeHolder}
      />
      ) : (
        'Loading...'
      )}

    </Wrapper>,
    document.body
  );
};
