import { countries, globalContributor } from 'constants/index';

export const getCountryImageName = (country?: string) => {
  if (!country) return undefined;

  // Normalize the country string (trim whitespace, lowercase, replace spaces with hyphens)
  const formattedCountry = country.toLowerCase().trim().replace(/\s+/g, '-');

  return [
    { name: globalContributor, img: 'Global.svg' }, // Add global option
    ...countries
  ].find(({ name }) => name.toLowerCase().replace(/\s+/g, '-') === formattedCountry);
};