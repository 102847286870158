import { StatementFragment, SubStep } from '../data/graphql/generated';

export function getStatementText({
  statements,
  drugName,
}: {
  statements?: StatementFragment[] | undefined;
  drugName: string;
}) {
  const theWhoStatement = statements?.filter(
    (statement) => statement?.substep === SubStep.TheWho
  )?.[0]?.text;
  const firstStatement = theWhoStatement?.[0] || 'the brand target';
  const secondStatement = theWhoStatement?.[1] || 'want to overcome, or achieve something';
  const theWhoStatementSummaryText =
    drugName &&
    (theWhoStatement?.[0] || theWhoStatement?.[1]) &&
    `${drugName} is for ${firstStatement} who ${secondStatement}`;

  const whatItIsStatement = statements?.filter(
    (statement) => statement.title.toLowerCase() === 'what it is'.toLowerCase()
  )?.[0]?.text[0];
  const whatItIsStatementText =
    whatItIsStatement && drugName + ' is ' + whatItIsStatement;

  const benefitsStatement = statements?.filter(
    (statement) => statement.title.toLowerCase() === 'benefits'
  )[0]?.text;

  const benefitsStatementText =
    !!benefitsStatement?.length &&
    `${drugName} ${
      benefitsStatement[0] || 'provides a functional benefit'
    } so that ${benefitsStatement[1] || 'an emotional benefit.'}`;

  const reasonsToBelieveStatement = statements?.filter(
    (statement) =>
      statement.title.toLowerCase() === 'reasons to believe'.toLowerCase()
  )[0];

  const reasonsToBelieveStatementText = reasonsToBelieveStatement?.text?.filter(
    (v) => !!v
  );
  return {
    theWhoStatementSummaryText,
    whatItIsStatementText,
    benefitsStatementText,
    reasonsToBelieveStatementText,
  };
}
