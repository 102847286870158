import React from 'react';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import Routing from './Routing';
import apolloClient from 'data/apollo';
import { AuthProvider } from 'contexts/AuthContext';
import { StakeholderProvider } from 'contexts/StakeholderContext';
import { CollaborationProvider } from 'contexts/CollaborationContext';
import { HelpProvider } from 'contexts/HelpContext';
import { DropZoneProvider } from 'contexts/DropZoneContext';
import { ToastProvider } from 'contexts/ToastContext';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import './App.css';
import { CookieBanner } from 'containers/CookieBanner';
import { history } from './Routing';
import ErrorPage from 'components/ErrorPage';
import { StrategyProvider } from './contexts/StrategyContext';
import { GlobalProvider } from 'contexts/GlobalContext';

if (['production', 'staging'].includes(process.env.REACT_APP_BUILD_ENV || '')) {
  Sentry.init({
    release: 'web@' + process.env.npm_package_version,
    environment: process.env.REACT_APP_BUILD_ENV,
    dsn:
      'https://21f0e9f7ca3a4dd4b790d94948a45a4c@o54302.ingest.sentry.io/5764916',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
    normalizeDepth: 6,
  });
}

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage errorType={'generic'} />}>
      <ApolloProvider client={apolloClient}>
        <AuthProvider>
          <DndProvider
            backend={TouchBackend}
            options={{ enableMouseEvents: true }}
          >
            <DropZoneProvider>
              <StakeholderProvider>
              <GlobalProvider>
                <CollaborationProvider>
                  <HelpProvider>
                    <ToastProvider>
                      <StrategyProvider>
                        <Routing />
                      </StrategyProvider>
                    </ToastProvider>
                  </HelpProvider>
                </CollaborationProvider>
              </GlobalProvider>
              </StakeholderProvider>
            </DropZoneProvider>
          </DndProvider>
          <CookieBanner />
        </AuthProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
