import { useRef, useState } from 'react';
import { StyledFormTextInput } from 'components/Options/ImperativeModal';
import {
  Subtitle1,
  BodyNormal,
  ButtonPill,
  DatePicker,
  Icon,
} from 'components/shared';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { colors } from 'constants/index';
import { Input } from 'components/shared/FormTextInput';
import { monthNames } from 'utils/monthNames';
import { thousandSeparator } from 'utils/thousandSeparator';
import { device } from 'utils/breakpoints';

const StyledButtonPill = styled(ButtonPill)``;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-bottom: 10px;
  ${StyledButtonPill} {
    width: 180px;
  }
  @media ${device.mobile} {
    padding: 20px 15px;

    ${StyledButtonPill} {
      width: 100%;
    }
  }
`;

const CurrencyIcon = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  inset: 0;
  top: 15px;
  left: 10px;
`;

const DueTextInput = styled(StyledFormTextInput)`
  pointer-events: none;

  ${Input} {
    padding-left: 32px;
  }
`;

const BudgetTextInput = styled(StyledFormTextInput)`
  ${Input} {
    padding-left: 32px;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  position: absolute;
  inset: 0;
  height: fit-content;
  top: 50px;
  z-index: 1;
`;

interface ModalFields {
  lowerLimit: number[] | null | undefined;
  dueDate: number[] | null | undefined;
  responsibility: string;
  budget: string | null | undefined;
}

type Props = {
  handleClose(): void;
  currency: string;
  data: ModalFields;
  onUpdate(
    data: {
      dueDate: number[] | null | undefined;
      responsibility: string;
      budget: string | null | undefined;
    } | null
  ): void;
  showSingleSlide: boolean;
};

const ModalContent = ({ currency, data, onUpdate, showSingleSlide }: Props) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerRef = useRef<HTMLDivElement | null>(null);

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    errors,
    setSubmitting,
    touched,
    setFieldValue,
  } = useFormik({
    validateOnMount: true,
    initialValues: {
      dueDate: data.dueDate,
      responsibility: data.responsibility,
      budget: data.budget,
    },
    onSubmit: ({ dueDate, responsibility, budget }) => {
      setSubmitting(false);
    },
    validate: (values) => {
      const errors = {} as {
        dueDate: string;
        responsibility: string;
        budget: string;
      };

      if (!values.dueDate) {
        errors.dueDate = 'Required';
      }
      if (!values.responsibility.trim()) {
        errors.responsibility = 'Required';
      }
      if (!values.budget) {
        errors.budget = 'Required';
      }
      const errs = Object.keys(errors);

      if (!errs.length) {
        onUpdate(values);
      } else {
        onUpdate(null);
      }

      return errors;
    },
  });

  return (
    <Content>
      <div>
        <Subtitle1 style={{ textAlign: showSingleSlide ? 'center' : 'left' }}>
          {!showSingleSlide && '1. '}Delivery
        </Subtitle1>
        <BodyNormal
          style={{
            textAlign: showSingleSlide ? 'center' : 'left',
          }}
        >
          Enter the key information for delivering this tactic
        </BodyNormal>
      </div>

      <form
        onSubmit={handleSubmit}
        style={{ width: '100%' }}
        className="cypress-modal-form"
      >
        <div>
          <div ref={datePickerRef} style={{ position: 'relative' }}>
            <div
              onClick={() => {
                setShowDatePicker(!showDatePicker);
              }}
              style={{
                cursor: 'pointer',
              }}
            >
              <DueTextInput
                errorMessage={!!touched?.dueDate ? errors.dueDate : ''}
                name="dueDate"
                type="text"
                onChange={() => {}}
                value={
                  !!values?.dueDate?.length
                    ? [monthNames[values.dueDate[0]], values.dueDate[1]].join(
                        ' '
                      )
                    : ''
                }
                title="Due"
              />
              <Icon
                name="Calendar"
                size={20}
                color={colors.greyDark}
                style={{ position: 'absolute', inset: 0, left: 7 }}
              />
            </div>
            <StyledDatePicker
              lowerLimit={
                !!data?.lowerLimit?.length
                  ? { month: data?.lowerLimit[0], year: data?.lowerLimit[1] }
                  : undefined
              }
              date={
                !!values?.dueDate?.length
                  ? { month: values.dueDate[0], year: values.dueDate[1] }
                  : undefined
              }
              visible={showDatePicker}
              onUpdate={(date) => {
                setShowDatePicker(false);

                setFieldValue('dueDate', date ? [date.month, date.year] : []);
              }}
            />
          </div>
          <StyledFormTextInput
            errorMessage={!!touched.responsibility ? errors.responsibility : ''}
            name="responsibility"
            type="text"
            onChange={handleChange}
            value={values.responsibility}
            title="Responsibility"
            onBlur={handleBlur}
          />
          <div style={{ position: 'relative' }}>
            <BudgetTextInput
              errorMessage={!!touched.budget ? errors.budget : ''}
              name="budget"
              type="text"
              onChange={(e) => {
                setFieldValue('budget', e.target.value.replace(/[\D,]/g, ''));
              }}
              value={
                typeof values.budget === 'number' ||
                typeof values.budget === 'string'
                  ? thousandSeparator(values?.budget)
                  : ''
              }
              title="Budget"
              onBlur={handleBlur}
            />
            <CurrencyIcon>
              <BodyNormal color={colors.greyDark}>{currency[0]}</BodyNormal>
            </CurrencyIcon>
          </div>
        </div>
      </form>
    </Content>
  );
};

export default ModalContent;
