export const zIndex = {
  // highest
  versionCheckModal: 1400,
  cookieBanner: 1400,

  toasts: 1360,
  errorPage: 1300,

  notificationsFeed: 1281,
  notificationsBG: 1280,

  errorModal: 1270,

  patientJourneyTextArea: 1250,

  navSideMenu: 1220,
  navSideMenuBG: 1200,

  overlayModal: 1112,

  dialog: 1110,
  overlay: 1100,

  commercialTacticsSidebar: 1100,
  rationaleDropdown: 1110,
  touchPositioningOverlay: 1100,
  pinDragPreview: 1000,

  timelineCalloutCard: 1011,
  timelinePin: 1010,
  timelineCalloutBG: 1000,

  countriesDropdownSmall: 1000,
  nav: 1000,
  pageModal: 990,

  observationsDragLayer: 990,

  observationsMenu: 980,
  reusableSidebarDefault: 970,
  imperativeSummarySidebar: 960,

  btnExpandOpenText: 910,
  btnExpandOpen: 900,

  bigIdeaSidebar: 900,
  observationsMenuOverlay: 900,

  observationsBtn: 820,

  secondaryNav: 820,

  financialSummaryCountryDropdown: 800,
  tacticCallout: 819,
  gridOverlay: 815,
  draggablePin: 810,

  patientCanvasTextArea: 800,
  patientCanvasTextAreaBG: 790,

  draggablePinSmallerScreens: 790,
  commercialTacticsSidebarDesktop: 700,

  postIts: 610,

  commentDelete: 510,
  commentReplyDelete: 500,

  chooseCompetitorsBar: 200,

  bubbleSummary: 8,
  bubbleSummaryBG: 7,

  datePickerDropdown: 7,
  // lowest
};
