import React, { useRef } from 'react';
import styled from 'styled-components/macro';

import { colors } from 'constants/colors';
import { zIndex } from 'constants/zIndex';
import { BigIdeaFragment } from 'data/graphql/generated';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { device } from 'utils/breakpoints';
import { BigIdeaSidebarContent } from './BigIdeaSidebarContent';

const SideBar = styled.div<{ visible: boolean }>`
  position: fixed;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scrollbar-gutter: stable;
  top: 110px;
  right: 0px;
  bottom: 0px;
  width: 554px;
  display: block;
  background: white;
  border-left: 1px solid ${colors.greyMedium};
  padding: 20px;
  box-shadow: 0px 10px 20px 0px #0000001a, 0px 1px 5px 0px #0000000d;
  transform: ${({ visible }) =>
    visible ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0, 0)'};
  transition: transform 0.3s;
  z-index: ${zIndex.bigIdeaSidebar};

  @media ${device.tabletMax} {
    top: 160px;
  }

  @media (max-width: 570px) {
    width: 100vw;
  }
`;

interface Props {
  visible: boolean;
  bigIdea?: BigIdeaFragment;
  handleClose(): void;
  strategyId: string;
  bigIdeasRefetch(): void;
  countryTacticsEnabled: boolean;
}

export const BigIdeaSidebar: React.FC<Props> = ({
  visible,
  bigIdea,
  handleClose,
  strategyId,
  bigIdeasRefetch,
  countryTacticsEnabled,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useClickOutsideComponent(
    ref,
    (e) => {
      const selection = window.getSelection();
      const containsChild =
        selection?.focusNode && ref.current?.contains(selection.focusNode);

      !containsChild && handleClose();
    },
    ['bigidea-create', 'rationale-dropdown', 'focus-rationale-modal']
  );

  return (
    <SideBar ref={ref} visible={visible}>
      {visible ? (
        <BigIdeaSidebarContent
          bigIdea={bigIdea}
          handleClose={handleClose}
          strategyId={strategyId}
          bigIdeasRefetch={bigIdeasRefetch}
          countryTacticsEnabled={countryTacticsEnabled}
        />
      ) : null}
    </SideBar>
  );
};
