import { FetchResult } from '@apollo/client/link/core/types';
import {
  FileModel,
  PostItCard,
  PostItCardCreateMutation,
  RequestFileUploadMutationFn,
} from 'data/graphql/generated';
import { usePostItCardsPayload } from 'hooks/usePostItCards';
import { FileRejection } from 'react-dropzone';
import { PostItGroupAndCards } from 'types';

interface Props {
  setIsDragOver: (value: React.SetStateAction<boolean>) => void;
  setErrMsg: (
    value: React.SetStateAction<{
      id: number;
      message: string;
      cardHasImage: boolean;
    }>
  ) => void;
  errMsg: {
    id: number;
    message: string;
    cardHasImage: boolean;
  };
  editCardImage: PostItCard | null;
  group: PostItGroupAndCards;
  addCard(): Promise<
    | FetchResult<
        PostItCardCreateMutation,
        Record<string, any>,
        Record<string, any>
      >
    | undefined
  >;
  fileRejections: FileRejection[];
  setUploading: (value: React.SetStateAction<number>) => void;
  acceptedFiles: File[];
  requestFileUpload: RequestFileUploadMutationFn;
  uploadFile: (files: File[], presignedPostData: any) => Promise<unknown>;
  setCardImageToEdit: (value: React.SetStateAction<PostItCard | null>) => void;
  updateCard: usePostItCardsPayload['updateCard'];
  removeCard: usePostItCardsPayload['removeCard'];
}

export async function onDropHandler({
  setIsDragOver,
  setErrMsg,
  errMsg,
  editCardImage,
  group,
  addCard,
  fileRejections,
  setUploading,
  acceptedFiles,
  requestFileUpload,
  uploadFile,
  setCardImageToEdit,
  updateCard,
  removeCard,
}: Props) {
  setIsDragOver(false);

  if (!group.strategyId) {
    throw Error('Upload failed');
  }

  //Create card, then upload image and then update card with image
  let card = undefined as PostItCard | undefined | null;
  const cardStillInGroup = group.cards.some((c) => c?.id === editCardImage?.id);

  try {
    if (
      //if there is an error and the card has no image, we are replacing the image for a card with a failed upload
      (errMsg.id === editCardImage?.id &&
        !editCardImage?.image &&
        cardStillInGroup) ||
      //if there is no error and the card has an image, we are replacing the image on an existing card
      (editCardImage && editCardImage.image && errMsg.id !== editCardImage?.id)
    ) {
      card = editCardImage;
    } else {
      let response = await addCard();
      setErrMsg({ id: -1, message: '', cardHasImage: false });

      card = response?.data?.postItCardCreate as PostItCard;
    }

    if (!card) {
      throw Error('Upload failed');
    }

    //If the card being edited is not the same as the card that has an error
    //Check if the error card has no image. If it does, do nothing, else delete it
    if (errMsg.id > 0 && errMsg.id !== card.id && !errMsg.cardHasImage) {
      try {
        await removeCard(errMsg.id);
      } catch (error) {
        throw Error('Upload failed');
      }
    }

    //handle file fileRejections
    const err = fileRejections[0];
    const firstError = err?.errors[0];

    if (firstError) {
      if (firstError.code.includes('file-invalid-type')) {
        throw Error('file-invalid-type');
      }
      if (firstError.code.includes('file-too-large')) {
        throw Error('file-too-large');
      }
    }

    if (errMsg.id === card?.id) {
      setErrMsg({ id: -1, message: '', cardHasImage: false });
    }

    setUploading(card?.id);

    const file = acceptedFiles[0];

    if (!file) {
      throw Error('Upload failed');
    }

    const { data } = await requestFileUpload({
      variables: {
        data: {
          strategyId: group.strategyId,
          model: FileModel.PostItCard,

          modelId: card?.id,

          name: file.name,
        },
      },
    });

    if (!data?.requestFileUpload) {
      throw Error('Upload failed');
    }

    await uploadFile(acceptedFiles, data.requestFileUpload);
    if (card !== null) {
      await updateCard({
        ...card,
        image: `${data.requestFileUpload.url}/${data.requestFileUpload.fields.key}`,
      });
    }

    setCardImageToEdit(null);
  } catch (error) {
    setCardImageToEdit(card || null);

    if (JSON.stringify(error).includes('exceeds the maximum allowed size')) {
      return setErrMsg({
        id: card?.id || -1,
        message: 'File too big',
        cardHasImage: !!card?.image,
      });
    }

    if (card) {
      if (error instanceof Error) {
        if (error.message.includes('file-invalid-type')) {
          return setErrMsg({
            id: card?.id,
            message: 'Invalid file',
            cardHasImage: !!card?.image,
          });
        }

        if (error.message.includes('file-too-large')) {
          return setErrMsg({
            id: card?.id,
            message: 'File too big',
            cardHasImage: !!card?.image,
          });
        }
      }
    }

    setErrMsg({
      id: card?.id || -1,
      message: 'Upload failed',
      cardHasImage: !!card?.image,
    });
  } finally {
    setUploading(-1);
  }
}
