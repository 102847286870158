import { CriticalMetricGoalFrequency } from 'data/graphql/generated';

export const convertGoalFrequencyEnumToText = (
  apiGoalFrequency?: CriticalMetricGoalFrequency | null
) => {
  switch (apiGoalFrequency) {
    case CriticalMetricGoalFrequency.Monthly:
      return 'Monthly';
    case CriticalMetricGoalFrequency.Quarterly:
      return 'Quarterly';
    case CriticalMetricGoalFrequency.Annually:
      return 'Annually';
    default:
      return undefined;
  }
};
