import { zIndex } from 'constants/index';
import {
  Stakeholder,
  Step,
  StakeholderDefinitionFragment,
  PostItCard,
  usePostItCardsQuery,
  PostItCardType,
} from 'data/graphql/generated';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { SubMenu } from 'components/shared';
import { OutcomesMenu } from './shared/OutcomesMenu';
import { usePostItCards } from 'hooks/usePostItCards';
import { ErrorModal } from './ErrorModal';
import { sortPostItsOutcomes } from 'hooks';
import { usePostItGroups } from 'hooks/usePostItGroups';
import { useOutcomesCardMove } from 'hooks/useOutcomesCardMove';

interface Props {
  stakeholder?: Stakeholder;
  offsetTopOverride?: number;
  refetchGroupsWithCards(): void;
  selectedStakeholderDefinition?: StakeholderDefinitionFragment;
  hasNoSelectedCompetitor: boolean;
  hasNoTargetPopulation: boolean;
}

const OutcomesButtonWrapper = styled.div<{ open: boolean }>`
  display: flex;
  z-index: ${zIndex.observationsBtn};

  opacity: ${({ open }) => Number(!open)};
  pointer-events: ${({ open }) => (open ? 'none' : 'all')};
  transition: all 0.1s;
`;

interface URLParams {
  drugId: string;
  strategyId: string;
  stakeholder: Stakeholder;
  stakeholderDefinitionId: string;
  competitorId: string;
}

export const Outcomes = ({
  offsetTopOverride,
  refetchGroupsWithCards,
  hasNoSelectedCompetitor,
  hasNoTargetPopulation,
  selectedStakeholderDefinition
}: Props) => {
  const {
    drugId,
    strategyId,
    stakeholder,
    stakeholderDefinitionId,
    competitorId,
  }: URLParams = useParams();

  const [open, setOpen] = useState(false);
  const [deleteErrorModal, setDeleteErrorModal] = useState(false);

  const {
    createCard,
    updateCard,
    removeCard,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Keystakeholders,
        // stakeholderDefinitionId: Number(stakeholderDefinitionId),
        // competitorId: Number(competitorId),
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Keystakeholders,
      // stakeholderDefinitionId: Number(stakeholderDefinitionId),
    }
  );

  // get "What are the outcomes that matter?" cards to sort the groups
  const {
    data: groupsWithCardsData,
    // error,
    refetch: refetchCards,
    loading: groupsWithCardsLoading,
  } = usePostItCardsQuery({
    variables: {
      where: {
        strategyId: Number(strategyId),
        step: Step.Keystakeholders,
        // competitorId: Number(competitorId),
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const {
    items: groupItems,
    loading,
  } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Keystakeholders,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Keystakeholders,
    }
  );

  // for "What are the outcomes that matter to" PostItGroups for Patients, HPC and Payors
  const postItGroups = useMemo(() => sortPostItsOutcomes(groupItems, groupsWithCardsData?.postItCards?.items || []), [groupItems, groupsWithCardsData]);

  // get all cards from PostItGroups
  const allCards = useMemo(() => postItGroups.flatMap(e => e.cards), [postItGroups]) as PostItCard[];
  const selectedPostItGroupId = useMemo(() => postItGroups?.filter(e => e.stakeholder === stakeholder)?.[0]?.id, [postItGroups, stakeholder])

  const mappedCards = useMemo(() => {
    if (!stakeholderDefinitionId) return { includes: [], excludes: [] };

    // Filter and sort include cards
    const includes = allCards
      .filter(card =>
        card.include &&
        card.stakeholder === stakeholder &&
        card.stakeholderDefinitionId === +stakeholderDefinitionId &&
        card.type === PostItCardType.Plain
      )
      .sort((a, b) => b.pos - a.pos) as PostItCard[];

    // Filter and sort exclude cards
    const excludes = allCards
      .filter(card =>
        (card.include === false && card.type === PostItCardType.Outcome) ||
        card.stakeholder !== stakeholder
      )
      .filter(excludedItem =>
        !includes.some(includedItem => includedItem.title === excludedItem.title)
      )
      .sort((a, b) => b.pos - a.pos) as PostItCard[];

    // Remove duplicate titles from excludes
    const filteredIncludes = excludes.filter((item, index, self) =>
      self.findIndex(excludedItem => excludedItem.title === item.title) === index
    );

    return { includes, excludes: filteredIncludes };
  }, [allCards, stakeholder, stakeholderDefinitionId]);


  const { onChange, loading: movingOrSwapping } = useOutcomesCardMove(
    mappedCards,
    updateCard,
    createCard,
    refetchGroupsWithCards,
    refetchCards,
    stakeholder,
    stakeholderDefinitionId,
    competitorId,
    selectedPostItGroupId
  );

  return (
    <>
      <OutcomesButtonWrapper open={false}>
        <SubMenu
          icon="Target"
          text="Outcomes"
          onClick={() => {
            setOpen(true);
          }}
        />
      </OutcomesButtonWrapper>
      
      <OutcomesMenu
        readonly
        loading={groupsWithCardsLoading || loading}
        hasNoSelectedCompetitor={hasNoSelectedCompetitor}
        hasNoTargetPopulation={hasNoTargetPopulation}
        onChange={onChange}
        addCard={createCard}
        updateCard={updateCard}
        removeCard={async (vals) => {
          try {
            return await removeCard(vals);
          } catch (err) {
            if (err instanceof Error && (err.message === 'DELETE_ERROR' || err.message === 'Card Something went wrong')) {
              setDeleteErrorModal(true);
            }
          }
        }}
        refetch={() => {
          refetchGroupsWithCards();
          refetchCards();
        }}
        selectedStakeholderDefinition={selectedStakeholderDefinition}
        groups={mappedCards}
        selectedPostItGroupId={selectedPostItGroupId}
        setOpen={setOpen}
        open={open}
        offsetTopOverride={offsetTopOverride}
        movingOrSwapping={movingOrSwapping}
      />

      <ErrorModal
        visible={deleteErrorModal}
        handleClose={() => setDeleteErrorModal(false)}
        title="Cannot delete this outcome"
        text="Content in one or more stakeholders depends on this outcome. You may move it to “Not included”."
      />
    </>
  );
};