import { colors } from 'constants/index';
import React from 'react';
import styled, { css } from 'styled-components/macro';

import { IconName } from 'types';
import { BodySmall, Caption, Icon } from './shared';
import { ButtonArrow } from './shared/ButtonArrow';

export const Wrapper = styled.div<{
  top?: number;
  left?: number;
  isMiniEnabled: boolean;
}>`
  margin-bottom: 30px;
  ${({ isMiniEnabled }) =>
    isMiniEnabled &&
    css`
      pointer-events: none;
    `}

  ${({ top, left }) =>
    !!top && !!left
      ? css`
          @media screen and (min-width: 945px) {
            position: absolute;
            top: ${top}px;
            left: ${left}px;
          }
        `
      : css`
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
        `}
`;

export const InYourPlanLabel = styled.button`
  color: ${colors.white};
  display: inline-flex;
  align-items: center;
  font-size: 10px;
  padding: 5px;
  gap: 5px;
  position: absolute;
  top: 26px;
  margin-left: 20px;
  pointer-events: none;
  border-radius: 5px;
  border: none;
  background: var(
    --Green-Purple,
    linear-gradient(314deg, #7800ff -83%, #078766 76.34%)
  );
`;

interface Props {
  text: string;
  subtext: string;
  top?: number;
  left?: number;
  link?: string;
  iconName: IconName;
  reversed?: boolean;
  className?: string;
  iconText: string | number;
  hasProduct: boolean;
  isMiniEnabled?: boolean;
}

export const StrategyStep: React.FC<Props> = ({
  text,
  subtext,
  top,
  left,
  link = '#',
  iconName,
  reversed,
  className,
  iconText,
  isMiniEnabled = false,
  hasProduct,
}) => {
  return (
    <Wrapper
      className="step"
      isMiniEnabled={isMiniEnabled}
      top={top}
      left={left}
    >
      <ButtonArrow
        className={className}
        reversed={reversed}
        text={''}
        name={iconName}
        link={link}
        iconColor={
          iconName === 'Step Complete' && !isMiniEnabled
            ? colors.purple
            : colors.purple10
        }
        iconText={iconText}
        iconTextColor={
          iconName === 'Step Complete' && !isMiniEnabled
            ? colors.white
            : colors.black
        }
      >
        <div style={{ textAlign: reversed ? 'right' : 'left' }}>
          <BodySmall>{text}</BodySmall>
          <Caption color={colors.greyDark}>{subtext}</Caption>
        </div>

        {hasProduct && isMiniEnabled && (
          <InYourPlanLabel>
            <Icon name="Checkcircle-on-white" size={15} />
            In your plan
          </InYourPlanLabel>
        )}
      </ButtonArrow>
    </Wrapper>
  );
};