import { MedicalStrategyTabNames } from 'components/4-2-medical-strategy/shared/data-access-medical-strategy';
import { getTooltipText } from 'components/4-2-medical-strategy/shared/util/src';
import { KeyStatements } from 'components/shared';
import { CountriesDropdownSmall } from 'components/shared/CountriesDropdownSmall';
import {
  CompetitiveAdvantageRowFragment,
  KeyStatementFragment,
  KeyStatementsDocument,
  Step,
  SubStep,
  useKeyStatementUpsertMutation,
  useKeyStatementDeleteMutation,
  useKeyStatementUpdateMutation,
  User,
} from 'data/graphql/generated';
import { camelCase, kebabCase } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Country, CountryGlobal } from 'types';
import {
  apolloCreateHelper,
  apolloDeleteHelper,
} from 'utils/apolloQueryHelpers';

type Props = {
  countryTacticsEnabled: boolean;
  viewingOwnRegion: boolean;
  selectedRegion: CountryGlobal;
  countryDropdownActive: boolean;
  setCountryDropdownActive: React.Dispatch<React.SetStateAction<boolean>>;

  countryDropDownRef: React.MutableRefObject<null>;

  drugId: string;
  strategyId: string;
  activeSubStep: string;
  summaryPage: boolean;
  competitiveAdvantageRows: CompetitiveAdvantageRowFragment[];
  activeStrategicImperativeId: number;
  history: ReturnType<typeof useHistory>;
  regionsExcludingGlobal: Country[];
  subStep: string;
  isLead: boolean;
  user: User | undefined;

  upsertKeyStatement: ReturnType<typeof useKeyStatementUpsertMutation>[0];
  keyStatementsQueryVars: {
    where: {
      strategyId: number;
      step: Step;
      subStep: SubStep;
      competitiveAdvantageRowId: number;
      region: CountryGlobal | undefined;
    };
  };
  deleteKeyStatement: ReturnType<typeof useKeyStatementDeleteMutation>[0];
  updateKeyStatement: ReturnType<typeof useKeyStatementUpdateMutation>[0];
  keyStatements: KeyStatementFragment[];
};

const KeyMedicalObjectives = ({
  countryTacticsEnabled,

  viewingOwnRegion,
  selectedRegion,
  countryDropdownActive,
  setCountryDropdownActive,
  countryDropDownRef,
  drugId,
  strategyId,
  activeSubStep,
  summaryPage,
  competitiveAdvantageRows,
  activeStrategicImperativeId,
  history,
  regionsExcludingGlobal,
  subStep,
  user,
  upsertKeyStatement,
  keyStatementsQueryVars,
  deleteKeyStatement,
  updateKeyStatement,
  keyStatements,
  isLead,
}: Props) => {
  return (
    <KeyStatements
      overrideTooltip={getTooltipText({
        isLead,
        selectedRegion,
        viewingOwnRegion,
      })}
      isLead={true} // isLead is true to override KeyStatement permissions logic tied to user role
      style={{ width: '100%' }}
      title="Key Medical Objectives"
      placeholder={
        viewingOwnRegion
          ? 'Type a key medical objective'
          : 'None identified yet'
      }
      marketRepPlaceholder="None yet. A Lead will add key medical objectives here."
      viewingOtherRegion={!viewingOwnRegion}
      showCountryDropdown={countryTacticsEnabled}
      CountryDropdown={
        <CountriesDropdownSmall
          country={selectedRegion}
          active={countryDropdownActive}
          onClick={() => setCountryDropdownActive(!countryDropdownActive)}
          viewOnly={false}
          hideCountryName={true}
          largeIcon
          globalOptionText={'Global plan'}
          dropDownRef={countryDropDownRef}
          showDropdown={countryDropdownActive}
          setShowDropdown={setCountryDropdownActive}
          setCountry={(country) => {
            let baseURL = `/d/${drugId}/strategy/${strategyId}/4_2/${activeSubStep}`;

            if (!summaryPage && !!competitiveAdvantageRows?.length) {
              baseURL += `/${activeStrategicImperativeId}`;

              if (
                activeSubStep === MedicalStrategyTabNames.MEDICAL_OBJECTIVES
              ) {
                baseURL += `/${kebabCase(country as CountryGlobal)}`;
              }
            }

            history.replace(baseURL);
          }}
          allCountries={regionsExcludingGlobal}
        />
      }
      addStatement={({ text, localUid }) => {
        if (!viewingOwnRegion) return;

        const itemData = {
          localUid,
          text,
          title: 'Key Medical Objectives',
          strategyId: Number(strategyId),
          step: Step.MedicalStrategy,
          subStep: camelCase(subStep) as SubStep,
          competitiveAdvantageRowId: +activeStrategicImperativeId,
          tactics: [],
          region: isLead ? 'global' : user?.country,
        };

        const id = Math.ceil(Math.random() * 20000);

        upsertKeyStatement({
          variables: {
            data: {
              ...itemData,
            },
          },
          optimisticResponse: {
            keyStatementUpsert: {
              ...itemData,
              id,
              lastUpdated: new Date().toISOString(),
              createdAt: new Date().toISOString(),
              __typename: 'KeyStatement',
              focus: false,
              focusRationale: '',
              collaboration: null,
              accessStrategyPrioritiseSupportingMessages: [],
              accessStrategyEndpointTargets: [],
            },
          },
          update: apolloCreateHelper({
            responseField: 'keyStatementUpsert',
            query: KeyStatementsDocument,
            queryName: 'keyStatements',
            queryVars: keyStatementsQueryVars,
          }),
        });
      }}
      deleteStatement={(keyStatement) => {
        if (!viewingOwnRegion) return;

        deleteKeyStatement({
          variables: { localUid: keyStatement.localUid },
          optimisticResponse: {
            keyStatementDelete: keyStatement,
          },
          update: apolloDeleteHelper({
            responseField: 'keyStatementDelete',
            query: KeyStatementsDocument,
            queryVars: keyStatementsQueryVars,
            queryName: 'keyStatements',
          }),
        });
      }}
      updateStatement={({ localUid, text }) => {
        if (!viewingOwnRegion) return;
        updateKeyStatement({ variables: { localUid, data: { text } } });
      }}
      data={keyStatements}
    />
  );
};

export default KeyMedicalObjectives;
