import { BodySmall, Icon } from 'components/shared';
import { colors, zIndex } from 'constants/index';
import useDesktop from 'hooks/useDesktop';
import { DebouncedFunc } from 'lodash';
import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components/macro';
import { Callout } from './Callout';
import { TimelineData } from './LongTermStrategySummaryTimeline';

const PinWrapper = styled.div<{ active: boolean; zIndex: number }>`
  position: absolute;
  inset: 0;
  cursor: pointer;
  z-index: ${({ zIndex }) => zIndex};

  &:hover {
    z-index: ${zIndex.timelinePin};
    svg path {
      stroke: white;
    }
  }

  ${({ active }) => {
    if (active)
      return css`
        z-index: ${zIndex.timelinePin};

        svg path {
          stroke: white;
        }
      `;
  }}
`;

const PinIcon = styled(Icon)`
  transform: translateY(-6px) rotate(-135deg);
  z-index: 1;
`;

type Props = {
  pinTitle: string | number;
  x: number;
  data: TimelineData[];
  containerWidth: number;
  handleMouseEnter: () => void;
  debouncedHandleOnMouseLeave: DebouncedFunc<() => void> | null;
  isHovered: boolean;
  setIsHovered(): void;
  onClick(): void;
  zIndex: number;
};

function Pin({
  pinTitle,
  x,
  data,
  containerWidth,
  isHovered,
  handleMouseEnter,
  debouncedHandleOnMouseLeave,
  setIsHovered,
  onClick,
  zIndex,
}: Props) {
  const isDesktop = useDesktop();

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [offsetX, setOffsetX] = React.useState<undefined | number>(undefined);

  useEffect(() => {
    if (!wrapperRef.current) return;
    const calloutWidth = 325;
    const pinWidth = 30;

    const offsetLeft = wrapperRef.current?.offsetLeft;

    const overlapOnRight =
      offsetLeft + pinWidth > containerWidth - calloutWidth / 2;

    if (overlapOnRight) {
      // position to the left
      setOffsetX((calloutWidth - pinWidth) * -1);
    } else {
      // position to the right
      setOffsetX(0);
    }
  }, [containerWidth, isHovered]);

  const activeMobilePin = isHovered && !isDesktop;

  useEffect(() => {
    if (activeMobilePin) {
      wrapperRef.current?.scrollIntoView({ inline: 'center' });
    }
  }, [activeMobilePin]);

  return (
    <PinWrapper
      active={activeMobilePin}
      zIndex={zIndex}
      ref={wrapperRef}
      style={{ left: x }}
    >
      {offsetX !== undefined && isHovered && isDesktop && (
        <Callout
          offsetX={offsetX}
          onMouseEnter={() => {
            debouncedHandleOnMouseLeave?.cancel?.();
            setIsHovered();
          }}
          onMouseLeave={() => {
            debouncedHandleOnMouseLeave?.();
          }}
          data={data}
        />
      )}

      <PinIcon name="PinSolid" size={30} color={colors.purple10} />
      <span
        onClick={() => {
          if (isDesktop) return;
          onClick();
        }}
        onMouseEnter={() => {
          debouncedHandleOnMouseLeave?.cancel?.();
          handleMouseEnter();
        }}
        onMouseLeave={() => {
          debouncedHandleOnMouseLeave?.();
        }}
        style={{
          transform: 'translateY(-6px)',
          position: 'absolute',
          inset: 0,
          width: 30,
          height: 30,
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <BodySmall color={colors.white}>{pinTitle}</BodySmall>
      </span>
    </PinWrapper>
  );
}

export default Pin;
