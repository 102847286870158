import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import Textarea from 'react-expanding-textarea';
import { debounce, throttle } from 'lodash';

import {
  CountryFlag,
  Collaboration,
  ButtonRound,
  Icon,
  ButtonLabel,
  TooltipWrapper,
  Caption,
} from 'components/shared';
import { colors } from 'constants/colors';
import { useIsMounted } from 'hooks/useIsMounted';
import { CardType } from 'types';
import { BodyNormal } from 'components/shared';
import { getIconFromNoteType } from 'utils/getIconFromNoteType';
import { getTitleFromNoteType } from 'utils/getTitleFromNoteType';
import {
  PostItCardFragment,
  PostItCardType,
  Role,
} from 'data/graphql/generated';
import { device } from 'utils/breakpoints';
import { CharacterLimitProgressDonut } from './shared/CharacterLimitProgressDonut';
import { TooltipCSS } from './shared/Tooltip';
import { postItCardWidths } from 'constants/index';
import useMobile from 'hooks/useMobile';
import { getUserTitleOrCountryAndName } from 'utils/getUserTitleOrCountryAndName';
import { verifyUserRole } from 'utils/verifyUserRole';

const CharacterLimitProgressDonutWrapper = styled.div<{ visible: boolean }>`
  position: absolute;
  left: 45px;
  bottom: 15px;
  z-index: 10;
  width: 25px;
  height: 25px;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ visible }) => Number(visible)};
  transition: opacity 0.3s;
  @media ${device.mobile} {
    left: 15px;
    bottom: 15px;
  }
`;

const ImageCardWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: relative;
`;

const UploadingWrapper = styled.div`
  width: 100%;
  height: 88%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
`;

const leftToRight = keyframes`
  from {
    transform:translateX(-100px);
  }
  to {
    transform:translateX(100px);
  }
`;

const AnimatingLoadingBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  animation: ${leftToRight} 2s forwards linear infinite;
  > .frame {
    width: 29px;
    overflow: hidden;
    margin-left: auto;
    > div {
      position: relative;
      top: 0;
      left: -71px;
      right: 0;
      animation: ${leftToRight} 2s forwards linear infinite reverse;
    }
  }
`;

const ImageWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const CardImage = styled.div<{ imageURL: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: ${({ imageURL }) => `url(${imageURL})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const CardImageBG = styled.div<{ imageURL: string }>`
  background-image: ${({ imageURL }) => `url(${imageURL})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1.5);
  filter: blur(15px);

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const ConfirmationNotificationCTA = styled.div`
  display: flex;
  justify-content: space-around;
`;

export function getCardColor(type: PostItCardType): string {
  switch (type) {
    case 'plain':
      return colors.yellow;
    case 'advantage':
      return colors.green30;
    case 'limitation':
    case PostItCardType.Hurdle:
      return colors.red20;
    case 'unexpected':
      return colors.purple20;
    case 'pattern':
    case PostItCardType.Gap:
      return colors.blue20;
    case 'trigger':
    case 'feelings':
      return colors.pink20;
    case 'adaptation':
      return colors.orange20;
    case 'leadIndicator':
    case 'thoughts':
      return colors.blue20;
    case 'lagIndicator':
      return colors.orange20;
    default:
      return colors.yellow;
  }
}

const Wrapper = styled.div<{
  color: string;
  cardType: CardType;
  disabled: boolean;
}>`
  user-select: none;
  background: ${({ color }) => color};
  vertical-align: top;
  width: ${postItCardWidths.max}px;

  height: ${({ cardType }) =>
    ['plain', 'image'].includes(cardType) ? 220 : 240}px;
  display: inline-block;

  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  position: relative;

  @media (max-width: 480px) {
    width: ${postItCardWidths.min}px;
  }

  ${({ cardType }) => {
    if (cardType === 'image') {
      return css`
        margin: 0;
      `;
    }
  }}
`;

function disableElement(disabled: boolean) {
  return disabled
    ? css`
        opacity: 0.3;
        pointer-events: none;
      `
    : css`
        opacity: 1;
        pointer-events: all;
      `;
}

const FooterFlag = styled.div<{ disabled: boolean; visible: boolean }>`
  position: absolute;
  left: 15px;
  bottom: 15px;
  z-index: 10;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  ${({ disabled }) => disableElement(disabled)}
  display: flex;
  align-items: center;

  @media ${device.mobile} {
    display: ${({ visible }) => (visible ? 'flex' : 'none')};
  }
`;

const FooterCollaboration = styled.div<{ disabled: boolean }>`
  position: absolute;
  right: 15px;
  bottom: 15px;
  ${({ disabled }) => disableElement(disabled)}
  ${({ disabled }) => {
    if (disabled) {
      return css`
        pointer-events: auto;
        cursor: not-allowed;

        > div {
          background: transparent;
          &:hover {
            background: transparent;
          }
        }
      `;
    }
  }}
`;

const StyledCollaboration = styled((props) => <Collaboration {...props} />)<{
  imageCard: boolean;
  disabled: boolean;
}>`
  ${({ imageCard }) => {
    if (imageCard) {
      return css`
        background: ${colors.white70};
        transition: background 0.3s;

        &:hover {
          background: ${colors.white90};
        }
      `;
    }
  }}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

const Content = styled.div<{
  focused: boolean;
  color: string;
  cardType: CardType;
  disabled: boolean;
}>`
  display: block;
  margin: ${({ cardType }) =>
    cardType === 'plain' ? '15px 15px 45px 15px' : '0px 15px 0px 15px'};
  border: 0.5px solid ${({ focused, color }) => (focused ? colors.blue : color)};
  height: 160px;

  ${({ cardType }) => {
    if (cardType === 'image') {
      return css`
        height: 100%;
        margin: 0;
        border: none;
      `;
    }
  }}

  pointer-events: ${({ disabled }) => disabled && 'none'};
`;

const IconsEditDelete = styled.div<{ visible: boolean }>`
  position: absolute;
  top: -10px;
  right: -7px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const DeleteIcon = styled(ButtonRound)`
  margin-bottom: 5px;
`;

const TextareaWrapper = styled.div`
  display: table-cell;
  vertical-align: middle;
  height: 160px;
  width: 200px;
  position: relative;
`;

const StyledTextarea = styled(Textarea)<{
  textsize: number;
  lineheight: number;
  $height: number;
}>`
  font-family: ABCFavorit;
  font-style: normal;
  font-weight: 400;
  background: transparent;
  user-select: text;
  font-size: ${({ textsize }) => textsize}px;
  line-height: ${({ lineheight }) => lineheight}px;
  border: none;
  resize: none;
  width: 100%;
  max-width: 200px;
  color: ${colors.black};
  height: ${({ $height }) => $height + 'px !important'};
  padding: 0;

  &:disabled {
    color: ${colors.black};
  }

  &:focus {
    outline: none;
  }
`;

const CardText = styled.span<{
  textsize: number;
  lineheight: number;
  disabled: boolean;
}>`
  font-family: ABCFavorit;
  font-style: normal;
  font-weight: 400;
  background: transparent;
  user-select: none;
  font-size: ${({ textsize }) => textsize}px;
  line-height: ${({ lineheight }) => lineheight}px;
  border: none;
  width: 100%;
  resize: none;
  max-width: 200px;
  color: ${({ disabled }) => (disabled ? colors.black60 : colors.black)};

  display: block;
  word-break: break-word;
`;

const ConfirmationNotification = styled.div<{
  visible: boolean;
  color: string;
}>`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  background: ${({ color }) => color};
  text-align: center;
  padding: 40px 35px;
  border-radius: 5px;
  @media ${device.mobile} {
    padding: 35px 15px;
  }
`;

const ConfirmationNotificationTitle = styled(BodyNormal)`
  margin-bottom: 5px;
  color: ${colors.black};
`;

const ConfirmationNotificationSubTitle = styled(BodyNormal)`
  margin-bottom: 20px;
  color: ${colors.black60};
`;

const CTACancel = styled(ButtonLabel)`
  display: inline-block;
`;

const CTAConfirm = styled(ButtonLabel)<{ purple?: boolean }>`
  color: ${({ purple }) => {
    return purple ? colors.purple : colors.red;
  }};
  display: inline-block;
`;

const CardIconWrapper = styled.div`
  display: inline-block;
  margin: 5px 0 0 5px;
  width: 30px;
  height: 30px;
`;

function getTypeSize(
  length: number,
  isMobile: boolean
): { fontSize: number; lineHeight: number } {
  // NOTE ^maxim
  // If you're changing this make sure to
  // test on an actually iOS device since
  // it renders very differently to on a
  // browser on MacOS
  if (isMobile) {
    // large
    if (length < 16) {
      return { fontSize: 26, lineHeight: 32 };
    }
    // medium
    if (length < 44) {
      return { fontSize: 16, lineHeight: 20 };
    }
    // small
    if (length < 60) {
      return { fontSize: 13, lineHeight: 20 };
    }
    return { fontSize: 10, lineHeight: 18 };
  }

  // large
  if (length < 35) {
    return { fontSize: 26, lineHeight: 32 };
  }
  // medium
  if (length < 80) {
    return { fontSize: 16, lineHeight: 20 };
  }
  // small
  return { fontSize: 14, lineHeight: 20 };
}

interface Props {
  tooltipUID: string;
  card: PostItCardFragment;
  groupId: number;
  userId: number;
  updateCard(card: PostItCardFragment): void;
  removeCard(cardId: number): void;
  dragDisabled: boolean;
  onFocus(): void;
  onBlur(): void;
  onMouseDown(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  disableCollaboration?: boolean;
  userRole: Role | null;
  uploading: boolean;
  errMsg: { id: number; message: string };
  setErrMsg: React.Dispatch<
    React.SetStateAction<{
      id: number;
      message: string;
      cardHasImage: boolean;
    }>
  >;
  openFileDialog(): void;
  setEditImage(card: PostItCardFragment | null): void;
  editingDisabled?: boolean;
}

export const PostItCard: React.FC<Props> = ({
  card,
  tooltipUID,
  groupId,
  userId,
  removeCard,
  updateCard,
  dragDisabled,
  onBlur,
  onFocus,
  onMouseDown,
  disableCollaboration = false,
  userRole,
  uploading,
  errMsg,
  setErrMsg,
  openFileDialog,
  setEditImage,
  editingDisabled = false,
}) => {
  const isMobile = useMobile();
  const isMounted = useIsMounted();
  const [title, setTitle] = useState(card.title || '');
  const [focused, setFocused] = useState<boolean>(false);
  const [iconsEditDeleteVisible, setIconsEditDeleteVisible] = useState<boolean>(
    false
  );
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

  const color = getCardColor(card.type);
  const cardType = card.type as PostItCardType;
  const editDeleteButtonsRef = useRef<HTMLDivElement>(null);
  const imageCardWrapperRef = useRef<HTMLDivElement>(null);

  const debouncedSetEditDeleteVisible = debounce(() => {
    setIconsEditDeleteVisible(false);
  }, 200);

  // NOTE this editing local state is too stop apollo overwritting
  // textarea state when user is typing
  const [isEditing, setIsEditing] = useState(false);

  // eslint-disable-next-line
  const throttleSave = useCallback(
    throttle((val: PostItCardFragment) => {
      updateCard(val);
    }, 1000),
    []
  );

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [textAreaHeight, setTextAreaHeight] = useState(36);

  useEffect(() => {
    if (!isEditing) setTitle(card.title);
  }, [card.title, isEditing]);

  const { fontSize, lineHeight } = getTypeSize(title.length, isMobile);

  useEffect(() => {
    const fixTextSize = () => {
      const textBoxHeight = Number(
        textAreaRef?.current?.getBoundingClientRect().height
      );
      setTextAreaHeight(textBoxHeight);
    };
    fixTextSize();
    window.addEventListener('resize', fixTextSize);
    return () => {
      window.removeEventListener('resize', fixTextSize);
    };
  }, [title, fontSize]);

  const isOwner = card.user.id === userId;
  const { isLead } = verifyUserRole(userRole);
  const characterlimit = 102;
  const showTyping = card.typing && !isEditing;

  useEffect(() => {
    let timeout: any;
    if (showTyping) {
      timeout = setTimeout(() => {

        updateCard({
          ...card,
          typing: false,
          typingUserId: null,
        });
      }, 60000);
    }
    return () => clearTimeout(timeout);
  }, [showTyping, card, updateCard]);

  return (
    <Wrapper
      disabled={editingDisabled}
      cardType={card.type}
      onMouseDown={(e) => {
        onMouseDown(e);
      }}
      className={`postitcard ${showTyping && 'show-typing'} cypress-postitcard`}
      color={color}
    >
      {!['plain', 'image'].includes(cardType) ? (
        /* Header Icons */
        <CardIconWrapper>
          <TooltipWrapper
            text={
              cardType !== PostItCardType.Other
                ? getTitleFromNoteType(cardType)
                : ''
            }
            effect="float"
            place="top"
          >
            {card.type !== 'other' ? (
              <Icon
                name={getIconFromNoteType(card.type)}
                size={30}
                color={colors.black70}
              />
            ) : null}
          </TooltipWrapper>
        </CardIconWrapper>
      ) : null}

      <Content
        disabled={editingDisabled || showTyping}
        focused={focused}
        color={color}
        cardType={card.type}
      >
        {card.type !== 'image' ? (
          <TextareaWrapper>
            {(!isOwner && typeof userRole === 'string' && !isLead) ||
            showTyping ? (
              <CardText
                ref={textAreaRef}
                textsize={fontSize}
                lineheight={lineHeight}
                disabled={showTyping}
              >
                {card.title}
              </CardText>
            ) : (
              <>
                {/* This CardText allows us to measure the height of the text.
                The text area updates it's height too late, so we can't use that component */}
                <CardText
                  ref={textAreaRef}
                  style={{
                    position: 'absolute',
                    pointerEvents: 'none',
                    visibility: 'hidden',
                    padding: 2,
                    minHeight: 30,
                  }}
                  textsize={fontSize}
                  lineheight={lineHeight}
                  disabled={showTyping}
                >
                  {title}
                </CardText>
                <StyledTextarea
                  $height={isMobile ? 150 : textAreaHeight}
                  autoFocus={isOwner && title.length === 0}
                  value={title}
                  onFocus={() => {
                    setIsEditing(true);
                    setFocused(true);
                    setIconsEditDeleteVisible(true);
                    updateCard({ ...card, typing: true, typingUserId: userId });
                    onFocus();
                  }}
                  onBlur={() => {
                    onBlur();
                    setFocused(false);

                    if (!title && !card.title) return removeCard(card.id);

                    setTimeout(() => {
                      if (isMounted.current) {
                        setIconsEditDeleteVisible(false);
                      }
                    }, 200);

                    // cancel any current debounced saving states
                    throttleSave.cancel();

                    updateCard({
                      ...card,
                      title: title || card.title,
                      typing: false,
                      typingUserId: null,
                    });
                    setIsEditing(false);
                  }}
                  // @ts-ignore
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    const val = e.target.value.replace('\n', '');
                    setTitle(val);
                    updateCard({ ...card, typing: true, typingUserId: userId });
                    throttleSave({ ...card, title: val });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e?.currentTarget?.blur();
                    }
                  }}
                  maxLength={characterlimit}
                  textsize={fontSize}
                  lineheight={lineHeight}
                  className="cypress-postit-input"
                />
              </>
            )}
          </TextareaWrapper>
        ) : uploading ? (
          <UploadingWrapper>
            <Caption color={colors.greyDark}>Uploading...</Caption>
            <div style={{ position: 'relative', overflow: 'hidden' }}>
              <Icon name="LoadingBar" size={100} />
              <AnimatingLoadingBar>
                <div className="frame">
                  <Icon name="LoadingBarGradient" size={100} />
                </div>
              </AnimatingLoadingBar>
            </div>
          </UploadingWrapper>
        ) : (
          <ImageCardWrapper
            ref={imageCardWrapperRef}
            onMouseEnter={() => {
              if (!isOwner && !isLead) {
                return;
              }
              setIconsEditDeleteVisible(true);
            }}
            onMouseLeave={(e) => {
              if (!isOwner && !isLead) {
                return;
              }
              const deleteBtn = editDeleteButtonsRef.current;

              const newFocusedElement = e.relatedTarget as Node | null;

              //if not hovering on delete button, hide delete button in 2ms
              if (newFocusedElement && deleteBtn instanceof HTMLElement) {
                try {
                  !deleteBtn?.contains(newFocusedElement) &&
                    debouncedSetEditDeleteVisible();
                } catch (error) {}
              }
            }}
          >
            <ImageWrapper>
              {/* Image BG */}
              <CardImageBG imageURL={card?.image || ''} />
              {/* Image */}
              <CardImage imageURL={card?.image || ''} />
            </ImageWrapper>
          </ImageCardWrapper>
        )}
      </Content>

      <ConfirmationNotification visible={errMsg.id === card.id} color={color}>
        <ConfirmationNotificationTitle>
          {errMsg.message}
        </ConfirmationNotificationTitle>
        <ConfirmationNotificationSubTitle>
          {errMsg.message === 'File too big'
            ? 'Maximum file size is 20MB'
            : errMsg.message === 'File too big'
            ? 'Only image files are allowed.'
            : 'There was an issue uploading the file.'}
        </ConfirmationNotificationSubTitle>
        <ConfirmationNotificationCTA>
          <CTACancel
            onClick={() => {
              setErrMsg({
                id: -1,
                message: '',

                cardHasImage: false,
              });
              setEditImage(null);
              setShowDeleteConfirm(false);
              //Remove the card if it has no image
              !card.image && removeCard(card.id);
            }}
          >
            Cancel
          </CTACancel>
          <CTAConfirm
            purple
            onClick={() => {
              if (card.image) {
                setErrMsg({ id: -1, message: '', cardHasImage: false });
              }
              setEditImage(card);
              openFileDialog();
              setShowDeleteConfirm(false);
            }}
          >
            Try again
          </CTAConfirm>
        </ConfirmationNotificationCTA>
      </ConfirmationNotification>

      <ConfirmationNotification
        color={color}
        className="cypress-post-it-Delete-confirmation"
        visible={showDeleteConfirm}
      >
        <ConfirmationNotificationTitle>
          {card.type === 'image' ? 'Delete this image?' : 'Delete this note?'}
        </ConfirmationNotificationTitle>
        <ConfirmationNotificationSubTitle>
          Any discussion and files will be lost.
        </ConfirmationNotificationSubTitle>
        <ConfirmationNotificationCTA>
          <CTACancel onClick={() => setShowDeleteConfirm(false)}>
            Cancel
          </CTACancel>
          <CTAConfirm onClick={() => removeCard(card.id)}>Delete</CTAConfirm>
        </ConfirmationNotificationCTA>
      </ConfirmationNotification>
      <IconsEditDelete
        className="cypress-postitcard-delete-icon"
        ref={editDeleteButtonsRef}
        visible={iconsEditDeleteVisible}
      >
        <DeleteIcon
          level="secondary"
          iconName="Trash"
          size="small"
          onClick={() => setShowDeleteConfirm(true)}
        />

        {card.type === 'image' && (
          <ButtonRound
            level="secondary"
            iconName="Pencil"
            size="small"
            iconStyle={{ color: colors.black }}
            onClick={() => {
              setEditImage(card);
              openFileDialog();
              debouncedSetEditDeleteVisible();
            }}
          />
        )}
      </IconsEditDelete>

      <FooterFlag
        disabled={errMsg.id === card.id || uploading}
        visible={!focused}
      >
        <TooltipCSS text={getUserTitleOrCountryAndName(card?.user)}>
          <CountryFlag
            user={card.typing && card.typingUser ? card.typingUser : card.user}
            size="sm"
            disableTooltip
          />
        </TooltipCSS>
        {showTyping && (
          <Caption color={colors.black70} style={{ marginLeft: 5 }}>
            Typing...
          </Caption>
        )}
      </FooterFlag>

      <CharacterLimitProgressDonutWrapper visible={focused && !showTyping}>
        <CharacterLimitProgressDonut
          count={title.length}
          limit={characterlimit}
          grow={characterlimit - title.length < 20}
          showCount={characterlimit - title.length < 20}
          color={() => {
            const limit = characterlimit;
            const remainingCharacters = limit - title.length;

            if (remainingCharacters >= 20) {
              return colors.blue;
            }

            return colors.orange;
          }}
        />
      </CharacterLimitProgressDonutWrapper>

      <FooterCollaboration
        disabled={errMsg.id === card.id || uploading || showTyping}
      >
        {disableCollaboration || (showTyping && isMobile) ? null : (
          <StyledCollaboration
            imageCard={card.type === 'image'}
            collaboration={card.collaboration}
            lightBG
            disabled={showTyping}
          />
        )}
      </FooterCollaboration>
    </Wrapper>
  );
};
