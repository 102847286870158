import styled from 'styled-components/macro';
import { colors } from 'constants/colors';
import { GoalsTableRowColumnTemplate } from '../GoalsTable.styles';

export const DesktopContainer = styled.div<{ isFocused: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  border-radius: 5px;
  border: 1px solid transparent;
  padding: ${({ isFocused }) => (isFocused ? '15px' : '10px 15px')};

  background: ${({ isFocused }) =>
    isFocused ? colors.purplePinkGradient : colors.white};

  ::before {
    display: ${({ isFocused }) => (isFocused ? 'block' : 'none')};
    position: absolute;
    inset: 0;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: ${colors.fadedGradient}, white;
    z-index: 0;
  }

  .Row__deleteIcon {
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;
  }

  &:hover {
    .Row__deleteIcon {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const ColumnContainer = styled.div`
  display: grid;
  position: relative;
  align-items: center;
  ${GoalsTableRowColumnTemplate}
`;

export const LastColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;
