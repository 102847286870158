import styled from 'styled-components/macro';

import { colors } from 'constants/colors';
import { device } from 'utils/breakpoints';
import { Page, Wrapper } from 'components/Page';
import { BodyNormal, StepHeaderBar, Subtitle2 } from 'components/shared';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: stretch;
`;

export const HeaderContent = styled(StepHeaderBar)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  min-height: unset;
  padding: 15px;
  padding-bottom: 0px;
  margin-top: 164px;

  @media ${device.desktopMin} {
    margin-top: 110px;
  }
`;

export const HeaderContentTitle = styled(Subtitle2)`
  margin-bottom: 5px;
`;

export const HeaderContentDescription = styled(BodyNormal)`
  display: inline-block;
  margin-bottom: 15px;
  color: ${colors.greyDark};
`;

export const TabsContainer = styled.div`
  width: 100%;
`;

export const MainContentContainer = styled(Page)<{
  totalNavHeight: number;
  sidebarOffset: {
    offset: number;
    isHidden: boolean;
  };
}>`
  width: ${({ sidebarOffset }) =>
    !sidebarOffset.isHidden && `calc(100% - ${sidebarOffset.offset}px)`};

  ${Wrapper} {
    max-width: none;
    width: 100%;
    top: ${({ totalNavHeight }) => `${totalNavHeight}px`};

    @media ${device.tabletMin} {
      display: flex;
      top: ${({ totalNavHeight }) => `${totalNavHeight}px`};
    }
  }
  padding: 0;
`;
