import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { createContext } from 'react';
import styled from 'styled-components/macro';
import { uid } from 'uid';

import { ToastLevel } from 'types';
import { zIndex } from 'constants/zIndex';
import { ToastPreview } from 'components/shared';

const Toasts = styled.div`
  z-index: ${zIndex.toasts};
  position: fixed;
  top: 35px;
  left: 50%;
  right: 0px;
  max-width: fit-content;
  width: 90%;
  transform: translate3d(-50%, 0, 0);
`;

interface Toast {
  id: string;
  level: ToastLevel;
  text: string;
}

type CreateToast = (
  text: string,
  level: ToastLevel
) => {
  id: string;
  text: string;
  level: ToastLevel;
  setCTAClick(cb: (...args: any[]) => void): void;
};

type RemoveToast = (idToRemove: string) => void;

const ToastContext = createContext<[CreateToast, RemoveToast]>([
  (text: string, level: ToastLevel) => ({
    id: '0',
    text: '',
    level: 'Info',
    setCTAClick: () => {},
  }),
  (id: string) => {},
]);

export const ToastProvider: React.FC = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);
  const callBackRef = useRef(() => {});

  function setCTAClick<Tcb extends (...args: any[]) => void>(cb: Tcb) {
    callBackRef.current = cb;
  }

  const createToast = useCallback(function (text: string, level: ToastLevel) {
    const newToast = {
      id: uid(),
      text,
      level,
      setCTAClick,
    };
    setToasts((t) => [...t, newToast]);

    // NOTE do we want to automatically remove toasts
    // setTimeout
    return newToast;
  }, []);

  const removeToast = useCallback(function removeToast(idToRemove: string) {
    setToasts((ts) => ts.filter(({ id }) => id !== idToRemove));
  }, []);

  const updateNetwork = () => {
    const isOnline = window.navigator.onLine;
    if (!isOnline) {
      createToast('Offline - check your connection.', 'Info');
    } else {
      // remove any offline toasts
      setToasts([]);
    }
  };
  useEffect(() => {
    window.addEventListener('offline', updateNetwork);
    window.addEventListener('online', updateNetwork);
    return () => {
      window.removeEventListener('offline', updateNetwork);
      window.removeEventListener('online', updateNetwork);
    };
  });

  return (
    <ToastContext.Provider value={[createToast, removeToast]}>
      <Toasts className="toasts-wrapper">
        {toasts.map(({ id, text, level }) => (
          <ToastPreview
            text={text}
            level={level}
            key={id}
            close={() => {
              removeToast(id);
            }}
            onCTAClick={() => {
              // This seems to fail sometimes when the ref is provided to the prop,
              // but declaring a new function gives it more consistent behavior
              callBackRef.current();
            }}
          />
        ))}
      </Toasts>
      {children}
    </ToastContext.Provider>
  );
};

export function useToastContext() {
  return useContext(ToastContext);
}
