import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { colors } from 'constants/colors';
import { useToastContext } from 'contexts/ToastContext';
import { Button } from 'components/Button';
import { ModalLarge } from 'components/ModalLarge';
import { BodySmall, Icon, Subtitle1 } from 'components/shared';
import { navigateToPico } from '../util-navigate-to-pico/navigateToPico';
import { MedicalTacticsUrlParams } from 'components/4-2-medical-strategy/shared-medical-tactics';

const Content = styled.div`
  padding: 40px;
`;

const Title = styled(Subtitle1)`
  text-align: center;
  margin-bottom: 15px;
`;

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const Section = styled.div``;
const LinkSection = styled(Section)`
  display: flex;
  align-items: center;
  gap: 5px;
  height: 20px;
`;
const EditPicoButton = styled(Button)`
  color: ${colors.purple};
  background: none;
  padding: 0;
  cursor: pointer;
`;

interface ViewPicoSuggestionDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  tacticId: number | undefined;
  picoSuggestion: {
    id: number;
    name: string;
    population: string;
    intervention: string;
    comparison: string;
    outcome: string;
  };
}

export const ViewPicoSuggestionDetailsModal = ({
  isOpen,
  onClose,
  picoSuggestion,
  tacticId,
}: ViewPicoSuggestionDetailsModalProps) => {
  let { url } = useRouteMatch();
  const history = useHistory();
  const urlParams = useParams<MedicalTacticsUrlParams>();
  const [createToast, removeToast] = useToastContext();

  const goToPico = navigateToPico(history);

  const onEditPicoClick = () => {
    goToPico({
      drugId: urlParams.drugId,
      strategyId: urlParams.strategyId,
      originalPicoId: +picoSuggestion.id || undefined,
    });

    const { id: toastId, setCTAClick } = createToast(
      'Return to 4.2 Medical Strategy',
      'Return'
    );

    setCTAClick(() => {
      history.push({
        pathname: `${url}`,
        search: tacticId ? `returnToId=${tacticId}` : '',
      });
      removeToast(toastId);
    });
  };

  return (
    <ModalLarge
      portalId="#pico-tactic-details"
      size="small"
      visible={isOpen}
      handleClose={onClose}
      headerColor={colors.cream}
    >
      <Content>
        <Title color={colors.black}>{picoSuggestion.name}</Title>
        <Sections>
          <Section>
            <BodySmall color={colors.greyDark}>Population</BodySmall>
            <BodySmall color={colors.black}>
              {picoSuggestion.population}
            </BodySmall>
          </Section>
          <Section>
            <BodySmall color={colors.greyDark}>Intervention</BodySmall>
            <BodySmall color={colors.black}>
              {picoSuggestion.intervention}
            </BodySmall>
          </Section>
          <Section>
            <BodySmall color={colors.greyDark}>Comparison</BodySmall>
            <BodySmall color={colors.black}>
              {picoSuggestion.comparison}
            </BodySmall>
          </Section>
          <Section>
            <BodySmall color={colors.greyDark}>Outcome</BodySmall>
            <BodySmall color={colors.black}>{picoSuggestion.outcome}</BodySmall>
          </Section>
          <LinkSection>
            <Icon name="Link" size={15} color={colors.greyDark} />
            <BodySmall color={colors.greyDark}>Linked PICO</BodySmall>

            <Icon name="Edit" size={15} color={colors.purple} />
            <EditPicoButton text="Edit Original" onClick={onEditPicoClick} />
          </LinkSection>
        </Sections>
      </Content>
    </ModalLarge>
  );
};
