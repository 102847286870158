import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStrategyQuery, useDrugQuery } from 'data/graphql/generated';
import { Navbar } from '../components/Navbar';
import { Strategy as StrategyComp } from '../components/Strategy';
import { ErrorWrapper } from '../components/ErrorLoadingComponent';

export const Strategy: React.FC = () => {

  const { drugId, strategyId } = useParams<{
    drugId: string;
    strategyId: string;
  }>();

  const {
    data,
    loading: strategyLoading,
    error: strategyError,
  } = useStrategyQuery({
    variables: { id: Number(strategyId) },
  });
  const {
    data: drugData,
    loading: drugLoading,
    error: drugError,
  } = useDrugQuery({
    variables: { id: Number(drugId) },
  });

  const drugName = drugData?.drug?.name;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  return (
    <>
      <Navbar
        disableSecondary
        hideArrows
        title={`${
          data?.strategy && drugName
            ? drugName +
              '||•||' +
              data?.strategy?.startYear +
              '-' +
              data?.strategy?.endYear
            : ''
        }`}
        url="/drugs"
      />

      <ErrorWrapper
        isLoading={strategyLoading || drugLoading}
        errors={[strategyError, drugError]}
        dataMissing={!data || !drugData}
      >
        <StrategyComp strategyId={Number(strategyId)} drugId={Number(drugId)} />
      </ErrorWrapper>
    </>
  );
};
