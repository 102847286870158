import { colors } from 'constants/index';
import styled from 'styled-components/macro';
import { DIAMOND_WIDTH } from './constants';

export const DueDateDiamond = styled.div`
  background: ${colors.purplePinkGradient};
  border: 1px solid ${colors.white};
  width: ${DIAMOND_WIDTH}px;
  height: ${DIAMOND_WIDTH}px;
  transform: rotate(45deg);
`;
