import { PatientJourneyPageURLParams } from 'components/PatientJourney/src/lib/types';
import { useCallback } from 'react';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

export function useSetPatientJourneyURL() {
  const {
    drugId,
    strategyId,
    state,
  } = useParams<PatientJourneyPageURLParams>();

  const { path } = useRouteMatch();
  const history = useHistory();

  // default url
  const setUrl = useCallback(
    ({
      vars = {},
      method,
    }: {
      vars?: Partial<PatientJourneyPageURLParams>;
      method: 'push' | 'replace';
    }) => {
      const generatedPath = generatePath(path, {
        drugId,
        strategyId,
        state: state?.toLowerCase(),
        ...vars,
      });
      history[method](generatedPath);
    },
    [drugId, history, path, state, strategyId]
  );

  return setUrl;
}
