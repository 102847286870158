import React from 'react';
import { startCase } from 'lodash';
import styled from 'styled-components/macro';

import { Card } from 'components/CompetitiveAdvantage/Summary/Card';
import { BulletPoints } from 'components/Strategy/BulletPoints';
import { colors } from 'constants/colors';
import { CountryGlobal } from 'types';

import { BodySmall } from '..';
import { Caption } from '../TextStyles';

const GreyCard = styled(Card)`
  flex-direction: column;
  padding: 10px;
  background: ${colors.black02OnWhite};
  border-color: ${colors.black10};
`;

export const GreyCardComponent: React.FC<{
  title: string;
  listData?: string[];
  data?: string;
  viewingCountry?: CountryGlobal;
  localListData?: string[];
}> = ({ title, listData, data, viewingCountry, localListData }) => (
  <GreyCard>
    <BodySmall color={colors.greyDark} style={{ marginBottom: 5 }}>
      {title}
    </BodySmall>
    {typeof data === 'string' && (
      <BodySmall color={colors.black}>
        {data || 'None identified yet'}
      </BodySmall>
    )}
    {!!listData && <BulletPoints listData={listData} />}

    {!!viewingCountry && (
      <>
        <Caption color={colors.greyDark} style={{ margin: '5px 0' }}>
          {`${startCase(viewingCountry)} specific`}
        </Caption>
        {!!localListData && <BulletPoints listData={localListData} />}
      </>
    )}
  </GreyCard>
);
