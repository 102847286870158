import { colors } from 'constants/index';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { Icon } from './Icon';

const StyledIcon = styled(Icon)`
  cursor: pointer;
  &:hover {
    background: ${colors.black10};
    border-radius: 5px;
  }
`;

type Props = {
  onClick: () => unknown;
  size?: number;
  color?: string;
  className?: string;
  style?: CSSProperties;
  active?: boolean;
};

export const MoreTextIcon = ({
  onClick,
  size = 30,
  color = colors.greyDark,
  className,
  style,
  active,
}: Props) => {
  return (
    <StyledIcon
      style={style}
      className={className}
      name={active ? 'MoreTextActive' : 'MoreText'}
      size={size}
      color={color}
      onClick={onClick}
    />
  );
};
