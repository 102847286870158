import {
  BodyNormal,
  BodySmall,
  ButtonPill,
  Subtitle1,
} from 'components/shared';
import { zIndex } from 'constants/zIndex';
import { colors } from 'constants/colors';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import FormTextInput from 'components/shared/FormTextInput';
import { TooltipWrapper } from 'components/shared/ButtonPill';
import { device } from 'utils/breakpoints';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { fadeIn } from 'utils/keyframes';
import {
  PicoSuggestionFragment,
  PicoSuggestionsDocument,
  usePicoSuggestionUpdateMutation,
} from 'data/graphql/generated';
import { apolloUpdateHelper } from 'utils/apolloQueryHelpers';
import { picoSuggestionsQueryVars } from '../../../AccessStrategy/AccessStrategyPico';

const Wrapper = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'initial' : 'none')};
  animation: ${fadeIn} 0.5s;
  background: ${colors.white};
  position: fixed;
  top: 50px;
  z-index: ${zIndex.pageModal};
  height: 100%;
  width: 100%;

  @media ${device.tabletMin} {
    max-width: 456px;
    height: fit-content;
    border-radius: 5px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05),
      0px 10px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid ${colors.black30};
  }
`;

const Header = styled.header`
  background: ${colors.black05};
  padding: 15px;
  border-radius: 5px;
`;

const HeaderText = styled.div`
  padding: 15px 10px;
  background: ${colors.white};
`;

const Body = styled.section`
  padding: 0 15px;

  @media ${device.tabletMin} {
    padding: 0 40px 40px;
  }
`;

const BodyText = styled.div`
  margin: 20px 0;
  text-align: center;

  ${Subtitle1} {
    margin-bottom: 10px;
  }
`;

const Buttons = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;

  margin: 10px 0;

  ${TooltipWrapper} {
    width: 165px;
  }

  @media ${device.tabletMin} {
    margin-bottom: 0;

    ${TooltipWrapper} {
      width: 180px;
    }
  }
`;

const StyledTextInput = styled(FormTextInput)`
  span {
    top: -24px;
  }

  input {
    margin: 5px 0 40px;
  }

  @media ${device.tabletMin} {
    input {
      margin-bottom: 30px;
    }
  }
`;

interface Props {
  headerTitle: string;
  headerText: string;
  handleClose: () => void;
  visible: boolean;
  picoSuggestion: PicoSuggestionFragment | undefined;
  strategyId: string;
}

export function NamePicoSuggestionModal({
  headerText,
  headerTitle,
  handleClose,
  visible,
  picoSuggestion,
  strategyId,
}: Props) {
  const [name, setName] = useState('');
  const picoModalRef = useRef<HTMLDivElement | null>(null);
  const [updateSuggestion] = usePicoSuggestionUpdateMutation();

  useEffect(() => {
    setName(picoSuggestion?.name || '');
  }, [picoSuggestion]);

  useClickOutsideComponent(picoModalRef, handleClose);

  async function handleSave() {
    if (!name || !picoSuggestion?.id) return;

    try {
      await updateSuggestion({
        variables: {
          data: { focus: true, name },
          id: picoSuggestion.id,
        },
        update: apolloUpdateHelper({
          responseField: 'picoSuggestionUpdate',
          query: PicoSuggestionsDocument,
          queryVars: picoSuggestionsQueryVars(strategyId),
          queryName: 'picoSuggestions',
        }),
        optimisticResponse: {
          picoSuggestionUpdate: {
            ...picoSuggestion,
            focus: true,
            name,
          },
        },
      });
    } catch (err) {
      alert('Something went wrong');
    } finally {
      handleClose();
    }
  }

  return (
    <Wrapper visible={visible} ref={picoModalRef}>
      <Header>
        <HeaderText>
          <BodySmall color={colors.greyDark}>{headerTitle}</BodySmall>
          <BodySmall>{headerText}</BodySmall>
        </HeaderText>
      </Header>

      <Body>
        <BodyText>
          <Subtitle1>Name this PICO suggestion</Subtitle1>
          <BodyNormal>Enter a short tagline to refer to it by.</BodyNormal>
        </BodyText>

        <StyledTextInput
          name="name"
          onChange={(e) => setName(e.target.value)}
          value={name}
          title="Name"
          type="text"
        />

        <Buttons>
          <ButtonPill
            text="Cancel"
            level="secondary"
            onClick={(e) => handleClose()}
          />
          <ButtonPill
            disabled={!name.length}
            text="Save"
            onClick={handleSave}
          />
        </Buttons>
      </Body>
    </Wrapper>
  );
}
