import { DatePickerDropdown } from 'components/MedicalStrategy/DatePickerDropdown';
import MedicalTacticsRationaleModal from 'components/MedicalStrategy/MedicalTacticsRationaleModal';
import {
  DetailItem,
  DetailsWrapper,
  MobileFocusedDetails,
  MobileOuterWrapper,
  MobileWrapper,
  Wrapper,
} from 'components/MedicalStrategy/TacticRow';
import {
  BodyNormal,
  BodySmall,
  ButtonLabel,
  ButtonRound,
  Collaboration,
  EditableTextarea,
  Icon,
} from 'components/shared';
import { DateType } from 'components/shared/DatePicker';
import FocusDropdown from 'components/shared/FocusDropdown';
import { colors } from 'constants/index';
import { PreviewAndDBDataSolutionType } from 'containers/RiskMitigationIdeas';
import { SolutionUpdateInput } from 'data/graphql/generated';
import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { NonNull } from 'types';
import { device } from 'utils/breakpoints';
import { monthNames } from 'utils/monthNames';
import { thousandSeparator } from 'utils/thousandSeparator';

export const columnGridStyle = css`
  grid-template-columns: minmax(auto, 427px) 480px;
`;
const ColumnsWrapper = styled.div`
  display: grid;
  ${columnGridStyle}
  grid-auto-rows: minmax(65px, auto);
  position: relative;
  align-items: center;
  column-gap: 15px;
  padding-left: 15px;
  textarea {
    min-width: auto;
  }
`;

const TimingWrapper = styled.div`
  display: flex;
  flex: none;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;

export const DeleteConfirmationWrapper = styled.div`
  display: flex;
  flex: none;
  width: 188px;
  height: 30px;

  background: ${colors.white};
  border: 0.5px solid ${colors.black30};
  border-radius: 5px;

  padding: 4px 20px;
  justify-content: space-between;
  align-items: center;

  position: relative;
  right: 138px;
  z-index: 2;

  @media ${device.tabletMax} {
    right: 0;
  }
`;

interface Props {
  isDesktop: boolean;
  isLead: boolean;

  data: PreviewAndDBDataSolutionType;
  currency: string;
  onDelete(): Promise<any>;
  onUpdate(data: NonNull<SolutionUpdateInput>): void;
  autoFocus?: boolean;
}

export const SolutionRow = ({
  autoFocus,
  isDesktop,
  isLead,
  data,
  currency,
  onUpdate,
  onDelete,
}: Props) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [modalSlideIdx, setModalSlideIdx] = useState<number | null>(null);
  const [showSingleSlide, setShowSingleSlide] = useState<boolean>(false);

  const currencyIcon = currency[0];

  const fromDateUpdateHandler = (date: DateType | null) => {
    if (typeof date?.month !== 'number') {
      onUpdate({ timingEnd: [], timingStart: [] });
      return;
    }
    if (
      data.timingEnd.length &&
      (date.year > data.timingEnd[1] ||
        (date.year === data.timingEnd[1] && date.month > data.timingEnd[0]))
    ) {
      onUpdate({ timingEnd: [], timingStart: [date.month, date.year] });
      return;
    }
    onUpdate({ timingStart: [date.month, date.year] });
  };

  const canUpdate = (value: string | number) =>
    !!data.id || (!data.id && !!value);

  function onSubmit(
    data: {
      dueDate: number[] | null | undefined;
      responsibility: string;
      budget: string | null | undefined;
    } | null,
    rationale: string
  ) {
    const {
      dueDate,
      responsibility,
      budget,
    } = data as NonNull<SolutionUpdateInput>;
    onUpdate({
      dueDate,
      responsibility,
      budget,
      focused: true,
      focusRationale: rationale,
    });
  }

  if (isDesktop)
    return (
      <Wrapper focused={data.focused}>
        <ColumnsWrapper>
          <MedicalTacticsRationaleModal
            key={data?.localUid || 0}
            modalSlideIdx={modalSlideIdx}
            setModalPage={setModalSlideIdx}
            setModalSlideIdx={setModalSlideIdx}
            selectedTactic={data}
            showSingleSlide={showSingleSlide}
            currency={currency}
            onSubmit={onSubmit}
            modalDetails={{ Solution: data.solutionText || '' }}
          />

          <div>
            <EditableTextarea
              autoFocus={autoFocus}
              style={{ background: 'transparent' }}
              editable={true}
              placeholder="Solution"
              initialValue={data.solutionText}
              onBlur={(value) => {
                if (canUpdate(value)) {
                  onUpdate({ solutionText: value });
                }
              }}
            />
          </div>
          <TimingWrapper>
            <div style={{ display: 'flex', gap: 10 }}>
              <DatePickerDropdown
                style={{ width: 90 }}
                placeholder="From"
                date={data.timingStart}
                onUpdate={(date) => {
                  fromDateUpdateHandler(date);
                }}
              />
              <DatePickerDropdown
                disabled={!data.timingStart.length}
                style={{ width: 90 }}
                placeholder="To"
                lowerLimit={data.timingStart}
                date={data.timingEnd}
                onUpdate={(date) => {
                  onUpdate({
                    timingEnd: !!date ? [date.month, date.year] : [],
                  });
                }}
              />
            </div>

            <Collaboration
              style={{
                marginLeft: 'auto',
                pointerEvents: data.id ? 'all' : 'none',
              }}
              collaboration={data.collaboration}
            />
            <FocusDropdown
              onClick={() => {
                if (isLead) {
                  if (!data.focused) {
                    setModalSlideIdx(0);
                    setShowSingleSlide(false);
                  } else {
                    onUpdate({ focused: false });
                  }
                }
              }}
              checked={data.focused}
              disabled={!isLead}
              rationaleText={data.focusRationale}
              setModalSlideIdx={setModalSlideIdx}
              setShowSingleSlide={setShowSingleSlide}
            />

            {!!isLead ? (
              !showDeleteConfirm ? (
                <ButtonRound
                  level="secondary"
                  iconName="Trash"
                  size="small"
                  onClick={() => {
                    setShowDeleteConfirm(true);
                  }}
                  className="Row__deleteIcon"
                />
              ) : (
                <DeleteConfirmationWrapper>
                  <ButtonLabel
                    color={colors.black}
                    onClick={() => setShowDeleteConfirm(false)}
                  >
                    Cancel
                  </ButtonLabel>
                  <ButtonLabel
                    color={colors.red}
                    onClick={async () => {
                      if (!isDeleting) {
                        setIsDeleting(true);
                        await onDelete();
                        setShowDeleteConfirm(false);
                        setIsDeleting(false);
                      }
                    }}
                  >
                    Delete row
                  </ButtonLabel>
                </DeleteConfirmationWrapper>
              )
            ) : (
              <div style={{ width: 30, flex: 'none' }}></div>
            )}
          </TimingWrapper>
        </ColumnsWrapper>
        {data.focused && (
          <DetailsWrapper>
            <DetailItem>
              <BodyNormal color={colors.greyDark}>Due:</BodyNormal>
              <BodyNormal>
                {[monthNames[data.dueDate[0]], data.dueDate[1]].join(' ')}
              </BodyNormal>
            </DetailItem>
            <DetailItem>
              <BodyNormal color={colors.greyDark}>Responsibility:</BodyNormal>
              <BodyNormal>{data.responsibility}</BodyNormal>
            </DetailItem>
            <DetailItem>
              <BodyNormal color={colors.greyDark}>Budget: </BodyNormal>
              {!!data.budget && (
                <BodyNormal>
                  {currencyIcon + thousandSeparator(data.budget)}
                </BodyNormal>
              )}
            </DetailItem>
            {!!isLead && (
              <Icon
                name={'Edit'}
                size={20}
                color={colors.black}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setShowSingleSlide(true);
                  setModalSlideIdx(0);
                }}
              />
            )}
          </DetailsWrapper>
        )}
      </Wrapper>
    );
  return (
    <>
      <MedicalTacticsRationaleModal
        key={data?.localUid || 0}
        modalSlideIdx={modalSlideIdx}
        setModalPage={setModalSlideIdx}
        setModalSlideIdx={setModalSlideIdx}
        selectedTactic={data}
        showSingleSlide={showSingleSlide}
        currency={currency}
        onSubmit={onSubmit}
        modalDetails={{ Solution: data.solutionText || '' }}
      />
      <MobileOuterWrapper focused={data.focused}>
        <MobileWrapper>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <FocusDropdown
              onClick={() => {
                if (isLead) {
                  if (!data.focused) {
                    setModalSlideIdx(0);
                    setShowSingleSlide(false);
                  } else {
                    onUpdate({ focused: false });
                  }
                }
              }}
              checked={data.focused}
              disabled={!isLead}
              rationaleText={data.focusRationale}
              setModalSlideIdx={setModalSlideIdx}
              setShowSingleSlide={setShowSingleSlide}
            />

            {!!isLead &&
              (!showDeleteConfirm ? (
                <ButtonRound
                  level="secondary"
                  iconName="Trash"
                  size="small"
                  onClick={() => {
                    setShowDeleteConfirm(true);
                  }}
                  className="Row__deleteIcon"
                />
              ) : (
                <DeleteConfirmationWrapper>
                  <ButtonLabel
                    color={colors.black}
                    onClick={() => setShowDeleteConfirm(false)}
                  >
                    Cancel
                  </ButtonLabel>
                  <ButtonLabel
                    color={colors.red}
                    onClick={async () => {
                      if (!isDeleting) {
                        setIsDeleting(true);
                        await onDelete();
                        setShowDeleteConfirm(false);
                        setIsDeleting(false);
                      }
                    }}
                  >
                    Delete row
                  </ButtonLabel>
                </DeleteConfirmationWrapper>
              ))}
          </div>
          <div>
            <BodySmall color={colors.greyDark}>Solution</BodySmall>
            <EditableTextarea
              autoFocus={autoFocus}
              style={{ background: 'transparent' }}
              editable={true}
              placeholder="Solution"
              initialValue={data.solutionText}
              onBlur={(value) => {
                if (canUpdate(value)) onUpdate({ solutionText: value });
              }}
            />
          </div>

          <div>
            <BodySmall color={colors.greyDark}>Timing</BodySmall>
            <div style={{ display: 'flex', gap: 10 }}>
              <DatePickerDropdown
                style={{ flex: 1 }}
                placeholder="From"
                date={data.timingStart}
                onUpdate={(date) => {
                  fromDateUpdateHandler(date);
                }}
              />
              <DatePickerDropdown
                disabled={!data.timingStart.length}
                alignRight
                style={{ flex: 1 }}
                placeholder="To"
                lowerLimit={data.timingStart}
                date={data.timingEnd}
                onUpdate={(date) => {
                  onUpdate({
                    timingEnd: !!date ? [date.month, date.year] : [],
                  });
                }}
              />
            </div>
          </div>
          {!!data.focused && (
            <MobileFocusedDetails>
              <div>
                <BodySmall color={colors.greyDark}>Due</BodySmall>
                <BodyNormal>
                  {[monthNames[data.dueDate[0]], data.dueDate[1]].join(' ')}
                </BodyNormal>
              </div>
              <div>
                <BodySmall color={colors.greyDark}>Responsibility</BodySmall>
                <BodyNormal>{data.responsibility}</BodyNormal>
              </div>
              <div>
                <BodySmall color={colors.greyDark}>Budget</BodySmall>
                {!!data.budget && (
                  <BodyNormal>
                    {currencyIcon + thousandSeparator(data.budget)}
                  </BodyNormal>
                )}
              </div>
              <Icon
                name={'Edit'}
                size={20}
                color={colors.black}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: 5,
                  top: 5,
                }}
                onClick={() => {
                  setShowSingleSlide(true);
                  setModalSlideIdx(0);
                }}
              />
            </MobileFocusedDetails>
          )}
          <Collaboration
            style={{
              marginLeft: 'auto',
              pointerEvents: data.id ? 'all' : 'none',
            }}
            collaboration={data.collaboration}
          />
        </MobileWrapper>
      </MobileOuterWrapper>
    </>
  );
};
