import { FetchResult } from '@apollo/client/link/core/types';
import {
  DistributionModal,
  DistributionModalValues,
} from 'components/CompetitiveLandscape/DistributionModal';
import { TransitionWrapper } from 'components/InsightGroup';
import { ButtonPill, EmptyState, Subtitle2 } from 'components/shared';
import { colors } from 'constants/index';
import {
  SuccessConditionUpsertMutation,
  SuccessConditionDeleteMutation,
  SuccessConditionFragment,
  SuccessConditionRatingFragment,
  SuccessConditionRatingUpsertMutation,
  SuccessConditionType,
  SuccessConditionUpdateMutation,
  SuccessConditionPerspective,
  useStrategyWithDrugQuery,
} from 'data/graphql/generated';
import lodash from 'lodash';
import React, { useEffect, useState } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components/macro';
import { uid } from 'uid';
import { device } from 'utils/breakpoints';
import { Condition } from './Condition';

const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 575px;

  background: ${colors.greyLight};
  display: flex;
  flex-direction: column;
  gap: 15px;

  padding: 15px 0;
  border: none;

  @media ${device.tabletMin} {
    padding: 15px;
    border: 1px solid ${colors.black10a};
    border-radius: 5px;
  }
`;

const StyledButtonPill = styled(ButtonPill)`
  margin-left: 15px;

  @media ${device.tabletMin} {
    margin-left: 0;
  }
`;

export interface SuccessConditionsProps {
  data: SuccessConditionFragment[];
  ratings: SuccessConditionRatingFragment[];
  strategyId: string;
  upsertSuccessCondition({
    text,
    localUid,
    type,
    perspective,
  }: {
    text: string;
    localUid: string;
    type?: SuccessConditionType;
    perspective?: SuccessConditionPerspective;
  }): Promise<FetchResult<SuccessConditionUpsertMutation>>;

  updateSuccessCondition(
    id: number,
    {
      text,
      type,
      perspective,
    }: {
      text?: string;
      type?: SuccessConditionType;
      perspective?: SuccessConditionPerspective;
    }
  ): Promise<FetchResult<SuccessConditionUpdateMutation>>;

  deleteSuccessCondition(
    id: number
  ): Promise<FetchResult<SuccessConditionDeleteMutation>>;
  upsertSuccessConditionRating: ({
    region,
    userId,
    score,
    successConditionLocalUid,
  }: {
    region?: string;
    userId?: number;
    score: number;
    successConditionLocalUid: string;
  }) => Promise<FetchResult<SuccessConditionRatingUpsertMutation>>;
}

export type PreviewAndDBDataType = Partial<
  Omit<SuccessConditionFragment, 'localUid' | 'text' | 'perspective'>
> &
  Pick<SuccessConditionFragment, 'localUid' | 'text' | 'perspective'>;

export const SuccessConditions = ({
  data,
  ratings,
  strategyId,
  upsertSuccessCondition,
  updateSuccessCondition,
  deleteSuccessCondition,
  upsertSuccessConditionRating,
}: SuccessConditionsProps) => {
  // const { data: uniqueCountries } = useStrategyCountriesQuery({
  //   variables: { id: +strategyId },
  //   notifyOnNetworkStatusChange: true,
  //   fetchPolicy: 'network-only',
  // });

  const { data: strategy } = useStrategyWithDrugQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'no-cache',
  });

  const currentStrategyUsers = strategy?.strategy?.users?.flat() || [];
  const currentDrugUsers = strategy?.strategy?.drug?.users?.flat() || [];

  const currentStrategyAndDrugUniqueUsers = lodash.uniqBy(
    [...currentStrategyUsers, ...currentDrugUsers],
    'id'
  );

  const [tempSuccessCondition, setTempSuccessCondition] = useState<
    PreviewAndDBDataType[]
  >([]);
  const [previewAndDBData, setPreviewAndDBData] = useState<
    PreviewAndDBDataType[]
  >(data);

  const [previousPreviewUID, setPreviousPreviewUID] = useState('');

  useEffect(() => {
    const previewInDB = data?.some(
      (item) => item.localUid === tempSuccessCondition[0]?.localUid
    );

    if (previewInDB) {
      setTempSuccessCondition([]);
      setPreviewAndDBData([...(data || [])]);
    } else {
      setPreviewAndDBData([...tempSuccessCondition, ...data]);
    }
  }, [data, tempSuccessCondition]);

  const highestVoteValue = (ratings.reduce((acc, curr, idx, array) => {
    if (typeof acc === 'object') {
      if (!(curr.score in acc)) {
        acc[curr.score] = 0;
      }
      acc[curr.score]++;
    }

    if (idx === array.length - 1) {
      const valuesArray = Object.values(acc);

      const highestVoteValue = Math.max(...valuesArray);

      return highestVoteValue;
    } else {
      return acc;
    }
  }, {} as number | Record<number, number>) as unknown) as number;

  const [
    showDistributionModal,
    setShowDistributionModal,
  ] = useState<DistributionModalValues | null>(null);

  return (
    <Wrapper>
      {!!currentStrategyAndDrugUniqueUsers?.length && (
        <DistributionModal
          companyUsersData={currentStrategyAndDrugUniqueUsers}
          visible={!!showDistributionModal}
          handleClose={() => {
            setShowDistributionModal(null);
          }}
          title={showDistributionModal?.title}
          ratings={showDistributionModal?.ratings}
        />
      )}
      <StyledButtonPill
        disabled={!!tempSuccessCondition.length}
        text="Add condition for success"
        clickClassName="cypress-success-condition-add"
        iconName="Plus"
        level="primary-solid"
        onClick={() => {
          if (!tempSuccessCondition.length) {
            const uniqueID = uid();
            setTempSuccessCondition([
              {
                text: '',
                localUid: uniqueID,
                perspective: SuccessConditionPerspective.None,
              },
            ]);

            setPreviousPreviewUID(uniqueID);
          }
        }}
      />
      <ChildrenWrapper>
        {!!previewAndDBData.length ? (
          <TransitionGroup component={null}>
            {previewAndDBData.map((condition) => {
              const isPreview = !condition.id;

              if (condition) {
                return (
                  <Transition key={condition.localUid} timeout={300}>
                    {(state) => {
                      return (
                        <TransitionWrapper state={state} isPreview={isPreview}>
                          <Condition
                            key={condition.localUid}
                            data={condition}
                            setShowDistributionModal={setShowDistributionModal}
                            highestVoteValue={highestVoteValue}
                            isPreview={isPreview}
                            autoFocus={isPreview}
                            upsertSuccessCondition={upsertSuccessCondition}
                            updateSuccessCondition={updateSuccessCondition}
                            upsertRating={upsertSuccessConditionRating}
                            deleteSuccessCondition={() => {
                              if (isPreview) {
                                //Remove from array
                                setTempSuccessCondition([]);

                                return;
                              } else {
                                if (condition?.id) {
                                  return deleteSuccessCondition(condition?.id);
                                }
                              }
                            }}
                            isNewItem={
                              previousPreviewUID === condition.localUid
                            }
                            setTempSuccessCondition={setTempSuccessCondition}
                            tempSuccessCondition={tempSuccessCondition}
                          />
                        </TransitionWrapper>
                      );
                    }}
                  </Transition>
                );
              } else return null;
            })}
          </TransitionGroup>
        ) : (
          <EmptyState style={{ borderRadius: 5, background: colors.white }}>
            <Subtitle2 color={colors.greyDark} style={{ marginBottom: 15 }}>
              Conditions for success will appear here
            </Subtitle2>
          </EmptyState>
        )}
      </ChildrenWrapper>
    </Wrapper>
  );
};
