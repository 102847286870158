import React, { useEffect, useState } from 'react';
import {
  CompaniesDocument,
  CompanyCreateMutation,
  CompanyUpdateMutation,
  useCompaniesQuery,
  useCompanyCreateMutation,
  useCompanyUpdateMutation,
} from '../data/graphql/generated';
import { Navbar } from '../components/Navbar';
import styled from 'styled-components/macro';
import CompanyModal from 'components/Admin/CompanyModal';
import { device } from 'utils/breakpoints';
import { ButtonPill } from 'components/shared';
import { ModalForm } from 'components/ModalForm';
import FormTextInput from 'components/shared/FormTextInput';
import {
  apolloCreateHelper,
  apolloUpdateHelper,
} from 'utils/apolloQueryHelpers';
import { useHistory } from 'react-router-dom';

const StyledButtonPill = styled(ButtonPill)`
  margin-left: auto;
  margin-bottom: 15px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
`;

const StyledCompanyModal = styled(CompanyModal)`
  border-radius: 5px;
`;

const PageWrapper = styled.div`
  padding: 80px 15px 0 15px;

  @media ${device.tabletMax} {
    padding-top: 65px;
  }
`;

export const Admin: React.FC = () => {
  const { data: companyData } = useCompaniesQuery({
    fetchPolicy: 'network-only',
  });
  const [createCompany] = useCompanyCreateMutation({
    update: apolloCreateHelper({
      responseField: 'companiesCreate',
      query: CompaniesDocument,
      queryName: 'companies',
      queryVars: {},
    }),
  });

  const [updateCompany] = useCompanyUpdateMutation({
    update: apolloUpdateHelper({
      responseField: 'companiesUpdate',
      query: CompaniesDocument,
      queryName: 'companies',
      queryVars: {},
    }),
  });

  const [modalState, setModalState] = useState<Pick<
    ModalProps,
    'type' | 'heading' | 'name' | 'id'
  > | null>(null);

  return (
    <PageWrapper>
      <Modal
        handleClose={() => {
          setModalState(null);
        }}
        visible={!!modalState}
        heading={modalState?.heading}
        name={modalState?.name}
        id={modalState?.id}
        type={modalState?.type}
        onUpdate={async (name: string, id: number) => {
          //If the name has changed
          if (modalState?.name && name !== modalState?.name) {
            const { data } = await updateCompany({
              variables: { data: { name }, id },
            });

            return data;
          }
        }}
        onCreate={async (name: string) => {
          const { data } = await createCompany({
            variables: { data: { name } },
          });

          return data;
        }}
      />
      <Navbar
        disableSecondary
        include={['userLink', 'productLink']}
        exclude={[
          'drugLink',
          'filesLink',
          'strategyLink',
          'notifications',
          'navItems',
          'navContent',
        ]}
      />
      <StyledButtonPill
        className="cypress-create-company"
        iconName="Plus"
        text="Create company"
        onClick={() => {
          setModalState({
            type: 'create',
            heading: 'Create company',
          });
        }}
      />

      {companyData?.companies?.items.map((company) => {
        if (!company) return null;
        return (
          <StyledCompanyModal
            key={company.id}
            company={company}
            onEditClick={() => {
              setModalState({
                type: 'edit',
                heading: 'Rename company',
                name: company.name,
                id: company.id,
              });
            }}
          />
        );
      })}
    </PageWrapper>
  );
};

interface ModalProps {
  type: 'create' | 'edit' | undefined;
  handleClose(): void;
  visible: boolean;
  heading?: string;
  name?: string;
  id?: number;
  onUpdate(
    name: string,
    id: number
  ): Promise<CompanyUpdateMutation | null | undefined>;
  onCreate(name: string): Promise<CompanyCreateMutation | null | undefined>;
}

function Modal({
  handleClose,
  visible,
  heading,
  name,
  id,
  onUpdate,
  onCreate,
  type,
}: ModalProps) {
  const history = useHistory();

  const [textValue, setTextValue] = useState(name || '');
  const [responseError, setResponseError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputTouched, setInputTouched] = useState(false);

  useEffect(() => {
    setTextValue(name || '');
  }, [name]);

  useEffect(() => {
    setResponseError('');

    return () => {
      setInputTouched(false);
      setIsSubmitting(false);
      setTextValue('');
      setResponseError('');
    };
  }, [visible]);

  return (
    <ModalForm
      handleClose={() => {
        handleClose();
      }}
      visible={visible}
      heading={heading}
    >
      <FormTextInput
        name="name"
        type="text"
        onChange={(e) => {
          setTextValue(e.target.value);
        }}
        onBlur={() => {
          setInputTouched(true);
        }}
        value={textValue}
        title="Company name"
        errorMessage={
          responseError ||
          (!textValue && inputTouched ? 'Name cannot be blank.' : '')
        }
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 20,
          gap: 15,
        }}
      >
        <ButtonPill
          level="secondary"
          width="180px"
          text="Cancel"
          onClick={() => {
            setResponseError('');
            handleClose();
          }}
        />
        <ButtonPill
          className="cypress-submit"
          disabled={isSubmitting || !textValue}
          type="submit"
          width="180px"
          text={type === 'create' ? 'Create company' : 'Rename company'}
          onClick={async () => {
            if (type) {
              setIsSubmitting(true);
              const trimmedTextValue = textValue.trim();
              if (!trimmedTextValue) {
                setResponseError('Name cannot be blank.');
                setTextValue('');
                setIsSubmitting(false);
                return;
              }

              try {
                if (type === 'create') {
                  const company = await onCreate(trimmedTextValue);
                  //navigate to new company page
                  if (company) {
                    history.push(`/admin/company/${company.companyCreate.id}`);
                  }
                }

                if (type === 'edit' && typeof id === 'number') {
                  await onUpdate(trimmedTextValue, id);
                }
              } catch (error) {
                if (error instanceof Error) {
                  setResponseError(error.message);
                }
                setIsSubmitting(false);
                return;
              }
              setIsSubmitting(false);
              handleClose();
            }
          }}
        />
      </div>
    </ModalForm>
  );
}