import { CountryConstants } from 'types';

export const getTooltipText = ({
  viewingOwnRegion,
  isLead,
  selectedRegion,
}: {
  viewingOwnRegion: boolean;
  isLead: boolean;
  selectedRegion: string;
}) => {
  if (viewingOwnRegion) {
    return '';
  }

  if (isLead || selectedRegion !== CountryConstants.GLOBAL) {
    return 'Only local contributors can edit';
  }

  return 'Only Leads can edit';
};
