import { BodySmall, Icon, Subtitle2 } from 'components/shared';
import { colors } from 'constants/colors';
import React from 'react';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { thousandSeparator } from 'utils/thousandSeparator';
import { LEFT_SIDE_PADDING, MOBILE_LEFT_SIDE_PADDING } from './constants';
import { GroupedTactics } from './GroupedTactics';
import { IndividualTactics, IndividualTacticsProps } from './IndividualTactics';
import { RowProps } from './SummaryTimelineRestyled';

const Budget = styled.div`
  padding-left: 5px;
  margin-bottom: 15px;
  max-width: fit-content;

  position: sticky;
  left: ${LEFT_SIDE_PADDING}px;

  display: flex;
  gap: 5px;
  @media ${device.mobile} {
    left: ${MOBILE_LEFT_SIDE_PADDING}px;
  }
`;

const Imperative = styled.div`
  padding: 20px 0 20px ${LEFT_SIDE_PADDING}px;
  @media ${device.mobile} {
    padding: 20px 0 20px ${MOBILE_LEFT_SIDE_PADDING}px;
  }
`;

const ImperativeTitleWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  padding-left: 5px;
  position: sticky;
  left: ${LEFT_SIDE_PADDING}px;
  @media ${device.mobile} {
    left: ${MOBILE_LEFT_SIDE_PADDING}px;
  }
  max-width: fit-content;
  cursor: pointer;
`;

const ImperativeTitle = styled(Subtitle2)`
  max-width: 640px;

  @media (max-width: 639px) {
    max-width: calc(100vw - 100px);
  }
`;

const ImperativeTitleIcon = styled(Icon)``;

const TacticEmptyState = styled.div`
  padding: 24px;
  background: ${colors.yellow20a};
  text-align: center;
  width: 100%;
  position: sticky;
  left: ${LEFT_SIDE_PADDING}px;
  @media ${device.mobile} {
    left: ${MOBILE_LEFT_SIDE_PADDING}px;
  }
`;

type Props = {
  imperative: {
    id: number;
    __typename?: string;
    strategicPossibility?: { name: string } | null | undefined;
    title?: string;
    budget?: number;
  };
  idx: number;
  title: string;
  currency: string;
  imperativeTactics: RowProps[];
  emptyStateText?: string;
  wrapperRef: React.RefObject<HTMLDivElement>;
  isOpen: boolean;
  setIsOpen: () => void;
} & IndividualTacticsProps;

export const TimelineImperativeRow = ({
  imperative,
  idx,
  title,
  currency,
  imperativeTactics,
  emptyStateText,
  minDate,
  quarterColumnWidth,
  prependDetailText,
  wrapperRef,
  isOpen,
  setIsOpen,
}: Props) => {
  return (
    <Imperative
      style={{
        background: !(idx % 2) ? colors.purple10a : 'transparent',
      }}
    >
      <ImperativeTitleWrapper
        onClick={() => {
          setIsOpen();
        }}
      >
        <ImperativeTitle>{title}</ImperativeTitle>
        {!!imperativeTactics.length && (
          <ImperativeTitleIcon
            name={isOpen ? 'Chevron-up' : 'Chevron-down'}
            size={30}
            color={colors.black}
          />
        )}
      </ImperativeTitleWrapper>
      <Budget>
        <BodySmall color={colors.greyDark}>Budget</BodySmall>
        <BodySmall color={colors.purple}>
          {currency}
          {thousandSeparator(imperative?.budget) || '-'}
        </BodySmall>
      </Budget>
      {!imperativeTactics.length ? (
        <TacticEmptyState
          style={{
            width: (wrapperRef.current?.offsetWidth || 0) - 66,
          }}
        >
          <Subtitle2 color={colors.greyDark}>
            {emptyStateText || 'No tactics yet'}
          </Subtitle2>
        </TacticEmptyState>
      ) : isOpen ? (
        <IndividualTactics
          imperativeTactics={imperativeTactics}
          minDate={minDate}
          quarterColumnWidth={quarterColumnWidth}
          prependDetailText={prependDetailText}
        />
      ) : (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setIsOpen();
          }}
        >
          <GroupedTactics
            imperativeTactics={imperativeTactics}
            quarterColumnWidth={quarterColumnWidth}
            minDate={minDate}
          />
        </div>
      )}
    </Imperative>
  );
};
