import { useEffect, useState } from 'react';

/** Content scrolls below Header with a shadow effect */
export const useScrollContentBelowHeader = () => {
  const [fadeContent, setFadeContent] = useState(false);

  useEffect(() => {
    const content = document.getElementById('sidebar-content');

    function toggleFadeContent() {
      if (!!content && content.scrollTop > 0) {
        setFadeContent(true);
      } else {
        setFadeContent(false);
      }
    }

    content?.addEventListener('scroll', toggleFadeContent);

    return () => {
      content?.removeEventListener('scroll', toggleFadeContent);
    };
  });

  return fadeContent;
};
