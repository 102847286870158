import { ButtonLabel } from 'components/shared';
import { colors } from 'constants/colors';
import React from 'react';
import styled from 'styled-components';
import { Icon } from './Icon';

const Wrapper = styled.div`
  height: 30px;
  padding: 5px 9px 5px 0;
  border-radius: 25px;
  display: flex;
  align-items: center;
  width: fit-content;
  background: ${colors.white};
  border: 1px solid ${colors.black20a};
`;

interface Props {
  text: string;
  className?: string;
  onClick: () => void;
}
export const ChipButton: React.FC<Props> = ({ text, onClick, className }) => {
  return (
    <Wrapper onClick={onClick} className={className}>
      <Icon
        name="Plus"
        size={30}
        color={colors.greyDark}
        style={{
          borderRadius: '50%',
        }}
      />
      <ButtonLabel>{text}</ButtonLabel>
    </Wrapper>
  );
};
