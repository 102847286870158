import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { Subtitle1, BodyNormal } from 'components/shared';
import FormTextInput from 'components/shared/FormTextInput';
import {
  FutureTrendFragment,
  useFutureTrendUpdateMutation,
} from 'data/graphql/generated';
import { RationaleModal } from 'components/shared/RationaleModal';

const StyledInput = styled(FormTextInput)`
  display: block;
  margin-bottom: 30px;
`;

interface Props {
  trend: FutureTrendFragment;
  modalSlideIdx: number | null;
  setModalSlideIdx: React.Dispatch<React.SetStateAction<number | null>>;
  showSingleSlide: boolean;
}

export const FutureTrendBrandModal: React.FC<Props> = ({
  trend,
  modalSlideIdx,
  setModalSlideIdx,
  showSingleSlide,
}) => {
  const [risk, setRisk] = useState(trend.risk || '');
  const [futureTrendUpdate] = useFutureTrendUpdateMutation();

  const onSubmit = async (focusRationale: string) => {
    await futureTrendUpdate({
      variables: {
        id: trend.id,
        data: {
          focus: true,
          risk,
          focusRationale,
        },
      },
    });
  };

  const closeModal = () => {
    setModalSlideIdx(null);
  };

  return (
    <RationaleModal
      modalSlideIdx={modalSlideIdx}
      setModalSlideIdx={setModalSlideIdx}
      handleClose={closeModal}
      handleSubmit={onSubmit}
      rationaleText={trend.focusRationale}
      showSingleSlide={showSingleSlide}
      modalDetails={{ 'Future trend / Change': trend.title }}
      firstSlideContent={
        <div>
          <Subtitle1 style={{ marginBottom: 10 }}>
            How does this impact the brand?
          </Subtitle1>
          <BodyNormal style={{ marginBottom: 20 }}>
            Describe the opportunity / risk arising
          </BodyNormal>

          <StyledInput
            className="cypress-future-trend-brand-modal-input"
            type="text"
            name="risk"
            title="Opportunity / Risk"
            value={risk}
            onChange={(e) => setRisk(e.currentTarget.value)}
          />
        </div>
      }
    />
  );
};
