import { colors } from 'constants/colors';
import React from 'react';
import styled from 'styled-components/macro';
import { ButtonPill } from '.';
import { ButtonPillWrapper } from './ButtonPill';

const Header = styled.header`
  display: flex;
  gap: 10px;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  margin-top: 5px;
`;

const StyledButtonPill = styled(ButtonPill)`
  max-height: 30px;
  ${ButtonPillWrapper} {
    max-height: 30px;
  }
`;

interface Props {
  showTimeline: boolean;
  hideTimelineButton?: boolean;
  setShowTimeline: (state: boolean) => void;
  className?: string;
  children?: React.ReactNode;
}

export const SummaryHeader = ({
  setShowTimeline,
  showTimeline,
  className,
  hideTimelineButton,
  children,
}: Props) => {
  return (
    <Header className={className}>
      <Buttons>
        {hideTimelineButton ? null : (
          <StyledButtonPill
            text="Timeline"
            level={showTimeline ? 'primary-solid' : 'secondary'}
            color={showTimeline ? colors.white : colors.black}
            onClick={() => {
              setShowTimeline(true);
            }}
          />
        )}
        <StyledButtonPill
          text="Financials"
          level={!showTimeline ? 'primary-solid' : 'secondary'}
          color={!showTimeline ? colors.white : colors.black}
          onClick={() => {
            setShowTimeline(false);
          }}
        />
      </Buttons>
      {children}
    </Header>
  );
};
