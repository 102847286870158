import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { PostItGroupType } from 'data/graphql/generated';
import { HurdlesAndGapsConstants } from '../constants/HurdlesAndGapsConstants';
import { findAreaToExplore } from '../util-find-area-to-explore/findAreaToExplore';
import { AreaToExploreView } from './AreaToExploreView';

interface AreaToExploreViewsProps {
  area: string;
  drugId: number;
  strategyId: number;
  competitiveAdvantageRowId: number;
}

export const AreaToExploreViews = ({
  drugId,
  strategyId,
  competitiveAdvantageRowId,
  area,
}: AreaToExploreViewsProps) => {
  const { path } = useRouteMatch();
  const areaToExplore = findAreaToExplore(area);

  if (!areaToExplore) {
    return (
      <Switch>
        <Redirect
          to={`${path}/${HurdlesAndGapsConstants.POLICIES_AND_GUIDELINES.id}`}
        />
      </Switch>
    );
  }

  if (areaToExplore.id === HurdlesAndGapsConstants.POLICIES_AND_GUIDELINES.id) {
    return (
      <AreaToExploreView
        key={PostItGroupType.PoliciesAndGuidelines}
        drugId={drugId}
        strategyId={strategyId}
        competitiveAdvantageRowId={competitiveAdvantageRowId}
        groupType={PostItGroupType.PoliciesAndGuidelines}
      />
    );
  }

  if (areaToExplore.id === HurdlesAndGapsConstants.DISEASE_AND_THERAPY.id) {
    return (
      <AreaToExploreView
        key={PostItGroupType.DiseaseAndTherapy}
        drugId={drugId}
        strategyId={strategyId}
        competitiveAdvantageRowId={competitiveAdvantageRowId}
        groupType={PostItGroupType.DiseaseAndTherapy}
      />
    );
  }

  if (
    areaToExplore.id ===
    HurdlesAndGapsConstants.MEDICAL_AND_CLINICAL_PRACTICE.id
  ) {
    return (
      <AreaToExploreView
        key={PostItGroupType.MedicalAndClinicalPractice}
        drugId={drugId}
        strategyId={strategyId}
        competitiveAdvantageRowId={competitiveAdvantageRowId}
        groupType={PostItGroupType.MedicalAndClinicalPractice}
      />
    );
  }

  if (areaToExplore.id === HurdlesAndGapsConstants.PRODUCT.id) {
    return (
      <AreaToExploreView
        key={PostItGroupType.Product}
        drugId={drugId}
        strategyId={strategyId}
        competitiveAdvantageRowId={competitiveAdvantageRowId}
        groupType={PostItGroupType.Product}
      />
    );
  }

  return (
    <Switch>
      <Redirect
        to={`${path}/${HurdlesAndGapsConstants.POLICIES_AND_GUIDELINES.id}`}
      />
    </Switch>
  );
};
