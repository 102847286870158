import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { FetchResult } from '@apollo/client';

import {
  useCriticalMetricGoalCreateMutation,
  Step,
  SubStep,
  CriticalMetricGoalsDocument,
  CriticalMetricGoalCreateMutation,
} from 'data/graphql/generated';
import { CriticalMetricsParams } from '../../../../../shared/shared-data-access-critical-metrics/src';

export type CreateCriticalMetricGoalResponse = FetchResult<
  CriticalMetricGoalCreateMutation,
  Record<string, any>,
  Record<string, any>
>;

export type CreateCriticalMetricGoalFn = () => Promise<CreateCriticalMetricGoalResponse>;

export const useCreateCriticalMetricGoal = () => {
  const {
    strategyId,
    competitiveAdvantageRowUrlParam,
  } = useParams<CriticalMetricsParams>();

  const [createRow] = useCriticalMetricGoalCreateMutation();

  const handleCreateRow = useCallback<CreateCriticalMetricGoalFn>(async () => {
    return await createRow({
      variables: {
        data: {
          strategyId: +strategyId,
          competitiveAdvantageRowId: +competitiveAdvantageRowUrlParam,
          step: Step.CriticalMetrics,
          subStep: SubStep.Goals,
        },
      },
      refetchQueries: [
        {
          query: CriticalMetricGoalsDocument,
          variables: {
            where: {
              strategyId: +strategyId,
              competitiveAdvantageRowId: +competitiveAdvantageRowUrlParam,
              step: Step.CriticalMetrics,
              subStep: SubStep.Goals,
            },
          },
          fetchPolicy: 'cache-only',
        },
      ],
    });
  }, [competitiveAdvantageRowUrlParam, createRow, strategyId]);

  return handleCreateRow;
};
