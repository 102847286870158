import { useContext } from 'react';
import { PatientJourneyPageContext } from '.';

export function usePatientJourneyPageContext() {
  const context = useContext(PatientJourneyPageContext);

  if (context === undefined) {
    throw new Error(
      'usePatientJourneyPageContext must be used within a PatientJourneyPageProvider'
    );
  }

  return context;
}
