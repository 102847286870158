import { colors } from 'constants/index';
import React from 'react';
import styled from 'styled-components/macro';
import {
  dateStringToMonthYear,
  monthYearToDateTimestamp,
} from 'utils/dateStringToMonthYear';
import { formatTimestamp } from 'utils/formatTimestamp';
import { DatePicker } from './DatePicker';
import { ErrorContainer } from './FormField';
import FormTextInput, { Input } from './FormTextInput';
import { Icon } from './Icon';

const StyledDatePicker = styled(DatePicker)`
  position: absolute;
  inset: 0;
  height: fit-content;
  top: 50px;
  z-index: 1;
`;

const DueTextInput = styled(FormTextInput)`
  display: block;
  pointer-events: none;

  ${ErrorContainer} {
    position: relative;
  }

  ${Input} {
    padding-left: 32px;
  }
  margin-bottom: 30px;
`;

type Props = {
  errorMessage?: string;
  name: string;
  title: string;
  date: string | null | undefined;
  lowerLimit?: string;
  onUpdate(date: string | null | undefined): void;
  setShowDatePicker: React.Dispatch<React.SetStateAction<string>>;
  showDatePicker: boolean;
  onInputClick?(): void;
};

const DateTextInput = ({
  errorMessage,
  name,
  title,
  date,
  lowerLimit,
  onUpdate,
  setShowDatePicker,
  showDatePicker,
  onInputClick,
}: Props) => {
  const formattedDate = dateStringToMonthYear(date);
  const formattedLowerLimit = dateStringToMonthYear(lowerLimit);

  return (
    <div style={{ position: 'relative' }}>
      <div
        onClick={() => {
          setShowDatePicker((state) => {
            if (state === name) {
              return '';
            } else return name;
          });
          onInputClick?.();
        }}
        style={{
          cursor: 'pointer',
        }}
      >
        <DueTextInput
          errorMessage={errorMessage || ''}
          name={name}
          type="text"
          onChange={() => {}}
          value={!!date ? formatTimestamp(date) : ''}
          title={title}
        />
        <Icon
          name="Calendar"
          size={20}
          color={colors.greyDark}
          style={{
            position: 'absolute',
            inset: 0,
            left: 7,
            top: 10,
            height: 'fit-content',
          }}
        />
      </div>
      <StyledDatePicker
        lowerLimit={
          !!lowerLimit
            ? { month: formattedLowerLimit[0], year: formattedLowerLimit[1] }
            : undefined
        }
        date={
          !!date
            ? { month: formattedDate[0], year: formattedDate[1] }
            : undefined
        }
        visible={showDatePicker}
        onUpdate={(date) => {
          setShowDatePicker('');
          if (!!date) {
            const timestamp = monthYearToDateTimestamp([date.month, date.year]);
            if (!!timestamp) {
              onUpdate(timestamp + '');
            }
          } else {
            onUpdate(date);
          }
        }}
      />
    </div>
  );
};

export default DateTextInput;
