import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { BodySmall, Subtitle2, ButtonPill } from 'components/shared';
import { colors } from 'constants/colors';
import {
  EndpointTargetFragment,
  EndpointTargetCompetitiveLandscapeOpportunitiesFragment,
  useEndpointTargetCreateMutation,
} from 'data/graphql/generated';
import {
  TableRow,
  ColumnTitle,
  ColumnMeasurement,
  ColumnFeasibility,
  ColumnImpact,
  ColumnCost,
} from './TableRow';

const Group = styled.div`
  padding: 15px;
  background: ${colors.white};
  margin-bottom: 15px;

  max-width: 575px;
  margin: 0 auto 15px auto;

  @media (min-width: 480px) {
    border: 1px solid rgba(20, 20, 39, 0.1);
    border-radius: 5px;
  }

  @media (min-width: 945px) {
    max-width: 905px;
  }

  @media (min-width: 1200px) {
    max-width: 1400px;
  }
`;

const GroupHeader = styled.header`
  padding: 0px 15px 10px 15px;
  position: relative;
  display: flex;
  flex-direction: row;

  @media (max-width: 945px) {
    display: none;
  }
`;

const GroupTitle = styled(BodySmall)`
  color: ${colors.greyDark};
  font-weight: 400;
  display: inline-block;
`;

const GroupItems = styled.div`
  border-top: 1px solid rgba(232, 232, 233, 1);
`;

const GroupEmpty = styled.div`
  background: ${colors.yellow20};
  text-align: center;
  padding: 11px;
  margin: 0px 15px;
`;

export const Table: React.FC<{
  opportunity: EndpointTargetCompetitiveLandscapeOpportunitiesFragment;
  endpointTargets: EndpointTargetFragment[];
  strategyId: number;
  refetch: any;
}> = ({ opportunity, endpointTargets, strategyId, refetch }) => {
  const [creatingEndpoint, setCreatingEndpoint] = useState(false);
  const [createEndpointTarget] = useEndpointTargetCreateMutation();
  const [autoFocus, setAutoFocus] = useState(false);
  return (
    <Group>
      <Subtitle2 style={{ margin: '15px 15px 20px 15px' }}>
        {opportunity?.postItGroup?.title}
      </Subtitle2>
      <div>
        <GroupHeader>
          <ColumnTitle>
            <GroupTitle>Endpoint target</GroupTitle>
          </ColumnTitle>
          <ColumnMeasurement>
            <GroupTitle>Measurement</GroupTitle>
          </ColumnMeasurement>
          <ColumnFeasibility>
            <GroupTitle>Feasibility</GroupTitle>
          </ColumnFeasibility>
          <ColumnImpact>
            <GroupTitle>Impact</GroupTitle>
          </ColumnImpact>
          <ColumnCost>
            <GroupTitle>Cost</GroupTitle>
          </ColumnCost>
        </GroupHeader>
      </div>

      {(endpointTargets.length || 0) > 0 ? (
        <GroupItems>
          {endpointTargets.map((endpointTarget) => (
            <TableRow
              autoFocus={autoFocus}
              endpointTarget={endpointTarget}
              refetch={refetch}
              key={endpointTarget.id}
            />
          ))}
        </GroupItems>
      ) : (
        <GroupEmpty>
          <Subtitle2 style={{ color: colors.greyDark }}>
            No endpoint targets
          </Subtitle2>
          <BodySmall style={{ color: colors.greyDark }}>
            Add endpoint targets to this outcome to share your ideas
          </BodySmall>
        </GroupEmpty>
      )}

      <div style={{ margin: 15 }}>
        <ButtonPill
          iconName="Plus"
          text="Add evidence generation idea"
          level="secondary"
          clickClassName="cypress-endpoint-add"
          loading={creatingEndpoint}
          onClick={async () => {
            setCreatingEndpoint(true);
            setAutoFocus(true);

            await createEndpointTarget({
              variables: {
                data: {
                  strategyId: +strategyId,
                  opportunityId: opportunity.id,
                  title: '',
                  measurement: '',
                  feasibility: 0,
                  impact: 0,
                  cost: 0,
                },
              },
            });
            await refetch();
            setCreatingEndpoint(false);
          }}
        />
      </div>
    </Group>
  );
};
