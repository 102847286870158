import { useMemo, useState } from 'react';

import { CriticalMetricGoalInput } from 'data/graphql/generated';
import { GoalInputDropdownOptionType } from '../../../../shared-ui-goals/src';

export const useGoalInput = (initialGoal?: CriticalMetricGoalInput | null) => {
  const inputMemo = useMemo(() => {
    return initialGoal != null ? { id: initialGoal, value: initialGoal } : null;
  }, [initialGoal]);

  const [input, setInput] = useState<GoalInputDropdownOptionType | null>(
    inputMemo
  );

  return {
    input,
    setInput,
  };
};
