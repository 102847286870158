import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { CTAWrapper, ModalForm } from './ModalForm';
import { ButtonPill } from './shared';
import { ButtonLevel } from './shared/ButtonPill';

const ButtonWrapper = styled(CTAWrapper)`
  gap: 15px;
  flex-direction: column-reverse;
  align-items: center;

  @media ${device.tabletMin} {
    flex-direction: row;
  }
`;

interface Props {
  leftButtonOnClick: () => void;
  rightButtonOnClick?: () => void;
  leftButtonText?: string;
  rightButtonText: string;
  leftButtonLevel?: ButtonLevel;
  rightButtonLevel?: ButtonLevel;
  rightButtonDisabled?: boolean;
  title: string;
  body?: string;
  modalOpen: boolean;
  leftButtonLoading?: boolean;
  rightButtonLoading?: boolean;
  handleClose: () => void;
  className?: string;
}

export default function TwoButtonModal({
  leftButtonOnClick,
  rightButtonOnClick,
  leftButtonText = 'Cancel',
  rightButtonText,
  leftButtonLevel = 'secondary',
  rightButtonLevel = 'primary',
  rightButtonDisabled,
  title,
  body,
  modalOpen,
  handleClose,
  leftButtonLoading,
  rightButtonLoading,
  className,
}: Props) {
  return ReactDOM.createPortal(
    <ModalForm
      handleClose={handleClose}
      visible={modalOpen}
      heading={title}
      message={body}
      className={className}
    >
      <ButtonWrapper>
        <ButtonPill
          width="180px"
          text={leftButtonText}
          level={leftButtonLevel}
          onClick={leftButtonOnClick}
          loading={leftButtonLoading}
        />
        <ButtonPill
          disabled={rightButtonDisabled}
          level={rightButtonLevel}
          width="180px"
          text={rightButtonText}
          onClick={rightButtonOnClick}
          loading={rightButtonLoading}
        />
      </ButtonWrapper>
    </ModalForm>,
    document.body
  );
}
