import { colors } from 'constants/index';
import React, { useState } from 'react';
import { Rect } from 'react-konva';
import { Tooltip } from '../..';

type Props = {
  enableHover?: boolean;
  inRecapSidebar?: boolean;
};

const CardIconBackground = ({
  enableHover = false,
  inRecapSidebar = false,
}: Props) => {
  const [bgColour, setBgColour] = useState('transparent');
  const text = inRecapSidebar ? 'View in Patient Journey' : 'More details';
  return (
    <>
      <Rect
        width={30}
        height={30}
        sides={4}
        cornerRadius={3}
        fill={bgColour}
        onMouseEnter={() => {
          if (!enableHover) return;
          setBgColour(colors.black10);
        }}
        onMouseLeave={() => {
          if (!enableHover) return;
          setBgColour('transparent');
        }}
      />
      <Tooltip
        text={text}
        width={55}
        visible={enableHover && bgColour !== 'transparent'}
        y={35}
        x={10}
      />
    </>
  );
};

export default CardIconBackground;
