import {
  DeleteConfirmCancel,
  DeleteConfirmConfirm,
  DeleteConfirmWrapper,
} from 'components/CreatingValue/TableRow';
import { ErrorModal } from 'components/ErrorModal';
import {
  BodyNormal,
  BodySmall,
  ButtonRound,
  Collaboration,
  EditableTextarea,
  Icon,
  TextDropdown,
  UserDropdown,
} from 'components/shared';
import { TooltipWrapper } from 'components/shared/ButtonRound';
import FocusDropdown from 'components/shared/FocusDropdown';
import { colors } from 'constants/index';
import {
  LeveragePointFragment,
  Stakeholder,
  StrategicPossibilityFragment,
  User,
} from 'data/graphql/generated';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { StrategicPossibilityUnlockingInsight } from 'types';
import { verifyUserRole } from 'utils/verifyUserRole';
import { ImperativeModal } from './ImperativeModal';
import { LeveragePointTableProps, RowTitle } from './LeveragePointTable';

const tableColumns = css`
  grid-template-columns: repeat(4, minmax(110px, 250px));
`;

const StrategicPossibilityDesktop = styled.div<{
  mayEditRow: boolean;
  isImperative: boolean;
}>`
  display: flex;
  border-top: 1px solid ${colors.greyLight};
  justify-content: space-evenly;

  ${TooltipWrapper} {
    visibility: hidden;
  }

  &:hover {
    ${TooltipWrapper} {
      ${({ mayEditRow }) => mayEditRow && `visibility: visible;`}
    }
  }

  ${({ isImperative }) => {
    if (isImperative) {
      return css`
        position: relative;
        border-radius: 5px;
        background: linear-gradient(315deg, #ff00c8 -83%, #7800ff 93.75%);
        padding: 1px;
        margin: 5px 0;

        ::after {
          content: '';
          position: absolute;
          top: -5px;
          left: 0;
          border-top: 1px solid ${colors.greyLight};
          height: 100%;
          width: 100%;
          pointer-events: none;
        }

        > .StrategicPossibilityDesktop__wrapper {
          position: relative;
          background: white;
          border-radius: 4px;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: ${colors.fadedGradient};
            opacity: 1;
            pointer-events: none;
          }
        }
      `;
    }
  }}
`;

const RowFieldsDesktop = styled.div`
  display: grid;
  ${tableColumns}
  align-items: flex-start;
  grid-gap: 20px;
  padding: 20px 0 20px 15px;
  min-height: 70px;
  > textarea {
    margin-top: 5px;
  }
`;

const RowExtras = styled.div`
  min-width: 280px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const StrategicPossibilityMobile = styled.div<{
  mayEditRow: boolean;
  isImperative: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  border-top: 1px solid ${colors.greyLight};

  > .StrategicPossibilityMobile__wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  ${TooltipWrapper} {
    visibility: ${({ mayEditRow }) => (mayEditRow ? 'visible' : 'hidden')};
  }

  ${({ isImperative }) => {
    if (isImperative) {
      return css`
        position: relative;
        border: none;
        background: white;
        margin: 5px 0;

        &::before {
          content: '';
          position: absolute;
          top: 0px;
          left: -10px;
          width: calc(100% + 20px);
          height: 100%;
          background: linear-gradient(315deg, #ff00c8 -83%, #7800ff 93.75%);
          opacity: 1;
          pointer-events: none;
          border-radius: 5px;
        }

        ::after {
          content: '';
          position: absolute;
          top: -5px;
          left: 0;
          border-top: 1px solid ${colors.greyLight};
          height: 100%;
          width: 100%;
          pointer-events: none;
        }

        > .StrategicPossibilityMobile__wrapper {
          display: flex;
          flex-direction: column;
          gap: 15px;
          z-index: 1;
          position: relative;
          background: white;

          &::before {
            content: '';
            position: absolute;
            top: -14px;
            left: -9px;
            width: calc(100% + 18px);
            height: calc(100% + 28px);
            background: white;

            opacity: 1;
            pointer-events: none;
            border-radius: 4px;
            z-index: -1;
          }

          ::after {
            content: '';
            position: absolute;
            top: -14px;
            left: -9px;
            width: calc(100% + 18px);
            height: calc(100% + 28px);
            background: ${colors.fadedGradient};
            pointer-events: none;
          }
        }
      `;
    }
  }}
`;

const FocusAndDelete = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlagAndCollaboration = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

interface RowsProps {
  isDesktop: boolean;
  autoFocus: boolean;
  possibilityId: number;
  initialValue: string;
  keyStakeholdersList: LeveragePointFragment['keyStakeholdersList'];
  strategicPossibility: StrategicPossibilityFragment;
  user?: User;
  deleteRowConfirmation: number | null;
  updateStrategicPossibility: LeveragePointTableProps['updateStrategicPossibility'];
  relevantKeyInsights: LeveragePointFragment['unlockingInsightsList'];
  setDeleteRowConfirmation: React.Dispatch<React.SetStateAction<number | null>>;
  deleteStrategicPossibility: LeveragePointTableProps['deleteStrategicPossibility'];
  setAutoFocus: (value: React.SetStateAction<boolean>) => void;
}

export const StrategicPossibilityRow: React.FC<RowsProps> = ({
  isDesktop,
  autoFocus,
  possibilityId,
  initialValue,
  keyStakeholdersList,
  strategicPossibility,
  user,
  deleteRowConfirmation,
  updateStrategicPossibility,
  relevantKeyInsights,
  setDeleteRowConfirmation,
  deleteStrategicPossibility,
  setAutoFocus,
}) => {
  const { drugId, strategyId } = useParams<{
    drugId: string;
    strategyId: string;
  }>();

  const [isImperative, setIsImperative] = useState(
    !!strategicPossibility.imperative
  );
  // imperative modal logic
  const [modalSlideIdx, setModalSlideIdx] = useState<number | null>(null);
  const [showSingleSlide, setShowSingleSlide] = useState<boolean>(false);
  const openModal = () => setModalSlideIdx(0);
  const closeModal = () => {
    setShowSingleSlide(false);
    setModalSlideIdx(null);
  };
  const openRenameSlide = () => {
    setShowSingleSlide(true);
    setModalSlideIdx(0);
  };

  const { isLead } = verifyUserRole(user?.role, user?.country);

  const [inUseErrorModalType, setInUseErrorModalType] = useState<
    'delete' | 'remove' | null
  >(null);

  useEffect(() => {
    setIsImperative(!!strategicPossibility.imperative);
  }, [strategicPossibility.imperative]);

  async function handleModalSubmit(name: string, rationale: string) {
    return await updateStrategicPossibility(strategicPossibility.id, {
      name,
      rationale,
      imperative: true,
    });
  }

  async function toggleFocus() {
    if (!isImperative) {
      openModal();
      return;
    }

    try {
      setIsImperative(false);

      await updateStrategicPossibility(possibilityId, {
        imperative: false,
      });
    } catch (error) {
      setIsImperative(true);
      if (error instanceof Error && error.message === 'IN_USE')
        setInUseErrorModalType('remove');
    }
  }

  if (!isDesktop) {
    return (
      <>
        <ImperativeModal
          modalSlideIdx={modalSlideIdx}
          setModalSlideIdx={setModalSlideIdx}
          handleClose={closeModal}
          textValue={strategicPossibility.name}
          handleSubmit={handleModalSubmit}
          modalDetails={{
            'Behaviour to drive': strategicPossibility.behaviourToDrive || '',
            'Driver of the behaviour':
              strategicPossibility.driverOfTheBehaviour || '',
          }}
          rationaleText={strategicPossibility.rationale}
          showSingleSlide={showSingleSlide}
        />
        <ErrorModal
          title={
            inUseErrorModalType === 'delete'
              ? 'Cannot delete this row'
              : inUseErrorModalType === 'remove'
              ? 'Cannot remove focus from this row'
              : ''
          }
          text={
            inUseErrorModalType === 'delete'
              ? 'Content in later steps depends on this strategic possibility. Remove content and try again.'
              : inUseErrorModalType === 'remove'
              ? 'Content in later steps depends on this strategic possibility. Remove content and try again.'
              : ''
          }
          handleClose={() => setInUseErrorModalType(null)}
          visible={!!inUseErrorModalType}
        />

        <StrategicPossibilityMobile
          isImperative={isImperative}
          key={strategicPossibility.id}
          mayEditRow
        >
          <div className={'StrategicPossibilityMobile__wrapper'}>
            <FocusAndDelete>
              <FocusDropdown
                onClick={toggleFocus}
                checked={isImperative}
                disabled={!isLead}
                rationaleText={strategicPossibility.rationale}
                setModalSlideIdx={setModalSlideIdx}
                setShowSingleSlide={setShowSingleSlide}
              />
              {deleteRowConfirmation === strategicPossibility.id ? (
                <DeleteConfirmation
                  onCancel={() => setDeleteRowConfirmation(null)}
                  onConfirm={() => {
                    deleteStrategicPossibility(strategicPossibility.id);
                  }}
                />
              ) : (
                <ButtonRound
                  level="secondary"
                  iconName="Trash"
                  size="small"
                  onClick={() =>
                    setDeleteRowConfirmation(strategicPossibility.id)
                  }
                />
              )}
            </FocusAndDelete>

            <div>
              <RowTitle text="Behaviour to drive" />
              <EditableTextarea
                editable
                placeholder="Behaviour to drive"
                initialValue={strategicPossibility.behaviourToDrive || ''}
                onBlur={(newValue) =>
                  updateStrategicPossibility(strategicPossibility.id, {
                    behaviourToDrive: newValue,
                  })
                }
              />
            </div>

            <div>
              <RowTitle text="Key Stakeholder" />
              <UserDropdown
                options={keyStakeholdersList}
                selectedOption={
                  strategicPossibility.keyStakeholder ||
                  (!!strategicPossibility.stakeholder
                    ? {
                        __typename: 'StakeholderDefinition',
                        id: 0,
                        image: undefined,
                        title:
                          strategicPossibility.stakeholder ===
                          Stakeholder.Healthcare
                            ? 'Healthcare Professionals'
                            : strategicPossibility.stakeholder,
                        stakeholder: strategicPossibility.stakeholder,
                      }
                    : null)
                }
                clearSelection={() => {
                  updateStrategicPossibility(strategicPossibility.id, {
                    keyStakeholderId: null,
                    stakeholder: null,
                  });
                }}
                selectOption={(option) => {
                  if (!!option.id) {
                    updateStrategicPossibility(strategicPossibility.id, {
                      keyStakeholderId: option.id,
                      stakeholder: null,
                    });
                  } else {
                    updateStrategicPossibility(strategicPossibility.id, {
                      keyStakeholderId: null,
                      stakeholder: option.stakeholder,
                    });
                  }
                }}
                editable
              />
            </div>
            <div>
              <RowTitle text="Unlocking insight" />
              <TextDropdown
                options={relevantKeyInsights}
                selectedOption={strategicPossibility.unlockingInsight}
                clearSelection={() => {
                  updateStrategicPossibility(strategicPossibility.id, {
                    unlockingInsightId: null,
                  });
                }}
                selectOption={(
                  option: StrategicPossibilityUnlockingInsight
                ) => {
                  updateStrategicPossibility(strategicPossibility.id, {
                    unlockingInsightId: option.id,
                  });
                }}
                editable={
                  !!strategicPossibility.keyStakeholder ||
                  !!strategicPossibility.stakeholder
                }
                emptyState={{
                  header: 'No insights',
                  text: (
                    <div>
                      <BodySmall
                        style={{
                          display: 'inline',
                        }}
                        color={colors.greyDark}
                      >
                        Add them at
                      </BodySmall>
                      <Link
                        to={`/d/${drugId}/strategy/${strategyId}/1_5/${strategicPossibility.keyStakeholder?.stakeholder}`}
                        style={{
                          color: colors.greyDark,
                          display: 'inline',
                          fontSize: 14,
                          fontWeight: 500,
                        }}
                      >
                        {' '}
                        1.5 Key Insights.
                      </Link>
                    </div>
                  ),
                }}
              />
            </div>
            <div>
              <RowTitle text="Driver of the behaviour" />
              <EditableTextarea
                editable
                placeholder="Driver of the behaviour"
                initialValue={strategicPossibility.driverOfTheBehaviour || ''}
                onBlur={(newValue) =>
                  updateStrategicPossibility(strategicPossibility.id, {
                    driverOfTheBehaviour: newValue,
                  })
                }
              />
            </div>
            {isImperative && (
              <StrategicImperativeName
                isMobile
                name={strategicPossibility.name}
                isLead={isLead}
                onClick={openRenameSlide}
              />
            )}
            <div>
              <FlagAndCollaboration>
                <Collaboration
                  collaboration={strategicPossibility.collaboration}
                />
              </FlagAndCollaboration>
            </div>
          </div>
        </StrategicPossibilityMobile>
      </>
    );
  }

  return (
    <>
      <ImperativeModal
        modalSlideIdx={modalSlideIdx}
        setModalSlideIdx={setModalSlideIdx}
        handleClose={closeModal}
        textValue={strategicPossibility.name}
        handleSubmit={handleModalSubmit}
        modalDetails={{
          'Behaviour to drive': strategicPossibility.behaviourToDrive || '',
          'Driver of the behaviour':
            strategicPossibility.driverOfTheBehaviour || '',
        }}
        rationaleText={strategicPossibility.rationale}
        showSingleSlide={showSingleSlide}
      />

      <ErrorModal
        title={
          inUseErrorModalType === 'delete'
            ? 'Cannot delete this row'
            : inUseErrorModalType === 'remove'
            ? 'Cannot remove focus from this row'
            : ''
        }
        text={
          inUseErrorModalType === 'delete'
            ? 'Content in later steps depends on this strategic possibility. Remove content and try again.'
            : inUseErrorModalType === 'remove'
            ? 'Content in later steps depends on this strategic possibility. Remove content and try again.'
            : ''
        }
        handleClose={() => setInUseErrorModalType(null)}
        visible={!!inUseErrorModalType}
      />

      <StrategicPossibilityDesktop
        isImperative={isImperative}
        key={possibilityId}
        mayEditRow
      >
        <div
          className={'StrategicPossibilityDesktop__wrapper'}
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <div
            className={'StrategicPossibilityDesktop__wrapper__mainContent'}
            style={{ display: 'flex' }}
          >
            <RowFieldsDesktop id="row-fields-desktop">
              <EditableTextarea
                autoFocus={autoFocus}
                editable
                placeholder="Behaviour to drive"
                initialValue={initialValue}
                onBlur={(newValue) => {
                  setAutoFocus(false);

                  updateStrategicPossibility(possibilityId, {
                    behaviourToDrive: newValue,
                  });
                }}
              />
              <UserDropdown
                options={keyStakeholdersList}
                selectedOption={
                  strategicPossibility.keyStakeholder ||
                  (!!strategicPossibility.stakeholder
                    ? {
                        __typename: 'StakeholderDefinition',
                        id: 0,
                        image: undefined,
                        title:
                          strategicPossibility.stakeholder ===
                          Stakeholder.Healthcare
                            ? 'Healthcare Professionals'
                            : strategicPossibility.stakeholder,
                        stakeholder: strategicPossibility.stakeholder,
                      }
                    : null)
                }
                clearSelection={() => {
                  updateStrategicPossibility(strategicPossibility.id, {
                    keyStakeholderId: null,
                    stakeholder: null,
                  });
                }}
                selectOption={(option) => {
                  if (option.id) {
                    updateStrategicPossibility(strategicPossibility.id, {
                      keyStakeholderId: option.id,
                      stakeholder: null,
                    });
                  } else {
                    updateStrategicPossibility(strategicPossibility.id, {
                      keyStakeholderId: null,
                      stakeholder: option.stakeholder,
                    });
                  }
                }}
                editable
              />
              <TextDropdown
                options={relevantKeyInsights}
                selectedOption={strategicPossibility.unlockingInsight}
                clearSelection={() => {
                  updateStrategicPossibility(possibilityId, {
                    unlockingInsightId: null,
                  });
                }}
                selectOption={(
                  option: StrategicPossibilityUnlockingInsight
                ) => {
                  updateStrategicPossibility(possibilityId, {
                    unlockingInsightId: option.id,
                  });
                }}
                editable={
                  !!strategicPossibility.keyStakeholder ||
                  !!strategicPossibility.stakeholder
                }
                emptyState={{
                  header: 'No insights',
                  text: (
                    <div>
                      <BodySmall
                        style={{
                          display: 'inline',
                        }}
                        color={colors.greyDark}
                      >
                        Add them at
                      </BodySmall>
                      <Link
                        to={`/d/${drugId}/strategy/${strategyId}/1_5/${strategicPossibility.keyStakeholder?.stakeholder}`}
                        style={{
                          color: colors.greyDark,
                          display: 'inline',
                          fontSize: 14,
                          fontWeight: 500,
                        }}
                      >
                        {' '}
                        1.5 Key Insights.
                      </Link>
                    </div>
                  ),
                }}
              />

              <EditableTextarea
                editable
                placeholder="Driver of the behaviour"
                initialValue={strategicPossibility.driverOfTheBehaviour || ''}
                onBlur={(newValue) =>
                  updateStrategicPossibility(possibilityId, {
                    driverOfTheBehaviour: newValue,
                  })
                }
              />
            </RowFieldsDesktop>
            <RowExtras>
              <FocusDropdown
                onClick={toggleFocus}
                checked={isImperative}
                disabled={!isLead}
                rationaleText={strategicPossibility.rationale}
                setModalSlideIdx={setModalSlideIdx}
                setShowSingleSlide={setShowSingleSlide}
              />
              <>
                <Collaboration
                  collaboration={strategicPossibility.collaboration}
                />
                <div style={{ position: 'relative' }}>
                  {deleteRowConfirmation === possibilityId && (
                    <DeleteConfirmation
                      onCancel={() => setDeleteRowConfirmation(null)}
                      onConfirm={async () => {
                        try {
                          await deleteStrategicPossibility(possibilityId);
                        } catch (error) {
                          if (
                            error instanceof Error &&
                            error.message === 'IN_USE'
                          ) {
                            return setInUseErrorModalType('delete');
                          }
                        }
                      }}
                    />
                  )}
                  <ButtonRound
                    level="secondary"
                    iconName="Trash"
                    size="small"
                    onClick={() => setDeleteRowConfirmation(possibilityId)}
                  />
                </div>
              </>
            </RowExtras>
          </div>

          {isImperative && (
            <StrategicImperativeName
              name={strategicPossibility.name}
              isLead={isLead}
              onClick={openRenameSlide}
            />
          )}
        </div>
      </StrategicPossibilityDesktop>
    </>
  );
};

const StyledDeleteConfirmWrapper = styled(DeleteConfirmWrapper)`
  position: relative;
  transform: none;
  top: 0;
  right: 0;
`;

interface DeleteConfirmationProps {
  onConfirm: () => void;
  onCancel: () => void;
}

const DeleteConfirmation = ({
  onConfirm,
  onCancel,
}: DeleteConfirmationProps) => (
  <div style={{ position: 'absolute', right: 0 }}>
    <StyledDeleteConfirmWrapper>
      <DeleteConfirmCancel onClick={onCancel}>Cancel</DeleteConfirmCancel>
      <DeleteConfirmConfirm onClick={onConfirm}>
        Delete row
      </DeleteConfirmConfirm>
    </StyledDeleteConfirmWrapper>
  </div>
);

const StrategicImperativeName = ({
  name,
  isLead,
  onClick,
  isMobile,
}: {
  name: string;
  isLead: boolean;
  onClick(): void;
  isMobile?: boolean;
}) => {
  if (isMobile) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', gap: 5 }}>
          <BodySmall color={colors.greyDark}>Strategic imperative</BodySmall>

          {isLead && (
            <Icon
              style={{ cursor: 'pointer' }}
              size={20}
              name="Pencil"
              color={colors.black}
              onClick={onClick}
            />
          )}
        </div>

        <BodySmall style={{ wordWrap: 'break-word' }}>{name}</BodySmall>
      </div>
    );
  }

  return (
    <div
      data-cy="Strategic-Imperative-Name"
      style={{ padding: 15, display: 'flex', gap: 5, alignItems: 'flex-end' }}
    >
      <BodyNormal color={colors.greyDark}>Name:</BodyNormal>
      <BodyNormal>{name}</BodyNormal>
      {isLead && (
        <Icon
          style={{ cursor: 'pointer' }}
          size={20}
          name="Pencil"
          color={colors.black}
          onClick={() => {
            onClick();
          }}
        />
      )}
    </div>
  );
};
