export const featureSet: any = [
  { id: 1, feature: '1.1' },
  { id: 2, feature: '1.2' },
  { id: 3, feature: '1.3' },
  { id: 4, feature: '1.4' },
  { id: 5, feature: '1.5' },
  { id: 6, feature: '2.1' },
  { id: 7, feature: '2.2' },
  { id: 8, feature: '2.3' },
  { id: 9, feature: '2.4' },
  { id: 10, feature: '2.5' },
  { id: 11, feature: '3.1' },
  { id: 12, feature: '3.2' },
  { id: 13, feature: '3.3' },
  { id: 14, feature: '3.4' },
  { id: 15, feature: '3.5' },
  { id: 16, feature: '4.1' },
  { id: 17, feature: '4.2' },
  { id: 18, feature: '4.3' },
  { id: 19, feature: '4.4' },
  { id: 20, feature: '4.5' }
] as const;