export const YEAR_COLUMN_WIDTH = 5;
export const QUARTER_COLUMN_WIDTH = 1;
export const SCROLLBAR_PADDING = 20;
export const LEFT_SIDE_PADDING = 28;
export const MOBILE_LEFT_SIDE_PADDING = 18;
export const DIAMOND_WIDTH = 10.6;

export const TIMELINE_TOP_MARGIN = 5;
export const MOBILE_TIMELINE_TOP_MARGIN = 0;

export const MONTHS_PER_QUARTER = 3;
export const KEY_EVENT_ICON_WIDTH = 24;
