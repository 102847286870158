import { useQuery } from 'hooks/useQuery';
import { useEffect } from 'react';
import { ActionTypeEnum } from '../types';

type Props = {
  setActionType: React.Dispatch<React.SetStateAction<ActionTypeEnum>>;
};

function isBlockOrColumnTextAreaVisible() {
  const blockTextAreaVisible = document.querySelector('.block-textarea');
  const columnTextAreaVisible = document.querySelector('.column-background');

  return Boolean(blockTextAreaVisible || columnTextAreaVisible);
}

export const useKeyboardShortcuts = ({ setActionType }: Props) => {
  let query = useQuery();
  const collaboration = query.get('collaboration');

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (isBlockOrColumnTextAreaVisible() || collaboration) return;

      if (['v', 'V'].includes(e.key)) {
        setActionType(ActionTypeEnum.INTERACTIVE);
      }
      if (['h', 'H'].includes(e.key)) {
        setActionType(ActionTypeEnum.DRAG);
      }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (isBlockOrColumnTextAreaVisible() || collaboration || e.repeat) return;

      let prevAction = ActionTypeEnum.DRAG;

      if (['Space'].includes(e.code)) {
        setActionType((v) => {
          prevAction = v;
          return ActionTypeEnum.DRAG;
        });
      }
      window.addEventListener(
        'keyup',
        (e) => {
          if (isBlockOrColumnTextAreaVisible() || collaboration || e.repeat)
            return;

          if (['Space'].includes(e.code)) {
            setActionType(prevAction);
          }
        },
        { once: true }
      );
    };

    window.addEventListener('keypress', handleKeyPress);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [collaboration, setActionType]);
};
