import { ErrorModal } from 'components/ErrorModal';
import { CTAWrapper } from 'components/ModalForm';
import { ButtonPill } from 'components/shared';
import {
  DismissedModals,
  User,
  useUserSafeUpdateMutation,
} from 'data/graphql/generated';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const CannotEditModal = styled(ErrorModal)`
  ${CTAWrapper} {
    flex-direction: row;
  }
`;

type Props = {
  title: string;
  text: string;
  userCanEdit: boolean;
  user: User | undefined;
  modalName: DismissedModals;
  localStorageString: string;
};

export const ReusableCannotEditModal = ({
  title,
  text,
  user,
  userCanEdit,
  modalName,
  localStorageString,
}: Props) => {
  const [updateUser] = useUserSafeUpdateMutation();

  const [showCannotEditModal, setShowCannotEditModal] = useState(false);

  useEffect(() => {
    if (!!userCanEdit) return;
    const showModalLocalStorage = !sessionStorage.getItem(localStorageString);
    const showModal = !user?.dismissedModals?.includes(modalName);

    showModal
      ? setShowCannotEditModal(showModalLocalStorage)
      : setShowCannotEditModal(false);
  }, [userCanEdit, user?.dismissedModals, modalName, localStorageString]);

  if (!user) return null;
  return (
    <CannotEditModal
      handleClose={() => {
        sessionStorage.setItem(localStorageString, String(false));
        setShowCannotEditModal(false);
      }}
      title={title}
      text={text}
      visible={showCannotEditModal}
    >
      <ButtonPill
        level="secondary"
        text="Don't show this again"
        onClick={async () => {
          try {
            if (!user.dismissedModals) return;

            const updatedDismissedModals = [...user.dismissedModals, modalName];

            await updateUser({
              variables: {
                data: {
                  dismissedModals: updatedDismissedModals,
                },
              },
            });
          } catch (error) {
            alert('Something went wrong');
          } finally {
            sessionStorage.setItem(localStorageString, String(false));
            setShowCannotEditModal(false);
          }
        }}
      />
    </CannotEditModal>
  );
};
