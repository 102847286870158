import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { Page, Wrapper } from 'components/Page';
import {
  BodySmall,
  StepHeaderBar,
  StrategicSummary,
  Subtitle2,
} from 'components/shared';
import { colors } from 'constants/colors';
import { polling } from 'constants/index';
import {
  StatementFragment,
  useStrategyQuery,
  useTheWhyPageWithStatementsQuery,
} from 'data/graphql/generated';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { DownloadButton } from '../../components/shared/DownloadButton';
import { GradientBorderCard } from '../../components/shared/GradientCard';
import { PositioningTabKebabCase } from '../../types';
import { getStatementText } from '../../utils/brandPositioning';
import { Navbar } from './Navbar';
import { downloadSummary } from './downloadSummary';

const ReasonsToBelieveUL = styled.ul`
  margin: 0px;
  padding-inline-start: 30px;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 20px;
`;

const PageWrapper = styled(Page)`
  margin-top: 160px;
  padding-bottom: 60px;
  ${Wrapper} {
    width: 100%;

    @media ${device.desktopMin} {
      display: flex;
      justify-content: space-between;
    }
  }

  @media ${device.desktopMin} {
    padding: 20px;
    margin-top: 110px;
  }
`;

export const StepHeader = styled(StepHeaderBar)<{ isDesktop: boolean }>`
  display: block;
  padding: 15px 20px 0px;
  border-bottom: 0.5px solid ${colors.greyMedium};
  margin-top: ${({ isDesktop }) => (isDesktop ? '110px' : '155px')};
  min-height: auto;
  overflow: visible;
`;

interface URLParams {
  drugId: string;
  strategyId: string;
  positioningTab: PositioningTabKebabCase;
  competitorId: string;
}

export const Summary: FC = () => {
  const { drugId, strategyId, competitorId }: URLParams = useParams();

  const {
    data: strategyData,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
  } = useStrategyQuery({
    variables: { id: +strategyId },
  });

  const {
    data: theWhyPageData,
    loading: theWhyPageLoading,
    startPolling: theWhyPageStartPolling,
    stopPolling: theWhyPageStopPolling,
    error: theWhyPageError,
  } = useTheWhyPageWithStatementsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        focus: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    theWhyPageStartPolling(polling.default);
    startStrategyPolling(polling.default);
    return () => {
      theWhyPageStopPolling();
      stopStrategyPolling();
    };
  }, [
    startStrategyPolling,
    stopStrategyPolling,
    theWhyPageStartPolling,
    theWhyPageStopPolling,
  ]);

  const drugName = strategyData?.strategy?.drug?.name || '';
  const whoItsForStatement = theWhyPageData?.theWhyPage?.StatementParent;
  const statements = theWhyPageData?.theWhyPage?.Statement || [];

  const noStatements = !whoItsForStatement && !statements;
  const combinedStatements = [...statements, whoItsForStatement].filter(
    Boolean
  ) as StatementFragment[];

  const {
    theWhoStatementSummaryText,
    whatItIsStatementText,
    benefitsStatementText,
    reasonsToBelieveStatementText,
  } = getStatementText({
    statements: noStatements ? undefined : combinedStatements,
    drugName,
  });

  return (
    <>
      <StrategicSummary />

      <Navbar
        drugId={drugId}
        strategyId={strategyId}
        competitorId={competitorId}
        currentPositioningTab={'summary'}
      />

      <PageWrapper paddingTop={false} fullWidthMobile>
        <PageContent>
          <ErrorWrapper
            isLoading={theWhyPageLoading}
            errors={[theWhyPageError]}
            dataMissing={false}
          >
            <div
              style={{ width: '100%', display: 'flex', position: 'relative' }}
            >
              <GradientWrapper>
                <BodySmall color={colors.greyDark}>Who it's for</BodySmall>
                {theWhoStatementSummaryText ? (
                  <Subtitle2>{theWhoStatementSummaryText}</Subtitle2>
                ) : (
                  <Subtitle2 color={colors.greyDark}>Not defined yet</Subtitle2>
                )}
              </GradientWrapper>
              <div
                style={{
                  position: 'absolute',
                }}
              >
                <DownloadButton
                  onClick={() => {
                    if (!statements) return;
                    downloadSummary({
                      statements: combinedStatements,
                      drugName,
                    });
                  }}
                />
              </div>
            </div>
            <GradientWrapper>
              <BodySmall color={colors.greyDark}>What it is</BodySmall>
              {whatItIsStatementText ? (
                <Subtitle2>{whatItIsStatementText}</Subtitle2>
              ) : (
                <Subtitle2 color={colors.greyDark}>Not defined yet</Subtitle2>
              )}
            </GradientWrapper>
            <GradientWrapper>
              <BodySmall color={colors.greyDark}>Benefits</BodySmall>
              {benefitsStatementText ? (
                <Subtitle2>{benefitsStatementText}</Subtitle2>
              ) : (
                <Subtitle2 color={colors.greyDark}>Not defined yet</Subtitle2>
              )}
            </GradientWrapper>
            <GradientWrapper>
              <BodySmall color={colors.greyDark}>Reasons to believe</BodySmall>
              {reasonsToBelieveStatementText?.length ? (
                <ReasonsToBelieveUL>
                  {reasonsToBelieveStatementText?.map((text, idx) => (
                    <li key={idx}>
                      <Subtitle2>{text}</Subtitle2>
                    </li>
                  ))}
                </ReasonsToBelieveUL>
              ) : (
                <Subtitle2 color={colors.greyDark}>Not defined yet</Subtitle2>
              )}
            </GradientWrapper>
          </ErrorWrapper>
        </PageContent>
      </PageWrapper>
    </>
  );
};

const GradientWrapper: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <GradientBorderCard
      style={{
        width: '100%',
        maxHeight: '20vh',
        display: 'flex',
        maxWidth: '640px',
        margin: '0 auto',
      }}
    >
      <div>{children}</div>
    </GradientBorderCard>
  );
};
