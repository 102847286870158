import { PostItsEmpty } from 'components/PostItsEmpty';
import { BodySmall } from 'components/shared';
import { colors } from 'constants/colors';
import React from 'react';
import { Link } from 'react-router-dom';
import { startCase } from 'utils/startCase';

type Props = {
  country: string | undefined | null;
  drugId: string;
  strategyId: string;
  competitiveAdvantageRowId: number;
  countryTacticsDisabledAndNoGlobalObjectives: boolean;
  noMedicalObjectives: boolean;
  noLocalObjectivesToShow: boolean;
  noUserLocalOrGlobalObjectives: boolean;
};

const MedicalTacticsEmptyStates = ({
  country,
  drugId,
  strategyId,
  competitiveAdvantageRowId,
  countryTacticsDisabledAndNoGlobalObjectives,
  noMedicalObjectives,
  noLocalObjectivesToShow,
  noUserLocalOrGlobalObjectives,
}: Props) => {
  if (countryTacticsDisabledAndNoGlobalObjectives) {
    return (
      <PostItsEmpty style={{ margin: 20 }} title={'Nothing to discuss'}>
        <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
          Tactics may only be considered when there are global Medical
          Objectives in
        </BodySmall>

        <Link
          to={`/d/${drugId}/strategy/${strategyId}/4_2/medical-objectives/${competitiveAdvantageRowId}`}
          style={{
            color: colors.greyDark,
            display: 'inline',
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {' '}
          Part 2
        </Link>
      </PostItsEmpty>
    );
  }

  if (noMedicalObjectives) {
    return (
      <PostItsEmpty style={{ margin: 20 }} title={'Nothing to discuss'}>
        <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
          Tactics may only be considered when there are global or local Medical
          Objectives in
        </BodySmall>

        <Link
          to={`/d/${drugId}/strategy/${strategyId}/4_2/medical-objectives/${competitiveAdvantageRowId}`}
          style={{
            color: colors.greyDark,
            display: 'inline',
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {' '}
          Part 2
        </Link>
      </PostItsEmpty>
    );
  }

  if (noLocalObjectivesToShow) {
    return (
      <PostItsEmpty
        style={{ margin: 20 }}
        title={'No global medical objectives'}
      >
        <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
          Use the toggle above to consider tactics for the local medical
          objectives
        </BodySmall>
      </PostItsEmpty>
    );
  }

  if (noUserLocalOrGlobalObjectives) {
    return (
      <PostItsEmpty style={{ margin: 20 }} title={'Nothing to discuss'}>
        <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
          Tactics may only be considered when there are global or local (
          {startCase(country || '')}) Medical Objectives in
        </BodySmall>

        <Link
          to={`/d/${drugId}/strategy/${strategyId}/4_2/medical-objectives/${competitiveAdvantageRowId}`}
          style={{
            color: colors.greyDark,
            display: 'inline',
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {' '}
          Part 2
        </Link>
      </PostItsEmpty>
    );
  }

  return null;
};

export default MedicalTacticsEmptyStates;
