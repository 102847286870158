import styled from 'styled-components/macro';
import capitalize from 'lodash/capitalize';
import { CriticalMetricGoalInput } from 'data/graphql/generated';
import { CountryFlagIcon, getCountryImageName } from 'components/shared';
import { ProgressCardItemPercentSection } from './ProgressCardItemPercentSection';
import { ProgressCardItemBarSection } from './ProgressCardItemBarSection';
import { getCountry } from 'types';

const ProgressCardItem = styled.div<{ isTracked: boolean }>`
  display: flex;
  gap: ${({ isTracked }) => isTracked && '5px'};
  height: 47px;
  min-height: 47px;
`;

interface GoalPeriodTrackingProps {
  isLead: boolean;
  goalTarget: number;
  goalInput: CriticalMetricGoalInput;
  tracking: { region: string; average?: number };
  isTracked?: boolean;
}

export const GoalPeriodTracking = ({
  isLead,
  goalInput,
  goalTarget,
  tracking,
  isTracked = false,
}: GoalPeriodTrackingProps) => {
  return (
    <ProgressCardItem isTracked={isTracked}>
      <div>
        <CountryFlagIcon
          isLead={isLead && goalInput === CriticalMetricGoalInput.GlobalOnly}
          countryImage={getCountryImageName(getCountry(tracking.region))?.img}
          size={30}
          tooltip={capitalize(tracking.region)}
          showTooltip
        />
      </div>

      <ProgressCardItemBarSection
        tracking={tracking}
        goalTarget={goalTarget}
        isTracked={isTracked}
      />

      <ProgressCardItemPercentSection percentage={tracking.average} />
    </ProgressCardItem>
  );
};
