import React from 'react';
import styled from 'styled-components';

import { colors, zIndex } from 'constants/index';

const DrugWrapperTop = styled.div`
  position: relative;
  z-index: ${zIndex.pageModal};

  height: 10px;
  background: radial-gradient(
    100% 57600% at 0% 100%,
    #49129b 0%,
    #4f72c9 50%,
    #bc2eaa 100%
  );

  overflow: hidden;
  &::before {
    content: '';
    background: url(/noise.png) repeat;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 60px;
    opacity: 0.3;
    pointer-events: none;
    z-index: 2;
  }
`;

const DrugWrapper = styled.div`
  overflow: hidden;
  background: ${colors.white};
  border: 1px solid rgba(20, 20, 39, 0.1);
  border-radius: 5px;
  position: relative;
`;

const InformationModal: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <DrugWrapper className={className}>
      <DrugWrapperTop className="wrapperTop" />
      {children}
    </DrugWrapper>
  );
};

export default InformationModal;
