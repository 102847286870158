import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';

import { useAuthContext } from 'contexts/AuthContext';
import { useHelpContext } from 'contexts/HelpContext';
import { colors } from '../constants';
import { Icon } from './shared';

const NotificationDot = styled.div`
  width: 12px;
  height: 12px;
  background: ${colors.purple};
  border-radius: 50%;
  position: absolute;
  top: 0px;
  right: 0px;
  transition: border 0.3s;
  border: 2px solid ${colors.black};
`;

const Wrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;
  transition: background 0.3s;
  color: ${colors.cream};
  &:hover {
    background: #5a5a68;
  }
  &:hover ${NotificationDot} {
    border-color: #5a5a68;
  }
  position: relative;
`;

export const HelpIcon: FC<{ onClose?(): void }> = ({ onClose }) => {
  const [, setHelpContext] = useHelpContext();
  const { pathname } = useLocation();
  const [{ user }] = useAuthContext();

  const step = pathname.split('/')[5];
  const stepAlreadyDismissed = (user?.helpDismissed || []).includes(step);
  const onStrategyBubblesPage = !!pathname.match(/strategy\/\d+\/?$/g);
  const onHomePage = !!pathname.match(/^\/$/g);
  const onStrategyWizard = !!pathname.match(/\/strategy\/\d+\/create\//);
  const onCollaboratorsPage = !!pathname.match(/\/strategy\/\d+\/people/g);
  const showHelpIcon =
    !onStrategyBubblesPage &&
    !onHomePage &&
    !onStrategyWizard &&
    !onCollaboratorsPage;
  if (!showHelpIcon) return null;

  return (
    <Wrapper
      onClick={() => {
        setHelpContext(true);
        onClose && onClose();
      }}
    >
      {!stepAlreadyDismissed ? <NotificationDot /> : null}
      <Icon name="HelpCircle" size={30} color={colors.cream} />
    </Wrapper>
  );
};
