import React from 'react';
import styled from 'styled-components/macro';

import { ButtonLabel } from 'components/shared';
import {
  ReusableDropdown,
  SelectedOption,
} from 'components/shared/ReusableDropdown';
import { colors } from 'constants/colors';
import {
  CriticalMetricGoalFrequency,
  CriticalMetricGoalUpdateInput,
} from 'data/graphql/generated';
import { device } from 'utils/breakpoints';
import { GoalFrequencyDropdownItem } from './GoalFrequencyDropdownItem';

export interface GoalFrequencyDropdownOptionType {
  id: CriticalMetricGoalFrequency;
  value: CriticalMetricGoalFrequency;
}

const DEFAULT_DROPDOWN_OPTIONS: GoalFrequencyDropdownOptionType[] = [
  {
    id: CriticalMetricGoalFrequency.Monthly,
    value: CriticalMetricGoalFrequency.Monthly,
  },
  {
    id: CriticalMetricGoalFrequency.Quarterly,
    value: CriticalMetricGoalFrequency.Quarterly,
  },
  {
    id: CriticalMetricGoalFrequency.Annually,
    value: CriticalMetricGoalFrequency.Annually,
  },
];

const StyledDropdown = styled(ReusableDropdown)`
  min-width: 130px;

  ul {
    @media ${device.desktopMin} {
      width: 100%;
    }
  }

  @media ${device.tabletMax} {
    ${SelectedOption} {
      padding-left: 0px;

      :hover {
        padding-left: 4px;
      }
    }
  }
` as typeof ReusableDropdown;

interface GoalFrequencyDropdownProps {
  selectedOption: GoalFrequencyDropdownOptionType | null;
  setSelectedOption: React.Dispatch<
    React.SetStateAction<GoalFrequencyDropdownOptionType | null>
  >;
  onUpdate: (val: CriticalMetricGoalUpdateInput) => void;
  editable?: boolean;
}

export function GoalFrequencyDropdown({
  selectedOption,
  setSelectedOption,
  onUpdate,
  editable = true,
}: GoalFrequencyDropdownProps) {
  return (
    <StyledDropdown
      editable={!!editable}
      options={DEFAULT_DROPDOWN_OPTIONS}
      selectedOption={selectedOption}
      selectOption={(option) => {
        setSelectedOption(option);
        onUpdate({
          frequency: option.id as CriticalMetricGoalFrequency,
        });
      }}
      clearSelection={() => {
        setSelectedOption(null);
        onUpdate({ frequency: null });
      }}
      dropdownItem={(option) => (
        <GoalFrequencyDropdownItem value={option.value} />
      )}
      selectedDropdownItem={(option) => (
        <GoalFrequencyDropdownItem value={option.value} />
      )}
      noSelectionItem={() => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ButtonLabel color={colors.greyDark}>Choose frequency</ButtonLabel>
        </div>
      )}
    />
  );
}
