import { CompetitorFragment, Stakeholder } from 'data/graphql/generated';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { TabGroup } from 'components/shared';
import { CompetitorTab } from './CompetitorTab';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from 'contexts/GlobalContext';

export const Wrapper = styled.div`
  width: 100%;
`;

interface Props {
  competitors: CompetitorFragment[];
  selectedCompetitorId: number;
  drugId: number;
  strategyId: number;
  stakeholder: Stakeholder;
  selectedStakeholderDefinitionId: number;
  onClick?(): void;
}

export const CompetitorTabs: React.FC<Props> = ({
  competitors,
  selectedCompetitorId,
  drugId,
  strategyId,
  stakeholder,
  selectedStakeholderDefinitionId,
  onClick,
}) => {
  const history = useHistory();

  // handle global context path
  const [ competitorIdGlobal, setCompetitorIdGlobal ] = useGlobalContext(
    'competitorIdGlobal'
  );

  useEffect(() => {
    if (competitorIdGlobal) {
      setTimeout(() => {
        history.replace(
          `/d/${drugId}/strategy/${strategyId}/1_2/${stakeholder}/${selectedStakeholderDefinitionId}/${competitorIdGlobal}`
        );
      }, 50)
    }
  }, [competitorIdGlobal, history, drugId, strategyId, stakeholder, selectedStakeholderDefinitionId])
 
  function setSelectedCompetitor(competitorId: number) {
    if (onClick) {
      onClick();
    } else {
      history.push(
        `/d/${drugId}/strategy/${strategyId}/1_2/${stakeholder}/${selectedStakeholderDefinitionId}/${competitorId}`
      );
      setCompetitorIdGlobal(competitorId);
    }
  }

  return (
    <Wrapper>
      <TabGroup componentName="CompetitorTab" key={competitors.length}>
        <>
          {competitors.map((competitor, index) => {
            return (
              <div
                key={competitor.id}
                style={{ display: 'inline-block', padding: '0 2px' }}
              >
                <CompetitorTab
                  text={competitor.title}
                  image={competitor?.image}
                  active={competitor.id === selectedCompetitorId}
                  onClick={() => setSelectedCompetitor(competitor.id)}
                />
              </div>
            );
          })}
        </>
      </TabGroup>
    </Wrapper>
  );
};
