import {
  differenceInCalendarQuarters,
  differenceInCalendarYears,
  getMonth,
} from 'date-fns';
import React, { useMemo } from 'react';
import {
  YEAR_COLUMN_WIDTH,
  QUARTER_COLUMN_WIDTH,
  DIAMOND_WIDTH,
} from './constants';
import { RowProps } from './SummaryTimelineRestyled';
import styled from 'styled-components';
import { colors } from 'constants/colors';
import { DueDateDiamond } from './DueDateDiamond';

const Duration = styled.div`
  height: 20px;
  background: ${colors.purple20};
  border: 1px solid ${colors.purple};
  border-radius: 5px;
`;

type Props = {
  imperativeTactics: RowProps[];
  quarterColumnWidth: number;
  minDate: number;
};

const getDateYear = (date: string) => +date.slice(-4);
const getDateMonth = (date: string) => +date.slice(0, -4);
const dateArrToDate = (date: number[]) =>
  new Date(
    getDateYear(date.join('')),
    getDateMonth(date.join('')),
    1
  ).getTime();

const monthsPerQuarter = 3;

export const GroupedTactics = ({
  imperativeTactics,
  quarterColumnWidth,
  minDate,
}: Props) => {
  const measurements = useMemo(
    () =>
      getMeasurements({
        imperativeTactics,
        quarterColumnWidth,
        minDate,
      }),
    [imperativeTactics, quarterColumnWidth, minDate]
  );

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        marginBottom: 5,
      }}
    >
      <Duration
        style={{
          width: measurements.width,
          minWidth: 33.3,
          transform: `translateX(${measurements.x}px)`,
        }}
      />
      <DueDates
        tactics={imperativeTactics}
        minDate={minDate}
        quarterColumnWidth={quarterColumnWidth}
      />
    </div>
  );
};

const DueDates = ({
  tactics,
  minDate,
  quarterColumnWidth,
}: {
  tactics: RowProps[];
  minDate: number;
  quarterColumnWidth: number;
}) => {
  return (
    <>
      {tactics.map((tactic) => {
        if (!tactic.dueDate.length) {
          return null;
        }
        const monthsPerQuarter = 3;

        const dueDate = new Date(
          getDateYear(tactic.dueDate.join('')),
          getDateMonth(tactic.dueDate.join('')),
          1
        ).getTime();

        const dueDatePosition =
          differenceInCalendarQuarters(dueDate, minDate) * quarterColumnWidth;

        const dueDateQuarterOffset = Math.max(
          (((tactic.dueDate[0] % monthsPerQuarter) + 1) / monthsPerQuarter) *
            100,
          33.3
        );

        const dueDateYearColumnOffset =
          (differenceInCalendarYears(dueDate, minDate) + 1) * YEAR_COLUMN_WIDTH;

        const dueDateQuarterColumnOffset =
          Math.max(
            differenceInCalendarQuarters(dueDate, minDate) +
              1 * QUARTER_COLUMN_WIDTH,
            0
          ) - differenceInCalendarYears(dueDate, minDate);

        return (
          <DueDateDiamond
            key={tactic.id}
            style={{
              position: 'absolute',
              left: `${
                // If it's landing on a year column, adjust its position so it sits in the middle of the column
                (dueDateQuarterColumnOffset > 2
                  ? dueDateYearColumnOffset + 1.5
                  : dueDateYearColumnOffset) +
                dueDatePosition +
                dueDateQuarterColumnOffset +
                dueDateQuarterOffset -
                Math.floor(DIAMOND_WIDTH / 2)
              }px`,
            }}
          />
        );
      })}
    </>
  );
};

const getMeasurements = ({
  imperativeTactics,
  quarterColumnWidth,
  minDate,
}: Props) => {
  const allDates = imperativeTactics.flatMap(
    (tactic) =>
      [
        tactic.timingStart.length
          ? dateArrToDate(tactic.timingStart)
          : undefined,
        tactic.timingEnd.length ? dateArrToDate(tactic.timingEnd) : undefined,
        tactic.dueDate.length ? dateArrToDate(tactic.dueDate) : undefined,
      ].filter((v) => !!v) as number[]
  );

  const allDueDates = imperativeTactics.map((tactic) =>
    dateArrToDate(tactic.dueDate)
  );

  const min = Math.min(...allDates);
  const max = Math.max(...allDates);
  const maxDueDate = Math.max(...allDueDates);

  const duration =
    Math.max(differenceInCalendarQuarters(max, min), 0) * quarterColumnWidth;

  const durationQuarterOffset =
    Math.max(differenceInCalendarQuarters(max, min) * QUARTER_COLUMN_WIDTH, 0) -
    differenceInCalendarYears(max, min);

  const durationYearOffset = Math.max(
    differenceInCalendarYears(max, min) * YEAR_COLUMN_WIDTH,
    0
  );

  const dueDateWidth = maxDueDate >= max ? DIAMOND_WIDTH : 0;

  const endQuarterOffset =
    (((getMonth(max) % monthsPerQuarter) + 1) / monthsPerQuarter) * 100;

  const startPositionQuarterOffset =
    ((getMonth(min) % monthsPerQuarter) / monthsPerQuarter) * 100;

  const startPosition =
    min === minDate
      ? 0
      : differenceInCalendarQuarters(min, minDate) * quarterColumnWidth;

  // These add the pixels occupied by the columns to the positions
  const startPositionYearColumnOffset =
    (differenceInCalendarYears(min, minDate) + 1) * YEAR_COLUMN_WIDTH;

  const startPositionQuarterColumnOffset =
    Math.max(
      differenceInCalendarQuarters(min, minDate) * QUARTER_COLUMN_WIDTH,
      0
    ) - differenceInCalendarYears(min, minDate);

  return {
    width:
      duration +
      durationQuarterOffset +
      durationYearOffset +
      endQuarterOffset -
      startPositionQuarterOffset +
      dueDateWidth,
    x:
      startPositionQuarterColumnOffset +
      startPositionQuarterOffset +
      startPositionYearColumnOffset +
      startPosition,
  };
};
