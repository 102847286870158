import React from 'react';
import styled from 'styled-components/macro';
import { ButtonLabel } from 'components/shared';
import { colors } from 'constants/colors';
import { TreatmentIcon } from './TreatmentIcon';

export const Image = styled.div<{ imageURL: string }>`
  background-image: ${({ imageURL }) => `url(${imageURL})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${colors.white};
  image-rendering: -webkit-optimize-contrast;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  overflow: hidden;
`;

interface Props {
  text: string;
  image?: string | null;
  active: boolean;
  onClick(): void;
  color?: string;
}

export const Wrapper = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;

  height: 52px;
  width: 240px;
  padding: 5px;
  border-radius: 5px 5px 0 0;
  background: ${colors.white};

  cursor: pointer;

  border-top: 2px solid transparent;
  border-bottom: 2px solid
    ${({ active }) => (active ? colors.purple : colors.white)};
  transition: border 0.3s ease;
`;

export const CompetitorTab: React.FC<Props> = ({
  text,
  image,
  active,
  onClick,
  color,
}) => {
  return (
    <Wrapper active={active} onClick={onClick}>
      {!!image ? <Image imageURL={image} /> : <TreatmentIcon />}
      <ButtonLabel color={color}>{text}</ButtonLabel>
    </Wrapper>
  );
};
