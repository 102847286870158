import { Modal } from 'components/Modal';
import { colors } from 'constants/index';
import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';
import { BodyNormal, Subtitle1 } from './TextStyles';

const StyledModal = styled(Modal)`
  @media ${device.mobile} {
    width: 100%;
    height: 100%;
    max-width: none;
    margin-top: 110px;
    border-radius: 0;
  }
`;

const CTAWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  @media ${device.mobile} {
    flex-direction: row-reverse;
    > div:nth-child(2) {
      margin-bottom: 15px;
    }
    > div {
      width: 315px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  text-align: center;
  @media ${device.mobile} {
    padding: 30px 15px;
  }
  > ${Subtitle1} {
    margin-bottom: 10px;
  }
  > ${BodyNormal} {
    margin-bottom: 30px;
  }
`;

const TopBar = styled.div`
  height: 40px;
  width: 100%;
  background: ${colors.cream};
  @media ${device.mobile} {
    height: 30px;
  }
`;

const Wrapper = styled.div`
  width: 456px;
  background: ${colors.white};
  border: 0.5px solid rgba(20, 20, 39, 0.3);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  @media ${device.mobile} {
    width: 100vw;
    height: 100vh;
    border-radius: 0px;
  }
`;

interface Props {
  heading?: string;
  message?: string;
  className?: string;
  handleClose(): void;
  visible: boolean;
}

export const YellowHeaderModal: React.FC<Props> = ({
  heading,
  message,
  handleClose,
  visible,
  children,
  className,
}) => {
  return ReactDOM.createPortal(
    <StyledModal
      className={className}
      handleClose={handleClose}
      visible={visible}
    >
      <Wrapper>
        <TopBar />
        <Content>
          {heading && <Subtitle1>{heading}</Subtitle1>}
          {message && <BodyNormal>{message}</BodyNormal>}
          <CTAWrapper>{children}</CTAWrapper>
        </Content>
      </Wrapper>
    </StyledModal>,
    document.body
  );
};
