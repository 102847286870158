import styled from 'styled-components/macro';

import { StepHeaderBar } from 'components/shared';
import { device } from 'utils/breakpoints';

export const StyledStepHeader = styled(StepHeaderBar)`
  margin-top: 160px;
  padding: 15px;
  padding-bottom: 0px;
  min-height: unset;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${device.desktopMin} {
    margin-top: 114px;
  }
`;
export const IndicatorsPageStepHeader = styled(StyledStepHeader)`
  @media ${device.desktopMin} {
    margin-top: 64px;
  }
`;
