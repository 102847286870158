import { Icon, Subtitle2 } from 'components/shared';
import { colors } from 'constants/index';
import React from 'react';
import styled, { css } from 'styled-components/macro';

const Wrapper = styled.div<{
  isDragActive: boolean;
  isDragOver: boolean;
}>`
  opacity: ${({ isDragActive }) => Number(isDragActive)};
  visibility: ${({ isDragActive }) => (isDragActive ? 'visible' : 'hidden')};
  transition: opacity 0.3s;

  padding: 10px;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const DropzoneWrapper = styled.div<{
  isDragActive: boolean;
  isDragOver: boolean;
}>`
  width: 100%;
  height: 100%;

  padding: 5px;
  border-radius: 5px;
  background: ${({ isDragActive, isDragOver }) => {
    //https://kovart.github.io/dashed-border-generator/
    const dashes = isDragOver
      ? `
        ${
          colors.white90
        } url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23${colors.purple.slice(
          1
        )}' stroke-width='2' stroke-dasharray='10 5' stroke-dashoffset='100' stroke-linecap='butt'/%3e%3c/svg%3e")`
      : isDragActive
      ? `${
          colors.white90
        } url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23${colors.greyDark.slice(
          1
        )}' stroke-width='2' stroke-dasharray='10 5' stroke-dashoffset='100' stroke-linecap='butt'/%3e%3c/svg%3e")`
      : '';
    return dashes;
  }};

  .info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 0.5px solid ${colors.black30};
    background: ${colors.white90};
    transition: background 0.3s;

    ${({ isDragOver }) => {
      if (isDragOver) {
        return css`
          background: ${colors.purple05};
        `;
      }
    }}
  }
`;

interface Props {
  isDragActive: boolean;
  isDragOver: boolean;
}

export const DropZoneWrapper = ({ isDragActive, isDragOver }: Props) => {
  return (
    <Wrapper isDragActive={isDragActive} isDragOver={isDragOver}>
      <DropzoneWrapper isDragActive={isDragActive} isDragOver={isDragOver}>
        <div className="info">
          {isDragOver ? (
            <Icon
              name="GradientFileEmptyState"
              size={115}
              height={115}
              color="initial"
            />
          ) : (
            <Icon
              name="FileEmptyState"
              size={115}
              height={115}
              color="initial"
            />
          )}
          <Subtitle2 color={colors.greyDark}>Drop here to add image</Subtitle2>
        </div>
      </DropzoneWrapper>
    </Wrapper>
  );
};
