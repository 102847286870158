import { Country } from 'types';

// codes from here:
// https://www.iban.com/country-codes

export function countryCode(country: Country): string {
  switch (country) {
    // case 'abkhazia':
    // return '**';
    case 'afghanistan':
      return 'AF';
    // case 'aland islands':
    // return '**';
    case 'albania':
      return 'AL';
    case 'algeria':
      return 'DZ';
    case 'american samoa':
      return 'AS';
    case 'andorra':
      return 'AD';
    case 'angola':
      return 'AO';
    case 'anguilla':
      return 'AI';
    case 'antigua and barbuda':
      return 'AG';
    case 'argentina':
      return 'AR';
    case 'armenia':
      return 'AM';
    case 'aruba':
      return 'AW';
    case 'australia':
      return 'AU';
    case 'austria':
      return 'AT';
    case 'azerbaijan':
      return 'AZ';
    // case 'azores islands':
    // return '**';
    case 'bahamas':
      return 'BS';
    case 'bahrain':
      return 'BH';
    // case 'balearic islands':
    // return '**';
    case 'bangladesh':
      return 'BD';
    case 'barbados':
      return 'BB';
    // case 'basque country':
    // return '';
    case 'belarus':
      return 'BY';
    case 'belgium':
      return 'BE';
    case 'belize':
      return 'BZ';
    case 'benin':
      return 'BJ';
    case 'bermuda':
      return 'BM';
    case 'bhutan':
      return 'BT';
    case 'bolivia':
      return 'BO';
    case 'bonaire':
      return 'BQ';
    case 'bosnia and herzegovina':
      return 'BA';
    case 'botswana':
      return 'BW';
    case 'brazil':
      return 'BR';
    // case 'british columbia':
    // return '**';
    case 'british indian ocean territory':
      return 'IO';
    // case 'british virgin islands':
    // return '**';
    case 'brunei':
      return 'BN';
    case 'bulgaria':
      return 'BG';
    case 'burkina faso':
      return 'BF';
    case 'burundi':
      return 'BI';
    case 'cambodia':
      return 'KH';
    case 'cameroon':
      return 'CM';
    case 'canada':
      return 'CA';
    case 'canary islands':
      return 'KY';
    case 'cape verde':
      return 'CV';
    case 'cayman islands':
      return 'KY';
    case 'central african republic':
      return 'CF';
    // case 'ceuta':
    // return '**';
    case 'chad':
      return 'TD';
    case 'chile':
      return 'CL';
    case 'china':
      return 'CN';
    case 'christmas island':
      return 'CX';
    case 'cocos island':
      return 'CC';
    case 'colombia':
      return 'CO';
    case 'comoros':
      return 'KM';
    case 'cook islands':
      return 'CK';
    // case 'corsica':
    // return '**';
    case 'costa rica':
      return 'CR';
    case 'croatia':
      return 'HR';
    case 'cuba':
      return 'CU';
    case 'curacao':
      return 'CW';
    case 'cyprus':
      return 'CY';
    // case 'czech republic':
    // return '**';
    // case 'democratic republic of congo':
    // return '**';
    case 'denmark':
      return 'DK';
    case 'djibouti':
      return 'DJ';
    case 'dominican republic':
      return 'DO';
    case 'dominica':
      return 'DM';
    // case 'east timor':
    // return '**';
    case 'ecuador':
      return 'EC';
    case 'egypt':
      return 'EG';
    case 'england':
      return 'GB';
    case 'equatorial guinea':
      return 'GQ';
    case 'eritrea':
      return 'ER';
    case 'estonia':
      return 'EE';
    case 'ethiopia':
      return 'ET';
    case 'european union':
      return 'EU';
    case 'falkland islands':
      return 'FK';
    case 'faroe islands':
      return 'FO';
    case 'fiji':
      return 'FJ';
    case 'finland':
      return 'FI';
    case 'france':
      return 'FR';
    case 'french polynesia':
      return 'PF';
    case 'gabon':
      return 'GA';
    // case 'galapagos islands':
    // return '**';
    case 'gambia':
      return 'GM';
    case 'georgia':
      return 'GE';
    case 'germany':
      return 'DE';
    case 'ghana':
      return 'GH';
    case 'gibraltar':
      return 'GI';
    case 'greece':
      return 'GR';
    case 'greenland':
      return 'GL';
    case 'grenada':
      return 'GD';
    case 'guam':
      return 'GU';
    case 'guatemala':
      return 'GT';
    case 'guernsey':
      return 'GG';
    case 'guinea bissau':
      return 'GW';
    case 'guinea':
      return 'GN';
    case 'guyana':
      return 'GY';
    case 'haiti':
      return 'HT';
    // case 'hawaii':
    // return '**';
    case 'honduras':
      return 'HN';
    case 'hong kong':
      return 'HK';
    case 'hungary':
      return 'HU';
    case 'iceland':
      return 'IS';
    case 'india':
      return 'IN';
    case 'indonesia':
      return 'ID';
    case 'iran':
      return 'IR';
    case 'iraq':
      return 'IQ';
    case 'ireland':
      return 'IE';
    case 'isle of man':
      return 'IM';
    case 'israel':
      return 'IL';
    case 'italy':
      return 'IT';
    // case 'ivory coast':
    // return '**';
    case 'jamaica':
      return 'JM';
    case 'japan':
      return 'JP';
    case 'jersey':
      return 'JE';
    case 'jordan':
      return 'JO';
    case 'kazakhstan':
      return 'KZ';
    case 'kenya':
      return 'KE';
    case 'kiribati':
      return 'KI';
    // case 'kosovo':
    // return '**';
    case 'kuwait':
      return 'KW';
    case 'kyrgyzstan':
      return 'KG';
    // case 'laos':
    // return '**';
    case 'latvia':
      return 'LV';
    case 'lebanon':
      return 'LB';
    case 'lesotho':
      return 'LS';
    case 'liberia':
      return 'LR';
    case 'libya':
      return 'LY';
    case 'liechtenstein':
      return 'LI';
    case 'lithuania':
      return 'LT';
    case 'luxembourg':
      return 'LU';
    case 'macao':
      return 'MO';
    case 'madagascar':
      return 'MG';
    // case 'madeira':
    // return '**';
    case 'malawi':
      return 'MW';
    case 'malaysia':
      return 'MY';
    case 'maldives':
      return 'MV';
    case 'mali':
      return 'ML';
    case 'malta':
      return 'MT';
    case 'marshall island':
      return 'MH';
    case 'martinique':
      return 'MQ';
    case 'mauritania':
      return 'MR';
    case 'mauritius':
      return 'MU';
    // case 'melilla':
    // return '**';
    case 'mexico':
      return 'MX';
    case 'micronesia':
      return 'FM';
    case 'moldova':
      return 'MD';
    case 'monaco':
      return 'MC';
    case 'mongolia':
      return 'MN';
    case 'montenegro':
      return 'ME';
    case 'montserrat':
      return 'MS';
    case 'morocco':
      return 'MA';
    case 'mozambique':
      return 'MZ';
    case 'myanmar':
      return 'MM';
    case 'namibia':
      return 'NA';
    // case 'nato':
    // return '**';
    case 'nauru':
      return 'NR';
    case 'nepal':
      return 'NP';
    case 'netherlands':
      return 'NL';
    case 'new zealand':
      return 'NZ';
    case 'nicaragua':
      return 'NI';
    case 'nigeria':
      return 'NG';
    case 'niger':
      return 'NE';
    case 'niue':
      return 'NU';
    case 'norfolk island':
      return 'NF';
    // case 'northen cyprus':
    // return '**';
    case 'northern marianas islands':
      return 'MP';
    case 'north korea':
      return 'KP';
    case 'norway':
      return 'NO';
    case 'oman':
      return 'OM';
    // case 'orkney islands':
    // return '**';
    // case 'ossetia':
    // return '**';
    case 'pakistan':
      return 'PK';
    case 'palau':
      return 'PW';
    case 'palestine':
      return 'PS';
    case 'panama':
      return 'PA';
    case 'papua new guinea':
      return 'PG';
    case 'paraguay':
      return 'PY';
    case 'peru':
      return 'PE';
    case 'philippines':
      return 'PH';
    case 'pitcairn islands':
      return 'PN';
    case 'portugal':
      return 'PT';
    case 'puerto rico':
      return 'PR';
    case 'qatar':
      return 'QA';
    // case 'rapa nui':
    // return '**';
    case 'republic of macedonia':
      return 'MK';
    case 'republic of poland':
      return 'PL';
    // case 'republic of the congo':
    // return '**';
    case 'romania':
      return 'RO';
    case 'russia':
      return 'RU';
    case 'rwanda':
      return 'RW';
    // case 'saba island':
    // return '**';
    case 'saint kitts and nevis':
      return 'KN';
    // case 'salvador':
    // return '**';
    case 'samoa':
      return 'WS';
    case 'san marino':
      return 'SM';
    case 'sao tome and principe':
      return 'ST';
    // case 'sardinia':
    // return '**';
    case 'saudi arabia':
      return 'SA';
    case 'scotland':
      return 'GB';
    case 'senegal':
      return 'SN';
    case 'serbia':
      return 'RS';
    case 'seychelles':
      return 'SC';
    case 'sierra leone':
      return 'SL';
    case 'singapore':
      return 'SG';
    case 'sint eustatius':
      return 'SX';
    case 'sint maarten':
      return 'SX';
    case 'slovakia':
      return 'SK';
    case 'slovenia':
      return 'SI';
    case 'solomon islands':
      return 'SB';
    case 'somalia':
      return 'SO';
    // case 'somaliland':
    // return '**';
    case 'south africa':
      return 'ZA';
    case 'south korea':
      return 'KR';
    case 'south sudan':
      return 'SS';
    case 'spain':
      return 'ES';
    case 'sri lanka':
      return 'LK';
    // case 'st barts':
    // return '**';
    // case 'st lucia':
    // return '**';
    // case 'st vincent and the grenadines':
    // return '**';
    case 'sudan':
      return 'SD';
    case 'suriname':
      return 'SR';
    // case 'swaziland':
    // return '**';
    case 'sweden':
      return 'SE';
    case 'switzerland':
      return 'CH';
    case 'syria':
      return 'SY';
    case 'taiwan':
      return 'TW';
    case 'tajikistan':
      return 'TJ';
    case 'tanzania':
      return 'TZ';
    case 'thailand':
      return 'TH';
    // case 'tibet':
    // return '**';
    case 'togo':
      return 'TG';
    case 'tokelau':
      return 'TK';
    case 'tonga':
      return 'TO';
    // case 'transnistria':
    // return '**';
    case 'trinidad and tobago':
      return 'TT';
    case 'tunisia':
      return 'TN';
    case 'turkey':
      return 'TR';
    case 'turkmenistan':
      return 'TM';
    case 'turks and caicos':
      return 'TC';
    case 'tuvalu':
      return 'TV';
    case 'uganda':
      return 'UG';
    case 'ukraine':
      return 'UA';
    case 'united arab emirates':
      return 'AD';
    case 'united kingdom':
      return 'GB';
    case 'united states of america':
      return 'US';
    case 'uruguay':
      return 'UY';
    case 'uzbekistan':
      return 'UZ';
    case 'vanuatu':
      return 'VU';
    // case 'vatican city':
    // return '**';
    case 'venezuela':
      return 'VE';
    case 'vietnam':
      return 'VN';
    case 'virgin islands':
      return 'VG';
    // case 'wales':
    // return 'GB';
    case 'western sahara':
      return 'EH';
    case 'yemen':
      return 'YE';
    case 'zambia':
      return 'ZM';
    case 'zimbabwe':
      return 'ZW';
  }
  return '';
}
