import { BodySmall, CountryFlag } from 'components/shared';
import { colors } from 'constants/colors';
import styled from 'styled-components';
import { CountryGlobalRegional } from '../../types';
import { columnSettings } from './index.style';
import { StrategicImperative } from './types';
import { getReadableBudget } from './utils/getReadableBudget';

const TacticAndBudget = styled.div`
  ${columnSettings}
  padding-top: 15px;
  padding-bottom: 15px;

  border-bottom: 1px solid ${colors.greyLight};

  &:last-child {
    border-bottom: none;
  }
`;

const RowBudgets = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const BudgetWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export function TacticAndBudgetRow({
  budgets,
  currency,
}: {
  budgets: StrategicImperative['items'];
  currency: string;
}) {
  return (
    <>
      {budgets.map((item) => {
        const title = item?.title;

        const sortedBudgets = item.budget.sort((a, b) => {
          return b.value - a.value;
        });

        return (
          <TacticAndBudget key={(item?.__typename || '') + item.id}>
            <BodySmall>
              {item.__typename === 'BigIdeaDetail' ? (
                <span
                  style={{
                    color: colors.greyDark,
                  }}
                >
                  Big idea:{' '}
                </span>
              ) : null}
              {title}
            </BodySmall>
            <RowBudgets>
              {sortedBudgets.map((budget, idx) => {
                const readableTacticBudget = getReadableBudget(
                  currency,
                  budget.value
                );

                const country = budget?.country;
                const isGlobalRow = country === 'global';

                return (
                  <BudgetWrapper key={idx}>
                    <CountryFlag
                      isLead={isGlobalRow}
                      size={20}
                      country={country as CountryGlobalRegional}
                    />
                    <BodySmall>{readableTacticBudget}</BodySmall>
                  </BudgetWrapper>
                );
              })}
            </RowBudgets>
          </TacticAndBudget>
        );
      })}
    </>
  );
}
