import {
  CommercialTacticsEmptyState,
  CountrySolutionRow,
} from 'components/LongTermStrategy/SolutionRow';
import { PlanTable } from 'components/PlanTable';
import {
  CommercialTacticDetailFragment,
  KeyStatementFragment,
  Role,
  StrategyQuery,
  User,
  useCommercialTacticDetailUpdateMutation,
} from 'data/graphql/generated';
import { CountryGlobalRegional } from '../../types';
import { verifyUserRole } from '../../utils/verifyUserRole';
import { headerColumns } from './CommercialStrategyThePlan';

export function ObjectiveTables({
  activeObjectives,
  tactics,
  user,
  smallTable,
  strategyData,
  commercialTacticDetailUpdate,
  userRole,
  showGlobalPlan,
  drugId,
  strategyId,
  strategicImperativeId,
  localCountryTacticsEnabled,
}: {
  activeObjectives: KeyStatementFragment[];
  tactics: CommercialTacticDetailFragment[];
  user: User | undefined;
  smallTable: boolean;
  strategyData: StrategyQuery | undefined;
  commercialTacticDetailUpdate: ReturnType<
    typeof useCommercialTacticDetailUpdateMutation
  >[0];
  userRole: ReturnType<typeof verifyUserRole>;
  showGlobalPlan: boolean;
  drugId: string;
  strategyId: string;
  strategicImperativeId: string;
  localCountryTacticsEnabled: boolean | null | undefined;
}) {
  return (
    <>
      {activeObjectives.map((objective) => {
        const data = tactics.filter((t) => t.keyStatementId === objective.id);
        const isCountryObjective = objective.region !== 'global';
        const noGlobalTactics = !data.filter((item) => {
          return item.region === 'global';
        }).length;

        const noCountryTactics = !data.filter((item) => {
          return item.region === user?.country;
        }).length;

        return (
          <PlanTable
            key={objective.id}
            region={
              objective.region !== 'global'
                ? (objective.region as CountryGlobalRegional)
                : undefined
            }
            isDesktop={!smallTable}
            headerColumns={['Winning actions', ...headerColumns]}
            headerTitle={objective.text}
            emptyTitle="No commercial initiatives yet"
            rows={({
              id,
              text,
              audience,
              from,
              to,
              due,
              responsibility,
              budget,
              collaboration,
              region,
              additionalCountries,
            }: any) => {
              return (
                <CountrySolutionRow
                  isDesktop={!smallTable}
                  currency={strategyData?.strategy?.currency[0] || '¥'}
                  text={text || ''}
                  audience={audience}
                  from={from}
                  to={to}
                  due={due}
                  responsibility={responsibility}
                  budget={budget}
                  solutionTextOverride="Winning action"
                  actionsTextOverride="Audience"
                  collaboration={collaboration}
                  updateRow={async (vals) => {
                    await commercialTacticDetailUpdate({
                      variables: {
                        id,
                        data: {
                          ...vals,
                        },
                      },
                    });
                  }}
                  canEdit={
                    (region === 'global' && user?.role === Role.Lead) ||
                    (region === user?.country &&
                      user?.role === Role.Contributor)
                  }
                  country={region}
                  additionalCountries={Number(additionalCountries)}
                  isCountryContributor={userRole.isCountryContributor}
                  globalPlanVisible={showGlobalPlan}
                />
              );
            }}
            data={data}
            EmptySubHeading={
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <CommercialTacticsEmptyState
                  drugId={drugId}
                  strategyId={strategyId}
                  showGlobalPlan={showGlobalPlan}
                  userRole={userRole}
                  strategicImperativeId={strategicImperativeId}
                  localCountryTacticsEnabled={Boolean(
                    localCountryTacticsEnabled
                  )}
                  noGlobalTactics={noGlobalTactics}
                  noCountryTactics={noCountryTactics}
                  isCountryObjective={isCountryObjective}
                />
              </div>
            }
          />
        );
      })}
    </>
  );
}
