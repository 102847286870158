import { BodySmall, ButtonLabel } from 'components/shared';
import { colors } from 'constants/index';
import { ImperativeWithSuccessConditionType } from 'containers/CompetitiveAdvantage';
import React, { RefObject } from 'react';
import styled from 'styled-components';
import { Card } from './Card';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;

  max-height: calc(100% - 5px);
  overflow: auto;
`;

const ImperativeDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ImperativeContentWrapper = styled.div`
  margin: 15px;
  max-width: 640px;
  width: 100%;
`;

const ImperativeBorderWrapper = styled.div`
  display: flex;
  justify-content: center;

  position: relative;
  width: 100%;
  border-radius: 5px;
  border: 1px solid transparent;
  background: ${colors.purplePinkGradient};
`;

const ImperativeWrapper = styled.div`
  border-radius: 4px;
  width: 100%;

  display: flex;
  justify-content: center;
  background: white;

  position: relative;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: ${colors.fadedGradient};

    border-radius: 4px;
  }
`;

const StyledCard = styled(Card)`
  padding: 5px;
  flex: 1;
  min-width: 300px;
  height: min-content;
  max-height: calc(100% - 5px);
  word-break: break-word;

  user-select: none;
`;

const KeyIssueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  padding-bottom: 0px;
  max-width: 670px;
  width: 100%;
`;

interface Props {
  data: ImperativeWithSuccessConditionType[0];
  innerRef?: RefObject<HTMLDivElement>;
  style?: React.CSSProperties;
}

export const SummaryColumn = ({ data, innerRef, style = {} }: Props) => {
  return (
    <StyledCard ref={innerRef} style={style}>
      <ContentWrapper>
        <KeyIssueWrapper>
          <BodySmall color={colors.greyDark}>Key Issue</BodySmall>
          <BodySmall>
            {data?.competitiveAdvantageRow?.text || 'Not identified yet'}
          </BodySmall>
        </KeyIssueWrapper>
        <ImperativeBorderWrapper>
          <ImperativeWrapper>
            <ImperativeContentWrapper>
              <BodySmall color={colors.greyDark}>
                Strategic Imperative
              </BodySmall>
              <ImperativeDetailsWrapper>
                {!!data.name && (
                  <ButtonLabel style={{ cursor: 'default' }}>
                    {data.name}
                  </ButtonLabel>
                )}
                {!!data.behaviourToDrive && (
                  <BodySmall>{data.behaviourToDrive}</BodySmall>
                )}
                {!!data.driverOfTheBehaviour && (
                  <BodySmall>{data.driverOfTheBehaviour}</BodySmall>
                )}
              </ImperativeDetailsWrapper>
            </ImperativeContentWrapper>
          </ImperativeWrapper>
        </ImperativeBorderWrapper>
      </ContentWrapper>
    </StyledCard>
  );
};
