import React, { useEffect, useMemo, useState } from 'react';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components/macro';

import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { BudgetBar } from 'components/MedicalStrategy/BudgetBar';
import { SummaryTimeline } from 'components/MedicalStrategy/SummaryTimeline';
import { Page, Wrapper } from 'components/Page';
import {
  BodySmall,
  Caption,
  EmptyState,
  Subtitle1,
  Subtitle2,
} from 'components/shared';
import { SummaryHeader } from 'components/shared/SummaryHeader';
import { colors } from 'constants/colors';
import {
  budgetChartMaxWidth,
  budgetGroupsFlexGap,
  polling,
} from 'constants/index';
import {
  Sort,
  SubStep,
  Step,
  KeyEventFragment,
  useBigIdeaDetailsQuery,
  useCommercialTacticDetailsQuery,
  useCompetitiveAdvantageRowsQuery,
  useStrategyQuery,
} from 'data/graphql/generated';
import { device } from 'utils/breakpoints';
import { dateStringToMonthYear } from 'utils/dateStringToMonthYear';
import { thousandSeparator } from 'utils/thousandSeparator';
import { CommercialStrategyNav } from '../../components/4-3-commercial-strategy/shared/ui/feature-navbar/CommercialStrategyNav';
import { CountriesDropdownSmall } from '../../components/shared/CountriesDropdownSmall';
import { useAuthContext } from '../../contexts/AuthContext';
import { useCommercialStrategyNav } from '../../hooks';
import useHandleRegionDropdown from '../../hooks/useHandleRegionDropdown';
import useMobile from '../../hooks/useMobile';
import { CountryGlobal } from '../../types';
import { FinancialSummaryCountryDropdown } from '../MedicalStrategy/index.style';
import { SummaryPageEmptyStates } from './SummaryPageEmptyStates';
import { TacticAndBudgetRow } from './TacticAndBudgetRow';
import { columnSettings } from './index.style';
import { StrategicImperative } from './types';
import { getReadableBudget } from './utils/getReadableBudget';
import { getSums } from './utils/getSums';
import { KeyEventsButton } from 'components/shared';
import { useWidth } from 'hooks/useWidth';
import { verifyUserRole } from 'utils/verifyUserRole';
import { lowerCase } from 'lodash';
import { useGetKeyEvents } from 'components/4-2-medical-strategy/shared/data-access-key-events-sidebar/src';

import {
  KeyEventsSidebar,
} from 'components/4-2-medical-strategy/shared/feature-key-events-sidebar/src';
export const SMALLEST_MOBILE_WIDTH =
  375 +
  // space for scrollbar
  30;

const MedicalSummaryKeyEventsButton = styled(KeyEventsButton)`
  margin-left: auto;
  @media ${device.desktopMin} {
    margin-right: 76px;
  }
  @media (max-width: ${SMALLEST_MOBILE_WIDTH}px) {
    margin-right: 0;
  }
`;

const PageWrapper = styled(Page)`
  padding: 0px 0 60px;
  overflow: visible; // for the dropdown components
  margin-top: 215px;

  ${Wrapper} {
    width: auto;
    padding: 0 15px;
    max-width: none;
  }

  @media ${device.tabletMin} {
    margin-top: 170px;

    ${Wrapper} {
      padding: 0 20px;
    }
  }

  @media ${device.desktopMin} {
    margin-top: 135px;
  }
`;

const TopGroups = styled.div`
  margin-bottom: 15px;

  @media ${device.tabletMin} {
    max-width: ${budgetChartMaxWidth}px;
    margin: auto;
  }

  @media ${device.desktopMin} {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
    max-width: ${3 * budgetChartMaxWidth + 2 * budgetGroupsFlexGap}px;
  }
`;

const TopGroup = styled.div`
  background: ${colors.white};
  padding: 15px;
  border: 1px solid rgba(20, 20, 39, 0.1);
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 15px;

  @media ${device.desktopMin} {
    width: 50%;
    margin-bottom: 0px;
  }
`;

const TotalInvestment = styled.div`
  margin-bottom: 20px;
`;

const FinancialTables = styled.div`
  padding: 30px 15px;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.tabletMin} {
    max-width: ${budgetChartMaxWidth}px;

    margin: auto;
    border-radius: 5px;
    border: 1px solid ${colors.black10};
  }

  @media ${device.desktopMin} {
    max-width: ${3 * budgetChartMaxWidth + 2 * budgetGroupsFlexGap}px;
    padding: 30px 121px;
  }
`;

const ImperativeBudgets = styled.div`
  margin-bottom: 40px;
  max-width: 663px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const TitleAndBudget = styled.div`
  margin-bottom: 15px;
  ${columnSettings}
`;

const SubTableHeaders = styled.div`
  ${columnSettings}
  margin-bottom: 10px;
`;

const PurpleSeparator = styled.div`
  height: 1px;
  background: ${colors.purple};
`;

export const StyledEmptyState = styled(EmptyState)`
  border-radius: 5px;
  margin-top: 10px;
  height: auto;
`;

const BudgetEmptyState = styled(EmptyState)`
  border-radius: 5px;
  margin-top: 10px;
  height: calc(100% - 50px);
`;
interface URLParams {
  drugId: string;
  strategyId: string;
  strategicImperativeId: string;
  region: string;
}

export const CommercialStrategySummary = () => {
  const { drugId, strategyId, region }: URLParams = useParams();
  const [showTimeline, setShowTimeline] = useState(true);

  const {
    localCountryTacticsEnabled,
    setCountryTacticsEnabled,
  } = useCommercialStrategyNav({ strategyId: +strategyId });

  const [
    timelineSummarySelectedRegion,
    setTimelineSummarySelectedRegion,
  ] = useState<CountryGlobal>((region || 'global') as CountryGlobal);

  useEffect(() => {
    if (region) {
      setTimelineSummarySelectedRegion(region as CountryGlobal);
    }
  }, [region]);

  const [
    financialSummarySelectedRegions,
    setFinancialSummarySelectedRegions,
  ] = useState<CountryGlobal[]>(['global']);

  const [{ user }] = useAuthContext();

  const { path } = useRouteMatch();
  const history = useHistory();

  const isMobile = useMobile();

  const useMobileIcon = useWidth() < 512;
  const [keyEventsSidebarOpen, setKeyEventsSidebarOpen] = useState(false);

  // const [{ user }] = useAuthContext();
  const { isLead, isCountryContributor, isGlobalContributor } = verifyUserRole(
    user?.role,
    user?.country
  );


  const isUserCountry = isLead
  ? region === 'global'
  : lowerCase(region) ===
    lowerCase(user?.country || '');

  const showKeyEventsButton =
  (isLead || isCountryContributor) && isUserCountry && showTimeline;

  const {
    countryDropdownActive: timelineCountryDropdownActive,
    setCountryDropdownActive: setTimelineCountryDropdownActive,
    countryDropDownRef: timelineCountryDropDownRef,
  } = useHandleRegionDropdown({ region, user, strategyId: +strategyId });

  const {
    countryDropdownActive: financialCountryDropdownActive,
    setCountryDropdownActive: setFinancialCountryDropdownActive,
    countryDropDownRef: financialCountryDropDownRef,
    uniqueRegions,
  } = useHandleRegionDropdown({ region, user, strategyId: +strategyId });

  const {
    data: strategyData,
    loading: strategyLoading,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
    error: strategyError,
  } = useStrategyQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'network-only',
  });

  const {
    data: competitiveAdvantageRowsData,
    error: competitiveAdvantageRowsError,
    loading: competitiveAdvantageRowsLoading,
    startPolling: startCompetitiveAdvantageRowsPolling,
    stopPolling: stopCompetitiveAdvantageRowsPolling,
  } = useCompetitiveAdvantageRowsQuery({
    variables: {
      orderBy: {
        idx: Sort.Desc,
      },
      where: {
        strategyId: +strategyId,
        focus: true,
      },
      include: {
        strategicPossibility: true,
        successConditions: true,
        patientFlowBlockStarStakeholderDefinitions: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: bigIdeaDetailsData,
    error: bigIdeaDetailsError,
    loading: bigIdeaDetailsLoading,
    startPolling: bigIdeaDetailsStartPolling,
    stopPolling: bigIdeaDetailsStopPolling,
  } = useBigIdeaDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderBy: {
        createdAt: Sort.Desc,
      },
      where: {
        strategyId: +strategyId,
      },
    },
  });

  const {
    data: tacticsData,
    error: tacticsError,
    loading: tacticsLoading,
    startPolling: startTacticsPolling,
    stopPolling: stopTacticsPolling,
  } = useCommercialTacticDetailsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
      },
      orderBy: {
        id: Sort.Asc,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  useEffect(() => {
    startCompetitiveAdvantageRowsPolling(polling.default);
    bigIdeaDetailsStartPolling(polling.default);
    startStrategyPolling(polling.default);
    startTacticsPolling(polling.default);
    return () => {
      bigIdeaDetailsStopPolling();
      stopCompetitiveAdvantageRowsPolling();
      stopStrategyPolling();
      stopTacticsPolling();
    };
  }, [
    bigIdeaDetailsStartPolling,
    bigIdeaDetailsStopPolling,
    startCompetitiveAdvantageRowsPolling,
    stopCompetitiveAdvantageRowsPolling,
    startStrategyPolling,
    stopStrategyPolling,
    startTacticsPolling,
    stopTacticsPolling,
  ]);

  const validTactics = useMemo(
    () =>
      tacticsData?.commercialTacticDetails?.items.filter(
        (val) => !!val.keyStatementId
      ),
    [tacticsData?.commercialTacticDetails?.items]
  );

  const bigIdeas = useMemo(() => {
    return bigIdeaDetailsData?.bigIdeaDetails?.items;
  }, [bigIdeaDetailsData?.bigIdeaDetails?.items]);

  const currency = strategyData?.strategy?.currency || '¥ CNY';

  const financialTactics = useMemo(() => {
    return validTactics?.filter((tactic) => {
      return localCountryTacticsEnabled
        ? financialSummarySelectedRegions.includes(
            tactic.region as CountryGlobal
          )
        : financialSummarySelectedRegions.includes('global');
    });
  }, [
    financialSummarySelectedRegions,
    localCountryTacticsEnabled,
    validTactics,
  ]);

  const financialBigIdeas = useMemo(() => {
    return bigIdeas?.filter((item) => {
      return localCountryTacticsEnabled
        ? financialSummarySelectedRegions.includes(
            item.country as CountryGlobal
          )
        : financialSummarySelectedRegions.includes('global');
    });
  }, [bigIdeas, financialSummarySelectedRegions, localCountryTacticsEnabled]);

  const { total, strategicImperatives, years } = getSums(
    competitiveAdvantageRowsData?.competitiveAdvantageRows?.items || [],
    financialTactics || [],
    financialBigIdeas || [],
    !!localCountryTacticsEnabled
  );

  const imperativeArray = Object.values(strategicImperatives).sort(
    (a, b) => b.total - a.total
  ) as StrategicImperative[];

  const largestStratTotal = imperativeArray.reduce(
    (acc, val) => (val.total > acc ? val.total : acc),
    0
  );

  const largestYearTotal = Object.values(years).reduce(
    (acc, val) => (val > acc ? val : acc),
    0
  );

  const tacticsForTimeline = useMemo(
    () =>
      [
        ...(bigIdeaDetailsData?.bigIdeaDetails?.items || []),
        ...(validTactics || []),
      ]
        .filter((item) => {
          if (localCountryTacticsEnabled) {
            const region = 'country' in item ? item.country : item.region;

            return timelineSummarySelectedRegion === region;
          } else {
            const region = 'country' in item ? item.country : item.region;

            return region === 'global';
          }
        })

        .map((tactic) => {
          return {
            id: tactic.id,
            __typename: tactic.__typename,
            dueDate: dateStringToMonthYear(tactic.due || ''),
            timingEnd: dateStringToMonthYear(tactic.to || ''),
            timingStart: dateStringToMonthYear(tactic.from || ''),
            competitiveAdvantageRowId: tactic.competitiveAdvantageRowId as number,
            focused: true,
            tacticText: tactic.text,
            usePrependedText: tactic.__typename === 'BigIdeaDetail',
          };
        }),
    [
      bigIdeaDetailsData?.bigIdeaDetails?.items,
      localCountryTacticsEnabled,
      timelineSummarySelectedRegion,
      validTactics,
    ]
  );

  const { keyEvents, startPolling, stopPolling } = useGetKeyEvents({
    skip: !showTimeline,
    where: {
      strategyId: Number(strategyId),
      type: Step.CommercialStrategy,
    },
    enabledConditions: {
      region: 'global',
      step: Step.CommercialStrategy,
    },
  });

  useEffect(() => {
    startPolling(polling.default);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const sortedTimestampedKeyEvents = useMemo(
    () =>
      keyEvents.reduce((acc, curr) => {
        const existingItem = acc[curr.date];
        // only include keyEvents that are enabled for the chosen region
        if (!curr.enabledForRequestRegion) {
          return acc;
        }

        if (existingItem) {
          existingItem.push(curr);

          return acc;
        }

        acc[curr.date] = [curr];

        return acc;
      }, {} as Record<string, KeyEventFragment[]>),
    [keyEvents]
  );

  return (
    <>
      <CommercialStrategyNav
        drugId={drugId}
        strategyId={strategyId}
        subStep={SubStep.Summary}
        countryTacticsEnabled={Boolean(localCountryTacticsEnabled)}
        setCountryTacticsEnabled={(val) => {
          setCountryTacticsEnabled(val);
        }}
        countryContributionsExist={false}
      />

      <PageWrapper>
        <ErrorWrapper
          isLoading={
            strategyLoading ||
            bigIdeaDetailsLoading ||
            tacticsLoading ||
            competitiveAdvantageRowsLoading
          }
          errors={[
            strategyError,
            competitiveAdvantageRowsError,
            bigIdeaDetailsError,
            tacticsError,
          ]}
          dataMissing={false}
        >
          <SummaryHeader
            setShowTimeline={setShowTimeline}
            showTimeline={showTimeline}
          >
            {localCountryTacticsEnabled &&
              (showTimeline ? (
                <CountriesDropdownSmall
                  country={timelineSummarySelectedRegion}
                  active={timelineCountryDropdownActive}
                  onClick={() =>
                    setTimelineCountryDropdownActive(
                      !timelineCountryDropdownActive
                    )
                  }
                  viewOnly={false}
                  hideCountryName={isMobile}
                  largeIcon
                  globalOptionText={'Global plan'}
                  dropDownRef={timelineCountryDropDownRef}
                  showDropdown={timelineCountryDropdownActive}
                  setShowDropdown={setTimelineCountryDropdownActive}
                  setCountry={(country) => {
                    const generatedPath = generatePath(path, {
                      drugId: drugId,
                      strategyId: strategyId,
                      region: country,
                    });

                    history.replace(generatedPath);
                  }}
                  allCountries={uniqueRegions}
                />
              ) : (
                <FinancialSummaryCountryDropdown
                  multiSelect
                  selectedRegions={financialSummarySelectedRegions}
                  setCountry={(country: CountryGlobal) => {
                    if (financialSummarySelectedRegions.includes(country)) {
                      setFinancialSummarySelectedRegions(
                        financialSummarySelectedRegions.filter(
                          (c) => c !== country
                        )
                      );
                    } else {
                      setFinancialSummarySelectedRegions([
                        ...financialSummarySelectedRegions,
                        country,
                      ]);
                    }
                  }}
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    const target = e.target as HTMLDivElement;
                    const dropdown = financialCountryDropDownRef.current;
                    if (!dropdown) return;
                    if (dropdown.contains(target)) return;

                    setFinancialCountryDropdownActive(
                      !financialCountryDropdownActive
                    );
                  }}
                  dropDownRef={financialCountryDropDownRef}
                  viewOnly={false}
                  hideCountryName={isMobile}
                  largeIcon
                  globalOptionText={'Global plan'}
                  showDropdown={financialCountryDropdownActive}
                  setShowDropdown={setFinancialCountryDropdownActive}
                  allCountries={uniqueRegions}
                />
              ))}
            {showKeyEventsButton && (
              <MedicalSummaryKeyEventsButton
                buttonText="Show events"
                mobile={useMobileIcon}
                onClick={() => setKeyEventsSidebarOpen(true)}
              />
            )}
            <KeyEventsSidebar
              type={Step.CommercialStrategy}
              isOpen={keyEventsSidebarOpen}
              onClose={() => setKeyEventsSidebarOpen(false)}
              strategyId={Number(strategyId)}
              region={lowerCase(region)}
              step={Step.CommercialStrategy}
              isGlobalContributor={isGlobalContributor}
              canEnable={
                (isLead || isCountryContributor) &&
                lowerCase(region) ===
                  (isLead ? 'global' : lowerCase(region || ''))
              } 
            />
          </SummaryHeader>
          {!showTimeline && (
            <TotalInvestment>
              <Subtitle1 color={colors.greyDark} style={{ display: 'inline' }}>
                Total investment required:{' '}
              </Subtitle1>
              <Subtitle1 style={{ display: 'inline' }}>
                {currency.charAt(0)} {total ? thousandSeparator(total) : `-`}
              </Subtitle1>
            </TotalInvestment>
          )}

          {showTimeline ? (
            <SummaryTimeline
              keyEvents={sortedTimestampedKeyEvents}
              emptyStateText={'No big ideas or  winning actions yet'}
              tactics={tacticsForTimeline || []}
              rows={
                competitiveAdvantageRowsData?.competitiveAdvantageRows?.items ||
                []
              }
              prependDetailText={'Big idea: '}
              isCountryTimeline={Boolean(
                localCountryTacticsEnabled &&
                  timelineSummarySelectedRegion !== 'global'
              )}
            />
          ) : (
            <>
              {imperativeArray.length ? (
                <>
                  <TopGroups>
                    <TopGroup>
                      <Subtitle2 style={{ marginBottom: 20 }}>
                        Strategic Imperative
                      </Subtitle2>
                      {imperativeArray.map((strat) => (
                        <div style={{ marginBottom: 20 }} key={strat.id}>
                          <BudgetBar
                            maxValue={largestStratTotal}
                            value={strat.total}
                            currency={currency}
                          />
                          <Caption
                            style={{ color: colors.greyDark, marginTop: 2 }}
                          >
                            {strat.strategicPossibility.name}
                          </Caption>
                        </div>
                      ))}
                    </TopGroup>
                    <TopGroup>
                      <Subtitle2 style={{ marginBottom: 20 }}>
                        Annual Spend{' '}
                        <span style={{ color: colors.greyDark }}>
                          Jan - Dec
                        </span>
                      </Subtitle2>
                      {Object.values(years).length ? (
                        Object.entries(years).map(([year, total]) => (
                          <div style={{ marginBottom: 20 }} key={year}>
                            <BudgetBar
                              maxValue={largestYearTotal}
                              value={total}
                              currency={currency}
                            />
                            <Caption
                              style={{ color: colors.greyDark, marginTop: 2 }}
                            >
                              {year}
                            </Caption>
                          </div>
                        ))
                      ) : (
                        <BudgetEmptyState includeIcon={false}>
                          <Subtitle2 color={colors.greyDark}>
                            No budget timings yet
                          </Subtitle2>
                        </BudgetEmptyState>
                      )}
                    </TopGroup>
                  </TopGroups>

                  <FinancialTables>
                    {imperativeArray.map((imperative) => {
                      const readableBudget = getReadableBudget(
                        currency,
                        imperative.total
                      );

                      const sortedByBudget = imperative.items.sort((a, b) => {
                        if (!a.totalBudget && !b.totalBudget) return 0;
                        if (!a.totalBudget) return 1;
                        if (!b.totalBudget) return -1;

                        return +b.totalBudget > +a.totalBudget ? 1 : -1;
                      });

                      return (
                        <ImperativeBudgets key={imperative.id}>
                          <TitleAndBudget>
                            <Subtitle2>
                              {imperative?.strategicPossibility?.name}
                            </Subtitle2>
                            <Subtitle2>{readableBudget}</Subtitle2>
                          </TitleAndBudget>

                          <SubTableHeaders>
                            <BodySmall color={colors.greyDark}>
                              Winning actions
                            </BodySmall>
                            <BodySmall color={colors.greyDark}>
                              Budget
                            </BodySmall>
                          </SubTableHeaders>
                          <PurpleSeparator />
                          {!!sortedByBudget?.length ? (
                            <TacticAndBudgetRow
                              budgets={sortedByBudget}
                              currency={currency}
                            />
                          ) : (
                            <StyledEmptyState includeIcon={false}>
                              <Subtitle2 color={colors.greyDark}>
                                No big ideas or winning actions yet
                              </Subtitle2>
                            </StyledEmptyState>
                          )}
                        </ImperativeBudgets>
                      );
                    })}
                  </FinancialTables>
                </>
              ) : (
                <SummaryPageEmptyStates
                  localCountryTacticsEnabled={localCountryTacticsEnabled}
                  financialSummarySelectedRegions={
                    financialSummarySelectedRegions
                  }
                />
              )}
            </>
          )}
        </ErrorWrapper>
      </PageWrapper>
    </>
  );
};
