import { kebabCase } from 'lodash';
import styled from 'styled-components/macro';
import { SubStep } from 'data/graphql/generated';
import { ButtonPill, TabGroup } from 'components/shared';
import { ButtonPillWrapper } from 'components/shared/ButtonPill';
import { useNavigateTo } from 'hooks';
import { HurdlesAndGapsAreasToExploreIdAndTitles } from '../constants/HurdlesAndGapsAreasToExploreConstants';
import { colors } from 'constants/colors';
import { device } from 'utils/breakpoints';

const StyledTabGroup = styled(TabGroup)`
  @media ${device.tabletMax} {
    padding: 0 15px;
  }

  .TabNavWrapper {
    :hover {
      background-color: ${colors.purple30};
    }

    background-color: ${colors.purple20};
  }
`;

const AreaToExploreButton = styled(ButtonPill)`
  max-height: 30px;
  ${ButtonPillWrapper} {
    max-height: 30px;
  }
`;

const AreaToExploreButtonsContainer = styled.div`
  display: flex;
  gap: 5px;

  height: 30px;
  width: 100%;
`;

interface AreaToExploreButtonsProps {
  area: string;
  region: string;
  drugId: number;
  strategyId: number;
  competitiveAdvantageRowId: number;
}

export const AreaToExploreButtons = ({
  area,
  region,
  drugId,
  strategyId,
  competitiveAdvantageRowId,
}: AreaToExploreButtonsProps) => {
  const navigateToAreaToExplore = useNavigateTo({
    drugId,
    strategyId,
    strategicImperativeId: competitiveAdvantageRowId,
    subStep: kebabCase(SubStep.HurdlesAndGaps),
    region,
  });

  return (
    <StyledTabGroup componentName="StepTab">
      <AreaToExploreButtonsContainer>
        {HurdlesAndGapsAreasToExploreIdAndTitles.map((areaToExplore) => (
          <AreaToExploreButton
            key={areaToExplore.id}
            level={areaToExplore.id === area ? 'primary-solid' : 'secondary'}
            text={areaToExplore.title}
            color={areaToExplore.id === area ? colors.white : colors.black}
            onClick={navigateToAreaToExplore({ area: areaToExplore.id })}
          />
        ))}
      </AreaToExploreButtonsContainer>
    </StyledTabGroup>
  );
};
