import { StyledDataFieldContainer } from '../../StrategyDefinitionHeader.styles';
import { StyledDeprecatedBulletPoints } from './DeprecatedBulletPoints.styles';

interface DeprecatedBulletPointsProps {
  title: string;
  name: string;
  isLead?: boolean;
  tooltip?: string;
  disabled?: boolean;
  placeholder?: string;
  bulletPoints?: string[];
  onUpdate: (value: string[]) => void;
}

export const DeprecatedBulletPoints = ({
  name,
  title,
  tooltip,
  placeholder,
  bulletPoints,
  isLead = false,
  disabled = false,
  onUpdate,
}: DeprecatedBulletPointsProps) => {
  const isDisabled = disabled || !isLead;
  const defaultPlaceholder = `Type the ${title.toLocaleLowerCase()}`;
  const placeholderText = placeholder ? placeholder : defaultPlaceholder;

  if (!bulletPoints) {
    return null;
  }

  return (
    <StyledDataFieldContainer
      isLead={isLead}
      className="textArea__wrapper"
      data-for={name}
      data-tip={tooltip}
      data-offset="{'top': 0}"
    >
      <StyledDeprecatedBulletPoints
        isDisabled={isDisabled}
        preventAutoFocus
        statement={bulletPoints}
        onUpdate={onUpdate}
        placeholder={placeholderText}
      />
    </StyledDataFieldContainer>
  );
};
