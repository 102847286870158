import {
  blockKeyStakeholdersDisplayNames,
  blockKeyStakeholdersColors,
} from 'components/PatientJourney/constants';
import { ButtonLabel, Icon } from 'components/shared';
import styled from 'styled-components/macro';

export const StakeholderRowContainer = styled.div<{
  stakeholderKey: keyof typeof blockKeyStakeholdersDisplayNames;
  starred: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  gap: 5px;
  background: ${({ stakeholderKey }) =>
    blockKeyStakeholdersColors[stakeholderKey].default};
  outline: ${({ stakeholderKey, starred }) =>
    starred &&
    `1.5px solid  ${blockKeyStakeholdersColors[stakeholderKey].stroke}`};

  :hover {
    background: ${({ stakeholderKey }) =>
      blockKeyStakeholdersColors[stakeholderKey].hover};
  }

  ${ButtonLabel} {
    cursor: default;
  }
`;

export const StarIcon = styled(Icon)<{
  stakeholderKey: keyof typeof blockKeyStakeholdersDisplayNames;
  starred: boolean;
}>`
  path {
    stroke: ${({ stakeholderKey }) =>
      blockKeyStakeholdersColors[stakeholderKey].stroke};

    fill: ${({ starred, stakeholderKey }) =>
      starred && blockKeyStakeholdersColors[stakeholderKey].stroke};
  }
  cursor: pointer;
`;

export const StakeholderIcon = styled(Icon)`
  margin: -5px 0;
  overflow: hidden;
`;
