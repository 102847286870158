import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useVersionQuery } from 'data/graphql/generated';

import { version as clientVersion } from '../version';
import { polling } from 'constants/polling';
import { Modal } from 'components/Modal';
import { Subtitle1, BodyNormal, ButtonPill } from 'components/shared';
import { zIndex } from 'constants/zIndex';
import { useVisibility } from 'hooks/useVisibilty';

const StyledModal = styled(Modal)`
  z-index: ${zIndex.versionCheckModal};
`;

console.log(clientVersion);

const Content = styled.div`
  padding: 20px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {}

export const VersionCheck: React.FC<Props> = () => {
  // query for version
  const { data, startPolling, stopPolling, loading, error, refetch } =
    useVersionQuery();
  const serverVersion = data?.version.version;
  const vis = useVisibility();

  useEffect(() => {
    startPolling(polling.version);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  useEffect(() => {
    refetch();
  }, [vis, refetch]);

  if (loading || error || serverVersion?.trim() === clientVersion?.trim()) {
    return null;
  }

  return (
    <StyledModal visible handleClose={() => {}}>
      <Content>
        <Subtitle1 style={{ textAlign: 'center', marginTop: 20 }}>
          A new version of Nmblr is available
        </Subtitle1>
        <BodyNormal>Refresh to get the latest version</BodyNormal>
        <ButtonWrapper>
          <ButtonPill
            text="Refresh"
            level="primary"
            onClick={() => {
              window.location.reload();
            }}
          />
        </ButtonWrapper>
      </Content>
    </StyledModal>
  );
};
