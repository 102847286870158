import React, { useEffect, useRef, useState } from 'react';
import { DatePicker, DateType } from 'components/shared/DatePicker';
import { BodySmall, Icon } from 'components/shared';
import { colors, zIndex } from 'constants/index';
import styled from 'styled-components/macro';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { monthNames } from 'utils/monthNames';
import { useHandleDropdownBottomOverlap } from 'hooks/useHandleDropdownBottomOverlap';

const Wrapper = styled.div`
  position: relative;
`;

const DatePreview = styled.div<{ disabled: boolean; isOpen: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  border-bottom: 0.5px solid
    ${({ disabled, isOpen }) =>
      isOpen ? colors.blue : disabled ? colors.greyMedium : colors.greyDark};
`;

export const StyledDatePicker = styled(DatePicker)<{
  alignRight: boolean;
  showAbove: null | number;
}>`
  position: absolute;
  z-index: ${zIndex.datePickerDropdown};
  right: ${({ alignRight }) => (alignRight ? 0 : 'auto')};

  transform: ${({ showAbove }) =>
    showAbove ? `translateY(calc(-100% + ${showAbove}px))` : 'unset'};
`;

interface Props {
  date: number[];
  onUpdate(date: DateType | null): void;
  lowerLimit?: number[];
  startDate?: number[];
  placeholder: string;
  className?: string;
  style?: React.CSSProperties;
  alignRight?: boolean;
  disabled?: boolean;
}
export const DatePickerDropdown = ({
  date,
  onUpdate,
  lowerLimit,
  startDate,
  placeholder,
  className,
  style,
  alignRight,
  disabled = false,
}: Props) => {
  const [localDate, setLocalDate] = useState(date);

  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = React.useRef<HTMLDivElement | null>(null);

  const [showDatePicker, setShowDatePicker] = useState(false);

  useClickOutsideComponent(containerRef, (event) => {
    setShowDatePicker(false);
  });

  const translateYVal = useHandleDropdownBottomOverlap({
    containerRef,
    dropdownRef,
    isActive: showDatePicker,
  });

  const hasDateValue = !!localDate.length;

  useEffect(() => {
    setLocalDate(date);
  }, [date]);

  return (
    <Wrapper
      ref={containerRef}
      className={className}
      style={{
        position: 'relative',
        ...style,
      }}
    >
      <DatePreview
        data-cy="date-picker-preview"
        className="cypress-date-picker-preview"
        isOpen={showDatePicker}
        disabled={disabled}
        onClick={() => {
          if (!disabled) setShowDatePicker(!showDatePicker);
        }}
      >
        {!!hasDateValue ? (
          <BodySmall color={disabled ? colors.greyDark : colors.black}>
            {monthNames[localDate[0]] + ' ' + localDate[1]}
          </BodySmall>
        ) : (
          <BodySmall color={disabled ? colors.black50 : colors.greyDark}>
            {placeholder}
          </BodySmall>
        )}

        <Icon
          name={`Chevron-${showDatePicker ? 'up' : 'down'}`}
          size={20}
          color={colors.greyDark}
        />
      </DatePreview>
      {showDatePicker && !disabled && (
        <StyledDatePicker
          showAbove={translateYVal}
          innerRef={dropdownRef}
          startDate={
            typeof startDate?.[0] === 'number'
              ? {
                  month: startDate[0],
                  year: startDate[1],
                }
              : undefined
          }
          alignRight={!!alignRight}
          date={
            !!hasDateValue
              ? { month: localDate[0], year: localDate[1] }
              : undefined
          }
          lowerLimit={
            typeof lowerLimit?.[0] === 'number'
              ? {
                  month: lowerLimit[0],
                  year: lowerLimit[1],
                }
              : undefined
          }
          visible={showDatePicker}
          onUpdate={(date) => {
            setShowDatePicker(false);

            onUpdate(date);
            if (date?.month !== undefined && date?.year !== undefined) {
              setLocalDate([date?.month, date?.year]);
            }
          }}
        />
      )}
    </Wrapper>
  );
};
