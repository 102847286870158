import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { SummaryTimeline } from 'components/MedicalStrategy/SummaryTimeline';
import { polling } from 'constants/index';
import {
  ArchetypeWhereInput,
  KeyEventFragment,
  SupportingMessageTacticWhereInput,
  useArchetypesQuery,
  useSupportingMessageTacticsQuery,
} from 'data/graphql/generated';
import { useEffect, useMemo } from 'react';
import { dateStringToMonthYear } from 'utils/dateStringToMonthYear';
import { useGetKeyEvents } from 'components/4-2-medical-strategy/shared/data-access-key-events-sidebar/src';
import { Step } from 'data/graphql/generated';

type Props = {
  strategyId: string;
  drugId: string;
  showTimeline: boolean;
};

export const AccessStrategySummaryTimeline = ({
  strategyId,
  showTimeline,
  drugId,
}: Props) => {
  const archetypesQueryVars: Record<'where', ArchetypeWhereInput> = {
    where: { strategyId: Number(strategyId), countriesAssigned: true },
  };
  const {
    data: archetypeData,
    loading: archetypeLoading,
    error: archetypeError,
    startPolling: archetypesStartPolling,
    stopPolling: archetypesStopPolling,
  } = useArchetypesQuery({
    variables: {
      ...archetypesQueryVars,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const supportingMessageTacticsVars: {
    where: SupportingMessageTacticWhereInput;
  } = {
    where: { strategyId: Number(strategyId), focus: true },
  };

  const { keyEvents, startPolling, stopPolling } = useGetKeyEvents({
    skip: !showTimeline,
    where: {
      strategyId: Number(strategyId),
      type: Step.AccessStrategy,
    },
    enabledConditions: {
      region: 'global',
      step: Step.AccessStrategy,
    },
  });
  
  useEffect(() => {
    startPolling(polling.default);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const {
    data: supportingMessageTacticsData,
    loading: supportingMessageTacticsLoading,
    error: supportingMessageTacticsError,
    startPolling: supportingMessageTacticsStartPolling,
    stopPolling: supportingMessageTacticsStopPolling,
  } = useSupportingMessageTacticsQuery({
    variables: supportingMessageTacticsVars,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    archetypesStartPolling(polling.default);
    supportingMessageTacticsStartPolling(polling.default);

    return () => {
      supportingMessageTacticsStopPolling();
      archetypesStopPolling();
    };
  }, [
    archetypesStartPolling,
    archetypesStopPolling,
    supportingMessageTacticsStartPolling,
    supportingMessageTacticsStopPolling,
  ]);

  const tacticsForTimeline = useMemo(
    () =>
      [
        ...(supportingMessageTacticsData?.supportingMessageTactics?.items ||
          []),
      ].map((tactic) => {
        return {
          id: tactic.id,
          dueDate: dateStringToMonthYear(tactic.due || ''),
          timingEnd: dateStringToMonthYear(tactic.to || ''),
          timingStart: dateStringToMonthYear(tactic.from || ''),
          competitiveAdvantageRowId: tactic.archetypeId ?? 0,
          focused: true,
          tacticText: tactic.text,
        };
      }),
    [supportingMessageTacticsData?.supportingMessageTactics?.items]
  );

  const rowsForTimeline = useMemo(
    () =>
      [...(archetypeData?.archetypes?.items || [])]
        .map((archetype) => {
          return {
            id: archetype.id,
            __typename: archetype.__typename,
            title: archetype.stakeholderDefinition.title,
          };
        })
        .sort((a, b) => {
          const nameA = a.title.toUpperCase();
          const nameB = b.title.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }),
    [archetypeData?.archetypes?.items]
  );

  const sortedTimestampedKeyEvents = useMemo(
    () =>
      keyEvents.reduce((acc, curr) => {
        const existingItem = acc[curr.date];
        // only include keyEvents that are enabled for the chosen region
        if (!curr.enabledForRequestRegion) {
          return acc;
        }

        if (existingItem) {
          existingItem.push(curr);

          return acc;
        }

        acc[curr.date] = [curr];

        return acc;
      }, {} as Record<string, KeyEventFragment[]>),
    [keyEvents]
  );

  return (
    <ErrorWrapper
      isLoading={archetypeLoading || supportingMessageTacticsLoading}
      errors={[archetypeError, supportingMessageTacticsError]}
      dataMissing={false}
    >
      <SummaryTimeline
        emptyStateText={'No tactics yet'}
        tactics={tacticsForTimeline || []}
        keyEvents={sortedTimestampedKeyEvents}
        rows={[
          {
            id: 0,
            __typename: 'archetype',
            title: 'Global (Across all archetypes)',
          },
          ...rowsForTimeline,
        ]}
      />
    </ErrorWrapper>
  );
};
