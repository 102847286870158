import React, { useEffect } from 'react';
import Textarea from 'react-expanding-textarea';
import styled, { css } from 'styled-components/macro';
import { colors } from 'constants/colors';
import { BodySmall } from 'components/shared';

export const ValueText = styled(BodySmall)`
  word-wrap: break-word;
`;

export type ResetValueFn = () => void;
export type OnBlurFn = (value: string) => void;
export type OnBlurResetValueFn = (
  value: string,
  resetValue?: ResetValueFn
) => void;

interface EditableTextareaProps {
  placeholder: string;
  initialValue: string;
  onBlur: OnBlurResetValueFn;
  editable?: boolean;
  autoFocus?: boolean;
  style?: React.CSSProperties;
  className?: string;
  overrideColor?: string;
}

export const TextareaStyle = css`
  min-width: 127px;
  display: block;
  padding: 0;
  width: 100%;
  resize: none;
  line-height: 20px;
  border-radius: 2px;
  border: 0.5px solid transparent;
  outline: none;
  font-size: 14px;
  color: ${colors.black};

  &:hover {
    border: 0.5px solid ${colors.greyDark};
  }
  &:focus {
    border: 0.5px solid ${colors.blue};
  }
  transition: border 0.3s;
`;

export const StyledTextarea = styled(Textarea)`
  ${TextareaStyle}
`;

export const EditableTextarea = ({
  placeholder,
  onBlur,
  initialValue,
  editable = true,
  autoFocus,
  style,
  className,
  overrideColor,
}: EditableTextareaProps) => {
  const [value, setValue] = React.useState<string>(initialValue);

  const resetValue = () => setValue(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return editable ? (
    <StyledTextarea
      className={className}
      style={style}
      autoFocus={!!autoFocus}
      placeholder={placeholder}
      rows={1}
      value={value}
      onChange={(e: React.FormEvent<HTMLTextAreaElement>) => {
        const target = e.nativeEvent.target as HTMLTextAreaElement;
        setValue(target?.value);
      }}
      onBlur={() => {
        setValue(value.trim());
        onBlur(value.trim(), resetValue);
      }}
    />
  ) : initialValue ? (
    <ValueText color={overrideColor}>{initialValue}</ValueText>
  ) : (
    <ValueText color={colors.greyDark}>{placeholder}</ValueText>
  );
};
