import {
  LeveragePointFragment,
  Stakeholder,
  StakeholderDefinitionForKeyStakeholdersDropdownFragment,
} from 'data/graphql/generated';

export function createStakeholderDropdownList(
  leveragePoint: LeveragePointFragment
) {
  const keyStakeholdersList = leveragePoint.keyStakeholdersList;

  const groupedByStakeholderType = keyStakeholdersList.reduce(
    (acc, curr) => {
      switch (curr.stakeholder) {
        case Stakeholder.Patient:
          acc[0].push(curr);
          break;
        case Stakeholder.Healthcare:
          acc[1].push(curr);
          break;
        case Stakeholder.Payor:
          acc[2].push(curr);

          break;
        case Stakeholder.Provider:
          acc[3].push(curr);

          break;
        case Stakeholder.Policymaker:
          acc[4].push(curr);
          break;
      }

      return acc;
    },
    [
      // Stakeholder.Patient
      [
        {
          __typename: 'StakeholderDefinition',
          id: 0,
          image: undefined,
          title: Stakeholder.Patient,
          stakeholder: Stakeholder.Patient,
        },
      ],
      // Stakeholder.Healthcare
      [
        {
          __typename: 'StakeholderDefinition',
          id: 0,
          image: undefined,
          title: 'Healthcare Professionals',
          stakeholder: Stakeholder.Healthcare,
        },
      ],
      // Stakeholder.Payor
      [
        {
          __typename: 'StakeholderDefinition',
          id: 0,
          image: undefined,
          title: Stakeholder.Payor,
          stakeholder: Stakeholder.Payor,
        },
      ],
      // Stakeholder.Provider
      [
        {
          __typename: 'StakeholderDefinition',
          id: 0,
          image: undefined,
          title: Stakeholder.Provider,
          stakeholder: Stakeholder.Provider,
        },
      ],
      // Stakeholder.Policymaker
      [
        {
          __typename: 'StakeholderDefinition',
          id: 0,
          image: undefined,
          title: Stakeholder.Policymaker,
          stakeholder: Stakeholder.Policymaker,
        },
      ],
    ] as StakeholderDefinitionForKeyStakeholdersDropdownFragment[][]
  );

  return groupedByStakeholderType.flat();
}
