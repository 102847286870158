import React, { createContext, useContext, useEffect, useState } from 'react';
import { polling } from '../constants';
import { useStrategyQuery } from '../data/graphql/generated';

export const StrategyContext = createContext<
  [
    (
      | Pick<
          ReturnType<typeof useStrategyQuery>,
          'data' | 'loading' | 'error' | 'refetch'
        >
      | undefined
    ),
    (val: undefined | number) => void
  ]
>([undefined, () => {}]);

export const StrategyProvider: React.FC = ({ children }) => {
  const [strategyId, setStrategyId] = useState<undefined | number>(undefined);

  const {
    data,
    loading,
    startPolling,
    stopPolling,
    error,
    refetch,
  } = useStrategyQuery({
    skip: !strategyId,
    variables: { id: strategyId ? +strategyId : 0 },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    startPolling(polling.default);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const strategyData = {
    data,
    loading,
    error,
    refetch,
  };

  return (
    <StrategyContext.Provider value={[strategyData, setStrategyId]}>
      {children}
    </StrategyContext.Provider>
  );
};

export function useStrategyContext(strategyId: number) {
  const [, setStrategyId] = useContext(StrategyContext);

  useEffect(() => {
    setStrategyId(strategyId);
  }, [setStrategyId, strategyId]);

  return useContext(StrategyContext);
}
