import { CircularProgressBar } from 'components/CircularProgressBar';
import { colors } from 'constants/index';
import React from 'react';
import styled, { css } from 'styled-components';
import { BodyLarge, BodyNormal, Caption } from '.';

const AlignedProgressWrapper = styled.div<{ inDonut?: boolean }>`
  margin: 0;
  display: flex;
  width: auto;
  height: auto;
  align-items: center;
  > .resultDetails {
    position: relative;
    margin-left: 5px;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    > .resultDetails_counter {
      display: flex;
      align-items: baseline;
    }
  }

  ${({ inDonut }) => {
    if (inDonut) {
      return css`
        width: 60px;
        height: 60px;

        position: relative;

        > .resultDetails {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          margin-left: 0px;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      `;
    }
  }}
`;

interface Props {
  alignmentCount: number;
  totalUsers: number;
  className?: string;
  style?: React.CSSProperties;
  inDonut?: boolean;
}

export const AlignmentCount = ({
  alignmentCount,
  totalUsers,
  className,
  style,
  inDonut,
}: Props) => {
  return (
    <AlignedProgressWrapper
      className={className}
      style={style}
      inDonut={inDonut}
    >
      <CircularProgressBar
        progress={(alignmentCount / totalUsers) * 100}
        stroke={inDonut ? 4 : 2}
        size={inDonut ? 60 : 23}
      />
      <div className="resultDetails">
        <div className="resultDetails_counter">
          {inDonut ? (
            <BodyNormal>{alignmentCount}</BodyNormal>
          ) : (
            <BodyLarge>{alignmentCount}</BodyLarge>
          )}
          <Caption color={colors.greyDark}>{`/${totalUsers}${
            inDonut ? '' : ' aligned'
          }`}</Caption>
        </div>

        {inDonut && <Caption color={colors.greyDark}>aligned</Caption>}
      </div>
    </AlignedProgressWrapper>
  );
};
