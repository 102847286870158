import React from 'react';
import styled, { css } from 'styled-components/macro';
import { BigIdeaFragment } from 'data/graphql/generated';
import { Icon, BodyNormal, BodySmall } from 'components/shared';
import { colors } from 'constants/colors';

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 40px;
  width: -webkit-fill-available;
  margin-bottom: -30px;
`;

const Content = styled.div`
  background: ${colors.white};
  border-radius: 5px;
  padding: 15px;
  width: -webkit-fill-available;
  margin: -5px;
`;

const FocusWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-left: -10px;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ImageWrapper = styled.div`
  width: 64px;
  height: 64px;
  position: relative;
  background: ${colors.greyLight};
  overflow: hidden;
  border-radius: 3px;
  margin-right: 15px;
`;

const ImageBG = styled.div<{ src: string }>`
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1.5);
  filter: blur(15px);

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Image = styled.div<{ src: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  ${({ src }) =>
    css`
      background-image: url(${src});
    `};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

interface Props {
  bigIdea: BigIdeaFragment;
}

export const BigIdea: React.FC<Props> = ({ bigIdea }) => {
  return (
    <Wrapper>
      <Content>
        {bigIdea.focus ? (
          <FocusWrapper>
            <Icon name="Tick" color={colors.purple} size={30} />
            <BodySmall>Focus</BodySmall>
          </FocusWrapper>
        ) : null}
        <TextWrapper>
          {bigIdea.image ? (
            <ImageWrapper>
              <ImageBG src={bigIdea.image} />
              <Image src={bigIdea.image} />
            </ImageWrapper>
          ) : null}
          <BodyNormal>{bigIdea.title || 'New big idea'}</BodyNormal>
        </TextWrapper>
      </Content>
    </Wrapper>
  );
};
