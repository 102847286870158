import React, { CSSProperties, useState } from 'react';
import { useDrag, XYCoord } from 'react-dnd';
import { createPortal } from 'react-dom';
import styled from 'styled-components/macro';
import { colors } from 'constants/colors';
import { Icon } from 'components/shared';
import { zIndex } from 'constants/zIndex';
import {
  AccessStrategyPrioritiseSupportingMessageFragment,
  CommercialTacticFragment,
  LongTermSolutionFragment,
} from 'data/graphql/generated';
import { device } from 'utils/breakpoints';
import { PinDragPreview } from '../shared/PinDragPreview';
import { DragNDropItems } from 'types';
import useDesktop from 'hooks/useDesktop';

const Wrapper = styled.div<{ isDragging: boolean; disableDrag?: boolean }>`
  position: absolute;
  cursor: ${({ disableDrag }) => (disableDrag ? 'initial' : 'grab')};
  opacity: ${({ isDragging }) => (isDragging ? '0.2' : '1')};
  z-index: ${zIndex.draggablePinSmallerScreens};

  @media ${device.desktopMin} {
    z-index: ${zIndex.draggablePin};
  }
`;

const PinIcon = styled(Icon)<{ $selected: boolean }>`
  color: ${({ $selected }) => ($selected ? colors.white : colors.purple10)};

  &:hover {
    color: ${colors.white};
  }
`;

function coordinatesWithinThreshold(distanceMoved: XYCoord | null) {
  if (!distanceMoved) return;

  const withinBounds = (coord: number) => coord > -10 && coord < 10;
  const { x, y } = distanceMoved as XYCoord;

  return withinBounds(x) && withinBounds(y);
}

interface Props {
  data:
    | CommercialTacticFragment
    | LongTermSolutionFragment
    | AccessStrategyPrioritiseSupportingMessageFragment;
  style: CSSProperties;
  showCallout: boolean;
  selected: boolean;
  onMouseOver: React.MouseEventHandler<HTMLDivElement> | undefined;
  onMouseOut: React.MouseEventHandler<HTMLDivElement> | undefined;
  setTouchPositioningView?: React.Dispatch<React.SetStateAction<string | null>>;
  removeFromGrid: () => void;
  children: React.ReactNode;
  disableDrag?: boolean;
}

export const DraggablePin: React.FC<Props> = ({
  data,
  onMouseOut,
  onMouseOver,
  showCallout,
  style,
  setTouchPositioningView,
  selected,
  removeFromGrid,
  children,
  disableDrag,
}) => {
  const isDesktop = useDesktop();
  const focused = 'focused' in data ? data.focused : false;
  const [distanceMoved, setDistanceMoved] = useState<XYCoord | null>(null);

  const [{ isDragging }, draggable] = useDrag(
    () => ({
      item: { type: DragNDropItems.PIN, data },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const res = monitor.getDropResult();

        // prevents a bug where the pin removes itself from the grid if dragged only a short distance
        if (!res && !coordinatesWithinThreshold(distanceMoved))
          removeFromGrid();
      },
    }),
    [distanceMoved]
  );

  return (
    <>
      {isDragging && (
        <PinDragPreview
          isBeingDragged={isDragging}
          setDistanceMoved={setDistanceMoved}
        />
      )}
      <Wrapper
        className="draggable-pin"
        ref={disableDrag ? null : draggable}
        isDragging={isDragging}
        style={style}
        onMouseOut={(e) => !isDragging && onMouseOut && onMouseOut(e)}
        onMouseOver={onMouseOver}
        id={`draggable-pin-${data.id}`}
        onClick={(e) => {
          e.stopPropagation();

          return setTouchPositioningView && !isDesktop
            ? setTouchPositioningView(data.localUid)
            : null;
        }}
        disableDrag={disableDrag}
      >
        <PinIcon
          name={focused && !selected ? 'PinSolid' : 'Pin'}
          size={30}
          $selected={selected}
        />

        {!isDragging &&
          isDesktop &&
          showCallout &&
          createPortal(children, document.body)}
      </Wrapper>
    </>
  );
};
