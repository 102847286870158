import { BodySmall, Icon } from 'components/shared';
import { CollapseIcon } from 'components/shared/ImperativeSummarySidebar';
import { ScrollableFadeContainer } from 'components/shared/ScrollableFadeContainer';
import { colors } from 'constants/index';
import { useAuthContext } from 'contexts/AuthContext';
import { usePatientJourneyPageContext } from 'contexts/PatientJourneyPageContext';
import {
  PatientJourneyBlockFragment,
  PatientJourneyState,
} from 'data/graphql/generated';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { useGetNavHeight } from 'hooks/useGetNavHeight';
import { useMemo, useRef } from 'react';
import { verifyUserRole } from 'utils/verifyUserRole';
import { PatientJourneyContainerType } from '../../types';
import BlockCardPreviewContainer from './BlockCardPreviewContainer';
import { CurrentPageContent } from './CurrentPageContent';
import { DesiredPageContent } from './DesiredPageContent';
import { StyledSidebar, Container, Header, Content } from './index.style';
import KeyStakeholders from './KeyStakeholders';

interface Props {
  onUpdate: PatientJourneyContainerType['updateBlock'];
  blocks: PatientJourneyBlockFragment[] | undefined;
  pageState: PatientJourneyState;
}

const BlockSidebar = ({ onUpdate, blocks, pageState }: Props) => {
  const containerRef = useRef(null);

  const [{ user }] = useAuthContext();
  const { isLead: canEdit } = verifyUserRole(user?.role, user?.country);

  const { closeSidebar, sidebarBlockId } = usePatientJourneyPageContext();

  const cachedBlock = useMemo(
    () => blocks?.find((block) => block.id === Number(sidebarBlockId)),
    [blocks, sidebarBlockId]
  );

  const navHeight = useGetNavHeight();

  const isOpen = !!sidebarBlockId;
  const handleClose = () => {
    closeSidebar();
  };

  useClickOutsideComponent(
    containerRef,
    () => {
      handleClose();
    },
    [
      'patient-journey-wrapper',
      'collaboration-modal',
      'block-textarea',
      'block-background',
      'toasts-wrapper',
    ]
  );

  return (
    <StyledSidebar
      outerRef={containerRef}
      isOpen={isOpen}
      heightOffset={navHeight}
    >
      <Container data-cy="block-sidebar">
        <Header>
          <Icon
            name={canEdit ? 'MoreDetailActive' : 'MoreTextActive'}
            size={30}
          />
          <BodySmall>Step details</BodySmall>
          <CollapseIcon
            onClick={() => handleClose()}
            name="Collapse right"
            size={30}
            color={colors.purple}
            style={{ marginLeft: 'auto' }}
          />
        </Header>
        {!!cachedBlock && (
          <Content>
            <ScrollableFadeContainer>
              <BlockCardPreviewContainer
                readOnly={!canEdit}
                block={cachedBlock}
                onUpdate={async (val: string) => {
                  if (cachedBlock) {
                    await onUpdate(cachedBlock, { text: val });
                  }
                }}
              />
              <KeyStakeholders
                pageState={pageState}
                onClick={async (val) => {
                  if (cachedBlock) {
                    await onUpdate(cachedBlock, val);
                  }
                }}
                starred={{
                  keyStakeHolderPatient: !!cachedBlock?.keyStakeHolderPatient,
                  keyStakeHolderHealthcare: !!cachedBlock?.keyStakeHolderHealthcare,
                  keyStakeHolderPayor: !!cachedBlock?.keyStakeHolderPayor,
                  keyStakeHolderProvider: !!cachedBlock?.keyStakeHolderProvider,
                  keyStakeHolderPolicymaker: !!cachedBlock?.keyStakeHolderPolicymaker,
                }}
                readOnly={!canEdit}
                slider={{
                  onUpdate: async (val) => {
                    if (cachedBlock) {
                      await onUpdate(cachedBlock, {
                        addressingIssueRating: val,
                      });
                    }
                  },
                  addressingIssueRating: cachedBlock?.addressingIssueRating,
                }}
              />
              {pageState === PatientJourneyState.Current && (
                <CurrentPageContent
                  cachedBlock={cachedBlock}
                  onUpdate={onUpdate}
                  canEdit={canEdit}
                />
              )}
              {pageState === PatientJourneyState.Desired && (
                <DesiredPageContent
                  cachedBlock={cachedBlock}
                  onUpdate={onUpdate}
                  canEdit={canEdit}
                />
              )}
            </ScrollableFadeContainer>
          </Content>
        )}
      </Container>
    </StyledSidebar>
  );
};

export default BlockSidebar;
