import styled, { css } from 'styled-components/macro';

import { CriticalMetricGoalFragment } from 'data/graphql/generated';
import { Table, TableProps } from 'components/shared';
import { TableDivHeaders } from 'components/shared/Table';
import { colors } from 'constants/colors';
import { device } from 'utils/breakpoints';

export const GoalsTableRowColumnTemplate = css`
  grid-template-columns: minmax(150px, 387px) repeat(2, minmax(130px, 200px)) minmax(
      410px,
      480px
    );

  column-gap: 15px;
`;

interface StyledTableProps extends TableProps<CriticalMetricGoalFragment> {
  isActive: boolean;
}

export const StyledGoalsTable = styled(Table)<StyledTableProps>`
  ${TableDivHeaders} {
    grid-template-columns: minmax(150px, 387px) repeat(2, minmax(130px, 200px)) minmax(
        410px,
        480px
      );
  }

  border-color: ${colors.black10};

  @media ${device.desktopMin} {
    min-width: fit-content;
    max-width: 1261px;
  }

  @media ${device.tabletMax} {
    padding: 15px;
  }
`;
