import { colors } from 'constants/index';
import React, { useLayoutEffect, useRef, useState } from 'react';

interface Props {
  progress: number;
  size?: number | string;
  stroke?: number;
}

//Created with help from https://css-tricks.com/building-progress-ring-quickly/ & https://briangrinstead.com/gradient/

export const CircularProgressBar = ({ progress, size, stroke = 4 }: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const circleRef = useRef<SVGCircleElement>(null!);
  const [viewboxWidth, setViewboxWidth] = useState(0);
  const [radius, setRadius] = useState(0);

  const circumference = radius * 2 * Math.PI;

  useLayoutEffect(() => {
    if (wrapperRef.current?.offsetWidth) {
      setRadius(
        Number(wrapperRef.current?.offsetWidth) / 2 - stroke * 2 || 242
      );
    }

    if (circleRef.current?.getBBox().width) {
      setViewboxWidth(circleRef.current?.getBBox().width + stroke || 0);
    }
  }, [size, stroke, radius, wrapperRef.current?.offsetWidth]);

  return (
    <div
      ref={wrapperRef}
      className="CircularProgressBar"
      style={{
        width: size || '',
        height: size || '',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 'none',
      }}
    >
      <svg
        preserveAspectRatio={'xMinYMin meet'}
        viewBox={`0 0 ${viewboxWidth} ${viewboxWidth}`}
        width="100%"
        height="100%"
      >
        <defs>
          <linearGradient
            spreadMethod="pad"
            id="CircularProgressBar__linearGradient"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <stop
              offset="0%"
              style={{ stopColor: 'rgb(255, 0, 200)', stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: 'rgb(120, 0, 255)', stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
        <circle
          stroke={colors.greyMedium}
          fill="transparent"
          strokeWidth={stroke}
          r={radius}
          cx={'50%'}
          cy={'50%'}
        />
        <circle
          ref={circleRef}
          stroke="url(#CircularProgressBar__linearGradient)"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference + ' ' + circumference}
          strokeDashoffset={circumference - (progress / 100) * circumference}
          style={{
            transformOrigin: 'center',
            transform: 'rotate(-90deg)',
          }}
          r={radius}
          cx={'50%'}
          cy={'50%'}
        />
      </svg>
    </div>
  );
};
