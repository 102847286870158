import React from 'react';

import {
  CriticalMetricGoalFragment,
  CriticalMetricGoalUpdateInput,
} from 'data/graphql/generated';
import { useModal } from 'hooks';

import { useFocusGoalModal } from '../../../data-access-critical-metrics-goals/src';
import { useGoalFrequency } from '../../../data-access-critical-metrics-goals/src';
import { useGoalInput } from '../../../data-access-critical-metrics-goals/src';

import { DesktopGoalsTableRow } from './components/DesktopGoalsTableRow';
import { MobileGoalsTableRow } from './components/MobileGoalsTableRow';
import { UpdateRowErrorModal } from './components/UpdateRowErrorModal';
import { GoalFrequencyDropdownOptionType } from '../../../shared-ui-goals/src';
import { GoalInputDropdownOptionType } from '../../../shared-ui-goals/src';
import { FieldLockedModal } from './components/modals/FieldLockedModal';

interface GoalsTableRowProps {
  isDesktop: boolean;
  data: CriticalMetricGoalFragment;
  isLead: boolean;
  isContributor: boolean;
  autoFocus: boolean;
  onUpdate: (data: CriticalMetricGoalUpdateInput) => Promise<void>;
  onDelete: () => void;
}

export const GoalsTableRow: React.FC<GoalsTableRowProps> = ({
  data,
  isDesktop,
  isLead,
  isContributor,
  autoFocus,
  onUpdate,
  onDelete,
}) => {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const {
    isOpen: showUpdateErrorModal,
    open: openUpdateErrorModal,
    close: closeUpdateErrorModal,
  } = useModal();
  const {
    isOpen: isFieldLockedModalVisible,
    open: openFieldLockedModal,
    close: closeFieldLockedModal,
  } = useModal();

  const {
    frequency: selectedFrequency,
    setFrequency: setSelectedFrequency,
  } = useGoalFrequency(data.frequency);

  const { input: selectedInput, setInput: setSelectedInput } = useGoalInput(
    data.input
  );

  const handleUpdate = React.useCallback(
    async (updated: CriticalMetricGoalUpdateInput) => {
      if (
        (data.isFocused && updated.input === null) ||
        (data.isFocused && updated.frequency === null)
      ) {
        return;
      }

      await onUpdate(updated);
    },
    [data.isFocused, onUpdate]
  );

  const {
    isFocused,
    setIsFocused,
    closeModal,
    toggleFocus,
    modalSlideIdx,
    setModalSlideIdx,
    setShowSingleSlide,
  } = useFocusGoalModal({ isFocused: data.isFocused, onUpdate: handleUpdate });

  const handleSetFrequency = React.useCallback<
    React.Dispatch<React.SetStateAction<GoalFrequencyDropdownOptionType | null>>
  >(
    (value) => {
      if (isFocused && value === null) {
        openUpdateErrorModal();
        return;
      }

      setSelectedFrequency(value);
    },
    [isFocused, openUpdateErrorModal, setSelectedFrequency]
  );

  const handleSetInput = React.useCallback<
    React.Dispatch<React.SetStateAction<GoalInputDropdownOptionType | null>>
  >(
    (value) => {
      if (isFocused && value === null) {
        openUpdateErrorModal();
        return;
      }

      setSelectedInput(value);
    },
    [isFocused, openUpdateErrorModal, setSelectedInput]
  );

  const canUpdate = (value: string | number | null) => {
    const isEmptyString = typeof value === 'string' && value.length === 0;

    if (data.isFocused && (!value || isEmptyString)) {
      openUpdateErrorModal();
      return false;
    }

    return !!data?.id || (!data?.id && !!value);
  };

  if (isDesktop) {
    return (
      <>
        <FieldLockedModal
          isVisible={isFieldLockedModalVisible}
          onClose={closeFieldLockedModal}
        />
        <UpdateRowErrorModal
          isVisible={showUpdateErrorModal}
          onClose={closeUpdateErrorModal}
        />
        <DesktopGoalsTableRow
          closeModal={closeModal}
          modalSlideIdx={modalSlideIdx}
          setModalSlideIdx={setModalSlideIdx}
          setShowSingleSlide={setShowSingleSlide}
          data={data}
          onUpdate={handleUpdate}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          toggleFocus={toggleFocus}
          autoFocus={autoFocus}
          isLead={isLead}
          isContributor={isContributor}
          canUpdate={canUpdate}
          selectedFrequency={selectedFrequency}
          setSelectedFrequency={handleSetFrequency}
          selectedInput={selectedInput}
          setSelectedInput={handleSetInput}
          isDeleting={isDeleting}
          setIsDeleting={setIsDeleting}
          onDelete={onDelete}
          onLockedFieldClick={openFieldLockedModal}
        />
      </>
    );
  }

  return (
    <>
      <FieldLockedModal
        isVisible={isFieldLockedModalVisible}
        onClose={closeFieldLockedModal}
      />
      <UpdateRowErrorModal
        isVisible={showUpdateErrorModal}
        onClose={closeUpdateErrorModal}
      />
      <MobileGoalsTableRow
        closeModal={closeModal}
        modalSlideIdx={modalSlideIdx}
        setModalSlideIdx={setModalSlideIdx}
        setShowSingleSlide={setShowSingleSlide}
        data={data}
        onUpdate={handleUpdate}
        isFocused={isFocused}
        setIsFocused={setIsFocused}
        toggleFocus={toggleFocus}
        autoFocus={autoFocus}
        isLead={isLead}
        isContributor={isContributor}
        canUpdate={canUpdate}
        selectedFrequency={selectedFrequency}
        setSelectedFrequency={handleSetFrequency}
        selectedInput={selectedInput}
        setSelectedInput={handleSetInput}
        isDeleting={isDeleting}
        setIsDeleting={setIsDeleting}
        onDelete={onDelete}
        onLockedFieldClick={openFieldLockedModal}
      />
    </>
  );
};
