import styled, { css } from 'styled-components/macro';

import { colors } from 'constants/colors';
import { BodySmall, Subtitle2 } from 'components/shared';
import { device } from 'utils/breakpoints';

export const TrackedGoalsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
`;

export const GoalTrackingCard = styled.div<{
  isExpanded?: boolean;
  isPreview?: boolean;
  isActive?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background: ${colors.white};
  padding: 15px;
  cursor: ${({ isPreview }) => (isPreview ? 'pointer' : 'default')};

  width: 100%;
  height: ${({ isExpanded }) => (isExpanded ? '320px' : '295px')};

  @media ${device.tabletMin} {
    width: ${({ isExpanded }) => (isExpanded ? '430px' : '339px')};
  }

  border: ${({ isExpanded, isActive }) =>
    isExpanded
      ? 'none'
      : `1px solid ${isActive ? colors.purple : colors.greyMedium}`};
  border-radius: ${({ isExpanded }) => (isExpanded ? 'none' : '5px')};

  :hover {
    border: ${({ isExpanded }) =>
      isExpanded ? 'none' : `1px solid ${colors.purple30}`};
  }
`;

export const GoalTrackingCardHeader = styled.div`
  align-self: stretch;
`;

export const GoalTrackingCardTitle = styled(Subtitle2)<{
  isExpanded?: boolean;
}>`
  align-self: stretch;

  ${({ isExpanded }) =>
    !isExpanded &&
    css`
      max-height: 50px;
      width: 309px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    `}
`;

export const GoalTrackingCardDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const GoalTrackingCardTargetSubtitle = styled(BodySmall)`
  color: ${colors.greyDark};
`;

export const GoalTrackingCardInputTrackingTitle = styled(BodySmall)`
  color: ${colors.greyDark};
`;

export const GoalTrackingCardProgressStatus = styled.div<{
  isOnTrack: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 0px 5px;
  gap: 5px;
  background: ${({ isOnTrack }) =>
    isOnTrack ? colors.greenPurpleGradient : colors.redPurpleGradient};
  border-radius: 5px;
`;
