import { colors } from 'constants/colors';
import {
  StrategicPossibility,
  StrategicQuestionListFragment,
  StrategyFragment,
  SubStep,
} from 'data/graphql/generated';
import useMobile from 'hooks/useMobile';
import React, {
  Dispatch,
  SetStateAction,
  useRef,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components/macro';
import { BodySmall, Icon } from '..';
import { ImperativeSummaryCard } from '../ImperativeSummaryCard';
import { device } from 'utils/breakpoints';
import { Loading } from 'components/Loading';
import { zIndex } from 'constants/zIndex';
import { useParams } from 'react-router-dom';
import { kebabCase } from 'lodash';
import { CountryGlobal } from 'types';
import { GreyCardComponent } from './GreyCardComponent';
import { KeyIssuesSummaryCard } from '../KeyIssuesSummaryCard';

export const sidebarWidth = 300;

export const sidebarTransition = {
  timingFunction: 'linear',
  duration: 200,
};

export const Wrapper = styled.div<{
  sidebarOpen: boolean;
  desktopWrapperTop: number;
  totalNavHeight: number;
}>`
  background: ${colors.white};
  width: ${sidebarWidth}px;
  min-width: ${sidebarWidth}px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  z-index: ${zIndex.imperativeSummarySidebar};
  order: 1;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  margin-right: ${({ sidebarOpen }) => !sidebarOpen && `-${sidebarWidth}px`};
  min-height: 100%;
  transition: margin-right ${sidebarTransition.duration}ms
    ${sidebarTransition.timingFunction};

  @media ${device.tabletMin} {
    top: ${({ desktopWrapperTop, totalNavHeight }) =>
    desktopWrapperTop <= totalNavHeight ? `${totalNavHeight}px` : 0};
    position: ${({ desktopWrapperTop, totalNavHeight }) =>
    desktopWrapperTop <= totalNavHeight ? 'fixed' : 'absolute'};
  }
`;

const Header = styled.header`
  height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
`;

const SidebarTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const CollapseIcon = styled(Icon)`
  cursor: pointer;

  &:hover {
    background: ${colors.black10};
    border-radius: 5px;
  }
`;

const FadeContainer = styled.div<{ applyFade: boolean }>`
  position: relative;
  overflow: hidden;
  height: calc(100% - 55px);

  &::after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    z-index: 1;

    box-shadow: ${({ applyFade }) =>
    applyFade && `inset 0px 35px 45px -52px rgba(0, 0, 0, 0.5)`};
  }
`;

const Content = styled.div`
  padding: 0 15px 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  overflow-y: auto;
`;

interface Props {
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  desktopWrapperTop: number;
  totalNavHeight: number;
  activeImperativeId?: number | null;
  activeImperative?: Pick<
    StrategicPossibility,
    'name' | 'driverOfTheBehaviour' | 'behaviourToDrive'
  > | null;
  drivers?: string[];
  barriers?: string[];
  strategicObjectives?:
  | string[]
  | {
    global: string[];
    regional: string[];
  }
  | null;
  distinctiveCapabilities?: string[] | null;
  keyIssue?: string;
  unlockingInsight?: string;
  medicalObjectives?: string[];
  globalHurdlesAndGaps?: StrategicQuestionListFragment[];
  localHurdlesAndGaps?: StrategicQuestionListFragment[];
  selectedRegion?: CountryGlobal;
  className?: string;
  preventDocumentStyles?: boolean;
  strategy?: StrategyFragment | null;
  customTitle?: string;
}

interface URLParams {
  substep: string;
}

export const ImperativeSummarySidebar: React.FC<Props> = ({
  sidebarOpen,
  setSidebarOpen,
  desktopWrapperTop,
  totalNavHeight,
  activeImperativeId,
  activeImperative,
  drivers,
  barriers,
  strategicObjectives,
  distinctiveCapabilities,
  keyIssue,
  unlockingInsight,
  medicalObjectives,
  globalHurdlesAndGaps,
  localHurdlesAndGaps,
  selectedRegion,
  className,
  preventDocumentStyles = false,
  strategy,
  customTitle,
}) => {
  const closeSidebar = () => setSidebarOpen(false);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const [fadeContent, setFadeContent] = useState(false);
  const isMobile = useMobile();
  const { substep }: URLParams = useParams();

  // Content scrolls below Header with a shadow effect
  useEffect(() => {
    const content = document.getElementById('sidebar-content');

    function toggleFadeContent() {
      if (!!content && content.scrollTop > 0) {
        setFadeContent(true);
      } else {
        setFadeContent(false);
      }
    }

    content?.addEventListener('scroll', toggleFadeContent);

    return () => {
      content?.removeEventListener('scroll', toggleFadeContent);
    };
  });

  useEffect(() => {
    if (isMobile && !preventDocumentStyles) {
      if (sidebarOpen) {
        document.body.style.overflowY = 'hidden';
        document.body.style.height = '100vh';
      } else {
        document.body.style.overflowY = 'unset';
        document.body.style.height = 'unset';
      }
    }

    return () => {
      document.body.style.overflowY = 'unset';
      document.body.style.height = 'unset';
    };
  }, [sidebarOpen, isMobile, preventDocumentStyles]);
  const globalHurdles = globalHurdlesAndGaps?.[0]?.list?.filter((v) => !!v);
  const globalGaps = globalHurdlesAndGaps?.[1]?.list?.filter((v) => !!v);
  const localHurdles = localHurdlesAndGaps?.[0]?.list?.filter((v) => !!v);
  const localGaps = localHurdlesAndGaps?.[1]?.list?.filter((v) => !!v);

  return (
    <Wrapper
      sidebarOpen={sidebarOpen}
      ref={sidebarRef}
      totalNavHeight={totalNavHeight}
      desktopWrapperTop={desktopWrapperTop}
      className={className}
    >
      <Header>
        <SidebarTitle>
          {strategy ? (
            <BodySmall>Strategic statements recap</BodySmall>
          ) : (
            <>
              <Icon
                name="MoreText"
                size={30}
                color={colors.greyDark}
                style={{ marginRight: 5 }}
              />
              <BodySmall>
                {customTitle ? customTitle : 'Strategic Imperative'}
              </BodySmall>
            </>
          )}
        </SidebarTitle>
        <CollapseIcon
          onClick={closeSidebar}
          name="Collapse right"
          size={30}
          color={colors.purple}
        />
      </Header>

      <FadeContainer applyFade={fadeContent}>
        <Content id="sidebar-content">
          {!!activeImperative && !!activeImperativeId ? (
            <>
              <ImperativeSummaryCard strategicImperative={activeImperative} />

              {drivers && (
                <GreyCardComponent title="Drivers" listData={drivers} />
              )}

              {barriers && (
                <GreyCardComponent title="Barriers" listData={barriers} />
              )}

              {!!keyIssue && (
                <KeyIssuesSummaryCard title="Key issue" data={keyIssue} />
              )}

              {strategicObjectives && (
                <GreyCardComponent
                  title="Goals"
                  viewingCountry={
                    selectedRegion !== 'global' ? selectedRegion : undefined
                  }
                  listData={
                    'regional' in strategicObjectives
                      ? strategicObjectives.global
                      : strategicObjectives
                  }
                  localListData={
                    'regional' in strategicObjectives
                      ? strategicObjectives.regional
                      : undefined
                  }
                />
              )}

              {!!medicalObjectives && (
                <GreyCardComponent
                  title="Medical Objectives"
                  listData={medicalObjectives}
                />
              )}

              {!!globalHurdles && (
                <GreyCardComponent
                  title="Hurdles (External)"
                  listData={globalHurdles}
                  viewingCountry={
                    selectedRegion === 'global' ? undefined : selectedRegion
                  }
                  localListData={localHurdles}
                />
              )}

              {!!globalGaps && (
                <GreyCardComponent
                  title="Gaps (Internal)"
                  listData={globalGaps}
                  viewingCountry={
                    selectedRegion === 'global' ? undefined : selectedRegion
                  }
                  localListData={localGaps}
                />
              )}

              {typeof unlockingInsight === 'string' && (
                <GreyCardComponent
                  title="Unlocking insight"
                  data={unlockingInsight}
                />
              )}

              {distinctiveCapabilities && (
                <GreyCardComponent
                  title="Distinctive Capabilities"
                  listData={distinctiveCapabilities}
                />
              )}
            </>
          ) : strategy ? (
            <>
              {substep === kebabCase(SubStep.StrategicQuestion) && (
                <GreyCardComponent
                  title="Strategic problem"
                  data={strategy.strategicProblem || 'Not identified yet'}
                />
              )}
              <GreyCardComponent
                title="Winning aspiration"
                data={strategy.winningAspiration || 'Not identified yet'}
              />
            </>
          ) : (
            <Loading />
          )}
        </Content>
      </FadeContainer>
    </Wrapper>
  );
};
