import { ModalForm, TopBar } from 'components/ModalForm';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { ButtonPill } from 'components/shared';
import { TooltipWrapper } from 'components/shared/ButtonPill';
import { Content } from 'components/Modal';
import ReactDOM from 'react-dom';
import { zIndex } from 'constants/index';

const Wrapper = styled.div`
  ${TooltipWrapper} {
    width: 180px;
    margin: auto;
  }

  ${Content} {
    min-width: 345px;
    max-width: 456px;
    height: unset;
    margin: 120px auto 0px auto;
    border-radius: 5px;
    width: 90%;

    ${TopBar} {
      border-radius: 5px 5px 0 0;
    }
  }
  position: fixed;
  z-index: ${zIndex.errorModal};
`;

interface Props {
  title: string;
  text: string;
  visible: boolean;
  handleClose(): void;
  children?: ReactNode;
  className?: string;
}

export const ErrorModal: React.FC<Props> = ({
  title,
  text,
  visible,
  handleClose,
  children,
  className,
}) => {
  return ReactDOM.createPortal(
    <Wrapper className={className}>
      <ModalForm
        heading={title}
        message={text}
        visible={visible}
        handleClose={handleClose}
      >
        {children}
        <ButtonPill text={'OK'} onClick={handleClose} />
      </ModalForm>
    </Wrapper>,
    document.body
  );
};
