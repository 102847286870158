import { ModalForm } from 'components/ModalForm';
import { ButtonPill } from 'components/shared';
import { useRemoveCollaboratorMutation } from 'data/graphql/generated';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';
const CTAWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 15px;

  @media ${device.mobile} {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-items: center;
  }
`;

interface Props {
  name: string | undefined;
  collabUserId: number | undefined;
  removeStrategyId: number;
  modalOpen: boolean;
  setModalOpen(state: boolean): void;
  message?: string;
}

export const DeleteCollaboratorModal = ({
  name,
  collabUserId,
  removeStrategyId,
  modalOpen,
  setModalOpen,
  message,
}: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { strategyId, drugId } = useParams<{
    strategyId: string;
    drugId: string;
  }>();
  const [removeCollaborator] = useRemoveCollaboratorMutation();

  async function removeCollaboratorHandler(collabUserId: number) {
    try {
      setIsDeleting(true);

      await removeCollaborator({
        variables: {
          data: {
            userId: collabUserId,
            strategyId: +strategyId,
            drugId: +drugId,
          },
        },
      });
    } catch (err) {
      console.error(err);
      alert(err);
    } finally {
      setIsDeleting(false);
      setModalOpen(false);
    }
  }

  return (
    <ModalForm
      handleClose={() => setModalOpen(false)}
      visible={modalOpen}
      heading={`Remove ${name}`}
      message={message}
    >
      <CTAWrapper>
        <ButtonPill
          width="180px"
          text="Cancel"
          level="secondary"
          onClick={() => {
            setModalOpen(false);
          }}
        />
        <ButtonPill
          disabled={isDeleting}
          loading={isDeleting}
          level="danger"
          width="180px"
          text="Remove"
          onClick={async () => {
            if (typeof collabUserId !== 'number') {
              throw new Error('userID not found');
            } else {
              removeCollaboratorHandler(collabUserId);
            }
          }}
        />
      </CTAWrapper>
    </ModalForm>
  );
};
