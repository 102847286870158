import {
  differenceInCalendarQuarters,
  differenceInCalendarYears,
} from 'date-fns';

import {
  DIAMOND_WIDTH,
  QUARTER_COLUMN_WIDTH,
  YEAR_COLUMN_WIDTH,
} from '../constants';
import { RowProps } from '../SummaryTimelineRestyled';

const getDateYear = (date: string) => +date.slice(-4);
const getDateMonth = (date: string) => +date.slice(0, -4);

export const getTacticMeasurementsForTimeline = ({
  tactic,
  minDate,
  quarterColumnWidth,
}: {
  tactic: RowProps;
  minDate: number;
  quarterColumnWidth: number;
}) => {
  const monthsPerQuarter = 3;

  const endMonth =
    typeof tactic.timingEnd[0] === 'number'
      ? tactic.timingEnd[0]
      : tactic.timingStart[0];

  const dueDate = new Date(
    getDateYear(tactic.dueDate.join('')),
    getDateMonth(tactic.dueDate.join('')),
    1
  );

  const dueDatePosition =
    differenceInCalendarQuarters(dueDate, minDate) * quarterColumnWidth;

  const dueDateQuarterOffset = Math.max(
    (((tactic.dueDate[0] % monthsPerQuarter) + 1) / monthsPerQuarter) * 100,
    33.3
  );

  const dueDateYearColumnOffset =
    (differenceInCalendarYears(dueDate, minDate) + 1) * YEAR_COLUMN_WIDTH;

  const dueDateQuarterColumnOffset =
    Math.max(
      differenceInCalendarQuarters(dueDate, minDate) + 1 * QUARTER_COLUMN_WIDTH,
      0
    ) - differenceInCalendarYears(dueDate, minDate);

  const startDate = new Date(
    getDateYear(tactic.timingStart.join('')),
    getDateMonth(tactic.timingStart.join('')),
    1
  );

  const endDate = tactic.timingEnd.length
    ? new Date(
        getDateYear(tactic.timingEnd.join('')),
        getDateMonth(tactic.timingEnd.join('')),
        1
      )
    : startDate;

  const duration =
    Math.max(differenceInCalendarQuarters(endDate, startDate), 0) *
    quarterColumnWidth;

  const startPosition =
    startDate.getTime() === minDate
      ? 0
      : differenceInCalendarQuarters(startDate, minDate) * quarterColumnWidth;

  const startPositionQuarterOffset =
    ((tactic.timingStart[0] % monthsPerQuarter) / monthsPerQuarter) * 100;

  const endQuarterOffset =
    (((endMonth % monthsPerQuarter) + 1) / monthsPerQuarter) * 100;

  // These add the pixels occupied by the columns to the positions
  const startPositionYearColumnOffset =
    (differenceInCalendarYears(startDate, minDate) + 1) * YEAR_COLUMN_WIDTH;

  const startPositionQuarterColumnOffset =
    Math.max(
      differenceInCalendarQuarters(startDate, minDate) * QUARTER_COLUMN_WIDTH,
      0
    ) - differenceInCalendarYears(startDate, minDate);

  const durationQuarterOffset =
    Math.max(
      differenceInCalendarQuarters(endDate, startDate) * QUARTER_COLUMN_WIDTH,
      0
    ) - differenceInCalendarYears(endDate, startDate);

  const durationYearOffset = Math.max(
    differenceInCalendarYears(endDate, startDate) * YEAR_COLUMN_WIDTH,
    0
  );

  return {
    width:
      duration +
      durationQuarterOffset +
      durationYearOffset +
      endQuarterOffset -
      startPositionQuarterOffset,
    x:
      startPositionQuarterColumnOffset +
      startPositionQuarterOffset +
      startPositionYearColumnOffset +
      startPosition,
    diamondMargin:
      (dueDateQuarterColumnOffset > 2
        ? dueDateYearColumnOffset + 1.5
        : dueDateYearColumnOffset) +
      dueDatePosition +
      dueDateQuarterColumnOffset +
      dueDateQuarterOffset -
      Math.floor(DIAMOND_WIDTH / 2),
    textX:
      startPositionQuarterColumnOffset +
      startPositionQuarterOffset +
      startPositionYearColumnOffset +
      startPosition,
  };
};
