import { colors } from 'constants/colors';
import React from 'react';
import { ScorePillContainer } from './ScorePillContainer';
import { BodySmall } from './TextStyles';
interface Props {
  value: number;
  className?: string;
}

export const ScorePill: React.FC<Props> = ({ value, className }) => {
  return (
    <ScorePillContainer className={className} value={value}>
      {value ? (
        <>
          <BodySmall style={{ marginRight: 3 }} color={colors.greyDark}>
            Av.
          </BodySmall>
          <BodySmall color={colors.black}>{'' + value.toFixed(1)}</BodySmall>
        </>
      ) : (
        <BodySmall color={colors.greyDark}>No ratings</BodySmall>
      )}
    </ScorePillContainer>
  );
};
