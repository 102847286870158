import { useEffect, useMemo, useState } from 'react';
import { cloneDeep, debounce, isEqual } from 'lodash';

import { StrategicQuestionListFragment } from 'data/graphql/generated';
import { colors } from 'constants/colors';
import { useWidth } from 'hooks/useWidth';
import {
  BodyNormal,
  BodySmall,
  Caption,
  Collaboration,
  Tooltip,
} from 'components/shared';
import { DrugDetailBorderWrapper } from 'containers/Strategies';

import { StrategyDefinitionHeaderProps } from '../../StrategyDefinitionHeader.types';
import {
  AlignedProgressWrapper,
  MetricsWrapper,
  MultiBulletPointListWrapper,
  StyledBulletPointList,
  StyledCollaboration,
  StyledDrugDetailWrapper,
} from '../../StrategyDefinitionHeader.styles';
import { CircularProgressBar } from 'components/CircularProgressBar';

interface MultiListStrategyDefinitionHeaderProps
  extends Omit<
    StrategyDefinitionHeaderProps,
    'bulletPoints' | 'textValue' | 'type'
  > {
  bulletPoints: StrategicQuestionListFragment[];
}

export const MultiListStrategyDefinitionHeader = ({
  totalUsers,
  isLead,
  title,
  collaboration,
  style = {},
  disabled,
  className,
  placeholder,
  bulletPoints,
  tooltipMessage,
  hideAlignment,
  viewingOtherRegion,
  hideTooltip,
  handleSave,
}: MultiListStrategyDefinitionHeaderProps) => {
  const windowWidth = useWidth();
  const multipleListsMediumBreakpoint = windowWidth < 838;
  const splitTitle = title.split('\n');

  const [isEditing, setIsEditing] = useState(false);

  const [bulletPointsLocalState, setBulletPointsLocalState] = useState(
    cloneDeep(bulletPoints)
  );

  const isClean = useMemo(() => isEqual(bulletPoints, bulletPointsLocalState), [
    bulletPoints,
    bulletPointsLocalState,
  ]);

  // Only update bulletPoints when user is not editing
  useEffect(() => {
    if (bulletPoints && !isEditing) {
      setBulletPointsLocalState(bulletPoints);
    }

    // avoid overwriting bulletPointsLocalState when user is editing
    // eslint-disable-next-line
  }, [bulletPoints]);

  const save = async () => {
    if (isClean) {
      return;
    }

    await handleSave({
      lists: bulletPointsLocalState.map((x) => ({
        id: x.id,
        list: x.list,
      })),
    });
  };

  const debounceSave = debounce(async () => {
    setIsEditing(false);
    await save();
  }, 200);

  return (
    <DrugDetailBorderWrapper
      className={className}
      style={{
        width: '100%',
        maxWidth: '1050px',
        zIndex: 9,
        position: 'relative',
        ...style,
      }}
    >
      <StyledDrugDetailWrapper
        isMultipleLists
        style={{
          alignItems:
            typeof bulletPoints === 'object' ? 'flex-start' : 'center',
        }}
        isLead={isLead}
      >
        <div
          className="textArea__wrapper"
          data-for={'strategyDefinition'}
          data-tip={
            hideTooltip
              ? ''
              : viewingOtherRegion
              ? tooltipMessage
              : disabled
              ? tooltipMessage || 'Only Leads can edit'
              : ''
          }
          data-offset="{'top': 0}"
          style={{
            flex: 1,
            width: '100%',
            cursor: !isLead ? 'not-allowed' : 'default',
          }}
        >
          {bulletPointsLocalState && (
            <MultiBulletPointListWrapper stack={!collaboration}>
              {bulletPointsLocalState.map(({ id, list: listData }, idx) => {
                const list = listData || [''];
                const listIsEmpty = list.every((item) => item === '');

                return (
                  <div
                    key={id}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                    }}
                  >
                    <Caption>{splitTitle[idx]}</Caption>
                    {viewingOtherRegion && listIsEmpty ? (
                      <BodySmall color={colors.greyDark}>
                        None identified yet
                      </BodySmall>
                    ) : (
                      <StyledBulletPointList
                        style={{
                          pointerEvents:
                            disabled || viewingOtherRegion ? 'none' : 'all',
                        }}
                        handleFocus={() => {
                          debounceSave.cancel();
                          setIsEditing(true);
                        }}
                        handleBlur={() => {
                          debounceSave();
                        }}
                        preventAutoFocus
                        statement={list}
                        onUpdate={(value) => {
                          const data = bulletPointsLocalState.map((x) => {
                            const item = { ...x };
                            if (item.id === id) {
                              item.list = value;
                            }
                            return item;
                          });

                          setBulletPointsLocalState(data);
                        }}
                        placeholder={
                          (Array.isArray(placeholder)
                            ? placeholder[idx]
                            : placeholder) ||
                          `Type the ${splitTitle[idx]?.toLocaleLowerCase()}`
                        }
                      />
                    )}
                  </div>
                );
              })}
            </MultiBulletPointListWrapper>
          )}
        </div>

        <Tooltip id={'strategyDefinition'} effect="float" place="bottom" />
        {collaboration && totalUsers && (
          <MetricsWrapper isMultipleLists>
            <StyledCollaboration isMultipleLists>
              <Collaboration collaboration={collaboration} />
            </StyledCollaboration>

            {!hideAlignment && (
              <AlignedProgressWrapper isMultipleLists>
                <CircularProgressBar
                  progress={(collaboration.alignmentCount / totalUsers) * 100}
                  stroke={multipleListsMediumBreakpoint ? 2 : 4}
                  size={multipleListsMediumBreakpoint ? 23 : undefined}
                />
                <div className="resultDetails">
                  <div className="resultDetails_counter">
                    <BodyNormal>{collaboration.alignmentCount}</BodyNormal>
                    <Caption color={colors.greyDark}>{`/${totalUsers}${
                      multipleListsMediumBreakpoint ? ' aligned' : ''
                    }`}</Caption>
                  </div>
                  <Caption color={colors.greyDark}>
                    {multipleListsMediumBreakpoint ? '' : 'aligned'}
                  </Caption>
                </div>
              </AlignedProgressWrapper>
            )}
          </MetricsWrapper>
        )}
      </StyledDrugDetailWrapper>
    </DrugDetailBorderWrapper>
  );
};
