import React, { FC, useEffect, useCallback, ReactElement } from 'react';
import ReactDOM from 'react-dom';
import styled, { css, keyframes } from 'styled-components/macro';
import { device } from 'utils/breakpoints';

import { colors, zIndex } from '../constants';
import { Icon } from './shared';

const DetailHeaderInnerWrapper = styled.div`
  background-color: ${colors.white};
  border-radius: 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const DetailHeaderWrapper = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ color }) => color};
  padding: 15px;
  border-radius: 5px 5px 0px 0px;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Background = styled.div`
  display: block;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: ${zIndex.overlay};
  opacity: 0;
  animation: ${fadeIn} 0.3s;
  animation-fill-mode: forwards;

  @media (min-width: 481px) {
    background: rgba(20, 20, 39, 0.4);
  }
`;

type Size = 'small' | 'large';

export const Scroll = styled.div<{ disableScroll: boolean }>`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  padding-top: 50px;
  height: 100%;
  z-index: ${zIndex.dialog};

  @media (min-width: 481px) {
    ${({ disableScroll }) =>
      disableScroll
        ? css`
            overflow: hidden;
          `
        : css`
            overflow-y: auto;
            overflow-x: hidden;
          `}
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    padding-top: 0px;
  }
`;

export const Content = styled.div<{ padding: boolean; size: Size }>`
  display: block;
  opacity: 0;
  animation: ${fadeIn} 0.3s;
  animation-fill-mode: forwards;
  background: ${colors.white};
  height: calc(100vh - 50px);
  padding-bottom: 10px;

  @media ${device.mobile} {
    overflow-y: auto;
    overflow-x: hidden;
  }

  @media ${device.tabletMin} {
    height: auto;
    min-height: ${({ size }) => (size === 'large' ? '520px' : 'auto')};
    width: 90%;
    max-width: 456px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05),
      0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin: 60px auto 20px auto;
  }

  @media ${device.desktopMin} {
    max-width: ${({ size }) => (size === 'large' ? '940px' : '456px')};
  }
`;

export const MobileHeader = styled.div<{ headerColor?: string }>`
  background: ${({ headerColor }) =>
    headerColor ? headerColor : colors.black05};
  padding: 10px 6px;
  height: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: row;

  @media (min-width: 480px) {
    display: none;
  }

  span {
    margin-top: 4px;
    margin-left: 2px;
  }
`;

const MobileHeaderBack = styled.div`
  transform: rotate(90deg);
  width: 30px;
  height: 30px;
  display: inline-block;
`;

const Header = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  height: 40px;
  display: none;
  position: relative;
  border-radius: 5px 5px 0px 0px;

  @media (min-width: 480px) {
    display: block;
  }
`;

const HeaderIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 5px;
`;

interface Props {
  handleClose: () => void;
  visible: boolean;
  size?: Size;
  padding?: boolean;
  headerColor?: string;
  className?: string;
  portalId?: string;
  hideX?: boolean;
  hideMobileBack?: boolean;
  DetailHeader?: ReactElement | null;
  disableScroll?: boolean;
  keepOpenOnClickOutside?: boolean;
}

export const ModalLarge: FC<Props> = ({
  handleClose,
  visible,
  children,
  size = 'large',
  padding = false,
  headerColor = colors.greyDark,
  className,
  portalId,
  hideX,
  hideMobileBack = false,
  DetailHeader = null,
  disableScroll = false,
  keepOpenOnClickOutside = false,
}) => {
  // Keyboard shortcuts
  const keydownListener = useCallback(
    (keydownEvent) => {
      if (!visible) return;
      const { key } = keydownEvent;
      if (key === 'Escape') handleClose();
    },
    [visible, handleClose]
  );

  // Bind keyboard shortuts
  useEffect(() => {
    window.addEventListener('keydown', keydownListener, true);
    return () => window.removeEventListener('keydown', keydownListener, true);
  }, [keydownListener]);

  if (!visible) return null;

  const portalDOMElement = portalId && document.querySelector(portalId);

  return ReactDOM.createPortal(
    <div className={`${className} "modal"`}>
      <Background
        data-cy="modal-bg"
        onClick={(e: React.MouseEvent) => {
          if (e.target === e.currentTarget) {
            handleClose();
          }
        }}
      />
      <Scroll
        onClick={(e: React.MouseEvent) => {
          if (e.target === e.currentTarget) {
            if (keepOpenOnClickOutside) return;

            handleClose();
          }
        }}
        disableScroll={disableScroll}
      >
        <Content
          size={size}
          padding={padding}
          className="cypress-modal-large-content"
        >
          {!!DetailHeader ? (
            <DetailHeaderWrapper color={headerColor}>
              <DetailHeaderInnerWrapper>
                {DetailHeader}
              </DetailHeaderInnerWrapper>
            </DetailHeaderWrapper>
          ) : (
            <>
              <MobileHeader
                onClick={handleClose}
                headerColor={hideMobileBack ? headerColor : undefined}
              >
                {!hideMobileBack && (
                  <>
                    <MobileHeaderBack>
                      <Icon
                        name="Chevron-down"
                        size={30}
                        color={colors.black}
                      />
                    </MobileHeaderBack>
                    <span>Back</span>
                  </>
                )}
              </MobileHeader>
              <Header
                className="cypress-modal-large-header"
                onClick={handleClose}
                color={headerColor}
              >
                {!hideX && (
                  <HeaderIcon className="cypress-modal-large-header-close-icon">
                    <Icon name="Close" size={30} color={colors.black} />
                  </HeaderIcon>
                )}
              </Header>
            </>
          )}
          {children}
        </Content>
      </Scroll>
    </div>,
    portalDOMElement || document.body
  );
};
