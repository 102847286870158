import { Icon } from 'components/shared';
import { colors } from 'constants/index';
import React from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled.button<{
  right?: boolean;
  onClick(): void;
  height?: number | string;
  hide: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: width 0.3s;
  flex: none;
  padding: 0;
  overflow: hidden;
  width: ${({ hide }) => (hide ? 0 : '30px')};
  background: ${colors.white};
  border-radius: ${({ right }) =>
    right ? '0px 5px 5px 0' : '5px 0px 0px 5px'};
  height: ${({ height }) => height + 'px'};
  margin: 0;
  margin-right: ${({ hide }) => (hide ? '-2px' : 0)};

  &:hover {
    svg {
      transition: color 0.3s;
    }
    svg {
      color: ${colors.black};
    }
  }
`;

interface scrollerProps {
  right?: boolean;
  onClick(): void;
  height?: number | string;
  hide(): boolean;
}

export function GraphScroller({ right, onClick, height, hide }: scrollerProps) {
  return (
    <ButtonWrapper
      right={right}
      height={height}
      hide={hide()}
      onClick={onClick}
    >
      <Icon
        name={right ? 'Arrow-right' : 'Arrow-left'}
        size={30}
        color={colors.greyDark}
      />
    </ButtonWrapper>
  );
}
