import { ButtonLabel } from 'components/shared';
import { colors } from 'constants/index';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

const WedgeWrapper = styled.div<{ readOnly: boolean }>`
  width: 92px;
  height: 20px;
  display: flex;
  gap: 1px;

  > svg {
    cursor: ${({ readOnly }) => (readOnly ? 'default' : 'pointer')};
  }
`;

const Wrapper = styled.div`
  width: 209px;
  border-radius: 4px;
  border: 1px solid ${colors.greyMedium};
  padding: 4px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`;

interface Props {
  rating: number;
  onChange?(rating: number): void;
  readOnly?: boolean;
  className?: string;
}

const getRatingColour = (value: number) => {
  switch (value) {
    case 1:
      return colors.greenDark;
    case 2:
      return colors.orange;

    case 3:
      return colors.darkRed;
    default:
      return colors.greyMedium;
  }
};

const getRatingText = (value: number) => {
  switch (value) {
    case 1:
      return 'Low impact';
    case 2:
      return 'Medium impact';
    case 3:
      return 'High impact';
    default:
      return 'Unrated impact';
  }
};

export const BarRating = ({
  rating,
  onChange,
  readOnly = false,
  className,
}: Props) => {
  const [value, setValue] = useState(rating);
  const [hoveredWedge, setHoveredWedge] = useState<number>(0);

  useEffect(() => {
    setValue(rating);
  }, [rating]);

  function handleChange(value: number) {
    setValue(value);
    onChange?.(value);
  }

  return (
    <Wrapper
      className={className}
      style={{ borderColor: getRatingColour(value) }}
    >
      <WedgeWrapper
      data-cy='impact-rating'
      readOnly={readOnly}>
        <svg
          className="first__wedge"
          onMouseEnter={() => {
            if (!readOnly) setHoveredWedge(1);
          }}
          onMouseLeave={() => {
            if (!readOnly) setHoveredWedge(0);
          }}
          onClick={() => {
            if (!readOnly) {
              if (value !== 1) {
                handleChange(1);
              } else {
                handleChange(0);
              }
            }
          }}
          width="30"
          height="20"
          viewBox="0 0 30 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 17.2975C0 15.9729 0.960176 14.8434 2.26759 14.6303L88.5173 0.56784C90.3427 0.270215 92 1.67921 92 3.52874V17C92 18.6569 90.6569 20 89 20H2.70247C1.20994 20 0 18.7901 0 17.2975V17.2975Z"
            fill={
              !!hoveredWedge
                ? getRatingColour(hoveredWedge)
                : value > 0
                ? getRatingColour(value)
                : colors.greyMedium
            }
          />
        </svg>
        <svg
          className="second__wedge"
          onMouseEnter={() => {
            if (!readOnly) setHoveredWedge(2);
          }}
          onMouseLeave={() => {
            if (!readOnly) setHoveredWedge(0);
          }}
          onClick={() => {
            if (!readOnly) {
              if (value !== 2) {
                handleChange(2);
              } else {
                handleChange(0);
              }
            }
          }}
          width="30"
          height="20"
          viewBox="0 0 30 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-31 17.2975C-31 15.9729 -30.0398 14.8434 -28.7324 14.6303L57.5173 0.56784C59.3427 0.270215 61 1.67921 61 3.52874V17C61 18.6569 59.6569 20 58 20H-28.2975C-29.7901 20 -31 18.7901 -31 17.2975V17.2975Z"
            fill={
              !!hoveredWedge
                ? hoveredWedge > 1
                  ? getRatingColour(hoveredWedge)
                  : colors.greyMedium
                : value > 1
                ? getRatingColour(value)
                : colors.greyMedium
            }
          />
        </svg>
        <svg
          onMouseEnter={() => {
            if (!readOnly) setHoveredWedge(3);
          }}
          onMouseLeave={() => {
            if (!readOnly) setHoveredWedge(0);
          }}
          className="third__wedge"
          data-cy='impact-rating__third-wedge'
          onClick={() => {
            if (!readOnly) {
              if (value !== 3) {
                handleChange(3);
              } else {
                handleChange(0);
              }
            }
          }}
          width="30"
          height="20"
          viewBox="0 0 30 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-62 17.2975C-62 15.9729 -61.0398 14.8434 -59.7324 14.6303L26.5173 0.56784C28.3427 0.270215 30 1.67921 30 3.52874V17C30 18.6569 28.6569 20 27 20H-59.2975C-60.7901 20 -62 18.7901 -62 17.2975V17.2975Z"
            fill={
              !!hoveredWedge
                ? hoveredWedge > 2
                  ? getRatingColour(hoveredWedge)
                  : colors.greyMedium
                : value > 2
                ? getRatingColour(value)
                : colors.greyMedium
            }
          />
        </svg>
      </WedgeWrapper>
      <ButtonLabel
        style={{ width: '100%', textAlign: 'center', cursor: 'default' }}
      >
        {getRatingText(value)}
      </ButtonLabel>
    </Wrapper>
  );
};
