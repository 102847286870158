import React, { useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Link, useHistory, useParams } from 'react-router-dom';
import { InsightGroup } from 'components/InsightGroup';
import { Navbar } from 'components/Navbar';
import { Observations } from 'components/Observations';
import { Page } from 'components/Page';
import {
  BodyNormal,
  BodySmall,
  Icon,
  RotateScreenTip,
  StepHeaderBar,
  Subtitle2,
  Toggle,
} from 'components/shared';
import { ObservationDragLayer } from 'components/shared/Observation/ObservationDragLayer';
import { StakeholderDefinitionAvatars } from 'components/shared/StakeholderDefinitionAvatars';
import { StakeHolderTabs } from 'components/StakeHolderTabs';
import { colors, polling } from 'constants/index';
import { useStakeholderContext } from 'contexts/StakeholderContext';
import {
  BlockType,
  KeyInsightsQueryVariables,
  ObservationsQueryVariables,
  Sort,
  Stakeholder,
  Step,
  useKeyInsightCreateMutation,
  useKeyInsightDeleteMutation,
  useKeyInsightsQuery,
  useKeyInsightUpdateMutation,
  useObservationsQuery,
  usePatientJourneyBlocksQuery,
  usePatientJourneyColumnsQuery,
  useStakeholderDefinitionsWithPatientFlowBlockStarQuery,
} from 'data/graphql/generated';
import styled from 'styled-components/macro';
import { URLParams } from 'types';
import { device } from 'utils/breakpoints';
import buildStrategyURL from 'utils/buildStrategyURL';
import { ObservationsPane } from './ObservationsPane';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import useDesktop from 'hooks/useDesktop';
import AssignObservationsModal from 'components/AssignObservationsModal';

interface Props {}

const PageWrapper = styled(Page)<{ showAddObservations: boolean }>`
  position: relative;

  @media (max-width: 1570px) {
    padding-top: 50px;
  }

  @media ${device.mobile} {
    > div {
      width: 100%;
      padding: 0;
    }
  }
`;

const StepHeaderBarWrapper = styled.div`
  margin-top: 155px;

  @media ${device.tabletMin} {
    margin-top: 160px;
  }

  @media ${device.desktopMin} {
    margin-top: 110px;
  }

  ${StepHeaderBar} {
    padding: 15px;

    ${Subtitle2} {
      margin-bottom: 5px;
    }
  }
`;

const ObservationsToggleWrapper = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
`;

const InsightGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > * + * {
    margin-top: 15px;
  }
`;

const EmptyStateWrapper = styled.div`
  background: ${colors.white};
  width: 100%;
  height: 345px;
  padding: 12px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.black30};
  p {
    text-align: center;
  }
  @media ${device.tabletMax} {
    width: calc(100% - 30px);
    margin: 0 auto;
  }
`;

function mapToDBStakeholder(stakeholder: Stakeholder): string {
  switch (stakeholder) {
    case Stakeholder.Healthcare:
      return 'keyStakeHolderHealthcare';
    case Stakeholder.Patient:
      return 'keyStakeHolderPatient';
    case Stakeholder.Payor:
      return 'keyStakeHolderPayor';
    case Stakeholder.Provider:
      return 'keyStakeHolderProvider';
    case Stakeholder.Policymaker:
      return 'keyStakeHolderPolicymaker';
  }
}

export const KeyInsights = (props: Props) => {
  const {
    drugId,
    strategyId,
    stakeholder: stakeholderParam,
  }: URLParams = useParams();
  const history = useHistory();
  const isDesktop = useDesktop();

  const [stakeholder, setStakeholder] = useStakeholderContext(stakeholderParam);

  const [enableAddObservations, setEnableAddObservations] = useState<boolean>(
    true
  );

  const [showAddObservations, setShowAddObservations] = useState<
    boolean | number
  >(false); // ID of the Insight

  const [observationsPaneFilterData, setObservationsPaneFilterData] = useState<
    (number | null)[]
  >([]); // observationIds of the selected insight

  const {
    data: stakeholderDefinitionData,
    error,
    loading: stakeholderDefinitionDataLoading,
    startPolling,
    stopPolling,
  } = useStakeholderDefinitionsWithPatientFlowBlockStarQuery({
    variables: {
      where: { strategyId: Number(strategyId), stakeholder },
    },
    fetchPolicy: 'network-only',
  });
  // stop polling
  useEffect(() => {
    startPolling(polling.default);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const observationsQueryVars: ObservationsQueryVariables = {
    where: { strategyId: Number(strategyId) },
    orderBy: { createdAt: Sort.Desc },
  };

  const {
    data: ObservationsData,
    loading,
    refetch: observationsRefetch,
    startPolling: observationsStartPolling,
    stopPolling: observationsStopPolling,
  } = useObservationsQuery({
    variables: {
      ...observationsQueryVars,
    },
  });

  useEffect(() => {
    observationsStartPolling(polling.default);
    return () => {
      observationsStopPolling();
    };
  }, [observationsStartPolling, observationsStopPolling]);

  const mappedStakeholderToDBName = mapToDBStakeholder(stakeholder);

  const {
    data: patientJourneyBlocksData,
    loading: patientJourneyBlocksDataLoading,
    refetch: patientJourneyBlocksRefetch,
  } = usePatientJourneyBlocksQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        strategyId: Number(strategyId),
        [mappedStakeholderToDBName]: true,
        type: BlockType.Step,
      },
    },
  });
  const patientJourneyBlocks = patientJourneyBlocksData?.patientJourneyBlocks;

  const getColumns: number[] = useMemo(() => {
    return (
      patientJourneyBlocks?.items?.map((block) => {
        if (block?.columnId) {
          return block?.columnId;
        }
        return 0;
      }) || []
    );
  }, [patientJourneyBlocks?.items]);

  const {
    data: patientJourneyColumnsData,
    loading: patientJourneyColumnsDataLoading,
    refetch: patientRefetch,
  } = usePatientJourneyColumnsQuery({
    variables: {
      where: { strategyId: Number(strategyId) },
    },
  });

  const patientJourneyColumns =
    patientJourneyColumnsData?.patientJourneyColumns.items;

  const uniqueColumns =
    getColumns && !!patientJourneyColumns?.length
      ? patientJourneyColumns
          .filter((column) => getColumns.includes(column.id))
          .sort((a, b) => a.idx - b.idx)
      : undefined;

  const [createKeyInsight] = useKeyInsightCreateMutation();
  const [updateKeyInsight] = useKeyInsightUpdateMutation();
  const [deleteKeyInsight] = useKeyInsightDeleteMutation();

  const keyInsightsQueryVars: KeyInsightsQueryVariables = {
    where: { strategyId: Number(strategyId), stakeholder },
    orderBy: { createdAt: Sort.Desc },
  };

  const {
    data: keyInsightsData,
    loading: keyInsightsLoading,
    refetch: keyInsightsRefetch,
    startPolling: keyInsightsStartPolling,
    stopPolling: keyInsightsStopPolling,
  } = useKeyInsightsQuery({
    variables: {
      ...keyInsightsQueryVars,
    },
  });

  useEffect(() => {
    keyInsightsStartPolling(polling.default);
    return () => {
      keyInsightsStopPolling();
    };
  }, [keyInsightsStartPolling, keyInsightsStopPolling]);

  useEffect(() => {
    if (stakeholder) {
      (async () => {
        await patientRefetch();
        await patientJourneyBlocksRefetch();
        await keyInsightsRefetch();
        observationsRefetch();
      })();
    }
  }, [
    stakeholder,
    patientRefetch,
    patientJourneyBlocksRefetch,
    keyInsightsRefetch,
    observationsRefetch,
  ]);

  useEffect(() => {
    if (!stakeholderParam || stakeholderParam === 'undefined') {
      history.replace(
        `/d/${drugId}/strategy/${strategyId}/1_5/${
          stakeholder || Stakeholder.Patient
        }`
      );
    }
  }, [drugId, history, stakeholder, stakeholderParam, strategyId]);

  const [removingFromInsight, setRemovingFromInsight] = useState(false);

  const insightIsOpen = typeof showAddObservations === 'number';

  const dataMissing =
    !stakeholderDefinitionData ||
    !ObservationsData ||
    !patientJourneyBlocksData ||
    !patientJourneyColumnsData ||
    !keyInsightsData;

  const dataLoading =
    stakeholderDefinitionDataLoading ||
    loading ||
    patientJourneyBlocksDataLoading ||
    patientJourneyColumnsDataLoading ||
    keyInsightsLoading;

  const relevantDefinitions =
    stakeholderDefinitionData?.stakeholderDefinitions?.items.filter((sd) => {
      if (!sd.title) return false;

      return sd.stakeholder === Stakeholder.Patient;
    }) || [];

  const observations = ObservationsData?.observations?.items;
  const selectedInsight = keyInsightsData?.keyInsights?.items?.find(
    (i) => i.id === showAddObservations
  );

  return (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <RotateScreenTip />
      <Navbar
        prev={{
          title: 'Key trends Analysis',
          url: buildStrategyURL(drugId, strategyId, '1_4'),
        }}
        next={{
          url: buildStrategyURL(drugId, strategyId, '2_1'),

          title: 'Strategic Question',
        }}
        stepNumber="1.5"
        title="Key Insights"
        url={`/d/${drugId}/strategy/${strategyId}?nav=1`}
        navMenuChildren={null}
      >
        <Observations
          readonly
          stakeholder={stakeholder}
          step={Step.Keyinsights}
        />

        <StakeHolderTabs
          stakeholder={stakeholder}
          setStakeholder={(stakeholder) => {
            history.push(
              `/d/${drugId}/strategy/${strategyId}/1_5/${stakeholder}`
            );
            setStakeholder(stakeholder);
          }}
        />
      </Navbar>

      <StepHeaderBarWrapper>
        <StepHeaderBar>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Subtitle2>
              Create{' '}
              {stakeholder === 'Healthcare'
                ? 'healthcare professional'
                : stakeholder.toLowerCase()}{' '}
              insights supported by observations.
            </Subtitle2>
            <BodyNormal color={colors.greyDark}>
              Make suggestions under the relevant patient journey stages.
            </BodyNormal>

            <div
              style={{
                position: 'absolute',
                top: 15,
                right: 95,
                display: isDesktop ? 'flex' : 'none',
              }}
            >
              <StakeholderDefinitionAvatars
                stakeholderDefinitions={relevantDefinitions}
              />
            </div>
          </div>
        </StepHeaderBar>
      </StepHeaderBarWrapper>

      <ErrorWrapper
        isLoading={dataLoading}
        errors={[error]}
        dataMissing={dataMissing}
      >
        <PageWrapper showAddObservations={insightIsOpen} paddingTop={false}>
          {!!uniqueColumns?.length ? (
            <ObservationsToggleWrapper>
              <Toggle
                disabled={insightIsOpen}
                value={enableAddObservations}
                onChange={() => {
                  if (!insightIsOpen) {
                    setEnableAddObservations(!enableAddObservations);
                  }
                }}
                label="Show observations"
                toggleClassName="observations--toggle"
              />
            </ObservationsToggleWrapper>
          ) : null}
          {keyInsightsLoading || loading ? (
            <p style={{ margin: '0 auto', textAlign: 'center' }}>Loading...</p>
          ) : !!uniqueColumns?.length ? (
            <InsightGroupWrapper>
              {uniqueColumns.map(({ id: columnId, name }) => (
                <InsightGroup
                  setRemovingFromInsight={setRemovingFromInsight}
                  enableAddObservations={enableAddObservations}
                  setShowAddObservations={setShowAddObservations}
                  showAddObservations={showAddObservations}
                  keyInsightsQueryVars={keyInsightsQueryVars}
                  keyInsightsRefetch={() => keyInsightsRefetch()}
                  key={columnId}
                  stakeholder={stakeholder}
                  queryVars={observationsQueryVars}
                  drugId={drugId}
                  strategyId={strategyId}
                  columnId={columnId}
                  createKeyInsight={createKeyInsight}
                  updateKeyInsight={updateKeyInsight}
                  deleteKeyInsight={deleteKeyInsight}
                  title={name}
                  setObservationsPaneFilterData={setObservationsPaneFilterData}
                  data={keyInsightsData}
                  observationsData={ObservationsData?.observations?.items}
                  stakeholderDefinitions={
                    stakeholderDefinitionData?.stakeholderDefinitions?.items.filter(
                      (b) => {
                        // leveragePoints are patientBlocks with a global star
                        return !!b.PatientFlowBlockStar.find(
                          (star) => star.global
                        );
                      }
                    ) || []
                  }
                />
              ))}
            </InsightGroupWrapper>
          ) : (
            <EmptyStateWrapper>
              <Icon
                name="GenericEmptyState"
                size={115}
                height={115}
                color="initial"
              />
              <Subtitle2 color={colors.greyDark}>
                Nothing to discuss for this stakeholder
              </Subtitle2>
              <BodySmall color={colors.greyDark}>
                Insights can only be added when this stakeholder is starred
                against an action in the{' '}
                <Link
                  style={{
                    color: colors.greyDark,
                    display: 'inline',
                    fontSize: 14,
                    fontWeight: 500,
                    pointerEvents: 'all',
                  }}
                  to={`/d/${drugId}/strategy/${strategyId}/1_3`}
                >
                  1.3 Patient Journey
                </Link>
                .
              </BodySmall>
            </EmptyStateWrapper>
          )}
          <ObservationsPane
            removingFromInsight={removingFromInsight}
            filter={observationsPaneFilterData}
            show={insightIsOpen}
            ObservationsData={ObservationsData}
          />
          <AssignObservationsModal
            visible={insightIsOpen && !isDesktop}
            handleClose={() => setShowAddObservations(false)}
            observations={observations || []}
            insight={selectedInsight}
            updateKeyInsight={updateKeyInsight}
          />
        </PageWrapper>
        <ObservationDragLayer data={ObservationsData?.observations?.items} />
      </ErrorWrapper>
    </DndProvider>
  );
};
