import { HurdlesAndGapsType } from '../types/HurdlesAndGapsType';

export const HurdlesAndGapsConstants: HurdlesAndGapsType = {
  POLICIES_AND_GUIDELINES: {
    id: 'cl7ixogg9000001mgfm1261tw',
    title: 'Policies and Guidelines',
  },
  DISEASE_AND_THERAPY: {
    id: 'cl7ixogg9000101mg24ynafo6',
    title: 'Disease and Therapy',
  },
  MEDICAL_AND_CLINICAL_PRACTICE: {
    id: 'cl7ixogg9000201mg8byjg07h',
    title: 'Medical and Clinical Practice',
  },
  PRODUCT: {
    id: 'cl7ixogg9000301mgdlw0d61l',
    title: 'Product',
  },
};
