import { colors } from 'constants/index';
import { PatientJourneyColumn } from 'data/graphql/generated';
import { Group, Rect, Path } from 'react-konva';
import { BlockTree, Cursor } from 'types';
import { RECT_WIDTH } from '../constants';

export function Arrows({
  block,
  blockHover,
  topStage,
  changeCursor,
  handleBlockMouseEnter,
  setMoveBlockUpHover,
  handleUpArrowClick,
  moveBlockUpHover,
  bottomStage,
  setMoveBlockDownHover,
  handleDownArrowClick,
  moveBlockDownHover,
}: {
  block: BlockTree;
  blockHover: boolean;
  topStage: PatientJourneyColumn;
  changeCursor: (cursor: Cursor) => void;
  handleBlockMouseEnter: (id: number) => void;
  setMoveBlockUpHover: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpArrowClick: () => void;
  moveBlockUpHover: boolean;
  bottomStage: PatientJourneyColumn;
  setMoveBlockDownHover: React.Dispatch<React.SetStateAction<boolean>>;
  handleDownArrowClick: () => void;
  moveBlockDownHover: boolean;
}) {
  return (
    <>
      {/* Up arrow */}
      <Group
        id={`block-${block.id}-rect-up-arrow`}
        visible={
          blockHover &&
          block.parent?.columnId !== block.columnId &&
          block.columnId !== topStage.id
        }
        x={RECT_WIDTH - 25}
        y={-25}
        onMouseEnter={() => {
          changeCursor('pointer');
          handleBlockMouseEnter(block.id);
          setMoveBlockUpHover(true);
        }}
        onMouseLeave={() => {
          changeCursor('default');
          setMoveBlockUpHover(false);
        }}
        onTap={handleUpArrowClick}
        onClick={handleUpArrowClick}
      >
        <Rect
          height={20}
          width={20}
          sides={4}
          cornerRadius={[10, 10, 2, 2]}
          stroke={moveBlockUpHover ? colors.purple50 : colors.black20}
          strokeWidth={1}
          fill={colors.white}
        />
        <Path
          x={6}
          y={6}
          data="M3.96661 0.99981L4.49694 0.46948L3.43628 0.46948L3.96661 0.99981ZM1.19711 4.82997L4.49694 1.53014L3.43628 0.46948L0.136446 3.76931L1.19711 4.82997ZM3.43628 1.53014L6.73611 4.82997L7.79677 3.76931L4.49694 0.46948L3.43628 1.53014ZM3.21661 0.99981L3.21661 8.93281L4.71661 8.93281L4.71661 0.99981L3.21661 0.99981Z"
          fill={moveBlockUpHover ? colors.purple : colors.black70}
        />
      </Group>

      {/* Down arrow */}
      <Group
        id={`block-${block.id}-rect-down-arrow`}
        visible={
          blockHover &&
          block.columnId !== bottomStage.id &&
          // Children not in same stage
          block.children.every((child) => child.columnId !== block.columnId)
        }
        x={RECT_WIDTH - 25}
        y={block.height + 25}
        onMouseEnter={() => {
          changeCursor('pointer');
          handleBlockMouseEnter(block.id);
          setMoveBlockDownHover(true);
        }}
        onMouseLeave={() => {
          changeCursor('default');
          setMoveBlockDownHover(false);
        }}
        onTap={handleDownArrowClick}
        onClick={handleDownArrowClick}
      >
        <Rect
          rotation={180}
          offsetX={20}
          height={20}
          width={20}
          sides={4}
          cornerRadius={[10, 10, 2, 2]}
          stroke={moveBlockDownHover ? colors.purple50 : colors.black20}
          strokeWidth={1}
          fill={colors.white}
        />
        <Path
          x={6}
          y={-6}
          offsetX={8}
          rotation={180}
          data="M3.96661 0.99981L4.49694 0.46948L3.43628 0.46948L3.96661 0.99981ZM1.19711 4.82997L4.49694 1.53014L3.43628 0.46948L0.136446 3.76931L1.19711 4.82997ZM3.43628 1.53014L6.73611 4.82997L7.79677 3.76931L4.49694 0.46948L3.43628 1.53014ZM3.21661 0.99981L3.21661 8.93281L4.71661 8.93281L4.71661 0.99981L3.21661 0.99981Z"
          fill={moveBlockDownHover ? colors.purple : colors.black70}
        />
      </Group>
    </>
  );
}
