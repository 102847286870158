import React, { CSSProperties, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useDragLayer, XYCoord } from 'react-dnd';
import styled, { createGlobalStyle } from 'styled-components/macro';
import { Icon } from 'components/shared';
import { colors } from 'constants/colors';
import { dragNDropPinOffset as pinOffset } from 'constants/index';
import { zIndex } from 'constants/zIndex';

const Wrapper = styled.div`
  position: absolute;
  width: fit-content;
  height: fit-content;
  inset: 0;
  pointer-events: none; // this is required to overcome a bug when dropping a DragLayer preview: https://github.com/react-dnd/react-dnd/issues/1041
  z-index: ${zIndex.touchPositioningOverlay};
`;
interface Props {
  style?: CSSProperties;
  isBeingDragged: boolean;
  setDistanceMoved?: React.Dispatch<React.SetStateAction<XYCoord | null>>;
  isCountryPin?: boolean;
}

const SetDraggingCursor = createGlobalStyle`
  * {
    cursor: grabbing !important;
  }
`;

export const PinDragPreview: React.FC<Props> = ({
  style,
  isBeingDragged,
  setDistanceMoved,
  isCountryPin,
}) => {
  const { differenceFromInitialOffset, originalMousePosition } = useDragLayer(
    (monitor) => ({
      originalMousePosition: monitor.getInitialClientOffset(),
      differenceFromInitialOffset: monitor.getDifferenceFromInitialOffset(),
    })
  );

  useEffect(() => {
    !!differenceFromInitialOffset &&
      setDistanceMoved &&
      setDistanceMoved(differenceFromInitialOffset);

    return () => {};
  }, [differenceFromInitialOffset, setDistanceMoved]);

  function calculateCoordinate(coordinate: 'x' | 'y') {
    if (
      originalMousePosition?.[coordinate] &&
      differenceFromInitialOffset?.[coordinate]
    ) {
      return (
        originalMousePosition?.[coordinate] +
        differenceFromInitialOffset?.[coordinate]
      );
    }
  }

  const [xCoordinate, yCoordinate] = isBeingDragged
    ? [
        calculateCoordinate('x') || originalMousePosition?.x,
        calculateCoordinate('y') || originalMousePosition?.y,
      ]
    : [0, 0];

  // pinOffset shifts the pin left and up, beneath the cursor
  const translate =
    xCoordinate && yCoordinate
      ? `translate(${xCoordinate - pinOffset}px, ${
          yCoordinate - pinOffset + window.scrollY
        }px)`
      : '';

  return createPortal(
    <Wrapper
      style={{
        transform: translate,
        ...style,
      }}
    >
      {isCountryPin ? (
        <Icon
          name="BluePin"
          size={30}
          color={colors.blue10a}
          style={{
            width: 'fit-content',
            transition: 'transform 0.2s',
            overflow: 'hidden',
            maxHeight: 'none',
          }}
        />
      ) : (
        <Icon
          name="Pin"
          size={30}
          style={{
            width: 'fit-content',
            transition: 'transform 0.2s',
            overflow: 'hidden',
            maxHeight: 'none',
            color: colors.purple10,
          }}
        />
      )}
      <SetDraggingCursor />
    </Wrapper>,
    document.body
  );
};
