import { Icon } from 'components/shared';
import { colors } from 'constants/colors';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';

const Wrapper = styled.button`
  border: 0;
  padding: 0;
  cursor: pointer;

  background-color: transparent;
  border-radius: 5px;
  transition: background 0.2s;

  width: 30px;
  height: 30px;
  :hover {
    background-color: ${colors.black10};
  }
`;

interface Props {
  onClick: () => void;
  style?: CSSProperties;
  className?: string;
}

export const InfoIcon = ({ onClick, className, style }: Props) => {
  return (
    <Wrapper onClick={onClick} className={className} style={{ ...style }}>
      <Icon name={'Info'} color={colors.black} size={30} />
    </Wrapper>
  );
};
