import { Icon } from 'components/shared';
import { colors } from 'constants/index';
import React from 'react';

interface Props {}

export const TreatmentIcon = (props: Props) => {
  return (
    <div
      style={{
        background: colors.black10a,
        borderRadius: '100%',
        width: 40,
        height: 40,
        flex: 'none',
        marginRight: 5,
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <Icon name="Treatment" size={40} color={colors.white} />
    </div>
  );
};
