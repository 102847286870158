import { TypeNames } from './LongTermStrategySummaryTimeline';

export function getTag(val: TypeNames) {
  switch (val) {
    case 'BigIdeaDetail':
      return 'BI';
    case 'LongTermSolutionDetail':
      return 'LT';
    case 'Solution':
      return 'RM';
    default:
      return '';
  }
}

export function getTitle(val: TypeNames) {
  switch (val) {
    case 'BigIdeaDetail':
      return 'Big Idea';
    case 'LongTermSolutionDetail':
      return 'Long-term Solution';
    case 'Solution':
      return 'Risk Mitigation Solution';
    default:
      return '';
  }
}
