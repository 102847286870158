import { colors } from 'constants/index';
import { PatientJourneyColumn } from 'data/graphql/generated';
import { Group, Rect, Text } from 'react-konva';
import { BlockTree, Cursor } from 'types';
import { RECT_WIDTH } from '../constants';

export function DeleteBlock({
  block,
  column,
  x,
  y,
  setDeleteCardVisible,
  isLead,
  country,
  changeCursor,
  handleDelete,
}: {
  block: BlockTree;
  column: PatientJourneyColumn;
  x: number;
  y: number;
  setDeleteCardVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isLead: boolean;
  country: string;
  changeCursor: (cursor: Cursor) => void;
  handleDelete: () => void;
}): React.ReactElement<any, any> | null {
  return (
    <Group
      key={`block-${block.id}-delete`}
      id={`block-${block.id}-delete`}
      name={`flowblock-col-${column.idx}`}
      x={x}
      y={y}
    >
      <Group id={`block-${block.id}-card`}>
        <Rect
          width={RECT_WIDTH}
          height={block.height}
          sides={4}
          cornerRadius={5}
          stroke="rgba(20, 20, 39, 0.5)"
        />
        <Text
          text="Delete this block?"
          fontFamily="ABCFavorit"
          fontSize={14}
          fontWeight="normal"
          lineHeight={0.9375}
          wrap="word"
          align="center"
          width={RECT_WIDTH - 10}
          y={12}
          x={5}
        />
        <Text
          text="Any discussion and files will be lost."
          fill="#6F6D7D"
          fontFamily="ABCFavorit"
          fontSize={12}
          fontWeight={500}
          lineHeight={0.9375}
          align="center"
          width={RECT_WIDTH - 10}
          y={31}
          x={5}
        />
        <Text
          text="Cancel"
          fill={colors.black}
          fontFamily="ABCFavorit"
          fontSize={14}
          fontWeight={500}
          align="center"
          width={RECT_WIDTH / 2}
          lineHeight={1.25}
          onClick={() => setDeleteCardVisible(false)}
          onTap={() => setDeleteCardVisible(false)}
          y={58}
          onMouseEnter={() =>
            isLead && country === 'global' && changeCursor('pointer')
          }
          onMouseLeave={() =>
            isLead && country === 'global' && changeCursor('default')
          }
        />

        <Text
          text="Delete"
          fill={colors.red}
          fontFamily="ABCFavorit"
          fontSize={14}
          fontWeight={500}
          align="center"
          lineHeight={1.25}
          width={RECT_WIDTH / 2}
          onTap={handleDelete}
          onClick={handleDelete}
          y={58}
          x={RECT_WIDTH / 2}
          onMouseEnter={() =>
            isLead && country === 'global' && changeCursor('pointer')
          }
          onMouseLeave={() =>
            isLead && country === 'global' && changeCursor('default')
          }
        />
      </Group>
    </Group>
  );
}
