import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ButtonPill } from 'components/shared';
import { LongTermSolutionFragment } from 'data/graphql/generated';
import { Transition, TransitionGroup } from 'react-transition-group';
import { TransitionWrapper } from 'components/InsightGroup';
import { uid } from 'uid';
import {
  ReusableListEmptyState,
  TransitionList,
} from 'components/GridPages/GridPageSidebar';
import { DraggableCard } from 'components/GridPages/DraggableCard';
import {
  LongTermSolutionsDocument,
  useLongTermSolutionCreateMutation,
  useLongTermSolutionDeleteMutation,
  useLongTermSolutionUpdateMutation,
} from 'data/graphql/generated';
import { useParams } from 'react-router';
import { apolloDeleteHelper } from 'utils/apolloQueryHelpers';
import { SidebarCard } from 'components/shared/SidebarCard';
import { PreviewOrExistingSolution } from 'types';
import { longTermSolutionsQueryVars } from 'containers/LongTermStrategySolutions';

const Wrapper = styled.div``;

const AddSolutionButton = styled(ButtonPill)`
  margin-top: 10px;
`;

type SolutionsWithPreview = PreviewOrExistingSolution[];

interface URLParams {
  strategyId: string;
}

interface Props {
  data: LongTermSolutionFragment[];
  isDragging: boolean;
  setTouchPositioningView: React.Dispatch<React.SetStateAction<string | null>>;
  futureTrendId: number;
}

export const SolutionGroup: React.FC<Props> = ({
  data,
  isDragging,
  setTouchPositioningView,
  futureTrendId,
}) => {
  const [solutions, setSolutions] = useState<SolutionsWithPreview>(data);
  const allSolutionsOnGrid = !solutions.length;
  const [previewUID, setPreviewUID] = useState<string | null>(null);
  const { strategyId } = useParams<URLParams>();
  const [createLongTermSolution] = useLongTermSolutionCreateMutation();
  const [updateLongTermSolution] = useLongTermSolutionUpdateMutation();
  const [deleteLongTermSolution] = useLongTermSolutionDeleteMutation();

  function addSolution(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const newUID = uid();
    setPreviewUID(newUID);

    setSolutions((state) => {
      const newArr = state.slice();
      newArr.unshift({ localUid: newUID });
      return newArr;
    });
  }

  function deletePreviewSolution() {
    setPreviewUID(null);

    setSolutions((solutions) =>
      solutions.filter((el) => el.localUid !== previewUID)
    );
  }

  // Ensure previews are unaffected when data comes back from server
  useEffect(() => {
    if (!isDragging) {
      if (previewUID) {
        setSolutions([{ localUid: previewUID }, ...data]);
      } else {
        setSolutions(data);
      }
    }
    // We don't want this to run each time previewUID changes, so it is excluded from the dependencies array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isDragging]);

  return (
    <Wrapper>
      <AddSolutionButton
        text="Add solution"
        iconName="Plus"
        level="secondary"
        onClick={(e) => addSolution(e)}
        className="cypress-add-solution-btn"
      />

      {!!solutions.length ? (
        allSolutionsOnGrid ? (
          <ReusableListEmptyState
            title="All solutions positioned on the grid"
            subtitle="You can still add more"
          />
        ) : (
          <TransitionList>
            <TransitionGroup component={null}>
              {solutions?.map((s) => {
                const isPreview = !('id' in s);

                return (
                  <Transition
                    enter={true}
                    exit={true}
                    key={s.localUid}
                    timeout={300}
                  >
                    {(state) => {
                      return (
                        <TransitionWrapper state={state} isPreview={isPreview}>
                          <DraggableCard data={s} disableDrag={!('id' in s)}>
                            <SidebarCard
                              previewUID={previewUID}
                              setPreviewUID={setPreviewUID}
                              deletePreview={deletePreviewSolution}
                              itemData={s}
                              itemType="solution"
                              isPreview={isPreview}
                              setTouchPositioningView={setTouchPositioningView}
                              handleCreate={async (text: string) =>
                                await createLongTermSolution({
                                  variables: {
                                    data: {
                                      text,
                                      strategy: +strategyId,
                                      localUid: previewUID || uid(),
                                      futureTrend: futureTrendId,
                                    },
                                  },
                                })
                              }
                              handleDelete={async () => {
                                if (!('id' in s)) return;

                                await deleteLongTermSolution({
                                  variables: { id: s.id },
                                  update: apolloDeleteHelper({
                                    responseField: 'longTermSolutionDelete',
                                    query: LongTermSolutionsDocument,
                                    queryVars: longTermSolutionsQueryVars(
                                      strategyId
                                    ),
                                    queryName: 'longTermSolutions',
                                  }),
                                  optimisticResponse: {
                                    longTermSolutionDelete: {
                                      ...s,
                                      id: s.id,
                                      __typename: 'LongTermSolution',
                                    },
                                  },
                                });
                              }}
                              handleUpdate={async (text: string) =>
                                updateLongTermSolution({
                                  variables: {
                                    localUid: s.localUid,
                                    data: { text },
                                  },
                                })
                              }
                            />
                          </DraggableCard>
                        </TransitionWrapper>
                      );
                    }}
                  </Transition>
                );
              })}
            </TransitionGroup>
          </TransitionList>
        )
      ) : (
        <ReusableListEmptyState
          title="No solutions yet"
          subtitle="Add solutions then position them on the grid"
        />
      )}
    </Wrapper>
  );
};
