import { useEffect, useRef, useState } from 'react';

export default function useHandleCalloutHover({
  hoveredPin,
}: {
  hoveredPin: number;
}) {
  const [showingCallout, setShowingCallout] = useState<number>(-1);
  const hideCalloutTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const showCalloutTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Handle callout card hover states
  useEffect(() => {
    const hideCallout = () => setShowingCallout(-1);

    if (hoveredPin === -1) {
      // not hovering any pin, hide the callout
      hideCalloutTimeoutRef.current = setTimeout(hideCallout, 500);

      showCalloutTimeoutRef.current &&
        clearTimeout(showCalloutTimeoutRef.current);
    } else {
      // hovering over hoveredPin
      if (showingCallout === -1) {
        // no callout showing, display after 0.5s
        showCalloutTimeoutRef.current = setTimeout(
          () => setShowingCallout(hoveredPin),
          0
        );
      } else {
        // callout currently showing for a pin, switch to that pin's callout immediately
        setShowingCallout(hoveredPin);
      }

      hideCalloutTimeoutRef.current &&
        clearTimeout(hideCalloutTimeoutRef.current);
    }

    return () => {
      hideCalloutTimeoutRef.current &&
        clearTimeout(hideCalloutTimeoutRef.current);
      showCalloutTimeoutRef.current &&
        clearTimeout(showCalloutTimeoutRef.current);
    };
  }, [hoveredPin, hideCalloutTimeoutRef, showingCallout]);

  return { showingCallout };
}
