import { colors } from 'constants/colors';
import { BlockType } from 'data/graphql/generated';

export function getBlockBorder(type: BlockType, starred?: boolean): string {
  if (starred) {
    switch (type) {
      case BlockType.Step:
        return colors.purplePinkGradient;
    }
  }

  switch (type) {
    case BlockType.Patient:
      return colors.purple;
    case BlockType.Healthcareprofessional:
      return colors.green;
    case BlockType.Payor:
      return colors.blue;
    case BlockType.Treatment:
      return colors.orange;
    case BlockType.Step:
      return colors.black50;
    case BlockType.Provider:
      return colors.pink;
    case BlockType.Policymaker:
      return colors.red;
  }
}

export function getBlockBG(type: BlockType): string {
  switch (type) {
    case BlockType.Patient:
      return colors.purple05;
    case BlockType.Healthcareprofessional:
      return colors.green05;
    case BlockType.Payor:
      return colors.blue05;
    case BlockType.Treatment:
      return colors.orange05;
    case BlockType.Step:
      return colors.white;
    case BlockType.Provider:
      return colors.pink05;
    case BlockType.Policymaker:
      return colors.red05;
  }
}

export function getBlockShadow(type: BlockType): string {
  switch (type) {
    case BlockType.Patient:
      return colors.purple10;
    case BlockType.Healthcareprofessional:
      return colors.green10;
    case BlockType.Payor:
      return colors.blue10;
    case BlockType.Treatment:
      return colors.orange10;
    case BlockType.Step:
      return colors.black10;
    case BlockType.Provider:
      return colors.pink10;
    case BlockType.Policymaker:
      return colors.red;
  }
}

export function getBlockCollaborationBG(type: BlockType): string {
  switch (type) {
    case BlockType.Patient:
      return colors.purple20;
    case BlockType.Healthcareprofessional:
      return colors.green20;
    case BlockType.Payor:
      return colors.blue20;
    case BlockType.Treatment:
      return colors.orange20;
    case BlockType.Step:
      return colors.black10;
    case BlockType.Provider:
      return colors.pink20;
    case BlockType.Policymaker:
      return colors.red20;
  }
}
