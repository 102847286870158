import * as React from 'react';
import { ModalContext } from './ModalContext';

export const useModalWithSteps = () => {
  const context = React.useContext(ModalContext);

  if (context === undefined) {
    throw new Error('useModalWithSteps must be used within a ModalProvider');
  }

  return context;
};
