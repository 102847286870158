import { PatientJourneyBlockFragment } from 'data/graphql/generated';
import { PatientJourneyContainerType } from '../../types';
import BulletList from './BulletList';

type Props = {
  cachedBlock: PatientJourneyBlockFragment | undefined;
  onUpdate: PatientJourneyContainerType['updateBlock'];
  canEdit: boolean;
};

export function DesiredPageContent({ cachedBlock, onUpdate, canEdit }: Props) {
  return (
    <>
      <BulletList
        title={'Key decisions'}
        placeholder={'Type a key decision'}
        list={cachedBlock?.keyDecisions}
        onUpdate={(val) => {
          if (cachedBlock) {
            onUpdate(cachedBlock, { keyDecisions: val });
          }
        }}
        readOnly={!canEdit}
      />

      <BulletList
        title={'Desired patient experience'}
        placeholder={'Type an experience'}
        list={cachedBlock?.keyNeeds}
        onUpdate={(val) => {
          if (cachedBlock) {
            onUpdate(cachedBlock, { keyNeeds: val });
          }
        }}
        readOnly={!canEdit}
      />
    </>
  );
}
