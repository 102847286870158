import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { Tooltip } from '.';
import { Avatar } from './Avatar';

const AvatarWrapper = styled.div``;

interface Props {
  stakeholderDefinitions: {
    title: string;
    id: number;
    image?: string | null;
  }[];
}

export const StakeholderDefinitionAvatars = ({
  stakeholderDefinitions,
}: Props) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (!stakeholderDefinitions) {
    return null;
  }

  if (stakeholderDefinitions.length > 3) {
    const otherTitles = stakeholderDefinitions
      .slice(2, stakeholderDefinitions.length)
      .map((stakeholderDefinition) => {
        return stakeholderDefinition?.title.replace(/\n/, '<br />');
      })
      .filter((val) => !!val)
      .join('<br />');

    return (
      <>
        {stakeholderDefinitions
          ?.slice(0, 2)
          .map((stakeholderDefinition, idx) => (
            <AvatarWrapper
              data-for={'StakeholderDefinitionAvatars'}
              data-tip={stakeholderDefinition?.title || ''}
              data-place={'bottom'}
              data-offset="{'left': -14, 'top': 5 }"
              key={stakeholderDefinition?.id}
              style={{
                marginLeft: `-${!idx ? 0 : 25}px`,

                zIndex: stakeholderDefinitions.length - idx,
              }}
            >
              <Avatar
                size={80}
                src={stakeholderDefinition?.image || ''}
                disableTooltip
              />
            </AvatarWrapper>
          ))}

        {/* Others */}
        <AvatarWrapper
          data-for={'StakeholderDefinitionAvatars'}
          data-tip={otherTitles}
          data-place={'bottom'}
          data-offset="{'left': -14, 'top': 5 }"
          key={'others'}
          style={{
            marginLeft: -25 + 'px',
            zIndex: 0,
          }}
        >
          <Avatar size={80} text={`+${stakeholderDefinitions.length - 2}`} />
        </AvatarWrapper>

        <Tooltip id={'StakeholderDefinitionAvatars'} effect="float" multiline />
      </>
    );
  }

  // else
  return (
    <>
      {stakeholderDefinitions?.slice(0, 3).map((stakeholderDefinition, idx) => (
        <AvatarWrapper
          key={stakeholderDefinition?.id}
          style={{
            marginLeft: `-${!idx ? 0 : 25}px`,
            zIndex: stakeholderDefinitions.length - idx,
          }}
          data-offset="{'left': -14, 'top': 5 }"
          data-for={'StakeholderDefinitionAvatars'}
          data-tip={stakeholderDefinition?.title || ''}
          data-place={'bottom'}
        >
          <Avatar size={80} src={stakeholderDefinition?.image || ''} />
        </AvatarWrapper>
      ))}

      <Tooltip id={'StakeholderDefinitionAvatars'} effect="float" multiline />
    </>
  );
};
