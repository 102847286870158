import { throttle } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import Textarea from 'react-expanding-textarea';
import styled from 'styled-components/macro';

import {
  BodyLarge,
  BodyNormal,
  ButtonLabel,
  Collaboration,
  Icon,
} from 'components/shared';
import { TooltipCSS } from 'components/shared/Tooltip';
import { colors } from 'constants/colors';
import { useAuthContext } from 'contexts/AuthContext';
import {
  BigIdeaFragment,
  FileModel,
  useBigIdeaDeleteMutation,
  useBigIdeaUpdateMutation,
  useRequestFileUploadMutation,
} from 'data/graphql/generated';
import { device } from 'utils/breakpoints';
import { uploadFile } from 'utils/uploadFile';
import { verifyUserRole } from 'utils/verifyUserRole';
import useMobile from '../../hooks/useMobile';
import { BigIdeaEditor } from './BigIdeaEditor';
import { BigIdeaFocusDropdowns } from './BigIdeaFocusDropdowns';
import { BigIdeaImageUploader } from './BigIdeaImageUploader';

const Header = styled.div`
  display: flex;

  .FocusButtons {
    margin-left: 15px;
  }
`;

const Content = styled.div`
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const BigIdeaTitleInput = styled(Textarea)`
  border: none;
  border-bottom: 1px solid ${colors.greyDark};
  width: 100%;
  font-size: 26px;
  line-height: 34px;
  margin-top: 25px;
  resize: none;

  &:focus {
    outline: none;
    border-bottom: 1px solid ${colors.blue};
  }
`;

const BigIdeaTitle = styled(BodyLarge)`
  margin-top: 25px;
  width: 100%;
`;

const DeleteBtn = styled.div`
  margin-right: 7px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:hover {
    background: ${colors.cream};
  }
`;

const DeleteBtnBorder = styled.div`
  border-right: 1px solid ${colors.greyMedium};
  margin-right: 12px;
  cursor: pointer;

  &:hover {
    path {
      color: ${colors.red};
    }
  }
`;

const HeaderRight = styled.div`
  display: block;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-left: auto;

  @media (max-width: 570px) {
    right: 0px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 64px;
  width: 64px;
`;

const ConfirmationNotification = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  background: rgba(255, 255, 255, 0.97);
  text-align: center;
  padding: 40px 35px;
  border-radius: 5px;
  z-index: 2;
  @media ${device.mobile} {
    padding: 35px 15px;
  }
`;

const ConfirmationNotificationTitle = styled(BodyNormal)`
  margin-bottom: 5px;
  color: ${colors.black};
`;

const ConfirmationNotificationSubTitle = styled(BodyNormal)`
  margin-bottom: 20px;
  color: ${colors.black60};
`;

const ConfirmationNotificationCTA = styled.div`
  display: flex;
  justify-content: space-around;
  width: 120px;
  margin: 0 auto;
`;

const CTACancel = styled(ButtonLabel)`
  display: inline-block;
`;

const CTAConfirm = styled(ButtonLabel)`
  color: ${colors.red};
  display: inline-block;
`;

const CloseBtn = styled.div`
  &:hover {
    background: ${colors.greyLight};
    border-radius: 50%;
  }
`;

export type ImageStateType =
  | undefined
  | (Partial<File> & {
      preview: string;
    })
  | '';

interface Props {
  bigIdea?: BigIdeaFragment;
  handleClose(): void;
  strategyId: string;
  bigIdeasRefetch(): void;
  countryTacticsEnabled: boolean;
}

export const BigIdeaSidebarContent: React.FC<Props> = ({
  bigIdea,
  handleClose,
  strategyId,
  bigIdeasRefetch,
  countryTacticsEnabled,
}) => {
  const [errMsg, setErrMsg] = useState('');
  const [imageState, setImageState] = useState<ImageStateType>(
    bigIdea && bigIdea.image ? { preview: bigIdea.image } : undefined
  );
  const [title, setTitle] = useState(bigIdea?.title || '');
  const [showDelete, setShowDelete] = useState(false);

  const [bigIdeaUpdate] = useBigIdeaUpdateMutation();
  const [bigIdeaDelete] = useBigIdeaDeleteMutation();
  const [requestFileUpload] = useRequestFileUploadMutation();

  const isMobile = useMobile();
  const [{ user }] = useAuthContext();
  const isOwner = user && user.id === bigIdea?.user.id;
  const { isLead } = verifyUserRole(user?.role, user?.country);
  const canEdit = isOwner || isLead;

  useEffect(() => {
    setTitle(bigIdea?.title || '');
    setImageState(
      bigIdea && bigIdea.image ? { preview: bigIdea.image } : undefined
    );
  }, [bigIdea]);

  useEffect(() => {
    return () => {
      // Avoids memory leaks
      if (!!imageState) URL.revokeObjectURL(imageState.preview);
    };
  }, [imageState]);

  useEffect(() => {
    return () => {
      setImageState('');
      setErrMsg('');
    };
  }, []);

  async function handleUpload(imageState: Partial<File>) {
    if (!bigIdea) return;

    const { data } = await requestFileUpload({
      variables: {
        data: {
          strategyId: +strategyId,
          model: FileModel.BigIdea,
          modelId: bigIdea.id,
          name: imageState.name,
        },
      },
    });

    if (!data?.requestFileUpload) {
      throw Error('Upload failed');
    }

    await uploadFile([imageState] as File[], data.requestFileUpload);

    await bigIdeaUpdate({
      variables: {
        id: bigIdea.id,
        data: {
          image: `${data.requestFileUpload.url}/${data.requestFileUpload.fields.key}`,
        },
      },
    });
    bigIdeasRefetch();
  }

  async function handleTitleSave() {
    if (!bigIdea) return;
    await bigIdeaUpdate({
      variables: {
        id: bigIdea.id,
        data: {
          title,
        },
      },
    });
    bigIdeasRefetch();
  }

  // eslint-disable-next-line
  const throttleUpdates = useCallback(
    throttle(async (content: string, bigIdeaId: number) => {
      await bigIdeaUpdate({
        variables: {
          id: bigIdeaId,
          data: {
            content,
          },
        },
      });
    }, 1000),
    [bigIdea?.id]
  );

  return (
    <>
      <Content>
        {bigIdea ? (
          <>
            <ConfirmationNotification visible={showDelete}>
              <ConfirmationNotificationTitle>
                Delete this big idea?
              </ConfirmationNotificationTitle>
              <ConfirmationNotificationSubTitle>
                All content, discussion and files will be lost.
              </ConfirmationNotificationSubTitle>
              <ConfirmationNotificationCTA>
                <CTACancel
                  onClick={() => {
                    setShowDelete(false);
                  }}
                >
                  Cancel
                </CTACancel>
                <CTAConfirm
                  onClick={async () => {
                    if (!bigIdea) return;

                    try {
                      await bigIdeaDelete({
                        variables: { id: bigIdea.id },
                      });
                    } catch (e: any) {
                      handleClose();
                    }
                    
                    handleClose();
                    setShowDelete(false);
                    bigIdeasRefetch();
                  }}
                >
                  Delete
                </CTAConfirm>
              </ConfirmationNotificationCTA>
            </ConfirmationNotification>

            <Header>
              <ImageWrapper>
                <BigIdeaImageUploader
                  errMsg={errMsg}
                  setErrMsg={setErrMsg}
                  imageState={imageState}
                  setImageState={(value) => {
                    if (!canEdit) return;
                    setImageState(value);
                    if (value) handleUpload(value);
                  }}
                  bigIdea={bigIdea}
                  canEdit={canEdit}
                />
              </ImageWrapper>
              <BigIdeaFocusDropdowns
                bigIdea={bigIdea}
                user={user}
                countryTacticsEnabled={countryTacticsEnabled}
                bigIdeasRefetch={bigIdeasRefetch}
              />
              <HeaderRight>
                {canEdit ? (
                  <DeleteBtnBorder>
                    <TooltipCSS
                      text="Delete big idea"
                      direction="top"
                      tooltipStyle={{
                        top: 35,
                        left: -26,
                      }}
                    >
                      <DeleteBtn>
                        <Icon
                          name="TrashOutline"
                          color={colors.black70}
                          size={30}
                          onClick={() => {
                            if (!canEdit) return;
                            setShowDelete(true);
                          }}
                        />
                      </DeleteBtn>
                    </TooltipCSS>
                  </DeleteBtnBorder>
                ) : null}
                {!isMobile && (
                  <Collaboration
                    className="Collaboration"
                    collaboration={bigIdea.collaboration}
                  />
                )}
                <TooltipCSS
                  text="Close details"
                  direction="top"
                  tooltipStyle={{
                    top: 35,
                    left: -40,
                  }}
                >
                  <CloseBtn onClick={handleClose} style={{ cursor: 'pointer' }}>
                    <Icon
                      name="Collapse right"
                      color={colors.black70}
                      size={30}
                    />
                  </CloseBtn>
                </TooltipCSS>
              </HeaderRight>
            </Header>
            {isMobile && (
              <Collaboration
                className="Collaboration"
                style={{
                  marginRight: 'auto',
                  marginTop: 15,
                }}
                collaboration={bigIdea.collaboration}
              />
            )}

            {canEdit ? (
              <BigIdeaTitleInput
                placeholder="Write the Big Idea name"
                className="cypress-bigidea-title-input"
                value={title}
                onChange={(e) => {
                  if (!canEdit) return;
                  // @ts-ignore
                  setTitle(e.target.value);
                }}
                onBlur={() => handleTitleSave()}
              />
            ) : (
              <BigIdeaTitle>{title}</BigIdeaTitle>
            )}

            <BigIdeaEditor
              editable={!!canEdit}
              value={bigIdea?.content || ''}
              strategyId={+strategyId}
              bigIdeaId={bigIdea.id}
              onUpdate={(val: string, bigIdeaId: number) => {
                throttleUpdates(val, bigIdeaId);
              }}
            />
          </>
        ) : null}
      </Content>
    </>
  );
};
