import { BodySmall } from 'components/shared';
import { BulletPointList } from 'components/shared/BulletPointList';
import { UnderlineTextFieldContainer } from 'components/shared/UnderlineTextField';
import { colors } from 'constants/index';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { SectionContainer } from './SectionContainer';

const StyledBulletPointList = styled(BulletPointList)`
  textarea {
    background: transparent;
    padding-left: 26px;
  }

  ${UnderlineTextFieldContainer} {
    position: relative;
  }
  .bullet-point {
    position: absolute;
    transform: translateX(9px);
  }
`;

const ListWrapper = styled.div<{ $readOnly: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 5px;

  pointer-events: ${({ $readOnly }) => $readOnly && 'none'};
`;

type Props = {
  title: string;
  placeholder: string;
  list: string[] | undefined | null;
  onUpdate: (val: string[]) => void;
  readOnly?: boolean;
};

const BulletList = ({
  title,
  placeholder,
  list,
  onUpdate,
  readOnly = false,
}: Props) => {
  const [listState, setListState] = useState(list || []);

  useEffect(() => {
    setListState(list || []);
  }, [list]);

  const isEmpty = !list?.length;

  if (isEmpty && readOnly) {
    return (
      <SectionContainer>
        <BodySmall color={colors.greyDark} style={{ marginBottom: 5 }}>
          {title}
        </BodySmall>

        <BodySmall>None identified yet</BodySmall>
      </SectionContainer>
    );
  }

  return (
    <SectionContainer>
      <BodySmall color={colors.greyDark}>{title}</BodySmall>
      <ListWrapper $readOnly={readOnly}>
        <StyledBulletPointList
          preventAutoFocus
          statement={listState}
          onUpdate={setListState}
          placeholder={placeholder}
          handleBlur={() => {
            onUpdate(listState);
          }}
        />
      </ListWrapper>
    </SectionContainer>
  );
};

export default BulletList;
