import React, { useEffect, useRef, useState } from 'react';
import { useDragLayer } from 'react-dnd';
import { SummaryColumn } from './SummaryColumn';
import { createGlobalStyle } from 'styled-components';

interface Props {
  onDrop(): void;
  setDragging: React.Dispatch<React.SetStateAction<boolean>>;
}

const SetDraggingCursor = createGlobalStyle`
  * {
    cursor: grabbing !important;
  }
`;

export const DraggableSummaryColumnDragLayer = ({
  onDrop,
  setDragging,
}: Props) => {
  const [rotate, setRotate] = useState(`rotate(${0 + 'deg'})`);
  const { currentOffset, isDragging, item } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));
  const previousDragState = useRef(isDragging);

  const translate = currentOffset?.x
    ? ` translate(${currentOffset?.x}px, ${currentOffset?.y}px)`
    : '';

  useEffect(() => {
    setDragging(isDragging);
    setRotate(`rotate(${(isDragging ? 5 : 0) + 'deg'})`);
    if (!isDragging && !!previousDragState.current) {
      onDrop();
    }
    previousDragState.current = isDragging;
  }, [isDragging, onDrop, item, setDragging]);

  if (!item?.data) return null;

  return (
    <>
      <div
        style={{
          visibility: !isDragging ? 'hidden' : 'visible',
          position: 'fixed',
          inset: 0,
          pointerEvents: 'none',
          width: 'fit-content',
          height: 'fit-content',
          transform: translate,
          transition: `${isDragging ? 'transform 0s' : 'transform 0.2s'}`,

          zIndex: 100,
        }}
      >
        <SummaryColumn
          data={item?.data}
          style={{
            width: item?.dimensions?.width,
            transform: rotate,
            transition: 'transform 0.2s',
            overflow: 'hidden',
            maxHeight: 'none',
          }}
        />
      </div>

      {isDragging && <SetDraggingCursor />}
    </>
  );
};
