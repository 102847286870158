import { BodySmall, Icon } from 'components/shared';
import { colors } from 'constants/index';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  containsAtLeastOneNumber,
  containsLowercaseChar,
  containsUppercaseChar,
  minimumEightChars,
} from 'utils/signin';

const RequirementsContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  padding-left: 0;
  width: 100%;
`;

const ListItem = styled.li`
  list-style-type: none;
  display: flex;
  align-items: center;
`;

const passwordRequirementsList = [
  {
    text: 'Lower case character',
    testRequirement: containsLowercaseChar,
  },
  {
    text: 'Upper case character',
    testRequirement: containsUppercaseChar,
  },
  {
    text: 'At least one number',
    testRequirement: containsAtLeastOneNumber,
  },
  {
    text: '8 characters minimum',
    testRequirement: minimumEightChars,
  },
];

interface Props {
  passwordRequirements?: {
    text: string;
    testRequirement: (password: string) => boolean;
  }[];
  password: string;
  className?: string;
  allPassed?(passed: boolean): void;
}

export const PasswordRequirements = ({
  passwordRequirements = passwordRequirementsList,
  password,
  className,
  allPassed,
}: Props) => {
  useEffect(() => {
    if (allPassed) {
      allPassed(
        passwordRequirements.every(({ text, testRequirement }) =>
          testRequirement(password)
        )
      );
    }
  }, [allPassed, password, passwordRequirements]);

  return (
    <RequirementsContainer className={className}>
      {passwordRequirements.map(({ text, testRequirement }) => (
        <ListItem key={text}>
          <Icon
            name="Tick"
            size={25}
            color={testRequirement(password) ? colors.green : colors.greyLight}
          />
          <BodySmall>{text}</BodySmall>
        </ListItem>
      ))}
    </RequirementsContainer>
  );
};
