import { BodyExtraSmall, Icon } from 'components/shared';
import { colors } from 'constants/index';
import { KeyEventFragment } from 'data/graphql/generated';
import React from 'react';
import styled from 'styled-components';
import { KEY_EVENT_ICON_WIDTH } from './constants';
import { TimelineKeyEventCallOut } from './TimelineKeyEventCallOut';

const Text = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 25%;
`;

const Wrapper = styled.div<{ left: string; isActive: boolean }>`
  cursor: pointer;
  height: ${KEY_EVENT_ICON_WIDTH}px;
  width: ${KEY_EVENT_ICON_WIDTH}px;
  position: absolute;
  left: ${({ left }) => left};
  background: ${({ isActive }) =>
    isActive ? colors.purple15 : colors.white70};
  border-radius: 12px;

  :hover {
    background: ${colors.purple15};
  }
`;

type Props = {
  left: string;
  events: KeyEventFragment[];
  onClick(): void;
  isHovered: boolean;
  setIsHovered(): void;
  handleOnMouseLeave(): void;
  isDesktop: boolean;
  isCountryTimeline: boolean;
};

const TimelineKeyEvent = ({
  left,
  events,
  onClick,
  isHovered,
  setIsHovered,
  handleOnMouseLeave,
  isDesktop,
  isCountryTimeline,
}: Props) => {
  const eventsAmountDisplay = events.length < 2 ? 0 : events.length;

  return (
    <Wrapper
      isActive={isHovered}
      left={left}
      onMouseEnter={setIsHovered}
      onMouseLeave={handleOnMouseLeave}
      onClick={onClick}
    >
      {isHovered && isDesktop && (
        <TimelineKeyEventCallOut
          data={events}
          onMouseEnter={setIsHovered}
          onMouseLeave={handleOnMouseLeave}
          offsetX={0}
        />
      )}

      <div style={{ pointerEvents: 'none', position: 'absolute', top: 0 }}>
        <Icon
          name="Calendar"
          size={KEY_EVENT_ICON_WIDTH}
          height={KEY_EVENT_ICON_WIDTH}
          color={isCountryTimeline ? colors.blue : colors.purple}
        />
        {Boolean(eventsAmountDisplay) && (
          <Text>
            <BodyExtraSmall
              color={isCountryTimeline ? colors.blue : colors.purple}
            >
              {eventsAmountDisplay}
            </BodyExtraSmall>
          </Text>
        )}
      </div>
    </Wrapper>
  );
};

export default TimelineKeyEvent;
