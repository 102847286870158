import PptxGenJS from 'pptxgenjs';
import { colors } from '../../constants';
import { getStatementText } from '../../utils/brandPositioning';
import { StatementFragment } from '../../data/graphql/generated';
import { isArray } from 'lodash';

export function downloadSummary({
  statements,
  drugName,
}: {
  statements: StatementFragment[];
  drugName: string;
}) {
  const {
    theWhoStatementSummaryText,
    whatItIsStatementText,
    benefitsStatementText,
    reasonsToBelieveStatementText,
  } = getStatementText({ statements, drugName });

  const groups = {
    "Who it's for": theWhoStatementSummaryText,
    'What it is': whatItIsStatementText,
    Benefits: benefitsStatementText,
    'Reasons to Believe': reasonsToBelieveStatementText,
  };

  let pres = new PptxGenJS();
  let slide = pres.addSlide();

  const rows: PptxGenJS.TableRow[] = [];

  slide.addText('Summary', {
    x: 0.2,
    y: 0.2,
    color: colors.black,
    fontSize: 14,
    fontFace: 'Arial',
  });

  Object.keys(groups).forEach((t) => {
    const title = t as keyof typeof groups;
    const text = groups[title];
    const renderText = isArray(text)
      ? text.map((text = '') => text).join('\n')
      : text || '';

    rows.push([
      {
        text: [{ text: title }],
        options: {
          color: colors.black,
          fontSize: 10,
          fontFace: 'Arial',
          bold: true,
          align: 'center',
          border: [
            { pt: 1, color: colors.purple },
            { pt: 1, color: colors.purple },
            { pt: 1, color: colors.purple },
            { pt: 1, color: colors.purple },
          ],
        },
      },
      {
        text: [
          {
            text: renderText,
          },
        ],
        options: {
          bullet: isArray(groups[title]),
          color: colors.black,
          fontSize: 10,
          fontFace: 'Arial',
          align: 'left',
          border: [
            { pt: 1, color: colors.purple },
            { pt: 1, color: colors.purple },
            { pt: 1, color: colors.purple },
            { pt: 1, color: colors.purple },
          ],
        },
      },
    ]);
  });

  slide.addTable(rows, { x: 0.3, y: 0.6, w: 9.4 });

  pres.writeFile({
    fileName: `Positioning_label.pptx`,
  });
}
