import {
  KeyEventFragment,
  KeyEventInput,
  KeyEventStepInput,
  KeyEventUpdateInput,
  Step,
} from 'data/graphql/generated';
import KeyEvent from '../ui/KeyEvent';

type Props = {
  id: number;
  keyEvent: KeyEventFragment;
  localUid: string;
  date: KeyEventFragment['date'];
  text: KeyEventFragment['text'];
  type: KeyEventFragment['type'];
  strategyId: KeyEventFragment['strategyId'];
  onUpdate({
    localUid,
    date,
    text,
    keyEvent,
    step,
    region,
  }: KeyEventUpdateInput & { keyEvent: KeyEventFragment }): void;
  onCreate: ({ localUid, date, strategy, text, type }: KeyEventInput) => void;
  onDelete?(id: number, localUid: string): void;
  toggleEnabledStatus({
    keyEvent,
    step,
    strategy,
  }: KeyEventStepInput & {
    keyEvent: KeyEventFragment;
  }): void;
  currentStep: Step | undefined;
  canEnable: boolean;
  region?: string;
  isPreview: boolean;
};

const KeyEventContainer = ({
  id,
  keyEvent,
  onCreate,
  onDelete,
  onUpdate,
  toggleEnabledStatus,
  localUid,
  region,
  strategyId,
  date,
  text,
  type,
  currentStep,
  canEnable,
  isPreview,
}: Props) => {
  const handleDelete = () => {
    onDelete?.(id, localUid);
  };

  const handleOnClickOutside = (text: string) => {
    if (!text && isPreview) onDelete?.(id, localUid);
  };

  const handleUpdate = ({ text, date }: { text?: string; date: string }) => {
    if (!text || !currentStep) {
      return;
    }

    if (isPreview) {
      onCreate({
        localUid,
        strategy: strategyId,
        text,
        type,
        date,
        step: currentStep,
        createdAt: keyEvent.createdAt,
      });
      return;
    }

    onUpdate?.({
      step: currentStep,
      localUid,
      date,
      text,
      keyEvent,
      region,
    });
  };

  const handleToggleEnabledStatus = () => {
    if (isPreview) return;
    if (currentStep) {
      toggleEnabledStatus({
        keyEvent,
        keyEventLocalUid: localUid,
        step: currentStep,
        strategy: strategyId,
      });
    }
  };
  return (
    <KeyEvent
      onDelete={handleDelete}
      onUpdate={handleUpdate}
      onClickOutside={handleOnClickOutside}
      toggleEnabledStatus={handleToggleEnabledStatus}
      text={text}
      date={date}
      enabled={!!keyEvent?.enabledForRequestRegion}
      autoFocus={isPreview}
      canEnable={canEnable}
      localTactic={region !== 'global'}
      region={region}
      isPreview={isPreview}
    />
  );
};

export default KeyEventContainer;
