import { colors } from 'constants/index';
import React from 'react';
import styled from 'styled-components';
import { ButtonLabel } from '.';

const Wrapper = styled.button`
  cursor: pointer;
  user-select: none;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  height: 30px;
  width: 100%;
  border: 0.5px solid ${colors.black30};
  border-radius: 5px;
  background-color: ${colors.yellow50};

  padding: 0px 15px 0px 0px;

  font-family: ABCFavorit;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  transition: all 0.3s ease;

  :hover {
    background-color: ${colors.white50};
  }
`;

interface Props {
  icon: JSX.Element;
  text: string;
  onClick(): void;
}

export const ButtonNoteCardType = ({ icon, text, onClick }: Props) => {
  return (
    <Wrapper className="cypress-add-note-button" onClick={onClick}>
      {icon}
      <ButtonLabel>{text}</ButtonLabel>
    </Wrapper>
  );
};
