import styled from 'styled-components/macro';
import { BodySmall, Icon } from 'components/shared';
import { colors } from 'constants/colors';
import { getCategoryIcon } from '../util/getCategoryIcon';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  margin-right: 5px;
`;

interface MedicalTacticCategoryDropdownItemProps {
  value: string;
  textColor?: string;
  iconColor?: string;
}

export const MedicalTacticCategoryDropdownItem = ({
  value,
  textColor = colors.black,
  iconColor = colors.black,
}: MedicalTacticCategoryDropdownItemProps) => {
  const iconName = getCategoryIcon(value);

  return (
    <StyledContainer>
      <StyledIcon name={iconName} color={iconColor} size={25} />
      <BodySmall color={textColor}>{value}</BodySmall>
    </StyledContainer>
  );
};
