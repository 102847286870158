import { CommercialTacticFragmentWithGlobalFocusedStatus } from '../../../types';

export function getGlobalAndRegionTactics(
  tacticItems: CommercialTacticFragmentWithGlobalFocusedStatus[],
  region: string
) {
  return tacticItems
    .map((tactic) => {
      const regionTactic = tactic.GlobalCommercialTacticRegionStatus.find(
        (regionStatus) => {
          return regionStatus.region === region;
        }
      );

      if (regionTactic) {
        return {
          ...tactic,
          x: null,
          y: null,
          ...regionTactic,
          id: tactic.id,
          __typename: 'CommercialTactic',

          isGlobalLocalTactic: true,
          focusRationale: regionTactic?.focusRationale,
          isGlobalFocused: tactic.focused,
          isLocalFocused: regionTactic?.focused,
        };
      }

      // region version of global Tactic does not currently exist, so display card in sidebar by providing null x and y
      if (tactic.region === 'global') {
        return {
          ...tactic,
          x: null,
          y: null,
          // A tactic that is global but is available for placement and focus in a region
          isGlobalLocalTactic: true,
          isGlobalFocused: tactic.focused,
        };
      }

      return {
        ...tactic,
        isLocalFocused: tactic.region !== 'global' && tactic.focused,
      };
    })
    .filter(
      (item) => item.region === 'global' || item.region === region
    ) as CommercialTacticFragmentWithGlobalFocusedStatus[];
}

export function getGlobalTactics(
  tacticItems: CommercialTacticFragmentWithGlobalFocusedStatus[]
) {
  return tacticItems
    .filter((item) => item.region === 'global')
    .map((tactic) => {
      const isRegionTacticFocused = Boolean(
        tactic.GlobalCommercialTacticRegionStatus.find((regionStatus) => {
          return regionStatus.focused;
        })
      );

      return {
        ...tactic,

        isGlobalFocused: tactic.focused,
        isLocalFocused: Boolean(isRegionTacticFocused),
        isGlobalTactic: true,
      };
    });
}
