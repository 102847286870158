import { ReusableListEmptyState } from 'components/GridPages/GridPageSidebar';

type Props = {
  showAllTacticsState: boolean;
};

export const TacticGroupEmptyState = ({ showAllTacticsState }: Props) => {
  if (showAllTacticsState) {
    return (
      <ReusableListEmptyState
        title="All commercial initiatives positioned on the grid"
        subtitle="You can still add more"
      />
    );
  }
  return (
    <ReusableListEmptyState
      title="No commercial initiatives yet"
      subtitle="Add commercial initiatives then position them on the grid"
    />
  );
};
