import styled, { css } from 'styled-components/macro';

import { colors } from 'constants/index';
import { DrugDetailWrapper } from 'containers/Strategies';
import { device } from 'utils/breakpoints';

import { BulletPointList } from '../shared/BulletPointList';
import { UnderlineTextFieldContainer } from '../shared/UnderlineTextField';

export const MultiBulletPointListWrapper = styled.div<{ stack: boolean }>`
  display: flex;
  width: 100%;
  gap: 15px;
  flex-direction: ${({ stack }) => (stack ? 'column' : 'row')};

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 0px;

    > * {
      margin-bottom: 15px;
    }
  }
`;

export const StyledBulletPointList = styled(BulletPointList)`
  ${UnderlineTextFieldContainer} {
    align-items: baseline;

    color: ${colors.greyDark};
  }
`;

export const StyledDrugDetailWrapper = styled(DrugDetailWrapper)<{
  isLead: boolean;
  isMultipleLists: boolean;
}>`
  width: auto;
  justify-content: flex-start;

  .definitionHeader__textarea {
    width: 100%;

    ${({ isLead }) => {
      if (!isLead) {
        return css`
          cursor: not-allowed;
        `;
      }
    }}
  }
  .definitionHeader__textarea:disabled {
    background-color: transparent;
  }

  ${({ isMultipleLists }) => {
    if (isMultipleLists) {
      return css`
        @media (max-width: 837px) {
          flex-direction: column;
          align-items: flex-start;
        }
      `;
    } else {
      return css`
        @media ${device.tabletMax} {
          flex-direction: column;
          align-items: flex-start;
          width: 100%;

          > p {
            width: auto;
            margin-bottom: 5px;
          }
          .textArea__wrapper {
            width: 100%;
            .definitionHeader__textarea {
              width: 100%;

              min-width: 100%;
            }
          }
        }
      `;
    }
  }}
`;

export const AlignedProgressWrapper = styled.div<{ isMultipleLists: boolean }>`
  width: 60px;
  height: 60px;
  margin: -10px auto;

  position: relative;

  > .resultDetails {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > .resultDetails_counter {
      display: flex;
      align-items: baseline;
    }
  }

  ${({ isMultipleLists }) => {
    if (!!isMultipleLists) {
      return css`
        @media (max-width: 837px) {
          margin: 0;
          order: -1;
          display: flex;
          width: auto;
          height: auto;
          align-items: center;
          > .resultDetails {
            position: relative;
            margin-left: 5px;
            flex-direction: row;
            align-items: baseline;
          }
        }
      `;
    } else {
      return css`
        @media ${device.tabletMax} {
          margin: 0;
          order: -1;
          display: flex;
          width: auto;
          height: auto;
          align-items: center;
          > .resultDetails {
            position: relative;
            margin-left: 5px;
            flex-direction: row;
            align-items: baseline;
          }
        }
      `;
    }
  }}
`;

export const MetricsWrapper = styled.div<{ isMultipleLists: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 15px;

  align-self: center;

  ${({ isMultipleLists }) => {
    if (!!isMultipleLists) {
      return css`
        @media (max-width: 837px) {
          justify-content: space-between;
          width: 100%;
          margin-left: 0;
          margin-top: 5px;
        }
      `;
    } else {
      return css`
        @media ${device.tabletMax} {
          justify-content: space-between;
          width: 100%;
          margin-left: 0;
          margin-top: 5px;
        }
      `;
    }
  }}
`;

export const StyledCollaboration = styled.div<{ isMultipleLists: boolean }>`
  background: ${colors.black05};
  border-radius: 30px;

  margin-right: 15px;

  ${({ isMultipleLists }) => {
    if (!!isMultipleLists) {
      return css`
        @media (max-width: 837px) {
          margin-right: 0;
        }
      `;
    } else {
      return css`
        @media ${device.tabletMax} {
          margin-right: 0;
        }
      `;
    }
  }}
`;

export const StyledDataFieldContainer = styled.div<{ isLead?: boolean }>`
  flex: 1;
  width: 100%;
  cursor: ${({ isLead }) => (isLead ? 'default' : 'not-allowed')};
`;
