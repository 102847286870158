import { Loading } from 'components/Loading';
import { Content, ModalLarge, Scroll } from 'components/ModalLarge';
import { BodySmall, Caption, Subtitle1, Subtitle2 } from 'components/shared';
import { colors } from 'constants/index';
import { polling } from 'constants/polling';
import {
  KeyInsightsQueryVariables,
  Sort,
  useKeyInsightsLazyQuery,
} from 'data/graphql/generated';
import useMobile from 'hooks/useMobile';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';

const StyledModalLarge = styled(ModalLarge)`
  > ${Scroll} {
    padding-top: 110px;

    @media ${device.tabletMin} {
      padding-top: 0px;
    }

    > ${Content} {
      @media ${device.tabletMin} {
        max-width: 639px;
      }
    }
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 30px;
`;

const Sections = styled.div`
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media ${device.desktopMin} {
    padding: 0 40px;
  }
`;

const Section = styled.section`
  border: 1px solid ${colors.black10a};
  border-radius: 5px;
  padding: 15px;
`;

const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  background: ${colors.yellow20};
  padding: 25px 15px;
`;

const BulletPointList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const BulletPointAndText = styled.div`
  display: flex;
`;

const BulletPoint = styled.div`
  width: 15px;
  height: 15px;
  border: 5px solid ${colors.purple};
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 2px;
  flex: none;
`;

const SectionTitle = styled(BodySmall)`
  margin-bottom: 15px;
`;

const BulletPointListComponent: React.FC<{
  data: { id: number; text?: string; title?: string }[];
}> = ({ data }) => (
  <BulletPointList>
    {data.map((v) => {
      const textShown = 'text' in v ? v.text : v.title;

      return (
        <BulletPointAndText key={v.id}>
          <BulletPoint />
          <BodySmall color={colors.greyDark}>{textShown}</BodySmall>
        </BulletPointAndText>
      );
    })}
  </BulletPointList>
);
interface Props {
  handleClose(): void;
  visible: boolean;
  strategyId: number;
  drugId: number;
}

export const WhatToConsiderModal = ({
  handleClose,
  visible,
  strategyId,
  drugId,
}: Props) => {
  const isMobile = useMobile();

  const keyInsightsQueryVars: KeyInsightsQueryVariables = {
    where: {
      strategyId: Number(strategyId),
      inValidPatientJourneyColumn: true,
    },
    orderBy: { createdAt: Sort.Desc },
  };

  const [
    fetchKeyInsights,
    {
      data: keyInsightsData,
      loading: keyInsightsLoading,
      startPolling: keyInsightsStartPolling,
      stopPolling: keyInsightsStopPolling,
    },
  ] = useKeyInsightsLazyQuery({
    variables: {
      ...keyInsightsQueryVars,
    },
  });

  useEffect(() => {
    if (!visible || !keyInsightsStartPolling) return;
    keyInsightsStartPolling(polling.default);
    return () => {
      keyInsightsStopPolling();
    };
  }, [keyInsightsStartPolling, keyInsightsStopPolling, visible]);

  useEffect(() => {
    if (!visible) return;

    fetchKeyInsights();
  }, [visible, fetchKeyInsights]);

  const insights = keyInsightsData?.keyInsights?.items;

  return (
    <StyledModalLarge
      size={'small'}
      handleClose={handleClose}
      visible={visible}
      headerColor={isMobile ? colors.black05 : colors.cream}
    >
      {keyInsightsLoading ? (
        <Loading style={{ marginTop: 20 }} />
      ) : (
        <ModalContent>
          <Subtitle1 style={{ marginBottom: 5 }}>What to consider</Subtitle1>
          <Caption color={colors.greyDark} style={{ marginBottom: 20 }}>
            A recap of the key insights
          </Caption>

          <Sections>
            <Section>
              <SectionTitle>Key insights</SectionTitle>

              {insights?.length ? (
                <BulletPointListComponent data={insights} />
              ) : (
                <EmptyStateWrapper>
                  <Subtitle2 color={colors.greyDark}>
                    No insights revealed yet
                  </Subtitle2>
                  <div style={{ textAlign: 'center' }}>
                    <BodySmall
                      style={{ display: 'inline' }}
                      color={colors.greyDark}
                    >
                      Insights are created in{' '}
                    </BodySmall>
                    <Link
                      to={`/d/${drugId}/strategy/${strategyId}/1_5/Patient`}
                      style={{
                        color: colors.greyDark,
                        display: 'inline',
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      1.5 Key Insights.
                    </Link>
                  </div>
                </EmptyStateWrapper>
              )}
            </Section>
          </Sections>
        </ModalContent>
      )}
    </StyledModalLarge>
  );
};
