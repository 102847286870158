import { colors } from 'constants/index';
import React from 'react';
import styled from 'styled-components';
import { clamp } from 'utils/clamp';
import { BodySmall, Subtitle1 } from '.';

const Wrapper = styled.div<{ maxValue: number; $value: number }>`
  width: 95px;
  height: 95px;
  border-radius: 100px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: ${({ $value, maxValue }) => {
    const maxTransparancy = 0.2;
    const transparancy = (1 - $value / maxValue) * maxTransparancy;

    return `rgba(120, 0, 255, ${clamp(transparancy, 0.05, 1)})`;
  }};
`;

interface Props {
  value: number;
  text: string;
  maxValue?: number;
  emptyText?: string;
}

export const ScoreCircle = ({
  value,
  text,
  maxValue = 5,
  emptyText,
}: Props) => {
  return (
    <Wrapper $value={value} maxValue={maxValue}>
      {value ? (
        <>
          <Subtitle1>{value.toFixed(1)}</Subtitle1>
          <BodySmall color={colors.black70}>{text}</BodySmall>
        </>
      ) : (
        <BodySmall color={colors.greyDark}>{emptyText}</BodySmall>
      )}
    </Wrapper>
  );
};
