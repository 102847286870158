import { colors } from 'constants/colors';
import styled from 'styled-components/macro';
import { ProgressDonutConstants } from './ProgressDonut.constants';

export const StyledContainer = styled.div<{ size: number }>`
  min-width: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  min-height: ${({ size }) => `${size}px`};

  position: relative;
  /* transform: scale(1);
  transition: transform 0.3s; */
`;

export const ProgressDonutSvg = styled.svg<{}>`
  height: 100%;
  width: 100%;
  overflow: visible;
`;

export const ChildrenContainer = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ProgressDonutBaseCircle = styled.circle<{}>`
  fill: transparent;
  stroke: ${colors.greyMedium};
  stroke-width: ${ProgressDonutConstants.BASE_STROKE_WIDTH};
`;

interface ProgressDonutCircleProps {
  progress: number;
  circumference: number;
}
interface ProgressDonutProgressCircleProps extends ProgressDonutCircleProps {
  progressPercentage: number;
  targetPercentage: number;
}
export const ProgressDonutProgressCircle = styled.circle<ProgressDonutProgressCircleProps>`
  fill: transparent;
  stroke: ${({ progressPercentage, targetPercentage }) => {
    if (progressPercentage >= targetPercentage) {
      return 'url(#ProgressDonut-green-purple-gradient)';
    }
    return colors.darkRed;
  }};
  stroke-width: ${ProgressDonutConstants.PROGRESS_STROKE_WIDTH};
  stroke-linecap: ${({ progressPercentage }) =>
    progressPercentage === 100 ? 'square' : 'round'};
  stroke-dasharray: ${({ circumference }) =>
    `${circumference} ${circumference}`};
  stroke-dashoffset: ${({ progress, progressPercentage, circumference }) =>
    progressPercentage
      ? progress +
        // compensates for round line cap at both ends of circle
        ProgressDonutConstants.PROGRESS_STROKE_WIDTH
      : circumference};

  transform: ${({ circumference }) => {
    const roundButtLength = ProgressDonutConstants.PROGRESS_STROKE_WIDTH / 2;
    const ratio =
      (roundButtLength + ProgressDonutConstants.MARKER_STROKE_WIDTH / 2) /
      circumference;
    /* offset rotation by butt length against the circumference of the circle. 
This gives us the ratio to use to offset the rotations */
    const offsetRotation = ratio * 360;
    return `rotate(${270 + offsetRotation}deg)`;
  }};

  transition: stroke-dashoffset 0.4s, stroke 0.3s;
`;

export const ProgressDonutTargetCircle = styled.circle<ProgressDonutCircleProps>`
  fill: transparent;
  stroke: ${colors.greyMedium};
  stroke-width: ${ProgressDonutConstants.TARGET_STROKE_WIDTH};
  stroke-linecap: ${({ progress }) => (!progress ? 'square' : 'round')};

  stroke-dasharray: ${({ circumference }) =>
    `${circumference} ${circumference}`};

  stroke-dashoffset: ${({ progress }) =>
    progress +
    // compensates for round line cap at both ends of circle
    ProgressDonutConstants.TARGET_STROKE_WIDTH};

  transform: ${({ circumference }) => {
    const roundButtLength = ProgressDonutConstants.TARGET_STROKE_WIDTH / 2;
    const ratio =
      (roundButtLength + ProgressDonutConstants.MARKER_STROKE_WIDTH / 2) /
      circumference;
    /* offset rotation by butt length against the circumference of the circle. 
This gives us the ratio to use to offset the rotations */
    const offsetRotation = ratio * 360;
    return `rotate(${270 + offsetRotation}deg)`;
  }};
  transition: stroke-dashoffset 0.4s;
`;
