import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import {
  ArchetypeWhereInput,
  useArchetypesQuery,
  SubStep,
  useAccessStrategyPrioritiseSupportingMessagesQuery,
  User,
  useUserSafeUpdateMutation,
  Role,
  ArchetypeFragment,
} from 'data/graphql/generated';
import { pageHeaderHeight_4_1_1, polling } from 'constants/index';
import AccessStrategyNav from './AccessStrategyNav';
import AccessStrategyStepHeader from './AccessStrategyStepHeader';
import AccessStrategyGrid from './AccessStrategyGrid';
import useDesktop from 'hooks/useDesktop';
import {
  GridHeader,
  TouchPositioningContent,
  TouchPositioningOverlay,
} from 'components/GridPages/DropzoneGrid';
import { ButtonPill } from 'components/shared';
import { AccessStrategySidebar } from './AccessStrategySidebar';
import {
  PageAndSidebarContainer,
  PageContainer,
  Content,
} from 'components/GridPages/GridPageComponents';
import { ErrorModal } from 'components/ErrorModal';
import { CTAWrapper } from 'components/ModalForm';
import { useAuthContext } from 'contexts/AuthContext';
import { SupportingMessageCallout } from './SupportingMessageCallout';
import { PostItsEmpty } from 'components/PostItsEmpty';
import { device } from 'utils/breakpoints';
import { verifyUserRole } from 'utils/verifyUserRole';
import { Country } from 'types';
import { Loading } from 'components/Loading';

const SHOW_ARCHETYPE_EDIT_MODAL = 'showArchetypeEditModal';

const StyledGridHeader = styled(GridHeader)`
  justify-content: space-between;
`;

export const CannotEditModal = styled(ErrorModal)`
  ${CTAWrapper} {
    flex-direction: row;
  }
`;

const StyledEmptyState = styled(PostItsEmpty)`
  margin: 190px 20px;

  @media ${device.desktopMin} {
    margin: 130px 20px;
  }
`;

const StyledLoading = styled(Loading)`
  width: 100%;
`;

interface URLParams {
  drugId: string;
  strategyId: string;
  archetypeId: string;
}

export const messsagesQueryVars = (strategyId: string) => ({
  where: {
    strategyId: Number(strategyId),
  },
});

function determineModalDisplayState(
  archetypes: ArchetypeFragment[] | undefined,
  urlArchetypeId: string,
  userNotAssignedToArchetype: boolean,
  user?: User
): boolean {
  if (!archetypes) return false;

  const { isGlobalContributor, isLead } = verifyUserRole(
    user?.role,
    user?.country
  );

  if (userNotAssignedToArchetype || isLead || isGlobalContributor) return false;

  if (user?.modalAccessStrategyArchetypeDismissed) return false; // User has permanently hidden the modal

  const showModal = sessionStorage.getItem(SHOW_ARCHETYPE_EDIT_MODAL);
  if (showModal === String(false)) return false; // User has hidden the modal for this session

  const urlArchetype = archetypes.find((a) => a.id === +urlArchetypeId);
  const userViewingOwnArchetype = urlArchetype?.archetypeCountries.some(
    (ac) => (ac.country as Country) === user?.country
  );

  if (userViewingOwnArchetype) return false;

  return true;
}

export const AccessStrategyPrioritisation = () => {
  const { strategyId, archetypeId }: URLParams = useParams();
  const [{ user, refetch: refetchUser }] = useAuthContext();
  const { isGlobalContributor, isCountryContributor } = verifyUserRole(
    user?.role,
    user?.country
  );

  const [updateUser] = useUserSafeUpdateMutation();
  const isDesktop = useDesktop();
  const [sidebarOpen, setSidebarOpen] = useState(isDesktop ? true : false);
  const [archetypeModalOpen, setArchetypeModalOpen] = useState(false);
  const [touchPositioningView, setTouchPositioningView] = useState<
    string | null
  >(null); // holds the localUID

  // Fetch 4.1.1 supporting messages
  const {
    data: messagesData,
    loading: messagesLoading,
    error: messagesError,
    startPolling: messagesStartPolling,
    stopPolling: messagesStopPolling,
  } = useAccessStrategyPrioritiseSupportingMessagesQuery({
    variables: messsagesQueryVars(strategyId),
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  // Fetch archetypes
  const archetypesQueryVars: Record<'where', ArchetypeWhereInput> = {
    where: { strategyId: Number(strategyId) },
  };

  const {
    data: archetypeData,
    loading: archetypeLoading,
    error: archetypeError,
    startPolling: archetypesStartPolling,
    stopPolling: archetypesStopPolling,
  } = useArchetypesQuery({
    variables: {
      ...archetypesQueryVars,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  useEffect(() => {
    messagesStartPolling(polling.default);
    archetypesStartPolling(polling.default);
    return () => {
      messagesStopPolling();
      archetypesStopPolling();
    };
  }, [
    messagesStartPolling,
    messagesStopPolling,
    archetypesStartPolling,
    archetypesStopPolling,
  ]);

  const archetypes = archetypeData?.archetypes?.items;
  const messages =
    messagesData?.accessStrategyPrioritiseSupportingMessages?.items;

  // ArchetypeSwitcher determines the archetype in the URL, this component listens to URL changes
  const urlArchetype =
    archetypes?.find((a) => a.id === +archetypeId) || archetypes?.[0];

  const selectedArchetypeMessages = messages?.filter(
    (m) => m.archetypeId === (Number(urlArchetype?.id) || -1)
  );
  const touchViewMessage = messages?.find(
    (m) => m.localUid === touchPositioningView
  );

  const permittedToEdit =
    user?.role === Role.Lead ||
    isGlobalContributor ||
    (user &&
      urlArchetype?.archetypeCountries.some(
        (ac) => ac.country === user.country
      )) ||
    false;

  const accessStrategyGrid = useMemo(
    () => (
      <AccessStrategyGrid
        setSidebarOpen={setSidebarOpen}
        setTouchPositioningView={setTouchPositioningView}
        touchPositioningView={touchPositioningView}
        strategyId={strategyId}
        supportingMessages={selectedArchetypeMessages || []}
        permittedToEdit={permittedToEdit}
      />
    ),
    [
      setTouchPositioningView,
      touchPositioningView,
      setSidebarOpen,
      strategyId,
      selectedArchetypeMessages,
      permittedToEdit,
    ]
  );

  const accessStrategySidebar = useMemo(
    () => (
      <AccessStrategySidebar
        sidebarOpen={sidebarOpen}
        offsetTop={false}
        setSidebarOpen={setSidebarOpen}
        setTouchPositioningView={setTouchPositioningView}
        supportingMessages={selectedArchetypeMessages || []}
        permittedToEdit={permittedToEdit}
      />
    ),
    [
      sidebarOpen,
      setSidebarOpen,
      setTouchPositioningView,
      selectedArchetypeMessages,
      permittedToEdit,
    ]
  );

  useEffect(() => {
    if (isDesktop) {
      setTouchPositioningView(null);
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  }, [isDesktop]);

  const dataErrors = [archetypeError, messagesError];
  const dataMissing = !messagesData || !archetypeData;
  const dataLoading = archetypeLoading || messagesLoading;

  const noCountriesAssignedToArchetype = !archetypes?.some(
    (a) => !!a.archetypeCountries.length
  );

  const userNotAssignedToArchetype = !archetypes?.some((a) =>
    a.archetypeCountries.some((ac) => ac.country === user?.country || '')
  );

  const showEmptyState =
    noCountriesAssignedToArchetype ||
    (isCountryContributor && userNotAssignedToArchetype);
  const emptyStateMessage =
    isCountryContributor && userNotAssignedToArchetype
      ? 'Your market is not assigned to an archetype'
      : 'No countries assigned to archetypes yet';

  useEffect(() => {
    if (dataLoading || !archetypeId) return;
    setArchetypeModalOpen(
      determineModalDisplayState(
        archetypes,
        archetypeId,
        userNotAssignedToArchetype,
        user
      )
    );
  }, [
    archetypes,
    archetypeId,
    user,
    userNotAssignedToArchetype,
    isGlobalContributor,
    dataLoading,
  ]);

  if (!archetypes) return null;

  return (
    <>
      <CannotEditModal
        handleClose={() => {
          sessionStorage.setItem(SHOW_ARCHETYPE_EDIT_MODAL, String(false));
          setArchetypeModalOpen(false);
        }}
        title="Cannot edit archetypes you are not in"
        text="This archetype is view only. Return to your archetype using the toggle at the top of the page to contribute."
        visible={archetypeModalOpen}
      >
        <ButtonPill
          level="secondary"
          text="Don't show this again"
          onClick={async () => {
            try {
              if (!user?.id) return;

              await updateUser({
                variables: {
                  data: {
                    modalAccessStrategyArchetypeDismissed: true,
                  },
                },
              });

              refetchUser && (await refetchUser());
            } catch (error) {
              alert('Something went wrong');
            } finally {
              sessionStorage.setItem(SHOW_ARCHETYPE_EDIT_MODAL, String(false));
              setArchetypeModalOpen(false);
            }
          }}
        />
      </CannotEditModal>

      <AccessStrategyNav substep={SubStep.Prioritisation} />

      {showEmptyState ? (
        <StyledEmptyState
          title={emptyStateMessage}
          subtitle={'Leads must first assign countries to archetypes'}
        />
      ) : (
        <PageAndSidebarContainer>
          <AccessStrategyStepHeader
            substep={SubStep.Prioritisation}
            permittedToEdit={permittedToEdit}
          />

          {!isDesktop && touchPositioningView && (
            <TouchPositioningOverlay>
              <TouchPositioningContent>
                <SupportingMessageCallout
                  data={touchViewMessage}
                  permittedToEdit={permittedToEdit}
                />
                {accessStrategyGrid}
              </TouchPositioningContent>
            </TouchPositioningOverlay>
          )}

          <ErrorWrapper
            isLoading={dataLoading}
            errors={dataErrors}
            dataMissing={dataMissing}
          >
            <PageContainer pageHeaderHeight={pageHeaderHeight_4_1_1}>
              {!!archetypeId ? (
                <>
                  <Content>
                    {!isDesktop && (
                      <StyledGridHeader>
                        <ButtonPill
                          text="Assign supporting messages"
                          onClick={() => {
                            setSidebarOpen(true);
                          }}
                          disabled={sidebarOpen}
                        />{' '}
                      </StyledGridHeader>
                    )}

                    {!touchPositioningView && accessStrategyGrid}
                  </Content>
                  {!touchPositioningView && isDesktop && accessStrategySidebar}
                </>
              ) : (
                <StyledLoading />
              )}
            </PageContainer>
          </ErrorWrapper>
          {!touchPositioningView && !isDesktop && accessStrategySidebar}
        </PageAndSidebarContainer>
      )}
    </>
  );
};
