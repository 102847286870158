import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import {
  KeyInsightFragment,
  KeyInsightUpdateMutation,
  ObservationFragment,
  Exact,
  KeyInsightUpdateInput,
} from 'data/graphql/generated';
import { device } from 'utils/breakpoints';
import { CTAWrapper } from './ModalForm';
import {
  BodyNormal,
  ButtonPill,
  ModalWithDetail,
  Observation,
  Subtitle1,
  Subtitle2,
  BodySmall,
  EmptyState,
} from './shared';
import { FetchResult, MutationFunctionOptions } from '@apollo/client';
import { colors } from 'constants/colors';
import { Content } from 'components/ModalLarge';

const StyledModalWithDetail = styled(ModalWithDetail)`
  ${Content} {
    padding-bottom: 70px;

    @media ${device.tabletMin} {
      padding-bottom: 0;
      position: relative;
    }
  }
`;

const ObservationsList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  > div {
    margin-bottom: 5px;
  }
  > div:last-child {
    margin-bottom: 0px;
  }
`;

const StyledEmptyState = styled(EmptyState)`
  border-radius: 5px;
  background: ${colors.white50};
  text-align: center;
`;

const ButtonWrapper = styled(CTAWrapper)`
  gap: 15px;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
  position: absolute;
  bottom: 0;
  padding: 15px;
  background: ${colors.white};
  border-top: 1px solid ${colors.greyLight};
  z-index: 2;

  @media ${device.tabletMin} {
    border-top: none;
    position: relative;
    padding: 0;
  }
`;

interface Props {
  visible: boolean;
  handleClose: () => void;
  observations: (ObservationFragment | null)[];
  insight: KeyInsightFragment | undefined;
  updateKeyInsight: (
    options?:
      | MutationFunctionOptions<
          KeyInsightUpdateMutation,
          Exact<{
            id: number;
            data: KeyInsightUpdateInput;
          }>
        >
      | undefined
  ) => Promise<
    FetchResult<
      KeyInsightUpdateMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;
}

export default function AssignObservationsModal({
  visible,
  handleClose,
  observations,
  insight,
  updateKeyInsight,
}: Props) {
  const [saving, setSaving] = useState(false);
  const [selectedObservationIds, setSelectedObservationIds] = useState<{
    [id: string]: boolean;
  }>({});

  useEffect(() => {
    if (!insight) return;

    setSelectedObservationIds(
      observations.reduce((acc, observation) => {
        if (!observation) return acc;

        const id = observation.id;
        acc[`${id}`] = insight.observationIds.includes(id);
        return acc;
      }, {} as typeof selectedObservationIds)
    );
  }, [insight, observations]);

  function toggleSelected(id: number) {
    if (selectedObservationIds[id] === true) {
      setSelectedObservationIds({
        ...selectedObservationIds,
        [id]: false,
      });
    } else {
      setSelectedObservationIds({
        ...selectedObservationIds,
        [id]: true,
      });
    }
  }

  async function handleSave() {
    setSaving(true);
    const newObservations: number[] = Object.keys(
      selectedObservationIds
    ).reduce((acc, id) => {
      if (id && selectedObservationIds[id] === true) acc.push(+id);

      return acc;
    }, [] as number[]);

    try {
      await updateKeyInsight({
        variables: {
          id: insight!.id,
          data: { observations: newObservations },
        },
      });
    } catch (error) {
      alert('Something went wrong');
    } finally {
      setSaving(false);
      handleClose();
    }
  }

  return (
    <StyledModalWithDetail
      handleClose={handleClose}
      visible={visible}
      data={{ Insight: insight?.text || '' }}
      className={'cypress-assign-observations-modal'}
      keepOpenOnClickOutside={true}
    >
      {!!observations.length ? (
        <>
          <Subtitle1 style={{ marginBottom: 10 }}>
            Assign observations
          </Subtitle1>
          <BodyNormal style={{ textAlign: 'center', marginBottom: 15 }}>
            Select observations that support this insight
          </BodyNormal>

          <ObservationsList>
            {observations?.map((o) => {
              if (!o) return null;

              return (
                <Observation
                  data={o}
                  readonly
                  key={o.id}
                  deleteObservation={() => {}}
                  checked={selectedObservationIds[o.id] === true}
                  onClick={() => toggleSelected(o.id)}
                />
              );
            })}
          </ObservationsList>
        </>
      ) : (
        <StyledEmptyState>
          <Subtitle2 color={colors.greyDark}>
            No observations available
          </Subtitle2>
          <BodySmall color={colors.greyDark}>
            Add observations on the previous steps to use them here
          </BodySmall>
        </StyledEmptyState>
      )}
      <ButtonWrapper>
        <ButtonPill
          width="180px"
          text={'Cancel'}
          level={'secondary'}
          onClick={handleClose}
        />
        <ButtonPill
          width="180px"
          text={'Save'}
          onClick={handleSave}
          loading={saving}
        />
      </ButtonWrapper>
    </StyledModalWithDetail>
  );
}
