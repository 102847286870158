import React from 'react';
import { Icon } from '.';
import styled from 'styled-components';
import { colors } from 'constants/index';

const Wrapper = styled.button`
  border: 1px solid ${colors.black20a};
  background: ${colors.white};
  border-radius: 100px;
  padding: 0;
  cursor: pointer;
  width: 25px;
  height: 25px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border: 1px solid ${colors.purple50};
    background: ${colors.purple05};

    > div {
      color: ${colors.purple};
    }
  }
`;

interface Props {
  onClick(): void;
  className?: string;
}

export const ChartButton = ({ onClick, className }: Props) => {
  return (
    <Wrapper className={className} onClick={onClick}>
      <Icon name="Chart" size={25} color={colors.black70} />
    </Wrapper>
  );
};
