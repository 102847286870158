import React from 'react';
import styled from 'styled-components/macro';

import { CriticalMetricGoalInput } from 'data/graphql/generated';
import { ButtonLabel } from 'components/shared';
import {
  ReusableDropdown,
  SelectedOption,
} from 'components/shared/ReusableDropdown';
import { colors } from 'constants/colors';
import { device } from 'utils/breakpoints';
import { GoalInputDropdownItem } from './GoalInputDropdownItem';

export interface GoalInputDropdownOptionType {
  id: CriticalMetricGoalInput;
  value: CriticalMetricGoalInput;
}

const DEFAULT_GOAL_INPUT_DROPDOWN_OPTIONS: GoalInputDropdownOptionType[] = [
  {
    id: CriticalMetricGoalInput.GlobalOnly,
    value: CriticalMetricGoalInput.GlobalOnly,
  },
  {
    id: CriticalMetricGoalInput.PerCountry,
    value: CriticalMetricGoalInput.PerCountry,
  },
];

const StyledDropdown = styled(ReusableDropdown)`
  min-width: 115px;
  width: 100%;

  ul {
    @media ${device.desktopMin} {
      width: 100%;
    }
  }

  @media ${device.tabletMax} {
    ${SelectedOption} {
      padding-left: 0px;

      :hover {
        padding-left: 4px;
      }
    }
  }
` as typeof ReusableDropdown;

interface GoalInputDropdownProps {
  selectedOption: GoalInputDropdownOptionType | null;
  setSelectedOption: React.Dispatch<
    React.SetStateAction<GoalInputDropdownOptionType | null>
  >;
  onUpdate: (val: any) => void;
  editable?: boolean;
}

export function GoalInputDropdown({
  selectedOption,
  setSelectedOption,
  onUpdate,
  editable = true,
}: GoalInputDropdownProps) {
  return (
    <StyledDropdown
      editable={!!editable}
      options={DEFAULT_GOAL_INPUT_DROPDOWN_OPTIONS}
      selectedOption={selectedOption}
      selectOption={(option) => {
        setSelectedOption(option);
        onUpdate({
          input: option.id as CriticalMetricGoalInput,
        });
      }}
      clearSelection={() => {
        setSelectedOption(null);
        onUpdate({ input: null });
      }}
      dropdownItem={(option) => <GoalInputDropdownItem value={option.value} />}
      selectedDropdownItem={(option) => (
        <GoalInputDropdownItem value={option.value} />
      )}
      noSelectionItem={() => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ButtonLabel color={colors.greyDark}>Choose input</ButtonLabel>
        </div>
      )}
    />
  );
}
