import { colors } from 'constants/colors';
import React, { CSSProperties } from 'react';
import { Icon } from './Icon';
import styled from 'styled-components';

const StyledIcon = styled(Icon)`
  cursor: pointer;
  :hover {
    color: ${colors.purple};
  }
`;

type Props = {
  onClick: () => unknown;
  size?: number;
  color?: string;
  className?: string;
  style?: CSSProperties;
};

export const CopyIcon = ({
  onClick,
  size = 20,
  color = colors.greyDark,
  className,
  style,
}: Props) => {
  return (
    <StyledIcon
      style={style}
      className={className}
      size={size}
      color={color}
      onClick={onClick}
      name="Copy"
    />
  );
};
