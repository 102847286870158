import { colors } from 'constants/index';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BodyNormal, BodySmall, ButtonLabel, ButtonPill, ButtonRound } from '.';
import { TextAreaInput } from './TextAreaInput';
import { TooltipCSS } from './Tooltip';
import OneButtonModalLink from 'components/OneButtonModalLink';
import { Link, useParams } from 'react-router-dom';

const DeleteConfirmation = styled.div`
  position: absolute;
  inset: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;

  background-color: ${colors.white};
  border-radius: 4px;
`;

const StyledTextArea = styled(TextAreaInput)`
  display: block;
  padding: 0;
  width: 100%;
  height: 100% !important;
  max-height: 42px !important;
  overflow: visible;
  resize: none;
  line-height: 20px;
  border-radius: 2px;
  outline: none;
  font-size: 14px;
  color: ${colors.black};
  border: 0.5px solid transparent;
  margin: 0;
`;

const DeleteButtonWrapper = styled.div<{ visible: boolean }>`
  position: absolute;
  right: -15px;
  top: -15px;
  z-index: 2;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

const StatementWrapper = styled.div<{ showEmptyState: boolean }>`
  border-radius: 5px;
  border: 1px solid
    ${({ showEmptyState }) =>
    showEmptyState ? colors.greyMedium : colors.purple};
  background-color: ${colors.white};
  padding: 10px;

  width: 260px;
  height: 60px;

  position: relative;
  margin-right: 15px;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  width: 100%;
  margin-top: 20px;
`;

export const ModalButton = styled(ButtonPill)`
  width: 181px;
  flex: 1;
`;

export const ModalButtonLink = styled(Link)`
  color: ${colors.greyDark};
  display: inline;
  font-size: 14px;
  font-weight: bolder;
  margin-bottom: 20px;
`

interface Props {
  placeholder?: string;
  showEmptyState?: boolean;
  text: string;
  autoFocus?: boolean;
  onDelete?(): void;
  onBlur?(text: string): void;
  onChange?(text: string): void;
  isLead?: boolean;
  marketRepPlaceholder: string;
  viewingOtherRegion?: boolean;
  overrideTooltip?: string;
  id?: number;
  commercialTactics?: commercialT[];
}

interface commercialT {
  focused: boolean;
  id: number;
  keyStatementId: number;
  text: string;
}

interface URLParams {
  drugId: string;
  strategyId: string;
  strategicImperativeId: string;
}

export const KeyStatement = ({
  autoFocus,
  placeholder = '',
  showEmptyState = false,
  text,
  onDelete,
  onBlur,
  isLead,
  onChange,
  marketRepPlaceholder,
  viewingOtherRegion,
  overrideTooltip,
  commercialTactics,
  id
}: Props) => {
  const { drugId, strategyId, strategicImperativeId }: URLParams = useParams();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [deleteIconHover, setDeleteIconHover] = useState(false);
  const [textValue, setTextValue] = useState(text);
  const [isErrorModalOpen, setErrorModalOpen] = React.useState<boolean>(false);
  const keyStatementIdToCheck = commercialTactics !== undefined && commercialTactics.find(
    (i) => i.keyStatementId === id
  );
  const preventDelete = keyStatementIdToCheck ? true : false;

  useEffect(() => {
    setTextValue(text);
  }, [text]);

  return (
    <StatementWrapper showEmptyState={showEmptyState} data-cy="key-statement">
      <OneButtonModalLink
        title="Cannot delete this strategic objective"
        body="Content in 4.3.2 depends on this strategic objective."
        modalOpen={isErrorModalOpen}
        handleClose={() => setErrorModalOpen(false)}
        onClick={() => setErrorModalOpen(false)}
      >
        <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
          Remove from grid in{' '}
        </BodySmall>
        <ModalButtonLink
          to={`/d/${drugId}/strategy/${strategyId}/4_3/winning-actions${strategicImperativeId ? '/' + strategicImperativeId : ''
            }`}
        >
          4.3.2 {' '}
        </ModalButtonLink>
        <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
          to do this.
        </BodySmall>
      </OneButtonModalLink>
      <TooltipCSS
        text={
          overrideTooltip ||
          (viewingOtherRegion
            ? 'Only local contributors can edit'
            : !isLead
              ? 'Only Leads can edit'
              : '')
        }
        style={viewingOtherRegion || !isLead ? { cursor: 'not-allowed' } : {}}
      >
        {showEmptyState ? (
          <BodySmall color={colors.greyDark}>{marketRepPlaceholder}</BodySmall>
        ) : (
          <StyledTextArea
            // disable focus
            disabled={viewingOtherRegion || !isLead}
            $disabled={viewingOtherRegion || !isLead}
            style={
              viewingOtherRegion || !isLead ? { cursor: 'not-allowed' } : {}
            }
            autoFocus={autoFocus}
            onFocus={() => {
              setShowDeleteIcon(true);
            }}
            onBlur={(e) => {
              if (isLead && !viewingOtherRegion) {
                if (!deleteIconHover) {
                  setShowDeleteIcon(false);
                }
                const validString = textValue.trim();

                onBlur?.(textValue);
                if (!validString) {
                  setTextValue(text);
                }
              }
            }}
            placeholder={placeholder}
            value={textValue}
            onChange={(e) => {
              const evt = e as React.ChangeEvent<HTMLTextAreaElement>;
              const text = evt.target.value;

              if (isLead && !viewingOtherRegion) {
                onChange?.(text);

                setTextValue(text);
              }
            }}
          />
        )}
      </TooltipCSS>

      <DeleteButtonWrapper
        visible={showDeleteIcon}
        onMouseEnter={() => {
          setDeleteIconHover(true);
        }}
        onMouseLeave={() => {
          setDeleteIconHover(false);
        }}
      >
        <ButtonRound
          iconName="Trash"
          level="secondary"
          size="small"
          onClick={() => {
            setShowDeleteConfirmation(true);
            setShowDeleteIcon(false);
          }}
        />
      </DeleteButtonWrapper>

      {!!showDeleteConfirmation && (
        <DeleteConfirmation>
          <BodyNormal>Delete this statement?</BodyNormal>
          <div style={{ display: 'flex', gap: 30, justifyContent: 'center' }}>
            <ButtonLabel
              onClick={() => {
                setShowDeleteConfirmation(false);
              }}
            >
              Cancel
            </ButtonLabel>
            <ButtonLabel
              color={colors.red}
              onClick={() => {
                if (preventDelete) {
                  setShowDeleteConfirmation(false);
                  return setErrorModalOpen(true)
                }
                onDelete?.();
              }}
            >
              Delete
            </ButtonLabel>
          </div>
        </DeleteConfirmation>
      )}
    </StatementWrapper>
  );
};
