import {
  BodyNormal,
  BodySmall,
  CountryFlag,
  EmptyState,
  Icon,
  PatientDefinitionImage,
  Subtitle1,
  Subtitle2,
  Tooltip,
} from 'components/shared';
import { colors } from 'constants/index';
import {
  PatientFlowBlock,
  PatientFlowBlockStarFragment,
  Role,
  StakeholderDefinition,
  StrategyFragment,
  usePatientFlowBlockStarUpdateMutation,
  usePatientFlowBlockStarUpsertMutation,
  User,
} from 'data/graphql/generated';
import React from 'react';
import { Country, CountryGlobal } from 'types';
import styled from 'styled-components';
import { Star } from './Star';
import { Link } from 'react-router-dom';
import { InternalRefetchQueriesInclude } from '@apollo/client';
import { startCase } from 'utils/startCase';
import { ModalLarge, Content } from 'components/ModalLarge';
import { device } from 'utils/breakpoints';

const ContentWrapper = styled.div`
  max-width: 515px;
  padding: 40px;
  text-align: center;
  @media ${device.mobile} {
    padding: 40px 15px;
  }
`;

const StyledModalLarge = styled(ModalLarge)`
  ${Content} {
    max-width: 515px;

    @media ${device.tabletMin} {
      height: min-content;
      min-height: min-content;
    }
  }
`;

const Header = styled.div`
  display: none;
  background: ${colors.cream};
  padding: 10px 6px;
  height: 50px;
  cursor: pointer;
  flex-direction: row;
  position: absolute;
  inset: 0;
  top: 50px;

  div {
    position: absolute;
    right: 5px;
  }

  @media ${device.mobile} {
    display: flex;
  }
`;

const CountriesWrapper = styled.div`
  margin-left: auto;
  width: 115px;
  max-width: 115px;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  gap: 5px;
`;

const PatientRow = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.greyLight};
  padding: 10px 0;
`;

interface Props {
  title: string;
  setLeveragePointInUseModal: React.Dispatch<React.SetStateAction<boolean>>;
  patientDefinitions: StrategyFragment['StakeholderDefinition'];
  starsOfBlock: PatientFlowBlockStarFragment[];
  starringModalBlock: PatientFlowBlock | null;
  currentCountry: CountryGlobal;
  handleVisible(): void;
  strategyId: number;
  drugId: number;
  user: User | undefined;
  refetchPatientFlowData: {
    refetchQueries: InternalRefetchQueriesInclude;
  };
}

export const StarringModal = ({
  title,
  patientDefinitions,
  starsOfBlock,
  setLeveragePointInUseModal,
  starringModalBlock,
  currentCountry,
  handleVisible,
  strategyId,
  drugId,
  user,
  refetchPatientFlowData,
}: Props) => {
  // Compare patientDefinitions against available
  const starForCountry = starsOfBlock.find((star) =>
    currentCountry !== 'global' ? star.region === currentCountry : star.global
  );

  const [
    starredStakeholderDefinitionIds,
    setStarredStakeholderDefinitionIds,
  ] = React.useState<number[]>(
    starForCountry?.stakeholderDefinitions.map((s) => s.id) || []
  );

  const isGlobalCountry = currentCountry === 'global';

  const [upsertStar] = usePatientFlowBlockStarUpsertMutation();
  const [updateStar] = usePatientFlowBlockStarUpdateMutation();

  if (starringModalBlock === null) {
    return null;
  }

  async function handleStarring(
    isStarred: boolean,
    patientDefinition: Pick<StakeholderDefinition, 'title' | 'image' | 'id'>,
    blockId: number
  ) {
    if (isStarred) {
      if (starForCountry) {
        try {
          const newStakeholderDefinitions = starredStakeholderDefinitionIds.filter(
            (s) => s !== patientDefinition.id
          );

          setStarredStakeholderDefinitionIds(newStakeholderDefinitions);

          await updateStar({
            variables: {
              id: starForCountry?.id,
              data: {
                strategy: strategyId,
                removeStakeholderDefinition: patientDefinition.id,
              },
            },
          });
        } catch (err) {
          if (
            err instanceof Error &&
            err.message === `Later content depends on item`
          ) {
            setStarredStakeholderDefinitionIds(starredStakeholderDefinitionIds);
            setLeveragePointInUseModal(true);
          } else {
            alert(err);
          }
        }
      }
    } else {
      try {
        const newStakeholderDefinitions = [
          ...starredStakeholderDefinitionIds,
          patientDefinition.id,
        ];

        setStarredStakeholderDefinitionIds(newStakeholderDefinitions);

        const data = {
          patientFlowBlock: blockId,
          stakeholderDefinition: patientDefinition.id,
          strategy: strategyId,
          global: currentCountry === 'global',
          region: currentCountry === 'global' ? undefined : currentCountry,
        };

        await upsertStar({
          variables: {
            data,
          },
          ...refetchPatientFlowData,
        });
      } catch (err) {
        setStarredStakeholderDefinitionIds(starredStakeholderDefinitionIds);
        alert(err);
      }
    }
  }

  const canEdit =
    user?.role && currentCountry === 'global'
      ? user.role === Role.Lead
      : user?.country === currentCountry && user.role !== Role.Lead;

  return (
    <StyledModalLarge
      hideMobileBack
      handleClose={handleVisible}
      visible={!!starringModalBlock}
      headerColor={colors.cream}
    >
      <Header>
        <Icon
          onClick={() => {
            handleVisible();
          }}
          name="Close"
          size={30}
          color={colors.black}
        />
      </Header>
      <Tooltip id="starringModal" effect="float" />
      <ContentWrapper>
        <Subtitle1 style={{ marginBottom: 15 }}>{title}</Subtitle1>
        <BodyNormal>Star patients this is a leverage point for</BodyNormal>
        <div style={{ marginTop: 20 }}>
          {patientDefinitions?.length ? (
            <div style={{ borderTop: `1px solid ${colors.greyLight}` }}>
              {patientDefinitions?.map((patientDefinition) => {
                const isStarred = starredStakeholderDefinitionIds?.includes(
                  patientDefinition.id
                );

                const countriesStarred = starsOfBlock
                  .filter((val) => {
                    const hasStakeholderDefinition = val.stakeholderDefinitions.some(
                      (definition) => definition.id === patientDefinition.id
                    );

                    const hasRegion = typeof val.region === 'string';

                    return hasStakeholderDefinition && hasRegion;
                  })
                  .map((star) => star.region as Country)
                  .sort();

                return (
                  <PatientRow key={patientDefinition.id}>
                    <Star
                      canEdit={canEdit}
                      currentCountry={currentCountry}
                      isStarred={!!isStarred}
                      isGlobalCountry={isGlobalCountry}
                      className="cypress-leverage-point-create"
                      onClick={() =>
                        handleStarring(
                          !!isStarred,
                          patientDefinition,
                          starringModalBlock.id
                        )
                      }
                    />

                    <PatientDefinitionImage
                      style={{ marginLeft: 5, marginRight: 10 }}
                      imageURL={patientDefinition.image}
                    />

                    <BodySmall style={{ textAlign: 'left' }}>
                      {patientDefinition.title}
                    </BodySmall>

                    {currentCountry === 'global' && (
                      <CountriesWrapper>
                        {countriesStarred.map((country) => (
                          <div
                            key={country}
                            data-for={'starringModal'}
                            data-tip={startCase(country)}
                            data-offset="{ 'right': -14, 'top':18 }"
                            data-place="right"
                          >
                            <CountryFlag
                              size={25}
                              country={country as Country}
                              disableTooltip
                            />
                          </div>
                        ))}
                      </CountriesWrapper>
                    )}
                  </PatientRow>
                );
              })}
            </div>
          ) : (
            <EmptyState style={{ minWidth: 435 }}>
              <Subtitle2 color={colors.greyDark}>
                No patient definitions
              </Subtitle2>
              <BodySmall
                style={{ pointerEvents: 'all' }}
                color={colors.greyDark}
              >
                Leads must define patients in{' '}
                <Link
                  to={`/d/${drugId}/strategy/${strategyId}/1_1/Patient`}
                  style={{
                    cursor: 'pointer',
                    fontWeight: 500,
                    color: colors.greyDark,
                  }}
                >
                  1.1 Key Stakeholder Analysis
                </Link>
              </BodySmall>
            </EmptyState>
          )}
        </div>
      </ContentWrapper>
    </StyledModalLarge>
  );
};
