import React, { createContext, useContext, useState } from 'react';

interface GlobalContextType {
  state: Record<string, any>;
  setValue: (key: string, value: any) => void; // Use more specific type instead of any if possible
}
const GlobalContext = createContext<GlobalContextType>({
  state: {},
  setValue: () => {} // This function is just a placeholder to match the expected type.
});

export const GlobalProvider = ({ children }: any) => {
    const [state, setState] = useState({key: '', value: ''});

    const setValue = (key: any, value: any): any => {
        setState(prevState => ({ ...prevState, [key]: value }));
    };

    return (
        <GlobalContext.Provider value={{ state, setValue }}>
            {children}
        </GlobalContext.Provider>
    );
};


export const useGlobalContext = (key: any) => {
    const context = useContext(GlobalContext);
    if (context === undefined) {
        throw new Error('useGlobalContext must be used within a GlobalProvider');
    }

    const { state, setValue } = context;

    const setKeyValue = (value: any) => {
        setValue(key, value);
    };

    return [state[key], setKeyValue];
};
