import React, {  useMemo, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { StrategyProgress } from './StrategyProgress';
import { StrategyStep, Wrapper as StrategyStepWrapper } from './StrategyStep';
import { useQuery } from '../hooks/useQuery';
import { colors } from '../constants/';
import {
  BodyNormal,
  BodySmall,
  ButtonLabel,
  ButtonPill,
  Heading3,
  Icon,
} from './shared';
import { device } from 'utils/breakpoints';
import { useContributionsQuery, useDrugsQuery } from 'data/graphql/generated';
import { ButtonArrow } from './shared/ButtonArrow';
import useDesktop from 'hooks/useDesktop';
import { BubbleSummary } from './Strategy/BubbleSummary';
import { IconContainer } from './shared/Icon';
import { useHistory } from 'react-router-dom';
import { checkIfFullNmblr } from 'containers/Strategies';

interface PhaseProps {
  active?: boolean;
  finished?: boolean;
  mobileView?: boolean;
  mobileViewHide: boolean;
}

const Background = styled.div<{
  mobileView: boolean;
  bubbleSummaryOpen: boolean;
}>`
  background: url('/strat-gradient.png') ${colors.black};
  min-height: calc(100vh - 50px);
  height: ${({ bubbleSummaryOpen }) =>
    bubbleSummaryOpen ? `calc(100vh - 50px)` : `2150px`};
  margin-top: 50px;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    background: rgba(250, 250, 250);
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0.2;
    pointer-events: none;
    z-index: 1;
  }

  &::before {
    content: '';
    background: url(/noise.png) repeat;
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0.1;
    pointer-events: none;
    z-index: 3;
  }

  @media ${device.tabletMax} {
    height: 1452px;
  }

  @media ${device.tabletMax} {
    ${({ mobileView }) =>
    mobileView
      ? css`
            height: calc(100vh - 50px);
            min-height: 800px;
          `
      : ''}
  }
`;

const PhaseTitle = styled(Heading3) <{ small?: boolean; mobileView?: boolean }>`
  color: #ffffff;
  display: block;
  text-align: center;

  font-family: ABCFavoritExpanded;
  font-size: ${({ small }) => (small ? 40 : 60)}px;
  line-height: 80px;

  ${({ mobileView }) => {
    if (mobileView)
      return css`
        font-size: 40px;
        line-height: 50px;
      `;
  }};

  @media ${device.tabletMax} {
    font-size: ${({ small }) => (small ? 32 : 60)}px;
    line-height: 80px;
  }
`;

const Phases = styled.div`
  max-width: 1200px;

  position: relative;
  margin: 0 auto;

  @media ${device.tabletMax} {
    max-width: 400px;
  }
`;

const PhaseSteps = styled.span<{ visible: boolean; mobileView?: boolean }>`
  position: absolute;
  left: 0px;
  top: 0px;

  opacity: ${({ visible }) => (visible ? 1 : 0)};
  display: ${({ visible }) => (visible ? 'block' : 'none')};

  @media ${device.tabletMax} {
    ${({ mobileView }) =>
    mobileView
      ? css`
            top: 165px;
            right: 0px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          `
      : css`
            display: none;
          `};
  }
`;

const Phase = styled.div<PhaseProps>`
  border-radius: 50%;
  z-index: 5;
  user-select: none;
  text-align: center;
  display: flex;
  flex-direction: column;

  ${({ finished }) =>
    !!finished &&
    css`
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background: #141427;
        border-radius: 50%;
        opacity: 0.6;
      }
    `}

  ${({ active }) =>
    active
      ? css`
          box-shadow: 0 0 0 5px #ffffff, 0 0 0 50px rgba(255, 255, 255, 0.05),
            0 0 0 100px rgba(255, 255, 255, 0.05);
          @media ${device.tabletMax} {
            box-shadow: 0 0 0 5px #ffffff, 0 0 0 20px rgba(255, 255, 255, 0.05),
              0 0 0 40px rgba(255, 255, 255, 0.05);
          }
        `
      : ''}

  ${({ active, finished }) =>
    active || finished
      ? css`
          background: linear-gradient(
              0deg,
              rgba(20, 20, 39, 0.2),
              rgba(20, 20, 39, 0.2)
            ),
            url('/strat-grad.png') center center, #665c97;
          background-size: contain;
        `
      : css`
          background: #a397ef;
        `}

  ${({ mobileView, mobileViewHide }) =>
    mobileView
      ? css`
          @media ${device.tabletMax} {
            .mobileViewHide {
              display: none;
            }

            position: absolute !important;
            top: 40px !important;
            left: 50% !important;
            height: 682px !important;
            padding-top: 60px !important;
            transform: translate(-50%, 0) !important;
            width: 682px !important;
            z-index: 8;
            display: ${mobileViewHide ? 'none' : 'block'};
          }
        `
      : mobileViewHide
        ? css`
          @media ${device.tabletMax} {
            display: none !important;
          }
        `
        : null}
`;

const Phase1 = styled(Phase) <PhaseProps>`
  flex-direction: column;
  position: absolute;
  width: 500px;
  height: 500px;
  padding-top: 180px;
  left: 50%;
  transform: translate(-50%, 100px);

  @media ${device.tabletMax} {
    width: 345px;
    height: 345px;
    padding-top: 102px;
    transform: translate(-50%, 40px);

    ${({ mobileView }) =>
    mobileView
      ? css`
            ${PhaseTitle} {
              font-size: 40px;
              line-height: 50px;
            }

            ${PhaseSteps} {
              top: 170px;
            }
          `
      : css`
            ${StrategyStepWrapper} {
              bottom: 83px;
              margin-bottom: 0;
            }
          `}
  }
`;

const Phase2 = styled(Phase) <PhaseProps>`
  position: absolute;
  width: 340px;
  height: 340px;
  left: 10%;
  transform: translate(0%, 720px);
  padding-top: 133px;

  ${PhaseTitle} {
    line-height: 45px;
    font-size: 32px;
  }

  @media ${device.tabletMax} {
    width: 251px;
    height: 251px;
    left: 5%;
    transform: translate(0%, 445px);
    padding-top: 73px;

    ${({ mobileView }) =>
    !mobileView &&
    css`
        ${StrategyStepWrapper} {
          margin-bottom: 0;
          bottom: 53px;
        }
      `}
  }
`;

const Phase3 = styled(Phase) <PhaseProps>`
  position: absolute;

  width: 340px;
  height: 340px;
  padding-top: 110px;
  right: 10%;
  transform: translate(0%, 1160px);

  ${PhaseTitle} {
    line-height: 50px;
  }

  @media ${device.tabletMax} {
    width: 251px;
    height: 251px;
    right: 5%;
    transform: translate(0%, 756px);
    padding-top: 73px;

    ${PhaseTitle} {
      line-height: 45px;
      margin-bottom: 7px;
    }
  }
`;

const Phase4 = styled(Phase) <PhaseProps>`
  position: absolute;
  width: 340px;
  height: 340px;
  left: 10%;
  transform: translate(0%, 1620px);
  padding-top: 115px;

  ${PhaseTitle} {
    line-height: 45px;
  }

  @media ${device.tabletMax} {
    width: 251px;
    height: 251px;
    left: 5%;
    transform: translate(0%, 1070px);
    padding-top: 73px;
  }
`;

const PhaseOverview = styled.span`
  display: none;
  align-self: center;

  @media ${device.tabletMax} {
    display: block;
  }
`;

const BackNav = styled.div<{ mobileView: boolean }>`
  width: 30px;
  height: 30px;
  background-color: ${colors.black50a};
  border-radius: 5px;
  position: fixed;
  top: 70px;
  left: 15px;
  z-index: 8;

  display: none;
  @media ${device.tabletMax} {
    display: ${({ mobileView }) => (mobileView ? 'block' : 'none')};
  }
`;

const SummaryLink = styled.div`
  margin-top: 25px;

  .button-container {
    display: flex;
    align-items: center;
    background: ${colors.white};
    border-radius: 40px;
    padding: 5px 20px 5px 5px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .icon-container {
    background: ${colors.purplePinkGradient};
    border-radius: 50%;
    color: ${colors.white};
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  svg {
    transform: translateX(0);
    transition: transform 0.2s ease-out;
  }

  &:hover {
    svg {
      transform: translateX(3px);
    }
  }

  @media ${device.desktopMin} {
    align-self: center;
    margin-top: 15px;
  }
`;

const TopLeftContainer = styled.div`
  background-color: transparent;
  border-radius: 5px;
  position: fixed;
  top: 70px;
  left: 15px;
  z-index: 8;
  display: flex;
`;

const BottomRightContainer = styled.div`
  width: 300px;
  border: 2px solid var(--purple, #7800ff);
  background-color: ${colors.white};
  border-radius: 5px;
  position: fixed;
  bottom: 20px;
  right: 15px;
  z-index: 8;
  display: flex;
`;

const Content = styled.div`
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding: 10px 5px 10px 15px;
`;

const FindOutMoreContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: flex-start;
  align-items: right;
`;

const FiveStepsLink = styled(ButtonArrow)`
  display: flex;
  justify-content: center;
  margin-top: 10px;

  ${IconContainer} {
    background: ${colors.purplePinkGradient};
    border-radius: 50%;
    color: ${colors.white};
  }
`;

interface Props {
  drugId: number;
  strategyId: number;
}

export const Strategy: React.FC<Props> = ({ drugId, strategyId }) => {
  let query = useQuery();
  const history = useHistory();

  const [active] = useState(5);
  const [mobileView, setMobileView] = useState<number | undefined>(
    Number(query.get('nav'))
  );
  const [bubbleSummary, setBubbleSummary] = useState<number | null>(null);
  const closeBubbleSummary = () => setBubbleSummary(null);
  const isDesktop = useDesktop();
  const { data: drugData } = useDrugsQuery({
    variables: { where: { id: drugId } },
    fetchPolicy: 'network-only',
  });

  const ProductOnDrugs = drugData?.drugs?.items?.filter(
    (e) => e.id === drugId
  )[0]?.ProductOnDrugs;

  const miniFeatureSet = useMemo(() => {
    if (ProductOnDrugs) {
      return ProductOnDrugs?.flatMap((f: any) => {
        if (f.Product?.features)
          return f.Product?.features?.map((g: any) => g.featureSet);
        return [];
      });
    }
    return [];
  }, [ProductOnDrugs]);

  const isFullNmblr = checkIfFullNmblr(miniFeatureSet);

  const checkFeatureSet = (feature: string) => {
    return miniFeatureSet.includes(feature) || false;
  };

  const {
    data: contributionsData,
    loading: contributionLoading,
  } = useContributionsQuery({
    variables: { where: { strategyId } },
    fetchPolicy: 'cache-and-network',
  });

  if (!contributionsData || contributionLoading) {
    return (
      <Background
        mobileView={!!mobileView}
        bubbleSummaryOpen={!!bubbleSummary}
      ></Background>
    );
  }

  const {
    keyStakeholders,
    patientFlow,
    patientJourney,
    keyInsights,
    strategicQuestion,
    competitiveLandscape,
    creatingValue,
    options,
    driversAndBarriers,
    keyTrendsAnalysis,
  } = contributionsData?.contributions;

  function buildURL(page: string) {
    return `/d/${drugId}/strategy/${strategyId}/${page}`;
  }
  return (
    <Background mobileView={!!mobileView} bubbleSummaryOpen={!!bubbleSummary}>
      {miniFeatureSet.length > 0 && !isFullNmblr && isDesktop && (
        <TopLeftContainer>
          <ButtonPill
            level="primary-solid"
            iconName="Arrow-left"
            className="add-insight"
            clickClassName="cypress-insight-create"
            onClick={async (e) => {
              history.push(`/d/${drugId}/strategy/${strategyId}/welcome`);
            }}
            text="Back to your plan"
          />
        </TopLeftContainer>
      )}
      {!!bubbleSummary && (
        <BubbleSummary
          closeBubbleSummary={closeBubbleSummary}
          strategyId={strategyId}
          bubble={bubbleSummary}
        />
      )}

      {!bubbleSummary && (
        <>
          <Phases>
            <Phase1
              active={true}
              finished={false}
              onClick={() => {
                setMobileView(1);
              }}
              mobileView={mobileView === 1}
              mobileViewHide={!!mobileView && mobileView !== 1}
            >
              <PhaseTitle>Discover</PhaseTitle>

              {isDesktop && (
                <SummaryLinkComponent
                  openBubbleSummary={() => setBubbleSummary(1)}
                />
              )}

              <PhaseSteps visible={true} mobileView={mobileView === 1}>
                <StrategyStep
                  text="Who are the stakeholders?"
                  subtext="Key Stakeholder Analysis"
                  className="cypress-pagelink-keystakeholders"
                  iconName={
                    keyStakeholders ? 'Step Complete' : 'Step Incomplete'
                  }
                  isMiniEnabled={miniFeatureSet.length > 0 && !isFullNmblr}
                  hasProduct={checkFeatureSet('1.1') === true}
                  top={90}
                  left={442}
                  link={buildURL(`1_1`)}
                  iconText={1.1}
                />
                <StrategyStep
                  iconName={
                    competitiveLandscape ? 'Step Complete' : 'Step Incomplete'
                  }
                  isMiniEnabled={miniFeatureSet.length > 0 && !isFullNmblr}
                  hasProduct={checkFeatureSet('1.2') === true}
                  text="Where is the potential for differentiation?"
                  subtext="Competitive Analysis"
                  className="cypress-pagelink-competitive-landscape"
                  top={160}
                  left={475}
                  link={buildURL(`1_2`)}
                  iconText={1.2}
                />
                <StrategyStep
                  text="What are the moments that matter?"
                  subtext="Patient Journey"
                  className="cypress-pagelink-patient-journey"
                  iconName={
                    patientJourney ? 'Step Complete' : 'Step Incomplete'
                  }
                  isMiniEnabled={miniFeatureSet.length > 0 && !isFullNmblr}
                  hasProduct={checkFeatureSet('1.3') === true}
                  top={230}
                  left={485}
                  link={buildURL(`1_3`)}
                  iconText={1.3}
                />
                <StrategyStep
                  text="What opportunities and risks do we anticipate?"
                  subtext="Key Trends Analysis"
                  className="cypress-pagelink-key-trends-analysis"
                  iconName={
                    keyTrendsAnalysis ? 'Step Complete' : 'Step Incomplete'
                  }
                  isMiniEnabled={miniFeatureSet.length > 0 && !isFullNmblr}
                  hasProduct={checkFeatureSet('1.4') === true}
                  top={300}
                  left={475}
                  link={buildURL(`1_4`)}
                  iconText={1.4}
                />
                <StrategyStep
                  text="What insights are revealed?"
                  subtext="Key Insights"
                  className="cypress-pagelink-keyinsights"
                  iconName={keyInsights ? 'Step Complete' : 'Step Incomplete'}
                  isMiniEnabled={miniFeatureSet.length > 0 && !isFullNmblr}
                  hasProduct={checkFeatureSet('1.5') === true}
                  top={370}
                  left={442}
                  link={buildURL(`1_5`)}
                  iconText={1.5}
                />
                {!isDesktop && (
                  <SummaryLinkComponent
                    openBubbleSummary={() => setBubbleSummary(1)}
                  />
                )}
              </PhaseSteps>
              <PhaseOverview className="mobileViewHide">
                <FiveStepsLink text={'5 steps'} name={'Complete'} />
              </PhaseOverview>
            </Phase1>

            <Phase2
              active={true}
              finished={false}
              onClick={() => setMobileView(2)}
              mobileView={mobileView === 2}
              mobileViewHide={!!mobileView && mobileView !== 2}
            >
              <PhaseTitle small>Explore</PhaseTitle>

              {isDesktop && (
                <SummaryLinkComponent
                  openBubbleSummary={() => setBubbleSummary(2)}
                />
              )}

              <PhaseSteps visible={active > 1} mobileView={mobileView === 2}>
                <StrategyStep
                  iconName={
                    strategicQuestion ? 'Step Complete' : 'Step Incomplete'
                  }
                  isMiniEnabled={miniFeatureSet.length > 0 && !isFullNmblr}
                  hasProduct={checkFeatureSet('2.1') === true}
                  text="What is our winning aspiration?"
                  subtext="Strategic Question"
                  className="cypress-pagelink-strategic-question"
                  top={10}
                  left={250}
                  link={buildURL('2_1')}
                  iconText={2.1}
                />
                <StrategyStep
                  text="Where might we focus for growth?"
                  subtext="Patient Flow"
                  className="cypress-pagelink-patient-flow"
                  iconName={patientFlow ? 'Step Complete' : 'Step Incomplete'}
                  isMiniEnabled={miniFeatureSet.length > 0 && !isFullNmblr}
                  hasProduct={checkFeatureSet('2.2') === true}
                  top={80}
                  left={305}
                  link={buildURL('2_2')}
                  iconText={2.2}
                />
                <StrategyStep
                  iconName={creatingValue ? 'Step Complete' : 'Step Incomplete'}
                  text="How might we create value?"
                  subtext="Evidence Generation Ideas"
                  className="cypress-pagelink-creating-value"
                  isMiniEnabled={miniFeatureSet.length > 0 && !isFullNmblr}
                  hasProduct={checkFeatureSet('2.3') === true}
                  link={buildURL('2_3')}
                  top={150}
                  left={322}
                  iconText={2.3}
                />
                <StrategyStep
                  iconName={options ? 'Step Complete' : 'Step Incomplete'}
                  text="What are our options?"
                  subtext="Strategic Possibilities"
                  isMiniEnabled={miniFeatureSet.length > 0 && !isFullNmblr}
                  hasProduct={checkFeatureSet('2.4') === true}
                  className="cypress-pagelink-options"
                  link={buildURL('2_4')}
                  top={220}
                  left={310}
                  iconText={2.4}
                />
                <StrategyStep
                  iconName={
                    driversAndBarriers ? 'Step Complete' : 'Step Incomplete'
                  }
                  text="What would have to be true?"
                  subtext="Drivers and Barriers"
                  isMiniEnabled={miniFeatureSet.length > 0 && !isFullNmblr}
                  hasProduct={checkFeatureSet('2.5') === true}
                  className="cypress-pagelink-drivers-and-barriers"
                  link={buildURL('2_5')}
                  top={290}
                  left={250}
                  iconText={2.5}
                />
                {!isDesktop && (
                  <SummaryLinkComponent
                    openBubbleSummary={() => setBubbleSummary(2)}
                  />
                )}
              </PhaseSteps>
              <PhaseOverview className="mobileViewHide">
                <FiveStepsLink text={'5 steps'} name={'Complete'} />
              </PhaseOverview>
            </Phase2>

            <Phase3
              active={true}
              finished={false}
              onClick={() => setMobileView(3)}
              mobileView={mobileView === 3}
              mobileViewHide={!!mobileView && mobileView !== 3}
            >
              <PhaseTitle small>Develop</PhaseTitle>

              {isDesktop && (
                <SummaryLinkComponent
                  openBubbleSummary={() => setBubbleSummary(3)}
                />
              )}

              <PhaseSteps visible={active > 2} mobileView={mobileView === 3}>
                <StrategyStep
                  iconName="Step Incomplete"
                  text="Differentiation"
                  subtext="Brand Positioning"
                  className="cypress-pagelink-positioning"
                  isMiniEnabled={miniFeatureSet.length > 0 && !isFullNmblr}
                  hasProduct={checkFeatureSet('3.1') === true}
                  top={10}
                  left={-65}
                  link={buildURL('3_1')}
                  reversed={!mobileView}
                  iconText={3.1}
                />
                <StrategyStep
                  isMiniEnabled={miniFeatureSet.length > 0 && !isFullNmblr}
                  hasProduct={checkFeatureSet('3.2') === true}
                  iconName="Step Incomplete"
                  text="Value proposition"
                  subtext="Core Value Messages"
                  className="cypress-pagelink-value-proposition"
                  top={80}
                  left={-145}
                  link={buildURL('3_2')}
                  reversed={!mobileView}
                  iconText={3.2}
                />
                <StrategyStep
                  isMiniEnabled={miniFeatureSet.length > 0 && !isFullNmblr}
                  hasProduct={checkFeatureSet('3.3') === true}
                  iconName="Step Incomplete"
                  text="Competitive advantage"
                  subtext="Strategic Imperatives"
                  className="cypress-pagelink-competitive-advantage"
                  top={150}
                  left={-190}
                  link={buildURL('3_3')}
                  reversed={!mobileView}
                  iconText={3.3}
                />
                <StrategyStep
                  isMiniEnabled={miniFeatureSet.length > 0 && !isFullNmblr}
                  hasProduct={checkFeatureSet('3.4') === true}
                  iconName="Step Incomplete"
                  text="Critical success factors"
                  subtext="Goals and Distinctive Capabilities"
                  className="cypress-pagelink-distinctive-capabilities"
                  top={220}
                  left={-244}
                  link={buildURL('3_4/strategic-objectives?')}
                  reversed={!mobileView}
                  iconText={3.4}
                />
                <StrategyStep
                  isMiniEnabled={miniFeatureSet.length > 0 && !isFullNmblr}
                  hasProduct={checkFeatureSet('3.5') === true}
                  iconName="Step Incomplete"
                  text="Critical metrics"
                  subtext="Lead and Lag Indicators"
                  className="cypress-pagelink-critical-metrics"
                  top={290}
                  left={-108}
                  link={buildURL('3_5/indicators')}
                  reversed={!mobileView}
                  iconText={3.5}
                />

                {!isDesktop && (
                  <SummaryLinkComponent
                    openBubbleSummary={() => setBubbleSummary(3)}
                  />
                )}
              </PhaseSteps>
              <PhaseOverview className="mobileViewHide">
                <FiveStepsLink text={'5 steps'} name={'Complete'} />
              </PhaseOverview>
            </Phase3>

            <Phase4
              active={true}
              finished={false}
              mobileViewHide={!!mobileView && mobileView !== 4}
              onClick={() => setMobileView(4)}
              mobileView={mobileView === 4}
            >
              <PhaseTitle small>Activate</PhaseTitle>

              {isDesktop && (
                <SummaryLinkComponent
                  openBubbleSummary={() => setBubbleSummary(4)}
                />
              )}

              <PhaseSteps visible={active > 3} mobileView={mobileView === 4}>
                <StrategyStep
                  isMiniEnabled={miniFeatureSet.length > 0 && !isFullNmblr}
                  hasProduct={checkFeatureSet('4.1') === true}
                  iconName={'Step Incomplete'}
                  text="Creating and sustaining patient access"
                  subtext="Access Strategy"
                  className="cypress-pagelink-access-strategy"
                  top={10}
                  left={249}
                  link={buildURL('4_1')}
                  iconText={4.1}
                />
                <StrategyStep
                  isMiniEnabled={miniFeatureSet.length > 0 && !isFullNmblr}
                  hasProduct={checkFeatureSet('4.2') === true}
                  iconName={'Step Incomplete'}
                  text="Enhancing medical practice"
                  subtext="Medical Strategy"
                  className="cypress-pagelink-medical-strategy"
                  top={80}
                  left={305}
                  link={buildURL('4_2')}
                  iconText={4.2}
                />
                <StrategyStep
                  isMiniEnabled={miniFeatureSet.length > 0 && !isFullNmblr}
                  hasProduct={checkFeatureSet('4.3') === true}
                  iconName={'Step Incomplete'}
                  text="Shaping attitudes and beliefs"
                  subtext="Commercial Strategy"
                  className="cypress-pagelink-commercial-strategy"
                  link={buildURL('4_3')}
                  top={150}
                  left={322}
                  iconText={4.3}
                />
                <StrategyStep
                  isMiniEnabled={miniFeatureSet.length > 0 && !isFullNmblr}
                  hasProduct={checkFeatureSet('4.4') === true}
                  iconName={'Step Incomplete'}
                  text="Managing risk"
                  subtext="Risk Mitigation"
                  className="cypress-pagelink-risk-mitigation"
                  link={buildURL('4_4')}
                  top={220}
                  left={310}
                  iconText={4.4}
                />
                <StrategyStep
                  isMiniEnabled={miniFeatureSet.length > 0 && !isFullNmblr}
                  hasProduct={checkFeatureSet('4.5') === true}
                  iconName={'Step Incomplete'}
                  text="Maintaining brand relevance"
                  subtext="Long-term Strategy"
                  className="cypress-pagelink-long-term-strategy"
                  link={buildURL('4_5')}
                  top={290}
                  left={250}
                  iconText={4.5}
                />

                {!isDesktop && (
                  <SummaryLinkComponent
                    openBubbleSummary={() => setBubbleSummary(4)}
                  />
                )}
              </PhaseSteps>
              <PhaseOverview className="mobileViewHide">
                <FiveStepsLink text={'5 steps'} name={'Complete'} />
              </PhaseOverview>
            </Phase4>
          </Phases>

          <BackNav
            onClick={() => {
              setMobileView(0);
            }}
            mobileView={!!mobileView}
          >
            <Icon name="Back" size={30} color={colors.cream} />
          </BackNav>
          <StrategyProgress active={active} mobileView={!!mobileView} />
        </>
      )}

      {miniFeatureSet.length > 0 && !isFullNmblr && isDesktop && (
        <BottomRightContainer>
          <Content>
            <BodyNormal color={colors.black}>
              Expand your plan and get more out of nmblr
            </BodyNormal>
            <FindOutMoreContainer>
              <ButtonPill
                level="primary-solid"
                className="add-insight"
                clickClassName="cypress-insight-create"
                onClick={() => {
                  window.open('https://nmblr.co/arrange-a-demo/', '_blank');
                }}
                text="Arrange a demo"
              />
            </FindOutMoreContainer>
          </Content>
        </BottomRightContainer>
      )}
    </Background>
  );
};

const SummaryLinkComponent: React.FC<{
  openBubbleSummary: () => void;
}> = ({ openBubbleSummary }) => (
  <SummaryLink onClick={openBubbleSummary}>
    <div className="button-container">
      <div className="icon-container">
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.8995 14.9497L21.4298 15.48L21.4298 14.4193L20.8995 14.9497ZM15.4194 10.5302L20.3691 15.48L21.4298 14.4193L16.48 9.46959L15.4194 10.5302ZM20.3691 14.4193L15.4194 19.3691L16.48 20.4297L21.4298 15.48L20.3691 14.4193ZM20.8995 14.1997L8.99996 14.1997L8.99996 15.6997L20.8995 15.6997L20.8995 14.1997Z"
            fill={colors.white}
          />
        </svg>
      </div>
      <BodySmall color={colors.black}>Summary</BodySmall>
    </div>
    <ButtonLabel color={colors.white}>Learn more</ButtonLabel>
  </SummaryLink>
);