export function connectionDotHideAll(stageRef: any, blockId: number) {
  const dots = stageRef.current.find(`.connection-dot-${blockId}`);
  Object.keys(dots).forEach((k) => {
    const d = dots[k];
    if (!d || !d.show) return;
    d.opacity(0);
  });
  stageRef.current.batchDraw();
}

export function connectionDotShowAll(stageRef: any, blockId: number) {
  const dots = stageRef.current.find(`.connection-dot-${blockId}`);

  Object.keys(dots).forEach((k) => {
    const d = dots[k];
    if (!d || !d.show) return;
    d.opacity(1);
  });

  stageRef.current.batchDraw();
}
