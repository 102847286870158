import styled from 'styled-components/macro';
import capitalize from 'lodash/capitalize';
import { colors } from 'constants/colors';
import { Caption } from 'components/shared';
import { ProgressCardConstants } from '../constants-goal-tracking';
import { device } from 'utils/breakpoints';

const calculateBarWidthFromPercentage = (
  percentage: number,
  isMobile = false
) => {
  const maxWidth = isMobile
    ? ProgressCardConstants.MOBILE_BAR_MAX_WIDTH
    : ProgressCardConstants.BAR_MAX_WIDTH;

  const width = (percentage / 100) * maxWidth;

  if (width < ProgressCardConstants.BAR_MIN_WIDTH) {
    return ProgressCardConstants.BAR_MIN_WIDTH;
  }

  return width;
};

const ProgressCardItemBarContainer = styled.div<{
  average: number;
  isTracked: boolean;
}>`
  width: ${({ average }) => `${calculateBarWidthFromPercentage(average)}px`};
  overflow: visible;
  position: relative;

  ${Caption} {
    position: absolute;
    width: max-content;
    margin-left: ${({ isTracked }) => !isTracked && '5px'};
  }

  @media ${device.mobile} {
    width: ${({ average }) =>
      `${calculateBarWidthFromPercentage(average, true)}px`};
  }
`;

const ProgressCardItemBarEmpty = styled.div`
  height: 30px;
`;

const ProgressCardItemBar = styled.div<{ average: number; isOnTrack: boolean }>`
  height: 30px;
  width: ${({ average }) => `${calculateBarWidthFromPercentage(average)}px`};
  background: ${({ isOnTrack }) =>
    isOnTrack ? colors.greenPurpleGradient : colors.redPurpleGradient};
  border-radius: 0 5px 5px 0;

  @media ${device.mobile} {
    width: ${({ average }) =>
      `${calculateBarWidthFromPercentage(average, true)}px`};
  }
`;

interface ProgressCardItemBarSectionProps {
  tracking: { region: string; average?: number };
  goalTarget: number;
  isTracked?: boolean;
}

export const ProgressCardItemBarSection = ({
  tracking,
  goalTarget,
  isTracked = false,
}: ProgressCardItemBarSectionProps) => {
  if (tracking.average === undefined) {
    return (
      <ProgressCardItemBarContainer average={0} isTracked={isTracked}>
        <ProgressCardItemBarEmpty />
        <Caption color={colors.greyDark}>{capitalize(tracking.region)}</Caption>
      </ProgressCardItemBarContainer>
    );
  }

  return (
    <ProgressCardItemBarContainer
      average={tracking.average}
      isTracked={isTracked}
    >
      <ProgressCardItemBar
        average={tracking.average}
        isOnTrack={tracking.average >= goalTarget}
      />
      <Caption color={colors.greyDark}>{capitalize(tracking.region)}</Caption>
    </ProgressCardItemBarContainer>
  );
};
