import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { colors } from 'constants/colors';
import { Icon } from './Icon';

const Stars = styled.div`
  height: 19px;
  position: relative;

  .stars__non-hover,
  .stars__hover {
    position: absolute;
    top: 0px;
    display: flex;
    gap: 1px;
  }

  .stars__hover {
    display: none;
  }

  &:hover .stars__hover {
    display: block;
    position: absolute;
    top: 0px;
    display: flex;
    gap: 1px;
  }
  &:hover .stars__non-hover {
    display: none;
  }
`;

interface Props {
  rating: number;
  onUpdate?(val: number): void;
  style?: React.CSSProperties;
  readOnly?: boolean;
}

export const RatingStar: React.FC<Props> = ({
  rating,
  onUpdate,
  style,
  readOnly = false,
}) => {
  const [tempRating, setTempRating] = useState(rating);
  if (rating < 0 || rating > 5) console.error('rating must be between 0 and 5');

  useEffect(() => {
    if (rating !== tempRating) {
      setTempRating(rating);
    }
    // eslint-disable-next-line
  }, [rating]);

  // NOTE couldn't stop an issue with onMouseEnter being
  // randomly called after onMouseLeave, so I've added a
  // non-hover state with CSS

  return (
    <Stars data-cy="rating-stars">
      <div className="stars__non-hover">
        {[...Array(5)].map((_, idx) => {
          const unselected = idx >= rating;
          return (
            <div key={idx} style={{ width: 20, height: 19 }}>
              {readOnly ? (
                <Icon
                  name="StarSelected"
                  size={20}
                  color={unselected ? colors.greyMedium : colors.purple}
                  style={style}
                />
              ) : (
                <Icon
                  name={unselected ? 'StarUnselected' : 'StarSelected'}
                  size={20}
                  color={unselected ? colors.greyDark : colors.purple}
                  style={{ cursor: readOnly ? 'auto' : 'pointer', ...style }}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className="stars__hover" data-cy="rating-stars-hover">
        {[...Array(5)].map((_, idx) => {
          const unselected = idx >= tempRating;
          return (
            <div
              key={idx}
              style={{ width: 20, height: 19 }}
              onMouseEnter={() => {
                !readOnly && setTempRating(idx + 1);
              }}
              onMouseLeave={() => {
                setTempRating(rating);
              }}
            >
              {readOnly ? (
                <Icon
                  name="StarSelected"
                  size={20}
                  color={unselected ? colors.greyMedium : colors.purple}
                  style={style}
                />
              ) : (
                <Icon
                  name={unselected ? 'StarUnselected' : 'StarSelected'}
                  size={20}
                  color={unselected ? colors.greyDark : colors.purple}
                  onClick={() => !readOnly && onUpdate && onUpdate(idx + 1)}
                  style={{ cursor: readOnly ? 'auto' : 'pointer', ...style }}
                />
              )}
            </div>
          );
        })}
      </div>
    </Stars>
  );
};
