import { Caption } from 'components/shared';
import { colors } from 'constants/colors';
import React from 'react';
import styled from 'styled-components/macro';
import { clamp } from 'utils/clamp';

const Wrapper = styled.div`
  width: 250px;
  max-width: fill-available;
`;

export const Bar = styled.div`
  height: 10px;
  border-radius: 5px;
`;

const GreyBar = styled(Bar)`
  background: ${colors.greyLight};
  position: relative;
`;

const PurpleBar = styled(Bar)<{ width: number }>`
  background: ${colors.purple};
  width: ${({ width }) => width && `${width}%`};
`;

const Marker = styled.div<{ left: number }>`
  position: absolute;
  left: ${({ left }) => left && `${left}%`};
  width: 1px;
  top: 0;
  bottom: 0;
  background: ${colors.greyLight};
`;

interface Props {
  title?: string;
  percentageFilled: number;
}

export default function RatingBar({ title, percentageFilled }: Props) {
  return (
    <Wrapper>
      <Caption color={colors.greyDark} style={{ marginBottom: 5 }}>
        {title}
      </Caption>

      <GreyBar>
        <PurpleBar
          width={clamp(percentageFilled, 4, 100)} // Below 4% width the rating loses its rounded appearance
        />

        <Marker left={25} />
        <Marker left={50} />
        <Marker left={75} />
      </GreyBar>
    </Wrapper>
  );
}
