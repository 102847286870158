import React, { useEffect, useRef, useState } from 'react';
import { Layer, Stage } from 'react-konva';
import styled from 'styled-components/macro';
import { ApolloQueryResult, QueryOptions } from '@apollo/client';

import { useLocation } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthContext';
import { colors } from 'constants/colors';
import { useCollaborationContext } from 'contexts/CollaborationContext';
import { StrategyDefinitionHeader } from 'components/StrategyDefinitionHeader';
import {
  Collaboration,
  CollaborationQuery,
  StrategicQuestionType,
  useCollaborationQuery,
  User,
} from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import { device } from 'utils/breakpoints';
import { getStakeholderColor } from 'utils/stakeholderColor';
import { Alignments } from './Collaboration/Alignments';
import { Attachments } from './Collaboration/Attachments';
import { CollaborationMedicalTactic } from './Collaboration/CollaborationMedicalTactic';
import { Comments } from './Comments';
import { ErrorModal } from './ErrorModal';
import { Insight } from './Insight';
import { ModalLarge } from './ModalLarge';
import { BlockView as PatientFlowBlock } from './PatientFlow/Block';
import { getBlockBG } from './PatientJourney/src/lib/utils/blockColors';
import { getCardColor, PostItCard } from './PostItCard';
import {
  BodySmall,
  Observation,
  Subtitle1,
  Subtitle2,
  Icon,
  ScorePill,
  ImageAndTitle,
  StatementV2,
  Caption,
  BodyNormal,
  CountryFlag,
} from './shared';
import { average } from 'utils/average';
import { EndpointTarget } from './Collaboration/EndpointTarget';
import { Loading } from 'components/Loading';
import { Wrapper as ImageAndTitleWrapper } from 'components/shared/ImageAndTitle';
import { SuccessConditionPreview } from './Collaboration/SuccessCondition';
import { BigIdea } from './Collaboration/BigIdea';
import { BigIdeaDetail } from './Collaboration/BigIdeaDetail';
import { CommercialTacticDetail } from './Collaboration/CommercialTacticDetail';
import { FutureTrend } from './Collaboration/FutureTrend';
import { polling } from 'constants/index';
import { getFullStatement } from 'utils/getFullStatement';
import { Solution } from './Collaboration/Solution';
import { startCase } from 'lodash';
import RatingBar from './shared/RatingBar';
import calculateAverageMessageRatings from 'utils/calculateAverageMessageRatings';
import { SupportingMessageTactic } from './Collaboration/SupportingMessageTactic';
import { getUserTitleOrCountryAndName } from 'utils/getUserTitleOrCountryAndName';
import CollaborationPicoSuggestion from './Collaboration/CollaborationPicoSuggestion';
import { CompAdvantageRow } from './Collaboration/CompAdvantageRow';
import { LongTermSolution } from './Collaboration/LongTermSolution';
import { CollaborationCriticalMetricsGoal } from './3-5-critical-metrics/3-5-2-goals/shared-ui-goals/src';
import { CollaborationCriticalMetricsTrackedGoal } from './3-5-critical-metrics/3-5-2-goals-tracking/feature-goals-tracking/src';
import { BlockCardPreview } from './PatientJourney/src';

const StyledStatementV2 = styled((props) => <StatementV2 {...props} />)``;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledAttachments = styled(Attachments)`
  margin-bottom: 40px;
  @media ${device.tabletMax} {
    margin-top: 40px;
    margin-bottom: 0px;
  }
`;

const StyledAlignments = styled(Alignments)`
  @media ${device.tabletMax} {
    margin-top: 40px;
  }
`;

const StyledComments = styled(Comments)``;

const Preview = styled.div``;

const Title = styled.div`
  margin-bottom: 15px;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: minmax(400px, 500px) minmax(auto, 320px);
  gap: 0px 40px;
  margin: 20px 15px;

  @media ${device.tabletMax} {
    grid-template-columns: 100%;
  }

  @media ${device.desktopMin} {
    margin: 40px;
  }
`;

const ChildContent = styled.div<{ noAddedBy?: boolean }>`
  background: ${colors.black05};
  position: relative;
  min-height: 50px;
  padding: 15px;
  border-radius: 5px;

  ${StyledStatementV2} {
    padding: 15px;
  }
`;

const CenterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CompetitiveLandscapeOpportunityCollaboration = styled.div`
  width: 260px;
  height: 125px;
  background: ${colors.white};
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
`;

const WhiteContainer = styled.div`
  background: ${colors.white};
  width: 431px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 5px;

  ${ImageAndTitleWrapper} {
    max-width: none;
  }
`;

const RatingBars = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 250px;
  height: fit-content;
  margin-top: 15px;
`;

const AddedByFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  margin-top: 15px;
`;

const CollaborationContainer = styled.div`
  background: ${colors.white};
  padding: 15px;
  margin: -5px;
  border-radius: 5px;
  width: 100%;
`;

const StrategicPossibilityCollaboration = styled(WhiteContainer)``;

export const CollaborationModal: React.FC = () => {
  const [collaborationId, setCollaboration] = useCollaborationContext();
  // const [url, setUrl] = useState<string>();
  const location = useLocation();
  const [auth] = useAuthContext();

  // set collaborationId if it's in the url
  useEffect(() => {
    if (!auth.user) return;
    // if (collaborationId && location.pathname !== url) {
    // setUrl(undefined);
    // setCollaboration(undefined);
    // return;
    // }
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.get('collaboration')) {
      setCollaboration(Number(urlParams.get('collaboration')));
      // setUrl(location.pathname);
    }
    // eslint-disable-next-line
  }, [location, auth.user, setCollaboration]);

  // update url params on collaborationId change
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (collaborationId) {
      urlParams.set('collaboration', String(collaborationId));
    } else {
      urlParams.delete('collaboration');
    }
    window.history.pushState(null, '', '?' + urlParams.toString());
  }, [collaborationId]);

  // Disable scroll on html
  useEffect(() => {
    const htmlNode = document.getElementsByTagName('html')[0];
    if (!htmlNode) return;
    if (collaborationId) {
      htmlNode.style['overflow'] = 'hidden';
    } else {
      htmlNode.style['overflow'] = 'auto';
    }
  }, [collaborationId]);
  if (!collaborationId) return null;

  return (
    <CollaborationContent
      collaborationId={collaborationId}
      setCollaboration={setCollaboration}
    />
  );
};

function getHeaderColor(collaboration?: Collaboration | null) {
  if (!collaboration) return colors.black05;

  if (collaboration.strategicQuestion) return colors.purple30;

  if (collaboration.patientFlowBlock) return colors.black05;

  if (collaboration.postItCard) {
    return getCardColor(collaboration.postItCard.type);
  }

  if (collaboration.observation) {
    return getStakeholderColor(collaboration.observation.stakeholder);
  }

  if (collaboration.patientJourneyBlock) {
    return getBlockBG(collaboration.patientJourneyBlock.type);
  }

  return colors.black05;
}

interface Props {
  collaborationId: number;
  setCollaboration: any;
}

const CollaborationContent: React.FC<Props> = ({
  collaborationId,
  setCollaboration,
}) => {
  const isDesktop = useDesktop();
  const collaborationQueryVars = {
    variables: {
      id: collaborationId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  } as Parameters<typeof useCollaborationQuery>[0];

  const {
    data,
    loading,
    error,
    startPolling,
    stopPolling,
  } = useCollaborationQuery(collaborationQueryVars);

  // Enable scroll on html when request errors
  useEffect(() => {
    const htmlNode = document.getElementsByTagName('html')[0];
    if (!htmlNode) return;
    if (error) {
      htmlNode.style['overflow'] = 'auto';
    }
  }, [error]);

  useEffect(() => {
    startPolling(polling.default);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const stageRef = useRef(null);
  const contentRef = useRef(null);
  const [width, setWidth] = useState(300);

  useEffect(() => {
    if (contentRef.current) {
      // @ts-ignore
      const width = contentRef?.current?.offsetWidth;
      setWidth(width);
    }
  }, [contentRef]);

  const collaboration = data?.collaboration;
  const alignments = data?.collaboration?.alignments;
  const attachments = data?.collaboration?.attachments;

  const strategicQuestionTextValue =
    collaboration?.strategicQuestion?.text ||
    collaboration?.strategy?.[
      collaboration?.strategicQuestion?.field as
        | 'strategicQuestion'
        | 'winningAspiration'
        | 'strategicQuestion'
    ];

  if (!loading && (!collaboration || error)) {
    return (
      <ErrorModal
        title="Contribution no longer exists"
        text="The contribution you are looking for has been deleted."
        handleClose={() => setCollaboration()}
        visible
      />
    );
  }

  return (
    <ModalLarge
      handleClose={() => {
        setCollaboration();
      }}
      visible
      headerColor={getHeaderColor(collaboration as Collaboration)}
      portalId="#collaboration"
      className="collaboration-modal"
    >
      {!loading && collaboration ? (
        <Content>
          <LeftColumn>
            <Title>
              {collaboration?.title ? (
                <Subtitle1>{collaboration?.title}</Subtitle1>
              ) : null}
              {collaboration?.subtitle ? (
                <BodySmall style={{ marginTop: 6 }} color={colors.greyDark}>
                  {collaboration?.subtitle}
                </BodySmall>
              ) : null}
            </Title>

            <Preview ref={contentRef}>
              <ChildContent noAddedBy={!!collaboration.keyStatement}>
                {collaboration?.postItCard ? (
                  <>
                    <CenterContent>
                      <PostItCard
                        // @ts-ignore
                        card={collaboration.postItCard}
                        groupId={0}
                        userId={999}
                        updateCard={() => {}}
                        removeCard={() => {}}
                        dragDisabled
                        onBlur={() => {}}
                        onFocus={() => {}}
                        onMouseDown={() => {}}
                        disableCollaboration
                        errMsg={{ id: -1, message: '' }}
                        uploading={false}
                        editingDisabled
                      />
                    </CenterContent>
                    <CreatorDetails user={collaboration?.postItCard.user} />
                  </>
                ) : null}
                {collaboration?.keyInsight ? (
                  <>
                    <Insight
                      // @ts-ignore
                      setPreviewHasText={true}
                      setRemovingFromInsight={() => {}}
                      isNewItem={false}
                      enableAddObservations={true}
                      showAddObservations={true}
                      setShowAddObservations={() => {}}
                      keyInsightsQueryVars={{}}
                      observationsData={[]}
                      insightData={collaboration?.keyInsight}
                      preview={false}
                      id={collaboration?.keyInsight?.id}
                      // @ts-ignore
                      createKeyInsight={() => {}}
                      // @ts-ignore
                      deleteKeyInsight={() => {}}
                      // @ts-ignore
                      updateKeyInsight={() => {}}
                      // @ts-ignore
                      deletePreviewInsight={() => {}}
                      setObservationsPaneFilterData={() => {}}
                      key={collaboration?.keyInsight?.id}
                      disableCollaboration
                      readonly
                    />
                    <CreatorDetails user={collaboration?.keyInsight.user} />
                  </>
                ) : null}
                {collaboration?.observation ? (
                  <>
                    <Observation readonly data={collaboration.observation} />
                    <CreatorDetails user={collaboration?.observation.user} />
                  </>
                ) : null}
                {collaboration?.patientJourneyBlock ? (
                  <>
                    <CenterContent>
                      <BlockCardPreview
                        isCollaborationPreview
                        block={collaboration?.patientJourneyBlock}
                        readOnly
                      />
                    </CenterContent>
                    <CreatorDetails
                      user={collaboration?.patientJourneyBlock.user}
                    />
                  </>
                ) : null}
                {collaboration?.strategy && collaboration?.strategicQuestion ? (
                  <StrategyDefinitionHeader
                    disabled
                    type={collaboration.strategicQuestion.type}
                    hideTooltip
                    isLead={false}
                    title={collaboration.strategicQuestion.title}
                    bulletPoints={
                      collaboration.strategicQuestion.type === 'list'
                        ? {
                            type: StrategicQuestionType.List,
                            bulletPoints:
                              collaboration.strategicQuestion.list || [],
                          }
                        : {
                            type: StrategicQuestionType.MultiList,
                            bulletPoints:
                              collaboration.strategicQuestion.lists || [],
                          }
                    }
                    textValue={strategicQuestionTextValue || ''}
                    style={{ width: '100%', margin: 0 }}
                    handleSave={async () => {}}
                  />
                ) : null}
                {collaboration?.patientFlowBlock ? (
                  <>
                    <CenterContent>
                      <Stage
                        ref={stageRef}
                        width={width}
                        height={
                          collaboration.patientFlowBlock?.height + 20 || 300
                        }
                      >
                        <Layer>
                          <PatientFlowBlock
                            canEditValue={false}
                            isLead={false}
                            // @ts-ignore
                            block={{
                              ...collaboration.patientFlowBlock,
                              depth: 0,
                              left: 0,
                              children: [],
                            }}
                            x={width / 2 - 160 / 2}
                            y={20}
                            column={{
                              id: 0,
                              strategyId: 0,
                              drugId: 0,
                              name: '',
                              idx: 0,
                              width: 0,
                              defaultName: '',
                              lastUpdated: '',
                              createdAt: '',
                            }}
                            patientFlowValue={{
                              id: 0,
                              companyId: 0,
                              drugId: 0,
                              strategyId: 0,
                              columnId: 0,
                              userId: 0,
                              lastUpdated: '',
                              createdAt: '',
                              region: '',
                              value: 0,
                              patientFlowBlockId: 0,
                            }}
                            setEditing={() => {}}
                            sumOfChildrenValues={0}
                            parentBlockValue={0}
                            forceEdit={false}
                            nextStageId={0}
                            changeCursor={() => {}}
                            stageRef={stageRef}
                            patientFlowBlockCreate={() => Promise.resolve()}
                            patientFlowBlockUpdate={() => {}}
                            setConfirmDeleteModalId={() => {}}
                            patientFlowBlockDelete={() => {}}
                            upsertPatientFlowValue={async () => {}}
                            showPercentage={false}
                            onDistributionClick={() => {}}
                            country={'global'}
                            globalLayoutCompleted={false}
                            currentCountryIsComplete={false}
                            topStage={{
                              id: 0,
                              strategyId: 0,
                              drugId: 0,
                              name: '',
                              idx: 0,
                              width: 0,
                              defaultName: '',
                              lastUpdated: '',
                              createdAt: '',
                            }}
                            bottomStage={{
                              id: 0,
                              strategyId: 0,
                              drugId: 0,
                              name: '',
                              idx: 0,
                              width: 0,
                              defaultName: '',
                              lastUpdated: '',
                              createdAt: '',
                            }}
                            handleBlockMouseEnter={() => {}}
                            handleBlockMouseLeave={() => {}}
                            blockHover={false}
                            disableCollaboration
                          />
                        </Layer>
                      </Stage>
                    </CenterContent>
                    <CreatorDetails
                      user={collaboration?.patientFlowBlock.user}
                    />
                  </>
                ) : null}
                {collaboration?.competitiveLandscapeOpportunity ? (
                  <>
                    <CenterContent>
                      <CompetitiveLandscapeOpportunityCollaboration>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                          }}
                        >
                          {collaboration.competitiveLandscapeOpportunity
                            .approved ? (
                            <div style={{ display: 'flex' }}>
                              <Icon
                                name="GradientTick"
                                size={20}
                                style={{ marginRight: '5px' }}
                              />

                              <BodySmall>Opportunity</BodySmall>
                            </div>
                          ) : (
                            <div></div>
                          )}
                          {!!collaboration.competitiveLandscapeOpportunity
                            .postItGroup?.competitiveLandscapeRating && (
                            <ScorePill
                              value={average(
                                collaboration.competitiveLandscapeOpportunity.postItGroup?.competitiveLandscapeRating
                                  ?.slice()
                                  .filter(
                                    (rating) =>
                                      rating?.stakeholderDefinitionId ===
                                      collaboration
                                        .competitiveLandscapeOpportunity
                                        ?.stakeholderDefinitionId
                                  )
                                  .map((val) => val?.score || 0)
                              )}
                            />
                          )}
                        </div>
                        <div
                          style={{
                            height: '75px',
                            width: '240px',
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Subtitle2>
                            {collaboration?.competitiveLandscapeOpportunity
                              ?.postItGroup?.title || ''}
                          </Subtitle2>
                        </div>
                      </CompetitiveLandscapeOpportunityCollaboration>
                    </CenterContent>
                    <CreatorDetails
                      user={
                        collaboration?.competitiveLandscapeOpportunity
                          ?.postItGroup?.user
                      }
                    />
                  </>
                ) : null}
                {collaboration?.endpointTarget ? (
                  <>
                    <CenterContent>
                      <EndpointTarget
                        endpointTarget={collaboration.endpointTarget}
                      />
                    </CenterContent>
                    <CreatorDetails user={collaboration?.endpointTarget.user} />
                  </>
                ) : null}
                {collaboration?.accessStrategyEndpointTarget ? (
                  <>
                    <CenterContent>
                      <EndpointTarget
                        endpointTarget={
                          collaboration.accessStrategyEndpointTarget
                        }
                      />
                    </CenterContent>
                    <CreatorDetails
                      user={collaboration?.accessStrategyEndpointTarget.user}
                    />
                  </>
                ) : null}

                {collaboration?.strategicPossibility ? (
                  <>
                    <CenterContent>
                      <StrategicPossibilityCollaboration>
                        {collaboration.strategicPossibility.imperative && (
                          <div style={{ display: 'flex', marginTop: 5 }}>
                            <Icon
                              name="GradientTick"
                              size={20}
                              style={{ marginRight: '4px' }}
                            />
                            <BodySmall>Focus</BodySmall>
                          </div>
                        )}

                        <StrategicPossibilityData
                          title="Behaviour to drive"
                          text={
                            collaboration.strategicPossibility.behaviourToDrive
                          }
                        />

                        <div>
                          <BodySmall
                            style={{ marginBottom: '2.5px' }}
                            color={colors.greyDark}
                          >
                            Key Stakeholder
                          </BodySmall>

                          {collaboration.strategicPossibility.keyStakeholder ||
                          collaboration.strategicPossibility.stakeholder ? (
                            <ImageAndTitle
                              stakeholder={
                                collaboration.strategicPossibility
                                  .stakeholder || undefined
                              }
                              title={
                                collaboration.strategicPossibility
                                  .keyStakeholder?.title ||
                                collaboration.strategicPossibility
                                  .stakeholder ||
                                'Choose key stakeholder'
                              }
                              imageUrl={
                                collaboration.strategicPossibility
                                  .keyStakeholder?.image
                              }
                            />
                          ) : (
                            <BodySmall
                              color={colors.greyDark}
                              style={{ fontSize: '13px' }}
                            >
                              None identified yet
                            </BodySmall>
                          )}
                        </div>

                        <StrategicPossibilityData
                          title="Unlocking insight"
                          text={
                            collaboration.strategicPossibility.unlockingInsight
                              ?.text
                          }
                        />
                        <StrategicPossibilityData
                          title="Driver of the behaviour"
                          text={
                            collaboration.strategicPossibility
                              .driverOfTheBehaviour
                          }
                        />
                      </StrategicPossibilityCollaboration>
                    </CenterContent>
                    <CreatorDetails
                      user={collaboration?.strategicPossibility.user}
                    />
                  </>
                ) : null}
                {collaboration?.successCondition ? (
                  <>
                    <SuccessConditionPreview
                      successCondition={collaboration.successCondition}
                    />
                    <CreatorDetails
                      user={collaboration?.successCondition.user}
                    />
                  </>
                ) : null}
                {collaboration?.statement ? (
                  <StyledStatementV2 title={collaboration?.statement.title}>
                    {getFullStatement(
                      collaboration?.statement.title,
                      collaboration?.statement.text,
                      collaboration.strategy?.drug?.name || ''
                    )}
                  </StyledStatementV2>
                ) : null}
                {collaboration?.positioningWhoRows ? (
                  <div
                    style={{
                      width: '100%',
                    }}
                  >
                    <CollaborationContainer
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 15,
                      }}
                    >
                      <StrategicPossibilityData
                        title="Brand Target"
                        text={collaboration.positioningWhoRows.brandTarget}
                      />

                      <StrategicPossibilityData
                        title="Motivated to..."
                        text={collaboration.positioningWhoRows.motivation}
                      />
                    </CollaborationContainer>
                    <CreatorDetails
                      user={collaboration.positioningWhoRows.user}
                    />
                  </div>
                ) : null}
                {collaboration.suggestionRow ? (
                  <div
                    style={{
                      background: colors.white,
                      borderRadius: 5,
                      padding: 15,
                      width: '-webkit-fill-available',
                      margin: -5,
                    }}
                  >
                    <BodySmall
                      color={colors.greyDark}
                      style={{ marginBottom: 5 }}
                    >
                      Suggestion
                    </BodySmall>
                    <BodySmall>{collaboration.suggestionRow.text}</BodySmall>
                  </div>
                ) : null}
                {collaboration.bigIdea ? (
                  <>
                    <CenterContent>
                      <BigIdea bigIdea={collaboration.bigIdea} />
                    </CenterContent>
                    <CreatorDetails user={collaboration?.bigIdea.user} />
                  </>
                ) : null}
                {collaboration.bigIdeaDetail ? (
                  <>
                    <CenterContent>
                      <BigIdeaDetail
                        data={collaboration.bigIdeaDetail}
                        currency={collaboration?.strategy?.currency || '$'}
                      />
                    </CenterContent>
                    <CreatorDetails user={collaboration?.bigIdeaDetail.user} />
                  </>
                ) : null}
                {collaboration.commercialTacticDetail ? (
                  <>
                    <CenterContent>
                      <CommercialTacticDetail
                        commercialTacticDetail={
                          collaboration.commercialTacticDetail
                        }
                        currency={collaboration?.strategy?.currency || '$'}
                      />
                    </CenterContent>
                    <CreatorDetails
                      user={collaboration?.commercialTacticDetail?.user}
                    />
                  </>
                ) : null}
                {collaboration.futureTrend ? (
                  <>
                    <FutureTrend trend={collaboration.futureTrend} />
                    <CreatorDetails
                      user={collaboration.futureTrend?.postItCard?.user}
                    />
                  </>
                ) : null}
                {!!collaboration?.tactic && (
                  <CollaborationMedicalTactic
                    {...collaboration?.tactic}
                    currency={collaboration?.strategy?.currency}
                  />
                )}
                {!!collaboration?.criticalMetricGoal && (
                  <CollaborationCriticalMetricsGoal
                    {...collaboration?.criticalMetricGoal}
                  />
                )}
                {!!collaboration?.criticalMetricTrackedGoal && (
                  <CollaborationCriticalMetricsTrackedGoal
                    {...collaboration?.criticalMetricTrackedGoal}
                  />
                )}
                {!!collaboration?.solution && (
                  <>
                    <CenterContent>
                      <Solution
                        {...collaboration?.solution}
                        currency={collaboration?.strategy?.currency}
                      />
                    </CenterContent>
                    <CreatorDetails user={collaboration?.solution.user} />
                  </>
                )}
                {collaboration?.commercialTactic && (
                  <>
                    <CenterContent>
                      <div
                        style={{
                          background: colors.white,
                          borderRadius: 5,
                          padding: '20px 10px',
                          width: '-webkit-fill-available',
                        }}
                      >
                        {!!collaboration.commercialTactic.focused && (
                          <div style={{ display: 'flex', marginBottom: 20 }}>
                            <Icon
                              name="GradientTick"
                              size={20}
                              style={{ marginRight: '4px' }}
                            />
                            <BodySmall>Focus</BodySmall>
                          </div>
                        )}

                        <BodySmall
                          color={colors.greyDark}
                          style={{ marginBottom: 5 }}
                        >
                          Commercial Tactic
                        </BodySmall>
                        <BodySmall>
                          {collaboration.commercialTactic.text}
                        </BodySmall>
                      </div>
                    </CenterContent>
                    <CreatorDetails
                      user={collaboration?.commercialTactic.user}
                    />
                  </>
                )}
                {!!collaboration?.longTermSolutionDetail && (
                  <BigIdeaDetail
                    data={{
                      ...collaboration.longTermSolutionDetail,
                      audience:
                        collaboration.longTermSolutionDetail.actionsRequired,
                      text:
                        collaboration.longTermSolutionDetail.longTermSolution
                          .text,
                    }}
                    currency={collaboration?.strategy?.currency || '$'}
                    heading="Solution"
                    audienceHeading="Actions required"
                  />
                )}
                {!!collaboration.accessStrategyPrioritiseSupportingMessage && (
                  <>
                    <div
                      style={{
                        background: colors.white,
                        borderRadius: 5,
                        padding: '20px 10px',
                        width: '-webkit-fill-available',
                        margin: -5,
                      }}
                    >
                      <BodySmall
                        color={colors.greyDark}
                        style={{ marginBottom: 5 }}
                      >
                        {startCase(
                          collaboration
                            .accessStrategyPrioritiseSupportingMessage
                            .keyStatement.subStep as string
                        )}
                      </BodySmall>
                      <BodySmall>
                        {
                          collaboration
                            .accessStrategyPrioritiseSupportingMessage
                            .keyStatement.text
                        }
                      </BodySmall>
                    </div>
                    <CreatorDetails
                      user={
                        collaboration?.accessStrategyPrioritiseSupportingMessage
                          .user
                      }
                    />
                  </>
                )}

                {!!collaboration?.competitiveAdvantageRow && (
                  <>
                    <CompAdvantageRow
                      data={collaboration?.competitiveAdvantageRow}
                    />
                    <CreatorDetails
                      user={
                        collaboration?.competitiveAdvantageRow
                          .strategicPossibility?.user
                      }
                    />
                  </>
                )}
                {!!collaboration?.longTermSolution && (
                  <>
                    <LongTermSolution data={collaboration?.longTermSolution} />
                    <CreatorDetails
                      user={collaboration?.longTermSolution?.user}
                    />
                  </>
                )}
                {!!collaboration?.keyStatement && (
                  <div
                    style={{
                      background: colors.white,
                      borderRadius: 5,
                      padding: '20px 10px',
                      width: '-webkit-fill-available',
                      margin: -5,
                    }}
                  >
                    {!!collaboration.keyStatement.focus && (
                      <div style={{ display: 'flex', marginBottom: 20 }}>
                        <Icon
                          name="GradientTick"
                          size={20}
                          style={{ marginRight: '4px' }}
                        />
                        <BodySmall>Focus</BodySmall>
                      </div>
                    )}
                    <Caption
                      color={colors.greyDark}
                      style={{ marginBottom: 2 }}
                    >
                      {startCase(collaboration.keyStatement.subStep || '')}
                    </Caption>
                    <BodyNormal>{collaboration.keyStatement.text}</BodyNormal>

                    <RatingBars>
                      <RatingBar
                        title="Importance (Average)"
                        percentageFilled={
                          calculateAverageMessageRatings(
                            collaboration.keyStatement
                              .accessStrategyPrioritiseSupportingMessages
                          ).averageScoreImportance
                        }
                      />
                      <RatingBar
                        title="Data Robustness (Average)"
                        percentageFilled={
                          calculateAverageMessageRatings(
                            collaboration.keyStatement
                              .accessStrategyPrioritiseSupportingMessages
                          ).averageScoreDataRobustness
                        }
                      />
                    </RatingBars>
                  </div>
                )}

                {!!collaboration?.supportingMessageTactic && (
                  <>
                    <CenterContent>
                      <SupportingMessageTactic
                        {...collaboration?.supportingMessageTactic}
                        currency={collaboration?.strategy?.currency}
                      />
                    </CenterContent>
                    <CreatorDetails
                      user={collaboration?.supportingMessageTactic?.user}
                    />
                  </>
                )}

                {!!collaboration?.picoSuggestion && (
                  <CollaborationPicoSuggestion
                    picoSuggestion={collaboration.picoSuggestion}
                  />
                )}
              </ChildContent>
            </Preview>

            {!isDesktop ? (
              <>
                <StyledAlignments
                  data={alignments}
                  collaborationId={collaboration?.id}
                />

                <StyledAttachments
                  data={attachments}
                  collaborationId={collaboration?.id}
                ></StyledAttachments>
              </>
            ) : null}

            <StyledComments
              collaboration={collaboration as Collaboration}
              queryVars={collaborationQueryVars as Omit<QueryOptions, 'query'>}
            />
          </LeftColumn>
          <RightColumn>
            {isDesktop ? (
              <>
                <StyledAttachments
                  data={attachments}
                  collaborationId={collaboration?.id}
                ></StyledAttachments>

                <StyledAlignments
                  data={alignments}
                  collaborationId={collaboration?.id}
                />
              </>
            ) : null}
          </RightColumn>
        </Content>
      ) : (
        <Loading style={{ marginTop: 200 }} />
      )}
    </ModalLarge>
  );
};

export type RefetchCollaborationType = () => Promise<
  ApolloQueryResult<CollaborationQuery>
>;

const StrategicPossibilityData: React.FC<{
  title: string;
  text?: string | null;
}> = ({ title, text }) => (
  <div>
    <BodySmall style={{ marginBottom: 5 }} color={colors.greyDark}>
      {title}
    </BodySmall>
    {text ? (
      <BodySmall>{text}</BodySmall>
    ) : (
      <BodySmall color={colors.greyDark} style={{ fontSize: '13px' }}>
        None identified yet
      </BodySmall>
    )}
  </div>
);

export function FieldAndText({
  title,
  text,
}: {
  title: string;
  text: string | undefined | null;
}) {
  return (
    <div>
      <BodySmall color={colors.greyDark}>{title}</BodySmall>
      <BodySmall>{text || 'Not identified yet'}</BodySmall>
    </div>
  );
}

export function CreatorDetails({
  user,
}: {
  user?: Pick<User, 'country' | 'role' | 'name' | 'title'> | null;
}) {
  if (!user) return null;
  return (
    <AddedByFooter>
      <BodySmall color={colors.greyDark}>Added by</BodySmall>
      <CountryFlag size="sm" user={user} disableTooltip />

      <BodySmall>{getUserTitleOrCountryAndName(user)}</BodySmall>
    </AddedByFooter>
  );
}
