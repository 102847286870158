import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { Subtitle2 } from 'components/shared';

const objectiveHeaderMarginLeft = 15;

const Header = styled.div`
  margin-bottom: 20px;
  margin-left: ${objectiveHeaderMarginLeft}px;
  max-width: 640px;

  @media ${device.tabletMax} {
    margin-bottom: 5px;
  }
`;

export const MedicalPicoTacticTableHeader = () => (
  <Header>
    <Subtitle2>PICO</Subtitle2>
  </Header>
);
