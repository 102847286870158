import { Table } from 'components/shared';
import { TableDivHeaders } from 'components/shared/Table';
import { colors } from 'constants/index';
import {
  ArchetypeFragment,
  SupportingMessageTacticFragment,
  useSupportingMessageTacticCreateMutation,
} from 'data/graphql/generated';
import { ReactElement, useState } from 'react';
import styled from 'styled-components/macro';
import { AccessStrategyRowData } from 'types';
import { device } from 'utils/breakpoints';
import {
  AccessStrategyTacticsRow,
  accessStrategyTacticsRowColumns,
} from './AccessStrategyTacticsRow';

const StyledTable = styled(Table)<{ isActive: boolean }>`
  order: 3;

  ${TableDivHeaders} {
    ${accessStrategyTacticsRowColumns}
  }

  border-color: ${({ isActive }) =>
    isActive ? colors.purple : colors.black10a};

  @media ${device.desktopMin} {
    min-width: fit-content;
  }
  @media ${device.tabletMax} {
    padding: 15px;
  }
` as typeof Table;

type Props = {
  currency: string;
  archetype?: ArchetypeFragment;
  includesUserCountry?: boolean;
  isLead: boolean;
  isGlobalContributor: boolean;
  isCountryContributor: boolean;
  isDesktop: boolean;
  handleUpdateRow: ({
    id,
    data,
    initialData,
  }: {
    id: number;
    data: AccessStrategyRowData;
    initialData: SupportingMessageTacticFragment;
  }) => Promise<void>;
  data: SupportingMessageTacticFragment[];
  handleCreateRow: (
    archetypeId?: number | undefined
  ) => ReturnType<
    ReturnType<typeof useSupportingMessageTacticCreateMutation>[0]
  >;
  header: ReactElement;
  handleDeleteRow: (initialData: SupportingMessageTacticFragment) => void;
  isActive?: boolean;
};

export function AccessStrategyTacticsTable({
  archetype,
  includesUserCountry,
  isLead,
  isGlobalContributor,
  isCountryContributor,
  isDesktop,
  handleUpdateRow,
  data,
  currency,
  handleCreateRow,
  header,
  handleDeleteRow,
  isActive,
}: Props): JSX.Element {
  const [isCreating, setIsCreating] = useState(false);
  const [autoFocusId, setAutoFocusId] = useState<undefined | number>(undefined);

  return (
    <StyledTable
      // Not too sure how to get styled to recognise additional props for this component yet
      //@ts-ignore
      isActive={!!isActive}
      showTopMobileDivider
      key={archetype?.id || ''}
      style={{
        order: !!archetype ? (includesUserCountry ? 0 : 3) : isLead ? 0 : 2,
      }}
      isDesktop={isDesktop}
      useDiv
      header={header}
      cols={['Access initiative', 'Importance', 'Timing']}
      rows={(data) => {
        return (
          <AccessStrategyTacticsRow
            // The cache is updated before the state changes
            // This means the component is rendered before focusId has the value of the new row
            // So to make it autofocus, which only seems to happen on mount, we need force the component to rerender
            key={(data.id === autoFocusId) + ''}
            autoFocus={data.id === autoFocusId}
            isLead={isLead}
            isGlobalContributor={isGlobalContributor}
            isCountryContributor={isCountryContributor}
            canEdit={!!(isLead || includesUserCountry)}
            data={data}
            isDesktop={isDesktop}
            onUpdate={(updatedData) => {
              handleUpdateRow({
                id: data.id,
                data: updatedData as AccessStrategyRowData,
                initialData: data,
              });
            }}
            currency={currency}
            onDelete={() => handleDeleteRow(data)}
          />
        );
      }}
      data={data.filter((row) =>
        archetype ? row.archetypeId === archetype.id : !row.archetypeId
      )}
      emptyStateText={{
        header: 'No Access initiatives yet',
        subHeading: undefined,
      }}
      loading={isCreating}
      addButtonText={
        includesUserCountry || isLead ? 'Add Access initiative' : ''
      }
      addRow={async () => {
        setIsCreating(true);
        const newRow = await handleCreateRow(archetype?.id || undefined);
        setAutoFocusId(newRow.data?.supportingMessageTacticCreate.id);
        setIsCreating(false);
      }}
    />
  );
}
