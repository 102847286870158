import { useState, useMemo, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import {
  CriticalMetricGoalInput,
  useCreateCriticalMetricGoalTrackingMutation,
  useStrategyCountriesQuery,
  useGetGoalTrackingLazyQuery,
  useUpdateCriticalMetricGoalTrackingMutation,
} from 'data/graphql/generated';
import { Country, countryArr, CountryConstants } from 'types';
import { useAuthContext } from 'contexts/AuthContext';
import { verifyUserRole } from 'utils/verifyUserRole';
import { CriticalMetricsParams } from 'components/3-5-critical-metrics/shared/shared-data-access-critical-metrics/src';
import { ModalForm } from 'components/ModalForm';
import FormTextInput from 'components/shared/FormTextInput';
import { ButtonPill, TooltipWrapper } from 'components/shared/ButtonPill';
import FormCountryDropdown from 'components/shared/FormCountryDropdown';

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;

  ${TooltipWrapper} {
    width: 100%;
  }
`;

export const NumberInput = styled(FormTextInput)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
`;

interface AddGoalTrackingModalProps {
  trackedGoalId: string;
  period: string;
  periodDate: Date;
  periodOffset: number;
  input: CriticalMetricGoalInput;
  isOpen: boolean;
  onClose: () => void;
}

export const AddGoalTrackingModal: React.FC<AddGoalTrackingModalProps> = ({
  trackedGoalId,
  isOpen,
  period,
  periodDate,
  periodOffset,
  input,
  onClose,
}) => {
  const {
    strategyId,
    competitiveAdvantageRowUrlParam,
  } = useParams<CriticalMetricsParams>();
  const [{ user }] = useAuthContext();
  const { isLead, isContributor } = verifyUserRole(user?.role, user?.country);
  const [getGoalTracking, { data }] = useGetGoalTrackingLazyQuery();
  const [create] = useCreateCriticalMetricGoalTrackingMutation();
  const [update] = useUpdateCriticalMetricGoalTrackingMutation();
  const { data: getSupportedCountriesQuery } = useStrategyCountriesQuery({
    variables: { id: +strategyId },
  });
  const [isDropdownActive, setIsDropdownActive] = useState<boolean>(false);
  const [percent, setPercent] = useState<number | null>(null);

  const [region, setRegion] = useState<Country>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const countries = useMemo<Country[]>(() => {
    const supportedCountries =
      getSupportedCountriesQuery?.strategyCountries?.countries;

    if (supportedCountries) {
      return countryArr.filter((x) => supportedCountries.includes(x));
    }

    return [...countryArr] as Country[];
  }, [getSupportedCountriesQuery]);

  const existingGoalTrackingId = useMemo<number | undefined>(() => {
    if (!data?.criticalMetricGoalTracking?.id) {
      setPercent(null);
      return undefined;
    }

    setPercent(+data.criticalMetricGoalTracking.percent);

    return +data.criticalMetricGoalTracking.id;
  }, [
    data?.criticalMetricGoalTracking?.id,
    data?.criticalMetricGoalTracking?.percent,
  ]);

  const handleCountryChange = useCallback(
    (country: Country) => {
      setRegion(country);
      getGoalTracking({
        variables: {
          where: {
            strategyId: +strategyId,
            trackedGoalId: +trackedGoalId,
            region: country,
            periodOffset: periodOffset,
          },
        },
      });
    },
    [getGoalTracking, periodOffset, strategyId, trackedGoalId]
  );

  const handleClose = () => onClose();
  const handleSave = async () => {
    if (existingGoalTrackingId) {
      if (percent !== null && region) {
        setIsLoading(true);
        await update({
          variables: {
            id: existingGoalTrackingId,
            data: {
              strategyId: +strategyId,
              percent: percent,
              period: periodDate.toISOString(),
              region: region as string,
            },
          },
        });
        onClose();
        setIsLoading(false);
      }
    } else {
      if (percent !== null && region) {
        setIsLoading(true);
        await create({
          variables: {
            data: {
              trackedGoalId: +trackedGoalId,
              strategyId: +strategyId,
              competitiveAdvantageRowId: +competitiveAdvantageRowUrlParam,
              percent: percent,
              period: periodDate.toISOString(),
              region: region as string,
            },
          },
          refetchQueries: ['getGoalTrackings'],
        });
        onClose();
        setIsLoading(false);
      }
    }
  };

  const isSubmitDisabled = useMemo(() => {
    if (isLead) {
      return percent === null || !region;
    }

    return percent === null || !region;
  }, [isLead, percent, region]);

  useEffect(() => {
    if (isLead && input === CriticalMetricGoalInput.GlobalOnly) {
      handleCountryChange(CountryConstants.GLOBAL as Country);
    }

    if (isContributor && user?.country) {
      handleCountryChange(user.country as Country);
    }
  }, [handleCountryChange, input, isContributor, isLead, user?.country]);

  if (!isOpen) {
    return null;
  }

  return (
    <ModalForm
      className="modal-add-tracking-form"
      visible={isOpen}
      handleClose={handleClose}
      heading={'Track goal'}
      message={period}
    >
      {isLead && input === CriticalMetricGoalInput.PerCountry && (
        <FormCountryDropdown
          title="Country"
          name="country"
          dropdownActive={isDropdownActive}
          setDropdownActive={setIsDropdownActive}
          onClick={() => setIsDropdownActive(!isDropdownActive)}
          selectedItem={region}
          setSelectedItem={handleCountryChange}
          placeholder="Choose country"
          options={countries}
        />
      )}

      <NumberInput
        onChange={(e) => {
          const val = parseInt(e.target.value);

          if (isNaN(val)) {
            setPercent(null);
            return;
          }

          if (val > 100) {
            setPercent(100);
            return;
          }

          if (val < 0) {
            setPercent(0);
            return;
          }

          setPercent(val);
        }}
        value={`${percent}`}
        name="percent"
        type="number"
        title="Percent"
        errorMessage={''}
        autocomplete="off"
        autoFocus
        icon="%"
      />

      <ButtonsContainer>
        <ButtonPill onClick={handleClose} text="Cancel" level="secondary" />

        <ButtonPill
          clickClassName="cypress-competitor-save"
          onClick={handleSave}
          text={'Save'}
          loading={isLoading}
          disabled={isSubmitDisabled}
        />
      </ButtonsContainer>
    </ModalForm>
  );
};

const AddGoalTrackingModalPortal = ({
  trackedGoalId,
  isOpen,
  period,
  input,
  periodDate,
  periodOffset,
  onClose,
}: AddGoalTrackingModalProps) =>
  isOpen
    ? ReactDOM.createPortal(
        <AddGoalTrackingModal
          isOpen={isOpen}
          trackedGoalId={trackedGoalId}
          period={period}
          periodDate={periodDate}
          periodOffset={periodOffset}
          input={input}
          onClose={onClose}
        />,
        document.body
      )
    : null;

export default AddGoalTrackingModalPortal;
