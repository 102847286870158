import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { PostItsEmpty } from 'components/PostItsEmpty';
import { BodySmall } from 'components/shared';
import { colors } from 'constants/colors';
import { polling } from 'constants/polling';
import {
  ArchetypeWhereInput,
  SubStep,
  Step,
  useArchetypesQuery,
  useKeyStatementsQuery,
  KeyStatementFragment,
} from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import { sortBy } from 'lodash';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import calculateAverageMessageRatings from 'utils/calculateAverageMessageRatings';
import AccessStrategyGap from './AccessStrategyGap';
import AccessStrategyNav from './AccessStrategyNav';
import AccessStrategyStepHeader from './AccessStrategyStepHeader';

const Wrapper = styled.div``;

const StyledEmptyState = styled(PostItsEmpty)`
  margin: 10px 0;
  border-radius: 0;

  @media ${device.tabletMin} {
    border-radius: 5px;
    margin: 10px;
  }

  @media ${device.desktopMin} {
    margin: 20px;
  }
`;

const GapsList = styled.ul`
  padding: 0;
  margin: 15px 0;

  display: flex;
  flex-direction: column;
  gap: 15px;

  @media ${device.tabletMin} {
    margin: 15px 20px;
  }
`;

interface URLParams {
  drugId: string;
  strategyId: string;
}

export const messsagesQueryVars = (strategyId: string) => ({
  where: {
    strategyId: Number(strategyId),
  },
});

export const AccessStrategyGaps = () => {
  const { strategyId, drugId }: URLParams = useParams();
  const isDesktop = useDesktop();

  // Fetch supporting messages
  const {
    data: supportingMessagesData,
    error: supportingMessagesError,
    loading: supportingMessagesLoading,
    startPolling: supportingMessagesStartPolling,
    stopPolling: supportingMessagesStopPolling,
  } = useKeyStatementsQuery({
    variables: {
      where: { strategyId: +strategyId, step: Step.ValueProposition },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  // Fetch archetypes
  const archetypesQueryVars: Record<'where', ArchetypeWhereInput> = {
    where: { strategyId: Number(strategyId) },
  };

  const {
    data: archetypeData,
    loading: archetypeLoading,
    error: archetypeError,
    startPolling: archetypesStartPolling,
    stopPolling: archetypesStopPolling,
  } = useArchetypesQuery({
    variables: {
      ...archetypesQueryVars,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  useEffect(() => {
    supportingMessagesStartPolling(polling.default);
    archetypesStartPolling(polling.default);

    return () => {
      supportingMessagesStopPolling();
      archetypesStopPolling();
    };
  }, [
    supportingMessagesStartPolling,
    supportingMessagesStopPolling,
    archetypesStartPolling,
    archetypesStopPolling,
  ]);

  const archetypes = archetypeData?.archetypes?.items;
  const supportingMessages = supportingMessagesData?.keyStatements?.items;
  const dataMissing = !supportingMessagesData || !archetypeData;
  const dataLoading = supportingMessagesLoading || archetypeLoading;
  const showEmptyState = !supportingMessages?.length;

  const messagesSortedByImportance = sortBy(
    supportingMessages,
    (m) =>
      calculateAverageMessageRatings(
        m.accessStrategyPrioritiseSupportingMessages
      ).averageScoreImportance
  ).reverse();

  const messagesGroupedBySubStep = messagesSortedByImportance.reduce(
    (
      acc: {
        diseaseBurdenAndUnmetNeed: KeyStatementFragment[];
        clinicalValue: KeyStatementFragment[];
        humanisticValue: KeyStatementFragment[];
        economicValue: KeyStatementFragment[];
      },
      val
    ) => {
      if (val?.subStep) {
        // @ts-ignore
        acc[val.subStep].push(val);
      }
      return acc;
    },
    {
      diseaseBurdenAndUnmetNeed: [],
      clinicalValue: [],
      humanisticValue: [],
      economicValue: [],
    }
  );

  const messagesOrderedBySubStep = Object.values(
    messagesGroupedBySubStep
  ).flat();

  return (
    <>
      <AccessStrategyNav substep={SubStep.Gaps} />
      <Wrapper
        style={{
          marginTop: dataLoading ? (isDesktop ? '150px' : '200px') : '',
        }}
      >
        <ErrorWrapper
          isLoading={dataLoading}
          errors={[supportingMessagesError, archetypeError]}
          dataMissing={dataMissing}
        >
          <AccessStrategyStepHeader substep={SubStep.Gaps} />
          {showEmptyState && (
            <StyledEmptyState title={'No supporting messages'}>
              <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
                Leads should add supporting messages in{' '}
              </BodySmall>
              <Link
                to={`/d/${drugId}/strategy/${strategyId}/3_2`}
                style={{
                  color: colors.greyDark,
                  display: 'inline',
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                Core Value Messages
              </Link>
            </StyledEmptyState>
          )}

          <GapsList>
            {messagesOrderedBySubStep?.map((m) => {
              return (
                <AccessStrategyGap
                  key={m.id}
                  supportingMessage={m}
                  archetypes={archetypes || []}
                />
              );
            })}
          </GapsList>
        </ErrorWrapper>
      </Wrapper>
    </>
  );
};
