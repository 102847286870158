import { colors } from 'constants/index';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '.';

const EmptyStateWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.yellow20};
  padding: 15px;
  gap: 5px;
  margin: 0 auto;
`;

interface Props {
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
  includeIcon?: boolean;
}

export const EmptyState: React.FC<Props> = ({
  children,
  style,
  className,
  includeIcon = true,
}) => {
  return (
    <EmptyStateWrapper className={className} style={style}>
      {includeIcon && (
        <Icon
          name="GenericEmptyState"
          size={115}
          height={115}
          color="initial"
        />
      )}
      {children}
    </EmptyStateWrapper>
  );
};
