import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { BodyNormal, Caption, Collaboration } from 'components/shared';
import { colors } from 'constants/colors';
import {
  ArchetypeFragment,
  KeyStatementFragment,
  useKeyStatementUpdateMutation,
} from 'data/graphql/generated';
import { startCase } from 'lodash';
import { device } from 'utils/breakpoints';
import ArchetypeCard from './ArchetypeCard';
import RatingBar from '../shared/RatingBar';
import { archetypeCardScrollbar } from 'components/Strategy/scrollbar';
import calculateAverageMessageRatings from 'utils/calculateAverageMessageRatings';
import { accessStrategyGapWrapperPadding } from 'constants/index';
import TwoButtonModal from 'components/TwoButtonModal';
import FocusDropdown from 'components/shared/FocusDropdown';
import { RationaleModal } from 'components/shared/RationaleModal';
import useManageFocusRationale from 'hooks/useManageFocusRationale';

const BorderWrapper = styled.div<{ focused: boolean }>`
  @media ${device.tabletMin} {
    border-radius: 5px;
    padding: 1px;
    background: ${({ focused }) =>
      focused ? colors.purplePinkGradient : 'none'};
  }
`;

const Wrapper = styled.div<{ focused: boolean }>`
  position: relative;
  padding: ${accessStrategyGapWrapperPadding}px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      circle at bottom left,
      rgba(75, 126, 235, 1) 40%,
      rgba(83, 123, 234, 1) 0%,
      rgba(234, 78, 209, 1) 100%
    );

    opacity: 0.1;
    pointer-events: none;
    display: ${({ focused }) => !focused && 'none'};
  }

  display: flex;
  flex-direction: column;
  gap: 15px;

  background: ${colors.white};

  @media ${device.tabletMin} {
    border-radius: 4px;
  }
`;

const TopSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media ${device.tabletMin} {
    flex-direction: row;

    .FocusDropdownContainer {
      order: 1;
      align-self: flex-start;
    }
  }

  @media ${device.desktopMin} {
    max-width: 1284px;
  }
`;

const MainText = styled.div`
  @media ${device.tabletMin} {
    width: 100%;
  }

  @media (min-width: 1440px) {
    max-width: 440px;
  }
`;

const RatingBars = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 250px;
  height: fit-content;

  @media (min-width: 1440px) {
    flex-direction: row;
    width: unset;
  }
`;

const StyledCollaboration = styled(Collaboration)`
  align-self: flex-end;

  @media ${device.tabletMin} {
    align-self: flex-start;
  }
`;

const GreyDivider = styled.div`
  height: 1px;
  background: ${colors.greyMedium};
`;

const TextRatingsAndCollab = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media ${device.tabletMin} {
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
  }

  @media ${device.desktopMin} {
    flex-wrap: nowrap;
  }
`;

const RatingsAndCollab = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media ${device.desktopMin} {
    flex-direction: row;
  }
`;

const ArchetypeCards = styled.ul`
  display: flex;
  gap: 15px;
  overflow: auto;
  padding: 0 ${accessStrategyGapWrapperPadding}px;
  margin: 0 -${accessStrategyGapWrapperPadding}px;
  padding-bottom: 5px;

  ${archetypeCardScrollbar}
`;

interface Props {
  supportingMessage: KeyStatementFragment;
  archetypes: ArchetypeFragment[];
}

export default function AccessStrategyGap({
  supportingMessage,
  archetypes,
}: Props) {
  const [focused, setFocused] = useState(supportingMessage.focus);
  const [updateKeyStatement] = useKeyStatementUpdateMutation();
  const [showUnfocusWarningModal, setShowUnfocusWarningModal] = useState(false);

  // Focus rationale modal logic
  const {
    modalSlideIdx,
    setModalSlideIdx,
    setShowSingleSlide,
    closeModal,
  } = useManageFocusRationale();
  const openModal = () => setModalSlideIdx(1);

  const {
    averageScoreImportance,
    averageScoreDataRobustness,
  } = calculateAverageMessageRatings(
    supportingMessage.accessStrategyPrioritiseSupportingMessages
  );

  async function toggleFocus() {
    if (!focused) {
      openModal();
      return;
    }

    // Unfocusing
    if ((supportingMessage?.accessStrategyEndpointTargets?.length || 0) > 0) {
      setShowUnfocusWarningModal(true);
      return;
    }

    try {
      setFocused(false);
      await updateKeyStatement({
        variables: {
          data: { focus: false, text: supportingMessage.text },
          localUid: supportingMessage.localUid,
        },
      });
    } catch (error) {
      setFocused(true);
      alert('Something went wrong');
    }
  }

  async function handleModalSubmit(focusRationale: string) {
    setFocused(true);

    try {
      await updateKeyStatement({
        variables: {
          data: {
            focus: true,
            text: supportingMessage.text,
            focusRationale,
          },
          localUid: supportingMessage.localUid,
        },
      });
    } catch (error) {
      setFocused(false);

      alert('Something went wrong');
    }
  }

  return (
    <>
      <TwoButtonModal
        handleClose={() => setShowUnfocusWarningModal(false)}
        leftButtonLevel={'secondary'}
        leftButtonText={'Keep'}
        leftButtonOnClick={() => setShowUnfocusWarningModal(false)}
        rightButtonLevel={'danger'}
        rightButtonText={'Remove focus'}
        rightButtonOnClick={async () => {
          try {
            setFocused(false);

            await updateKeyStatement({
              variables: {
                data: { focus: false, text: supportingMessage.text },
                localUid: supportingMessage.localUid,
              },
            });
          } catch (error) {
            alert('Something went wrong');
          } finally {
            setShowUnfocusWarningModal(false);
          }
        }}
        modalOpen={showUnfocusWarningModal}
        title="You will lose content in later steps if you remove focus"
        body="There are contributions under this supporting message in later parts. Removing focus will also remove dependent content."
      />

      <RationaleModal
        modalSlideIdx={modalSlideIdx}
        handleClose={closeModal}
        handleSubmit={handleModalSubmit}
        rationaleText={supportingMessage.focusRationale}
        showSingleSlide={true}
        modalDetails={{
          'Disease burden and unmet need': supportingMessage.text || '',
        }}
      />

      <BorderWrapper focused={focused}>
        <Wrapper focused={focused}>
          <TopSection>
            <FocusDropdown
              onClick={toggleFocus}
              checked={focused}
              setModalSlideIdx={setModalSlideIdx}
              setShowSingleSlide={setShowSingleSlide}
              rationaleText={supportingMessage.focusRationale}
            />

            <TextRatingsAndCollab>
              <MainText>
                <Caption color={colors.greyDark} style={{ marginBottom: 2 }}>
                  {startCase(supportingMessage.subStep || '')}
                </Caption>
                <BodyNormal>{supportingMessage.text}</BodyNormal>
              </MainText>

              <RatingsAndCollab>
                <RatingBars>
                  <RatingBar
                    title="Importance (Average)"
                    percentageFilled={averageScoreImportance}
                  />
                  <RatingBar
                    title="Data Robustness (Average)"
                    percentageFilled={averageScoreDataRobustness}
                  />
                </RatingBars>

                <StyledCollaboration
                  collaboration={supportingMessage.collaboration}
                />
              </RatingsAndCollab>
            </TextRatingsAndCollab>
          </TopSection>

          <GreyDivider />

          <ArchetypeCards>
            {archetypes.map((a) => {
              if (a.archetypeCountries.length === 0) return null;

              const prioritiseMessage = supportingMessage.accessStrategyPrioritiseSupportingMessages.find(
                (m) => m.archetypeId === a.id
              );

              if (!prioritiseMessage) return null;

              return (
                <ArchetypeCard
                  key={a.id}
                  archetype={a}
                  prioritiseMessage={prioritiseMessage}
                />
              );
            })}
          </ArchetypeCards>
        </Wrapper>
      </BorderWrapper>
    </>
  );
}
