import { BodySmall, Caption, Icon } from 'components/shared';
import { colors } from 'constants/colors';
import React from 'react';
import styled from 'styled-components/macro';

interface Props {
  onClick?: () => void;
  className?: string;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 20px 5px 5px;
  border-radius: 30px;
  height: 40px;
  width: fit-content;
  position: relative;
  background: ${colors.purplePinkGradient};
  cursor: pointer;

  &::after {
    opacity: 0;
  }

  :hover {
    &::after {
      content: '';
      border-radius: 30px;
      transition: opacity 0.2s 0s;
      opacity: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: ${colors.black10};
      z-index: 1;
    }
  }
`;

const TextContainer = styled.div`
  margin-left: 10px;
  z-index: 2;
`;

const Text = styled(BodySmall)`
  color: ${colors.white};
  font-weight: 400;
`;

export const NotPublishedButton: React.FC<Props> = ({ onClick, className }) => {
  return (
    <Wrapper onClick={onClick} className={className}>
      <Icon
        name="More"
        style={{
          backgroundColor: colors.white,
          borderRadius: '50%',
          transform: 'rotate(90deg)',
          zIndex: 2,
        }}
        size={30}
        color={colors.purple}
      />

      <TextContainer>
        <Text>Not published</Text>
        <Caption color={colors.white}>Edit and publish strategy</Caption>
      </TextContainer>
    </Wrapper>
  );
};
