import React from 'react';
import { uid } from 'uid';
import { User } from 'data/graphql/generated';
import { CountryGlobalRegional } from 'types';
import { getUserTitleOrCountryAndName } from 'utils/getUserTitleOrCountryAndName';
import { verifyUserRole } from 'utils/verifyUserRole';
import { getCountryImageName } from '../utils/getCountryImageName';
import { Size } from '../types/Size';
import { CountryFlag } from '../ui/CountryFlag';

interface UserCountryFlagProps {
  user?: Pick<User, 'country' | 'role' | 'name' | 'title'>;
  size: Size;
  tooltip?: string;
  country?: CountryGlobalRegional;
  disableTooltip?: boolean;
  showLabel?: boolean;
  isLead?: boolean;
  tooltipId?: string;
}

export const UserCountryFlag: React.FC<UserCountryFlagProps> = ({
  user,
  size,
  tooltip,
  country,
  disableTooltip = false,
  showLabel,
  tooltipId = uid(),
  isLead: isLeadOverride,
}) => {
  const c = getCountryImageName(user?.country || country);
  const { isLead } = verifyUserRole(user?.role, user?.country);

  const userTitle = getUserTitleOrCountryAndName(user);

  return (
    <CountryFlag
      isLead={isLeadOverride || isLead}
      label={userTitle}
      tooltipId={tooltipId}
      tooltip={tooltip ? tooltip : `${userTitle}`}
      countryImage={c?.img}
      size={size}
      showLabel={showLabel}
      showTooltip={!disableTooltip}
    />
  );
};
