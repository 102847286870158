import {
  StrategicImperativePicoTacticsQueryHookResult,
  TacticFragment,
  useStrategicImperativePicoTacticsQuery,
} from 'data/graphql/generated';

export const useFetchPicoTactics = (strategyId: number) => {
  const { data, loading, error } = useStrategicImperativePicoTacticsQuery({
    variables: {
      where: { strategyId: strategyId },
      // We only really care about picoTactics if the pico suggestion has been focused
      picoSuggestionWhere: { picoSuggestionFocused: true },
    },
    fetchPolicy: 'cache-and-network',
  });
  const picoTactics = sanitizePicoTactic(data);

  return {
    isLoading: loading,
    error: error,
    tactics: picoTactics,
  };
};

const sanitizePicoTactic = (
  data: StrategicImperativePicoTacticsQueryHookResult['data']
) => {
  if (!data?.strategicImperatives?.items) {
    return [];
  }

  const picoSuggestions = data.strategicImperatives.items
    .map((item) => item.picoSuggestions.items)
    .flat();

  const tactics = (picoSuggestions ?? []).map((x) => {
    if (!x.tactic) {
      return undefined;
    }

    return x.tactic;
  });

  return tactics.filter((x) => x !== undefined) as TacticFragment[];
};
