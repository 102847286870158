import { CriticalMetricGoalInput } from 'data/graphql/generated';
import { colors } from 'constants/colors';
import { BodyNormal, Caption, Heading2 } from 'components/shared';
import { NoGoalTrackingAvailable } from './NoGoalTrackingAvailable';
import { ProgressDonut } from '../feature-progress-donut';

import {
  GoalTrackingCardHeader,
  GoalTrackingCardTargetSubtitle,
  GoalTrackingCardTitle,
  GoalTrackingCardProgressStatus,
  GoalTrackingCardDetails,
  GoalTrackingCardInputTrackingTitle,
  GoalTrackingCard,
} from '../TrackingGoals.styles';
import { getProgressStatus } from '../util-goal-tracking';

interface GoalTrackingSidebarProgressDonutProps {
  goal: string;
  target: number;
  period: string;
  progress?: number;
  input: CriticalMetricGoalInput;
  numberOfSupportedCountries: number;
  numberOfCountriesWithTracking?: number;
}

export const GoalTrackingSidebarProgressDonut = ({
  goal,
  target,
  input,
  period,
  progress,
  numberOfSupportedCountries,
  numberOfCountriesWithTracking = 0,
}: GoalTrackingSidebarProgressDonutProps) => {
  const isOnTrack: boolean =
    progress !== undefined ? progress >= target : false;
  const precisionProgress =
    progress !== undefined ? Number(progress.toFixed(0)) : undefined;
  const status = getProgressStatus(target, precisionProgress);
  const subtitle = `Target: ${target}%`;
  const trackingTitle =
    input === CriticalMetricGoalInput.GlobalOnly
      ? 'Global tracking only'
      : `${numberOfCountriesWithTracking} of ${numberOfSupportedCountries} tracked`;

  if (progress === undefined) {
    return (
      <NoGoalTrackingAvailable
        title={goal}
        subtitle={subtitle}
        trackingTitle={trackingTitle}
        period={period}
        target={target}
        isExpanded
      />
    );
  }

  return (
    <GoalTrackingCard isExpanded>
      <GoalTrackingCardHeader>
        <GoalTrackingCardTitle isExpanded>{goal}</GoalTrackingCardTitle>
        <GoalTrackingCardDetails>
          <GoalTrackingCardTargetSubtitle>
            {subtitle}
          </GoalTrackingCardTargetSubtitle>
          <GoalTrackingCardInputTrackingTitle>
            {trackingTitle}
          </GoalTrackingCardInputTrackingTitle>
        </GoalTrackingCardDetails>
      </GoalTrackingCardHeader>
      <ProgressDonut size={180} percentage={precisionProgress} target={target}>
        <Heading2>{precisionProgress}%</Heading2>
        <BodyNormal color={colors.greyDark}>{period}</BodyNormal>
        <GoalTrackingCardProgressStatus isOnTrack={isOnTrack}>
          <Caption color={colors.white}>{status}</Caption>
        </GoalTrackingCardProgressStatus>
      </ProgressDonut>
    </GoalTrackingCard>
  );
};
