import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Loading } from 'components/Loading';
import { BlurredBGImage, BodySmall, Subtitle2, Icon } from 'components/shared';
import {
  PostItCardFragment,
  PostItCardType,
  Sort,
  Step,
  SubStep,
  useCompetitiveAdvantageRowsQuery,
  usePostItCardsQuery,
  useStatementsQuery,
  useStrategicQuestionsQuery,
  useKeyStatementsQuery,
  useStrategyQuery,
} from 'data/graphql/generated';
import { DownloadButton } from './BubbleSummary';
import { polling } from '../../constants';
import { colors } from 'constants/colors';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { device } from 'utils/breakpoints';
import { BulletPoints } from './BulletPoints';
import { textScrollbar, summaryScrollbar } from './scrollbar';
import useMobile from 'hooks/useMobile';
import {
  ScrollableContent,
  ScrollableFadeContainer,
} from 'components/shared/ScrollableFadeContainer';
import exportSummaryThree from 'utils/export/summaryThree';

interface Props {
  strategyId: number;
}

const Card = styled.div`
  background: ${colors.white94};
  border: 1px solid ${colors.greyMedium};
  border-radius: 5px;
  padding: 0 15px;
  z-index: 1;
`;

const WinningAspirationCard = styled(Card)`
  overflow: hidden;

  @media ${device.tabletMin} {
    margin: 0 15px;
  }
`;

const WinningAspirationContent = styled.div<{ hasImage: boolean }>`
  width: 100%;
  max-width: ${({ hasImage }) => (hasImage ? '731px' : '648px')};
  display: flex;
  margin: auto;
  overflow: hidden;

  @media ${device.tabletMin} {
    max-height: 120px;
  }

  @media ${device.desktopMin} {
    max-height: 153px;
  }
`;

const WinningAspirationTextContent = styled.div`
  height: auto;
  width: 100%;
  overflow-y: auto;
  padding: 15px 8px 15px 0;
  word-break: break-word;
  ${textScrollbar}
`;

const BrandPositioningCard = styled(Card)`
  overflow: hidden;
  display: flex;

  @media ${device.tabletMin} {
    margin: 0 15px;
  }
`;

const BrandPositioningContent = styled.div<{
  hasImage: boolean;
  isMobile: boolean;
}>`
  margin: auto;
  padding-right: ${({ hasImage, isMobile }) => hasImage && !isMobile && '83px'};
  width: 100%;
`;

const BrandPositioningTextContainer = styled.div<{
  hasImage: boolean;
}>`
  max-width: 648px;
  margin: auto;
  overflow-y: auto;
  padding: 15px 8px 15px 0;
  word-break: break-word;
  ${textScrollbar}

  @media ${device.tabletMin} {
    max-height: 120px;
  }

  @media ${device.desktopMin} {
    max-height: 153px;
  }
`;

const DividerAndImage = styled.div`
  display: flex;
  padding: 15px 0;
`;

const ImageContainer = styled.div`
  border-radius: 5px;
  overflow: hidden;
  width: 70px;
  height: 70px;
`;

const Divider = styled.div`
  width: 1px;
  background: ${colors.greyMedium};
  margin: 0 10px 0 2px;
`;

const ImperativesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  ${Card} {
    padding: 5px;

    ${Card} {
      padding: 15px;
    }
  }

  @media ${device.tabletMin} {
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 5px;
    padding: 0 15px 5px 15px;
    gap: 15px;
    ${summaryScrollbar}
  }
`;

const Imperative = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: auto;

  ${ScrollableContent} {
    gap: 5px;
  }

  @media ${device.tabletMin} {
    max-width: 480px;
    width: 480px;
    min-width: 300px;
    height: fit-content;
    max-height: 100%;
    overflow: auto;
  }
`;

const ImperativesEmptyState = styled(Card)`
  padding: 15px 5px;
  text-align: center;

  @media ${device.tabletMin} {
    margin: 0 15px;
  }
`;

const StrategicObjectives = styled(Card)`
  padding: 15px;
`;

const IndicatorsContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const Indicators = styled(Card)`
  padding: 15px;
  width: 100%;
`;

const ImperativeDetails = styled.div`
  background: ${colors.purplePinkGradient};
  padding: 1px;
  border-radius: 5px;
`;

const Details = styled.div`
  background: ${colors.lightPinkGradient};
  border-radius: 4px;
  padding: 15px;
`;

const TextWrapper = styled.div<{ hasImage: boolean }>`
  max-width: ${({ hasImage }) => (hasImage ? '731px' : '648px')};
  width: 100%;
  margin: auto;
  opacity: 1;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 648px;
`;

const CardTitle = styled(BodySmall)`
  color: ${colors.greyDark};
`;

function generateBenefitsStatement(
  brandName?: string,
  functionalBenefit?: string,
  emotionalBenefit?: string
) {
  return brandName && functionalBenefit && emotionalBenefit
    ? `${brandName} ${functionalBenefit} so that ${emotionalBenefit}`
    : 'Not defined yet';
}

export const BubbleSummaryThreeContent: React.FC<Props> = ({ strategyId }) => {
  const isMobile = useMobile();

  // Get the winning aspiration
  const {
    data: strategyData,
    loading: strategyLoading,
    error: strategyError,
    startPolling,
    stopPolling,
  } = useStrategyQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: strategyId,
    },
  });

  // Get the brand positioning statements
  const {
    data: statementsData,
    loading: statementsLoading,
    startPolling: statementsStartPolling,
    stopPolling: statementsStopPolling,
    error: statementsError,
  } = useStatementsQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        strategyId,
        title: 'Benefits',
      },
    },
  });

  // Get the competitive advantage rows marked as a key issue in 3.1
  const {
    data: competitiveAdvantageRowsData,
    error: competitiveAdvantageRowsError,
    loading: competitiveAdvantageRowsLoading,
    startPolling: competitiveAdvantageRowsStartPolling,
    stopPolling: competitiveAdvantageRowsStopPolling,
  } = useCompetitiveAdvantageRowsQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderBy: {
        idx: Sort.Desc,
      },
      where: {
        strategyId,
        focus: true,
      },
      include: {
        strategicPossibility: true,
        successConditions: true,
      },
    },
  });

  // Get the strategic objectives and lead/lag indicators
  const {
    data: strategicQuestionsData,
    error: strategicQuestionsError,
    loading: strategicQuestionsLoading,
    startPolling: strategicQuestionsStartPolling,
    stopPolling: strategicQuestionsStopPolling,
  } = useStrategicQuestionsQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        strategyId,
      },
    },
  });

  const {
    data: keyStatementsData,
    error: keyStatementsError,
    loading: keyStatementsLoading,
    startPolling: startKeyStatementsPolling,
    stopPolling: stopKeyStatementsPolling,
  } = useKeyStatementsQuery({
    variables: {
      where: {
        strategyId,
        step: Step.DistinctiveCapabilities,
        subStep: SubStep.StrategicObjectives,
      },
    },
  });

  // Get the most aligned image
  const {
    data: imagePostItsData,
    loading: imagePostItsLoading,
    error: imagePostItsError,
    startPolling: imagePostItsStartPolling,
    stopPolling: imagePostItsStopPolling,
  } = usePostItCardsQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        strategyId,
        step: Step.Strategicquestion,
        substep: SubStep.WinningAspiration,
        type: PostItCardType.Image,
      },
    },
  });

  useEffect(() => {
    startPolling(polling.default);
    statementsStartPolling(polling.default);
    strategicQuestionsStartPolling(polling.default);
    imagePostItsStartPolling(polling.default);
    competitiveAdvantageRowsStartPolling(polling.default);
    startKeyStatementsPolling(polling.default);

    return () => {
      stopPolling();
      statementsStopPolling();
      strategicQuestionsStopPolling();
      imagePostItsStopPolling();
      competitiveAdvantageRowsStopPolling();
      stopKeyStatementsPolling();
    };
  }, [
    startPolling,
    stopPolling,
    statementsStartPolling,
    statementsStopPolling,
    strategicQuestionsStartPolling,
    strategicQuestionsStopPolling,
    imagePostItsStartPolling,
    imagePostItsStopPolling,
    competitiveAdvantageRowsStartPolling,
    competitiveAdvantageRowsStopPolling,
    startKeyStatementsPolling,
    stopKeyStatementsPolling,
  ]);

  // Ensure imperatives & scrollbar stretch to bottom of container
  useEffect(() => {
    const container = document.getElementById(
      'bubble-summary-container'
    ) as HTMLDivElement;
    const imperativesContainer = document.getElementById(
      'imperatives-container'
    ) as HTMLDivElement;

    if (!container || !imperativesContainer) return;

    if (isMobile) {
      imperativesContainer.style.height = `auto`;
    } else {
      const bottomContainer = container.clientTop + container.clientHeight;
      const imperativesHeight =
        bottomContainer - imperativesContainer.offsetTop - 10;

      imperativesContainer.style.height = `${imperativesHeight}px`;
    }
  });

  // Variables for the UI
  const loading =
    strategyLoading ||
    statementsLoading ||
    strategicQuestionsLoading ||
    imagePostItsLoading ||
    competitiveAdvantageRowsLoading ||
    keyStatementsLoading;
  const error =
    strategyError ||
    statementsError ||
    strategicQuestionsError ||
    imagePostItsError ||
    competitiveAdvantageRowsError ||
    keyStatementsError;
  const dataMissing =
    !strategyData ||
    !statementsData ||
    !strategicQuestionsData ||
    !imagePostItsData ||
    !competitiveAdvantageRowsData ||
    !keyStatementsData;

  const strategy = strategyData?.strategy;
  const benefits = statementsData?.statements?.items?.[0]?.text;
  const keyIssueStrategicImperatives =
    competitiveAdvantageRowsData?.competitiveAdvantageRows?.items;
  const allStrategicQuestions =
    strategicQuestionsData?.strategicQuestions?.items;
  const imagePostItCards = imagePostItsData?.postItCards?.items;
  const functionalBenefit = benefits?.[0];
  const emotionalBenefit = benefits?.[1];
  const benefitsStatement = generateBenefitsStatement(
    strategy?.drug?.name,
    functionalBenefit,
    emotionalBenefit
  );
  const noStrategicImperatives = !keyIssueStrategicImperatives?.length;
  const cardsWithImages = imagePostItCards
    ?.filter((card) => !!card.image && !!card.collaboration?.alignmentCount)
    .sort(
      (a, b) =>
        Number(b.collaboration?.alignmentCount) -
        Number(a.collaboration?.alignmentCount)
    ) as (Omit<PostItCardFragment, 'image'> & { image: string })[];
  const mostAlignedImage = cardsWithImages?.[0]?.image;
  const winningAspirationHasImage = !!mostAlignedImage;

  if (loading)
    return (
      <Loading style={{ margin: '50px auto auto' }} textColor={colors.white} />
    );

  return (
    <>
      <DownloadButton
        onClick={() =>
          exportSummaryThree({
            brand: strategyData?.strategy?.drug?.name || '',
            startYear: strategyData?.strategy?.startYear || 0,
            endYear: strategyData?.strategy?.endYear || 0,
            winningAspiration: strategy?.winningAspiration || undefined,
            brandPositioning: benefitsStatement || undefined,
            imperatives: keyIssueStrategicImperatives,
            allStrategicQuestions: allStrategicQuestions,
            keyStatements: keyStatementsData?.keyStatements?.items || undefined,
            mostAlignedImage,
          })
        }
      >
        <Icon name="Download" size={18} color={colors.black} />
      </DownloadButton>

      <ErrorWrapper
        errors={[error]}
        isLoading={loading}
        dataMissing={dataMissing}
      >
        <WinningAspirationCard>
          <WinningAspirationContent hasImage={winningAspirationHasImage}>
            <WinningAspirationTextContent>
              {strategy?.winningAspiration ? (
                <>
                  <CardTitle>Winning aspiration</CardTitle>
                  <Subtitle2>{strategy?.winningAspiration}</Subtitle2>
                </>
              ) : (
                <Subtitle2
                  style={{ textAlign: 'center' }}
                  color={colors.greyDark}
                >
                  Winning aspiration not defined yet
                </Subtitle2>
              )}
            </WinningAspirationTextContent>

            {!!strategy?.winningAspiration && !!mostAlignedImage && !isMobile && (
              <DividerAndImage>
                <Divider />
                <ImageContainer>
                  <BlurredBGImage imageURL={mostAlignedImage} blurAmount={2} />
                </ImageContainer>
              </DividerAndImage>
            )}
          </WinningAspirationContent>
        </WinningAspirationCard>

        <BrandPositioningCard>
          <BrandPositioningContent
            hasImage={
              !!functionalBenefit &&
              !!emotionalBenefit &&
              winningAspirationHasImage
            }
            isMobile={isMobile}
          >
            <BrandPositioningTextContainer hasImage={winningAspirationHasImage}>
              {!!functionalBenefit && !!emotionalBenefit ? (
                <>
                  <CardTitle>Brand positioning</CardTitle>
                  <Subtitle2>{benefitsStatement}</Subtitle2>
                </>
              ) : (
                <Subtitle2
                  style={{ textAlign: 'center' }}
                  color={colors.greyDark}
                >
                  Brand positioning not defined yet
                </Subtitle2>
              )}
            </BrandPositioningTextContainer>
          </BrandPositioningContent>
        </BrandPositioningCard>

        {noStrategicImperatives ? (
          <ImperativesEmptyState>
            <Subtitle2 style={{ color: colors.greyDark }}>
              No Strategic Imperatives yet
            </Subtitle2>
          </ImperativesEmptyState>
        ) : (
          <ImperativesContainer id="imperatives-container">
            {keyIssueStrategicImperatives?.map((s) => {
              const strategicQuestions = allStrategicQuestions?.filter(
                (sq) => sq.competitiveAdvantageRowId === s.id
              );
              const indicators = strategicQuestions?.find(
                (sq) => sq.field === 'leadIndicators\nlagIndicators'
              )?.lists;
              const leadIndicators = indicators?.[0].list;
              const lagIndicators = indicators?.[1].list;
              const strategicObjectives =
                keyStatementsData?.keyStatements?.items.filter((ks) => {
                  return ks.competitiveAdvantageRowId === s.id;
                }) || [];

              return (
                <Imperative key={s.id}>
                  <ScrollableFadeContainer>
                    <ImperativeDetails>
                      <Details>
                        <TextWrapper hasImage={winningAspirationHasImage}>
                          <BodySmall color={colors.greyDark}>
                            Strategic imperative
                          </BodySmall>

                          <FlexColumn>
                            <BodySmall style={{ fontWeight: 500 }}>
                              {s.strategicPossibility?.name}
                            </BodySmall>
                            {s?.strategicPossibility?.behaviourToDrive && (
                              <BodySmall>
                                {s.strategicPossibility.behaviourToDrive}
                              </BodySmall>
                            )}
                            {s?.strategicPossibility?.driverOfTheBehaviour && (
                              <BodySmall>
                                {s.strategicPossibility.driverOfTheBehaviour}
                              </BodySmall>
                            )}
                          </FlexColumn>
                        </TextWrapper>
                      </Details>
                    </ImperativeDetails>

                    <StrategicObjectives>
                      <TextWrapper hasImage={winningAspirationHasImage}>
                        <CardTitle>Goals</CardTitle>
                        <BulletPoints
                          listData={strategicObjectives.map((so) => so.text)}
                        />
                      </TextWrapper>
                    </StrategicObjectives>

                    <IndicatorsContainer>
                      <Indicators>
                        <CardTitle>Lead indicators</CardTitle>
                        <BulletPoints listData={leadIndicators} />
                      </Indicators>
                      <Indicators>
                        <CardTitle>Lag indicators</CardTitle>
                        <BulletPoints listData={lagIndicators} />
                      </Indicators>
                    </IndicatorsContainer>
                  </ScrollableFadeContainer>
                </Imperative>
              );
            })}
          </ImperativesContainer>
        )}
      </ErrorWrapper>
    </>
  );
};
