import React, { useRef, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components/macro';

import { useEditor, EditorContent } from '@tiptap/react';
import { uploadFile } from 'utils/uploadFile';
import {
  useRequestFileUploadMutation,
  FileModel,
} from 'data/graphql/generated';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Underline from '@tiptap/extension-underline';
import { colors } from 'constants/colors';
import { Icon } from 'components/shared';
import { TooltipCSS } from 'components/shared/Tooltip';

const FileInput = styled.input`
  height: 0px;
  opacity: 0;
  position: absolute;
`;

const Wrapper = styled.section<{ editable: boolean }>`
  display: block;
  flex: 1;
  margin-top: 20px;

  ${({ editable }) =>
    editable
      ? css`
          border: 1px solid ${colors.grey};
          border-radius: 5px;
        `
      : css``}

  .ProseMirror {
    h1 {
      font-size: 26px;
      line-height: 35px;
      font-weight: 400;
    }
    h2 {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }
    strong {
      font-weight: 500;
    }
    a,
    a:visited {
      color: ${colors.purple};
    }
    ul,
    ol {
      p {
        margin: 0px;
      }
    }
  }
`;

const Header = styled.header`
  position: sticky;
  top: -20px;
  height: 55px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${colors.grey};
  background: ${colors.white};
  z-index: 2;

  @media (max-width: 570px) {
    overflow-x: scroll;
    overflow-y: hidden;
  }
`;

const Content = styled.div<{ editable: boolean }>`
  ${({ editable }) =>
    editable
      ? css`
          padding: 0px 15px 15px 15px;
          height: 100%;
        `
      : css`
          cursor: default;
        `}
`;

const Button = styled.button<{ active?: boolean }>`
  background: ${({ active }) => (active ? colors.purple : 'transparent')};
  border: none;
  margin-right: 10px;
  border-radius: 2px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  path {
    fill: ${({ active }) => (active ? colors.white : colors.black)};
  }

  &:hover {
    background: ${({ active }) => (active ? colors.purple : colors.black10)};
  }
`;

interface Props {
  editable: boolean;
  value: string;
  strategyId: number;
  bigIdeaId: number;
  onUpdate(val: string, bigIdeaId: number): void;
}

export const BigIdeaEditor: React.FC<Props> = ({
  editable,
  value,
  strategyId,
  bigIdeaId,
  onUpdate,
}) => {
  const [requestFileUpload] = useRequestFileUploadMutation();
  const refFileInput = useRef<HTMLInputElement | null>(null);

  const updateContent = useCallback(
    (html: string) => {
      onUpdate(html, bigIdeaId);
    },
    [bigIdeaId, onUpdate]
  );

  const editor = useEditor({
    extensions: [
      StarterKit,
      Image,
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Link.configure({
        openOnClick: true,
      }),
    ],
    content: value,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      updateContent(html);
    },
  });

  useEffect(() => {
    if (editable) {
      editor?.setOptions({ editable: true });
    } else {
      editor?.setOptions({ editable: false });
    }
  }, [editable, editor]);

  const setLink = useCallback(() => {
    if (!editor) return;
    const previousUrl = editor.getAttributes('link').href;
    let url = window.prompt('URL', previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // Add http to url string
    if (!url.startsWith('https://') && !url.startsWith('http://')) {
      url = `https://${url}`;
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url, target: '_blank' }).run();
  }, [editor]);

  function addImage() {
    refFileInput?.current?.click();
  }

  async function uploadImage(file: File) {
    try {
      const { data } = await requestFileUpload({
        variables: {
          data: {
            strategyId: +strategyId,
            model: FileModel.BigIdeaContent,
            modelId: bigIdeaId,
            name: file.name,
          },
        },
      });

      if (!data?.requestFileUpload) {
        throw Error('Upload failed');
      }

      await uploadFile([file] as File[], data.requestFileUpload);

      const url = `${data.requestFileUpload.url}/${data.requestFileUpload.fields.key}`;

      editor?.chain().focus().setImage({ src: url }).run();

      if (refFileInput?.current?.value) {
        refFileInput.current.value = '';
      }
    } catch (err) {
      alert('Something went wrong');
    }
  }

  return (
    <Wrapper editable={editable}>
      <FileInput
        ref={refFileInput}
        type="file"
        accept="image/png, image/jpeg"
        onChange={(e) => {
          const file = e.currentTarget.files?.[0];
          if (!file) return;
          if (file.size > 10485760) return alert('File is over the 10MB limit');
          uploadImage(file);
        }}
      />

      {editable ? (
        <Header>
          <TooltipCSS
            text="Bold"
            direction="top"
            tooltipStyle={{
              top: 33,
              transform: 'translate3d(-5px, 0, 0)',
            }}
          >
            <Button
              onClick={() => editor?.chain().focus().toggleBold().run()}
              active={editor?.isActive('bold')}
            >
              <Icon name="Bold" color="red" size={30} />
            </Button>
          </TooltipCSS>
          <TooltipCSS
            text="Italic"
            direction="top"
            tooltipStyle={{
              top: 33,
              transform: 'translate3d(-6px, 0, 0)',
            }}
          >
            <Button
              onClick={() => editor?.chain().focus().toggleItalic().run()}
              active={editor?.isActive('italic')}
            >
              <Icon name="Italic" color="red" size={30} />
            </Button>
          </TooltipCSS>
          <TooltipCSS
            text="Underline"
            direction="top"
            tooltipStyle={{
              top: 33,
              transform: 'translate3d(-20px, 0, 0)',
            }}
          >
            <Button
              onClick={() => editor?.chain().focus().toggleUnderline().run()}
              active={editor?.isActive('underline')}
            >
              <Icon name="Underline" color="red" size={30} />
            </Button>
          </TooltipCSS>
          <TooltipCSS
            text="Heading 1"
            direction="top"
            tooltipStyle={{
              top: 33,
              transform: 'translate3d(-15px, 0, 0)',
            }}
          >
            <Button
              onClick={() =>
                editor?.chain().focus().toggleHeading({ level: 1 }).run()
              }
              active={editor?.isActive('heading', { level: 1 })}
            >
              <Icon name="HeadingOne" color="red" size={30} />
            </Button>
          </TooltipCSS>
          <TooltipCSS
            text="Heading 2"
            direction="top"
            tooltipStyle={{
              top: 33,
              transform: 'translate3d(-15px, 0, 0)',
            }}
          >
            <Button
              onClick={() =>
                editor?.chain().focus().toggleHeading({ level: 2 }).run()
              }
              active={editor?.isActive('heading', { level: 2 })}
            >
              <Icon name="HeadingTwo" color="red" size={30} />
            </Button>
          </TooltipCSS>
          <TooltipCSS
            text="Normal text"
            direction="top"
            tooltipStyle={{
              top: 33,
              transform: 'translate3d(-20px, 0, 0)',
            }}
          >
            <Button
              onClick={() => editor?.chain().focus().setParagraph().run()}
              active={editor?.isActive('paragraph')}
            >
              <Icon name="EditorText" color="red" size={30} />
            </Button>
          </TooltipCSS>
          <TooltipCSS
            text="Left align"
            direction="top"
            tooltipStyle={{
              top: 33,
              transform: 'translate3d(-20px, 0, 0)',
            }}
          >
            <Button
              onClick={() => editor?.chain().focus().setTextAlign('left').run()}
              active={editor?.isActive({ textAlign: 'left' })}
            >
              <Icon name="Left" color="red" size={30} />
            </Button>
          </TooltipCSS>
          <TooltipCSS
            text="Centre align"
            direction="top"
            tooltipStyle={{
              top: 33,
              transform: 'translate3d(-20px, 0, 0)',
            }}
          >
            <Button
              onClick={() =>
                editor?.chain().focus().setTextAlign('center').run()
              }
              active={editor?.isActive({ textAlign: 'center' })}
            >
              <Icon name="Center" color="red" size={30} />
            </Button>
          </TooltipCSS>
          <TooltipCSS
            text="Bulleted list"
            direction="top"
            tooltipStyle={{
              top: 33,
              transform: 'translate3d(-22px, 0, 0)',
            }}
          >
            <Button
              onClick={() => editor?.chain().focus().toggleBulletList().run()}
              active={editor?.isActive('bulletList')}
            >
              <Icon name="List" color="red" size={30} />
            </Button>
          </TooltipCSS>
          <TooltipCSS
            text="Numbered list"
            direction="top"
            tooltipStyle={{
              top: 33,
              transform: 'translate3d(-20px, 0, 0)',
            }}
          >
            <Button
              onClick={() => editor?.chain().focus().toggleOrderedList().run()}
              active={editor?.isActive('orderedList')}
            >
              <Icon name="OrderedList" color="red" size={30} />
            </Button>
          </TooltipCSS>
          <TooltipCSS
            text="Set link"
            direction="top"
            tooltipStyle={{
              top: 33,
              transform: 'translate3d(-15px, 0, 0)',
            }}
          >
            <Button onClick={setLink} active={editor?.isActive('link')}>
              <Icon name="Link" color="red" size={30} />
            </Button>
          </TooltipCSS>
          <TooltipCSS
            text="Insert image"
            direction="top"
            tooltipStyle={{
              top: 33,
              transform: 'translate3d(-20px, 0, 0)',
            }}
          >
            <Button onClick={addImage}>
              <Icon name="EditorImage" color="red" size={30} />
            </Button>
          </TooltipCSS>
          <TooltipCSS
            text="Undo"
            direction="top"
            tooltipStyle={{
              top: 33,
              transform: 'translate3d(-5px, 0, 0)',
            }}
          >
            <Button onClick={() => editor?.chain().focus().undo().run()}>
              <Icon name="Undo" color="red" size={30} />
            </Button>
          </TooltipCSS>
          <TooltipCSS
            text="Redo"
            direction="top"
            tooltipStyle={{
              top: 33,
              transform: 'translate3d(-5px, 0, 0)',
            }}
          >
            <Button onClick={() => editor?.chain().focus().redo().run()}>
              <Icon name="Redo" color="red" size={30} />
            </Button>
          </TooltipCSS>
        </Header>
      ) : null}

      <Content
        editable={editable}
        onClick={() => {
          editor?.commands.focus();
        }}
      >
        <EditorContent editor={editor} />
      </Content>
    </Wrapper>
  );
};
