import { ModalForm } from 'components/ModalForm';
import { ModalButton, ModalButtonContainer } from 'containers/AdminCompany';
import { ErrorBox, ErrorMessage } from 'containers/Signin';
import { User } from 'data/graphql/generated';
import { validate } from 'email-validator';
import { Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import { preventEnterSubmit } from 'utils/preventEnterSubmit';
import FormTextInput from './FormTextInput';
import { TextInputWithDropdownAndSelection } from './TextInputWithDropdownAndSelection';

const Wrapper = styled(ModalForm)`
  label[for='email'] {
    margin-top: 0px;
  }

  .CTAWrapper {
    position: relative;
  }
`;

const StyledErrorBox = styled(ErrorBox)`
  margin-top: 30px;
  text-align: left;
`;

interface Props {
  handleClose(): void;
  visible: boolean;
  heading: string;
  message: string;
  usersForDropdown?: (User | null | undefined)[];
  handleSubmit({
    name,
    email,
    drugId,
  }: {
    name: string;
    email: string;
    drugId: number;
  }): Promise<void>;
  drugId: number | null;
  usersToExclude: (User | null | undefined)[];
  excludedUsersMessage: string;
}

export const AddUserModal = ({
  handleClose,
  visible,
  heading,
  message,
  usersForDropdown,
  handleSubmit,
  drugId,
  usersToExclude,
  excludedUsersMessage,
}: Props) => {
  const [showNameField, setShowNameField] = useState(false);

  const [networkError, setNetworkError] = useState(false);

  if (!visible) {
    return null;
  }

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        email: '',
        name: '',
      }}
      validate={({ name, email }) => {
        setNetworkError(false);
        const errors = {} as {
          name: string;
          email: string;
        };

        if (!name) {
          errors.name = 'Cannot be empty';
        }

        if (!email) {
          errors.email = 'Cannot be empty';
        }

        if (!validate(email)) {
          errors.email = 'Invalid email address';
        }

        return errors;
      }}
      onSubmit={async ({ email, name }, actions) => {
        if (!drugId) return;
        try {
          await handleSubmit({ name, email, drugId });

          actions.resetForm();
          handleClose();
        } catch (error) {
          if (
            [
              'Unique constraint failed on the fields: (`email`)',
              'This email address is already in use',
            ].some(
              (message) =>
                error instanceof Error && error.message.includes(message)
            )
          ) {
            actions.setFieldError('email', 'Email address already in use');
          } else {
            setNetworkError(true);
            console.error(error);
          }
        }
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        setFieldValue,
        isValid,
        dirty,
        resetForm,
        isSubmitting,
        touched,
      }) => (
        <form onSubmit={handleSubmit} onKeyDown={preventEnterSubmit}>
          <Wrapper
            handleClose={() => {
              resetForm();
              handleClose();
            }}
            visible={visible}
            heading={heading}
            message={message}
          >
            <TextInputWithDropdownAndSelection
              emailFieldValue={values.email}
              nameValue={values.name}
              onDisabledChange={(disableState) => {
                if (!disableState) {
                  setShowNameField(false);
                } else {
                  setFieldValue('name', '', true);
                }
              }}
              usersForDropdown={usersForDropdown}
              handleValueChange={handleChange}
              emailErrorMessage={errors.email}
              usersToExclude={usersToExclude}
              onOptionSelected={({ name, email }) => {
                setFieldValue('email', email);
                setFieldValue('name', name);
              }}
              excludedUsersMessage={excludedUsersMessage}
              disabledState={showNameField}
              setDisabledState={setShowNameField}
              onSelectedItemChange={() => {
                resetForm();
              }}
              existingUserInputMessage="This user will be a Lead on all brands and strategies they
              have access to."
            />

            {showNameField ? (
              <FormTextInput
                name="name"
                value={values.name}
                title="Name"
                type="text"
                onChange={(e) => handleChange(e)}
                errorMessage={touched.name ? errors.name : ''}
              />
            ) : null}

            {/* Buttons */}
            <ModalButtonContainer>
              <ModalButton
                onClick={() => {
                  resetForm();
                  handleClose();
                }}
                text="Cancel"
                level="secondary"
              />
              <ModalButton
                className={'cypress-send-invite'}
                loading={isSubmitting}
                type="submit"
                disabled={!(values.name && values.email)}
                onClick={() => {}}
                text={'Send invite'}
              />
            </ModalButtonContainer>

            {networkError ? (
              <StyledErrorBox>
                <ErrorMessage>
                  {
                    'Invite could not be sent due to an unknown error. Please try again.'
                  }
                </ErrorMessage>
              </StyledErrorBox>
            ) : null}
          </Wrapper>
        </form>
      )}
    </Formik>
  );
};
