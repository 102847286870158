import React from 'react';
import styled from 'styled-components';

import { Stakeholder } from 'data/graphql/generated';
import { StepTab, TabGroup } from 'components/shared';
import { device } from 'utils/breakpoints';
import { useHistory } from 'react-router-dom';

const StepBarsWrapper = styled.div`
  width: 100%;
  display: flex;

  > * + * {
    margin-left: 5px;
  }
`;

const TabGroupWrapper = styled.div`
  margin-top: 0px;
  @media ${device.tabletMax} {
    margin-top: 20px;
  }
`;

interface Props {
  stakeholder: Stakeholder;
  setStakeholder(stakeholder: Stakeholder): void;
}

export const StakeHolderTabs: React.FC<Props> = ({
  stakeholder,
  setStakeholder,
}) => {

  const history = useHistory();

  const { pathname } = history.location;
  const [contains1_2, setContains1_2] = React.useState(false);

  React.useEffect(() => {
    if (pathname.includes('1_2')) {
      setContains1_2(true);
    }
  }, [pathname]);
  
  return (
    <TabGroupWrapper>
      <TabGroup componentName="StepTab">
        <StepBarsWrapper>
          <StepTab
            active={stakeholder === Stakeholder.Patient}
            text="Patients"
            onClick={() => {
              setStakeholder(Stakeholder.Patient);
            }}
          />
          <StepTab
            active={stakeholder === Stakeholder.Healthcare}
            text="Healthcare Professionals"
            onClick={() => {
              setStakeholder(Stakeholder.Healthcare);
            }}
          />
          <StepTab
            active={stakeholder === 'Payor'}
            text="Payors"
            onClick={() => {
              setStakeholder(Stakeholder.Payor);
            }}
          />
          {!contains1_2 && 
          <>
            <StepTab
              active={stakeholder === Stakeholder.Provider}
              text="Providers"
              onClick={() => {
                setStakeholder(Stakeholder.Provider);
              }}
            />
            <StepTab
              active={stakeholder === Stakeholder.Policymaker}
              text="Policymakers"
              onClick={() => {
                setStakeholder(Stakeholder.Policymaker);
              }}
            />
          </>
          }
        </StepBarsWrapper>
      </TabGroup>
    </TabGroupWrapper>
  );
};
