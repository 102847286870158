import React from 'react';
import styled from 'styled-components/macro';

import { CriticalMetricGoalUpdateInput } from 'data/graphql/generated';
import { BodySmall } from 'components/shared';
import { colors } from 'constants/colors';

import {
  GoalInputDropdownOptionType,
  GoalInputDropdown,
} from '../../../../shared-ui-goals/src';
import { GoalInputFieldConstants } from '../../../../shared-util-goals-constants/src';
import { LockedField } from './LockedField';

interface BaseGoalInputFieldProps {
  editEnabled: boolean;
  hasTracking?: boolean;
  selectedInput: GoalInputDropdownOptionType | null;
  setSelectedInput: (
    value: React.SetStateAction<GoalInputDropdownOptionType | null>
  ) => void;
  onUpdate: (values: CriticalMetricGoalUpdateInput) => void;
  onLockedFieldClick: () => void;
}

const DesktopGoalInputField = ({
  editEnabled,
  selectedInput,
  setSelectedInput,
  onUpdate,
  hasTracking = false,
  onLockedFieldClick,
}: BaseGoalInputFieldProps) => {
  if (hasTracking && selectedInput) {
    return (
      <LockedField text={selectedInput.value} onClick={onLockedFieldClick} />
    );
  }

  return (
    <GoalInputDropdown
      editable={editEnabled}
      selectedOption={selectedInput}
      setSelectedOption={setSelectedInput}
      onUpdate={onUpdate}
    />
  );
};

const MobileLockedGoalInput = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;
`;

const MobileGoalInputField = ({
  editEnabled,
  selectedInput,
  setSelectedInput,
  onUpdate,
  hasTracking = false,
  onLockedFieldClick,
}: BaseGoalInputFieldProps) => {
  if (hasTracking && selectedInput) {
    return (
      <MobileLockedGoalInput>
        <BodySmall color={colors.greyDark}>
          {GoalInputFieldConstants.FIELD_LABEL}
        </BodySmall>
        <LockedField text={selectedInput.value} onClick={onLockedFieldClick} />
      </MobileLockedGoalInput>
    );
  }

  return (
    <>
      <BodySmall color={colors.greyDark}>
        {GoalInputFieldConstants.FIELD_LABEL}
      </BodySmall>
      <GoalInputDropdown
        editable={editEnabled}
        selectedOption={selectedInput}
        setSelectedOption={setSelectedInput}
        onUpdate={onUpdate}
      />
    </>
  );
};

interface GoalInputFieldProps extends BaseGoalInputFieldProps {
  isDesktop?: boolean;
  onUpdate: (data: CriticalMetricGoalUpdateInput) => Promise<void>;
}

export const GoalInputField = ({
  isDesktop,
  editEnabled,
  selectedInput,
  setSelectedInput,
  onUpdate,
  hasTracking = false,
  onLockedFieldClick,
}: GoalInputFieldProps) => {
  const handleSetSelectInput = React.useCallback<
    (value: React.SetStateAction<GoalInputDropdownOptionType | null>) => void
  >(
    (e) => {
      if (!editEnabled) {
        return;
      }

      setSelectedInput(e);
    },
    [editEnabled, setSelectedInput]
  );

  const handleOnUpdate = React.useCallback(
    (e) => {
      if (!editEnabled) {
        return;
      }
      onUpdate(e);
    },
    [editEnabled, onUpdate]
  );

  if (isDesktop) {
    return (
      <DesktopGoalInputField
        editEnabled={editEnabled}
        selectedInput={selectedInput}
        setSelectedInput={handleSetSelectInput}
        onUpdate={handleOnUpdate}
        hasTracking={hasTracking}
        onLockedFieldClick={onLockedFieldClick}
      />
    );
  }

  return (
    <MobileGoalInputField
      editEnabled={editEnabled}
      selectedInput={selectedInput}
      setSelectedInput={handleSetSelectInput}
      onUpdate={handleOnUpdate}
      hasTracking={hasTracking}
      onLockedFieldClick={onLockedFieldClick}
    />
  );
};
