import Document from '@tiptap/extension-document';
import ListItem from '@tiptap/extension-list-item';
import BulletList from '@tiptap/extension-bullet-list';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { Editor, EditorContent, useEditor } from '@tiptap/react';
import { DatePickerDropdown } from 'components/MedicalStrategy/DatePickerDropdown';
import {
  BodyNormal,
  BodySmall,
  BodySmallStyle,
  Collaboration,
  EditableTextarea,
} from 'components/shared';
import { TextareaStyle } from 'components/shared/EditableTextarea';
import { TextAreaInput } from 'components/shared/TextAreaInput';
import { colors } from 'constants/index';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { thousandSeparator } from 'utils/thousandSeparator';
import {
  BigIdeaFragment,
  CommercialTacticFragment,
  CollaborationForPreviewFragment,
} from 'data/graphql/generated';
import {
  dateStringToMonthYear,
  getTimestamp,
} from 'utils/dateStringToMonthYear';
import { SolutionRowStatus } from './SolutionRowStatus';

const BudgetIcon = styled(BodyNormal)`
  position: absolute;
  top: 9px;
  left: 2px;
  height: 20px;
  line-height: 25px;

  @media (max-width: 1013px) {
    top: 0px;
  }
`;

const StyledTextAreaInput = styled(TextAreaInput)`
  margin: 0;
  ${BodySmallStyle}
  ${TextareaStyle}
  pointer-events: auto;
  cursor: text;
  padding-left: 15px;

  ::placeholder {
    color: ${colors.greyDark};
  }
`;

const SolutionTextEmptyStateWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  inset: 0;
  cursor: text;

  @media (min-width: 1014px) {
    padding: 10px;
  }
`;

const StyledDatePickerDropdown = styled(DatePickerDropdown)`
  flex: 1;
  height: 20px;
`;

const MobileSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const MobileWrapper = styled.div`
  padding: 0 20px;

  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const BudgetWrapper = styled.div`
  position: relative;

  textarea {
    padding: 0px;
    padding-left: 15px;
    @media (min-width: 1014px) {
      padding: 10px 0px;
      padding-left: 15px;
    }
  }
`;

const EndColumn = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ResponsibilityWrapper = styled.div`
  textarea {
    min-height: 100%;
    @media (min-width: 1014px) {
      padding-top: 10px;
    }
  }
`;

const StyledDue = styled.div``;

const StyledTiming = styled.div`
  display: flex;
  gap: 10px;
`;

const ActionsRequiredWrapper = styled.div`
  position: relative;
  ${TextareaStyle}
  padding: 0px;
  min-width: auto;
  &:focus-within {
    border: 0.5px solid ${colors.blue};
  }

  @media (min-width: 1014px) {
    padding: 10px;
  }
  // renders a random p tag when it's empty, which forces an incorrect min-height
  p {
    margin: 0;
  }

  .modifiedOrderedList {
    @media (max-width: 1013px) {
      padding-inline-start: 20px;
    }

    padding-inline-start: 13px;
    margin: 0;

    li {
      &::marker {
        font-size: 9px;
      }
      line-height: 5px;
      width: 100%;
      p {
        margin: 0;
        ${BodySmallStyle}
        pointer-events: auto;
        cursor: text;
        position: relative;
        margin-left: -4px;
      }
    }
  }
`;

const StyledSolutionText = styled.div`
  padding: 10px;
  background: ${colors.black05};
  border-radius: 5px;
`;

const Wrapper = styled.div`
  display: grid;
  column-gap: 15px;
  grid-template-columns:
    minmax(110px, 216px) minmax(90px, 197px)
    190px 90px minmax(110px, 197px) 223px;
  padding: 10px 15px;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 1014px) {
    flex-direction: column;
    align-items: stretch;
    gap: 5px;
  }
`;

interface Props {
  isDesktop: boolean;
  currency: string;
  text: string;
  audience: string;
  from: string | undefined;
  to: string | undefined;
  due: string | undefined;
  responsibility: string;
  budget: string;
  collaboration?: CollaborationForPreviewFragment;
  updateRow(val: Partial<CommercialTacticFragment | BigIdeaFragment>): void;
  audiencePlaceholder?: string;
  solutionTextOverride?: string;
  actionsTextOverride?: string;
}

export const SolutionRow = ({
  isDesktop,
  currency,
  text,
  audience,
  from,
  to,
  due,
  responsibility,
  budget,
  collaboration,
  updateRow,
  audiencePlaceholder,
  solutionTextOverride,
  actionsTextOverride,
}: Props) => {
  const [localDate, setLocalDate] = useState<
    Record<string, string | null | undefined>
  >({
    from,
    to,
    due,
  });

  useEffect(() => {
    setLocalDate({ from, to, due });
  }, [from, to, due]);

  const isComplete = useMemo(
    () =>
      Boolean(audience) &&
      Boolean(from) &&
      Boolean(to) &&
      Boolean(due) &&
      Boolean(responsibility) &&
      Boolean(budget),
    [audience, budget, due, from, responsibility, to]
  );

  function handleFromUpdate(val: string | null) {
    const cleanVal = getTimestamp(val);
    if (!val) {
      if (!!localDate.to) {
        setLocalDate({ from: cleanVal, to: cleanVal, due: cleanVal });
        updateRow({ from: val, to: val, due: val });
        return;
      }
      setLocalDate({ ...localDate, from: cleanVal });
      updateRow({ from: val });
      return;
    }

    if (!!localDate.to) {
      const fromDate = getTimestamp(val);
      const toDate = getTimestamp(to);

      if (fromDate && toDate) {
        if (fromDate > toDate) {
          setLocalDate({ from: cleanVal, to: null, due: null });
          updateRow({ from: val, to: null, due: null });
          return;
        }
      }
    }
    setLocalDate({ ...localDate, from: cleanVal });
    updateRow({ from: val });
  }

  function handleToUpdate(val: string | null) {
    const cleanVal = getTimestamp(val);

    if (!val) {
      if (!!localDate.due) {
        setLocalDate({ ...localDate, to: cleanVal, due: cleanVal });
        updateRow({ to: val, due: val });
        return;
      }

      setLocalDate({ ...localDate, to: cleanVal });
      updateRow({ to: val });
      return;
    }

    if (!!localDate.due) {
      const toDate = getTimestamp(val);
      const dueDate = getTimestamp(localDate.due);

      if (dueDate && toDate) {
        if (toDate > dueDate) {
          setLocalDate({ ...localDate, to: cleanVal, due: null });
          updateRow({ to: val, due: null });
          return;
        }
      }
    }
    setLocalDate({ ...localDate, to: cleanVal });
    updateRow({ to: val });
  }

  if (!isDesktop) {
    return (
      <MobileWrapper>
        <MobileSectionWrapper>
          <BodySmall color={colors.greyDark}>
            {solutionTextOverride ? solutionTextOverride : 'Solution'}
          </BodySmall>
          <SolutionText text={text} />
        </MobileSectionWrapper>

        <MobileSectionWrapper>
          <BodySmall color={colors.greyDark}>
            {actionsTextOverride ? actionsTextOverride : 'Actions required'}
          </BodySmall>
          <ActionsRequired
            value={audience}
            handleUpdate={(val: string) => updateRow({ audience: val })}
            placeholder={audiencePlaceholder}
          />
        </MobileSectionWrapper>

        <MobileSectionWrapper style={{ marginBottom: 10 }}>
          <BodySmall color={colors.greyDark}>Timing</BodySmall>
          <Timing
            from={localDate.from}
            updateFrom={(val: string) => {
              handleFromUpdate(val);
            }}
            to={localDate.to}
            updateTo={(val: string) => {
              handleToUpdate(val);
            }}
          />
        </MobileSectionWrapper>

        <MobileSectionWrapper
          style={{ width: 'calc(50% - 10px)', marginBottom: 10 }}
        >
          <BodySmall color={colors.greyDark}>Due</BodySmall>
          <Due
            lowerLimit={getTimestamp(localDate.to)}
            value={localDate.due}
            handleUpdate={(val: string) => {
              setLocalDate({ ...localDate, due: getTimestamp(val) });
              updateRow({ due: val });
            }}
          />
        </MobileSectionWrapper>

        <MobileSectionWrapper>
          <BodySmall color={colors.greyDark}>Responsibility</BodySmall>
          <Responsibility
            value={responsibility}
            handleUpdate={(val: string) => updateRow({ responsibility: val })}
          />
        </MobileSectionWrapper>

        <MobileSectionWrapper>
          <BodySmall color={colors.greyDark}>Budget</BodySmall>
          <Budget
            currency={currency}
            value={budget}
            handleUpdate={(val: string) => updateRow({ budget: val })}
          />
        </MobileSectionWrapper>

        <StatusContainer>
          <SolutionRowStatus isComplete={isComplete} />

          {collaboration ? (
            <Collaboration
              style={{
                margin: 'auto 0 auto auto',
              }}
              collaboration={collaboration}
            />
          ) : null}
        </StatusContainer>
      </MobileWrapper>
    );
  } else
    return (
      <Wrapper>
        <SolutionText text={text} />
        <ActionsRequired
          value={audience}
          handleUpdate={(val: string) => updateRow({ audience: val })}
          placeholder={audiencePlaceholder}
        />
        <div style={{ paddingTop: 10 }}>
          <Timing
            from={localDate.from}
            updateFrom={(val: string | null) => {
              handleFromUpdate(val);
            }}
            to={localDate.to}
            updateTo={(val: string | null) => {
              handleToUpdate(val);
            }}
          />
        </div>
        <div style={{ paddingTop: 10 }}>
          <Due
            lowerLimit={getTimestamp(localDate.to)}
            value={localDate.due}
            handleUpdate={(val: string | null) => {
              setLocalDate({ ...localDate, due: getTimestamp(val) });
              updateRow({ due: val });
            }}
          />
        </div>
        <Responsibility
          value={responsibility}
          handleUpdate={(val: string) => updateRow({ responsibility: val })}
        />

        <EndColumn>
          <Budget
            currency={currency}
            value={budget}
            handleUpdate={(val: string) => updateRow({ budget: val })}
          />

          <StatusContainer>
            <SolutionRowStatus isComplete={isComplete} />

            {collaboration ? (
              <Collaboration collaboration={collaboration} />
            ) : null}
          </StatusContainer>
        </EndColumn>
      </Wrapper>
    );
};

const SolutionText = ({ text }: { text: string }) => (
  <StyledSolutionText>
    <BodySmall>{text}</BodySmall>
  </StyledSolutionText>
);

const ActionsRequired = ({
  value = '',
  handleUpdate,
  placeholder,
}: {
  value: string;
  handleUpdate(val: string): void;
  placeholder?: string;
}) => {
  const [textValue, setTextValue] = useState(value);

  const modifiedListItem = ListItem.extend({
    addKeyboardShortcuts() {
      return {
        Enter: ({ editor }) => {
          const textInLi = !!editor?.view.state?.selection.$from.parent
            .textContent;

          if (textInLi) {
            return editor.commands.splitListItem(this.name);
          } else return true;
        },
        Tab: () => true,
        'Shift-Tab': () => true,
      };
    },
  });

  const modifiedOrderedList = BulletList.extend({
    addKeyboardShortcuts() {
      return {
        'Mod-Shift-7': () => {
          return true;
        },
      };
    },
  });

  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      modifiedOrderedList.configure({
        HTMLAttributes: {
          class: 'modifiedOrderedList',
        },
      }),
      modifiedListItem,
    ],

    content: textValue,

    onFocus: ({ editor }) => {
      if (!editor?.isActive('bulletList')) {
        editor.commands.toggleBulletList();
      }
    },

    onBlur: ({ editor }) => {
      if (!editor.getText() && editor?.isActive('bulletList')) {
        editor.commands.toggleBulletList();
      }
      const html = editor.getHTML();
      const cleanedHTML = html.replace(/(<li><p>\s*<\/p><\/li>)/gm, '');
      const trimText = editor.getText().trim();
      const validText = trimText && cleanedHTML;

      // Remove empty rows
      setTextValue(validText);

      editor.commands.setContent(validText);
      handleUpdate(validText);
    },
    onUpdate: ({ editor }) => {
      if (editor.isFocused && !editor?.isActive('bulletList')) {
        editor.commands.toggleBulletList();
      }
    },
  });

  const handleClick = (editor: Editor | null) => {
    editor?.commands?.focus();
  };
  if (!editor) {
    return null;
  }

  return (
    <ActionsRequiredWrapper>
      <EditorContent editor={editor}>
        {!editor?.isFocused && !editor.getText() && (
          <SolutionTextEmptyStateWrapper
            onClick={() => {
              if (!editor.getText()) handleClick(editor);
            }}
          >
            <BodySmall color={colors.greyDark}>
              {placeholder || 'Audience'}
            </BodySmall>
          </SolutionTextEmptyStateWrapper>
        )}
      </EditorContent>
    </ActionsRequiredWrapper>
  );
};

const Timing = ({
  from,
  to,
  updateFrom,
  updateTo,
}: {
  from: string | undefined | null;
  to: string | undefined | null;
  updateFrom(val: string | null): void;
  updateTo(val: string | null): void;
}) => {
  const [fromState, setFromState] = useState(from);
  const [toState, setToState] = useState(to);

  useEffect(() => {
    setFromState(from);
  }, [from]);

  useEffect(() => {
    setToState(to);
  }, [to]);

  return (
    <StyledTiming>
      <StyledDatePickerDropdown
        placeholder="From"
        date={dateStringToMonthYear(fromState)}
        onUpdate={(date) => {
          if (!date) {
            setFromState(null);
            updateFrom(null);
          } else {
            // Prisma server expects the date string(sends back timestamp for some reason)
            // but we need it as a timestamp to do a comparison here, so we use gdtTime instead of toISOString
            const newDate = new Date(date.year, date.month, 10).getTime();
            if (!!toState && +toState < newDate) {
              setToState(null);
              updateTo(null);
            }

            setFromState(new Date(date.year, date.month, 10).getTime() + '');
            updateFrom(new Date(date.year, date.month, 10).toISOString());
          }
        }}
      />
      <StyledDatePickerDropdown
        placeholder="To"
        disabled={!dateStringToMonthYear(fromState).length}
        lowerLimit={dateStringToMonthYear(fromState)}
        date={dateStringToMonthYear(toState)}
        onUpdate={(date) => {
          if (!date) {
            setToState(null);
            updateTo(null);
          } else {
            setToState(new Date(date.year, date.month, 10).getTime() + '');
            updateTo(new Date(date.year, date.month, 10).toISOString());
          }
        }}
      />
    </StyledTiming>
  );
};

const Due = ({
  value,
  handleUpdate,
  lowerLimit,
}: {
  value: string | undefined | null;
  handleUpdate(val: string | null): void;
  lowerLimit: string | undefined | null;
}) => {
  const [dueState, setDueState] = useState(value);

  useEffect(() => {
    setDueState(value);
  }, [value]);

  return (
    <StyledDue>
      <StyledDatePickerDropdown
        disabled={!lowerLimit}
        lowerLimit={dateStringToMonthYear(lowerLimit)}
        style={{ flex: 1 }}
        placeholder="Due"
        date={dateStringToMonthYear(dueState)}
        onUpdate={(date) => {
          if (date) {
            setDueState(new Date(date.year, date.month, 10).getTime() + '');
            handleUpdate(new Date(date.year, date.month, 10).toISOString());
          } else {
            setDueState(null);
            handleUpdate(null);
          }
        }}
      />
    </StyledDue>
  );
};

const Responsibility = ({
  value,
  handleUpdate,
}: {
  value: string;
  handleUpdate(val: string): void;
}) => (
  <ResponsibilityWrapper>
    <EditableTextarea
      style={{ background: 'transparent' }}
      editable={true}
      placeholder="Responsibility"
      initialValue={value}
      onBlur={(value) => {
        handleUpdate(value);
      }}
    />
  </ResponsibilityWrapper>
);

const Budget = ({
  currency,
  value,
  handleUpdate,
}: {
  currency: string;
  value: string;
  handleUpdate(val: string): void;
}) => {
  const [textValue, setTextValue] = useState(value);
  return (
    <BudgetWrapper>
      <StyledTextAreaInput
        placeholder="Budget"
        onChange={(e) => {
          const target = e.nativeEvent.target as HTMLTextAreaElement;
          setTextValue(target.value.replace(/[\D,]/g, ''));
        }}
        onBlur={() => handleUpdate(textValue)}
        value={thousandSeparator(textValue)}
      />
      <BudgetIcon color={colors.greyDark}>{currency}</BudgetIcon>
    </BudgetWrapper>
  );
};
