import { ChipButton } from 'components/shared';
import {
  CompetitorFragment,
  Stakeholder,
  StakeholderDefinitionFragment,
  StakeholderDefinitionsQueryVariables,
} from 'data/graphql/generated';
import React from 'react';
import styled from 'styled-components';
import { CompetitorModalState } from 'types';
import { CompetitorChip } from './CompetitorChip';
import { competitorModalDefaultState } from './CreateUpdateCompetitorModal';
import { DeleteCompetitorModalShape } from './DeleteCompetitorModal';

const Wrapper = styled.div`
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 15px;
  column-gap: 5px;
  margin-bottom: 15px;
`;

interface Props {
  stakeholder: Stakeholder;
  competitors: CompetitorFragment[];
  setCreateUpdateCompetitorModal: React.Dispatch<
    React.SetStateAction<CompetitorModalState>
  >;
  setDeleteCompetitorModal: React.Dispatch<
    React.SetStateAction<DeleteCompetitorModalShape>
  >;
  setTreatmentInUseModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedStakeholderDefinition: StakeholderDefinitionFragment;
  stakeholderDefinitionsQueryVars: StakeholderDefinitionsQueryVariables;
  displayDeleteCompetitorModal: (
    competitor: CompetitorFragment
  ) => Promise<void>;
}
export const CompetitorChips: React.FC<Props> = ({
  competitors,
  stakeholder,
  setCreateUpdateCompetitorModal,
  setTreatmentInUseModal,
  selectedStakeholderDefinition,
  stakeholderDefinitionsQueryVars,
  displayDeleteCompetitorModal,
}) => {
  return (
    <Wrapper>
      {competitors.map((c: CompetitorFragment) => (
        <CompetitorChip
          key={c.id}
          competitor={c}
          stakeholder={stakeholder}
          setCreateUpdateCompetitorModal={setCreateUpdateCompetitorModal}
          setTreatmentInUseModal={setTreatmentInUseModal}
          stakeholderDefinitionsQueryVars={stakeholderDefinitionsQueryVars}
          selectedStakeholderDefinition={selectedStakeholderDefinition}
          displayDeleteCompetitorModal={displayDeleteCompetitorModal}
        />
      ))}
      <ChipButton
        text="Add competitor"
        className="cypress-competitor-create"
        onClick={() => {
          setCreateUpdateCompetitorModal({
            ...competitorModalDefaultState,
            type: 'add',
          });
        }}
      />
    </Wrapper>
  );
};
