import React, { ComponentProps, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { colors } from 'constants/index';

import { device } from 'utils/breakpoints';
import { BodySmall, CountryFlag, Subtitle2, Table } from 'components/shared';
import { TableDivHeaders } from 'components/shared/Table';
import { CountryGlobalRegional } from '../types';
import { startCase } from '../utils/startCase';

const CountryWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
`;

const StyledTable = styled(Table)`
  margin-bottom: 20px;

  ${TableDivHeaders} {
    grid-template-columns:
      minmax(110px, 216px) minmax(90px, 197px)
      190px 90px minmax(110px, 197px) 223px;
  }
  @media ${device.mobile} {
    border: none;
    border-radius: 0px;
    max-width: 100%;
  }
` as typeof Table;

export function PlanTable<T extends { id?: string | number }>({
  headerTitle,
  rows,
  data,
  isDesktop,
  headerColumns,
  emptyTitle,
  EmptySubHeading,
  region,
}: {
  headerTitle: string;
  rows: (data: T, idx: number) => ReactElement | null;
  data: T[];
  isDesktop: Pick<ComponentProps<typeof Table>, 'isDesktop'>['isDesktop'];
  headerColumns: string[];
  emptyTitle: string;
  EmptySubHeading: React.ReactElement;
  region?: CountryGlobalRegional;
}) {
  return (
    <StyledTable
      useDiv
      isDesktop={isDesktop}
      header={
        <div style={{ marginBottom: 20, marginLeft: 15, maxWidth: 640 }}>
          {!!region && (
            <CountryWrapper>
              <CountryFlag size={15} country={region} />
              <BodySmall color={colors.greyDark}>{startCase(region)}</BodySmall>
            </CountryWrapper>
          )}
          <Subtitle2>{headerTitle}</Subtitle2>
        </div>
      }
      cols={headerColumns}
      rows={rows}
      data={data}
      emptyStateText={{
        header: emptyTitle,
        subHeading: EmptySubHeading,
      }}
      isAddingRow={false}
    />
  );
}
