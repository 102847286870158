import { colors } from 'constants/index';
import useDesktop from 'hooks/useDesktop';
import React, { CSSProperties, FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { device } from 'utils/breakpoints';
import { fadeIn } from 'utils/keyframes';
import { Icon } from './Icon';
import { ButtonLabel } from './TextStyles';

const ChildrenWrapper = styled.div`
  animation: ${fadeIn} 0.5s;
`;

const Toggle = styled.div<{ show: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0px;

  position: relative;
  left: -8px;

  @media ${device.desktopMin} {
    display: none;
  }

  > div {
    > svg {
      margin: -10px;
    }
    transition: transform 0.3s;
  }

  ${({ show }) => {
    if (show) {
      return css`
        margin-bottom: 5px;

        > div {
          transform: rotate(0.5turn);
        }
      `;
    }
  }}
`;

const Wrapper = styled.div`
  flex: none;
  width: 315px;
  height: fit-content;
  background: ${colors.white};
  border: 1px solid ${colors.black10a};
  border-radius: 5px;
  padding: 15px;
  position: sticky;
  top: 70px;

  @media ${device.tabletMax} {
    width: 100%;
    border-radius: 0px;
    border: 0;
    border-bottom: 1px solid ${colors.black10a};

    &::before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.6),
          rgba(255, 255, 255, 0.6)
        ),
        url('/strat-gradient.png'), #ffffff;
      opacity: 0.1;
    }
  }
`;

interface Props {
  className?: string;
  style?: CSSProperties;
}

export const SummaryBox: FC<Props> = ({ children, style, className }) => {
  const [show, setShow] = useState(true);
  const isDesktop = useDesktop();

  useEffect(() => {
    if (isDesktop) {
      setShow(true);
    }
  }, [isDesktop]);

  return (
    <Wrapper className={className} style={style}>
      <Toggle
        show={show}
        onClick={() => {
          setShow(!show);
        }}
      >
        <Icon name="Chevron-up" size={30} color={colors.greyDark} />
        <ButtonLabel>{show ? 'Hide' : 'Show'} details</ButtonLabel>
      </Toggle>
      {show && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </Wrapper>
  );
};
