import React from 'react';
import styled from 'styled-components/macro';

import { colors } from 'constants/index';
import { BodyNormal, BodySmall } from './TextStyles';

export const ToggleWrapper = styled.div<{
  textAlign: 'left' | 'right';
  isSmall: boolean;
}>`
  display: flex;
  > * + * {
    margin-left: ${({ isSmall }) => (isSmall ? 5 : 15)}px;
    margin-right: ${({ isSmall }) => (isSmall ? 5 : 15)}px;
  }
  flex-direction: ${({ textAlign }) =>
    textAlign === 'right' ? 'row-reverse' : 'row'};
`;

export const ToggleValue = styled.div<{ show: boolean; disable: boolean }>`
  flex: none;
  width: 35px;
  height: 20px;
  background: ${({ show, disable }) =>
    show ? (disable ? colors.purple30 : colors.purple) : colors.greyDark};
  border-radius: 15px;
  padding: 2px;
  cursor: ${({ disable }) => (!disable ? 'pointer' : 'default')};
  position: relative;
`;

const ToggleDial = styled.div<{
  show: boolean;
  disable: boolean;
}>`
  width: 50%;
  height: 100%;
  background: ${colors.white};
  border-radius: 15px;
  transform: translateX(${({ show }) => (show ? '100%' : 0)});
  transition: transform 0.1s;
`;

interface Props {
  disabled?: boolean;
  value: boolean;
  onChange(val: boolean): void;
  label: string;
  className?: string;
  textAlign?: 'right' | 'left';
  isSmall?: boolean;
  toggleClassName?: string;
  enableLabelClick?: boolean;
}

export const Toggle: React.FC<Props> = ({
  value,
  disabled = false,
  onChange,
  label,
  className,
  textAlign = 'left',
  isSmall = false,
  toggleClassName,
  enableLabelClick,
}) => {
  function onLabelClickHandler() {
    if (enableLabelClick) onChange?.(!value);
  }

  return (
    <ToggleWrapper
      className={className}
      textAlign={textAlign}
      isSmall={isSmall}
    >
      <ToggleValue
        className={toggleClassName}
        disable={disabled}
        show={value}
        onClick={() => {
          onChange(!value);
        }}
      >
        <ToggleDial disable={value} show={value} />
      </ToggleValue>
      {isSmall ? (
        <BodySmall
          style={{ pointerEvents: 'all', cursor: 'pointer' }}
          onClick={onLabelClickHandler}
        >
          {label}
        </BodySmall>
      ) : (
        <BodyNormal
          style={{ pointerEvents: 'all', cursor: 'pointer' }}
          onClick={onLabelClickHandler}
        >
          {label}
        </BodyNormal>
      )}
    </ToggleWrapper>
  );
};
