import { ButtonPill, Icon } from 'components/shared';
import { ButtonPillWrapper } from 'components/shared/ButtonPill';
import { colors } from 'constants/index';
import { useWidth } from 'hooks/useWidth';
import styled from 'styled-components/macro';

const EvaluationMobileButton = styled.div.attrs({
  'data-cy': 'lightning-button',
})<{ showEvaluation: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${({ showEvaluation }) =>
    showEvaluation ? colors.white : colors.coloredGradient};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 26px;
    background: ${({ showEvaluation }) =>
      showEvaluation ? colors.purplePink : colors.lightPinkGradient};
    border-radius: 50%;
  }
`;

export const EvaluateButtonWrapper = styled.div.attrs({
  'data-cy': 'lightning-button',
})<{ showEvaluation: boolean }>`
  height: 30px;
  border-radius: 25px;
  padding: 2px;
  background: ${({ showEvaluation }) =>
    showEvaluation ? colors.white : colors.coloredGradient};
  display: flex;
  align-items: center;

  ${ButtonPillWrapper} {
    justify-content: unset;
  }
`;

export function EvaluationButtons({
  showEvaluation,
  toggleEvaluationMode,
  text,
  className,
}: {
  showEvaluation: boolean;
  toggleEvaluationMode(): void;
  text?: string;
  className?: string;
}) {
  const width = useWidth();

  if (width < 491)
    return (
      <EvaluationMobileButton
        className={className}
        showEvaluation={showEvaluation}
        onClick={toggleEvaluationMode}
      >
        <Icon
          name="Lightning"
          size={26}
          fillColor={colors.white}
          color={showEvaluation ? colors.white : colors.purple}
        />
      </EvaluationMobileButton>
    );

  return (
    <EvaluateButtonWrapper
      className={className}
      showEvaluation={showEvaluation}
    >
      <ButtonPill
        iconName="Lightning"
        text={text ? text : showEvaluation ? 'Evaluating' : 'Evaluate'}
        onClick={toggleEvaluationMode}
        level={showEvaluation ? 'evaluating' : 'evaluate'}
        color={showEvaluation ? colors.white : colors.black}
        clickClassName="cypress-competitive-landscape-evaluation-open"
      />
    </EvaluateButtonWrapper>
  );
}
