import { colors } from 'constants/index';
import { User } from 'data/graphql/generated';
import { validate } from 'email-validator';
import { FormikHandlers } from 'formik/dist/types';
import _ from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { BodySmall, ButtonLabel, Caption } from '.';
import { DropdownList, DropdownOption } from './FormDropdown';
import FormTextInput from './FormTextInput';

const ExistingLeadInputWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const ExistingLeadInput = styled.div`
  width: 100%;
  height: 45px;
  padding: 5px 15px;
  border: 1.5px solid ${colors.purple};
  border-radius: 5px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
`;

const StyledDropdownOption = styled(DropdownOption)<{ invalid?: boolean }>`
  padding: 0;
  height: auto;
  justify-content: space-between;
  min-height: 45px;
  > div {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 5px 15px;
    opacity: ${({ invalid }) => (invalid ? 0.5 : 1)};
  }
`;

const NoHoverDropdownOption = styled(StyledDropdownOption)<{
  invalid?: boolean;
}>`
  :hover {
    background-color: unset;
  }

  ${ButtonLabel} {
    cursor: ${({ invalid }) => (invalid ? 'not-allowed' : 'pointer')};
  }
`;

const StyledDropdownList = styled(DropdownList)`
  border-top: 1px solid #141427;
  max-height: 230px;
  top: 45px;
  cursor: default;
  :hover {
    cursor: default;
  }
`;

interface Props {
  disabledState: boolean;
  setDisabledState(value: boolean): void;
  onDisabledChange(value: boolean): void;
  emailFieldValue: string;
  nameValue: string;
  usersForDropdown?: (User | null | undefined)[];
  handleValueChange: FormikHandlers['handleChange'];
  emailErrorMessage: string | undefined;
  usersToExclude: (User | null | undefined)[];
  onOptionSelected(user: User): void;
  excludedUsersMessage: string;
  onSelectedItemChange(): void;
  existingUserInputMessage?: string;
}

export const TextInputWithDropdownAndSelection = ({
  usersForDropdown,
  disabledState,
  setDisabledState,
  onDisabledChange,
  emailFieldValue,
  nameValue,
  handleValueChange,
  emailErrorMessage,
  usersToExclude,
  onOptionSelected,
  excludedUsersMessage,
  onSelectedItemChange,
  existingUserInputMessage,
}: Props) => {
  const [
    showExistingUserInputWrapper,
    setShowExistingUserInputWrapper,
  ] = useState(false);

  const [matchingUsers, setMatchingUsers] = useState(usersForDropdown || []);

  const [ShowMatchingUsersDropdown, setShowMatchingUsersDropdown] = useState(
    false
  );

  if (!usersForDropdown) {
    return null;
  }

  return (
    <>
      {!showExistingUserInputWrapper ? (
        <FormTextInput
          autocomplete={'off'}
          showDisableToggle={disabledState}
          onDisabledChange={onDisabledChange}
          disabled={disabledState}
          value={emailFieldValue}
          name="email"
          title="Email address"
          type="text"
          onBlur={() => {}}
          onChange={(e) => {
            setShowMatchingUsersDropdown(!!e.target.value);
            setMatchingUsers(
              usersForDropdown.filter((user) =>
                user?.email
                  ?.toLocaleLowerCase()
                  .startsWith(e.target.value.toLocaleLowerCase())
              )
            );
            handleValueChange(e);
          }}
          errorMessage={emailErrorMessage}
        />
      ) : null}

      {ShowMatchingUsersDropdown &&
      !disabledState &&
      !showExistingUserInputWrapper ? (
        <StyledDropdownList>
          {matchingUsers.length ? (
            _.orderBy(
              matchingUsers,
              [
                (user) => {
                  return usersToExclude.some(
                    (excludedUser) => excludedUser?.id === user?.id
                  );
                },
                'name',
              ],
              ['asc', 'asc']
            ).map((user) => {
              const excludeUser = usersToExclude.some(
                (excludedUser) => excludedUser?.id === user?.id
              );
              return (
                <StyledDropdownOption
                  key={user?.id}
                  disableHover={excludeUser}
                  onClick={() => {
                    if (user && !excludeUser) {
                      setShowMatchingUsersDropdown(false);
                      setShowExistingUserInputWrapper(true);
                      onOptionSelected(user);
                    }
                  }}
                  isSelected={false}
                >
                  <div style={{ overflow: 'hidden' }}>
                    <ButtonLabel
                      style={{
                        maxWidth: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {user?.name}
                    </ButtonLabel>
                    <Caption color={colors.greyDark}>{user?.email}</Caption>
                  </div>
                  {excludeUser ? (
                    <Caption
                      style={{ paddingRight: 15, flexShrink: 0 }}
                      color={colors.greyDark}
                    >
                      {excludedUsersMessage}
                    </Caption>
                  ) : null}
                </StyledDropdownOption>
              );
            })
          ) : (
            // Type the full email to invite
            <NoHoverDropdownOption
              disableHover={!validate(emailFieldValue)}
              invalid={!validate(emailFieldValue)}
              isSelected={false}
            >
              <div>
                <BodySmall color={colors.greyDark}>
                  Type the full email to invite
                </BodySmall>
                <ButtonLabel
                  className={'cypress-invite-button'}
                  onClick={() => {
                    if (validate(emailFieldValue)) {
                      // show name field and disable email field
                      setDisabledState(true);
                    }
                  }}
                  color={colors.purple}
                >
                  {`+ Invite “${emailFieldValue}” via email`}
                </ButtonLabel>
              </div>
            </NoHoverDropdownOption>
          )}
        </StyledDropdownList>
      ) : null}

      {showExistingUserInputWrapper ? (
        <SelectedNameEmailPreview
          nameValue={nameValue}
          emailFieldValue={emailFieldValue}
          onClick={() => {
            onSelectedItemChange();
            setShowExistingUserInputWrapper(false);
          }}
          existingUserInputMessage={existingUserInputMessage}
        />
      ) : null}
    </>
  );
};

interface SelectedNameEmailPreviewProps {
  nameValue: string;
  emailFieldValue: string;
  onClick(): void;
  existingUserInputMessage?: string;
  readOnly?: never;
  className?: string;
}

interface SelectedNameEmailPreviewReadOnlyProps {
  nameValue: string;
  emailFieldValue: string;
  onClick?(): never;
  existingUserInputMessage?: never;
  readOnly: boolean;
  className?: string;
}

export const SelectedNameEmailPreview = ({
  nameValue,
  emailFieldValue,
  onClick,
  existingUserInputMessage,
  readOnly,
  className,
}: SelectedNameEmailPreviewProps | SelectedNameEmailPreviewReadOnlyProps) => {
  return (
    <ExistingLeadInputWrapper
      className={className}
      style={{ pointerEvents: readOnly ? 'none' : 'auto' }}
    >
      <ExistingLeadInput>
        <div>
          <ButtonLabel>{nameValue}</ButtonLabel>
          <Caption color={colors.greyDark}>{emailFieldValue}</Caption>
        </div>
        {!readOnly && (
          <ButtonLabel
            onClick={() => {
              onClick?.();
            }}
          >
            Change
          </ButtonLabel>
        )}
      </ExistingLeadInput>
      {!readOnly && !!existingUserInputMessage ? (
        <BodySmall
          style={{
            textAlign: 'left',
            marginTop: '5px',
            color: colors.greyDark,
          }}
        >
          {existingUserInputMessage}
        </BodySmall>
      ) : null}
    </ExistingLeadInputWrapper>
  );
};
