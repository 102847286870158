import {
  DeleteConfirmation,
  DeleteConfirmationBody,
  DeleteConfirmationFooter,
} from 'components/Insight';
import { Loading } from 'components/Loading';
import {
  BodyNormal,
  BodySmall,
  ButtonLabel,
  Caption,
  Collaboration,
  Icon,
  TooltipWrapper,
} from 'components/shared';
import { DeleteIcon } from 'components/shared/DeleteIcon';
import FocusDropdown from 'components/shared/FocusDropdown';
import { RationaleModal } from 'components/shared/RationaleModal';
import { colors } from 'constants/colors';
import {
  CommercialTacticsDocument,
  KeyStatementFragment,
  useCommercialTacticDeleteMutation,
  useCommercialTacticUpdateMutation,
  useGlobalCommercialTacticRegionStatusUpdateMutation,
} from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import useManageFocusRationale from 'hooks/useManageFocusRationale';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import {
  apolloDeleteHelper,
  apolloUpdateHelper,
} from 'utils/apolloQueryHelpers';
import { StyledTwoButtonModal } from '../../containers/MedicalStrategy/index.style';
import { CommercialTacticFragmentWithGlobalFocusedStatus } from '../../types';
import { getKeyTacticsQueryVars } from './utils';

const GlobalFocusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Wrapper = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.greyMedium};
  border-radius: 5px;
  width: 325px;
  padding: 15px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PinAndCaption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextContainer = styled.div`
  margin: 5px 0;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`;

const TickWrapper = styled.div`
  overflow: hidden;
  width: 12px;

  display: flex;
  justify-content: center;
`;

const StyledDeleteConfirmation = styled(DeleteConfirmation)``;

interface URLParams {
  strategyId: string;
  strategicImperativeId: string;
}

interface Props {
  tacticData?: CommercialTacticFragmentWithGlobalFocusedStatus;
  strategicObjective?: KeyStatementFragment;
  isLocalView: boolean;
  region: string;
  isViewingOwnRegion: boolean;
}

export const TacticCallout: React.FC<Props> = ({
  tacticData,
  strategicObjective,
  isLocalView,
  region,
  isViewingOwnRegion,
}) => {
  const [
    showCountryAlreadyFocusedModal,
    setShowCountryAlreadyFocusedModal,
  ] = useState(false);
  const { strategyId, strategicImperativeId } = useParams<URLParams>();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [deleteCommercialTactic] = useCommercialTacticDeleteMutation();
  const [updateCommercialTactic] = useCommercialTacticUpdateMutation();

  const [
    updateGlobalCommercialTacticRegionStatus,
  ] = useGlobalCommercialTacticRegionStatusUpdateMutation();
  const [focused, setFocused] = useState(tacticData?.focused || false);

  const isDesktop = useDesktop();
  const queryVars = useMemo(
    () => getKeyTacticsQueryVars(strategyId, strategicImperativeId),
    [strategyId, strategicImperativeId]
  );

  // Focus rationale modal logic
  const {
    modalSlideIdx,
    setModalSlideIdx,
    setShowSingleSlide,
  } = useManageFocusRationale();

  const onFocusClick = async () => {
    setShowCountryAlreadyFocusedModal(false);
    if (!tacticData?.id) return;

    if (!!focused) {
      try {
        setFocused(false);
        if (isLocalView && tacticData.isGlobalLocalTactic) {
          const commercialTacticId = tacticData.commercialTacticId;
          if (!commercialTacticId) return;
          await updateGlobalCommercialTacticRegionStatus({
            variables: {
              where: {
                commercialTacticId: commercialTacticId,
                region: region,
              },
              data: {
                strategyId: +strategyId,
                focused: false,
                focusRationale: '',
              },
            },
          });
        } else {
          await updateCommercialTactic({
            variables: {
              localUid: tacticData.localUid,
              data: {
                focused: false,
                focusRationale: '',
              },
            },
            update: apolloUpdateHelper({
              responseField: 'commercialTacticUpdate',
              query: CommercialTacticsDocument,
              queryVars,
              queryName: 'commercialTactics',
            }),
            optimisticResponse: {
              commercialTacticUpdate: {
                ...tacticData,
                focused: false,
                focusRationale: '',
              },
            },
          });
        }
      } catch (error) {
        setFocused(false);
        alert('Something went wrong');
      }
    } else {
      setModalSlideIdx(1);
    }
  };

  async function handleSubmit(rationale: string) {
    if (!tacticData?.id) return;

    try {
      setFocused(true);
      if (isLocalView && tacticData.isGlobalLocalTactic) {
        const commercialTacticId = tacticData.commercialTacticId;

        if (!commercialTacticId) return;

        await updateGlobalCommercialTacticRegionStatus({
          variables: {
            where: {
              commercialTacticId: commercialTacticId,
              region: region,
            },
            data: {
              strategyId: +strategyId,
              focused: true,
            },
          },
        });
      } else {
        await updateCommercialTactic({
          variables: {
            localUid: tacticData.localUid,
            data: {
              focused: true,
              focusRationale: rationale,
            },
          },
          update: apolloUpdateHelper({
            responseField: 'commercialTacticUpdate',
            query: CommercialTacticsDocument,
            queryVars,
            queryName: 'commercialTactics',
          }),
          optimisticResponse: {
            commercialTacticUpdate: {
              ...tacticData,
              focused: true,
              focusRationale: rationale,
            },
          },
        });
      }
    } catch (error) {
      setFocused(focused);
      alert('Something went wrong');
    }
  }

  useEffect(() => {
    setFocused(tacticData?.focused || false);
  }, [tacticData]);

  if (!strategicObjective || !tacticData)
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    );

  const cannotFocus = !(isLocalView && !isViewingOwnRegion);

  return (
    <Wrapper className="callout">
      <StyledTwoButtonModal
        modalOpen={showCountryAlreadyFocusedModal}
        handleClose={() => setShowCountryAlreadyFocusedModal(false)}
        title="Already focused at the global level"
        body="Adding country focus may duplicate effort. Are you sure?"
        rightButtonText="Add country focus"
        leftButtonOnClick={() => setShowCountryAlreadyFocusedModal(false)}
        rightButtonOnClick={onFocusClick}
      />

      <RationaleModal
        modalSlideIdx={modalSlideIdx}
        setModalSlideIdx={setModalSlideIdx}
        handleClose={() => {
          setModalSlideIdx(null);
        }}
        handleSubmit={handleSubmit}
        rationaleText={tacticData?.focusRationale || ''}
        showSingleSlide={true}
        DetailComponent={
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <span style={{ display: 'flex', gap: 5 }}>
              <BodySmall color={colors.greyDark} style={{ flexShrink: 0 }}>
                Strategic objective:
              </BodySmall>
              <BodySmall>
                {strategicObjective.text || 'Not identified yet'}
              </BodySmall>
            </span>

            <BodySmall>{tacticData.text}</BodySmall>
          </div>
        }
      />
      {showConfirmDelete && (
        <StyledDeleteConfirmation show={true}>
          <div>
            <BodyNormal color={colors.black}>Delete this tactic?</BodyNormal>
          </div>
          <DeleteConfirmationBody>
            <BodyNormal color={colors.black60}>
              Any discussion and files will be lost.
            </BodyNormal>
          </DeleteConfirmationBody>
          <DeleteConfirmationFooter>
            <ButtonLabel onClick={() => setShowConfirmDelete(false)}>
              Cancel
            </ButtonLabel>
            <ButtonLabel
              color={colors.red}
              onClick={async (e) => {
                try {
                  await deleteCommercialTactic({
                    variables: { id: tacticData.id },
                    update: apolloDeleteHelper({
                      responseField: 'commercialTacticDelete',
                      query: CommercialTacticsDocument,
                      queryVars,
                      queryName: 'commercialTactics',
                    }),
                  });
                } catch (error) {
                  alert('Something went wrong');
                }
              }}
            >
              Delete
            </ButtonLabel>
          </DeleteConfirmationFooter>
        </StyledDeleteConfirmation>
      )}

      <FlexContainer>
        {isDesktop ? (
          <Caption color={colors.greyDark}>{strategicObjective.text}</Caption>
        ) : (
          <PinAndCaption>
            <Icon
              name="Pin"
              size={30}
              color={colors.purple10}
              style={{ marginRight: 5 }}
            />
            <Caption color={colors.greyDark}>Tap grid to position</Caption>
          </PinAndCaption>
        )}

        {cannotFocus && (
          <FocusDropdown
            onClick={
              !focused && tacticData.isGlobalFocused && isLocalView
                ? () => setShowCountryAlreadyFocusedModal(true)
                : onFocusClick
            }
            checked={focused}
            disabled={!isViewingOwnRegion}
            rationaleText={tacticData.focusRationale}
            setModalSlideIdx={setModalSlideIdx}
            setShowSingleSlide={setShowSingleSlide}
            isLocal={isLocalView}
            region={region}
          />
        )}
      </FlexContainer>

      <TextContainer>
        {!isDesktop && (
          <Caption color={colors.greyDark} style={{ marginRight: 15 }}>
            {strategicObjective.text}
          </Caption>
        )}
        <BodySmall>{tacticData.text}</BodySmall>
      </TextContainer>
      <FlexContainer>
        {!isLocalView && !showConfirmDelete && isDesktop && (
          <TooltipWrapper text="Delete tactic" effect="solid">
            <DeleteIcon onClick={() => setShowConfirmDelete(true)} />
          </TooltipWrapper>
        )}

        {isLocalView && (
          <FocusedTicks
            isGlobalFocused={Boolean(tacticData.isGlobalFocused)}
            isLocalFocused={Boolean(tacticData.isLocalFocused)}
          />
        )}
        <Collaboration collaboration={tacticData.collaboration} />
      </FlexContainer>
    </Wrapper>
  );
};

const FocusedTicks = ({
  isGlobalFocused,
  isLocalFocused,
}: {
  isGlobalFocused: boolean;
  isLocalFocused: boolean;
}) => {
  switch (true) {
    case isGlobalFocused && isLocalFocused:
      return (
        <GlobalFocusWrapper>
          <div style={{ display: 'flex' }}>
            <TickWrapper>
              <Icon className="tick" name="GradientTick" size={30} />
            </TickWrapper>
            <TickWrapper>
              <Icon className="tick" name="GradientBlueTick" size={30} />
            </TickWrapper>
          </div>
          <BodySmall>Global & Country focus</BodySmall>
        </GlobalFocusWrapper>
      );

    case isGlobalFocused:
      return (
        <GlobalFocusWrapper>
          <TickWrapper>
            <Icon className="tick" name="GradientTick" size={30} />
          </TickWrapper>
          <BodySmall>Global focus</BodySmall>
        </GlobalFocusWrapper>
      );

    case isLocalFocused:
      return (
        <GlobalFocusWrapper>
          <TickWrapper>
            <Icon className="tick" name="GradientBlueTick" size={30} />
          </TickWrapper>
          <BodySmall>Country focus</BodySmall>
        </GlobalFocusWrapper>
      );

    default:
      return null;
  }
};
