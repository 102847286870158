import React from 'react';
import styled from 'styled-components/macro';
import { FutureTrendFragment } from 'data/graphql/generated';

import { Icon, BodySmall } from 'components/shared';
import { colors } from 'constants/colors';

const Wrapper = styled.div`
  position: relative;
  width: -webkit-fill-available;
`;

const Content = styled.div`
  background: ${colors.white};
  border-radius: 5px;
  padding: 15px;
  width: -webkit-fill-available;
  margin: -5px;
`;

const FocusWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-left: -10px;
`;

const Section = styled.section`
  margin-bottom: 15px;
  &:last-of-type {
    margin-bottom: 0px;
  }
`;

interface Props {
  trend: FutureTrendFragment;
}

export const FutureTrend: React.FC<Props> = ({ trend }) => {
  return (
    <Wrapper>
      <Content>
        {trend.focus ? (
          <FocusWrapper>
            <Icon name="Tick" color={colors.purple} size={30} />
            <BodySmall>Focus</BodySmall>
          </FocusWrapper>
        ) : null}

        <Section>
          <BodySmall color={colors.greyDark}>Future trend / Change</BodySmall>
          <BodySmall>{trend.title}</BodySmall>
        </Section>

        <Section>
          <BodySmall color={colors.greyDark}>Year of impact</BodySmall>
          <BodySmall>
            {trend.year === 0 ? 'Immediately' : `Year ${trend.year}`}
          </BodySmall>
        </Section>

        {trend.focus ? (
          <Section>
            <BodySmall color={colors.greyDark}>Opportunity / Risk</BodySmall>
            <BodySmall>{trend.risk}</BodySmall>
          </Section>
        ) : null}
      </Content>
    </Wrapper>
  );
};
