import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import {
  sidebarTransition,
  sidebarWidth,
} from 'components/shared/ImperativeSummarySidebar';
import { StepHeaderBar } from 'components/shared';
import { colors } from 'constants/index';
import { Page, Wrapper } from 'components/Page';
import { Wrapper as LoadingWrapper } from 'components/Loading';

// Note to devs: These components are used across multiple pages & viewports. It's inadvisable to change the styles here
// If you need to change a style, import the component and style it on the relevant page

export const SidebarMobileWrapper = styled.div<{
  totalNavHeight: number;
}>`
  width: 100%;
  margin-top: ${({ totalNavHeight }) => `${totalNavHeight}px`};
  position: relative;
  overflow-x: hidden;

  ${LoadingWrapper} {
    margin-top: 0;
    padding-top: 80px;
  }
`;

export const SidebarDesktopWrapper = styled.div<{
  sidebarOpen: boolean;
  isRelative: boolean;
  totalNavHeight: number;
}>`
  min-height: ${({ totalNavHeight }) => `calc(100vh - ${totalNavHeight}px)`};
  @media ${device.tabletMin} {
    position: ${({ isRelative }) => isRelative && 'relative'};
  }
`;

export const StepHeader = styled(StepHeaderBar)`
  margin-top: 0;
  display: block;
  padding: 15px 20px 0px;
  border-bottom: 0.5px solid ${colors.greyMedium};
  min-height: auto;
  overflow: visible;
`;

export const PageWrapper = styled(Page)<{ totalNavHeight: number }>`
  padding: 0;

  ${Wrapper} {
    width: 100%;
    top: ${({ totalNavHeight }) => `${totalNavHeight}px`};

    @media ${device.tabletMin} {
      width: 100%;
      display: flex;
      top: ${({ totalNavHeight }) => `${totalNavHeight}px`};
    }
  }
`;

export const PostItsPageWrapper = styled(PageWrapper)<{
  sidebarOpen: boolean;
  overlapWidth: number;
}>`
  @media ${device.tabletMin} {
    width: ${({ sidebarOpen }) => (sidebarOpen ? '405px' : '100%')};
    transition: width ${sidebarTransition.duration}ms
      ${sidebarTransition.timingFunction};
  }

  @media (min-width: ${({ overlapWidth }) => overlapWidth}px) {
    width: auto;
  }
`;

// Note on the media query: Once the content has shrunk below the defined minimum, overlap the Sidebar
// The breakpoints are outlined here: https://app.asana.com/0/1201421143011015/1201310306744698/f

export const MainContent = styled.div<{
  sidebarOpen: boolean;
  overlapWidth?: number;
}>`
  width: 100%;
  padding: 15px 0;

  @media ${device.desktopMin} {
    padding: 20px;
  }

  @media ${({ overlapWidth }) =>
      `(min-width: ${overlapWidth ? overlapWidth : 945}px)`} {
    width: ${({ sidebarOpen }) =>
      sidebarOpen && `calc(100% - ${sidebarWidth}px)`};

    transition: width ${sidebarTransition.duration}ms
      ${sidebarTransition.timingFunction};
  }
`;
