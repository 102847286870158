import { useLayoutEffect, useState } from 'react';
import { useWidth } from './useWidth';

export const useGetNavHeight = () => {
  const [totalHeight, setTotalHeight] = useState(0);
  const windowWidth = useWidth();

  useLayoutEffect(() => {
    const desktopNav: HTMLDivElement | null = document.querySelector(
      '.main__desktopNav'
    );
    const mobileNav: HTMLDivElement | null = document.querySelector(
      '.main__mobileNav'
    );
    const secondaryNav: HTMLDivElement | null = document.querySelector(
      '.main__secondaryNav'
    );

    const totalHeight =
      (desktopNav?.offsetHeight || 0) +
      (mobileNav?.offsetHeight || 0) +
      (secondaryNav?.offsetHeight || 0);

    setTotalHeight(totalHeight);
  }, [windowWidth]);

  return totalHeight;
};
