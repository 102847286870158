import { colors } from 'constants/colors';
import React from 'react';
import styled from 'styled-components';
import { ErrorMessage, FormField } from './FormField';
import { ButtonLabel } from './TextStyles';

export const Input = styled.input<{ disabled?: boolean; error?: boolean }>`
  width: 100%;
  height: 50px;
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  border-color: ${({ disabled, error }) =>
    disabled ? colors.black50 : error ? colors.darkRed : colors.greyDark};

  padding: 0 15px;
  color: ${({ disabled }) => (disabled ? colors.black50 : colors.black)};
  font-size: 16px;
  line-height: 20px;
  background: ${colors.white};

  :focus {
    outline: none;
    border-color: ${({ error }) => (error ? colors.darkRed : colors.black)};
  }
`;

export const ShowPasswordButton = styled(ButtonLabel)<{ onClick: any }>`
  position: absolute;
  top: 15px;
  right: 15px;
`;

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
  title: string;
  disabled?: boolean;
  errorMessage?: string;
  placeholder?: string;
  className?: string;
}

const FormPasswordInput: React.FC<Props> = ({
  onChange,
  value,
  name,
  title,
  disabled,
  errorMessage,
  placeholder,
  className,
}) => {
  const [passwordRevealed, setPasswordRevealed] = React.useState<boolean>(
    false
  );

  return (
    <FormField name={name} title={title} disabled={disabled}>
      <Input
        disabled={disabled}
        id={name}
        name={name}
        type={passwordRevealed ? 'text' : 'password'}
        onChange={onChange}
        value={value}
        error={!!errorMessage}
        placeholder={placeholder}
        className={className}
      />
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <ShowPasswordButton
        onClick={() => setPasswordRevealed(!passwordRevealed)}
      >
        {passwordRevealed ? 'Hide' : 'Show'}
      </ShowPasswordButton>
    </FormField>
  );
};

export default FormPasswordInput;
