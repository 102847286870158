import { colors } from 'constants/colors';
import React, { MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  background: ${colors.purplePinkGradient};
  border-radius: 5px;
  padding: 1px;
`;

export const FadedGradientContainer = styled.div`
  background: ${colors.lightPinkGradient};
  border-radius: 4px;
  padding: 10px;
`;
const Container = styled.div`
  border-radius: 4px;
  overflow: hidden;
  background: ${colors.white};
  padding: 10px;
  width: 100%;
`;

interface Props {
  children: ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onMouseEnter?: MouseEventHandler<HTMLDivElement> | undefined;
  onMouseLeave?: MouseEventHandler<HTMLDivElement> | undefined;
  id?: string;
}

export const GradientCard: React.FC<Props> = ({
  children,
  className,
  style,
  onMouseEnter,
  onMouseLeave,
  id,
}) => {
  return (
    <Wrapper
      id={id}
      className={className}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <FadedGradientContainer>{children}</FadedGradientContainer>
    </Wrapper>
  );
};

export const GradientBorderCard: React.FC<Props> = ({
  children,
  className,
  style,
}) => {
  return (
    <Wrapper className={className} style={style}>
      <Container>{children}</Container>
    </Wrapper>
  );
};
