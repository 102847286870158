import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';

import {
  CriticalMetricGoalInput,
  TrackedGoalFragment,
} from 'data/graphql/generated';
import {
  Icon,
  Collaboration,
  ReusableSidebar,
  Subtitle2,
  StepButton,
} from 'components/shared';
import { colors } from 'constants/colors';

import { useModal } from 'hooks';
import { useAuthContext } from 'contexts/AuthContext';
import { verifyUserRole } from 'utils/verifyUserRole';
import { GoalTrackingSidebarProgressDonut } from '../TrackedGoal/GoalTrackingSidebarProgressDonut';
import AddGoalTrackingModal from '../feature-add-goal-tracking-modal/AddGoalTrackingModal';
import {
  useGetGoalTracking,
  useGoalTrackingPeriod,
} from '../data-access-goal-tracking';
import { polling } from 'constants/polling';
import { CountryConstants } from 'types';
import { formatDateByFrequency } from '../util-goal-tracking';
import {
  GoalPeriodTrackings,
  AddTrackingButton,
} from '../ui-goal-tracking-sidebar';
import { device } from 'utils/breakpoints';
import { uniqBy } from 'lodash';

const CollapseIcon = styled(Icon)`
  cursor: pointer;

  &:hover {
    background: ${colors.black10};
    border-radius: 5px;
  }
`;

const StyledReusableSidebar = styled(ReusableSidebar)`
  background: ${colors.black05OnWhiteGradient};
  position: fixed;

  @media ${device.tabletMin} {
    width: ${({ isOpen }) => (isOpen ? '430px' : '0px')};
    min-width: ${({ isOpen }) => (isOpen ? '430px' : '0px')};
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  z-index: 1;
  position: relative;

  @media ${device.tabletMin} {
    width: 430px;
    min-width: 430px;
    overflow-y: hidden;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 61px;
  background: ${colors.white};
  padding: 15px;
`;

const StyledHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledHeaderOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledPeriodContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  @media ${device.tabletMin} {
    width: 400px;
  }
`;

const PeriodTitleAndTrackButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PreviousPeriodButton = styled(StepButton)`
  button:hover {
    background: ${colors.black10};
  }

  div {
    color: ${colors.black};
  }
`;

const NextPeriodButton = styled(StepButton)<{ isLatestPeriod: boolean }>`
  button:hover {
    background: ${({ isLatestPeriod }) => !isLatestPeriod && colors.black10};
  }

  div {
    cursor: pointer;
    color: ${({ isLatestPeriod }) =>
      isLatestPeriod ? colors.greyMedium : colors.black};
  }
`;

interface GoalTrackingModalProps {
  isOpen: boolean;
  onClose: () => void;
  heightOffset: number;
  trackedGoal: TrackedGoalFragment;
  supportedCountries: string[];
}

export const GoalTrackingSidebar: React.FC<GoalTrackingModalProps> = ({
  isOpen,
  onClose,
  heightOffset,
  trackedGoal,
  supportedCountries,
}) => {
  const [{ user }] = useAuthContext();
  const { isLead, isGlobalContributor } = verifyUserRole(
    user?.role,
    user?.country
  );
  const {
    next,
    period,
    date: periodDate,
    offset: periodOffset,
    previous,
    isLatestPeriod,
  } = useGoalTrackingPeriod(trackedGoal.frequency);
  const {
    trackings: allTrackings,
    startPolling,
    stopPolling,
  } = useGetGoalTracking(
    +trackedGoal.id,
    trackedGoal.input,
    supportedCountries
  );
  const {
    isOpen: isTrackingModalOpen,
    open: openTrackingModal,
    close: closeTrackingModal,
  } = useModal();
  const currentPeriodTracking = useMemo(
    () => allTrackings.find((t) => t.period === period)?.items || [],
    [allTrackings, period]
  );

  const countryAverage = !!currentPeriodTracking.length
    ? currentPeriodTracking.map((t) => t.average).reduce((a, b) => a + b, 0) /
      currentPeriodTracking.length
    : undefined;

  const globalAverage = currentPeriodTracking.find(
    (t) => t.region === CountryConstants.GLOBAL
  )?.average;

  const periodAverage =
    trackedGoal.input === CriticalMetricGoalInput.GlobalOnly
      ? globalAverage
      : countryAverage;

  const handleClose = () => onClose();

  useEffect(() => {
    startPolling(polling.default);

    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  return (
    <>
      <AddGoalTrackingModal
        key={trackedGoal.id}
        trackedGoalId={trackedGoal.id}
        input={trackedGoal.input}
        isOpen={isTrackingModalOpen}
        period={period}
        periodDate={periodDate}
        periodOffset={periodOffset}
        onClose={closeTrackingModal}
      />
      <StyledReusableSidebar
        isOpen={isOpen}
        heightOffset={heightOffset}
        onClickOutside={handleClose}
        ignoreClickClasses={[
          'modal-form-background',
          'modal-add-tracking-form',
        ]}
      >
        <StyledContainer>
          <StyledHeader>
            <StyledHeaderTitle>
              <Icon name="Target" size={30} color={colors.purple} />
              <Subtitle2>Goal tracking</Subtitle2>
            </StyledHeaderTitle>
            <StyledHeaderOptions>
              <Collaboration collaboration={trackedGoal.collaboration} />
              <CollapseIcon
                onClick={handleClose}
                name="Collapse right"
                size={30}
                color={colors.purple}
              />
            </StyledHeaderOptions>
          </StyledHeader>

          <GoalTrackingSidebarProgressDonut
            goal={trackedGoal.title}
            progress={periodAverage}
            target={trackedGoal.target}
            input={trackedGoal.input}
            period={formatDateByFrequency(periodDate, trackedGoal.frequency)}
            numberOfSupportedCountries={supportedCountries.length}
            numberOfCountriesWithTracking={
              uniqBy(currentPeriodTracking, 'region').length
            }
          />

          <StyledPeriodContainer>
            <PreviousPeriodButton direction="l" onClick={previous} />

            <PeriodTitleAndTrackButton>
              <Subtitle2 color={colors.black}>{period}</Subtitle2>

              <AddTrackingButton
                onClick={openTrackingModal}
                isHidden={
                  isGlobalContributor ||
                  (!isLead &&
                    trackedGoal.input === CriticalMetricGoalInput.GlobalOnly)
                }
              >
                Track
              </AddTrackingButton>
            </PeriodTitleAndTrackButton>

            <NextPeriodButton
              isLatestPeriod={isLatestPeriod}
              direction="r"
              onClick={next}
              disabled={isLatestPeriod}
            />
          </StyledPeriodContainer>

          <GoalPeriodTrackings
            trackings={currentPeriodTracking}
            isLead={
              isLead && trackedGoal.input === CriticalMetricGoalInput.GlobalOnly
            }
            goalTarget={trackedGoal.target}
            goalInput={trackedGoal.input}
            supportedCountries={supportedCountries}
          />
        </StyledContainer>
      </StyledReusableSidebar>
    </>
  );
};
