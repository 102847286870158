import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { QueryOptions } from '@apollo/client';

import { useAuthContext } from 'contexts/AuthContext';
import {
  BodyNormal,
  ButtonLabel,
  BodySmall,
  Caption,
  CountryFlag,
  Icon,
  TooltipWrapper,
} from './shared';
import { formatCreatedDate } from 'utils/formatCreatedDate';
import { colors } from 'constants/colors';
import { zIndex } from 'constants/zIndex';
import {
  Comment as CommentType,
  CollaborationDocument,
  useCommentDeleteMutation,
  useCommentUpdateMutation,
  User,
  Attachment,
} from 'data/graphql/generated';
import { CommentForm } from './CommentForm';
import { device } from 'utils/breakpoints';
import { countryCode } from 'utils/countryCode';
import { Country } from 'types';
import { AttachmentPreview } from './Collaboration/Attachment';
import { getUserTitleOrCountryAndName } from 'utils/getUserTitleOrCountryAndName';
import { verifyUserRole } from 'utils/verifyUserRole';

const CommentView = styled.article<{
  resolved: boolean;
  isReply: boolean;
  replyIdx?: number;
  showDeleteConfirm?: boolean;
}>`
  position: relative;
  background: ${({ resolved }) => (resolved ? colors.black05 : 'transparent')};

  ${({ isReply, replyIdx, showDeleteConfirm }) =>
    isReply
      ? css`
          border: 0.5px solid transparent;
          margin: 0px 15px 0px 15px;
          padding-top: 15px;
          ${!!replyIdx && !showDeleteConfirm && replyIdx > 0
            ? `border-top: 0.5px solid ${colors.black30};`
            : null}
        `
      : css`
          border: 0.5px solid ${colors.black30};
          border-radius: 5px;
          padding: 15px;
        `};

  margin-bottom: 10px;
  overflow: hidden;
`;

const CommentHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const CommentUser = styled.div`
  display: flex;
  flex-direction: row;
`;

const CommentCountryDesktop = styled.div`
  display: none;
  @media ${device.tabletMin} {
    display: block;
  }
`;

const CommentCountryMobile = styled.div`
  display: block;
  @media ${device.tabletMin} {
    display: none;
  }
`;

const CommentActions = styled.div`
  display: flex;
  flex-direction: row;
`;

const CommentResolve = styled.div<{ resolved: boolean }>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1.5px solid ${colors.purple};
  background: ${({ resolved }) => (resolved ? colors.purple : colors.purple10)};
  margin-left: 10px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardDeleteConfirm = styled.div<{ visible: boolean; isReply: boolean }>`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: ${({ isReply }) =>
    isReply ? zIndex.commentReplyDelete : zIndex.commentDelete};
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  background: ${colors.white};
  text-align: center;
  padding: 10px 35px;
  align-items: center;
  justify-content: center;
  border: 0.5px solid
    ${({ isReply }) => (isReply ? colors.black30 : 'transparent')};
  border-radius: 5px;

  @media ${device.mobile} {
    padding: 35px 15px;
  }
`;

const CardDeleteConfirmTitle = styled(BodyNormal)`
  margin-bottom: 4px;
  color: ${colors.black};
`;

const CardDeleteConfirmSubTitle = styled(BodyNormal)`
  margin-bottom: 4px;
  color: ${colors.black60};
`;

const CardDeleteConfirmCancel = styled(ButtonLabel)`
  display: inline-block;
`;

const CardDeleteConfirmConfirm = styled(ButtonLabel)`
  color: ${colors.red};
  display: inline-block;
`;

const CardDeleteCTA = styled.div`
  display: flex;
  justify-content: space-around;
`;

const ReplyAction = styled(BodySmall)`
  text-decoration: underline;
  color: ${colors.greyDark};
  margin-top: 15px;
  cursor: pointer;
`;

const CommentCreate = styled.div`
  margin-bottom: 10px;
  position: relative;
  padding-left: 35px;
`;

const CountryFlagWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 10px;
`;

export type CommentRefetchQueryVarsType = Omit<QueryOptions, 'query'>;

interface Props {
  collaborationId?: number;
  comment?: CommentType;
  queryVars: CommentRefetchQueryVarsType;
  isReply?: boolean;
  replyIdx?: number;
}

export const Comment: React.FC<Props> = ({
  comment,
  collaborationId,
  queryVars,
  isReply = false,
  replyIdx,
}) => {
  const [{ user }] = useAuthContext();
  const [editing, setEditing] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [showReply, setShowReply] = useState<boolean>(false);
  const [text, setText] = useState(comment?.text);

  const [commentDelete] = useCommentDeleteMutation();
  const [commentUpdate] = useCommentUpdateMutation();

  if (!comment || !collaborationId) return null;

  const { isLead } = verifyUserRole(user?.role, user?.country);
  const isOwnComment = comment?.user.id === user?.id;
  const isEditable = isOwnComment || isLead;

  async function deleteComment() {
    if (comment?.id) {
      await commentDelete({
        variables: {
          id: comment.id,
        },
        refetchQueries: [
          {
            query: CollaborationDocument,
            variables: {
              id: collaborationId,
            },
          },
        ],
        optimisticResponse: {
          commentDelete: {
            ...comment,
          },
        },
        update: (cache) => {
          cache.evict({ id: `Comment:${comment.id}` });
        },
      });
    }
  }

  async function updateComment(resolved: boolean) {
    if (!comment?.id) return;

    await commentUpdate({
      variables: {
        id: comment.id,
        data: {
          resolved,
        },
      },
      refetchQueries: [
        {
          query: CollaborationDocument,
          variables: {
            id: collaborationId,
          },
        },
      ],
      optimisticResponse: {
        commentUpdate: {
          ...comment,
          resolved,
        },
      },
      update: (cache, { data }) => {
        cache.modify({
          id: cache.identify({
            __ref: `Comment:${comment.id}`,
          }),
          fields: {
            resolved() {
              return resolved;
            },
          },
        });
      },
    });
  }

  return (
    <CommentView
      resolved={comment?.resolved}
      isReply={isReply}
      replyIdx={replyIdx}
      showDeleteConfirm={showDeleteConfirm}
    >
      <CardDeleteConfirm visible={showDeleteConfirm} isReply={isReply}>
        <div>
          <CardDeleteConfirmTitle>Delete this comment?</CardDeleteConfirmTitle>
          <CardDeleteConfirmSubTitle>
            Any discussion and files will be lost.
          </CardDeleteConfirmSubTitle>
          <CardDeleteCTA>
            <CardDeleteConfirmCancel
              onClick={() => setShowDeleteConfirm(false)}
            >
              Cancel
            </CardDeleteConfirmCancel>
            <CardDeleteConfirmConfirm onClick={() => deleteComment()}>
              Delete
            </CardDeleteConfirmConfirm>
          </CardDeleteCTA>
        </div>
      </CardDeleteConfirm>
      <CommentHeader>
        <CommentUser>
          <CountryFlag size="sm" user={comment?.user} disableTooltip />
          <CommentCountryDesktop>
            <BodySmall style={{ marginLeft: 7, marginTop: 4 }}>
              {getUserTitleOrCountryAndName(comment?.user)}
            </BodySmall>
          </CommentCountryDesktop>
          <CommentCountryMobile>
            <BodySmall style={{ marginLeft: 7, marginTop: 4 }}>
              {comment?.user?.role === 'LEAD'
                ? comment?.user?.title || 'Lead'
                : countryCode(comment?.user?.country as Country)}{' '}
              • {comment?.user?.name}
            </BodySmall>
          </CommentCountryMobile>
          <Caption
            color={colors.greyDark}
            style={{ marginLeft: 8, marginTop: 8 }}
          >
            {formatCreatedDate(Number(comment?.createdAt))}
          </Caption>
        </CommentUser>
        <CommentActions>
          {isEditable ? (
            <Icon
              name="Pencil"
              size={20}
              color={editing ? colors.greyMedium : colors.greyDark}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setEditing(true);
              }}
            />
          ) : null}
          {isEditable ? (
            <Icon
              name="TrashOutline"
              size={20}
              color={colors.greyDark}
              style={{ marginLeft: 5, cursor: 'pointer' }}
              onClick={() => {
                setShowDeleteConfirm(true);
              }}
            />
          ) : null}
          {isReply ? null : (
            <TooltipWrapper
              text={comment?.resolved ? 'Unresolve' : 'Resolve'}
              effect="float"
              place="top"
            >
              <CommentResolve
                resolved={comment?.resolved}
                onClick={() => {
                  updateComment(!comment.resolved);
                }}
              >
                <Icon
                  name="Tick"
                  size={40}
                  color={colors.white}
                  style={{ marginLeft: 0, marginTop: -1 }}
                />
              </CommentResolve>
            </TooltipWrapper>
          )}
        </CommentActions>
      </CommentHeader>

      {editing ? (
        <CommentForm
          comment={comment}
          commentId={comment?.id}
          commentText={comment?.text}
          collaborationId={collaborationId}
          onBlur={(_, text) => {
            setText(text);
            setEditing(false);
          }}
          queryVars={queryVars}
          saveOnBlur
          autoFocus
        />
      ) : (
        <div
          style={{ marginTop: 15 }}
          onDoubleClick={() => {
            if (isEditable) {
              setEditing(true);
            }
          }}
        >
          <BodySmall style={{ lineBreak: 'anywhere' }}>{text}</BodySmall>
        </div>
      )}

      {comment?.attachments.length > 0 ? (
        <div>
          {comment?.attachments?.map((attachment) => (
            <AttachmentPreview
              key={attachment.id}
              file={attachment as Attachment}
              clearErr={() => {}}
              onDelete={() => {}}
            />
          ))}
        </div>
      ) : null}

      <div style={{ marginTop: 15 }}>
        {comment?.replies?.map((c, idx) => (
          <Comment
            key={c?.id}
            comment={c as CommentType}
            isReply
            collaborationId={collaborationId}
            queryVars={queryVars}
            replyIdx={idx}
          />
        ))}
      </div>

      {isReply ? null : (
        <>
          {showReply ? (
            <CommentCreate>
              <CountryFlagWrapper>
                <CountryFlag
                  size="sm"
                  user={(user as unknown) as User}
                  disableTooltip
                />
              </CountryFlagWrapper>
              <CommentForm
                collaborationId={collaborationId}
                queryVars={queryVars}
                replyTo={comment?.id}
                autoFocus
              />
            </CommentCreate>
          ) : (
            <div
              onClick={() => setShowReply(true)}
              style={{ cursor: 'pointer' }}
            >
              <ReplyAction>Reply to this comment</ReplyAction>
            </div>
          )}
        </>
      )}
    </CommentView>
  );
};
