import { ButtonsContainer } from 'components/CompetitiveLandscape/CreateUpdateCompetitorModal';
import { ModalForm } from 'components/ModalForm';
import { ButtonPill } from 'components/shared';
import FormTextInput from 'components/shared/FormTextInput';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

const StyledFormTextInput = styled(FormTextInput)`
  margin: 0;
  textarea {
    max-width: 100%;
  }
`;

const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  white-space: pre-wrap;
`;

type Props = {
  showModal: boolean;
  handleClose(): void;
  handleSubmit(s: string): void;
  textValue: string;
  loading: boolean;
};

export const PropositionModal = ({
  handleClose,
  showModal,
  handleSubmit,
  textValue,
  loading,
}: Props) => {
  const [inputText, setInputText] = useState(textValue);

  useEffect(() => {
    setInputText(textValue);
  }, [textValue]);

  return (
    <ModalForm
      heading={`Core Value Proposition`}
      message="Summarise the brand's value propositon"
      handleClose={() => {
        handleClose();
      }}
      visible={showModal}
    >
      <ModalContentWrapper>
        <StyledFormTextInput
          onChange={(e) => {
            setInputText(e.target.value);
          }}
          value={inputText}
          name={'Core Value Proposition'}
          type={'text'}
          title={'Core Value Proposition'}
          textArea
        />
        <ButtonsContainer
          style={{ width: '100%', display: 'flex', marginTop: 20 }}
        >
          <ButtonPill
            onClick={() => {
              handleClose();
            }}
            text="Cancel"
            level="secondary"
          />

          <ButtonPill
            loading={loading}
            onClick={() => {
              handleSubmit(inputText.trim());
            }}
            text="Save"
          />
        </ButtonsContainer>
      </ModalContentWrapper>
    </ModalForm>
  );
};
