import React from 'react';

import { Content as ModalContent, Modal } from './Modal';
import { BodyNormal, ButtonPill, Subtitle1 } from './shared';
import { colors } from 'constants/colors';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';
interface Props {
  title: string;
  text: string;
  visible: boolean;
  handleClose(): void;
  handleConfirm(): void;
  leftButtonText: string;
  rightButtonText: string;
}

const Wrapper = styled.div`
  ${ModalContent} {
    min-width: 345px;
    max-width: 456px;
  }
`;

const Content = styled.div`
  text-align: center;
  border-top: 40px solid ${colors.cream};
  padding: 30px 15px;

  @media ${device.tabletMin} {
    padding: 40px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media ${device.tabletMin} {
    flex-direction: row;
  }
`;

const ModalButton = styled(ButtonPill)`
  width: 100%;

  :first-child {
    margin-bottom: 15px;
  }

  @media ${device.tabletMin} {
    width: 181px;

    :first-child {
      margin-bottom: 0;
    }
  }
`;

const Title = styled(Subtitle1)`
  margin-bottom: 10px;
`;

export const ModalConfirmDelete: React.FC<Props> = ({
  title,
  text,
  visible,
  handleClose,
  handleConfirm,
  leftButtonText,
  rightButtonText,
}) => {
  const confirmDeletion = () => {
    handleConfirm();
    handleClose();
  };

  return (
    <Wrapper>
      <Modal handleClose={handleClose} visible={visible}>
        <Content>
          <Title>{title}</Title>
          <BodyNormal style={{ marginBottom: 30 }}>{text}</BodyNormal>

          <ButtonContainer>
            <ModalButton
              text={leftButtonText}
              onClick={handleClose}
              width="180px"
              level="secondary"
            />
            <ModalButton
              text={rightButtonText}
              onClick={confirmDeletion}
              width="180px"
              level="danger"
            />
          </ButtonContainer>
        </Content>
      </Modal>
    </Wrapper>
  );
};
