import { useState } from 'react';
import { useDropzone, DropzoneState } from 'react-dropzone';

import {
  Attachment,
  useAttachmentCreateMutation,
} from 'data/graphql/generated';
import { uploadFile } from 'utils/uploadFile';

interface Props {
  collaborationId?: number;
  onSuccess(attachment: Attachment): void;
  isDraft?: boolean;
  disableNotification?: boolean;
  attachmentCreateArgs?: Parameters<typeof useAttachmentCreateMutation>[0];
}

export function useAttachmentCreate({
  collaborationId,
  disableNotification = false,
  onSuccess,
  isDraft = false,
  attachmentCreateArgs = {},
}: Props) {
  const [createAttachment] = useAttachmentCreateMutation(attachmentCreateArgs);

  const [uploadingName, setUploadingName] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [failedUpload, setFailedUpload] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  async function create(
    files: DropzoneState['acceptedFiles'],
    collaborationId: number
  ): Promise<Attachment> {
    const file = files[0];
    if (!file) {
      throw Error('No file found');
    }
    const { data } = await createAttachment({
      variables: {
        data: {
          title: file.name,
          collaborationId,
          disableNotification,
          isDraft,
        },
      },
    });

    if (!data?.attachmentCreate || !data?.attachmentCreate?.file) {
      throw Error('Upload failed');
    }

    uploadFile(files, data?.attachmentCreate?.file);

    return data.attachmentCreate as Attachment;
  }

  const dropZoneState = useDropzone({
    onDrop: async (acceptedFiles) => {
      if (!collaborationId) return;
      setFailedUpload(false);
      setErrMsg('');
      try {
        setUploading(true);
        const file = acceptedFiles[0];
        setUploadingName(file.name || '');
        const attachment = await create(acceptedFiles, collaborationId);
        setUploading(false);
        setUploadingName(null);
        onSuccess(attachment as Attachment);
      } catch (err) {
        console.log(err);
        setFailedUpload(true);
      }
    },
    onDropRejected: async (evt) => {
      const err = evt[0];
      const firstError = err.errors[0];
      if (!firstError) return;
      if (firstError.code.includes('file-invalid-type')) {
        return setErrMsg('Unsupported file');
      }
      if (firstError.code.includes('file-too-large')) {
        return setErrMsg('File exceeds recommended size (20mb)');
      }
      setErrMsg('File failed to upload');
    },
    noClick: true,
    accept:
      'image/jpeg, image/png, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .pdf, audio/mp4, video/mp4',
    maxFiles: 1,
    maxSize: 2e7,
  });

  function clearErr() {
    setFailedUpload(false);
    setUploading(false);
    setUploadingName(null);
  }

  return {
    dropzone: dropZoneState,
    uploading,
    uploadingName,
    failedUpload,
    errMsg,
    clearErr,
  };
}
