import { BodyNormal, BodySmall, Subtitle2, TabGroup } from 'components/shared';
import { kebabCase, uniqBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { PageWrapper, StepHeader } from '.';
import { CompetitorTab } from '../../components/CompetitiveLandscape/CompetitorTab';
import { ErrorWrapper } from '../../components/ErrorLoadingComponent';
import { ErrorModal } from '../../components/ErrorModal';
import { Statement } from '../../components/Positioning/Statement';
import { StatementModal } from '../../components/Positioning/StatementModal';
import { PostIts } from '../../components/PostIts';
import { colors, polling } from '../../constants';
import { useAuthContext } from '../../contexts/AuthContext';
import {
  CompetitorDocument,
  CompetitorFragment,
  Step,
  SubStep,
  useCompetitorUpdateMutation,
  useStakeholderDefinitionsWithPatientFlowBlockStarQuery,
} from '../../data/graphql/generated';
import { sortPostIts } from '../../hooks';
import useDesktop from '../../hooks/useDesktop';
import { usePostItCardMove } from '../../hooks/usePostItCardMove';
import { usePostItCards } from '../../hooks/usePostItCards';
import { usePostItGroups } from '../../hooks/usePostItGroups';
import { verifyUserRole } from '../../utils/verifyUserRole';
import { PostItsEmpty } from '../../components/PostItsEmpty';
import { useGlobalContext } from 'contexts/GlobalContext';

interface URLParams {
  drugId: string;
  strategyId: string;
  competitorId: string;
}

export const CompetitorPositioningPage = () => {
  const { drugId, strategyId, competitorId }: URLParams = useParams();

  const [statementModalOpen, setStatementModalOpen] = useState(false);
  const [noCompetitorModal, setNoCompetitorModal] = useState(false);
  const kebabCaseSubStep = kebabCase(SubStep.CompetitorPositioning);
  const history = useHistory();
  const isDesktop = useDesktop();

  // handle global context path
  const [ competitorPositioningId, setCompetitorPositioningId] = useGlobalContext(
    'competitorPositioning'
  );

  useEffect(() => {
    if (!competitorId && competitorPositioningId) {
      history.replace(
        `/d/${drugId}/strategy/${strategyId}/3_1/${kebabCaseSubStep}/${competitorPositioningId}`
      );
    }
  }, [drugId, history, competitorId, competitorPositioningId, kebabCaseSubStep, strategyId]);


  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);

  const {
    items: groupItems,
    loading: groupsLoading,
    createGroup,
    updateGroup,
    removeGroup,
    error: groupsError,
  } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.CompetitorPositioning,
        competitorId: +competitorId,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Positioning,
      substep: SubStep.CompetitorPositioning,
      competitorId: +competitorId,
    }
  );

  const {
    items: cardItems,
    loading: cardsLoading,
    createCard,
    updateCard,
    removeCard,
    error: cardsError,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.CompetitorPositioning,
        competitorId: +competitorId,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Positioning,
      substep: SubStep.CompetitorPositioning,
      competitorId: +competitorId,
    }
  );

  const groups = sortPostIts(groupItems, cardItems);

  const onChange = usePostItCardMove(groups, updateCard);
  const noCardsData = !groups;

  const {
    data: stakeholderDefinitionsData,
    loading: stakeholderDefinitionsLoading,
    startPolling,
    stopPolling,
  } = useStakeholderDefinitionsWithPatientFlowBlockStarQuery({
    variables: { where: { strategyId: +strategyId } },
    fetchPolicy: 'network-only',
  });

  const featuredCompetitors = useMemo(
    () =>
      uniqBy(
        stakeholderDefinitionsData?.stakeholderDefinitions?.items
          .reduce((acc, curr) => {
            const validCompetitors = curr?.CompetitorDetails?.filter(
              (cd) => !!cd?.isStarred
            ).map((cd) => cd?.Competitor) as CompetitorFragment[];

            if (!!validCompetitors) {
              acc.push(validCompetitors);
            }

            return acc;
          }, [] as CompetitorFragment[][])
          .flat() || [],
        'id'
      ),
    [stakeholderDefinitionsData?.stakeholderDefinitions?.items]
  );

  const noCompetitors = !featuredCompetitors.length;
  const selectedCompetitor = featuredCompetitors?.find(
    (c) => c?.id === +competitorId
  );

  const [updateCompetitor] = useCompetitorUpdateMutation();

  useEffect(() => {
    // stakeholderDefinitions holds featuredCompetitors data
    if (stakeholderDefinitionsLoading || selectedCompetitor || noCompetitors) {
      return;
    }

    // Ensure the first positioningTab is selected by default
    let url = `/d/${drugId}/strategy/${strategyId}/3_1/${kebabCase(
      SubStep.CompetitorPositioning
    )}`;

    // Ensure the first competitor is selected by default
    if (competitorId && !selectedCompetitor) setNoCompetitorModal(true);
    const firstCompetitor = featuredCompetitors[0];
    if (firstCompetitor) {
      url += `/${firstCompetitor.id}`;
    }

    return history.replace(url);
  }, [
    history,
    drugId,
    strategyId,
    kebabCaseSubStep,
    competitorId,
    stakeholderDefinitionsLoading,
    featuredCompetitors,
    noCompetitors,
    selectedCompetitor,
  ]);

  useEffect(() => {
    startPolling(polling.default);

    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  return (
    <>
      <StatementModal
        placeholder={'A strong brand association'}
        closeModal={() => setStatementModalOpen(false)}
        substep={SubStep.CompetitorPositioning}
        statement={selectedCompetitor?.brandAssociations}
        visible={statementModalOpen}
        drugId={+drugId}
        strategyId={+strategyId}
        title={`Space occupied by ${selectedCompetitor?.title || ''}`}
        subTitle={
          'Summarise the strong brand associations. Each new line will create a new bullet point.'
        }
        onUpdate={async (value) => {
          if (selectedCompetitor?.id) {
            const trimmedValidValues = value
              .filter((arr) => !!arr)
              .map((s) => s.trim());

            await updateCompetitor({
              variables: {
                id: selectedCompetitor?.id,
                data: { brandAssociations: trimmedValidValues },
              },
              refetchQueries: [
                {
                  query: CompetitorDocument,
                  variables: { id: selectedCompetitor?.id },
                },
              ],
            });
          }
        }}
      />

      <ErrorModal
        title="Competitor no longer exists"
        text="The competitor you are looking for has been deleted."
        handleClose={() => setNoCompetitorModal(false)}
        visible={noCompetitorModal}
      />

      <StepHeader isDesktop={isDesktop}>
        <Subtitle2 style={{ marginBottom: '5px' }}>
          What do customers think and feel about each competitor brand?
        </Subtitle2>
        <div
          style={{
            whiteSpace: 'pre-wrap',
            marginBottom: '15px',
          }}
        >
          <BodyNormal color={colors.greyDark} style={{ display: 'inline' }}>
            Competitors that appear here are those starred in the competitive
            landscape analysis.
          </BodyNormal>
        </div>

        {noCompetitors ? null : (
          <TabGroup componentName="CompetitorTab">
            <>
              {featuredCompetitors.map((c) => {
                if (!c) return null;
                return (
                  <div
                    key={c.id}
                    style={{ display: 'inline-block', padding: '0 2px' }}
                  >
                    <CompetitorTab
                      key={c.id}
                      text={c.title}
                      image={c.image}
                      onClick={() => {
                        history.push(
                          `/d/${drugId}/strategy/${strategyId}/3_1/${kebabCaseSubStep}/${c.id}`
                        );
                        setCompetitorPositioningId(c.id)
                      }}
                      active={c.id === +competitorId}
                    />
                  </div>
                );
              })}
            </>
          </TabGroup>
        )}
      </StepHeader>

      <ErrorWrapper
        isLoading={
          groupsLoading || cardsLoading || stakeholderDefinitionsLoading
        }
        errors={[groupsError, cardsError]}
        dataMissing={noCardsData}
      >
        {noCompetitors ? (
          <PostItsEmpty style={{ margin: 20 }} title="No competitors to assess">
            <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
              Only competitors starred in the{' '}
            </BodySmall>
            <Link
              to={`/d/${drugId}/strategy/${strategyId}/1_2?evaluating=true`}
              style={{
                color: colors.greyDark,
                display: 'inline',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              1.2 Competitive Landscape{' '}
            </Link>
            <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
              can be assessed
            </BodySmall>
          </PostItsEmpty>
        ) : (
          <PageWrapper paddingTop={false} fullWidthMobile>
            <Statement
              onClick={() => setStatementModalOpen(true)}
              allowChange={isLead}
              substep={SubStep.CompetitorPositioning}
              selectedCompetitor={selectedCompetitor}
            />

            <PostIts
              step={Step.Positioning}
              groups={groups}
              subStep={SubStep.CompetitorPositioning}
              addCard={createCard}
              removeCard={removeCard}
              updateCard={updateCard}
              handleCardChange={onChange}
              createGroup={createGroup}
              updateGroup={updateGroup}
              removeGroup={removeGroup}
            />
          </PageWrapper>
        )}
      </ErrorWrapper>
    </>
  );
};
