import { colors, zIndex } from 'constants/index';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { ReactNode, useRef } from 'react';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';

export const InnerWrapper = styled.div`
  width: 300px;
  height: 100%;
`;

const Wrapper = styled.div<{ heightOffset: number; isOpen: boolean }>`
  background: ${colors.white};
  width: ${({ isOpen }) => (isOpen ? '300px' : '0px')};
  min-width: ${({ isOpen }) => (isOpen ? '300px' : '0px')};
  overflow: hidden;
  position: sticky;
  top: ${({ heightOffset }) => heightOffset}px;
  right: 0;
  z-index: ${zIndex.reusableSidebarDefault};
  order: 1;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  height: calc(100vh - ${({ heightOffset }) => heightOffset}px);

  transition: min-width 0.3s, width 0.3s;

  @media ${device.mobile} {
    position: fixed;
    // 100% is preferred to 100vh on mobile. Browsers like Chrome and Safari include the address bar in this calculation, making it incorrect
    height: calc(100% - ${({ heightOffset }) => heightOffset}px);
    bottom: 0;
    right: 0;
    top: unset;
    min-height: auto;
  }

  ${InnerWrapper} {
    opacity: ${({ isOpen }) => Number(isOpen)};
  }
`;

type Props = {
  heightOffset?: number;
  children?: ReactNode;
  isOpen: boolean;
  onClickOutside?(event?: MouseEvent): void;
  ignoreClickClasses?: string[];
  className?: string;
  outerRef?: React.MutableRefObject<HTMLDivElement | null>;
};

export const ReusableSidebar = ({
  heightOffset = 0,
  children,
  isOpen,
  onClickOutside,
  ignoreClickClasses,
  className,
  outerRef,
}: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useClickOutsideComponent(
    containerRef,
    (event) => {
      onClickOutside?.(event);
    },
    ignoreClickClasses
  );

  return (
    <Wrapper
      ref={(e) => {
        if (outerRef) {
          outerRef.current = e;
        }
        containerRef.current = e;
      }}
      isOpen={isOpen}
      heightOffset={heightOffset}
      className={className}
    >
      <InnerWrapper>{children}</InnerWrapper>
    </Wrapper>
  );
};
