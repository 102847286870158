import React from 'react';
import styled, { css } from 'styled-components/macro';
import Textarea from 'react-expanding-textarea';
import { colors } from 'constants/colors';
import { BodySmall } from '.';

const TEXTAREA_PADDING = 4.5;

export const UnderlineTextFieldContainer = styled.div<{
  $disabled?: boolean;
  $invalid?: boolean;
}>`
  display: flex;
  align-items: baseline;
  :hover {
    color: ${colors.greyDark};
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${colors.black50};
    `}

  ${({ $invalid }) =>
    $invalid &&
    css`
      color: ${colors.black};
    `}

    transition: color 0.3s;
`;

const BulletPoint = styled.div`
  margin-top: 6px;
  margin-right: 5px;

  font-family: ABCFavorit;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
`;

const StyledTextarea = styled(Textarea)<{
  $disabled?: boolean;
  $small?: boolean;
  $invalid?: boolean;
  $borderless?: boolean;
  $table?: boolean;
  $hideUnderline?: boolean;
}>`
  width: 100%;
  margin: 0;
  padding: ${TEXTAREA_PADDING}px 0;
  overflow-y: hidden;
  resize: none;

  font-family: ABCFavorit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  outline: none;
  border: none;
  border-bottom: 0.5px solid
    ${({ $hideUnderline }) =>
      $hideUnderline ? 'transparent' : colors.greyDark};

  :hover {
    border-color: ${colors.black};
    color: ${colors.greyDark};
  }

  :focus {
    border-color: ${colors.blue};
    color: ${colors.black};
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      border-color: ${colors.greyMedium};
      color: ${colors.black50};
      background: none;
    `}

  ${({ $invalid }) =>
    $invalid &&
    css`
      border-color: ${colors.red};
      color: ${colors.black};
    `}

    transition: background 0.3s, color 0.3s, border 0.3s;
`;

interface Props {
  initialValue: string;
  id?: string;
  placeholder?: string;
  handleChange(e: React.FormEvent<HTMLTextAreaElement>): void;
  onKeyDown(e: React.KeyboardEvent): void;
  disabled?: boolean;
  invalid?: boolean;
  hideUnderline?: boolean;
  bulletPoint?: boolean;
  numbered?: boolean;
  autoFocus?: boolean;
  className?: string;
  style?: React.CSSProperties;
  idx?: number;
}
export const UnderlineTextField: React.FC<Props> = ({
  initialValue,
  id,
  placeholder,
  handleChange,
  onKeyDown,
  disabled,
  invalid,
  hideUnderline = false,
  bulletPoint = false,
  numbered = false,
  autoFocus = false,
  className,
  style,
  idx,
}) => {
  return (
    <UnderlineTextFieldContainer
      id={id}
      $disabled={disabled}
      $invalid={invalid}
      className={className}
      style={style}
    >
      {!!numbered ? (
        <>
          <BodySmall style={{ marginRight: 5, wordBreak: 'normal' }}>
            {idx}.
          </BodySmall>
        </>
      ) : (
        bulletPoint && <BulletPoint className="bullet-point">•</BulletPoint>
      )}
      <StyledTextarea
        $disabled={disabled}
        $invalid={invalid}
        placeholder={placeholder}
        $hideUnderline={hideUnderline}
        onChange={(e) => handleChange(e)}
        value={initialValue}
        onKeyDown={(e) => onKeyDown(e)}
        autoFocus={autoFocus}
      />
    </UnderlineTextFieldContainer>
  );
};
