import { useGetNavHeight } from './useGetNavHeight';
import { useWidthHeight } from './useWidthHeight';

export const usePatientJourneyDimensions = () => {
  const navHeight = useGetNavHeight();
  // update size on window change
  const { width: windowWidth } = useWidthHeight();
  const canvasWidth = windowWidth;
  const canvasHeight = document.documentElement.clientHeight - navHeight - 2;

  return {
    canvasWidth,
    canvasHeight,
  };
};
