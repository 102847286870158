import { colors } from 'constants/index';
import React, { CSSProperties, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components';
import { uid } from 'uid';
import { isTouchDevice } from 'utils/isTouchDevice';

type Direction = 'left' | 'right' | 'top' | 'bottom';

const SpanTooltip = styled.span<{ visible: boolean; direction: Direction }>`
  pointer-events: none;
  position: absolute;
  ${({ direction }) => {
    switch (direction) {
      case 'left':
        return css`
          left: 14px;
        `;
      case 'right':
        return css`
          right: 14px;
        `;
      case 'top':
        return css`
          top: 0px;
        `;
      case 'bottom':
        return css`
          bottom: 0px;
        `;
      default:
        return css`
          left: 14px;
        `;
    }
  }}
  bottom: 18px;
  width: max-content;
  max-width: 215px;
  height: max-content;
  color: ${colors.white};
  font-family: ABCFavorit;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  border-radius: 2px;
  background: ${() => colors.black90a};
  padding: 5px;
  opacity: ${({ visible }) => Number(visible)};
  transition: opacity 0.3s;
`;

interface Props {
  /**
   *Incase multiple instances of tooltips are required, this id can be used to isolate this tooltip
   *to only the components with a "data-for" value that is the same as the ID value
   */
  id?: string;
  capitalize?: boolean;
  effect?: 'float' | 'solid';
  place?: 'right' | 'top' | 'bottom' | 'left';
  /**
   *Prevents the tooltip from wrapping across multiple lines
   */
  noWrap?: boolean;
  multiline?: boolean;
}

const StyledTooltip = styled(ReactTooltip)<Props>`
  font-family: ABCFavorit;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 15px !important;
  border-radius: 2px !important;
  background: ${() => colors.black90} !important;
  padding: 5px !important;
  opacity: 1;
  text-transform: ${({ capitalize }) => {
    return capitalize ? 'capitalize' : 'none';
  }};
  ${({ noWrap }) => {
    if (noWrap) {
      return css`
        white-space: nowrap;
      `;
    }
  }}

  // pseudo element creates black block above tooltip
  :before {
    content: none !important;
  }
`;

export const Tooltip = ({
  id = '',
  capitalize = false,
  effect = 'solid',
  place = 'top',
  noWrap,
  multiline,
}: Props) => {
  return (
    <StyledTooltip
      place={place}
      capitalize={capitalize}
      id={id}
      arrowColor="transparent"
      effect={effect}
      type="dark"
      noWrap={noWrap}
      multiline={multiline}
    />
  );
};

interface TooltipWrapperProps {
  text: string;
  capitalize?: boolean;
  effect?: 'float' | 'solid';
  place?: 'right' | 'top' | 'bottom' | 'left';
  multiline?: boolean;
  style?: React.CSSProperties;
}

export const TooltipWrapper: React.FC<TooltipWrapperProps> = ({
  text,
  capitalize,
  effect,
  place,
  children,
  multiline,
  style,
}) => {
  const isTouch = isTouchDevice();

  const id = useMemo(() => uid(), []);

  return (
    <div data-for={id} data-tip={text} style={{ zIndex: 999999, ...style }}>
      {children}
      {!isTouch && (
        <Tooltip
          id={id}
          place={place}
          effect={effect}
          capitalize={capitalize}
          multiline={multiline}
        />
      )}
    </div>
  );
};

export const TooltipCSS: React.FC<{
  text: string;
  className?: string;
  style?: CSSProperties;
  tooltipStyle?: CSSProperties;
  direction?: Direction;
  disabled?: boolean;
  overrideVisible?: boolean | undefined;
}> = ({
  children,
  text,
  className,
  style,
  tooltipStyle,
  direction = 'left',
  disabled = false,
  overrideVisible,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className={className}
      style={{ position: 'relative', ...style }}
      onMouseEnter={() => {
        if (!disabled) setShowTooltip(true);
      }}
      onMouseLeave={() => {
        setShowTooltip(false);
      }}
    >
      {!!text && (
        <SpanTooltip
          visible={
            typeof overrideVisible !== 'undefined'
              ? overrideVisible
              : showTooltip
          }
          direction={direction}
          style={tooltipStyle}
        >
          {text}
        </SpanTooltip>
      )}

      {children}
    </div>
  );
};
