import { Maybe, Observation as ObservationType } from 'data/graphql/generated';
import React, { useEffect } from 'react';

import { DragSourceMonitor, useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Observation } from '.';
import styled, { css } from 'styled-components/macro';
import { colors } from 'constants/index';

const Wrapper = styled.div<{ isDragging: boolean; enableDrag: boolean }>`
  cursor: ${({ enableDrag }) => (enableDrag ? 'grab' : 'default')};
  width: 100%;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 2;
    background: ${colors.white50};
    border-radius: 5px;
    display: ${({ isDragging }) => (isDragging ? 'block' : 'none')};
  }

  > * {
    opacity: 1;
    transition: opacity 0.3s 0.2s;
  }

  ${({ isDragging }) => {
    if (isDragging) {
      return css`
        > * {
          opacity: 0;
          transition: opacity 0s;
        }
      `;
    }
  }}
`;

interface DraggableProps {
  data: Maybe<
    {
      __typename?: 'Observation' | undefined;
    } & {
      __typename?: 'Observation' | undefined;
    } & Pick<
        ObservationType,
        | 'step'
        | 'text'
        | 'id'
        | 'companyId'
        | 'strategyId'
        | 'drugId'
        | 'lastUpdated'
        | 'createdAt'
        | 'stakeholder'
      >
  >;
  onEnd?: (dropResult: unknown, monitor: DragSourceMonitor) => void;
  deleteObservation?(): void;
  source: string;
  enableDrag?: boolean;
}

export const DraggableObservation: React.FC<DraggableProps> = ({
  data,
  onEnd,
  deleteObservation,
  source,
  enableDrag = true,
}) => {
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    item: { type: 'observation', id: data?.id, source },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      onEnd?.(dropResult, monitor);
    },
  }));

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  useEffect(() => {
    if (isDragging) {
      document.body.style.userSelect = 'none';
      document.body.style.setProperty('-webkit-user-select', 'none');
    } else {
      document.body.style.userSelect = 'auto';
      document.body.style.setProperty('-webkit-user-select', 'auto');
    }
  }, [isDragging]);

  return (
    <Wrapper
      onClick={(e) => {
        e.stopPropagation();
      }}
      enableDrag={!!enableDrag}
      isDragging={isDragging}
      ref={enableDrag ? drag : null}
    >
      <Observation
        deleteObservation={deleteObservation}
        readonly
        key={data?.id}
        data={data}
      />
    </Wrapper>
  );
};
