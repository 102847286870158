export type Action =
  | { type: 'SET_NAME'; name: string }
  | { type: 'SET_STRATEGIC_IMPERATIVE_ID'; id: number }
  | { type: 'TOUCHED_NAME'; isTouched: boolean }
  | { type: 'TOUCHED_STRATEGIC_IMPERATIVE'; isTouched: boolean };
export type State = {
  strategicImperativeId?: number;
  isStrategicImperativeTouched?: boolean;
  name: string;
  isNameTouched?: boolean;
};

export const focusPicoSuggestionReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_NAME':
      return { ...state, name: action.name };
    case 'SET_STRATEGIC_IMPERATIVE_ID':
      return { ...state, strategicImperativeId: action.id };
    case 'TOUCHED_NAME':
      return { ...state, isNameTouched: action.isTouched };
    case 'TOUCHED_STRATEGIC_IMPERATIVE':
      return { ...state, isStrategicImperativeTouched: action.isTouched };
    default:
      return state;
  }
};
