import * as React from 'react';
import { ModalContext, TModalContext } from './ModalContext';

interface ModalProviderProps {
  children: React.ReactNode | React.ReactNode[] | null;
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modalSlideIdx, setModalSlideIdx] = React.useState<number | null>(null);
  const [showSingleSlide, setShowSingleSlide] = React.useState<boolean>(false);

  const value: TModalContext = {
    modalSlideIdx,
    setModalSlideIdx,
    showSingleSlide,
    setShowSingleSlide,
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};
