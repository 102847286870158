import { BodySmall, Icon, ReusableSidebar, Subtitle2 } from 'components/shared';
import {
  CollapseIcon,
  GreyCardComponent,
} from 'components/shared/ImperativeSummarySidebar';
import { colors } from 'constants/index';
import { ArchetypeFragment, SubStep } from 'data/graphql/generated';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  width: 100%;
  margin-bottom: 10px;
`;

const Wrapper = styled.div`
  padding: 15px;
  height: 100%;
  width: 100%;
`;

type Props = {
  title?: string;
  activeArchetype: ArchetypeFragment | null;
  onClose(): void;
};

type ValidSubSteps =
  | SubStep.DiseaseBurdenAndUnmetNeed
  | SubStep.ClinicalValue
  | SubStep.HumanisticValue
  | SubStep.EconomicValue;

type TextAndImportance = { text: string; y: number | null | undefined }[][];

type OrganisedSubStepData = {
  [SubStep.DiseaseBurdenAndUnmetNeed]: TextAndImportance;
  [SubStep.ClinicalValue]: TextAndImportance;
  [SubStep.HumanisticValue]: TextAndImportance;
  [SubStep.EconomicValue]: TextAndImportance;
};

const handleLists = (arr: TextAndImportance) =>
  arr
    .flat()
    .map((message) => message.text)
    .filter((v) => !!v)
    .slice(0, 3);

export const SupportingMessagesSidebar = ({
  isOpen,
  heightOffset,
  onClose,
  activeArchetype,
}: React.ComponentProps<typeof ReusableSidebar> & Props) => {
  const topSupportingMessages = useMemo(
    () =>
      (activeArchetype?.accessStrategyPrioritiseSupportingMessages?.reduce(
        (acc, curr, idx, origArr) => {
          // If it's not on the grid
          if (curr?.x === null || curr?.y === null) {
            return acc;
          }

          const subStep = curr?.keyStatement.subStep as SubStep | undefined;

          if (
            subStep &&
            [
              SubStep.DiseaseBurdenAndUnmetNeed,
              SubStep.ClinicalValue,
              SubStep.HumanisticValue,
              SubStep.EconomicValue,
            ].includes(subStep)
          ) {
            if (
              !!curr?.keyStatement.text &&
              !!curr?.keyStatement.id &&
              curr.y
            ) {
              const arrPosition = acc[subStep as ValidSubSteps][curr.y];
              if (arrPosition) {
                // There is a chance 2 statements have the same y position, so we add them to an array which we'll flatten later
                acc[subStep as ValidSubSteps][curr.y].push({
                  text: curr?.keyStatement.text,
                  y: curr.y,
                });
              } else {
                acc[subStep as ValidSubSteps][curr.y] = [
                  {
                    text: curr?.keyStatement.text,
                    y: curr.y,
                  },
                ];
              }
            }
          }

          return acc;
        },
        {
          [SubStep.DiseaseBurdenAndUnmetNeed]: [],
          [SubStep.ClinicalValue]: [],
          [SubStep.HumanisticValue]: [],
          [SubStep.EconomicValue]: [],
        } as OrganisedSubStepData
      ) as OrganisedSubStepData) || {
        [SubStep.DiseaseBurdenAndUnmetNeed]: [],
        [SubStep.ClinicalValue]: [],
        [SubStep.HumanisticValue]: [],
        [SubStep.EconomicValue]: [],
      },
    [activeArchetype?.accessStrategyPrioritiseSupportingMessages]
  );

  const diseaseBurdenAndUnmetNeed = handleLists(
    topSupportingMessages.diseaseBurdenAndUnmetNeed
  );
  const clinicalValue = handleLists(topSupportingMessages.clinicalValue);
  const humanisticValue = handleLists(topSupportingMessages.humanisticValue);
  const economicValue = handleLists(topSupportingMessages.economicValue);

  return (
    <ReusableSidebar isOpen={isOpen} heightOffset={heightOffset}>
      <Wrapper>
        <Header>
          <Icon name="MoreText" size={30} color={colors.greyDark} />
          <BodySmall>Top supporting messages</BodySmall>
          <CollapseIcon
            onClick={() => onClose?.()}
            name="Collapse right"
            size={30}
            color={colors.purple}
            style={{ marginLeft: 'auto' }}
          />
        </Header>
        <Content>
          <Subtitle2>
            {activeArchetype?.stakeholderDefinition.title || ''}
          </Subtitle2>

          <GreyCardComponent
            title="Disease burden and unmet need"
            listData={diseaseBurdenAndUnmetNeed || []}
          />
          <GreyCardComponent
            title="Clinical value"
            listData={clinicalValue || []}
          />
          <GreyCardComponent
            title="Patient value"
            listData={humanisticValue || []}
          />
          <GreyCardComponent
            title="Economic value"
            listData={economicValue || []}
          />
        </Content>
      </Wrapper>
    </ReusableSidebar>
  );
};
