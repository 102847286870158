import { colors, TEMP_ID } from 'constants/index';
import { Layer as LayerType } from 'konva/types/Layer';
import React, { useCallback } from 'react';
import { Circle, Line, Arrow, Group } from 'react-konva';
import { ConnectionSide } from 'types';
import { BlockDots } from '../components/BlockDots';
import { PatientJourneyStageType } from '../types';
import { SharedPatientJourneyTypes } from '../types/shared';
import { generatePathFromConnection } from '../utils';

type Props = {
  layerConnectionsRef: React.MutableRefObject<LayerType | null>;
  newConnection: SharedPatientJourneyTypes['newConnection'];
  connections: PatientJourneyStageType['connections'];
  selectedConnection: PatientJourneyStageType['selectedConnection'];
  isDrag: boolean;
  setSelectedConnection: PatientJourneyStageType['setSelectedConnection'];
  changeCursor: PatientJourneyStageType['changeCursor'];
  createHide: PatientJourneyStageType['createHide'];
  createShow: PatientJourneyStageType['createShow'];
  updateConnection: PatientJourneyStageType['updateConnection'];
  adjustFromScreenX: PatientJourneyStageType['adjustFromScreenX'];
  adjustFromScreenY: PatientJourneyStageType['adjustFromScreenY'];
  blocks: PatientJourneyStageType['blocks'];
  createConnection: PatientJourneyStageType['createConnection'];
  setNewConnection: PatientJourneyStageType['setNewConnection'];
  showDotsForBlock: PatientJourneyStageType['showDotsForBlock'];
  hideDotsForBlock: PatientJourneyStageType['hideDotsForBlock'];
};

export const ConnectorsLayer = ({
  layerConnectionsRef,
  newConnection,
  connections,
  selectedConnection,
  isDrag,
  setSelectedConnection,
  changeCursor,
  createHide,
  createShow,
  updateConnection,
  adjustFromScreenX,
  adjustFromScreenY,
  blocks,
  createConnection,
  setNewConnection,
  showDotsForBlock,
  hideDotsForBlock,
}: Props) => {
  const handleConnectionDragStart = useCallback(() => {
    const layerConnection = layerConnectionsRef.current;
    if (!layerConnection) return;
    // show all dots
    const dots = layerConnection.find('.dot');
    Object.keys(dots).forEach((k) => {
      const d = dots[k as keyof typeof dots];
      if (!d || typeof d === 'number' || !('opacity' in d)) return;
      d.opacity(0.5);
    });
    layerConnection.draw();
  }, [layerConnectionsRef]);

  const handleConnectionDragEnd = useCallback(
    (e, connection: Props['connections'][0]) => {
      const layer = layerConnectionsRef.current;
      if (!layer) return;

      // hide all dots
      const dots = layer.find('.dot');
      Object.keys(dots).forEach((k) => {
        const d = dots[k as keyof typeof dots];

        if (typeof d === 'object' && 'opacity' in d) {
          d.opacity(0);
        }
      });
      layer.draw();

      // check for connection
      let hasConnection = false;
      const dragPos = e.target.getAbsolutePosition();

      Object.keys(dots).find((k) => {
        const d = dots[k as keyof typeof dots];
        if (!d || typeof d === 'number' || !('getAbsolutePosition' in d)) {
          return false;
        }

        const dPos = d.getAbsolutePosition();

        const intersection =
          dragPos.x > dPos.x - 30 &&
          dragPos.x < dPos.x + 30 &&
          dragPos.y > dPos.y - 30 &&
          dragPos.y < dPos.y + 30;

        if (intersection) {
          const [toBlockId, toSide] = d
            // @ts-ignore
            .getId()
            .replace('block-', '')
            .replace('dot-', '')
            .split('-');

          if (Number(toBlockId) === connection.fromBlockId) {
            return false;
          }

          updateConnection({
            ...connection,
            toBlockId: Number(toBlockId),
            toSide,
            path: generatePathFromConnection(
              layerConnectionsRef,
              adjustFromScreenX,
              adjustFromScreenY,
              {
                toBlockId: Number(toBlockId),
                toSide,
                fromBlockId: Number(connection.fromBlockId),
                fromSide: connection.fromSide as ConnectionSide,
              }
            ),
          });

          hasConnection = true;
        }

        return false;
      });

      // reset drag to dot position
      if (!hasConnection) {
        e.target.position({
          x: connection.path[connection.path.length - 2],
          y: connection.path[connection.path.length - 1],
        });
        const dotLineNode = layer.findOne(`#connection-${connection.id}`);
        dotLineNode.attrs.points = connection.path;
        layer.draw();
      }
    },
    [
      adjustFromScreenX,
      adjustFromScreenY,
      layerConnectionsRef,
      updateConnection,
    ]
  );

  const handleConnectionDragMove = useCallback(
    (e, connection: Props['connections'][0]) => {
      const layer = layerConnectionsRef.current;
      if (!layer) return;
      // get dot position
      const dotNode = layer.findOne(
        `#block-${connection.fromBlockId}-dot-${connection.fromSide}`
      );
      const dotPosition = dotNode.getAbsolutePosition();
      // get draggable dot position
      const dragPosition = e.target.getAbsolutePosition();
      // update dot line
      const dotLineNode = layer.findOne(`#connection-${connection.id}`);
      dotLineNode.attrs.points = [
        dotPosition.x,
        dotPosition.y,
        dragPosition.x,
        dragPosition.y,
      ];
      dotLineNode.opacity(1);
      layer.batchDraw();
    },
    [layerConnectionsRef]
  );

  return (
    <Group>
      {!!newConnection.path.length && (
        <Circle
          id={`new-line-dot`}
          x={0}
          y={0}
          opacity={1}
          radius={9}
          width={9}
          height={9}
          fill="#F3F3F4"
          stroke={colors.purple}
          strokeWidth={1.5}
        />
      )}

      {connections.map((connection) => {
          const selected = selectedConnection === connection.id;

          const minimizeBends =(points: number[]) =>{
            const start = { x: points[0], y: points[1] };
            const end = { x: points[points.length - 2], y: points[points.length - 1] };
            const tolerance = 10; // Distance from line to consider a point as aligned
        
            // Calculate the line equation from start to end
            const dx = end.x - start.x;
            const dy = end.y - start.y;
        
            // For vertical lines, avoid division by zero
            if (dx === 0) {
                // Check if all points are within the tolerance of the start.x
                const allInTolerance = points.every((value: number, index: number) => {
                    return index % 2 === 0 ? Math.abs(value - start.x) <= tolerance : true;
                });
        
                if (allInTolerance) {
                    return [start.x, start.y, end.x, end.y];
                }
                return points;
            }
        
            // Line equation coefficients (Ax + By + C = 0)
            const A = dy;
            const B = -dx;
            const C = dx * start.y - dy * start.x;
        
            // Function to calculate distance from point to line
            const distanceToLine = (x: number, y: number) => Math.abs(A * x + B * y + C) / Math.sqrt(A * A + B * B);
        
            // Check all points
            for (let i = 2; i < points.length - 2; i += 2) {
                const x = points[i];
                const y = points[i + 1];
                if (distanceToLine(x, y) > tolerance) {
                    return points; // Return original if any point is outside the tolerance
                }
            }
        
            // If all points are within the tolerance, return only start and end
            return [start.x, start.y, end.x, end.y];
        }

        function onClick() {
          if (
            isDrag ||
            newConnection.path.length ||
            connection.id === TEMP_ID
          ) {
            return;
          }
          setSelectedConnection(connection.id);
        }

        return (
          <Group key={connection.id}>
            <Line
              opacity={0}
              stroke="red"
              strokeWidth={14}
              points={connection.path}
              onClick={onClick}
              onMouseEnter={() => {
                if (isDrag) return;
                changeCursor('pointer');
                createHide();
              }}
              onMouseLeave={() => {
                changeCursor('default');
                if (!isDrag) createShow();
              }}
            />
            <Arrow
              id={`connection-${connection.id}`}
              key={connection.id}
              fill={selected ? colors.pink : colors.purple}
              stroke={selected ? colors.pink : colors.purple}
              points={minimizeBends(connection.path)}
              onClick={onClick}
              onMouseEnter={() => {
                if (isDrag) return;
                changeCursor('pointer');
                createHide();
              }}
              onMouseLeave={() => {
                changeCursor('default');
                if (!isDrag) createShow();
              }}
            />
            <Circle
              name={`connection-dot-${connection.fromBlockId} connection-dot-c-${connection.id}`}
              id={`connection-dot-${connection.fromBlockId}`}
              fill={selected ? colors.white : colors.purple}
              stroke={selected ? colors.pink : 'transparent'}
              strokeWidth={1.5}
              x={connection.path[0]}
              y={connection.path[1]}
              radius={5}
              opacity={1}
            />
            {selected && (
              <Circle
                fill={selected ? colors.white : colors.purple}
                stroke={selected ? colors.pink : 'transparent'}
                strokeWidth={1.5}
                x={connection.path[connection.path.length - 2]}
                y={connection.path[connection.path.length - 1]}
                radius={5}
                opacity={1}
                draggable
                onDragStart={handleConnectionDragStart}
                onDragEnd={(e) => handleConnectionDragEnd(e, connection)}
                onDragMove={(e) => {
                  handleConnectionDragMove(e, connection);
                }}
              />
            )}
          </Group>
        );
      })}
      {blocks.map((block) => {
        return (
          <BlockDots
            key={block.id}
            block={block}
            layerDotsRef={layerConnectionsRef}
            createConnection={createConnection}
            newConnection={newConnection}
            setNewConnection={setNewConnection}
            changeCursor={changeCursor}
            showDotsForBlock={showDotsForBlock}
            hideDotsForBlock={hideDotsForBlock}
            createShow={createShow}
            createHide={createHide}
            isDrag={isDrag}
            adjustFromScreenX={adjustFromScreenX}
            adjustFromScreenY={adjustFromScreenY}
          />
        );
      })}

      <Line
        id={`new-line`}
        stroke={colors.purple}
        opacity={1}
        points={newConnection.path}
      />
    </Group>
  );
};
