import { PatientJourneyState } from 'data/graphql/generated';
import { generatePath } from 'react-router-dom';

export const getPatientJourneyUrl = (drugId: string, strategyId: string) => {
  const routeMatch = '/d/:drugId/strategy/:strategyId/1_3/:state?';
  const path = generatePath(routeMatch, {
    drugId,
    strategyId,
    state: PatientJourneyState.Desired.toLowerCase(),
  });
  return path;
};
