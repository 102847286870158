import { colors } from 'constants/colors';
import React from 'react';
import styled from 'styled-components';
import { BodySmall } from './TextStyles';

export const Label = styled.label`
  position: relative;
  width: 100%;
  margin: 20px 0;
`;

export const Span = styled.span<{ disabled?: boolean }>`
  position: absolute;
  top: -10px;
  left: 10px;
  padding: 0 5px;
  background: white;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ disabled }) => (disabled ? colors.black30 : colors.greyDark)};
`;

export const Icon = styled.span<{ disabled?: boolean }>`
  position: absolute;
  width: 14px;
  height: 20px;
  right: 15px;
  top: calc(50% - 20px / 2);

  padding: 0 5px;
  background: white;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ disabled }) => (disabled ? colors.black30 : colors.greyDark)};
`;

export const ErrorContainer = styled.div`
  width: 100%;
  height: 20px;
  position: absolute;
  margin-top: 5px;
  padding: 0 15px;
  display: flex;
  align-items: center;
`;

const ErrorText = styled(BodySmall)`
  color: ${colors.darkRed};
`;

export const ErrorMessage: React.FC<{
  message: string;
  style?: React.CSSProperties;
  className?: string;
}> = ({ message, style, className }) => (
  <ErrorContainer className={className} style={style}>
    <ErrorText>{message}</ErrorText>
  </ErrorContainer>
);

interface Props {
  name: string;
  title: string;
  icon?: string;
  disabled?: boolean;
  children: React.ReactNode;
  innerRef?: React.MutableRefObject<null>;
  className?: string;
}

export const FormField: React.FC<Props> = ({
  name,
  title,
  icon,
  disabled,
  children,
  innerRef,
  className,
}) => {
  return (
    <Label className={className} ref={innerRef} htmlFor={name}>
      <Span disabled={disabled}>{title}</Span>
      {icon && <Icon disabled={disabled}>{icon}</Icon>}
      {children}
    </Label>
  );
};

export default FormField;
