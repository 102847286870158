import { colors } from 'constants/colors';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ButtonLabel } from '.';
import { FormField, ErrorMessage } from './FormField';
import { TextAreaInput } from './TextAreaInput';

export const ModalTextAreaInput = styled(TextAreaInput)`
  width: 376px;
  height: 70px;
  padding: 15px;
  border-radius: 5px;
  margin-top: 0;
  font-size: 16px;
`;

const ToggleDisableButton = styled.div`
  position: absolute;
  top: 16px;
  right: 15px;
`;

export const Input = styled.input<{
  disabled?: boolean;
  error?: boolean;
}>`
  width: 100%;
  height: 50px;
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  font-size: 16px;
  line-height: 20px;
  padding: 0 15px;

  border-color: ${({ disabled, error }) =>
    disabled ? colors.black30 : error ? colors.darkRed : colors.greyDark};
  color: ${({ disabled }) => (disabled ? colors.black50 : colors.black)};
  background: ${colors.white};

  :focus {
    outline: none;
    border-color: ${({ error }) => (error ? colors.darkRed : colors.black)};
  }
`;

interface Props {
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
  type: string;
  title: string;
  disabled?: boolean;
  errorMessage?: string;
  placeholder?: string;
  autocomplete?: 'off' | 'on' | string;
  showDisableToggle?: boolean;
  onDisabledChange?(disableState: boolean): void;
  className?: string;
  textArea?: boolean;
  autoFocus?: boolean;
  icon?: string;
}

const FormTextInput: React.FC<Props> = ({
  onBlur,
  onChange,
  onFocus,
  value,
  name,
  title,
  disabled,
  errorMessage,
  placeholder,
  type,
  autocomplete = 'on',
  showDisableToggle,
  onDisabledChange,
  className,
  textArea,
  autoFocus,
  icon,
}) => {
  const [forceDisable, setForceDisable] = useState(false);

  const textInputRef = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    //Focus on input after enalbling the element
    setForceDisable(!!disabled);
  }, [disabled]);

  const previousDisabledState = useRef(disabled);

  useEffect(() => {
    if (previousDisabledState.current !== forceDisable) {
      onDisabledChange?.(forceDisable);
      previousDisabledState.current = forceDisable;
    }
  }, [forceDisable, onDisabledChange]);

  useEffect(() => {
    if (autoFocus && textInputRef.current) {
      textInputRef.current.focus();
    }
  }, [autoFocus, textInputRef]);

  return (
    <FormField
      className={className}
      name={name}
      title={title}
      disabled={forceDisable}
      icon={icon}
    >
      {!!textArea ? (
        <ModalTextAreaInput
          ref={textInputRef}
          rows={2}
          value={value}
          onChange={
            (onChange as unknown) as (
              e: React.FormEvent<HTMLTextAreaElement>
            ) => void
          }
          onBlur={
            (onBlur as unknown) as React.FocusEventHandler<HTMLTextAreaElement>
          }
          onFocus={
            (onFocus as unknown) as React.FocusEventHandler<HTMLTextAreaElement>
          }
        />
      ) : (
        <Input
          ref={textInputRef}
          autoComplete={autocomplete}
          disabled={forceDisable}
          id={name}
          name={name}
          type={type}
          onFocus={onFocus}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          error={!!errorMessage}
          placeholder={placeholder}
          autoFocus={autoFocus}
        />
      )}
      {showDisableToggle ? (
        <ToggleDisableButton
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            if (forceDisable) {
              textInputRef.current.focus();
            }

            setForceDisable(!forceDisable);
          }}
        >
          <ButtonLabel>Change</ButtonLabel>
        </ToggleDisableButton>
      ) : null}
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </FormField>
  );
};

export default FormTextInput;
