import {
  DataList,
  DropOverlay,
  GridPageSidebar,
  ListContainer,
  SidebarEmptyState,
} from 'components/GridPages/GridPageSidebar';
import {
  BodyNormal,
  BodySmall,
  CategoryTab,
  CountryFlag,
  Subtitle1,
} from 'components/shared';
import { colors } from 'constants/colors';
import { KeyStatementFragment } from 'data/graphql/generated';
import React, { useMemo, useRef } from 'react';
import { useDragLayer } from 'react-dnd';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  CommercialTacticFragmentWithGlobalFocusedStatus,
  CountryGlobalRegional,
  DragNDropItems,
} from 'types';
import { startCase } from '../../utils/startCase';
import { TacticGroup } from './TacticGroup';
import { globalCountryTabs } from './constants';

const StyledSidebarEmptyState = styled(SidebarEmptyState)`
  position: relative;
  inset: 0;
  height: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ListTitle = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const TabsWrapper = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  margin: 0 -20px;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${colors.greyLight};
  width: calc(100% + 40px);
  margin: 0 -20px;
  margin-bottom: 20px;
  padding: 0 20px;
`;

interface Props {
  localCountryTacticsEnabled: boolean;
  sidebarOpen: boolean;
  strategicObjectives: KeyStatementFragment[] | undefined;
  tactics: CommercialTacticFragmentWithGlobalFocusedStatus[] | undefined;
  offsetTop: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTouchPositioningView: React.Dispatch<React.SetStateAction<string | null>>;
  strategicImperativeId: string;
  canDrag: boolean;
  isGlobalView: boolean;
  activeCountryTabState: [string, React.Dispatch<React.SetStateAction<string>>];
  region: string;
  canAddTactic: boolean;
}

interface URLParams {
  drugId: string;
  strategyId: string;
}

export const CommercialTacticsSidebar: React.FC<Props> = ({
  localCountryTacticsEnabled,
  sidebarOpen,
  strategicObjectives = [],
  tactics = [],
  offsetTop,
  setSidebarOpen,
  setTouchPositioningView,
  strategicImperativeId,
  canDrag,
  isGlobalView,
  activeCountryTabState,
  region,
  canAddTactic,
}) => {
  const { drugId, strategyId }: URLParams = useParams();
  const dataListRef = useRef<HTMLDivElement | null>(null);
  const dataListTop = dataListRef.current?.offsetTop;

  const { isDragging, itemType } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    itemType: monitor.getItemType(),
  }));

  const [activeCountryTab, setActiveCountryTab] = activeCountryTabState;

  const activeTabIsGlobal =
    activeCountryTab === globalCountryTabs[0] || !localCountryTacticsEnabled;

  const sidebarHeaderText = activeTabIsGlobal
    ? 'Think about commercial initiatives that will have a significant impact on the ability to achieve the objective'
    : 'Local contributors should add and prioritise country-specific initiatives. Initiatives with focus become winning actions and are taken on at the country level.';

  const [globalTactics, countryTactics] = useMemo(
    () =>
      tactics.reduce(
        (acc, curr) => {
          if (curr.isGlobalLocalTactic || curr.isGlobalTactic) {
            acc[0].push(curr);
          } else {
            acc[1].push(curr);
          }
          return acc;
        },
        [[], []] as CommercialTacticFragmentWithGlobalFocusedStatus[][]
      ),
    [tactics]
  );

  const [globalStrategicObjectives, regionStrategicObjectives] = useMemo(
    () =>
      strategicObjectives.reduce(
        (acc, curr) => {
          if (curr.region === 'global') {
            acc[0].push(curr);
          }

          if (curr.region === region) {
            acc[1].push(curr);
          }

          return acc;
        },
        [[], []] as KeyStatementFragment[][]
      ),
    [region, strategicObjectives]
  );

  const regionObjectivesData = activeTabIsGlobal
    ? globalStrategicObjectives
    : regionStrategicObjectives;

  const regionTacticsData = activeTabIsGlobal ? globalTactics : countryTactics;

  const noGlobalObjectivesToShow =
    (isGlobalView || activeTabIsGlobal) && !globalStrategicObjectives?.length;
  const noRegionObjectivesToShow =
    !isGlobalView && !activeTabIsGlobal && !regionObjectivesData?.length;

  return (
    <GridPageSidebar
      sidebarOpen={sidebarOpen}
      offsetTop={offsetTop}
      setSidebarOpen={setSidebarOpen}
    >
      <InnerWrapper>
        {localCountryTacticsEnabled && !isGlobalView && (
          <TabContainer>
            <TabsWrapper>
              {globalCountryTabs.map((val) => {
                return (
                  <CategoryTab
                    key={val}
                    fivePxRadius
                    spreadEvenly
                    text={val}
                    active={val === activeCountryTab}
                    onClick={() => {
                      setActiveCountryTab(val);
                    }}
                  />
                );
              })}
            </TabsWrapper>
          </TabContainer>
        )}

        <BodyNormal style={{ marginBottom: 15 }}>
          {sidebarHeaderText}
        </BodyNormal>

        {noGlobalObjectivesToShow && (
          <StyledSidebarEmptyState title="No strategic objectives">
            <div>
              <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
                Commercial initiatives can only be added when a Lead has defined
                strategic objectives in{' '}
              </BodySmall>
              <Link
                to={`/d/${drugId}/strategy/${strategyId}/3_4/strategic-objectives/${strategicImperativeId}`}
                style={{
                  color: colors.greyDark,
                  display: 'inline',
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                3.4 Goals and Distinctive Capabilities
              </Link>
            </div>
          </StyledSidebarEmptyState>
        )}

        {noRegionObjectivesToShow && (
          <StyledSidebarEmptyState title="No strategic objectives">
            <div>
              <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
                Commercial initiatives can only be added when a Contributor has
                defined strategic objectives in{' '}
              </BodySmall>
              <Link
                to={`/d/${drugId}/strategy/${strategyId}/3_4/strategic-objectives/${strategicImperativeId}/${region}`}
                style={{
                  color: colors.greyDark,
                  display: 'inline',
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                3.4 Goals and Distinctive Capabilities
              </Link>
            </div>
          </StyledSidebarEmptyState>
        )}

        {!(noGlobalObjectivesToShow && noRegionObjectivesToShow) && (
          <React.Fragment
            key={
              'strategicImperativeId' +
              strategicImperativeId +
              'region' +
              region
            }
          >
            {isDragging && itemType === DragNDropItems.PIN && (
              <DropOverlay top={dataListTop || 0}>
                <Subtitle1>Drop here to remove</Subtitle1>
              </DropOverlay>
            )}

            {activeTabIsGlobal ? (
              <GlobalList
                dataListRef={dataListRef}
                strategicObjectives={globalStrategicObjectives}
                tactics={globalTactics}
                isDragging={isDragging}
                setTouchPositioningView={setTouchPositioningView}
                canDrag={canDrag}
                isGlobalView={isGlobalView}
                region={region}
                activeTabIsGlobal={activeTabIsGlobal}
                canAddTactic={canAddTactic}
              />
            ) : (
              <GlobalLocalList
                dataListRef={dataListRef}
                strategicObjectives={regionObjectivesData}
                tactics={regionTacticsData}
                region={region}
                isDragging={isDragging}
                setTouchPositioningView={setTouchPositioningView}
                canDrag={canDrag}
                isGlobalView={isGlobalView}
                activeTabIsGlobal={activeTabIsGlobal}
                canAddTactic={canAddTactic}
              />
            )}
          </React.Fragment>
        )}
      </InnerWrapper>
    </GridPageSidebar>
  );
};

interface GlobalListProps {
  dataListRef: React.MutableRefObject<HTMLDivElement | null>;
  strategicObjectives: KeyStatementFragment[];
  tactics: CommercialTacticFragmentWithGlobalFocusedStatus[];
  region: string;
  isDragging: boolean;
  setTouchPositioningView: React.Dispatch<React.SetStateAction<string | null>>;
  canDrag: boolean;
  isGlobalView: boolean;
  activeTabIsGlobal: boolean;
  canAddTactic: boolean;
}

function GlobalLocalList({
  dataListRef,
  strategicObjectives,
  tactics,
  region,
  isDragging,
  setTouchPositioningView,
  canDrag,
  canAddTactic,
}: GlobalListProps) {
  return (
    <DataList ref={dataListRef}>
      {strategicObjectives?.map((s) => {
        const tacticsForObjective = tactics.filter(
          (t) => t.keyStatementId === s.id
        );

        return (
          <ListContainer key={s.id}>
            {region && (
              <ListTitle>
                <CountryFlag
                  size={15}
                  country={region as CountryGlobalRegional}
                />
                <BodySmall
                  color={colors.greyDark}
                  style={{ marginLeft: 5, paddingTop: 2 }}
                >
                  {startCase(region)} specific
                </BodySmall>
              </ListTitle>
            )}

            <BodySmall style={{ display: 'inline' }} color={colors.greyDark}>
              Strategic objective:{' '}
            </BodySmall>
            <BodySmall style={{ display: 'inline' }}>{s.text}</BodySmall>

            <TacticGroup
              data={tacticsForObjective || []}
              keyStatementId={s.id}
              isDragging={isDragging}
              setTouchPositioningView={setTouchPositioningView}
              canDrag={canDrag}
              region={region}
              canAddTactic={canAddTactic}
            />
          </ListContainer>
        );
      })}
    </DataList>
  );
}

function GlobalList({
  dataListRef,
  strategicObjectives,
  tactics,
  isDragging,
  setTouchPositioningView,
  canDrag,
  region,
  canAddTactic,
}: GlobalListProps) {
  return (
    <DataList ref={dataListRef}>
      {strategicObjectives?.map((s) => {
        const tacticsForObjective = tactics.filter(
          (t) => t.keyStatementId === s.id
        );

        return (
          <ListContainer key={s.id}>
            <BodySmall style={{ display: 'inline' }} color={colors.greyDark}>
              Goals:{' '}
            </BodySmall>
            <BodySmall style={{ display: 'inline' }}>{s.text}</BodySmall>

            <TacticGroup
              data={tacticsForObjective || []}
              keyStatementId={s.id}
              isDragging={isDragging}
              setTouchPositioningView={setTouchPositioningView}
              canDrag={canDrag}
              region={region}
              canAddTactic={canAddTactic}
            />
          </ListContainer>
        );
      })}
    </DataList>
  );
}
