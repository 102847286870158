import '../styles/index.css';
import { Stage, Layer, Rect, Circle, Group, Path } from 'react-konva';
import { Portal } from 'react-konva-utils';
import styled, { css } from 'styled-components';
import { CollaborationContext } from 'contexts/CollaborationContext';
import { BlockCreate } from './BlockCreate';
import { useEffect, useState } from 'react';
import { BlockCard } from './BlockCard';
import { COLUMN_HEIGHT, RECT_HEIGHT, RECT_WIDTH } from '../../../constants';
import { clamp } from 'utils/clamp';
import { snapToGrid } from '../utils';
import { Columns } from './Columns';
import { device } from 'utils/breakpoints';
import { PatientJourneyStageType } from '../types/patientJourneyStageTypes';
import { ConnectorAction } from '../..';
import { ConnectorsLayer } from '../ui/ConnectorsLayer';
import {
  PatientJourneyPageContext,
  usePatientJourneyPageContext,
} from 'contexts/PatientJourneyPageContext';
import { useAuthContext } from 'contexts/AuthContext';
import { verifyUserRole } from 'utils/verifyUserRole';
import { usePatientJourneyDimensions } from 'hooks/usePatientJourneyDimensions';
import { useGetNavHeight } from 'hooks/useGetNavHeight';

const Wrapper = styled.div<{ inRecapSidebar: boolean; marginTop: number }>`
  background: #dbdbdb;

  ${({ inRecapSidebar, marginTop }) =>
    inRecapSidebar
      ? css`
          margin-top: 0px;
          @media ${device.desktopMin} {
            margin-top: 0px;
          }
        `
      : css`
          margin-top: ${marginTop}px;
        `}
`;

const StageWrapper = styled.div`
  overflow: hidden;
`;

export function PatientJourneyStage({
  highLevelStageRef,
  isPreview,
  inRecapSidebar,
  stageRef,
  isDrag,
  columnTotalWidth,
  changeCursor,
  createShow,
  createHide,
  columns,
  create,
  newConnection,
  selectedConnection,
  setActiveColumn,
  getColumnIdxForX,
  setSelectedConnection,
  setNewConnection,
  collaborationContextData,
  blocks,
  connections,
  columnStartingPoints,
  activeColumn,
  updateColumn,
  updateColumnLocal,
  updateMany,
  setDisableActions,
  setCreate,
  layerRef,
  blockBeingDeleted,
  updateBlockLocal,
  updateConnection,
  updateConnectionLocal,
  removeBlock,
  showDotsForBlock,
  hideDotsForBlock,
  editing,
  setEditing,
  setBlockBeingDeleted,
  createConnection,
  createBlock,
  removeConnection,
  adjustFromScreenY,
  adjustFromScreenX,

  handleBlockIconClick,
}: PatientJourneyStageType) {
  const [sideBarWidth, setSideBarWidth] = useState(555);
  const pageContextData = usePatientJourneyPageContext();
  const { closeSidebar } = pageContextData;

  const { canvasWidth, canvasHeight } = usePatientJourneyDimensions();
  const navHeight = useGetNavHeight();

  useEffect(() => {
    // Checks width of sidebar to update the maximum scroll position
    const updateSideBarWidth = () => {
      const sideBar = document.querySelector(
        '.patient-journey-sidebar'
      ) as HTMLDivElement | null;

      if (!sideBar) return;

      setSideBarWidth(sideBar.offsetWidth);
    };

    window.addEventListener('resize', updateSideBarWidth);
    return () => {
      window.removeEventListener('resize', updateSideBarWidth);
    };
  }, []);
  // Allows the stage to move around properly within the sidebar
  const spaceAfterPreviewSidebar = inRecapSidebar
    ? window.innerWidth -
      sideBarWidth -
      // removes gap at the end
      10
    : 0;

  const [{ user }] = useAuthContext();
  const { isLead: canEdit } = verifyUserRole(user?.role, user?.country);
  return (
    <Wrapper
      marginTop={navHeight}
      className={'patient-journey-wrapper'}
      inRecapSidebar={!!inRecapSidebar}
    >
      <StageWrapper>
        <Stage
          ref={(e) => {
            stageRef.current = e;
            highLevelStageRef.current = e;
          }}
          width={canvasWidth}
          height={canvasHeight}
          x={2}
          y={2}
          draggable={isDrag}
          dragBoundFunc={(pos) => {
            let dragPosition = { x: 2, y: 2 };

            if (!inRecapSidebar && columnTotalWidth < canvasWidth) {
              // No room to move in X axis
              dragPosition = {
                x: 2,
                y: clamp(pos.y, -1200 + canvasHeight - 10, 2),
              };
            }

            dragPosition = {
              x: clamp(
                pos.x,
                -columnTotalWidth + canvasWidth - 10 - spaceAfterPreviewSidebar,
                2
              ),
              y: clamp(pos.y, -1200 + canvasHeight - 10, 2),
            };
            return dragPosition;
          }}
          onDragStart={() => {
            changeCursor('grabbing');
          }}
          onDragEnd={() => {
            changeCursor('default');
          }}
          onMouseEnter={() => {
            if (isDrag) return;
            createShow();
          }}
          onMouseLeave={() => {
            if (isDrag) return;
            setCreate();
            createHide();
          }}
          onMouseMove={(e) => {
            if (isDrag) return;

            // create
            const layer = layerRef.current;
            const stage = stageRef.current;
            if (!layer || !stage) return;

            const block = layer.findOne(`#create`);
            const mouseEvent = e.evt as MouseEvent & {
              layerX: number;
              layerY: number;
            };
            if (!mouseEvent) return;
            const isOverColumnTitle = adjustFromScreenY(mouseEvent.layerY) < 35;
            // WORKING
            const isOverColumnEdges = columns.reduce(
              (acc, val) => {
                const isOver =
                  adjustFromScreenX(mouseEvent.layerX) >=
                    acc.cumulativeWidth + val.width - 15 &&
                  adjustFromScreenX(mouseEvent.layerX) <=
                    acc.cumulativeWidth + val.width;

                return {
                  isOver: isOver ? true : acc.isOver,
                  cumulativeWidth: acc.cumulativeWidth + val.width + 2,
                };
              },
              { isOver: false, cumulativeWidth: 0 }
            ).isOver;

            if (
              !!create ||
              newConnection.path.length ||
              !!selectedConnection ||
              isOverColumnTitle ||
              isOverColumnEdges
            ) {
              createHide();
            } else {
              if (
                adjustFromScreenY(mouseEvent.layerY) >= 40 &&
                e.target.className === 'Rect'
              ) {
                createShow();
              }
            }

            if (isDrag) createHide();

            setActiveColumn(
              getColumnIdxForX(adjustFromScreenX(mouseEvent.layerX))
            );

            block.position({
              x: snapToGrid(
                adjustFromScreenX(mouseEvent.layerX) - RECT_WIDTH / 2
              ),
              y: snapToGrid(
                adjustFromScreenY(mouseEvent.layerY) - RECT_HEIGHT / 2
              ),
            });
            layer.batchDraw();

            // new connection
            if (newConnection.path.length) {
              const connectionsLayer = layerRef.current;
              if (!connectionsLayer) return;
              const newLineNode = connectionsLayer.findOne(`#new-line`);

              // fix either x or y
              const lastX = newConnection.path[newConnection.path.length - 2];
              const xDiff = lastX - adjustFromScreenX(mouseEvent.layerX);
              const lastY = newConnection.path[newConnection.path.length - 1];
              const yDiff = lastY - adjustFromScreenY(mouseEvent.layerY);
              const isXLarger = Math.abs(xDiff) > Math.abs(yDiff);
              newLineNode.attrs.points = [
                ...newConnection.path,
                snapToGrid(
                  isXLarger ? adjustFromScreenX(mouseEvent.layerX) : lastX
                ),
                snapToGrid(
                  isXLarger ? lastY : adjustFromScreenY(mouseEvent.layerY)
                ),
              ];

              const newLineDotNode = connectionsLayer.findOne(`#new-line-dot`);
              newLineDotNode.position({
                x: snapToGrid(
                  isXLarger ? adjustFromScreenX(mouseEvent.layerX) : lastX
                ),
                y: snapToGrid(
                  isXLarger ? lastY : adjustFromScreenY(mouseEvent.layerY)
                ),
              });
              connectionsLayer.batchDraw();
            }
          }}
          onClick={(e) => {
            if (isDrag) return;
            closeSidebar();

            const mouseEvent = e.evt as MouseEvent & {
              layerX: number;
              layerY: number;
            };
            const stage = stageRef.current;

            if (!mouseEvent || !stage) return;
            // deselect connection

            if (
              layerRef.current &&
              selectedConnection &&
              e.target.className === 'Rect'
            ) {
              setSelectedConnection();
              layerRef.current.findOne('#create').show();
            }

            // new connection
            if (newConnection.path.length) {
              // fix either x or y
              const lastX = newConnection.path[newConnection.path.length - 2];
              const xDiff = lastX - adjustFromScreenX(mouseEvent.layerX);
              const lastY = newConnection.path[newConnection.path.length - 1];
              const yDiff = lastY - adjustFromScreenY(mouseEvent.layerY);
              const isXLarger = Math.abs(xDiff) > Math.abs(yDiff);
              setNewConnection({
                ...newConnection,
                path: [
                  ...newConnection.path,
                  snapToGrid(
                    isXLarger ? adjustFromScreenX(mouseEvent.layerX) : lastX
                  ),
                  snapToGrid(
                    isXLarger ? lastY : adjustFromScreenY(mouseEvent.layerY)
                  ),
                ],
              });

              const connectionsLayer = layerRef.current;
              if (!connectionsLayer) return;
              const newLineDotNode = connectionsLayer.findOne(`#new-line-dot`);
              newLineDotNode.position({
                x: snapToGrid(
                  isXLarger ? adjustFromScreenX(mouseEvent.layerX) : lastX
                ),
                y: snapToGrid(
                  isXLarger ? lastY : adjustFromScreenY(mouseEvent.layerY)
                ),
              });
            }
          }}
        >
          <PatientJourneyPageContext.Provider value={pageContextData}>
            <CollaborationContext.Provider value={collaborationContextData}>
              <Layer ref={layerRef}>
                <Columns
                  columns={columns}
                  blocks={blocks}
                  connections={connections}
                  stageRef={stageRef}
                  columnStartingPoints={columnStartingPoints}
                  changeCursor={changeCursor}
                  activeColumn={activeColumn}
                  isDrag={isDrag}
                  updateColumn={updateColumn}
                  updateColumnLocal={updateColumnLocal}
                  updateMany={updateMany}
                  setDisableActions={setDisableActions}
                  setCreate={setCreate}
                />

                <Group
                  visible={false}
                  id="create"
                  onMouseEnter={() => changeCursor('pointer')}
                  onMouseLeave={() => changeCursor('default')}
                  onMouseDown={(e) => {
                    closeSidebar();

                    // deselect connection
                    if (selectedConnection) setSelectedConnection();
                    const layer = layerRef.current;
                    const mouseEvent = e.evt as MouseEvent & {
                      layerX: number;
                      layerY: number;
                    };
                    const stage = stageRef.current;

                    if (!mouseEvent || !stage || !layer || !columns) return;

                    // create block
                    const blockNode = layer.findOne(`#create`);
                    // hide block
                    blockNode.hide();

                    const colIdx = getColumnIdxForX(
                      adjustFromScreenX(mouseEvent.layerX)
                    );
                    const column = columns.find((c) => {
                      return c.idx === colIdx;
                    });

                    const buffer = 40;

                    const x = clamp(
                      snapToGrid(blockNode.x()),
                      buffer,
                      columnTotalWidth - buffer - RECT_WIDTH
                    );

                    const y = clamp(
                      snapToGrid(blockNode.y()),
                      buffer,
                      COLUMN_HEIGHT - RECT_HEIGHT - buffer
                    );
                    const firstColumnId = columns.find((c) => c.idx === 0)?.id;
                    const lastColumnId = columns.find((c) => c.idx === 4)?.id;

                    if (!firstColumnId || !lastColumnId) {
                      return;
                    }

                    setCreate({
                      x,
                      y,
                      column: column
                        ? column.id
                        : x < 0
                        ? firstColumnId
                        : lastColumnId,
                    });
                  }}
                >
                  <Rect
                    width={RECT_WIDTH}
                    height={RECT_HEIGHT}
                    cornerRadius={5}
                    sides={4}
                    fill="rgba(255, 255, 255, 0.7)"
                  />
                  <Circle
                    x={RECT_WIDTH / 2}
                    y={RECT_HEIGHT / 2}
                    radius={20}
                    width={20}
                    height={20}
                    fill="rgba(255, 255, 255, 0.7)"
                    stroke="rgba(20,20,39,0.2)"
                    strokeWidth={1.5}
                  />
                  <Path
                    x={RECT_WIDTH / 2 - 15}
                    y={RECT_HEIGHT / 2 - 15}
                    data="M16 9H14V14H9V16H14V21H16V16H21V14H16V9Z"
                    fill="#5B5B68"
                  />
                </Group>

                <ConnectorsLayer
                  layerConnectionsRef={layerRef}
                  newConnection={newConnection}
                  connections={connections}
                  selectedConnection={selectedConnection}
                  isDrag={isDrag}
                  setSelectedConnection={setSelectedConnection}
                  changeCursor={changeCursor}
                  createHide={createHide}
                  createShow={createShow}
                  updateConnection={updateConnection}
                  adjustFromScreenX={adjustFromScreenX}
                  adjustFromScreenY={adjustFromScreenY}
                  blocks={blocks}
                  createConnection={createConnection}
                  setNewConnection={setNewConnection}
                  showDotsForBlock={showDotsForBlock}
                  hideDotsForBlock={hideDotsForBlock}
                />
                
                <Group>
                  {blocks.map((block) => {
                    return (
                      <Portal
                        key={block.id}
                        selector=".top-layer"
                        enabled={blockBeingDeleted === block.id}
                      >
                        <Group>
                          <BlockCard
                            disableCollaboration={isPreview}
                            inRecapSidebar={inRecapSidebar}
                            block={block}
                            blocks={blocks}
                            //Prevent dragging when a connector is being created by checking newConnection.path.length
                            isDrag={
                              Boolean(newConnection.path.length) || isDrag
                            }
                            updateBlockLocal={updateBlockLocal}
                            updateConnectionLocal={updateConnectionLocal}
                            changeCursor={changeCursor}
                            columnStartingPoints={columnStartingPoints}
                            stageRef={stageRef}
                            removeBlock={removeBlock}
                            showDotsForBlock={showDotsForBlock}
                            hideDotsForBlock={hideDotsForBlock}
                            connections={connections}
                            forceEdit={editing.includes(block.id)}
                            setEditing={(val: number[]) => setEditing(val)}
                            getColumnIdxForX={getColumnIdxForX}
                            columns={columns}
                            setActiveColumn={setActiveColumn}
                            updateMany={updateMany}
                            setBlockBeingDeleted={(
                              blockId: number | undefined
                            ) => setBlockBeingDeleted(blockId)}
                            columnTotalWidth={columnTotalWidth}
                            adjustFromScreenY={adjustFromScreenY}
                            adjustFromScreenX={adjustFromScreenX}
                            canEdit={canEdit}
                            handleBlockIconClick={handleBlockIconClick}
                          />
                        </Group>
                      </Portal>
                    );
                  })}
                </Group>

                
              </Layer>
              <Layer name="top-layer">
                {create ? (
                  <BlockCreate
                    block={create}
                    createBlock={(val) => createBlock({ ...val })}
                    changeCursor={changeCursor}
                    onSuccess={(block) => {
                      setCreate();
                      setEditing([...editing, block.id]);
                    }}
                  />
                ) : null}
              </Layer>
            </CollaborationContext.Provider>
          </PatientJourneyPageContext.Provider>
        </Stage>
      </StageWrapper>

      {!!selectedConnection && (
        <ConnectorAction
          text="Delete connector"
          handleClick={() => {
            removeConnection(selectedConnection);
            setSelectedConnection();
          }}
        />
      )}
      {!!newConnection.path.length && (
        <ConnectorAction
          text="Cancel connector"
          handleClick={() => {
            setNewConnection({
              toBlockId: undefined,
              toSide: undefined,
              fromBlockId: undefined,
              fromSide: undefined,
              path: [],
            });

            const layer = layerRef.current;
            if (!layer) return;
            // hide all dots
            const dots = layer.find('.dot');
            Object.keys(dots).forEach((k) => {
              const d = dots[k as keyof typeof dots];
              if (!d || typeof d === 'number' || !('opacity' in d)) return;
              d.opacity(0);
            });
            layer.draw();
          }}
        />
      )}
    </Wrapper>
  );
}
