import { BodySmall, Collaboration, CountryFlag } from 'components/shared';
import { colors } from 'constants/colors';
import { useAuthContext } from 'contexts/AuthContext';
import { BigIdeaFragment } from 'data/graphql/generated';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { BigIdeaFocusDropdowns } from './BigIdeaFocusDropdowns';

const Wrapper = styled.div<{ active: boolean }>`
  width: 339px;
  min-width: 339px;
  max-width: 339px;
  height: 467px;
  background: ${colors.white};
  border: 1px solid
    ${({ active }) => (active ? colors.purple : colors.greyMedium)};
  border-radius: 5px;
  display: inline-block;
  float: left;
  vertical-align: top;
  cursor: pointer;
  position: relative;
`;

const ImageWrapper = styled.div<{ src: string }>`
  border-radius: 3px;
  margin: 1px;
  height: 335px;
  width: calc(100% - 2px);
  position: relative;
  overflow: hidden;
  background: ${colors.greyLight};

  ${({ src }) =>
    src
      ? css``
      : css`
          background-image: url('/Generic-Empty-State.svg');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 110px 110px;
        `};
`;

const ImageBG = styled.div<{ src: string }>`
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1.5);
  filter: blur(15px);

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Image = styled.div<{ src: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  ${({ src }) =>
    css`
      background-image: url(${src});
    `};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const Content = styled.div`
  padding: 15px;
`;

const Title = styled(BodySmall)`
  color: ${colors.black};
  margin-bottom: 15px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const TitlePlaceholder = styled(Title)`
  color: ${colors.greyDark};
`;

const AddedBy = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 15px;
  left: 15px;
`;

const StyledCollaboration = styled(Collaboration)`
  position: absolute;
  bottom: 15px;
  right: 15px;
`;

const FocusWrapper = styled.div`
  position: absolute;
  top: 7px;
  left: 7px;
`;

interface Props {
  countryTacticsEnabled: boolean;
  onClick(): void;
  bigIdea: BigIdeaFragment;
  active: boolean;
  bigIdeasRefetch(): void;
}

export const BigIdeaPreview: React.FC<Props> = ({
  countryTacticsEnabled,
  onClick,
  bigIdea,
  active = false,
  bigIdeasRefetch,
}) => {
  const [{ user }] = useAuthContext();

  return (
    <>
      <Wrapper onClick={onClick} active={active} className="cypress-bigidea">
        <ImageWrapper src={bigIdea?.image || ''}>
          <ImageBG src={bigIdea?.image || ''} />
          <Image src={bigIdea?.image || ''} />
        </ImageWrapper>

        <FocusWrapper>
          <BigIdeaFocusDropdowns
            bigIdea={bigIdea}
            user={user}
            countryTacticsEnabled={countryTacticsEnabled}
            bigIdeasRefetch={bigIdeasRefetch}
          />
        </FocusWrapper>

        <Content>
          {bigIdea.title ? (
            <Title>{bigIdea.title}</Title>
          ) : (
            <TitlePlaceholder>New Big Idea</TitlePlaceholder>
          )}

          <AddedBy>
            <BodySmall color={colors.greyDark} style={{ marginRight: 5 }}>
              Added by
            </BodySmall>
            <CountryFlag size="sm" user={bigIdea.user} />
          </AddedBy>

          <StyledCollaboration collaboration={bigIdea.collaboration} />
        </Content>
      </Wrapper>
    </>
  );
};
