import { AuthContext } from 'contexts/AuthContext';
import { useSignInMutation } from 'data/graphql/generated';
import get from 'lodash/get';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

export const useSignInRedirect = () => {
  const [signin] = useSignInMutation();
  const [, setAuth] = useContext(AuthContext);
  const history = useHistory();

  return async (email: string, password: string) => {
    const res = await signin({
      variables: {
        email,
        password: password,
      },
    });

    const signInData = get(res, 'data.signIn');

    setAuth({
      user: signInData.user,
    });
    if (signInData?.user?.role === 'ADMIN') {
      history.push('/admin');
    } else {
      history.push('/');
    }
  };
};
