import {
  BodySmall,
  Caption,
  Icon,
  Subtitle1,
  Subtitle2,
} from 'components/shared';
import { colors } from 'constants/index';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Country, CountryGlobal } from 'types';
import { BarRow } from './BarRow';
import { DropdownToggle } from './DropdownToggle';
import { ModalLarge, Content, MobileHeader } from 'components/ModalLarge';
import { device } from 'utils/breakpoints';

const StyledModalLarge = styled(ModalLarge)`
  ${Content} {
    position: relative;
    inset: 0;
    max-width: 515px;
  }

  ${MobileHeader} {
    display: none;
  }
`;

const NoDataWrapper = styled.div`
  display: flex;

  > * + * {
    margin-left: 5px;
  }

  align-items: center;
`;

const CloseIcon = styled.div`
  cursor: pointer;
`;

const StyledSubtitle1 = styled(Subtitle1)`
  margin-bottom: 5px;
`;

const ModalContent = styled.section`
  padding: 40px;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @media ${device.mobile} {
    padding-top: 80px;
  }
`;

const Results = styled.section`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  > * + * {
    margin-top: 20px;
  }

  flex: 1;
  position: relative;
`;

const Header = styled.div`
  background: ${colors.cream};
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  z-index: 1;

  position: absolute;
  inset: 0;

  border-radius: 5px 5px 0px 0px;

  @media ${device.mobile} {
    border-radius: 0px;
  }
`;

interface Props {
  isOpen: boolean;
  setIsOpen?: React.Dispatch<
    React.SetStateAction<
      | false
      | {
          title: string;
          type: 'block' | 'stage';
          id: number;
        }
    >
  >;
  data:
    | {
        region: string;
        value?: number | null | undefined;
      }[]
    | undefined;
  title: string;
  allCountries: Country[];
  countriesStarredOnBlocks: Record<number, string[]>;
  caller:
    | false
    | {
        title: string;
        type: 'block' | 'stage';
        id: number;
      };
  idsOfBlocksInStages: Record<number, number[]>;
}

export const FiguresDistributionModal = ({
  isOpen,
  setIsOpen,
  data,
  title,
  allCountries,
  countriesStarredOnBlocks,
  caller,
  idsOfBlocksInStages,
}: Props) => {
  const [showAsPercentage, setShowAsPercentage] = useState(false);

  if (!isOpen || !data) {
    return null;
  }
  const globalObject = data.find(
    (blockValue) => blockValue?.region === 'global'
  );

  const globalValue = globalObject?.value || 0;

  const maxValue =
    data.length < 1
      ? 0
      : Math.max(
          ...data.map((val) => {
            if (typeof val?.value === 'number') {
              return val.value;
            }
            return 0;
          })
        );

  const noValueCountries = allCountries.filter((country) => {
    return !data.some((blockValue) => blockValue?.region === country);
  });

  return (
    <StyledModalLarge
      hideMobileBack
      handleClose={() => {
        setIsOpen?.(false);
      }}
      headerColor={colors.cream}
      visible
    >
      <Header>
        <DropdownToggle
          showAsPercentage={showAsPercentage}
          setShowAsPercentage={setShowAsPercentage}
        />
        <CloseIcon
          className={'cypress-figures-distribution-close-icon'}
          onClick={() => {
            setIsOpen?.(false);
          }}
        >
          <Icon name="Close" size={30} color={colors.black} />
        </CloseIcon>
      </Header>
      <ModalContent>
        <section style={{ textAlign: 'center' }}>
          <StyledSubtitle1>{title}</StyledSubtitle1>
          <Caption color={colors.greyDark}>
            Market totals may not sum to global value
          </Caption>
        </section>

        <Results>
          {!data || (showAsPercentage && !globalValue) ? (
            <EmptyState />
          ) : (
            <>
              <BarRow
                key={'global'}
                isGlobal
                showAsPercentage={showAsPercentage}
                maxValue={maxValue || globalValue}
                value={globalValue}
                country={'global'}
                starred={
                  !!caller &&
                  (caller.type === 'block'
                    ? countriesStarredOnBlocks[caller?.id]?.includes('global')
                    : idsOfBlocksInStages[caller?.id]?.some((blockId) =>
                        countriesStarredOnBlocks[blockId]?.includes('global')
                      ))
                }
              />
              {data.map((blockValue) =>
                blockValue?.region !== 'global' ? (
                  <BarRow
                    key={blockValue?.region}
                    showAsPercentage={showAsPercentage}
                    maxValue={maxValue || globalValue}
                    value={Number(blockValue?.value)}
                    country={blockValue?.region as CountryGlobal}
                    starred={
                      !!caller &&
                      (caller.type === 'block'
                        ? countriesStarredOnBlocks[caller?.id]?.includes(
                            blockValue?.region
                          )
                        : idsOfBlocksInStages[caller?.id]?.some((blockId) =>
                            countriesStarredOnBlocks[blockId]?.includes(
                              blockValue?.region
                            )
                          ))
                    }
                  />
                ) : null
              )}

              {noValueCountries.map((country) => (
                <BarRow
                  key={country}
                  showAsPercentage={showAsPercentage}
                  country={country}
                  starred={false}
                />
              ))}
            </>
          )}
        </Results>
      </ModalContent>
    </StyledModalLarge>
  );
};

export const EmptyState = () => {
  return (
    <div>
      <NoDataWrapper>
        <Icon name="LeadGlobe" size={30} />
        <BodySmall>No data</BodySmall>
      </NoDataWrapper>
      <Caption style={{ marginLeft: 35, marginTop: 2 }} color={colors.greyDark}>
        Global
      </Caption>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: 171,
          backgroundColor: colors.yellow20,
          marginTop: 20,
        }}
      >
        <Subtitle2 color={colors.greyDark}>
          Cannot calculate percentages
        </Subtitle2>
        <BodySmall color={colors.greyDark} style={{ marginTop: 5 }}>
          There must be a global value to derive market distribution
        </BodySmall>
      </div>
    </div>
  );
};
