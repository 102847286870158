import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { IconName } from 'types';
import { colors } from 'constants/index';
import { Icon } from '.';
import { BodySmall, Caption } from './TextStyles';

const Wrapper = styled.div<{
  top?: number;
  left?: number;
  reversed?: boolean;
}>`
  display: flex;
  ${({ reversed }) =>
    reversed &&
    css`
      flex-direction: row-reverse;
    `}
  align-items: center;
  background: #ffffff;
  padding: ${({ reversed }) => (reversed ? '5px;' : `5px 20px 5px 5px;`)}
  border-radius: 30px;
  height: 40px;
  width: fit-content;
  white-space: nowrap;
  position: relative;
  transition: padding 0.2s;

  &::after {
    transition: opacity 0.2s 0s;
    opacity: 0;
    content: '';
    background: url(/arrow-forward.svg) no-repeat;
    width: 18px;
    height: 13px;
    position: absolute;
    top: 53%;
    transform: translateY(-50%);
    right: 10px;
  }

  &:hover {
    padding-right: 40px;
    &::after {
      transition: opacity 0.2 0.2s;
      opacity: 1;
    }
  }

  &:active {
    background: #e7e7e9;
  }
`;

export const Text = styled(BodySmall)`
  color: ${colors.black};
  font-size: 14px;
  font-weight: 400;
`;

export const TextContainer = styled.div<{ reversed?: boolean }>`
  margin-left: 10px;
  ${({ reversed }) =>
    reversed &&
    css`
      margin-right: 10px;
    `}
`;
interface Props {
  text?: string;
  name: IconName;
  link?: string;
  subtext?: string;
  reversed?: boolean;
  iconStyle?: React.CSSProperties;
  iconColor?: string;
  iconTextColor?: string;
  className?: string;
  iconText?: string | number;
  onClick?(): void;
}

export const ButtonArrow: React.FC<Props> = ({
  text,
  link = '#',
  name,
  subtext,
  reversed = false,
  iconStyle,
  iconColor,
  iconTextColor,
  className,
  onClick,
  children,
  iconText,
}) => {
  return (
    <Link to={link} onClick={onClick}>
      <Wrapper reversed={reversed} className={className}>
        {!!name && (
          <div
            style={{
              display: 'grid',
              placeContent: 'center',
              position: 'relative',
              width: 30,
              height: 30,
            }}
          >
            <Icon
              name={name}
              size={30}
              style={{ ...iconStyle, position: 'absolute' }}
              color={iconColor}
            />
            <BodySmall
              style={{ zIndex: 0, lineHeight: '30px' }}
              color={iconTextColor}
            >
              {iconText}
            </BodySmall>
          </div>
        )}
        <TextContainer reversed={reversed}>
          {children || (
            <>
              {!!text && <Text>{text}</Text>}
              {!!subtext && (
                <Caption color={colors.greyDark}>{subtext}</Caption>
              )}
            </>
          )}
        </TextContainer>
      </Wrapper>
    </Link>
  );
};
