import React, { useState } from 'react';
import styled from 'styled-components';

import {
  AnimatingWaveIcon,
  BodySmall,
  ButtonLabel,
  ButtonRound,
  Caption,
  Icon,
} from 'components/shared';
import { colors } from 'constants/index';
import { formatCreatedDate } from 'utils/formatCreatedDate';
import {
  Attachment,
  useAttachmentViewUrlMutation,
  useAttachmentDeleteMutation,
  CollaborationDocument,
} from 'data/graphql/generated';
import { downloadURI } from 'components/PatientJourney/src/lib/utils/downloadURI';
import { getUserTitleOrCountryAndName } from 'utils/getUserTitleOrCountryAndName';

const FileDetailsWrapper = styled.div`
  display: block;
  > p:first-child {
    margin-bottom: 2px;
  }
`;

const FileName = styled(BodySmall)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UserNameAndTime = styled.div`
  display: flex;
  > p:first-child {
    margin-right: 4px;
  }
`;

const File = styled.div<{ redBorder: boolean }>`
  margin-top: 15px;
  padding: 10px 15px 10px 47px;
  border-radius: 5px;
  min-height: 57px;
  cursor: pointer;
  border: 0.5px solid
    ${({ redBorder }) => (redBorder ? colors.darkRed : colors.black30a)};
  position: relative;

  .file__trash {
    visibility: hidden;

    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }

  :hover {
    border-color: #141427;
    .file__trash {
      visibility: visible;
    }
  }
`;

const FileIcon = styled.div`
  position: absolute;
  top: 14px;
  left: 10px;
`;

const DeleteConfirmation = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  padding: 0px 15px;
  background: white;
  display: flex;
  align-items: center;
  border-radius: 5px;

  > div:last-child {
    margin-left: auto;
    width: 120px;
    display: flex;
    justify-content: space-between;
  }
`;

interface Props {
  file: Attachment;
  uploading?: boolean;
  failedUpload?: boolean;
  errMsg?: string;
  clearErr(): void;
  onDelete(attachment: Attachment): void;
  canBeDeleted?: boolean;
}

export const AttachmentPreview: React.FC<Props> = ({
  file,
  uploading = false,
  failedUpload = false,
  errMsg,
  clearErr,
  onDelete,
  canBeDeleted = false,
}) => {
  const [viewAttachment] = useAttachmentViewUrlMutation();
  const [deleteAttachment] = useAttachmentDeleteMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: CollaborationDocument,
        variables: {
          id: file.collaborationId,
        },
      },
    ],
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  return (
    <File redBorder={failedUpload}>
      {canBeDeleted && !uploading && !showDeleteConfirmation ? (
        <ButtonRound
          className="file__trash"
          iconName="Trash"
          level="secondary"
          size="small"
          tooltip="Delete file"
          onClick={(e) => {
            e.preventDefault();
            setShowDeleteConfirmation(true);
          }}
          noWrap
        />
      ) : null}

      <div
        onClick={async () => {
          if (failedUpload || uploading) return;
          const { data } = await viewAttachment({ variables: { id: file.id } });
          const url = data?.attachmentViewUrl;
          if (!url) return;

          downloadURI(url, file.title);
        }}
      >
        <FileIcon>
          {failedUpload ? (
            <Icon
              onClick={clearErr}
              name="Frown"
              size={30}
              color={colors.darkRed}
            />
          ) : uploading ? (
            <AnimatingWaveIcon size={30} />
          ) : (
            <Icon name="Files" size={30} color={colors.purple} />
          )}
        </FileIcon>

        <FileDetailsWrapper>
          <FileName>{file?.title}</FileName>

          {failedUpload ? (
            <Caption color={colors.darkRed}>{errMsg}</Caption>
          ) : uploading ? (
            <Caption color={colors.greyMedium}>Uploading...</Caption>
          ) : (
            <UserNameAndTime>
              <Caption>{getUserTitleOrCountryAndName(file?.user)}</Caption>
              <Caption style={{ whiteSpace: 'nowrap' }} color={colors.greyDark}>
                {formatCreatedDate(Number(file?.createdAt))}
              </Caption>
            </UserNameAndTime>
          )}
        </FileDetailsWrapper>
      </div>

      {!uploading && showDeleteConfirmation ? (
        <DeleteConfirmation>
          <BodySmall>Delete this file?</BodySmall>
          <div>
            <ButtonLabel onClick={() => setShowDeleteConfirmation(false)}>
              Cancel
            </ButtonLabel>
            <ButtonLabel
              color={colors.red}
              onClick={async () => {
                if (isDeleting) return;
                setDeleting(true);
                try {
                  await deleteAttachment({
                    variables: { id: file.id },
                  });
                } catch (err) {
                  alert('Something went wrong');
                  console.error(err);
                  return;
                }
                setShowDeleteConfirmation(false);
                onDelete(file);
              }}
            >
              Delete
            </ButtonLabel>
          </div>
        </DeleteConfirmation>
      ) : null}
    </File>
  );
};
