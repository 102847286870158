import { colors } from 'constants/index';
import { PatientJourneyBlockFragment } from 'data/graphql/generated';
import { BlockCardPreview } from '../../components/BlockCardPreview';
import { SectionContainer } from './SectionContainer';

type Props = {
  block: PatientJourneyBlockFragment | undefined;
  onUpdate: (val: string) => Promise<void>;
  readOnly?: boolean;
};

const BlockCardPreviewContainer = ({
  block,
  onUpdate,
  readOnly = false,
}: Props) => {
  if (!block) return null;

  return (
    <SectionContainer
      style={{
        position: 'relative',
        background: colors.black02OnWhite,
      }}
    >
      <BlockCardPreview block={block} onUpdate={onUpdate} readOnly={readOnly} />
    </SectionContainer>
  );
};

export default BlockCardPreviewContainer;
