import React, { useState } from 'react';
import {
  BodySmall,
  ButtonLabel,
  ButtonPill,
  Icon,
  Subtitle2,
} from 'components/shared';
import { colors } from 'constants/index';
import { useDropZoneContext } from 'contexts/DropZoneContext';
import useDesktop from 'hooks/useDesktop';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';
import { useAttachmentCreate } from 'hooks/useAttachmentCreate';
import {
  Attachment,
  CollaborationDocument,
  CollaborationFragment,
} from 'data/graphql/generated';
import { AttachmentPreview } from './Attachment';

const EmptyState = styled.div`
  border: 1px solid ${colors.black30a};
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  margin-top: 11px;
  > * + * {
    margin-top: 5px;
  }
  @media ${device.tabletMax} {
    padding: 25px 0;
  }
`;

const ShowMoreDropdown = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
`;

const Wrapper = styled.div<{ isDragActive: boolean }>`
  width: 100%;

  position: relative;

  &::after {
    font-family: ABCFavorit;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 30px;
    content: 'Drop file';

    position: absolute;
    display: grid;
    place-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${({ isDragActive }) => Number(isDragActive)};
    visibility: ${({ isDragActive }) => (isDragActive ? 'visible' : 'hidden')};
    background: ${({ isDragActive }) => {
      //https://kovart.github.io/dashed-border-generator/
      const dashes = isDragActive
        ? `
        ${
          colors.white90
        } url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23${colors.purple.slice(
            1
          )}' stroke-width='2' stroke-dasharray='10 5' stroke-dashoffset='100' stroke-linecap='butt'/%3e%3c/svg%3e")`
        : '';
      return dashes;
    }};
    border-radius: 5px;
    transition: opacity 0.3s;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > input {
    display: none;
  }
`;

interface Props {
  data: CollaborationFragment['attachments'] | undefined;
  className?: string;
  collaborationId?: number;
}

export const Attachments = ({ className, data, collaborationId }: Props) => {
  const [showAllAttachments, setShowAllAttachments] = useState(false);
  const isDesktop = useDesktop();

  const {
    dropzone: { getRootProps, getInputProps, inputRef },
    uploading,
    uploadingName,
    failedUpload,
    errMsg,
    clearErr,
  } = useAttachmentCreate({
    collaborationId,
    onSuccess: () => {
      const hiddenInput = inputRef.current;
      if (!hiddenInput) return;
      hiddenInput.value = '';
    },
    attachmentCreateArgs: {
      refetchQueries: [
        {
          query: CollaborationDocument,
          variables: {
            id: collaborationId,
          },
        },
      ],
    },
  });

  const { isDragActive } = useDropZoneContext();

  if (!collaborationId) return null;

  return (
    <Wrapper
      {...getRootProps()}
      className={className}
      isDragActive={isDragActive}
    >
      <Header>
        <Subtitle2 color={colors.black}>Attachments</Subtitle2>
        <input id="fileUploadInputRef" type="file" {...getInputProps()} />
        <ButtonPill
          level={'align'}
          text={'Attach'}
          iconName={'Attachment'}
          onClick={async () => {
            const hiddenInput = inputRef.current;
            if (hiddenInput) hiddenInput.click();
          }}
        />
      </Header>

      {uploading ? (
        <AttachmentPreview
          file={
            {
              id: 99999,
              lastUpdated: new Date().toISOString(),
              createdAt: new Date().toISOString(),
              title: uploadingName || '',
              collaborationId,
            } as Attachment
          }
          uploading
          failedUpload={failedUpload}
          errMsg={errMsg}
          clearErr={clearErr}
          onDelete={() => {}}
        />
      ) : null}

      {data?.length === 0 && !uploading ? (
        <EmptyState>
          {isDesktop ? (
            <Icon
              name="FileEmptyState"
              size={115}
              height={115}
              color="initial"
            />
          ) : null}
          <Subtitle2 color={colors.greyDark}>Nothing attached yet</Subtitle2>
          <BodySmall color={colors.greyDark}>Why not add a file?</BodySmall>
        </EmptyState>
      ) : null}

      {data
        ?.filter((_, idx) => {
          if (isDesktop || showAllAttachments) return true;
          return idx < 2;
        })
        .map((attachment) => (
          <AttachmentPreview
            file={attachment as Attachment}
            key={attachment?.id}
            canBeDeleted
            clearErr={() => {}}
            onDelete={() => {}}
          />
        ))}

      {!isDesktop && data && data.length > 2 && !showAllAttachments ? (
        <ShowMoreDropdown
          onClick={() => {
            setShowAllAttachments(true);
          }}
        >
          <Icon name="Chevron-down" size={30} color={colors.greyDark} />
          <ButtonLabel color={colors.greyDark}>
            Show {data.length - 2} more
          </ButtonLabel>
        </ShowMoreDropdown>
      ) : null}
    </Wrapper>
  );
};
