import React, { useContext } from 'react';
import { Formik } from 'formik';
import { prettyGraphqlError } from '../../utils/prettyGraphqlError';
import {
  ErrorBox,
  ErrorMessage,
  Form,
  Title,
  Signin,
  SubmitButton,
} from '../../containers/Signin';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import { AuthContext } from '../../contexts/AuthContext';
import {
  useResetPasswordMutation,
  useSignInMutation,
} from 'data/graphql/generated';
import FormPasswordInput from 'components/shared/FormPasswordInput';
import { PasswordRequirements } from './PasswordRequirements';
import { passwordIsValid } from 'utils/signin';

export const RequirementsContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  padding-left: 0;
  width: 100%;
`;

export default function ResetPasswordForm() {
  const [resetPassword] = useResetPasswordMutation();
  const [signin] = useSignInMutation();
  const [, setAuth] = useContext(AuthContext);
  const history = useHistory();
  const resetToken = history.location.search.split('=')[1];

  return (
    <Signin>
      <Formik
        initialValues={{
          password: '',
        }}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          try {
            const { data } = await resetPassword({
              variables: { password: values.password, token: resetToken },
            });

            if (!data?.resetPassword.user.email)
              throw new Error('Email not returned');

            const res = await signin({
              variables: {
                email: data.resetPassword.user.email,
                password: values.password,
              },
            });

            const signInData = get(res, 'data.signIn');

            setAuth({
              user: signInData.user,
            });
            if (signInData?.user?.role === 'ADMIN') {
              history.push('/admin');
            } else {
              history.push('/');
            }
          } catch (err) {
            console.error(err);
            actions.setStatus({
              error: prettyGraphqlError(err as Error),
            });
            actions.setSubmitting(false);
          }
        }}
      >
        {({ values, handleSubmit, handleChange, status, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Title>Reset password</Title>

            <FormPasswordInput
              name="password"
              title="New Password"
              onChange={handleChange}
              value={values.password}
            />
            <PasswordRequirements password={values.password} />

            <SubmitButton
              width="100%"
              onClick={() => {}}
              disabled={!passwordIsValid(values.password) || isSubmitting}
              level="primary"
              text={isSubmitting ? 'Loading...' : 'Change password'}
            />

            {status && status.error ? (
              <ErrorBox>
                <ErrorMessage>{status.error}</ErrorMessage>
              </ErrorBox>
            ) : null}
          </Form>
        )}
      </Formik>
    </Signin>
  );
}
