import { ReusableSidebar } from 'components/shared';
import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Content = styled.div`
  padding: 15px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: overlay;
`;

export const StyledSidebar = styled(ReusableSidebar)`
  position: fixed;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 15px;
  width: 100%;
`;
