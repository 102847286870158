import { Stakeholder } from 'data/graphql/generated';
import { StakeholderFullString, StakeholdersFullStringForGroups } from 'types';

export const mapStakeholderEnumToSingularStakeholder: Record<
  Stakeholder,
  StakeholderFullString
> = {
  Patient: 'Patient',
  Healthcare: 'Healthcare professional',
  Payor: 'Payor',
  Provider: 'Provider',
  Policymaker: 'Policymaker',
};

export const mapStakeholderEnumToPostItGroupTitle: Record<
  Stakeholder,
  StakeholdersFullStringForGroups
> = {
  Patient: 'Patients',
  Healthcare: 'HCPs',
  Payor: 'Payors',
  Provider: 'Providers',
  Policymaker: 'Policymakers',
};
