import { BodySmall } from 'components/shared';
import { colors } from 'constants/index';
import { CompetitiveAdvantageRowFragment } from 'data/graphql/generated';
import React from 'react';
import styled from 'styled-components/macro';

const WhiteCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 10px 15px;
  border-radius: 5px;
  background: ${colors.white};
`;

type Props = {
  data: CompetitiveAdvantageRowFragment;
};

export const CompAdvantageRow = ({ data }: Props) => {
  return (
    <WhiteCardWrapper>
      <div>
        <BodySmall color={colors.greyDark}>Strategic Possibility</BodySmall>
        {data.strategicPossibility?.name ? (
          <BodySmall>{data.strategicPossibility?.name}</BodySmall>
        ) : (
          <BodySmall color={colors.greyDark}>Not identified yet</BodySmall>
        )}
      </div>
      <div>
        <BodySmall color={colors.greyDark}>Key Issue</BodySmall>
        {data.text ? (
          <BodySmall>{data.text}</BodySmall>
        ) : (
          <BodySmall color={colors.greyDark}>Not identified yet</BodySmall>
        )}
      </div>
    </WhiteCardWrapper>
  );
};
