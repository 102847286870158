import { RefObject, useEffect, useState } from 'react';

export default function useGetElementTop(ref: RefObject<any>) {
  const [elTop, setElTop] = useState(0);

  useEffect(() => {
    const el = ref.current;
    if (!el) return;

    function captureScroll() {
      setElTop(el.getBoundingClientRect().top);
    }

    captureScroll();
    window.addEventListener('scroll', captureScroll);

    return () => window.removeEventListener('scroll', captureScroll);
  });

  return elTop;
}
