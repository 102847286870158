import React from 'react';
import { Loading } from 'components/Loading';
import { ContributionTab, TabGroup } from 'components/shared';
import { StrategyDefinitionHeader } from 'components/StrategyDefinitionHeader';
import {
  StrategicQuestionListFragment,
  StrategicQuestionType,
  SubStep,
} from 'data/graphql/generated';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import URLHelper from 'utils/URLHelper';
import { MultiListStrategicQuestionType } from 'components/StrategyDefinitionHeader/StrategyDefinitionHeader.types';
import { CriticalMetricsTabGroup } from './CriticalMetricsStrategicImperatives.styles';

const StyledLoading = styled(Loading)`
  margin: 0;
  height: 115;
`;

interface URLParams {
  drugId: string;
  strategyId: string;
  competitiveAdvantageRowUrlParam: string;
}

interface CriticalMetricsStrategicImperativesProps {
  isLead: boolean;
  subStep: SubStep.Goals | SubStep.Indicators | SubStep.Tracking;
  strategicQuestionsLoading: boolean;
  competitiveAdvantageRows?: null | any[];
  totalUsers?: number;
  sidebarOpen: boolean;
  setStrategicImperativeId: (id: number) => void;
  onOpenSidebar: () => void;
  onSave: (val: any) => Promise<void>;
  leadLagStrategicQuestion?: {
    competitiveAdvantageRowId?: number;
    title: string;
    type?: StrategicQuestionType;
    lists?: StrategicQuestionListFragment[];
    collaboration?: {
      __typename?: 'Collaboration';
      id: number;
      commentCount: number;
      attachmentCount: number;
      alignmentCount: number;
    } | null;
  };
}

export const CriticalMetricsStrategicImperatives: React.FC<CriticalMetricsStrategicImperativesProps> = ({
  isLead,
  strategicQuestionsLoading,
  competitiveAdvantageRows,
  sidebarOpen,
  totalUsers,
  leadLagStrategicQuestion,
  onOpenSidebar,
  setStrategicImperativeId,
  onSave,
  children,
  subStep,
}) => {
  const {
    drugId,
    strategyId,
    competitiveAdvantageRowUrlParam,
  } = useParams<URLParams>();
  const history = useHistory();
  const handleOpenSidebar = () => onOpenSidebar();
  const handleSave = React.useCallback(async (val) => onSave(val), [onSave]);
  const handleStrategyImperativeId = React.useCallback(async (id) => setStrategicImperativeId(id), [setStrategicImperativeId]);

  if (!competitiveAdvantageRows || !competitiveAdvantageRows.length) {
    return null;
  }

  return (
    <>
      <CriticalMetricsTabGroup>
        <TabGroup componentName="ContributionTab">
          <>
            {competitiveAdvantageRows.map((competitiveAdvantageRow) => {
              return (
                <ContributionTab
                  key={competitiveAdvantageRow.id}
                  id={`contribution-tab-${competitiveAdvantageRow.strategicPossibility?.id}`}
                  secondary=""
                  text={
                    competitiveAdvantageRow.strategicPossibility?.name || ''
                  }
                  active={
                    +competitiveAdvantageRowUrlParam ===
                    competitiveAdvantageRow.id
                  }
                  onClick={() => {
                    const url = URLHelper.getCriticalMetricsUrlBySubStep(
                      subStep,
                      {
                        drugId,
                        strategyId,
                        competitiveAdvantageRowId: competitiveAdvantageRow.id,
                      }
                    );
                    handleStrategyImperativeId(competitiveAdvantageRow.id)
                    history.replace(url);
                  }}
                  displaySummary={handleOpenSidebar}
                  revealTruncatedText={false}
                  sidebarOpen={sidebarOpen}
                />
              );
            })}
          </>
        </TabGroup>
      </CriticalMetricsTabGroup>
      {children}

      {subStep === SubStep.Indicators && (
        <StrategicDefinitionHeaders
          isLoading={strategicQuestionsLoading}
          isLead={isLead}
          title={leadLagStrategicQuestion?.title || ''}
          type={leadLagStrategicQuestion?.type}
          bulletPoints={{
            type: StrategicQuestionType.MultiList,
            bulletPoints: leadLagStrategicQuestion?.lists || [],
          }}
          collaboration={leadLagStrategicQuestion?.collaboration || undefined}
          totalUsers={totalUsers}
          onSave={handleSave}
        />
      )}
    </>
  );
};

interface StrategyDefinitionHeaderProps {
  isLoading: boolean;
  isLead: boolean;
  title: string;
  type?: StrategicQuestionType | null;
  bulletPoints: MultiListStrategicQuestionType;
  collaboration?: {
    __typename?: 'Collaboration';
    id: number;
    commentCount: number;
    attachmentCount: number;
    alignmentCount: number;
  };
  totalUsers?: number;
  onSave: (val: any) => Promise<void>;
}

const StrategicDefinitionHeaders: React.FC<StrategyDefinitionHeaderProps> = ({
  isLoading,
  isLead,
  title,
  type,
  bulletPoints,
  collaboration,
  totalUsers,
  onSave,
}) => {
  if (isLoading) {
    return <StyledLoading />;
  }
  return (
    <StrategyDefinitionHeader
      type={type}
      bulletPoints={bulletPoints}
      collaboration={collaboration}
      totalUsers={totalUsers}
      isLead={isLead}
      title={title}
      handleSave={onSave}
    />
  );
};
