import { ApolloQueryResult } from '@apollo/client';
import { CountryDraggablePin } from 'components/GridPages/CountryDraggablePin';
import {
  DropzoneGrid,
  calculateDropCoordinates,
} from 'components/GridPages/DropzoneGrid';
import TwoButtonModal from 'components/TwoButtonModal';
import {
  CommercialTacticOrderByInput,
  CommercialTacticWhereInput,
  CommercialTacticsDocument,
  CommercialTacticsQuery,
  Exact,
  KeyStatementFragment,
  Maybe,
  useCommercialTacticUpdateMutation,
  useGlobalCommercialTacticRegionStatusUpsertMutation,
} from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import useHandleCalloutHover from 'hooks/useHandleCalloutHover';
import { useMemo, useState } from 'react';
import { DragObjectWithType, DropTargetMonitor } from 'react-dnd';
import {
  CommercialTacticFragmentWithGlobalFocusedStatus,
  DraggableCardType,
  PreviewOrExistingTactic,
} from 'types';
import { apolloUpdateHelper } from 'utils/apolloQueryHelpers';
import { colors } from '../../constants';
import { HoverCalloutWrapper } from '../GridPages/HoverCalloutWrapper';
import { TacticCallout } from './TacticCallout';
import { getKeyTacticsQueryVars } from './utils';

interface Props {
  refetchCommercialTactics: (
    variables?:
      | Partial<
          Exact<{
            where: CommercialTacticWhereInput;
            orderBy?: Maybe<CommercialTacticOrderByInput> | undefined;
            skip?: Maybe<number> | undefined;
            take?: Maybe<number> | undefined;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<CommercialTacticsQuery>>;
  setTouchPositioningView: React.Dispatch<React.SetStateAction<string | null>>;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  touchPositioningView: string | null;
  placedTactics: CommercialTacticFragmentWithGlobalFocusedStatus[];
  activeObjectives: KeyStatementFragment[];
  strategyId: string;
  strategicImperativeId: string;
  isGlobalView: boolean;
  canDrag: boolean;
  region: string;
  canEdit: boolean;
  isViewingOwnRegion: boolean;
  activeTabIsGlobal: boolean;
  canAddTactic: boolean;
}

export default function KeyTacticsGrid({
  refetchCommercialTactics,
  setTouchPositioningView,
  touchPositioningView,
  setSidebarOpen,
  placedTactics,
  activeObjectives,
  strategyId,
  strategicImperativeId,
  isGlobalView,
  canDrag,
  region,
  canEdit,
  isViewingOwnRegion,
  activeTabIsGlobal,
  canAddTactic,
}: Props) {
  const [
    upsertGlobalCommercialTactic,
  ] = useGlobalCommercialTacticRegionStatusUpsertMutation();
  const [updateCommercialTactic] = useCommercialTacticUpdateMutation();
  const isDesktop = useDesktop();
  const [hoveredPin, setHoveredPin] = useState<number>(-1);
  const [removePinLoading, setRemovePinLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState<string | null>(
    null
  );
  const queryVars = useMemo(
    () => getKeyTacticsQueryVars(strategyId, strategicImperativeId),
    [strategyId, strategicImperativeId]
  );

  // Handle callout card hover states
  const { showingCallout } = useHandleCalloutHover({ hoveredPin });

  async function updateTacticPosition({
    data,
    localUid,
    x,
    y,
    closeTouchView,
    region,
  }: {
    data: PreviewOrExistingTactic | null;
    localUid: string;
    x: number;
    y: number;
    closeTouchView: () => void;
    region?: string;
  }) {
    try {
      if (data && 'id' in data) {
        if (region && data.isGlobalLocalTactic) {
          const commercialTacticId = data?.id;
          if (!commercialTacticId) return;
          await upsertGlobalCommercialTactic({
            variables: {
              data: {
                x,
                y,
                strategyId: +strategyId,
                commercialTacticId: commercialTacticId,
                region,
              },
            },
          });
        } else {
          // Update existing tactic
          const { localUid } = data;

          await updateCommercialTactic({
            variables: {
              localUid,
              data: { x, y },
            },
            update: apolloUpdateHelper({
              responseField: 'commercialTacticUpdate',
              query: CommercialTacticsDocument,
              queryVars,
              queryName: 'commercialTactics',
            }),
            optimisticResponse: {
              commercialTacticUpdate: {
                ...data,
                x,
                y,
              },
            },
          });
        }
      } else {
        // Update preview
        await updateCommercialTactic({
          variables: {
            localUid,
            data: { x, y },
          },
        });
      }

      refetchCommercialTactics();
    } catch (error) {
      alert('Something went wrong');
    } finally {
      closeTouchView();
    }
  }

  async function handleRemove({
    localUid,
    focused,
    id: commercialTacticId,
    isGlobalLocalTactic,
  }: CommercialTacticFragmentWithGlobalFocusedStatus) {
    if (focused) {
      setConfirmationModal(localUid);
    } else {
      try {
        const tacticToUpdate = placedTactics.find(
          (item) => item.localUid === localUid
        );
        if (!tacticToUpdate) return;

        if (region && isGlobalLocalTactic) {
          if (!commercialTacticId) return;

          await upsertGlobalCommercialTactic({
            variables: {
              data: {
                x: null,
                y: null,
                strategyId: +strategyId,
                commercialTacticId,
                region,
              },
            },
          });
        } else {
          await updateCommercialTactic({
            variables: {
              localUid,
              data: { x: null, y: null },
            },
            update: apolloUpdateHelper({
              responseField: 'commercialTacticUpdate',
              query: CommercialTacticsDocument,
              queryVars,
              queryName: 'commercialTactics',
            }),
            optimisticResponse: {
              commercialTacticUpdate: {
                ...tacticToUpdate,
                x: null,
                y: null,
              },
            },
          });
        }
      } catch (err) {
        alert('Something went wrong');
      }
    }
  }

  return (
    <DropzoneGrid
      borderColor={isGlobalView ? undefined : colors.blue}
      blockColors={
        isGlobalView
          ? undefined
          : [colors.blue10, colors.blue15, colors.blue05, colors.blue10]
      }
      xAxisTitle={'Risk'}
      yAxisTitle={'Reward'}
      setTouchPositioningView={setTouchPositioningView}
      touchPositioningView={touchPositioningView}
      setSidebarOpen={setSidebarOpen}
      gridClickHandler={(
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        closeTouchView: () => void
      ) => {
        if (isDesktop || !touchPositioningView) return;

        e.stopPropagation();

        const { clientX: clickXCoord, clientY: clickYCoord } = e;

        const grid = e.currentTarget as HTMLDivElement;
        const {
          height,
          width,
          x: gridX,
          y: gridY,
        } = grid.getBoundingClientRect();
        const xPosition = ((clickXCoord - gridX) / width) * 100;
        const yPosition = ((clickYCoord - gridY) / height) * 100;
        const [x, y] = [Math.round(xPosition), Math.round(yPosition)];

        // Update the tactic with its grid coordinates
        (async () =>
          await updateTacticPosition({
            data: null,
            localUid: touchPositioningView,
            x,
            y,
            closeTouchView,
          }))();
      }}
      gridDropHandler={(
        item: DragObjectWithType,
        monitor: DropTargetMonitor,
        closeTouchView: () => void
      ) => {
        const squareContainer = document.getElementById('square-container');
        if (!squareContainer) return;

        const coords = calculateDropCoordinates(monitor, squareContainer);
        if (!coords) return;

        const { data } = item as DraggableCardType;
        if (!data?.localUid) return;
        // Update the tactic with its grid coordinates
        updateTacticPosition({
          data,
          localUid: data.localUid,
          x: coords.x,
          y: coords.y,
          closeTouchView,
          region,
        });
      }}
    >
      {placedTactics?.map((t) => {
        const strategicObjective = activeObjectives?.find(
          (s) => s.id === t.keyStatementId
        );
        if (!strategicObjective) return null;

        const onMouseOver = () => setHoveredPin(t.id);
        const onMouseOut = () => setHoveredPin(-1);

        return (
          <CountryDraggablePin
            key={t.id}
            data={t}
            style={{
              top: `calc(${t.y}%)`,
              left: `calc(${t.x}%)`,
            }}
            showCallout={showingCallout === t.id}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            setTouchPositioningView={setTouchPositioningView}
            selected={touchPositioningView === t.localUid}
            removeFromGrid={() => handleRemove(t)}
            disableDrag={!canDrag}
          >
            <HoverCalloutWrapper
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              pinID={t.id}
            >
              <TacticCallout
                tacticData={t}
                strategicObjective={strategicObjective}
                isLocalView={!isGlobalView}
                region={region}
                isViewingOwnRegion={isViewingOwnRegion}
              />
            </HoverCalloutWrapper>
          </CountryDraggablePin>
        );
      })}
      <TwoButtonModal
        rightButtonLevel="danger"
        rightButtonOnClick={async () => {
          if (!confirmationModal) return;
          setRemovePinLoading(true);

          const tacticToUpdate = placedTactics.find(
            (item) => item.localUid === confirmationModal
          );
          if (!tacticToUpdate) return;

          try {
            await updateCommercialTactic({
              variables: {
                localUid: confirmationModal,
                data: { x: null, y: null, focused: false },
              },

              update: apolloUpdateHelper({
                query: CommercialTacticsDocument,
                queryVars,
                queryName: 'commercialTactics',
                responseField: 'commercialTacticUpdate',
              }),
              optimisticResponse: {
                commercialTacticUpdate: {
                  ...tacticToUpdate,
                  x: null,
                  y: null,
                  focused: false,
                },
              },
            });
            refetchCommercialTactics();
          } catch (err) {
            alert('Something went wrong');
          } finally {
            setConfirmationModal(null);
            setRemovePinLoading(false);
          }
        }}
        rightButtonText="Remove"
        leftButtonLevel="secondary"
        leftButtonOnClick={() => setConfirmationModal(null)}
        leftButtonText="Cancel"
        handleClose={() => setConfirmationModal(null)}
        modalOpen={!!confirmationModal}
        title="Remove this item from your plan?"
        body="Removing this item from the prioritisation grid will also remove it from your plan"
        rightButtonLoading={removePinLoading}
      />
    </DropzoneGrid>
  );
}
