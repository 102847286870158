import { Navbar as NavbarComponent } from 'components/Navbar';
import { StepTab, TabGroup } from 'components/shared';
import { useEffect } from 'react';
import { kebabCase } from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  ButtonContainerDesktop,
  Divider,
  EvaluationButton,
} from '../../components/3-5-critical-metrics/shared/shared-ui-critical-metrics/src';
import { PositioningTabKebabCase } from '../../types';
import { StepBarsWrapper, TabGroupWrapper } from '../StrategicQuestion';
import buildStrategyURL from '../../utils/buildStrategyURL';
import { SubStep } from '../../data/graphql/generated';
import { useGlobalContext } from 'contexts/GlobalContext';

type Props = {
  drugId: string;
  strategyId: string;
  competitorId: string;

  currentPositioningTab: PositioningTabKebabCase | 'summary';
};

const positioningTabs = [
  { title: 'Competitor Positioning', subStep: SubStep.CompetitorPositioning },
  {
    title: 'The "Who"',
    subStep: SubStep.TheWho,
  },
  {
    title: 'The "Why"',
    subStep: SubStep.TheWhy,
  },
];

export const Navbar = ({
  drugId,
  strategyId,
  competitorId,
  currentPositioningTab,
}: Props) => {
  const history = useHistory();

  const [ brandPositioning, setBrandPositioning] = useGlobalContext(
    'brandPositioning'
  );

  useEffect(() => {
    if (!competitorId && brandPositioning) {
      history.replace(
        brandPositioning
      );
    }
  }, [drugId, history, competitorId, brandPositioning]);

  
  return (
    <NavbarComponent
      stepNumber="3.1"
      title="Brand Positioning"
      prev={{
        title: 'Drivers and Barriers',
        url: buildStrategyURL(drugId, strategyId, '2_5'),
      }}
      next={{
        title: 'Core Value Messages',
        url: buildStrategyURL(drugId, strategyId, '3_2'),
      }}
    >
      <TabGroupWrapper>
        <TabGroup componentName="StepTab">
          <StepBarsWrapper>
            {positioningTabs?.map((p, idx) => {
              return (
                <StepTab
                  className={`cypress-stepTab-${kebabCase(p.subStep)}`}
                  key={p.subStep}
                  active={kebabCase(p.subStep) === currentPositioningTab}
                  text={`Part ${idx + 1}: ${p.title}`}
                  onClick={() => {
                    const url = `/d/${drugId}/strategy/${strategyId}/3_1/${kebabCase(
                      p.subStep
                    )}/${competitorId || ''}`;
                    history.push(url);
                    setBrandPositioning(url)
                  }}
                />
              );
            })}

            <ButtonContainerDesktop>
              <Divider />

              <EvaluationButton
                text={'Summary'}
                isActive={currentPositioningTab === 'summary'}
                onClick={() => {
                  const url = `/d/${drugId}/strategy/${strategyId}/3_1/summary`;
                  history.push(url);
                  setBrandPositioning(url);

                }}
              />
            </ButtonContainerDesktop>
          </StepBarsWrapper>
        </TabGroup>
      </TabGroupWrapper>
    </NavbarComponent>
  );
};
