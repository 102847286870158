import { useParams } from 'react-router-dom';
import { useGetAllTrackedGoalsQuery } from 'data/graphql/generated';
import { CriticalMetricsParams } from 'components/3-5-critical-metrics/shared/shared-data-access-critical-metrics/src';

export const useGetAllTrackedGoals = () => {
  const {
    strategyId,
    competitiveAdvantageRowUrlParam,
  } = useParams<CriticalMetricsParams>();

  const {
    data,
    error,
    loading,
    startPolling,
    stopPolling,
  } = useGetAllTrackedGoalsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        competitiveAdvantageRowId: +competitiveAdvantageRowUrlParam,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const goals = data?.criticalMetricTrackedGoals || [];

  return {
    goals,
    error,
    loading,
    startPolling,
    stopPolling,
  };
};
