import React, { useRef, useEffect, useState } from 'react';

import {
  ButtonRound,
} from 'components/shared';
import {
  StakeholderDefinitionsQuery,
  Stakeholder,
} from 'data/graphql/generated';
import { StakeholderFullString } from 'types';
import styled from 'styled-components/macro';
import { ErrorModal } from './ErrorModal';

interface Props {
  drugId: string;
  strategyId: string;
  data: StakeholderDefinitionsQuery;
  stakeholderTab: StakeholderFullString;
  stakeholder: Stakeholder;
}

export const StyledButtonRound = styled(ButtonRound)<{ disabled: boolean }>`
  margin-left: 9px;
  opacity: ${({ disabled }) => disabled && '0.5'};
`;

export const StakeholderDefinitions: React.FC<Props> = ({
  drugId,
  strategyId,
  data,
  stakeholder,
  stakeholderTab,
}) => {
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);

  //Ref can be used to force a scroll to a particular position
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const previousWidth = useRef(0);

  const effectHasRun = useRef(false);
  useEffect(() => {
    // Scroll to the end when we add a new patient
    const effectHasRunValue = effectHasRun.current;
    const timer = setTimeout(() => {
      if (effectHasRunValue) {
        if (scrollContainerRef.current) {
          previousWidth.current = scrollContainerRef.current?.offsetWidth;
          if (
            scrollContainerRef.current.scrollWidth -
              scrollContainerRef.current.offsetWidth >
            40
          )
            scrollContainerRef.current.scrollLeft =
              scrollContainerRef.current?.offsetWidth * 2;
        }
      }
    }, 500);

    effectHasRun.current = true;
    return () => clearTimeout(timer);
  }, [data?.stakeholderDefinitions?.items]);

  return (
    <>
      <ErrorModal
        title="Cannot delete this stakeholder definition"
        text="Content in later steps depends on this stakeholder definition. Remove content and try again."
        visible={showCannotDeleteModal}
        handleClose={() => setShowCannotDeleteModal(false)}
      />
    </>
  );
};
