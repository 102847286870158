import {
  KeyStatementFragment,
  TacticFragment,
  TacticsDocument,
  TacticUpdateInput,
  useTacticUpsertMutation,
  useTacticDeleteMutation,
  TacticsQueryVariables,
  User,
} from 'data/graphql/generated';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TacticRow } from '../TacticRow';
import styled, { createGlobalStyle, css } from 'styled-components/macro';
import {
  MobileContentDivider,
  MobileContentWrapper,
  Table,
  TableDivHeaders,
} from 'components/shared/Table';
import { uid } from 'uid';
import {
  apolloDeleteHelper,
  apolloUpsertHelper,
} from 'utils/apolloQueryHelpers';
import { BodySmall, Subtitle2, CountryFlag } from 'components/shared';
import { colors, globalContributor } from 'constants/index';
import { device } from 'utils/breakpoints';
import MedicalTacticsRationaleModal from '../MedicalTacticsRationaleModal';
import { CountryGlobalRegional } from 'types';
import { sortBy, startCase } from 'lodash';
import { ErrorModal } from 'components/ErrorModal';
import { Content } from 'components/ModalForm';
import { verifyUserRole } from 'utils/verifyUserRole';
import MedicalTacticsEmptyStates from './MedicalTacticsEmptyStates';
import { SpinOffsToggle } from 'containers/MedicalStrategy/index.style';
import { MedicalTacticsPicos } from 'components/4-2-medical-strategy/feature-medical-tactics-picos';
import {
  PicoSuggestionRowData,
  useScrollToId,
} from 'components/4-2-medical-strategy/data-access-medical-tactics-picos';

const objectiveHeaderMarginLeft = 15;

const Header = styled.div`
  margin-bottom: 20px;
  margin-left: ${objectiveHeaderMarginLeft}px;
  max-width: 640px;

  @media ${device.tabletMax} {
    margin-bottom: 5px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.greyLight};
  margin-bottom: 5px;
`;

const pagePadding = 20;
const sideBarWidth = 300;

const ScrollContainer = styled.div<{ sidebarOpen: boolean; topOffset: number }>`
  ${({ sidebarOpen, topOffset }) => {
    if (sidebarOpen) {
      return css`
        height: calc(100vh - ${topOffset}px - 40px);

        @media ${device.desktopMin} {
          width: calc(100vw - ${pagePadding + sideBarWidth}px);
          margin-right: -15px;
        }
      `;
    }
  }}
`;

const Wrapper = styled.div<{ sidebarOpen: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 15px;

  overflow: hidden;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding-bottom: 140px;

  @media ${device.tabletMin} {
    width: calc(100vw - 40px);
  }

  ${({ sidebarOpen }) =>
    sidebarOpen
      ? css`
          @media ${device.tabletMax} {
            width: calc(100vw - 300px);
            margin: 0;
            min-width: 451px;
          }
          // add extra space at the end of scrolled area
          padding-right: 20px;
        `
      : ''}
`;

const StyledTable = styled(Table)`
  ${TableDivHeaders} {
    grid-template-columns: repeat(3, minmax(108px, 285px)) 473px;
  }

  ${MobileContentDivider} {
    margin-top: 5px;
  }

  ${MobileContentWrapper} {
    padding-bottom: 0px;
  }
` as typeof Table;

const ModalStyling = createGlobalStyle`
  .rationale__sliderWindow {
    overflow: visible;
    z-index:1;
  }
`;

const TacticsDeleteModal = styled(ErrorModal)`
  ${Content} {
    padding: 40px 0;

    > p:first-child {
      margin-bottom: 0px;
    }
    > p:nth-child(2) {
      margin-bottom: 0px;
    }
  }
`;

const GreyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 40px;
  width: 100%;
  background: ${colors.black05OnWhiteGradient};
  margin: 15px 0;
`;

const WhiteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  gap: 10px;

  width: 376px;
  background: ${colors.white};
  border: 1px solid ${colors.greyMedium};
  border-radius: 5px;
`;

const FlagAndName = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

interface SortedMedicalObjectives {
  globalMedicalObjectives: KeyStatementFragment[];
  countryMedicalObjectives: KeyStatementFragment[];
}
interface Props {
  strategyId: string;
  drugId: string;
  currency: string;
  competitiveAdvantageRowId: number;
  allTactics: TacticFragment[] | undefined;
  isDesktop: boolean;
  keyStatements: KeyStatementFragment[] | undefined;
  sidebarOpen: boolean;
  tacticsQueryVars: TacticsQueryVariables;
  user: User | undefined;
  showCountrySpinOffs: boolean;
  setShowCountrySpinOffs: React.Dispatch<React.SetStateAction<boolean>>;
  countryTacticsEnabled: boolean;
  picoSuggestions: PicoSuggestionRowData[];
}

export type PreviewAndDBDataTacticType = Partial<
  Omit<
    TacticFragment,
    | 'localUid'
    | 'tacticText'
    | 'audienceText'
    | 'category'
    | 'timingStart'
    | 'timingEnd'
    | 'focused'
    | 'dueDate'
    | 'responsibility'
    | 'budget'
    | 'lastUpdated'
    | 'createdAt'
    | 'region'
  >
> &
  Pick<
    TacticFragment,
    | 'localUid'
    | 'tacticText'
    | 'audienceText'
    | 'category'
    | 'timingStart'
    | 'timingEnd'
    | 'focused'
    | 'dueDate'
    | 'responsibility'
    | 'budget'
    | 'lastUpdated'
    | 'createdAt'
    | 'region'
  >;

export const MedicalTactics = ({
  strategyId,
  drugId,
  currency,
  competitiveAdvantageRowId,
  allTactics,
  isDesktop,
  keyStatements,
  sidebarOpen,
  tacticsQueryVars,
  user,
  showCountrySpinOffs,
  setShowCountrySpinOffs,
  countryTacticsEnabled,
  picoSuggestions,
}: Props) => {
  const { isLead, isCountryContributor, isGlobalContributor } = verifyUserRole(
    user?.role,
    user?.country
  );

  const isLeadOrGlobalContributor = isLead || isGlobalContributor;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [topOffset, setTopOffset] = useState(0);

  const tactics = useMemo(
    () =>
      allTactics?.filter((t) =>
        isLeadOrGlobalContributor
          ? showCountrySpinOffs
            ? t.region === 'global' || t.focused
            : t.region === 'global'
          : t.focused || [user?.country, 'global'].includes(t.region)
      ),
    [allTactics, showCountrySpinOffs, isLeadOrGlobalContributor, user?.country]
  );

  // hide tactics from users from another country

  const [tempTactic, setTempTactic] = useState<
    PreviewAndDBDataTacticType | undefined
  >(undefined);
  const [localData, setLocalData] = useState<
    PreviewAndDBDataTacticType[] | undefined
  >(tactics);

  const [upsertTactic] = useTacticUpsertMutation();
  const [deleteTactic] = useTacticDeleteMutation();

  const [addingTactic, setAddingTactic] = useState(false);
  const [tacticDeleteModal, setTacticDeleteModal] = useState<{
    visible: boolean;
    regions: string[];
  }>({
    visible: false,
    regions: [],
  });

  const [modalSlideIdx, setModalSlideIdx] = useState<number | null>(null);
  const [showSingleSlide, setShowSingleSlide] = useState<boolean>(false);
  const [
    selectedTactic,
    setSelectedTactic,
  ] = useState<PreviewAndDBDataTacticType | null>(null);

  const addPreview = (
    localUid: string,
    keyStatementId: number,
    region: string
  ) => {
    setTempTactic({
      localUid,
      tacticText: '',
      audienceText: '',
      dueDate: [],
      focused: false,
      timingStart: [],
      timingEnd: [],
      budget: null,
      responsibility: '',
      category: null,
      keyStatementId,
      lastUpdated: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      x: null,
      y: null,
      region,
      parentTactic: null,
    });
  };

  useEffect(() => {
    if (!tactics) return;
    const previewInDB = tactics?.some((item) => {
      return item.localUid === tempTactic?.localUid;
    });

    if (previewInDB) {
      setTempTactic(undefined);
      setLocalData(tactics);
    } else {
      // Update Data
      if (tempTactic) {
        setLocalData([...tactics, tempTactic]);
      } else {
        setLocalData(tactics);
      }
    }
  }, [tactics, tempTactic]);

  useEffect(() => {
    const resizeHandler = () => {
      setTopOffset(
        (wrapperRef.current?.getBoundingClientRect().top || 0) +
          window.pageYOffset
      );
    };
    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, [setTopOffset]);

  const sortedMedicalObjectives: SortedMedicalObjectives = useMemo(
    () =>
      (keyStatements || [])?.reduce(
        (acc, medObj) => {
          if (medObj.region === 'global') {
            acc.globalMedicalObjectives.push(medObj);
            return acc;
          } else {
            if (!showCountrySpinOffs || !countryTacticsEnabled) return acc;

            // Country contributors should not see medical objective groups of other countries
            if (!isLeadOrGlobalContributor && user?.country !== medObj.region)
              return acc;

            acc.countryMedicalObjectives.push(medObj);
            return acc;
          }
        },
        {
          globalMedicalObjectives: [],
          countryMedicalObjectives: [],
        } as SortedMedicalObjectives
      ),
    [
      keyStatements,
      showCountrySpinOffs,
      isLeadOrGlobalContributor,
      user?.country,
      countryTacticsEnabled,
    ]
  );

  const onlyLocalMedicalObjectivesExist =
    !!keyStatements?.length &&
    !keyStatements?.some(
      (medicalObjective) => medicalObjective.region === 'global'
    );

  const noMedicalObjectives = !isCountryContributor && !keyStatements?.length;

  const noLocalObjectivesToShow =
    !isCountryContributor &&
    onlyLocalMedicalObjectivesExist &&
    !showCountrySpinOffs;

  const noUserLocalOrGlobalObjectives =
    isCountryContributor &&
    !sortedMedicalObjectives.countryMedicalObjectives.length &&
    !sortedMedicalObjectives.globalMedicalObjectives.length;

  const countryTacticsDisabledAndNoGlobalObjectives =
    !countryTacticsEnabled &&
    !sortedMedicalObjectives.globalMedicalObjectives.length;

  const noObjectives =
    countryTacticsDisabledAndNoGlobalObjectives ||
    noMedicalObjectives ||
    noLocalObjectivesToShow ||
    noUserLocalOrGlobalObjectives;

  const showEmptyState = !picoSuggestions.length && noObjectives;

  function setModalPage(val: number | null) {
    setModalSlideIdx(val);
  }
  function onSubmit(
    formData: {
      dueDate: number[] | null | undefined;
      responsibility: string;
      budget: string | null | undefined;
    } | null,
    rationale: string,
    selectedTactic: PreviewAndDBDataTacticType
  ) {
    if (!selectedTactic.localUid) return;

    const randomId = Math.floor(Math.random() * 100000);

    upsertTactic({
      variables: {
        data: {
          localUid: selectedTactic.localUid,
          strategyId: +strategyId,
          competitiveAdvantageRow: +competitiveAdvantageRowId,
          keyStatement: selectedTactic.keyStatementId,
          ...formData,
          focused: true,
          focusRationale: rationale,
        },
      },
      optimisticResponse: {
        //@ts-ignore
        tacticUpsert: {
          ...selectedTactic,
          id: selectedTactic.id || randomId,
          dueDate: formData?.['dueDate'] as number[],
          responsibility: formData?.['responsibility']?.trim() || '',
          budget: formData?.['budget'],
          focusRationale: rationale,
          focused: true,
        },
      },

      update: apolloUpsertHelper({
        responseField: 'tacticUpsert',
        query: TacticsDocument,
        queryVars: tacticsQueryVars,
        queryName: 'tactics',
      }),
    });
  }

  useScrollToId({
    isLoading: false,
    isDataPresent: !showEmptyState,
    queryString: 'returnToId',
    addedClass: 'originalTactic',
  });

  const countryMedicalObjectivesSortedAlphabetically = sortBy(
    sortedMedicalObjectives.countryMedicalObjectives,
    'region'
  );

  return (
    <>
      <TacticsDeleteModal
        visible={tacticDeleteModal.visible}
        handleClose={() =>
          setTacticDeleteModal({ visible: false, regions: [] })
        }
        title="Cannot delete this tactic"
        text="This tactic has a country spin-off depending on it."
      >
        <GreyContainer>
          <WhiteContainer>
            <BodySmall color={colors.greyDark}>
              Countries depending on this tactic
            </BodySmall>
            {tacticDeleteModal.regions.map((region) => (
              <FlagAndName>
                <CountryFlag
                  country={region as CountryGlobalRegional}
                  size="sm"
                />
                <BodySmall>{startCase(region)}</BodySmall>
              </FlagAndName>
            ))}
          </WhiteContainer>
        </GreyContainer>
      </TacticsDeleteModal>

      <ModalStyling />
      <MedicalTacticsRationaleModal
        key={selectedTactic?.localUid || 0}
        modalSlideIdx={modalSlideIdx}
        setModalPage={setModalPage}
        setModalSlideIdx={setModalSlideIdx}
        selectedTactic={selectedTactic}
        showSingleSlide={showSingleSlide}
        currency={currency}
        onSubmit={onSubmit}
        modalDetails={{ Tactic: selectedTactic?.tacticText || '' }}
      />

      <ScrollContainer
        ref={wrapperRef}
        sidebarOpen={sidebarOpen}
        topOffset={topOffset}
        id="medical-tactics-scroll-container"
      >
        {countryTacticsEnabled && isLeadOrGlobalContributor && isDesktop && (
          <SpinOffsToggle
            disabled={false}
            value={showCountrySpinOffs}
            onChange={() => setShowCountrySpinOffs(!showCountrySpinOffs)}
            label="Show country tactics"
          />
        )}

        {showEmptyState ? (
          <MedicalTacticsEmptyStates
            country={user?.country}
            drugId={drugId}
            strategyId={strategyId}
            competitiveAdvantageRowId={competitiveAdvantageRowId}
            countryTacticsDisabledAndNoGlobalObjectives={
              countryTacticsDisabledAndNoGlobalObjectives
            }
            noMedicalObjectives={noMedicalObjectives}
            noLocalObjectivesToShow={noLocalObjectivesToShow}
            noUserLocalOrGlobalObjectives={noUserLocalOrGlobalObjectives}
          />
        ) : (
          <Wrapper sidebarOpen={sidebarOpen}>
            <MedicalTacticsPicos
              currency={currency}
              picoSuggestions={picoSuggestions}
            />
            {[
              ...sortedMedicalObjectives.globalMedicalObjectives,
              ...countryMedicalObjectivesSortedAlphabetically,
            ]?.map((statement) => {
              const isGlobalObjective = statement.region === 'global';

              const filteredTactics =
                localData?.filter(
                  (tactic) =>
                    !tactic.parentTacticId &&
                    tactic.keyStatementId === statement.id &&
                    (tactic.focused ||
                      (isLeadOrGlobalContributor
                        ? tactic.region === 'global'
                        : true))
                ) || [];
              return (
                <StyledTable
                  key={statement.id}
                  header={
                    <>
                      {!isGlobalObjective && (
                        <div
                          style={{
                            display: 'flex',
                            gap: 5,
                            marginBottom: 2,
                            marginLeft: objectiveHeaderMarginLeft,
                            alignItems: 'center',
                          }}
                        >
                          <CountryFlag
                            size={15}
                            country={statement.region as CountryGlobalRegional}
                          />
                          <BodySmall color={colors.greyDark}>
                            {startCase(statement.region || '')} specific
                          </BodySmall>
                        </div>
                      )}
                      <Header>
                        <Subtitle2>{statement.text}</Subtitle2>
                      </Header>
                    </>
                  }
                  keyField={'localUid'}
                  addButtonText={'Add tactic'}
                  useDiv
                  isDesktop={isDesktop}
                  cols={['Tactic', 'Category', 'Audience', 'Timing']}
                  data={filteredTactics}
                  rows={(data, idx) => {
                    const localUid = data.localUid;

                    if (!localUid) return null;

                    const id = data.id || Math.ceil(Math.random() * 20000);

                    const fromOwnRegion =
                      user?.country === globalContributor
                        ? false
                        : isLeadOrGlobalContributor
                        ? data.region === 'global'
                        : data.region === user?.country;

                    const spinOffs: TacticFragment[] = sortBy(
                      tactics?.filter((t) => t.parentTacticId === data.id) ||
                        [],
                      'region'
                    );

                    return (
                      <>
                        {!idx && !isDesktop ? <Divider /> : null}
                        <TacticRow
                          key={localUid}
                          showCountrySpinOffs={
                            countryTacticsEnabled && showCountrySpinOffs
                          }
                          countryTacticsEnabled={countryTacticsEnabled}
                          fromOwnRegion={fromOwnRegion}
                          userMayEditRow={
                            isLead
                              ? data?.region === 'global'
                              : [user?.country, 'global'].includes(data.region)
                          }
                          isLocalTactic={statement.region !== 'global' || false}
                          isSpinOff={false}
                          region={statement.region || undefined}
                          hideSpinOffBtn={
                            !countryTacticsEnabled ||
                            statement.region !== 'global' ||
                            spinOffs.some((sp) => sp.region === user?.country)
                          }
                          createSpinOff={async () => {
                            if (!data.id) return;
                            if (!user?.country) return;

                            try {
                              await upsertTactic({
                                variables: {
                                  data: {
                                    localUid: uid(),
                                    strategyId: +strategyId,
                                    competitiveAdvantageRow: +competitiveAdvantageRowId,
                                    keyStatement: statement.id,
                                    parentTactic: data.id,
                                    region: user.country,
                                    tacticText: data.tacticText,
                                    category: data.category,
                                  },
                                },
                                optimisticResponse: {
                                  tacticUpsert: {
                                    ...data,
                                    parentTactic: data as TacticFragment,
                                    parentTacticId: data.id,
                                    region: user.country,
                                    childTactics: [],
                                    tacticText: data.tacticText,
                                    category: data.category,
                                    audienceText: '',
                                    budget: null,
                                    dueDate: [],
                                    focused: false,
                                    localUid: uid(),
                                    strategyId: +strategyId,
                                    competitiveAdvantageRowId: +competitiveAdvantageRowId,
                                    focusRationale: '',
                                    __typename: 'Tactic',
                                    createdAt: new Date().toISOString(),
                                    lastUpdated: data.lastUpdated,
                                    responsibility: '',
                                    timingEnd: [],
                                    timingStart: [],
                                    x: null,
                                    y: null,
                                    id: -1,
                                  },
                                },

                                update: apolloUpsertHelper({
                                  responseField: 'tacticUpsert',
                                  query: TacticsDocument,
                                  queryVars: tacticsQueryVars,
                                  queryName: 'tactics',
                                }),
                              });
                            } catch (e) {
                              alert('Something went wrong');
                            }
                          }}
                          setModalSlideIdx={(v) => {
                            if (typeof data?.id !== 'undefined') {
                              setSelectedTactic(data);
                            } else {
                              if (!!tempTactic) {
                                setSelectedTactic(tempTactic);
                              }
                            }
                            setModalPage(v);
                          }}
                          setShowSingleSlide={setShowSingleSlide}
                          showModal={(pageIdx: number) => {
                            if (typeof data?.id !== 'undefined') {
                              setSelectedTactic(data);
                            } else {
                              if (!!tempTactic) {
                                setSelectedTactic(tempTactic);
                              }
                            }
                            setModalSlideIdx(pageIdx);
                          }}
                          autoFocus={tempTactic?.localUid === localUid}
                          isDesktop={isDesktop}
                          isLead={isLead}
                          currency={currency}
                          data={data}
                          onUpdate={async (updatedData: TacticUpdateInput) => {
                            const getData = (
                              field: keyof Partial<
                                Omit<TacticUpdateInput, 'x' | 'y'>
                              >
                            ) => {
                              const upData = updatedData[field];

                              if (
                                typeof upData === 'undefined' ||
                                upData === null
                              ) {
                                return data[field];
                              } else return upData;
                            };
                            if (!statement.region) return;
                            try {
                              await upsertTactic({
                                variables: {
                                  data: {
                                    localUid,
                                    strategyId: +strategyId,
                                    competitiveAdvantageRow: +competitiveAdvantageRowId,
                                    keyStatement: statement.id,
                                    region: statement.region,
                                    parentTactic: null,
                                    ...updatedData,
                                  },
                                },
                                optimisticResponse: {
                                  tacticUpsert: {
                                    ...data,
                                    ...updatedData,
                                    childTactics: [],
                                    parentTactic: null,
                                    parentTacticId: null,
                                    region: statement.region,

                                    audienceText: getData(
                                      'audienceText'
                                    ) as typeof data['audienceText'],
                                    budget: getData(
                                      'budget'
                                    ) as typeof data['budget'],
                                    category: getData(
                                      'category'
                                    ) as typeof data['category'],
                                    collaboration: data.collaboration || null,
                                    competitiveAdvantageRowId: +competitiveAdvantageRowId,
                                    createdAt: new Date().toISOString(),
                                    dueDate: getData(
                                      'dueDate'
                                    ) as typeof data['dueDate'],
                                    focused: getData(
                                      'focused'
                                    ) as typeof data['focused'],
                                    id,
                                    keyStatementId: data['keyStatementId'],
                                    lastUpdated: data.lastUpdated,
                                    localUid,
                                    responsibility: getData(
                                      'responsibility'
                                    ) as typeof data['responsibility'],

                                    strategyId: +strategyId,
                                    tacticText: getData(
                                      'tacticText'
                                    ) as typeof data['tacticText'],
                                    timingEnd: getData(
                                      'timingEnd'
                                    ) as typeof data['timingEnd'],
                                    timingStart: getData(
                                      'timingStart'
                                    ) as typeof data['timingStart'],
                                    x: null,
                                    y: null,
                                    focusRationale:
                                      (getData(
                                        'focusRationale'
                                      ) as typeof data['focusRationale']) || '',
                                    __typename: 'Tactic',
                                  },
                                },

                                update: apolloUpsertHelper({
                                  responseField: 'tacticUpsert',
                                  query: TacticsDocument,
                                  queryVars: tacticsQueryVars,
                                  queryName: 'tactics',
                                }),
                              });
                            } catch (error) {
                              alert('Something went wrong');
                            }
                          }}
                          onDelete={async () => {
                            // Checks if it's in the cache (meaning it has been created on the DB)
                            if (!data.__typename) {
                              setTempTactic(undefined);
                            } else {
                              const tacticData = data as TacticFragment;

                              try {
                                await deleteTactic({
                                  optimisticResponse: {
                                    tacticDelete: {
                                      ...tacticData,
                                    },
                                  },
                                  update: apolloDeleteHelper({
                                    responseField: 'tacticDelete',
                                    query: TacticsDocument,
                                    queryVars: tacticsQueryVars,
                                    queryName: 'tactics',
                                  }),
                                  variables: {
                                    localUid,
                                  },
                                });
                              } catch (error) {
                                if (
                                  error instanceof Error &&
                                  error.message.includes(
                                    'cannot be deleted as it has child data'
                                  )
                                ) {
                                  const regionsString = error.message.split(
                                    'child data: '
                                  )[1];
                                  const regions = regionsString.split(',');

                                  setTacticDeleteModal({
                                    visible: true,
                                    regions,
                                  });
                                } else {
                                  alert('Something went wrong');
                                }
                              }
                            }
                          }}
                        />

                        {countryTacticsEnabled &&
                          spinOffs.map((spinOff) => {
                            const spinOffFromOwnRegion = isLeadOrGlobalContributor
                              ? spinOff.region === 'global'
                              : spinOff.region === user?.country;
                            const isGlobalContributor =
                              user?.country === globalContributor;

                            if (
                              !spinOff.localUid ||
                              (!isLeadOrGlobalContributor &&
                                !isGlobalContributor &&
                                !spinOffFromOwnRegion)
                            )
                              return null;

                            return (
                              <TacticRow
                                key={spinOff.localUid}
                                region={spinOff.region}
                                userMayEditRow={
                                  !isLeadOrGlobalContributor &&
                                  spinOff.region === user?.country
                                }
                                fromOwnRegion={spinOffFromOwnRegion}
                                hideSpinOffBtn={true}
                                isLocalTactic={true}
                                isSpinOff={true}
                                createSpinOff={() => null}
                                setModalSlideIdx={(v) => {
                                  if (typeof spinOff?.id !== 'undefined') {
                                    setSelectedTactic(spinOff);
                                  } else {
                                    if (!!tempTactic) {
                                      setSelectedTactic(tempTactic);
                                    }
                                  }
                                  setModalPage(v);
                                }}
                                setShowSingleSlide={setShowSingleSlide}
                                showModal={(pageIdx: number) => {
                                  if (typeof spinOff?.id !== 'undefined') {
                                    setSelectedTactic(spinOff);
                                  } else {
                                    if (!!tempTactic) {
                                      setSelectedTactic(tempTactic);
                                    }
                                  }
                                  setModalSlideIdx(pageIdx);
                                }}
                                isDesktop={isDesktop}
                                isLead={isLead}
                                currency={currency}
                                data={spinOff}
                                parentTactic={data}
                                onUpdate={async (
                                  updatedData: TacticUpdateInput
                                ) => {
                                  if (!spinOff.localUid) return;

                                  try {
                                    await upsertTactic({
                                      variables: {
                                        data: {
                                          localUid: spinOff.localUid,
                                          strategyId: +strategyId,
                                          competitiveAdvantageRow: +competitiveAdvantageRowId,
                                          keyStatement: statement.id,
                                          ...updatedData,
                                        },
                                      },

                                      update: apolloUpsertHelper({
                                        responseField: 'tacticUpsert',
                                        query: TacticsDocument,
                                        queryVars: tacticsQueryVars,
                                        queryName: 'tactics',
                                      }),
                                    });
                                  } catch (err) {
                                    alert('Something went wrong');
                                  }
                                }}
                                onDelete={async () => {
                                  // Checks if it's in the cache (meaning it has been created on the DB)
                                  if (!spinOff.__typename) {
                                    setTempTactic(undefined);
                                  } else {
                                    const tacticData = spinOff as TacticFragment;

                                    if (!tacticData.localUid) return;

                                    try {
                                      await deleteTactic({
                                        optimisticResponse: {
                                          tacticDelete: {
                                            ...tacticData,
                                          },
                                        },
                                        update: apolloDeleteHelper({
                                          responseField: 'tacticDelete',
                                          query: TacticsDocument,
                                          queryVars: tacticsQueryVars,
                                          queryName: 'tactics',
                                        }),
                                        variables: {
                                          localUid: tacticData.localUid,
                                        },
                                      });
                                    } catch (err) {
                                      alert('Something went wrong');
                                    }
                                  }
                                }}
                              />
                            );
                          })}
                      </>
                    );
                  }}
                  emptyStateText={{
                    header: 'No tactics yet',

                    subHeading:
                      isLeadOrGlobalContributor &&
                      statement.region !== 'global' &&
                      !statement.tactics.some((t) => t.focused)
                        ? undefined
                        : 'Add possible tactics to this medical objective to share your ideas',
                  }}
                  isAddingRow={addingTactic}
                  addRow={async () => {
                    if (!statement.region) return;
                    setAddingTactic(true);
                    const localUid = uid();

                    addPreview(localUid, statement.id, statement.region);
                    setAddingTactic(false);
                  }}
                  hideAddButton={
                    isLeadOrGlobalContributor && statement.region !== 'global'
                  }
                />
              );
            })}
          </Wrapper>
        )}
      </ScrollContainer>
    </>
  );
};
