import { ReactElement, useEffect, useRef, useState, useMemo } from 'react';
import { CalloutMobile } from 'components/LongTermStrategy/Summary/CalloutMobile';
import { PostItsEmpty } from 'components/PostItsEmpty';
import { BodySmall, Caption, Subtitle2 } from 'components/shared';
import { black20ScrollBar } from 'components/Strategy/scrollbar';
import { colors } from 'constants/index';
import { KeyEventFragment } from 'data/graphql/generated';
import { addYears, getQuarter, getTime } from 'date-fns';
import differenceInCalendarQuarters from 'date-fns/differenceInCalendarQuarters';
import useDesktop from 'hooks/useDesktop';
import useMobile from 'hooks/useMobile';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import {
  getImperativePositions,
  getKeyEventLeftOffset,
  getBackgroundColumnStyle,
} from './utils';
import TimelineKeyEvent from './TimelineKeyEvent';
import TimelineKeyEventVerticalLine from './TimelineKeyEventVerticalLine';
import {
  YEAR_COLUMN_WIDTH,
  QUARTER_COLUMN_WIDTH,
  SCROLLBAR_PADDING,
  LEFT_SIDE_PADDING,
  MOBILE_LEFT_SIDE_PADDING,
  DIAMOND_WIDTH,
  TIMELINE_TOP_MARGIN,
  MOBILE_TIMELINE_TOP_MARGIN,
  KEY_EVENT_ICON_WIDTH,
} from './constants';
import { RowProps } from './types';

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;

  @media ${device.tabletMin} {
    width: calc(100%);
  }
`;

const TimelineWrapperClipBorder = styled.div`
  min-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  border: 1px solid ${colors.black10};

  @media ${device.tabletMin} {
    padding: 1px;
    background: ${colors.black10};

    clip-path: polygon(
      calc(100% - ${SCROLLBAR_PADDING}px) calc(0% + 5px),
      100% 50%,
      calc(100% - ${SCROLLBAR_PADDING}px) 100%,
      0% 100%,
      ${SCROLLBAR_PADDING}px 50%,
      0% calc(0% + 5px)
    );
  }
`;
const TimelineWrapperClip = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;
  @media ${device.tabletMin} {
    // Hide scrollbar
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    clip-path: polygon(
      calc(100% - ${SCROLLBAR_PADDING}px) calc(0% + 5px),
      100% 50%,
      calc(100% - ${SCROLLBAR_PADDING}px) 100%,
      0% 100%,
      ${SCROLLBAR_PADDING}px 50%,
      0% calc(0% + 5px)
    );
  }
`;

const Tactic = styled.div`
  position: relative;
  height: 15px;
  display: flex;
  align-items: center;
  width: fit-content;
`;

const TacticWrapper = styled.div`
  margin-bottom: 30px;
`;

const TacticTextWrapper = styled.div`
  max-width: 374px;

  @media (max-width: 373px) {
    max-width: calc(100vw - ${MOBILE_LEFT_SIDE_PADDING + 5}px);
  }
`;

const TacticText = styled(Caption)``;

const Imperative = styled.div`
  padding: 20px 0 20px ${LEFT_SIDE_PADDING}px;
  @media ${device.mobile} {
    padding: 20px 0 20px ${MOBILE_LEFT_SIDE_PADDING}px;
  }
`;

const KeyEventWrapper = styled.div`
  padding: 0;
  position: sticky;
  top: 5px;
  z-index: 1;
`;

const QuarterLabelsWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  height: 15px;
`;

const TacticEmptyState = styled.div`
  padding: 24px;
  background: ${colors.yellow20a};
  text-align: center;
  width: 100%;
  position: sticky;
  left: ${LEFT_SIDE_PADDING}px;
  @media ${device.mobile} {
    left: ${MOBILE_LEFT_SIDE_PADDING}px;
  }
`;

const ScrollController = styled.div<{ displayEmptyState: boolean }>`
  /* Due to the clip path, the scroll bars are also cut out 
  so we need an element above the entire timeline to control the scrolling */
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: ${({ displayEmptyState }) => (displayEmptyState ? '' : 'auto')};

  @media ${device.tabletMin} {
    width: calc(100% + ${SCROLLBAR_PADDING}px);
    height: calc(100% + ${SCROLLBAR_PADDING}px);
    ${black20ScrollBar}
    // Removes square scrollbar corner
    ::-webkit-scrollbar-corner {
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      margin: 10px 5px;
    }
  }

  // Only allow user interaction with the scrollbars
  clip-path: polygon(
    calc(100% - ${SCROLLBAR_PADDING}px) 0,
    100% 0,
    100% 100%,
    0 100%,
    0 calc(100% - ${SCROLLBAR_PADDING}px),
    calc(100% - ${SCROLLBAR_PADDING}px) calc(100% - ${SCROLLBAR_PADDING}px)
  );
`;

const ImperativeTitle = styled(Subtitle2)`
  margin-bottom: 15px;
  padding-left: 5px;
  max-width: 640px;
  position: sticky;
  left: ${LEFT_SIDE_PADDING}px;
  @media (max-width: 639px) {
    max-width: calc(100vw - 100px);
  }
  @media ${device.mobile} {
    left: ${MOBILE_LEFT_SIDE_PADDING}px;
  }
`;

const TimelineWrapper = styled.div<{ displayEmptyState: boolean }>`
  position: relative;
  padding-top: ${TIMELINE_TOP_MARGIN}px;
  width: fit-content;
  background: white;
  overflow: visible;
  // allows me to use overflow hidden behaviour but still allows children to be sticky if needed
  contain: content;
  @media ${device.mobile} {
    padding-top: ${MOBILE_TIMELINE_TOP_MARGIN};
  }

  background: ${({ displayEmptyState }) =>
    displayEmptyState ? colors.purple05 : colors.white};
`;

const BG = styled.div``;

const Content = styled.div``;

const Duration = styled.div<{ isCountryTimeline?: boolean }>`
  height: 5px;
  background: ${({ isCountryTimeline }) =>
    isCountryTimeline ? colors.bluePurple : colors.purplePinkGradient};
  border-radius: 5px;
`;
const DueDateDiamond = styled.div<{ isCountryTimeline?: boolean }>`
  background: ${({ isCountryTimeline }) =>
    isCountryTimeline ? colors.bluePurple : colors.purplePinkGradient};
  border: 1px solid ${colors.white};
  width: ${DIAMOND_WIDTH}px;
  height: ${DIAMOND_WIDTH}px;
  transform: rotate(45deg);
`;

const QuarterLabel = styled.div`
  width: 102px;
  text-align: center;
  display: flex;
  justify-content: center;
  white-space: pre-wrap;
  flex-shrink: 0;
`;

const QuarterLabels = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  padding-right: ${LEFT_SIDE_PADDING * 2}px;
  padding-left: ${LEFT_SIDE_PADDING}px;
  @media ${device.mobile} {
    padding-right: ${MOBILE_LEFT_SIDE_PADDING * 2}px;
    padding-left: ${MOBILE_LEFT_SIDE_PADDING}px;
  }
  width: fit-content;
`;

const StyledEmptyState = styled(PostItsEmpty)`
  border: 1px solid ${colors.black30};
  position: absolute;
  inset: 30px 50px;
  width: auto;
  height: auto;
  z-index: 5;
  padding-top: 50px;

  @media ${device.tabletMin} {
    padding-top: 200px;
  }
`;

interface Props {
  tactics: RowProps[];
  rows: {
    id: number;
    __typename?: string;
    strategicPossibility?: { name: string } | null | undefined;
    title?: string;
  }[];
  emptyStateText?: string;
  prependDetailText?: string | ReactElement;
  isCountryTimeline?: boolean;
  keyEvents?: Record<string, KeyEventFragment[]>;
}

/* This helps prevent a constant resizing of the wrapper when we scroll down on a mobile browser. 
A resize is triggered because the tactics object triggers a rerender for some reason */
// let previousScreenSizes = [0, 0];

export const SummaryTimeline = ({
  tactics,
  rows,
  emptyStateText,
  prependDetailText,
  isCountryTimeline,
  keyEvents = {},
}: Props) => {
  const outerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const clipBorderRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);
  const labelWrapperRef = useRef<HTMLDivElement>(null);
  const scrollControllerRef = useRef<HTMLDivElement>(null);
  const [quarterAreaWidth, setQuarterAreaWidth] = useState(102);
  const [hoveredPinIdx, setHoveredPinIdx] = useState<undefined | number>(
    undefined
  );
  const isDesktop = useDesktop();
  const handleOnMouseLeave = () => {
    if (isDesktop) {
      setHoveredPinIdx(undefined);
    }
  };

  const isMobile = useMobile();

  const leftSidePadding = isMobile
    ? MOBILE_LEFT_SIDE_PADDING
    : LEFT_SIDE_PADDING;

  const getDateYear = (date: string) => +date.slice(-4);
  const getDateMonth = (date: string) => +date.slice(0, -4);

  const allDates = useMemo(
    () =>
      tactics.flatMap((val) => {
        // convert dates to timestamps so they can be compared
        return [
          val.dueDate.length
            ? new Date(
                getDateYear(val.dueDate.join('')),
                getDateMonth(val.dueDate.join('')),
                1
              ).getTime()
            : '',
          val.timingEnd.length
            ? new Date(
                getDateYear(val.timingEnd.join('')),
                getDateMonth(val.timingEnd.join('')),
                1
              ).getTime()
            : '',
          val.timingStart.length
            ? new Date(
                getDateYear(val.timingStart.join('')),
                getDateMonth(val.timingStart.join('')),
                1
              ).getTime()
            : '',
        ].filter((val) => !!val) as number[];
      }),
    [tactics]
  );

  function getMinDate(allDates: number[]): number {
    const min = Math.min(...allDates);
    if (min === Number.POSITIVE_INFINITY) {
      return Date.now();
    }

    return min;
  }

  function getMaxDate(allDates: number[]): number {
    const max = Math.max(...allDates);

    if (max === Number.NEGATIVE_INFINITY) {
      return getTime(addYears(Date.now(), 2));
    }

    return max;
  }

  // If no data provided, show the range from now to 2 years into the future
  const minDate = getMinDate(allDates);
  const maxDate = getMaxDate(allDates);

  const quarters = differenceInCalendarQuarters(maxDate, minDate) + 1;

  const minDateQuarter = getQuarter(new Date(minDate));

  const [distanceFromTop, setDistanceFromTop] = useState(0);
  const [timelineWidth, setTimelineWidth] = useState(0);
  const [timelineScrollableHeight, setTimelineScrollableHeight] = useState(0);

  const displayEmptyState = !rows.length;

  useEffect(() => {
    const onResize = () => {
      // const newScreenSizes = [window.innerWidth, window.innerHeight];
      // Temp scroll prevention
      // if (
      //   previousScreenSizes[0] === newScreenSizes[0] &&
      //   previousScreenSizes[1] === newScreenSizes[1]
      // ) {
      //   return;
      // }

      // previousScreenSizes = newScreenSizes;

      if (!!labelRef.current) {
        setTimelineWidth(labelRef.current.offsetWidth);
      }
      /* The value of scrollHeight is different between the time this hook runs and when the children of wrapperRef are rendered. 
      This means the value is retrieved  too soon here, so the requestAnimation frame fixes that.*/
      requestAnimationFrame(() => {
        if (wrapperRef.current) {
          setTimelineScrollableHeight(wrapperRef.current.scrollHeight);
          const {
            bottom,
            height,
          } = wrapperRef?.current?.getBoundingClientRect();
          setDistanceFromTop(bottom - height);
        }
      });

      const evenColumnWidth =
        ((outerRef?.current?.offsetWidth || 1) -
          SCROLLBAR_PADDING -
          // labels have 1 padding on the left and (padding X 2) on the right
          leftSidePadding * 3) /
        quarters;

      setQuarterAreaWidth(Math.max(evenColumnWidth, 102));
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [tactics, rows, leftSidePadding, quarters]);
  const yearGap = quarterAreaWidth + YEAR_COLUMN_WIDTH;
  const quarterGap = QUARTER_COLUMN_WIDTH + quarterAreaWidth;

  const backgroundColumnStyle = getBackgroundColumnStyle({
    yearGap,
    quarterGap,
  });

  const backgroundOffset =
    leftSidePadding +
    Math.max(0, minDateQuarter - 1) *
      (-quarterAreaWidth - QUARTER_COLUMN_WIDTH);

  const organisedKeyEvents = useMemo(
    () =>
      Object.entries(keyEvents).filter((event) => {
        const timestamp = Number(event[0]);
        return timestamp >= minDate && timestamp <= maxDate;
      }),
    [keyEvents, minDate, maxDate]
  );

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      {displayEmptyState && (
        <StyledEmptyState title="No plan yet">
          <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
            Big ideas and tactics will be displayed here by strategic imperative
            and year
          </BodySmall>
        </StyledEmptyState>
      )}

      <InnerWrapper ref={outerRef}>
        <QuarterLabelsWrapper ref={labelWrapperRef}>
          <QuarterLabels ref={labelRef}>
            {!!quarters &&
              [...Array(quarters)].map((val, idx) => {
                const yearIncrement = Math.floor((minDateQuarter + idx) / 4);

                const start = !((minDateQuarter + idx + 3) % 4);
                const year = new Date(minDate).getFullYear();

                const text = start ? (
                  <QuarterLabel
                    style={{
                      width: quarterAreaWidth + YEAR_COLUMN_WIDTH,
                    }}
                    key={idx}
                  >
                    <Caption color={colors.greyDark}>
                      Q{!idx ? minDateQuarter : 1}
                    </Caption>{' '}
                    <Caption> {year + yearIncrement}</Caption>
                  </QuarterLabel>
                ) : (
                  <QuarterLabel
                    style={{
                      width: quarterAreaWidth + QUARTER_COLUMN_WIDTH,
                    }}
                    key={idx}
                  >
                    <Caption color={colors.greyDark}>
                      Q{(minDateQuarter + idx) % 4 || 4}
                    </Caption>
                  </QuarterLabel>
                );

                return text;
              })}
          </QuarterLabels>
        </QuarterLabelsWrapper>

        <div style={{ position: 'relative' }}>
          <ScrollController
            ref={scrollControllerRef}
            displayEmptyState={displayEmptyState}
            onScroll={(e) => {
              const target = e.target as HTMLDivElement;

              if (wrapperRef.current && e.target) {
                wrapperRef.current.scrollLeft = target.scrollLeft || 0;
                wrapperRef.current.scrollTop = target.scrollTop || 0;
              }

              if (labelWrapperRef.current && e.target) {
                labelWrapperRef.current.scrollLeft = target.scrollLeft || 0;
              }
            }}
          >
            <div
              style={{
                width: timelineWidth,
                minWidth: '100%',
                height: timelineScrollableHeight,
              }}
            />
          </ScrollController>

          <TimelineWrapperClipBorder
            style={{
              height: `calc(100vh - ${distanceFromTop + 40}px)`,
            }}
            ref={clipBorderRef}
          >
            <TimelineWrapperClip
              ref={wrapperRef}
              onScroll={(e) => {
                const target = e.target as HTMLDivElement;
                if (scrollControllerRef.current && target) {
                  scrollControllerRef.current.scrollLeft =
                    target.scrollLeft || 0;
                  scrollControllerRef.current.scrollTop = target.scrollTop || 0;
                }

                if (labelWrapperRef.current && target) {
                  labelWrapperRef.current.scrollLeft = target.scrollLeft || 0;
                }
              }}
            >
              <TimelineWrapper
                style={{
                  width: timelineWidth || '100%',
                  minWidth: '100%',
                  minHeight: '100%',
                }}
                displayEmptyState={displayEmptyState}
              >
                <BG
                  style={{
                    position: 'absolute',
                    inset: 0,
                    top: `${TIMELINE_TOP_MARGIN}px`,
                    left: `${backgroundOffset}px`,
                    backgroundImage: backgroundColumnStyle,
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    pointerEvents: 'none',
                    overflow: 'hidden',
                    height: `calc(100% - ${TIMELINE_TOP_MARGIN}px)`,
                    maxHeight: '100%',
                    width: '100%',
                  }}
                >
                  {organisedKeyEvents?.map((event, idx) => {
                    const left = getKeyEventLeftOffset({
                      event,
                      minDate,
                      quarterAreaWidth,
                      YEAR_COLUMN_WIDTH,
                      QUARTER_COLUMN_WIDTH,
                      leftSidePadding,
                    });

                    return (
                      <TimelineKeyEventVerticalLine
                        key={Number(event[0])}
                        left={left + KEY_EVENT_ICON_WIDTH / 2}
                        isHovered={hoveredPinIdx === idx}
                        isCountryTimeline={Boolean(isCountryTimeline)}
                      />
                    );
                  })}
                </div>

                <Content>
                  {!isDesktop && (
                    <CalloutMobile
                      idx={hoveredPinIdx}
                      setHoveredIdx={setHoveredPinIdx}
                      handleClose={() => {
                        setHoveredPinIdx(undefined);
                      }}
                      data={organisedKeyEvents.map((v: any) => {
                        return [{
                          type: v[1].type,
                          text: v[1].text
                        }];
                      })}
                    />
                  )}
                  <KeyEventWrapper>
                    {organisedKeyEvents?.map((event, idx) => {
                      const left = getKeyEventLeftOffset({
                        event,
                        minDate,
                        quarterAreaWidth,
                        YEAR_COLUMN_WIDTH,
                        QUARTER_COLUMN_WIDTH,
                        leftSidePadding,
                      });

                      return (
                        <TimelineKeyEvent
                          key={Number(event[0])}
                          isCountryTimeline={Boolean(isCountryTimeline)}
                          isDesktop={isDesktop}
                          isHovered={hoveredPinIdx === idx}
                          setIsHovered={() => {
                            if (isDesktop) {
                              setHoveredPinIdx(idx);
                            }
                          }}
                          onClick={() => {
                            setHoveredPinIdx(idx);
                          }}
                          events={event[1]}
                          handleOnMouseLeave={handleOnMouseLeave}
                          left={`calc(${left}px)`}
                        />
                      );
                    })}
                  </KeyEventWrapper>

                  {rows.map((imperative, idx) => {
                    let title = '';
                    if (imperative.__typename === 'CompetitiveAdvantageRow') {
                      title = imperative?.strategicPossibility?.name || '';
                    } else {
                      title = imperative?.title || '';
                    }

                    const imperativeTactics = tactics.filter(
                      (tactic) =>
                        tactic.competitiveAdvantageRowId === imperative.id &&
                        !!tactic.focused
                    );

                    return (
                      <Imperative
                        key={imperative.id}
                        style={{
                          background: !(idx % 2)
                            ? isCountryTimeline
                              ? colors.blue05
                              : colors.purple05a
                            : 'transparent',
                        }}
                      >
                        <ImperativeTitle>{title}</ImperativeTitle>
                        {!imperativeTactics.length ? (
                          <TacticEmptyState
                            style={{
                              width:
                                (wrapperRef.current?.offsetWidth || 0) - 66,
                            }}
                          >
                            <Subtitle2 color={colors.greyDark}>
                              {emptyStateText || 'No tactics yet'}
                            </Subtitle2>
                          </TacticEmptyState>
                        ) : (
                          imperativeTactics.map((tactic) => {
                            const {
                              durationWidth,
                              durationTransformX,
                              dueDateLeft,
                              tacticTextTranslateX,
                            } = getImperativePositions(
                              tactic,
                              getDateYear,
                              getDateMonth,
                              minDate,
                              quarterAreaWidth
                            );

                            const hasStartAndEndDates = Boolean(
                              tactic.timingStart.length &&
                                tactic.timingEnd.length
                            );

                            const hasDueDate = Boolean(tactic.dueDate.length);

                            const allDatesPresent =
                              hasStartAndEndDates && hasDueDate;
                            // Tactics
                            return (
                              <TacticWrapper
                                key={(tactic?.__typename || '') + tactic.id}
                              >
                                {(hasStartAndEndDates || hasDueDate) && (
                                  <Tactic>
                                    {hasStartAndEndDates && (
                                      <Duration
                                        style={{
                                          position: 'absolute',
                                          width: durationWidth,
                                          minWidth: 33.3,
                                          transform: `translateX(${durationTransformX}px)`,
                                        }}
                                        isCountryTimeline={isCountryTimeline}
                                      />
                                    )}
                                    {!!hasDueDate && (
                                      <DueDateDiamond
                                        style={{
                                          inset: 0,
                                          marginLeft: `${dueDateLeft}px`,
                                        }}
                                        isCountryTimeline={isCountryTimeline}
                                      />
                                    )}
                                  </Tactic>
                                )}
                                <TacticTextWrapper
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    transform: `translateX(${
                                      allDatesPresent
                                        ? tacticTextTranslateX
                                        : hasDueDate
                                        ? dueDateLeft
                                        : YEAR_COLUMN_WIDTH
                                    }px)`,
                                  }}
                                >
                                  {!hasDueDate && !hasStartAndEndDates && (
                                    <Caption color={colors.darkRed}>
                                      From/To date missing
                                    </Caption>
                                  )}
                                  <div
                                    style={{
                                      display: 'flex',
                                      whiteSpace: 'pre-wrap',
                                    }}
                                  >
                                    {!!tactic?.usePrependedText &&
                                      (typeof prependDetailText === 'string' ? (
                                        <Caption
                                          color={colors.purple}
                                          style={{ textAlign: 'center' }}
                                        >
                                          {prependDetailText}
                                        </Caption>
                                      ) : (
                                        prependDetailText
                                      ))}
                                    <TacticText
                                      color={
                                        tactic.tacticText
                                          ? colors.black
                                          : colors.greyDark
                                      }
                                    >
                                      {tactic.tacticText ||
                                        'Not identified yet'}
                                    </TacticText>
                                  </div>
                                </TacticTextWrapper>
                              </TacticWrapper>
                            );
                          })
                        )}
                      </Imperative>
                    );
                  })}
                </Content>
              </TimelineWrapper>
            </TimelineWrapperClip>
          </TimelineWrapperClipBorder>
        </div>
      </InnerWrapper>
    </div>
  );
};
