import React from 'react';
import { Formik } from 'formik';
import { prettyGraphqlError } from '../../utils/prettyGraphqlError';
import {
  ErrorBox,
  ErrorMessage,
  Form,
  Title,
  SubmitButton,
  Signin,
} from '../../containers/Signin';
import InstructionsSent from './InstructionsSent';
import { useForgottenPasswordMutation } from 'data/graphql/generated';
import FormTextInput from 'components/shared/FormTextInput';

export default function ForgotPassword() {
  const [userForgottenPassword] = useForgottenPasswordMutation();
  const [instructionsSent, setInstructionsSent] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState<string>('');

  if (instructionsSent) return <InstructionsSent userEmail={userEmail} />;

  return (
    <Signin>
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          setUserEmail(values.email);

          try {
            await userForgottenPassword({
              variables: { email: values.email.toLowerCase() },
            });

            setInstructionsSent(true);
          } catch (err) {
            console.error(err);
            actions.setStatus({
              error: prettyGraphqlError(err as Error),
            });
            actions.setSubmitting(false);
          }
        }}
      >
        {({ values, handleSubmit, handleChange, status, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Title>Forgot your password?</Title>

            <FormTextInput
              name="email"
              type="email"
              onChange={handleChange}
              value={values.email}
              title="Email Address"
            />

            <SubmitButton
              width="100%"
              onClick={() => {}}
              disabled={isSubmitting}
              level="primary"
              text={isSubmitting ? 'Loading...' : 'Send reset instructions'}
            />

            {status && status.error ? (
              <ErrorBox>
                <ErrorMessage>{status.error}</ErrorMessage>
              </ErrorBox>
            ) : null}
          </Form>
        )}
      </Formik>
    </Signin>
  );
}
