import styled from 'styled-components/macro';
import { colors } from 'constants/colors';
import { getStatusText } from '../util/getStatusText';
import { AllSetStatusIcon } from '../ui/AllSetStatusIcon';

const Container = styled.div<{ isComplete: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;

  color: ${({ isComplete }) =>
    isComplete ? colors.purple : colors.burntOrange};

  background: ${({ isComplete }) =>
    isComplete ? colors.fadedGradient : colors.cream};
  border-radius: 5px;
  padding: 5px;
  width: 107px;
`;

interface RowStatusProps {
  isComplete: boolean;
}

export const RowStatus: React.FC<RowStatusProps> = ({ isComplete }) => {
  const status = getStatusText(isComplete);
  return (
    <Container isComplete={isComplete}>
      <AllSetStatusIcon isComplete={isComplete} />
      {status}
    </Container>
  );
};
