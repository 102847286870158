import styled from 'styled-components/macro';
import { Caption } from 'components/shared';
import { ArchetypeFragment } from 'data/graphql/generated';
import { colors } from 'constants/colors';
import orderArchetypeCountries from 'utils/orderArchetypeCountries';
import { useAuthContext } from 'contexts/AuthContext';
import OverlappingFlags from './OverlappingFlags';

const Wrapper = styled.div<{ selected: boolean }>`
  padding: 8px 12px;
  background: ${colors.white};
  border: 2px solid
    ${({ selected }) => (selected ? colors.purple : 'transparent')};
  border-radius: 5px;
  margin: 5px 0px;
  cursor: pointer;
`;

interface Props {
  archetype: ArchetypeFragment;
  selected: boolean;
  onClick: () => void;
}

export default function ArchetypeAndCountryFlags({
  archetype,
  selected,
  onClick,
}: Props) {
  const [{ user }] = useAuthContext();

  const countriesOrdered = orderArchetypeCountries(
    archetype.archetypeCountries,
    user
  );

  return (
    <Wrapper selected={selected} onClick={onClick}>
      <Caption>{archetype?.stakeholderDefinition?.title}</Caption>
      <OverlappingFlags archetypeCountries={countriesOrdered} />
    </Wrapper>
  );
}
