import { Link } from 'react-router-dom';
import { colors } from '../../../constants';
import { verifyUserRole } from '../../../utils/verifyUserRole';
import { BodySmall } from '../../shared';

export function BigIdeasEmptyState({
  drugId,
  strategyId,
  showGlobalPlan,
  noGlobalBigIdeas,
  noCountryBigIdeas,
  localCountryTacticsEnabled,
  userRole,
}: {
  drugId: string;
  strategyId: string;
  showGlobalPlan: boolean;
  noGlobalBigIdeas: boolean;
  noCountryBigIdeas: boolean;
  localCountryTacticsEnabled: boolean;
  userRole: ReturnType<typeof verifyUserRole>;
}) {
  const PartOneLink = () => {
    return (
      <Link
        to={`/d/${drugId}/strategy/${strategyId}/4_3/big-ideas`}
        style={{
          color: colors.greyDark,
          display: 'inline',
          fontSize: 14,
          fontWeight: 500,
        }}
      >
        {' '}
        Part 1{' '}
      </Link>
    );
  };

  switch (true) {
    case !localCountryTacticsEnabled:
      return (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <BodySmall
            style={{
              display: 'inline',
              color: colors.greyDark,
            }}
          >
            A Lead should choose big ideas to focus on in
          </BodySmall>
          <PartOneLink />
        </div>
      );
    case !showGlobalPlan && noGlobalBigIdeas && !noCountryBigIdeas:
      return (
        <>
          <BodySmall
            style={{
              display: 'inline',
              color: colors.greyDark,
            }}
          >
            Use the toggle above to view big ideas with a country-specific focus
          </BodySmall>
        </>
      );
    case !showGlobalPlan &&
      noGlobalBigIdeas &&
      !noCountryBigIdeas &&
      userRole.isCountryContributor:
      return (
        <>
          <BodySmall
            style={{
              display: 'inline',
              color: colors.greyDark,
            }}
          >
            Use the toggle above to view big ideas with a global focus
          </BodySmall>
        </>
      );
    case showGlobalPlan &&
      noGlobalBigIdeas &&
      !noCountryBigIdeas &&
      (userRole.isGlobalContributor || userRole.isLead):
      return (
        <>
          <BodySmall
            style={{
              display: 'inline',
              color: colors.greyDark,
            }}
          >
            Collaborators should choose big ideas to focus on globally or
            locally in
          </BodySmall>
          <PartOneLink />
        </>
      );
    default:
      return (
        <>
          <BodySmall
            style={{
              display: 'inline',
              color: colors.greyDark,
            }}
          >
            A Lead should choose big ideas to focus on in
          </BodySmall>
          <PartOneLink />
        </>
      );
  }
}
