import { colors } from 'constants/index';
import styled from 'styled-components/macro';
import { ButtonLabel } from './TextStyles';

interface Props {
  text: string;
  active?: boolean;
  onClick(): void;
  fivePxRadius?: boolean;
  spreadEvenly?: boolean;
}

interface ActiveProps {
  active: boolean;
  fivePxRadius: boolean;
  spreadEvenly?: boolean;
}

export const Wrapper = styled.div<ActiveProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 0 15px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid
    ${({ active }) => (active ? colors.purple : colors.white)};
  cursor: pointer;
  background: ${colors.white};
  border-radius: ${({ fivePxRadius }) =>
    fivePxRadius ? '5px 5px 0px 0px' : '15px 15px 0px 0px'};

  p {
    color: ${({ active }) => (active ? colors.black : colors.greyDark)};
    transition-property: background-color, color;
    transition-duration: 0.3s;
  }

  &:hover {
    p {
      color: ${colors.black};
    }
    border-bottom: 2px solid
      ${({ active }) => (active ? colors.purple : colors.purple30)};
  }

  transition: border 0.3s ease;
  flex: ${({ spreadEvenly }) => spreadEvenly && 'auto'};
`;

export const CategoryTab = ({
  text,
  active = false,
  onClick,
  fivePxRadius,
  spreadEvenly,
}: Props) => {
  return (
    <Wrapper
      active={active}
      onClick={onClick}
      fivePxRadius={!!fivePxRadius}
      spreadEvenly={spreadEvenly}
    >
      <ButtonLabel>{text}</ButtonLabel>
    </Wrapper>
  );
};
