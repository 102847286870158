import { useEffect, useState } from 'react';

import {
  PostItGroup,
  PostItGroupFragment,
  PostItGroupInput,
  PostItGroupsDocument,
  PostItGroupsQuery,
  PostItGroupsQueryVariables,
  Stakeholder,
  Step,
  SubStep,
  usePostItGroupCreateMutation,
  usePostItGroupDeleteMutation,
  usePostItGroupsQuery,
  usePostItGroupUpdateMutation,
} from 'data/graphql/generated';
import { ApolloError, InternalRefetchQueryDescriptor } from '@apollo/client';
import client from 'data/apollo';
import { polling } from 'constants/index';

export interface Payload {
  items: PostItGroupFragment[];
  loading: boolean;
  disabled: boolean;
  createGroup(
    group: Pick<PostItGroupInput, 'title' | 'company' | 'preset'>,
    additionalRefetchQueries?: InternalRefetchQueryDescriptor[]
  ): Promise<number | undefined>;
  updateGroup(group: PostItGroup): void;
  removeGroup(groupId: number): Promise<void>;
  error: ApolloError | undefined;
}

interface Extras {
  step: Step;
  drugId: string | number;
  strategyId: string | number;
  stakeholder?: Stakeholder;
  substep?: SubStep;
  idx?: number;
  competitiveAdvantageRowId?: number;
  competitorId?: number;
  type?: PostItGroup['type'];
}

export function usePostItGroups(
  queryVars: PostItGroupsQueryVariables,
  extras: Extras
): Payload {
  const [disabled, setDisabled] = useState(false);

  const {
    data,
    loading,
    startPolling,
    stopPolling,
    error,
  } = usePostItGroupsQuery({
    variables: queryVars,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    startPolling(polling.default);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const [groupCreate] = usePostItGroupCreateMutation();
  const [groupDelete] = usePostItGroupDeleteMutation();
  const [groupUpdate] = usePostItGroupUpdateMutation();

  const createGroup: Payload['createGroup'] = async (
    group,
    additionalRefetchQueries = []
  ) => {
    setDisabled(true);
    const res = await groupCreate({
      variables: {
        data: {
          title: group.title,
          company: group.company,
          step: extras.step,
          strategy: Number(extras.strategyId),
          stakeholder: extras?.stakeholder,
          substep: extras.substep,
          drug: Number(extras.drugId),
          idx: extras?.idx,
          preset: group.preset,
          competitiveAdvantageRow: extras?.competitiveAdvantageRowId,
          type: extras.type,
          competitor: extras?.competitorId,
        },
      },
      refetchQueries: [
        {
          query: PostItGroupsDocument,
          variables: {
            ...queryVars,
          },
        },
        ...additionalRefetchQueries,
      ],
      awaitRefetchQueries: true,
      errorPolicy: 'ignore',
    });
    setDisabled(false);
    return res.data?.postItGroupCreate.id;
  };

  async function updateGroup(group: PostItGroup) {
    const data = {
      title: group.title,
    };

    try {
      await groupUpdate({
        variables: {
          id: Number(group.id),
          data,
        },

        optimisticResponse: {
          __typename: 'Mutation',
          postItGroupUpdate: {
            __typename: 'PostItGroup',
            ...group,
            ...data,
          },
        },

        update: (proxy, { data: responseData }) => {
          if (!responseData) return;

          const { postItGroupUpdate } = responseData;

          const data: PostItGroupsQuery | null = proxy.readQuery({
            query: PostItGroupsDocument,
            variables: queryVars,
          });
          if (!data || !data.postItGroups) return;
          const updated = {
            postItGroups: {
              ...data.postItGroups,
              items: data.postItGroups.items.map((i) => {
                if (i?.id === group.id)
                  return { ...group, ...postItGroupUpdate };
                return i;
              }),
              total: (data.postItGroups.total || 0) - 1,
            },
          };
          proxy.writeQuery({
            query: PostItGroupsDocument,
            variables: queryVars,
            data: updated,
          });
        },
      });
    } catch (err) {
      console.error(err);
    }
  }

  async function removeGroup(groupId: number) {
    if (!groupId) return;
    try {
      //Get card from proxy
      const data: PostItGroupsQuery | null = client.readQuery({
        query: PostItGroupsDocument,
        variables: queryVars,
      });

      const group = data?.postItGroups?.items.find(
        (group) => group?.id === groupId
      );

      if (!group) return;

      await groupDelete({
        variables: {
          id: Number(groupId),
        },
      });
      client.cache.evict({
        id: `PostItGroup:${groupId}`,
      });
      client.cache.gc();
    } catch (err) {
      console.error(err);
    }
  }

  return {
    items: data?.postItGroups?.items || [],
    loading,
    disabled,
    createGroup,
    updateGroup,
    removeGroup,
    error,
  };
}
