import { StrategicQuestionType } from 'data/graphql/generated';

import { DeprecatedListStrategyDefinitionHeader } from './components/List/DeprecatedListStrategyDefinitionHeader';
import { MultiListStrategyDefinitionHeader } from './components/MultiList/MultiListStrategyDefinitionHeader';
import { TextStrategyDefinitionHeader } from './components/Text/TextStrategyDefinitionHeader';
import { StrategyDefinitionHeaderProps } from './StrategyDefinitionHeader.types';

export const StrategyDefinitionHeader = ({
  type,
  totalUsers,
  isLead,
  title,
  textValue,
  collaboration,
  style = {},
  disabled,
  className,
  placeholder,
  bulletPoints,
  tooltipMessage,
  hideAlignment,
  viewingOtherRegion,
  hideTooltip,
  handleSave,
}: StrategyDefinitionHeaderProps) => {
  if (type === StrategicQuestionType.Text) {
    return (
      <TextStrategyDefinitionHeader
        totalUsers={totalUsers}
        isLead={isLead}
        title={title}
        textValue={textValue}
        collaboration={collaboration}
        style={style}
        disabled={disabled}
        className={className}
        placeholder={placeholder as any}
        tooltipMessage={tooltipMessage}
        hideAlignment={hideAlignment}
        viewingOtherRegion={viewingOtherRegion}
        hideTooltip={hideTooltip}
        handleSave={handleSave}
      />
    );
  }

  if (bulletPoints?.type === StrategicQuestionType.MultiList) {
    return (
      <MultiListStrategyDefinitionHeader
        totalUsers={totalUsers}
        isLead={isLead}
        title={title}
        collaboration={collaboration}
        style={style}
        disabled={disabled}
        className={className}
        placeholder={placeholder}
        bulletPoints={bulletPoints.bulletPoints}
        tooltipMessage={tooltipMessage}
        hideAlignment={hideAlignment}
        viewingOtherRegion={viewingOtherRegion}
        hideTooltip={hideTooltip}
        handleSave={handleSave}
      />
    );
  }

  return (
    <DeprecatedListStrategyDefinitionHeader
      totalUsers={totalUsers}
      isLead={isLead}
      title={title}
      collaboration={collaboration}
      style={style}
      disabled={disabled}
      className={className}
      placeholder={placeholder as any}
      bulletPoints={bulletPoints?.bulletPoints}
      tooltipMessage={tooltipMessage}
      hideAlignment={hideAlignment}
      viewingOtherRegion={viewingOtherRegion}
      hideTooltip={hideTooltip}
      handleSave={handleSave}
    />
  );
};
