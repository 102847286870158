import { orderBy } from 'lodash';
import { BigIdeaDetailsQuery, User } from 'data/graphql/generated';
import { verifyUserRole } from '../../../utils/verifyUserRole';

export function getBigIdeas(
  localCountryTacticsEnabled: boolean,
  showGlobalPlan: boolean,
  bigIdeasData: BigIdeaDetailsQuery | undefined,
  userRole: ReturnType<typeof verifyUserRole>,
  user: User | undefined
) {
  if (!localCountryTacticsEnabled) {
    return bigIdeasData?.bigIdeaDetails?.items.filter(
      (b) => b.country === 'global'
    );
  }

  const bigIdeaWithAdditionalCountries =
    bigIdeasData?.bigIdeaDetails?.items.map((item) => {
      return {
        ...item,
        additionalCountries: Number(item.bigIdea.BigIdeaCountryFocus?.length),
      };
    }) || [];

  const data = bigIdeaWithAdditionalCountries.filter((idea) => {
    if (userRole.isCountryContributor) {
      return idea.country === 'global' || idea.country === user?.country;
    }
    return true;
  });

  const isCountryContributor = userRole.isCountryContributor;

  if (isCountryContributor) {
    return (
      (showGlobalPlan
        ? orderBy(data, (item) => {
            const countryOnTop = [item.country === 'global', item.country];

            return countryOnTop;
          })
        : data?.filter((b) => b.country === user?.country)) || []
    );
  }

  return (
    (showGlobalPlan
      ? orderBy(data, (item) => {
          const globalOnTop = [item.country !== 'global', item.country];

          return globalOnTop;
        })
      : data?.filter((b) => b.country === 'global')) || []
  );
}
