import React from 'react';

import { BodySmall } from 'components/shared';
import { colors } from 'constants/colors';
import { Link } from 'react-router-dom';
import { PostItsEmpty } from 'components/PostItsEmpty';
import URLHelper from 'utils/URLHelper';

export interface MissingTrackedGoalsProps {
  drugId: string;
  strategyId: string;
  competitiveAdvantageRowId: string;
}

export const MissingTrackedGoals: React.FC<MissingTrackedGoalsProps> = ({
  drugId,
  strategyId,
  competitiveAdvantageRowId,
}) => {
  const redirectURL = URLHelper.getCriticalMetricsGoalUrl(
    drugId,
    strategyId,
    competitiveAdvantageRowId
  );
  return (
    <PostItsEmpty
      style={{ margin: 20, width: '100%' }}
      title="Nothing to discuss"
    >
      <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
        You can only view tracked goals when a goal has been focused on in{' '}
      </BodySmall>
      <Link
        to={redirectURL}
        style={{
          color: colors.greyDark,
          display: 'inline',
          fontSize: 14,
          fontWeight: 500,
        }}
      >
        3.5.2 Critical Metric Goals.
      </Link>
    </PostItsEmpty>
  );
};
