import { DraggableCard } from 'components/GridPages/DraggableCard';
import { TransitionList } from 'components/GridPages/GridPageSidebar';
import { TransitionWrapper } from 'components/InsightGroup';
import { ButtonPill } from 'components/shared';
import { SidebarCard } from 'components/shared/SidebarCard';
import {
  CommercialTacticFragment,
  CommercialTacticsDocument,
  useCommercialTacticCreateMutation,
  useCommercialTacticDeleteMutation,
  useCommercialTacticUpdateMutation,
} from 'data/graphql/generated';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Transition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components/macro';
import { PreviewOrExistingTactic } from 'types';
import { uid } from 'uid';
import {
  apolloDeleteHelper,
  apolloUpdateHelper,
} from 'utils/apolloQueryHelpers';
import { TacticGroupEmptyState } from './TacticGroupEmptyState';
import { getKeyTacticsQueryVars } from './utils';

const Wrapper = styled.div``;

const AddTacticButton = styled(ButtonPill)`
  margin-top: 10px;
`;

type CommercialTacticsWithPreview = PreviewOrExistingTactic[];

interface URLParams {
  strategyId: string;
  strategicImperativeId: string;
}

interface Props {
  data: CommercialTacticFragment[];
  keyStatementId: number;
  isDragging: boolean;
  setTouchPositioningView: React.Dispatch<React.SetStateAction<string | null>>;
  canDrag: boolean;
  region: string;
  canAddTactic: boolean;
}

export const TacticGroup: React.FC<Props> = ({
  data,
  keyStatementId,
  isDragging,
  setTouchPositioningView,
  canDrag,
  region,
  canAddTactic,
}) => {
  const [tactics, setTactics] = useState<CommercialTacticsWithPreview>(data);
  const unplacedTactics = tactics.filter((t) => !t || !('x' in t) || !t?.x);
  const allTacticsOnGrid = !!tactics.length && !unplacedTactics.length;
  const { strategyId, strategicImperativeId } = useParams<URLParams>();
  const [createCommercialTactic] = useCommercialTacticCreateMutation();
  const [updateCommercialTactic] = useCommercialTacticUpdateMutation();
  const [deleteCommercialTactic] = useCommercialTacticDeleteMutation();
  const [previewUID, setPreviewUID] = useState<string | null>(null);

  function addTactic(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const previewUID = uid();
    setPreviewUID(previewUID);

    setTactics((state) => {
      const newArr = state.slice();
      newArr.unshift({ localUid: previewUID });
      return newArr;
    });
  }

  function deletePreviewTactic() {
    setPreviewUID(null);

    setTactics((tactics) => tactics.filter((el) => el.localUid !== previewUID));
  }

  // Ensure previews are unaffected when data comes back from server
  useEffect(() => {
    if (!isDragging) {
      if (previewUID) {
        setTactics([{ localUid: previewUID }, ...data]);
      } else {
        setTactics(data);
      }
    }
    // We don't want this to run each time previewUID changes, so it is excluded from the dependencies array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isDragging]);

  const showEmptyState = allTacticsOnGrid || !tactics.length;

  return (
    <Wrapper>
      {canAddTactic && (
        <AddTacticButton
          text="Add initiative"
          iconName="Plus"
          level="secondary"
          onClick={(e) => addTactic(e)}
          className="cypress-add-tactic-btn"
          disabled={!!previewUID}
        />
      )}
      {showEmptyState ? (
        <TacticGroupEmptyState showAllTacticsState={allTacticsOnGrid} />
      ) : (
        <TransitionList>
          <TransitionGroup component={null}>
            {unplacedTactics?.map((t) => {
              const isPreview = !('id' in t);

              return (
                <Transition
                  enter={true}
                  exit={true}
                  key={t.localUid}
                  timeout={300}
                >
                  {(state) => {
                    return (
                      <TransitionWrapper state={state} isPreview={isPreview}>
                        <DraggableCard
                          data={t}
                          disableDrag={!('id' in t) || !canDrag}
                          isCountryCard={region !== 'global'}
                        >
                          <SidebarCard
                            disableDrag={!canDrag}
                            disableEdit={!canAddTactic}
                            previewUID={previewUID}
                            setPreviewUID={setPreviewUID}
                            itemData={t}
                            itemType="tactic"
                            isPreview={isPreview}
                            deletePreview={deletePreviewTactic}
                            setTouchPositioningView={setTouchPositioningView}
                            handleCreate={async (text: string) =>
                              await createCommercialTactic({
                                variables: {
                                  data: {
                                    competitiveAdvantageRow: +strategicImperativeId,
                                    text,
                                    keyStatement: keyStatementId,
                                    strategy: +strategyId,
                                    localUid: previewUID || uid(),
                                    region,
                                  },
                                },
                              })
                            }
                            handleDelete={async () =>
                              await deleteCommercialTactic({
                                variables: { id: 'id' in t ? t.id : -1 },
                                update: apolloDeleteHelper({
                                  responseField: 'commercialTacticDelete',
                                  query: CommercialTacticsDocument,
                                  queryVars: getKeyTacticsQueryVars(
                                    strategyId,
                                    strategicImperativeId
                                  ),
                                  queryName: 'commercialTactics',
                                }),
                              })
                            }
                            handleUpdate={async (text: string) => {
                              await updateCommercialTactic({
                                variables: {
                                  localUid: t.localUid,
                                  data: { text },
                                },
                                update: apolloUpdateHelper({
                                  responseField: 'commercialTacticUpdate',
                                  query: CommercialTacticsDocument,
                                  queryVars: getKeyTacticsQueryVars(
                                    strategyId,
                                    strategicImperativeId
                                  ),
                                  queryName: 'commercialTactics',
                                }),
                              });
                            }}
                          />
                        </DraggableCard>
                      </TransitionWrapper>
                    );
                  }}
                </Transition>
              );
            })}
          </TransitionGroup>
        </TransitionList>
      )}
    </Wrapper>
  );
};
