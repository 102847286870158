import { Navbar } from 'components/Navbar';
import { ButtonLabel } from 'components/shared';
import { PrivacyPolicy } from 'components/Terms/PrivacyPolicy';
import { TermsOfService } from 'components/Terms/TermsOfService';
import { colors } from 'constants/index';
import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import styled from 'styled-components';
import { ExcludedFeature } from 'types';
import { device } from 'utils/breakpoints';

const ActiveIcon = styled.div<{ active: boolean }>`
  width: 2px;
  height: 16px;
  background: ${colors.black};
  margin-right: 5px;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
`;

const NavItemWrapper = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  padding: 50px 20px 0;
  display: flex;
  gap: 120px;

  margin-top: 30px;

  @media ${device.tabletMax} {
    flex-direction: column;
    gap: 30px;
    margin-top: 0px;

    padding: 50px 15px 0;
  }
`;

const NavWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 30px;

  margin-top: 30px;

  flex-shrink: 0;
`;

interface Props {}

export const Terms = (props: Props) => {
  const history = useHistory();
  const location = useLocation();

  const termsOfServicesPath = '/terms/terms-of-service';
  const privacyPolicyPath = '/terms/privacy-policy';

  const navExcludeItems = [
    'drugLink',
    'filesLink',
    'strategyLink',
    'notifications',
    'navItems',
    'navContent',
    'sidebarTrigger',
    'profile',
  ] as ExcludedFeature[];

  return (
    <>
      <Navbar exclude={navExcludeItems} disableSecondary={true} />

      <Wrapper>
        <NavWrapper>
          <NavItemWrapper>
            <ActiveIcon
              active={location.pathname.startsWith(termsOfServicesPath)}
            />
            <ButtonLabel
              onClick={() => {
                history.push(termsOfServicesPath);
              }}
            >
              Terms of Service
            </ButtonLabel>
          </NavItemWrapper>

          <NavItemWrapper>
            <ActiveIcon
              active={location.pathname.startsWith(privacyPolicyPath)}
            />

            <ButtonLabel
              onClick={() => {
                history.push(privacyPolicyPath);
              }}
            >
              Privacy Policy
            </ButtonLabel>
          </NavItemWrapper>
        </NavWrapper>
        <Switch>
          <Route path={privacyPolicyPath}>
            <PrivacyPolicy />
          </Route>
          <Route path={termsOfServicesPath}>
            <TermsOfService />
          </Route>
          <Route path="/terms">
            <Redirect to={termsOfServicesPath} />
          </Route>
        </Switch>
      </Wrapper>
    </>
  );
};
