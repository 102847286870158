import React from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router';
import { Transition, TransitionGroup } from 'react-transition-group';
import { TransitionWrapper } from 'components/InsightGroup';
import {
  ReusableListEmptyState,
  TransitionList,
} from 'components/GridPages/GridPageSidebar';
import { DraggableCard } from 'components/GridPages/DraggableCard';
import { AccessStrategyPrioritiseSupportingMessageFragment } from 'data/graphql/generated';
import { SidebarCard } from 'components/shared/SidebarCard';

const Wrapper = styled.div``;

interface URLParams {
  drugId: string;
  strategyId: string;
}

interface Props {
  data: AccessStrategyPrioritiseSupportingMessageFragment[];
  isDragging: boolean;
  setTouchPositioningView: React.Dispatch<React.SetStateAction<string | null>>;
  permittedToEdit: boolean;
  substep: string;
}

export const SupportingMessageGroup: React.FC<Props> = ({
  data,
  setTouchPositioningView,
  permittedToEdit,
  substep,
}) => {
  const unplacedMessages = data.filter((m) => !m.x);
  const allMessagesOnGrid = !unplacedMessages.length;
  const { strategyId, drugId } = useParams<URLParams>();

  return (
    <Wrapper>
      {!!data.length ? (
        allMessagesOnGrid ? (
          <ReusableListEmptyState
            title="All positioned on the grid"
            subtitle="No more to prioritise"
          />
        ) : (
          <TransitionList>
            <TransitionGroup component={null}>
              {unplacedMessages?.map((t) => {
                const isPreview = false;

                return (
                  <Transition enter={true} exit={true} key={t.id} timeout={300}>
                    {(state) => {
                      return (
                        <TransitionWrapper state={state} isPreview={isPreview}>
                          <DraggableCard
                            data={t}
                            disableDrag={!permittedToEdit}
                          >
                            <SidebarCard
                              previewUID={'-1'}
                              setTouchPositioningView={setTouchPositioningView}
                              itemData={t}
                              itemType="supporting message"
                              isPreview={isPreview}
                              setPreviewUID={() => null}
                              deletePreview={() => null}
                              handleCreate={() => null}
                              handleUpdate={() => null}
                              handleDelete={() => null}
                              disableEdit
                              disableDrag={!permittedToEdit}
                            />
                          </DraggableCard>
                        </TransitionWrapper>
                      );
                    }}
                  </Transition>
                );
              })}
            </TransitionGroup>
          </TransitionList>
        )
      ) : (
        <ReusableListEmptyState
          title="None available"
          subtitle="Leads should add supporting messages in"
          link={`/d/${drugId}/strategy/${strategyId}/3_2/${substep}?support=true`}
          linkText="Core Value Messages"
        />
      )}
    </Wrapper>
  );
};
