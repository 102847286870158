import { PatientJourneyBlockFragment } from 'data/graphql/generated';
import { PatientJourneyContainerType } from '../../types';
import BulletList from './BulletList';

type Props = {
  cachedBlock: PatientJourneyBlockFragment | undefined;
  onUpdate: PatientJourneyContainerType['updateBlock'];
  canEdit: boolean;
};

export function CurrentPageContent({ cachedBlock, onUpdate, canEdit }: Props) {
  return (
    <>
      <BulletList
        title={'Key decisions'}
        placeholder={'Type a key decision'}
        list={cachedBlock?.keyDecisions}
        onUpdate={(val) => {
          if (cachedBlock) {
            onUpdate(cachedBlock, { keyDecisions: val });
          }
        }}
        readOnly={!canEdit}
      />
      <BulletList
        title={'Key needs'}
        placeholder={'Type a key need'}
        list={cachedBlock?.keyNeeds}
        onUpdate={(val) => {
          if (cachedBlock) {
            onUpdate(cachedBlock, { keyNeeds: val });
          }
        }}
        readOnly={!canEdit}
      />
      <BulletList
        title={'Positive patient emotions'}
        placeholder={'Type a positive emotion'}
        list={cachedBlock?.positivePatientEmotions}
        onUpdate={(val) => {
          if (cachedBlock) {
            onUpdate(cachedBlock, { positivePatientEmotions: val });
          }
        }}
        readOnly={!canEdit}
      />
      <BulletList
        title={'Negative patient emotions'}
        placeholder={'Type a negative emotion'}
        list={cachedBlock?.negativePatientEmotions}
        onUpdate={(val) => {
          if (cachedBlock) {
            onUpdate(cachedBlock, { negativePatientEmotions: val });
          }
        }}
        readOnly={!canEdit}
      />
      <BulletList
        title={'Problems to be solved for'}
        placeholder={'Type a problem'}
        list={cachedBlock?.problemsToBeSolved}
        onUpdate={(val) => {
          if (cachedBlock) {
            onUpdate(cachedBlock, { problemsToBeSolved: val });
          }
        }}
        readOnly={!canEdit}
      />
    </>
  );
}
