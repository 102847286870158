export const getFocusDisabledText = (isLead: boolean, isFormValid: boolean) => {
  if (!isLead) {
    return 'Only leads can edit';
  }

  if (!isFormValid) {
    return 'Complete all fields to focus';
  }

  return '';
};
