import { createContext } from 'react';

export type PatientJourneyPageContextType = {
  sidebarBlockId: string;
  openSidebar(v: number): void;
  closeSidebar(): void;
};

export const PatientJourneyPageContext = createContext<
  PatientJourneyPageContextType | undefined
>(undefined);
