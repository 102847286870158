import { Stakeholder } from 'data/graphql/generated';

export const stakeholderAliases: {
  [key in Stakeholder]: { [key in 'singular' | 'plural']: string };
} = {
  Patient: { singular: 'Patient', plural: 'Patients' },
  Healthcare: {
    singular: 'Healthcare Professional',
    plural: 'Healthcare Professionals',
  },
  Payor: { singular: 'Payor', plural: 'Payors' },
  Provider: { singular: 'Provider', plural: 'Providers' },
  Policymaker: { singular: 'Policymaker', plural: 'Policymakers' },
};
