import React from 'react';

import {
  CriticalMetricGoalFragment,
  CriticalMetricGoalUpdateInput,
} from 'data/graphql/generated';
import FocusDropdown from 'components/shared/FocusDropdown';

import { GoalFocusRationaleModal } from '../../../../shared-ui-goals/src';
import { GoalFrequencyDropdownOptionType } from '../../../../shared-ui-goals/src';
import { GoalInputDropdownOptionType } from '../../../../shared-ui-goals/src';

import { GoalField } from '../ui/GoalField';
import { GoalFrequencyField } from '../ui/GoalFrequencyField';
import { GoalInputField } from '../ui/GoalInputField';
import { GoalTargetField } from '../ui/GoalTargetField';
import { DeleteGoalsTableRow } from './DeleteGoalsTableRow';
import {
  MobileContainer,
  MobileRow,
  MobileRowOptionsContainer,
  StyledCollaboration,
} from './MobileGoalsTableRow.styles';
import { useGoalTableRowValidation } from '../util';

interface MobileGoalsTableRowProps {
  closeModal: () => void;
  modalSlideIdx: number | null;
  setModalSlideIdx: React.Dispatch<React.SetStateAction<number | null>>;
  setShowSingleSlide: React.Dispatch<React.SetStateAction<boolean>>;
  data: CriticalMetricGoalFragment;
  onUpdate: (data: CriticalMetricGoalUpdateInput) => Promise<void>;
  isFocused: boolean;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  toggleFocus: () => Promise<void>;
  autoFocus: boolean;
  isLead: boolean;
  isContributor: boolean;
  canUpdate: (value: string | number | null) => boolean;
  selectedFrequency: GoalFrequencyDropdownOptionType | null;
  setSelectedFrequency: React.Dispatch<
    React.SetStateAction<GoalFrequencyDropdownOptionType | null>
  >;
  selectedInput: GoalInputDropdownOptionType | null;
  setSelectedInput: React.Dispatch<
    React.SetStateAction<GoalInputDropdownOptionType | null>
  >;
  isDeleting: boolean;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete: () => void;
  onLockedFieldClick: () => void;
}

export const MobileGoalsTableRow: React.FC<MobileGoalsTableRowProps> = ({
  closeModal,
  modalSlideIdx,
  setModalSlideIdx,
  setShowSingleSlide,
  data,
  onUpdate,
  isFocused,
  setIsFocused,
  toggleFocus,
  autoFocus,
  isLead,
  isContributor,
  canUpdate,
  selectedFrequency,
  setSelectedFrequency,
  selectedInput,
  setSelectedInput,
  isDeleting,
  setIsDeleting,
  onDelete,
  onLockedFieldClick,
}) => {
  const {
    isAllowedToEdit,
    isFocusButtonDisabled,
    focusButtonDisabledText,
  } = useGoalTableRowValidation({
    isLead,
    isContributor,
    data: {
      goal: data.goal,
      target: data.target,
      frequency: data.frequency,
      input: data.input,
    },
  });

  return (
    <>
      <GoalFocusRationaleModal
        closeModal={closeModal}
        modalSlideIdx={modalSlideIdx}
        setModalSlideIdx={setModalSlideIdx}
        data={data}
        onUpdate={onUpdate}
        setIsFocused={setIsFocused}
      />
      <MobileContainer isFocused={isFocused}>
        <MobileRow>
          <MobileRowOptionsContainer>
            <FocusDropdown
              onClick={toggleFocus}
              checked={isFocused}
              setModalSlideIdx={setModalSlideIdx}
              setShowSingleSlide={setShowSingleSlide}
              rationaleText={data.focusRationale}
              disabledTooltipText={focusButtonDisabledText}
              disabled={isFocusButtonDisabled}
            />
            <DeleteGoalsTableRow
              canEdit={isAllowedToEdit}
              isDeleting={isDeleting}
              setIsDeleting={setIsDeleting}
              onDelete={onDelete}
            />
          </MobileRowOptionsContainer>

          <GoalField
            autoFocus={autoFocus}
            editEnabled={isAllowedToEdit}
            initialValue={data.goal || ''}
            canUpdate={canUpdate}
            onUpdate={onUpdate}
          />

          <GoalTargetField
            editEnabled={isAllowedToEdit}
            initialValue={data.target}
            canUpdate={canUpdate}
            onUpdate={onUpdate}
          />

          <GoalFrequencyField
            editEnabled={isAllowedToEdit}
            selectedFrequency={selectedFrequency}
            setSelectedFrequency={setSelectedFrequency}
            onUpdate={onUpdate}
            hasTracking={data.hasTracking}
            onLockedFieldClick={onLockedFieldClick}
          />

          <GoalInputField
            editEnabled={isAllowedToEdit}
            selectedInput={selectedInput}
            setSelectedInput={setSelectedInput}
            onUpdate={onUpdate}
            hasTracking={data.hasTracking}
            onLockedFieldClick={onLockedFieldClick}
          />

          <StyledCollaboration collaboration={data.collaboration} />
        </MobileRow>
      </MobileContainer>
    </>
  );
};
