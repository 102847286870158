import styled from 'styled-components/macro';

import { ButtonLabel } from 'components/shared';
import { colors } from 'constants/colors';
import { device } from 'utils/breakpoints';

const DeleteConfirmationWrapper = styled.div`
  display: flex;
  flex: none;
  width: 188px;
  height: 30px;

  background: ${colors.white};
  border: 0.5px solid ${colors.black30};
  border-radius: 5px;

  padding: 4px 20px;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  right: 0;
  z-index: 2;

  @media ${device.tabletMax} {
    right: 0;
  }
`;

interface DeleteConfirmationProps {
  isHidden: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
}

export const DeleteConfirmation = ({
  isHidden,
  onCancel,
  onConfirm,
}: DeleteConfirmationProps) => {
  if (isHidden) {
    return null;
  }

  return (
    <DeleteConfirmationWrapper>
      <ButtonLabel color={colors.black} onClick={onCancel}>
        Cancel
      </ButtonLabel>
      <ButtonLabel color={colors.red} onClick={onConfirm}>
        Delete row
      </ButtonLabel>
    </DeleteConfirmationWrapper>
  );
};
