import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';
import { GridDropZone, GridItem } from 'react-grid-dnd';
import {
  BodyNormal,
  BodySmall,
  Icon,
  Subtitle2,
} from 'components/shared';

import { colors } from 'constants/colors';
import Transition from 'react-transition-group/Transition';
import { device } from 'utils/breakpoints';
import { AddNoteButton } from './AddNoteButton';
import { uid } from 'uid';
import {
  PostItCard as Card,
  SubStep,
  Role,
  Stakeholder,
  Alignment,
  useAlignmentCreateMutation,
  Step,
  PostItCardType,
} from 'data/graphql/generated';
import { usePostItCardsPayload } from 'hooks/usePostItCards';
import { OutcomeCard } from 'components/OutcomeCard';
import { maxBy } from 'lodash';
import "./styles.css";


export const GroupWrapper = styled.div<{ offsetTopOverride: number | undefined }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  @media ${device.tabletMax} {
    border: none;
    border-radius: 0px;
  }
  margin-bottom: 15px;
  border-radius: 5px;
  transition-delay: 0.3s;
`;

const GroupHeader = styled.div<{ centeredTitle: boolean }>`
  margin-bottom: 15px;
  display: block;
  justify-content: space-between;
  align-items: center;
  flex-wrap: ${({ centeredTitle }) => (centeredTitle ? 'nowrap' : 'wrap')};

  @media ${device.tabletMin} {
    justify-content: flex-start;
  }
`;

export const GroupDelete = styled.div`
  z-index: 0;
  display: flex;
  justify-content: flex-end;
  padding: 0 15px 15px 15px;

  @media ${device.desktopMin} {
    padding: 0;
  }
`;

const NoteAdd = styled.div`
  height: 40px;
`;

const GroupContent = styled.div<{ empty: boolean }>`
  display: flex;
  background: transparent;
  height: calc(90% - 150px);
  overflow: ${({ empty }) => empty ? 'auto' : 'unset'};
  flex-direction: column;
  width: 100%;
  padding: 0px 15px 15px 15px;
`;

const GroupCards = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  transition: 0.3s;

  @media ${device.tabletMax} {
    margin: 0 auto;
  }
`;

const StyledComponent = styled.div`
  align-items: center;
  display: inline-flex;
  width: 100%;
  position: relative;

  margin-top: 0;
  &::before,
  &::after {
  margin-left: 5px;
  margin-right: 5px;
    content: '';
    flex: 1 1 0;
    height: 0;
    border: 1px solid #141427B2;
  }

  & > span {
    color: #141427B2;
    font-size: 14px;
    font-family: 'ABC Favorit Trial';
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
  }
`;


export interface SliderUpsertFnInput {
  postItGroup: number;
  score: number;
  region?: string;
  user?: number;
}
export type SliderUpsertFn = (input: SliderUpsertFnInput) => Promise<void>;

interface Props {
  group: {
    includes: Card[],
    excludes: Card[],
  };
  allGroupsCount: number;
  step: Step;
  subStep?: SubStep;
  stakeholder: Stakeholder;
  stakeholderDefinitionId: number;
  rows: number;
  userId: number;
  addCard: usePostItCardsPayload['createCard'];
  removeCard: usePostItCardsPayload['removeCard'];
  updateCard: usePostItCardsPayload['updateCard'];
  dragDisabled: boolean;
  setDragDisabled(dragDisabled: boolean): any;
  userRole: Role | null;
  selectedPostItGroupId: number;
  refetch(): void;
  loading: boolean;
  hasNoSelectedCompetitor: boolean;
  hasNoTargetPopulation: boolean;
  movingOrSwapping: boolean;
}

const EmptyWrapper = styled.div<{ noMargin: boolean }>`
  padding: 37px 0px ${({ noMargin }) => (noMargin ? '10px' : '38px')} 0px;
  text-align: center;
  position: unset;
  // top: 0px;
  // left: 0px;
  // right: 0px;
  // bottom: 0px;
  pointer-events: none;
  z-index: 0;
  border-radius: 5px;
  transition: 0.3s;
`;

const EmptyState: React.FC<{ text: string, hasIcon?: boolean, noMargin?: boolean }> = ({
  text, hasIcon = false, noMargin = false
}) => {
  return (
    <EmptyWrapper noMargin>
      {hasIcon && (
        <Icon
          name="GenericEmptyState"
          size={115}
          height={115}
          color="initial"
          style={{ margin: '0 auto' }}
        />
      )}
      <Subtitle2 color="#141427B2" style={{ fontSize: 20 }}>{text}</Subtitle2>
    </EmptyWrapper>
  );
};

export const OutcomesPostItGroupView: React.FC<Props> = ({
  group,
  step,
  subStep,
  hasNoSelectedCompetitor,
  hasNoTargetPopulation,
  stakeholder,
  selectedPostItGroupId,
  stakeholderDefinitionId,
  userId,
  rows,
  addCard,
  removeCard,
  refetch,
  updateCard,
  dragDisabled,
  setDragDisabled,
  userRole,
  loading,
  movingOrSwapping
}) => {
  const [createAlignment] = useAlignmentCreateMutation();
  const animId = useRef<any>();
  const mouseUp = useRef<boolean>(true);
  const [errMsg, setErrMsg] = useState({
    id: -1,
    message: '',
    cardHasImage: false,
  });
  // const [isDragging, setIsDragging] = useState(false);

  const cards = group

  const groupContainer = useRef<HTMLDivElement>(null!);
  const tooltipUID = uid();

  const mouseMoveHandler = (e: MouseEvent) => {
    if (animId.current) {
      window.cancelAnimationFrame(animId.current);
    }
    function scrollPage() {
      const dx = e.clientX;
      const dy = e.clientY;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const scrollRight = windowWidth - Math.abs(dx) < 50;
      const scrollLeft = windowWidth - Math.abs(dx) > windowWidth - 50;
      const scrollUp = windowHeight - Math.abs(dy) > windowHeight - 50;
      const scrollDown = windowHeight - Math.abs(dy) < 50;
      switch (true) {
        case scrollRight:
          window.scrollBy(10, 0);
          break;
        case scrollLeft:
          window.scrollBy(-10, -10);
          break;
        case scrollUp:
          window.scrollBy(0, -10);
          break;
        case scrollDown:
          window.scrollBy(0, 10);
          break;
      }
      if (!mouseUp.current) {
        animId.current = window.requestAnimationFrame(scrollPage);
      }
    }
    animId.current = window.requestAnimationFrame(scrollPage);
  };

  const scrollPageOnDragHandler = function (
    e:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.TouchEvent<HTMLDivElement>
  ) {
    const app = document.querySelector('.app') as HTMLElement;
    if (app) {
      mouseUp.current = false;
      document.addEventListener('mousemove', mouseMoveHandler);
      // document.addEventListener('touchmove', mouseMoveHandler);

      document.addEventListener(
        'mouseup',
        () => {
          mouseUp.current = true;

          window.cancelAnimationFrame(animId.current);
          document.removeEventListener('mousemove', mouseMoveHandler);
          // document.removeEventListener('touchmove', mouseMoveHandler);
        },
        { once: true }
      );
    }
  };

  const highestCardPos = maxBy(cards.includes, 'pos')?.pos || 0;

  return (
    <GroupWrapper
      offsetTopOverride={groupContainer.current?.offsetTop || 0}
      ref={groupContainer}
      className="postit-group cypress-postit-group"
    >
      {(hasNoTargetPopulation || hasNoSelectedCompetitor) ? (
        <GroupContent empty className="group-content">
          <GroupHeader centeredTitle style={{
            margin: 10,
          }}>
            <Transition timeout={100} component={null}>
              {(state) => (
                <div style={{ textAlign: 'center' }}>
                  <EmptyState
                    hasIcon
                    text="Cannot add outcomes yet"
                    noMargin
                  />
                  <BodySmall color={colors.greyDark}>Work can only begin when competitors are defined.</BodySmall>
                </div>

              )}
            </Transition>
          </GroupHeader>
        </GroupContent>
      ) : (
        <>
          <GroupHeader centeredTitle style={{ margin: 10 }}>
            <NoteAdd>
              <AddNoteButton
                group={cards as any}
                step={step}
                subStep={subStep}
                addCard={async (e) => {
                  const card = await addCard({
                    postItGroup: selectedPostItGroupId,
                    pos: (highestCardPos || 0) + 1000,
                    title: e.title,
                    ...(Step.Keystakeholders ? { step: Step.Keystakeholders, stakeholder, include: true } : undefined),
                    // competitor: +competitorId,
                    ...(stakeholderDefinitionId ? { stakeholderDefinition: Number(stakeholderDefinitionId) } : undefined),
                    type: PostItCardType.Plain,
                  });
                  const collaborationId = card?.data?.postItCardCreate?.collaboration?.id ?? null;
                  const strategyId = card?.data?.postItCardCreate?.strategyId ?? null;

                  if (collaborationId && strategyId) {
                    await createAlignment({
                      variables: {
                        data: { collaborationId, strategyId },
                      },
                      update: (cache, { data }) => {
                        cache.modify({
                          id: cache.identify({
                            __ref: `Collaboration:${data?.alignmentCreate.collaborationId}`,
                          }),
                          fields: {
                            alignmentCount(alignment) {
                              return alignment + 1;
                            },
                            alignments(
                              alignments: Pick<
                                Alignment, 'id' | 'createdAt' | 'collaborationId'
                              >[]
                            ) {
                              return [...alignments, data?.alignmentCreate];
                            },
                          },
                        });
                      },
                    });
                    await refetch();
                  }
                }}
                cardArgs={{
                  postItGroup: selectedPostItGroupId,
                  pos: highestCardPos ? highestCardPos + 1000 : 1000,
                  stakeholder,
                  title: '',
                }} />
            </NoteAdd>
          </GroupHeader>

          <GroupContent empty={!!cards} className={`group-content ${movingOrSwapping ? 'items-saving' : ''}`}>
            <GroupCards style={{ background: 'transparent' }}>
              <div style={{
                minHeight: 75,
                height: 'auto',
                display: 'block',
              }}
                className="container"
              >
                <StyledComponent>
                  <BodyNormal color="#141427B2">Includes</BodyNormal>
                </StyledComponent>

                <GridDropZone
                  id="includes"
                  boxesPerRow={1}
                  boxAmount={cards.includes.length || 0}
                  rowHeight={73}
                  className={!cards.includes.length ? 'outcome-dropzone__empty-state-include' : ''}
                >

                  {cards.includes.map((c, i) => {
                    return (
                      <GridItem
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          padding: "10px",
                        }}
                        key={c.id}
                      >
                        <BodyNormal style={{ marginRight: 10 }} color={colors.purple}>{i + 1}</BodyNormal>
                        {/* <div style={{padding: "10px"}}> */}
                        <OutcomeCard
                          tooltipUID={tooltipUID}
                          onMouseDown={(e) => {
                            scrollPageOnDragHandler(e);
                          }}
                          cardTitles={[...cards.includes, ...cards.excludes]}
                          card={c}
                          groupId={c.id}
                          onFocus={() => setDragDisabled(true)}
                          onBlur={() => { setDragDisabled(false); }}
                          userId={userId}
                          refetch={refetch}
                          userRole={userRole}
                          removeCard={removeCard}
                          updateCard={updateCard}
                          dragDisabled={dragDisabled}
                          uploading={false}
                          errMsg={errMsg}
                          setErrMsg={setErrMsg}
                        />
                      </GridItem>
                    );
                  })
                  }

                  {/* if there is no outcomes to include, show empty state */}
                  {cards.includes?.length === 0 && (
                    <EmptyState
                      text="No outcomes to include" />
                  )}

                </GridDropZone>
              </div>

              <GroupCards style={{ background: 'transparent' }}>
                <div 
                  style={{
                    minHeight: 200,
                    display: 'block',
                    height: 'auto',
                  }}
                  className="container"
                >

                  <StyledComponent>
                    <BodySmall color="#141427B2">Excludes</BodySmall>
                  </StyledComponent>

                  <GridDropZone
                    id="excludes"
                    boxesPerRow={1}
                    boxAmount={cards.excludes.length || 0}
                    rowHeight={73}
                    className={!cards.excludes.length ? 'outcome-dropzone__empty-state-exclude' : ''}
                  >
                    {cards.excludes.map((c, i) => {
                      return (
                        <GridItem
                          key={c.id}
                          style={{ padding: "10px" }}
                        >
                          <OutcomeCard
                            tooltipUID={tooltipUID}
                            onMouseDown={(e) => {
                              scrollPageOnDragHandler(e);
                            }}
                            cardTitles={[...cards.includes, ...cards.excludes]}
                            card={c}
                            refetch={refetch}
                            groupId={c.id}
                            onFocus={() => setDragDisabled(true)}
                            onBlur={() => setDragDisabled(false)}
                            userId={userId}
                            userRole={userRole}
                            removeCard={removeCard}
                            updateCard={updateCard}
                            dragDisabled={dragDisabled}
                            uploading={false}
                            errMsg={errMsg}
                            setErrMsg={setErrMsg} 
                          />
                        </GridItem>
                      );
                    })}

                    {cards.excludes.length === 0 && (
                      <EmptyState text="No outcomes left out" />
                    )}
                  </GridDropZone>
                </div>
              </GroupCards>
            </GroupCards>
          </GroupContent>
        </>
      )}

    </GroupWrapper >
  );
};
