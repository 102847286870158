import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { Page, Wrapper } from 'components/Page';
import { PostItsEmpty } from 'components/PostItsEmpty';
import { RiskMitigationIdeasModal } from 'components/RiskMitigation/RiskMitigationIdeasModal';
import { RiskMitigationNav } from 'components/RiskMitigation/RiskMitigationNav';
import {
  BodyNormal,
  BodySmall,
  ContributionTab,
  StepHeaderBar,
  Subtitle2,
  TabGroup,
} from 'components/shared';
import { ImperativeSummarySidebar } from 'components/shared/ImperativeSummarySidebar';
import { colors, polling } from 'constants/index';
import { useAuthContext } from 'contexts/AuthContext';
import {
  SolutionFragment,
  Sort,
  SubStep,
  SuccessConditionFragment,
  useCompetitiveAdvantageRowsQuery,
  useSolutionDeleteMutation,
  useSolutionUpsertMutation,
  useStrategyQuery,
  useSuccessConditionsQuery,
  useSuccessConditionUpdateMutation,
} from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import useGetElementTop from 'hooks/useGetElementTop';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { verifyUserRole } from 'utils/verifyUserRole';
import { RiskTables } from './../components/RiskMitigation/RiskTables';
import { useGlobalContext } from 'contexts/GlobalContext';

export const PageWrapperForSideBar = styled.div<{
  allowSidebarOverlay: boolean;
}>`
  display: flex;
  position: relative;

  max-width: ${({ allowSidebarOverlay }) =>
    allowSidebarOverlay ? 'calc(100% - 300px)' : '100%'};
  overflow: hidden;
`;
const desktopNavHeight = 110;
const mobileNavHeight = 160;
const StyledImperativeSummarySidebar = styled(ImperativeSummarySidebar)<{
  allowSidebarOverlay: boolean;
  stepHeaderHeight: number | undefined;
}>`
  position: static;
  height: ${({ stepHeaderHeight }) =>
    `calc(100vh - ${mobileNavHeight - (20)}px)`};

  @media ${device.desktopMin} {
    height: ${({ stepHeaderHeight }) =>
      `calc(100vh - ${mobileNavHeight - (20)}px)`};
  }
  @media ${device.tabletMin} {
    position: ${({ allowSidebarOverlay }) => {
      return allowSidebarOverlay ? 'fixed' : 'static';
    }};
    top: ${({ allowSidebarOverlay }) => {
      return (allowSidebarOverlay ? desktopNavHeight : 0) + 'px';
    }};

    min-height: auto;
  }

  @media ${device.mobile} {
    position: fixed;
    top: 160px;
  }
`;

const StyledStepHeaderBar = styled(StepHeaderBar)`
  min-height: auto;
  @media ${device.tabletMax} {
    margin-top: 160px;
  }
`;

export const PageWrapper = styled(Page)`
  width: 100%;
  min-width: 181px;
  ${Wrapper} {
    min-width: 451px;
    @media ${device.mobile} {
      min-width: 100%;
    }
  }
  padding: 0px 0 60px;
  overflow: visible;

  margin-top: 15px;

  @media ${device.tabletMin} {
    margin-top: 15px;

    ${Wrapper} {
      width: auto;
      padding: 0 15px;
    }
  }

  @media ${device.desktopMin} {
    margin-top: 20px;
    ${Wrapper} {
      padding: 0 20px;
    }
  }
`;

export type PreviewAndDBDataSolutionType = Partial<
  Omit<
    SolutionFragment,
    | 'localUid'
    | 'solutionText'
    | 'timingStart'
    | 'timingEnd'
    | 'focused'
    | 'dueDate'
    | 'responsibility'
    | 'budget'
    | 'lastUpdated'
    | 'createdAt'
    | 'lastUpdated'
    | 'createdAt'
    | 'strategyId'
    | 'competitiveAdvantageRowId'
    | 'successConditionId'
  >
> &
  Pick<
    SolutionFragment,
    | 'localUid'
    | 'solutionText'
    | 'timingStart'
    | 'timingEnd'
    | 'focused'
    | 'dueDate'
    | 'responsibility'
    | 'budget'
    | 'lastUpdated'
    | 'createdAt'
    | 'lastUpdated'
    | 'createdAt'
    | 'strategyId'
    | 'competitiveAdvantageRowId'
    | 'successConditionId'
  >;

// This avoids the need to list out every parameter in the preview data type as some are optional
export type LocalDataType = (SuccessConditionFragment & {
  solutions: PreviewAndDBDataSolutionType[];
})[];
interface URLParams {
  drugId: string;
  strategyId: string;
  strategicImperativeId: string;
}

export const RiskMitigationIdeas = () => {
  const { drugId, strategyId, strategicImperativeId }: URLParams = useParams();
  const history = useHistory();
  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);
  const isDesktop = useDesktop();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const sidebarDesktopRef = useRef<HTMLDivElement | null>(null);
  const stepHeaderBarRef = useRef<HTMLDivElement | null>(null);
  const desktopWrapperTop = useGetElementTop(sidebarDesktopRef);

  const [showAddModal, setShowAddModal] = useState(false);
  const [addingSolution, setAddingSolution] = useState(false);

   // handle global context path
   const [ strategicImperativeIdGlobal, setStrategicImperativeId ] = useGlobalContext(
    'strategicImperativeId'
  );

  const {
    data: strategyData,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
    loading: strategyLoading,
    error: strategyError,
  } = useStrategyQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'network-only',
  });

  const {
    data: competitiveAdvantageRowsData,
    error: competitiveAdvantageRowsError,
    loading: competitiveAdvantageRowsLoading,
    startPolling: startCompetitiveAdvantageRowsPolling,
    stopPolling: stopCompetitiveAdvantageRowsPolling,
  } = useCompetitiveAdvantageRowsQuery({
    variables: {
      orderBy: {
        idx: Sort.Desc,
      },
      where: {
        strategyId: +strategyId,
        focus: true,
      },
      include: {
        strategicPossibility: true,
        successConditions: true,
        patientFlowBlockStarStakeholderDefinitions: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  const competitiveAdvantageRows =
    competitiveAdvantageRowsData?.competitiveAdvantageRows?.items;

  const activeImperative = useMemo(
    () =>
      competitiveAdvantageRows?.find(
        (row) => row.id === +strategicImperativeId
      ),
    [competitiveAdvantageRows, strategicImperativeId]
  );

  const successConditionsQueryVars = {
    where: {
      strategyId: Number(strategyId),
      /* Because these were created against strategic possibilities rather than competitiveAdvantageRows,
       we need to use this method to match it against the correct strategicPossibility */
      strategicPossibilityId: !!activeImperative?.strategicPossibilityId
        ? activeImperative?.strategicPossibilityId
        : undefined,
    },
    orderBy: { createdAt: Sort.Desc },
    include: { solutions: true },
  };

  const {
    data: successConditionsData,
    loading: successConditionsLoading,
    error: successConditionsError,
    startPolling: startSuccessConditionsStartPolling,
    stopPolling: stopSuccessConditionsStopPolling,
  } = useSuccessConditionsQuery({
    variables: {
      ...successConditionsQueryVars,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const [updateSuccessCondition] = useSuccessConditionUpdateMutation();

  const successConditions = useMemo(
    () => successConditionsData?.successConditions?.items || [],
    [successConditionsData?.successConditions?.items]
  ) as (SuccessConditionFragment & { solutions: SolutionFragment[] })[];

  const [upsertSolution] = useSolutionUpsertMutation();
  const [deleteSolution] = useSolutionDeleteMutation();

  const [tempSolution, setTempSolution] = useState<
    PreviewAndDBDataSolutionType | undefined
  >(undefined);

  const [localData, setLocalData] = useState<LocalDataType>(successConditions);

  const addPreview = (
    localUid: string,
    successConditionId: number,
    strategyId: number,
    competitiveAdvantageRowId: number
  ) => {
    setTempSolution({
      localUid,
      successConditionId,
      solutionText: '',
      dueDate: [],
      focused: false,
      timingStart: [],
      timingEnd: [],
      budget: null,
      responsibility: '',
      lastUpdated: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      strategyId,
      competitiveAdvantageRowId,
      successCondition: null,
    });
  };

  useEffect(() => {
    // Default url
    if (strategicImperativeIdGlobal) {
      history.push(
        `/d/${drugId}/strategy/${strategyId}/4_4/ideas${
          '/' + strategicImperativeIdGlobal
        }`
      );
    } else if (!strategicImperativeId && !!competitiveAdvantageRows?.[0]?.id) {
      history.push(
        `/d/${drugId}/strategy/${strategyId}/4_4/ideas${
          '/' + competitiveAdvantageRows[0].id
        }`
      );
    }
  }, [
    competitiveAdvantageRows,
    drugId,
    history,
    strategicImperativeId,
    strategicImperativeIdGlobal,
    strategyId,
  ]);

  useEffect(() => {
    // Update local data with temp and db data
    const previewInDB = successConditions?.some((item) =>
      item?.solutions?.some(
        (solution) => solution.localUid === tempSolution?.localUid
      )
    );

    if (previewInDB) {
      setTempSolution(undefined);
      setLocalData(successConditions);
    } else {
      // Update Data
      if (tempSolution) {
        // update data but keep tempSolution in there
        const localSuccessConditions = successConditions.map((cond) => {
          if (cond.id === tempSolution.successConditionId) {
            return { ...cond, solutions: [...cond.solutions, tempSolution] };
          } else return cond;
        }) as LocalDataType;

        setLocalData(localSuccessConditions);
      } else {
        setLocalData(successConditions);
      }
    }
  }, [successConditions, tempSolution]);

  const noCompetitiveAdvantageRows =
    !competitiveAdvantageRowsLoading && !competitiveAdvantageRows?.length;

  const riskGroups = useMemo(() => localData.filter((sc) => !!sc.isRiskGroup), [
    localData,
  ]);

  const noSuccessConditions =
    noCompetitiveAdvantageRows ||
    (!successConditionsLoading && !riskGroups.length);

  useEffect(() => {
    startStrategyPolling(polling.default);
    startCompetitiveAdvantageRowsPolling(polling.default);
    startSuccessConditionsStartPolling(polling.default);
    return () => {
      stopStrategyPolling();
      stopCompetitiveAdvantageRowsPolling();
      stopSuccessConditionsStopPolling();
    };
  }, [
    startCompetitiveAdvantageRowsPolling,
    startStrategyPolling,
    startSuccessConditionsStartPolling,
    stopCompetitiveAdvantageRowsPolling,
    stopStrategyPolling,
    stopSuccessConditionsStopPolling,
  ]);

  return (
    <>
      <RiskMitigationNav subStep={SubStep.Ideas} />

      <StyledStepHeaderBar ref={stepHeaderBarRef}>
        <div style={{ overflow: 'hidden' }}>
          <Subtitle2 style={{ marginTop: 15, marginBottom: 5 }}>
            Consider how to mitigate the key risks
          </Subtitle2>
          <BodyNormal color={colors.greyDark} style={{ marginBottom: 20 }}>
            Add solutions to the risks shortlisted by Leads. Leads will choose
            which solutions to focus on.
          </BodyNormal>
          {!!competitiveAdvantageRows &&
          competitiveAdvantageRows?.length > 0 ? (
            <TabGroup componentName="ContributionTab">
              <>
                {competitiveAdvantageRows?.map((c) => {
                  return (
                    <ContributionTab
                      key={c.id}
                      id={`contribution-tab-${c.strategicPossibilityId}`}
                      secondary=""
                      text={c.strategicPossibility?.name || ''}
                      active={
                        +strategicImperativeId === c.id
                      }
                      onClick={() => {
                        history.push(
                          `/d/${drugId}/strategy/${strategyId}/4_4/ideas${
                            '/' + c.id
                          }`
                        );
                        setStrategicImperativeId(c.id)
                      }}
                      sidebarOpen={sidebarOpen}
                      displaySummary={() => setSidebarOpen(true)}
                      revealTruncatedText={false}
                    />
                  );
                })}
              </>
            </TabGroup>
          ) : null}
        </div>
      </StyledStepHeaderBar>

      <ErrorWrapper
        isLoading={
          successConditionsLoading ||
          competitiveAdvantageRowsLoading ||
          strategyLoading
        }
        errors={[
          competitiveAdvantageRowsError,
          successConditionsError,
          strategyError,
        ]}
        dataMissing={false}
      >
        <PageWrapperForSideBar
          allowSidebarOverlay={
            desktopWrapperTop < desktopNavHeight && sidebarOpen
          }
          ref={sidebarDesktopRef}
        >
          <PageWrapper paddingTop={false} fullWidthMobile>
            <RiskMitigationIdeasModal
              drugId={drugId}
              strategicImperativeId={strategicImperativeId}
              strategyId={strategyId}
              hideModal={() => setShowAddModal(false)}
              isVisible={showAddModal}
              successConditions={successConditions || []}
            />

            {noCompetitiveAdvantageRows ? (
              <PostItsEmpty title="Nothing to discuss">
                <BodySmall
                  color={colors.greyDark}
                  style={{ display: 'inline' }}
                >
                  Leads must first choose Strategic Imperatives to focus on in{' '}
                </BodySmall>
                <Link
                  to={`/d/${drugId}/strategy/${strategyId}/3_3`}
                  style={{
                    color: colors.greyDark,
                    display: 'inline',
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  3.3 Strategic Imperatives
                </Link>
              </PostItsEmpty>
            ) : (
              <RiskTables
                setTempSolution={setTempSolution}
                currency={strategyData?.strategy?.currency}
                noCompetitiveAdvantageRows={noCompetitiveAdvantageRows}
                strategyId={strategyId}
                strategicImperativeId={activeImperative?.id}
                noSuccessConditions={noSuccessConditions}
                riskGroups={riskGroups}
                isDesktop={isDesktop}
                updateSuccessCondition={updateSuccessCondition}
                tempSolution={tempSolution}
                isLead={isLead}
                deleteSolution={deleteSolution}
                upsertSolution={upsertSolution}
                setAddingSolution={setAddingSolution}
                addingSolution={addingSolution}
                addPreview={addPreview}
                setShowAddModal={setShowAddModal}
                sidebarOpen={sidebarOpen}
              />
            )}
          </PageWrapper>
          <StyledImperativeSummarySidebar
            stepHeaderHeight={stepHeaderBarRef.current?.offsetHeight}
            activeImperative={activeImperative?.strategicPossibility}
            activeImperativeId={activeImperative?.strategicPossibilityId}
            sidebarOpen={sidebarOpen && !!strategicImperativeId}
            setSidebarOpen={setSidebarOpen}
            desktopWrapperTop={0}
            totalNavHeight={0}
            keyIssue={activeImperative?.text}
            allowSidebarOverlay={
              desktopWrapperTop < desktopNavHeight && sidebarOpen
            }
            preventDocumentStyles
          />
        </PageWrapperForSideBar>
      </ErrorWrapper>
    </>
  );
};
