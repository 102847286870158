import { ButtonLabel, Icon } from 'components/shared';
import { colors } from 'constants/index';
import React from 'react';
import styled from 'styled-components';

const Dropdown = styled.div<{ showDropdown: boolean }>`
  width: 168px;
  padding: 5px;

  background: ${colors.white};
  border-radius: 5px;
  border: 0.5px solid rgba(20, 20, 39, 0.3);
  position: absolute;
  top: -5.5px;
  left: -7px;

  display: ${({ showDropdown }) => (showDropdown ? 'flex' : 'none')};
  flex-direction: column;

  > * + * {
    margin-top: 5px;
  }
`;

const DropDownItem = styled.div<{ isActive?: boolean }>`
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  > * + * {
    margin-left: 5px;
  }
  cursor: pointer;
  border: 1.5px solid
    ${({ isActive }) => (isActive ? colors.purple : 'transparant')};
  border-radius: 5px;
`;

interface Props {
  showDropdown: boolean;
  setShowAsPercentage: React.Dispatch<React.SetStateAction<boolean>>;
  showAsPercentage: boolean;
  setShowDropdown: (value: React.SetStateAction<boolean>) => void;
  innerRef?: React.MutableRefObject<null>;
  className?: string;
  labels: string[];
}

export const PercentageToggleDropdown = ({
  showDropdown,
  setShowDropdown,
  showAsPercentage,
  setShowAsPercentage,
  innerRef,
  className,
  labels,
}: Props) => {
  return (
    <Dropdown ref={innerRef} className={className} showDropdown={showDropdown}>
      <DropDownItem
        className={'cypress-show-number'}
        isActive={!showAsPercentage}
        onClick={() => {
          setShowAsPercentage(false);
          setShowDropdown(false);
        }}
      >
        <Icon size={30} name="Hash" color={colors.black} />
        <ButtonLabel>{labels[0]}</ButtonLabel>
      </DropDownItem>
      <DropDownItem
        className={'cypress-show-percentage'}
        isActive={showAsPercentage}
        onClick={() => {
          setShowAsPercentage(true);
          setShowDropdown(false);
        }}
      >
        <Icon size={30} name="Percent" color={colors.black} />
        <ButtonLabel>{labels[1]}</ButtonLabel>
      </DropDownItem>
    </Dropdown>
  );
};
