import { KeyEventFragment } from 'data/graphql/generated';
import differenceInCalendarQuarters from 'date-fns/differenceInCalendarQuarters';
import differenceInCalendarYears from 'date-fns/differenceInCalendarYears';
import { RowProps } from './types';
import {
  YEAR_COLUMN_WIDTH,
  QUARTER_COLUMN_WIDTH,
  DIAMOND_WIDTH,
  MONTHS_PER_QUARTER,
} from './constants';
import { colors } from 'constants/index';

const calenderIconWidth = 24;

export const getKeyEventLeftOffset = ({
  event,
  minDate,
  quarterAreaWidth,
  YEAR_COLUMN_WIDTH,
  QUARTER_COLUMN_WIDTH,
  leftSidePadding,
}: {
  event: [string, KeyEventFragment[]];
  minDate: number;
  quarterAreaWidth: number;
  YEAR_COLUMN_WIDTH: number;
  QUARTER_COLUMN_WIDTH: number;
  leftSidePadding: number;
}) => {
  const eventDate = Number(event[0]);
  const month = new Date(eventDate).getMonth();

  const startPosition =
    differenceInCalendarQuarters(eventDate, minDate) * quarterAreaWidth;

  // offset for the month within the quarter area
  const startPositionQuarterOffset = Math.max(
    ((month % MONTHS_PER_QUARTER) / MONTHS_PER_QUARTER) * quarterAreaWidth,
    0
  );

  // These add offsets for the pixels occupied by the columns
  const startPositionYearColumnOffset =
    (differenceInCalendarYears(eventDate, minDate) + 1) * YEAR_COLUMN_WIDTH;

  const startPositionQuarterColumnOffset =
    Math.max(
      differenceInCalendarQuarters(eventDate, minDate) * QUARTER_COLUMN_WIDTH,
      0
    ) - differenceInCalendarYears(eventDate, minDate);

  // Center the icon on the year Column
  const centerOnYearColumn = month === 0 ? -2.5 : 0;

  return (
    leftSidePadding +
    startPosition +
    startPositionYearColumnOffset +
    centerOnYearColumn +
    startPositionQuarterColumnOffset +
    startPositionQuarterOffset -
    calenderIconWidth / 2
  );
};

export function getImperativePositions(
  tactic: RowProps,
  getDateYear: (date: string) => number,
  getDateMonth: (date: string) => number,
  minDate: number,
  quarterColumnWidth: number
) {
  const MONTHS_PER_QUARTER = 3;

  const endMonth =
    typeof tactic.timingEnd[0] === 'number'
      ? tactic.timingEnd[0]
      : tactic.timingStart[0];

  const dueDate = new Date(
    getDateYear(tactic.dueDate.join('')),
    getDateMonth(tactic.dueDate.join('')),
    1
  );

  const dueDatePosition =
    differenceInCalendarQuarters(dueDate, minDate) * quarterColumnWidth;

  const dueDateQuarterOffset = Math.max(
    ((tactic.dueDate[0] % MONTHS_PER_QUARTER) / MONTHS_PER_QUARTER) * 100,
    0
  );

  const dueDateYearColumnOffset =
    (differenceInCalendarYears(dueDate, minDate) + 1) * YEAR_COLUMN_WIDTH;

  const dueDateQuarterColumnOffset =
    Math.max(
      differenceInCalendarQuarters(dueDate, minDate) + 1 * QUARTER_COLUMN_WIDTH,
      0
    ) - differenceInCalendarYears(dueDate, minDate);

  const startDate = new Date(
    getDateYear(tactic.timingStart.join('')),
    getDateMonth(tactic.timingStart.join('')),
    1
  );

  const endDate = tactic.timingEnd.length
    ? new Date(
        getDateYear(tactic.timingEnd.join('')),
        getDateMonth(tactic.timingEnd.join('')),
        1
      )
    : startDate;

  const duration =
    Math.max(differenceInCalendarQuarters(endDate, startDate), 0) *
    quarterColumnWidth;

  const startPosition =
    startDate.getTime() === minDate
      ? 0
      : differenceInCalendarQuarters(startDate, minDate) * quarterColumnWidth;

  const startPositionQuarterOffset =
    ((tactic.timingStart[0] % MONTHS_PER_QUARTER) / MONTHS_PER_QUARTER) * 100;

  const endQuarterOffset =
    (((endMonth % MONTHS_PER_QUARTER) + 1) / MONTHS_PER_QUARTER) * 100;

  // These add the pixels occupied by the columns to the positions
  const startPositionYearColumnOffset =
    (differenceInCalendarYears(startDate, minDate) + 1) * YEAR_COLUMN_WIDTH;

  const startPositionQuarterColumnOffset =
    Math.max(
      differenceInCalendarQuarters(startDate, minDate) * QUARTER_COLUMN_WIDTH,
      0
    ) - differenceInCalendarYears(startDate, minDate);

  const durationQuarterOffset =
    Math.max(
      differenceInCalendarQuarters(endDate, startDate) * QUARTER_COLUMN_WIDTH,
      0
    ) - differenceInCalendarYears(endDate, startDate);

  const durationYearOffset = Math.max(
    differenceInCalendarYears(endDate, startDate) * YEAR_COLUMN_WIDTH,
    0
  );

  return {
    durationWidth:
      duration +
      durationQuarterOffset +
      durationYearOffset +
      endQuarterOffset -
      startPositionQuarterOffset,
    durationTransformX:
      startPositionQuarterColumnOffset +
      startPositionQuarterOffset +
      startPositionYearColumnOffset +
      startPosition,

    // If it's landing on a year column, adjust its position so it sits in the middle of the column
    dueDateLeft:
      (dueDateQuarterColumnOffset > 2
        ? dueDateYearColumnOffset + 1.5
        : dueDateYearColumnOffset) +
      dueDatePosition +
      dueDateQuarterColumnOffset +
      dueDateQuarterOffset -
      Math.floor(DIAMOND_WIDTH / 2),

    tacticTextTranslateX:
      startPositionQuarterColumnOffset +
      startPositionQuarterOffset +
      startPositionYearColumnOffset +
      startPosition,
  };
}

export const getBackgroundColumnStyle = ({
  yearGap,
  quarterGap,
}: {
  yearGap: number;
  quarterGap: number;
}) => `repeating-linear-gradient(90deg, transparent 0 ${
  // Thin lines
  yearGap
}px,
   ${colors.black05a} ${yearGap}px ${yearGap + QUARTER_COLUMN_WIDTH}px, 
   transparent ${yearGap + QUARTER_COLUMN_WIDTH}px ${yearGap + quarterGap}px, 
   ${colors.black05a} ${yearGap + quarterGap}px  ${
  yearGap + quarterGap + QUARTER_COLUMN_WIDTH
}px, 
   transparent ${yearGap + quarterGap + QUARTER_COLUMN_WIDTH}px ${
  yearGap + quarterGap + quarterGap
}px, 
   ${colors.black05a} ${yearGap + quarterGap + quarterGap}px ${
  yearGap + quarterGap + quarterGap + QUARTER_COLUMN_WIDTH
}px, 
   transparent ${yearGap + quarterGap + quarterGap + QUARTER_COLUMN_WIDTH}px ${
  yearGap + quarterGap + quarterGap + quarterGap
}px ),

repeating-linear-gradient(90deg, ${
  // Thick lines
  colors.black05a
} 0 ${YEAR_COLUMN_WIDTH}px, transparent ${YEAR_COLUMN_WIDTH}px ${
  yearGap + quarterGap + quarterGap + quarterGap
}px)`;
