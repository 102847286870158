import { PostItCardType } from 'data/graphql/generated';

export function getTitleFromNoteType(level: PostItCardType): string {
  switch (level) {
    case 'advantage':
      return 'Advantage';
    case 'limitation':
      return 'Limitation';
    case 'trigger':
      return 'Trigger';
    case 'unexpected':
      return 'Unexpected behaviour';
    case 'adaptation':
      return 'Adaptation';
    case 'pattern':
      return 'Pattern arising';
    case 'leadIndicator':
      return 'Lead Indicator';
    case 'lagIndicator':
      return 'Lag Indicator';
    case PostItCardType.Hurdle:
      return 'Hurdle';
    case PostItCardType.Gap:
      return 'Gap';
    case PostItCardType.Thoughts:
      return 'Thoughts';
    case PostItCardType.Feelings:
      return 'Feelings';
    default:
      return 'Other';
  }
}
