import { Content, ModalLarge } from 'components/ModalLarge';
import { Subtitle2, Caption, BodySmall, CopyIcon } from 'components/shared';
import { Tooltip, TooltipCSS } from 'components/shared/Tooltip';
import { colors, exampleTactics } from 'constants/index';
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { isTouchDevice } from 'utils/isTouchDevice';

const StyledCopyIcon = styled(CopyIcon)`
  position: relative;
  z-index: 0;
`;

const StyledModalLarge = styled(ModalLarge)`
  ${Content} {
    max-width: fit-content;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  ${StyledCopyIcon} {
    opacity: 0;
    transition: opacity 0.3s;
  }
  :hover {
    ${StyledCopyIcon} {
      opacity: 1;
    }
  }
`;

const PurpleBullet = styled.div`
  border-radius: 500px;
  width: 15px;
  height: 15px;
  border: 5px solid ${colors.purple};
`;

const TacticExamplesWrapper = styled.div`
  border: 1px solid ${colors.black10a};
  border-radius: 5px;
  padding: 15px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ModalContentWrapper = styled.div`
  width: 100%;
  /* min-width: 435px; */
  display: flex;
  flex-direction: column;
`;

type Props = {
  visible: boolean;
  handleClose: () => void;
};

export const AccessStrategyTacticsExamplesModal = ({
  visible,
  handleClose,
}: Props) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });
  return (
    <>
      <Tooltip place="right" multiline effect="solid" id={'copy-icon'} />

      <StyledModalLarge
        size="small"
        headerColor={colors.cream}
        handleClose={handleClose}
        visible={visible}
      >
        <div
          style={{
            padding: 40,
          }}
        >
          <ModalContentWrapper>
            <Subtitle2 style={{ marginBottom: 5, textAlign: 'center' }}>
              Access initiative examples
            </Subtitle2>
            <Caption
              color={colors.greyDark}
              style={{ marginBottom: 15, textAlign: 'center' }}
            >
              Access initiatives might include deployment of the following
            </Caption>
            <TacticExamplesWrapper>
              {exampleTactics.map((example) => (
                <ExampleRow key={example} example={example} />
              ))}
            </TacticExamplesWrapper>
          </ModalContentWrapper>
        </div>
      </StyledModalLarge>
    </>
  );
};

type ExampleProps = {
  example: string;
};

const ExampleRow = ({ example }: ExampleProps) => {
  const isTouch = isTouchDevice();

  const [tooltipText, setTooltipText] = useState('Copy to clipboard');
  const [forceTooltip, setForceTooltip] = useState<boolean | undefined>(
    undefined
  );

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    // On touch devices, show tooltip for 1 second after tapping
    if (isTouch && forceTooltip) {
      timeout = setTimeout(() => {
        setForceTooltip(false);
      }, 1000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isTouch, forceTooltip]);

  return (
    <Row>
      <PurpleBullet />
      <BodySmall style={{ display: 'inline' }} color={colors.greyDark}>
        {example}
      </BodySmall>
      <TooltipCSS
        overrideVisible={forceTooltip}
        style={{
          position: 'relative',
          transform: 'translate3d(0, 0, 0)',
        }}
        text={tooltipText}
        direction="top"
        tooltipStyle={{
          top: 33,
          transform: 'translate3d(-50%, 0, 0)',
          left: '50%',
        }}
      >
        <div
          onMouseLeave={() => {
            if (tooltipText === 'Copied') setTooltipText('Copy to clipboard');
          }}
          onClick={() => {
            handleCopy(example);
            setTooltipText('Copied');

            if (isTouch) {
              setForceTooltip(true);
            }
          }}
        >
          <StyledCopyIcon
            style={isTouch ? { opacity: 1 } : undefined}
            onClick={() => {}}
            size={20}
          />
        </div>
      </TooltipCSS>
    </Row>
  );
};
