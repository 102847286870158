import { TrackedGoalPreview } from './TrackedGoalPreview';
import {
  CriticalMetricGoalFrequency,
  CriticalMetricGoalInput,
} from 'data/graphql/generated';
import { getCurrentPeriodByFrequencyAndOffset } from '../util-goal-tracking';

export const TrackedGoals = ({
  goals,
}: {
  goals: {
    id: string;
    goal: string;
    target: number;
    input: CriticalMetricGoalInput;
    frequency: CriticalMetricGoalFrequency;
    averageProgress?: number;
    numberOfCountriesWithTracking: number;
    supportedCountries: string[];
    isActive: boolean;
    onClick: () => void;
  }[];
}) => {
  return (
    <>
      {goals.map(
        ({
          id,
          goal,
          target,
          input,
          frequency,
          averageProgress,
          onClick,
          isActive,
          supportedCountries,
          numberOfCountriesWithTracking,
        }) => (
          <TrackedGoalPreview
            key={id}
            goal={goal}
            progress={averageProgress}
            target={target}
            goalInput={input}
            period={
              getCurrentPeriodByFrequencyAndOffset(frequency, -1).formatted
            }
            isActive={isActive}
            onClick={onClick}
            numberOfSupportedCountries={supportedCountries.length}
            numberOfCountriesWithTracking={numberOfCountriesWithTracking}
          />
        )
      )}
    </>
  );
};
