import styled from 'styled-components/macro';
import { CountryConstants } from 'types';
import { colors } from 'constants/colors';
import { CriticalMetricGoalInput } from 'data/graphql/generated';
import { GoalPeriodTracking } from './GoalPeriodTracking';
import orderBy from 'lodash/orderBy';
import { device } from 'utils/breakpoints';

const ProgressCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 15px;

  width: calc(100% - 30px);
  margin: 0 15px;
  overflow: auto;

  background: ${colors.white};
  border: 1px solid ${colors.greyMedium};
  border-radius: 5px;

  @media ${device.tabletMin} {
    width: 400px;
  }
`;

interface GoalPeriodTrackingsProps {
  isLead: boolean;
  goalTarget: number;
  goalInput: CriticalMetricGoalInput;
  trackings: { region: string; average: number }[];
  supportedCountries: string[];
}

export const GoalPeriodTrackings = ({
  isLead,
  goalInput,
  goalTarget,
  trackings,
  supportedCountries,
}: GoalPeriodTrackingsProps) => {
  const countriesToFilter =
    goalInput === CriticalMetricGoalInput.PerCountry
      ? supportedCountries
      : [CountryConstants.GLOBAL];

  const orderedTrackings = orderBy(trackings, ['average', 'region'], ['desc']);
  const missingTrackings = orderBy(countriesToFilter, undefined, 'asc').filter(
    (sc) => !trackings.some((t) => t.region === sc)
  );

  return (
    <ProgressCard>
      {orderedTrackings.map((tracking, index) => (
        <GoalPeriodTracking
          key={index}
          tracking={tracking}
          isLead={isLead}
          goalInput={goalInput}
          goalTarget={goalTarget}
          isTracked
        />
      ))}
      {missingTrackings.map((tracking, index) => (
        <GoalPeriodTracking
          key={index}
          tracking={{ region: tracking }}
          isLead={isLead}
          goalInput={goalInput}
          goalTarget={goalTarget}
        />
      ))}
    </ProgressCard>
  );
};
