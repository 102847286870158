import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';

import { TextAreaInput } from 'components/shared/TextAreaInput';
import { colors } from 'constants/colors';
import {
  Collaboration,
  RatingStar,
  RatingDollar,
  ButtonRound,
  BodySmall,
  Icon,
  ButtonLabel,
} from 'components/shared';
import {
  AccessStrategyEndpointTargetFragment,
  AccessStrategyEndpointTargetsQueryVariables,
  useAccessStrategyEndpointTargetUpdateMutation,
  useAccessStrategyEndpointTargetDeleteMutation,
  AccessStrategyEndpointTargetsDocument,
} from 'data/graphql/generated';
import {
  apolloDeleteHelper,
  apolloUpdateHelper,
} from 'utils/apolloQueryHelpers';
import { useWidth } from 'hooks/useWidth';
import { isTouchDevice } from 'utils/isTouchDevice';
import {
  ColumnTitle,
  ColumnMeasurement,
  ColumnFeasibility,
  ColumnImpact,
  ColumnCost,
  ColumnCollaboration,
} from '../CreatingValue/TableRow';
import { fadeFromPurpleBGCSS } from 'utils/keyframes';

const GroupItemMobile = styled.div`
  position: relative;
  padding: 10px 15px 65px 15px;

  @media (min-width: 945px) {
    display: none;
  }
`;

const GroupItemDesktop = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 10px 15px 10px 15px;

  @media (max-width: 945px) {
    display: none;
  }
`;

export const GroupItem = styled.div`
  position: relative;
  border-bottom: 1px solid rgba(232, 232, 233, 1);
  &.originalIdea::before {
    ${fadeFromPurpleBGCSS}
    pointer-events: none;
  }
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;

const Content = styled.div<{ align: 'top' | 'center' }>`
  align-self: ${({ align }) => (align === 'top' ? 'flex-start' : 'center')};
`;

export const DeleteConfirmWrapper = styled.div`
  display: block;
  border: 1px solid ${colors.black30};
  width: 188px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: ${colors.white};
  right: 0px;
  z-index: 9;
  border-radius: 5px;
`;

export const DeleteConfirmCancel = styled.div`
  font-size: 14px;
  text-align: center;
  color: ${colors.black};
  width: 50%;
  display: inline-block;
  font-weight: 500;
  margin-top: 5px;
  cursor: pointer;
`;

export const DeleteConfirmConfirm = styled.div`
  font-size: 14px;
  text-align: center;
  color: ${colors.red};
  width: 50%;
  display: inline-block;
  font-weight: 500;
  margin-top: 5px;
  cursor: pointer;
`;

const FieldTitle = styled(BodySmall)`
  color: ${colors.greyDark};
  font-weight: 400;
  display: inline-block;
  margin-bottom: 2px;
`;

const LinkedMsg = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0px -5px 15px;
  gap: 5px;
  position: relative;
`;

const LinkedMsgMobile = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const GreyBox = styled.div`
  background: ${colors.black05};
  border-radius: 5px;
  padding: 10px;
`;

export const getColumnWidths = (width: number) => {
  return (width / 1368) * 100;
};

export const TableRow: React.FC<{
  accessStrategyEndpointTarget: AccessStrategyEndpointTargetFragment;
  refetch(): Promise<void>;
  autoFocus?: boolean;
  queryVars: AccessStrategyEndpointTargetsQueryVariables;
  drugId: number;
  strategyId: number;
}> = ({
  accessStrategyEndpointTarget,
  refetch,
  autoFocus,
  queryVars,
  drugId,
  strategyId,
}) => {
  const history = useHistory();
  const isTouch = isTouchDevice();
  const [showTrashBtn, setShowTrashBtn] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);
  const [localEndpointTarget, setLocalEndpointTarget] = useState(
    accessStrategyEndpointTarget
  );

  const width = useWidth();

  const showMobileView = width < 945;

  useEffect(() => {
    if (!isEditing) {
      setLocalEndpointTarget(accessStrategyEndpointTarget);
    }
  }, [accessStrategyEndpointTarget, isEditing]);

  const { title, measurement, cost, feasibility, impact } = localEndpointTarget;

  const [
    updateAccessStrategyEndpointTarget,
  ] = useAccessStrategyEndpointTargetUpdateMutation();
  const [
    deleteAccessStrategyEndpointTarget,
  ] = useAccessStrategyEndpointTargetDeleteMutation();

  async function updateEndpoint(data: {
    title?: string;
    measurement?: string;
    feasibility?: number;
    impact?: number;
    cost?: number;
  }) {
    await updateAccessStrategyEndpointTarget({
      variables: {
        id: localEndpointTarget.id,
        data,
      },
      update: apolloUpdateHelper({
        responseField: 'accessStrategyEndpointTargetUpdate',
        query: AccessStrategyEndpointTargetsDocument,
        queryName: 'accessStrategyEndpointTargets',
        queryVars,
      }),
    });
  }

  async function deleteEndpoint() {
    await deleteAccessStrategyEndpointTarget({
      variables: {
        id: localEndpointTarget.id,
      },
      optimisticResponse: {
        accessStrategyEndpointTargetDelete: { ...localEndpointTarget },
      },
      update: apolloDeleteHelper({
        responseField: 'accessStrategyEndpointTargetDelete',
        query: AccessStrategyEndpointTargetsDocument,
        queryName: 'accessStrategyEndpointTargets',
        queryVars,
      }),
    });
    await refetch();
    setShowDeleteConfirm(false);
  }

  const hasEndpointTarget = localEndpointTarget?.endpointTarget;

  const returnToOriginalEndpointTargetHandler = () => {
    history.push(
      `/d/${drugId}/strategy/${strategyId}/2_3/${localEndpointTarget.endpointTarget?.opportunity?.stakeholder}/${localEndpointTarget.endpointTarget?.opportunity?.stakeholderDefinitionId}?originalIdeaId=${localEndpointTarget.endpointTarget?.id}_${localEndpointTarget.keyStatementId}`
    );
  };

  return (
    <GroupItem
      onMouseEnter={() => setShowTrashBtn(true)}
      onMouseLeave={() => setShowTrashBtn(false)}
      id={
        accessStrategyEndpointTarget.endpointTarget?.id
          ? `endpoint_${accessStrategyEndpointTarget.endpointTarget?.id}`
          : ''
      }
    >
      {showMobileView ? (
        <GroupItemMobile>
          <div style={{ height: 40 }}>
            <div style={{ position: 'absolute', top: 15, right: 0 }}>
              <ButtonRound
                level="secondary"
                iconName="Trash"
                size="small"
                onClick={() => setShowDeleteConfirm(true)}
              />
              {showDeleteConfirm ? (
                <DeleteConfirmWrapper>
                  <DeleteConfirmCancel
                    onClick={() => setShowDeleteConfirm(false)}
                  >
                    Cancel
                  </DeleteConfirmCancel>
                  <DeleteConfirmConfirm onClick={deleteEndpoint}>
                    Delete row
                  </DeleteConfirmConfirm>
                </DeleteConfirmWrapper>
              ) : null}
            </div>
          </div>

          {hasEndpointTarget ? (
            <LinkedMsgMobile>
              <Icon name="Link" size={15} color={colors.black} />
              <BodySmall color={colors.greyDark}>Linked</BodySmall>{' '}
              <ButtonLabel
                color={colors.purple}
                onClick={returnToOriginalEndpointTargetHandler}
              >
                Edit original
              </ButtonLabel>
            </LinkedMsgMobile>
          ) : null}
          <div style={{ marginTop: 5 }}>
            <FieldTitle>Endpoint target</FieldTitle>
            {hasEndpointTarget ? (
              <GreyBox>
                <BodySmall>
                  {accessStrategyEndpointTarget?.endpointTarget?.title ||
                    'Not identified yet'}
                </BodySmall>
              </GreyBox>
            ) : (
              <TextAreaInput
                autoFocus={!!autoFocus}
                $borderless
                $table
                $small
                placeholder="Endpoint target"
                style={{ margin: '0px 0px 0px -1px', lineHeight: '20px' }}
                value={title}
                onFocus={() => setIsEditing(true)}
                onBlur={() => {
                  setIsEditing(false);
                  updateEndpoint({ title });
                }}
                onChange={async (e) => {
                  const target = e.target as HTMLTextAreaElement;
                  setLocalEndpointTarget({
                    ...localEndpointTarget,
                    title: target.value,
                  });
                }}
              />
            )}
          </div>
          <div style={{ marginTop: 15 }}>
            <FieldTitle>Measurement</FieldTitle>
            {hasEndpointTarget ? (
              <GreyBox>
                <BodySmall>
                  {accessStrategyEndpointTarget?.endpointTarget?.measurement ||
                    ''}
                </BodySmall>
              </GreyBox>
            ) : (
              <TextAreaInput
                $borderless
                $table
                $small
                placeholder="Measurement"
                style={{ margin: '0px 0px 0px -1px', lineHeight: '20px' }}
                value={measurement}
                onFocus={() => setIsEditing(true)}
                onBlur={() => {
                  setIsEditing(false);
                  updateEndpoint({ measurement });
                }}
                onChange={async (e) => {
                  const target = e.target as HTMLTextAreaElement;
                  setLocalEndpointTarget({
                    ...localEndpointTarget,
                    measurement: target.value,
                  });
                }}
              />
            )}
          </div>
          <>
            <div style={{ display: 'inline-block', width: 154, marginTop: 15 }}>
              <FieldTitle>Feasibility</FieldTitle>
              {hasEndpointTarget ? (
                <RatingStar
                  rating={
                    accessStrategyEndpointTarget?.endpointTarget?.feasibility ||
                    0
                  }
                  onUpdate={() => {}}
                  style={{ marginTop: 5 }}
                  readOnly
                />
              ) : (
                <RatingStar
                  rating={feasibility}
                  onUpdate={(val) => {
                    setLocalEndpointTarget({
                      ...localEndpointTarget,
                      feasibility: val,
                    });
                    updateEndpoint({ feasibility: val });
                  }}
                  style={{ marginTop: 5 }}
                />
              )}
            </div>
            <div style={{ display: 'inline-block', width: 154, marginTop: 15 }}>
              <FieldTitle>Impact</FieldTitle>
              {hasEndpointTarget ? (
                <RatingStar
                  rating={
                    accessStrategyEndpointTarget?.endpointTarget?.impact || 0
                  }
                  onUpdate={(val) => {}}
                  style={{ marginTop: 5 }}
                  readOnly
                />
              ) : (
                <RatingStar
                  rating={impact}
                  onUpdate={(val) => {
                    setLocalEndpointTarget({
                      ...localEndpointTarget,
                      impact: val,
                    });
                    updateEndpoint({ impact: val });
                  }}
                  style={{ marginTop: 5 }}
                />
              )}
            </div>
            <div style={{ display: 'inline-block', width: 154, marginTop: 15 }}>
              <FieldTitle>Cost</FieldTitle>
              {hasEndpointTarget ? (
                <RatingDollar
                  rating={
                    accessStrategyEndpointTarget?.endpointTarget?.cost || 0
                  }
                  onUpdate={() => {}}
                  style={{ marginTop: 5 }}
                  readOnly
                />
              ) : (
                <RatingDollar
                  rating={cost}
                  onUpdate={(val) => {
                    setLocalEndpointTarget({
                      ...localEndpointTarget,
                      cost: val,
                    });
                    updateEndpoint({ cost: val });
                  }}
                  style={{ marginTop: 5 }}
                />
              )}
            </div>
          </>
          <div style={{ position: 'absolute', bottom: 15, right: 0 }}>
            <Collaboration
              collaboration={
                hasEndpointTarget
                  ? accessStrategyEndpointTarget?.endpointTarget?.collaboration
                  : accessStrategyEndpointTarget.collaboration
              }
              style={{ zIndex: 4 }}
            />
          </div>
        </GroupItemMobile>
      ) : (
        <>
          {hasEndpointTarget ? (
            <LinkedMsg>
              <Icon name="Link" size={15} color={colors.black} />
              <BodySmall color={colors.greyDark}>Linked</BodySmall>
              <ButtonLabel
                color={colors.purple}
                onClick={returnToOriginalEndpointTargetHandler}
              >
                Edit original
              </ButtonLabel>
            </LinkedMsg>
          ) : null}
          <GroupItemDesktop>
            <ColumnTitle>
              <Content align="top">
                <div style={{ paddingRight: 20 }}>
                  {hasEndpointTarget ? (
                    <GreyBox>
                      {accessStrategyEndpointTarget?.endpointTarget?.title ? (
                        <BodySmall>
                          {accessStrategyEndpointTarget?.endpointTarget?.title}
                        </BodySmall>
                      ) : (
                        <BodySmall color={colors.greyDark}>
                          Not identified yet
                        </BodySmall>
                      )}
                    </GreyBox>
                  ) : (
                    <TextAreaInput
                      autoFocus={!!autoFocus}
                      $borderless
                      $table
                      $small
                      placeholder="Endpoint target"
                      style={{ margin: 0, lineHeight: '20px' }}
                      value={title}
                      onBlur={() => updateEndpoint({ title })}
                      onChange={async (e) => {
                        const target = e.target as HTMLTextAreaElement;
                        setLocalEndpointTarget({
                          ...localEndpointTarget,
                          title: target.value,
                        });
                      }}
                    />
                  )}
                </div>
              </Content>
            </ColumnTitle>
            <ColumnMeasurement>
              <Content align="top">
                <div style={{ paddingRight: 18 }}>
                  {hasEndpointTarget ? (
                    <GreyBox>
                      {accessStrategyEndpointTarget?.endpointTarget
                        ?.measurement ? (
                        <BodySmall>
                          {
                            accessStrategyEndpointTarget?.endpointTarget
                              ?.measurement
                          }
                        </BodySmall>
                      ) : (
                        <BodySmall color={colors.greyDark}>
                          Not identified yet
                        </BodySmall>
                      )}
                    </GreyBox>
                  ) : (
                    <TextAreaInput
                      $borderless
                      $table
                      $small
                      placeholder="Measurement"
                      style={{ margin: 0, lineHeight: '20px' }}
                      value={measurement}
                      onBlur={() => updateEndpoint({ measurement })}
                      onChange={async (e) => {
                        const target = e.target as HTMLTextAreaElement;
                        setLocalEndpointTarget({
                          ...localEndpointTarget,
                          measurement: target.value,
                        });
                      }}
                    />
                  )}
                </div>
              </Content>
            </ColumnMeasurement>
            <ColumnFeasibility>
              <Content align="center">
                {hasEndpointTarget ? (
                  <RatingStar
                    rating={
                      accessStrategyEndpointTarget?.endpointTarget
                        ?.feasibility || 0
                    }
                    onUpdate={() => {}}
                    style={{ marginTop: 5 }}
                    readOnly
                  />
                ) : (
                  <RatingStar
                    rating={feasibility}
                    onUpdate={(val) => {
                      setLocalEndpointTarget({
                        ...localEndpointTarget,
                        feasibility: val,
                      });
                      updateEndpoint({ feasibility: val });
                    }}
                    style={{ marginTop: 5 }}
                    readOnly={!!hasEndpointTarget}
                  />
                )}
              </Content>
            </ColumnFeasibility>
            <ColumnImpact>
              <Content align="center">
                {hasEndpointTarget ? (
                  <RatingStar
                    rating={
                      accessStrategyEndpointTarget?.endpointTarget?.impact || 0
                    }
                    onUpdate={(val) => {}}
                    style={{ marginTop: 5 }}
                    readOnly
                  />
                ) : (
                  <RatingStar
                    rating={impact}
                    onUpdate={(val) => {
                      setLocalEndpointTarget({
                        ...localEndpointTarget,
                        impact: val,
                      });
                      updateEndpoint({ impact: val });
                    }}
                    style={{ marginTop: 5 }}
                    readOnly={!!hasEndpointTarget}
                  />
                )}
              </Content>
            </ColumnImpact>
            <ColumnCost>
              <Content align="center">
                {hasEndpointTarget ? (
                  <RatingDollar
                    rating={
                      accessStrategyEndpointTarget?.endpointTarget?.cost || 0
                    }
                    onUpdate={() => {}}
                    style={{ marginTop: 5 }}
                    readOnly
                  />
                ) : (
                  <RatingDollar
                    rating={cost}
                    onUpdate={(val) => {
                      setLocalEndpointTarget({
                        ...localEndpointTarget,
                        cost: val,
                      });
                      updateEndpoint({ cost: val });
                    }}
                    style={{ marginTop: 5 }}
                    readOnly={!!hasEndpointTarget}
                  />
                )}
              </Content>
            </ColumnCost>
            <ColumnCollaboration>
              <Content align="center">
                <Wrapper>
                  <Collaboration
                    collaboration={
                      hasEndpointTarget
                        ? accessStrategyEndpointTarget?.endpointTarget
                            ?.collaboration
                        : accessStrategyEndpointTarget.collaboration
                    }
                    style={{ zIndex: 4 }}
                  />
                </Wrapper>
              </Content>
            </ColumnCollaboration>

            {isTouch || showTrashBtn ? (
              <div style={{ position: 'absolute', top: 10, right: 0 }}>
                <ButtonRound
                  level="secondary"
                  iconName="Trash"
                  size="small"
                  onClick={() => setShowDeleteConfirm(true)}
                />
              </div>
            ) : null}
            {showDeleteConfirm ? (
              <DeleteConfirmWrapper>
                <DeleteConfirmCancel
                  onClick={() => setShowDeleteConfirm(false)}
                >
                  Cancel
                </DeleteConfirmCancel>
                <DeleteConfirmConfirm onClick={deleteEndpoint}>
                  Delete row
                </DeleteConfirmConfirm>
              </DeleteConfirmWrapper>
            ) : null}
          </GroupItemDesktop>
        </>
      )}
    </GroupItem>
  );
};
