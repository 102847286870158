import React from 'react';
import styled from 'styled-components';

import { colors, zIndex } from 'constants/index';

const WrapperTop = styled.div`
  position: relative;
  z-index: ${zIndex.pageModal};

  height: 10px;
  background: radial-gradient(
    100% 57600% at 0% 100%,
    #49129b 0%,
    #4f72c9 50%,
    #bc2eaa 100%
  );

  overflow: hidden;
  &::before {
    content: '';
    background: url(/noise.png) repeat;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 60px;
    opacity: 0.3;
    pointer-events: none;
    z-index: 2;
  }
`;

export const Wrapper = styled.div`
  overflow: hidden;
  background: ${colors.white};
  border: 1px solid rgba(20, 20, 39, 0.1);
  border-radius: 5px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        rgb(255 255 255 / 88%) 0%,
        rgb(255 255 255 / 82%) 100%
      ),
      radial-gradient(
        circle at bottom left,
        rgba(10, 3, 18, 1) 0%,
        rgba(72, 16, 167, 1) 10%,
        rgba(75, 126, 235, 1) 20%,
        rgba(83, 123, 234, 1) 50%,
        rgba(234, 78, 209, 1) 60%,
        rgba(254, 247, 253, 1) 80% 100%
      );
    background-attachment: fixed;
    opacity: 0.5;
  }
`;

const GradientModal: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <Wrapper className={className + ' GradientModal'}>
      <WrapperTop className="wrapperTop" />
      {children}
    </Wrapper>
  );
};

export default GradientModal;
