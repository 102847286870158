import { useAuthContext } from 'contexts/AuthContext';
import { usePostItCardsPayload } from 'hooks/usePostItCards';
import { PostItGroupAndCards } from 'types';
import showTyping from '../utils/showTyping';

export function usePostItCardMove(
  groups: PostItGroupAndCards[],
  updateCard: usePostItCardsPayload['updateCard']
) {
  const [{ user }] = useAuthContext();

  // target id will only be set if dragging from one dropzone to another.
  return function onChange(
    sourceId: string,
    sourceIndex: number,
    targetIndex: number,
    targetId?: string
  ) {
    const sourceGroup = groups.find(({ id }) => id === Number(sourceId));
    if (!sourceGroup) return;
    const sourceCard = sourceGroup.cards[sourceIndex];
    if (!sourceCard) return;

    if (!user) return;
    // if another user is adding a card, prevent this user from updating its position
    if (showTyping(sourceCard, user)) return;

    if (targetId) {
      // move from one list to another
      const targetGroup = groups.find(({ id }) => id === Number(targetId));
      if (!targetGroup) return;

      // if there are no cards in group add to front
      if (targetGroup.cards.length === 0) {
        updateCard({
          ...sourceCard,
          pos: 1000,
          postItGroupId: Number(targetId),
        });
        return;
      }

      // move to beginning
      if (targetIndex === 0) {
        const targetCard = targetGroup.cards[0];
        updateCard({
          ...sourceCard,
          pos: Math.round(targetCard?.pos + 1000),
          postItGroupId: Number(targetId),
        });
        return;
      }

      const targetBeforeCard = targetGroup.cards[targetIndex - 1];
      const targetCard = targetGroup.cards[targetIndex];

      const posOne = targetBeforeCard?.pos || 0;
      const posTwo = targetCard?.pos || 0;
      const diff = Math.abs(posOne - posTwo) / 2;
      updateCard({
        ...sourceCard,
        pos: Math.round(posTwo + diff),
        postItGroupId: Number(targetId),
      });
      return;
    } else {
      // move a card in it's own group

      // move to beginning
      if (targetIndex === 0) {
        const targetCard = sourceGroup.cards[0];
        updateCard({ ...sourceCard, pos: Math.round(targetCard.pos + 1000) });
        return;
      }

      // move to end
      if (targetIndex >= sourceGroup.cards.length) {
        const lastCard = sourceGroup.cards[sourceGroup.cards.length - 1];
        const posOne = lastCard.pos;
        const newPos = Math.abs(posOne / 2);
        updateCard({ ...sourceCard, pos: Math.round(newPos) });
        return;
      }

      if (sourceIndex > targetIndex) {
        const targetBeforeCard = sourceGroup.cards[targetIndex - 1];
        const targetCard = sourceGroup.cards[targetIndex];
        const posOne = targetBeforeCard?.pos || 0;
        const posTwo = targetCard.pos;
        const diff = Math.abs(posOne - posTwo) / 2;
        const newPos = posTwo + diff;
        if (!newPos) return;
        updateCard({ ...sourceCard, pos: Math.round(newPos) });
        return;
      } else {
        // move card forward
        const targetBeforeCard = sourceGroup.cards[targetIndex];
        const targetCard = sourceGroup.cards[targetIndex + 1];
        const posOne = targetBeforeCard?.pos;
        const posTwo = targetCard?.pos || 0;
        const diff = Math.abs(posTwo - posOne) / 2;
        const newPos = posOne - diff;
        if (!newPos) return;
        updateCard({ ...sourceCard, pos: Math.round(newPos) });
        return;
      }
    }
  };
}
