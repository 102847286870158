import { chain } from 'lodash';
import { CriticalMetricGoalTrackingFragment } from 'data/graphql/generated';
import { formatDateByFrequency } from '../util-goal-tracking/formatDateByFrequency';
import { groupByRegion } from './groupByRegion';

export const groupByPeriod = (
  data: CriticalMetricGoalTrackingFragment[],
  supportedCountries?: string[]
) => {
  const output = chain(data)
    .groupBy((item) => {
      const period = formatDateByFrequency(
        new Date(Number(item.period)),
        item.trackedGoal.frequency
      );

      return period;
    })
    .map((items, key) => ({
      period: key,
      items: groupByRegion(items, supportedCountries),
    }))
    .value();

  return output;
};
