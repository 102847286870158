import { CriticalMetricGoalTrackingFragment } from 'data/graphql/generated';
import { chain } from 'lodash';

export const groupByRegion = (
  data: CriticalMetricGoalTrackingFragment[],
  supportedCountries?: string[]
) => {
  const grouped = chain(data)
    .groupBy('region')
    .map((value, key) => ({
      region: key,
      average:
        value.map((v) => v.percent).reduce((a, b) => a + b, 0) / value.length,
    }))
    .value();

  if (supportedCountries) {
    return grouped.filter((item) => supportedCountries.includes(item.region));
  }

  return grouped;
};
