import { colors } from 'constants/index';
import React from 'react';
import styled from 'styled-components/macro';
import { BodySmall, Icon } from 'components/shared';
import { Stakeholder } from 'data/graphql/generated';
import { IconName } from 'types';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 215px;
`;

const UserImage = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px;
  flex: none;
`;

interface Props {
  imageUrl?: string | null;
  title: string;
  stakeholder?: Stakeholder;
}
export const ImageAndTitle: React.FC<Props> = ({
  imageUrl,
  title,
  stakeholder,
}) => {
  return (
    <Wrapper>
      {stakeholder && !imageUrl ? (
        <StakeholderIcon stakeholder={stakeholder} />
      ) : imageUrl ? (
        <UserImage src={imageUrl} alt={title} />
      ) : (
        <PlaceholderIcon />
      )}
      <BodySmall>{title}</BodySmall>
    </Wrapper>
  );
};

function getStakeholderIconName(stakeholder: Stakeholder): IconName {
  switch (stakeholder) {
    case Stakeholder.Patient:
      return 'Patient';
    case Stakeholder.Healthcare:
      return 'HCP';
    case Stakeholder.Payor:
      return 'Dollar';
    case Stakeholder.Provider:
      return 'Provider';
    case Stakeholder.Policymaker:
      return 'Policymaker';
  }
}
function getStakeholderIconColor(stakeholder: Stakeholder): string {
  switch (stakeholder) {
    case Stakeholder.Patient:
      return colors.purple;
    case Stakeholder.Healthcare:
      return colors.green;
    case Stakeholder.Payor:
      return colors.blue;
    case Stakeholder.Provider:
      return colors.pink;
    case Stakeholder.Policymaker:
      return colors.red;
  }
}

const PlaceholderIcon = () => (
  <Icon
    name="Patient"
    size={25}
    color={colors.purple}
    style={{
      background: colors.purple10,
      borderRadius: '50%',
      marginRight: '5px',
      height: 25,
      alignSelf: 'flex-start',
    }}
  />
);
const StakeholderIcon = ({ stakeholder }: { stakeholder: Stakeholder }) => (
  <Icon
    name={getStakeholderIconName(stakeholder)}
    size={25}
    color={getStakeholderIconColor(stakeholder)}
    style={{
      borderRadius: '50%',
      marginRight: '5px',
      height: 25,
      alignSelf: 'flex-start',
    }}
  />
);
