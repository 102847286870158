export function handleClickOutside(
  selector: HTMLElement,
  callback: (event?: MouseEvent | TouchEvent) => void,
  detectDrag: boolean,
  excludeClassNames?: string[]
) {
  const drag = { current: false };
  const mouseDown = { current: false };

  const mousePosition = { current: { x: 0, y: 0 } };

  function setDragToFalse(event: MouseEvent) {
    mousePosition.current.x = event.clientX;
    mousePosition.current.y = event.clientY;

    mouseDown.current = true;
    drag.current = false;
  }

  function setDragToTrue(event: MouseEvent) {
    const movedTwentyPixels =
      Math.abs(event.clientX - mousePosition.current.x) >= 20 ||
      Math.abs(event.clientY - mousePosition.current.y) >= 20;

    if (movedTwentyPixels && mouseDown.current) {
      drag.current = true;
    }
  }

  function handler(event: MouseEvent | TouchEvent) {
    mouseDown.current = false;
    if (drag.current && detectDrag) {
      drag.current = false;
      return;
    }

    const target = event.target as Node;

    const removeListeners = () => {
      if (detectDrag) {
        document.removeEventListener('mousedown', setDragToFalse);
        document.removeEventListener('mousemove', setDragToTrue);
        document.removeEventListener('mouseup', handler);
        document.removeEventListener('touchend', handler);
      } else {
        document.removeEventListener('mousedown', handler);
        document.removeEventListener('touchstart', handler);
      }
    };

    //Add classname to exclusion
    if (!!(excludeClassNames && selector && !selector.contains(target))) {
      const path = event.composedPath();

      const clicked = path.find((e) => {
        //@ts-ignore
        return e?.classList
          ? //@ts-ignore
            Array.from(e.classList).some((c: string) =>
              excludeClassNames.includes(c)
            )
          : //@ts-ignore
            e.className === excludeClassNames[0];
      });
      if (selector && !clicked) {
        removeListeners();
        callback(event);
      }
    }

    if (
      !excludeClassNames &&
      selector &&
      //@ts-ignore
      !selector.contains(event.target)
    ) {
      removeListeners();
      callback(event);
    }
  }
  // apply the listeners after a small amount of time so the element is mounted
  requestAnimationFrame(() => {
    if (detectDrag) {
      document.addEventListener('mousedown', setDragToFalse);
      document.addEventListener('mousemove', setDragToTrue);
      document.addEventListener('mouseup', handler, { once: true });
      document.addEventListener('touchend', handler, { once: true });
    } else {
      document.addEventListener('mousedown', handler);
      document.addEventListener('touchstart', handler);
    }
  });
}
