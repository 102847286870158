import {
  MobileOuterWrapper,
  MobileWrapper,
} from 'components/MedicalStrategy/TacticRow';
import { BodySmall } from 'components/shared';
import { colors } from 'constants/colors';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { monthNames } from 'utils/monthNames';
import { thousandSeparator } from 'utils/thousandSeparator';
import { BarRating } from './BarRating';

const BudgetWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export const columnGridStyle = css`
  padding: 0 15px;
  grid-template-columns:
    minmax(180px, 350px)
    minmax(192px, 350px)
    90px
    minmax(181px, 350px)
    minmax(140px, 140px);
`;

const RowWrapper = styled.div`
  width: 100%;
  display: grid;
  padding-left: 15px;
  gap: 15px;
  ${columnGridStyle}
  margin: 15px 0;
`;

const RiskWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface Props {
  isDesktop: boolean;
  solutionText: string;
  riskText: string;
  impactRating: number;
  dueDate: number[];
  responsibility: string;
  budget: string;
  currency: string;
}

export const SummaryRow = ({
  isDesktop,
  solutionText,
  riskText,
  impactRating,
  dueDate,
  responsibility,
  budget,
  currency,
}: Props) => {
  if (isDesktop)
    return (
      <RowWrapper>
        <div>
          <TextWrapper text={solutionText} />
        </div>
        <RiskWrapper>
          <BodySmall>{riskText}</BodySmall>

          <BarRating readOnly rating={impactRating || 0} />
        </RiskWrapper>
        <div>
          <BodySmall>
            {[monthNames[dueDate[0]], dueDate[1]].join(' ')}
          </BodySmall>
        </div>
        <div>
          <BodySmall>{responsibility}</BodySmall>
        </div>
        <BudgetWrapper>
          <BodySmall color={colors.greyDark}>{currency}</BodySmall>

          <BodySmall>{thousandSeparator(budget || '')}</BodySmall>
        </BudgetWrapper>
      </RowWrapper>
    );

  return (
    <MobileOuterWrapper focused={false}>
      <MobileWrapper>
        <div>
          <BodySmall color={colors.greyDark}>Solution</BodySmall>
          <TextWrapper text={solutionText} />
        </div>
        <div>
          <BodySmall color={colors.greyDark}>Risk</BodySmall>
          <BodySmall style={{ marginBottom: 5 }}>{riskText}</BodySmall>
          <BarRating readOnly rating={impactRating || 0} />
        </div>
        <div>
          <BodySmall color={colors.greyDark}>Due</BodySmall>
          <BodySmall>
            {[monthNames[dueDate[0]], dueDate[1]].join(' ')}
          </BodySmall>
        </div>
        <div>
          <BodySmall color={colors.greyDark}>Responsibility</BodySmall>
          <BodySmall>{responsibility}</BodySmall>
        </div>
        <div>
          <BodySmall color={colors.greyDark}>Budget</BodySmall>
          <BudgetWrapper>
            <BodySmall color={colors.greyDark}>{currency}</BodySmall>

            <BodySmall>{thousandSeparator(budget || '')}</BodySmall>
          </BudgetWrapper>
        </div>
      </MobileWrapper>
    </MobileOuterWrapper>
  );
};

const TextWrapper = ({ text }: { text: string }) => {
  return !!text ? (
    <BodySmall>{text}</BodySmall>
  ) : (
    <BodySmall>Not identified yet</BodySmall>
  );
};
