import { CriticalMetricGoalInput } from 'data/graphql/generated';

export const convertGoalInputEnumToText = (
  apiGoalInput?: CriticalMetricGoalInput | null
) => {
  switch (apiGoalInput) {
    case CriticalMetricGoalInput.GlobalOnly:
      return 'Global only';
    case CriticalMetricGoalInput.PerCountry:
      return 'Per country';
    default:
      return undefined;
  }
};
