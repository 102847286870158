import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { Page } from 'components/Page';
import { useStakeholderContext } from 'contexts/StakeholderContext';
import { StakeHolderTabs } from 'components/StakeHolderTabs';
import { Navbar } from 'components/Navbar';
import {
  BodyNormal,
  StepHeaderBar,
  Subtitle2,
  BodySmall,
} from 'components/shared';
import { colors } from 'constants/colors';
import { device } from 'utils/breakpoints';
import { TooltipWrapper } from 'components/shared/ButtonPill';
import {
  Stakeholder,
  useEndpointTargetsQuery,
  Step,
  useStakeholderDefinitionsQuery,
  Sort,
} from 'data/graphql/generated';
import {
  StakeholderDefinitions,
  Wrapper as StakeholderDefinitionsWrapper,
} from 'components/shared/StakeholderDefinitions';
import { Table } from 'components/CreatingValue/Table';
import { PostItsEmpty } from '../components/PostItsEmpty';
import { Loading } from 'components/Loading';
import { StakeholderDefinitionSummaryModal } from 'components/StakeholderDefinitionSummaryModal';
import { polling } from 'constants/index';
import { Observations } from 'components/Observations';
import { useToastContext } from 'contexts/ToastContext';
import { useQuery } from 'hooks/useQuery';

interface URLParams {
  drugId: string;
  strategyId: string;
  stakeholder: Stakeholder;
  stakeholderDefinitionId: string;
  competitorId: string;
}

const PageWrapper = styled(Page)`
  overflow: hidden;
  @media ${device.tabletMax} {
    padding-top: 30px;
  }
`;

const StepHeader = styled(StepHeaderBar)`
  display: block;
  padding: 15px 15px 0 15px;
  border-bottom: 0.5px solid ${colors.greyMedium};

  @media ${device.tabletMax} {
    margin-top: 160px;
    display: block;
  }

  ${TooltipWrapper} {
    position: relative;
  }

  ${StakeholderDefinitionsWrapper} {
    margin-bottom: 15px;
  }
`;

export const EvidenceGenerationIdeas: React.FC = () => {
  const {
    drugId,
    strategyId,
    stakeholder: stakeholderParam,
    stakeholderDefinitionId,
  }: URLParams = useParams();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);

  const originalIdeaId = useQuery().get('originalIdeaId');

  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [stakeholder, setStakeholder] = useStakeholderContext(stakeholderParam);
  const history = useHistory();
  const [createToast, removeToast] = useToastContext();

  const {
    data: stakeholderDefinitions,
    error: stakeholderDefinitionsError,
    loading: stakeholderDefinitionsLoading,
    startPolling,
    stopPolling,
  } = useStakeholderDefinitionsQuery({
    variables: {
      orderBy: { createdAt: Sort.Asc },
      where: {
        stakeholder: Stakeholder.Patient,
        strategyId: +strategyId,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    startPolling(polling.default);
    return () => stopPolling();
  }, [startPolling, stopPolling]);

  const validStakeholderDefinitions = useMemo(
    () =>
      stakeholderDefinitions?.stakeholderDefinitions?.items?.filter(
        (definition) => definition.title
      ) || [],
    [stakeholderDefinitions]
  );

  useEffect(() => {
    // If there is a stakeholderDefinitionId set, don't override
    if (stakeholderDefinitionId) return;

    // When user navigates to the page or toggles the stakeholder, select the first stakeholder definition by default
    if (validStakeholderDefinitions?.length) {
      history.replace({
        pathname: `/d/${drugId}/strategy/${strategyId}/2_3/${stakeholder}/${validStakeholderDefinitions[0].id}`,
        search: params.toString(),
      });
    }
  }, [
    stakeholderDefinitionId,
    stakeholderDefinitions,
    drugId,
    strategyId,
    history,
    stakeholder,
    validStakeholderDefinitions,
    originalIdeaId,
    params,
  ]);

  const selectedStakeholderDefinition = validStakeholderDefinitions?.find(
    (s) => s.id === +stakeholderDefinitionId
  );

  const {
    data: endpointTargetData,
    loading: endpointTargetLoading,
    error: endpointTargetError,
    refetch,
    startPolling: endpointStartPolling,
    stopPolling: endpointStopPolling,
  } = useEndpointTargetsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
      },
      strategyId: +strategyId,
      stakeholderDefinitionId: +stakeholderDefinitionId,
    },
    fetchPolicy: 'network-only',
    skip: !stakeholderDefinitionId,
  });

  useEffect(() => {
    endpointStartPolling(polling.default);
    return () => endpointStopPolling();
  }, [endpointStartPolling, endpointStopPolling]);

  const hasStakeholderError =
    !stakeholderDefinitionsLoading && !validStakeholderDefinitions.length;

  const targetPatientError = JSON.stringify(stakeholderDefinitionsError) || '';

  const hasStakeholderNotIdentifiedError = targetPatientError?.includes(
    'STAKEHOLDER_NOT_IDENTIFIED'
  );

  const competitiveLandscapeOpportunities = useMemo(
    () =>
      endpointTargetData?.competitiveLandscapeOpportunities?.items.filter(
        (o) => {
          return o.stakeholder === stakeholder;
        }
      ) || [],
    [endpointTargetData?.competitiveLandscapeOpportunities?.items, stakeholder]
  );

  useEffect(() => {
    if (!originalIdeaId) return;
    const getOriginalIdea = () =>
      document.querySelector(`#endpoint_${originalIdeaId.split('_')[0]}`);

    // This prevents it from creating an observer if the item already exists on mount
    const existingOriginalIdea = getOriginalIdea();

    let observerHandle: MutationObserver | null = null;

    if (!existingOriginalIdea) {
      let observer = new MutationObserver(function (mutations) {
        const originalIdea = getOriginalIdea();

        if (!!originalIdea) {
          originalIdea.scrollIntoView({ block: 'center' });
          originalIdea.classList.add('originalIdea');

          observer.disconnect();
        }
      });

      observer.observe(document, {
        attributes: false,
        childList: true,
        characterData: false,
        subtree: true,
      });
      observerHandle = observer;
    }
    return () => {
      observerHandle?.disconnect();
    };
  }, [competitiveLandscapeOpportunities, endpointTargetData, originalIdeaId]);

  useEffect(() => {
    if (!!originalIdeaId) {
      const { id, setCTAClick } = createToast(
        'Return to 4.1 Access Strategy',
        'Return'
      );

      history.listen(() => {
        removeToast(id);
      });

      setCTAClick(() => {
        history.push(
          `/d/${drugId}/strategy/${strategyId}/4_1/evidence?returnToId=${originalIdeaId}`
        );
      });
    }
  }, [createToast, drugId, history, originalIdeaId, removeToast, strategyId]);

  return (
    <>
      {!!showSummaryModal && (
        <StakeholderDefinitionSummaryModal
          strategyId={Number(strategyId)}
          drugId={Number(drugId)}
          stakeholderDefId={selectedStakeholderDefinition?.id}
          handleClose={() => setShowSummaryModal(false)}
          visible={showSummaryModal}
        />
      )}
      <Navbar
        stepNumber="2.3"
        title="Evidence Generation Ideas"
        prev={{
          title: 'Patient Flow',
          url: `/d/${drugId}/strategy/${strategyId}/2_2`,
        }}
        next={{
          title: 'Strategic Possibilities',
          url: `/d/${drugId}/strategy/${strategyId}/2_4`,
        }}
      >
        <StakeHolderTabs
          stakeholder={stakeholder}
          setStakeholder={(stakeholder) => {
            history.push(
              `/d/${drugId}/strategy/${strategyId}/2_3/${stakeholder}`
            );
            setStakeholder(stakeholder);
          }}
        />
        <Observations stakeholder={stakeholder} step={Step.CreatingValue} />
      </Navbar>

      <StepHeader>
        {stakeholderDefinitionsLoading ? (
          <Loading style={{ width: 80, marginTop: 15 }} />
        ) : (
          <>
            <Subtitle2>
              Consider evidence generation ideas for each outcome.
            </Subtitle2>
            <BodyNormal
              color={colors.greyDark}
              style={{ marginBottom: '15px' }}
            >
              Make suggestions under the relevant outcomes.
            </BodyNormal>

            <StakeholderDefinitions
              viewSummary={() => setShowSummaryModal(true)}
              stakeholderDefinitions={validStakeholderDefinitions || []}
              hideStakeholderDefinitionsEmptyText={
                !!hasStakeholderNotIdentifiedError
              }
              selectedStakeholderDefinition={selectedStakeholderDefinition}
              stakeholder={stakeholder}
              drugId={drugId}
              strategyId={strategyId}
              showEvaluation={false}
              competitorId={undefined}
              page="2_3"
            />
          </>
        )}
      </StepHeader>

      <ErrorWrapper
        isLoading={endpointTargetLoading || stakeholderDefinitionsLoading}
        errors={[endpointTargetError]}
        dataMissing={false}
      >
        <PageWrapper paddingTop={false} fullWidthMobile>
          {hasStakeholderError ? (
            <PostItsEmpty
              title={
                hasStakeholderNotIdentifiedError
                  ? 'Nothing to discuss'
                  : 'No stakeholder definitions to assess'
              }
            >
              {hasStakeholderNotIdentifiedError ? (
                <>
                  <BodySmall
                    color={colors.greyDark}
                    style={{ display: 'inline' }}
                  >
                    Evidence generation ideas can only be added when a Lead
                    marks an outcome as an Opportunity in{' '}
                  </BodySmall>
                  <Link
                    to={`/d/${drugId}/strategy/${strategyId}/1_2/${stakeholder}`}
                    style={{
                      color: colors.greyDark,
                      display: 'inline',
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    1.2 Competitive Landscape{' '}
                  </Link>
                </>
              ) : (
                <>
                  <BodySmall
                    color={colors.greyDark}
                    style={{ display: 'inline' }}
                  >
                    Leads must define stakeholders in{' '}
                  </BodySmall>
                  <Link
                    to={`/d/${drugId}/strategy/${strategyId}/1_1`}
                    style={{
                      color: colors.greyDark,
                      display: 'inline',
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    1.1 Key Stakeholder Analysis{' '}
                  </Link>
                  <BodySmall
                    color={colors.greyDark}
                    style={{ display: 'inline' }}
                  >
                    to start assessing the competitive landscape
                  </BodySmall>
                </>
              )}
            </PostItsEmpty>
          ) : null}

          {competitiveLandscapeOpportunities.length > 0 ? (
            competitiveLandscapeOpportunities.map((opportunity) => {
              if (!opportunity) return null;
              return (
                <Table
                  key={opportunity.id}
                  opportunity={opportunity}
                  endpointTargets={
                    endpointTargetData?.endpointTargets?.items?.filter(
                      ({ opportunityId }) => opportunityId === opportunity.id
                    ) || []
                  }
                  strategyId={+strategyId}
                  refetch={refetch}
                />
              );
            })
          ) : (
            <>
              {!hasStakeholderError ? (
                <PostItsEmpty title="Nothing to discuss for this stakeholder definition">
                  <BodySmall
                    color={colors.greyDark}
                    style={{ display: 'inline' }}
                  >
                    Endpoints can only be added when a Lead marks an outcome as
                    an “Opportunity” in{' '}
                  </BodySmall>
                  <Link
                    to={`/d/${drugId}/strategy/${strategyId}/1_2`}
                    style={{
                      color: colors.greyDark,
                      display: 'inline',
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    1.2 Competitive Landscape
                  </Link>
                </PostItsEmpty>
              ) : null}
            </>
          )}
        </PageWrapper>
      </ErrorWrapper>
    </>
  );
};
