import { useQuery } from 'hooks/useQuery';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  PatientJourneyPageContext,
  PatientJourneyPageContextType,
} from './PatientJourneyPageContext';

export const PatientJourneyPageProvider: React.FC = ({ children }) => {
  const history = useHistory();

  let query = useQuery();
  const sidebarBlockId = query.get('sidebarBlockId');

  const setSidebarBlockId = useCallback(
    (blockId: number) => {
      if (!blockId) return;
      history.push({ search: `sidebarBlockId=${blockId}` });
    },
    [history]
  );

  const removeSidebarBlockId = useCallback(() => {
    if (!sidebarBlockId) return;
    query.delete('sidebarBlockId');
    history.replace({ search: query.toString() });
  }, [history, query, sidebarBlockId]);

  const value: PatientJourneyPageContextType = {
    sidebarBlockId: sidebarBlockId || '',
    openSidebar: setSidebarBlockId,
    closeSidebar: removeSidebarBlockId,
  };

  return (
    <PatientJourneyPageContext.Provider value={value}>
      {children}
    </PatientJourneyPageContext.Provider>
  );
};
