import { User } from 'data/graphql/generated';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CountryGlobal } from 'types';
import { verifyUserRole } from 'utils/verifyUserRole';
import useClickOutsideComponent from './useClickOutsideComponent';
import { useStrategyContext } from '../contexts/StrategyContext';
import { getUniqueRegions } from '../utils/getUniqueRegions';

type Props = {
  region: string;
  user: User | undefined;
  strategyId: number;
};

const useHandleRegionDropdown = ({ region, user, strategyId }: Props) => {
  const [strategy] = useStrategyContext(strategyId);

  const uniqueRegions = getUniqueRegions(
    strategy?.data?.strategy?.users || [],
    false
  ).sort();

  const [selectedRegion, setSelectedRegion] = useState<CountryGlobal>(
    (region as CountryGlobal) || 'global'
  );

  const { isLead } = verifyUserRole(user?.role, user?.country);

  const viewingOwnRegion = isLead
    ? selectedRegion === 'global'
    : selectedRegion === user?.country;

  // Region selection
  const countryDropDownRef = useRef<HTMLDivElement | null>(null);
  const [countryDropdownActive, setCountryDropdownActive] = useState(false);

  useClickOutsideComponent(countryDropDownRef, () => {
    setCountryDropdownActive(false);
  });

  useEffect(() => {
    if (region) {
      setSelectedRegion(region as CountryGlobal);
    }
  }, [region]);

  const data = useMemo(() => {
    return {
      countryDropDownRef,
      viewingOwnRegion,
      selectedRegion,
      countryDropdownActive,
      setSelectedRegion,
      setCountryDropdownActive,
      uniqueRegions,
    };
  }, [countryDropdownActive, selectedRegion, uniqueRegions, viewingOwnRegion]);
  return data;
};

export default useHandleRegionDropdown;
