import { Connection, ConnectionSide, Position } from 'types';
import { snapToGrid } from './snapToGrid';
import { generateInitialPath } from './generateInitialPath';
import { GRID_SIZE } from '../../../constants';
import { Stage } from 'konva/types/Stage';
import { Layer } from 'konva/types/Layer';

function getPosition(
  node: any,
  snap: boolean,
  adjustFromScreenX: (x: number) => number,
  adjustFromScreenY: (x: number) => number,
  screenRef: React.MutableRefObject<Stage>
) {
  const pos = node.getAbsolutePosition();
  if (!snap) {
    return {
      x: adjustFromScreenX(pos.x),
      y: adjustFromScreenY(pos.y),
    };
  }
  return {
    x: snapToGrid(adjustFromScreenX(pos.x)),
    y: snapToGrid(adjustFromScreenY(pos.y)),
  };
}

export function generatePathFromConnection(
  screenRef: React.MutableRefObject<Stage | Layer | null>,
  adjustFromScreenX: (x: number) => number,
  adjustFromScreenY: (x: number) => number,
  {
    toBlockId,
    toSide,
    fromBlockId,
    fromSide,
  }: Omit<Omit<Connection, 'path'>, 'id'>,
  snap: boolean = false
) {
  if (!screenRef.current) return [];
  const fromNode = screenRef.current.findOne(
    `#block-${fromBlockId}-dot-${fromSide}`
  );

  if (!fromNode) return [];

  // get pos of fromDot
  const fromPos = getPosition(
    fromNode,
    snap,
    adjustFromScreenX,
    adjustFromScreenY,
    screenRef as React.MutableRefObject<Stage>
  );

  const toNode = screenRef.current.findOne(`#block-${toBlockId}-dot-${toSide}`);

  if (!toNode) return [];

  const toPos = getPosition(
    toNode,
    snap,
    adjustFromScreenX,
    adjustFromScreenY,
    screenRef as React.MutableRefObject<Stage>
  );

  return generatePath(fromSide, fromPos, toSide, toPos, snap);
}

export function generatePath(
  fromSide: ConnectionSide,
  fromPos: Position,
  toSide: ConnectionSide,
  toPos: Position,
  snap: boolean = false
) {
  let path: number[] = [];

  const [initialPathX, initialPathY] = generateInitialPath(
    fromSide,
    fromPos.x,
    fromPos.y
  );

  path = [fromPos.x, fromPos.y, initialPathX, initialPathY];

  const [lastPathX, lastPathY] = generateInitialPath(toSide, toPos.x, toPos.y);

  let mid = Math.floor(initialPathX + (lastPathX - initialPathX) / 2);

  // prevents additional corners when the path has not moved much
  if (Math.abs(initialPathX - mid) < GRID_SIZE * 2) {
    mid = initialPathX;
  }

  path = [
    ...path,
    snap ? snapToGrid(mid) : mid,
    initialPathY,
    snap ? snapToGrid(mid) : mid,
    lastPathY,
  ];

  path = [...path, lastPathX, lastPathY, toPos.x, toPos.y];

  return path;
}
