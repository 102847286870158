import { css } from 'styled-components';

const textAreaMinWidth = '80px';
const collaborationsWidth = '107px';

export const tableColumnWidths = css`
  grid-template-columns:
    repeat(3, minmax(${textAreaMinWidth}, 180px)) 190px 90px minmax(
      ${textAreaMinWidth},
      180px
    )
    minmax(calc(${textAreaMinWidth} + ${collaborationsWidth}), 287px);
`;
