import {
    User,
    Role,
  } from 'data/graphql/generated';
import { globalContributor } from 'constants/index';

function getUniqueCombinedUsers(sortedRatings: any, companyUsersData: User[] | undefined) {
  // Extract rated user IDs
  const ratingsUsers = sortedRatings?.map((rating: any) => rating.user?.id).filter(Boolean) || [];

  // Identify unrated users
  const unratedUsers = companyUsersData
    ?.filter((user) => !ratingsUsers.includes(user?.id))
    .map((val) => ({ user: val })) || [];
  // Combine rated and unrated users and sort them
  const combinedUsers = [...sortedRatings, ...unratedUsers].sort((a, b) => {
    if (!a.user?.country || !b.user?.country) {
      return 0;
    }

    // Sort by role
    if (a.user.role === Role.Lead && b.user.role !== Role.Lead) {
      return 1;
    }
    if (b.user.role === Role.Lead && a.user.role !== Role.Lead) {
      return -1;
    }

    // Sort by title, with "Lead" as default title if none
    const titleA = a.user.role === Role.Lead && !a.user.title
      ? Role.Lead.toUpperCase()
      : a.user.title?.toUpperCase();
    const titleB = b.user.role === Role.Lead && !b.user.title
      ? Role.Lead.toUpperCase()
      : b.user.title?.toUpperCase();

    if (!!titleA && !!titleB) {
      if (titleA < titleB) return -1;
      if (titleA > titleB) return 1;
    }
    if (!titleA && !!titleB) return -1;
    if (!titleB && !!titleA) return 1;

    // Sort by country, prioritizing global contributor
    const countryA = a.user.country.toUpperCase();
    const countryB = b.user.country.toUpperCase();

    if (countryA === globalContributor.toUpperCase() && countryB !== globalContributor.toUpperCase()) {
      return 1;
    }
    if (countryB === globalContributor.toUpperCase() && countryA !== globalContributor.toUpperCase()) {
      return -1;
    }

    // Sort alphabetically by country if not a lead
    if (a.user.role !== Role.Lead) {
      if (countryA < countryB) return -1;
      if (countryA > countryB) return 1;
    }

    // Sort alphabetically by name
    const nameA = a.user.name.toUpperCase();
    const nameB = b.user.name.toUpperCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0;
  });

  // Remove duplicates by stakeholderDefinitionId for combined users
  const uniqueCombinedUsers = combinedUsers.reduce((acc, current) => {
    const existingUser = acc.find((item: { user: { id: any; }; }) => item?.user?.id === current?.user?.id);
    if (!existingUser) {
      acc.push(current);
    }
    return acc;
  }, []);

  // Remove duplicates in ratingsUsers
  const uniqueRatingUsers = ratingsUsers.reduce((acc: any[], current: any) => {
    const existingUser = acc.find((userId: any) => userId === current);
    if (!existingUser) {
      acc.push(current);
    }
    return acc;
  }, []);

  return { uniqueCombinedUsers, uniqueRatingUsers };
}

export default getUniqueCombinedUsers;