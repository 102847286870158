import { SubStep } from 'data/graphql/generated';

export function mapDBStepToReadableSubStep(substep?: SubStep): string {
  switch (substep) {
    case 'bigIdeas':
      return 'Big Ideas';
    case 'capabilities':
      return 'Capabilities';
    case 'clinicalValue':
      return 'Clinical Value';
    case 'competitorPositioning':
      return 'Competitor Positioning';
    case 'diseaseBurdenAndUnmetNeed':
      return 'Disease Burden and Unmet Need';
    case 'economicValue':
      return 'Economic Value';
    case 'humanisticValue':
      return 'Humanistic Value';
    default:
      return '';
  }
}
