import { colors } from 'constants/index';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: rgba(20, 20, 39, 0.7);
  border-radius: 40px;
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 99999;
  height: 40px;
  cursor: pointer;
`;

const Content = styled.div`
  position: relative;
  padding: 0px 20px 0px 45px;
`;

const Icon = styled.div`
  background: url('/delete.svg') center center no-repeat;
  background-color: ${colors.cream};
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
`;

const Text = styled.span`
  margin-top: 10px;
  display: inline-block;
  font-family: 'ABCFavorit';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.white};
`;

interface Props {
  handleClick(): void;
  text: string;
}

export const ConnectorAction: React.FC<Props> = ({ text, handleClick }) => {
  return (
    <Wrapper onClick={handleClick}>
      <Content>
        <Icon />
        <Text>{text}</Text>
      </Content>
    </Wrapper>
  );
};
