import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';

import { CountryFlag, Icon } from 'components/shared';
import { colors } from 'constants/index';
import { useDropZoneContext } from 'contexts/DropZoneContext';
import { BigIdeaFragment } from 'data/graphql/generated';
import { ImageStateType } from './BigIdeaSidebarContent';

const BackgroundIcon = styled.div<{ hasImage: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  pointer-events: none;
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ hasImage }) =>
    hasImage ? 'transparent' : colors.greyLight};
  transition: background 0.3s;

  // hide icon if there is no image and not hover
  > div {
    display: ${({ hasImage }) => (hasImage ? 'none' : 'block')};
  }
`;

const ErrorMsg = styled.div<{ hasImage: boolean }>`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 64px;
  width: 64px;
  background: ${({ hasImage }) =>
    hasImage ? colors.black80a : colors.greyLight};

  padding: 7px 5px;

  span {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${({ hasImage }) => (hasImage ? colors.white : colors.red)};
  }
  span:nth-of-type(2n) {
    color: ${({ hasImage }) => (hasImage ? colors.white : colors.black)};
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div<{ canEdit: boolean }>`
  position: relative;
  height: 64px;
  width: 64px;
  position: relative;
  overflow: hidden;
  background: ${colors.greyLight};
  cursor: ${({ canEdit }) => (canEdit ? 'pointer' : 'auto')};
`;

const ImageBG = styled.div<{ src: string }>`
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1.5);
  filter: blur(15px);

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Image = styled.div<{ src: string }>`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  ${({ src }) =>
    css`
      background-image: url(${src});
    `};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const Country = styled.div`
  position: absolute;
  bottom: 2px;
  right: 2px;
  pointer-events: none;
`;

const DropZoneWrapper = styled.div<{
  isDragOver: boolean;
  hasImage: boolean;
  isDragActive: boolean;
  canEdit: boolean;
}>`
  position: relative;
  height: 64px;
  width: 64px;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;

  border-radius: 5px;
  transition: border-color 0.5s, background 0.3s;
  background: ${colors.greyLight};

  ${({ canEdit, hasImage }) =>
    canEdit
      ? css`
          &:hover ${BackgroundIcon} {
            background: ${hasImage ? colors.black80a : colors.purple20};
            // show icon for both no-image and image state
            > div {
              display: block;
            }
          }
          &:hover ${Country} {
            display: none;
          }
        `
      : ''};
`;

interface Props {
  errMsg: string;
  setErrMsg: React.Dispatch<React.SetStateAction<string>>;
  imageState: ImageStateType;
  setImageState(image: ImageStateType): void;
  bigIdea?: BigIdeaFragment;
  canEdit?: boolean;
}

export const BigIdeaImageUploader = ({
  errMsg,
  setErrMsg,
  imageState,
  setImageState,
  bigIdea,
  canEdit = false,
}: Props) => {
  const [, setImagePreviewUploading] = React.useState(false);
  const [isDragOver, setIsDragOver] = useState(false);

  const isDragActive = useDropZoneContext().isDragActive;

  const { getRootProps, getInputProps, open } = useDropzone({
    disabled: !canEdit,
    onDrop: async (acceptedFiles) => {
      setIsDragOver(false);
      setImagePreviewUploading(true);

      try {
        setErrMsg('');
        const file = acceptedFiles[0];

        if (!file) {
          throw Error('Upload failed');
        }
        const previewURL = { preview: URL.createObjectURL(file) };

        const withPreviewURL = Object.assign(file, previewURL);

        setImageState(withPreviewURL);
      } catch (error) {
        setErrMsg('Upload failed');
      } finally {
        setImagePreviewUploading(false);
      }
    },

    onDropRejected: async (evt) => {
      setImagePreviewUploading(false);
      const err = evt[0];
      const firstError = err.errors[0];

      if (JSON.stringify(firstError).includes('file-too-large')) {
        return setErrMsg('Max 10MB');
      }

      if (JSON.stringify(firstError).includes('file-invalid-type')) {
        return setErrMsg('Upload failed');
      }

      setErrMsg('Upload failed');
    },
    onDragEnter: () => {
      setIsDragOver(true);
    },
    onDragLeave: () => {
      setIsDragOver(false);
    },
    accept: 'image/*',
    maxFiles: 1,
    maxSize: 10485760,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <DropZoneWrapper
      isDragOver={isDragOver}
      isDragActive={isDragActive}
      hasImage={!!imageState}
      canEdit={canEdit}
      {...getRootProps({})}
    >
      <input {...getInputProps()} />

      <ImageWrapper onClick={open} canEdit={canEdit}>
        <ImageBG
          src={
            !!imageState && 'preview' in imageState ? imageState?.preview : ''
          }
        />
        <Image
          src={
            !!imageState && 'preview' in imageState ? imageState?.preview : ''
          }
        />
      </ImageWrapper>

      {!errMsg ? (
        <BackgroundIcon hasImage={!!imageState}>
          <Icon
            name="Camera"
            color={!!imageState ? colors.white : colors.purple}
            style={{ marginTop: 4 }}
            size={30}
          />
        </BackgroundIcon>
      ) : null}

      {!!errMsg ? (
        <ErrorMsg hasImage={!!imageState}>
          <span>{errMsg}</span>
          <span onClick={() => setErrMsg('')}>OK</span>
        </ErrorMsg>
      ) : (
        <div />
      )}

      {bigIdea && !errMsg ? (
        <Country>
          <CountryFlag size="sm" user={bigIdea.user} />
        </Country>
      ) : null}
    </DropZoneWrapper>
  );
};
