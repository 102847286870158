import React from 'react';

import { SubStep } from 'data/graphql/generated';
import { useCustomTotalNavHeight } from './data-access-goal-tracking';
import { CriticalMetricsNavbar } from 'components/3-5-critical-metrics/shared/shared-ui-critical-metrics/src';
import {
  PageContainer,
  HeaderContent,
  HeaderContentTitle,
  HeaderContentDescription,
  TabsContainer,
  MainContentContainer,
} from './CriticalMetricsPage.styles';

interface CriticalMetricsPageProps {
  subStep: SubStep.Indicators | SubStep.Goals | SubStep.Tracking;
  title: string;
  description: string;
  TabsComponent: React.ReactNode;
  children: React.ReactNode[];
  sidebarOffset: {
    offset: number;
    isHidden: boolean;
  };
}

export const CriticalMetricsPage: React.FC<CriticalMetricsPageProps> = ({
  subStep,
  title,
  description,
  TabsComponent,
  children,
  sidebarOffset,
}) => {
  const { totalNavHeight } = useCustomTotalNavHeight();

  return (
    <PageContainer>
      <CriticalMetricsNavbar substep={subStep} />

      <HeaderContent>
        <HeaderContentTitle>{title}</HeaderContentTitle>
        <HeaderContentDescription>{description}</HeaderContentDescription>

        <TabsContainer>{TabsComponent}</TabsContainer>
      </HeaderContent>
      <MainContentContainer
        totalNavHeight={totalNavHeight}
        sidebarOffset={sidebarOffset}
      >
        {children}
      </MainContentContainer>
    </PageContainer>
  );
};
