import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { createGlobalStyle, css } from 'styled-components/macro';
import { Stage as KonvaStage, Layer, Group } from 'react-konva';
import { useLocation } from 'react-router-dom';

import {
  CollaborationContext,
  useCollaborationContext,
} from 'contexts/CollaborationContext';
import { BlockTree, Country, CountryGlobal } from 'types';
import { useWidthHeight } from 'hooks/useWidthHeight';
import buildStrategyURL from 'utils/buildStrategyURL';
import { ButtonPill, Icon, Toggle, TooltipWrapper } from 'components/shared';
import { Navbar } from 'components/Navbar';
import {
  DismissedModals,
  PatientFlowBlock,
  PatientFlowBlockFragment,
  PatientFlowQuery,
  PatientFlowUpdateInput,
  PatientFlowValueUpsertInput,
  PatientJourneyColumn,
  PatientJourneyColumnFragment,
  PatientJourneyColumnUpdateInput,
  User,
} from 'data/graphql/generated';
import { StageView } from './Stage';
import { clamp } from 'utils/clamp';
import { ModalConfirmDelete } from 'components/ModalConfirmDelete';
import { removeByClassName } from 'components/PatientJourney/src/lib/utils/removeByClassName';
import {
  BLOCK_PADDING_TOP,
  NAV_HEIGHT,
  RECT_HEIGHT,
  RECT_WIDTH,
} from 'components/PatientFlow/constants';
import { colors, zIndex } from 'constants/index';
import { Connection } from './Connection';
import { PercentageToggleDropdown } from './PercentageToggleDropdown';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { YellowHeaderModal } from 'components/shared/YellowHeaderModal';
import { makeRenderStageTextInput } from './renderStageTextInput';
import { CountriesDropdownToggle } from './CountriesDropdownToggle';
import { CountriesDropdown } from '../shared/CountriesDropdown';
import { FlowProgressCheckbox } from './FlowProgressCheckbox';
import { constructTree } from './constructTree';
import orderBy from 'lodash/orderBy';
import { exportAsSvg } from 'components/PatientJourney/src/lib/utils/exportAsSvg';
import Konva from 'konva';
import { device, size } from 'utils/breakpoints';
import { ReusableCannotEditModal } from './ReusableCannotEditModal';
import useMobile from 'hooks/useMobile';
import { ToggleValue } from 'components/shared/Toggle';
import { verifyUserRole } from 'utils/verifyUserRole';
import { BlockContainer } from './BlockContainer';
import { Stage } from 'konva/types/Stage';

const StyledToggle = styled(Toggle)`
  align-items: center;
  ${ToggleValue} {
    border: 1px solid ${colors.white};
    // increase hit area to 30px
    margin: 5px 0;
  }

  p {
    color: ${colors.white};
  }
`;

const CTAWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

const NavItemsWrapper = styled.div`
  display: flex;
  gap: 15px;

  @media ${device.tabletMax} {
    margin-top: 15px;
    margin-bottom: -10px;

    width: 100%;
  }

  @media ${device.mobile} {
    align-items: center;
    width: 100%;
    margin-top: 15px;
    margin-bottom: -10px;
  }
`;

const FlowProgressWrapper = styled.div<{ notAllowed: boolean }>`
  ${({ notAllowed }) => {
    if (notAllowed) {
      return css`
        * {
          cursor: not-allowed;
        }
      `;
    }
  }}

  @media ${device.tabletMax} {
    margin-left: auto;
  }
`;

const StyledCountriesDropdownToggle = styled(CountriesDropdownToggle)<{
  globalLayoutCompleted: boolean;
}>`
  opacity: ${({ globalLayoutCompleted }) => (globalLayoutCompleted ? 1 : 0.5)};
  transition: opacity 0.3s;
`;

const StyledPercentageToggleDropdown = styled(PercentageToggleDropdown)`
  position: absolute;
  top: 41px;
  left: 0;
`;

const StageTextareaStyle = createGlobalStyle`
.stage-textarea{
  position: absolute;
  background: #F8F8F9;
  text-align: center;
  z-index: ${zIndex.patientCanvasTextArea};
  font-family: ABCFavorit;
  line-height: 20px;
  font-size: 16px;
  font-weight: 400;
  height: 20px;
  padding: 5px;
  box-sizing: content-box;

  overflow: hidden;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  border: 0.5px solid #00a1d3;
}

.stage-background {
  z-index: ${zIndex.patientCanvasTextAreaBG};
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
`;

const Processing = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: block;
  cursor: wait;
  z-index: 999999;
`;

const PatientJourneyNav = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PercentageToggle = styled.div<{
  active: boolean;
  isDisabled: boolean;
}>`
  position: relative;
  width: 60px;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: ${({ active }) =>
    active ? colors.purple80a : 'transparent'};
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 5px;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.3 : 1)};
  &:hover {
    background-color: ${({ active, isDisabled }) =>
      isDisabled
        ? 'transparent'
        : active
        ? colors.purple80a
        : colors.purple30a};
  }
`;

const Wrapper = styled.div`
  margin-top: 155px;
  background: #dbdbdb;

  @media ${device.desktopMin} {
    margin-top: ${NAV_HEIGHT}px;
  }
`;

const StageWrapper = styled.div`
  overflow: hidden;
`;

interface Props {
  user: User | undefined;
  drugId: number;
  strategyId: number;
  loading: boolean;
  setPatientFlowRegionComplete(regions: CountryGlobal[]): Promise<void>;
  completedRegions: string[];
  patientFlow?: PatientFlowQuery['patientFlow'];
  stages?: PatientFlowQuery['patientJourneyColumns'];
  blocks?: PatientFlowQuery['patientFlowBlocks'];
  values?: PatientFlowQuery['patientFlowValues'];
  patientFlowUpdate: (patientFlowData: PatientFlowUpdateInput) => Promise<void>;
  patientFlowBlockCreate(
    columnId: number,
    parentId: number
  ): Promise<PatientFlowBlock | undefined>;
  patientFlowBlockUpdate(
    id: number,
    patientFlowBlock: Partial<PatientFlowBlock>
  ): void;
  patientFlowBlockDelete(id: number): void;
  patientFlowColumnUpdate(
    data: PatientJourneyColumnUpdateInput & { id: number }
  ): void;
  upsertPatientFlowValue(
    layoutBlocks: BlockTree[] | undefined
  ): (
    id: number | undefined,
    data: Omit<PatientFlowValueUpsertInput, 'drugId' | 'strategyId'>,
    isPercentage: boolean
  ) => Promise<void>;
  setDistributionModalOpen: React.Dispatch<
    React.SetStateAction<
      | false
      | {
          title: string;
          type: 'block' | 'stage';
          id: number;
        }
    >
  >;
  country: CountryGlobal;
  setCountry: React.Dispatch<React.SetStateAction<CountryGlobal>>;
  allCountriesExcludingGlobal: Country[];
  valueWasEditedByMR: boolean;
  disableActions: boolean;
  showStarringModal(block: PatientFlowBlock): void;
}

const getParentHeight = (
  block: PatientFlowBlockFragment,
  stageBlocks: PatientFlowBlockFragment[]
) => {
  if (typeof block.parentId === 'number') {
    const parent = stageBlocks.find((pBlock) => pBlock.id === block.parentId);
    let total = 0;
    if (parent) {
      total = getParentHeight(parent, stageBlocks);
    }
    return total + (parent?.height ? parent?.height + BLOCK_PADDING_TOP : 0);
  } else return 0;
};

export function calcStageHeights(
  blocks: PatientFlowBlockFragment[],
  columns: PatientJourneyColumnFragment[]
) {
  return columns.reduce((colsById, col, idx) => {
    //for each stage, get all the blocks and find the family tree with the largest total height
    const stageBlocks = blocks.filter((block) => block.columnId === col.id);

    if (stageBlocks.length < 1) {
      colsById[idx] = 125;
      return colsById;
    }

    const totalTreeHeights = stageBlocks.map((block) => {
      let currentHeight = block.height + BLOCK_PADDING_TOP;

      currentHeight += getParentHeight(block, stageBlocks);
      return currentHeight;
    });

    colsById[idx] = Math.max(...totalTreeHeights);

    return colsById;
  }, [] as number[]);
}

const getStageParentBuffer = (
  block: BlockTree,
  blocks: PatientFlowBlockFragment[]
): number => {
  // find all blocks for this block's stages
  const stageBlocks = blocks.filter((b) => b.columnId === block.columnId);

  return getParentHeight(block, stageBlocks);
};

export const PatientFlowView: React.FC<Props> = ({
  user,
  drugId,
  strategyId,
  loading,
  completedRegions,
  setPatientFlowRegionComplete,
  patientFlow,
  stages,
  blocks,
  values,
  patientFlowUpdate,
  patientFlowBlockCreate,
  patientFlowBlockUpdate,
  patientFlowBlockDelete,
  patientFlowColumnUpdate: updateColumn,
  upsertPatientFlowValue,
  setDistributionModalOpen,
  country,
  setCountry,
  allCountriesExcludingGlobal,
  valueWasEditedByMR,
  disableActions,
  showStarringModal,
}) => {
  const stageRef = useRef<Konva.Stage | null>(null);
  const [showAsPercentage, setShowAsPercentage] = useState(false);
  const collaborationContextData = useCollaborationContext();

  const [confirmDeleteModalId, setConfirmDeleteModalId] = useState<
    number | null
  >(null);

  const [blockHover, setBlockHover] = useState<false | number>(false);
  /**
   * Makes sure the hover side effects are only present on one block at a time
   */
  const hoverTimeout = useRef<null | NodeJS.Timeout>(null);

  const handleBlockMouseEnter = (id: number) => {
    hoverTimeout.current && clearTimeout(hoverTimeout.current);
    setBlockHover(id);
  };

  const handleBlockMouseLeave = () => {
    hoverTimeout.current = setTimeout(() => {
      blockHover && setBlockHover(false);
    }, 200);
  };

  const [editing, setEditing] = useState<number>(); // block id

  // update size on window change
  const { width: windowWidth, height } = useWidthHeight();

  const changeCursor = useCallback((type) => {
    if (!stageRef?.current) return;
    if (type === 'grabbing') {
      stageRef.current.container().style.cursor = 'grabbing';
    } else if (type === 'default') {
      stageRef.current.container().style.cursor = 'grab';
    } else {
      stageRef.current.container().style.cursor = type;
    }
  }, []);

  useEffect(() => {
    return changeCursor('grab');
  }, [changeCursor]);

  // Hide all textareas on page change
  const location = useLocation();
  useEffect(() => {
    return () => {
      removeByClassName(`block-textarea`);
      removeByClassName(`block-background`);
      removeByClassName(`block-delete`);
    };
  }, [location]);

  const [layoutBlocks, setLayoutBlocks] = useState<BlockTree[] | undefined>();

  useLayoutEffect(() => {
    setLayoutBlocks(
      constructTree(
        blocks?.items as PatientFlowBlock[],
        blocks?.items.find((b) => b?.default) as PatientFlowBlock
      )
    );
    // the state of values updates which causes this to rerender.
    // Values are usually also updated when the block position is updated
  }, [blocks, values]);

  const upsertPatientFlowValueWithLayoutBlocks = upsertPatientFlowValue(
    layoutBlocks
  );
  const layerColumnRef = useRef(null);

  const showColumnTextarea = makeRenderStageTextInput({
    removeByClassName,
    layerColumnRef,
    stageRef,
    updateColumn,
    classnamePrefix: 'stage',
  });

  const stageItems = orderBy(stages?.items, ['idx'], ['asc']);
  const topStage = stageItems[0];
  const bottomStage = stageItems[stageItems.length - 1];

  function getNextStage(stageId: number): number | undefined {
    if (!stages || !stages.items) return;
    const stage = stages?.items.find((s) => s.id === stageId);
    if (!stage) return;
    const nextStage = stages?.items.find((x) => x.idx === stage.idx + 1);
    if (!nextStage) return;
    return nextStage.id;
  }

  function getPreviousStage(stageId: number): number | undefined {
    if (!stages || !stages.items) return;
    const stage = stages?.items.find((s) => s.id === stageId);
    if (!stage) return;
    const previousStage = stages?.items.find((x) => x.idx === stage.idx - 1);
    if (!previousStage) return;
    return previousStage.id;
  }

  const furthestBlockOffsetLeft = useMemo(
    () =>
      (layoutBlocks?.reduce((acc, val) => {
        if (val.left > acc) return val.left;
        return acc;
      }, 1) || 1) + 1,
    [layoutBlocks]
  );

  const OFFSET = 200;
  const layoutBlockWidth = furthestBlockOffsetLeft * (RECT_WIDTH + 20);
  const layoutBlockWidthWithOffset = layoutBlockWidth + OFFSET * 2;

  const leftOffset = OFFSET;
  const blocksWiderThanWindow = layoutBlockWidthWithOffset > windowWidth;

  //Dropdown
  const [showPercentageToggle, setShowPercentageToggle] = useState(false);
  const dropDownRef = useRef(null);

  const [countryDropdownActive, setCountryDropdownActive] = useState(false);

  useClickOutsideComponent(dropDownRef, () => {
    setShowPercentageToggle(false);
    setCountryDropdownActive(false);
  });

  const [leftShift, setLeftShift] = useState(0);

  useLayoutEffect(() => {
    if (!layoutBlocks?.length) return;
    const origBlockPos = layoutBlocks[0].left * (RECT_WIDTH + 20);
    const desiredXPos = windowWidth / 2 - RECT_WIDTH / 2;

    if (blocksWiderThanWindow) {
      setLeftShift(
        Math.min(
          desiredXPos - (origBlockPos + leftOffset),
          // issues on mobile can result in an offset that incorrectly offsets the canvas
          0
        )
      );
    } else {
      setLeftShift(desiredXPos - origBlockPos);
    }
  }, [layoutBlocks, windowWidth, blocksWiderThanWindow, leftOffset]);

  function allValuesAddUp() {
    return layoutBlocks?.every((block) => {
      if (!block.children.length) {
        return true;
      }
      const blockValue =
        values?.items.find((value) => value?.patientFlowBlockId === block?.id)
          ?.value || 0;

      const parentIsIndependent = () => {
        let parent = block.parent;
        let canBeIndependent = false;
        while (parent && !canBeIndependent) {
          if (parent?.isIndependent) {
            canBeIndependent = true;
          }
          parent = parent?.parent;
        }
        return canBeIndependent;
      };

      const isIndependent = block.isIndependent || parentIsIndependent();

      const childrenSum = isIndependent
        ? blockValue
        : block.children.reduce((acc, currentVal) => {
            const childValue = currentVal.isIndependent
              ? 0
              : values?.items.find(
                  (value) => value?.patientFlowBlockId === currentVal.id
                )?.value || 0;

            return childValue + acc;
          }, 0);

      return blockValue === childrenSum;
    });
  }

  const [showWarningModal, setShowWarningModal] = useState<
    { heading: string; message: string; buttons?: React.ReactNode } | false
  >(false);

  const { isLead, isContributor } = verifyUserRole(user?.role, user?.country);

  const userCanEdit =
    country === 'global' ? isLead : isContributor && user?.country === country;

  useEffect(() => {
    if (userCanEdit) {
      // If the person viewing this page can make edits,
      // update the countriesVisitedSinceLastUpdate so we know the page has been visited
      const countriesVisitedSinceLastUpdate =
        patientFlow?.countriesVisitedSinceLastUpdate;

      if (
        country !== 'global' &&
        countriesVisitedSinceLastUpdate &&
        !countriesVisitedSinceLastUpdate.includes(country)
      ) {
        setShowWarningModal({
          heading: 'Patient Flow layout updated',
          message:
            'The layout has been updated by the Lead. Please check your figures and mark the diagram as complete when you’re ready.',
        });

        patientFlowUpdate({
          countriesVisitedSinceLastUpdate: [
            ...countriesVisitedSinceLastUpdate,
            country,
          ],
        });
      }
    }
  }, [
    country,
    patientFlow?.countriesVisitedSinceLastUpdate,
    patientFlowUpdate,
    userCanEdit,
  ]);

  const [stageHeights, setStageHeights] = useState(
    calcStageHeights(
      blocks?.items || [],
      (stages?.items as PatientJourneyColumn[]) || []
    )
  );

  useEffect(() => {
    setStageHeights(
      calcStageHeights(
        blocks?.items || [],
        (stages?.items as PatientJourneyColumn[]) || []
      )
    );
  }, [blocks?.items, stages?.items]);

  const [stageStartingPoints, setStageStartingPoints] = useState<number[]>([]);

  useEffect(() => {
    const { heights } = stageHeights.reduce(
      (acc, height) => {
        const total = acc.total + height;
        acc.heights.push(total);
        acc.total = total;
        return acc;
      },
      { total: 0, heights: [0] as number[] }
    );
    setStageStartingPoints(heights);
  }, [stageHeights]);

  const colsById = stages?.items.reduce((colsById, col) => {
    colsById[col.id] = col;
    return colsById;
  }, {} as Record<number, PatientJourneyColumn>);

  // patientFlow
  const [globalLayoutCompleted, setGlobalLayoutCompleted] = useState(
    !!patientFlow?.layoutCompleted
  );

  useEffect(() => {
    setGlobalLayoutCompleted(!!patientFlow?.layoutCompleted);
  }, [patientFlow]);

  const allCountriesCompleted =
    completedRegions.length > 0 &&
    completedRegions.length === allCountriesExcludingGlobal.length &&
    globalLayoutCompleted;

  const isMobile = useMobile();
  const isDesktop = windowWidth > parseInt(size.tablet);

  const layoutNotComplete =
    country === 'global'
      ? !globalLayoutCompleted
      : !completedRegions.includes(country);

  async function onLayoutCompleteHandler() {
    if (country === 'global') {
      patientFlowUpdate({ layoutCompleted: true });
      return;
    }

    if (allValuesAddUp()) {
      setPatientFlowRegionComplete([
        ...Array.from(
          new Set([...(completedRegions as CountryGlobal[]), country])
        ),
      ]);
    } else {
      setShowWarningModal({
        heading:
          'Cannot mark as complete because branches do not add up correctly',
        message:
          'There are branches in your diagram that do not sum to a valid total. Edit values until each parent block shows a check mark below it, then try again.',
      });
    }
  }

  async function onLayoutIncompleteHandler() {
    if (country === 'global' && valueWasEditedByMR) {
      setShowWarningModal({
        heading: 'Editing the layout may impact market figures',
        message:
          'If collaborators have begun working on their figures, editing the layout may impact their work. Completed market versions will revert to “In progress”.',
        buttons: (
          <CTAWrapper>
            <ButtonPill
              level="secondary"
              width="180px"
              text="Cancel"
              onClick={() => {
                setShowWarningModal(false);
              }}
            />
            <ButtonPill
              width="180px"
              text="Continue to edit"
              onClick={() => {
                if (country === 'global') {
                  patientFlowUpdate({
                    layoutCompleted: false,
                  });
                  setShowWarningModal(false);
                  return;
                }
                const index = completedRegions.indexOf(country);
                const copyArr = completedRegions.slice();
                copyArr.splice(index, 1);

                if (index > -1) {
                  setPatientFlowRegionComplete(copyArr as CountryGlobal[]);
                }
                setShowWarningModal(false);
              }}
            />
          </CTAWrapper>
        ),
      });

      return;
    }

    if (country === 'global') {
      await patientFlowUpdate({ layoutCompleted: false });
      return;
    }

    const index = completedRegions.indexOf(country);
    const copyArr = completedRegions.slice();
    copyArr.splice(index, 1);

    if (index > -1) {
      setPatientFlowRegionComplete(copyArr as CountryGlobal[]);
    }
  }

  const onCheckboxToggle = async () => {
    if (!userCanEdit) {
      return;
    }
    layoutNotComplete ? onLayoutCompleteHandler() : onLayoutIncompleteHandler();
  };

  const canvasVisibleHeight = height - NAV_HEIGHT() - 2;

  const canvasWidth =
    layoutBlockWidthWithOffset > windowWidth
      ? layoutBlockWidthWithOffset
      : windowWidth;

  const canvasTotalHeight = stageHeights.reduce((a, b) => a + b, 0);
  const maxScrollableX = windowWidth - canvasWidth;
  const maxScrollableY = -Math.max(
    canvasVisibleHeight,
    canvasTotalHeight - canvasVisibleHeight + RECT_HEIGHT
  );

  const handleIndependentToggle = () => {
    patientFlowUpdate({
      independentBlockUpdated: new Date().toISOString(),
    });
  };

  return (
    <>
      {!loading && !userCanEdit && (
        <ReusableCannotEditModal
          title={'Cannot edit this Patient Flow'}
          text={
            "This Patient Flow is view only. Return to your own market's view using the toggle at the top of the page to make changes."
          }
          userCanEdit={userCanEdit}
          user={user}
          modalName={DismissedModals.PatientFlowCannotEdit}
          localStorageString={'showPatientFlowEditModal'}
        />
      )}

      {disableActions ? <Processing /> : null}
      <StageTextareaStyle />
      <ModalConfirmDelete
        visible={!!confirmDeleteModalId}
        handleClose={() => setConfirmDeleteModalId(null)}
        handleConfirm={() =>
          confirmDeleteModalId
            ? patientFlowBlockDelete(confirmDeleteModalId)
            : null
        }
        title="Delete this block and children?"
        text="This action will will also delete linked blocks below (children). Any discussion and files associated with these blocks will be lost."
        leftButtonText="Cancel"
        rightButtonText="Delete with children"
      />
      <Navbar
        stepNumber="2.2"
        prev={{
          title: 'Strategic Question',
          url: buildStrategyURL(String(drugId), String(strategyId), '2_1'),
        }}
        next={{
          title: 'Evidence Generation Ideas',
          url: buildStrategyURL(String(drugId), String(strategyId), '2_3'),
        }}
        title="Patient Flow"
        url={`/d/${drugId}/strategy/${strategyId}?nav=1`}
        navMenuChildren={
          <ButtonPill
            iconName="Export"
            text={'Export Patient Flow'}
            level="export"
            onClick={exportAsSvg(
              stageRef,
              canvasWidth,
              canvasWidth,
              canvasTotalHeight,
              'Patient Flow'
            )}
          />
        }
      >
        <PatientJourneyNav>
          <NavItemsWrapper>
            <PercentageToggle
              className="cypress-percentage-toggle"
              active={showPercentageToggle}
              isDisabled={!globalLayoutCompleted}
              onClick={() => {
                if (globalLayoutCompleted) {
                  setShowPercentageToggle(!showPercentageToggle);
                }
              }}
            >
              <Icon
                name={showAsPercentage ? 'Percent' : 'Hash'}
                size={30}
                color={colors.white}
              />
              <Icon
                name={showPercentageToggle ? 'Chevron-up' : 'Chevron-down'}
                size={20}
                color={colors.white}
              />

              <StyledPercentageToggleDropdown
                innerRef={dropDownRef}
                showDropdown={showPercentageToggle}
                setShowDropdown={setShowPercentageToggle}
                setShowAsPercentage={setShowAsPercentage}
                showAsPercentage={showAsPercentage}
                labels={['Edit figures', 'View percentage']}
              />
            </PercentageToggle>

            <StyledCountriesDropdownToggle
              className={'cypress-countries-dropdown'}
              globalLayoutCompleted={globalLayoutCompleted}
              viewOnly={!userCanEdit}
              country={country}
              active={countryDropdownActive}
              onClick={() =>
                globalLayoutCompleted
                  ? setCountryDropdownActive(!countryDropdownActive)
                  : setShowWarningModal({
                      heading: 'Cannot switch to a local market view',
                      message:
                        'The layout is still a work in progress. When it’s marked as complete by a Lead, figures for each market can be populated.',
                    })
              }
              hideCountryName={isMobile}
            >
              <CountriesDropdown
                innerRef={dropDownRef}
                showDropdown={countryDropdownActive}
                setShowDropdown={setShowPercentageToggle}
                activeCountry={country}
                setCountry={setCountry}
                allCountries={allCountriesExcludingGlobal}
              />
            </StyledCountriesDropdownToggle>
            {!isMobile ? (
              <>
                {layoutNotComplete ? (
                  <FlowProgressWrapper notAllowed={!userCanEdit}>
                    <TooltipWrapper
                      place="bottom"
                      text={
                        country === 'global' && !isLead
                          ? 'Only Leads can mark complete'
                          : ''
                      }
                    >
                      <FlowProgressCheckbox
                        className="cypress-progress-btn"
                        isDisabled={!userCanEdit}
                        messaging={
                          country === 'global'
                            ? [
                                'Layout in progress',
                                userCanEdit
                                  ? 'Click to finalise and start sizing'
                                  : '',
                              ]
                            : [
                                'In progress',
                                userCanEdit ? 'Click to mark complete' : '',
                              ]
                        }
                        onClick={onCheckboxToggle}
                      />
                    </TooltipWrapper>
                  </FlowProgressWrapper>
                ) : (
                  <FlowProgressWrapper notAllowed={!userCanEdit}>
                    <FlowProgressCheckbox
                      className="cypress-progress-btn"
                      isDisabled={!userCanEdit}
                      complete
                      messaging={
                        country === 'global'
                          ? [
                              'Layout completed',
                              userCanEdit ? 'Uncheck to edit layout' : '',
                            ]
                          : ['Complete', userCanEdit ? 'Click to edit' : '']
                      }
                      onClick={onCheckboxToggle}
                    />
                  </FlowProgressWrapper>
                )}
              </>
            ) : (
              <StyledToggle
                enableLabelClick
                isSmall
                disabled={!userCanEdit}
                value={!layoutNotComplete}
                onChange={onCheckboxToggle}
                label={
                  !globalLayoutCompleted
                    ? 'Layout in progress'
                    : 'Layout completed'
                }
                className="observations--toggle"
              />
            )}
          </NavItemsWrapper>
          {isDesktop && (
            <ButtonPill
              iconName="Export"
              text={'Export'}
              level="export"
              onClick={exportAsSvg(
                stageRef,
                canvasWidth,
                canvasWidth,
                canvasTotalHeight,
                'Patient Flow'
              )}
            />
          )}
        </PatientJourneyNav>
      </Navbar>
      <YellowHeaderModal
        handleClose={() => setShowWarningModal(false)}
        visible={!!showWarningModal}
        heading={showWarningModal ? showWarningModal.heading : ''}
        message={showWarningModal ? showWarningModal.message : ''}
      >
        {showWarningModal && showWarningModal?.buttons ? (
          showWarningModal?.buttons
        ) : (
          <ButtonPill
            width="180px"
            text="OK"
            onClick={() => {
              setShowWarningModal(false);
            }}
          />
        )}
      </YellowHeaderModal>
      <Wrapper>
        {loading ? (
          <span>Loading...</span>
        ) : (
          <div className="patient-flow">
            <StageWrapper>
              <KonvaStage
                ref={stageRef}
                width={windowWidth}
                height={canvasVisibleHeight}
                x={blocksWiderThanWindow ? leftShift : 0}
                y={0}
                draggable
                dragBoundFunc={(pos) => {
                  return {
                    x: clamp(pos.x, maxScrollableX, 0),
                    y: clamp(pos.y, maxScrollableY, 0),
                  };
                }}
                onDragStart={() => {
                  changeCursor('grabbing');
                }}
                onDragEnd={(e) => {
                  changeCursor('default');
                }}
              >
                <CollaborationContext.Provider value={collaborationContextData}>
                  <Layer ref={layerColumnRef}>
                    {stages?.items.map((stage) => {
                      if (!stage) return null;
                      return (
                        <StageView
                          stage={stage}
                          key={stage.id}
                          canvasWidth={canvasWidth + 200}
                          changeCursor={changeCursor}
                          showColumnTextarea={showColumnTextarea}
                          setDistributionModalOpen={setDistributionModalOpen}
                          stageHeights={stageHeights}
                          stageStartingPoints={stageStartingPoints}
                          country={country}
                          globalLayoutCompleted={globalLayoutCompleted}
                          stageRef={stageRef}
                        />
                      );
                    })}

                    <Group
                      name="block-layer"
                      x={blocksWiderThanWindow ? leftOffset : leftShift}
                      y={20}
                    >
                      {layoutBlocks?.map((b) => {
                        if (!b || !colsById) return null;
                        const x = b.left * (RECT_WIDTH + 20);
                        const column = colsById[b.columnId];
                        const y =
                          (stageStartingPoints[column.idx] || 1) +
                          getStageParentBuffer(b, blocks?.items || []);
                        return (
                          <Group key={b.id}>
                            <BlockContainer
                              showWarningModal={({
                                heading,
                                message,
                              }: {
                                heading: string;
                                message: string;
                              }) =>
                                setShowWarningModal({
                                  heading,
                                  message,
                                })
                              }
                              onIndependentClick={handleIndependentToggle}
                              layoutBlocks={layoutBlocks}
                              blocks={blocks}
                              handleBlockMouseEnter={handleBlockMouseEnter}
                              handleBlockMouseLeave={handleBlockMouseLeave}
                              blockHover={blockHover}
                              b={b}
                              userCanEdit={userCanEdit}
                              user={user}
                              x={x}
                              y={y}
                              column={column}
                              values={values}
                              setEditing={setEditing}
                              editing={editing}
                              getNextStage={getNextStage}
                              getPreviousStage={getPreviousStage}
                              topStage={topStage}
                              bottomStage={bottomStage}
                              changeCursor={changeCursor}
                              stageRef={stageRef}
                              patientFlowBlockCreate={patientFlowBlockCreate}
                              windowWidth={windowWidth}
                              canvasHeight={canvasVisibleHeight}
                              maxScrollableX={maxScrollableX}
                              maxScrollableY={maxScrollableY}
                              patientFlowBlockUpdate={patientFlowBlockUpdate}
                              setConfirmDeleteModalId={setConfirmDeleteModalId}
                              patientFlowBlockDelete={patientFlowBlockDelete}
                              upsertPatientFlowValueWithLayoutBlocks={
                                upsertPatientFlowValueWithLayoutBlocks
                              }
                              showAsPercentage={showAsPercentage}
                              setDistributionModalOpen={
                                setDistributionModalOpen
                              }
                              globalLayoutCompleted={globalLayoutCompleted}
                              country={country}
                              completedRegions={completedRegions}
                              allCountriesCompleted={allCountriesCompleted}
                              showStarringModal={showStarringModal}
                              blocksWiderThanWindow={blocksWiderThanWindow}
                              leftOffset={leftOffset}
                              leftShift={leftShift}
                              getStageParentBuffer={getStageParentBuffer}
                            />
                            <Connections
                              b={b}
                              layoutBlocks={layoutBlocks}
                              column={column}
                              stageRef={stageRef}
                              leftOffset={
                                blocksWiderThanWindow ? leftOffset : leftShift
                              }
                            />
                          </Group>
                        );
                      })}
                    </Group>
                  </Layer>

                  <Layer name="top-layer" />
                </CollaborationContext.Provider>
              </KonvaStage>
            </StageWrapper>
          </div>
        )}
      </Wrapper>
    </>
  );
};

const Connections = ({
  b,
  layoutBlocks,
  column,
  stageRef,
  leftOffset,
}: {
  b: BlockTree;
  layoutBlocks: BlockTree[];
  column: PatientJourneyColumn;
  stageRef: React.RefObject<Stage>;
  leftOffset: number;
}) => {
  return (
    <>
      {b.children.map(({ id, isIndependent }) => {
        const dash =
          isIndependent || b.hasIndependentAncestor || b.isIndependent;

        return (
          <Connection
            key={id}
            stageRef={stageRef}
            leftOffset={leftOffset}
            parentId={b.id}
            childId={id}
            blocks={layoutBlocks}
            column={column}
            dash={dash}
          />
        );
      })}
    </>
  );
};
