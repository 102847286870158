import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import { colors } from 'constants/colors';
import { device } from 'utils/breakpoints';
import { zIndex } from 'constants/zIndex';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import useDesktop from 'hooks/useDesktop';
import {
  gridPageSidebarSidePaddingMobile,
  gridPageSidebarSidePaddingDesktop,
} from 'constants/index';
import { PostItsEmpty } from 'components/PostItsEmpty';
import { BodySmall, Subtitle2 } from '../shared';
import { Link } from 'react-router-dom';

const pageContentWidthDesktop = 645;

const Wrapper = styled.div<{
  sidebarOpen: boolean;
  offsetTop: boolean;
}>`
  width: 300px;
  min-width: 300px;
  background: ${colors.black05};
  z-index: ${zIndex.commercialTacticsSidebar};
  padding: 15px ${gridPageSidebarSidePaddingMobile}px 10px
    ${gridPageSidebarSidePaddingMobile}px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;

  position: fixed;
  margin-top: 50px;
  top: 0;
  bottom: 0;
  right: 0;
  transform: ${({ sidebarOpen }) => `translateX(${sidebarOpen ? 0 : 100}%)`};
  transition: transform 0.2s linear;

  @media ${device.desktopMin} {
    position: relative;
    padding: ${gridPageSidebarSidePaddingDesktop}px;
    margin-top: 0;
    max-width: 555px;
    width: calc(100% - ${pageContentWidthDesktop}px);
    z-index: ${zIndex.commercialTacticsSidebarDesktop};
    height: 100%;
  }
`;

export const DataList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ListContainer = styled.div`
  border-radius: 5px;
  border: 1px solid ${colors.greyMedium};
  padding: 10px;
`;

export const DropOverlay = styled.div<{ top: number }>`
  position: absolute;
  inset: 80px 15px 15px;
  background: ${colors.white90};
  border: 3px dashed ${colors.purple};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  @media ${device.desktopMin} {
    inset: 90px 20px 0px;
    inset: ${({ top }) => `${top}px 20px 0`};
  }
`;

export const SidebarEmptyState = styled(PostItsEmpty)`
  border: none;
  position: absolute;
  inset: 80px 15px 15px;
  height: auto;
  background: ${colors.white50};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0;

  ${Subtitle2} {
    margin-bottom: 5px;
  }

  @media ${device.desktopMin} {
    inset: 80px 20px 20px;
  }
`;

export const TransitionList = styled.ul`
  margin: 0;
  margin-top: 10px;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ListEmptyState = styled.div`
  border: none;
  background: ${colors.white50};
  align-items: center;
  margin-top: 10px;
  padding-top: 0;
  border-radius: 5px;
  padding: 15px;
  text-align: center;
`;

interface Props {
  sidebarOpen: boolean;
  offsetTop: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
}

export const GridPageSidebar: React.FC<Props> = ({
  sidebarOpen,
  setSidebarOpen,
  offsetTop,
  children,
}) => {
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const isDesktop = useDesktop();

  const closeSidebar = () => {
    if (isDesktop) return;
    setSidebarOpen(false);
  };
  useClickOutsideComponent(sidebarRef, closeSidebar, ['collaboration-modal']);

  return (
    <Wrapper sidebarOpen={sidebarOpen} offsetTop={offsetTop} ref={sidebarRef}>
      {children}
    </Wrapper>
  );
};

export function ReusableListEmptyState({
  title,
  subtitle,
  link,
  linkText,
}: {
  title: string;
  subtitle: string;
  link?: string;
  linkText?: string;
}) {
  return (
    <ListEmptyState>
      <Subtitle2 style={{ marginBottom: 5 }} color={colors.greyDark}>
        {title}
      </Subtitle2>
      <BodySmall
        color={colors.greyDark}
        style={{
          display: 'inline',
        }}
      >
        {subtitle}
      </BodySmall>
      {link && linkText && (
        <Link to={link}>
          <BodySmall
            style={{
              fontWeight: 500,
              display: 'inline',
              pointerEvents: 'auto',
              cursor: 'pointer',
            }}
            color={colors.greyDark}
          >
            {' '}
            {linkText}
          </BodySmall>
        </Link>
      )}
    </ListEmptyState>
  );
}
