import {
  BodySmall,
  CheckCircle,
  Collaboration,
  Subtitle2,
  Toggle,
  ScoreCircle,
  Tooltip,
  ButtonLabel,
  Icon,
  TabGroup,
} from 'components/shared';
import { colors } from 'constants/index';
import {
  createRef,
  RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import styled from 'styled-components/macro';
import { TreatmentIcon } from './TreatmentIcon';

// https://github.com/tsayen/dom-to-image
// This allows us to convert the html to an Image, and allows you to style the exported Component
// Works on Safari
// import domtoimage from 'dom-to-image';
import {
  CompetitiveLandscapeOpportunityFragment,
  CompetitiveLandscapeOpportunityUpdateMutationFn,
  useCompetitiveLandscapeOpportunityUpdateMutation,
  CompetitorFragment,
  PostItGroupFragment,
  Stakeholder,
  useCompetitorUpdateMutation,
  CompetitorDocument,
  CompetitiveLandscapeRatingFragment,
  CompetitiveLandscapeOpportunity,
  useStrategyWithDrugQuery,
} from 'data/graphql/generated';
import { average } from 'utils/average';
import { useAuthContext } from 'contexts/AuthContext';
import ReactTooltip from 'react-tooltip';
import { useWidth } from 'hooks/useWidth';
import { EvaluationGraphs } from './EvaluationGraphs';
import { GraphScroller } from './GraphScroller';
import {
  DistributionModal,
  DistributionModalProps,
  DistributionModalValues,
} from './DistributionModal';
import lodash, { throttle } from 'lodash';
import { ErrorModal } from 'components/ErrorModal';
import { Loading } from 'components/Loading';
import { device } from 'utils/breakpoints';
import { Image } from './CompetitorTab';
import { verifyUserRole } from 'utils/verifyUserRole';

const GraphsWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1;
  height: 100%;
`;

const TitleWrapper = styled.div<{ showDistribution: boolean }>`
  min-width: 260px;
  max-width: 260px;
  background: ${colors.white};
  border-radius: 5px 0px 0px 5px;

  @media (max-width: 714px) {
    max-width: none;
    border-radius: ${({ showDistribution }) =>
      showDistribution ? '5px' : '5px 5px 0px 0px'};
  }
`;

const ScoreCircleWrapper = styled.div`
  background: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

const TopRow = styled.div`
  display: flex;
  align-items: center;

  > p {
    margin: 0 auto 0 5px;
  }
`;

const InnerWrapper = styled.div`
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;

  gap: 5px;
`;

const Row = styled.div<{ showDistribution: boolean }>`
  min-height: 125px;
  background: transparent;

  display: flex;
  gap: 2px;

  overflow: hidden;

  @media (max-width: 714px) {
    flex-direction: column;
    gap: ${({ showDistribution }) => (showDistribution ? '5px' : '2px')};

    height: auto;
  }
`;

const containerHeight = 82;

export const CompetitorTitleContainer = styled.div`
  min-width: 240px;
  height: ${containerHeight}px;
  background: ${colors.white};
  padding: 5px 5px 7px;
  border-radius: 5px 5px 0 0;

  @media ${device.desktopMin} {
    max-width: 240px;
  }
`;

export const CompetitorTitleWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const CompetitorRating = styled.div`
  background: ${colors.purple10};
  margin-top: 5px;
  border-radius: 15px;
  height: 25px;
  text-align: center;
  padding-top: 3px;
  font-size: 15px;

  span {
    color: ${colors.black70};
  }
`;

export const CompetitorImage = styled(Image)<{ imageURL: string }>`
  margin-right: 5px;
  border-radius: 50%;
  image-rendering: -webkit-optimize-contrast;
`;

export const CompetitorTitle = styled(ButtonLabel)`
  margin-right: 10px;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  pointer-events: none;
`;

export const StarIcon = styled(Icon)`
  opacity: 0.5;
  margin-right: -5px;

  svg {
    margin-left: -10px;
  }
`;

const StarToggle = styled(Icon)<{ starred: boolean }>`
  background: ${colors.purple10};
  border-radius: 50%;
  height: 30px;
  width: 30px;
  flex: none;
  color: ${({ starred }) => starred && '#9b01f2'};
  :hover {
    background: ${colors.purple20};
    color: ${({ starred }) => starred && '#B852f6'};
  }

  cursor: pointer;
`;

function getCompetitorRatingColor(hasRating: boolean, score: number): string {
  if (!hasRating) return colors.white;
  if (score <= 2) return colors.purple20;
  if (score <= 3) return colors.purple15;
  if (score <= 4) return colors.purple10;
  if (score <= 5) return colors.purple05;
  return colors.purple05;
}

interface Props {
  groups: PostItGroupFragment[];
  stakeholder: Stakeholder;
  strategyId: number;
  stakeholderDefinitionId: number;
  competitors: CompetitorFragment[];
  showDistribution: boolean;
  setShowDistribution: Dispatch<SetStateAction<boolean>>;
}

export const Evaluation = ({
  groups,
  strategyId,
  stakeholder,
  stakeholderDefinitionId,
  competitors,
  showDistribution,
  setShowDistribution,
}: Props) => {
  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);

  const isMobile = useWidth() < 715;

  const graphsTitleContainerRef = useRef<HTMLDivElement>(null);

  const [
    desktopGraphsNode,
    setDesktopGraphsNode,
  ] = useState<HTMLDivElement | null>(null);

  const [rowRefs, setRowRefs] = useState<
    Record<number, RefObject<HTMLDivElement>>
  >({});

  const [
    distributionModalRatings,
    setDistributionModalRatings,
  ] = useState<DistributionModalValues | null>(null);

  const { data: strategy, loading } = useStrategyWithDrugQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'no-cache',
  });

  const currentStrategyUsers = strategy?.strategy?.users?.flat() || [];
  const currentDrugUsers = strategy?.strategy?.drug?.users?.flat() || [];

  const currentStrategyAndDrugUniqueUsers = lodash.uniqBy(
    [...currentStrategyUsers, ...currentDrugUsers],
    'id'
  );
  const [
    competitiveLandscapeOpportunityUpdate,
  ] = useCompetitiveLandscapeOpportunityUpdateMutation();
  const [updateCompetitor] = useCompetitorUpdateMutation();

  const [scrollContainerMetrics, setScrollContainerMetrics] = useState({
    scrollWidth: desktopGraphsNode?.scrollWidth || 0,
    offsetWidth: desktopGraphsNode?.offsetWidth || 0,
    scrollLeft: desktopGraphsNode?.scrollLeft || 0,
  });

  const onRefChange = useCallback((node) => {
    setDesktopGraphsNode(node); // trigger re-render on changes
  }, []);

  const [whiteSpaceVisible, setWhiteSpaceVisible] = useState(true);

  const allRatings: CompetitiveLandscapeRatingFragment[] = groups.reduce(
    (acc: CompetitiveLandscapeRatingFragment[], group) => {
      if (group?.competitiveLandscapeRating) {
        return [
          ...acc,
          ...(group.competitiveLandscapeRating as CompetitiveLandscapeRatingFragment[]),
        ];
      }
      return acc;
    },
    []
  );

  useLayoutEffect(() => {
    function onScrollHandler() {
      const whiteSpace = document.querySelector('.graph__white-space');

      const whiteSpaceVisible = whiteSpace?.getBoundingClientRect().width;

      setWhiteSpaceVisible(!!whiteSpaceVisible);
      if (desktopGraphsNode)
        setScrollContainerMetrics({
          scrollWidth: desktopGraphsNode.scrollWidth || 0,
          offsetWidth: desktopGraphsNode.offsetWidth || 0,
          scrollLeft: desktopGraphsNode.scrollLeft || 0,
        });
    }

    if (showDistribution && !isMobile) {
      onScrollHandler();

      const throttledScrollHandler = throttle(
        (e) => {
          onScrollHandler();
        },
        300,
        { trailing: true, leading: false }
      );

      if (desktopGraphsNode) {
        desktopGraphsNode.addEventListener('scroll', throttledScrollHandler);
        window.addEventListener('resize', throttledScrollHandler);
      }

      const element = desktopGraphsNode;

      return () => {
        element?.removeEventListener('scroll', throttledScrollHandler);
        window?.removeEventListener('resize', throttledScrollHandler);
      };
    }
  }, [showDistribution, isMobile, desktopGraphsNode]);

  useEffect(() => {
    // add or remove refs
    setRowRefs((rowRefs) =>
      groups.reduce((acc, val) => {
        if (!!val) {
          acc[val.id as keyof typeof acc] = createRef();
          return acc;
        }
        return acc;
      }, {} as Record<number, RefObject<HTMLDivElement>>)
    );
  }, [groups]);

  async function handleStarring(competitorId: number, isStarred: boolean) {
    let data = isStarred
      ? { removeStarredStakeholderDefinitionId: stakeholderDefinitionId }
      : { starredStakeholderDefinitionId: stakeholderDefinitionId };

    try {
      await updateCompetitor({
        variables: {
          id: competitorId,
          data: {
            stakeholder,
            ...data,
          },
        },
        refetchQueries: [
          { query: CompetitorDocument, variables: { id: competitorId } },
        ],
      });
    } catch (err) {
      alert(err);
    }
  }

  const scrollerHeight =
    //Header
    containerHeight +
    //Row
    Number(graphsTitleContainerRef.current?.offsetHeight || 0) +
    //Gap
    2;

  const scrollAmount =
    //at start
    scrollContainerMetrics.scrollLeft === 0
      ? 261
      : //at end
      scrollContainerMetrics.scrollLeft + scrollContainerMetrics.offsetWidth ===
        scrollContainerMetrics.scrollWidth
      ? 229
      : //in-between
        242;

  function handleScrollerClick(direction: 'left' | 'right') {
    if (!desktopGraphsNode) return;

    desktopGraphsNode.scrollBy({
      left: direction === 'left' ? -scrollAmount : scrollAmount,
      top: 0,
      behavior: (window as any).safari ? 'auto' : 'smooth',
    });
  }

  if (loading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Loading style={{ width: 80, marginTop: 15 }} />
      </div>
    );
  }

  return (
    <div>
      <DistributionModal
        companyUsersData={currentStrategyAndDrugUniqueUsers}
        visible={!!distributionModalRatings}
        handleClose={() => {
          setDistributionModalRatings(null);
        }}
        title={distributionModalRatings?.title}
        subtitle={distributionModalRatings?.subtitle}
        ratings={distributionModalRatings?.ratings}
      />

      <Tooltip id={'Evaluation'} effect="float" place="bottom" />

      <Toggle
        value={showDistribution}
        onChange={() => {
          setShowDistribution(!showDistribution);
        }}
        label="Show competitor distribution"
      />

      {isMobile && (
        <div style={{ marginTop: 20 }}>
          <TabGroup componentName="CompetitveLandscapeCompetitor">
            {competitors.map((competitor) => {
              const compRatings = allRatings
                .slice()
                .filter((rating) => rating.competitorId === competitor.id);

              const compAverageScore = average(
                compRatings.map((val) => val.score)
              ).toFixed(1);

              return (
                //Competitor Headers - Mobile
                <CompetitorTitleContainer
                  key={competitor.id}
                  style={{ marginRight: 2 }}
                >
                  <CompetitorTitleWrapper>
                    {competitor.image ? (
                      <CompetitorImage imageURL={competitor.image} />
                    ) : (
                      <TreatmentIcon />
                    )}
                    <CompetitorTitle>{competitor.title}</CompetitorTitle>

                    {competitor?.CompetitorDetails?.some(
                      (s) =>
                        s?.stakeholder === stakeholder &&
                        s.stakeholderDefinitionId === stakeholderDefinitionId &&
                        !!s.isStarred
                    ) ? (
                      <StarToggle
                        name="StarOutlinePurple"
                        size={30}
                        starred={true}
                        onClick={() => handleStarring(competitor.id, true)}
                        className="cypress-competitor-star"
                      />
                    ) : (
                      <StarToggle
                        name="StarOutlinePurple"
                        size={30}
                        starred={false}
                        color={colors.white}
                        onClick={() => handleStarring(competitor.id, false)}
                        className="cypress-competitor-star"
                      />
                    )}
                  </CompetitorTitleWrapper>
                  <CompetitorRating
                    style={{
                      backgroundColor: getCompetitorRatingColor(
                        compRatings.length > 0,
                        +compAverageScore
                      ),
                    }}
                  >
                    {compRatings.length > 0 ? (
                      <>
                        <span>Average score</span> {+compAverageScore}
                      </>
                    ) : (
                      'No ratings'
                    )}
                  </CompetitorRating>
                </CompetitorTitleContainer>
              );
            })}
          </TabGroup>
        </div>
      )}

      {/* Export */}
      {/* <ButtonPill
        level="export"
        text="export"
        onClick={() => {
          domtoimage
            //@ts-ignore
            .toJpeg(document.getElementById('graphsTitleContainer'), {
              quality: 0.95,
              bgcolor: colors.black05,
              style: {
                background: colors.black05,
                overflow: 'hidden',
              },
            })
            .then(function (dataUrl) {
              var link = document.createElement('a');
              link.download = 'my-image-name.jpeg';
              link.href = dataUrl;
              link.click();
            });
        }}
      /> */}
      <div
        style={{
          display: 'flex',
          gap: 2,
          marginTop: 20,
          alignItems: 'flex-start',
        }}
      >
        <div
          ref={graphsTitleContainerRef}
          id="graphsTitleContainer"
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: isMobile ? 15 : 2,
            width: isMobile ? '100%' : 'auto',
          }}
        >
          {groups.map((group, idx) => {
            const competitiveLandscapeRating = group?.competitiveLandscapeRating as CompetitiveLandscapeRatingFragment[];
            const competitiveLandscapeOpportunities = group?.competitiveLandscapeOpportunities as CompetitiveLandscapeOpportunity[];

            const ratings = competitiveLandscapeRating
              .slice()
              .filter(
                (rating) =>
                  rating.stakeholderDefinitionId === stakeholderDefinitionId
              );

            const averageScore = average(
              ratings.map((val) => val.score)
            ).toFixed(1);

            const scoreDistribution = createScoreDistribution(ratings);

            const selectedOpportunity = competitiveLandscapeOpportunities.find(
              (opportunity) => {
                return (
                  opportunity.stakeholderDefinitionId ===
                    stakeholderDefinitionId &&
                  opportunity.stakeholder === stakeholder
                );
              }
            );

            return (
              <Row
                key={group.id}
                ref={rowRefs[group.id]}
                showDistribution={showDistribution}
              >
                <TitleContainer
                  competitiveLandscapeOpportunityUpdate={
                    competitiveLandscapeOpportunityUpdate
                  }
                  group={group}
                  selectedOpportunity={selectedOpportunity}
                  isLead={isLead}
                  showDistribution={showDistribution}
                />

                {!isMobile && (
                  <ScoreCircleWrapper>
                    <ScoreCircle
                      value={+averageScore}
                      text="Av. score"
                      emptyText="No ratings"
                    />
                  </ScoreCircleWrapper>
                )}

                {/* Graphs for mobile view */}
                {isMobile && (
                  <EvaluationGraphs
                    isLead={isLead}
                    isMobile={true}
                    showDistribution={showDistribution}
                    competitors={competitors}
                    group={group}
                    overallAverageScore={+averageScore}
                    scoreDistribution={scoreDistribution}
                    setDistributionModalRatings={({
                      subtitle,
                      ratings,
                    }: Omit<DistributionModalProps, 'title'>) => {
                      setDistributionModalRatings({
                        title: group.title,
                        subtitle,
                        ratings,
                      });
                    }}
                  />
                )}
              </Row>
            );
          })}
        </div>

        {/* Graphs for Desktop view */}
        {!isMobile && (
          <>
            {showDistribution && (
              // Left scroll
              <GraphScroller
                hide={() => {
                  const scrolledPastStart =
                    scrollContainerMetrics.scrollLeft === 0;

                  return scrolledPastStart;
                }}
                height={scrollerHeight}
                onClick={() => handleScrollerClick('left')}
              />
            )}

            <GraphsWrapper className="GraphsWrapper" ref={onRefChange}>
              {showDistribution && (
                <div
                  style={{
                    display: 'flex',
                    gap: 2,
                    flex: 'none',
                  }}
                >
                  {competitors.map((competitor) => {
                    const compRatings = allRatings
                      .slice()
                      .filter(
                        (rating) => rating.competitorId === competitor.id
                      );

                    const compAverageScore = average(
                      compRatings.map((val) => val.score)
                    ).toFixed(1);

                    return (
                      //Competitor Headers - Desktop
                      <CompetitorTitleContainer key={competitor.id}>
                        <CompetitorTitleWrapper>
                          {competitor.image ? (
                            <CompetitorImage imageURL={competitor.image} />
                          ) : (
                            <TreatmentIcon />
                          )}
                          <CompetitorTitle>{competitor.title}</CompetitorTitle>

                          {competitor?.CompetitorDetails?.some(
                            (s) =>
                              s?.stakeholder === stakeholder &&
                              s.stakeholderDefinitionId ===
                                stakeholderDefinitionId &&
                              !!s.isStarred
                          ) ? (
                            <StarToggle
                              name="StarOutlinePurple"
                              size={20}
                              starred={true}
                              onClick={() =>
                                handleStarring(competitor.id, true)
                              }
                              className="cypress-competitor-star"
                            />
                          ) : (
                            <StarToggle
                              name="StarOutlinePurple"
                              size={20}
                              starred={false}
                              color={colors.white}
                              onClick={() =>
                                handleStarring(competitor.id, false)
                              }
                              className="cypress-competitor-star"
                            />
                          )}
                        </CompetitorTitleWrapper>
                        <CompetitorRating
                          style={{
                            backgroundColor: getCompetitorRatingColor(
                              compRatings.length > 0,
                              +compAverageScore
                            ),
                          }}
                        >
                          {compRatings.length > 0 ? (
                            <>
                              <span>Average score</span> {+compAverageScore}
                            </>
                          ) : (
                            'No ratings'
                          )}
                        </CompetitorRating>
                      </CompetitorTitleContainer>
                    );
                  })}
                </div>
              )}

              {groups.map((group) => {
                const competitiveLandscapeRating = group?.competitiveLandscapeRating as CompetitiveLandscapeRatingFragment[];

                const ratings = competitiveLandscapeRating
                  .slice()
                  .filter(
                    (rating) =>
                      rating.stakeholderDefinitionId === stakeholderDefinitionId
                  );

                const averageScore = average(
                  ratings.map((val) => val.score)
                ).toFixed(1);

                const scoreDistribution = createScoreDistribution(ratings);

                return (
                  !isMobile && (
                    <EvaluationGraphs
                      isLead={isLead}
                      rowRefs={rowRefs}
                      key={group.id}
                      isMobile={false}
                      showDistribution={showDistribution}
                      competitors={competitors}
                      group={group}
                      overallAverageScore={+averageScore}
                      scoreDistribution={scoreDistribution}
                      setDistributionModalRatings={({
                        subtitle,
                        ratings,
                      }: Omit<DistributionModalProps, 'title'>) => {
                        setDistributionModalRatings({
                          title: group.title,
                          subtitle,
                          ratings,
                        });
                      }}
                    />
                  )
                );
              })}
            </GraphsWrapper>

            {showDistribution && (
              <GraphScroller
                height={scrollerHeight}
                right
                hide={() => {
                  const roomToScroll =
                    scrollContainerMetrics.scrollWidth >=
                    scrollContainerMetrics.offsetWidth;

                  const scrolledToEnd =
                    scrollContainerMetrics.scrollLeft ===
                    scrollContainerMetrics.scrollWidth -
                      scrollContainerMetrics.offsetWidth;

                  return whiteSpaceVisible || (roomToScroll && scrolledToEnd);
                }}
                onClick={() => handleScrollerClick('right')}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

const TitleContainer = ({
  isLead,
  competitiveLandscapeOpportunityUpdate,
  group,
  selectedOpportunity,
  showDistribution,
}: {
  isLead: boolean;
  group: PostItGroupFragment;
  competitiveLandscapeOpportunityUpdate: CompetitiveLandscapeOpportunityUpdateMutationFn;
  selectedOpportunity?: CompetitiveLandscapeOpportunityFragment;
  showDistribution: boolean;
}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const [isApproved, setIsApproved] = useState(selectedOpportunity?.approved);
  const [errorModal, setErrorModal] = useState(false);
  const closeErrorModal = () => setErrorModal(false);

  useEffect(() => {
    setIsApproved(selectedOpportunity?.approved);
  }, [selectedOpportunity?.approved]);

  async function toggleOpportunityApproved() {
    if (!selectedOpportunity) return alert('No opportunity id provided');

    setIsApproved(!isApproved);

    try {
      await competitiveLandscapeOpportunityUpdate({
        variables: {
          id: selectedOpportunity.id,
          data: {
            strategyId: group.strategyId,
            approved: !isApproved,
          },
        },
      });
    } catch (err) {
      setIsApproved(isApproved);

      if (
        err instanceof Error &&
        err.message.includes('Cannot remove this opportunity')
      ) {
        setErrorModal(true);
      } else {
        alert(`Error: Couldn't update this Opportunity`);
      }
    }
  }

  return (
    <TitleWrapper showDistribution={showDistribution}>
      <ErrorModal
        title="Cannot remove this opportunity"
        text="Content in later steps depends on this outcome as an opportunity. Remove content and try again."
        visible={errorModal}
        handleClose={closeErrorModal}
      />

      <InnerWrapper>
        <TopRow>
          <div
            data-for={'Evaluation'}
            data-tip={isLead ? '' : 'Only Leads can edit'}
            data-offset="{'top': 0}"
          >
            <CheckCircle
              onClick={toggleOpportunityApproved}
              className={`cypress-competitive-landscape-opportunity_${!!isApproved}`}
              isDisabled={!isLead}
              complete={!!isApproved}
            />
          </div>
          <BodySmall color={isApproved ? colors.black : colors.greyDark}>
            Opportunity
          </BodySmall>
          <Collaboration collaboration={selectedOpportunity?.collaboration} />
        </TopRow>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Subtitle2>{group.title}</Subtitle2>
        </div>
      </InnerWrapper>
    </TitleWrapper>
  );
};

export function createScoreDistribution(ratings: { score: number }[]) {
  return ratings.reduce((acc, val) => {
    if (acc[val.score]) {
      acc[val.score] = acc[val.score] + 1;
    } else {
      acc[val.score] = 1;
    }

    return acc;
  }, {} as Record<number, number>);
}
