import { FC } from 'react';
import { colors, Colors } from 'constants/colors';
import { ButtonLevel } from 'components/shared/ButtonPill';
import {
  StyledButtonContainer,
  StyledButtonPill,
} from './EvaluationButton.styles';

interface EvaluationButtonProps {
  text: string;
  isActive: boolean;
  onClick: () => void;
}

export const EvaluationButton: FC<EvaluationButtonProps> = ({
  text,
  isActive,
  onClick,
}) => {
  const status: ButtonLevel = isActive ? 'evaluating' : 'evaluate';
  const color: Colors = isActive
    ? (colors.white as Colors)
    : (colors.black as Colors);

  return (
    <StyledButtonContainer active={isActive}>
      <StyledButtonPill
        text={text}
        onClick={onClick}
        level={status}
        color={color}
      />
    </StyledButtonContainer>
  );
};
