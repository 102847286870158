import React from 'react';
import styled from 'styled-components/macro';
import { BodySmall, Caption, Collaboration, Icon } from 'components/shared';
import { colors } from 'constants/colors';
import { AccessStrategyPrioritiseSupportingMessageFragment } from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import { Loading } from 'components/Loading';
import { startCase } from 'lodash';

export const Wrapper = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.greyLight};
  border-radius: 5px;
  width: 325px;
  padding: 15px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PinAndCaption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextContainer = styled.div`
  margin: 5px 0;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`;

interface Props {
  data?: AccessStrategyPrioritiseSupportingMessageFragment;
  permittedToEdit: boolean;
}

export const SupportingMessageCallout: React.FC<Props> = ({
  data,
  permittedToEdit,
}) => {
  const isDesktop = useDesktop();

  if (!data)
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    );

  return (
    <Wrapper className="callout">
      <FlexContainer>
        {isDesktop ? (
          <Caption color={colors.greyDark}>
            {startCase(data.keyStatement.subStep || '')}
          </Caption>
        ) : (
          <PinAndCaption>
            <Icon
              name="Pin"
              size={30}
              color={colors.purple10}
              style={{ marginRight: 5 }}
            />
            {permittedToEdit && (
              <Caption color={colors.greyDark}>Tap grid to position</Caption>
            )}
          </PinAndCaption>
        )}
        <div />
      </FlexContainer>

      <TextContainer>
        {!isDesktop && (
          <Caption color={colors.greyDark} style={{ marginRight: 15 }}>
            {data.keyStatement.text}
          </Caption>
        )}
        <BodySmall>{data.keyStatement.text}</BodySmall>
      </TextContainer>
      <FlexContainer>
        <div />
        <Collaboration collaboration={data.collaboration} />
      </FlexContainer>
    </Wrapper>
  );
};
