import PptxGenJS from 'pptxgenjs';
import { colors } from './colors';

interface Props {
  brand: string;
  startYear: number;
  endYear: number;
  data: any;
}

export default function exportSummaryTwo({
  brand,
  startYear,
  endYear,
  data,
}: Props) {
  if (!data) return;

  let pres = new PptxGenJS();

  const columns: string[] = data.map((c: { name: string }) => c.name);

  let slide = pres.addSlide();

  slide.addText(
    `${brand} • ${startYear} - ${endYear} | Strategic Possibilities`,
    {
      x: 0.2,
      y: 0.2,
      h: 0.2, // height in inches
      color: colors.black,
      fontSize: 14,
      fontFace: 'Arial',
    }
  );

  let rows = [];

  // titles
  rows.push(
    columns.map((text, idx) => ({
      text: [{ text }],
      options: {
        color: colors.black,
        fontSize: 10,
        fontFace: 'Arial',
        bold: true,
        align: 'center',
        fill: idx % 2 === 0 ? 'f8f2ff' : '',
        border: [
          { pt: 1, color: colors.purple },
          { pt: 0, color: colors.purple },
          { pt: 1, color: colors.purple },
          { pt: 0, color: colors.purple },
        ],
      },
    }))
  );

  const options = {
    paraSpaceAfter: 8,
  };
  const textOps = {
    ...options,
    fontSize: 10,
    fontFace: 'Arial',
    align: 'center',
  };

  rows.push(
    columns.map((text, idx) => {
      const lps = data[idx].leveragePoints.length
        ? data[idx].leveragePoints
        : [];

      const x = lps.reduce((acc: any[], lp: any, idx: number) => {
        acc.push({
          text: idx >= 1 ?
            '\n★ Leverage point\n' :
            '★ Leverage point\n',
          options: {
            ...textOps,
            color: colors.grey,
          },
        });

        acc.push({
          text: `${lp.text}\n`,
          options: {
            ...textOps,
            bold: true,
          },
        });

        const imperatives = lp.strategicPossibilities.filter(
          (sp: any) => !!sp.imperative
        );

        if (imperatives.length) {
          // Adding imperative content
          const imperativeRows = imperatives.reduce(
            (acc: any[], imperative: any) => {
              acc.push({
                text: '✓\n',
                options: {
                  ...textOps,
                  fontWeight: 'bold',
                  color: colors.purple,
                },
              });
              acc.push({
                text: `${imperative.name} \n`,
                options: {
                  ...textOps,
                },
              });
              if (imperative.behaviourToDrive) {
                acc.push({
                  text: `${imperative.behaviourToDrive} \n`,
                  options: {
                    ...textOps,
                  },
                });
              }
              if (imperative.driverOfTheBehaviour) {
                acc.push({
                  text: `${imperative.driverOfTheBehaviour} \n`,
                  options: {
                    ...textOps,
                  },
                });
              }
              return acc;
            },
            []
          );

          acc = [...acc, ...imperativeRows];
        } else {
          acc.push({
            text: `No options chosen\n`,
            options: {
              ...textOps,
              color: colors.grey,
            },
          });
        }

        acc.push({
          text: '–',
        });

        return acc;
      }, []);

      if (!x.length) {
        return {
          text: 'No leverage points',
          options: {
            ...textOps,
            fill: idx % 2 === 0 ? 'f8f2ff' : '',
            color: colors.grey
          },
        };
      }

      return {
        text: x,
        options: {
          fill: idx % 2 === 0 ? 'f8f2ff' : '',
        },
      };
    })
  );

  // @ts-ignore
  slide.addTable(rows, { x: 0.3, y: 0.6, w: 9.4 });

  // slide 2
  let slide2 = pres.addSlide();

  slide2.addText(
    `${brand} • ${startYear} - ${endYear} | Strategic Possibilities – Other options considered`,
    {
      x: 0.2,
      y: 0.2,
      h: 0.2, // height in inches
      color: colors.black,
      fontSize: 14,
      fontFace: 'Arial',
    }
  );

  let rows2 = [];

  // titles
  rows2.push(
    columns.map((text, idx) => ({
      text: [{ text }],
      options: {
        color: colors.black,
        fontSize: 10,
        fontFace: 'Arial',
        bold: true,
        align: 'center',
        fill: idx % 2 === 0 ? 'f8f2ff' : '',
        border: [
          { pt: 1, color: colors.purple },
          { pt: 0, color: colors.purple },
          { pt: 1, color: colors.purple },
          { pt: 0, color: colors.purple },
        ],
      },
    }))
  );

  rows2.push(
    columns.map((text, idx) => {
      const lps = data[idx].leveragePoints.length
        ? data[idx].leveragePoints
        : [];

      const x = lps.reduce((acc: any[], lp: any, idx: number) => {
        acc.push({
          text: idx >= 1 ?
            '\n★ Leverage point\n' :
            '★ Leverage point\n',
          options: {
            ...textOps,
            color: colors.grey,
          },
        });

        acc.push({
          text: `${lp.text}\n`,
          options: {
            ...textOps,
            bold: true,
          },
        });

        const otherOptions = lp.strategicPossibilities.filter(
          (sp: any) =>
            !sp.imperative &&
            (!!sp.behaviourToDrive || !!sp.driverOfTheBehaviour)
        );

        if (otherOptions.length) {
          // Adding imperative content
          const otherOptionRows = otherOptions.reduce(
            (acc: any[], op: any) => {
              acc.push({
                text: '–\n',
                options: {
                  ...textOps,
                  fontWeight: 'bold',
                },
              });
              if (op.behaviourToDrive) {
                acc.push({
                  text: `${op.behaviourToDrive}\n`,
                  options: {
                    ...textOps,
                  },
                });
              }
              if (op.driverOfTheBehaviour) {
                acc.push({
                  text: `${op.driverOfTheBehaviour}\n`,
                  options: {
                    ...textOps,
                  },
                });
              }
              return acc;
            },
            []
          );

          acc = [...acc, ...otherOptionRows];
        } else {
          acc.push({
            text: `No other options considered\n`,
            options: {
              ...textOps,
              color: colors.grey,
            },
          });
        }

        acc.push({
          text: '–',
        });

        return acc;
      }, []);

      if (!x.length) {
        return {
          text: 'No leverage points',
          options: {
            ...textOps,
            fill: idx % 2 === 0 ? 'f8f2ff' : '',
            color: colors.grey
          },
        };
      }

      return {
        text: x,
        options: {
          fill: idx % 2 === 0 ? 'f8f2ff' : '',
        },
      };
    })
  );

  // @ts-ignore
  slide2.addTable(rows2, { x: 0.3, y: 0.6, w: 9.4 });

  pres.writeFile({
    fileName: `${brand}_${startYear}-${endYear}_Strategic Possibilities.pptx`,
  });
}
