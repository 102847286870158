import { StyledFormTextInput } from 'components/Options/ImperativeModal';
import { BodyNormal, DatePicker, Icon, Subtitle1 } from 'components/shared';
import { Input } from 'components/shared/FormTextInput';
import { RationaleModal } from 'components/shared/RationaleModal';
import { colors } from 'constants/index';
import {
  SupportingMessageTacticFragment,
  SupportingMessageTacticUpdateInput,
} from 'data/graphql/generated';
import { useFormik } from 'formik';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { monthNames } from 'utils/monthNames';
import { thousandSeparator } from 'utils/thousandSeparator';

const CurrencyIcon = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  inset: 0;
  top: 15px;
  left: 10px;
`;

const DueTextInput = styled(StyledFormTextInput)`
  pointer-events: none;

  ${Input} {
    padding-left: 32px;
  }
`;

const BudgetTextInput = styled(StyledFormTextInput)`
  ${Input} {
    padding-left: 32px;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  position: absolute;
  inset: 0;
  height: fit-content;
  top: 50px;
  z-index: 1;
`;

interface ModalFields {
  lowerLimit: number[] | null | undefined;
  dueDate: number[] | null | undefined;
  responsibility: string;
  budget: string | null | undefined;
}

interface Props {
  data: SupportingMessageTacticFragment;
  rowData: ModalFields;
  currency: string;
  modalSlideIdx: number | null;
  setModalSlideIdx?:
    | React.Dispatch<React.SetStateAction<number | null>>
    | ((val: number | null) => void);
  showSingleSlide: boolean;
  closeModal(): void;
  setIsFocus: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdate(updatedData: SupportingMessageTacticUpdateInput): void;
}

export const AccessStrategyRationaleModal = ({
  rowData,
  modalSlideIdx,
  setModalSlideIdx,
  closeModal,
  showSingleSlide = true,
  data,
  currency,
  setIsFocus,
  onUpdate,
}: Props) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerRef = useRef<HTMLDivElement | null>(null);

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    errors,
    isSubmitting,
    touched,
    setTouched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      dueDate: rowData.dueDate,
      responsibility: rowData.responsibility,
      budget: rowData.budget,
    },
    onSubmit: ({ dueDate, responsibility, budget }) => {},
    validate: (values) => {
      const errors = {} as {
        dueDate: string;
        responsibility: string;
        budget: string;
      };

      if (!values.dueDate) {
        errors.dueDate = 'Required';
      }
      if (!values.responsibility.trim()) {
        errors.responsibility = 'Required';
      }
      if (!values.budget) {
        errors.budget = 'Required';
      }

      return errors;
    },
  });

  useClickOutsideComponent(datePickerRef, () => {
    setShowDatePicker(false);
    setTouched({ ...touched, dueDate: true });
  });

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, [resetForm]);

  async function handleModalSubmit(focusRationale: string) {
    try {
      setIsFocus(true);
      const { dueDate, responsibility, budget } = values;
      if (!dueDate?.length) return;

      await onUpdate({
        due: new Date(dueDate[1], dueDate[0], 10).toISOString() + '',
        responsibility: responsibility.trim(),
        budget: budget,
        focus: true,
        focusRationale,
      });
    } catch (error) {
      setIsFocus(false);
      alert('Something went wrong');
    }
  }

  return (
    <RationaleModal
      handleClose={closeModal}
      modalSlideIdx={modalSlideIdx}
      setModalSlideIdx={setModalSlideIdx}
      showSingleSlide={showSingleSlide}
      handleSubmit={handleModalSubmit}
      rationaleText={data.focusRationale}
      modalDetails={{ Tactic: data.text }}
      disableNext={
        isSubmitting ||
        !(
          values.budget &&
          values.dueDate?.length &&
          values.responsibility.trim()
        )
      }
      firstSlideContent={
        <>
          <Subtitle1
            style={{
              textAlign: showSingleSlide ? 'center' : 'left',
              marginBottom: 10,
            }}
          >
            {!showSingleSlide && '1. '}Delivery
          </Subtitle1>
          <BodyNormal
            style={{
              textAlign: showSingleSlide ? 'center' : 'left',
            }}
          >
            Enter the key information for delivering this tactic
          </BodyNormal>

          <form
            onSubmit={handleSubmit}
            style={{ width: '100%' }}
            className="cypress-modal-form"
          >
            <div>
              <div ref={datePickerRef} style={{ position: 'relative' }}>
                <div
                  onClick={() => {
                    setShowDatePicker(!showDatePicker);
                  }}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <DueTextInput
                    errorMessage={!!touched?.dueDate ? errors.dueDate : ''}
                    name="dueDate"
                    type="text"
                    onChange={() => {}}
                    value={
                      !!values?.dueDate?.length
                        ? [
                            monthNames[values.dueDate[0]],
                            values.dueDate[1],
                          ].join(' ')
                        : ''
                    }
                    title="Due"
                  />
                  <Icon
                    name="Calendar"
                    size={20}
                    color={colors.greyDark}
                    style={{ position: 'absolute', inset: 0, left: 7 }}
                  />
                </div>
                <StyledDatePicker
                  lowerLimit={
                    !!rowData?.lowerLimit?.length
                      ? {
                          month: rowData?.lowerLimit[0],
                          year: rowData?.lowerLimit[1],
                        }
                      : undefined
                  }
                  date={
                    !!values?.dueDate?.length
                      ? { month: values.dueDate[0], year: values.dueDate[1] }
                      : undefined
                  }
                  visible={showDatePicker}
                  onUpdate={(date) => {
                    setShowDatePicker(false);

                    setFieldValue(
                      'dueDate',
                      date ? [date.month, date.year] : []
                    );
                  }}
                />
              </div>
              <StyledFormTextInput
                errorMessage={
                  !!touched.responsibility ? errors.responsibility : ''
                }
                name="responsibility"
                type="text"
                onChange={handleChange}
                value={values.responsibility}
                title="Responsibility"
                onBlur={handleBlur}
              />
              <div style={{ position: 'relative' }}>
                <BudgetTextInput
                  errorMessage={!!touched.budget ? errors.budget : ''}
                  name="budget"
                  type="text"
                  onChange={(e) => {
                    setFieldValue(
                      'budget',
                      e.target.value.replace(/[\D,]/g, '')
                    );
                  }}
                  value={
                    typeof values.budget === 'number' ||
                    typeof values.budget === 'string'
                      ? thousandSeparator(values?.budget)
                      : ''
                  }
                  title="Budget"
                  onBlur={handleBlur}
                />
                <CurrencyIcon>
                  <BodyNormal color={colors.greyDark}>{currency[0]}</BodyNormal>
                </CurrencyIcon>
              </div>
            </div>
          </form>
        </>
      }
    />
  );
};
