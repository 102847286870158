export const size = {
  mobile: '480px',
  tablet: '944px',
  desktop: '1440px',
};

export const device = {
  tabletMax: `(max-width: ${size.tablet})`,
  desktopMax: `(max-width: ${size.desktop})`,

  tabletMin: `(min-width: 481px)`,
  desktopMin: `(min-width: 945px)`,

  mobile: `(max-width: ${size.mobile})`,
  tablet: `(min-width:481px) and (max-width: ${size.tablet})`,
  desktop: `(min-width:945px) and (max-width: ${size.desktop})`,
};
