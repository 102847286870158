import { Link } from 'react-router-dom';
import { colors } from '../../../constants';
import { verifyUserRole } from '../../../utils/verifyUserRole';
import { BodySmall } from '../../shared';

export function CommercialTacticsEmptyState({
  drugId,
  strategyId,
  strategicImperativeId,
  localCountryTacticsEnabled,
  showGlobalPlan,
  noGlobalTactics,
  noCountryTactics,
  userRole,
  isCountryObjective,
}: {
  drugId: string;
  strategyId: string;
  strategicImperativeId: string;
  localCountryTacticsEnabled: boolean;
  showGlobalPlan: boolean;
  noGlobalTactics: boolean;
  noCountryTactics: boolean;
  userRole: ReturnType<typeof verifyUserRole>;
  isCountryObjective: boolean;
}) {
  const PartTwoLink = () => {
    return (
      <Link
        to={`/d/${drugId}/strategy/${strategyId}/4_3/winning-actions${
          strategicImperativeId ? '/' + strategicImperativeId : ''
        }`}
        style={{
          color: colors.greyDark,
          display: 'inline',
          fontSize: 14,
          fontWeight: 500,
        }}
      >
        {' '}
        Part 2{' '}
      </Link>
    );
  };

  switch (true) {
    case !localCountryTacticsEnabled:
      return (
        <>
          <BodySmall
            style={{
              display: 'inline',
              color: colors.greyDark,
            }}
          >
            A Lead should choose commercial initiatives to focus on in{' '}
          </BodySmall>
          <PartTwoLink />
        </>
      );

    case !showGlobalPlan &&
      noGlobalTactics &&
      (userRole.isLead || userRole.isGlobalContributor):
      return (
        <>
          <BodySmall
            style={{
              display: 'inline',
              color: colors.greyDark,
            }}
          >
            A Lead should choose commercial initiatives to focus on in{' '}
          </BodySmall>
          <PartTwoLink />
        </>
      );

    case noGlobalTactics && noCountryTactics && userRole.isCountryContributor:
      return (
        <>
          <BodySmall
            style={{
              display: 'inline',
              color: colors.greyDark,
            }}
          >
            Choose commercial initiatives to focus on in{' '}
          </BodySmall>
          <PartTwoLink />
        </>
      );

    case noGlobalTactics &&
      !noCountryTactics &&
      !showGlobalPlan &&
      (userRole.isLead || userRole.isGlobalContributor):
      return (
        <>
          <BodySmall
            style={{
              display: 'inline',
              color: colors.greyDark,
            }}
          >
            Use the toggle above to view commercial initiatives with a
            country-specific focus
          </BodySmall>
          <PartTwoLink />
        </>
      );

    case isCountryObjective && noGlobalTactics && noCountryTactics:
      return (
        <>
          <BodySmall
            style={{
              display: 'inline',
              color: colors.greyDark,
            }}
          >
            No commercial initiatives yet
          </BodySmall>
        </>
      );

    case !noGlobalTactics &&
      noCountryTactics &&
      !showGlobalPlan &&
      userRole.isGlobalContributor:
      return (
        <>
          <BodySmall
            style={{
              display: 'inline',
              color: colors.greyDark,
            }}
          >
            Use the toggle above to view commercial initiatives with a global
            focus
          </BodySmall>
        </>
      );
    default:
      return (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <BodySmall
            style={{
              display: 'inline',
              color: colors.greyDark,
            }}
          >
            A Lead should choose commercial initiatives to focus on in
          </BodySmall>
          <PartTwoLink />
        </div>
      );
  }
}
