import {
  BodySmall,
  DraggableObservation,
  EmptyState,
  Subtitle2,
} from 'components/shared';
import { colors, zIndex } from 'constants/index';
import { ObservationsQuery } from 'data/graphql/generated';
import React from 'react';
import { useDrop } from 'react-dnd';
import styled, { css } from 'styled-components/macro';
import { device } from 'utils/breakpoints';

const StyledEmptyState = styled(EmptyState)`
  border-radius: 5px;
  background: ${colors.white50};
`;

const ObservationsWrapper = styled.div<{ canDrop: boolean }>`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  > * + * {
    margin-top: 15px;
  }
  overflow: auto;
  height: 95%;

  position: relative;

  ::after {
    content: 'Drop to remove Insight';
    position: fixed;
    bottom: 0;
    left: 20px;
    width: 515px;
    height: calc(100% - 65px);
    background: ${colors.white90};
    border: 2px dashed ${colors.purple};
    border-radius: 5px;
    z-index: ${zIndex.observationsMenuOverlay};

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: ABCFavorit;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 30px;

    display: flex;
    align-items: center;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s;
    ${({ canDrop }) => {
      return (
        canDrop &&
        css`
          opacity: 1;
          pointer-events: all;
        `
      );
    }}
  }
`;

const Wrapper = styled.div<{ show: boolean }>`
  position: fixed;
  width: 555px;
  height: calc(100% - 110px);
  background: linear-gradient(
      0deg,
      rgba(20, 20, 39, 0.05),
      rgba(20, 20, 39, 0.05)
    ),
    #ffffff;
  bottom: 0;
  right: 0;
  transform: ${({ show }) => `translateX(${show ? 0 : '555px'})`};
  padding: 20px 20px 20px 20px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  z-index: ${zIndex.observationsMenu};

  transition: transform 0.3s;
  @media ${device.tabletMax} {
    display: none;
  }
`;

interface Props {
  ObservationsData: ObservationsQuery | undefined;
  show: boolean;
  filter: (number | null)[];
  removingFromInsight: boolean;
}

export const ObservationsPane = ({
  ObservationsData,
  show,
  filter,
  removingFromInsight,
}: Props) => {
  useDrop(() => ({
    accept: 'observation',
    drop: async (item: { type: string; id: number | undefined }) => {},
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: () => {
        const item = monitor.getItem();
        return item?.source === 'ObservationsPane'
          ? false
          : !!monitor.canDrop();
      },
    }),
  }));

  if (ObservationsData) {
    const items = ObservationsData.observations?.items;

    return (
      <Wrapper show={show} className={'ObservationsPane'}>
        <Subtitle2>Drag supporting observations to the insight</Subtitle2>
        <ObservationsWrapper canDrop={removingFromInsight}>
          {show &&
            (!!items?.length && filter.length !== items.length ? (
              items
                .filter((val) => val?.id && !filter.includes(val?.id))
                .map((observation) => {
                  return (
                    <DraggableObservation
                      key={observation?.id}
                      data={observation}
                      source="ObservationsPane"
                    />
                  );
                })
            ) : (
              <StyledEmptyState>
                <Subtitle2 color={colors.greyDark}>
                  {!items?.length && !filter.length
                    ? 'No observations available'
                    : 'No more observations'}
                </Subtitle2>
                <BodySmall color={colors.greyDark}>
                  {!items?.length && !filter.length
                    ? 'Add observations on the previous steps to use them here'
                    : 'All observations have been assigned to this insight'}
                </BodySmall>
              </StyledEmptyState>
            ))}
        </ObservationsWrapper>
      </Wrapper>
    );
  }
  return null;
};
