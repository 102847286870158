import { Toggle, KeyEventsButton } from 'components/shared';
import { colors, zIndex } from 'constants/index';
import styled from 'styled-components/macro';
import { TabGroupWrapper } from '../StrategicQuestion';
import {
  PageWrapper,
  StepHeader,
  MainContent,
} from 'components/shared/SidebarPageComponents';
import { Wrapper } from 'components/Page';
import { device } from 'utils/breakpoints';
import { DrugDetailBorderWrapper, DrugDetailWrapper } from '../Strategies';
import { CountriesDropdownSmall } from 'components/shared/CountriesDropdownSmall';
import { FlowProgressCheckbox } from 'components/PatientFlow/FlowProgressCheckbox';
import TwoButtonModal from 'components/TwoButtonModal';
import { TopBar } from 'components/ModalForm';
import { TooltipWrapper } from 'components/shared/ButtonPill';

export const StyledKeyEventsButton = styled(KeyEventsButton)`
  margin-left: auto;

  @media ${device.desktopMin} {
    margin-right: 95px;
    margin-left: auto;
  }
`;

export const TabsAndButton = styled.div<{ showGlobalPlanButton: boolean }>`
  margin: ${({ showGlobalPlanButton }) =>
    showGlobalPlanButton ? 0 : '20px 0 -5px 0'};

  @media ${device.tabletMin} {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: ${({ showGlobalPlanButton }) =>
      showGlobalPlanButton ? '-10px' : '-5px'};
  }

  @media ${device.desktopMin} {
    margin: ${({ showGlobalPlanButton }) =>
      showGlobalPlanButton ? '-5px 5px -5px 5px' : '0px 5px 0px 5px'};
  }
`;

export const StyledMainContent = styled(MainContent)`
  overflow: ${({ sidebarOpen }) => (sidebarOpen ? 'auto' : 'visible')};
`;

export const GlobalPlanButton = styled(FlowProgressCheckbox)`
  margin: 10px 0;
  width: fit-content;

  @media ${device.tabletMin} {
    order: 1;
    margin: 0 0 0 5px;
    flex: auto;
    max-width: fit-content;
  }
`;

export const StrategicQuestionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 15px 0;

  @media ${device.desktopMin} {
    flex-direction: row;
  }

  ${DrugDetailWrapper} {
    @media ${device.desktopMin} {
      min-height: 80px;
    }
  }

  ${DrugDetailBorderWrapper} {
    margin: 0;

    @media ${device.desktopMin} {
      margin: 0 10px;
    }
  }
`;

export const DividerAndStrategicQuestion = styled.div`
  display: flex;
  width: 100%;
`;

export const PurpleDivider = styled.div`
  width: 1px;
  background: ${colors.purple};
`;

export const StyledPageWrapper = styled(PageWrapper)`
  overflow: visible;
  ${Wrapper} {
    max-width: none;
  }
`;

export const StyledTabGroupWrapper = styled(TabGroupWrapper)`
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-top: 0;

  @media ${device.tabletMin} {
    flex: 1;
  }
`;

export const HurdlesAndGapsCountriesDropdown = styled(CountriesDropdownSmall)`
  align-self: flex-end;
  margin-bottom: 15px;

  @media ${device.desktopMin} {
    margin-right: 10px;
    align-self: flex-start;
  }
`;

export const StyledStepHeader = styled(StepHeader)`
  z-index: 1;
`;

export const SpinOffsToggle = styled(Toggle)`
  margin-bottom: 15px;
  margin-left: 15px;

  @media ${device.desktopMin} {
    margin-left: 0;
  }
`;

export const FinancialSummaryCountryDropdown = styled(CountriesDropdownSmall)`
  z-index: ${zIndex.financialSummaryCountryDropdown};
`;

export const StyledTwoButtonModal = styled(TwoButtonModal)`
  ${TopBar} {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  ${TooltipWrapper} {
    width: 100%;
  }
`;

export const TopRow = styled.div`
  width: 100%;
  display: flex;
`;
