import React from 'react';

import {
  CriticalMetricGoalFragment,
  CriticalMetricGoalUpdateInput,
} from 'data/graphql/generated';

import { CreateCriticalMetricGoalFn } from '../../../data-access-critical-metrics-goals/src/lib/hooks/useCreateCriticalMetricGoal';
import { UpdateCriticalMetricGoalFn } from '../../../data-access-critical-metrics-goals/src/lib/hooks/useUpdateCriticalMetricGoal';
import { DeleteCriticalMetricGoalFn } from '../../../data-access-critical-metrics-goals/src/lib/hooks/useDeleteCriticalMetricGoal';

import { GoalsTableConstants } from '../../../shared-util-goals-constants/src';
import { GoalsTableRow } from './GoalsTableRow';
import { StyledGoalsTable } from './GoalsTable.styles';

interface GoalsTableProps {
  isLead: boolean;
  isContributor: boolean;
  isDesktop: boolean;
  data: CriticalMetricGoalFragment[];
  onCreateRow: CreateCriticalMetricGoalFn;
  onUpdateRow: UpdateCriticalMetricGoalFn;
  onDeleteRow: DeleteCriticalMetricGoalFn;
}

export const GoalsTable: React.FC<GoalsTableProps> = ({
  isDesktop,
  isLead,
  isContributor,
  data: goals,
  onCreateRow,
  onUpdateRow,
  onDeleteRow,
}) => {
  const [isCreating, setIsCreating] = React.useState(false);
  const [autoFocusId, setAutoFocusId] = React.useState<number | undefined>(
    undefined
  );

  const handleCreateRow = async () => {
    setIsCreating(true);
    const response = await onCreateRow();
    setAutoFocusId(response.data?.criticalMetricGoalCreate.id);
    setIsCreating(false);
  };

  const handleUpdateRow = (id: number) => async (
    values: CriticalMetricGoalUpdateInput
  ) => {
    await onUpdateRow(id, values);
  };

  const handleDeleteRow = (id: number) => async () => {
    await onDeleteRow(id);
  };

  return (
    <StyledGoalsTable
      isAddingRow={isCreating}
      isActive
      showTopMobileDivider
      isDesktop={isDesktop}
      useDiv
      emptyStateText={GoalsTableConstants.TABLE_EMPTY_STATE_TEXT}
      cols={GoalsTableConstants.COLUMN_HEADERS}
      data={goals}
      rows={(data) => (
        <GoalsTableRow
          key={data.id}
          isDesktop={isDesktop}
          autoFocus={autoFocusId === data.id}
          isLead={isLead}
          isContributor={isContributor}
          data={data as any}
          onUpdate={handleUpdateRow((data as CriticalMetricGoalFragment).id)}
          onDelete={handleDeleteRow((data as CriticalMetricGoalFragment).id)}
        />
      )}
      addButtonText={'Add Goal'}
      addRow={handleCreateRow}
    />
  );
};
