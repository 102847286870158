import { colors } from 'constants/colors';
import React, { useRef, useState, useEffect, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { CountryGlobalRegional } from 'types';
import { CountryFlag } from './CountryFlag';
import { ErrorMessage } from './FormField';
import { Icon } from './Icon';
import { BodyNormal, ButtonLabel } from './TextStyles';
import {
  DisplayedOption,
  DropdownOption,
} from 'components/shared/FormDropdown';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { countryPhoneCode } from 'utils/countryPhoneCode';
import FormTextInput from './FormTextInput';

const SelectedValueContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: space-between;
  padding: 0 15px 0 10px;
  top: 21px;

  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

const StyledTextInput = styled(FormTextInput)<{ dropdownOpen: boolean }>`
  width: 100%;
  > input {
    padding: 0 9px;
    background: transparent;
    border-radius: ${({ dropdownOpen }) =>
      dropdownOpen ? '5px 5px 0 0' : '5px'};
  }
`;

const DisplayedOptionText = styled(BodyNormal)`
  margin-left: 5px;
  text-transform: capitalize;
`;

const CountryDropdownOption = styled(DropdownOption)`
  padding: 5px;
`;

export const DropdownOptionText = styled(ButtonLabel)`
  margin-left: 5px;
  text-transform: capitalize;
  span {
    color: ${colors.greyDark};
  }
`;

export const CountryDropdownListWrapper = styled.div`
  border-radius: 0 0 5px 5px;
  overflow: hidden;
  position: absolute;
  width: max-content;
  right: 0;
  left: 0;
  top: 69px;
  border: 0.5px solid ${colors.black};
  z-index: 2;
  min-width: 100%;
`;

const CountryDropdownList = styled.ul<{ phoneCode?: boolean }>`
  margin: 0;
  padding: 0;
  border-radius: 0 0 5px 5px;
  padding: 5px;
  max-height: 230px;
  background: ${colors.white};
  overflow-x: auto;
  overflow-y: scroll;

  :hover {
    cursor: pointer;
  }
`;

interface Props<T> {
  name: string;
  title: string;
  disabled?: boolean;
  errorMessage?: string;
  placeholder: string;
  options: T[];
  onClick: () => void;
  selectedItem?: T | '';
  dropdownActive: boolean;
  setSelectedItem(country: T): void;
  setDropdownActive: React.Dispatch<React.SetStateAction<boolean>>;
  phoneCode?: boolean;
  className?: string;
  style?: React.CSSProperties;
  dropDownItem?: (country: T, idx: number) => ReactElement | null;
}

const FormCountryDropdownSearch = <T extends CountryGlobalRegional>({
  name,
  title,
  disabled,
  errorMessage,
  placeholder,
  options,
  onClick,
  selectedItem,
  dropdownActive,
  setSelectedItem,
  setDropdownActive,
  phoneCode,
  className,
  style,
  dropDownItem,
}: Props<T>) => {
  const handleOptionClick = (option: T) => {
    setSelectedItem(option);
    setDropdownActive(false);
    setPlaceholderValue('');
    setSearchValue('');
  };

  const [showSelectedValue, setShowSelectedValue] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [placeholderValue, setPlaceholderValue] = useState(
    showSelectedValue && !!selectedItem ? '' : placeholder
  );
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (!dropdownActive) {
      setSearchValue('');
    }
  }, [dropdownActive]);

  useEffect(() => {
    return () => {
      setDropdownActive(false);
    };
  }, [setDropdownActive]);

  useClickOutsideComponent(
    dropdownRef,
    () => {
      setDropdownActive(false);
    },
    ['selectedValueContainer']
  );

  useEffect(() => {
    setFilteredOptions(() => {
      return options.filter((value) => {
        const string = phoneCode ? value + countryPhoneCode(value) : value;

        return string
          .replace(/ /g, '')
          .toLocaleLowerCase()
          .includes(searchValue.replace(/ /g, '').toLowerCase());
      });
    });
  }, [searchValue, options, phoneCode]);

  return (
    <div
      tabIndex={1}
      ref={wrapperRef}
      className={className}
      style={{
        display: 'inline-flex',
        position: 'relative',
        ...style,
      }}
    >
      <SelectedValueContainer
        className="selectedValueContainer"
        disabled={!!disabled}
      >
        <DisplayedOption fade={!showSelectedValue}>
          {!searchValue && (
            <>
              {selectedItem && (
                <CountryFlag size={20} country={selectedItem} disableTooltip />
              )}

              <DisplayedOptionText>
                {phoneCode
                  ? selectedItem
                    ? countryPhoneCode(selectedItem)
                    : null
                  : selectedItem}
              </DisplayedOptionText>
            </>
          )}
        </DisplayedOption>

        <Icon
          className="country_dropdown__chevron"
          onClick={() => setDropdownActive(!dropdownActive)}
          style={{ marginLeft: 'auto', cursor: 'pointer', zIndex: 100 }}
          name={dropdownActive ? 'Chevron-up' : 'Chevron-down'}
          color={colors.black}
          size={30}
        />
      </SelectedValueContainer>

      <StyledTextInput
        autocomplete="country-search"
        dropdownOpen={dropdownActive && !!filteredOptions.length}
        placeholder={placeholderValue}
        className="selectedValueContainer"
        name={name}
        title={title}
        onChange={(e) => setSearchValue(e.target.value)}
        type="text"
        value={searchValue}
        disabled={disabled}
        onFocus={() => {
          !disabled && onClick();
          setShowSelectedValue(false);
          setPlaceholderValue(
            showSelectedValue && !!selectedItem ? '' : placeholder
          );
          setDropdownActive(true);
        }}
        onBlur={(e) => {
          setShowSelectedValue(true);

          const newFocusedElement = e.relatedTarget as Node | null;
          if (newFocusedElement) {
            if (!wrapperRef?.current?.contains(newFocusedElement)) {
              setDropdownActive(false);
            }
          }
        }}
      />
      {errorMessage && (
        <ErrorMessage style={{ bottom: 0 }} message={errorMessage} />
      )}

      {dropdownActive && !!filteredOptions.length && (
        <CountryDropdownListWrapper>
          <CountryDropdownList phoneCode={phoneCode} ref={dropdownRef}>
            {filteredOptions.map((option, idx) => {
              return (
                <React.Fragment key={option}>
                  <CountryDropdownOption
                    data-cy="country-dropdown-option"
                    onClick={() => {
                      handleOptionClick(option);
                    }}
                    isSelected={option === selectedItem}
                  >
                    {!!dropDownItem ? (
                      dropDownItem(option, idx)
                    ) : (
                      <>
                        <CountryFlag
                          disableTooltip
                          size={20}
                          country={option}
                        />
                        {phoneCode ? (
                          <DropdownOptionText>
                            <span>{option}</span>
                            {` ${countryPhoneCode(option)}`}
                          </DropdownOptionText>
                        ) : (
                          <DropdownOptionText>{option}</DropdownOptionText>
                        )}
                      </>
                    )}
                  </CountryDropdownOption>
                </React.Fragment>
              );
            })}
          </CountryDropdownList>
        </CountryDropdownListWrapper>
      )}
    </div>
  );
};

export default FormCountryDropdownSearch;
