import { ComponentProps } from 'react';
import { PostIts } from '../../PostIts';

type Props = {
  postIts: ComponentProps<typeof PostIts>;
  Content?: (groupId: number) => React.ReactNode;
};

export const TheWhoCardGroups = ({ postIts, Content }: Props) => {
  return <PostIts {...postIts} Content={Content} />;
};
