import { CircularProgressBar } from 'components/CircularProgressBar';
import { BodyNormal, Caption, Collaboration } from 'components/shared';
import { colors } from 'constants/colors';
import {
  AlignedProgressWrapper,
  MetricsWrapper,
  StyledCollaboration,
} from '../StrategyDefinitionHeader.styles';

interface StrategyDefinitionHeaderCollaborationProps {
  isDesktop?: boolean;
  totalUsers?: number;
  collaboration?: {
    id: number;
    alignmentCount: number;
    commentCount: number;
    attachmentCount: number;
  };
  showAlignment?: boolean;
  isMultipleLists?: boolean;
}

export const StrategyDefinitionHeaderCollaboration = ({
  totalUsers,
  collaboration,
  isDesktop = false,
  showAlignment = false,
  isMultipleLists = false,
}: StrategyDefinitionHeaderCollaborationProps) => {
  if (!collaboration || !totalUsers) return null;

  const progressBarStroke = isDesktop ? 4 : 2;
  const progressBarSize = isDesktop ? undefined : 23;
  const progressBarProgress = (collaboration.alignmentCount / totalUsers) * 100;
  const resultDetailsCaption = isDesktop ? 'aligned' : '';
  const resultDetailsCounterCaption = `/${totalUsers}${
    isDesktop ? '' : ' aligned'
  }`;
  return (
    <MetricsWrapper isMultipleLists={isMultipleLists}>
      <StyledCollaboration isMultipleLists={isMultipleLists}>
        <Collaboration collaboration={collaboration} />
      </StyledCollaboration>

      {showAlignment && (
        <AlignedProgressWrapper isMultipleLists={isMultipleLists}>
          <CircularProgressBar
            progress={progressBarProgress}
            stroke={progressBarStroke}
            size={progressBarSize}
          />
          <div className="resultDetails">
            <div className="resultDetails_counter">
              <BodyNormal>{collaboration.alignmentCount}</BodyNormal>
              <Caption color={colors.greyDark}>
                {resultDetailsCounterCaption}
              </Caption>
            </div>
            <Caption color={colors.greyDark}>{resultDetailsCaption}</Caption>
          </div>
        </AlignedProgressWrapper>
      )}
    </MetricsWrapper>
  );
};
