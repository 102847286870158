import styled from 'styled-components';
import { Icon } from 'components/shared/Icon';
import { colors } from '../../../constants';

interface SolutionRowStatusProps {
  isComplete: boolean;
}

const Container = styled.div<{ isComplete: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;

  color: ${({ isComplete }) =>
    isComplete ? colors.purple : colors.burntOrange};

  background: ${({ isComplete }) =>
    isComplete ? colors.fadedGradient : colors.cream};
  border-radius: 5px;
  padding: 5px;
  width: 107px;
`;

export const SolutionRowStatus: React.FC<SolutionRowStatusProps> = ({
  isComplete,
}) => {
  return (
    <Container isComplete={isComplete}>
      {isComplete && <Icon size={30} name="Tick Small" color={colors.purple} />}
      {isComplete ? 'All set' : 'Need details'}
    </Container>
  );
};
