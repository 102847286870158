import {
  CompetitiveAdvantageRowFragment,
  KeyStatementFragment,
  StrategicQuestionFragment,
} from 'data/graphql/generated';
import PptxGenJS from 'pptxgenjs';
import { colors } from './colors';

function borders(top: 0 | 1, right: 0 | 1, bottom: 0 | 1, left: 0 | 1) {
  return [
    { pt: top, color: colors.purple },
    { pt: right, color: colors.purple },
    { pt: bottom, color: colors.purple },
    { pt: left, color: colors.purple },
  ];
}

interface Props {
  brand: string;
  startYear: number;
  endYear: number;
  winningAspiration?: string;
  brandPositioning?: string;
  imperatives?: CompetitiveAdvantageRowFragment[];
  allStrategicQuestions?: StrategicQuestionFragment[];
  keyStatements?: KeyStatementFragment[];
  mostAlignedImage?: string;
}

export default function exportSummaryThree({
  brand,
  startYear,
  endYear,
  winningAspiration,
  brandPositioning,
  imperatives,
  allStrategicQuestions,
  keyStatements,
  mostAlignedImage,
}: Props) {
  let pres = new PptxGenJS();

  let slide = pres.addSlide();

  slide.addText(`${brand} • ${startYear} - ${endYear} | The Strategy`, {
    x: 0.2,
    y: 0.2,
    h: 0.2, // height in inches
    color: colors.black,
    fontSize: 14,
    fontFace: 'Arial',
  });

  const imperativeCount: number = imperatives?.length || 0;
  const columnCount: number = imperativeCount > 2 ? imperativeCount : 2;

  const options = {
    color: colors.black,
    fontSize: 10,
    fontFace: 'Arial',
    fill: 'ffffff',
  };

  const subTitle = {
    color: colors.grey,
    fontSize: 8,
  };

  let rows: any[] = [
    [
      {
        text: 'Winning Aspiration',
        options: {
          ...options,
          colspan: columnCount,
          fill: 'f8f2ff',
          border: borders(1, 0, 0, 0),
          ...subTitle,
          // NOTE ^maxim these doesn't seem to do anything
          paraSpaceAfter: 0,
          paraSpaceBefore: 0,
        },
      },
    ],
    [
      {
        text: winningAspiration || 'Winning aspiration not defined yet',
        options: {
          ...options,
          colspan: columnCount,
          fill: 'f8f2ff',
          border: borders(0, 0, 1, 0),
          // NOTE ^maxim these doesn't seem to do anything
          paraSpaceAfter: 0,
          paraSpaceBefore: 0,
        },
      },
    ],
    [
      {
        text: 'Brand Positioning',
        options: {
          ...options,
          colspan: columnCount,
          fill: 'f8f2ff',
          border: borders(1, 0, 0, 0),
          ...subTitle,
        },
      },
    ],
    [
      {
        text: brandPositioning || 'Brand positioning not defined yet',
        options: {
          ...options,
          colspan: columnCount,
          fill: 'f8f2ff',
          border: borders(0, 0, 1, 0),
        },
      },
    ],
  ];

  interface ImperativeData {
    name: string;
    behaviourToDrive?: string;
    driverOfTheBehaviour?: string;
    objectives: string[];
    leadIndicators: string[];
    lagIndicators: string[];
  }

  const imperativeData: ImperativeData[] = (imperatives || []).map((s) => {
    const strategicQuestions = allStrategicQuestions?.filter(
      (sq) => sq.competitiveAdvantageRowId === s.id
    );
    const indicators = strategicQuestions?.find(
      (sq) => sq.field === 'leadIndicators\nlagIndicators'
    )?.lists;
    const leadIndicators = indicators?.[0]?.list || [];
    const lagIndicators = indicators?.[1]?.list || [];
    const strategicObjectives = (keyStatements || []).filter((ks) => {
      return ks.competitiveAdvantageRowId === s.id;
    });

    return {
      name: s.strategicPossibility?.name || '',
      behaviourToDrive: s.strategicPossibility?.behaviourToDrive || '',
      driverOfTheBehaviour: s.strategicPossibility?.driverOfTheBehaviour || '',
      objectives: strategicObjectives.map((so) => so.text),
      leadIndicators: leadIndicators.filter((x) => x !== ''),
      lagIndicators: lagIndicators.filter((x) => x !== ''),
    };
  });

  const imperativeColumnSpan = imperativeData.length === 1 ? 2 : 1;

  const imperativeRows: any[] =
    imperativeData.length === 0
      ? [
          [
            {
              text: 'No Strategic Imperatives yet',
              options: {
                ...options,
                fill: 'f8f2ff',
                border: borders(1, 0, 1, 0),
              },
            },
          ],
        ]
      : [
          // Imperatives Row Titles
          imperativeData.map((imperativeData, idx) => ({
            text: 'Strategic imperative',
            options: {
              ...options,
              colspan: imperativeColumnSpan,
              ...subTitle,
              border: borders(1, 0, 0, 0),
              fill: idx % 2 === 0 ? 'f8f2ff' : 'ffffff',
            },
          })),
          // Imperatives Row Data
          imperativeData.map((imperativeData, idx) => ({
            text: [
              imperativeData.name,
              imperativeData.behaviourToDrive,
              imperativeData.driverOfTheBehaviour,
            ].join('\n\n'),
            options: {
              ...options,
              colspan: imperativeColumnSpan,
              border: borders(0, 0, 1, 0),
              fill: idx % 2 === 0 ? 'f8f2ff' : 'ffffff',
            },
          })),

          // Objectives Row Titles
          imperativeData.map((imperativeData, idx) => ({
            text: 'Strategic objectives',
            options: {
              ...options,
              colspan: imperativeColumnSpan,
              ...subTitle,
              border: borders(1, 0, 0, 0),
              fill: idx % 2 === 0 ? 'f8f2ff' : 'ffffff',
            },
          })),
          // Objectives Row
          imperativeData.map((imperativeData, idx) => ({
            text:
              imperativeData.objectives.length > 0
                ? imperativeData.objectives.join('\n\n')
                : 'None identified yet',
            options: {
              ...options,
              colspan: imperativeColumnSpan,
              border: borders(0, 0, 1, 0),
              fill: idx % 2 === 0 ? 'f8f2ff' : 'ffffff',
            },
          })),

          // Lead Indicators Row Titles
          imperativeData.map((imperativeData, idx) => ({
            text: 'Lead indicators',
            options: {
              ...options,
              colspan: imperativeColumnSpan,
              ...subTitle,
              border: borders(1, 0, 0, 0),
              fill: idx % 2 === 0 ? 'f8f2ff' : 'ffffff',
            },
          })),
          // Lead Indicators Row
          imperativeData.map((imperativeData, idx) => ({
            text:
              imperativeData.leadIndicators.length > 0
                ? imperativeData.leadIndicators.join('\n\n')
                : 'None identified yet',
            options: {
              ...options,
              colspan: imperativeColumnSpan,
              border: borders(0, 0, 1, 0),
              fill: idx % 2 === 0 ? 'f8f2ff' : 'ffffff',
            },
          })),

          // Lag Indicators Row Titles
          imperativeData.map((imperativeData, idx) => ({
            text: 'Lag indicators',
            options: {
              ...options,
              colspan: imperativeColumnSpan,
              ...subTitle,
              border: borders(1, 0, 0, 0),
              fill: idx % 2 === 0 ? 'f8f2ff' : 'ffffff',
            },
          })),
          // Lag Indicators Row
          imperativeData.map((imperativeData, idx) => ({
            text:
              imperativeData.lagIndicators.length > 0
                ? imperativeData.lagIndicators.join('\n\n')
                : 'None identified yet',
            options: {
              ...options,
              colspan: imperativeColumnSpan,
              border: borders(0, 0, 1, 0),
              fill: idx % 2 === 0 ? 'f8f2ff' : 'ffffff',
            },
          })),
        ];

  // @ts-ignore
  slide.addTable([...rows, ...imperativeRows], { x: 0.3, y: 0.6, w: 9.4 });

  // NOTE ^maxim this currently throws a CORS error, so I've removed it
  //if (mostAlignedImage) {
  //slide.addImage({
  //path: mostAlignedImage,
  //x: 9.25,
  //y: 0.65,
  //w: 0.4,
  //h: 0.4,
  //});
  //}

  pres.writeFile({
    fileName: `${brand}_${startYear}-${endYear}_The Strategy.pptx`,
  });
}
