import sortBy from 'lodash/sortBy';
import { StrategicQuestionFragment } from 'data/graphql/generated';
import { MedicalStrategyTabNames } from 'components/4-2-medical-strategy/shared/data-access-medical-strategy';

type Props = {
  activeSubStep: string;
  globalStrategicQuestion?: StrategicQuestionFragment;
  strategicQuestion?: StrategicQuestionFragment;
  unlockingInsight: string;
};

export const useGetImperativeSidebarData = ({
  activeSubStep,
  globalStrategicQuestion,
  strategicQuestion,
  unlockingInsight,
}: Props) => {
  const globalHurdlesAndGaps = [
    MedicalStrategyTabNames.MEDICAL_TACTICS,
    MedicalStrategyTabNames.MEDICAL_OBJECTIVES,
  ].includes(activeSubStep)
    ? sortBy(globalStrategicQuestion?.lists, ['id']) || []
    : undefined;

  const localHurdlesAndGaps = [
    MedicalStrategyTabNames.MEDICAL_TACTICS,
    MedicalStrategyTabNames.MEDICAL_OBJECTIVES,
  ].includes(activeSubStep)
    ? sortBy(strategicQuestion?.lists, ['id']) || []
    : undefined;

  const hurdlesAndGapsSidebarData = [
    MedicalStrategyTabNames.HURDLES_AND_GAPS,
  ].includes(activeSubStep)
    ? {
        unlockingInsight: unlockingInsight,
      }
    : {};

  return {
    globalHurdlesAndGaps,
    localHurdlesAndGaps,
    hurdlesAndGapsSidebarData,
  };
};
