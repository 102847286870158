import { colors } from 'constants/colors';
import { useEffect, useRef, useState } from 'react';
import { ProgressDonutConstants } from './ProgressDonut.constants';
import {
  StyledContainer,
  ChildrenContainer,
  ProgressDonutSvg,
  ProgressDonutBaseCircle,
  ProgressDonutProgressCircle,
  ProgressDonutTargetCircle,
} from './ProgressDonut.styles';

interface ProgressDonutProps {
  /** The size of the progress donut in px.
   * Used to calculate the height and width of the progress donut.
   */
  size: number;
  percentage?: number;
  target: number;
  children: React.ReactNode[];
}

export const ProgressDonut = ({
  size,
  percentage,
  target,
  children,
}: ProgressDonutProps) => {
  const targetCircleRef = useRef<SVGCircleElement>(null!);

  const [targetRotation, setTargetRotation] = useState<number>(90);

  const strokeWidthOffset = 2;
  const container = size - strokeWidthOffset;

  const R = container / 2;
  const PI = Math.PI;
  const circumference = R * 2 * PI;

  const progress =
    percentage && circumference - (percentage / 100) * circumference;
  const targetProgress = circumference - (target / 100) * circumference;

  useEffect(() => {
    /* We need to get the angle of rotation from the origin for the end point
     of the circle, so we can accurately place a marker there */
    const targetCircle = targetCircleRef.current;
    if (targetCircle) {
      const targetPoint = targetCircle.getPointAtLength(targetProgress);

      const rad = Math.atan2(targetPoint.x - R, targetPoint.y - R);
      const deg = rad * (180 / PI);
      const ratio =
        ProgressDonutConstants.MARKER_STROKE_WIDTH / 2 / circumference;
      const offsetRotation = ratio * 360;

      setTargetRotation(deg + offsetRotation);
    }
  }, [PI, R, circumference, targetProgress]);

  return (
    <StyledContainer size={size}>
      <ChildrenContainer>{children}</ChildrenContainer>
      <ProgressDonutSvg preserveAspectRatio={'xMinYMin meet'}>
        <defs>
          <linearGradient
            id="ProgressDonut-green-purple-gradient"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor="rgba(120, 0, 255, 1)" />
            <stop offset="100%" stopColor="rgba(7, 135, 102, 1)" />
          </linearGradient>
        </defs>
        <ProgressDonutBaseCircle cx={R} cy={R} r={R} />
        {Boolean(target) && (
          <ProgressDonutTargetCircle
            circumference={circumference}
            progress={targetProgress}
            ref={targetCircleRef}
            cx={R}
            cy={R}
            r={R}
            style={{
              transformOrigin: `${R}px ${R}px`,
            }}
          />
        )}

        <ProgressDonutProgressCircle
          circumference={circumference}
          progress={Number(progress)}
          progressPercentage={percentage ?? 0}
          targetPercentage={target}
          cx={R}
          cy={R}
          r={R}
          style={{
            transformOrigin: `${R}px ${R}px`,
          }}
        />

        <g
          transform={`rotate(${targetRotation})`}
          style={{
            transformOrigin: `${R}px ${R}px`,
          }}
        >
          <line
            x1={-10}
            y1={0}
            y2={0}
            x2={10}
            stroke={colors.purple}
            strokeWidth={ProgressDonutConstants.MARKER_STROKE_WIDTH}
            transform={`translate(${0}, ${R})`}
          />
        </g>
      </ProgressDonutSvg>
    </StyledContainer>
  );
};
