import { colors } from 'constants/index';
import React from 'react';
import styled from 'styled-components';
import { ButtonLabel } from './TextStyles';
import { Icon } from 'components/shared';
import { IconName } from 'types';
import { device } from 'utils/breakpoints';

interface Props {
  text: string;
  active?: boolean;
  icon?: IconName;
  onClick?(): void;
  className?: string;
  border?: boolean;
  gradient?: boolean;
  style?: React.CSSProperties;
}

interface WrapperProps {
  active: boolean;
  border: boolean;
  gradient?: boolean;
}

const Border = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 25px;
  padding: 2px;
  overflow: hidden;
  background: ${`linear-gradient(315deg, ${colors.pink} -83%, ${colors.purple} 93.75%)`};
`;

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 40px;
  justify-content: center;
  padding: 0 20px 0 15px;

  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 25px;

  background: #F2E6FF;

  p {
    white-space: nowrap;
    color: #7800FF;
    transition: color 0.3s;
  }

  &:hover {
    background: ${({ active }) => (active ? colors.white : colors.white70)};
  }

  ${({ gradient }) => gradient ?
  `
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      background: 
        linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
        url(/strat-grad.png) no-repeat center center / cover;
      opacity: 0.2;
      z-index: 0;
    }
  ` : null}

  @media (${device.tabletMax}) {
    height: 30px;
    padding: 0 8px 0 0px;
  }
`;

export const SubMenu = ({
  text,
  active = false,
  onClick,
  icon,
  className = '',
  style = {},
  border = false,
  gradient = false
}: Props) => {
  const BorderWrapper = ({ children }: { children: React.ReactNode }) => (
    <>
      {border ? <Border>{children}</Border> : children}
    </>
  );

  return (
    <BorderWrapper>
      <Wrapper
        data-cy="step-tab"
        className={`${className} stakeholderTab2`}
        active={active}
        border={border}
        gradient={gradient}
        onClick={onClick}
        style={style}
      >
        {icon && (
          <Icon
            name={icon}
            color={colors.purple}
            size={30}
            style={{ position: 'relative', zIndex: 1 }} 
          />
        )}

        {text && (
          <ButtonLabel style={{ position: 'relative', zIndex: 1 }}>{text}</ButtonLabel>
        )}
      </Wrapper>
    </BorderWrapper>
  );
};
