import { Icon } from 'components/shared';
import { colors } from 'constants/index';
import styled, { css } from 'styled-components';
import { CountryGlobal } from 'types';

const StarWrapper = styled.div<{ canEdit: boolean }>`
  ${({ canEdit }) => {
    if (!canEdit) {
      return css`
        cursor: not-allowed;
        > * {
          pointer-events: none;
        }
      `;
    }
  }};
`;
const StyledStarIcon = styled(Icon)`
  cursor: pointer;
`;

interface Props {
  canEdit: boolean;
  currentCountry: CountryGlobal;
  isStarred: boolean;
  isGlobalCountry: boolean;
  onClick(): void;
  className?: string;
}

export const Star = ({
  canEdit,
  currentCountry,
  isStarred,
  isGlobalCountry,
  onClick,
  className,
}: Props) => {
  return (
    <StarWrapper
      canEdit={canEdit}
      data-for="starringModal"
      data-place="right"
      data-tip={
        !canEdit
          ? currentCountry === 'global'
            ? 'Only Leads can edit'
            : 'Only users from this market can edit'
          : ''
      }
    >
      {isStarred && isGlobalCountry ? (
        <StyledStarIcon
          name="StarPurple"
          size={30}
          color={colors.black70}
          className={className}
          onClick={onClick}
        />
      ) : (
        <StyledStarIcon
          name="StarOutline"
          color={colors.black70}
          size={30}
          fillColor={isStarred ? colors.black70 : undefined}
          className={className}
          onClick={onClick}
        />
      )}
    </StarWrapper>
  );
};
