import styled from 'styled-components/macro';
import { lowerCase, upperFirst } from 'lodash';

import { colors } from 'constants/colors';
import { BodySmall, Icon } from 'components/shared';

const NonEditable = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  max-width: 250px;
  width: 100%;
`;

interface LockedFieldProps {
  text: string;
  onClick: () => void;
}

export const LockedField = ({ text, onClick }: LockedFieldProps) => {
  const fieldValue = upperFirst(lowerCase(text));

  return (
    <NonEditable onClick={onClick}>
      <BodySmall color={colors.black50}>{fieldValue}</BodySmall>
      <Icon name="Lock" size={20} color={colors.black50} />
    </NonEditable>
  );
};
