import { PostItsEmpty } from 'components/PostItsEmpty';
import { CountryGlobal } from '../../types';

export function SummaryPageEmptyStates({
  localCountryTacticsEnabled,
  financialSummarySelectedRegions,
}: {
  localCountryTacticsEnabled: boolean | null | undefined;
  financialSummarySelectedRegions: CountryGlobal[];
}) {
  return localCountryTacticsEnabled &&
    !financialSummarySelectedRegions.length ? (
    <PostItsEmpty
      title="No region selected"
      subtitle="Choose a region using the dropdown above"
    />
  ) : (
    <PostItsEmpty
      title="No budget assigned yet"
      subtitle="Financials will be displayed here by strategic imperative and year"
    />
  );
}
