import { colors } from 'constants/colors';
import React from 'react';
import styled from 'styled-components';
import { Icon } from './Icon';
import { Subtitle2 } from './TextStyles';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const TickIcon = styled(Icon)`
  height: 20px;
`;

const ClockIcon = styled(Icon)`
  svg {
    margin: -5px;
  }
`;

interface Props {
  name: string;
  verifiedEmail: boolean;
  displayStatus: boolean;
}

export const NameAndStatus = ({
  name,
  verifiedEmail,
  displayStatus,
}: Props) => (
  <Wrapper>
    <Subtitle2>{name}</Subtitle2>
    {displayStatus &&
      (verifiedEmail ? (
        <TickIcon name="Tick" size={26} color={colors.greenDark} />
      ) : (
        <ClockIcon name="Clock" size={30} color={colors.orange} />
      ))}
  </Wrapper>
);
