import { CreatorDetails, FieldAndText } from 'components/CollaborationModal';
import { BodySmall, Icon } from 'components/shared';
import { colors } from 'constants/colors';
import { PicoSuggestionFragment } from 'data/graphql/generated';
import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: 100%;
`;

const MainContent = styled.div`
  background: ${colors.white};
  border-radius: 5px;
  padding: 20px 10px;
  width: fill-available;
`;

interface Props {
  picoSuggestion: PicoSuggestionFragment;
}

export default function CollaborationPicoSuggestion({ picoSuggestion }: Props) {
  const focused = !!picoSuggestion.focus;

  return (
    <Wrapper>
      <MainContent>
        {focused && (
          <div style={{ display: 'flex', marginBottom: 20 }}>
            <Icon
              name="GradientTick"
              size={20}
              style={{ marginRight: '4px' }}
            />
            <BodySmall>Focus</BodySmall>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 15,
          }}
        >
          {focused && <FieldAndText title="Name" text={picoSuggestion.name} />}
          <FieldAndText title="Population" text={picoSuggestion.population} />

          <FieldAndText
            title="Intervention"
            text={picoSuggestion.intervention}
          />
          <FieldAndText title="Comparison" text={picoSuggestion.comparison} />
          <FieldAndText title="Outcome" text={picoSuggestion.outcome} />
        </div>
      </MainContent>
      <CreatorDetails user={picoSuggestion.user} />
    </Wrapper>
  );
}
