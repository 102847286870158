import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import get from 'lodash/get';

import { useSignInWithTokenMutation } from 'data/graphql/generated';
import { useAuthContext } from 'contexts/AuthContext';

export const AuthRedirect: React.FC = () => {
  const [auth, updateAuth] = useAuthContext();
  const [signinWithToken] = useSignInWithTokenMutation();
  const location = useLocation();
  const history = useHistory();

  async function signinOrRedirect({
    intendedUrl,
    token,
  }: {
    intendedUrl: string;
    token: string | null;
  }) {
    if (token) {
      // Signin with token
      try {
        const res = await signinWithToken({
          variables: {
            token,
          },
        });
        const data = get(res, 'data.signInWithToken');
        updateAuth({
          user: data.user,
        });
        Sentry.setUser({ email: data.user.email });
        // Redirect to page
        if (data?.user?.role === 'ADMIN') {
          history.push('/admin');
        } else if (intendedUrl && !intendedUrl.includes('/signin')) {
          history.push(intendedUrl);
        } else {
          history.push('/');
        }
      } catch (err) {
        console.error(err);
        // Store where they are intending to go
        updateAuth({ redirectPath: intendedUrl });
        // Redirect to signin page
        history.push('/signin');
      }
    } else {
      // Store where they are intending to go
      updateAuth({ redirectPath: intendedUrl });
      // Redirect to signin page
      history.push('/signin');
    }
  }

  useEffect(() => {
    const isAuthPage =
      location.pathname.includes('/signin/forgot-password') ||
      location.pathname.includes('/signin/reset-password') ||
      location.pathname.includes('/signup');

    const isPublicPage = location.pathname.includes('/terms');

    // If a user is not logged in
    if (!auth.user && !isAuthPage && !isPublicPage) {
      // Check if token is in url
      const urlParams = new URLSearchParams(location.search);
      const token = urlParams.get('token');
      const intendedUrl = location.pathname + location.search;
      signinOrRedirect({ intendedUrl, token });
    }
    // eslint-disable-next-line
  }, []);

  return <div />;
};
