import { Icon } from 'components/shared';
import { colors } from 'constants/index';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { PercentageToggleDropdown } from '../PercentageToggleDropdown';

const Toggle = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  div:nth-child(2) {
    position: relative;
    left: -5px;
  }
  cursor: pointer;
`;

const Wrapper = styled.div`
  position: relative;
`;

interface Props {
  setShowAsPercentage: React.Dispatch<React.SetStateAction<boolean>>;
  showAsPercentage: boolean;
}

export const DropdownToggle = ({
  setShowAsPercentage,
  showAsPercentage,
}: Props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef(null);
  useClickOutsideComponent(ref, (event) => {
    showDropdown && setShowDropdown(false);
  });

  return (
    <Wrapper ref={ref}>
      <Toggle
        onClick={() => {
          setShowDropdown(true);
        }}
      >
        <Icon
          size={30}
          name={!showAsPercentage ? 'Hash' : 'Percent'}
          color={colors.black}
        />
        <Icon color={colors.black} size={20} name="Chevron-down" />
      </Toggle>
      <PercentageToggleDropdown
        showDropdown={showDropdown}
        showAsPercentage={showAsPercentage}
        setShowAsPercentage={setShowAsPercentage}
        setShowDropdown={setShowDropdown}
        labels={['Figures', 'Percentage']}
      />
    </Wrapper>
  );
};
