import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import Textarea from 'react-expanding-textarea';

import { colors } from 'constants/index';
import {
  FileModel,
  Stakeholder,
  StakeholderDefinition,
  useRequestFileUploadMutation,
} from 'data/graphql/generated';
import { uploadFile } from 'utils/uploadFile';
import { useDropzone } from 'react-dropzone';
import {
  Icon,
  ButtonLabel,
  ButtonRound,
  AnimatingWaveIcon,
} from 'components/shared/';
import { useDropZoneContext } from 'contexts/DropZoneContext';
import { BodyNormal } from './TextStyles';
import { mapStakeholderEnumToSingularStakeholder } from 'utils/mapStakeholderEnumToSingularStakeholder';

interface Props {
  imageURL?: string | null;
  onTextChange(value: string): void;
  onImageUpload: (image: string) => void;
  onImageRemoved(): void;
  onDelete(): void;
  textValue: string;
  focus?: boolean;
  showDelete?: boolean;
  editable?: boolean;
  placeholder: string;
  stakeholderDefinition: StakeholderDefinition;
  setDisableCreateButton: React.Dispatch<React.SetStateAction<boolean>>;
  isLastInList: boolean;
}

const borderWidth = '2px';

const BorderWrapper = styled.div`
  width: 260px;
  height: 100px;
  background: ${`linear-gradient(315deg, ${colors.pink} -83%, ${colors.purple} 93.75%)`};
  border-radius: 50px 5px 5px 50px;

  position: relative;
  margin-right: 6px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background: ${colors.purple05};

  width: 100%;
  height: 100%;
  border-radius: 50px 5px 5px 50px;
  background-clip: padding-box;
  border: solid ${borderWidth} transparent;
  box-sizing: border-box;
  margin-left: auto;
`;

const StyledTextArea = styled(Textarea)<{
  ['data-showoutline']: boolean;
  $editable: boolean;
}>`
  resize: none;
  width: 143px;
  margin-left: 106px;
  margin-right: 10px;
  padding: 0 6px 0 0;
  border: none;
  background: transparent;
  color: ${colors.black};
  font-family: ABCFavorit;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  border-radius: 0px;
  caret-color: transparent;

  max-height: 80px;

  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;

  &:focus {
    overflow: auto;
    -webkit-line-clamp: none;
    text-overflow: '';
    display: block;
  }

  &:focus,
  &:active {
    outline: ${(props) =>
      `${
        !props['data-showoutline']
          ? `0.5px solid ${colors.blue}`
          : '0.5px solid transparent'
      }`};
    -moz-outline-radius: 0px;
    caret-color: ${colors.black};
  }
  &::selection {
    background: #c6e2f6;
  }
  transition: outline 0s, caret-color 0.1s;
  transition-delay: 0.1s, 0.1s;

  &:hover {
    cursor: ${({ $editable }) => (!$editable ? 'not-allowed' : 'auto')};
  }
`;
const DeleteIconWrapper = styled.div<{ show: boolean }>`
  position: absolute;
  top: -15px;
  right: -15px;
  opacity: ${({ show }) => Number(show)};
  pointer-events: ${({ show }) => (show ? 'all' : 'none')};
  transition: opacity 0.3s ease;
  z-index: 2;
`;

const DeletionConfirmation = styled.div<{ show: boolean }>`
  position: absolute;
  height: 80px;
  width: 100%;
  right: 10px;
  top: 10px;

  text-align: center;

  z-index: 4;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  > div:last-child {
    display: flex;
    width: 120px;
    justify-content: space-between;
    p {
      cursor: pointer;
    }
  }
`;

export const KeyStakeholderCreator = ({
  imageURL = '',
  onImageUpload,
  onImageRemoved,
  onTextChange,
  textValue,
  focus = false,
  onDelete,
  showDelete = true,
  editable = true,
  placeholder,
  stakeholderDefinition,
  setDisableCreateButton,
  isLastInList,
}: Props) => {
  const [text, setText] = useState(textValue);
  const [placeholderText, setPlaceholderText] = useState(() => {
    return !textValue ? placeholder : '';
  });
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteIconHover, setDeleteIconHover] = useState(false);

  useEffect(() => {
    // if last in list and no text, disable create button
    if (isLastInList && text === '') {
      setDisableCreateButton(true);
    } else {
      setDisableCreateButton(false);
    }
  }, [text, setDisableCreateButton, isLastInList]);

  return (
    <BorderWrapper className="cypress-keystakeholder-create">
      <DeletionConfirmation show={showDeleteConfirmation}>
        <BodyNormal>{`Delete this ${
          stakeholderDefinition.stakeholder === Stakeholder.Healthcare
            ? 'HCP'
            : mapStakeholderEnumToSingularStakeholder[
                stakeholderDefinition.stakeholder
              ]
        }?`}</BodyNormal>
        <div>
          <ButtonLabel
            onClick={() => {
              if (editable) {
                setShowDeleteConfirmation(false);
                setShowDeleteIcon(false);
              }
            }}
          >
            Cancel
          </ButtonLabel>
          <ButtonLabel
            color={colors.red}
            onClick={() => {
              editable && onDelete();
              setShowDeleteConfirmation(false);
              setShowDeleteIcon(false);
            }}
          >
            Delete
          </ButtonLabel>
        </div>
      </DeletionConfirmation>
      <DeleteIconWrapper
        className="cypress-keystakeholder-delete-icon"
        show={showDelete && showDeleteIcon}
        onClick={() => setShowDeleteConfirmation(true)}
        onMouseEnter={() => {
          setDeleteIconHover(true);
        }}
        onMouseLeave={() => {
          setDeleteIconHover(false);
        }}
      >
        <ButtonRound
          iconName="Trash"
          level="secondary"
          size="small"
          tooltip=""
        />
      </DeleteIconWrapper>

      <DropZone
        stakeholderDefinition={stakeholderDefinition}
        editable={editable && !!text.length}
        image={imageURL}
        onImageUpload={(imageUrl) => {
          editable && !!text.length && onImageUpload(imageUrl);
        }}
        onImageRemoved={() => {
          editable && onImageRemoved();
        }}
      />
      <Wrapper>
        {!showDeleteConfirmation && (
          <StyledTextArea
            className="cypress-keystakeholder-textfield"
            onChange={(e) => {
              const target = e.target as HTMLTextAreaElement;
              if (editable) setText(target.value);
            }}
            //Using a data attribute removes the "React does not recognize the [something] prop on a DOM element."
            data-showoutline={!!placeholderText}
            autoFocus={focus}
            value={text}
            rows={!!placeholderText ? (editable ? 2 : 3) : 1}
            onFocus={(e) => {
              if (editable) {
                setShowDeleteIcon(true);
                setPlaceholderText('');
              }
            }}
            onBlur={(e) => {
              e.stopPropagation();

              if (editable) {
                !deleteIconHover && setShowDeleteIcon(false);
                !text && setPlaceholderText(placeholder);

                if (text.length) {
                  setText(text.trim());
                  onTextChange(text.trim());
                } else {
                  if (!textValue) {
                    onDelete();
                  } else {
                    setText(textValue);
                    setPlaceholderText(textValue);
                  }
                }

                e.target.scrollTop = 0;
              }
            }}
            placeholder={placeholderText}
            $editable={editable}
          />
        )}
      </Wrapper>
    </BorderWrapper>
  );
};

interface ImageDropperWrapperProps {
  image: string | null;
  isDragActive: boolean;
  hover: boolean;
  editable: boolean;
}

const ImageDropperWrapper = styled.div<ImageDropperWrapperProps>`
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 96px;
  height: 96px;
  cursor: ${({ editable }) => (editable ? 'pointer' : 'not-allowed')};
  opacity: ${({ editable }) => (editable ? '1' : '0.5')};

  overflow: hidden;

  .patientImage__BG {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    filter: blur(15px);
    background-image: ${({ image }) => {
      const background = image ? `url(${image})` : `none`;

      return background;
    }};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scale(1.5);
  }

  .patientImage {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
    background-size: contain;
    background-image: ${({ image }) => {
      const background = image
        ? `url(${image})`
        : `linear-gradient(315deg, ${colors.pink} -83%, ${colors.purple} 93.75%)`;

      return background;
    }};
    background-position: center;
    background-repeat: no-repeat;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: ${({ isDragActive }) => {
      const dashes = isDragActive
        ? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23${colors.white.slice(
            1
          )}' stroke-width='5' stroke-dasharray='10' stroke-dashoffset='86' stroke-linecap='butt'/%3e%3c/svg%3e")`
        : '';
      return dashes;
    }};

    border-radius: 1000px;

    opacity: ${({ isDragActive }) => Number(isDragActive)};
    transition: opacity 0.3s ease;
  }

  border-radius: 1000px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &:focus {
    outline: none;
  }
`;
const DimBackground = styled.div<{ dim: boolean; overlay: boolean }>`
  width: 100%;
  height: 100%;
  background-color: ${({ dim, overlay }) => {
    if (overlay) {
      return 'rgba(0, 0, 0, 0.1)';
    }
    if (dim) {
      return 'rgba(0, 0, 0, 0.8)';
    }
    return 'transparent';
  }};
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 13px 5px;

  transition: background-color 0.3s ease;
`;

interface DropZoneProps {
  editable: boolean;
  image: string | null;
  onImageUpload: (imageUrl: string) => void;
  onImageRemoved(): void;
  stakeholderDefinition: StakeholderDefinition;
}

function DropZone({
  stakeholderDefinition,
  onImageUpload,
  onImageRemoved,
  editable,
  image,
}: DropZoneProps) {
  const hasImage = !!image;
  const [requestFileUpload] = useRequestFileUploadMutation();
  const [hover, setHover] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      setErrMsg('');
      setUploading(true);

      try {
        const file = acceptedFiles[0];

        if (!file) {
          throw Error('Upload failed');
        }

        const { data } = await requestFileUpload({
          variables: {
            data: {
              strategyId: stakeholderDefinition.strategyId,
              model: FileModel.StakeholderDefinition,
              modelId: stakeholderDefinition.id,
              name: file.name,
            },
          },
        });

        if (!data?.requestFileUpload) {
          throw Error('Upload failed');
        }

        await uploadFile(acceptedFiles, data.requestFileUpload);

        onImageUpload(
          `${data.requestFileUpload.url}/${data.requestFileUpload.fields.key}`
        );
      } catch (error) {
        if (
          JSON.stringify(error).includes('exceeds the maximum allowed size')
        ) {
          return setErrMsg('Max 20MB');
        }
        setErrMsg('Upload failed');
      } finally {
        setUploading(false);
      }
    },
    onDropRejected: async (evt) => {
      const err = evt[0];
      const firstError = err.errors[0];

      if (!firstError) return;
      if (firstError.code.includes('file-invalid-type')) {
        return setErrMsg('Invalid file');
      }
      if (firstError.code.includes('file-too-large')) {
        return setErrMsg('Max 20MB');
      }
    },
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
    maxSize: 20971520,
    noClick: hasImage || !!errMsg,
  });
  const isDragActive = useDropZoneContext().isDragActive && editable;

  return (
    <ImageDropperWrapper
      {...getRootProps({
        onMouseLeave: () => {
          setHover(false);
        },
        onMouseEnter: () => {
          setHover(true);
        },
        onClick: () => {
          if (hasImage && !errMsg && !uploading) {
            onImageRemoved();
          }
        },
      })}
      isDragActive={isDragActive}
      image={image}
      hover={hover}
      editable={editable}
    >
      <input {...getInputProps()} />
      <div className="patientImage__BG" />

      <div className="patientImage" />

      <DimBackground
        overlay={!hasImage && hover}
        dim={!!(hasImage && (isDragActive || hover || !!errMsg || uploading))}
      >
        {editable ? (
          <>
            {isDragActive ? (
              <>
                <Icon name="Upload" size={30} color={colors.white} />
                <ButtonLabel color={colors.white}>
                  Drop here to upload
                </ButtonLabel>
              </>
            ) : errMsg ? (
              <>
                <Icon name="Sad" size={30} color={colors.white} />
                <ButtonLabel color={colors.white}>{errMsg}</ButtonLabel>
                <ButtonLabel
                  style={{ zIndex: 1 }}
                  color={colors.white}
                  onClick={() => {
                    setErrMsg('');
                  }}
                >
                  OK
                </ButtonLabel>
              </>
            ) : uploading ? (
              <>
                <AnimatingWaveIcon className="icon" size={30} color={'white'} />
                <ButtonLabel color={colors.white}>Uploading...</ButtonLabel>
              </>
            ) : hasImage ? (
              hover ? (
                <>
                  <Icon name="Close" size={30} color={colors.white} />
                  <ButtonLabel color={colors.white}>Remove Image</ButtonLabel>
                </>
              ) : (
                <></>
              )
            ) : hover ? (
              <>
                <Icon name="Upload" size={30} color={colors.white} />
                <ButtonLabel color={colors.white}>Upload Image</ButtonLabel>
              </>
            ) : (
              <>
                <Icon name="Patient" size={30} color={colors.white} />
                <ButtonLabel color={colors.white}>Upload Image</ButtonLabel>
              </>
            )}
          </>
        ) : hasImage ? null : (
          <Icon name="Patient" size={30} color={colors.white} />
        )}
      </DimBackground>
    </ImageDropperWrapper>
  );
}
