import {
  AnimatingWaveIcon,
  BodySmall,
  ButtonLabel,
  ButtonRound,
} from 'components/shared';
import React, { useState } from 'react';
import styled from 'styled-components';

import { colors } from 'constants/index';
import { useDropzone } from 'react-dropzone';
import { useDropZoneContext } from 'contexts/DropZoneContext';
import { imageStateType } from './CreateUpdateCompetitorModal';

const TextOverlays = styled.div<{ imageState: boolean; isDragActive: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s;

  background: ${({ imageState, isDragActive }) =>
    imageState && isDragActive ? colors.black80a : 'transparent'};
`;

const ImageWrapper = styled.div`
  position: absolute;
  inset: 0;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditDeleteButtons = styled.div`
  position: absolute;
  right: -32px;
  top: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const DropZoneWrapper = styled.div<{
  isDragOver: boolean;
  hasImage: boolean;
  isDragActive: boolean;
}>`
  width: 100px;
  height: 100px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 5px;
  margin-bottom: 10px;

  border: 2px dashed
    ${({ isDragOver, hasImage, isDragActive }) =>
      hasImage && !isDragOver && !isDragActive
        ? 'transparent'
        : isDragOver
        ? colors.purple
        : colors.greyDark};

  background: ${({ isDragOver }) =>
    isDragOver ? colors.purple05 : 'transparent'};

  border-radius: 5px;
  padding: 5px;
  transition: border-color 0.5s, background 0.3s;
`;

const LogoUploadWrapper = styled.div`
  height: 155px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

interface Props {
  errMsg: string;
  setErrMsg: React.Dispatch<React.SetStateAction<string>>;
  imageState: imageStateType;
  setImageState: React.Dispatch<React.SetStateAction<imageStateType>>;
}

export const LogoUploader = ({
  errMsg,
  setErrMsg,
  imageState,
  setImageState,
}: Props) => {
  const [imagePreviewUploading, setImagePreviewUploading] = React.useState(
    false
  );
  const [isDragOver, setIsDragOver] = useState(false);

  const isDragActive = useDropZoneContext().isDragActive;

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: async (acceptedFiles) => {
      setIsDragOver(false);
      setImagePreviewUploading(true);

      try {
        setErrMsg('');
        const file = acceptedFiles[0];

        if (!file) {
          throw Error('Upload failed. There was an issue uploading the file.');
        }
        const previewURL = { preview: URL.createObjectURL(file) };

        const withPreviewURL = Object.assign(file, previewURL);

        setImageState(withPreviewURL);
      } catch (error) {
        setErrMsg('Upload failed. There was an issue uploading the file.');
      } finally {
        setImagePreviewUploading(false);
      }
    },

    onDropRejected: async (evt) => {
      setImagePreviewUploading(false);
      const err = evt[0];
      const firstError = err.errors[0];

      if (JSON.stringify(firstError).includes('file-too-large')) {
        return setErrMsg('File too big. Maximum file size is 5MB');
      }

      if (JSON.stringify(firstError).includes('file-invalid-type')) {
        return setErrMsg('Invalid file type. Only image files allowed.');
      }

      setErrMsg('Upload failed. There was an issue uploading the file.');
    },
    onDragEnter: () => {
      setIsDragOver(true);
    },
    onDragLeave: () => {
      setIsDragOver(false);
    },
    accept: 'image/jpeg, image/png, image/gif, image/svg+xml, image/webp',
    maxFiles: 1,
    maxSize: 5242880,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <LogoUploadWrapper>
      <BodySmall>Logo (optional)</BodySmall>

      <DropZoneWrapper
        isDragOver={isDragOver}
        isDragActive={isDragActive}
        hasImage={!!imageState}
        {...getRootProps({})}
      >
        <input {...getInputProps()} />

        {!!imageState && (
          <EditDeleteButtons>
            <ButtonRound
              iconName="Trash"
              level="secondary"
              size="small"
              tooltip=""
              onClick={() => {
                setImageState('');
              }}
            />

            <ButtonRound
              iconStyle={{ color: 'black' }}
              iconName="Pencil"
              level="secondary"
              size="small"
              tooltip=""
              onClick={open}
            />
          </EditDeleteButtons>
        )}

        <ImageWrapper>
          <img
            style={{
              borderRadius: 5,
              overflow: 'hidden',
              border: `0.5px solid ${
                !!imageState ? colors.black30a : 'transparent'
              }`,
              transition: `border 0.3s`,
              maxWidth: 90,
              maxHeight: 90,
            }}
            src={
              !!imageState && 'preview' in imageState ? imageState?.preview : ''
            }
            alt={!!imageState && 'name' in imageState ? imageState?.name : ''}
          />
        </ImageWrapper>

        <TextOverlays imageState={!!imageState} isDragActive={isDragActive}>
          {isDragActive ? (
            <BodySmall color={!!imageState ? colors.white : colors.black}>
              Drop here to upload
            </BodySmall>
          ) : imagePreviewUploading ? (
            <AnimatingWaveIcon size={30} />
          ) : (
            !imageState && (
              <div onClick={open} style={{ cursor: 'pointer' }}>
                <BodySmall>
                  Drag an <br />
                  image here or{' '}
                </BodySmall>
                <ButtonLabel>browse</ButtonLabel>
              </div>
            )
          )}
        </TextOverlays>
      </DropZoneWrapper>

      {!!errMsg ? (
        <BodySmall color={colors.darkRed}>{errMsg}</BodySmall>
      ) : (
        <BodySmall color={colors.greyDark}>
          Tip: Check their Twitter profile for an image that fits well
        </BodySmall>
      )}
    </LogoUploadWrapper>
  );
};
