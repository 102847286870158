import React from 'react';
import { Group, Line } from 'react-konva';

import {
  PatientJourneyBlockFragment,
  PatientJourneyConnection,
  PatientJourneyConnectionInput,
} from 'data/graphql/generated';
import { Dot } from './BlockDot';
import { Cursor } from 'types';
import { colors } from 'constants/index';
import { RECT_WIDTH } from 'components/PatientJourney/constants';
import { getBlockBorder } from '../utils';
import { SharedPatientJourneyTypes } from '../types/shared';

interface Props {
  block: PatientJourneyBlockFragment;
  layerDotsRef: any;
  changeCursor(type: Cursor): void;
  createConnection(connection: PatientJourneyConnectionInput): void;
  newConnection: SharedPatientJourneyTypes['newConnection'];
  setNewConnection(
    val:
      | Pick<
          PatientJourneyConnection,
          'path' | 'fromBlockId' | 'toSide' | 'toBlockId' | 'fromSide'
        >
      | {
          toBlockId: undefined;
          toSide: undefined;
          fromBlockId: undefined;
          fromSide: undefined;
          path: number[];
        }
  ): void;
  showDotsForBlock(blockId: number): void;
  hideDotsForBlock(blockId: number): void;
  createShow(): void;
  createHide(): void;
  isDrag: boolean;
  adjustFromScreenX(y: number): number;
  adjustFromScreenY(y: number): number;
}

export const BlockDots: React.FC<Props> = ({
  block,
  layerDotsRef,
  changeCursor,
  createConnection,
  newConnection,
  setNewConnection,
  showDotsForBlock,
  hideDotsForBlock,
  createShow,
  createHide,
  isDrag,
  adjustFromScreenX,
  adjustFromScreenY,
}) => {
  return (
    <Group
      onMouseEnter={() => {
        if (isDrag) return;
        showDotsForBlock(block.id);
      }}
      onMouseLeave={() => {
        hideDotsForBlock(block.id);
      }}
    >
      {/* Visible temp drag lines */}
      <Group>
        <Line
          id={`block-${block.id}-dot-line`}
          stroke={colors.purple}
          opacity={0}
          points={[]}
        />
      </Group>

      <Group id={`block-${block.id}-dots`} x={block.x} y={block.y}>
        {/* dots */}
        <Dot
          id={`block-${block.id}-dot-bottom`}
          x={RECT_WIDTH / 2}
          y={block.height}
          block={block}
          layerDotsRef={layerDotsRef}
          createConnection={createConnection}
          newConnection={newConnection}
          setNewConnection={setNewConnection}
          side="bottom"
          dotColor={getBlockBorder(block.type)}
          changeCursor={changeCursor}
          createShow={createShow}
          createHide={createHide}
          isDrag={isDrag}
          adjustFromScreenX={adjustFromScreenX}
          adjustFromScreenY={adjustFromScreenY}
        />
        <Dot
          id={`block-${block.id}-dot-top`}
          x={RECT_WIDTH / 2}
          y={0}
          block={block}
          layerDotsRef={layerDotsRef}
          createConnection={createConnection}
          newConnection={newConnection}
          setNewConnection={setNewConnection}
          side="top"
          dotColor={getBlockBorder(block.type)}
          changeCursor={changeCursor}
          createShow={createShow}
          createHide={createHide}
          isDrag={isDrag}
          adjustFromScreenX={adjustFromScreenX}
          adjustFromScreenY={adjustFromScreenY}
        />
        <Dot
          id={`block-${block.id}-dot-left`}
          x={0}
          y={block.height / 2}
          block={block}
          layerDotsRef={layerDotsRef}
          createConnection={createConnection}
          newConnection={newConnection}
          setNewConnection={setNewConnection}
          side="left"
          dotColor={getBlockBorder(block.type)}
          changeCursor={changeCursor}
          createShow={createShow}
          createHide={createHide}
          isDrag={isDrag}
          adjustFromScreenX={adjustFromScreenX}
          adjustFromScreenY={adjustFromScreenY}
        />
        <Dot
          id={`block-${block.id}-dot-right`}
          x={RECT_WIDTH}
          y={block.height / 2}
          block={block}
          layerDotsRef={layerDotsRef}
          createConnection={createConnection}
          newConnection={newConnection}
          setNewConnection={setNewConnection}
          side="right"
          dotColor={getBlockBorder(block.type)}
          changeCursor={changeCursor}
          createShow={createShow}
          createHide={createHide}
          isDrag={isDrag}
          adjustFromScreenX={adjustFromScreenX}
          adjustFromScreenY={adjustFromScreenY}
        />
      </Group>
    </Group>
  );
};
