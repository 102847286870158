import { ButtonRound, ObservationsMenu } from 'components/shared';
import { polling, zIndex } from 'constants/index';
import {
  useObservationCreateMutation,
  useObservationUpdateMutation,
  useObservationDeleteMutation,
  useObservationsQuery,
  ObservationsQueryVariables,
  Step,
  Stakeholder,
} from 'data/graphql/generated';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { useHistory } from 'react-router-dom';

interface Props {
  step: Step;
  stakeholder?: Stakeholder;
  offsetTopOverride?: number;
  readonly?: boolean;
}

const ObservationButtonContainer = styled.div`
  @media ${device.tabletMax} {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: ${zIndex.observationsBtn};
  }
`;

const ObservationButtonWrapper = styled.div<{ open: boolean }>`
  position: fixed;
  right: 20px;
  top: 125px;

  z-index: ${zIndex.observationsBtn};

  opacity: ${({ open }) => Number(!open)};
  pointer-events: ${({ open }) => (open ? 'none' : 'all')};
  transition: all 0.1s;
  @media ${device.tabletMax} {
    position: relative;
    top: 0;
    right: 0;
  }
    
  @media ${device.mobile} {
  }
`;

interface Params {
  strategyId: string;
}

export const Observations = ({
  step,
  stakeholder,
  offsetTopOverride,
  readonly,
}: Props) => {
  const [open, setOpen] = useState(false);
  const { strategyId }: Params = useParams();
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);
  const observationQuery = query.get('observation');
  const history = useHistory();

  const [createObservation] = useObservationCreateMutation();
  const [updateObservation] = useObservationUpdateMutation();
  const [deleteObservation] = useObservationDeleteMutation();

  const observationsQueryVars: ObservationsQueryVariables = {
    where: { strategyId: Number(strategyId) },
  };

  const {
    data: Observationsdata,
    loading,
    startPolling,
    stopPolling,
  } = useObservationsQuery({
    variables: {
      ...observationsQueryVars,
    },
  });

  useEffect(() => {
    startPolling(polling.default);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const readonlyData = {
    observations: {
      items: Observationsdata?.observations?.items.filter(
        (data) => data?.stakeholder === stakeholder
      ),
    },
  } as typeof Observationsdata;

  // open observation if set in url (used for notifications)
  useEffect(() => {
    if (!open && observationQuery === 'true') {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observationQuery]);

  useEffect(() => {
    // remove ?observation=true from url
    if (open === false) {
      query.delete('observation');
      history.replace({
        pathname: history.location.pathname,
        search: query.toString(),
      });
    }
  }, [open, history, query]);

  return (
    <ObservationButtonContainer>
      <ObservationButtonWrapper open={open}>
        <ButtonRound
          iconName="Observation"
          level="primary"
          btnClassName="cypress-observation-open"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
        />
      </ObservationButtonWrapper>
      <ObservationsMenu
        readonly={readonly}
        data={readonly ? readonlyData : Observationsdata}
        loading={loading}
        createObservation={createObservation}
        updateObservation={updateObservation}
        deleteObservation={deleteObservation}
        step={step}
        setOpen={setOpen}
        open={open}
        stakeholder={stakeholder || Stakeholder.Patient}
        offsetTopOverride={offsetTopOverride}
      />
    </ObservationButtonContainer>
  );
};
