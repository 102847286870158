import { TacticCategory, TacticUpdateInput } from 'data/graphql/generated';
import { colors } from 'constants/colors';
import { ReusableDropdown } from 'components/shared/ReusableDropdown';
import { MedicalTacticCategoryDropdownOption } from '../types/MedicalTacticCategoryDropdownOption';
import { MedicalTacticCategoryDropdownItem } from './MedicalTacticCategoryDropdownItem';
import { ButtonLabel } from 'components/shared';

interface MedicalTacticCategoryDropdownProps {
  selectedOption: MedicalTacticCategoryDropdownOption;
  setSelectedOption: (value: MedicalTacticCategoryDropdownOption) => void;
  onUpdate: (data: TacticUpdateInput) => Promise<void>;
  editable?: boolean;
}

export const MedicalTacticCategoryDropdown = ({
  selectedOption,
  setSelectedOption,
  onUpdate,
  editable,
}: MedicalTacticCategoryDropdownProps) => {
  return (
    <ReusableDropdown
      editable={editable}
      options={[
        {
          id: TacticCategory.MedicalLeadership,
          value: 'Medical Leadership',
        },
        {
          id: TacticCategory.DataAndEvidenceGeneration,
          value: 'Data & Evidence Generation',
        },
        {
          id: TacticCategory.DataAndEvidenceDissemination,
          value: 'Data & Evidence Dissemination',
        },
        {
          id: TacticCategory.MedicalKnowledge,
          value: 'Medical Knowledge',
        },
        {
          id: TacticCategory.MedicalCommunityIntegration,
          value: 'Medical Community Integration',
        },
        {
          id: TacticCategory.PatientAdvocacy,
          value: 'Patient Advocacy',
        },
      ]}
      selectedOption={selectedOption}
      selectOption={(option) => {
        setSelectedOption(option);
        onUpdate({
          category: option.id as TacticCategory,
        });
      }}
      clearSelection={() => {
        setSelectedOption(null);
        onUpdate({ category: null });
      }}
      dropdownItem={(option) => (
        <MedicalTacticCategoryDropdownItem value={option.value} />
      )}
      selectedDropdownItem={(option) => (
        <MedicalTacticCategoryDropdownItem
          value={option.value}
          textColor={editable ? colors.black : colors.greyDark}
          iconColor={editable ? colors.black : colors.greyDark}
        />
      )}
      noSelectionItem={() => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ButtonLabel
            color={colors.greyDark}
            style={{ cursor: editable ? 'pointer' : 'default' }}
          >
            {editable ? 'Choose tactic category' : 'Not identified yet'}
          </ButtonLabel>
        </div>
      )}
    />
  );
};
