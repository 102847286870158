import styled from 'styled-components/macro';
import { BodySmall, Icon } from 'components/shared';

const FocusContainer = styled.div`
  display: flex;
  margin-left: -9px;
`;

export const FocusIcon: React.FC<{ isFocused: boolean }> = ({ isFocused }) => {
  if (!isFocused) {
    return null;
  }

  return (
    <FocusContainer>
      <Icon name="GradientTick" size={30} />
      <BodySmall>Focus</BodySmall>
    </FocusContainer>
  );
};
