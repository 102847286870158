import { DrugDetailBorderWrapper } from 'containers/Strategies';
import useDesktop from 'hooks/useDesktop';
import { useEffect, useState } from 'react';
import { StrategyDefinitionHeaderProps } from '../../StrategyDefinitionHeader.types';
import { StrategyDefinitionHeaderTitle } from '../StrategyDefinitionHeaderTitle';
import { StrategyDefinitionHeaderTextField } from '../StrategyDefinitionHeaderTextField';
import { Tooltip } from 'components/shared';
import { StrategyDefinitionHeaderCollaboration } from '../StrategyDefinitionHeaderCollaboration';
import { StyledDrugDetailContainer } from './TextStrategyDefinitionHeader.styles';

interface TextStrategyDefinitionHeaderProps
  extends Omit<StrategyDefinitionHeaderProps, 'bulletPoints' | 'type'> {
  placeholder?: string;
}

export const TextStrategyDefinitionHeader = ({
  totalUsers,
  isLead,
  title,
  textValue = '',
  collaboration,
  style = {},
  disabled,
  className,
  placeholder,
  tooltipMessage,
  hideAlignment,
  viewingOtherRegion,
  hideTooltip,
  handleSave,
}: TextStrategyDefinitionHeaderProps) => {
  const isDesktop = useDesktop();
  const [value, setValue] = useState(textValue);

  useEffect(() => {
    setValue(textValue);
  }, [textValue]);

  return (
    <DrugDetailBorderWrapper
      className={className}
      style={{
        width: isDesktop ? '768px' : '100%',
        maxWidth: 'auto',
        zIndex: 9,
        position: 'relative',
        ...style,
      }}
    >
      <StyledDrugDetailContainer isMultipleLists={false} isLead={isLead}>
        <StrategyDefinitionHeaderTitle isDesktop={isDesktop} title={title} />

        <StrategyDefinitionHeaderTextField
          title={title}
          name="strategyDefinition"
          value={value}
          disabled={disabled}
          isLead={isLead}
          onChange={(text: string) => {
            if (isLead) {
              setValue(text);
            }
          }}
          onBlur={() => handleSave?.(value)}
          placeholder={placeholder as any}
          tooltip={
            hideTooltip
              ? ''
              : viewingOtherRegion
              ? tooltipMessage
              : disabled
              ? tooltipMessage || 'Only Leads can edit'
              : ''
          }
        />

        <Tooltip id={'strategyDefinition'} effect="float" place="bottom" />

        <StrategyDefinitionHeaderCollaboration
          isDesktop={isDesktop}
          totalUsers={totalUsers}
          collaboration={collaboration}
          showAlignment={!hideAlignment}
          isMultipleLists={false}
        />
      </StyledDrugDetailContainer>
    </DrugDetailBorderWrapper>
  );
};
