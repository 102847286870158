import { RationaleModal } from 'components/shared/RationaleModal';
import { PreviewAndDBDataSolutionType } from 'containers/RiskMitigationIdeas';
import React, { useState } from 'react';
import { PreviewAndDBDataTacticType } from './MedicalTactics';
import ModalContent from './ModalContent';
import styled from 'styled-components';

const StyledRationaleModal = styled(RationaleModal)`
  .rationale__sliderWindow {
    overflow: visible;
    z-index: 1;
  }
`;

type Props = {
  modalSlideIdx: number | null;
  setModalPage(val: number | null): void;
  setModalSlideIdx: (value: React.SetStateAction<number | null>) => void;
  selectedTactic:
    | PreviewAndDBDataTacticType
    | PreviewAndDBDataSolutionType
    | null;
  showSingleSlide: boolean;
  currency: string;
  onSubmit(
    formData: {
      dueDate: number[] | null | undefined;
      responsibility: string;
      budget: string | null | undefined;
    } | null,
    rationale: string,
    selectedTactic:
      | PreviewAndDBDataTacticType
      | PreviewAndDBDataSolutionType
      | null
  ): void;
  modalDetails?: Record<string, string>;
};

const MedicalTacticsRationaleModal = ({
  modalSlideIdx,
  setModalPage,
  setModalSlideIdx,
  selectedTactic,
  showSingleSlide,
  currency,
  onSubmit,
  modalDetails,
}: Props) => {
  const [formData, setFormData] = useState<{
    dueDate: number[] | null | undefined;
    responsibility: string;
    budget: string | null | undefined;
  } | null>(null);

  return (
    <StyledRationaleModal
      disableNext={!formData}
      modalSlideIdx={modalSlideIdx}
      setModalSlideIdx={setModalPage}
      handleClose={() => {
        setModalSlideIdx(null);
      }}
      handleSubmit={(rationale) => {
        onSubmit(formData, rationale, selectedTactic);
      }}
      rationaleText={selectedTactic?.focusRationale || ''}
      showSingleSlide={showSingleSlide}
      modalDetails={modalDetails}
      firstSlideContent={
        <ModalContent
          showSingleSlide={showSingleSlide}
          data={{
            lowerLimit: selectedTactic?.timingEnd,
            dueDate: selectedTactic?.dueDate,
            responsibility: selectedTactic?.responsibility || '',
            budget: selectedTactic?.budget,
          }}
          currency={currency}
          handleClose={() => setModalSlideIdx(null)}
          onUpdate={(data) => {
            setFormData(data);
          }}
        />
      }
    />
  );
};

export default MedicalTacticsRationaleModal;
