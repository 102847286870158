import PptxGenJS from 'pptxgenjs';
// import { Stakeholder } from 'data/graphql/generated';
import { colors } from './colors';

// interface ExportData {
// patientInsights: {
// __typename?: "KeyInsight" | undefined;
// id: number;
// text: string;
// stakeholder: Stakeholder;
// }[] | undefined;
// healthcareInsights: {
// __typename?: "KeyInsight" | undefined;
// id: number;
// text: string;
// stakeholder: Stakeholder;
// }[] | undefined;
// payorInsights: {
// __typename?: "KeyInsight" | undefined;
// id: number;
// text: string;
// stakeholder: Stakeholder;
// }[] | undefined;
// providerInsights: {
// __typename?: "KeyInsight" | undefined;
// id: number;
// text: string;
// stakeholder: Stakeholder;
// }[] | undefined;
// hasLeveragePoint: boolean | undefined;
// __typename?: "PatientJourneyColumn" | undefined;
// id: number;
// name: string;
// defaultName: string;
// idx: number;
// width: number;
// strategyId: number;
// drugId: number;
// lastUpdated: string;
// createdAt: string;
// KeyInsight?: {
// __typename?: "KeyInsight" | undefined;
// id: number;
// text: string;
// stakeholder: Stakeholder;
// }[] | null | undefined;
// strategicPossibilities: {
// __typename?: "StrategicPossibility" | undefined;
// id: number;
// name: string;
// imperative?: boolean | null | undefined;
// behaviourToDrive?: string | null | undefined;
// driverOfTheBehaviour?: string | null | undefined;
// }[];
// }

interface Props {
  brand: string;
  startYear: number;
  endYear: number;
  // data: ExportData[] | undefined;
  data?: any[];
}

export default function exportSummaryOne({
  brand,
  startYear,
  endYear,
  data,
}: Props) {
  if (!data) return;

  let pres = new PptxGenJS();
  let slide = pres.addSlide();

  slide.addText(
    `${brand} • ${startYear} - ${endYear} | Key Stakeholder Insights`,
    {
      x: 0.2,
      y: 0.2,
      h: 0.2, // height in inches
      color: colors.black,
      fontSize: 14,
      fontFace: 'Arial',
    }
  );

  let rows = [];

  function createNoBorderCell(text: string, style?: any) {
    return {
      text,
      options: {
        color: colors.grey,
        fontSize: 10,
        fontFace: 'Arial',
        ...style,
      },
    };
  }

  rows.push(
    data.map(({ name }: { name: string }, idx) => ({
      text: [
        { text: name },
        {
          text: data[idx].hasLeveragePoint ? '★ Leverage point' : '',
          options: { color: colors.grey },
        },
      ],
      options: {
        color: colors.black,
        fontSize: 10,
        fontFace: 'Arial',
        bold: true,
        align: 'center',
        border: [
          { pt: 1, color: colors.purple },
          { pt: 0, color: colors.purple },
          { pt: 1, color: colors.purple },
          { pt: 0, color: colors.purple },
        ],
      },
    }))
  );

  function createBulletCell(texts: string[], style?: any) {
    const invalidText = texts.length === 0;
    return {
      text: invalidText ? '–' : texts.map((text = '') => text).join('\n'),
      options: {
        fontSize: 10,
        fontFace: 'Arial',
        paraSpaceAfter: 8,
        color: colors.black,
        border: [
          { pt: 0, color: colors.purple },
          { pt: 0, color: colors.purple },
          { pt: 1, color: colors.purple },
          { pt: 0, color: colors.purple },
        ],
        ...style,
      },
    };
  }

  const sections = [
    { title: 'Patients', field: 'patientInsights' },
    { title: 'Healthcare Professionals', field: 'healthcareInsights' },
    { title: 'Payors', field: 'payorInsights' },
    { title: 'Providers', field: 'providerInsights' },
    { title: 'Policymakers', field: 'policyMakersInsights' },
  ];

  let count = 0;
  for (const { title, field } of sections) {
    const style = {
      fill: count % 2 === 0 ? 'f8f2ff' : '',
    };

    rows.push([
      createNoBorderCell(title, { ...style, bold: true }),
      createNoBorderCell('', style),
      createNoBorderCell('', style),
      createNoBorderCell('', style),
      createNoBorderCell('', style),
    ]);

    rows.push([
      createBulletCell(
        data[0][field].map(({ text }: any) => text),
        style
      ),
      createBulletCell(
        data[1][field].map(({ text }: any) => text),
        style
      ),
      createBulletCell(
        data[2][field].map(({ text }: any) => text),
        style
      ),
      createBulletCell(
        data[3][field].map(({ text }: any) => text),
        style
      ),
      createBulletCell(
        data[4][field].map(({ text }: any) => text),
        style
      ),
      // NOTE this will be Policymakers once added
      // createBulletCell(data[5][field].map((text: string) => ({ text }))),
    ]);

    count = count + 1;
  }

  // @ts-ignore
  slide.addTable(rows, { x: 0.3, y: 0.6, w: 9.4 });

  pres.writeFile({
    fileName: `${brand}_${startYear}-${endYear}_Key Stakeholder Insights.pptx`,
  });
}
