import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import {
  DataList,
  DropOverlay,
  ListContainer,
} from 'components/GridPages/GridPageSidebar';
import { BodyNormal, BodySmall, Subtitle1 } from 'components/shared';
import { GridPageSidebar } from 'components/GridPages/GridPageSidebar';
import { colors } from 'constants/colors';
import 'constants/index';
import {
  AccessStrategyPrioritiseSupportingMessageFragment,
  SubStep,
} from 'data/graphql/generated';
import { useDragLayer } from 'react-dnd';
import 'components/shared/CategoryTab';
import { SupportingMessageGroup } from './SupportingMessageGroup';
import { DragNDropItems } from 'types';

const supportingMessageSubsteps = [
  {
    title: 'Disease Burden and Unmet Need',
    substep: SubStep.DiseaseBurdenAndUnmetNeed,
  },
  {
    title: 'Clinical Value',
    substep: SubStep.ClinicalValue,
  },
  {
    title: 'Humanistic Value',
    substep: SubStep.HumanisticValue,
  },
  {
    title: 'Economic Value',
    substep: SubStep.EconomicValue,
  },
];

const SidebarHeader = styled.div`
  margin-bottom: 20px;
`;

interface Props {
  sidebarOpen: boolean;
  offsetTop: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTouchPositioningView: React.Dispatch<React.SetStateAction<string | null>>;
  supportingMessages: AccessStrategyPrioritiseSupportingMessageFragment[];
  permittedToEdit: boolean;
}

export const AccessStrategySidebar: React.FC<Props> = ({
  sidebarOpen,
  offsetTop,
  setSidebarOpen,
  setTouchPositioningView,
  supportingMessages,
  permittedToEdit,
}) => {
  const dataListRef = useRef<HTMLDivElement | null>(null);
  const dataListTop = dataListRef.current?.offsetTop;

  const { isDragging, itemType } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    itemType: monitor.getItemType(),
  }));

  return (
    <GridPageSidebar
      sidebarOpen={sidebarOpen}
      offsetTop={offsetTop}
      setSidebarOpen={setSidebarOpen}
    >
      <SidebarHeader>
        <BodyNormal>
          {permittedToEdit
            ? 'Drag supporting messages to the prioritisation grid'
            : 'Supporting messages'}
        </BodyNormal>
      </SidebarHeader>

      {isDragging && itemType === DragNDropItems.PIN && (
        <DropOverlay top={dataListTop || 0}>
          <Subtitle1>Drop here to remove</Subtitle1>
        </DropOverlay>
      )}
      <DataList ref={dataListRef}>
        {supportingMessageSubsteps.map(({ title, substep }) => {
          const substepSupportingMessages = supportingMessages.filter(
            (s) => s.keyStatement.subStep === substep
          );

          return (
            <ListContainer key={title}>
              <BodySmall color={colors.greyDark}>{title}</BodySmall>

              <SupportingMessageGroup
                data={substepSupportingMessages}
                isDragging={isDragging}
                setTouchPositioningView={setTouchPositioningView}
                permittedToEdit={permittedToEdit}
                substep={substep}
              />
            </ListContainer>
          );
        })}
      </DataList>
    </GridPageSidebar>
  );
};
