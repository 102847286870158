import React from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';

type BlockImageType = 'Action' | 'ActionPurple';

interface Props {
  type: BlockImageType;
  starred?: boolean;
}

interface ImageProps {
  src: string;
  width: number;
  height: number;
}

function getBlockImage(type: BlockImageType, starred: boolean): ImageProps {
  switch (true) {
    case type === 'Action' && starred:
      return {
        src: '/patient-journey/Action-starred.png',
        width: 17,
        height: 17,
      };
    case type === 'Action':
      return {
        src: '/patient-journey/Action-unstarred.png',
        width: 17,
        height: 17,
      };
    case type === 'ActionPurple':
      return {
        src: '/icons/ActionPurple.png',
        width: 17,
        height: 17,
      };

    default:
      return { src: '', width: 0, height: 0 };
  }
}

export const BlockIcon: React.FC<Props> = ({ type, starred = false }) => {
  const image = getBlockImage(type, starred);
  const [imageSrc] = useImage(image.src);

  return <Image image={imageSrc} width={image.width} height={image.height} />;
};
