import styled from 'styled-components/macro';
import { colors } from 'constants/colors';
import { BodySmall } from 'components/shared';

const ProgressCardItemPercentText = styled.div`
  position: relative;
  top: 5px;
  height: 30px;
`;

interface ProgressCardItemPercentSectionProps {
  percentage?: number;
}

export const ProgressCardItemPercentSection = ({
  percentage,
}: ProgressCardItemPercentSectionProps) => {
  if (percentage === undefined) {
    return (
      <ProgressCardItemPercentText>
        <BodySmall color={colors.black}>No data</BodySmall>
      </ProgressCardItemPercentText>
    );
  }

  return (
    <ProgressCardItemPercentText>
      <BodySmall color={colors.black}>{percentage.toFixed(0)}%</BodySmall>
    </ProgressCardItemPercentText>
  );
};
