import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { Page, Wrapper } from 'components/Page';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { device } from 'utils/breakpoints';
import {
  SubStep,
  useSolutionsQuery,
  useStrategyQuery,
} from 'data/graphql/generated';
import { RiskMitigationNav } from 'components/RiskMitigation/RiskMitigationNav';
import { useParams } from 'react-router-dom';
import { BodySmall, Subtitle1 } from 'components/shared';
import { colors, polling } from 'constants/index';
import {
  Table,
  Divider,
  TableBodyDiv,
  TableDivHeaders,
} from 'components/shared/Table';
import { thousandSeparator } from 'utils/thousandSeparator';
import { PostItsEmpty } from 'components/PostItsEmpty';
import useDesktop from 'hooks/useDesktop';
import {
  columnGridStyle,
  SummaryRow,
} from 'components/RiskMitigation/SummaryRow';

const MobileHeaderDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.purple};
  margin-bottom: -5px;
`;

const StyledTable = styled(Table)`
  margin-top: 15px;
  ${TableDivHeaders} {
    gap: 15px;

    ${columnGridStyle}
  }

  @media ${device.tabletMax} {
    max-width: 451px;
    padding: 15px;
    .Table__Mobile__EmptyState {
      margin-top: 20px;
    }
  }
  ${TableBodyDiv} {
    ${Divider}:last-of-type {
      display: none;
    }
  }

  .table__top__divider {
    background-color: ${colors.purple};
  }
  .table__footer__wrapper {
    display: none;
  }
` as typeof Table;

const Header = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  padding: 0;

  @media (max-width: 375px) {
    padding: 0 15px;
  }
`;

const PageWrapper = styled(Page)`
  margin-top: 145px;

  @media ${device.tabletMin} {
    ${Wrapper} {
      width: auto;
      padding: 0 15px;
    }
  }

  @media ${device.desktopMin} {
    margin-top: 100px;

    ${Wrapper} {
      padding: 0 20px;
    }
  }
`;

interface URLParams {
  strategyId: string;
}

export const RiskMitigationSummary = () => {
  const { strategyId }: URLParams = useParams();
  const isDesktop = useDesktop();

  const {
    data: strategyData,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
    loading: strategyLoading,
    error: strategyError,
  } = useStrategyQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'network-only',
  });

  const {
    data: solutionsData,
    startPolling: startSolutionsPolling,
    stopPolling: stopSolutionsPolling,
    loading: solutionsLoading,
    error: solutionsError,
  } = useSolutionsQuery({
    variables: {
      where: { strategyId: +strategyId, focused: true },
      include: { successCondition: true },
    },
    fetchPolicy: 'network-only',
  });

  const solutions = useMemo(
    () =>
      solutionsData?.solutions?.items.sort((a, b) => {
        if (a.dueDate[1] + a.dueDate[0] < b.dueDate[1] + b.dueDate[0]) {
          return -1;
        }

        if (a.dueDate[1] + a.dueDate[0] > b.dueDate[1] + b.dueDate[0]) {
          return 1;
        }
        return 0;
      }),
    [solutionsData?.solutions?.items]
  );

  const totalInvestment = solutions?.reduce((prev, curr) => {
    return prev + +(curr?.budget || 0);
  }, 0);

  const currency = strategyData?.strategy?.currency[0] || '';

  useEffect(() => {
    startStrategyPolling(polling.default);
    startSolutionsPolling(polling.default);

    return () => {
      stopStrategyPolling();
      stopSolutionsPolling();
    };
  }, [
    startSolutionsPolling,
    startStrategyPolling,
    stopSolutionsPolling,
    stopStrategyPolling,
  ]);

  return (
    <>
      <RiskMitigationNav subStep={SubStep.Summary} />
      <PageWrapper paddingTop={false} fullWidthMobile>
        <ErrorWrapper
          isLoading={strategyLoading || solutionsLoading}
          errors={[strategyError, solutionsError]}
          dataMissing={false}
        >
          {!solutions || !solutions.length ? (
            <PostItsEmpty title="No solutions yet">
              <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
                Risk mitigation solutions will be summarised here
              </BodySmall>
            </PostItsEmpty>
          ) : (
            <>
              <Header>
                <Subtitle1 color={colors.greyDark}>
                  Total investment required:
                </Subtitle1>
                <Subtitle1>
                  {currency}
                  {totalInvestment
                    ? thousandSeparator(totalInvestment || '')
                    : '-'}
                </Subtitle1>
              </Header>
              <StyledTable
                isDesktop={isDesktop}
                useDiv
                cols={['Solution', 'Risk', 'Due', 'Responsibility', 'Budget']}
                data={solutions}
                header={!isDesktop ? <MobileHeaderDivider /> : <></>}
                rows={({
                  solutionText,
                  responsibility,
                  budget,
                  dueDate,
                  successCondition,
                }) => (
                  <SummaryRow
                    isDesktop={isDesktop}
                    solutionText={solutionText}
                    riskText={successCondition?.text || ''}
                    impactRating={successCondition?.impactRating || 0}
                    dueDate={dueDate}
                    responsibility={responsibility}
                    budget={budget || ''}
                    currency={currency}
                  />
                )}
                emptyStateText={{
                  header: undefined,
                  subHeading: undefined,
                }}
                isAddingRow={false}
              />
            </>
          )}
        </ErrorWrapper>
      </PageWrapper>
    </>
  );
};
