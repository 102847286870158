import React, { useEffect, useState } from 'react';

import { NavbarDesktop } from './NavbarDesktop';
import { NavbarMobile } from './NavbarMobile';
import { SecondaryNavbar } from '../components/SecondaryNavbar';
import { useParams } from 'react-router-dom';
import { ExcludedFeature, IncludedFeature } from 'types';
import { NotificationsFeed } from './notifications/NotificationsFeed';
import {
  Sort,
  useNotificationsQuery,
  NotificationCreatedDocument,
} from 'data/graphql/generated';
import { Account } from 'containers/Account';

interface Props {
  title?: string;
  url?: string;
  disableSecondary?: boolean;
  prev?: { url: string; title: string };
  next?: { url: string; title: string };
  hideArrows?: boolean;
  navMenuChildren?: React.ReactNode | null;
  exclude?: ExcludedFeature[];
  include?: IncludedFeature[];
  pageNavChildren?: React.ReactNode;
  stepNumber?: string;
  desktopNavRef?: React.RefObject<HTMLElement>;
  mobileNavRef?: React.RefObject<HTMLDivElement>;
  secondaryNavRef?: React.RefObject<HTMLDivElement>;
}

export const Navbar: React.FC<Props> = ({
  title,
  url,
  disableSecondary = false,
  children,
  prev = { title: '', url: '' },
  next = { title: '', url: '' },
  hideArrows,
  navMenuChildren,
  exclude,
  include,
  pageNavChildren,
  stepNumber,
  desktopNavRef,
  mobileNavRef,
  secondaryNavRef,
}) => {
  const { drugId, strategyId } = useParams<{
    drugId: string;
    strategyId: string;
  }>();
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const { data, loading, refetch, subscribeToMore } = useNotificationsQuery({
    variables: {
      viewAll: true,
      orderBy: {
        createdAt: Sort.Desc,
      },
    },
  });

  useEffect(() => {
    if (subscribeToMore) {
      subscribeToMore({
        document: NotificationCreatedDocument,
        variables: {},
        updateQuery: (
          prev,
          {
            subscriptionData,
          }: {
            subscriptionData: { data: { notificationCreated: Notification } };
          }
        ) => {
          if (!subscriptionData.data) return prev;
          const newFeedItem = subscriptionData.data.notificationCreated;
          return Object.assign({}, prev, {
            ...prev,
            notifications: {
              ...prev.notifications,
              items: [newFeedItem, ...prev.notifications.items],
            },
          });
        },
      });
    }
  }, [subscribeToMore]);

  const unreadCount = (data?.notifications?.items || []).filter(
    (n) => !n.read
  ).length;

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <Account
          showModal={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
      <NavbarDesktop
        desktopNavRef={desktopNavRef}
        hideArrows={!!hideArrows}
        drugId={drugId}
        strategyId={strategyId}
        prev={prev}
        next={next}
        title={title}
        url={url}
        exclude={exclude}
        include={include}
        showNotifications={() => setNotificationsVisible(true)}
        notificationsVisible={notificationsVisible}
        notificationsUnread={unreadCount}
        showAccountModal={() => {
          setShowModal(true);
        }}
        stepNumber={stepNumber}
      />
      <NavbarMobile
        mobileNavRef={mobileNavRef}
        drugId={drugId}
        hideArrows={!!hideArrows}
        strategyId={strategyId}
        title={title}
        prev={prev}
        next={next}
        url={url}
        navMenuChildren={navMenuChildren}
        exclude={exclude}
        showNotifications={() => setNotificationsVisible(true)}
        notificationsVisible={notificationsVisible}
        notificationsUnread={unreadCount}
        showAccountModal={() => {
          setShowModal(true);
        }}
        stepNumber={stepNumber}
      >
        {children}
      </NavbarMobile>
      {disableSecondary ? null : (
        <SecondaryNavbar
          secondaryNavRef={secondaryNavRef}
          hideArrows={!!hideArrows}
          prev={prev}
          next={next}
          title={title}
          url={url}
          pageNavChildren={pageNavChildren}
          stepNumber={stepNumber}
        >
          {children}
        </SecondaryNavbar>
      )}
      {notificationsVisible ? (
        <NotificationsFeed
          visible={notificationsVisible}
          hideNotifications={() => setNotificationsVisible(false)}
          notifications={data?.notifications?.items || []}
          loading={loading}
          refetch={refetch}
        />
      ) : null}
    </>
  );
};