import { Role } from 'data/graphql/generated';
import { globalContributor } from '../constants';

export const verifyUserRole = (role?: Role | null, country?: string | null) => {
  const isAdmin = role === Role.Admin;
  const isLead = role === Role.Lead;
  const isContributor = role === Role.Contributor;
  const isCountryContributor =
    isContributor && Boolean(country) && country !== globalContributor;
  const isGlobalContributor = isContributor && country === globalContributor;

  return {
    isAdmin,
    isLead,
    isGlobalContributor,
    isCountryContributor,
    isContributor,
  };
};
