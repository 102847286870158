import React from 'react';
import {
  MissingTrackedGoals,
  MissingTrackedGoalsProps,
} from './MissingTrackedGoals';

interface RequireTrackedGoalsProps extends MissingTrackedGoalsProps {
  isEmpty: boolean;
  children: React.ReactNode | React.ReactNode[];
}

export const RequireMissingTrackedGoals: React.FC<RequireTrackedGoalsProps> = ({
  drugId,
  strategyId,
  competitiveAdvantageRowId,
  isEmpty,
  children,
}) => {
  if (isEmpty) {
    return (
      <MissingTrackedGoals
        drugId={drugId}
        strategyId={strategyId}
        competitiveAdvantageRowId={competitiveAdvantageRowId}
      />
    );
  }

  return <>{children}</>;
};
