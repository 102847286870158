import React, { useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';
import { ImperativeWithSuccessConditionType } from 'containers/CompetitiveAdvantage';
import { SummaryColumn } from './SummaryColumn';

interface Props {
  data: ImperativeWithSuccessConditionType[0];
  arrayIdx: number;
  moveColumn: (
    id: number,
    dragIndex: number,
    hoverIndex: number
  ) => Promise<void>;
  arr: ImperativeWithSuccessConditionType;
}

interface ItemType {
  type: string;
  id: number;
  Idx: number;
}

export const DraggableSummaryColumn = ({
  data,
  arrayIdx,
  moveColumn,
  arr,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop(
    () => ({
      accept: 'summaryColumn',
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },

      hover: async (item: ItemType, monitor: DropTargetMonitor) => {
        if (!ref.current) {
          return;
        }

        const dragIndex = arr.findIndex(
          (val) => val.competitiveAdvantageRow?.id === item.id
        );
        const hoverIndex = arr.findIndex(
          (val) =>
            val.competitiveAdvantageRow?.id === data.competitiveAdvantageRow?.id
        );

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        // Get horizontal middle
        const hoverMiddleX =
          (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the left
        const hoverClientX =
          (clientOffset as XYCoord).x - hoverBoundingRect.left;

        // Only perform the move when the mouse has crossed half of the items width
        // When dragging left, only move when the cursor is below 50%
        // When dragging right, only move when the cursor is above 50%

        // Dragging right
        if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
          return;
        }

        // Dragging left
        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
          return;
        }

        // Time to actually perform the action
        await moveColumn(item?.id, dragIndex, hoverIndex);
      },
    }),
    [arrayIdx, arr]
  );

  const [{ isDragging }, drag] = useDrag(
    () => ({
      item: {
        type: 'summaryColumn',
        id: data?.competitiveAdvantageRow?.id,
        arrayIdx,
        data,
        dimensions: ref.current?.getBoundingClientRect(),
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [data?.competitiveAdvantageRow?.id, ref.current]
  );

  const opacity = isDragging ? 0.5 : 1;

  drag(drop(ref));

  return (
    <SummaryColumn
      data={data}
      innerRef={ref}
      style={{
        visibility: isDragging ? 'hidden' : 'visible',
        opacity,
        cursor: 'grab',
      }}
    />
  );
};
