import {
  Sort,
  CommercialTacticsQueryVariables,
} from '../../data/graphql/generated';

export const getKeyTacticsQueryVars = (
  strategyId: string,
  strategicImperativeId: string
): CommercialTacticsQueryVariables => ({
  where: {
    strategyId: +strategyId,
    competitiveAdvantageRowId: +strategicImperativeId,
  },
  orderBy: {
    createdAt: Sort.Desc,
  },
});
