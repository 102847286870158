import * as Yup from 'yup';
import * as Sentry from '@sentry/react';

import {
  CriticalMetricGoalInput,
  CriticalMetricGoalFrequency,
} from 'data/graphql/generated';

const schema = Yup.object().shape({
  goal: Yup.string().required(),
  target: Yup.number().required(),
  frequency: Yup.string()
    .oneOf(Object.values<string>(CriticalMetricGoalFrequency))
    .required(),
  input: Yup.string()
    .oneOf(Object.values<string>(CriticalMetricGoalInput))
    .nullable()
    .required(),
});

export const validateRowData = (data: any) => {
  try {
    schema.validateSync({
      goal: data.goal,
      target: data.target,
      frequency: data.frequency,
      input: data.input,
    });

    return true;
  } catch (err) {
    Sentry.captureException(err);
    return false;
  }
};
