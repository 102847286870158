import { ConnectionSide } from 'types';

export function generateInitialPath(
  fromSide: ConnectionSide,
  x: number,
  y: number
): number[] {
  if (fromSide === 'left') return [x - 20, y];
  if (fromSide === 'right') return [x + 20, y];
  if (fromSide === 'top') return [x, y - 20];
  if (fromSide === 'bottom') return [x, y + 20];
  return [];
}
