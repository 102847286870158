import React from 'react';
import { ApolloError } from '@apollo/client';

import ErrorPage from 'components/ErrorPage';
import { Loading } from 'components/Loading';

interface Props {
  errors: (ApolloError | undefined)[];
  isLoading: boolean;
  dataMissing: boolean;
}

export const ErrorWrapper: React.FC<Props> = ({
  errors,
  isLoading,
  dataMissing,
  children,
}) => {
  if (isLoading) return <Loading />;

  const permissionError = errors.some((err) =>
    err?.message?.includes('not authorized')
  );

  if (permissionError) return <ErrorPage errorType="unpermitted" />;
  if (dataMissing) return <ErrorPage errorType="generic" />;

  return <>{children}</>;
};
