import FocusDropdown from 'components/shared/FocusDropdown';
import {
  BodyNormal,
  BodySmall,
  ButtonLabel,
  ButtonRound,
  Collaboration,
  EditableTextarea,
  Icon,
} from 'components/shared';
import { ReusableDropdown } from 'components/shared/ReusableDropdown';
import { colors } from 'constants/index';
import React, { useMemo, useState } from 'react';
import { DatePickerDropdown } from './DatePickerDropdown';
import styled from 'styled-components/macro';
import { TacticCategory, TacticUpdateInput } from 'data/graphql/generated';
import { thousandSeparator } from 'utils/thousandSeparator';
import { monthNames } from 'utils/monthNames';
import { device } from 'utils/breakpoints';
import { PreviewAndDBDataTacticType } from './MedicalTactics';
import { DateType } from 'components/shared/DatePicker';
import { AddSpinOffButton } from 'components/shared/AddSpinOffButton';
import { Country } from 'types';

export const DetailItem = styled.div`
  display: flex;
  gap: 5px;
`;

export const MobileFocusedDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 5px;
  border: 1px solid ${colors.greyMedium};
  padding: 5px;
  position: relative;
`;

const DeleteConfirmationWrapper = styled.div`
  display: flex;
  flex: none;
  width: 188px;
  height: 30px;

  background: ${colors.white};
  border: 0.5px solid ${colors.black30};
  border-radius: 5px;

  padding: 4px 20px;
  justify-content: space-between;
  align-items: center;

  position: relative;
  right: 138px;

  @media ${device.tabletMax} {
    right: 0;
  }
  z-index: 2;
`;

export const MobileOuterWrapper = styled.div<{
  focused: boolean;
  isLocalTactic?: boolean;
}>`
  margin: 5px 0;
  position: relative;
  background: ${({ focused, isLocalTactic }) =>
    isLocalTactic
      ? colors.bluePurple
      : focused
      ? colors.purplePinkGradient
      : colors.white};
  border-radius: 5px;
  border: 1px solid transparent;

  ::before {
    position: absolute;
    inset: 0;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 4px;
    z-index: 0;
    background: ${({ isLocalTactic, focused }) =>
      focused
        ? isLocalTactic
          ? colors.bluePurpleFaded
          : `${colors.fadedGradient}, ${colors.white}`
        : colors.white};
  }
`;

export const MobileWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
`;

export const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  align-items: center;
  z-index: 1;
`;

export const Wrapper = styled.div<{
  focused: boolean;
  isLocalTactic?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  background: ${({ focused, isLocalTactic }) =>
    isLocalTactic
      ? colors.bluePurple
      : focused
      ? colors.purplePinkGradient
      : colors.white};

  border-radius: 5px;
  border: 1px solid transparent;
  padding: 15px;

  ::before {
    position: absolute;
    inset: 0;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 4px;
    z-index: 0;

    background: ${({ isLocalTactic, focused }) =>
      focused
        ? isLocalTactic
          ? colors.bluePurpleFaded
          : `${colors.fadedGradient}, ${colors.white}`
        : colors.white};
  }

  .Row__deleteIcon {
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;
  }

  &:hover {
    .Row__deleteIcon {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const TimingWrapper = styled.div`
  display: flex;
  flex: none;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;

const ColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(108px, 285px)) 473px;
  grid-auto-rows: minmax(65px, auto);
  position: relative;
  align-items: center;
  column-gap: 15px;
`;

const FocusAndSpinOff = styled.div`
  display: flex;
  flex-direction: column;
  height: 75px;
  gap: 5px;
  justify-content: center;
`;

const MobileTacticHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FocusAndSpinOffBtn = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
`;

export const mapTacticCategoryEnumToString = {
  [TacticCategory.MedicalLeadership]: 'Medical Leadership',
  [TacticCategory.DataAndEvidenceGeneration]: 'Data & Evidence Generation',
  [TacticCategory.DataAndEvidenceDissemination]:
    'Data & Evidence Dissemination',
  [TacticCategory.MedicalKnowledge]: 'Medical Knowledge',
  [TacticCategory.MedicalCommunityIntegration]: 'Medical Community Integration',
  [TacticCategory.PatientAdvocacy]: 'Patient Advocacy',
};

interface Props {
  data?: PreviewAndDBDataTacticType;
  parentTactic?: PreviewAndDBDataTacticType;
  onUpdate(updatedData: TacticUpdateInput): void;
  currency: string;
  onDelete(): void;
  isLead: boolean;
  isDesktop: boolean;
  autoFocus?: boolean;
  showModal(idx: number): void;
  setShowSingleSlide: React.Dispatch<React.SetStateAction<boolean>>;
  setModalSlideIdx(val: number | null): void;
  isLocalTactic: boolean;
  isSpinOff: boolean;
  createSpinOff(): void;
  hideSpinOffBtn?: boolean;
  fromOwnRegion: boolean;
  showCountrySpinOffs?: boolean;
  userMayEditRow?: boolean;
  region?: string;
  countryTacticsEnabled?: boolean;
}

/*
isLocalTactic --> this prop determines the styling used
isSpinOff --> this prop disables the tacticText and category fields
*/

type optionType = {
  id: string | number;
  value: string;
} | null;

export const TacticRow = ({
  data,
  parentTactic,
  onUpdate,
  currency,
  onDelete,
  isLead,
  isDesktop,
  autoFocus,
  showModal,
  setShowSingleSlide,
  setModalSlideIdx,
  createSpinOff,
  isLocalTactic,
  isSpinOff,
  hideSpinOffBtn,
  fromOwnRegion,
  region,
  showCountrySpinOffs,
  userMayEditRow,
  countryTacticsEnabled,
}: Props) => {
  const [selectedCategory, setSelectedCategory] = useState<optionType>(
    !!data?.category
      ? {
          id: data?.category,
          value: mapTacticCategoryEnumToString[data?.category],
        }
      : null
  );

  const spinOffRegions = useMemo(
    () =>
      countryTacticsEnabled && !showCountrySpinOffs
        ? (Array.from(
            new Set(
              data?.childTactics
                ?.filter((t) => t && t.focused)
                .map((t) => t?.region)
            )
          ).sort() as Country[])
        : [],
    [data, showCountrySpinOffs, countryTacticsEnabled]
  );

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const focusTooltipText = fromOwnRegion
    ? undefined
    : !isLead && region === 'global'
    ? 'Only Leads can edit'
    : 'Only local contributors can edit';

  const currencyIcon = currency[0];
  if (!data) return null;

  const canUpdate = (value: string | number) =>
    !!data.id || (!data.id && !!value);

  const fromDateUpdateHandler = (date: DateType | null) => {
    if (typeof date?.month !== 'number') {
      onUpdate({ timingEnd: [], timingStart: [] });
      return;
    }
    if (
      data.timingEnd.length &&
      (date.year > data.timingEnd[1] ||
        (date.year === data.timingEnd[1] && date.month > data.timingEnd[0]))
    ) {
      onUpdate({ timingEnd: [] });
    }
    onUpdate({ timingStart: [date.month, date.year] });
  };

  function onFocusClick() {
    if (!data?.focused) {
      setShowSingleSlide(false);
      showModal(0);
    } else {
      onUpdate({ focused: false });
    }
  }

  if (!isDesktop)
    return (
      <>
        <MobileOuterWrapper
          focused={data.focused}
          isLocalTactic={isLocalTactic}
        >
          <MobileWrapper>
            <MobileTacticHeader>
              <FocusAndSpinOffBtn>
                <FocusDropdown
                  onClick={onFocusClick}
                  checked={data.focused}
                  disabled={!fromOwnRegion}
                  rationaleText={data.focusRationale}
                  setModalSlideIdx={setModalSlideIdx}
                  setShowSingleSlide={setShowSingleSlide}
                  isLocal={isLocalTactic}
                  region={region}
                  disabledTooltipText={focusTooltipText}
                  spinOffRegions={spinOffRegions}
                />
                {!hideSpinOffBtn && !isLead && !isLocalTactic && (
                  <AddSpinOffButton onClick={createSpinOff} />
                )}
              </FocusAndSpinOffBtn>

              {fromOwnRegion &&
                (!showDeleteConfirm ? (
                  <ButtonRound
                    level="secondary"
                    iconName="Trash"
                    size="small"
                    onClick={() => {
                      setShowDeleteConfirm(true);
                    }}
                    className="Row__deleteIcon"
                  />
                ) : (
                  <DeleteConfirmationWrapper>
                    <ButtonLabel
                      color={colors.black}
                      onClick={() => setShowDeleteConfirm(false)}
                    >
                      Cancel
                    </ButtonLabel>
                    <ButtonLabel
                      color={colors.red}
                      onClick={async () => {
                        if (!isDeleting) {
                          setIsDeleting(true);
                          onDelete();

                          setShowDeleteConfirm(false);
                          setIsDeleting(false);
                        }
                      }}
                    >
                      Delete row
                    </ButtonLabel>
                  </DeleteConfirmationWrapper>
                ))}
            </MobileTacticHeader>

            <div>
              <BodySmall color={colors.greyDark}>Tactic</BodySmall>
              <EditableTextarea
                autoFocus={autoFocus}
                style={{
                  background: 'transparent',
                }}
                editable={!isSpinOff && userMayEditRow}
                placeholder="Tactic"
                initialValue={
                  isSpinOff
                    ? parentTactic?.tacticText || data.tacticText
                    : data.tacticText
                }
                onBlur={(value) => {
                  if (canUpdate(value)) onUpdate({ tacticText: value });
                }}
                overrideColor={
                  !fromOwnRegion || isSpinOff ? colors.greyDark : colors.black
                }
              />
            </div>

            <div>
              <BodySmall color={colors.greyDark}>Category</BodySmall>
              <Dropdown
                selectedOption={selectedCategory}
                setSelectedOption={setSelectedCategory}
                onUpdate={onUpdate}
                editable={!isSpinOff && userMayEditRow}
              />
            </div>

            <div>
              <BodySmall color={colors.greyDark}>Audience</BodySmall>
              <EditableTextarea
                style={{ background: 'transparent' }}
                editable={userMayEditRow}
                placeholder="Audience"
                initialValue={data.audienceText}
                onBlur={(value) => {
                  if (canUpdate(value)) onUpdate({ audienceText: value });
                }}
              />
            </div>

            <div>
              <BodySmall color={colors.greyDark}>Timing</BodySmall>
              <div style={{ display: 'flex', gap: 10 }}>
                <DatePickerDropdown
                  style={{ flex: 1 }}
                  placeholder="From"
                  date={data.timingStart}
                  onUpdate={(date) => {
                    fromDateUpdateHandler(date);
                  }}
                  disabled={!userMayEditRow}
                />
                <DatePickerDropdown
                  disabled={!data.timingStart.length || !userMayEditRow}
                  alignRight
                  style={{ flex: 1 }}
                  placeholder="To"
                  lowerLimit={data.timingStart}
                  date={data.timingEnd}
                  onUpdate={(date) => {
                    onUpdate({
                      timingEnd: !!date ? [date.month, date.year] : [],
                    });
                  }}
                />
              </div>
            </div>
            {!!data.focused && (
              <MobileFocusedDetails>
                <div>
                  <BodySmall color={colors.greyDark}>Due</BodySmall>
                  <BodyNormal>
                    {[monthNames[data.dueDate[0]], data.dueDate[1]].join(' ')}
                  </BodyNormal>
                </div>
                <div>
                  <BodySmall color={colors.greyDark}>Responsibility</BodySmall>
                  <BodyNormal>{data.responsibility}</BodyNormal>
                </div>
                <div>
                  <BodySmall color={colors.greyDark}>Budget</BodySmall>
                  {!!data.budget && (
                    <BodyNormal>
                      {currencyIcon + thousandSeparator(data.budget)}
                    </BodyNormal>
                  )}
                </div>
                {fromOwnRegion && (
                  <Icon
                    name={'Edit'}
                    size={20}
                    color={colors.black}
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      right: 5,
                      top: 5,
                    }}
                    onClick={() => {
                      setShowSingleSlide(true);

                      showModal(0);
                    }}
                  />
                )}
              </MobileFocusedDetails>
            )}
            <Collaboration
              style={{
                marginLeft: 'auto',
                pointerEvents: data.id ? 'all' : 'none',
              }}
              collaboration={data.collaboration}
            />
          </MobileWrapper>
        </MobileOuterWrapper>
      </>
    );

  return (
    <>
      <Wrapper focused={data.focused} isLocalTactic={isLocalTactic}>
        <ColumnsWrapper>
          <div>
            <EditableTextarea
              autoFocus={autoFocus}
              style={{
                background: 'transparent',
              }}
              overrideColor={
                !fromOwnRegion || isSpinOff ? colors.greyDark : colors.black
              }
              editable={!isSpinOff && userMayEditRow}
              placeholder="Tactic"
              initialValue={
                isSpinOff
                  ? parentTactic?.tacticText || data.tacticText
                  : data.tacticText
              }
              onBlur={(value) => {
                if (canUpdate(value)) {
                  onUpdate({ tacticText: value });
                }
              }}
            />
          </div>
          <div>
            <Dropdown
              selectedOption={selectedCategory}
              setSelectedOption={setSelectedCategory}
              onUpdate={onUpdate}
              editable={!isSpinOff && userMayEditRow}
            />
          </div>
          <div>
            <EditableTextarea
              style={{ background: 'transparent' }}
              editable={userMayEditRow}
              placeholder="Audience"
              initialValue={data.audienceText}
              onBlur={(value) => {
                if (canUpdate(value)) {
                  onUpdate({ audienceText: value });
                }
              }}
            />
          </div>
          <TimingWrapper>
            <div style={{ display: 'flex', gap: 10 }}>
              <DatePickerDropdown
                style={{ width: 90 }}
                placeholder="From"
                date={data.timingStart}
                onUpdate={(date) => {
                  fromDateUpdateHandler(date);
                }}
                disabled={!userMayEditRow}
              />
              <DatePickerDropdown
                disabled={!data.timingStart.length || !userMayEditRow}
                style={{ width: 90 }}
                placeholder="To"
                lowerLimit={data.timingStart}
                date={data.timingEnd}
                onUpdate={(date) => {
                  onUpdate({
                    timingEnd: !!date ? [date.month, date.year] : [],
                  });
                }}
              />
            </div>
            <Collaboration
              style={{
                marginLeft: 'auto',
                pointerEvents: data.id ? 'all' : 'none',
              }}
              collaboration={data.collaboration}
            />

            <FocusAndSpinOff>
              <FocusDropdown
                onClick={onFocusClick}
                checked={data.focused}
                disabled={!fromOwnRegion}
                rationaleText={data.focusRationale}
                setModalSlideIdx={setModalSlideIdx}
                setShowSingleSlide={setShowSingleSlide}
                isLocal={isLocalTactic}
                region={region}
                disabledTooltipText={focusTooltipText}
                spinOffRegions={spinOffRegions}
              />
              {!hideSpinOffBtn && !isLead && !isLocalTactic && (
                <AddSpinOffButton onClick={createSpinOff} />
              )}
            </FocusAndSpinOff>

            {fromOwnRegion ? (
              !showDeleteConfirm ? (
                <ButtonRound
                  level="secondary"
                  iconName="Trash"
                  size="small"
                  onClick={() => {
                    setShowDeleteConfirm(true);
                  }}
                  className="Row__deleteIcon"
                />
              ) : (
                <DeleteConfirmationWrapper>
                  <ButtonLabel
                    color={colors.black}
                    onClick={() => setShowDeleteConfirm(false)}
                  >
                    Cancel
                  </ButtonLabel>
                  <ButtonLabel
                    color={colors.red}
                    onClick={async () => {
                      if (!isDeleting) {
                        setIsDeleting(true);
                        onDelete();

                        setShowDeleteConfirm(false);
                        setIsDeleting(false);
                      }
                    }}
                  >
                    Delete row
                  </ButtonLabel>
                </DeleteConfirmationWrapper>
              )
            ) : (
              <div style={{ width: 30, flex: 'none' }}></div>
            )}
          </TimingWrapper>
        </ColumnsWrapper>
        {!!data.focused && (
          <DetailsWrapper>
            <DetailItem>
              <BodyNormal color={colors.greyDark}>Due:</BodyNormal>
              <BodyNormal>
                {[monthNames[data.dueDate[0]], data.dueDate[1]].join(' ')}
              </BodyNormal>
            </DetailItem>
            <DetailItem>
              <BodyNormal color={colors.greyDark}>Responsibility:</BodyNormal>
              <BodyNormal>{data.responsibility}</BodyNormal>
            </DetailItem>
            <DetailItem>
              <BodyNormal color={colors.greyDark}>Budget: </BodyNormal>
              {!!data.budget && (
                <BodyNormal>
                  {currencyIcon + thousandSeparator(data.budget)}
                </BodyNormal>
              )}
            </DetailItem>
            {fromOwnRegion && (
              <Icon
                name={'Edit'}
                size={20}
                color={colors.black}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setShowSingleSlide(true);

                  showModal(0);
                }}
              />
            )}
          </DetailsWrapper>
        )}
      </Wrapper>
    </>
  );
};

export function getCategoryIcon(value: string) {
  switch (value) {
    case 'Medical Leadership':
      return 'MedicalLeadership';

    case 'Data & Evidence Generation':
      return 'DataGeneration';

    case 'Data & Evidence Dissemination':
      return 'DataDissemination';

    case 'Medical Knowledge':
      return 'MedicalKnowledge';

    case 'Medical Community Integration':
      return 'MedicalCommunityIntegration';

    case 'Patient Advocacy':
      return 'PatientAdvocacy';

    default:
      return 'MedicalLeadership';
  }
}

export const TacticDropdownItem = ({
  value,
  textColor,
  iconColor,
}: {
  value: string;
  textColor?: string;
  iconColor?: string;
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icon
        name={getCategoryIcon(value)}
        size={25}
        color={iconColor || colors.black}
        style={{
          marginRight: '5px',
        }}
      />
      <BodySmall color={textColor || colors.black}>{value}</BodySmall>
    </div>
  );
};

function Dropdown({
  selectedOption,
  setSelectedOption,
  onUpdate,
  editable,
}: {
  selectedOption: optionType;
  setSelectedOption: React.Dispatch<React.SetStateAction<optionType>>;
  onUpdate: Props['onUpdate'];
  editable?: boolean;
}) {
  return (
    <ReusableDropdown
      editable={editable}
      options={[
        {
          id: TacticCategory.MedicalLeadership,
          value: 'Medical Leadership',
        },
        {
          id: TacticCategory.DataAndEvidenceGeneration,
          value: 'Data & Evidence Generation',
        },
        {
          id: TacticCategory.DataAndEvidenceDissemination,
          value: 'Data & Evidence Dissemination',
        },
        {
          id: TacticCategory.MedicalKnowledge,
          value: 'Medical Knowledge',
        },
        {
          id: TacticCategory.MedicalCommunityIntegration,
          value: 'Medical Community Integration',
        },
        {
          id: TacticCategory.PatientAdvocacy,
          value: 'Patient Advocacy',
        },
      ]}
      selectedOption={selectedOption}
      selectOption={(option) => {
        setSelectedOption(option);
        onUpdate({
          category: option.id as TacticCategory,
        });
      }}
      clearSelection={() => {
        setSelectedOption(null);
        onUpdate({ category: null });
      }}
      dropdownItem={(option) => <TacticDropdownItem value={option.value} />}
      selectedDropdownItem={(option) => (
        <TacticDropdownItem
          value={option.value}
          textColor={editable ? colors.black : colors.greyDark}
          iconColor={editable ? colors.black : colors.greyDark}
        />
      )}
      noSelectionItem={() => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ButtonLabel
            color={colors.greyDark}
            style={{
              cursor: editable ? 'pointer' : 'default',
            }}
          >
            {editable ? 'Choose tactic category' : 'Not identified yet'}
          </ButtonLabel>
        </div>
      )}
    />
  );
}
