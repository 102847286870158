import { colors } from 'constants/index';
import { PatientFlowBlock } from 'data/graphql/generated';
import { Group, Text } from 'react-konva';
import { BlockTree, Cursor } from 'types';
import { BlockIcon } from './BlockIcon';

export function BlockStar({
  showStarringModal,
  block,
  changeCursor,
  country,
  starred,
  amountOfStarsExcludingGlobal,
}: {
  showStarringModal: (block: PatientFlowBlock) => void;
  block: BlockTree;
  changeCursor: (cursor: Cursor) => void;
  country: string;
  starred: boolean;
  amountOfStarsExcludingGlobal: number;
}) {
  return (
    <Group
      x={10}
      y={10}
      onTap={() => {
        showStarringModal(block);
      }}
      onClick={() => {
        showStarringModal(block);
      }}
      onMouseEnter={() => {
        changeCursor('pointer');
      }}
      onMouseLeave={() => {
        changeCursor('default');
      }}
    >
      <BlockIcon
        type={country === 'global' && starred ? 'ActionPurple' : 'Action'}
        starred={starred}
      />
      {/* star counter */}
      {country === 'global' && (
        <Text
          //star icon width (20) + leftMargin(2)
          x={22}
          y={2}
          text={amountOfStarsExcludingGlobal + ''}
          fill={amountOfStarsExcludingGlobal ? colors.black70 : colors.black30}
          fontFamily="ABCFavorit"
          fontSize={12}
          fontWeight={500}
          align="center"
          lineHeight={1.25}
        />
      )}
    </Group>
  );
}
