import { colors } from 'constants/index';
import styled, { css } from 'styled-components';
interface Props {
  color?: string;
}
export const Heading1 = styled.h1<Props>`
  font-family: ABCFavoritExpanded;
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 80px;
  margin: 0;
  color: ${({ color }) => color || colors.black};
  cursor: default;
  pointer-events: none;
`;

export const Heading2 = styled.h2<Props>`
  font-family: ABCFavoritExpanded;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 50px;
  margin: 0;
  color: ${({ color }) => color || colors.black};
  cursor: default;
  pointer-events: none;
`;

export const Heading3 = styled.h3<Props>`
  font-family: ABCFavoritExpanded;
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 30px;
  margin: 0;
  color: ${({ color }) => color || colors.black};
  pointer-events: none;
  cursor: default;
`;

export const Subtitle1 = styled.p<Props>`
  font-family: ABCFavorit;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  margin: 0;
  color: ${({ color }) => color || colors.black};
  pointer-events: none;
  cursor: default;
`;

export const Subtitle2 = styled.p<Props>`
  font-family: ABCFavorit;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  margin: 0;
  color: ${({ color }) => color || colors.black};
  pointer-events: none;
  cursor: default;
`;

export const BodyExtraLarge = styled.p<Props>`
  font-family: ABCFavorit;
  font-style: normal;
  font-weight: normal;
  font-size: 45px;
  line-height: 60px;
  margin: 0;
  color: ${({ color }) => color || colors.black};
  pointer-events: none;
  cursor: default;
`;

export const BodyLarge = styled.p<Props>`
  font-family: ABCFavorit;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 35px;
  margin: 0;
  color: ${({ color }) => color || colors.black};
  pointer-events: none;
  cursor: default;
`;

export const BodyNormal = styled.p<Props>`
  font-family: ABCFavorit;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: ${({ color }) => color || colors.black};
  pointer-events: none;
  cursor: default;
`;

export const BodySmallStyle = css<Props>`
  font-family: ABCFavorit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  word-wrap: break-word;
  line-height: 20px;
  margin: 0;
  color: ${({ color }) => color || colors.black};
  pointer-events: none;
  cursor: default;
`;

export const BodySmall = styled.p<Props>`
  ${BodySmallStyle}
`;

export const BodyExtraSmall = styled.p<Props>`
  font-family: ABCFavorit;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 20px;
  margin: 0;
  color: ${({ color }) => color || colors.black};
  cursor: default;
`;

export const ButtonLabel = styled.p<Props>`
  font-family: ABCFavorit;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: ${({ color }) => color || colors.black};
  cursor: pointer;
`;

export const Caption = styled.p<Props>`
  font-family: ABCFavorit;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  color: ${({ color }) => color || colors.black};
  pointer-events: none;
  cursor: default;
`;
