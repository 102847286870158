export function getCategoryIcon(value: string) {
  switch (value) {
    case 'Medical Leadership':
      return 'MedicalLeadership';

    case 'Data & Evidence Generation':
      return 'DataGeneration';

    case 'Data & Evidence Dissemination':
      return 'DataDissemination';

    case 'Medical Knowledge':
      return 'MedicalKnowledge';

    case 'Medical Community Integration':
      return 'MedicalCommunityIntegration';

    case 'Patient Advocacy':
      return 'PatientAdvocacy';

    default:
      return 'MedicalLeadership';
  }
}
