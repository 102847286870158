import { countries } from 'constants/countries';
import { colors } from 'constants/colors';
import React from 'react';
import styled from 'styled-components/macro';
import { Country } from 'types';
import { Icon } from '.';
import { IconContainer } from './Icon';
import { lowerCase } from 'lodash';

function getCountryFlagAssetPath(region: Country) {
  const c = countries.find((c) => lowerCase(c.name) === lowerCase(region));

  return `url(/country-flags/${c?.img || 'afghanistan.png'})`;
}

export const CheckCircleWrapper = styled.div<{
  isChecked: boolean;
  small: boolean;
  disabled?: boolean;
  localTactic?: boolean;
  countryFlagAssetPath: string;
}>`
  background: ${({ localTactic }) =>
    localTactic ? colors.bluePurple : colors.purplePinkGradient};
  border-radius: 50%;
  min-width: ${({ small }) => (small ? 25 : 30)}px;
  min-height: ${({ small }) => (small ? 25 : 30)}px;
  width: ${({ small }) => (small ? 25 : 30)}px;
  height: ${({ small }) => (small ? 25 : 30)}px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${IconContainer} {
    border-radius: 50%;
    background: ${({ isChecked, localTactic, countryFlagAssetPath }) =>
      localTactic
        ? isChecked
          ? countryFlagAssetPath
          : colors.bluePurpleFaded
        : isChecked
        ? colors.purplePinkGradient
        : colors.purple10};
    width: ${({ small }) => (small ? 21 : 26)}px;
    height: ${({ small }) => (small ? 21 : 26)}px;
    position: relative;
    background-size: cover;
  }

  &:hover:not([disabled]) {
    ${IconContainer} {
      background: ${({ isChecked, localTactic, countryFlagAssetPath }) =>
        localTactic
          ? countryFlagAssetPath
          : isChecked
          ? colors.purple60
          : colors.purple20};
      background-size: cover;

      &:after {
        display: ${({ localTactic }) => (localTactic ? 'block' : 'none')};
        content: '';
        position: absolute;
        inset: 0;
        background: white;
        opacity: ${({ isChecked }) => (isChecked ? 0.3 : 0.5)};
        border-radius: 50%;
      }
    }
  }
`;

const IconOverlay = styled.div<{ isDisabled?: boolean }>`
  width: 32px;
  height: 32px;
  position: absolute;
  top: -1px;
  left: -1px;
  background: white;
  z-index: 1;
  border-radius: 15px;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 0)};
`;

interface Props {
  isDisabled?: boolean;
  complete: boolean;
  onClick?(e: React.MouseEvent<HTMLDivElement>): void;
  className?: string;
  small?: boolean;
  localTactic?: boolean;
  region?: string;
}

export const CheckCircle = ({
  isDisabled,
  complete,
  onClick,
  className,
  small = false,
  localTactic,
  region,
}: Props) => {
  const countryFlagAssetPath = getCountryFlagAssetPath(
    (region as Country) || 'afghanistan'
  );

  return (
    <CheckCircleWrapper
      className={className}
      onClick={(e) => (isDisabled || !onClick ? null : onClick(e))}
      style={{
        position: 'relative',
        cursor: !isDisabled ? 'pointer' : 'not-allowed',
      }}
      disabled={isDisabled}
      isChecked={complete}
      small={small}
      localTactic={localTactic}
      countryFlagAssetPath={countryFlagAssetPath}
    >
      <IconOverlay isDisabled={isDisabled} />
      <Icon name="Tick" size={30} color={colors.white} />
    </CheckCircleWrapper>
  );
};
