import ExpandingTextArea from 'react-expanding-textarea';
import { StyledDataFieldContainer } from '../StrategyDefinitionHeader.styles';

interface StrategyDefinitionHeaderTextProps {
  title: string;
  name: string;
  value: string;
  tooltip?: string;
  placeholder?: string;
  isLead?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: () => void;
}

export const StrategyDefinitionHeaderTextField = ({
  title,
  name,
  value,
  tooltip,
  placeholder,
  onChange,
  onBlur,
  isLead = false,
  disabled = false,
}: StrategyDefinitionHeaderTextProps) => {
  const isDisabled = disabled || !isLead;
  const defaultPlaceholder = `Define your ${title.toLocaleLowerCase()}`;
  const placeholderText = placeholder ? placeholder : defaultPlaceholder;
  const handleOnBlur = () => {
    if (onBlur) {
      return onBlur();
    }
  };

  const handleOnChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      const text = e.currentTarget.value;
      return onChange(text);
    }
  };

  return (
    <StyledDataFieldContainer
      isLead={isLead}
      className="textArea__wrapper"
      data-for={name}
      data-tip={tooltip}
      data-offset="{'top': 0}"
    >
      <ExpandingTextArea
        className="definitionHeader__textarea DrugDetailWrapper__textarea"
        disabled={isDisabled}
        rows={2}
        value={value}
        name={name}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        placeholder={placeholderText}
      />
    </StyledDataFieldContainer>
  );
};
