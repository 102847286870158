import React from 'react';
import styled from 'styled-components/macro';

import { colors } from 'constants/colors';
import { RatingStar, RatingDollar, BodySmall } from 'components/shared';
import {
  EndpointTargetFragment,
  AccessStrategyEndpointTargetFragment,
} from 'data/graphql/generated';

const Container = styled.div`
  padding: 15px;
  max-width: 431px;
  display: block;
  border-radius: 5px;
  position: relative;
`;

const FieldTitle = styled(BodySmall)`
  color: ${colors.greyDark};
  font-weight: 400;
  display: block;
`;

const FieldText = styled(BodySmall)`
  color: ${colors.black};
  font-weight: 400;
  display: block;
  margin-bottom: 2px;
`;

interface Props {
  endpointTarget: EndpointTargetFragment | AccessStrategyEndpointTargetFragment;
}

export const EndpointTarget: React.FC<Props> = ({ endpointTarget }) => {
  return (
    <Container>
      <div style={{ marginBottom: 15 }}>
        <FieldTitle>Endpoint target</FieldTitle>
        {endpointTarget.title ? (
          <FieldText>{endpointTarget.title}</FieldText>
        ) : (
          <BodySmall color={colors.greyDark}>Not identified yet</BodySmall>
        )}
      </div>
      <div>
        <FieldTitle>Measurement</FieldTitle>
        {endpointTarget.measurement ? (
          <FieldText>{endpointTarget.measurement}</FieldText>
        ) : (
          <BodySmall color={colors.greyDark}>Not identified yet</BodySmall>
        )}
      </div>
      <div style={{ display: 'inline-block', width: 140, marginTop: 15 }}>
        <FieldTitle>Feasibility</FieldTitle>
        <RatingStar
          rating={endpointTarget.feasibility}
          style={{ marginTop: 5 }}
          readOnly
        />
      </div>
      <div style={{ display: 'inline-block', width: 140, marginTop: 15 }}>
        <FieldTitle>Impact</FieldTitle>
        <RatingStar
          rating={endpointTarget.impact}
          style={{ marginTop: 5 }}
          readOnly
        />
      </div>
      <div style={{ display: 'inline-block', width: 100, marginTop: 15 }}>
        <FieldTitle>Cost</FieldTitle>
        <RatingDollar
          rating={endpointTarget.cost}
          style={{ marginTop: 5 }}
          readOnly
        />
      </div>
    </Container>
  );
};
