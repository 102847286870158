import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const USE_PROXY = process.env.USE_PROXY === 'true';
const DEFAULT_HTTP_API_URL = 'http://localhost:4000/';
const DEFAULT_WS_API_URL = 'ws://localhost:4000/graphql';
const BASE_PROXY_API_URL = 'api.nmblr.local';
const HTTP_PROXY_API_URL = `http://${BASE_PROXY_API_URL}/`;
const WS_PROXY_API_URL = `ws://${BASE_PROXY_API_URL}/graphql`;

function getApiURL() {
  if (USE_PROXY) {
    return HTTP_PROXY_API_URL;
  }

  if (process.env.REACT_APP_BUILD_ENV === 'development') {
    return DEFAULT_HTTP_API_URL;
  }

  if (process.env.REACT_APP_BUILD_ENV === 'production') {
    return 'https://api.nmblr.co/';
  }

  return 'https://api.staging.nmblr.co/';
}

const socketUri = (() => {
  if (USE_PROXY) {
    return WS_PROXY_API_URL;
  }

  if (process.env.REACT_APP_BUILD_ENV === 'development') {
    return DEFAULT_WS_API_URL;
  }

  if (process.env.REACT_APP_BUILD_ENV === 'production') {
    return 'wss://api.nmblr.co:4000/graphql';
  }

  return 'wss://api.staging.nmblr.co:4000/graphql';
})();

const wsLink = new GraphQLWsLink(
  createClient({
    url: socketUri,
  })
);

const httpLink = createHttpLink({
  uri: getApiURL(),
  credentials: 'include',
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      notification: {
        keyFields: ['id', 'title'],
      },
      KeyStatement: {
        keyFields: ['localUid'],
      },
      Tactic: {
        keyFields: ['localUid'],
      },
      KeyEvent: {
        keyFields: ['localUid'],
      },
      KeyEventStep: {
        keyFields: ['keyEventLocalUid', 'region', 'step'],
      },
    },
  }),
  link: split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  ),
});

export default client;
