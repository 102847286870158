import * as React from 'react';
import { FormDropdown } from 'components/shared/FormDropdown';
import { noop } from 'utils/noop';

const INVALID_STRATEGIC_IMPERATIVE = 'Please select a strategic imperative';

interface StrategicImperativeDropdownProps {
  strategicImperativeId?: string;
  strategicImperatives: { id: string; text: string }[];
  onChange: (id: string) => void;
  isTouched?: boolean;
  onTouch?: () => void;
}

export const StrategicImperativeDropdown = ({
  strategicImperativeId,
  strategicImperatives,
  onChange,
  onTouch = noop,
  isTouched = false,
}: StrategicImperativeDropdownProps) => {
  const [isDropdownActive, setIsDropdownActive] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState<
    { id: string; text: string } | undefined
  >();

  const isDisabled = strategicImperatives.length === 0;
  const toggleDropdown = () => {
    onTouch();
    setIsDropdownActive(!isDropdownActive);
  };
  const setSelectedItem = (value: { id: string; text: string }) => {
    onChange(value.id);
    setSelectedOption(value);
  };

  React.useEffect(() => {
    if (strategicImperativeId && !selectedOption) {
      const selected = strategicImperatives.find(
        (item) => item.id === strategicImperativeId
      );

      if (selected) {
        setSelectedOption(selected);
      }
    }
  }, [selectedOption, strategicImperativeId, strategicImperatives]);

  return (
    <FormDropdown
      name="Strategic Imperative"
      title="Strategic Imperative"
      onClick={toggleDropdown}
      options={strategicImperatives}
      placeholder={
        isDisabled ? 'No Strategic Imperatives' : 'Choose Strategic Imperative'
      }
      disabled={isDisabled}
      selectedItem={selectedOption}
      dropdownActive={isDropdownActive}
      setSelectedItem={setSelectedItem}
      setDropdownActive={setIsDropdownActive}
      errorMessage={
        isTouched && !selectedOption ? INVALID_STRATEGIC_IMPERATIVE : undefined
      }
    />
  );
};
