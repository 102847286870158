import { BodySmall, Icon } from 'components/shared';
import { colors } from 'constants/index';
import { SupportingMessageTacticCollaborationFragment } from 'data/graphql/generated';
import React from 'react';
import { thousandSeparator } from 'utils/thousandSeparator';
import styled from 'styled-components';
import { formatTimestamp } from 'utils/formatTimestamp';
import { ImportanceDropdownItem } from 'components/AccessStrategy/AccessStrategyTactics/AccessStrategyTacticsRow';

const WhiteCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 10px 15px;
  border-radius: 5px;
  background: ${colors.white};
`;

export const SupportingMessageTactic = (
  data?: SupportingMessageTacticCollaborationFragment & {
    currency: string | undefined;
  }
) => {
  if (!data) return null;
  const {
    text,
    from,
    to,
    due,
    importance,
    responsibility,
    budget,
    currency,
    focus,
  } = data;
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
      }}
    >
      <WhiteCardWrapper>
        {!!focus && (
          <div style={{ display: 'flex', marginLeft: -10 }}>
            <Icon name="GradientTick" size={30} />
            <BodySmall>Focus</BodySmall>
          </div>
        )}
        <div>
          <BodySmall color={colors.greyDark}>Tactic</BodySmall>
          {text ? <BodySmall>{text}</BodySmall> : <UnidentifiedText />}
        </div>

        <div>
          <BodySmall color={colors.greyDark}>Importance</BodySmall>
          {importance ? (
            <ImportanceDropdownItem value={importance} />
          ) : (
            <UnidentifiedText />
          )}
        </div>

        <div>
          <BodySmall color={colors.greyDark}>Timing</BodySmall>
          {from && to ? (
            <BodySmall>
              {formatTimestamp(from) + ' - ' + formatTimestamp(to)}
            </BodySmall>
          ) : (
            <UnidentifiedText />
          )}
        </div>
        {!!focus && (
          <>
            <div>
              <BodySmall color={colors.greyDark}>Due</BodySmall>

              {due ? (
                <BodySmall>{formatTimestamp(due)}</BodySmall>
              ) : (
                <UnidentifiedText />
              )}
            </div>
            <div>
              <BodySmall color={colors.greyDark}>Responsibility</BodySmall>
              {responsibility ? (
                <BodySmall>{responsibility}</BodySmall>
              ) : (
                <UnidentifiedText />
              )}
            </div>
            <div>
              <BodySmall color={colors.greyDark}>Budget</BodySmall>
              {budget ? (
                <BodySmall>
                  {currency?.[0] + thousandSeparator(budget)}
                </BodySmall>
              ) : (
                <UnidentifiedText />
              )}
            </div>
          </>
        )}
      </WhiteCardWrapper>
    </div>
  );
};

function UnidentifiedText() {
  return <BodySmall color={colors.greyDark}>Not identified yet</BodySmall>;
}
