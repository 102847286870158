import React from 'react';
import styled from 'styled-components';

import { colors } from 'constants/colors';

const Notifications = styled.div<{ hasUnread: boolean; visible: boolean }>`
  position: relative;
  display: inline-block;
  padding: 10px 10px;
  vertical-align: top;
  float: right;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: ${({ visible }) => (visible ? colors.purple : colors.black)};
  border-radius: 50%;
  margin-top: 5px;
  transition: all 0.3s;

  &:hover {
    background: ${({ visible }) => (visible ? colors.purple : '#3c0c7d')};
  }
`;

const Badge = styled.div`
  background: ${colors.purple};
  border: 2px solid ${colors.black};
  width: 20px;
  height: 20px;
  position: absolute;
  top: -1px;
  right: -1px;
  border-radius: 50%;
  color: ${colors.white};
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  padding-top: 1px;
`;

interface Props {
  onClick(): void;
  visible: boolean;
  notificationsUnread: number;
}

export const NotificationBell: React.FC<Props> = ({
  onClick,
  visible,
  notificationsUnread,
}) => {
  return (
    <Notifications
      onClick={onClick}
      hasUnread={notificationsUnread > 0}
      visible={visible}
    >
      {notificationsUnread > 0 ? (
        <>
          <img src="/bell-unread.svg" alt="notifications" />
          <Badge>{notificationsUnread > 99 ? 99 : notificationsUnread}</Badge>
        </>
      ) : (
        <img src="/bell.svg" alt="notifications" />
      )}
    </Notifications>
  );
};
