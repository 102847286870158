import { BodySmall } from 'components/shared';
import { colors } from 'constants/index';
import { LongTermSolutionCollaborationFragment } from 'data/graphql/generated';
import styled from 'styled-components/macro';

const WhiteCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 10px 15px;
  border-radius: 5px;
  background: ${colors.white};
`;

type Props = {
  data: LongTermSolutionCollaborationFragment;
};

export const LongTermSolution = ({ data }: Props) => {
  return (
    <WhiteCardWrapper>
      <div style={{ display: 'flex', gap: 5 }}>
        <BodySmall color={colors.greyDark}>Opportunity / Risk:</BodySmall>
        {data?.futureTrend?.risk ? (
          <BodySmall>{data?.futureTrend?.risk}</BodySmall>
        ) : (
          <BodySmall color={colors.greyDark}>Not identified yet</BodySmall>
        )}
      </div>
      {!!data.text && <BodySmall>{data.text}</BodySmall>}
    </WhiteCardWrapper>
  );
};
