import { Navbar } from 'components/Navbar';
import React from 'react';
import { useParams } from 'react-router-dom';

interface Props {}

interface Params {
  drugId: string;
  strategyId: string;
}
export const Files = (props: Props) => {
  const { strategyId, drugId }: Params = useParams();

  return (
    <div>
      <Navbar
        hideArrows
        title="Files"
        url={`/d/${drugId}/strategy/${strategyId}?nav=1`}
      />
    </div>
  );
};
