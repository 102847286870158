import { StrategicPossibility } from 'data/graphql/generated';
import { ImperativeSummaryCard } from '../../ImperativeSummaryCard';
import { Loading } from 'components/Loading';
import { GreyCardComponent } from '../GreyCardComponent';

interface ContentSummaryProps {
  isLoading: boolean;
  strategicImperative?: Pick<
    StrategicPossibility,
    'name' | 'driverOfTheBehaviour' | 'behaviourToDrive'
  >;
  leadIndicators?: string[];
  lagIndicators?: string[];
}

export const ContentSummary = ({
  isLoading,
  strategicImperative,
  leadIndicators = [],
  lagIndicators = [],
}: ContentSummaryProps) => {
  if (isLoading) {
    return <Loading />;
  }

  if (!strategicImperative) {
    return <Loading />;
  }

  return (
    <>
      <ImperativeSummaryCard strategicImperative={strategicImperative} />

      <GreyCardComponent title="Lead indicators" listData={leadIndicators} />

      <GreyCardComponent title="Lag indicators" listData={lagIndicators} />
    </>
  );
};
