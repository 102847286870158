import React, { useEffect, useRef, useState, useMemo } from 'react';
import { TabGroup, KeyStatement, ButtonPill, BodySmall } from '.';
import styled from 'styled-components/macro';
import { colors } from 'constants/index';
import { KeyStatementFragment } from 'data/graphql/generated';
import { uid } from 'uid';
import useMobile from 'hooks/useMobile';
import { ButtonPillWrapper } from './ButtonPill';
import { Dropdown, DropdownToggle } from './CountriesDropdownSmall';
import { device } from 'utils/breakpoints';

const KeyStatementContainer = styled.div`
  display: flex;
  // This allows the delete icon to overflow out of the tab group
  padding: 15px 0;
  align-items: center;
`;

const Title = styled(BodySmall)`
  width: fit-content;
`;

const Wrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${colors.purple10};
  width: 100%;
  padding: 0px 20px;
  border-bottom: 1px solid ${colors.greyMedium};

  ${ButtonPillWrapper} {
    padding: ${({ isMobile }) => isMobile && '0 5px'};
  }
`;

const TitleAndCountryDropdown = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  align-items: center;
`;

const CountryDropdownAndKeyStatements = styled.div`
  display: flex;
  margin-top: -10px;

  ${DropdownToggle} {
    align-self: flex-start;
    margin-top: 15px;
  }

  ${Dropdown} {
    left: 0;
  }
`;

const StatementsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  overflow: hidden;

  @media ${device.mobile} {
    margin-top: 0px;
  }
`;

const PurpleDivider = styled.div`
  width: 1px;
  background: ${colors.purple};
  margin: 15px 10px;
`;

interface commercialT {
  focused: boolean;
  id: number;
  keyStatementId: number;
  text: string;
}

interface Props {
  title: string;
  hideScrollTabs?: boolean;
  addStatement({ text, localUid }: { text: string; localUid: string }): void;
  deleteStatement(keyStatement: KeyStatementFragment): void;
  updateStatement({ localUid, text }: { localUid: string; text: string }): void;
  className?: string;
  style?: React.CSSProperties;
  data: KeyStatementFragment[];
  isLead: boolean;
  placeholder: string;
  marketRepPlaceholder: string;
  showCountryDropdown?: boolean;
  CountryDropdown?: React.ReactNode;
  viewingOtherRegion?: boolean;
  overrideTooltip?: string;
  commercialTactics?: commercialT[];
}

export type PreviewAndDBDataType = Partial<
  Omit<KeyStatementFragment, 'localUid' | 'text'>
> &
  Pick<KeyStatementFragment, 'localUid' | 'text'>;

export const KeyStatements = ({
  title,
  addStatement,
  deleteStatement,
  updateStatement,
  className,
  style,
  data,
  isLead,
  placeholder,
  marketRepPlaceholder,
  showCountryDropdown,
  CountryDropdown,
  viewingOtherRegion,
  overrideTooltip,
  commercialTactics
}: Props) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const [tempKeyStatement, setTempKeyStatement] = useState<
    PreviewAndDBDataType | undefined
  >(undefined);
  const [autoFocus, setAutoFocus] = useState(false);
  const isMobile = useMobile();

  const localData = useMemo(() => {
    return [...data];
  }, [data]);
  const dataIsEmpty = localData.length === 0;
  const isPreviewEmpty = tempKeyStatement && !tempKeyStatement.text.trim();

  const addPreview = () => {
    setTempKeyStatement({ localUid: uid(), text: '' });
  };

  useEffect(() => {
    if (isLead && dataIsEmpty && !tempKeyStatement) {
      addPreview();
    }
  }, [dataIsEmpty, isLead, tempKeyStatement]);

  useEffect(() => {
    // Scroll tabGroup to end
    const timeout = setTimeout(() => {
      if (
        tempKeyStatement &&
        !!scrollContainerRef?.current &&
        'scrollLeft' in scrollContainerRef?.current
      ) {
        const newScrollLeft = scrollContainerRef.current?.scrollWidth || 0;

        scrollContainerRef.current.scrollLeft = newScrollLeft || 0;
      }
    }, 300);
    return () => clearTimeout(timeout);
  }, [tempKeyStatement, isMobile]);

  return (
    <Wrapper className={className} style={style} isMobile={isMobile}>
      <TitleAndCountryDropdown>
        <Title color={colors.greyDark}>{title}</Title>
        {isMobile && showCountryDropdown && CountryDropdown}
      </TitleAndCountryDropdown>

      <CountryDropdownAndKeyStatements>
        {!isMobile && showCountryDropdown && CountryDropdown}
        {!isMobile && showCountryDropdown && <PurpleDivider />}

        <StatementsWrapper>
          <TabGroup
            componentName="KeyStatement"
            scrollContainerRef={scrollContainerRef}
          >
            <KeyStatementContainer>
              {!dataIsEmpty &&
                localData.map((val) => {
                  return (
                    <KeyStatement
                      overrideTooltip={overrideTooltip}
                      autoFocus={!val.id && autoFocus}
                      key={val?.localUid}
                      id={val?.id}
                      commercialTactics={commercialTactics?.filter((k) => k.keyStatementId === val?.id)}
                      text={val.text}
                      placeholder={placeholder}
                      marketRepPlaceholder={marketRepPlaceholder}
                      onBlur={(text) => {
                        const validString = text.trim();
                        if (!validString) {
                          return;
                        }

                        updateStatement({
                          localUid: val.localUid,
                          text: text,
                        });
                      }}
                      onDelete={() => {
                        if (!!val.id) {
                          return deleteStatement(val as KeyStatementFragment);
                        }
                        if (val.localUid) {
                          setTempKeyStatement(undefined);
                        }
                      }}
                      isLead={isLead}
                      viewingOtherRegion={viewingOtherRegion}
                    />
                  );
                })}

              {!isLead && dataIsEmpty && (
                <KeyStatement
                  showEmptyState={true}
                  text=""
                  marketRepPlaceholder={marketRepPlaceholder}
                />
              )}

              {tempKeyStatement && (
                <KeyStatement
                  overrideTooltip={overrideTooltip}
                  autoFocus={autoFocus}
                  key={tempKeyStatement?.localUid}
                  text={tempKeyStatement.text}
                  placeholder={placeholder}
                  marketRepPlaceholder={marketRepPlaceholder}
                  onChange={(text) => {
                    setTempKeyStatement({ ...tempKeyStatement, text });
                  }}
                  onBlur={(text) => {
                    const validString = text.trim();

                    if (!validString && !tempKeyStatement.id) {
                      setTempKeyStatement(undefined);
                      return;
                    }

                    addStatement({
                      localUid: tempKeyStatement.localUid,
                      text: text,
                    });
                    setTempKeyStatement(undefined);
                  }}
                  onDelete={() => setTempKeyStatement(undefined)}
                  isLead={isLead}
                  viewingOtherRegion={viewingOtherRegion}
                />
              )}
              {!!isLead && !viewingOtherRegion && (
                <ButtonPill
                  className="cy-add-statement-button"
                  disabled={viewingOtherRegion || isPreviewEmpty}
                  iconName={'Plus'}
                  text={isMobile ? '' : 'Add'}
                  onClick={() => {
                    setAutoFocus(true);
                    addPreview();
                  }}
                />
              )}
            </KeyStatementContainer>
          </TabGroup>
        </StatementsWrapper>
      </CountryDropdownAndKeyStatements>
    </Wrapper>
  );
};
