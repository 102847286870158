import { getStrategyUrl } from './getStrategyUrl';
import { getCompetitiveAdvantageRowUrlParam } from './getCompetitiveAdvantageRowUrlParam';

export const getCriticalMetricsLeadAndLagIndicatorsUrl = (
  drugId: string,
  strategyId: string,
  competitiveAdvantageRowId?: string | number
) => {
  const baseUrl = getStrategyUrl(drugId, strategyId);
  const competitiveAdvantageRowUrlParam = getCompetitiveAdvantageRowUrlParam(
    competitiveAdvantageRowId
  );

  return `${baseUrl}/3_5/indicators${competitiveAdvantageRowUrlParam}`;
};
