const containsLowercaseChar = (password: string): boolean => {
  return password.split('').some((char) => char.match(/[a-z]/));
};

const containsUppercaseChar = (password: string): boolean => {
  return password.split('').some((char) => char.match(/[A-Z]/));
};

const containsAtLeastOneNumber = (password: string): boolean => {
  return password.split('').some((char) => char.match(/[0-9]/));
};

const minimumEightChars = (password: string): boolean => password.length >= 8;

const passwordIsValid = (password: string): boolean => {
  return (
    containsLowercaseChar(password) &&
    containsUppercaseChar(password) &&
    containsAtLeastOneNumber(password) &&
    minimumEightChars(password)
  );
};

export {
  containsAtLeastOneNumber,
  containsLowercaseChar,
  containsUppercaseChar,
  minimumEightChars,
  passwordIsValid,
};
