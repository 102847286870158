import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { FinancialsSummary } from 'components/LongTermStrategy/FinancialsSummary';
import { LongTermStrategyNav } from 'components/LongTermStrategy/LongTermStrategyNav';
import { useTimelineData } from 'hooks/useTimelineData';
import { Page, Wrapper } from 'components/Page';
import { PostItsEmpty } from 'components/PostItsEmpty';
import { ButtonLabel, Subtitle2 } from 'components/shared';
import { ButtonPill, ButtonPillWrapper } from 'components/shared/ButtonPill';
import { colors } from 'constants/index';
import { polling } from 'constants/polling';
import { useAuthContext } from 'contexts/AuthContext';
import {
  SubStep,
  useLongTermSolutionDetailsQuery,
  useStrategyQuery,
} from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import { useEffect, useMemo, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { LongTermStrategySummaryTimeline } from '../components/LongTermStrategy/Summary/LongTermStrategySummaryTimeline';
import { verifyUserRole } from 'utils/verifyUserRole';

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 5px;
  flex-wrap: wrap;
  gap: 10px;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledButtonPill = styled(ButtonPill)`
  max-height: 30px;
  ${ButtonPillWrapper} {
    max-height: 30px;
  }
`;

const PageWrapper = styled(Page)`
  padding: 0px;
  overflow: visible; // for the dropdown components
  padding: 0 15px;
  margin-top: 180px;
  @media ${device.tabletMin} {
    ${Wrapper} {
      width: auto;
      padding: 0 15px;
      max-width: none;
    }
  }

  @media ${device.desktopMin} {
    margin-top: 130px;

    ${Wrapper} {
      padding: 0 20px;
    }
  }
`;

interface URLParams {
  drugId: string;
  strategyId: string;
  strategicImperativeId: string;
}

export const LongTermStrategySummary = () => {
  const { strategyId }: URLParams = useParams();
  const history = useHistory();
  let { path, url } = useRouteMatch();

  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);

  const isDesktop = useDesktop();
  const onFinancialsPage = history.location.pathname.includes('/financials');

  const [showKeyDatesModal, setShowKeyDatesModal] = useState(false);

  const {
    data: timelineData,
    timelineDatesData,
    error,
    loading,
    startPolling,
    stopPolling,
  } = useTimelineData({
    strategyId,
  });

  const {
    data: strategyData,
    loading: strategyLoading,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
    error: strategyError,
  } = useStrategyQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'network-only',
  });

  const currency = strategyData?.strategy?.currency || '¥ CNY';

  const {
    data: longTermSolutionDetailsData,
    loading: longTermSolutionDetailsLoading,
    startPolling: startLongTermSolutionDetailsPolling,
    stopPolling: stopLongTermSolutionDetailsPolling,
    error: longTermSolutionDetailsError,
  } = useLongTermSolutionDetailsQuery({
    variables: {
      where: { strategyId: +strategyId },
    },
    fetchPolicy: 'network-only',
  });

  const memoizedLongTermSolutionDetailsData = useMemo(
    () =>
      longTermSolutionDetailsData?.longTermSolutionDetails?.items.map(
        (detail) => {
          return {
            id: (detail.__typename || '') + detail.id,
            text: detail.longTermSolution.text,
            budget: detail.budget,
            due: detail.due,
            responsibility: detail.responsibility,
            type: detail.__typename,
          };
        }
      ) || [],
    [longTermSolutionDetailsData?.longTermSolutionDetails?.items]
  );

  const combinedData = useMemo(
    () =>
      [...memoizedLongTermSolutionDetailsData].sort((a, b) => {
        // Sort by date and then alphabet
        const dateDifference = +(a?.due || 0) - +(b?.due || 0);

        if (!dateDifference) {
          const nameA = a.text.toUpperCase();
          const nameB = b.text.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        }
        return dateDifference;
      }),
    [memoizedLongTermSolutionDetailsData]
  );

  useEffect(() => {
    startStrategyPolling(polling.default);
    startLongTermSolutionDetailsPolling(polling.default);
    startPolling(polling.default);
    return () => {
      stopStrategyPolling();
      stopLongTermSolutionDetailsPolling();
      stopPolling();
    };
  }, [
    startStrategyPolling,
    stopStrategyPolling,
    startLongTermSolutionDetailsPolling,
    stopLongTermSolutionDetailsPolling,
    startPolling,
    stopPolling,
  ]);

  return (
    <>
      <LongTermStrategyNav substep={SubStep.Summary} />

      <PageWrapper paddingTop={false} fullWidthMobile>
        <ErrorWrapper
          isLoading={
            strategyLoading || longTermSolutionDetailsLoading || loading
          }
          errors={[strategyError, longTermSolutionDetailsError, ...error]}
          dataMissing={!timelineData}
        >
          <Header>
            <Buttons>
              <Subtitle2 style={{ marginRight: 5 }}>Summary</Subtitle2>

              <StyledButtonPill
                text="Timeline"
                level={!onFinancialsPage ? 'primary-solid' : 'secondary'}
                color={!onFinancialsPage ? colors.white : colors.black}
                onClick={() => {
                  history.push(`${url}/timeline`);
                }}
              />

              <StyledButtonPill
                text="Financials"
                level={onFinancialsPage ? 'primary-solid' : 'secondary'}
                color={onFinancialsPage ? colors.white : colors.black}
                onClick={() => {
                  history.push(`${url}/financials`);
                }}
              />
            </Buttons>
            {isLead &&
              !onFinancialsPage &&
              !!Object.values(timelineData).length && (
                <ButtonLabel
                  data-cy="set-key-dates-btn"
                  onClick={() => {
                    setShowKeyDatesModal(true);
                  }}
                  color={colors.purple}
                >
                  Set key dates
                </ButtonLabel>
              )}
          </Header>
          <Switch>
            <Route path={`${path}/financials`}>
              {!combinedData.length ? (
                <PostItsEmpty
                  title="No long-term solutions yet"
                  subtitle="Long term solutions will be summarised here"
                />
              ) : (
                <>
                  <main>
                    <FinancialsSummary
                      combinedData={combinedData}
                      currency={currency[0]}
                      isDesktop={isDesktop}
                    />
                  </main>
                </>
              )}
            </Route>
            <Route path={`${path}/timeline`}>
              {!Object.values(timelineData).length ? (
                <PostItsEmpty
                  title="No long-term solutions yet"
                  subtitle="Big ideas, risk mitigation solutions and long-term solutions will be summarised here"
                />
              ) : (
                <LongTermStrategySummaryTimeline
                  data={timelineData}
                  timelineDatesData={timelineDatesData}
                  showKeyDatesModal={showKeyDatesModal}
                  closeKeyDatesModal={() => setShowKeyDatesModal(false)}
                />
              )}
            </Route>
            <Route path={`${path}`}>
              <Redirect to={`${url}/timeline`} />
            </Route>
          </Switch>
        </ErrorWrapper>
      </PageWrapper>
    </>
  );
};
