import React from 'react';
import styled from 'styled-components';
import { parseISO } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { useCollaborationContext } from 'contexts/CollaborationContext';
import { mapDBStepToReadableStep } from 'utils/mapDBStepToReadableStep';
import { formatCreatedDate } from 'utils/formatCreatedDate';
import {
  NotificationFragment,
  useNotificationsUpdateMutation,
} from 'data/graphql/generated';
import { colors } from '../../constants';
import { BodySmall, CountryFlag, TooltipWrapper, Icon } from '../shared';

const Wrapper = styled.div<{ read: boolean }>`
  background: ${({ read }) => (read ? colors.white : colors.cream)};
  margin: 0px 15px 15px 15px;
  border-radius: 6px;
  padding: 15px 80px 15px 15px;
  position: relative;
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

const CountryWrapper = styled.div`
  background: ${colors.white};
  border-radius: 50%;
  height: 25px;
  width: 25px;
  position: relative;
`;

const CountryIconWrapper = styled.div`
  background: ${colors.white};
  border-radius: 50%;
  height: 20px;
  width: 20px;
  position: absolute;
  bottom: -9px;
  right: -9px;
`;

const ReadStatus = styled.div<{ read: boolean }>`
  border: 2px solid ${colors.purple};
  background: ${({ read }) => (read ? 'transparent' : colors.purple)};
  width: 15px;
  height: 15px;
  display: block;
  position: absolute;
  border-radius: 50%;
  top: 18px;
  right: 15px;
  cursor: pointer;
`;

const NotificationDate = styled(BodySmall)`
  font-size: 12px;
  color: ${colors.greyDark};
  position: absolute;
  top: 60px;
  right: 15px;
`;

interface Props {
  notification: NotificationFragment;
  hideNotifications(): void;
  refetch(): void;
}

export const NotificationItem: React.FC<Props> = ({
  notification,
  hideNotifications,
  refetch,
}) => {
  const [, setCollaboration] = useCollaborationContext();
  const history = useHistory();

  const [notificationsUpdate, { loading }] = useNotificationsUpdateMutation();

  return (
    <Wrapper
      read={notification.read}
      onClick={async () => {
        // force collaboration modal to open
        if (notification.collaborationId) {
          setCollaboration(notification.collaborationId);
        }
        // move to correct page
        history.push(notification.url);
        // mark notification as read
        await notificationsUpdate({
          variables: {
            read: [notification.id],
            unread: [],
          },
        });
        // close notification feed
        hideNotifications();
      }}
    >
      <TooltipWrapper
        text={notification.read ? 'Mark unread' : 'Mark read'}
        effect="float"
        place="bottom"
      >
        <ReadStatus
          read={notification.read}
          onClick={async (e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            if (loading) return;

            if (notification.read) {
              await notificationsUpdate({
                variables: {
                  read: [],
                  unread: [notification.id],
                },
              });
            } else {
              await notificationsUpdate({
                variables: {
                  read: [notification.id],
                  unread: [],
                },
              });
            }

            refetch();
          }}
        />
      </TooltipWrapper>

      <BodySmall style={{ marginBottom: 15 }}>
        {notification?.collaboration?.observationId ? 'Observation • ' : ''}
        {mapDBStepToReadableStep(notification.step)}
      </BodySmall>
      <Content>
        <CountryWrapper>
          <CountryIconWrapper>
            {notification.type === 'attachment' ? (
              <Icon name="Attachment" size={18} color={colors.black} />
            ) : null}
            {notification.type === 'alignment' ? (
              <Icon name="Thumbs up" size={18} color={colors.black} />
            ) : null}
            {notification.type === 'comment' ? (
              <Icon name="Comment" size={18} color={colors.black} />
            ) : null}
          </CountryIconWrapper>
          <CountryFlag user={notification.fromUser} size="sm" />
        </CountryWrapper>
        <BodySmall style={{ marginLeft: 15 }}>{notification.title}</BodySmall>
      </Content>
      <NotificationDate>
        {formatCreatedDate(parseISO(notification.createdAt))}
      </NotificationDate>
    </Wrapper>
  );
};
