import { useState, useEffect } from 'react';

import { Tooltip } from 'components/shared';
import { DrugDetailBorderWrapper } from 'containers/Strategies';
import useDesktop from 'hooks/useDesktop';

import { StrategyDefinitionHeaderProps } from '../../StrategyDefinitionHeader.types';
import { StrategyDefinitionHeaderCollaboration } from '../StrategyDefinitionHeaderCollaboration';
import { StrategyDefinitionHeaderTitle } from '../StrategyDefinitionHeaderTitle';
import { DeprecatedBulletPoints } from './DeprecatedBulletPoints';
import { StyledDrugDetailContainer } from './DeprecatedListStrategyDefinitionHeader.styles';

interface DeprecatedListProps
  extends Omit<
    StrategyDefinitionHeaderProps,
    'bulletPoints' | 'type' | 'textValue'
  > {
  bulletPoints?: string[];
  placeholder?: string;
}

export const DeprecatedListStrategyDefinitionHeader = ({
  totalUsers,
  isLead,
  title,
  collaboration,
  style = {},
  disabled,
  className,
  placeholder,
  bulletPoints: initialBulletPoints,
  tooltipMessage,
  hideAlignment,
  viewingOtherRegion,
  hideTooltip,
  handleSave,
}: DeprecatedListProps) => {
  const isDesktop = useDesktop();
  const [bulletPoints, setBulletPoints] = useState(initialBulletPoints);

  useEffect(() => {
    if (initialBulletPoints) {
      setBulletPoints(initialBulletPoints);
    }
  }, [initialBulletPoints]);

  return (
    <DrugDetailBorderWrapper
      className={className}
      style={{
        width: isDesktop ? '768px' : '100%',
        maxWidth: 'auto',
        zIndex: 9,
        position: 'relative',
        ...style,
      }}
    >
      <StyledDrugDetailContainer isMultipleLists={false} isLead={isLead}>
        <StrategyDefinitionHeaderTitle isDesktop={isDesktop} title={title} />

        <DeprecatedBulletPoints
          name="strategyDefinition"
          title={title}
          isLead={isLead}
          bulletPoints={bulletPoints}
          placeholder={placeholder}
          tooltip={
            hideTooltip
              ? ''
              : viewingOtherRegion
              ? tooltipMessage
              : disabled
              ? tooltipMessage || 'Only Leads can edit'
              : ''
          }
          onUpdate={(list: string[]) => {
            handleSave({ list });
          }}
        />

        <Tooltip id={'strategyDefinition'} effect="float" place="bottom" />

        <StrategyDefinitionHeaderCollaboration
          isDesktop={isDesktop}
          totalUsers={totalUsers}
          collaboration={collaboration}
          showAlignment={!hideAlignment}
          isMultipleLists={false}
        />
      </StyledDrugDetailContainer>
    </DrugDetailBorderWrapper>
  );
};
