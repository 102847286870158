import { useParams } from 'react-router-dom';
import {
  CriticalMetricGoalInput,
  useGetGoalTrackingsQuery,
} from 'data/graphql/generated';
import { CriticalMetricsParams } from 'components/3-5-critical-metrics/shared/shared-data-access-critical-metrics/src';
import {
  filterGlobalTrackingData,
  filterTrackingDataByCountry,
} from '../util-goal-tracking-filters';

export const useGetGoalTracking = (
  trackedGoalId: number,
  goalInput: CriticalMetricGoalInput,
  supportedCountries: string[]
) => {
  const { strategyId } = useParams<CriticalMetricsParams>();
  const {
    data,
    loading,
    error,
    startPolling,
    stopPolling,
  } = useGetGoalTrackingsQuery({
    variables: { where: { trackedGoalId, strategyId: +strategyId } },
  });

  const raw = data?.criticalMetricGoalTrackings?.items || [];

  const trackings =
    goalInput === CriticalMetricGoalInput.GlobalOnly
      ? filterGlobalTrackingData(raw)
      : filterTrackingDataByCountry(raw, supportedCountries);

  return { loading, trackings, error, startPolling, stopPolling };
};
