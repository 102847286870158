import {
  PatientJourneyColumn,
  PatientFlowBlock,
  PatientFlowValueUpsertInput,
  PatientFlowBlockFragment,
  User,
  PatientFlowValuesPayload,
  PatientFlowBlocksQuery,
  PatientJourneyColumnFragment,
} from 'data/graphql/generated';
import { Stage } from 'konva/types/Stage';
import { clamp } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { BlockTree, CountryGlobal } from 'types';
import { BlockView } from './Block';
import { BlockProps } from './Block/types';
import { RECT_WIDTH, RECT_HEIGHT } from './constants';

type Props = {
  layoutBlocks: BlockTree[];
  blocks: PatientFlowBlocksQuery['patientFlowBlocks'] | undefined | null;
  handleBlockMouseEnter: (id: number) => void;
  handleBlockMouseLeave: () => void;
  blockHover: number | boolean;
  b: BlockTree;
  userCanEdit: boolean;
  user: User | undefined;
  x: number;
  y: number;
  column: PatientJourneyColumn;
  values: PatientFlowValuesPayload | undefined | null;
  setEditing: React.Dispatch<React.SetStateAction<number | undefined>>;
  editing: number | undefined;
  getNextStage: (stageId: number) => number | undefined;
  getPreviousStage: (stageId: number) => number | undefined;
  topStage: PatientJourneyColumnFragment;
  bottomStage: PatientJourneyColumnFragment;
  changeCursor: (type: any) => void;
  stageRef: React.RefObject<Stage>;
  patientFlowBlockCreate: (
    columnId: number,
    parentId: number
  ) => Promise<PatientFlowBlock | undefined>;
  windowWidth: number;
  canvasHeight: number;
  maxScrollableX: number;
  maxScrollableY: number;
  patientFlowBlockUpdate: (
    id: number,
    patientFlowBlock: Partial<PatientFlowBlock>
  ) => void;
  setConfirmDeleteModalId: React.Dispatch<React.SetStateAction<number | null>>;
  patientFlowBlockDelete: (id: number) => void;
  upsertPatientFlowValueWithLayoutBlocks: (
    id: number | undefined,
    data: Omit<PatientFlowValueUpsertInput, 'drugId' | 'strategyId'>,
    isPercentage: boolean
  ) => Promise<void>;
  showAsPercentage: boolean;
  setDistributionModalOpen: React.Dispatch<
    React.SetStateAction<
      false | { title: string; type: 'block' | 'stage'; id: number }
    >
  >;
  globalLayoutCompleted: boolean;
  country: string;
  completedRegions: string[];
  allCountriesCompleted: boolean;
  showStarringModal: (block: PatientFlowBlock) => void;
  blocksWiderThanWindow: boolean;
  leftOffset: number;
  leftShift: number;
  getStageParentBuffer(
    block: BlockTree,
    blocks: PatientFlowBlockFragment[]
  ): number;
  showWarningModal: ({
    heading,
    message,
  }: {
    heading: string;
    message: string;
  }) => void;
  onIndependentClick(): void;
};

export function BlockContainer({
  layoutBlocks,
  blocks,
  handleBlockMouseEnter,
  handleBlockMouseLeave,
  blockHover,
  b,
  userCanEdit,
  user,
  x,
  y,
  column,
  values,
  setEditing,
  editing,
  getNextStage,
  getPreviousStage,
  topStage,
  bottomStage,
  changeCursor,
  stageRef,
  patientFlowBlockCreate,
  windowWidth,
  canvasHeight,
  maxScrollableX,
  maxScrollableY,
  patientFlowBlockUpdate,
  setConfirmDeleteModalId,
  patientFlowBlockDelete,
  upsertPatientFlowValueWithLayoutBlocks,
  showAsPercentage,
  setDistributionModalOpen,
  globalLayoutCompleted,
  country,
  completedRegions,
  allCountriesCompleted,
  showStarringModal,
  blocksWiderThanWindow,
  leftOffset,
  leftShift,
  getStageParentBuffer,
  showWarningModal,
  onIndependentClick,
}: Props) {
  const blockValue = useMemo(() => {
    return values?.items.find((value) => value?.patientFlowBlockId === b.id);
  }, [b.id, values?.items]);

  const parentIsIndependent = useCallback(() => {
    let parent = b.parent;
    let canBeIndependent = false;
    while (parent && !canBeIndependent) {
      if (parent?.isIndependent) {
        canBeIndependent = true;
      }
      parent = parent?.parent;
    }
    return canBeIndependent;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [b.parent, blocks]);

  const isIndependent = b.isIndependent || parentIsIndependent();

  const sumOfChildrenValues = useMemo(
    () =>
      isIndependent
        ? blockValue?.value || 0
        : b.children.reduce((acc, currentVal) => {
            if (currentVal.isIndependent) {
              return acc;
            }
            const childValue =
              values?.items.find(
                (value) => value?.patientFlowBlockId === currentVal.id
              )?.value || 0;

            return childValue + acc;
          }, 0),
    [b.children, blockValue?.value, isIndependent, values?.items]
  );

  const handleBlockCreate: BlockProps['patientFlowBlockCreate'] = useCallback(
    async (stageId, vals) => {
      try {
        const block = await patientFlowBlockCreate(stageId, vals);
        // Scroll screen to view block
        if (block) {
          const blockNode = stageRef?.current?.findOne(
            `#flowblock-${block.id}`
          );
          if (!blockNode) return;
          const blockPosition = blockNode.position();
          const stagePos = stageRef?.current?.getAbsolutePosition();
          if (!stagePos) return;

          //handle blocks on right
          let stagePosX = stagePos.x;

          const viewableStartX = Math.abs(stagePos.x);
          const viewableEndX = viewableStartX + windowWidth;

          const isBlockViewableOnX =
            blockPosition.x >= viewableStartX &&
            blockPosition.x + RECT_WIDTH <= viewableEndX;

          if (!isBlockViewableOnX) {
            stagePosX = -blockPosition.x + RECT_WIDTH / 2;
          }

          //handle blocks on bottom
          let stagePosY = stagePos.y;

          const viewableStartY = Math.abs(stagePos.y);
          const viewableEndY = viewableStartY + canvasHeight;

          const isBlockViewableOnY =
            blockPosition.y >= viewableStartY &&
            blockPosition.y + RECT_HEIGHT <= viewableEndY;

          if (!isBlockViewableOnY) {
            stagePosY = -blockPosition.y + RECT_HEIGHT / 2;
          }

          stageRef?.current?.position({
            x: clamp(stagePosX, maxScrollableX, 0),
            y: clamp(stagePosY, maxScrollableY, 0),
          });

          setEditing(block.id);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [
      canvasHeight,
      maxScrollableX,
      maxScrollableY,
      patientFlowBlockCreate,
      setEditing,
      stageRef,
      windowWidth,
    ]
  );

  const parentBlockValue = useMemo(
    () =>
      values?.items.find(
        (value) => value?.patientFlowBlockId === (b.default ? b.id : b.parentId)
      )?.value || 0,
    [b.default, b.id, b.parentId, values?.items]
  );

  const calculateBlockPositions = useCallback(
    (stageStartingPoint, currentBlock, updatedBlocks) => {
      return (
        stageStartingPoint +
        getStageParentBuffer(currentBlock, updatedBlocks || [])
      );
    },
    [getStageParentBuffer]
  );

  return (
    <BlockView
      showWarningModal={showWarningModal}
      blockTreeForAllBlocks={layoutBlocks}
      calculateBlockPositions={calculateBlockPositions}
      allBlocks={blocks?.items || []}
      handleBlockMouseEnter={handleBlockMouseEnter}
      handleBlockMouseLeave={handleBlockMouseLeave}
      blockHover={blockHover === b.id}
      canEditValue={userCanEdit}
      isLead={user?.role === 'LEAD'}
      block={b}
      key={b.id}
      x={x}
      y={y}
      column={column}
      patientFlowValue={blockValue}
      setEditing={setEditing}
      sumOfChildrenValues={sumOfChildrenValues}
      parentBlockValue={parentBlockValue}
      forceEdit={editing === b.id}
      nextStageId={getNextStage(b.columnId)}
      previousStageId={getPreviousStage(b.columnId)}
      topStage={topStage}
      bottomStage={bottomStage}
      changeCursor={changeCursor}
      stageRef={stageRef}
      patientFlowBlockCreate={handleBlockCreate}
      patientFlowBlockUpdate={patientFlowBlockUpdate}
      setConfirmDeleteModalId={setConfirmDeleteModalId}
      patientFlowBlockDelete={patientFlowBlockDelete}
      upsertPatientFlowValue={upsertPatientFlowValueWithLayoutBlocks}
      showPercentage={showAsPercentage}
      onDistributionClick={setDistributionModalOpen}
      globalLayoutCompleted={globalLayoutCompleted}
      country={country as CountryGlobal}
      currentCountryIsComplete={completedRegions.includes(country)}
      allCountriesCompleted={allCountriesCompleted}
      showStarringModal={(blockId) => showStarringModal(blockId)}
      leftOffset={blocksWiderThanWindow ? leftOffset : leftShift}
      onIndependentClick={onIndependentClick}
    />
  );
}
