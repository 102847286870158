import React from 'react';
import { colors } from 'constants/index';
import { PostItCardType } from 'data/graphql/generated';
import styled, { css } from 'styled-components';
import { IconName } from 'types';
import { uid } from 'uid';
import { getIconFromNoteType } from 'utils/getIconFromNoteType';
import { getTitleFromNoteType } from 'utils/getTitleFromNoteType';
import { ButtonLabel, Icon } from '.';
import { Tooltip } from './Tooltip';

interface ButtonNoteProps {
  text?: string;
  tooltip?: string;
  level: PostItCardType;
  iconName?: IconName;
  onClick?(): void;
  disabled?: boolean;
  className?: string;
}

const TooltipWrapper = styled.div`
  display: block;
  border: none;
  height: 30px;
`;
function getLevelStyle(level: PostItCardType) {
  switch (level) {
    case 'advantage':
      return css`
        background: ${colors.green30};
        &:hover {
          background: ${colors.green40};
        }
      `;
    case 'limitation':
    case PostItCardType.Hurdle:
      return css`
        background: ${colors.red20};
        &:hover {
          background: ${colors.red25};
        }
      `;
    case 'trigger':
    case 'feelings':
      return css`
        background: ${colors.pink20};
        &:hover {
          background: ${colors.pink25};
        }
      `;
    case 'unexpected':
      return css`
        background: ${colors.purple20};
        &:hover {
          background: ${colors.purple23};
        }
      `;
    case 'adaptation':
      return css`
        background: ${colors.orange20};
        &:hover {
          background: ${colors.orange25};
        }
      `;
    case 'pattern':
    case 'gap':
    case 'leadIndicator':
    case 'thoughts':
      return css`
        background: ${colors.blue20};
        &:hover {
          background: ${colors.blue40};
        }
      `;
    case 'lagIndicator':
      return css`
        background: ${colors.orange20};
        &:hover {
          background: ${colors.orange25};
        }
      `;

    case 'other':
      return css`
        background: ${colors.yellow75};
        border: 0.5px solid rgba(20, 20, 39, 0.3);
        &:hover {
          background: ${colors.yellow};
          border: 0.5px solid rgba(20, 20, 39, 0.3);
        }
      `;
  }
}

const Wrapper = styled.button<ButtonNoteProps>`
  cursor: pointer;
  user-select: none;
  display: inline-flex;
  align-items: center;
  height: 30px;
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 5px 15px 5px 0px;
  color: ${colors.black};

  font-family: ABCFavorit;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  transition: all 0.3s ease;

  ${({ level }) => getLevelStyle(level)}

  &:focus {
    outline: none;
  }

  p {
    white-space: nowrap;
    margin-left: 5px;
  }
`;

const DotWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  color: ${colors.black70};
  font-size: 10px;
  margin-top: 3px;
`;

export const ButtonNote = ({
  text,
  level = PostItCardType.Adaptation,
  tooltip = '',
  iconName,
  onClick,
  // disabled = false,
  className,
}: ButtonNoteProps) => {
  const key = uid();

  const icon = getIconFromNoteType(level);
  const levelText = getTitleFromNoteType(level);

  return (
    <TooltipWrapper data-for={key} data-tip={tooltip} tabIndex={0}>
      <Wrapper level={level} onClick={onClick} className={className}>
        {level === 'other' ? (
          <DotWrapper>•</DotWrapper>
        ) : (
          <Icon name={iconName || icon} size={30} color={colors.black70} />
        )}
        <ButtonLabel>{text || levelText}</ButtonLabel>
      </Wrapper>
      <Tooltip id={key} />
    </TooltipWrapper>
  );
};
