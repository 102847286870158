import React from 'react';

import { DeleteButton } from '../ui/DeleteButton';
import { DeleteConfirmation } from '../ui/DeleteConfirmation';

interface DeleteGoalsTableRowProps {
  canEdit: boolean;
  isDeleting: boolean;
  setIsDeleting: (isDeleting: boolean) => void;
  onDelete: () => void;
}

export const DeleteGoalsTableRow: React.FC<DeleteGoalsTableRowProps> = ({
  canEdit,
  isDeleting,
  setIsDeleting,
  onDelete,
}) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const handleDeleteCancel = () => setShowDeleteConfirm(false);
  const handleDeleteConfirm = async () => {
    if (isDeleting) {
      return;
    }

    setIsDeleting(true);
    onDelete();

    setShowDeleteConfirm(false);
    setIsDeleting(false);
  };

  return (
    <>
      <DeleteButton
        className="Row__deleteIcon"
        isHidden={!canEdit}
        onClick={() => {
          setShowDeleteConfirm(true);
        }}
      />
      <DeleteConfirmation
        isHidden={!showDeleteConfirm}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />
    </>
  );
};
