import React from 'react';
import styled from 'styled-components/macro';
import { BodySmall } from '.';
import { GradientCard } from './GradientCard';

const Wrapper = styled.div``;

const KeyIssuesDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

interface Props {
  title: string;
  data: string;
}

export const KeyIssuesSummaryCard: React.FC<Props> = ({
  title,
  data
}) => {
  return (
    <Wrapper>
      <GradientCard>
        <KeyIssuesDetails>
          <BodySmall style={{ fontWeight: 500 }}>
            {data}
          </BodySmall>
        </KeyIssuesDetails>
      </GradientCard>
    </Wrapper>
  );
};
