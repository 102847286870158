import { PatientJourneyColumn } from 'data/graphql/generated';
import Konva from 'konva';
import { Layer } from 'konva/types/Layer';
import { Stage } from 'konva/types/Stage';

export function makeRenderStageTextInput({
  removeByClassName,
  layerColumnRef,
  stageRef,
  updateColumn,
  classnamePrefix,
}: {
  removeByClassName(className: string): void;
  layerColumnRef: React.MutableRefObject<Layer | null>;
  stageRef: React.MutableRefObject<Stage | null>;
  updateColumn(
    data: any & {
      id: number;
    }
  ): void;
  classnamePrefix: string;
}) {
  return function renderStageTextInput(
    column: PatientJourneyColumn,
    width: number,
    callback: () => void
  ) {
    if (
      !layerColumnRef?.current ||
      !stageRef?.current ||
      typeof layerColumnRef?.current === 'string' ||
      typeof stageRef?.current === 'string'
    ) {
      return;
    }
    const columnId = column.id;

    // check if there is already a textarea created for this block
    removeByClassName(`${classnamePrefix}-textarea`);
    removeByClassName(`${classnamePrefix}-background`);

    const columnNode = stageRef.current.findOne(
      `#${classnamePrefix}-title-${columnId}`
    ) as Konva.Text;
    const textPosition = columnNode.getAbsolutePosition();
    const stageBox = stageRef.current.container().getBoundingClientRect();

    const areaPosition = {
      x: stageBox.left + textPosition.x,
      y: stageBox.top + textPosition.y,
    };

    // create textarea and style it
    const textarea = document.createElement('textarea');
    document.body.appendChild(textarea);
    textarea.value = columnNode.text();
    textarea.maxLength = 30;
    textarea.className = `${classnamePrefix}-textarea ${classnamePrefix}-textarea-${columnId}`;
    textarea.style.top =
      areaPosition.y + document.documentElement.scrollTop + 'px';
    textarea.style.left =
      areaPosition.x + document.documentElement.scrollLeft + 'px';
    textarea.style.width = width + 'px';

    textarea.style.textAlign = 'left';

    textarea.focus();
    textarea.select();

    textarea.addEventListener('keydown', (e) => {
      // hide on enter
      if (e.keyCode === 13) {
        // update text node
        if (!textarea.value) {
          columnNode.text(column.defaultName);
          removeColumnTextarea();
          updateColumn({
            ...column,
            name: column.defaultName,
          });
          callback();
        } else {
          columnNode.text(textarea.value);

          removeColumnTextarea();
          updateColumn({
            ...column,
            name: textarea.value,
          });
          callback();
        }
      }
    });

    textarea.addEventListener('input', (e) => {
      //Resize text area element
      columnNode.text(textarea.value);
      textarea.style.width = columnNode.width() + 'px';
    });

    // background to capture outside click
    const textareaBG = document.createElement('div');
    document.body.appendChild(textareaBG);
    textareaBG.className = `${classnamePrefix}-background ${classnamePrefix}-background-${columnId}`;
    textareaBG.addEventListener('click', () => {
      // update text node
      if (!textarea.value) {
        columnNode.text(column.defaultName);
        removeColumnTextarea();
        updateColumn({
          ...column,
          name: column.defaultName,
        });
        callback();
      } else {
        columnNode.text(textarea.value);

        removeColumnTextarea();
        updateColumn({
          ...column,
          name: textarea.value,
        });
        callback();
      }
    });

    function removeColumnTextarea() {
      document.body.removeChild(textarea);
      document.body.removeChild(textareaBG);
      columnNode.show();
      layerColumnRef?.current?.draw();
    }
  };
}
