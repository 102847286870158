import { useState, useEffect } from 'react';
import { getWindowWidth } from './getWindowWidth';

export function useWidth() {
  let [width, setWidth] = useState(getWindowWidth());

  useEffect(() => {
    const resizeListener = () => {
      setTimeout(() => setWidth(getWindowWidth()), 1);
    };
    window.addEventListener('resize', resizeListener);
    window.addEventListener('orientationchange', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
      window.removeEventListener('orientationchange', resizeListener);
    };
  }, []);

  return width;
}
