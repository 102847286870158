import React, { useEffect, useRef, useState } from 'react';
import Konva from 'konva';
import { Stage } from 'konva/types/Stage';
import { Group, Rect, Text, Label, Tag } from 'react-konva';
import { PatientJourneyColumn } from 'data/graphql/generated';
import { STAGE_HEIGHT } from './constants';
import { CountryGlobal, Cursor } from 'types';
import { DistributionIcon } from './DistributionIcon';
import { KonvaEventObject } from 'konva/types/Node';
import { Portal } from 'react-konva-utils';

interface Props {
  stage: PatientJourneyColumn;
  canvasWidth: number;
  changeCursor(cursor: Cursor): void;
  showColumnTextarea: (
    column: PatientJourneyColumn,
    width: number,
    callback: () => void
  ) => void;
  setDistributionModalOpen: React.Dispatch<
    React.SetStateAction<
      | false
      | {
          title: string;
          type: 'block' | 'stage';
          id: number;
        }
    >
  >;
  stageHeights: any;
  stageStartingPoints: number[];
  country: CountryGlobal;
  globalLayoutCompleted: boolean;
  stageRef: any;
}

export const StageView: React.FC<Props> = ({
  stage,
  canvasWidth,
  changeCursor,
  showColumnTextarea,
  setDistributionModalOpen,
  stageHeights,
  stageStartingPoints,
  country,
  globalLayoutCompleted,
  stageRef,
}) => {
  const { id, name, idx } = stage;
  const textRef = useRef<Konva.Text>(null!);
  const [hover, setHover] = useState(false);
  const textHover = useRef(false);
  const distributionIconHover = useRef(false);

  const [showText, setshowText] = useState(true);

  const height = stageHeights[stage.idx] || STAGE_HEIGHT;
  const [startingYPos, setStartingYPos] = useState(0);

  const [offsetParentPos, setOffsetParentPos] = useState({ x: 0, y: 0 });

  let yPos = startingYPos;

  if (-offsetParentPos.y > startingYPos) {
    yPos = -offsetParentPos.y;
  }

  useEffect(() => {
    setStartingYPos(stageStartingPoints[stage.idx]);
  }, [stage.idx, stageStartingPoints]);

  useEffect(() => {
    const stage = stageRef.current as Stage | undefined;
    if (!stage) return;

    function handleDrag(e: KonvaEventObject<any>) {
      setOffsetParentPos({
        x: e.currentTarget.attrs.x,
        y: e.currentTarget.attrs.y,
      });
    }

    if (offsetParentPos.x === 0 && offsetParentPos.y === 0) {
      setOffsetParentPos({
        x: stage.attrs.x,
        y: stage.attrs.y,
      });
    }

    function handleChange(e: KonvaEventObject<any>) {
      if (offsetParentPos.x === 0 && offsetParentPos.y === 0) {
        setOffsetParentPos({
          x: e.currentTarget.attrs.x,
          y: e.currentTarget.attrs.y,
        });
      }
    }

    stage?.on('dragmove', handleDrag);
    stage?.on('xChange', handleChange);
    return () => {
      stage?.off('dragmove', handleDrag);
      stage?.off('xChange', handleChange);
    };

    // NOTE if offsetParentPos is include performance becomes choppy
    // eslint-disable-next-line
  }, [stageRef]);

  return (
    <>
      <Group x={0} y={yPos} key={id} id={`stage-${idx}`} dataId={id}>
        <Rect
          height={height}
          id={`stage-${idx}-bg`}
          y={0}
          width={canvasWidth}
          sides={4}
          fill="#F8F8F9"
          strokeWidth={2}
        />
        <Rect x={0} y={0} height={2} width={canvasWidth} fill="#E7E7E9" />

        <Portal selector=".top-layer" enabled={hover ? true : false}>
          <Label
            x={-offsetParentPos.x}
            y={hover ? yPos : 0}
            visible={showText}
            onMouseEnter={() => {
              changeCursor('text');
              setHover(true);
              textHover.current = true;
            }}
            onMouseLeave={() => {
              textHover.current = false;
              changeCursor('default');
              setHover(false);
            }}
          >
            <Rect
              x={0}
              y={0}
              height={30}
              width={textRef?.current?.textWidth + 45 || 100}
            />
            <Tag
              //@ts-ignore
              cornerRadius={[0, 0, 5, 0]}
              fill="#E7E7E9"
            />
            <Text
              ref={textRef}
              id={`stage-title-${id}`}
              name="stage-title"
              text={name}
              fontFamily="ABCFavorit"
              lineHeight={1.25}
              fontSize={16}
              fontWeight={400}
              align="center"
              padding={5}
              y={5}
              x={5}
              onClick={() => {
                setshowText(false);

                showColumnTextarea(
                  stage,
                  textRef.current.textWidth,
                  //show Text
                  () => {
                    setshowText(true);
                  }
                );
              }}
            />

            {hover ? (
              <DistributionIcon
                changeCursor={changeCursor}
                xPosition={textRef.current.textWidth + 30}
                yPosition={15}
                onClick={() => {
                  setDistributionModalOpen({ title: name, type: 'stage', id });
                }}
                onEnter={() => {
                  distributionIconHover.current = true;
                }}
                onLeave={() => {
                  distributionIconHover.current = false;
                }}
              />
            ) : null}
          </Label>
        </Portal>
      </Group>
    </>
  );
};
