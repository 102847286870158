import React, { useEffect, useState } from 'react';
import { Group, Rect, Text } from 'react-konva';
import {
  PatientJourneyBlockFragment,
  PatientJourneyColumn,
  PatientJourneyConnection,
} from 'data/graphql/generated';
import { Cursor } from 'types';
import { Portal } from 'react-konva-utils';
import { Stage } from 'konva/types/Stage';
import { KonvaEventObject } from 'konva/types/Node';
import { COLUMN_HEIGHT } from 'components/PatientJourney/constants';
import {
  makeShowColumnTextarea,
  adjustForScreen,
  columnUpdateWidth,
  snapToGrid,
  hideBlockLayers,
  showBlockLayers,
} from '../utils';

interface Props {
  column: PatientJourneyColumn;
  columns: PatientJourneyColumn[];
  connections: PatientJourneyConnection[];
  blocks: PatientJourneyBlockFragment[];
  columnStartingPoints: number[];
  columnLowestValidXPoints: number[];
  changeCursor(cursor: Cursor): void;
  activeColumn: number | undefined;
  isDrag: boolean;
  stageRef: any;
  updateColumn(column: Partial<PatientJourneyColumn>): void;
  updateColumnLocal(column: Partial<PatientJourneyColumn>): void;
  updateMany: any;
  setDisableActions(val: boolean): any;
  setCreate: (
    val?: { x: number; y: number; column: number } | undefined
  ) => void;
}

export const Column: React.FC<Props> = ({
  column,
  columnStartingPoints,
  columnLowestValidXPoints,
  changeCursor,
  activeColumn,
  isDrag,
  stageRef,
  blocks,
  columns,
  connections,
  updateMany,
  updateColumn,
  setDisableActions,
  setCreate,
}) => {
  const { name, idx, width, id } = column;
  const x = columnStartingPoints[idx];
  const [offsetParentYPosition, setOffsetParentYPosition] = useState(2);
  const showColumnTextarea = makeShowColumnTextarea({
    stageRef,
    updateColumn,
    classnamePrefix: 'column',
  });

  useEffect(() => {
    const stage = stageRef.current as Stage | undefined;
    if (!stage) return;

    function handleDrag(e: KonvaEventObject<any>) {
      setOffsetParentYPosition(e.currentTarget.attrs.y);
    }
    stage?.on('dragmove', handleDrag);
    return () => {
      stage?.off('dragmove', handleDrag);
    };
  }, [stageRef]);

  return (
    <Group
      x={x}
      y={0}
      key={`column-${id}`}
      id={`column-${id}`}
      name="column"
      onMouseDown={() => {
        setCreate();
      }}
    >
      <Rect
        id={`column-${id}-bg`}
        name="column-bg"
        width={width - 2}
        y={0}
        height={COLUMN_HEIGHT}
        cornerRadius={5}
        sides={4}
        fill={'#F8F8F9'}
        strokeWidth={2}
        stroke={activeColumn === id ? '#00A1D3' : 'transparent'}
      />

      <Portal selector=".top-layer" enabled={true}>
        <Group
          id={`column-${id}-header-group`}
          x={x}
          y={-offsetParentYPosition}
          onClick={() => {
            if (isDrag) return;
            showColumnTextarea(column, width);
          }}
          onMouseEnter={() => changeCursor('text')}
          onMouseLeave={() => changeCursor('default')}
        >
          <Rect
            id={`column-${id}-header`}
            width={width - 2}
            y={0}
            height={37}
            fill={'#F8F8F9'}
            strokeWidth={2}
            stroke={activeColumn === id ? '#00A1D3' : 'transparent'}
            cornerRadius={[5, 5, 0, 0]}
          />
          <Rect
            id={`column-${id}-header-bottom-line`}
            width={width}
            y={37}
            height={0}
            fill={'#F8F8F9'}
            strokeWidth={2}
            stroke={'#dbdbdb'}
          />
          <Text
            id={`column-title-${id}`}
            name="column-title"
            text={name}
            fontFamily="ABCFavorit"
            fontSize={16}
            fontWeight={500}
            align="center"
            width={width - 2}
            y={10}
          />
        </Group>
      </Portal>
      <Rect
        id={`column-${id}-resize`}
        fill="transparent"
        width={10}
        height={1200}
        y={0}
        x={width - 10}
        draggable={!isDrag}
        dragBoundFunc={(pos) => {
          return {
            x: pos.x,
            y: 0,
          };
        }}
        onMouseEnter={() => changeCursor('col-resize')}
        onMouseLeave={() => changeCursor('default')}
        onDragStart={() => {
          changeCursor('col-resize');

          hideBlockLayers(stageRef, blocks);

          stageRef.current.draw();
        }}
        onDragMove={async (e) => {
          changeCursor('col-resize');
          const dragPos = e.target.getAbsolutePosition();
          let targetX =
            adjustForScreen(dragPos.x, stageRef.current.attrs.x) + 10;

          if (targetX <= columnLowestValidXPoints[idx]) {
            targetX = columnLowestValidXPoints[idx];
          }

          const newWidth = targetX - x;

          // update columns
          const xDiff = newWidth - width;
          // update column's width
          const node = stageRef.current.findOne(`#column-${column.id}-bg`);
          if (node) node.attrs.width = newWidth - 2;
          const headerNode = stageRef.current.findOne(
            `#column-${column.id}-header`
          );
          if (headerNode) {
            headerNode.attrs.width = newWidth - 1;
          }
          const lineNode = stageRef.current.findOne(
            `#column-${column.id}-header-bottom-line`
          );
          if (lineNode) lineNode.attrs.width = newWidth - 1;
          const textNode = stageRef.current.findOne(
            `#column-title-${column.id}`
          );
          if (textNode) textNode.attrs.width = newWidth - 2;
          // update other columns' x
          columns.forEach((column) => {
            if (column.idx > idx) {
              stageRef.current.findOne(`#column-${column.id}`).position({
                x: columnStartingPoints[column.idx] + xDiff,
              });
              const header = stageRef.current.findOne(
                `#column-${column.id}-header-group`
              );
              header.position({
                x: columnStartingPoints[column.idx] + xDiff,
                y: header.attrs.y,
              });
            }
          });
          const toUpdate = await columnUpdateWidth({
            column,
            newWidth,
            blocks,
            columns,
            connections,
          });
          updateKonva(stageRef, toUpdate.blocks, toUpdate.connections);
        }}
        onDragEnd={async (e) => {
          setDisableActions(true);
          changeCursor('default');
          const dragPos = e.target.getAbsolutePosition();
          let targetX =
            adjustForScreen(dragPos.x, stageRef.current.attrs.x) + 10;

          if (targetX <= columnLowestValidXPoints[idx]) {
            targetX = columnLowestValidXPoints[idx];
          }

          const newWidth = targetX - x;

          // save updates
          const toUpdate = await columnUpdateWidth({
            column,
            newWidth,
            blocks,
            columns,
            connections,
          });

          // save updates
          await updateMany(toUpdate);

          // reset column to original place
          e.target.position({
            x: newWidth - 10,
            y: 0,
          });

          setDisableActions(false);

          showBlockLayers(stageRef, blocks);

          stageRef.current.batchDraw();
        }}
      />
    </Group>
  );
};

function updateKonva(
  stageRef: any,
  blocks: PatientJourneyBlockFragment[],
  connections: PatientJourneyConnection[]
) {
  blocks.forEach((block) => {
    const x = snapToGrid(block.x);
    const y = snapToGrid(block.y);

    stageRef.current.findOne(`#block-${block.id}`).position({
      x,
      y,
    });
  });

  connections.forEach((connection) => {
    const node = stageRef.current.findOne(`#connection-${connection.id}`);
    node.attrs.points = connection.path;

    const dotNode = stageRef.current.findOne(
      `#connection-dot-${connection.fromBlockId}`
    );
    if (dotNode) {
      dotNode.position({
        x: connection.path[0],
        y: connection.path[1],
      });
    }
  });

  stageRef.current.batchDraw();
}
