import { EmptyState, Subtitle2 } from 'components/shared';
import { colors } from 'constants/colors';
import React from 'react';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { BudgetBar } from './BudgetBar';
import { Caption } from 'components/shared/TextStyles';
import { budgetChartMaxWidth } from 'constants/index';

const Wrapper = styled.div`
  background: ${colors.white};
  padding: 15px;
  width: 100%;
  max-width: ${budgetChartMaxWidth}px;

  @media ${device.tabletMin} {
    border: 1px solid ${colors.black10};
    border-radius: 5px;
  }
`;

const BudgetsList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledEmptyState = styled(EmptyState)`
  height: auto;
  padding: 37.5px;
  border-radius: 5px;
`;

export interface Budget {
  subtitle: string | undefined;
  amount: number;
  id?: number;
}

interface Props {
  title: string;
  budgets: Budget[];
  currency: string;
  emptyStateTitle?: string;
  className?: string;
}

export const BudgetChart: React.FC<Props> = ({
  title,
  budgets,
  currency,
  emptyStateTitle,
  className,
}) => {
  const maxValue = budgets.reduce(
    (max, curr) => (curr.amount > max ? curr.amount : max),
    0
  );
  const displayEmptyState =
    !budgets.length || budgets.every((b) => b.amount === 0);

  return (
    <Wrapper className={className}>
      <Subtitle2 style={{ marginBottom: 20 }}>{title}</Subtitle2>
      {displayEmptyState ? (
        <BudgetChartEmptyState title={emptyStateTitle || ''} />
      ) : (
        <BudgetsList>
          {budgets.map((b) => (
            <div key={b.subtitle}>
              <BudgetBar
                maxValue={maxValue}
                value={b.amount}
                currency={currency}
              />
              <Caption color={colors.greyDark} style={{ marginTop: 2 }}>
                {b.subtitle}
              </Caption>
            </div>
          ))}
        </BudgetsList>
      )}
    </Wrapper>
  );
};

const BudgetChartEmptyState: React.FC<{ title: string }> = ({ title }) => (
  <StyledEmptyState includeIcon>
    <Subtitle2 color={colors.greyDark}>{title}</Subtitle2>
  </StyledEmptyState>
);
