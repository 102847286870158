import { LongTermStrategyTimelineFragment } from 'data/graphql/generated';
import { differenceInCalendarYears, differenceInMonths } from 'date-fns';
import { DIVIDER_WIDTH } from './LongTermStrategySummaryTimeline';

type Props = {
  dates: Pick<
    LongTermStrategyTimelineFragment,
    'launch' | 'approval' | 'lossOfExclusivity' | 'peakSales'
  >;
  containerDimensions: DOMRect | undefined;
  enableGraphTransitions: boolean;
};

const ChartGraph = ({
  dates,
  containerDimensions,
  enableGraphTransitions,
}: Props) => {
  const containerWidth = containerDimensions?.width || 0;
  const containerHeight = containerDimensions?.height || 0;
  const yearWidth = (containerWidth - DIVIDER_WIDTH * 3) / 11;
  const monthWidth = yearWidth / 12;

  const { approval, launch, peakSales, lossOfExclusivity } = dates;

  if (
    !containerWidth ||
    typeof approval !== 'string' ||
    typeof launch !== 'string' ||
    typeof peakSales !== 'string' ||
    typeof lossOfExclusivity !== 'string'
  ) {
    return null;
  }

  const launchMonthDifference =
    differenceInMonths(+launch, +approval) * monthWidth;
  const peakMonthDifference =
    differenceInMonths(+peakSales, +approval) * monthWidth;
  const lossMonthDifference =
    differenceInMonths(+lossOfExclusivity, +approval) * monthWidth;

  const launchYearDifference = differenceInCalendarYears(+launch, +approval);
  const peakYearDifference = differenceInCalendarYears(+peakSales, +approval);
  const lossYearDifference = differenceInCalendarYears(
    +lossOfExclusivity,
    +approval
  );

  const columnOffset = (yearDifference: number) =>
    yearDifference > 7
      ? DIVIDER_WIDTH * 3
      : yearDifference > 3
      ? DIVIDER_WIDTH * 2
      : yearDifference > 0
      ? DIVIDER_WIDTH
      : 0;

  const launchValue =
    launchMonthDifference + columnOffset(launchYearDifference);
  const peakValue = peakMonthDifference + columnOffset(peakYearDifference);
  const lossValue = lossMonthDifference + columnOffset(lossYearDifference);

  return (
    <svg
      width="100%"
      height="100%"
      viewBox={`0 0 ${containerWidth} ${containerHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio={'none'}
    >
      {/* Math max and mins clamp the bezier curve so you don't get odd looking tangents  */}
      <path
        style={
          enableGraphTransitions ? { transition: 'all 0.3s ease-out' } : {}
        }
        opacity="0.4"
        d={`
        m ${launchValue} ${containerHeight} 
        L ${containerWidth} ${containerHeight} 
        V 549.4
        H ${lossValue + yearWidth * 2}
        C ${lossValue + 62} 549.4 ${lossValue + 62} 0 ${lossValue} 0 
        L ${peakValue} 0 
        C ${Math.max(Math.min(peakValue, launchValue), peakValue - 270)} 0 
        ${Math.max(launchValue, peakValue - 270)}
          511.5 ${launchValue} ${containerHeight} 
        z`}
        fill="url(#paint0_linear_15602_270877)"
        fillOpacity="0.4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15602_270877"
          x1="203.565"
          y1="544.873"
          x2="1522.96"
          y2="566.199"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0524196" stopColor="#078766" />
          <stop offset="0.47783" stopColor="#7800FF" />
          <stop offset="0.711245" stopColor="#E51D5B" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ChartGraph;
