export default function getInitials(fullName: string | null | undefined) {
  if (!fullName) return '';

  const separated = fullName
    .trim()
    .split(/[ -]/)
    .filter((char) => char !== '');

  if (separated.length <= 3) {
    return separated
      .map((word) => (word[0].toUpperCase() ? word[0].toUpperCase() : word[0]))
      .join('');
  } else {
    return [
      separated[0],
      separated[separated.length - 2],
      separated[separated.length - 1],
    ]
      .map((word) => word[0].toUpperCase())
      .join('');
  }
}
