import { colors } from 'constants/index';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';

export const StepHeaderBar = styled.div`
  width: 100%;
  margin-top: 110px;
  display: grid;
  align-items: center;
  gap: 0px 30px;
  grid-auto-flow: column;
  justify-content: start;
  padding: 0px 15px;
  background: ${colors.white};
  border-bottom: 0.5px solid ${colors.black30};

  @media ${device.mobile} {
    padding: 0px 5px;
  }

  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      circle at bottom left,
      rgba(75, 126, 235, 1) 40%,
      rgba(83, 123, 234, 1) 0%,
      rgba(234, 78, 209, 1) 100%
    );
    opacity: 0.1;
    pointer-events: none;
  }

  overflow: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
