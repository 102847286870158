import React, { useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import { Loading } from 'components/Loading';
import { BodySmall, Subtitle2, Icon } from 'components/shared';
import {
  Sort,
  usePatientJourneyColumnsForBubbleSummaryQuery,
  useStrategyQuery,
} from 'data/graphql/generated';
import { polling } from '../../constants';
import { colors } from 'constants/colors';
import {
  BubbleTwoScrollableContent,
  Column,
  EmptyStateText,
  LeveragePointIndicatorComponent,
  StrategicImperative,
  DownloadButton,
} from './BubbleSummary';
import { CSSTransition } from 'react-transition-group';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import exportSummaryTwo from 'utils/export/summaryTwo';

interface Props {
  strategyId: number;
  showOptions: boolean;
  dataLoaded(): void;
}

const ColumnTitle = styled.div`
  align-self: center;
  text-align: center;
  margin-bottom: 15px;
`;

const LeveragePoint = styled.div`
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const LeveragePointsEmptyState = styled.div`
  width: 100%;
  border-radius: 5px;
  border: 1px solid ${colors.greyMedium};
  background: ${colors.yellow20};
  padding: 30px 15px;
`;

const visible = css`
  opacity: 1;
  max-height: 10000px;
`;

const hidden = css`
  opacity: 0;
  max-height: 1px;
`;

const transition = css`
  transition: opacity 0.3s, max-height 0.3s;
`;

const StrategicPossibilitiesList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  .other-options-enter {
    ${hidden}
  }
  .other-options-enter-active {
    ${visible}
    ${transition}
  }
  .other-options-exit {
    ${visible}
  }
  .other-options-exit-active {
    ${hidden}
    ${transition}
  }
`;

const OtherOptions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 15px 5px;
  background: ${colors.white};
  border: 1px solid ${colors.greyMedium};
  border-radius: 5px;
  max-height: 1000px;
`;

const OtherOptionsFields = styled.ul`
  margin: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-top: 1px solid ${colors.greyLight};
`;

export const BubbleSummaryTwoContent: React.FC<Props> = ({
  strategyId,
  showOptions,
  dataLoaded,
}) => {
  const {
    data,
    loading,
    error,
    startPolling,
    stopPolling,
  } = usePatientJourneyColumnsForBubbleSummaryQuery({
    variables: { where: { strategyId }, orderBy: { idx: Sort.Asc } },
    fetchPolicy: 'network-only',
  });

  const {
    data: strategyData,
    loading: strategyLoading,
    error: strategyError,
  } = useStrategyQuery({
    variables: { id: Number(strategyId) },
  });

  const columns = data?.patientJourneyColumns.items;

  useEffect(() => {
    if (!loading) {
      dataLoaded();
    }
  }, [loading, dataLoaded]);

  useEffect(() => {
    startPolling(polling.default);

    return () => stopPolling();
  }, [startPolling, stopPolling]);

  if (loading || strategyLoading) {
    return (
      <Loading style={{ margin: '50px auto auto' }} textColor={colors.white} />
    );
  }

  const columnData = (columns || []).map((c) => {
    const leveragePoints = c?.PatientFlowBlock?.filter(
      (pfb) =>
        !!pfb?.stars?.some(
          (star) => star.global && !!star.stakeholderDefinitions.length
        )
    );

    return {
      ...c,
      leveragePoints,
    };
  });

  return (
    <>
      <DownloadButton
        onClick={() =>
          exportSummaryTwo({
            brand: strategyData?.strategy?.drug?.name || '',
            startYear: strategyData?.strategy?.startYear || 0,
            endYear: strategyData?.strategy?.endYear || 0,
            data: columnData,
          })
        }
      >
        <Icon name="Download" size={18} color={colors.black} />
      </DownloadButton>

      <ErrorWrapper
        errors={[error, strategyError]}
        isLoading={loading || strategyLoading}
        dataMissing={!columns}
      >
        {columnData?.map((c) => {
          return (
            <Column key={c.id}>
              <ColumnTitle>
                <Subtitle2>{c.name}</Subtitle2>
              </ColumnTitle>
              {c.leveragePoints?.length ? (
                <BubbleTwoScrollableContent>
                  {c.leveragePoints.map((lp) => {
                    const imperatives = lp.strategicPossibilities.filter(
                      (sp) => !!sp.imperative
                    );
                    const otherOptions = lp.strategicPossibilities.filter(
                      (sp) =>
                        !sp.imperative &&
                        (!!sp.behaviourToDrive || !!sp.driverOfTheBehaviour)
                    );

                    return (
                      <LeveragePoint key={lp.id}>
                        <div style={{ marginBottom: 3 }}>
                          <LeveragePointIndicatorComponent />
                        </div>
                        <ColumnTitle>{lp?.text}</ColumnTitle>

                        {imperatives.length || otherOptions.length ? (
                          <StrategicPossibilitiesList>
                            {!imperatives.length && !!otherOptions.length && (
                              <CSSTransition
                                timeout={400}
                                in={!showOptions}
                                classNames="other-options"
                                unmountOnExit
                                component={null}
                              >
                                <LeveragePointsEmptyState>
                                  <Subtitle2
                                    color={colors.greyDark}
                                    style={{ textAlign: 'center' }}
                                  >
                                    No options chosen
                                  </Subtitle2>
                                </LeveragePointsEmptyState>
                              </CSSTransition>
                            )}

                            {imperatives.map((imperative) => (
                              <StrategicImperative key={imperative.id}>
                                <div className="content">
                                  <BodySmall style={{ fontWeight: 500 }}>
                                    {imperative.name}
                                  </BodySmall>
                                  {imperative.behaviourToDrive && (
                                    <BodySmall>
                                      {imperative.behaviourToDrive}
                                    </BodySmall>
                                  )}
                                  {imperative.driverOfTheBehaviour && (
                                    <BodySmall>
                                      {imperative.driverOfTheBehaviour}
                                    </BodySmall>
                                  )}
                                </div>
                              </StrategicImperative>
                            ))}

                            {!!otherOptions.length && (
                              <CSSTransition
                                timeout={300}
                                in={showOptions}
                                classNames="other-options"
                                unmountOnExit
                                component={null}
                              >
                                <OtherOptions>
                                  <BodySmall
                                    style={{
                                      textAlign: 'center',
                                      paddingBottom: 10,
                                    }}
                                  >
                                    {imperatives.length
                                      ? 'Other options considered'
                                      : 'Options considered'}
                                  </BodySmall>
                                  {otherOptions.map((o) => (
                                    <OtherOptionsFields key={o.id}>
                                      {o.behaviourToDrive && (
                                        <BodySmall color={colors.greyDark}>
                                          {o.behaviourToDrive}
                                        </BodySmall>
                                      )}
                                      {o.driverOfTheBehaviour && (
                                        <BodySmall color={colors.greyDark}>
                                          {o.driverOfTheBehaviour}
                                        </BodySmall>
                                      )}
                                    </OtherOptionsFields>
                                  ))}
                                </OtherOptions>
                              </CSSTransition>
                            )}
                          </StrategicPossibilitiesList>
                        ) : (
                          <LeveragePointsEmptyState>
                            <Subtitle2
                              color={colors.greyDark}
                              style={{ textAlign: 'center' }}
                            >
                              No options considered yet
                            </Subtitle2>
                          </LeveragePointsEmptyState>
                        )}
                      </LeveragePoint>
                    );
                  })}
                </BubbleTwoScrollableContent>
              ) : (
                <EmptyStateText>No leverage points</EmptyStateText>
              )}
            </Column>
          );
        })}
      </ErrorWrapper>
    </>
  );
};
