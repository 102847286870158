import { PostItsEmpty } from 'components/PostItsEmpty';
import { BodySmall, Caption } from 'components/shared';
import { summaryScrollbar } from 'components/Strategy/scrollbar';
import { colors } from 'constants/index';
import { addYears, getQuarter, getTime } from 'date-fns';
import differenceInCalendarQuarters from 'date-fns/differenceInCalendarQuarters';
import useMobile from 'hooks/useMobile';
import { ReactElement, useEffect, useRef, useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import {
  LEFT_SIDE_PADDING,
  MOBILE_LEFT_SIDE_PADDING,
  QUARTER_COLUMN_WIDTH,
  SCROLLBAR_PADDING,
  YEAR_COLUMN_WIDTH,
} from './constants';
import { TimelineImperativeRow } from './TimelineImperativeRow';
import {
  ArchetypeWhereInput,
  SupportingMessageTacticWhereInput,
  useArchetypesQuery,
  useSupportingMessageTacticsQuery,
} from 'data/graphql/generated';
import {
  dateStringToMonthYear,
  monthYearToDateTimestamp,
} from 'utils/dateStringToMonthYear';
import { polling } from 'constants/index';

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const TimelineWrapperClipBorder = styled.div<{
  timelineScrollable: { h: boolean; v: boolean };
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  border: 1px solid ${colors.black10};

  max-width: ${({ timelineScrollable }) =>
    timelineScrollable.v ? 'calc(100% - 20px)' : '100%'};
  max-height: ${({ timelineScrollable }) =>
    timelineScrollable.h ? 'calc(100% - 20px)' : '100%'};

  @media ${device.mobile} {
    max-width: 100%;
    max-height: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
  }

  @media ${device.tabletMin} {
    padding: 1px;
    background: ${colors.black10};

    clip-path: polygon(
      calc(100% - ${SCROLLBAR_PADDING}px) 0,
      100% 50%,
      calc(100% - ${SCROLLBAR_PADDING}px) 100%,
      0% 100%,
      ${SCROLLBAR_PADDING}px 50%,
      0% 0
    );
  }
`;
const TimelineWrapperClip = styled.div`
  overflow: auto;

  width: 100%;
  height: 100%;

  @media ${device.mobile} {
    max-height: calc(100% - 9px);
  }
  @media ${device.tabletMin} {
    // Hide scrollbar
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    clip-path: polygon(
      calc(100% - ${SCROLLBAR_PADDING}px) 0,
      100% 50%,
      calc(100% - ${SCROLLBAR_PADDING}px) 100%,
      0% 100%,
      ${SCROLLBAR_PADDING}px 50%,
      0% 0
    );
  }
`;

const QuarterLabelsWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  height: 15px;
  flex-shrink: 0;
`;

const ScrollController = styled.div<{
  displayEmptyState: boolean;
  timelineScrollable: { h: boolean; v: boolean };
}>`
  /* Due to the clip path, the scroll bars are also cut out 
  so we need an element above the entire timeline to control the scrolling */
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  inset: 0;
  overflow: ${({ displayEmptyState }) => (displayEmptyState ? '' : 'auto')};

  @media ${device.tabletMin} {
    width: calc(100% - 5px);
    height: calc(100% - 5px);
    ${summaryScrollbar}
    // Removes square scrollbar corner
    ::-webkit-scrollbar-corner {
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      margin: 10px 5px;
    }

    overflow-x: ${({ timelineScrollable }) =>
      timelineScrollable.h ? 'auto' : 'hidden'};
    overflow-y: ${({ timelineScrollable }) =>
      timelineScrollable.v ? 'auto' : 'hidden'};
  }
  // Only allow user interaction with the scrollbars
  clip-path: polygon(
    calc(100% - ${SCROLLBAR_PADDING}px) 0,
    100% 0,
    100% 100%,
    0 100%,
    0 calc(100% - ${SCROLLBAR_PADDING}px),
    calc(100% - ${SCROLLBAR_PADDING}px) calc(100% - ${SCROLLBAR_PADDING}px)
  );
`;

const TimelineWrapper = styled.div<{ displayEmptyState: boolean }>`
  position: relative;
  width: fit-content;
  background: white;
  overflow: visible;

  background: ${colors.white95};
`;

const BG = styled.div``;

const Content = styled.div``;

const QuarterLabel = styled.div`
  width: 102px;
  text-align: center;
  display: flex;
  justify-content: center;
  white-space: pre-wrap;
  flex-shrink: 0;
`;

const QuarterLabels = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  padding-right: ${LEFT_SIDE_PADDING * 2}px;
  padding-left: ${LEFT_SIDE_PADDING}px;
  @media ${device.mobile} {
    padding-right: ${MOBILE_LEFT_SIDE_PADDING * 2}px;
    padding-left: ${MOBILE_LEFT_SIDE_PADDING}px;
  }
  width: fit-content;
`;

const StyledEmptyState = styled(PostItsEmpty)`
  border: 1px solid ${colors.black30};
  position: absolute;
  height: 100%;
  z-index: 5;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: calc(100% - 60px);
  width: calc(100% - 60px);

  top: 30px;
  left: 30px;
  margin: auto;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;

  h2 {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-right: 10px;
  }

  .line {
    flex-grow: 1;
    height: 1px;
    background-color: #333;
  }
`;

export interface RowProps {
  dueDate: number[];
  timingEnd: number[];
  timingStart: number[];
  competitiveAdvantageRowId: number;
  focused: boolean;
  strategicPossibility?: { name: string };
  id: number;
  tacticText: string;
  prependText?: string;
}
interface Props {
  strategyId?: string;
  currency: string;
  tactics: RowProps[];
  rows: {
    id: number;
    __typename?: string;
    strategicPossibility?: { name: string } | null | undefined;
    title?: string;
    budget?: number;
  }[];
  emptyStateText?: string;
  prependDetailText?: string | ReactElement;
}

export const SummaryTimeline = ({
  strategyId,
  currency,
  tactics,
  rows,
  emptyStateText,
  prependDetailText,
}: Props) => {
  const outerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const scrollControllerRef = useRef<HTMLDivElement>(null);
  const clipBorderRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);
  const labelWrapperRef = useRef<HTMLDivElement>(null);
  const [imperativeRowsOpenState, setImperativeRowsOpenState] = useState<
    Record<number, boolean>
  >({});
  const [timelineScrollable, setTimelineScrollable] = useState({
    h: false,
    v: false,
  });
  const [quarterColumnWidth, setQuarterColumnWidth] = useState(102);

  const isMobile = useMobile();


  const archetypesQueryVars: Record<'where', ArchetypeWhereInput> = {
    where: { strategyId: Number(strategyId), countriesAssigned: true },
  };
  const {
    data: archetypeData,
    startPolling: archetypesStartPolling,
    stopPolling: archetypesStopPolling,
  } = useArchetypesQuery({
    variables: {
      ...archetypesQueryVars,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const supportingMessageTacticsVars: {
    where: SupportingMessageTacticWhereInput;
  } = {
    where: { strategyId: Number(strategyId), focus: true },
  };


  const {
    data: supportingMessageTacticsData,
    startPolling: supportingMessageTacticsStartPolling,
    stopPolling: supportingMessageTacticsStopPolling,
  } = useSupportingMessageTacticsQuery({
    variables: supportingMessageTacticsVars,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    archetypesStartPolling(polling.default);
    supportingMessageTacticsStartPolling(polling.default);

    return () => {
      supportingMessageTacticsStopPolling();
      archetypesStopPolling();
    };
  }, [
    archetypesStartPolling,
    archetypesStopPolling,
    supportingMessageTacticsStartPolling,
    supportingMessageTacticsStopPolling,
  ]);

  const tacticsForTimeline = useMemo(
    () =>
      [
        ...(supportingMessageTacticsData?.supportingMessageTactics?.items ||
          []),
      ].map((tactic) => {
        return {
          id: tactic.id,
          dueDate: dateStringToMonthYear(tactic.due || ''),
          timingEnd: dateStringToMonthYear(tactic.to || ''),
          timingStart: dateStringToMonthYear(tactic.from || ''),              
          competitiveAdvantageRowId: tactic.archetypeId ?? 0,
          focused: true,
          tacticText: tactic.text,
          budget: tactic.budget
        };
      }),
    [supportingMessageTacticsData?.supportingMessageTactics?.items]
  );

  const imperativeBudgetTotals = useMemo(() => {
    return (tacticsForTimeline || [])
      .reduce((acc, curr) => {
        let timingStart =
          'from' in curr
            ? curr.from
            : 'timingStart' in curr
            ? monthYearToDateTimestamp(curr.timingStart)
            : null;
  
        if (
          typeof curr?.budget !== 'undefined'
        ) {
          if (curr.competitiveAdvantageRowId in acc) {
            acc[curr?.competitiveAdvantageRowId].budget += +(curr?.budget || 0);
  
            const timing = acc[curr?.competitiveAdvantageRowId].timing;
  
            if (
              (!!timingStart && timing === null) ||
              (!!timingStart && timing !== null && +timingStart < timing)
            ) {
              acc[curr?.competitiveAdvantageRowId].timing = +timingStart;
            }
          } else {
            acc[curr?.competitiveAdvantageRowId] = {
              budget: +(curr?.budget || 0),
              timing: !!timingStart ? +timingStart : null,
            };
          }
        }
        return acc;
      }, {} as Record<number, { budget: number; timing: number | null }>);
  }, [tacticsForTimeline]);
  
  const rowsForTimeline = useMemo(() => {
    const archetypeItems = archetypeData?.archetypes?.items || [];
  
    return archetypeItems.map((archetype) => {
      const budgetData = imperativeBudgetTotals[archetype.id] || {};
      return {
        id: archetype.id,
        __typename: archetype.__typename,
        title: archetype.stakeholderDefinition.title,
        budget: budgetData.budget || 0,
        timing: budgetData.timing || null,
      };
    }).sort((a, b) => {
      const nameA = a.title.toUpperCase();
      const nameB = b.title.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }, [archetypeData?.archetypes?.items, imperativeBudgetTotals]);

  const rows2 = useMemo(() => [
    {
      id: 0,
      __typename: 'archetype',
      title: 'Global (Across all archetypes)',
      budget: imperativeBudgetTotals[0]?.budget,
    },
    ...rowsForTimeline,
  ], [rowsForTimeline, imperativeBudgetTotals]);

  const leftSidePadding = isMobile
    ? MOBILE_LEFT_SIDE_PADDING
    : LEFT_SIDE_PADDING;

  const getDateYear = (date: string) => +date.slice(-4);
  const getDateMonth = (date: string) => +date.slice(0, -4);

  

  const allDates = useMemo(() => {

    const extractDates = (items: any) => {
      return items.flatMap((val: any) => {
        return [
          val.dueDate?.length
            ? new Date(
                getDateYear(val.dueDate.join('')),
                getDateMonth(val.dueDate.join('')),
                1
              ).getTime()
            : '',
          val.timingEnd?.length
            ? new Date(
                getDateYear(val.timingEnd.join('')),
                getDateMonth(val.timingEnd.join('')),
                1
              ).getTime()
            : '',
          val.timingStart?.length
            ? new Date(
                getDateYear(val.timingStart.join('')),
                getDateMonth(val.timingStart.join('')),
                1
              ).getTime()
            : '',
        ].filter((date) => !!date) as number[];
      });
    };

    const datesFromTactics = extractDates(tactics);
    const datesFromTacticsForTimeline = extractDates(tacticsForTimeline);
  
    return [...datesFromTactics, ...datesFromTacticsForTimeline];
  }, [tactics, tacticsForTimeline]);

  function getMinDate(allDates: number[]): number {
    const min = Math.min(...allDates);
    if (min === Number.POSITIVE_INFINITY) {
      return Date.now();
    }

    return min;
  }

  function getMaxDate(allDates: number[]): number {
    const max = Math.max(...allDates);

    if (max === Number.NEGATIVE_INFINITY) {
      return getTime(addYears(Date.now(), 2));
    }

    return max;
  }

  // If no data provided, show the range from now to 2 years into the future
  const minDate = getMinDate(allDates);
  const maxDate = getMaxDate(allDates);

  const quarters = differenceInCalendarQuarters(maxDate, minDate) + 1;

  const minYearMonthQuarter = getQuarter(new Date(minDate));

  const [distanceFromTop, setDistanceFromTop] = useState(0);
  const [timelineWidth, setTimelineWidth] = useState(0);
  const [timelineHeight, setTimelineHeight] = useState(0);

  const displayEmptyState = !rows.length;

  useEffect(() => {
    const onResize = () => {
      if (!!labelRef.current) {
        setTimelineWidth(labelRef.current.offsetWidth);
      }
      if (wrapperRef.current) {
        const { bottom, height } = wrapperRef?.current?.getBoundingClientRect();
        setTimelineScrollable({
          h:
            wrapperRef?.current?.scrollWidth > wrapperRef?.current?.clientWidth,
          v:
            wrapperRef?.current?.scrollHeight >
            wrapperRef?.current?.clientHeight,
        });

        setTimelineHeight(wrapperRef.current.scrollHeight);
        setDistanceFromTop(bottom - height);
      }

      const evenColumnWidth =
        ((outerRef?.current?.offsetWidth || 1) -
          SCROLLBAR_PADDING -
          // labels have 1 padding on the left and (padding X 2) on the right
          leftSidePadding * 3) /
        quarters;

      setQuarterColumnWidth(Math.max(evenColumnWidth, 102));
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [
    tactics,
    rows,
    distanceFromTop,
    leftSidePadding,
    quarters,
    imperativeRowsOpenState,
  ]);

  const yearGap = quarterColumnWidth + YEAR_COLUMN_WIDTH;
  const quarterGap = QUARTER_COLUMN_WIDTH + quarterColumnWidth;

  const backgroundColumnStyle = getBackgroundColumnStyle({
    yearGap,
    quarterGap,
  });

  const backgroundOffset =
    leftSidePadding +
    Math.max(0, minYearMonthQuarter - 1) *
      (-quarterColumnWidth - QUARTER_COLUMN_WIDTH);

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <InnerWrapper ref={outerRef}>
        <QuarterLabelsWrapper ref={labelWrapperRef}>
          <QuarterLabels ref={labelRef}>
            {!!quarters &&
              [...Array(quarters)].map((val, idx) => {
                const yearIncrement = Math.floor(
                  (minYearMonthQuarter + idx) / 4
                );

                const start = !((minYearMonthQuarter + idx + 3) % 4);
                const year = new Date(minDate).getFullYear();

                const text = start ? (
                  <QuarterLabel
                    style={{
                      width: quarterColumnWidth + YEAR_COLUMN_WIDTH,
                    }}
                    key={idx}
                  >
                    <Caption color={colors.white}>
                      Q{!idx ? minYearMonthQuarter : 1}
                    </Caption>{' '}
                    <Caption color={colors.white}>
                      {' '}
                      {year + yearIncrement}
                    </Caption>
                  </QuarterLabel>
                ) : (
                  <QuarterLabel
                    style={{
                      width: quarterColumnWidth + QUARTER_COLUMN_WIDTH,
                    }}
                    key={idx}
                  >
                    <Caption color={colors.white}>
                      Q{(minYearMonthQuarter + idx) % 4 || 4}
                    </Caption>
                  </QuarterLabel>
                );

                return text;
              })}
          </QuarterLabels>
        </QuarterLabelsWrapper>

        <div
          style={{
            position: 'relative',
            flex: 1,
            // 20px = distance from top
            height: 'calc(100% - 20px)',
          }}
        >
          {displayEmptyState && (
            <StyledEmptyState title="No plan yet">
              <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
                The timeline will be populated with strategic imperatives and
                their tactics, solutions and big ideas.{' '}
              </BodySmall>
            </StyledEmptyState>
          )}

          <TimelineWrapperClipBorder
            timelineScrollable={timelineScrollable}
            ref={clipBorderRef}
          >
            <TimelineWrapperClip
              ref={wrapperRef}
              onScroll={(e) => {
                const target = e.target as HTMLDivElement;
                if (scrollControllerRef.current && e.target) {
                  scrollControllerRef.current.scrollLeft =
                    target.scrollLeft || 0;
                  scrollControllerRef.current.scrollTop = target.scrollTop || 0;
                }

                if (labelWrapperRef.current && e.target) {
                  labelWrapperRef.current.scrollLeft = target.scrollLeft || 0;
                }
              }}
            >
              <TimelineWrapper
                style={{
                  width: timelineWidth || '100%',
                  minWidth: '100%',
                  minHeight: '100%',
                  padding: '0px 30px'
                }}
                displayEmptyState={displayEmptyState}
              >
                <BG
                  style={{
                    position: 'absolute',
                    inset: 0,
                    left: `${backgroundOffset}px`,
                    backgroundImage: backgroundColumnStyle,
                    zIndex: -1,
                  }}
                />

                {/* Access Plan */}
                <div>
                  <Header>
                    <h2>Access Plan</h2>
                    <div className="line"></div>
                  </Header>
                  <Content>
                    {rows2.map((imperative, idx) => {
                     let title  = imperative?.title || '';
 
                     const imperativeTactics = tacticsForTimeline.filter(
                       (tactic) =>
                         tactic.competitiveAdvantageRowId === imperative.id &&
                         !!tactic.focused
                     );
 
                      // Imperative
                      return (
                        <TimelineImperativeRow
                          key={imperative.id}
                          imperative={imperative}
                          idx={idx}
                          title={title}
                          currency={currency}
                          imperativeTactics={imperativeTactics}
                          wrapperRef={wrapperRef}
                          minDate={minDate}
                          quarterColumnWidth={quarterColumnWidth}
                          emptyStateText={emptyStateText}
                          prependDetailText={prependDetailText}
                          isOpen={imperativeRowsOpenState?.[imperative.id]}
                          setIsOpen={() =>
                            setImperativeRowsOpenState((state) => {
                              return {
                                ...state,
                                [imperative.id]: !state?.[imperative.id],
                              };
                            })
                          }
                        />
                      );
                    })}
                  </Content>
                </div>
                {/* Strategic Imperatives */}
                <div>
                <Header>
                    <h2>Strategic Imperatives</h2>
                    <div className="line"></div>
                  </Header>

                  <Content>
                    {rows.map((imperative, idx) => {
                      let title = '';
                      if (imperative.__typename === 'CompetitiveAdvantageRow') {
                        title = imperative?.strategicPossibility?.name || '';
                      } else {
                        title = imperative?.title || '';
                      }

                      const imperativeTactics = tactics.filter(
                        (tactic) =>
                          tactic.competitiveAdvantageRowId === imperative.id &&
                          !!tactic.focused
                      );
                      // Imperative
                      return (
                        <TimelineImperativeRow
                          key={imperative.id}
                          imperative={imperative}
                          idx={idx}
                          title={title}
                          currency={currency}
                          imperativeTactics={imperativeTactics}
                          wrapperRef={wrapperRef}
                          minDate={minDate}
                          quarterColumnWidth={quarterColumnWidth}
                          emptyStateText={emptyStateText}
                          prependDetailText={prependDetailText}
                          isOpen={imperativeRowsOpenState?.[imperative.id]}
                          setIsOpen={() =>
                            setImperativeRowsOpenState((state) => {
                              return {
                                ...state,
                                [imperative.id]: !state?.[imperative.id],
                              };
                            })
                          }
                        />
                      );
                    })}
                  </Content>
                </div>
              </TimelineWrapper>
            </TimelineWrapperClip>
          </TimelineWrapperClipBorder>

          {!isMobile && (
            <ScrollController
              timelineScrollable={timelineScrollable}
              ref={scrollControllerRef}
              displayEmptyState={displayEmptyState}
              onScroll={(e) => {
                const target = e.target as HTMLDivElement;

                if (wrapperRef.current && e.target) {
                  wrapperRef.current.scrollLeft = target.scrollLeft || 0;
                  wrapperRef.current.scrollTop = target.scrollTop || 0;
                }

                if (labelWrapperRef.current && e.target) {
                  labelWrapperRef.current.scrollLeft = target.scrollLeft || 0;
                }
              }}
            >
              <div
                style={{
                  height: timelineHeight + (timelineScrollable.v ? 10 : 0),
                  width: timelineWidth + (timelineScrollable.h ? 10 : 0),
                }}
              />
            </ScrollController>
          )}
        </div>
      </InnerWrapper>
    </div>
  );
};

const getBackgroundColumnStyle = ({
  yearGap,
  quarterGap,
}: {
  yearGap: number;
  quarterGap: number;
}) => `repeating-linear-gradient(90deg, transparent 0 ${
  // Thin lines
  yearGap
}px,
   ${colors.white} ${yearGap}px ${yearGap + QUARTER_COLUMN_WIDTH}px, 
   transparent ${yearGap + QUARTER_COLUMN_WIDTH}px ${yearGap + quarterGap}px, 
   ${colors.white} ${yearGap + quarterGap}px  ${
  yearGap + quarterGap + QUARTER_COLUMN_WIDTH
}px, 
   transparent ${yearGap + quarterGap + QUARTER_COLUMN_WIDTH}px ${
  yearGap + quarterGap + quarterGap
}px, 
   ${colors.white} ${yearGap + quarterGap + quarterGap}px ${
  yearGap + quarterGap + quarterGap + QUARTER_COLUMN_WIDTH
}px, 
   transparent ${yearGap + quarterGap + quarterGap + QUARTER_COLUMN_WIDTH}px ${
  yearGap + quarterGap + quarterGap + quarterGap
}px ),

repeating-linear-gradient(90deg, ${
  // Thick lines
  colors.white
} 0 ${YEAR_COLUMN_WIDTH}px, transparent ${YEAR_COLUMN_WIDTH}px ${
  yearGap + quarterGap + quarterGap + quarterGap
}px)`;
