import React, { useState, useLayoutEffect } from 'react';
import { Shape } from 'react-konva';

import { colors } from 'constants/colors';
import { BlockTree, Position } from 'types';
import { RECT_WIDTH } from 'components/PatientFlow/constants';
import { adjustForScreenX, adjustForScreenY } from './adjustForScreen';
import { PatientJourneyColumn } from 'data/graphql/generated';
import { Stage } from 'konva/types/Stage';
import { ArrowConfig } from 'konva/types/shapes/Arrow';

interface Props {
  stageRef: React.RefObject<Stage>;
  parentId: number;
  childId: number;
  blocks: BlockTree[];
  leftOffset: number;
  column: PatientJourneyColumn;
  dash: boolean;
}

export const positionConnections = (
  stageRef: React.RefObject<Stage>,
  parentId: number,
  childId: number,
  leftOffset: number
) => {
  const parentNode = stageRef.current?.findOne(
    `#block-${parentId}-rect-gradient`
  );
  if (!parentNode) return;
  const parentNodePosition: Position = parentNode.getAbsolutePosition();

  const childNode = stageRef.current?.findOne(
    `#block-${childId}-rect-gradient`
  );
  if (!childNode) return;
  const childNodePosition: Position = childNode.getAbsolutePosition();

  const halfOfWidth = RECT_WIDTH / 2;

  const parentNodeX = parentNodePosition.x - leftOffset + halfOfWidth;

  const parentNodeY = parentNodePosition.y - 20 + parentNode.height();

  const childPosition = childNodePosition.x - leftOffset + halfOfWidth;

  const slightBend =
    childPosition > parentNodeX
      ? parentNodeX + 15
      : childPosition < parentNodeX
      ? parentNodeX - 15
      : parentNodeX;

  const secondSlightBend =
    childPosition > parentNodeX
      ? childPosition - 5
      : childPosition < parentNodeX
      ? childPosition + 5
      : childPosition;

  const secondSlightBend2 =
    childPosition > parentNodeX
      ? childPosition - 10
      : childPosition < parentNodeX
      ? childPosition + 10
      : childPosition;

  // playground to visualise shapes - https://math.hws.edu/graphicsbook/demos/c2/quadratic-bezier.html
  return [
    // Staring point
    adjustForScreenX(parentNodeX, stageRef),
    adjustForScreenY(parentNodeY, stageRef),

    // first bend - destination vertical handle
    adjustForScreenX(parentNodeX, stageRef),
    adjustForScreenY(parentNodeY + 15, stageRef),

    // first bend - destination
    adjustForScreenX(parentNodeX, stageRef),
    adjustForScreenY(parentNodeY + 20, stageRef),

    // first bend - horizontal destination handle
    adjustForScreenX(slightBend, stageRef),
    adjustForScreenY(parentNodeY + 20, stageRef),

    // in-between
    adjustForScreenX(slightBend, stageRef),
    adjustForScreenY(parentNodeY + 20, stageRef),

    adjustForScreenX(secondSlightBend2, stageRef),
    adjustForScreenY(parentNodeY + 20, stageRef),

    adjustForScreenX(secondSlightBend, stageRef),
    adjustForScreenY(parentNodeY + 20, stageRef),

    // second bend - horizontal destination handle
    adjustForScreenX(secondSlightBend, stageRef),
    adjustForScreenY(parentNodeY + 20, stageRef),

    // second bend - destination
    adjustForScreenX(childPosition, stageRef),
    adjustForScreenY(parentNodeY + 20, stageRef),

    // second bend - destination vertical handle
    adjustForScreenX(childPosition, stageRef),
    adjustForScreenY(parentNodeY + 25, stageRef),

    // end point
    adjustForScreenX(childPosition, stageRef),
    adjustForScreenY(childNodePosition.y - 20, stageRef),
    adjustForScreenX(childPosition, stageRef),
    adjustForScreenY(childNodePosition.y - 20, stageRef),
    adjustForScreenX(childPosition, stageRef),
    adjustForScreenY(childNodePosition.y - 20, stageRef),
  ];
};

export const Connection: React.FC<Props> = ({
  parentId,
  childId,
  stageRef,
  blocks,
  column,
  leftOffset,
  dash,
}) => {
  const [points, setPoints] = useState<number[]>([]);

  useLayoutEffect(() => {
    // Find out where the parent & child blocks are drawn
    // Then calc how to draw the arrow

    setPoints(
      positionConnections(stageRef, parentId, childId, leftOffset) || []
    );
    // NOTE blocks is needed here to update the connections
    // on any change to a block
  }, [parentId, childId, blocks, leftOffset, stageRef]);

  return (
    <ArrowShape
      lineDash={dash ? [10, 5] : undefined}
      dashOffset={6}
      bezier
      lineCap="round"
      lineJoin="round"
      id={`connection-${parentId}-${childId}`}
      name={`connection-col-${column.idx}`}
      parentId={parentId}
      childId={childId}
      stageId={column.id}
      fill={colors.purple}
      fillEnabled={false}
      stroke={colors.purple}
      strokeWidth={2}
      points={points}
      opacity={points.length ? 1 : 0}
    />
  );
};

function ArrowShape(attrs: ArrowConfig) {
  return (
    <Shape
      {...attrs}
      bezier
      sceneFunc={(ctx, shape) => {
        const points = shape.getAttr('points');
        // draw circle
        ctx.save();
        ctx.moveTo(points[0], points[1]);
        ctx.beginPath();

        ctx.arc(points[0], points[1], 4, 0, 2 * Math.PI, true);
        ctx._context.fillStyle = '#FFFFFF';
        ctx.fill();
        ctx.fillStrokeShape(shape);
        ctx.restore();

        // draw main line
        const dash = shape.getAttr('lineDash') || [];
        ctx.save();
        ctx.beginPath();
        ctx.setLineDash(dash);
        ctx.moveTo(points[0], points[1] + 4);

        // Bezier function taken from Konva library
        // node_modules/konva/lib/shapes/Line.js
        let i = 2;
        while (i < points.length) {
          ctx.bezierCurveTo(
            points[i++],
            points[i++],
            points[i++],
            points[i++],
            points[i++],
            points[i++]
          );
        }
        ctx.fillStrokeShape(shape);
        ctx.restore();

        // calculate attrs for pointer
        const PI2 = Math.PI / 180;

        const radians = 90 * PI2;
        const length = shape.getAttr('pointerLength') || 9;
        const width = shape.getAttr('pointerWidth') || 16;

        // draw pointer
        ctx.save();
        ctx.beginPath();
        ctx.setLineDash([]);

        ctx.translate(points[points.length - 2], points[points.length - 1]);
        ctx.rotate(radians);
        ctx.moveTo(0, 0);
        ctx.lineTo(-length, width / 2);
        ctx.moveTo(0, 0);
        ctx.lineTo(-length, -width / 2);
        ctx.restore();
        ctx.fillStrokeShape(shape);
      }}
    />
  );
}
