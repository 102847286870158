import { PatientJourneyColumn } from 'data/graphql/generated';
import Konva from 'konva';
import { Stage } from 'konva/types/Stage';
import { removeByClassName } from './removeByClassName';

export function makeShowColumnTextarea({
  stageRef,
  updateColumn,
  classnamePrefix,
}: {
  stageRef: React.MutableRefObject<null | Stage>;
  updateColumn: (connection: Partial<PatientJourneyColumn>) => void;
  classnamePrefix: string;
}) {
  return function showColumnTextarea(
    column: PatientJourneyColumn,
    width: number
  ) {
    if (!stageRef.current) return;
    const columnId = column.id;

    // check if there is already a textarea created for this block
    removeByClassName(`${classnamePrefix}-textarea`);
    removeByClassName(`${classnamePrefix}-background`);

    const columnNode = stageRef.current.findOne(
      `#${classnamePrefix}-title-${columnId}`
    ) as Konva.Text;
    const textPosition = columnNode.getAbsolutePosition();
    const stageBox = stageRef.current.container().getBoundingClientRect();

    const areaPosition = {
      x: stageBox.left + textPosition.x,
      y: stageBox.top + textPosition.y,
    };

    // create textarea and style it
    const textarea = document.createElement('textarea');
    document.body.appendChild(textarea);
    textarea.value = columnNode.text();
    textarea.maxLength = 30;
    textarea.className = `${classnamePrefix}-textarea ${classnamePrefix}-textarea-${columnId}`;
    textarea.style.top =
      areaPosition.y + document.documentElement.scrollTop + 'px';
    textarea.style.left =
      areaPosition.x + document.documentElement.scrollLeft + 'px';
    textarea.style.width = width + 'px';
    textarea.focus();
    textarea.select();

    textarea.addEventListener('keydown', (e) => {
      // hide on enter
      if (e.keyCode === 13) {
        // update text node
        if (!textarea.value) {
          columnNode.text(column.defaultName);
          removeColumnTextarea();
          updateColumn({
            ...column,
            name: column.defaultName,
          });
        } else {
          columnNode.text(textarea.value);
          removeColumnTextarea();
          updateColumn({
            ...column,
            name: textarea.value,
          });
        }
      }
    });

    // background to capture outside click
    const textareaBG = document.createElement('div');
    document.body.appendChild(textareaBG);
    textareaBG.className = `${classnamePrefix}-background ${classnamePrefix}-background-${columnId}`;
    textareaBG.addEventListener('click', () => {
      // update text node
      if (!textarea.value) {
        columnNode.text(column.defaultName);
        removeColumnTextarea();
        updateColumn({
          ...column,
          name: column.defaultName,
        });
      } else {
        columnNode.text(textarea.value);

        removeColumnTextarea();
        updateColumn({
          ...column,
          name: textarea.value,
        });
      }
    });

    function removeColumnTextarea() {
      document.body.removeChild(textarea);
      document.body.removeChild(textareaBG);
      columnNode.show();
      stageRef.current?.draw();
    }
  };
}
