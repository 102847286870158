import styled from 'styled-components/macro';
import { colors } from 'constants/colors';

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledText = styled.p`
  margin: 0;
  margin-left: 10px;
  text-transform: capitalize;

  font-family: ABCFavorit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  color: ${colors.black};
`;
