import { ModalForm } from 'components/ModalForm';
import { BodySmall, ButtonPill, Subtitle1 } from 'components/shared';
import FormCountryDropdownSearch from 'components/shared/FormCountryDropdownSearch';
import FormDropdown from 'components/shared/FormDropdown';
import FormTextInput from 'components/shared/FormTextInput';
import { SelectedNameEmailPreview } from 'components/shared/TextInputWithDropdownAndSelection';
import { colors, globalContributor } from 'constants/index';
import { ErrorBox, ErrorMessage, Form } from 'containers/Signin';
import {
  Role,
  useAddCollaboratorMutation,
  User,
  useUserUpdateMutation,
} from 'data/graphql/generated';
import { validate } from 'email-validator';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { countryAndGlobalRegionalArr, CountryGlobalRegional } from 'types';
import { preventEnterSubmit } from 'utils/preventEnterSubmit';
import { startCase } from 'utils/startCase';

const StyledTitleTextInput = styled(FormTextInput)<{
  errorMessageDisplayed: boolean;
}>`
  margin-bottom: ${({ errorMessageDisplayed }) =>
    errorMessageDisplayed ? '20px' : '10px'};
`;

const StyledFormCountryDropdown = styled(FormCountryDropdownSearch)`
  margin-bottom: 10px;
  label {
    margin-bottom: 0;
  }
` as typeof FormCountryDropdownSearch;

const StyledSelectedNameEmailPreview = styled(SelectedNameEmailPreview)`
  margin-top: 20px;
`;

const StyledErrorBox = styled(ErrorBox)`
  margin-top: 30px;
  text-align: left;
`;

const CTAs = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
`;

const StyledForm = styled(Form)`
  padding: 0;
`;

export const Title = styled(Subtitle1)`
  margin-bottom: 15px;
`;

interface AddProps {
  type: 'add';
  user?: never;
  modalOpen: boolean;
  closeModal(): void;
}

interface EditProps {
  type: 'edit';
  user: User | null;
  modalOpen: boolean;
  closeModal(): void;
}

export const EditCollaboratorModal = ({
  type,
  user,
  modalOpen,
  closeModal,
}: AddProps | EditProps) => {
  const { drugId, strategyId } = useParams<{
    drugId: string;
    strategyId: string;
  }>();

  const [dropdownActive, setDropdownActive] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string | number>('');
  const [countryDropdownActive, setCountryDropdownActive] = useState<boolean>(
    false
  );
  const [
    selectedCountry,
    setSelectedCountry,
  ] = useState<CountryGlobalRegional>();

  const [addCollaborator] = useAddCollaboratorMutation();
  const [userUpdate] = useUserUpdateMutation();

  const [disabledOnMount, setDisabledOnMount] = useState(true);
  const [networkError, setNetworkError] = useState(false);

  useEffect(() => {
    if (user?.role) {
      setSelectedItem(user?.role);
    }

    if (user?.role !== Role.Lead && user?.country) {
      setSelectedCountry(user?.country as CountryGlobalRegional);
    }
  }, [user]);

  const handleClose = () => {
    setSelectedItem('');
    closeModal();
  };

  return (
    <ModalForm
      heading={'Edit collaborator'}
      handleClose={handleClose}
      visible={modalOpen}
    >
      <Formik
        validateOnBlur
        initialValues={{
          name: user?.name || '',
          email: user?.email || '',
          role: user?.role || '',
          country: user?.country || '',
          title: user?.title || '',
        }}
        validate={(values) => {
          setNetworkError(false);
          setDisabledOnMount(false);
          const errors = {} as {
            name: string;
            email: string;
            title: string;
            country: string;
            role: boolean;
          };

          if (!values.name) {
            errors.name = 'Cannot be empty';
          }

          if (!values.email) {
            errors.email = 'Cannot be empty';
          } else if (!validate(values.email)) {
            errors.email = 'Invalid email address';
          }

          if (!values.role) {
            errors.role = false;
          }

          if (values.role === Role.Lead && !values.title) {
            errors.title = 'Leads must have a title.';
          }

          if (
            values.role === Role.Contributor &&
            (values.country || selectedCountry) === globalContributor &&
            !values.title
          ) {
            errors.title = 'Global / Regional contributors must have a title.';
          }

          if (values.role === Role.Contributor && !values.country) {
            errors.country = 'Contributors must be assigned a market.';
          }

          return errors;
        }}
        onSubmit={async (values, actions) => {
          values.email = values.email.toLowerCase();

          try {
            if (type === 'add') {
              await addCollaborator({
                variables: {
                  data: {
                    drugId: +drugId,
                    strategyId: +strategyId,
                    name: values.name?.trim() || undefined,
                    email: values.email?.trim() || undefined,
                    role: (values.role as Role) || undefined,
                    country: values.country || undefined,
                    phoneCountry: values.country || undefined,
                    title:
                      values.role === Role.Contributor &&
                      values.country !== globalContributor
                        ? ''
                        : values.title || undefined,
                  },
                },
              });

              handleClose();
            } else {
              if (user) {
                await userUpdate({
                  variables: {
                    id: user.id,
                    data: {
                      name: values.name || undefined,
                      email: values.email || undefined,
                      drugId: +drugId,
                      role: (values.role as Role) || undefined,
                      country: values.role === Role.Lead ? 'global' : values.country || undefined,
                      phoneCountry:
                        user?.phoneCountry || values.country || undefined,
                      title:
                        values.role === Role.Contributor &&
                        values.country !== globalContributor
                          ? ''
                          : values.title || undefined,
                      strategyId: +strategyId,
                    },
                  },
                });

                handleClose();
              }
            }
          } catch (error) {
            console.error(error instanceof Error && error.message);
            if (
              [
                'Unique constraint failed on the fields: (`email`)',
                'This email address is already in use',
              ].some(
                (message) =>
                  error instanceof Error && error.message.includes(message)
              )
            ) {
              actions.setFieldError('email', 'Email address already in use');
            } else {
              setNetworkError(true);
            }
          }
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          errors,
          isSubmitting,
          isValid,
          setFieldValue,
          touched,
          setFieldTouched,
        }) => (
          <StyledForm onKeyDown={preventEnterSubmit} onSubmit={handleSubmit}>
            <StyledSelectedNameEmailPreview
              nameValue={values.name}
              emailFieldValue={values.email}
              readOnly
            />

            <FormDropdown
              title="Role"
              name="role"
              placeholder="Choose role"
              onClick={() => setDropdownActive(!dropdownActive)}
              dropdownActive={dropdownActive}
              selectedItem={startCase(String(selectedItem))}
              setSelectedItem={(item) => {
                if (!item) return;
                const typedItem = String(item) as keyof typeof Role;
                setFieldTouched('role', true);
                setSelectedItem(Role[typedItem]);
                setFieldValue('role', Role[typedItem], true);

                if (typedItem.toUpperCase() === Role.Lead) {
                  if (!values.title) {
                    setFieldValue('title', startCase(Role.Lead), true);
                  }
                }
              }}
              setDropdownActive={setDropdownActive}
              options={[startCase(Role.Lead), startCase(Role.Contributor)]}
              errorMessage={touched.role ? errors.role : ''}
            />

            <div
              style={{
                marginBottom: 10,
                textAlign: 'left',
                marginTop: -10,
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
              }}
            >
              <BodySmall color={colors.greyDark}>
                Leads can access all strategies, make decisions, manage
                collaborators and contribute ideas.
              </BodySmall>
              <BodySmall color={colors.greyDark}>
                Contributors can only contribute ideas to the strategy.
              </BodySmall>
            </div>

            {selectedItem === Role.Contributor && (
              <StyledFormCountryDropdown
                style={{ width: '100%' }}
                title="Responsibility"
                name="country"
                placeholder="Choose responsibility"
                onClick={() => setCountryDropdownActive(!countryDropdownActive)}
                dropdownActive={countryDropdownActive}
                selectedItem={
                  (values.country as CountryGlobalRegional) || selectedCountry
                }
                setSelectedItem={(country) => {
                  setFieldTouched('country', true);
                  setSelectedCountry(country);
                  setFieldValue('country', country, true);
                }}
                setDropdownActive={setCountryDropdownActive}
                options={countryAndGlobalRegionalArr}
                errorMessage={touched.country ? errors.country : ''}
              />
            )}

            {(selectedItem === Role.Lead ||
              (selectedItem === Role.Contributor &&
                values.country === globalContributor)) && (
              <StyledTitleTextInput
                errorMessageDisplayed={!!(touched.title ? errors.title : '')}
                name="title"
                type="text"
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={(e) => {
                  setFieldTouched('title', true);
                  handleBlur(e);
                }}
                value={values.title}
                title="Title"
                errorMessage={touched.title ? errors.title : ''}
              />
            )}

            <CTAs>
              <ButtonPill
                level="secondary"
                width="180px"
                text="Cancel"
                onClick={handleClose}
              />
              {type === 'add' ? (
                <ButtonPill
                  disabled={
                    disabledOnMount ? disabledOnMount : isSubmitting || !isValid
                  }
                  type="submit"
                  width="180px"
                  text="Add"
                />
              ) : (
                <ButtonPill
                  disabled={
                    disabledOnMount ? disabledOnMount : isSubmitting || !isValid
                  }
                  type="submit"
                  width="180px"
                  text="Save"
                />
              )}
            </CTAs>

            {networkError ? (
              <StyledErrorBox>
                <ErrorMessage>
                  {
                    'Invite could not be sent due to an unknown error. Please try again.'
                  }
                </ErrorMessage>
              </StyledErrorBox>
            ) : null}
          </StyledForm>
        )}
      </Formik>
    </ModalForm>
  );
};
