import { colors } from 'constants/index';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Modal } from './Modal';
import { Icon } from './shared';

export const ModalContainer = styled.div`
  text-align: center;
  border-top: 40px solid ${colors.cream};
  padding: 40px;
  min-width: 456px;
  height: min-content;
  border-radius: 5px;
  position: relative;
  background: ${colors.white};
`;

const StyledModalWrapper = styled(Modal)<{ onClick(): void }>`
  margin: 0;
  padding-top: 250px;
  min-height: 100vh;
  overflow: auto;
  background: transparent;
  width: 100vw;
  max-width: none;
  display: flex;
  justify-content: center;
  height: 100vh;
  padding-bottom: 250px;
`;

const CloseXIcon = styled(Icon)`
  position: absolute;
  top: -35px;
  right: 5px;
  cursor: pointer;
`;

interface Props {
  handleClose(): void;
  visible: boolean;
  children: React.ReactNode;
  className?: string;
}

export const ModalXClose = ({
  handleClose,
  visible,
  children,
  className,
}: Props) => {
  useEffect(() => {
    const root = document.querySelector('#root');

    if (visible) {
      root?.setAttribute('style', 'overflow: hidden; height: 100vh');
    } else {
      root?.setAttribute('style', 'overflow: visible; height: auto');
    }
    return () => {
      root?.setAttribute('style', 'overflow: visible; height: auto');
    };
  }, [visible]);

  return ReactDOM.createPortal(
    <StyledModalWrapper
      className={className}
      handleClose={handleClose}
      visible={visible}
      onClick={handleClose}
    >
      <ModalContainer
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {/* Cross */}
        <CloseXIcon
          className={'cypress-modal-close-icon'}
          onClick={handleClose}
          name="Close"
          size={30}
          color={colors.black}
        />
        {children}
      </ModalContainer>
    </StyledModalWrapper>,
    document.body
  );
};
