import { TrackedGoalFragment } from 'data/graphql/generated';
import { useState } from 'react';

export const useSelectedTrackedGoal = () => {
  const [trackedGoal, setTrackedGoal] = useState<
    TrackedGoalFragment | undefined
  >(undefined);

  const selectTrackedGoal = (goal: any) => setTrackedGoal(goal);
  const deselectTrackedGoal = () => setTrackedGoal(undefined);

  return {
    trackedGoal,
    selectTrackedGoal,
    deselectTrackedGoal,
  };
};
