import { countBy } from 'lodash';
import { useEffect, useMemo, useState, Fragment } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { ModalLarge } from 'components/ModalLarge';
import {
  BodyNormal,
  BodySmall,
  ButtonPill,
  Caption,
  CheckCircle,
  EmptyState,
  Subtitle1,
  Subtitle2,
} from 'components/shared';
import { colors } from 'constants/index';
import {
  SuccessConditionFragment,
  SuccessConditionPerspective,
  SuccessConditionType,
  useSuccessConditionUpdateMutation,
} from 'data/graphql/generated';
import { device } from 'utils/breakpoints';
import { IconContainer } from 'components/shared/Icon';

const ColumnWrapper = styled.div`
  overflow: auto;
  max-width: 391px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 10px 0;
`;

const StyledCheckCircle = styled(CheckCircle)`
  flex-shrink: 0;
`;

const StyledModalButtonPill = styled(ButtonPill)`
  min-width: 160px;
  width: 180px;
`;

const ModalCTAWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 15px;
  width: 100%;
  justify-content: center;

  @media ${device.mobile} {
    margin-top: 0px;
    overflow: hidden;
    position: fixed;
    bottom: 15px;
    left: 0px;
    right: 0px;
  }
`;

const StyledModalList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  padding: 15px;
  margin: 0;
  border-radius: 5px;
  border: 1px solid ${colors.greyMedium};
`;

const StyledEmptyState = styled(EmptyState)`
  width: calc(100% - 80px);
  text-align: center;
`;

const ModalContent = styled.section`
  width: 100%;

  margin: 0 auto;
  border-top: 1px solid ${colors.greyLight};
  border-bottom: 1px solid ${colors.greyLight};

  max-height: 438px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.mobile} {
    padding: 0 15px;
    display: block;
    height: calc(100vh - 285px);
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: none;
  }
`;

const Wrapper = styled.div`
  padding: 20px 0 10px 0;

  @media ${device.mobile} {
    max-height: calc(100% - 50px);
    height: calc(100% - 50px);
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const Content = styled.div`
  @media ${device.mobile} {
    height: calc(100% - 50px);
  }
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  margin: 0 40px 15px;
`;

const CheckCircleWithLabel = styled.li<{
  isDisabled: boolean;
  complete: boolean;
}>`
  display: flex;
  gap: 5px;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  &:hover {
    ${IconContainer} {
      background: ${({ complete, isDisabled }) =>
        isDisabled ? undefined : complete ? colors.purple60 : colors.purple20};
    }
  }
`;

type RelevantSuccessConditionTypes =
  | SuccessConditionPerspective.Stakeholder
  | SuccessConditionPerspective.Company
  | SuccessConditionPerspective.Competitor
  | SuccessConditionPerspective.None;

export function RiskMitigationIdeasModal({
  hideModal,
  isVisible,
  drugId,
  strategyId,
  strategicImperativeId,
  successConditions,
}: {
  hideModal: () => void;
  isVisible: boolean;
  drugId: string;
  strategyId: string;
  strategicImperativeId: string;
  successConditions: SuccessConditionFragment[];
}) {
  const [updatingGroups, setUpdatingGroups] = useState(false);
  const [activeTab, setActiveTab] = useState<SuccessConditionPerspective>();
  const [groupsToCreate, setGroupsToCreate] = useState<number[]>([]);
  const [groupsToDelete, setGroupsToDelete] = useState<number[]>([]);
  const [createdGroups, setCreatedGroups] = useState<number[]>([]);

  const [updateSuccessCondition] = useSuccessConditionUpdateMutation();

  const tabs = Object.keys(countBy(successConditions, 'perspective')).filter(
    (v) => v !== SuccessConditionPerspective.None
  ) as SuccessConditionPerspective[];

  const noBarriers = !successConditions.length;

  useEffect(() => {
    setCreatedGroups(
      successConditions.filter((sc) => sc.isRiskGroup).map((group) => group.id)
    );
  }, [successConditions]);

  useEffect(() => {
    if (!activeTab) {
      setActiveTab(tabs?.[0]);
    }
  }, [tabs, activeTab]);

  const manageCRUDGroups = (id: number, isRiskGroup: boolean) => {
    if (isRiskGroup) {
      setGroupsToDelete((groups) => {
        if (!groups.includes(id)) {
          return [...groups, id];
        }
        return groups.filter((group) => group !== id);
      });
    } else {
      setGroupsToCreate((groups) => {
        if (!groups.includes(id)) {
          return [...groups, id];
        }
        return groups.filter((group) => group !== id);
      });
    }
  };

  const sortedPerspectiveGroups = useMemo(
    () => groupAndSortConditions(successConditions),
    [successConditions]
  );

  return (
    <ModalLarge
      handleClose={() => {
        hideModal();
      }}
      visible={isVisible}
      size="small"
      headerColor={colors.cream}
      portalId="#riskMitigation"
      hideX={!noBarriers}
      hideMobileBack={!noBarriers}
      disableScroll
    >
      <Wrapper>
        <Heading>
          <Subtitle1>Choose risks to consider</Subtitle1>
          <BodyNormal>
            Select drivers and barriers which might give rise to risks worth
            considering
          </BodyNormal>
        </Heading>
        <Content>
          {noBarriers ? (
            <StyledEmptyState>
              <Subtitle2 color={colors.greyDark}>
                No drivers or barriers to consider as risks
              </Subtitle2>
              <BodySmall
                style={{ pointerEvents: 'all' }}
                color={colors.greyDark}
              >
                Leads should add them in{' '}
                <Link
                  to={`/d/${drugId}/strategy/${strategyId}/2_5/${strategicImperativeId}`}
                  style={{
                    cursor: 'pointer',
                    fontWeight: 500,
                    color: colors.greyDark,
                  }}
                >
                  2.5: Drivers and Barriers
                </Link>
              </BodySmall>
            </StyledEmptyState>
          ) : (
            <>
              <ModalContent>
                <ColumnWrapper>
                  {sortedPerspectiveGroups.map((perspectiveGroup) => {
                    if (perspectiveGroup.title === 'No perspective assigned') {
                      // Hide No perspective assigned group if empty
                      const groupHasValues = Object.values(
                        perspectiveGroup.data
                      ).flat();
                      if (!groupHasValues.length) {
                        return null;
                      }
                    }
                    return (
                      <StyledModalList key={perspectiveGroup.title}>
                        <BodyNormal>{perspectiveGroup.title}</BodyNormal>

                        {Object.values(perspectiveGroup.data).map(
                          (groupedConditions, idx) => {
                            if (
                              perspectiveGroup.title ===
                              'No perspective assigned'
                            ) {
                              // Hide the type if it's empty
                              if (!groupedConditions.length) {
                                return null;
                              }
                            }

                            // Drivers/Barriers
                            const titles = Object.keys(perspectiveGroup.data);
                            return (
                              <Fragment key={idx}>
                                <BodySmall
                                  color={
                                    idx ? colors.darkRed : colors.greenDark
                                  }
                                >
                                  {titles[idx]}s
                                </BodySmall>

                                {!groupedConditions.length ? (
                                  <BodySmall>None identified</BodySmall>
                                ) : (
                                  groupedConditions.map((sc) => {
                                    return (
                                      <CheckCircleWithLabel
                                        key={sc.id}
                                        className="cypress-risk-mitigation-risk-select"
                                        isDisabled={!!sc?.solutions?.length}
                                        complete={createdGroups.includes(sc.id)}
                                        onClick={() => {
                                          if (!sc?.solutions?.length) {
                                            setCreatedGroups((state) => {
                                              return state.includes(sc.id)
                                                ? state.filter(
                                                    (v) => v !== sc.id
                                                  )
                                                : [...state, sc.id];
                                            });
                                            manageCRUDGroups(
                                              sc.id,
                                              !!sc.isRiskGroup
                                            );
                                          }
                                        }}
                                      >
                                        <StyledCheckCircle
                                          isDisabled={!!sc?.solutions?.length}
                                          complete={createdGroups.includes(
                                            sc.id
                                          )}
                                        />
                                        <div>
                                          <BodySmall>{sc.text} </BodySmall>
                                          {!!sc?.solutions?.length && (
                                            <Caption color={colors.greyDark}>
                                              Delete all solutions to remove
                                            </Caption>
                                          )}
                                        </div>
                                      </CheckCircleWithLabel>
                                    );
                                  })
                                )}
                              </Fragment>
                            );
                          }
                        )}
                      </StyledModalList>
                    );
                  })}
                </ColumnWrapper>
              </ModalContent>
              <ModalCTAWrapper>
                <StyledModalButtonPill
                  level="secondary"
                  text="Cancel"
                  onClick={() => {
                    hideModal();
                  }}
                />

                <StyledModalButtonPill
                  clickClassName="cypress-risk-mitigation-risk-save"
                  level="primary"
                  text="Save"
                  onClick={async () => {
                    setUpdatingGroups(true);
                    for await (const id of groupsToCreate) {
                      if (!!id)
                        await updateSuccessCondition({
                          variables: {
                            id,
                            data: { isRiskGroup: true },
                          },
                        });
                    }

                    for await (const id of groupsToDelete) {
                      if (!!id) {
                        await updateSuccessCondition({
                          variables: {
                            id,
                            data: { isRiskGroup: false },
                          },
                        });
                      }
                    }
                    setUpdatingGroups(false);
                    hideModal();
                  }}
                  loading={updatingGroups}
                />
              </ModalCTAWrapper>
            </>
          )}
        </Content>
      </Wrapper>
    </ModalLarge>
  );
}

const groupAndSortConditions = (
  successConditions: SuccessConditionFragment[]
) => {
  const groupedPerspectives = successConditions.reduce(
    (acc, curr) => {
      const perspectiveIndex = curr.perspective as RelevantSuccessConditionTypes;
      // split into drivers and barriers within perspectives
      if (
        !!curr.type &&
        (curr.type === SuccessConditionType.Driver ||
          curr.type === SuccessConditionType.Barrier)
      ) {
        if (acc[perspectiveIndex][curr.type]) {
          acc[perspectiveIndex][curr.type].push(curr);
        } else {
          acc[perspectiveIndex][curr.type] = [curr];
        }
      }

      return acc;
    },
    {
      [SuccessConditionPerspective.Stakeholder]: {
        [SuccessConditionType.Driver]: [],
        [SuccessConditionType.Barrier]: [],
      },
      [SuccessConditionPerspective.Company]: {
        [SuccessConditionType.Driver]: [],
        [SuccessConditionType.Barrier]: [],
      },
      [SuccessConditionPerspective.Competitor]: {
        [SuccessConditionType.Driver]: [],
        [SuccessConditionType.Barrier]: [],
      },
      [SuccessConditionPerspective.None]: {
        [SuccessConditionType.Driver]: [],
        [SuccessConditionType.Barrier]: [],
      },
    } as Record<
      RelevantSuccessConditionTypes,
      Record<
        SuccessConditionType.Driver | SuccessConditionType.Barrier,
        SuccessConditionFragment[]
      >
    >
  );

  const sortedPerspectiveGroups = [
    { title: 'Stakeholder perspective', data: groupedPerspectives.Stakeholder },
    { title: 'Competitor perspective', data: groupedPerspectives.Competitor },
    { title: 'Company perspective', data: groupedPerspectives.Company },
    { title: 'No perspective assigned', data: groupedPerspectives.None },
  ];

  return sortedPerspectiveGroups;
};
