import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';

import { BodySmall } from 'components/shared';
import { colors } from 'constants/colors';
import { Stakeholder } from 'data/graphql/generated';

const Wrapper = styled.div`
  width: 100%;
`;

const Amounts = styled.div`
  position: relative;
  height: 20px;
`;

const Caption = styled(BodySmall)<{ amount: number }>`
  font-size: 12px;
  color: ${colors.greyDark};
  text-align: center;
  position: absolute;
  top: 0px;
  left: ${({ amount }) => `${amount}0%`};
  transform: translate3d(-50%, 0, 0);

  &:first-of-type {
    left: 0%;
    transform: translate3d(0, 0, 0);
  }
  &:last-of-type {
    left: 100%;
    transform: translate3d(-100%, 0, 0);
  }
`;

const BarWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 4px;
`;

const Bars = styled.div`
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  overflow: hidden;
`;

const Bar = styled.div<{ active: boolean; color: string }>`
  display: block;
  height: 6px;
  width: 10%;
  border-right: 1px solid ${colors.black02OnWhite};
  background: ${({ active, color }) => (active ? color : colors.greyMedium)};
  cursor: pointer;

  &:last-of-type {
    border-right: none;
  }
`;

const SliderThumb = css<{ color: string }>`
  width: 20px;
  height: 17px;
  border-radius: 4px;
  background: ${({ color }) => color};
  border: 2px solid ${colors.white};
  background-image: url('/future-trend-slider-bg.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px 13px;
`;

const Slider = styled.input<{ color: string }>`
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  z-index: 2;
  cursor: pointer;
  // this is to position it correctly over bars/numbers
  width: calc(100% + 10px);
  position: absolute;
  left: -5px;
  right: -5px;

  &:focus {
    outline: none;
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  // thumb
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    ${SliderThumb}
    margin-top: -5px;
  }

  &::-moz-range-thumb {
    ${SliderThumb}
    transform: translate3d(0, -5px, 0);
    width: 15px;
    height: 13px;
  }

  &::-ms-thumb {
    ${SliderThumb}
  }
`;

function getStakeholderColor(s: Stakeholder) {
  switch (s) {
    case Stakeholder.Patient:
      return colors.purple;
    case Stakeholder.Healthcare:
      return colors.green;
    case Stakeholder.Payor:
      return colors.blue;
    case Stakeholder.Provider:
      return colors.pink;
    default:
      return colors.greyMedium;
  }
}

interface Props {
  amount: number;
  updateYear(year: number): void;
  stakeholder: Stakeholder;
}

export const ImpactYear: React.FC<Props> = ({
  amount,
  updateYear,
  stakeholder,
}) => {
  const [year, setYear] = useState(amount);

  useEffect(() => {
    if (year !== amount) setYear(amount);
    // eslint-disable-next-line
  }, [amount, setYear]);

  return (
    <Wrapper>
      <Amounts>
        <Caption amount={0}>0</Caption>
        <Caption amount={3}>3</Caption>
        <Caption amount={5}>5</Caption>
        <Caption amount={7}>7</Caption>
        <Caption amount={10}>10</Caption>
      </Amounts>
      <BarWrapper>
        <Slider
          color={getStakeholderColor(stakeholder)}
          type="range"
          min="0"
          max="10"
          onBlur={() => {
            updateYear(year);
          }}
          value={year}
          onChange={(e) => {
            setYear(parseInt(e.currentTarget.value));
          }}
          onMouseUp={() => {
            updateYear(year);
          }}
        />
        <Bars>
          {[...Array(10)].map((_, idx) => (
            <Bar
              key={idx}
              color={getStakeholderColor(stakeholder)}
              active={idx >= year}
            />
          ))}
        </Bars>
      </BarWrapper>
    </Wrapper>
  );
};
