import { Loading } from 'components/Loading';
import {
  CompetitiveAdvantageRowFragment,
  TacticFragment,
  KeyEventFragment,
} from 'data/graphql/generated';
import { SummaryTimeline } from '.';

type ContainerProps = {
  loading: boolean;
  tactics: TacticFragment[];
  rows: CompetitiveAdvantageRowFragment[];
  isCountryTimeline: boolean;
  keyEvents: Record<string, KeyEventFragment[]>;
};

export function TimelineContainer({
  loading,
  tactics,
  rows,
  isCountryTimeline,
  keyEvents,
}: ContainerProps) {
  if (loading) {
    return <Loading />;
  }

  return (
    <SummaryTimeline
      tactics={tactics}
      rows={rows}
      isCountryTimeline={isCountryTimeline}
      keyEvents={keyEvents}
    />
  );
}
