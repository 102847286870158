import { BodyNormal, Heading3, Subtitle1 } from 'components/shared';
import { colors } from 'constants/index';
import React from 'react';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';

export const FlatOL = styled.ol`
  padding-inline-start: 20px;

  li {
    list-style-type: none;
  }
`;

export const ContentBodyNormal = styled(BodyNormal)`
  margin-bottom: 20px;
  a {
    color: inherit;
    cursor: pointer;
    pointer-events: all;
  }
`;

export const ContentWrapper = styled.div`
  background: ${colors.white};
  border-radius: 5px;

  width: 1164px;
  min-height: 615px;

  padding: 30px;

  ${Heading3} {
    margin-bottom: 30px;
  }

  ${Subtitle1} {
    margin-top: 30px;
    margin-bottom: 5px;
    color: ${colors.greyDark};
  }

  @media ${device.tabletMax} {
    width: 100%;
  }
`;

interface Props {}

export const TermsOfService = (props: Props) => {
  return (
    <ContentWrapper>
      <Heading3>Terms of Service</Heading3>

      <section>
        <FlatOL>
          <li>
            <ContentBodyNormal>
              a) Claroo Ltd. incorporated and registered in England and Wales
              with company number 13032052 whose registered office is at 7 Bell
              Yard, London, England, WC2A 2JR, United Kingdom (the "
              <strong>Supplier</strong>") has developed the NMBLR platform which
              it makes available to subscribers online on a pay-per-user basis
              for the purposes of the Customer (and its respective brands)
              launching new products and/or services.
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              b) The Customer wishes to use the Supplier's Services in launching
              new products and/or services.
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              c) The Supplier has agreed to provide, and the Customer has agreed
              to take and pay for the Supplier's Services subject to the terms
              and conditions of these terms and conditions (the "
              <strong>Conditions</strong>").
            </ContentBodyNormal>
          </li>
        </FlatOL>
      </section>

      <section>
        <Subtitle1>Interpretation</Subtitle1>
        <ContentBodyNormal>
          The definitions and rules of interpretation in this clause apply in
          these Conditions.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Account Manager:</strong> the Supplier's account manager as
          set out in the Order Form.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Additional Users:</strong> the users using the Services in
          addition to the Number of Users specified in the Order Form.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Agreed Currency:</strong> the currency which payment of
          Subscription Fees (and other charges due pursuant to the Order Form)
          shall be made, as set out in the Order Form.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Archiving and Back-Up Procedures:</strong> the source code is
          backed up on a daily basis to Amazon Web Services S3 ("AWS S3");
          packaged applications are backed up at deployment in AWS S3 over three
          devices; databases are backed up on a daily basis on a seven-day
          rolling retention period.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Authorised Users:</strong> those employees, agents and
          independent contractors of each of the brands (or assets, as the case
          may be), owned or controlled by the Customer, who are authorised by
          the Customer to use the Services and the Documentation.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Business Day:</strong> a day other than a Saturday, Sunday or
          public holiday in England when banks in London are open for business.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Commencement Date:</strong> the commencement date stated in
          the Order Form.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong> Confidential Information:</strong> information that is
          proprietary or confidential and is either clearly labelled as such or
          identified as Confidential Information in use 11.5 or 11.6.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Customer:</strong> the customer as set out in the Order Form.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Customer Contact:</strong> the individual named in the Order
          Form who is authorised to enter into these Conditions ((and execute
          any Order Forms) on behalf of the Customer.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Customer Data:</strong> the data inputted by the Customer,
          Authorised Users, or the Supplier on the Customer's behalf for the
          purpose of using the Services or facilitating the Customer's use of
          the Services.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Documentation:</strong> includes the documents and Training
          Material made available to the Customer by the Supplier in any manner,
          which, among other things, set out a description of the Services and
          the user instructions for the Services, together with any engagement
          or communication with the Customer to finalise the content of the
          Training Material, also available to the Customer as part of the
          Service.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Feedback Data:</strong> data that can be collected from NMBLR
          that can and shall be used for the purposes of providing feedback to
          the Supplier in relation to the Services.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>NMBLR:</strong> a digital platform developed by the Supplier
          that provides strategic and business development support to a Company
          (and its brands) to launch new products and services.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Normal Business Hours:</strong> 8:00AM to 6:00PM, which are
          the normal business hours of the Customer for each Business Day.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Number of Users:</strong> the number of users of the Service
          as set out in each Order Form.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Order Form:</strong> the form provided and drafted by the
          Supplier, which is sent to the Customer by email for review and
          approval, setting out the commercial terms of any order. A template
          Order Form can be accessed via{' '}
          <strong>
            <a href="mailto:janice@nmblr.co">janice@nmblr.co</a>
          </strong>{' '}
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Parties:</strong> the Supplier and the Customer.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Payment Terms:</strong> payment terms as set out in the Order
          Form.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Process Data:</strong> data communicated to the Customer based
          on Customer Data, for the purposes of the supply (and delivery) of the
          Services.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Services:</strong> the subscription services provided by the
          Supplier to the Customer under these Conditions via{' '}
          <strong>
            <a target="_blank" href="https://nmblr.co/" rel="noreferrer">
              app.nmblr.co
            </a>
          </strong>{' '}
          or any other website notified to the Customer by the Supplier from
          time to time, as more particularly described below and in the Order
          Form, including the use of NMBLR and access to Support Services.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Software:</strong> the online software applications provided
          by the Supplier as part of the Services.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Subscription Fees:</strong> the subscription fees payable by
          the Customer to the Supplier for the User Subscriptions, as set out in
          the Order Form.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Subscription Term:</strong> has the meaning given in the Order
          Form.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Support Services:</strong> includes technology support
          services, and business development support services, limited to
          fifteen (15) hours of use per brand per Customer.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Training Material:</strong> any material, information, data,
          and other services to be used for the purposes of training and
          supporting NMBLR, prepared by the Parties, in relation to the
          Services.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>User Subscriptions:</strong> the user subscriptions purchased
          by the Customer pursuant to 9.1 which entitle Authorised Users to
          access and use the Services and the Documentation in accordance with
          these Conditions.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Virus:</strong> any thing or device (including any software,
          code, file or programme) which may: prevent, impair or otherwise
          adversely affect the operation of any computer software, hardware or
          network, any telecommunications service, equipment or network or any
          other service or device; prevent, impair or otherwise adversely affect
          access to or the operation of any programme or data, including the
          reliability of any programme or data (whether by re-arranging,
          altering or erasing the programme or data in whole or part or
          otherwise); or adversely affect the user experience, including worms,
          trojan horses, viruses and other similar things or devices.
        </ContentBodyNormal>
        <ContentBodyNormal>
          <strong>Invoice:</strong> any invoice raised by the Supplier to the
          Customer for the provision of the Services.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Company and user subscriptions</Subtitle1>
        <ContentBodyNormal>
          Subject to the Customer purchasing the User Subscriptions on behalf of
          each of its relevant brands (or assets, as the case may be), in
          accordance with 3.3 and 9.1, the restrictions set out in this se 2,
          the Customer providing valid contact information for each Authorised
          User and the other terms and conditions of these Conditions, the
          Supplier hereby grants to the Customer a non-exclusive,
          non-transferable right, without the right to grant sublicenses, to
          permit the Authorised Users to use the Services and the Documentation
          during the Subscription Term solely for the Customer's internal
          business operations.
        </ContentBodyNormal>
        <ContentBodyNormal>
          In relation to the Authorised Users, the Customer undertakes that:
        </ContentBodyNormal>
        <FlatOL>
          <li>
            <ContentBodyNormal>
              a) only designated individuals, each of whom is a key person in
              relation to the launching and development of the product and/or
              service (each, a "<strong>Designated Representative</strong>"),
              shall have access to the information contained on the platform for
              the purposes of downloading and otherwise, exporting such
              information.
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              b) the maximum number of Authorised Users that it authorises to
              access and use the Services and the Documentation shall not exceed
              the number of User Subscriptions it has purchased from time to
              time;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              c) it will not allow or suffer any User Subscription to be used by
              more than one individual Authorised User unless it has been
              reassigned in its entirety to another individual Authorised User,
              in which case the prior Authorised User shall no longer have any
              right to access or use the Services and/or Documentation;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              d) each Authorised User shall keep a secure password for their use
              of the Services and Documentation, that such password shall be
              changed no less frequently than three (3) months and that each
              Authorised User shall keep their password confidential;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              e) if the Supplier determines that the Customer has not paid the
              Subscription Fees (in part or in full) to the Supplier as a result
              of a breach of clauses 2.2(a) and 2.2 (b), the Customer shall pay
              to the Supplier an amount equal to such underpayment as calculated
              in accordance with the prices set out in the relevant Order Form
              within three (3) Business Days of such demand made to the
              Customer.
            </ContentBodyNormal>
          </li>
        </FlatOL>
        <ContentBodyNormal>
          The Customer shall not access, store, distribute or transmit any
          Viruses, or any material during the course of its use of the Services
          that:
        </ContentBodyNormal>
        <FlatOL>
          <li>
            <ContentBodyNormal>
              a) is unlawful, harmful, threatening, defamatory, obscene,
              infringing, harassing or racially or ethnically offensive;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              b) facilitates illegal activity;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              c) depicts sexually explicit images;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              d) promotes unlawful violence;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              e) is discriminatory based on race, gender, colour, religious
              belief, sexual orientation, disability; or
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              f) is otherwise illegal or causes damage or injury to any person
              or property;
            </ContentBodyNormal>
          </li>
        </FlatOL>
        <ContentBodyNormal>
          and the Supplier reserves the right, without liability or prejudice to
          its other rights to the Customer, to disable the Customer's access to
          any material that breaches the provisions of this clause.
        </ContentBodyNormal>
        <ContentBodyNormal>The Customer shall not:</ContentBodyNormal>
        <FlatOL>
          <li>
            <ContentBodyNormal>
              a) permit any party that is not a Designated Representative access
              to the information contained on the platform for the purposes of
              downloading and otherwise, exporting such information;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              b) except as may be allowed by any applicable law which is
              incapable of exclusion by agreement between the parties and except
              to the extent expressly permitted under these Conditions:{' '}
            </ContentBodyNormal>
          </li>
          <FlatOL>
            <li>
              <ContentBodyNormal>
                (i) attempt to copy, modify, duplicate, create derivative works
                from, frame, mirror, republish, download, display, transmit, or
                distribute all or any portion of the Software and/or
                Documentation (as applicable) in any form or media or by any
                means; or
              </ContentBodyNormal>
            </li>
            <li>
              <ContentBodyNormal>
                (ii) attempt to de-compile, reverse compile, disassemble,
                reverse engineer or otherwise reduce to human-perceivable form
                all or any part of the Software;
              </ContentBodyNormal>
            </li>
          </FlatOL>
          <li>
            <ContentBodyNormal>
              c) access all or any part of the Services and Documentation in
              order to build a product or service which competes with the
              Services;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              d) use the Services and/or Documentation to provide services to
              third parties;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              e) subject to 22.1, license, sell, rent, lease, transfer, assign,
              distribute, display, disclose, or otherwise commercially exploit,
              or otherwise make the Services and/or Documentation available to
              any third party except the Authorised Users;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              f) attempt to obtain, or assist third parties in obtaining, access
              to the Services and/or Documentation, other than as provided under
              this 2; or
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              g) introduce or permit the introduction of, any Virus into the
              Supplier's network and information systems in any manner.
            </ContentBodyNormal>
          </li>
        </FlatOL>
        <ContentBodyNormal>
          Notwithstanding clause 2.4(a), the Customer shall use best endeavours
          to prevent any unauthorised access to, or use of, the Services and/or
          the Documentation and, in the event of any such unauthorised access or
          use, the Customer must promptly notify the Supplier and shall bear any
          liabilities arising out of such unauthorised access or use.
        </ContentBodyNormal>
        <ContentBodyNormal>
          The rights provided under this 2 are granted to the Customer only, and
          shall not be considered granted to any affiliate, subsidiary or
          holding company of the Customer.
        </ContentBodyNormal>
      </section>
      <section>
        <Subtitle1>Additional asset/brand subscriptions</Subtitle1>
        <ContentBodyNormal>
          Subject to 3.2 and 3.3, the Customer may, from time to time during any
          Subscription Term, purchase additional User Subscriptions for
          additional brands (or assets, as the case may be) and the Supplier
          shall grant access to the Services and the Documentation to such
          Additional Users in accordance with the provisions of these
          Conditions.
        </ContentBodyNormal>
        <ContentBodyNormal>
          If the Customer wishes to purchase additional User Subscriptions, the
          Customer shall notify the Supplier in writing. The Supplier shall
          evaluate such request for additional User Subscriptions and respond to
          the Customer with approval or rejection of the request (such approval
          not to be unreasonably withheld). Where the Supplier approves the
          request, the Supplier shall activate the User Subscriptions of the
          Additional Users within three (3) Business Days of its approval of the
          Customer's request, unless agreed otherwise.
        </ContentBodyNormal>
        <ContentBodyNormal>
          If the Supplier approves the Customer's request to purchase additional
          User Subscriptions, the Customer shall, within thirty (30) days of the
          date of the Supplier's Invoice, pay to the Supplier the relevant
          Subscription Fees for such Additional Users. Unless agreed otherwise,
          the Subscription Fees for Additional Users shall be Subscription Fee
          agreed in the most recent Order Form between the Parties.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Services</Subtitle1>
        <ContentBodyNormal>
          The Supplier shall, during the Subscription Term, provide the Services
          and make available the Documentation to the Customer on and subject to
          the terms of these Conditions.
        </ContentBodyNormal>
        <ContentBodyNormal>
          The Supplier shall use commercially reasonable endeavours to make the
          Services available during Normal Business Hours.
        </ContentBodyNormal>
        <ContentBodyNormal>
          The Supplier will, as part of the Services and at no additional cost
          to the Customer provide the Customer with Support Services during
          Normal Business Hours at its discretion and upon request by the
          Customer. Any further Support Services shall be provided subject to
          separate terms and conditions agreed between the Supplier and the
          Customer.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Data protection</Subtitle1>
        <ContentBodyNormal>
          The Customer shall own all right, title and interest in and to all of
          the Customer Data and shall have sole responsibility for the legality,
          reliability, integrity, accuracy and quality of the Customer Data.
        </ContentBodyNormal>
        <ContentBodyNormal>
          The Supplier shall own all right, title and interest in and to all of
          the Feedback Data, Process Data, and data contained in (or otherwise
          in relation to) Training Materials and the supply of the Services
          (including but not limited to the Support Services).
        </ContentBodyNormal>
        <ContentBodyNormal>
          The Supplier shall follow its Archiving and Back-Up Procedures for
          Customer Data. In the event of any loss or damage to Customer Data,
          the Customer’s sole and exclusive remedy shall be for the Supplier to
          use commercially reasonable endeavours to restore the lost or damaged
          Customer Data from the latest back-up of such Customer Data maintained
          by the Supplier. The Supplier shall not be responsible for any loss,
          destruction, alteration or disclosure of Customer Data caused by any
          third party (except those third parties sub-contracted by the Supplier
          to perform services related to Customer Data maintenance and back-up).
        </ContentBodyNormal>
        <ContentBodyNormal>
          The Supplier shall, in providing the Services, comply with its Privacy
          Policy relating to the privacy and security of the Customer Data or
          such other website address as may be notified to the Customer from
          time to time, as such document may be amended from time to time by the
          Supplier in its sole discretion.
        </ContentBodyNormal>
        <ContentBodyNormal>
          If the Supplier processes any personal data on the Customer’s behalf
          when performing its obligations under these Conditions, the parties
          record their intention that the Customer shall be the data controller
          and the Supplier shall be a data processor and in any such case:
        </ContentBodyNormal>

        <FlatOL>
          <li>
            <ContentBodyNormal>
              a) the Customer acknowledges and agrees that the personal data may
              be transferred or stored outside the EEA or the country where the
              Customer and the Authorised Users are located in order to carry
              out the Services and the Supplier’s other obligations under these
              Conditions;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              b) the Customer shall ensure that the Customer is entitled to
              transfer the relevant personal data to the Supplier so that the
              Supplier may lawfully use, process and transfer the personal data
              in accordance with these Conditions on the Customer’s behalf;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              c) the Customer shall ensure that the relevant third parties have
              been informed of, and have given their consent to, such use,
              processing, and transfer as required by all applicable data
              protection legislation; and
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              d) each party shall take appropriate technical and organisational
              measures against unauthorised or unlawful processing of the
              personal data or its accidental loss, destruction or damage.
            </ContentBodyNormal>
          </li>
        </FlatOL>
        <ContentBodyNormal>
          Notwithstanding the provisions in the Privacy Policy, the Supplier
          shall at its own expense, as soon as practicable after receipt of a
          demand in writing from the Customer, return to the Customer (or if in
          electronic form, take all reasonably practicable steps to permanently
          erase from computer media) all original and copy documents containing
          Customer Data or otherwise, destroy (or if in electronic form, take
          all reasonably practicable steps to permanently erase from computer
          media) all original and copy documents containing Customer Data.
        </ContentBodyNormal>
        <ContentBodyNormal>
          Notwithstanding the provisions of clause 5.6, the Supplier shall not
          be required to destroy or, where in electronic form, erase:
        </ContentBodyNormal>

        <FlatOL>
          <li>
            <ContentBodyNormal>
              a) any Customer Data that it is required to retain by any
              applicable law or regulation;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              b) electronic records on back-up storage tapes or a similar medium
              where to destroy such electronic records would be significantly
              impractical or prohibitively costly; or
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              c) any information contained in papers submitted to, or minutes
              made of, any decision-making committee (including, but not limited
              to, the board of directors) of the Supplier.
            </ContentBodyNormal>
          </li>
        </FlatOL>
      </section>

      <section>
        <Subtitle1>Third party providers</Subtitle1>
        <ContentBodyNormal>
          The Customer acknowledges that the Services may enable or assist it to
          access the website content of, correspond with, and purchase products
          and services from, third parties via third-party websites and that it
          does so solely at its own risk. The Supplier makes no representation,
          warranty or commitment and shall have no liability or obligation
          whatsoever in relation to the content or use of, or correspondence
          with, any such third-party website, or any transactions completed, and
          any contract entered into by the Customer, with any such third party.
          Any contract entered into and any transaction completed via any
          third-party website is between the Customer and the relevant third
          party, and not the Supplier. The Supplier recommends that the Customer
          refers to the third party's website terms and conditions and privacy
          policy prior to using the relevant third-party website. The Supplier
          does not endorse or approve any third-party website nor the content of
          any of the third-party website made available via the Services.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Supplier's obligations</Subtitle1>
        <ContentBodyNormal>
          The Supplier undertakes that the Services will be performed
          substantially in accordance with the Documentation and with reasonable
          skill and care.
        </ContentBodyNormal>
        <ContentBodyNormal>
          The undertaking at 7.1 shall not apply to the extent of any
          non-conformance which is caused by use of the Services contrary to the
          Supplier's instructions, or modification or alteration of the Services
          by any party other than the Supplier or the Supplier's duly authorised
          contractors or agents. If the Services do not conform with the
          foregoing undertaking, Supplier will, at its expense, use all
          reasonable commercial endeavours to correct any such non-conformance
          promptly, or provide the Customer with an alternative means of
          accomplishing the desired performance. Such correction or substitution
          constitutes the Customer's sole and exclusive remedy for any breach of
          the undertaking set out in 7.1.
        </ContentBodyNormal>
        <ContentBodyNormal>The Supplier:</ContentBodyNormal>
        <ContentBodyNormal>does not warrant that:</ContentBodyNormal>
        <FlatOL style={{ paddingLeft: 20 }}>
          <li>
            <ContentBodyNormal>
              (i) the Customer's use of the Services will be uninterrupted or
              error-free;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              (ii) the Services, Documentation and/or the information obtained
              by the Customer through the Services will meet the Customer's
              requirements; or
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              (iii) the Software or the Services will be free from
              Vulnerabilities or Viruses.
            </ContentBodyNormal>
          </li>
        </FlatOL>
        <ContentBodyNormal>
          These Conditions shall not prevent the Supplier from entering into
          similar agreements with third parties, or from independently
          developing, using, selling or licensing documentation, products and/or
          services which are similar to those provided under these Conditions.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Customer's obligations</Subtitle1>
        <ContentBodyNormal>The Customer shall:</ContentBodyNormal>

        <FlatOL>
          <li>
            <ContentBodyNormal>
              a) notwithstanding other provisions in this clause 8.1, pay the
              Subscription Fee by the due date for such payment;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>b) provide the Supplier with:</ContentBodyNormal>
          </li>
          <FlatOL>
            <li>
              <ContentBodyNormal>
                (i) all necessary co-operation in relation to these Conditions;
                and
              </ContentBodyNormal>
            </li>
            <li>
              <ContentBodyNormal>
                (ii) all necessary access to such information as may be required
                by the Supplier;
              </ContentBodyNormal>
            </li>
            <ContentBodyNormal>
              in order to provide the Services, including but not limited to
              Customer Data, security access information and configuration
              services;
            </ContentBodyNormal>
          </FlatOL>
          <li>
            <ContentBodyNormal>
              c) comply with all applicable laws and regulations with respect to
              its activities under these Conditions;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              d) carry out all other Customer responsibilities set out in these
              Conditions in a timely and efficient manner. In the event of any
              delays in the Customer's provision of such assistance as agreed by
              the parties, the Supplier may adjust any agreed timetable or
              delivery schedule as reasonably necessary;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              e) ensure that the Authorised Users use the Services and the
              Documentation in accordance with the terms and conditions of these
              Conditions and shall be responsible for any Authorised User's
              breach of these Conditions;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              f) obtain and shall maintain all necessary licences, consents, and
              permissions necessary for the Supplier, its contractors and agents
              to perform their obligations under these Conditions, including
              without limitation the Services;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              g) ensure that its network and systems comply with the relevant
              specifications provided by the Supplier from time to time; and
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              h) be, solely responsible for procuring, maintaining and securing
              its network connections and telecommunications links from its
              systems to the Supplier's data centres, and all problems,
              conditions, delays, delivery failures and all other loss or damage
              arising from or relating to the Customer's network connections or
              telecommunications links or caused by the internet.
            </ContentBodyNormal>
          </li>
        </FlatOL>
      </section>

      <section>
        <Subtitle1>Charges and payment</Subtitle1>

        <ContentBodyNormal>
          The Customer shall pay the Subscription Fees to the Supplier for the
          User Subscriptions in accordance with this 9 and the Order Form.
        </ContentBodyNormal>
        <ContentBodyNormal>
          Unless otherwise agreed between the Parties, the Supplier shall
          invoice the Customer on or after the Commencement Date for the
          Subscription Fees payable in respect of the Subscription Term and the
          Customer shall pay each Invoice within the Payment Terms.
        </ContentBodyNormal>
        <ContentBodyNormal>
          If the Supplier has not received payment within 10 days after the due
          date of the Invoice, and without prejudice to any other rights and
          remedies of the Supplier:
        </ContentBodyNormal>

        <FlatOL>
          <li>
            <ContentBodyNormal>
              a) the Supplier may, without liability to the Customer, disable
              the Customer's password, account and access to all or part of the
              Services and the Supplier shall be under no obligation to provide
              any or all of the Services while the invoice(s) concerned remain
              unpaid; and
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              b) interest shall accrue on a daily basis on such due amounts at
              an annual rate equal to 3% over the then current base lending rate
              of the Supplier's bankers in the UK from time to time, commencing
              on the due date and continuing until fully paid, whether before or
              after judgment.
            </ContentBodyNormal>
          </li>
        </FlatOL>
        <ContentBodyNormal>
          All amounts and fees stated or referred to in these Conditions:
        </ContentBodyNormal>
        <FlatOL>
          <li>
            <ContentBodyNormal>
              a) shall be payable in the Agreed Currency;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              b) are, subject to 13.3(b), non-cancellable and non-refundable;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              c) are exclusive of value added tax, which shall be added to the
              Supplier's invoice(s) at the appropriate rate.
            </ContentBodyNormal>
          </li>
        </FlatOL>
        <ContentBodyNormal>
          The Supplier shall be entitled to increase the Subscription Fees, the
          fees payable in respect of the additional User Subscriptions purchased
          pursuant to 3.3, the additional charges for further Support Services
          pursuant to 4.3 upon 10 Business Days' prior notice to the Customer
          and the Order Form shall be deemed to have been amended accordingly.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Proprietary rights</Subtitle1>
        <ContentBodyNormal>
          The Customer acknowledges and agrees that the Supplier and/or its
          licensors own all intellectual property rights in the Services and the
          Documentation. Except as expressly stated herein, these Conditions
          does not grant the Customer any rights to, under or in, any patents,
          copyright, database right, trade secrets, trade names, trademarks
          (whether registered or unregistered), or any other rights or licences
          in respect of the Services or the Documentation.
        </ContentBodyNormal>
        <ContentBodyNormal>
          The Supplier confirms that it has all the rights in relation to the
          Services and the Documentation that are necessary to grant all the
          rights it purports to grant under, and in accordance with, the terms
          of these Conditions.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Confidentiality</Subtitle1>
        <ContentBodyNormal>
          Each party may be given access to Confidential Information from the
          other party in order to perform its obligations under these
          Conditions. A party's Confidential Information shall not be deemed to
          include information that:
        </ContentBodyNormal>

        <FlatOL>
          <li>
            <ContentBodyNormal>
              a) is or becomes publicly known other than through any act or
              omission of the receiving party;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              b) was in the other party's lawful possession before the
              disclosure;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              c) is lawfully disclosed to the receiving party by a third party
              without restriction on disclosure; or
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              d) is independently developed by the receiving party, which
              independent development can be shown by written evidence.
            </ContentBodyNormal>
          </li>
        </FlatOL>
        <ContentBodyNormal>
          Each party shall hold the other's Confidential Information in
          confidence and not make the other's Confidential Information available
          to any third party, or use the other's Confidential Information for
          any purpose other than the implementation of these Conditions.
        </ContentBodyNormal>
        <ContentBodyNormal>
          Each party shall take all reasonable steps to ensure that the other's
          Confidential Information to which it has access is not disclosed or
          distributed by its employees or agents in violation of the terms of
          these Conditions.
        </ContentBodyNormal>
        <ContentBodyNormal>
          A party may disclose Confidential Information to the extent such
          Confidential Information is required to be disclosed by law, by any
          governmental or other regulatory authority or by a court or other
          authority of competent jurisdiction, provided that, to the extent it
          is legally permitted to do so, it gives the other party as much notice
          of such disclosure as possible and, where notice of disclosure is not
          prohibited and is given in accordance with this 11.4, it takes into
          account the reasonable requests of the other party in relation to the
          content of such disclosure.
        </ContentBodyNormal>
        <ContentBodyNormal>
          The Customer acknowledges that details of the Services, and the
          results of any performance tests of the Services, constitute the
          Supplier's Confidential Information.
        </ContentBodyNormal>
        <ContentBodyNormal>
          The Supplier acknowledges that the Customer Data is the Confidential
          Information of the Customer.
        </ContentBodyNormal>
        <ContentBodyNormal>
          No party shall make, or permit any person to make, any public
          announcement concerning these Conditions without the prior written
          consent of the other parties (such consent not to be unreasonably
          withheld or delayed), except as required by law, any governmental or
          regulatory authority (including, without limitation, any relevant
          securities exchange), any court or other authority of competent
          jurisdiction.
        </ContentBodyNormal>
        <ContentBodyNormal>
          The above provisions of this ause 11 shall survive termination of
          these Conditions, however arising.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Indemnity</Subtitle1>

        <ContentBodyNormal>
          The Customer shall defend, indemnify and hold harmless the Supplier
          against claims, actions, proceedings, losses, damages, expenses and
          costs (including without limitation court costs and reasonable legal
          fees) arising out of or in connection with the Customer's use of the
          Services and/or Documentation, provided that:
        </ContentBodyNormal>

        <FlatOL>
          <li>
            <ContentBodyNormal>
              a) the Customer is given prompt notice of any such claim;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              b) the Supplier provides reasonable co-operation to the Customer
              in the defence and settlement of such claim, at the Customer's
              expense; and
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              c) the Customer is given sole authority to defend or settle the
              claim.
            </ContentBodyNormal>
          </li>
        </FlatOL>
        <ContentBodyNormal>
          The Supplier shall defend the Customer, its officers, directors and
          employees against any claim that the Customer's use of the Services or
          Documentation in accordance with these Conditions infringes any patent
          effective as of the Effective Date, copyright, trade mark, database
          right or right of confidentiality, and shall indemnify the Customer
          for any amounts awarded against the Customer in judgment or settlement
          of such claims, provided that:
        </ContentBodyNormal>
        <FlatOL>
          <li>
            <ContentBodyNormal>
              a) the Supplier is given prompt notice of any such claim;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              b) the Customer does not make any admission, or otherwise attempt
              to compromise or settle the claim and provides reasonable
              co-operation to the Supplier in the defence and settlement of such
              claim, at the Supplier's expense; and
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              c) the Supplier is given sole authority to defend or settle the
              claim.
            </ContentBodyNormal>
          </li>
        </FlatOL>
        <ContentBodyNormal>
          In the defence or settlement of any claim, the Supplier may procure
          the right for the Customer to continue using the Services, replace or
          modify the Services so that they become non-infringing or, if such
          remedies are not reasonably available, terminate these Conditions on 2
          Business Days' notice to the Customer without any additional liability
          or obligation to pay liquidated damages or other additional costs to
          the Customer.
        </ContentBodyNormal>
        <ContentBodyNormal>
          In no event shall the Supplier, its employees, agents and
          sub-contractors be liable to the Customer to the extent that the
          alleged infringement is based on:
        </ContentBodyNormal>

        <FlatOL>
          <li>
            <ContentBodyNormal>
              a) a modification of the Services or Documentation by anyone other
              than the Supplier;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              b) the Customer's use of the Services or Documentation in a manner
              contrary to the instructions given to the Customer by the
              Supplier; or
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              c) the Customer's use of the Services or Documentation after
              notice of the alleged or actual infringement from the Supplier or
              any appropriate authority.
            </ContentBodyNormal>
          </li>
        </FlatOL>
        <ContentBodyNormal>
          The foregoing and 13.3(b) state the Customer's sole and exclusive
          rights and remedies, and the Supplier's (including the Supplier's
          employees', agents' and sub-contractors') entire obligations and
          liability, for infringement of any patent, copyright, trade mark,
          database right or right of confidentiality.
        </ContentBodyNormal>
        <ContentBodyNormal>
          The Customer shall indemnify the Supplier against all liabilities,
          costs, expenses, damages and losses (including any direct, indirect or
          consequential losses, loss of profit, loss of reputation and all
          interest, penalties and legal and other professional costs and
          expenses) suffered or incurred by the Supplier arising out of or in
          connection with any claim made against the Supplier for actual or
          alleged infringement of a third party’s intellectual property rights,
          actual or alleged defamation, or otherwise, arising out of or in
          connection with use of the Training Material and/or Customer Data by
          the Supplier in the course of providing the Services.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Limitation of liability</Subtitle1>
        <ContentBodyNormal>
          This clause 13 sets out the entire financial liability of the Supplier
          (including any liability for the acts or omissions of its employees,
          agents and sub-contractors) to the Customer:
        </ContentBodyNormal>
        <FlatOL>
          <li>
            <ContentBodyNormal>
              a) arising under or in connection with these Conditions;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              b) in respect of any use made by the Customer of the Services and
              Documentation or any part of them; and
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              c) in respect of any representation, statement or tortious act or
              omission (including negligence) arising under or in connection
              with these Conditions.
            </ContentBodyNormal>
          </li>
        </FlatOL>
        <ContentBodyNormal>
          Except as expressly and specially provided in these Conditions:
        </ContentBodyNormal>
        <FlatOL>
          <li>
            <ContentBodyNormal>
              a) the Customer assumes sole responsibility for results obtained
              from the use of the Services and the Documentation by the
              Customer, and for conclusions drawn from such use. The Supplier
              shall have no liability for any damage caused by errors or
              omissions in any information, instructions or scripts provided to
              the Supplier by the Customer in connection with the Services, or
              any actions taken by the Supplier at the Customer's direction;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              b) all warranties, representations, conditions and all other terms
              of any kind whatsoever implied by statute or common law are, to
              the fullest extent permitted by applicable law, excluded from
              these Conditions; and
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              c) the Services and the Documentation are provided to the Customer
              on an "as is" basis.
            </ContentBodyNormal>
          </li>
        </FlatOL>
        <ContentBodyNormal>
          Nothing in these Conditions excludes the liability of the Supplier:
        </ContentBodyNormal>
        <FlatOL>
          <li>
            <ContentBodyNormal>
              a) for death or personal injury caused by the Supplier's
              negligence; or
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              b) for fraud or fraudulent misrepresentation.
            </ContentBodyNormal>
          </li>
        </FlatOL>
        <ContentBodyNormal>Subject to s 13.1 to 13.3:</ContentBodyNormal>
        <FlatOL>
          <li>
            <ContentBodyNormal>
              a) the Supplier shall not be liable whether in tort (including for
              negligence or breach of statutory duty), contract,
              misrepresentation, restitution or otherwise for any loss of
              profits, loss of business, depletion of goodwill and/or similar
              losses or loss or corruption of data or information, or pure
              economic loss, or for any special, indirect or consequential loss,
              costs, damages, charges or expenses however arising under these
              Conditions; and
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              b) the Supplier's total aggregate liability in contract (including
              in respect of the indemnity at use 12.2), tort (including
              negligence or breach of statutory duty), misrepresentation,
              restitution or otherwise, arising in connection with the
              performance or contemplated performance of these Conditions shall
              be subject to a maximum figure of £1,000,000 (one million pound
              sterling).
            </ContentBodyNormal>
          </li>
        </FlatOL>
        <ContentBodyNormal>
          Nothing in these Conditions excludes the liability of the Customer for
          any breach, infringement or misappropriation of the Supplier’s
          Intellectual Property Rights.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Term and termination</Subtitle1>
        <ContentBodyNormal>
          These Conditions shall, unless otherwise terminated as provided in
          this 14, commence on the Commencement Date and shall continue for
          Subscription Term and thereafter, these Conditions shall continue
          until either Party provides the other Party with three (3) months
          written notice of termination, unless otherwise agreed in the Order
          Form.
        </ContentBodyNormal>
        <ContentBodyNormal>
          Without affecting any other right or remedy available to it, either
          party may terminate these Conditions with immediate effect by giving
          written notice to the other party if:
        </ContentBodyNormal>
        <FlatOL>
          <li>
            <ContentBodyNormal>
              a) the other party fails to pay any amount due under these
              Conditions on the due date for payment and remains in default not
              fewer than 10 days after being notified in writing to make such
              payment;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              b) the other party commits a material breach of any other term of
              these Conditions and (if such breach is remediable) fails to
              remedy that breach within a period of 20 days after being notified
              in writing to do so;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              c) the other party suspends, or threatens to suspend, payment of
              its debts or is unable to pay its debts as they fall due or admits
              inability to pay its debts or is deemed unable to pay its debts
              within the meaning of section 123 of the Insolvency Act 1986;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              d) the other party commences negotiations with all or any class of
              its creditors with a view to rescheduling any of its debts, or
              makes a proposal for or enters into any compromise or arrangement
              with its creditors other than for the sole purpose of a scheme for
              a solvent amalgamation of that other party with one or more other
              companies or the solvent reconstruction of that other party;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              e) the other party applies to court for, or obtains, a moratorium
              under Part A1 of the Insolvency Act 1986;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              f) a petition is filed, a notice is given, a resolution is passed,
              or an order is made, for or in connection with the winding up of
              that other party other than for the sole purpose of a scheme for a
              solvent amalgamation of that other party with one or more other
              companies or the solvent reconstruction of that other party;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              g) an application is made to court, or an order is made, for the
              appointment of an administrator, or if a notice of intention to
              appoint an administrator is given or if an administrator is
              appointed, over the other party (being a company, partnership or
              limited liability partnership);
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              h) the holder of a qualifying floating charge over the assets of
              that other party (being a company or limited liability
              partnership) has become entitled to appoint or has appointed an
              administrative receiver;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              i) a person becomes entitled to appoint a receiver over the assets
              of the other party or a receiver is appointed over the assets of
              the other party;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              j) a creditor or encumbrancer of the other party attaches or takes
              possession of, or a distress, execution, sequestration or other
              such process is levied or enforced on or sued against, the whole
              or any part of the other party's assets and such attachment or
              process is not discharged within 14 days;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              k) any event occurs, or proceeding is taken, with respect to the
              other party in any jurisdiction to which it is subject that has an
              effect equivalent or similar to any of the events mentioned in
              14.2(c) to 14.2 (j) (inclusive);
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              l) the other party suspends or ceases, or threatens to suspend or
              cease, carrying on all or a substantial part of its business; or
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              m) there is a change of control of the other party (within the
              meaning of section 1124 of the Corporation Tax Act 2010).
            </ContentBodyNormal>
          </li>
        </FlatOL>
        <ContentBodyNormal>
          On termination of these Conditions for any reason:
        </ContentBodyNormal>
        <FlatOL>
          <li>
            <ContentBodyNormal>
              a) all licences granted under these Conditions shall immediately
              terminate;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              b) each party shall return and make no further use of any
              equipment, property, Documentation and other items (and all copies
              of them) belonging to the other party;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              c) the Supplier may destroy or otherwise dispose of any of the
              Customer Data in its possession; and
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              d) any rights, remedies, obligations or liabilities of the parties
              that have accrued up to the date of termination, including the
              right to claim damages in respect of any breach of the agreement
              which existed at or before the date of termination shall not be
              affected or prejudiced.
            </ContentBodyNormal>
          </li>
        </FlatOL>
      </section>

      <section>
        <Subtitle1>Force majeure</Subtitle1>
        <ContentBodyNormal>
          The Supplier shall have no liability to the Customer under these
          Conditions if it is prevented from or delayed in performing its
          obligations under these Conditions, or from carrying on its business,
          by acts, events, omissions or accidents beyond its reasonable control,
          including, without limitation, strikes, lock-outs or other industrial
          disputes (whether involving the workforce of the Supplier or any other
          party), failure of a utility service or transport or
          telecommunications network, act of God, war, riot, civil commotion,
          malicious damage, compliance with any law or governmental order, rule,
          regulation or direction, accident, breakdown of plant or machinery,
          fire, flood, storm or default of suppliers or sub-contractors,
          provided that the Customer is notified of such an event and its
          expected duration.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Conflict</Subtitle1>
        <ContentBodyNormal>
          If there is an inconsistency between any of the provisions in the main
          body of these Conditions and the Schedules, the provisions in the main
          body of these Conditions shall prevail.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Waiver</Subtitle1>
        <ContentBodyNormal>
          No failure or delay by a party to exercise any right or remedy
          provided under these Conditions or by law shall constitute a waiver of
          that or any other right or remedy, nor shall it prevent or restrict
          the further exercise of that or any other right or remedy. No single
          or partial exercise of such right or remedy shall prevent or restrict
          the further exercise of that or any other right or remedy.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Rights and remedies</Subtitle1>
        <ContentBodyNormal>
          Except as expressly provided in these Conditions, the rights and
          remedies provided under these Conditions are in addition to, and not
          exclusive of, any rights or remedies provided by law.
        </ContentBodyNormal>
        <ContentBodyNormal>
          Without prejudice to any other rights or remedies that the Supplier
          may have, the Customer hereby accepts that damages alone may not be an
          adequate remedy for any breach of the terms of these Conditions.
          Without proof of actual damages, injunctive relief, specific
          performance or other equitable relief in favour of the Supplier may be
          sought as a remedy for breach of the terms of these Conditions.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Severance</Subtitle1>
        <ContentBodyNormal>
          If any provision or part-provision of these Conditions is or becomes
          invalid, illegal or unenforceable, it shall be deemed deleted, but
          that shall not affect the validity and enforceability of the rest of
          these Conditions.
        </ContentBodyNormal>
        <ContentBodyNormal>
          If any provision or part-provision of these Conditions is deemed
          deleted under clause 20.1 the parties shall negotiate in good faith to
          agree a replacement provision that, to the greatest extent possible,
          achieves the intended commercial result of the original provision.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Entire agreement</Subtitle1>
        <ContentBodyNormal>
          These Conditions supersedes all prior agreements and representations
          between the Parties regarding the Services. Any terms and conditions
          inconsistent with these Conditions contained in any Order Form will be
          of no force or effect, even if the Order is accepted by the Supplier.
          The Supplier may make changes to these Conditions from time to time.
          If the Supplier makes a material change to any of the foregoing, the
          Supplier will inform the Customer by e-mail to the e-mail address(es)
          noted on the Order Form, or through a banner or other prominent notice
          on the relevant online portal, or through the Supplier's online
          support platform. If the Customer does not agree to the change, the
          Customer must so notify the Supplier by e-mail to{' '}
          <strong>
            <a href="mailto:janice@nmblr.co">janice@nmblr.co</a>
          </strong>{' '}
          within 30 days after the Supplier’s notice. If the Customer so
          notifies the Supplier, then the Customer will remain governed by the
          most recent Conditions applicable to the Customer until the end of the
          then-current year of the Subscription Term and the updated Conditions
          shall apply upon the commencement of the subsequent Subscription Term.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Assignment</Subtitle1>
        <ContentBodyNormal>
          The Customer shall not, without the prior written consent of the
          Supplier, assign, transfer, charge, sub-contract or deal in any other
          manner with all or any of its rights or obligations under these
          Conditions.
        </ContentBodyNormal>
        <ContentBodyNormal>
          The Supplier may at any time assign, transfer, charge, sub-contract or
          deal in any other manner with all or any of its rights or obligations
          under these Conditions.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>No partnership or agency</Subtitle1>
        <ContentBodyNormal>
          Nothing in these Conditions is intended to or shall operate to create
          a partnership between the parties, or authorise either party to act as
          agent for the other, and neither party shall have the authority to act
          in the name or on behalf of or otherwise to bind the other in any way
          (including, but not limited to, the making of any representation or
          warranty, the assumption of any obligation or liability and the
          exercise of any right or power).
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Third party rights</Subtitle1>
        <ContentBodyNormal>
          These Conditions does not confer any rights on any person or party
          (other than the parties to these Conditions and, where applicable,
          their successors and permitted assigns) pursuant to the Contracts
          (Rights of Third Parties) Act 1999.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Governing law</Subtitle1>
        <ContentBodyNormal>
          These Conditions and any dispute or claim arising out of or in
          connection with it or its subject matter or formation (including
          non-contractual disputes or claims) shall be governed by and construed
          in accordance with the law of England and Wales.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Jurisdiction</Subtitle1>
        <ContentBodyNormal>
          Each party irrevocably agrees that the courts of England and Wales
          shall have exclusive jurisdiction to settle any dispute or claim
          arising out of or in connection with these Conditions or its subject
          matter or formation (including non-contractual disputes or claims).
        </ContentBodyNormal>
      </section>
    </ContentWrapper>
  );
};
