import { Loading } from 'components/Loading';
import { ButtonPill, Subtitle2 } from 'components/shared';
import FormCountryDropdownSearch from 'components/shared/FormCountryDropdownSearch';
import { ErrorMessage } from 'components/shared/FormField';
import FormTextInput from 'components/shared/FormTextInput';
import { YellowHeaderModal } from 'components/shared/YellowHeaderModal';
import {
  UserAuthedDocument,
  useUserAuthedQuery,
  useUserSafeUpdateMutation,
} from 'data/graphql/generated';
import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Country, countryArr } from 'types';

const StyledForm = styled(Form)`
  max-width: 376px;

  display: flex;
  flex-direction: column;
`;

export const Account = ({
  showModal,
  handleClose,
}: {
  showModal: boolean;
  handleClose(): void;
}) => {
  const { data: user, loading } = useUserAuthedQuery({
    fetchPolicy: 'network-only',
  });

  const [userSafeUpdate] = useUserSafeUpdateMutation();
  const [serverErrorMessage, setServerErrorMessage] = useState('');
  const [countryDropdownActive, setCountryDropdownActive] = useState(false);

  return (
    <YellowHeaderModal visible={showModal} handleClose={handleClose}>
      {loading ? (
        <Loading />
      ) : (
        <main
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Subtitle2>Account</Subtitle2>
          <section
            style={{
              marginTop: 20,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Formik
              initialValues={{
                name: user?.userAuthed?.name || '',
                phoneNumber: user?.userAuthed?.phoneNumber || '',
                phoneCountry:
                  user?.userAuthed?.phoneCountry || 'united kingdom',
                email: user?.userAuthed?.email || '',
              }}
              validate={(values) => {
                const errors = {} as { name: string; password: string };

                if (!values.name) {
                  errors.name = 'Name cannot be blank.';
                }

                return errors;
              }}
              onSubmit={async (values, actions) => {
                setServerErrorMessage('');

                if (user) {
                  if (
                    user.userAuthed.phoneCountry?.toLocaleLowerCase().trim() ===
                      values.phoneCountry.toLocaleLowerCase().trim() &&
                    user.userAuthed.phoneNumber?.trim() ===
                      values.phoneNumber.trim() &&
                    user.userAuthed.name.toLocaleLowerCase().trim() ===
                      values.name.toLocaleLowerCase().trim()
                  ) {
                    //If nothing has changed, return
                    return;
                  }

                  try {
                    await userSafeUpdate({
                      variables: {
                        data: {
                          name: values.name.trim(),
                          phoneNumber: values.phoneNumber.trim(),
                          phoneCountry: values.phoneCountry,
                        },
                      },
                      refetchQueries: [
                        {
                          query: UserAuthedDocument,
                        },
                      ],
                    });
                    handleClose();
                  } catch (error) {
                    if (error instanceof Error) {
                      setServerErrorMessage(error.message);
                    }
                    console.error(error);
                    return;
                  }
                }
              }}
            >
              {({
                values,
                handleSubmit,
                handleChange,
                errors,
                isSubmitting,
                setFieldValue,
                dirty,
              }) => (
                <StyledForm onSubmit={handleSubmit}>
                  <FormTextInput
                    name="name"
                    type="text"
                    onChange={handleChange}
                    value={values.name}
                    title="Name"
                    errorMessage={errors.name}
                  />
                  <FormTextInput
                    disabled
                    name="email"
                    type="email"
                    onChange={handleChange}
                    value={values.email}
                    title="Email"
                  />
                  <div style={{ display: 'flex', gap: 15 }}>
                    <FormCountryDropdownSearch
                      style={{ width: 117, flex: 'none' }}
                      title="Country code"
                      name="phoneCountry"
                      placeholder="Placeholder Text"
                      onClick={() =>
                        setCountryDropdownActive(!countryDropdownActive)
                      }
                      dropdownActive={countryDropdownActive}
                      selectedItem={values.phoneCountry as Country}
                      setSelectedItem={(value) => {
                        setFieldValue('phoneCountry', value);
                      }}
                      setDropdownActive={setCountryDropdownActive}
                      options={countryArr}
                      phoneCode
                    />
                    <FormTextInput
                      autocomplete="off"
                      name="phoneNumber"
                      type="tel"
                      onChange={(e) => {
                        const { value } = e.target;
                        //Only allows 0-9
                        const regex = /^[0-9]*$/;
                        if (value.length < 1 || regex.test(value.toString())) {
                          handleChange(e);
                        }
                      }}
                      value={values.phoneNumber}
                      title="Phone (optional)"
                    />
                  </div>
                  {serverErrorMessage && (
                    <ErrorMessage
                      style={{
                        position: 'relative',
                        inset: 0,
                        margin: 0,
                      }}
                      message={serverErrorMessage}
                    />
                  )}
                  <div style={{ display: 'flex', gap: 15, marginTop: 30 }}>
                    <ButtonPill
                      level="secondary"
                      width="180px"
                      text="Cancel"
                      onClick={handleClose}
                    />
                    <ButtonPill
                      loading={isSubmitting}
                      width="180px"
                      onClick={() => {}}
                      disabled={!values.name || isSubmitting || !dirty}
                      level="primary"
                      text="Save"
                    />
                  </div>
                </StyledForm>
              )}
            </Formik>
          </section>
        </main>
      )}
    </YellowHeaderModal>
  );
};
