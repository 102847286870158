import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import {
  BodyNormal,
  ButtonPill,
  CharacterLimitProgressDonut,
  ModalWithDetail,
  Subtitle1,
} from 'components/shared';
import { ErrorContainer } from 'components/shared/FormField';
import FormTextInput, {
  ModalTextAreaInput,
} from 'components/shared/FormTextInput';
import { colors } from 'constants/index';
import { useWidth } from 'hooks/useWidth';

const SLIDE_MAX_WIDTH = 376;

const ModalContent = styled.div`
  width: 100%;
  align-self: center;
  position: relative;
`;

const CTAWrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;
  width: 100%;
`;

const FormWrapper = styled.div`
  position: relative;
  width: 100%;
  textarea {
    width: 100%;
  }
`;

export const StyledFormTextInput = styled(FormTextInput)`
  display: block;
  margin-top: 30px;

  > ${ModalTextAreaInput} {
    padding-right: 45px;
  }

  ${ErrorContainer} {
    margin-top: 0px;
  }
`;

const ProgressBars = styled.ul`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 20px;

  width: 100%;
`;

const ProgressBar = styled.div<{ highlighted: boolean }>`
  height: 4px;
  background: ${({ highlighted }) =>
    highlighted ? colors.purple : colors.greyLight};
  transition: background 200ms ease;
`;

const SliderWindow = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  transition: height 200ms ease;
`;

const SlidingContent = styled.div<{
  slide: number;
  slidingWindowWidth: number;
}>`
  width: ${({ slidingWindowWidth }) => 2 * slidingWindowWidth}px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: absolute;
  left: 0;
  transition: all 200ms ease;
  transform: translateX(${({ slide }) => `-${50 * slide}%`});
`;

const Slide = styled.div<{ visible: boolean; slidingWindowWidth: number }>`
  width: ${({ slidingWindowWidth }) => slidingWindowWidth}px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 200ms ease;
  height: fit-content;
`;

interface Props {
  modalSlideIdx: number | null;
  setModalSlideIdx: React.Dispatch<React.SetStateAction<number | null>>;
  handleClose(): void;
  textValue: string;
  handleSubmit(s: string, r: string): Promise<any> | void;
  rationaleText: string;
  modalDetails?: Record<string, string>;
  showSingleSlide: boolean;
}

export const ImperativeModal = ({
  modalSlideIdx,
  setModalSlideIdx,
  handleClose,
  textValue,
  handleSubmit,
  modalDetails,
  rationaleText,
  showSingleSlide,
}: Props) => {
  const [textValueState, setTextValueState] = useState(textValue);
  const [rationaleTextState, setRationaleTextState] = useState(rationaleText);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const slide = modalSlideIdx;
  const wordLimit = 64;
  const slidingContentRef = useRef<HTMLDivElement | null>(null);
  const sliderWindowRef = useRef<HTMLDivElement | null>(null);
  const [slidingWindowWidth, setSlidingWindowWidth] = useState(SLIDE_MAX_WIDTH);
  const width = useWidth();

  useEffect(() => {
    return () => {
      setErrorMessage('');
    };
  }, []);

  // Set height of SlidingContent to enable smooth transitions between slide heights
  useEffect(() => {
    const incomingSlide = document.getElementById(`rationale-slide-${slide}`);
    const slidingContent = slidingContentRef.current;
    const slidingWindow = sliderWindowRef.current;
    if (!incomingSlide || !slidingContent || !slidingWindow) return;

    const incomingHeight = incomingSlide.offsetHeight;
    slidingContent.style.height = `${incomingHeight}px`;
    slidingWindow.style.height = `${incomingHeight}px`;
  }, [slide]);

  useEffect(() => {
    // Set window width to enable smooth transitions between slide widths
    // slidingWindowWidth is used to calculate the sliding content's transform

    const sliderWindow = sliderWindowRef.current;
    if (!sliderWindow) return;

    const windowWidth = sliderWindow.offsetWidth;
    setSlidingWindowWidth(
      windowWidth > SLIDE_MAX_WIDTH ? SLIDE_MAX_WIDTH : windowWidth
    );
  }, [width]);

  return (
    <ModalWithDetail
      visible={modalSlideIdx !== null}
      handleClose={handleClose}
      data={modalDetails}
    >
      <ModalContent>
        {!showSingleSlide && (
          <ProgressBars>
            <ProgressBar highlighted={slide === 0} />
            <ProgressBar highlighted={slide === 1} />
          </ProgressBars>
        )}

        <SliderWindow ref={sliderWindowRef}>
          <SlidingContent
            slide={modalSlideIdx || 0}
            ref={slidingContentRef}
            slidingWindowWidth={slidingWindowWidth}
          >
            <Slide
              visible={slide === 0}
              id={`rationale-slide-0`}
              slidingWindowWidth={slidingWindowWidth}
            >
              <Subtitle1
                style={{
                  textAlign: showSingleSlide ? 'center' : 'left',
                  marginBottom: 10,
                }}
              >
                {!showSingleSlide && '1. '}Name this strategic possibility
              </Subtitle1>
              <BodyNormal
                style={{
                  textAlign: showSingleSlide ? 'center' : 'left',
                }}
              >
                Enter a short tagline to refer to it by.
              </BodyNormal>
              <FormWrapper>
                <StyledFormTextInput
                  errorMessage={errorMessage}
                  textArea
                  name="imperative"
                  type="text"
                  onChange={(e) => {
                    setTextValueState(e.target.value);
                  }}
                  value={textValueState}
                  title={'Name'}
                />
                <CharacterLimitProgressDonut
                  size={'25px'}
                  count={textValueState.length}
                  limit={64}
                  grow={64 - textValueState.length < 20}
                  showCount={64 - textValueState.length < 20}
                  color={() => {
                    const remainingCharacters =
                      wordLimit - textValueState.length;

                    if (remainingCharacters >= 20) {
                      return colors.blue;
                    }

                    if (textValueState.length > wordLimit) {
                      return colors.red;
                    }

                    return colors.orange;
                  }}
                  style={{
                    position: 'absolute',
                    bottom: 40,
                    right: 15,
                  }}
                />
              </FormWrapper>
            </Slide>
            <Slide
              visible={slide === 1}
              id={`rationale-slide-1`}
              slidingWindowWidth={slidingWindowWidth}
            >
              <Subtitle1
                style={{
                  textAlign: showSingleSlide ? 'center' : 'left',
                  marginBottom: 10,
                }}
              >
                {!showSingleSlide && '2. '}Provide the rationale
              </Subtitle1>
              <BodyNormal
                style={{
                  textAlign: showSingleSlide ? 'center' : 'left',
                }}
              >
                Describe the reason to focus here.
              </BodyNormal>
              <FormWrapper>
                <StyledFormTextInput
                  errorMessage={errorMessage}
                  textArea
                  name="rationale"
                  type="text"
                  onChange={(e) => {
                    setRationaleTextState(e.target.value);
                  }}
                  value={rationaleTextState}
                  title={'Rationale (optional)'}
                />
              </FormWrapper>
            </Slide>
          </SlidingContent>
        </SliderWindow>

        <CTAWrapper>
          <ButtonPill
            level="secondary"
            width="180px"
            text={slide === 0 || showSingleSlide ? 'Cancel' : 'Back'}
            onClick={() => {
              if (slide === 0 || showSingleSlide) {
                handleClose();
              } else {
                modalSlideIdx && setModalSlideIdx(modalSlideIdx - 1);
              }
            }}
          />
          <ButtonPill
            loading={isSaving}
            disabled={
              isSaving || !textValueState || textValueState.length > wordLimit
            }
            type="submit"
            width="180px"
            text={!showSingleSlide && slide === 0 ? 'Next' : 'Save'}
            clickClassName="cypress-imperative-save"
            onClick={async () => {
              if (!showSingleSlide && slide === 0) {
                setModalSlideIdx(slide + 1);
              } else {
                setErrorMessage('');
                setIsSaving(true);
                try {
                  await handleSubmit(textValueState, rationaleTextState);
                } catch (error) {
                  console.error(error);
                  if (error instanceof Error) {
                    setErrorMessage(error.message);
                  }
                }
                setIsSaving(false);
                handleClose();
              }
            }}
          />
        </CTAWrapper>
      </ModalContent>
    </ModalWithDetail>
  );
};
