// Having this css constant as a separate file avoids the "Cannot access ___ before initialization" error

import { css } from 'styled-components/macro';
import { colors } from 'constants/colors';
import { device } from 'utils/breakpoints';
import { accessStrategyGapWrapperPadding } from 'constants/index';

export const summaryScrollbar = css`
  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.black20a};
    border-radius: 10px;
    margin: 0 15px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.white50};
    border-radius: 10px;

    &:hover,
    &:active {
      background: ${colors.white70};
    }
  }
`;

export const textScrollbar = css`
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    margin: 15px 0;
    background: ${colors.greyLight};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${colors.greyMedium};
  }
`;

export const black20ScrollBar = css`
  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.white50};
    border-radius: 10px;
    margin: 0 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.black20a};
    border-radius: 10px;
    &:hover,
    &:active {
      background: ${colors.black30a};
    }
  }
`;

export const whiteScrollBar = css`
  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.white50};
    border-radius: 10px;
    margin: 0 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.black20a};
    border-radius: 10px;
    &:hover,
    &:active {
      background: ${colors.black30a};
    }
  }
`;

export const thinPurpleScrollbar = css`
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.white};
    border-radius: 10px;
    margin: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.purple};
    border-radius: 10px;
    border: 1px solid ${colors.white};
    &:hover,
    &:active {
      border: 1px solid ${colors.purple};
    }
  }
`;

export const summaryBottomScrollBar = css`
  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.white50};
    border-radius: 10px;
    margin: 0 20px;

    @media ${device.tabletMax} {
      margin: 0 15px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.black20a};
    border-radius: 10px;

    &:hover,
    &:active {
      background: ${colors.black30a};
    }
  }
`;

export const archetypeCardScrollbar = css`
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.greyLight};
    border-radius: 10px;
    margin: 0 ${accessStrategyGapWrapperPadding}px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.black20a};
    border-radius: 10px;
    &:hover,
    &:active {
      background: ${colors.black30a};
    }
  }
`;
