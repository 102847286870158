import {
  PatientJourneyBlockFragment,
  PatientJourneyColumn,
  PatientJourneyConnection,
} from 'data/graphql/generated';
import { ConnectionSide } from 'types';
import { generatePath } from './generatePath';
import { getDotPostionFromBlockXY } from './getDotPositionFromBlockXY';

interface Props {
  column: PatientJourneyColumn;
  columns: PatientJourneyColumn[];
  connections: PatientJourneyConnection[];
  blocks: PatientJourneyBlockFragment[];
  newWidth: number;
}

export async function columnUpdateWidth({
  column,
  newWidth,
  columns,
  connections,
  blocks,
}: Props) {
  // Map columns to object to save us from looping through columns later
  const colsById = columns.reduce((colsById, col) => {
    colsById[col.id] = col;
    return colsById;
  }, {} as Record<keyof typeof columns, PatientJourneyColumn>);

  const updatedColumn: PatientJourneyColumn = {
    ...column,
    width: newWidth,
  };

  // update all cards on the right of this column
  const columnsNeedUpdating = columns
    .filter((c) => c.idx > column.idx)
    .map((c) => c.id);
  const xDiff = newWidth - column.width;
  const blocksToUpdate = blocks
    .filter((b) => columnsNeedUpdating.includes(b.columnId))
    .map((b) => {
      const updated = {
        ...b,
        x: b.x + xDiff,
      };
      return updated;
    });
  const blocksToUpdateIds = blocksToUpdate.map((b) => b.id);
  // move connectors between other columns
  const connectorsToMove = connections
    .filter((c) => {
      return (
        blocksToUpdateIds.includes(c.fromBlockId) &&
        blocksToUpdateIds.includes(c.toBlockId)
      );
    })
    .map((c) => {
      const updated = {
        ...c,
        path: c.path.map((p, idx) => {
          // Update every other value (x coordinates)
          if (idx % 2 === 0 && typeof p === 'number') {
            return p + xDiff;
          }
          return p;
        }),
      };
      return updated;
    });

  // redraw connectors from that have one of their blocks moved
  const connectorsToRedraw = connections
    .filter((c) => {
      let a = 0;
      if (blocksToUpdateIds.includes(c.fromBlockId)) {
        a = a + 1;
      }
      if (blocksToUpdateIds.includes(c.toBlockId)) {
        a = a + 1;
      }
      return a === 1;
    })
    .map((c) => {
      const fromBlock = blocks.find((b) => b.id === c.fromBlockId);
      const toBlock = blocks.find((b) => b.id === c.toBlockId);

      if (!fromBlock) return c;
      if (!toBlock) return c;

      const fromCol = colsById[fromBlock.columnId];
      const toCol = colsById[toBlock.columnId];

      if (!fromCol) return c;
      if (!toCol) return c;

      // Note we can't use generatePathFromConnection here
      // as the DOM won't have the latest block positions
      const updated = {
        ...c,
        path: generatePath(
          c.fromSide as ConnectionSide,
          getDotPostionFromBlockXY(
            fromCol.idx > column.idx ? fromBlock.x + xDiff : fromBlock.x,
            fromBlock.y,
            c.fromSide as ConnectionSide,
            fromBlock.height
          ),
          c.toSide as ConnectionSide,
          getDotPostionFromBlockXY(
            toCol.idx > column.idx ? toBlock.x + xDiff : toBlock.x,
            toBlock.y,
            c.toSide as ConnectionSide,
            toBlock.height
          ),
          true
        ),
      };
      return updated;
    });

  return {
    blocks: blocksToUpdate,
    columns: [updatedColumn],
    connections: [...connectorsToRedraw, ...connectorsToMove],
  };
}
