import React from 'react';
import styled, { css, keyframes } from 'styled-components';

interface Props {
  size: number;
  color?: string;
  className?: string;
}

const loadingAnim = keyframes`
  from {
    background-position-x: 0px;
  }

  to {
    background-position-x: 30px;
  }
`;

export const Wrapper = styled.div<Props>`
  ${({ size }) => {
    return css`
      width: ${size}px;
      height: ${size}px;
      display: grid;
      place-items: center;
    `;
  }}
`;

export const StyledIcon = styled.div<Props>`
  ${({ color }) => {
    let svgName = color === 'white' ? 'Wave segment white' : 'Wave segment';

    return css`
      width: 17.9px;
      height: 17.9px;
      background-image: url('/icons/${svgName}.svg');
      background-repeat: repeat-x;
      background-position: center;
      animation: ${loadingAnim} 1s linear infinite;
    `;
  }}
`;

export const AnimatingWaveIcon = (props: Props) => {
  return (
    <Wrapper {...props}>
      <StyledIcon {...props} />
    </Wrapper>
  );
};
