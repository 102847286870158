import { colors } from 'constants/colors';
import styled from 'styled-components/macro';

const TrackButton = styled.button`
  color: ${colors.purple};
  background: none;
  border: none;
  cursor: pointer;
`;

interface AddTrackingButtonProps {
  isHidden?: boolean;
  children: string;
  onClick: () => void;
}
export const AddTrackingButton = ({
  children,
  onClick,
  isHidden = false,
}: AddTrackingButtonProps) => {
  if (isHidden) {
    return null;
  }

  return <TrackButton onClick={onClick}>{children}</TrackButton>;
};
