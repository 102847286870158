export const DEFAULT_PICO_TEXT = 'Not yet identified';
export const MEDICAL_PICO_TACTICS_COLUMNS = [
  'Tactic',
  'Category',
  'Audience',
  'Timing',
  'Due',
  'Responsibility',
  'Budget',
];
