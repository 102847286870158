import React from 'react';
import { colors } from 'constants/index';
import { Content, ModalLarge, Scroll } from 'components/ModalLarge';
import { BodySmall, Caption, Subtitle1, Subtitle2 } from 'components/shared';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { Loading } from 'components/Loading';
import useMobile from 'hooks/useMobile';
import { CompetitorSimpleFragment } from 'data/graphql/generated';
import { Link } from 'react-router-dom';

const StyledModalLarge = styled(ModalLarge)`
  > ${Scroll} {
    padding-top: 110px;

    @media ${device.tabletMin} {
      padding-top: 0;
    }

    > ${Content} {
      @media ${device.tabletMin} {
        max-width: 639px;
      }
    }
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 15px 30px;

  @media ${device.desktopMin} {
    padding: 40px 40px 30px 40px;
  }
`;

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const Section = styled.section`
  border: 1px solid ${colors.black10a};
  border-radius: 5px;
  padding: 15px;
`;

const CompetitorImageAndTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const CompetitorImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const BulletPointList = styled.ul`
  margin: 0 0 0 50px;
  padding: 0;
  flex-direction: column;
  display: flex;
  gap: 15px;
`;

const BulletPointAndText = styled.div`
  display: flex;
`;

const BulletPoint = styled.div`
  width: 15px;
  height: 15px;
  border: 5px solid ${colors.purple};
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 2px;
  flex: none;
`;

const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 5px;
  border-radius: 5px;
  background: ${colors.yellow20};
  padding: 25px 15px;
`;

interface Props {
  handleClose(): void;
  visible: boolean;
  strategyId: number;
  drugId: number;
  competitors: (CompetitorSimpleFragment | null | undefined)[];
}

export const SpaceOccupiedModal = ({
  handleClose,
  visible,
  competitors,
  drugId,
  strategyId,
}: Props) => {
  const isMobile = useMobile();
  const dataLoading = false;

  return (
    <StyledModalLarge
      size={'small'}
      handleClose={handleClose}
      visible={visible}
      headerColor={isMobile ? colors.black05 : colors.cream}
    >
      {dataLoading ? (
        <Loading style={{ marginTop: 20 }} />
      ) : (
        <ModalContent>
          <Subtitle1 style={{ marginBottom: 5 }}>Space occupied</Subtitle1>
          <Caption
            color={colors.greyDark}
            style={{ textAlign: 'center', marginBottom: 20 }}
          >
            Strong competitor brand associations is space already occupied.
          </Caption>

          <Sections>
            {competitors.length ? (
              competitors.map((c) => {
                if (!c) return null;
                return (
                  <Section key={c.id}>
                    <CompetitorImageAndTitle>
                      {c.image && (
                        <CompetitorImage src={c.image} alt={c.title} />
                      )}
                      <BodySmall>{c.title}</BodySmall>
                    </CompetitorImageAndTitle>

                    {c.brandAssociations.length ? (
                      <BulletPointList>
                        {c.brandAssociations.map((b, idx) => (
                          <BulletPointAndText key={idx}>
                            <BulletPoint />
                            <BodySmall color={colors.greyDark}>{b}</BodySmall>
                          </BulletPointAndText>
                        ))}
                      </BulletPointList>
                    ) : (
                      <EmptyStateWrapper>
                        <Subtitle2 color={colors.greyDark}>
                          No brand associations idenfitied yet
                        </Subtitle2>
                        <div>
                          <BodySmall
                            style={{ display: 'inline' }}
                            color={colors.greyDark}
                          >
                            Leads should decide these in Part 1
                          </BodySmall>
                        </div>
                      </EmptyStateWrapper>
                    )}
                  </Section>
                );
              })
            ) : (
              <EmptyStateWrapper>
                <Subtitle2 color={colors.greyDark}>
                  No competitors to assess{' '}
                </Subtitle2>
                <div>
                  <BodySmall
                    style={{ display: 'inline' }}
                    color={colors.greyDark}
                  >
                    Only competitors starred in the{' '}
                  </BodySmall>

                  <Link
                    to={`/d/${drugId}/strategy/${strategyId}/1_2?evaluating=true`}
                    style={{
                      color: colors.greyDark,
                      display: 'inline',
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    {' '}
                    1.2 Competitive Landscape{' '}
                  </Link>

                  <BodySmall
                    style={{ display: 'inline' }}
                    color={colors.greyDark}
                  >
                    can be assessed{' '}
                  </BodySmall>
                </div>
              </EmptyStateWrapper>
            )}
          </Sections>
        </ModalContent>
      )}
    </StyledModalLarge>
  );
};
