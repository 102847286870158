import {
  BodySmall,
  ButtonLabel,
  ButtonPill,
  Subtitle2,
} from 'components/shared';
import { colors, zIndex } from 'constants/index';
import useMobile from 'hooks/useMobile';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';

const Details = styled.span`
  * {
    display: inline;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

const Wrapper = styled.div`
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${zIndex.cookieBanner};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 15px;

  background: ${colors.white};
  max-width: 1101px;
  width: calc(100% - 169px * 2);
  flex-wrap: wrap;
  gap: 30px;
  @media ${device.tabletMax} {
    width: calc(100% - 30px);
  }

  @media ${device.mobile} {
    div:last-child {
      width: 100%;
    }
  }
`;

interface Props {}

export const CookieBanner = (props: Props) => {
  const [agreedConsent, setAgreedConsent] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    setAgreedConsent(Boolean(localStorage.getItem('@nmblr/cookieConsent')));
  }, []);

  const isMobile = useMobile();

  if (agreedConsent || agreedConsent === undefined) return null;

  return (
    <Wrapper>
      <TextWrapper>
        <Subtitle2>Your privacy</Subtitle2>
        <Details>
          <BodySmall>
            We use cookies to help provide a better experience. &nbsp;
          </BodySmall>
          <a href="/terms/privacy-policy" target="_blank">
            <ButtonLabel>Learn more</ButtonLabel>
          </a>
        </Details>
      </TextWrapper>
      <ButtonPill
        width={isMobile ? '100%' : '166px'}
        text="OK"
        className="cypress-privacy-policy"
        onClick={() => {
          localStorage.setItem('@nmblr/cookieConsent', 'true');
          setAgreedConsent(true);
        }}
      />
    </Wrapper>
  );
};
