import { colors, zIndex } from 'constants/index';
import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { BodySmall, Subtitle1 } from './shared/TextStyles';
import { TopBar } from './ModalForm';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const CTAWrapper = styled.div`
  display: inline;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const Wrapper = styled.div`
  border: none;
  border-radius: 0;

  @media ${device.tabletMin} {
    height: fit-content;
    border-radius: 5px;
    border: 0.5px solid ${colors.black30};
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05),
      0px 10px 20px rgba(0, 0, 0, 0.1);
  }
`;

const Background = styled.div`
  display: block;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: ${zIndex.overlay};
  background: rgba(20, 20, 39, 0.4);
  opacity: 0;
  animation: ${fadeIn} 0.3s;
  animation-fill-mode: forwards;
  overflow-y: scroll;
`;

export const ModalContent = styled.div<{ visible: boolean }>`
  display: flex;
  margin: 120px auto 30px auto;
  opacity: 0;
  animation: ${fadeIn} 0.3s;
  animation-fill-mode: forwards;
  background: ${colors.white};
  z-index: ${zIndex.dialog};
  width: 90%;
  max-width: 456px;
  box-shadow: 0px 10px 20px 0px rgb(0, 0, 0 / 10%),
    0px 1px 5px 0px rgb(0, 0, 0 / 5%);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 40px 15px;
  min-width: 345px;
  margin-botton: 20px;

  @media ${device.tabletMin} {
    padding: 40px;
  }
`;

interface Props {
  heading?: string;
  message?: string;
  handleClose(): void;
  visible: boolean;
  className?: string;
}

export const WarningModalLink: React.FC<Props> = ({
  heading,
  message,
  handleClose,
  visible,
  children,
  className,
}) => {
  if (!visible) return null;
  
  return (
    <Background
      className="modal-form-background"
      onClick={(e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
          handleClose();
        }
      }}
    >
      <ModalContent visible={visible} className={className}>
        <Wrapper>
          <TopBar />
          <Content>
            <Subtitle1>{heading}</Subtitle1>
            <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
              {message} {""}
            </BodySmall>
            <CTAWrapper className={'CTAWrapper'}>{children}</CTAWrapper>
          </Content>
        </Wrapper>
      </ModalContent>
    </Background>
  );
};
