import { GroupDelete } from 'components/PostItGroup';
import { PostIts } from 'components/PostIts';
import { BodySmall, Caption, StatementV2 } from 'components/shared';
import { colors } from 'constants/index';
import { URLParams } from 'containers/CoreValueMessages';

import React from 'react';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';

const StyledStatementV2 = styled(StatementV2)`
  @media ${device.mobile} {
    margin: 0 auto;
  }
`;

const StyledPostIts = styled(PostIts)<{ lastGroup: boolean }>`
  width: 1070px;

  @media ${device.tabletMax} {
    width: 100%;
  }

  ${GroupDelete} {
    display: ${({ lastGroup }) => lastGroup && 'none'};
  }
`;

const Wrapper = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  align-items: flex-start;
  justify-content: center;

  .SuppportingPage__StatementWrapper {
    min-width: 315px;
    max-width: 315px;
  }

  @media ${device.tabletMax} {
    flex-direction: column-reverse;
    gap: 0;

    .SuppportingPage__StatementWrapper {
      width: 100%;
      padding: 0 15px;
      margin: 0 auto;
      max-width: none;

      > ${StyledStatementV2} {
        padding: 15px;
      }
    }
  }

  @media ${device.mobile} {
    .SuppportingPage__StatementWrapper {
      min-width: auto;
      padding: 0;
    }
    ${StyledStatementV2} {
      max-width: calc(100% - 30px);
      padding: 15px;
    }
  }
`;

interface Props {
  params: URLParams;
  totalUsers?: number;
  isDesktop: boolean;
  coreValueMessage?: string;
}

export const SupportingPage = ({
  params,
  step,
  groups,
  addCard,
  removeCard,
  updateCard,
  handleCardChange,
  createGroup,
  updateGroup,
  removeGroup,
  isDesktop,
  coreValueMessage,
}: Props & React.ComponentProps<typeof PostIts>) => {
  return (
    <>
      <Wrapper>
        <StyledPostIts
          lastGroup={groups.length === 1}
          step={step}
          groups={groups}
          addCard={addCard}
          removeCard={removeCard}
          updateCard={updateCard}
          handleCardChange={handleCardChange}
          createGroup={createGroup}
          updateGroup={updateGroup}
          removeGroup={async (id) => {
            if (groups.length > 1) {
              return await removeGroup(id);
            }
          }}
        />

        <div className={'SuppportingPage__StatementWrapper'}>
          {isDesktop && (
            <div
              style={{
                padding: 15,
                maxWidth: 315,
                background: colors.white,
                borderRadius: 5,
              }}
            >
              <Caption style={{ marginBottom: 5 }}>Core Value Message</Caption>
              <BodySmall color={colors.greyDark}>
                {coreValueMessage || 'Not defined yet'}
              </BodySmall>
            </div>
          )}
        </div>
      </Wrapper>
    </>
  );
};
