import { useEffect } from 'react';
import { useQuery } from 'hooks/useQuery';

export const useScrollToId = ({
  isLoading,
  isDataPresent,
  queryString,
  addedClass,
}: {
  isLoading: boolean;
  isDataPresent: boolean;
  queryString: string;
  addedClass: string;
}) => {
  let query = useQuery();

  useEffect(() => {
    const originalId = query.get(queryString);
    if (!isLoading && isDataPresent && originalId) {
      const row = document.getElementById(originalId);

      if (row) {
        row.scrollIntoView({ block: 'center' });
        row.classList.add(addedClass);
      }
    }
  }, [addedClass, queryString, isLoading, isDataPresent, query]);
};
