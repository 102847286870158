import React from 'react';
import styled from 'styled-components/macro';

import { colors } from 'constants/colors';
import { device } from 'utils/breakpoints';
import { Link, useHistory } from 'react-router-dom';
import { Subtitle2, Icon, BodySmall, ButtonLabel } from 'components/shared';

import {
  EndpointTargetCompetitiveLandscapeOpportunitiesFragment,
  EndpointTargetFragment,
  EndpointTargetsQuery,
  Stakeholder,
} from 'data/graphql/generated';
import { EndpointTarget } from 'components/Collaboration/EndpointTarget';
import { SidebarEmptyState } from 'components/GridPages/GridPageSidebar';
import { ReusableLargeSidebar } from 'components/shared/ReusableLargeSidebar';
import { fadeFromPurpleBGCSS } from 'utils/keyframes';

const FooterWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 0px 15px 15px 15px;
  position: relative;
`;

export const Header = styled.header`
  position: absolute;
  height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
`;

export const SidebarTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const CollapseIcon = styled(Icon)`
  cursor: pointer;

  &:hover {
    background: ${colors.black10};
    border-radius: 5px;
  }
`;

export const Content = styled.div`
  padding: 60px 15px 15px;

  @media ${device.desktopMin} {
    padding: 60px 20px 20px;
  }
`;

export const EndpointTargetWrapper = styled.div`
  border-radius: 5px;
  background: ${colors.white};
  margin-bottom: 15px;
  position: relative;
  &.originalIdea::before {
    ${fadeFromPurpleBGCSS}
    pointer-events: none;
  }
`;

const AddIdea = styled.span`
  display: block;
  color: ${colors.purple};
  font-weight: 500;
  font-size: 14px;

  cursor: pointer;
`;

export const StakeholderTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  p {
    margin-left: 5px;
  }
`;

export const StakeholderGroup = styled.div``;
type EndpointTargetsWithOpportunitiesType = (EndpointTargetFragment & {
  opportunity: EndpointTargetCompetitiveLandscapeOpportunitiesFragment;
})[];
interface Props {
  endpointTargetData: EndpointTargetsQuery | undefined;

  sidebarKeyStatementId: number | null;
  sidebarOpen: boolean;
  closeSidebar(): void;
  strategyId: number;
  drugId: number;
  totalNavHeight: number;
  createLinkedAccessStratEndpointTarget(
    endpointTargetId: number
  ): Promise<void>;
  usedEndpointTargetIds: number[];
}

export const EvidenceSideBar: React.FC<Props> = ({
  endpointTargetData,
  sidebarKeyStatementId,
  sidebarOpen,
  closeSidebar,
  strategyId,
  drugId,
  totalNavHeight,
  createLinkedAccessStratEndpointTarget,
  usedEndpointTargetIds,
}) => {
  const history = useHistory();

  const endpointTargetsWithOpportunities = (
    endpointTargetData?.endpointTargets?.items.filter(
      (ep) => !usedEndpointTargetIds.includes(ep.id)
    ) || []
  ).map((endpoint) => {
    return {
      ...endpoint,
      opportunity: (
        endpointTargetData?.competitiveLandscapeOpportunities?.items || []
      ).find(({ id }) => id === endpoint.opportunityId),
    };
  }) as EndpointTargetsWithOpportunitiesType;

  const stakeholderEndpointTargets = endpointTargetsWithOpportunities.reduce(
    (
      acc: { [key: string]: EndpointTargetsWithOpportunitiesType },
      endpointTarget
    ) => {
      const stakeholder = endpointTarget.opportunity?.stakeholder;

      if (stakeholder) {
        acc[stakeholder as Stakeholder].push(endpointTarget);
      }

      return acc;
    },
    {
      [Stakeholder.Healthcare]: [],
      [Stakeholder.Patient]: [],
      [Stakeholder.Payor]: [],
      [Stakeholder.Policymaker]: [],
      [Stakeholder.Provider]: [],
    }
  );

  async function handleAddEndpoint(endpointTargetId: number) {
    try {
      await createLinkedAccessStratEndpointTarget(endpointTargetId);
    } catch (err) {
      console.error(err);
      alert('Something went wrong');
    }
  }

  return (
    <ReusableLargeSidebar
      isOpen={sidebarOpen}
      onClickOutside={closeSidebar}
      ignoreClickClasses={['collaboration-modal']}
    >
      <Header>
        <SidebarTitle>
          <Subtitle2>
            Consider evidence generation ideas previously noted
          </Subtitle2>
        </SidebarTitle>
        <CollapseIcon
          onClick={closeSidebar}
          name="Collapse right"
          size={30}
          color={colors.purple}
        />
      </Header>

      {endpointTargetsWithOpportunities.length === 0 ? (
        <SidebarEmptyState title="No evidence generation ideas">
          <div>
            <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
              Ideas from{' '}
            </BodySmall>
            <Link
              to={`/d/${drugId}/strategy/${strategyId}/2_3`}
              style={{
                color: colors.greyDark,
                display: 'inline',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              Evidence Generation Ideas{' '}
            </Link>
            <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
              will be shown here for use
            </BodySmall>
          </div>
        </SidebarEmptyState>
      ) : (
        <Content id="sidebar-content">
          {stakeholderEndpointTargets.Patient.length > 0 ? (
            <StakeholderGroup>
              <StakeholderTitle>
                <Icon name="Patient" size={25} color={colors.purple} />
                <BodySmall color={colors.greyDark}>Patients</BodySmall>
              </StakeholderTitle>
              {stakeholderEndpointTargets.Patient.map((endpointTarget) => (
                <EndpointTargetWrapper
                  id={`sidebar_endpoint_${endpointTarget.id}`}
                  key={endpointTarget.id}
                >
                  <EndpointTarget endpointTarget={endpointTarget} />
                  <Footer
                    sidebarKeyStatementId={sidebarKeyStatementId}
                    handleAddEndpoint={handleAddEndpoint}
                    endpointTarget={endpointTarget}
                    strategyId={strategyId}
                    drugId={drugId}
                    history={history}
                  />
                </EndpointTargetWrapper>
              ))}
            </StakeholderGroup>
          ) : null}

          {stakeholderEndpointTargets.Healthcare.length > 0 ? (
            <StakeholderGroup>
              <StakeholderTitle>
                <Icon name="HCP" size={25} color={colors.green} />
                <BodySmall color={colors.greyDark}>
                  Healthcare Professionals
                </BodySmall>
              </StakeholderTitle>
              {stakeholderEndpointTargets.Healthcare.map((endpointTarget) => (
                <EndpointTargetWrapper
                  id={
                    endpointTarget.id
                      ? `sidebar_endpoint_${endpointTarget.id}`
                      : ''
                  }
                  key={endpointTarget.id}
                >
                  <EndpointTarget endpointTarget={endpointTarget} />
                  <Footer
                    sidebarKeyStatementId={sidebarKeyStatementId}
                    handleAddEndpoint={handleAddEndpoint}
                    endpointTarget={endpointTarget}
                    strategyId={strategyId}
                    drugId={drugId}
                    history={history}
                  />
                </EndpointTargetWrapper>
              ))}
            </StakeholderGroup>
          ) : null}

          {stakeholderEndpointTargets.Payor.length > 0 ? (
            <StakeholderGroup>
              <StakeholderTitle>
                <Icon name="Provider" size={25} color={colors.blue} />
                <BodySmall color={colors.greyDark}>Payors</BodySmall>
              </StakeholderTitle>
              {stakeholderEndpointTargets.Payor.map((endpointTarget) => (
                <EndpointTargetWrapper
                  id={
                    endpointTarget.id
                      ? `sidebar_endpoint_${endpointTarget.id}`
                      : ''
                  }
                  key={endpointTarget.id}
                >
                  <EndpointTarget endpointTarget={endpointTarget} />
                  <Footer
                    sidebarKeyStatementId={sidebarKeyStatementId}
                    handleAddEndpoint={handleAddEndpoint}
                    endpointTarget={endpointTarget}
                    strategyId={strategyId}
                    drugId={drugId}
                    history={history}
                  />
                </EndpointTargetWrapper>
              ))}
            </StakeholderGroup>
          ) : null}

          {stakeholderEndpointTargets.Provider.length > 0 ? (
            <StakeholderGroup>
              <StakeholderTitle>
                <Icon name="Provider" size={25} color={colors.pink} />
                <BodySmall color={colors.greyDark}>Providers</BodySmall>
              </StakeholderTitle>
              {stakeholderEndpointTargets.Provider.map((endpointTarget) => (
                <EndpointTargetWrapper
                  id={
                    endpointTarget.id
                      ? `sidebar_endpoint_${endpointTarget.id}`
                      : ''
                  }
                  key={endpointTarget.id}
                >
                  <EndpointTarget endpointTarget={endpointTarget} />
                  <Footer
                    sidebarKeyStatementId={sidebarKeyStatementId}
                    handleAddEndpoint={handleAddEndpoint}
                    endpointTarget={endpointTarget}
                    strategyId={strategyId}
                    drugId={drugId}
                    history={history}
                  />
                </EndpointTargetWrapper>
              ))}
            </StakeholderGroup>
          ) : null}

          {stakeholderEndpointTargets.Policymaker.length > 0 ? (
            <StakeholderGroup>
              <StakeholderTitle>
                <Icon name="Policymaker" size={25} color={colors.red} />
                <BodySmall color={colors.greyDark}>Policymakers</BodySmall>
              </StakeholderTitle>
              {stakeholderEndpointTargets.Policymaker.map((endpointTarget) => (
                <EndpointTargetWrapper
                  id={
                    endpointTarget.id
                      ? `sidebar_endpoint_${endpointTarget.id}`
                      : ''
                  }
                  key={endpointTarget.id}
                >
                  <EndpointTarget endpointTarget={endpointTarget} />
                  <Footer
                    sidebarKeyStatementId={sidebarKeyStatementId}
                    handleAddEndpoint={handleAddEndpoint}
                    endpointTarget={endpointTarget}
                    strategyId={strategyId}
                    drugId={drugId}
                    history={history}
                  />
                </EndpointTargetWrapper>
              ))}
            </StakeholderGroup>
          ) : null}
        </Content>
      )}
    </ReusableLargeSidebar>
  );
};

const Footer = ({
  sidebarKeyStatementId,
  handleAddEndpoint,
  endpointTarget,
  drugId,
  strategyId,
  history,
}: {
  sidebarKeyStatementId: number | null;
  handleAddEndpoint(endpointTargetId: number): Promise<void>;
  endpointTarget: EndpointTargetsWithOpportunitiesType[0];
  strategyId: number;
  drugId: number;
  history: ReturnType<typeof useHistory>;
}) => {
  return (
    <FooterWrapper>
      <AddIdea onClick={() => handleAddEndpoint(endpointTarget.id)}>
        + Add as an idea
      </AddIdea>
      <div
        style={{
          display: 'flex',
          gap: 5,
          alignItems: 'center',
        }}
        onClick={() => {
          history.push(
            `/d/${drugId}/strategy/${strategyId}/2_3/${endpointTarget.opportunity.stakeholder}/${endpointTarget.opportunity.stakeholderDefinitionId}?originalIdeaId=${endpointTarget.id}_${sidebarKeyStatementId}`
          );
        }}
      >
        <Icon
          name={'Edit'}
          fillColor={colors.purple}
          size={20}
          color={colors.purple}
          style={{ cursor: 'pointer' }}
        />
        <ButtonLabel color={colors.purple}>Edit original</ButtonLabel>
      </div>
    </FooterWrapper>
  );
};
