import { PatientJourneyColumnFragment } from 'data/graphql/generated';

export function calcColumnStartingPoints(
  columns: PatientJourneyColumnFragment[]
) {
  // this return an array of starting points based on the column idx
  // [0, 200, 400, 500]
  return columns
    .slice()
    .sort((a, b) => a.idx - b.idx)
    .reduce(
      (acc, val) => {
        const newPoint = acc[acc.length - 1] + val.width;
        return [...acc, newPoint];
      },
      [0]
    );
}
