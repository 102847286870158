import { GoalTrackingProgressDonut } from './GoalTrackingProgressDonut';

import { NoGoalTrackingAvailable } from './NoGoalTrackingAvailable';
import { getProgressStatus } from '../util-goal-tracking';
import { CriticalMetricGoalInput } from 'data/graphql/generated';

interface TrackedGoalPreviewProps {
  goal: string;
  target: number;
  period: string;
  goalInput: CriticalMetricGoalInput;
  progress?: number;
  onClick: () => void;
  numberOfSupportedCountries: number;
  numberOfCountriesWithTracking?: number;
  isActive?: boolean;
}

export const TrackedGoalPreview = ({
  goal,
  target,
  period,
  goalInput,
  progress,
  onClick,
  numberOfSupportedCountries,
  numberOfCountriesWithTracking = 0,
  isActive = false,
}: TrackedGoalPreviewProps) => {
  const isOnTrack: boolean =
    progress !== undefined ? progress >= target : false;
  const precisionProgress =
    progress !== undefined ? Number(progress.toFixed(0)) : undefined;
  const status = getProgressStatus(target, precisionProgress);
  const subtitle = `Target: ${target}%`;
  const trackingTitle =
    goalInput === CriticalMetricGoalInput.GlobalOnly
      ? 'Global tracking only'
      : `${numberOfCountriesWithTracking} of ${numberOfSupportedCountries} tracked`;

  if (progress === undefined) {
    return (
      <NoGoalTrackingAvailable
        title={goal}
        subtitle={subtitle}
        trackingTitle={trackingTitle}
        period={period}
        target={target}
        onClick={onClick}
        isPreview
        isActive={isActive}
      />
    );
  }

  return (
    <GoalTrackingProgressDonut
      title={goal}
      subtitle={subtitle}
      trackingTitle={trackingTitle}
      status={status || ''}
      period={period}
      target={target}
      percentage={progress}
      isOnTrack={isOnTrack}
      onClick={onClick}
      isPreview
      isActive={isActive}
    />
  );
};
