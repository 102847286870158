import React, { useEffect, useState } from 'react';
import {
  PatientJourneyBlockFragment,
  PatientJourneyColumn,
  PatientJourneyConnection,
} from 'data/graphql/generated';
import { Group } from 'react-konva';

import { Column } from './Column';
import { Cursor } from 'types';
import { RECT_WIDTH } from 'components/PatientJourney/constants';

function calcColumnLowestValidXPoints(
  blocks: PatientJourneyBlockFragment[],
  columns: PatientJourneyColumn[],
  columnStartingPoints: number[]
) {
  // Map columns to object to save us from looping through columns later
  const colsById = columns.reduce((colsById, col) => {
    colsById[col.id] = col;
    return colsById;
  }, {} as Record<keyof typeof columns, PatientJourneyColumn>);

  // this return an array of column lowest valid x point
  // for dragging validation

  // calc lowest valid x val for column width
  return blocks.reduce(
    (acc, b) => {
      // get column
      const col = colsById[b.columnId];
      if (!col) return acc;
      const x = b.x + RECT_WIDTH + 20;
      // is x + width higher than current
      if (x > acc[col.idx]) {
        acc[col.idx] = x;
      }
      return acc;
    },
    // add 240 to the starting point of the column
    columnStartingPoints.map((x) => x + 240)
  );
}

interface Props {
  columns: PatientJourneyColumn[];
  blocks: PatientJourneyBlockFragment[];
  connections: PatientJourneyConnection[];
  columnStartingPoints: number[];
  changeCursor(cursor: Cursor): void;
  activeColumn: number | undefined;
  isDrag: boolean;
  stageRef: any;
  updateColumn(connection: Partial<PatientJourneyColumn>): void;
  updateColumnLocal(connection: Partial<PatientJourneyColumn>): void;
  updateMany: any;
  setDisableActions(val: boolean): any;
  setCreate(): void;
}

export const Columns: React.FC<Props> = ({
  blocks,
  columns,
  connections,
  columnStartingPoints,
  changeCursor,
  activeColumn,
  updateColumn,
  updateColumnLocal,
  isDrag,
  stageRef,
  updateMany,
  setDisableActions,
  setCreate,
}) => {
  const [columnLowestValidXPoints, setColLowestValidXPoints] = useState(
    calcColumnLowestValidXPoints(blocks, columns, columnStartingPoints)
  );

  // updates on blocks changes
  useEffect(() => {
    setColLowestValidXPoints(
      calcColumnLowestValidXPoints(blocks, columns, columnStartingPoints)
    );
  }, [blocks, columns, columnStartingPoints]);

  return (
    <Group>
      {columns.map((column) => (
        <Column
          key={column.id}
          column={column}
          blocks={blocks}
          connections={connections}
          columns={columns}
          columnStartingPoints={columnStartingPoints}
          columnLowestValidXPoints={columnLowestValidXPoints}
          changeCursor={changeCursor}
          activeColumn={activeColumn}
          isDrag={isDrag}
          stageRef={stageRef}
          updateColumn={updateColumn}
          updateColumnLocal={updateColumnLocal}
          updateMany={updateMany}
          setDisableActions={setDisableActions}
          setCreate={setCreate}
        />
      ))}
    </Group>
  );
};
