import React from 'react';
import styled from 'styled-components';

const Image = styled.div<{ imageURL: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: ${({ imageURL }) => `url(${imageURL})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;
const BG = styled.div<{ imageURL: string; blurAmount: number }>`
  background-image: ${({ imageURL }) => `url(${imageURL})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1.5);
  filter: ${({ blurAmount }) => `blur(${blurAmount}px)`};

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

interface Props {
  imageURL: string;
  className?: string;
  blurAmount?: number;
}

export const BlurredBGImage = ({
  imageURL,
  className,
  blurAmount = 15,
}: Props) => {
  return (
    <Wrapper className={className}>
      <BG imageURL={imageURL} blurAmount={blurAmount}></BG>
      <Image imageURL={imageURL}></Image>
    </Wrapper>
  );
};
