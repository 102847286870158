import {
  BodyNormal,
  BodySmall,
  ButtonLabel,
  ButtonRound,
  Collaboration,
  EditableTextarea,
  Icon,
} from 'components/shared';
import { colors } from 'constants/index';
import { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  SupportingMessageTacticFragment,
  SupportingMessageTacticImportance,
  SupportingMessageTacticUpdateInput,
} from 'data/graphql/generated';
import { thousandSeparator } from 'utils/thousandSeparator';
import { device } from 'utils/breakpoints';
import { DatePickerDropdown } from 'components/MedicalStrategy/DatePickerDropdown';
import { DateType } from 'components/shared/DatePicker';
import { lowerCase, upperFirst } from 'lodash';
import {
  dateStringToMonthYear,
  getTimestamp,
} from 'utils/dateStringToMonthYear';
import { formatTimestamp } from 'utils/formatTimestamp';
import { IconName } from 'types';
import { AccessStrategyTacticsRowDropdown } from './AccessStrategyTacticsRowDropdown';
import FocusDropdown from 'components/shared/FocusDropdown';
import useManageFocusRationale from 'hooks/useManageFocusRationale';
import { AccessStrategyRationaleModal } from './AccessStrategyRationaleModal';

export const DetailItem = styled.div`
  display: flex;
  gap: 5px;
`;

export const MobileFocusDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 5px;
  border: 1px solid ${colors.greyMedium};
  padding: 5px;
  position: relative;
`;

const DeleteConfirmationWrapper = styled.div`
  display: flex;
  flex: none;
  width: 188px;
  height: 30px;

  background: ${colors.white};
  border: 0.5px solid ${colors.black30};
  border-radius: 5px;

  padding: 4px 20px;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  right: 0;
  z-index: 2;

  @media ${device.tabletMax} {
    right: 0;
  }
`;

export const MobileOuterWrapper = styled.div<{ focus: boolean }>`
  position: relative;
  background: ${({ focus }) =>
    focus ? colors.purplePinkGradient : colors.white};
  border-radius: 5px;
  border: 1px solid transparent;
  ::before {
    display: ${({ focus }) => (focus ? 'block' : 'none')};
    position: absolute;
    inset: 0;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: ${colors.fadedGradient}, white;
    z-index: 0;
  }
`;

export const MobileWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
`;

export const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  align-items: center;
  z-index: 1;
  padding: 0 15px;
`;

export const Wrapper = styled.div<{ focus: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  background: ${({ focus }) =>
    focus ? colors.purplePinkGradient : colors.white};
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 15px 0;

  ::before {
    display: ${({ focus }) => (focus ? 'block' : 'none')};
    position: absolute;
    inset: 0;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: ${colors.fadedGradient}, white;
    z-index: 0;
  }

  .Row__deleteIcon {
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;
  }

  &:hover {
    .Row__deleteIcon {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const TimingWrapper = styled.div`
  display: flex;
  flex: none;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;

export const accessStrategyTacticsRowColumns = css`
  grid-template-columns:
    minmax(229px, 442px)
    144px
    minmax(442px, 498px);

  column-gap: 15px;
`;

const ColumnsWrapper = styled.div`
  display: grid;
  padding: 0 15px;
  ${accessStrategyTacticsRowColumns}
  position: relative;
  align-items: center;
`;

export interface AccessStrategyTacticsRowProps {
  data?: SupportingMessageTacticFragment;
  onUpdate(updatedData: SupportingMessageTacticUpdateInput): void;
  currency: string;
  onDelete(): void;
  isLead: boolean;
  isGlobalContributor: boolean;
  isCountryContributor: boolean;
  isDesktop: boolean;
  autoFocus?: boolean;
  canEdit?: boolean;
}

export type optionType = {
  id: SupportingMessageTacticImportance;
  value: string;
} | null;

export const AccessStrategyTacticsRow = ({
  data,
  onUpdate,
  currency,
  onDelete,
  isLead,
  isGlobalContributor,
  isCountryContributor,
  isDesktop,
  autoFocus,
  canEdit,
}: AccessStrategyTacticsRowProps) => {
  const [selectedOption, setSelectedOption] = useState<optionType>(
    !!data?.importance
      ? {
          id: data.importance,
          value: data.importance,
        }
      : null
  );
  const [isFocus, setIsFocus] = useState(data?.focus || false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const currencyIcon = currency[0];

  const fromDateUpdateHandler = useMemo(
    () => (date: DateType | null) => {
      if (!canEdit) {
        return;
      }
      if (typeof date?.month !== 'number') {
        onUpdate({ to: null, from: null });
        return;
      }

      const to = getTimestamp(data?.to);

      const toDateYear = to ? new Date(+to).getFullYear() : 0;
      const toDateMonth = to ? new Date(+to).getMonth() : 0;

      if (
        data?.to &&
        (date.year > toDateYear ||
          (date.year === toDateYear && date.month > toDateMonth))
      ) {
        onUpdate({ to: null });
      }
      if (typeof date?.month === 'number' && typeof date?.year === 'number') {
        onUpdate({
          from: new Date(date.year, date.month, 10).toISOString(),
        });
      }
    },
    [canEdit, data?.to, onUpdate]
  );

  const canUpdate = (value: string | number) =>
    !!data?.id || (!data?.id && !!value);

  const toDateUpdateHandler = (date: DateType | null) => {
    if (!canEdit) {
      return;
    }
    const toDate = !!date
      ? new Date(date.year, date.month, 10).toISOString()
      : undefined;

    onUpdate({
      to: toDate,
    });
  };

  const formattedDueDate = useMemo(() => dateStringToMonthYear(data?.due), [
    data?.due,
  ]);
  const formattedFromDate = useMemo(() => dateStringToMonthYear(data?.from), [
    data?.from,
  ]);
  const formattedToDate = useMemo(() => dateStringToMonthYear(data?.to), [
    data?.to,
  ]);

  const displayDueDate = useMemo(
    () => (data?.due ? formatTimestamp(data.due) : ''),
    [data?.due]
  );

  const {
    modalSlideIdx,
    setModalSlideIdx,
    showSingleSlide,
    setShowSingleSlide,
  } = useManageFocusRationale();

  const closeModal = () => {
    setShowSingleSlide(false);
    setModalSlideIdx(null);
  };

  const openModal = () => setModalSlideIdx(0);

  async function toggleFocus() {
    if (!isFocus) {
      openModal();
      return;
    }

    try {
      setIsFocus(false);
      await onUpdate({ focus: false });
    } catch (error) {
      setIsFocus(true);
      alert('Something went wrong');
    }
  }

  if (!data) return null;

  if (!isDesktop)
    return (
      <>
        <AccessStrategyRationaleModal
          closeModal={closeModal}
          modalSlideIdx={modalSlideIdx}
          setModalSlideIdx={setModalSlideIdx}
          showSingleSlide={showSingleSlide}
          data={data}
          rowData={{
            lowerLimit: formattedToDate,
            dueDate: formattedDueDate,
            responsibility: data.responsibility,
            budget: data.budget,
          }}
          currency={currency}
          setIsFocus={setIsFocus}
          onUpdate={onUpdate}
        />
        <MobileOuterWrapper focus={data.focus}>
          <MobileWrapper>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FocusDropdown
                onClick={toggleFocus}
                checked={isFocus}
                setModalSlideIdx={setModalSlideIdx}
                setShowSingleSlide={setShowSingleSlide}
                rationaleText={data.focusRationale}
                disabledTooltipText={
                  isGlobalContributor
                    ? 'Only Leads can edit'
                    : 'Cannot focus until timing is set'
                }
                disabled={
                  isGlobalContributor ||
                  isCountryContributor ||
                  !formattedToDate.length
                }
              />

              {!!isLead &&
                (!showDeleteConfirm ? (
                  <ButtonRound
                    level="secondary"
                    iconName="Trash"
                    size="small"
                    onClick={() => {
                      setShowDeleteConfirm(true);
                    }}
                    className="Row__deleteIcon"
                  />
                ) : (
                  <DeleteConfirmationWrapper>
                    <ButtonLabel
                      color={colors.black}
                      onClick={() => setShowDeleteConfirm(false)}
                    >
                      Cancel
                    </ButtonLabel>
                    <ButtonLabel
                      color={colors.red}
                      onClick={async () => {
                        if (!isDeleting) {
                          setIsDeleting(true);
                          await onDelete();

                          setShowDeleteConfirm(false);
                          setIsDeleting(false);
                        }
                      }}
                    >
                      Delete row
                    </ButtonLabel>
                  </DeleteConfirmationWrapper>
                ))}
            </div>
            <div>
              <BodySmall color={colors.greyDark}>Access initiative</BodySmall>
              <EditableTextarea
                editable={!!canEdit}
                autoFocus={autoFocus}
                style={{ background: 'transparent' }}
                placeholder="Access initiative"
                initialValue={data.text}
                onBlur={(value) => {
                  if (!canEdit) {
                    return;
                  }
                  if (canUpdate(value)) onUpdate({ text: value });
                }}
              />
            </div>

            <div>
              <BodySmall color={colors.greyDark}>Importance</BodySmall>
              <AccessStrategyTacticsRowDropdown
                editable={!!canEdit}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                onUpdate={onUpdate}
              />
            </div>

            <div>
              <BodySmall color={colors.greyDark}>Timing</BodySmall>
              <div style={{ display: 'flex', gap: 10 }}>
                <DatePickerDropdown
                  disabled={!canEdit}
                  style={{ flex: 1 }}
                  placeholder="From"
                  date={formattedFromDate}
                  onUpdate={(date) => {
                    fromDateUpdateHandler(date);
                  }}
                />
                <DatePickerDropdown
                  disabled={!formattedFromDate.length || !canEdit}
                  alignRight
                  style={{ flex: 1 }}
                  placeholder="To"
                  lowerLimit={formattedFromDate}
                  date={formattedToDate}
                  onUpdate={toDateUpdateHandler}
                />
              </div>
            </div>
            {!!data.focus && (
              <MobileFocusDetails>
                <div>
                  <BodySmall color={colors.greyDark}>Due</BodySmall>
                  <BodyNormal>{displayDueDate}</BodyNormal>
                </div>
                <div>
                  <BodySmall color={colors.greyDark}>Responsibility</BodySmall>
                  <BodyNormal>{data.responsibility}</BodyNormal>
                </div>
                <div>
                  <BodySmall color={colors.greyDark}>Budget</BodySmall>
                  {!!data.budget && (
                    <BodyNormal>
                      {currencyIcon + thousandSeparator(data.budget)}
                    </BodyNormal>
                  )}
                </div>
                <Icon
                  name={'Edit'}
                  size={20}
                  color={colors.black}
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: 5,
                    top: 5,
                  }}
                  onClick={() => {
                    setModalSlideIdx(0);
                    setShowSingleSlide(true);
                  }}
                />
              </MobileFocusDetails>
            )}
            <Collaboration
              style={{
                marginLeft: 'auto',
              }}
              collaboration={data.collaboration}
            />
          </MobileWrapper>
        </MobileOuterWrapper>
      </>
    );

  return (
    <>
      <AccessStrategyRationaleModal
        closeModal={closeModal}
        modalSlideIdx={modalSlideIdx}
        setModalSlideIdx={setModalSlideIdx}
        showSingleSlide={showSingleSlide}
        data={data}
        rowData={{
          lowerLimit: formattedToDate,
          dueDate: formattedDueDate,
          responsibility: data.responsibility,
          budget: data.budget,
        }}
        currency={currency}
        setIsFocus={setIsFocus}
        onUpdate={onUpdate}
      />

      <Wrapper focus={data.focus}>
        <ColumnsWrapper>
          <div>
            <EditableTextarea
              autoFocus={autoFocus}
              style={{ background: 'transparent' }}
              editable={!!canEdit}
              placeholder="Access initiative"
              initialValue={data.text}
              onBlur={(value) => {
                if (canUpdate(value)) {
                  onUpdate({ text: value });
                }
              }}
            />
          </div>
          <div>
            <AccessStrategyTacticsRowDropdown
              editable={!!canEdit}
              selectedOption={selectedOption}
              setSelectedOption={(e) => {
                if (!canEdit) {
                  return;
                }
                setSelectedOption(e);
              }}
              onUpdate={(e) => {
                if (!canEdit) {
                  return;
                }
                onUpdate(e);
              }}
            />
          </div>

          <TimingWrapper>
            <div style={{ display: 'flex', gap: 10 }}>
              <DatePickerDropdown
                disabled={!canEdit}
                style={{ width: 90 }}
                placeholder="From"
                date={formattedFromDate}
                onUpdate={(date) => {
                  if (!canEdit) {
                    return;
                  }
                  fromDateUpdateHandler(date);
                }}
              />
              <DatePickerDropdown
                disabled={!formattedFromDate.length || !canEdit}
                style={{ width: 90 }}
                placeholder="To"
                lowerLimit={formattedFromDate}
                date={formattedToDate}
                onUpdate={toDateUpdateHandler}
              />
            </div>
            <Collaboration collaboration={data.collaboration} />

            <FocusDropdown
              onClick={toggleFocus}
              checked={isFocus}
              setModalSlideIdx={setModalSlideIdx}
              setShowSingleSlide={setShowSingleSlide}
              rationaleText={data.focusRationale}
              disabledTooltipText={
                isGlobalContributor
                  ? 'Only Leads can edit'
                  : 'Cannot focus until timing is set'
              }
              disabled={
                isGlobalContributor ||
                isCountryContributor ||
                !formattedToDate.length
              }
            />
            {!!canEdit ? (
              <ButtonRound
                level="secondary"
                iconName="Trash"
                size="small"
                onClick={() => {
                  setShowDeleteConfirm(true);
                }}
                className="Row__deleteIcon"
              />
            ) : (
              <div style={{ width: 30, flex: 'none' }}></div>
            )}

            {!!showDeleteConfirm && (
              <DeleteConfirmationWrapper>
                <ButtonLabel
                  color={colors.black}
                  onClick={() => setShowDeleteConfirm(false)}
                >
                  Cancel
                </ButtonLabel>
                <ButtonLabel
                  color={colors.red}
                  onClick={async () => {
                    if (!isDeleting) {
                      setIsDeleting(true);
                      onDelete();

                      setShowDeleteConfirm(false);
                      setIsDeleting(false);
                    }
                  }}
                >
                  Delete row
                </ButtonLabel>
              </DeleteConfirmationWrapper>
            )}
          </TimingWrapper>
        </ColumnsWrapper>
        {!!data.focus && (
          <DetailsWrapper>
            <DetailItem>
              <BodyNormal color={colors.greyDark}>Due:</BodyNormal>
              <BodyNormal>{displayDueDate}</BodyNormal>
            </DetailItem>
            <DetailItem>
              <BodyNormal color={colors.greyDark}>Responsibility:</BodyNormal>
              <BodyNormal>{data.responsibility}</BodyNormal>
            </DetailItem>
            <DetailItem>
              <BodyNormal color={colors.greyDark}>Budget: </BodyNormal>
              {!!data.budget && (
                <BodyNormal>
                  {currencyIcon + thousandSeparator(data.budget)}
                </BodyNormal>
              )}
            </DetailItem>
            {!!isLead && (
              <Icon
                name={'Edit'}
                size={20}
                color={colors.black}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setModalSlideIdx(0);
                  setShowSingleSlide(true);
                }}
              />
            )}
          </DetailsWrapper>
        )}
      </Wrapper>
    </>
  );
};

export function getCategoryIcon(value: string): IconName {
  switch (value) {
    case SupportingMessageTacticImportance.NiceToHave:
      return 'Chevron-up';
    case SupportingMessageTacticImportance.MustHave:
      return 'HighPriority';
    default:
      return 'Chevron-up';
  }
}

export const ImportanceDropdownItem = ({ value }: { value: string }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icon
        name={getCategoryIcon(value)}
        size={25}
        color={colors.black}
        style={{
          marginRight: '5px',
        }}
      />
      <BodySmall color={colors.black}>{upperFirst(lowerCase(value))}</BodySmall>
    </div>
  );
};
