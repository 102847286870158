import { MutationFunctionOptions } from '@apollo/client';
import { colors, zIndex } from 'constants/index';
import { useAuthContext } from 'contexts/AuthContext';
import {
  ObservationCreateMutation,
  Exact,
  ObservationInput,
  ObservationUpdateMutation,
  ObservationUpdateInput,
  ObservationDeleteMutation,
  ObservationsQuery,
  Step,
  Stakeholder,
} from 'data/graphql/generated';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { Icon, Observation } from '.';
import { ButtonPill } from './ButtonPill';
import { BodySmall, Subtitle2 } from './TextStyles';
import useDesktop from 'hooks/useDesktop';
import { device } from 'utils/breakpoints';
import ReactDOM from 'react-dom';
import { GradientBG } from './Observation';
import { verifyUserRole } from 'utils/verifyUserRole';

interface Props {
  open: boolean;
  setOpen(state: boolean): void;
  step: Step;
  createObservation: (
    options?:
      | MutationFunctionOptions<
          ObservationCreateMutation,
          Exact<{
            data: ObservationInput;
          }>
        >
      | undefined
  ) => Promise<any>;
  updateObservation: (
    options?:
      | MutationFunctionOptions<
          ObservationUpdateMutation,
          Exact<{
            id: number;
            data: ObservationUpdateInput;
          }>
        >
      | undefined
  ) => Promise<any>;
  deleteObservation: (
    options?:
      | MutationFunctionOptions<
          ObservationDeleteMutation,
          Exact<{
            id: number;
          }>
        >
      | undefined
  ) => Promise<any>;
  data: ObservationsQuery | undefined;
  loading: boolean;
  stakeholder: Stakeholder;
  offsetTopOverride?: number;
  readonly?: boolean;
}

const Wrapper = styled.div<{
  open: boolean;
  offsetTop: number;
  offsetTopOverride: number | undefined;
}>`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: ${({ offsetTopOverride = 0 }) =>
    `calc(100vh -  50px - ${offsetTopOverride}px)`};
  top: 50px;
  background: linear-gradient(
      0deg,
      rgba(20, 20, 39, 0.05),
      rgba(20, 20, 39, 0.05)
    ),
    #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  right: ${({ open }) => (open ? '0px' : '-100%')};
  bottom: ${({ offsetTopOverride }) => (offsetTopOverride ? 0 + 'px' : 'auto')};

  z-index: ${zIndex.observationsMenu};

  transition: all 0.3s;

  @media ${device.tabletMin} {
    width: 430px;
  }

  ${GradientBG} {
    background: ${colors.white};
  }
`;

// This element prevents the Lead from clicking on anything whilst the observation is being created
const Overlay = styled.div<{ updating: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: ${({ updating }) => (updating ? 'block' : 'none')};
`;

const Header = styled.div`
  width: 100%;
  height: 61px;
  background: ${colors.white};
  padding: 15px 15px 16px 20px;
  display: flex;
  > * + * {
    margin-left: 5px;
  }
  align-items: center;
  > div:last-child {
    cursor: pointer;
    margin-left: auto;
  }
  > p > span {
    color: ${colors.black50};
  }
  path {
    fill: url(#paint0_linear);
  }
`;

const ContentWrapper = styled.div<{ topMargin: boolean }>`
  padding: 15px;
  display: flex;
  flex-direction: column;
  > * + * {
    margin-top: 15px;
  }
  height: 90%;
  overflow: auto;

  .content {
    display: flex;
    flex-direction: column;
    > * + * {
      margin-top: 15px;
    }
  }
  margin-top: ${({ topMargin }) => (topMargin ? '15px' : 0)};
  transition: margin 0.1s;
`;

const EmptyStateWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ObservationsMenu = ({
  open,
  setOpen,
  step,
  createObservation,
  updateObservation,
  deleteObservation,
  data,
  stakeholder,
  offsetTopOverride,
  readonly,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [observations, setObservations] = useState(
    () => data?.observations?.items || []
  );
  const [openObservationDropdown, setOpenObservationDropdown] = useState<
    number | false
  >(false);
  const [updating, setUpdating] = useState(false);
  const [topMargin, setTopMargin] = useState(false);

  const { drugId, strategyId } = useParams<{
    drugId: string;
    strategyId: string;
  }>();

  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);

  useEffect(() => {
    if (data?.observations?.items) {
      const updatedObservations = data?.observations?.items.slice();

      if (updatedObservations) {
        setObservations((observations) => {
          if (observations.some((item) => item === null)) {
            // if data is updating, check if there was a preview null item in the existing Observations list and add it back in
            updatedObservations.unshift(null);
          }

          return updatedObservations.sort((a, b) => {
            if (a?.createdAt && b?.createdAt) {
              return a?.createdAt > b.createdAt
                ? -1
                : a.createdAt < b.createdAt
                ? 1
                : 0;
            }
            return 0;
          });
        });
      }
    }
  }, [data]);

  useClickOutsideComponent(
    ref,
    (e) => {
      const selection = window.getSelection();
      const containsChild =
        selection?.focusNode && ref.current?.contains(selection.focusNode);

      !containsChild && setOpen(false);
    },
    ['stakeholderTab', 'modal']
  );

  const removePreview = () =>
    setObservations((state) => {
      return state.filter((val) => val !== null);
    });

  const isDesktop = useDesktop();

  useEffect(() => {
    if (open) removePreview();
  }, [open]);

  if (!data) {
    return null;
  }

  return ReactDOM.createPortal(
    <Wrapper
      ref={ref}
      offsetTopOverride={offsetTopOverride}
      offsetTop={ref.current?.offsetTop || 0}
      open={open}
    >
      <Overlay updating={updating} />
      <Header>
        <Icon
          name="Observation"
          size={30}
          color="linear-gradient(315deg, #FF00C8 -83%, #7800FF 93.75%)"
        />
        <Subtitle2>Observations</Subtitle2>
        <div
          className="cypress-observations-close"
          onClick={() => {
            setOpen(false);
          }}
        >
          <Icon name="Collapse right" size={30} color={colors.purple} />
        </div>
      </Header>
      {readonly ? (
        <div style={{ width: '100%', padding: '15px', paddingBottom: 0 }}>
          <BodySmall color={colors.black}>
            {`${
              isDesktop
                ? 'Add an insight on the left then click “Assign observations” to support it.'
                : 'Use a larger screen to add an insight and support it with these observations.'
            }`}
          </BodySmall>
        </div>
      ) : isLead ? (
        <div
          className="add-observation"
          style={{ width: 172, padding: '15px 0 0px 15px' }}
        >
          <ButtonPill
            iconName="Plus"
            text="Add observation"
            clickClassName="cypress-observation-create"
            onClick={() => {
              if (!observations.includes(null))
                setObservations(() => {
                  const newArr = observations.slice();
                  newArr.unshift(null);
                  return newArr;
                });
            }}
          />
        </div>
      ) : (
        <div style={{ width: '100%', padding: '15px', paddingBottom: 0 }}>
          <BodySmall color={colors.black}>
            Leads create observations to summarise contributions
          </BodySmall>
        </div>
      )}

      <ContentWrapper
        topMargin={topMargin}
        onScroll={(e) => {
          //@ts-ignore
          const position = e.target.scrollTop;

          if (position) {
            setTopMargin(true);
          } else setTopMargin(false);
        }}
      >
        {observations.length ? (
          <div className="content">
            {observations.map((observation) => {
              return readonly ? (
                <Observation
                  readonly
                  key={observation?.id || 'preview'}
                  data={observation}
                />
              ) : (
                <Observation
                  openObservationDropdown={openObservationDropdown}
                  setOpenObservationDropdown={setOpenObservationDropdown}
                  removePreview={removePreview}
                  setUpdating={setUpdating}
                  createObservation={createObservation}
                  updateObservation={updateObservation}
                  deleteObservation={deleteObservation}
                  key={observation?.id || -1}
                  preview={!observation?.id}
                  step={step}
                  data={observation}
                  strategyId={strategyId}
                  drugId={drugId}
                  stakeholder={stakeholder}
                />
              );
            })}
          </div>
        ) : (
          <EmptyStateWrapper>
            <Icon
              name="GenericEmptyState"
              size={115}
              height={115}
              color="initial"
            />
            <Subtitle2 color={colors.greyDark}>No observations yet</Subtitle2>
            <BodySmall color={colors.greyDark}>
              Leads can add key observations
            </BodySmall>
          </EmptyStateWrapper>
        )}
      </ContentWrapper>
    </Wrapper>,
    document.body
  );
};
