import React from 'react';
import { SidebarEmptyState } from 'components/GridPages/GridPageSidebar';
import { BodySmall, Subtitle2 } from 'components/shared';
import { colors } from 'constants/colors';
import { AccessStrategyEndpointTargetFragment } from 'data/graphql/generated';
import {
  Header,
  SidebarTitle,
  Content,
  CollapseIcon,
  EndpointTargetWrapper,
} from './EvidenceSideBar';
import { ReusableLargeSidebar } from 'components/shared/ReusableLargeSidebar';
import { EndpointTarget } from 'components/Collaboration/EndpointTarget';

interface Props {
  sidebarOpen: boolean;
  closeSidebar(): void;
  strategyId: number;
  drugId: number;
  desktopWrapperTop: number;
  totalNavHeight: number;
  evidenceGenerationIdeas: AccessStrategyEndpointTargetFragment[];
}

export default function PicoSidebar({
  sidebarOpen,
  closeSidebar,
  totalNavHeight,
  evidenceGenerationIdeas,
}: Props) {
  return (
    <ReusableLargeSidebar isOpen={sidebarOpen} onClickOutside={closeSidebar}>
      <Header>
        <SidebarTitle>
          <Subtitle2>Evidence generation ideas </Subtitle2>
        </SidebarTitle>
        <CollapseIcon
          onClick={closeSidebar}
          name="Collapse right"
          size={30}
          color={colors.purple}
        />
      </Header>
      {evidenceGenerationIdeas.length === 0 ? (
        <SidebarEmptyState title="No evidence generation ideas">
          <div>
            <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
              Ideas from the evidence tab will be shown here
            </BodySmall>
          </div>
        </SidebarEmptyState>
      ) : (
        <Content id="sidebar-content">
          {evidenceGenerationIdeas.map((idea) => (
            <EndpointTargetWrapper key={idea.id}>
              <EndpointTarget endpointTarget={idea?.endpointTarget || idea} />
            </EndpointTargetWrapper>
          ))}
        </Content>
      )}
    </ReusableLargeSidebar>
  );
}
