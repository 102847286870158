import { thousandSeparator } from 'utils/thousandSeparator';

export function getReadableBudget(currency: string, budget?: number | null) {
  const budgetAmount = budget || 0;

  const parsedValue = budgetAmount ? thousandSeparator(budgetAmount) : '-';
  const text = currency ? `${currency[0] + parsedValue}` : parsedValue;

  return text;
}
