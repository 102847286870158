import React from 'react';
import { colors } from 'constants/colors';
import styled from 'styled-components/macro';

import { AnimatingWaveIcon, BodyNormal } from './shared/';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 20px;
`;

interface Props {
  text?: string;
  color?: string;
  style?: React.CSSProperties;
  textColor?: string;
  className?: string;
}

export const Loading: React.FC<Props> = ({
  text = 'Loading...',
  color = colors.purple,
  style,
  textColor = colors.greyDark,
  className,
}) => {
  return (
    <Wrapper style={style} className={className}>
      <AnimatingWaveIcon className="icon" size={30} color={color} />
      <BodyNormal style={{ color: textColor }}>{text}</BodyNormal>
    </Wrapper>
  );
};

export const LoadingComponent: React.FC<{
  isLoading: boolean;
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}> = ({ isLoading, children, className }) => {
  if (isLoading) {
    return <Loading className={className} />;
  }

  return <>{children}</>;
};
