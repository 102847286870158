import { PatientFlowBlock } from 'data/graphql/generated';
import { BlockTree } from 'types';

export function constructTree(
  items: PatientFlowBlock[],
  block: PatientFlowBlock
): BlockTree[] | undefined {
  let count = 0;
  let hasIndependentAncestor = false;

  function buildTree(
    items: PatientFlowBlock[],
    block: PatientFlowBlock,
    depth: number,
    parent?: BlockTree
  ): BlockTree {
    if (!!parent?.isIndependent || parent?.hasIndependentAncestor) {
      hasIndependentAncestor = true;
    }

    const current: BlockTree = {
      ...block,
      left: count,
      depth,
      children: [],
      parent,
      hasIndependentAncestor,
      hasLeveragePointSibling: false,
    };
    const children = (items || [])
      .filter((b) => b.parentId === block.id)
      .map((b) => {
        if (!parent?.hasLeveragePointSibling) {
          const blockIsLeveragePoint = !!b?.stars?.find((star) => star.global)
            ?.stakeholderDefinitions?.length;

          if (parent && blockIsLeveragePoint) {
            current.hasLeveragePointSibling = true;
          }
        }

        return buildTree(items, b, depth + 1, current);
      });

    // recursively update parents
    if (parent && current.hasLeveragePointSibling) {
      parent.hasLeveragePointSibling = current.hasLeveragePointSibling;
    }

    if (!children.length) {
      count = count + 1;
      hasIndependentAncestor = false;
    }

    current.children = children;

    return current;
  }

  const tree: BlockTree = buildTree(items, block, 0, undefined);

  const layoutBlocks: BlockTree[] | undefined = treeToList(tree);

  return layoutBlocks?.map((b) => {
    if (b.default) {
      return {
        ...b,
        left: Math.floor(count / 2),
      };
    }

    // TODO Looks like the alignment issues are happening here. It's basing the parent position on the children
    if (b.children.length > 1) {
      let firstChildLeft = b.children[0].left;
      let lastChildLeft = b.children[b.children.length - 1].left;
      const avgLeft = (firstChildLeft + lastChildLeft) / 2;
      return { ...b, left: avgLeft };
    } else {
      return b;
    }
  });
}

function treeToList(root: BlockTree) {
  const stack: BlockTree[] = [];
  const array: BlockTree[] = [];
  const hashMap: any = {};

  stack.push(root);

  while (stack.length !== 0) {
    const node = stack.pop();
    if (!node) return;
    if (node.children === null) {
      if (!hashMap[node.id]) {
        hashMap[node.id] = true;
        array.push(node);
      }
    } else {
      for (var i = node.children.length - 1; i >= 0; i--) {
        stack.push(node.children[i]);
      }
      if (!hashMap[node.id]) {
        hashMap[node.id] = true;
        array.push(node);
      }
    }
  }

  return array;
}
