import { useState, useEffect } from 'react';

import { CriticalMetricGoalFrequency } from 'data/graphql/generated';
import { GoalFrequencyDropdownOptionType } from '../../../../shared-ui-goals/src';

export const useGoalFrequency = (
  initialFrequency?: CriticalMetricGoalFrequency | null
) => {
  const [
    frequency,
    setFrequency,
  ] = useState<GoalFrequencyDropdownOptionType | null>(
    initialFrequency != null
      ? { id: initialFrequency, value: initialFrequency }
      : null
  );

  useEffect(() => {
    if (initialFrequency != null) {
      setFrequency({ id: initialFrequency, value: initialFrequency });
    }
  }, [initialFrequency]);

  return {
    frequency,
    setFrequency,
  };
};
