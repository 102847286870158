import { useState } from 'react';

export default function useManageFocusRationale() {
  const [modalSlideIdx, setModalSlideIdx] = useState<number | null>(null);
  const [showSingleSlide, setShowSingleSlide] = useState<boolean>(false);
  const closeModal = () => setModalSlideIdx(null);

  return {
    modalSlideIdx,
    setModalSlideIdx,
    showSingleSlide,
    setShowSingleSlide,
    closeModal,
  };
}
