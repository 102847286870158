import { colors } from 'constants/index';
import React, { useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { clamp } from 'utils/clamp';
import { Caption } from '.';

const LineGraphWrapper = styled.div`
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  position: relative;
  flex: 1;
  border-radius: 0px 5px 5px 0px;

  @media ${device.mobile} {
    border-radius: 5px;
  }
`;

const GraphWrapper = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: 15px;

  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 10%,
      rgba(255, 255, 255, 0) 90%,
      rgba(255, 255, 255, 1) 100%
    );
  }
`;

const NumberMarkers = styled.div<{ sidePadding: number }>`
  position: absolute;
  bottom: 0;
  width: calc(100% - 30px);
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 ${({ sidePadding }) => sidePadding + '%'};
  padding-bottom: 15px;

  > p {
    color: ${colors.greyDark};
  }
`;

const WeakStrongLabel = styled.div`
  position: absolute;
  top: 39px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px;

  > p {
    color: ${colors.greyDark};
  }
`;
//Created with help from https://yqnn.github.io/svg-path-editor/
export const LineGraph = ({
  values,
  highestVote,
  maxRating,
  showWeakStrongLabel,
  className,
  showPeakLabel,
  labels = ['Weak', 'Strong'],
  conditionForSuccess = false,
}: {
  values: Record<number, number>;
  highestVote?: number;
  maxRating: number;
  showWeakStrongLabel?: boolean;
  className?: string;
  showPeakLabel?: boolean;
  labels?: string[];
  conditionForSuccess?: boolean;
}) => {
  const [viewboxWidth, setViewboxWidth] = useState(1);
  const [sidePadding, setSidePadding] = useState(0);

  const pathRef = useRef<SVGPathElement>(null);
  const peakKey = Object.keys(values)[0];
  const widthOfLinesOnends = 280;
  const lengthOfLineInbetween = 242;
  const widthOfPeak = 52;

  useLayoutEffect(() => {
    setViewboxWidth(
      (maxRating - 1) * lengthOfLineInbetween +
        maxRating * widthOfPeak +
        widthOfLinesOnends
    );
    if (pathRef.current?.getBBox().width)
      setSidePadding(
        (widthOfLinesOnends / 2 / pathRef.current?.getBBox().width) * 100
      );
  }, [maxRating, viewboxWidth]);

  return (
    <LineGraphWrapper className={className}>
      <GraphWrapper className="lineGraph__graphWrapper">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox={`0 -192 ${
            pathRef.current?.getBBox().width || viewboxWidth
          } 200`}
          preserveAspectRatio="none"
        >
          <path
            vectorEffect="non-scaling-stroke"
            ref={pathRef}
            strokeWidth="1"
            d={generatePath(values, maxRating, highestVote)}
            stroke="url(#lineGraph_gradient)"
            fill="none"
          />
          <defs>
            {conditionForSuccess ? (
              <linearGradient
                id="lineGraph_gradient"
                x1="0"
                x2="1"
                y1="0"
                y2="0"
              >
                <stop offset="25%" stopColor={colors.red} />
                <stop offset="37.5%" stopColor="#AB0EB3" />
                <stop offset="50%" stopColor={colors.purple} />
                <stop offset="62.5%" stopColor="#4242B6" />
                <stop offset="75%" stopColor={colors.green} />
              </linearGradient>
            ) : (
              <linearGradient
                id="lineGraph_gradient"
                x1="1831.83"
                y1="77.86"
                x2="1825.61"
                y2="-70.3491"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor={colors.pink} />
                <stop offset="1" stopColor={colors.purple} />
              </linearGradient>
            )}
          </defs>
        </svg>
      </GraphWrapper>
      {!!showWeakStrongLabel && (
        <WeakStrongLabel className="lineGraph__graphWrapper__weakStrongLabel">
          <Caption>{labels[0]}</Caption>
          <Caption>{labels[1]}</Caption>
        </WeakStrongLabel>
      )}

      <NumberMarkers
        className="lineGraph__numberMarkers"
        sidePadding={sidePadding}
      >
        {Array(maxRating)
          .fill(null)
          .map((val, idx) => (
            <Caption
              style={{
                //To align the numbers correctly, it helps if they are the same width as the peaks
                width: (widthOfPeak / viewboxWidth) * 100 + '%',
                textAlign: 'center',
              }}
              key={idx}
            >
              {showPeakLabel ? (+peakKey === idx + 1 ? idx + 1 : '') : idx + 1}
            </Caption>
          ))}
      </NumberMarkers>
    </LineGraphWrapper>
  );
};

function generatePath(
  values: Record<number, number>,
  maxRating: number,
  highestVote?: number
) {
  function generateSpike(
    voteAmount: number,
    mostRated: number,
    endSpike: boolean
  ) {
    const height = (clamp(voteAmount / mostRated, 0.1, 1) * -192).toFixed();

    if (voteAmount === 0) {
      return `l ${endSpike ? 192 : 294} 0`;
    }

    return `c 25 0 16 ${height} 26 ${height} 
                s 0 ${Math.abs(+height)} 26 ${Math.abs(+height)} 
                l ${endSpike ? 140 : 242} 0`;
  }

  const pathString = [];

  const keys = Object.keys(values).map(Number);

  //For every number up to the max number, generate a spike for it
  for (let i = 1; i <= maxRating; i++) {
    let mostRated = 0;
    if (highestVote) {
      mostRated = highestVote;
    } else {
      mostRated = Math.max(...Object.values(values));
    }

    const endSpike = i === maxRating;
    if (keys.includes(i)) {
      //Spike
      pathString.push(generateSpike(values[i], mostRated, endSpike));
    } else {
      //Flat
      pathString.push(generateSpike(0, mostRated, endSpike));
    }
  }

  return `M 0 0 
            l 140 0
            ${pathString.join(' ')}`;
}
