import { PostItsEmpty } from 'components/PostItsEmpty';
import { BodySmall } from 'components/shared';
import { colors } from 'constants/index';
import { Link } from 'react-router-dom';
import { verifyUserRole } from '../../utils/verifyUserRole';

export function ThePlanPageEmptyStates({
  drugId,
  strategyId,
  strategicImperativeId,
  showGlobalPlan,
  countryObjectivesExist,
  userRole,
  localCountryTacticsEnabled,
}: {
  drugId: string;
  strategyId: string;
  strategicImperativeId: string;
  showGlobalPlan: boolean;
  countryObjectivesExist: boolean;
  userRole: ReturnType<typeof verifyUserRole>;
  localCountryTacticsEnabled: boolean;
}) {
  switch (true) {
    case !localCountryTacticsEnabled &&
      (userRole.isGlobalContributor || userRole.isLead):
      return (
        <PostItsEmpty title="No strategic objectives">
          <div
            style={{
              textAlign: 'center',
              maxWidth: 800,
              margin: '0 auto',
            }}
          >
            <BodySmall
              style={{
                display: 'inline',
                color: colors.greyDark,
              }}
            >
              Winning actions will only appear when a Lead has defined strategic
              objectives in
            </BodySmall>
            <Link
              to={`/d/${drugId}/strategy/${strategyId}/3_4/strategic-objectives${
                strategicImperativeId ? '/' + strategicImperativeId : ''
              }`}
              style={{
                color: colors.greyDark,
                display: 'inline',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              {' '}
              3.4 Goals and Distinctive Capabilities
            </Link>
            <BodySmall
              style={{
                display: 'inline',
                color: colors.greyDark,
              }}
            >
              {' '}
              and focused on one or more initiatives in
            </BodySmall>
            <Link
              to={`/d/${drugId}/strategy/${strategyId}/4_3/winning-actions${
                strategicImperativeId ? '/' + strategicImperativeId : ''
              }`}
              style={{
                color: colors.greyDark,
                display: 'inline',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              {' '}
              4.3 Commercial strategy, part 2
            </Link>
          </div>
        </PostItsEmpty>
      );

    case countryObjectivesExist && !showGlobalPlan:
      return (
        <PostItsEmpty title="No global strategic objectives">
          <div
            style={{
              textAlign: 'center',
              maxWidth: 800,
              margin: '0 auto',
            }}
          >
            <BodySmall
              style={{
                display: 'inline',
                color: colors.greyDark,
              }}
            >
              Use the toggle above to consider tactics for country-specific
              medical objectives
            </BodySmall>
          </div>
        </PostItsEmpty>
      );

    default:
      return (
        <PostItsEmpty title="No strategic objectives">
          <div
            style={{
              textAlign: 'center',
              maxWidth: 800,
              margin: '0 auto',
            }}
          >
            <BodySmall
              style={{
                display: 'inline',
                color: colors.greyDark,
              }}
            >
              Winning actions will only appear when a Lead or Contributor has
              has defined strategic objectives in
            </BodySmall>
            <Link
              to={`/d/${drugId}/strategy/${strategyId}/3_4/strategic-objectives${
                strategicImperativeId ? '/' + strategicImperativeId : ''
              }`}
              style={{
                color: colors.greyDark,
                display: 'inline',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              {' '}
              3.4 Goals and Distinctive Capabilities
            </Link>
            <BodySmall
              style={{
                display: 'inline',
                color: colors.greyDark,
              }}
            >
              {' '}
              and focused on one or more initiatives in
            </BodySmall>
            <Link
              to={`/d/${drugId}/strategy/${strategyId}/4_3/winning-actions${
                strategicImperativeId ? '/' + strategicImperativeId : ''
              }`}
              style={{
                color: colors.greyDark,
                display: 'inline',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              {' '}
              4.3 Commercial strategy, part 2
            </Link>
          </div>
        </PostItsEmpty>
      );
  }
}
