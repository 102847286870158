import { FetchResult } from '@apollo/client/link/core/types';
import { DistributionModalValues } from 'components/CompetitiveLandscape/DistributionModal';
import { createScoreDistribution } from 'components/CompetitiveLandscape/Evaluation';
import {
  DeleteConfirmation,
  DeleteConfirmationBody,
  DeleteConfirmationFooter,
  StyledCaption,
  StyledDeleteButton,
} from 'components/Insight';
import { SliderRating } from 'components/PostItGroup/SliderRating';
import {
  BodyNormal,
  BodySmall,
  ButtonLabel,
  ButtonPill,
  ChartButton,
  Collaboration,
  Icon,
  LineGraph,
  ScorePill,
  Tooltip,
} from 'components/shared';
import { TextAreaInput } from 'components/shared/TextAreaInput';
import { colors } from 'constants/index';
import { useAuthContext } from 'contexts/AuthContext';
import {
  Role,
  SuccessConditionDeleteMutation,
  SuccessConditionFragment,
  SuccessConditionPerspective,
  SuccessConditionRatingFragment,
  SuccessConditionType,
  SuccessConditionUpsertMutation,
  User,
} from 'data/graphql/generated';
import React, {
  useEffect,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { device } from 'utils/breakpoints';
import styled, { css } from 'styled-components/macro';
import { average } from 'utils/average';
import { formatCreatedDate } from 'utils/formatCreatedDate';
import {
  PreviewAndDBDataType,
  SuccessConditionsProps,
} from './SuccessConditions';
import { debounceAsync } from 'utils/debounceAsync';
import { CategoryTag } from './CategoryTag';
import { CategoryButtons } from './CategoryButtons';
import { SuccessConditionsDropdown } from './SuccessConditionsDropdown';
import { verifyUserRole } from 'utils/verifyUserRole';

const ViewResultsButton = styled(ButtonPill)`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const StyledScorePill = styled(ScorePill)``;
const StyledChartButton = styled(ChartButton)``;
const StyledIcon = styled(Icon)`
  cursor: pointer;

  &:hover {
    color: #141427;
  }
`;

const RatingsHeader = styled.div`
  padding: 5px;
  padding-top: 0px;
  display: flex;
  width: 100%;
  align-items: center;

  > ${StyledScorePill} {
    margin-right: 5px;
  }

  > ${BodySmall} {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }

  > ${StyledIcon} {
    margin-left: auto;
  }
`;

const StyledLineGraph = styled(LineGraph)`
  height: 75px;
  width: 100%;
  flex: none;
  padding-top: 0;
  padding-bottom: 0;

  margin-top: auto;
  > .lineGraph__graphWrapper {
    height: 100%;
    padding-bottom: 20px;
    margin-bottom: 0;
  }

  > .lineGraph__graphWrapper__weakStrongLabel {
    top: 8px;
  }
  > .lineGraph__numberMarkers {
    padding-bottom: 0;
  }
`;

const SliderWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  height: 135px;
  z-index: 0;

  ${({ disabled }) => {
    if (disabled) {
      return css`
        > * {
          pointer-events: none;
          cursor: not-allowed;
        }
      `;
    }
  }}
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
  > ${BodySmall} {
    margin-top: 2px;
    margin-bottom: 5px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  margin: 1px;

  > ${StyledCaption} {
    margin-top: 8px;
  }
`;

const FooterWrapper = styled.div`
  padding: 5px 0 10px 0;
  border-bottom: 1px solid ${colors.greyLight};
`;

const FlagAndCollaboration = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
`;

const ConditionWrapper = styled.div`
  width: 100%;
  background: ${colors.white};

  @media ${device.tabletMin} {
    border: 1px solid ${colors.black30a};
    border-radius: 5px;
  }

  position: relative;

  padding: 15px;
  display: flex;
  flex-direction: column;

  ${TextAreaInput} {
    margin-top: 10px;
  }
`;

function getRatingValue(
  data: Partial<SuccessConditionFragment> &
    Pick<SuccessConditionFragment, 'localUid' | 'text'>,
  user: User | undefined
) {
  return data.ratings?.find((rating) => {
    const matchingRating = rating.successConditionId === data.id;

    return rating.userId === user?.id && matchingRating;
  })?.score;
}

export const Condition = ({
  data,
  highestVoteValue,
  upsertSuccessCondition,
  updateSuccessCondition,
  deleteSuccessCondition,
  autoFocus,
  isPreview,
  isNewItem,
  upsertRating,
  setShowDistributionModal,
  setTempSuccessCondition,
  tempSuccessCondition,
}: {
  data: PreviewAndDBDataType;
  highestVoteValue: number;
  upsertSuccessCondition: SuccessConditionsProps['upsertSuccessCondition'];
  updateSuccessCondition: SuccessConditionsProps['updateSuccessCondition'];
  deleteSuccessCondition(): Promise<
    FetchResult<SuccessConditionDeleteMutation>
  > | void;
  upsertRating: SuccessConditionsProps['upsertSuccessConditionRating'];
  autoFocus: boolean;
  isPreview: boolean;
  isNewItem?: boolean;
  setShowDistributionModal: (
    value: React.SetStateAction<DistributionModalValues | null>
  ) => void;
  setTempSuccessCondition: React.Dispatch<
    React.SetStateAction<PreviewAndDBDataType[]>
  >;
  tempSuccessCondition: PreviewAndDBDataType[];
}) => {
  const [{ user }] = useAuthContext();
  const [viewResults, setViewResults] = useState(false);
  const [type, setType] = useState<SuccessConditionType | undefined>(
    data.type || undefined
  );
  const [text, setText] = useState(data.text || '');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [didBlur, setDidBlur] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const isEditable =
    user?.role === Role.Lead || data?.user?.id === user?.id || !data?.user;

  const perspectives = [
    SuccessConditionPerspective.Stakeholder,
    SuccessConditionPerspective.Company,
    SuccessConditionPerspective.Competitor,
  ];

  const deleteButtonRef = useRef<HTMLDivElement>(null);
  const conditionRef = useRef<HTMLDivElement>(null);
  const textValidCheck = didBlur && !text;

  useEffect(() => {
    if (data && data.type) {
      setType(data.type);
      setText(data.text);
    }
  }, [data]);

  async function handleRating(sliderRatingValue: number) {
    if (data.localUid) {
      const region =
        user?.role === Role.Contributor && user?.country
          ? user?.country
          : undefined;

      const userId = user?.id;
      await upsertRating({
        score: sliderRatingValue,
        successConditionLocalUid: data.localUid,
        region,
        userId,
      });
    }
  }

  const debouncedHandleRating = debounceAsync(
    async (val: number) => await handleRating(val),
    500
  );

  const dataRatingValue = getRatingValue(data, user);

  return (
    <ConditionWrapper ref={conditionRef}>
      <StyledDeleteButton
        innerRef={deleteButtonRef}
        iconName="Trash"
        level="secondary"
        size="small"
        tooltip=""
        onClick={() => {
          setConfirmDelete(true);
        }}
        show={showDeleteButton && !confirmDelete}
      />
      {confirmDelete && (
        <DeleteConfirmation show={confirmDelete}>
          <div>
            <BodyNormal color={colors.black}>
              Delete this condition for success?
            </BodyNormal>
          </div>
          <DeleteConfirmationBody>
            <BodyNormal color={colors.black60}>
              Any discussion and files will be lost.
            </BodyNormal>
          </DeleteConfirmationBody>
          <DeleteConfirmationFooter>
            <ButtonLabel
              onClick={() => {
                setConfirmDelete(false);
                setShowDeleteButton(false);
              }}
            >
              Cancel
            </ButtonLabel>
            <ButtonLabel
              color={colors.red}
              onClick={async (e) => {
                if (!isDeleting) {
                  e.stopPropagation();
                  setIsDeleting(true);
                  await deleteSuccessCondition();
                  setIsDeleting(false);
                }
              }}
            >
              Delete
            </ButtonLabel>
          </DeleteConfirmationFooter>
        </DeleteConfirmation>
      )}
      <Header>
        <SuccessConditionsDropdown
          clearSelection={() => {
            if (data.id) {
              updateSuccessCondition(data.id, {
                perspective: SuccessConditionPerspective.None,
              });
            } else {
              setTempSuccessCondition([
                {
                  ...tempSuccessCondition[0],
                  perspective: SuccessConditionPerspective.None,
                },
              ]);
            }
          }}
          options={perspectives}
          selectOption={(perspective) => {
            if (data.id) {
              updateSuccessCondition(data.id, { perspective });
            } else {
              setTempSuccessCondition([
                { ...tempSuccessCondition[0], perspective },
              ]);
            }
          }}
          selectedOption={
            !data?.perspective ||
            data.perspective === SuccessConditionPerspective.None
              ? undefined
              : data.perspective
          }
          editable={isEditable}
        />

        {!!data.createdAt && (
          <StyledCaption color={colors.greyDark} state={!!isNewItem}>
            {data && typeof data.createdAt === 'string'
              ? formatCreatedDate(Number(data.createdAt))
              : null}
          </StyledCaption>
        )}
      </Header>

      <TextAreaInput
        $invalid={textValidCheck}
        style={{ flex: 'none', minHeight: 24, lineHeight: '25px' }}
        rows={1}
        $borderless
        value={text}
        autoFocus={autoFocus}
        onFocus={() => {
          setShowDeleteButton(true);
        }}
        onChange={(e) => {
          const target = e.target as HTMLTextAreaElement;
          setText(target.value);
        }}
        onBlur={(e) => {
          setDidBlur(true);

          const deleteBtn = deleteButtonRef.current;

          const newFocusedElement = e.relatedTarget as Node | null;

          if (newFocusedElement) {
            if (!deleteBtn?.contains(newFocusedElement)) {
              setShowDeleteButton(false);
            }
          }

          if (text !== data.text || (!!type && type !== data.type)) {
            if (!text) {
              setShowDeleteButton(false);
              setIsDeleting(true);

              deleteSuccessCondition();
              setIsDeleting(false);
            } else {
              const perspective = tempSuccessCondition[0]
                ? tempSuccessCondition[0].perspective
                : undefined;
              upsertSuccessCondition({
                text: text,
                perspective: perspective || undefined,
                localUid: data.localUid,
                type,
              });
            }
          }
        }}
      />

      <FooterWrapper>
        <FlagAndCollaboration>
          <Collaboration collaboration={data.collaboration} />
        </FlagAndCollaboration>

        {!!viewResults && (
          <CategoryTag
            successConditionType={
              (data?.type as SuccessConditionType) || undefined
            }
          />
        )}
      </FooterWrapper>
      <Tooltip
        id={'successConditions'}
        effect="float"
        place="right"
        multiline
      />

      <Ratings
        viewResults={viewResults}
        isPreview={!text}
        handleRating={debouncedHandleRating}
        setViewResults={(val) => setViewResults(val)}
        dataRatingValue={dataRatingValue}
        user={user}
        ratings={data?.ratings}
        highestVoteValue={highestVoteValue}
        setShowDistributionModal={() =>
          setShowDistributionModal({ title: text, ratings: data?.ratings })
        }
        type={type}
        setType={setType}
        text={text}
        upsertSuccessCondition={upsertSuccessCondition}
        data={data}
      />
    </ConditionWrapper>
  );
};

const Ratings = ({
  viewResults,
  isPreview,
  dataRatingValue,
  handleRating,
  setViewResults,
  user,
  ratings,
  highestVoteValue,
  setShowDistributionModal,
  setType,
  type,
  text,
  upsertSuccessCondition,
  data,
}: {
  viewResults: boolean;
  isPreview: boolean;
  dataRatingValue: number | undefined;
  handleRating(val: number): Promise<any> | undefined;
  setViewResults(val: boolean): void;
  user: User | undefined;
  ratings?: SuccessConditionRatingFragment[];
  highestVoteValue: number;
  setShowDistributionModal(): void;
  type?: SuccessConditionType;
  setType: Dispatch<SetStateAction<SuccessConditionType | undefined>>;
  text: string;
  upsertSuccessCondition: ({
    text,
    localUid,
    type,
  }: {
    text: string;
    localUid: string;
    type?: SuccessConditionType | undefined;
  }) => Promise<
    FetchResult<
      SuccessConditionUpsertMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;
  data: PreviewAndDBDataType;
}) => {
  const [isRating, setIsRating] = useState(false);
  const averageScore = average(
    ratings?.map((ratings) => ratings.score) || [0]
  ).toFixed(1);

  const [sliderRatingValue, setSliderRatingValue] = useState(
    dataRatingValue || 3
  );

  const { isLead } = verifyUserRole(user?.role, user?.country);

  useEffect(() => {
    if (dataRatingValue) {
      setSliderRatingValue(dataRatingValue);
    }
  }, [dataRatingValue]);

  if (viewResults && ratings) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            position: 'relative',
            paddingTop: 12,
          }}
        >
          <RatingsHeader>
            <StyledScorePill value={+averageScore} />
            {!!isLead && (
              <StyledChartButton
                onClick={() => {
                  setShowDistributionModal();
                }}
              />
            )}
            <BodySmall color={colors.greyDark}>Level of confidence</BodySmall>

            <StyledIcon
              name="Pencil"
              size={20}
              color={colors.greyDark}
              onClick={() => {
                setViewResults(false);
              }}
            />
          </RatingsHeader>

          <StyledLineGraph
            labels={['Low', 'High']}
            showWeakStrongLabel
            highestVote={highestVoteValue}
            values={createScoreDistribution(ratings)}
            maxRating={5}
            conditionForSuccess={true}
          />

          {isLead && (
            <CategoryButtons
              disabled={!isLead}
              onClick={(val: SuccessConditionType) => {
                const newValue =
                  val !== type ? val : SuccessConditionType.Unselected;

                setType(newValue);

                if (!!text) {
                  upsertSuccessCondition({
                    text,
                    localUid: data.localUid,
                    type: newValue,
                  });
                }
              }}
              selected={type}
            />
          )}
        </div>
      </>
    );
  }
  return (
    <SliderWrapper disabled={isPreview}>
      <BodySmall color={colors.greyDark}>Level of confidence</BodySmall>
      <SliderRating
        labels={['Low', 'High']}
        style={{ marginBottom: 15 }}
        user={user}
        value={sliderRatingValue}
        onChange={(val) => {
          setSliderRatingValue(+val);
        }}
        onMouseUp={(val) => {
          handleRating?.(+val);
        }}
        min={1}
        max={5}
        hideCover={!!dataRatingValue}
        onCoverClick={async () => {
          setIsRating(true);

          await handleRating?.(sliderRatingValue);

          setIsRating(false);
        }}
      />
      <ViewResultsButton
        className="cypress-success-condition-view-results"
        width={isLead ? 'unset' : '120px'}
        loading={isRating}
        disabled={!dataRatingValue}
        text={isLead ? 'View results and categorise' : 'View results'}
        level="secondary"
        onClick={async () => {
          setViewResults(true);
        }}
      />
    </SliderWrapper>
  );
};
