import { MedicalStrategyTabNames } from './MedicalStrategyTabNames';

export const MEDICAL_STRATEGY_TABS = [
  {
    title: 'Part 1: Hurdles & Gaps',
    substep: MedicalStrategyTabNames.HURDLES_AND_GAPS,
  },
  {
    title: 'Part 2: Medical Objectives',
    substep: MedicalStrategyTabNames.MEDICAL_OBJECTIVES,
  },
  {
    title: 'Part 3: Medical Tactics',
    substep: MedicalStrategyTabNames.MEDICAL_TACTICS,
  },
  {
    title: 'Summary',
    substep: MedicalStrategyTabNames.SUMMARY,
  },
];
