import { GoalTrackingConstants } from '../constants-goal-tracking';

export const getProgressStatus = (target: number, progress?: number) => {
  if (progress === undefined) {
    return null;
  }

  return Number(progress) >= target
    ? GoalTrackingConstants.ON_TARGET_TEXT
    : GoalTrackingConstants.OFF_TARGET_TEXT;
};
