import { Content, ModalLarge } from 'components/ModalLarge';
import { colors } from 'constants/index';
import React, { ComponentProps } from 'react';
import { BodySmall } from './TextStyles';
import styled from 'styled-components';
import useMobile from 'hooks/useMobile';

const StyledModal = styled(ModalLarge)`
  ${Content} {
    padding-bottom: 0;
  }
`;

const StyledModalChildren = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ isMobile }) => (isMobile ? `15px` : `20px 40px 40px`)};
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

const StyledDetailHeader = styled.div`
  width: 100%;
  background-color: ${colors.white};
  padding: 15px;
  display: flex;
  gap: 15px;
  flex-direction: column;
`;

type Props = {
  data?: Record<string, string>;
  children: React.ReactNode;
  headerColor?: string;
  DetailComponent?: React.ReactNode;
};

export const ModalWithDetail = (
  props: Props & ComponentProps<typeof ModalLarge>
) => {
  const { data, headerColor, children, DetailComponent, ...rest } = props;
  const isMobile = useMobile();

  return (
    <StyledModal
      size="small"
      headerColor={headerColor || colors.black05OnWhiteGradient}
      DetailHeader={
        !!DetailComponent || !!data ? (
          <StyledDetailHeader>
            {!!DetailComponent
              ? DetailComponent
              : !!data &&
                Object.entries(data).map((row, idx) => (
                  <div key={idx}>
                    <BodySmall color={colors.greyDark}>{row[0]}</BodySmall>
                    <BodySmall>{row[1] || 'Not identified yet'}</BodySmall>
                  </div>
                ))}
          </StyledDetailHeader>
        ) : null
      }
      {...rest}
    >
      <StyledModalChildren
        isMobile={isMobile}
        className="ModalWithDetail__ChildrenContainer"
      >
        {children}
      </StyledModalChildren>
    </StyledModal>
  );
};
