import React from 'react';
import { useDrag } from 'react-dnd';
import styled from 'styled-components/macro';
import { colors } from 'constants/colors';
import {
  DragNDropItems,
  PreviewOrExistingSolution,
  PreviewOrExistingTactic,
} from 'types';
import { PinDragPreview } from 'components/shared/PinDragPreview';
import { AccessStrategyPrioritiseSupportingMessageFragment } from 'data/graphql/generated';

const Wrapper = styled.div<{
  isDragging: boolean;
}>`
  opacity: ${({ isDragging }) => (isDragging ? 0.5 : 1)};
  border: ${({ isDragging }) =>
    isDragging ? `1px solid ${colors.purple50}` : '1px solid transparent'};
  border-radius: 5px;
`;
interface Props {
  data:
    | PreviewOrExistingTactic
    | PreviewOrExistingSolution
    | AccessStrategyPrioritiseSupportingMessageFragment;
  children: React.ReactNode;
  disableDrag: boolean;
  isCountryCard?: boolean;
}
export const DraggableCard: React.FC<Props> = ({
  data,
  children,
  disableDrag,
  isCountryCard,
}) => {
  const [{ isDragging }, dragRef] = useDrag(() => ({
    item: {
      type: DragNDropItems.CARD,
      data,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  const childrenWithProps = React.Children.map(children, (child) =>
    React.isValidElement(child)
      ? // @ts-ignore
        React.cloneElement(child, { isDragging })
      : child
  );

  return (
    <>
      {isDragging && (
        <PinDragPreview
          isCountryPin={isCountryCard}
          isBeingDragged={isDragging}
        />
      )}

      <Wrapper isDragging={isDragging} ref={disableDrag ? null : dragRef}>
        {childrenWithProps}
      </Wrapper>
    </>
  );
};
