import { useRef } from 'react';

import useDesktop from 'hooks/useDesktop';
import useGetElementTop from 'hooks/useGetElementTop';

export const useCustomTotalNavHeight = () => {
  const isDesktop = useDesktop();
  const totalNavHeight = isDesktop ? 114 : 164;
  const sidebarDesktopRef = useRef<HTMLDivElement | null>(null);
  const desktopWrapperTop = useGetElementTop(sidebarDesktopRef);

  return {
    totalNavHeight,
    sidebarDesktopRef,
    desktopWrapperTop,
  };
};
