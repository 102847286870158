import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Icon, BodySmall, ImageAndTitle } from 'components/shared';
import { DropdownList } from 'components/shared/FormDropdown';
import { colors } from 'constants/colors';
import { IconContainer } from 'components/shared/Icon';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { Wrapper as ImageAndTitleWrapper } from 'components/shared/ImageAndTitle';
import { BodyNormal } from './TextStyles';
import { device } from 'utils/breakpoints';
import { StakeholderDefinitionForKeyStakeholdersDropdownFragment } from 'data/graphql/generated';
import { useHandleDropdownBottomOverlap } from 'hooks/useHandleDropdownBottomOverlap';

const Wrapper = styled.div`
  position: relative;
  max-width: 250px;
`;

const IconDeselect = styled(Icon)`
  visibility: hidden;
  background: ${colors.greyDark};
  margin-top: 2.5px;
  &:hover {
    background: ${colors.black};
  }
`;

const NonEditable = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  ${ImageAndTitleWrapper} {
    max-width: none;
  }
`;

const SelectedOption = styled.div<{ dropdownActive: boolean }>`
  width: 100%;

  display: flex;
  gap: 5px;
  padding: 1.5px 5px;
  border-radius: 5px;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  border: 1px solid transparent;

  &:hover {
    background: ${({ dropdownActive }) => !dropdownActive && colors.greyLight};
    ${IconDeselect} {
      visibility: visible;
    }
  }

  ${({ dropdownActive }) =>
    dropdownActive &&
    css`
      background: none;
      border: 1px solid ${colors.black};
    `}
`;

const StyledDropdownList = styled(DropdownList)<{ showAbove: null | number }>`
  border: 1px solid ${colors.black30};
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  position: absolute;
  height: fit-content;
  max-height: 220px;
  width: 100%;

  ${ImageAndTitleWrapper} {
    max-width: none;
  }

  @media ${device.desktopMin} {
    width: 450px;
  }

  transform: ${({ showAbove }) =>
    showAbove ? `translateY(calc(-100% + ${showAbove}px))` : 'unset'};
`;

const DropdownOption = styled.li<{
  isSelected: boolean;
  disableHover?: boolean;
}>`
  width: 100%;
  list-style-type: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: ${({ isSelected }) => isSelected && `2px solid ${colors.purple}`};
  padding: 5px;
  min-height: 30px;
  flex: none;

  ${({ disableHover }) => {
    if (!disableHover) {
      return css`
        :hover {
          background-color: ${colors.greyLight};
        }
      `;
    } else {
      return css`
        cursor: not-allowed;
        * {
          pointer-events: none;
        }
      `;
    }
  }}

  :last-child {
    margin-bottom: 0;
  }

  ${IconContainer} {
    align-self: flex-start;
  }
`;

const DropdownOptionEmptyState = styled.div`
  width: 100%;
  height: fit-content;
  max-width: 450px;
  list-style-type: none;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 15px 25px;
  position: absolute;
  background: ${colors.white};
  border: 1px solid ${colors.black30};
  z-index: 1;

  @media ${device.desktopMin} {
    width: 450px;
  }
`;

interface Props {
  options: StakeholderDefinitionForKeyStakeholdersDropdownFragment[];
  selectedOption?: StakeholderDefinitionForKeyStakeholdersDropdownFragment | null;
  selectOption: (
    option: StakeholderDefinitionForKeyStakeholdersDropdownFragment
  ) => void;
  clearSelection: () => void;
  editable?: boolean;
  emptyState?: {
    header: string;
    text: string;
  };
  style?: React.CSSProperties;
}
export const UserDropdown: React.FC<Props> = ({
  options,
  selectedOption,
  selectOption,
  clearSelection,
  editable = true,
  emptyState,
  style,
}) => {
  const [dropdownActive, setDropdownActive] = React.useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const dropdownRef = React.useRef<HTMLUListElement | null>(null);

  useClickOutsideComponent(containerRef, (event) => {
    setDropdownActive(false);
  });

  const translateYVal = useHandleDropdownBottomOverlap({
    containerRef,
    dropdownRef,
    isActive: dropdownActive,
  });

  if (!editable)
    return (
      <Wrapper style={style} ref={containerRef}>
        <NonEditable>
          <div className="UserDropdown__NonEditable__content">
            {selectedOption ? (
              <ImageAndTitle
                stakeholder={
                  selectedOption.id ? undefined : selectedOption.stakeholder
                }
                imageUrl={selectedOption?.image}
                title={selectedOption?.title}
              />
            ) : (
              <NoUserSelected />
            )}
          </div>
        </NonEditable>
      </Wrapper>
    );

  return (
    <Wrapper style={style} ref={containerRef}>
      <SelectedOption
        dropdownActive={dropdownActive}
        onClick={() => setDropdownActive(true)}
        onBlur={() => setDropdownActive(false)}
      >
        {selectedOption ? (
          <>
            <ImageAndTitle
              stakeholder={
                selectedOption.id ? undefined : selectedOption.stakeholder
              }
              imageUrl={selectedOption?.image}
              title={selectedOption?.title}
            />

            <IconDeselect
              name="X-Cross"
              size={20}
              color={colors.white}
              style={{
                borderRadius: '50%',
                height: '20px',
                alignSelf: 'flex-start',
              }}
              onClick={(e) => {
                e.stopPropagation();
                clearSelection();
              }}
            />
          </>
        ) : (
          <NoUserSelected />
        )}
      </SelectedOption>

      {dropdownActive &&
        (options?.length ? (
          <StyledDropdownList ref={dropdownRef} showAbove={translateYVal}>
            {options.map((option) => {
              // Group stakeholders have an id of 0
              if (!option.id) {
                return (
                  <DropdownOption
                    key={option.title}
                    onClick={() => {
                      setDropdownActive(false);
                      selectOption(option);
                    }}
                    isSelected={option?.title === selectedOption?.title}
                  >
                    <ImageAndTitle
                      stakeholder={option.stakeholder}
                      imageUrl={option?.image}
                      title={option?.title}
                    />
                  </DropdownOption>
                );
              }
              return (
                <DropdownOption
                  key={option.id}
                  onClick={() => {
                    setDropdownActive(false);
                    selectOption(option);
                  }}
                  isSelected={option?.id === selectedOption?.id}
                >
                  <ImageAndTitle
                    imageUrl={option?.image}
                    title={option?.title}
                  />
                </DropdownOption>
              );
            })}
          </StyledDropdownList>
        ) : (
          <DropdownEmptyState
            header={emptyState?.header}
            text={emptyState?.text}
          />
        ))}
    </Wrapper>
  );
};

export const NoUserSelected = () => (
  <div style={{ display: 'flex' }}>
    <Icon
      name="Patient"
      size={25}
      style={{
        background: colors.greyLight,
        color: colors.black,
        borderRadius: '50%',
        marginRight: '5px',
        padding: '2px',
        height: 25,
      }}
    />
    <BodySmall
      style={{ fontWeight: 500, display: 'flex', alignItems: 'center' }}
      color={colors.greyDark}
    >
      Choose key stakeholder
    </BodySmall>
  </div>
);

export const DropdownEmptyState = ({
  header = '',
  text = '',
}: {
  header?: string | React.ReactNode;
  text?: string | React.ReactNode;
}) => {
  return (
    <DropdownOptionEmptyState>
      {typeof header === 'string' ? (
        <BodyNormal color={colors.greyDark} style={{ marginBottom: '5px' }}>
          {header}
        </BodyNormal>
      ) : (
        header
      )}

      {typeof text === 'string' ? (
        <BodySmall color={colors.greyDark}>{text}</BodySmall>
      ) : (
        text
      )}
    </DropdownOptionEmptyState>
  );
};
