import { ButtonLabel } from 'components/shared';
import { ReusableDropdown } from 'components/shared/ReusableDropdown';
import { colors } from 'constants/colors';
import { SupportingMessageTacticImportance } from 'data/graphql/generated';
import React from 'react';
import {
  AccessStrategyTacticsRowProps,
  ImportanceDropdownItem,
  optionType,
} from './AccessStrategyTacticsRow';

type Props = {
  selectedOption: optionType;
  setSelectedOption: React.Dispatch<React.SetStateAction<optionType>>;
  onUpdate: AccessStrategyTacticsRowProps['onUpdate'];
  editable?: boolean;
};

export function AccessStrategyTacticsRowDropdown({
  selectedOption,
  setSelectedOption,
  onUpdate,
  editable = true,
}: Props) {
  return (
    <ReusableDropdown
      editable={!!editable}
      options={[
        {
          id: SupportingMessageTacticImportance.NiceToHave,
          value: SupportingMessageTacticImportance.NiceToHave,
        },
        {
          id: SupportingMessageTacticImportance.MustHave,
          value: SupportingMessageTacticImportance.MustHave,
        },
      ]}
      selectedOption={selectedOption}
      selectOption={(option) => {
        setSelectedOption(option);
        onUpdate({
          importance: option.id as SupportingMessageTacticImportance,
        });
      }}
      clearSelection={() => {
        setSelectedOption(null);
        onUpdate({ importance: null });
      }}
      dropdownItem={(option) => <ImportanceDropdownItem value={option.value} />}
      selectedDropdownItem={(option) => (
        <ImportanceDropdownItem value={option.value} />
      )}
      noSelectionItem={() => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ButtonLabel color={colors.greyDark}>Choose importance</ButtonLabel>
        </div>
      )}
    />
  );
}
