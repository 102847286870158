import styled from 'styled-components/macro';

import {
  CriticalMetricGoalInput,
  TrackedGoalFragment,
  useStrategyCountriesQuery,
} from 'data/graphql/generated';
import { GoalTrackingProgressDonut } from '../TrackedGoal/GoalTrackingProgressDonut';
import {
  getCurrentPeriodByFrequencyAndOffset,
  getProgressStatus,
} from '../util-goal-tracking';
import { NoGoalTrackingAvailable } from '../TrackedGoal/NoGoalTrackingAvailable';
import { LoadingComponent } from 'components/Loading';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

type CollaborationCriticalMetricsGoalProps = TrackedGoalFragment;

export const CollaborationCriticalMetricsTrackedGoal = ({
  title,
  target,
  frequency,
  input,
  goal,
  averageProgress,
  numberOfCountriesWithTracking = 0,
}: CollaborationCriticalMetricsGoalProps) => {
  const {
    data: strategyCountries,
    loading: supportedCountriesLoading,
  } = useStrategyCountriesQuery({
    variables: { id: goal.strategyId },
    fetchPolicy: 'cache-first',
  });

  const supportedCountries =
    strategyCountries?.strategyCountries?.countries ?? [];

  const progress = averageProgress ?? undefined;
  const { formatted: period } = getCurrentPeriodByFrequencyAndOffset(
    frequency,
    -1
  );
  const isOnTrack: boolean =
    progress !== undefined && progress !== null ? progress >= target : false;
  const status = getProgressStatus(target, progress);
  const subtitle = `Target: ${target}%`;

  const numberOfSupportedCountries = supportedCountries.length;
  const trackingTitle =
    input === CriticalMetricGoalInput.PerCountry
      ? `${numberOfCountriesWithTracking} of ${numberOfSupportedCountries} tracked`
      : `Globally tracked only`;

  if (!progress || !status) {
    return (
      <Container>
        <LoadingComponent isLoading={supportedCountriesLoading}>
          <NoGoalTrackingAvailable
            title={title}
            subtitle={subtitle}
            trackingTitle={trackingTitle}
            period={period}
            target={target}
            isExpanded
          />
        </LoadingComponent>
      </Container>
    );
  }

  return (
    <Container>
      <LoadingComponent isLoading={supportedCountriesLoading}>
        <GoalTrackingProgressDonut
          title={title}
          subtitle={subtitle}
          trackingTitle={trackingTitle}
          status={status}
          period={period}
          target={target}
          percentage={progress}
          isOnTrack={isOnTrack}
          isExpanded={true}
        />
      </LoadingComponent>
    </Container>
  );
};
