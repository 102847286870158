import { getWindowWidth } from 'hooks/getWindowWidth';

export const STAGE_HEIGHT = 125;
export const RECT_HEIGHT = 85;
export const RECT_WIDTH = 160;
export const NAV_HEIGHT = () => (getWindowWidth() > 944 ? 120 : 155);
export const BLOCK_PADDING_TOP = 40;
export const BLOCK_PADDING_BOTTOM = 25;
export const BLOCK_SIDE_PADDING = 5;
export const BLOCK_MARGIN_TOP = 20;
export const BLOCK_BORDER_WIDTH = 2;
export const STAGE_PADDING_TOP = 20;
export const STAGE_PADDING_BOTTOM = 20;
