import { Subtitle2, BodySmall, Icon } from 'components/shared';
import { colors } from 'constants/index';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

const EmptyStateWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

type Props = {
  drugId: string;
  strategyId: string;
};

export const EmptyState = ({ drugId, strategyId }: Props) => {
  return (
    <EmptyStateWrapper>
      <Icon name="GenericEmptyState" size={115} height={115} color="initial" />
      <Subtitle2 color={colors.greyDark}>Nothing to recap</Subtitle2>
      <BodySmall style={{ pointerEvents: 'all' }} color={colors.greyDark}>
        The team should create a global desired state in{' '}
        <Link
          to={`/d/${drugId}/strategy/${strategyId}/1_3`}
          style={{
            cursor: 'pointer',
            fontWeight: 500,
            color: colors.greyDark,
          }}
        >
          1.3 Patient Journey
        </Link>
      </BodySmall>
    </EmptyStateWrapper>
  );
};
