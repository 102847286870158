import React from 'react';
import { Group, Circle } from 'react-konva';

import {
  PatientJourneyBlockFragment,
  PatientJourneyConnectionInput,
} from 'data/graphql/generated';
import { KonvaEventObject } from 'konva/types/Node';
import { ConnectionSide, Cursor } from 'types';
import { SharedPatientJourneyTypes } from '../types/shared';
import {
  generateInitialPath,
  generatePathFromConnection,
  connectInLeastPoints,
} from '../utils';
import { colors } from 'constants/index';

export const Dot: React.FC<{
  x: number;
  y: number;
  block: PatientJourneyBlockFragment;
  layerDotsRef: any;
  id: string;
  side: ConnectionSide;
  createConnection(
    connection: Omit<PatientJourneyConnectionInput, 'strategy'>
  ): void;
  newConnection: SharedPatientJourneyTypes['newConnection'];
  setNewConnection(val: SharedPatientJourneyTypes['newConnection']): void;
  dotColor: string;
  changeCursor(type: Cursor): void;
  createShow(): void;
  createHide(): void;
  isDrag: boolean;
  adjustFromScreenX(y: number): number;
  adjustFromScreenY(y: number): number;
}> = ({
  x,
  y,
  block,
  layerDotsRef,
  id,
  createConnection,
  side,
  newConnection,
  setNewConnection,
  dotColor,
  changeCursor,
  createShow,
  createHide,
  isDrag,
  adjustFromScreenX,
  adjustFromScreenY,
}) => {
  function dotsShowAll() {
    if (isDrag) return;
    const dots = layerDotsRef.current.find('.dot');

    Object.keys(dots).forEach((k) => {
      const d = dots[k];
      if (!d || !d.show) return;
      d.opacity(0.5);
    });

    layerDotsRef.current.draw();
  }

  function dotsHideAll() {
    const dots = layerDotsRef.current.find('.dot');

    Object.keys(dots).forEach((k) => {
      const d = dots[k];
      if (!d || !d.show) return;
      d.opacity(0);
    });

    layerDotsRef.current.draw();
  }

  function handleClick(e: KonvaEventObject<MouseEvent>) {
    if (isDrag) return;
    // cancel event propagation
    e.cancelBubble = true;

    const dotNode = layerDotsRef.current
      .findOne(`#${id}`)
      .getAbsolutePosition();

    // if there is no current connection being created
    // then start one

    if (newConnection?.path?.length === 0) {
      createHide();

      const [fromBlockId, fromSide] = e.target
        // @ts-ignore
        .getId()
        .replace('block-', '')
        .replace('dot-', '')
        .split('-');

      setNewConnection({
        toBlockId: undefined,
        toSide: undefined,
        fromBlockId: Number(fromBlockId),
        fromSide: fromSide as ConnectionSide,
        path: [
          adjustFromScreenX(dotNode.x),
          adjustFromScreenY(dotNode.y),
          ...generateInitialPath(
            fromSide,
            adjustFromScreenX(dotNode.x),
            adjustFromScreenY(dotNode.y)
          ),
        ],
      });

      dotsShowAll();
    } else if (newConnection?.path?.length === 2) {
      createShow();
      // if no points have been set: auto draw path
      if (!newConnection.fromBlockId) return;
      if (!newConnection.fromSide) return;

      const [toBlockId, toSide] = e.target
        // @ts-ignore
        .getId()
        .replace('block-', '')
        .replace('dot-', '')
        .split('-');

      // if connecting to own block, cancel connetion
      if (Number(toBlockId) === newConnection.fromBlockId) return;

      createConnection({
        fromBlockId: Number(newConnection.fromBlockId),
        fromSide: newConnection.fromSide,
        toBlockId: Number(toBlockId),
        toSide: toSide as ConnectionSide,
        path: generatePathFromConnection(
          layerDotsRef,
          adjustFromScreenX,
          adjustFromScreenY,
          {
            fromBlockId: Number(newConnection.fromBlockId),
            fromSide: newConnection.fromSide as ConnectionSide,
            toBlockId: Number(toBlockId),
            toSide: toSide as ConnectionSide,
          }
        ),
      });

      setNewConnection({
        toBlockId: undefined,
        toSide: undefined,
        fromBlockId: undefined,
        fromSide: undefined,
        path: [],
      });

      dotsHideAll();
    } else {
      createShow();
      // otherwise create it
      if (!newConnection) return;
      if (!newConnection.path) return;
      if (!newConnection.fromBlockId) return;
      if (!newConnection.fromSide) return;

      const [toBlockId, toSide] = e.target
        // @ts-ignore
        .getId()
        .replace('block-', '')
        .replace('dot-', '')
        .split('-');

      // if connecting to own block, cancel connetion
      if (Number(toBlockId) === newConnection.fromBlockId) return;

      // auto draw last section if it doesn't line up
      const lastX = newConnection.path[newConnection.path.length - 2];
      const lastY = newConnection.path[newConnection.path.length - 1];

      // if last dot is in line with either x/y then don't draw an extra point
      if (
        lastX === adjustFromScreenX(dotNode.x) ||
        lastX === adjustFromScreenY(dotNode.y) ||
        lastY === adjustFromScreenX(dotNode.x) ||
        lastY === adjustFromScreenY(dotNode.y)
      ) {
        createConnection({
          fromBlockId: Number(newConnection.fromBlockId),
          fromSide: newConnection.fromSide,
          toBlockId: Number(toBlockId),
          toSide: toSide as ConnectionSide,
          path: [
            ...(newConnection?.path || []),
            adjustFromScreenX(dotNode.x),
            adjustFromScreenY(dotNode.y),
          ],
        });
      } else {
        // otherwise draw an extra point to keep lines straight
        let offset = generateInitialPath(
          toSide,
          adjustFromScreenX(dotNode.x),
          adjustFromScreenY(dotNode.y)
        );
        let newPath: number[] = [
          ...newConnection.path,
          ...connectInLeastPoints(
            { x: lastX, y: lastY },
            { x: offset[0], y: offset[1] }
          ),
          offset[0],
          offset[1],
          adjustFromScreenX(dotNode.x),
          adjustFromScreenY(dotNode.y),
        ];

        createConnection({
          fromBlockId: Number(newConnection.fromBlockId),
          fromSide: newConnection.fromSide,
          toBlockId: Number(toBlockId),
          toSide: toSide as ConnectionSide,
          path: newPath,
        });
      }

      setNewConnection({
        toBlockId: undefined,
        toSide: undefined,
        fromBlockId: undefined,
        fromSide: undefined,
        path: [],
      });

      dotsHideAll();
    }
  }

  return (
    <Group x={x} y={y} id={`${id}-pos`}>
      {/* Visible dot */}
      <Circle
        id={id}
        fill={dotColor}
        onClick={handleClick}
        name="dot"
        radius={5}
        opacity={0}
      />

      {/* Invisible draggable dot */}
      <Circle
        fill={colors.purple}
        opacity={0}
        id={`${id}-drag`}
        radius={10}
        draggable={!isDrag}
        onClick={handleClick}
        onMouseEnter={() => {
          if (isDrag) return;
          changeCursor('pointer');
          const dotNode = layerDotsRef.current.findOne(`#${id}`);
          dotNode.opacity(1);
          layerDotsRef.current.batchDraw();
        }}
        onMouseLeave={() => {
          changeCursor('default');
          const dotNode = layerDotsRef.current.findOne(`#${id}`);
          dotNode.opacity(0.5);
          layerDotsRef.current.batchDraw();
        }}
        onDragStart={() => {
          if (isDrag) return;
          createHide();
          dotsShowAll();
        }}
        onDragEnd={(e) => {
          createShow();
          dotsHideAll();

          const layer = layerDotsRef.current;
          const dragPos = e.target.getAbsolutePosition();

          // update dot line
          const dotLineNode = layer.findOne(`#block-${block.id}-dot-line`);
          if (dotLineNode) {
            dotLineNode.opacity(0);
          }

          // check for connection
          const dots = layer.find('.dot');

          Object.keys(dots).find((k) => {
            const d = dots[k];

            if (!d || !d.getAbsolutePosition) return false;

            const dPos = d.getAbsolutePosition();

            const connection =
              dragPos.x > dPos.x - 30 &&
              dragPos.x < dPos.x + 30 &&
              dragPos.y > dPos.y - 30 &&
              dragPos.y < dPos.y + 30;

            if (connection) {
              const [toBlockId, toSide] = d
                .getId()
                .replace('block-', '')
                .replace('dot-', '')
                .split('-');

              if (Number(toBlockId) === block.id) return false;

              createConnection({
                toBlockId: Number(toBlockId),
                toSide,
                fromBlockId: Number(block.id),
                fromSide: side,
                path: generatePathFromConnection(
                  layerDotsRef,
                  adjustFromScreenX,
                  adjustFromScreenY,
                  {
                    toBlockId: Number(toBlockId),
                    toSide,
                    fromBlockId: Number(block.id),
                    fromSide: side,
                  }
                ),
              });
            }

            return false;
          });

          // reset drag to dot position
          // const dotNode = layer.findOne(`#${id}`);
          // dotPosition = dotNode.getAbsolutePosition();
          e.target.position({
            x: 0,
            y: 0,
          });
          layer.draw();
        }}
        onDragMove={(e) => {
          // console.log('onDragMove', id);
          const layer = layerDotsRef.current;
          // get dot position
          const dotNode = layer.findOne(`#${id}`);
          const dotPosition = dotNode.getAbsolutePosition();
          // get draggable dot position
          const dragPosition = e.target.getAbsolutePosition();
          // update dot line
          const dotLineNode = layer.findOne(`#block-${block.id}-dot-line`);
          dotLineNode.attrs.points = [
            adjustFromScreenX(dotPosition.x),
            adjustFromScreenY(dotPosition.y),
            adjustFromScreenX(dragPosition.x),
            adjustFromScreenY(dragPosition.y),
          ];
          dotLineNode.opacity(1);
          layer.draw();
        }}
      />
    </Group>
  );
};
