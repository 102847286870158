import { colors } from 'constants/colors';
import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { trimSpaces } from 'utils/trimSpaces';

const StyledEditableUnderlineTextDiv = styled.div<{
  $hideUnderline?: boolean;
  $placeholder?: string;
}>`
  cursor: text;
  min-height: 30px;

  min-width: 111px;
  margin: 0;
  padding: 6px 0 5px;
  overflow-y: hidden;
  resize: none;

  font-family: ABCFavorit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  outline: none;
  border: none;
  border-bottom: 0.5px solid
    ${({ $hideUnderline }) =>
      $hideUnderline ? 'transparent' : colors.greyDark};

  :hover {
    border-color: ${colors.black};
    color: ${colors.greyDark};
  }

  :focus {
    border-color: ${colors.blue};
    color: ${colors.black};
  }

  position: relative;

  &::before {
    content: ${({ $placeholder }) => '"' + $placeholder + '"'};
    position: absolute;
    left: 0;
    display: block;
    color: ${colors.greyDark};
    font-family: ABCFavorit;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    white-space: pre;
  }
`;

export const EditableUnderlineTextDiv = (
  props: Omit<
    React.HTMLAttributes<HTMLDivElement>,
    'onInput' | 'placeholder'
  > & {
    $hideUnderline?: boolean;
    onInput(s: string): void;
    placeholder?: string;
    initialValue?: string;
  }
) => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState(props.initialValue || '');
  const divRef = useRef<HTMLDivElement>(null);

  return (
    <StyledEditableUnderlineTextDiv
      {...props}
      ref={divRef}
      $placeholder={!value && !isFocused ? props.placeholder : ''}
      onInput={(e) => {
        const target = e.target as Node;
        if (target.textContent !== null) {
          props.onInput?.(trimSpaces(target.textContent));
        }
      }}
      //move this to the main component
      onPaste={(event) => {
        event.preventDefault();
        const text = event.clipboardData.getData('text/plain');
        if (!!divRef.current) {
          //remove new lines from pasted text
          divRef.current.innerHTML = text.replace(/(\r\n|\n|\r)/gm, '');
          props.onInput?.(trimSpaces(text));
        }
      }}
      onKeyPress={(event) => {
        //Disable new lines
        const keyCode = event.key;

        if (keyCode === 'Enter') {
          event.preventDefault();
          return false;
        }
      }}
      onFocus={(e) => {
        setIsFocused(true);
        props?.onFocus?.(e);
      }}
      onBlur={(e) => {
        setIsFocused(false);

        const target = e.target as Node;
        if (target.textContent !== null) {
          setValue(trimSpaces(target.textContent));
        }

        props?.onBlur?.(e);
      }}
    >
      {props.initialValue}
    </StyledEditableUnderlineTextDiv>
  );
};
