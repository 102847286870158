export const countries = [
  // { name: 'abkhazia', img: 'abkhazia.png' },
  { name: 'afghanistan', img: 'afghanistan.png' },
  // { name: 'aland islands', img: 'aland-islands.png' },
  { name: 'albania', img: 'albania.png' },
  { name: 'algeria', img: 'algeria.png' },
  { name: 'american samoa', img: 'american-samoa.png' },
  { name: 'andorra', img: 'andorra.png' },
  { name: 'angola', img: 'angola.png' },
  { name: 'anguilla', img: 'anguilla.png' },
  { name: 'antigua and barbuda', img: 'antigua-and-barbuda.png' },
  { name: 'argentina', img: 'argentina.png' },
  { name: 'armenia', img: 'armenia.png' },
  { name: 'aruba', img: 'aruba.png' },
  { name: 'australia', img: 'australia.png' },
  { name: 'austria', img: 'austria.png' },
  { name: 'azerbaijan', img: 'azerbaijan.png' },
  // { name: 'azores islands', img: 'azores-islands.png' },
  { name: 'bahamas', img: 'bahamas.png' },
  { name: 'bahrain', img: 'bahrain.png' },
  // { name: 'balearic islands', img: 'balearic-islands.png' },
  { name: 'bangladesh', img: 'bangladesh.png' },
  { name: 'barbados', img: 'barbados.png' },
  // { name: 'basque country', img: 'basque-country.png' },
  { name: 'belarus', img: 'belarus.png' },
  { name: 'belgium', img: 'belgium.png' },
  { name: 'belize', img: 'belize.png' },
  { name: 'benin', img: 'benin.png' },
  { name: 'bermuda', img: 'bermuda.png' },
  { name: 'bhutan', img: 'bhutan.png' },
  { name: 'bolivia', img: 'bolivia.png' },
  { name: 'bonaire', img: 'bonaire.png' },
  { name: 'bosnia and herzegovina', img: 'bosnia-and-herzegovina.png' },
  { name: 'botswana', img: 'botswana.png' },
  { name: 'brazil', img: 'brazil.png' },
  // { name: 'british columbia', img: 'british-columbia.png' },
  {
    name: 'british indian ocean territory',
    img: 'british-indian-ocean-territory.png',
  },
  // { name: 'british virgin islands', img: 'british-virgin-islands.png' },
  { name: 'brunei', img: 'brunei.png' },
  { name: 'bulgaria', img: 'bulgaria.png' },
  { name: 'burkina faso', img: 'burkina-faso.png' },
  { name: 'burundi', img: 'burundi.png' },
  { name: 'cambodia', img: 'cambodia.png' },
  { name: 'cameroon', img: 'cameroon.png' },
  { name: 'canada', img: 'canada.png' },
  { name: 'canary islands', img: 'canary-islands.png' },
  { name: 'cape verde', img: 'cape-verde.png' },
  { name: 'cayman islands', img: 'cayman-islands.png' },
  { name: 'central african republic', img: 'central-african-republic.png' },
  // { name: 'ceuta', img: 'ceuta.png' },
  { name: 'chad', img: 'chad.png' },
  { name: 'chile', img: 'chile.png' },
  { name: 'china', img: 'china.png' },
  { name: 'christmas island', img: 'christmas-island.png' },
  { name: 'cocos island', img: 'cocos-island.png' },
  { name: 'colombia', img: 'colombia.png' },
  { name: 'comoros', img: 'comoros.png' },
  { name: 'cook islands', img: 'cook-islands.png' },
  // { name: 'corsica', img: 'corsica.png' },
  { name: 'costa rica', img: 'costa-rica.png' },
  { name: 'croatia', img: 'croatia.png' },
  { name: 'cuba', img: 'cuba.png' },
  { name: 'curacao', img: 'curacao.png' },
  { name: 'cyprus', img: 'cyprus.png' },
  // { name: 'czech republic', img: 'czech-republic.png' },
  // {
  // name: 'democratic republic of congo',
  // img: 'democratic-republic-of-congo.png',
  // },
  { name: 'denmark', img: 'denmark.png' },
  { name: 'djibouti', img: 'djibouti.png' },
  { name: 'dominican republic', img: 'dominican-republic.png' },
  { name: 'dominica', img: 'dominica.png' },
  // { name: 'east timor', img: 'east-timor.png' },
  { name: 'ecuador', img: 'ecuador.png' },
  { name: 'egypt', img: 'egypt.png' },
  { name: 'england', img: 'england.png' },
  { name: 'equatorial guinea', img: 'equatorial-guinea.png' },
  { name: 'eritrea', img: 'eritrea.png' },
  { name: 'estonia', img: 'estonia.png' },
  { name: 'ethiopia', img: 'ethiopia.png' },
  { name: 'european union', img: 'european-union.png' },
  { name: 'falkland islands', img: 'falkland-islands.png' },
  { name: 'faroe islands', img: 'faroe-islands.png' },
  { name: 'fiji', img: 'fiji.png' },
  { name: 'finland', img: 'finland.png' },
  { name: 'france', img: 'france.png' },
  { name: 'french polynesia', img: 'french-polynesia.png' },
  { name: 'gabon', img: 'gabon.png' },
  // { name: 'galapagos islands', img: 'galapagos-islands.png' },
  { name: 'gambia', img: 'gambia.png' },
  { name: 'georgia', img: 'georgia.png' },
  { name: 'germany', img: 'germany.png' },
  { name: 'ghana', img: 'ghana.png' },
  { name: 'gibraltar', img: 'gibraltar.png' },
  { name: 'greece', img: 'greece.png' },
  { name: 'greenland', img: 'greenland.png' },
  { name: 'grenada', img: 'grenada.png' },
  { name: 'guam', img: 'guam.png' },
  { name: 'guatemala', img: 'guatemala.png' },
  { name: 'guernsey', img: 'guernsey.png' },
  { name: 'guinea bissau', img: 'guinea-bissau.png' },
  { name: 'guinea', img: 'guinea.png' },
  { name: 'guyana', img: 'guyana.png' },
  { name: 'haiti', img: 'haiti.png' },
  // { name: 'hawaii', img: 'hawaii.png' },
  { name: 'honduras', img: 'honduras.png' },
  { name: 'hong kong', img: 'hong-kong.png' },
  { name: 'hungary', img: 'hungary.png' },
  { name: 'iceland', img: 'iceland.png' },
  { name: 'india', img: 'india.png' },
  { name: 'indonesia', img: 'indonesia.png' },
  { name: 'iran', img: 'iran.png' },
  { name: 'iraq', img: 'iraq.png' },
  { name: 'ireland', img: 'ireland.png' },
  { name: 'isle of man', img: 'isle-of-man.png' },
  { name: 'israel', img: 'israel.png' },
  { name: 'italy', img: 'italy.png' },
  // { name: 'ivory coast', img: 'ivory-coast.png' },
  { name: 'jamaica', img: 'jamaica.png' },
  { name: 'japan', img: 'japan.png' },
  { name: 'jersey', img: 'jersey.png' },
  { name: 'jordan', img: 'jordan.png' },
  { name: 'kazakhstan', img: 'kazakhstan.png' },
  { name: 'kenya', img: 'kenya.png' },
  { name: 'kiribati', img: 'kiribati.png' },
  // { name: 'kosovo', img: 'kosovo.png' },
  { name: 'kuwait', img: 'kuwait.png' },
  { name: 'kyrgyzstan', img: 'kyrgyzstan.png' },
  // { name: 'laos', img: 'laos.png' },
  { name: 'latvia', img: 'latvia.png' },
  { name: 'lebanon', img: 'lebanon.png' },
  { name: 'lesotho', img: 'lesotho.png' },
  { name: 'liberia', img: 'liberia.png' },
  { name: 'libya', img: 'libya.png' },
  { name: 'liechtenstein', img: 'liechtenstein.png' },
  { name: 'lithuania', img: 'lithuania.png' },
  { name: 'luxembourg', img: 'luxembourg.png' },
  { name: 'macao', img: 'macao.png' },
  { name: 'madagascar', img: 'madagascar.png' },
  // { name: 'madeira', img: 'madeira.png' },
  { name: 'malawi', img: 'malawi.png' },
  { name: 'malaysia', img: 'malaysia.png' },
  { name: 'maldives', img: 'maldives.png' },
  { name: 'mali', img: 'mali.png' },
  { name: 'malta', img: 'malta.png' },
  { name: 'marshall island', img: 'marshall-island.png' },
  { name: 'martinique', img: 'martinique.png' },
  { name: 'mauritania', img: 'mauritania.png' },
  { name: 'mauritius', img: 'mauritius.png' },
  // { name: 'melilla', img: 'melilla.png' },
  { name: 'mexico', img: 'mexico.png' },
  { name: 'micronesia', img: 'micronesia.png' },
  { name: 'moldova', img: 'moldova.png' },
  { name: 'monaco', img: 'monaco.png' },
  { name: 'mongolia', img: 'mongolia.png' },
  { name: 'montenegro', img: 'montenegro.png' },
  { name: 'montserrat', img: 'montserrat.png' },
  { name: 'morocco', img: 'morocco.png' },
  { name: 'mozambique', img: 'mozambique.png' },
  { name: 'myanmar', img: 'myanmar.png' },
  { name: 'namibia', img: 'namibia.png' },
  // { name: 'nato', img: 'nato.png' },
  { name: 'nauru', img: 'nauru.png' },
  { name: 'nepal', img: 'nepal.png' },
  { name: 'netherlands', img: 'netherlands.png' },
  { name: 'new zealand', img: 'new-zealand.png' },
  { name: 'nicaragua', img: 'nicaragua.png' },
  { name: 'nigeria', img: 'nigeria.png' },
  { name: 'niger', img: 'niger.png' },
  { name: 'niue', img: 'niue.png' },
  { name: 'norfolk island', img: 'norfolk-island.png' },
  // { name: 'northen cyprus', img: 'northen-cyprus.png' },
  { name: 'northern marianas islands', img: 'northern-marianas-islands.png' },
  { name: 'north korea', img: 'north-korea.png' },
  { name: 'norway', img: 'norway.png' },
  { name: 'oman', img: 'oman.png' },
  // { name: 'orkney islands', img: 'orkney-islands.png' },
  // { name: 'ossetia', img: 'ossetia.png' },
  { name: 'pakistan', img: 'pakistan.png' },
  { name: 'palau', img: 'palau.png' },
  { name: 'palestine', img: 'palestine.png' },
  { name: 'panama', img: 'panama.png' },
  { name: 'papua new guinea', img: 'papua-new-guinea.png' },
  { name: 'paraguay', img: 'paraguay.png' },
  { name: 'peru', img: 'peru.png' },
  { name: 'philippines', img: 'philippines.png' },
  { name: 'pitcairn islands', img: 'pitcairn-islands.png' },
  { name: 'portugal', img: 'portugal.png' },
  { name: 'puerto rico', img: 'puerto-rico.png' },
  { name: 'qatar', img: 'qatar.png' },
  // { name: 'rapa nui', img: 'rapa-nui.png' },
  { name: 'republic of macedonia', img: 'republic-of-macedonia.png' },
  { name: 'republic of poland', img: 'republic-of-poland.png' },
  // { name: 'republic of the congo', img: 'republic-of-the-congo.png' },
  { name: 'romania', img: 'romania.png' },
  { name: 'russia', img: 'russia.png' },
  { name: 'rwanda', img: 'rwanda.png' },
  // { name: 'saba island', img: 'saba-island.png' },
  { name: 'saint kitts and nevis', img: 'saint-kitts-and-nevis.png' },
  { name: 'salvador', img: 'salvador.png' },
  { name: 'samoa', img: 'samoa.png' },
  { name: 'san marino', img: 'san-marino.png' },
  { name: 'sao tome and principe', img: 'sao-tome-and-principe.png' },
  // { name: 'sardinia', img: 'sardinia.png' },
  { name: 'saudi arabia', img: 'saudi-arabia.png' },
  { name: 'scotland', img: 'scotland.png' },
  { name: 'senegal', img: 'senegal.png' },
  { name: 'serbia', img: 'serbia.png' },
  { name: 'seychelles', img: 'seychelles.png' },
  { name: 'sierra leone', img: 'sierra-leone.png' },
  { name: 'singapore', img: 'singapore.png' },
  { name: 'sint eustatius', img: 'sint-eustatius.png' },
  { name: 'sint maarten', img: 'sint-maarten.png' },
  { name: 'slovakia', img: 'slovakia.png' },
  { name: 'slovenia', img: 'slovenia.png' },
  { name: 'solomon islands', img: 'solomon-islands.png' },
  { name: 'somalia', img: 'somalia.png' },
  // { name: 'somaliland', img: 'somaliland.png' },
  { name: 'south africa', img: 'south-africa.png' },
  { name: 'south korea', img: 'south-korea.png' },
  { name: 'south sudan', img: 'south-sudan.png' },
  { name: 'spain', img: 'spain.png' },
  { name: 'sri lanka', img: 'sri-lanka.png' },
  // { name: 'st barts', img: 'st-barts.png' },
  // { name: 'st lucia', img: 'st-lucia.png' },
  // {
  // name: 'st vincent and the grenadines',
  // img: 'st-vincent-and-the-grenadines.png',
  // },
  { name: 'sudan', img: 'sudan.png' },
  { name: 'suriname', img: 'suriname.png' },
  // { name: 'swaziland', img: 'swaziland.png' },
  { name: 'sweden', img: 'sweden.png' },
  { name: 'switzerland', img: 'switzerland.png' },
  { name: 'syria', img: 'syria.png' },
  { name: 'taiwan', img: 'taiwan.png' },
  { name: 'tajikistan', img: 'tajikistan.png' },
  { name: 'tanzania', img: 'tanzania.png' },
  { name: 'thailand', img: 'thailand.png' },
  // { name: 'tibet', img: 'tibet.png' },
  { name: 'togo', img: 'togo.png' },
  { name: 'tokelau', img: 'tokelau.png' },
  { name: 'tonga', img: 'tonga.png' },
  // { name: 'transnistria', img: 'transnistria.png' },
  { name: 'trinidad and tobago', img: 'trinidad-and-tobago.png' },
  { name: 'tunisia', img: 'tunisia.png' },
  { name: 'turkey', img: 'turkey.png' },
  { name: 'turkmenistan', img: 'turkmenistan.png' },
  { name: 'turks and caicos', img: 'turks-and-caicos.png' },
  { name: 'tuvalu', img: 'tuvalu.png' },
  { name: 'uganda', img: 'uganda.png' },
  { name: 'ukraine', img: 'ukraine.png' },
  { name: 'united arab emirates', img: 'united-arab-emirates.png' },
  { name: 'united kingdom', img: 'united-kingdom.png' },
  { name: 'united states of america', img: 'united-states-of-america.png' },
  { name: 'uruguay', img: 'uruguay.png' },
  { name: 'uzbekistan', img: 'uzbekistan.png' },
  { name: 'vanuatu', img: 'vanuatu.png' },
  // { name: 'vatican city', img: 'vatican-city.png' },
  { name: 'venezuela', img: 'venezuela.png' },
  { name: 'vietnam', img: 'vietnam.png' },
  { name: 'virgin islands', img: 'virgin-islands.png' },
  // { name: 'wales', img: 'wales.png' },
  { name: 'western sahara', img: 'western-sahara.png' },
  { name: 'yemen', img: 'yemen.png' },
  { name: 'zambia', img: 'zambia.png' },
  { name: 'zimbabwe', img: 'zimbabwe.png' },
] as const;
