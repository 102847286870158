import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { PostItGroupAndCards, Step } from 'types';

import { useAuthContext } from 'contexts/AuthContext';
import { GridContextProvider, move, swap } from 'react-grid-dnd';
import { useWidth } from 'hooks/useWidth';
import { Transition, TransitionGroup } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { usePostItCardsPayload } from 'hooks/usePostItCards';
import {
  Stakeholder,
  SubStep,
} from 'data/graphql/generated';
import { useEffect } from 'react';
import { outcomesCardWidths } from 'constants/index';
import { sidebarTransition } from './shared/ImperativeSummarySidebar';
import { OutcomesPostItGroupView } from './OutcomesPostItGroup';

export const Wrapper = styled.div``;

export const TransitionWrapper = styled.div<{ state: TransitionStatus }>`
  opacity: 1;
  transition:0.5s ease;
`;

interface Props {
  step: Step;
  subStep?: SubStep;
  groups: PostItGroupAndCards[] | any;
  stakeholderDefinitionId: number;
  handleCardChange(
    sourceId: string,
    sourceIndex: number,
    targetIndex: number,
    targetId?: string
  ): void;
  addCard: usePostItCardsPayload['createCard'];
  removeCard: usePostItCardsPayload['removeCard'];
  updateCard: usePostItCardsPayload['updateCard'];
  createGroupDisabled?: boolean;
  stakeholder: Stakeholder;
  loading: boolean;
  selectedPostItGroupId: number;
  sidebarOpen?: boolean;
  Content?: (groupId: number) => React.ReactNode;
  refetch(): void;
  hasNoSelectedCompetitor: boolean;
  hasNoTargetPopulation: boolean;
  movingOrSwapping: boolean;
}

export const OutcomesPostIts: React.FC<Props> = ({
  step,
  subStep,
  groups,
  hasNoSelectedCompetitor,
  hasNoTargetPopulation,
  stakeholder,
  selectedPostItGroupId,
  addCard,
  handleCardChange,
  refetch,
  removeCard,
  updateCard,
  stakeholderDefinitionId,
  sidebarOpen = false,
  loading,
  movingOrSwapping,
  Content,
}) => {
  const [{ user }] = useAuthContext();
  const [dragDisabled, setDragDisabled] = useState<boolean>(false);
  const [rows, setRows] = useState<number>(0);


  const [items, setItems] = useState(groups);
  useEffect(() => {
    setItems(groups)
  }, [groups])

  const width = useWidth();

  useEffect(() => {
    setTimeout(() => {
      const groupContent = document.getElementsByClassName(
        'group-content'
      )[0] as HTMLDivElement;

      const groupContentWidth = groupContent?.offsetHeight;

      if (!!groupContent) {
        const padding = 15 * 2;

        const postItWidthWithMargin =
          width < 481
            ? outcomesCardWidths.min + outcomesCardWidths.gutter / 2
            : outcomesCardWidths.max + outcomesCardWidths.gutter / 2;

        const newColumnCount = Math.floor(
          (groupContentWidth - padding) / postItWidthWithMargin
        );

        setRows(newColumnCount);
      }
    }, sidebarTransition.duration);
  }, [width, groups, sidebarOpen]);

  if (!user) return null;

  const handleChange = (sourceId: string, sourceIndex: number, targetIndex: number, targetId: string | undefined) => {
    if (targetId) {
      const result = move(
        items[sourceId],
        items[targetId],
        sourceIndex,
        targetIndex
      );

      setItems({
        ...items,
        [sourceId]: result[0],
        [targetId]: result[1]
      });

      handleCardChange(sourceId, sourceIndex, targetIndex, targetId);

      return;
    }

    const result = swap(items[sourceId], sourceIndex, targetIndex);

    setItems({
      ...items,
      [sourceId]: result
    });

    handleCardChange(sourceId, sourceIndex, targetIndex, targetId);

    return;
  }

  return (
    <Wrapper>
      <GridContextProvider onChange={handleChange}>
        <TransitionGroup component={null}>
          <Transition key={groups} timeout={200}>
            {(state) => {
              return (
                <TransitionWrapper state={state}>
                  <OutcomesPostItGroupView
                    step={step}
                    subStep={subStep}
                    userRole={user.role}
                    refetch={refetch}
                    userId={user.id || 0}
                    selectedPostItGroupId={selectedPostItGroupId}
                    // group={groups}
                    group={items}
                    stakeholder={stakeholder}
                    stakeholderDefinitionId={stakeholderDefinitionId}
                    allGroupsCount={groups.length}
                    addCard={addCard}
                    rows={rows}
                    updateCard={updateCard}
                    removeCard={removeCard}
                    loading={loading}
                    dragDisabled={dragDisabled}
                    setDragDisabled={setDragDisabled}
                    hasNoSelectedCompetitor={hasNoSelectedCompetitor}
                    hasNoTargetPopulation={hasNoTargetPopulation}
                    movingOrSwapping={movingOrSwapping}
                  />
                  {Content && Content(groups)}
                </TransitionWrapper>
              );
            }}
          </Transition>
        </TransitionGroup>
      </GridContextProvider>
    </Wrapper>
  );
};
