import { colors } from 'constants/index';
import styled, { css } from 'styled-components';
import { KEY_EVENT_ICON_WIDTH } from './constants';

const spaceBeneathIcon = 2;
const gapFromBottom = 5;

const VerticalLine = styled.div<{
  calendarIconIsHovered: boolean;
  left: number;
  isCountryTimeline: boolean;
}>`
  width: 1px;
  max-height: 100%;

  height: calc(
    100% - ${KEY_EVENT_ICON_WIDTH + spaceBeneathIcon + gapFromBottom}px
  );

  position: absolute;
  bottom: 5px;
  left: ${({ left }) => left}px;

  ${({ calendarIconIsHovered, isCountryTimeline }) => {
    if (isCountryTimeline) {
      return css`
        background: ${calendarIconIsHovered ? colors.blue : colors.blue30};
      `;
    }
    return css`
      background: ${calendarIconIsHovered ? colors.purple : colors.purple30};
    `;
  }}
`;

type Props = {
  left: number;
  isHovered: boolean;
  isCountryTimeline: boolean;
};

const TimelineKeyEventVerticalLine = ({
  left,
  isHovered,
  isCountryTimeline,
}: Props) => {
  return (
    <VerticalLine
      left={left}
      calendarIconIsHovered={isHovered}
      isCountryTimeline={isCountryTimeline}
    />
  );
};

export default TimelineKeyEventVerticalLine;
