import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';
import addQuarters from 'date-fns/addQuarters';
import { CriticalMetricGoalFrequency } from 'data/graphql/generated';
import { formatDateByFrequency } from './formatDateByFrequency';

export const getCurrentPeriodByFrequencyAndOffset = (
  frequency: CriticalMetricGoalFrequency,
  offset: number
) => {
  const now = new Date();
  if (frequency === CriticalMetricGoalFrequency.Quarterly) {
    const nowWithOffset = addQuarters(now, offset);
    return {
      date: nowWithOffset,
      formatted: formatDateByFrequency(nowWithOffset, frequency),
    };
  }

  if (frequency === CriticalMetricGoalFrequency.Annually) {
    const nowWithOffset = addYears(now, offset);
    return {
      date: nowWithOffset,
      formatted: formatDateByFrequency(nowWithOffset, frequency),
    };
  }

  const nowWithOffset = addMonths(now, offset);
  return {
    date: nowWithOffset,
    formatted: formatDateByFrequency(nowWithOffset, frequency),
  };
};
