import React from 'react';
import styled from 'styled-components';
import { BodySmall, Subtitle1 } from 'components/shared';
import { PageWrapper, FullLogo } from '../../containers/Signin';
import { Navbar } from 'components/Navbar';
import InformationModal from 'components/shared/InformationModal';
import { device } from 'utils/breakpoints';

const InstructionsPageWrapper = styled(PageWrapper)`
  padding: 15px;
`;

const InstructionsModal = styled(InformationModal)`
  min-height: 220px;
  width: 456px;
  margin-top: 40px;
  @media ${device.mobile} {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  padding: 40px;
`;

export const Title = styled(Subtitle1)`
  text-align: center;
  margin-bottom: 15px;
`;

interface Props {
  userEmail: string;
}

const InstructionsSent: React.FC<{ userEmail: string }> = ({
  userEmail,
}: Props) => {
  return (
    <InstructionsPageWrapper>
      <Navbar
        exclude={[
          'drugLink',
          'strategyLink',
          'filesLink',
          'profile',
          'notifications',
          'navItems',
          'navContent',
          'sidebarTrigger',
        ]}
        disableSecondary
      />
      <FullLogo name="FullLogo" height={40} size={125} />
      <InstructionsModal>
        <Wrapper>
          <Title>Instructions sent</Title>
          <BodySmall>
            If this account exists, instructions to reset the password will be
            sent to {userEmail}.
          </BodySmall>
          <br />
          <BodySmall>
            The email will arrive within 5 minutes. Check your spam folder if
            you don’t see it.
          </BodySmall>
        </Wrapper>
      </InstructionsModal>
    </InstructionsPageWrapper>
  );
};

export default InstructionsSent;
