import { useEffect, useState } from 'react';
import { useStrategyQuery } from '../../data/graphql/generated';
import { polling } from '../../constants';

type Props = {
  strategyId: number;
};

export const useCommercialStrategyNav = ({ strategyId }: Props) => {
  const {
    data: strategyData,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
    loading: strategyLoading,
    error: strategyError,
  } = useStrategyQuery({
    variables: { id: strategyId },
    fetchPolicy: 'network-only',
  });

  const countryPlansEnabled = strategyData?.strategy?.enableCountryTactics;

  const [localCountryTacticsEnabled, setCountryTacticsEnabled] = useState<
    boolean | null | undefined
  >(countryPlansEnabled);

  useEffect(() => {
    if (typeof countryPlansEnabled !== 'boolean') return;
    setCountryTacticsEnabled(countryPlansEnabled);
  }, [localCountryTacticsEnabled, countryPlansEnabled]);

  useEffect(() => {
    startStrategyPolling(polling.default);
    return () => {
      stopStrategyPolling();
    };
  }, [startStrategyPolling, stopStrategyPolling]);

  return {
    localCountryTacticsEnabled,
    setCountryTacticsEnabled,
    strategyLoading,
    strategyError,
  };
};
