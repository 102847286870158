export function observeResize(
  el: HTMLElement | null,
  callback: (observer: ResizeObserver) => void
) {
  if (!el) return;
  let observerHandle: ResizeObserver | null = null;

  let observer = new ResizeObserver(function (v) {
    callback(observerHandle as ResizeObserver);
  });

  observer.observe(el);
  observerHandle = observer;

  return observerHandle;
}
