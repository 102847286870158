import { BodySmall, Icon } from 'components/shared';
import { colors } from 'constants/index';
import { CollaborationSolutionFragment } from 'data/graphql/generated';
import React from 'react';
import { monthNames } from 'utils/monthNames';
import { thousandSeparator } from 'utils/thousandSeparator';
import styled from 'styled-components';

const WhiteCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 10px 15px;
  border-radius: 5px;
  background: ${colors.white};
`;

export const Solution = (
  data?: CollaborationSolutionFragment & { currency: string | undefined }
) => {
  if (!data) return null;
  const {
    solutionText,
    timingStart,
    timingEnd,
    dueDate,
    responsibility,
    budget,
    currency,
    focused,
  } = data;
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
      }}
    >
      <WhiteCardWrapper>
        {!!focused && (
          <div style={{ display: 'flex', marginLeft: -10 }}>
            <Icon name="GradientTick" size={30} />
            <BodySmall>Focus</BodySmall>
          </div>
        )}
        <div>
          <BodySmall color={colors.greyDark}>Solution</BodySmall>
          {solutionText ? (
            <BodySmall>{solutionText}</BodySmall>
          ) : (
            <UnidentifiedText />
          )}
        </div>

        <div>
          <BodySmall color={colors.greyDark}>Timing</BodySmall>
          {timingStart.length && timingEnd.length ? (
            <BodySmall>
              {monthNames[timingStart[0]] +
                ' ' +
                timingStart[1] +
                ' - ' +
                monthNames[timingEnd[0]] +
                ' ' +
                timingEnd[1]}
            </BodySmall>
          ) : (
            <UnidentifiedText />
          )}
        </div>
        {!!focused && (
          <>
            <div>
              <BodySmall color={colors.greyDark}>Due</BodySmall>

              {dueDate.length ? (
                <BodySmall>
                  {monthNames[dueDate[0]] + ' ' + dueDate[1]}
                </BodySmall>
              ) : (
                <UnidentifiedText />
              )}
            </div>
            <div>
              <BodySmall color={colors.greyDark}>Responsibility</BodySmall>
              {responsibility ? (
                <BodySmall>{responsibility}</BodySmall>
              ) : (
                <UnidentifiedText />
              )}
            </div>
            <div>
              <BodySmall color={colors.greyDark}>Budget</BodySmall>
              {budget ? (
                <BodySmall>
                  {currency?.[0] + thousandSeparator(budget)}
                </BodySmall>
              ) : (
                <UnidentifiedText />
              )}
            </div>
          </>
        )}
      </WhiteCardWrapper>
    </div>
  );
};

function UnidentifiedText() {
  return <BodySmall color={colors.greyDark}>Not identified yet</BodySmall>;
}
