import React from 'react';
import styled from 'styled-components/macro';
import { BodySmall, EmptyState, Subtitle2 } from 'components/shared';
import { colors } from 'constants/colors';
import {
  AccessStrategyPrioritiseSupportingMessageFragment,
  ArchetypeFragment,
} from 'data/graphql/generated';
import RatingBar, { Bar } from '../shared/RatingBar';
import OverlappingFlags from './OverlappingFlags';

const Wrapper = styled.div`
  width: 250px;
  min-width: 250px;
  height: 135px;
  position: relative;
  padding: 15px;
  background: ${colors.white};
  border: 1px solid ${colors.greyLight};
  border-radius: 5px;

  ${Bar} {
    height: 5px;
  }
`;

const CardFooter = styled.footer`
  margin-top: 15px;
`;

const Bars = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

interface Props {
  archetype: ArchetypeFragment;
  prioritiseMessage: AccessStrategyPrioritiseSupportingMessageFragment;
}

export default function ArchetypeCard({ archetype, prioritiseMessage }: Props) {
  const { x, y } = prioritiseMessage;

  return (
    <Wrapper>
      <BodySmall>{archetype.stakeholderDefinition.title}</BodySmall>
      {!!archetype.archetypeCountries.length ? (
        <OverlappingFlags archetypeCountries={archetype.archetypeCountries} />
      ) : (
        <div style={{ height: 15 }} />
      )}
      <CardFooter>
        {!x || !y ? (
          <EmptyState
            includeIcon={false}
            style={{
              border: `1px solid ${colors.greyMedium}`,
              borderRadius: 5,
            }}
          >
            <Subtitle2 color={colors.greyDark}>Not rated yet</Subtitle2>
          </EmptyState>
        ) : (
          <Bars>
            <RatingBar title="Importance" percentageFilled={100 - y} />
            <RatingBar title="Data Robustness" percentageFilled={x} />
          </Bars>
        )}
      </CardFooter>
    </Wrapper>
  );
}
