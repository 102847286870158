import { colors } from 'constants/index';
import React from 'react';
import styled, { css } from 'styled-components';
import { TabTypes } from 'types';
import { Icon } from '.';
import { IconContainer } from './Icon';

interface NavProps {
  onClick(): void;
  direction: 'r' | 'l';
  tabType: TabTypes;
}

const NavWrapper = styled.div<NavProps>`
  ${({ tabType }) => {
    switch (tabType) {
      case 'StepTab':
        return css`
          width: 30px;
          height: 30px;
          padding: 5px;
          display: grid;
          place-content: center;
          background-color: ${colors.white20};
          border-radius: 0px 15px 15px 0px;
          ${IconContainer} {
            color: ${colors.white};
          }
          &:hover {
            background-color: ${colors.white30};
          }
        `;
      case 'CategoryTab':
        return css`
          width: 30px;
          height: 30px;
          padding: 5px;
          display: grid;
          place-content: center;
          background-color: ${colors.white};
          border-radius: 0px 15px 15px 0px;

          & > ${IconContainer} {
            color: ${colors.greyDark};
          }

          &:hover {
            & > ${IconContainer} {
              color: ${colors.black};
            }
          }
        `;
      case 'CompetitveLandscapeCompetitor':
        return css`
          width: 30px;
          height: 82px;
          padding: 5px;
          display: grid;
          place-content: center;
          background-color: ${colors.white};
          border-radius: 0px 15px 15px 0px;

          & > ${IconContainer} {
            color: ${colors.greyDark};
          }

          &:hover {
            & > ${IconContainer} {
              color: ${colors.black};
            }
          }
        `;
      case 'ContributionTab':
        return css`
          width: 30px;
          height: 52px;
          padding: 5px;
          display: grid;
          place-content: center;
          background-color: ${colors.white};
          border-radius: 0px 15px 15px 0px;

          & > ${IconContainer} {
            color: ${colors.greyDark};
          }

          &:hover {
            & > ${IconContainer} {
              color: ${colors.black};
            }
          }
        `;
      case 'KeyStakeholderCreator':
        return css`
          width: 30px;
          height: 100px;
          padding: 5px;
          display: grid;
          place-content: center;
          background-color: ${colors.purple10};
          border-radius: 0px 15px 15px 0px;

          & > ${IconContainer} {
            color: ${colors.purple};
          }

          &:hover {
            background-color: ${colors.purple15};
          }
        `;
      case 'CompetitorTab':
        return css`
          width: 35px;
          height: 52px;
          display: grid;
          place-content: center;
          background-color: ${colors.white};
          border-radius: 0px 15px 15px 0px;

          & > ${IconContainer} {
            color: ${colors.greyDark};
          }

          &:hover {
            & > ${IconContainer} {
              color: ${colors.black};
            }
          }
        `;
      case 'KeyStatement':
        return css`
          width: 30px;
          height: 60px;
          padding: 5px;
          display: grid;
          place-content: center;
          background-color: ${colors.purple20};
          border-radius: 0px 15px 15px 0px;

          & > ${IconContainer} {
            color: ${colors.purple};
          }

          &:hover {
            background-color: ${colors.purple25a};
          }
        `;
    }
  }}

  transform: ${({ direction }) =>
    direction === 'l' ? 'rotate(180deg)' : 'rotate(0)'};
  cursor: pointer;

  transition: background-color 0.3s ease;
`;

export const TabNav = ({ onClick, direction, tabType }: NavProps) => {
  return (
    <NavWrapper
      tabType={tabType}
      onClick={onClick}
      direction={direction}
      className="TabNavWrapper"
    >
      <Icon name="Arrow-right" size={20} color={colors.white} />
    </NavWrapper>
  );
};
