import { colors } from 'constants/index';
import { IconName } from 'types';

export const GRID_SIZE = 20;
export const RECT_WIDTH = 160;
export const RECT_HEIGHT = 80;
export const RECT_OUTER_DIFFERENCE = 6;
export const NAV_MOBILE_HEIGHT = 170;
export const NAV_DESKTOP_HEIGHT = 120;
export const COLUMN_HEIGHT = 1200;

export const blockKeyStakeholdersDisplayNames = {
  keyStakeHolderPatient: 'Patient',
  keyStakeHolderHealthcare: 'Healthcare professional',
  keyStakeHolderPayor: 'Payor',
  keyStakeHolderProvider: 'Provider',
  keyStakeHolderPolicymaker: 'Policymaker',
};
export const blockKeyStakeholdersIconNames: Record<string, IconName> = {
  keyStakeHolderPatient: 'Patient',
  keyStakeHolderHealthcare: 'HCP',
  keyStakeHolderPayor: 'Dollar',
  keyStakeHolderProvider: 'Provider',
  keyStakeHolderPolicymaker: 'Policymaker',
};

export const blockKeyStakeholdersColors = {
  keyStakeHolderPatient: {
    default: '#F8F2FF',
    hover: '#F2E6FF',
    stroke: '#7800FF',
  },
  keyStakeHolderHealthcare: {
    default: '#F2FBF9',
    hover: '#E6F8F3',
    stroke: '#01B67F',
  },
  keyStakeHolderPayor: {
    default: '#F2FAFD',
    hover: '#E6F6FB',
    stroke: '#00A1D3',
  },
  keyStakeHolderProvider: {
    default: '#FFF2FC',
    hover: '#FFE6FA',
    stroke: '#FF00C8',
  },
  keyStakeHolderPolicymaker: {
    default: colors.red05,
    hover: colors.red10,
    stroke: colors.red,
  },
};
