import { RefObject, useEffect, useRef } from 'react';

export default function useClickOutsideComponent<
  T extends HTMLElement = HTMLElement
>(
  ref: RefObject<T>,
  callback: (event?: MouseEvent) => void,
  excludeClassNames?: string[]
) {
  const drag = useRef(false);
  const mouseDown = useRef(false);

  const mousePosition = useRef({ x: 0, y: 0 });

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      mouseDown.current = false;
      if (drag.current) {
        drag.current = false;
        return;
      }

      const target = event.target as Node;

      //Add classname to exclusion
      if (
        !!(excludeClassNames && ref.current && !ref.current.contains(target))
      ) {
        const path = event.composedPath();

        const clicked = path.find((e) => {
          if (e instanceof Element) {
            return e?.classList
              ? Array.from(e.classList).some((c) =>
                  excludeClassNames.includes(c)
                )
              : e.className === excludeClassNames[0];
          }

          // https://stackoverflow.com/questions/28900077/why-is-event-target-not-element-in-typescript
          // DEPRECATED: Avoided use of ts-ignore by checking if E aka EventTarget is instance of Element.
          //@ts-ignore
          return e?.classList
            ? //@ts-ignore
              Array.from(e.classList).some((c: string) =>
                excludeClassNames.includes(c)
              )
            : //@ts-ignore
              e.className === excludeClassNames[0];
        });
        if (ref.current && !clicked) {
          callback(event);
        }
      }

      if (
        !excludeClassNames &&
        ref.current &&
        //@ts-ignore
        !ref.current.contains(event.target)
      ) {
        callback(event);
      }
    }

    function setDragToFalse(event: MouseEvent) {
      mousePosition.current.x = event.clientX;
      mousePosition.current.y = event.clientY;

      mouseDown.current = true;
      drag.current = false;
    }

    function setDragToTrue(event: MouseEvent) {
      const movedTwentyPixels =
        Math.abs(event.clientX - mousePosition.current.x) >= 20 ||
        Math.abs(event.clientY - mousePosition.current.y) >= 20;

      if (movedTwentyPixels && mouseDown.current) {
        drag.current = true;
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', setDragToFalse);
    document.addEventListener('mousemove', setDragToTrue);
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', setDragToFalse);
      document.removeEventListener('mousemove', setDragToTrue);
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [ref, callback, excludeClassNames]);
}
