import * as React from 'react';

export type TModalContext = {
  modalSlideIdx: number | null;
  setModalSlideIdx: (state: number | null) => void;
  showSingleSlide: boolean;
  setShowSingleSlide: (state: boolean) => void;
};

export const ModalContext = React.createContext<TModalContext | undefined>(
  undefined
);
