export const GoalsTableConstants = {
  COLUMN_HEADERS: ['Goal', 'Target %', 'Frequency', 'Input'],
  TABLE_EMPTY_STATE_TEXT: { header: 'None Identified yet' },
};

export const GoalFieldConstants = {
  FIELD_LABEL: 'Goal',
  PLACEHOLDER_TEXT: 'Goal',
};

export const GoalTargetFieldConstants = {
  PLACEHOLDER_TEXT: 'Percentage',
  FIELD_LABEL: 'Target %',
  FIELD_NAME: 'target',
};

export const GoalFrequencyFieldConstants = {
  FIELD_LABEL: 'Frequency',
};

export const GoalInputFieldConstants = {
  FIELD_LABEL: 'Input',
};
