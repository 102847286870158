import React from 'react';
import styled from 'styled-components/macro';
import { CommercialTacticDetailFragment } from 'data/graphql/generated';
import { BodySmall, CountryFlag } from 'components/shared';
import { colors } from 'constants/colors';
import { thousandSeparator } from 'utils/thousandSeparator';
import { formatTimestamp } from 'utils/formatTimestamp';

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 40px;
  width: -webkit-fill-available;
`;

const Content = styled.div`
  background: ${colors.white};
  border-radius: 5px;
  padding: 15px;
  width: -webkit-fill-available;
  margin: -5px;
`;

const TextWrapper = styled.div`
  margin-bottom: 15px;
  ul {
    margin-top: 5px;
    padding-left: 25px;
  }
`;

const AddedBy = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;

  position: absolute;
  bottom: -15px;
  right: 0px;
  text-align: right;
`;

interface Props {
  commercialTacticDetail: CommercialTacticDetailFragment;
  currency: String;
}

export const CommercialTacticDetail: React.FC<Props> = ({
  commercialTacticDetail,
  currency,
}) => {
  return (
    <Wrapper>
      <Content>
        <TextWrapper>
          <BodySmall style={{ color: colors.greyDark }}>
            Commercial Tactic
          </BodySmall>
          <BodySmall>
            {commercialTacticDetail.text
              ? commercialTacticDetail.text
              : 'Not identified yet'}
          </BodySmall>
        </TextWrapper>
        <TextWrapper>
          <BodySmall style={{ color: colors.greyDark }}>Audience</BodySmall>
          <BodySmall>
            {commercialTacticDetail.audience ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: commercialTacticDetail.audience,
                }}
              />
            ) : (
              'Not identified yet'
            )}
          </BodySmall>
        </TextWrapper>
        <TextWrapper>
          <BodySmall style={{ color: colors.greyDark }}>Timing</BodySmall>
          <BodySmall>
            {commercialTacticDetail.from && commercialTacticDetail.to ? (
              <>
                {formatTimestamp(commercialTacticDetail.from)}
                {' - '}
                {formatTimestamp(commercialTacticDetail.to)}
              </>
            ) : (
              'Not identified yet'
            )}
          </BodySmall>
        </TextWrapper>
        <TextWrapper>
          <BodySmall style={{ color: colors.greyDark }}>Due</BodySmall>
          <BodySmall>
            {commercialTacticDetail.due
              ? formatTimestamp(commercialTacticDetail.due)
              : 'Not identified yet'}
          </BodySmall>
        </TextWrapper>
        <TextWrapper>
          <BodySmall style={{ color: colors.greyDark }}>
            Responsibility
          </BodySmall>
          <BodySmall>
            {commercialTacticDetail.responsibility
              ? commercialTacticDetail.responsibility
              : 'Not identified yet'}
          </BodySmall>
        </TextWrapper>
        <TextWrapper>
          <BodySmall style={{ color: colors.greyDark }}>Budget</BodySmall>
          <BodySmall>
            {commercialTacticDetail.budget ? (
              <>
                {currency.charAt(0)}
                {thousandSeparator(commercialTacticDetail.budget)}
              </>
            ) : (
              'Not identified yet'
            )}
          </BodySmall>
        </TextWrapper>
      </Content>
      <AddedBy>
        <BodySmall color={colors.greyDark} style={{ marginRight: 5 }}>
          Added by
        </BodySmall>
        <CountryFlag size="sm" user={commercialTacticDetail.user} />
        <BodySmall style={{ marginLeft: 5 }}>
          {commercialTacticDetail.user.role === 'LEAD'
            ? 'Lead'
            : commercialTacticDetail.user.country}{' '}
          • {commercialTacticDetail.user.name}
        </BodySmall>
      </AddedBy>
    </Wrapper>
  );
};
