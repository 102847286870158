import { kebabCase } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { CommercialStrategyStepHeader } from 'components/CommercialStrategy/CommercialStrategyStepHeader';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import {
  BigIdeasEmptyState,
  CountrySolutionRow,
} from 'components/LongTermStrategy/SolutionRow';
import { Page, Wrapper } from 'components/Page';
import { PlanTable } from 'components/PlanTable';
import { Tooltip } from 'components/shared';
import { polling } from 'constants/index';
import {
  Role,
  Sort,
  Step,
  SubStep,
  useBigIdeaDetailUpdateMutation,
  useBigIdeaDetailsQuery,
  useCommercialTacticDetailUpdateMutation,
  useCommercialTacticDetailsQuery,
  useCompetitiveAdvantageRowsQuery,
  useStrategyQuery,
} from 'data/graphql/generated';
import { useWidth } from 'hooks/useWidth';
import { device } from 'utils/breakpoints';
import { CommercialStrategyNav } from '../../components/4-3-commercial-strategy/shared/ui/feature-navbar/CommercialStrategyNav';
import { tableColumnsStyle } from '../../components/LongTermStrategy/shared';
import { TableDivHeaders } from '../../components/shared/Table';
import { useAuthContext } from '../../contexts/AuthContext';
import { CommercialTacticDetailsQueryVariables } from '../../data/graphql/generated';
import { useCommercialStrategyNav } from '../../hooks';
import { verifyUserRole } from '../../utils/verifyUserRole';
import { SpinOffsToggle } from '../MedicalStrategy/index.style';
import { ObjectiveTables } from './ObjectiveTables';
import { ThePlanPageEmptyStates } from './ThePlanPageEmptyStates';
import { getBigIdeas } from './utils/getBigIdeas';
import { getTacticDetails } from './utils/getTacticDetails';
import {
  KeyEventsSidebar,
} from 'components/4-2-medical-strategy/shared/feature-key-events-sidebar/src';
const PageWrapper = styled(Page)`
  padding: 0px 0 60px;
  overflow: visible; // for the dropdown components

  margin-top: 15px;

  ${Wrapper} {
    width: 100%;
  }

  @media ${device.tabletMin} {
    margin-top: 15px;

    ${Wrapper} {
      width: auto;
      padding: 0 15px;
    }
  }

  @media ${device.desktopMin} {
    margin-top: 20px;

    ${Wrapper} {
      padding: 0 20px;
    }
  }

  ${TableDivHeaders} {
    ${tableColumnsStyle}
  }
`;

export const headerColumns = [
  'Audience',
  'Timing',
  'Due',
  'Responsibility',
  'Budget',
];

interface URLParams {
  drugId: string;
  strategyId: string;
  strategicImperativeId: string;
}

export const CommercialStrategyThePlan = () => {
  const { drugId, strategyId, strategicImperativeId }: URLParams = useParams();
  const substep = SubStep.ThePlan;
  const [keyEventsSidebarOpen, setKeyEventsSidebarOpen] = useState(false);
  const [activeSubStep] = useState(kebabCase(substep));
  const {
    localCountryTacticsEnabled,
    setCountryTacticsEnabled,
  } = useCommercialStrategyNav({ strategyId: +strategyId });
  const [showGlobalPlan, setHideGlobalPlan] = useState(false);
  const history = useHistory();
  const width = useWidth();
  const smallTable = width < 1014;
  const [{ user }] = useAuthContext();
  const userRole = verifyUserRole(user?.role, user?.country);

  const [bigIdeaDetailUpdate] = useBigIdeaDetailUpdateMutation();
  const [
    commercialTacticDetailUpdate,
  ] = useCommercialTacticDetailUpdateMutation();

  const {
    data: strategyData,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
    loading: strategyLoading,
    error: strategyError,
  } = useStrategyQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'network-only',
  });

  const {
    data: competitiveAdvantageRowsData,
    error: competitiveAdvantageRowsError,
    loading: competitiveAdvantageRowsLoading,
    startPolling: startCompetitiveAdvantageRowsPolling,
    stopPolling: stopCompetitiveAdvantageRowsPolling,
  } = useCompetitiveAdvantageRowsQuery({
    variables: {
      orderBy: {
        idx: Sort.Desc,
      },
      where: {
        strategyId: +strategyId,
        focus: true,
      },
      include: {
        strategicPossibility: true,
        successConditions: true,
        keyStatements: true,
        patientFlowBlockStarStakeholderDefinitions: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: bigIdeasData,
    error: bigIdeasError,
    loading: bigIdeasLoading,
    startPolling: bigIdeasStartPolling,
    stopPolling: bigIdeasStopPolling,
  } = useBigIdeaDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderBy: {
        createdAt: Sort.Desc,
      },
      where: {
        strategyId: +strategyId,
        competitiveAdvantageRowId: +strategicImperativeId,
      },
    },
  });

  // Fetch commercial tactics
  const tacticsQueryVars: CommercialTacticDetailsQueryVariables = {
    where: {
      strategyId: +strategyId,
      competitiveAdvantageRowId: +strategicImperativeId,
    },
    orderBy: {
      createdAt: Sort.Desc,
    },
  };

  const {
    data: tacticsData,
    error: tacticsError,
    loading: tacticsLoading,
    startPolling: startTacticsPolling,
    stopPolling: stopTacticsPolling,
  } = useCommercialTacticDetailsQuery({
    variables: tacticsQueryVars,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    startCompetitiveAdvantageRowsPolling(polling.default);
    bigIdeasStartPolling(polling.default);
    startStrategyPolling(polling.default);
    startTacticsPolling(polling.default);
    return () => {
      bigIdeasStopPolling();
      stopCompetitiveAdvantageRowsPolling();
      stopStrategyPolling();
      stopTacticsPolling();
    };
  }, [
    bigIdeasStartPolling,
    bigIdeasStopPolling,
    startCompetitiveAdvantageRowsPolling,
    stopCompetitiveAdvantageRowsPolling,
    startStrategyPolling,
    stopStrategyPolling,
    startTacticsPolling,
    stopTacticsPolling,
  ]);

  const competitiveAdvantageRows =
    competitiveAdvantageRowsData?.competitiveAdvantageRows?.items;

  useEffect(() => {
    // Default url
    if (!strategicImperativeId && !!competitiveAdvantageRows?.length) {
      history.replace(
        `/d/${drugId}/strategy/${strategyId}/4_3/${activeSubStep}${
          competitiveAdvantageRows?.length
            ? '/' + competitiveAdvantageRows[0].id
            : ''
        }`
      );
    }
  }, [
    drugId,
    history,
    strategyId,
    activeSubStep,
    competitiveAdvantageRowsData,
    competitiveAdvantageRows,
    strategicImperativeId,
  ]);

  const bigIdeas = useMemo(
    () =>
      getBigIdeas(
        Boolean(localCountryTacticsEnabled),
        showGlobalPlan,
        bigIdeasData,
        userRole,
        user
      ) || [],
    [localCountryTacticsEnabled, bigIdeasData, showGlobalPlan, user, userRole]
  );

  const tactics = useMemo(
    () =>
      getTacticDetails(
        Boolean(localCountryTacticsEnabled),
        showGlobalPlan,
        tacticsData,
        userRole,
        user
      ) || [],
    [localCountryTacticsEnabled, showGlobalPlan, tacticsData, user, userRole]
  );

  const countryContributionsExist = useMemo(
    () =>
      Boolean(
        showGlobalPlan &&
          (bigIdeas.find((idea) => {
            const isCountryIdea = idea.country !== 'global';
            const hasInputData =
              idea.audience ||
              idea.due ||
              idea.from ||
              idea.to ||
              idea.responsibility ||
              idea.budget;
            return isCountryIdea && hasInputData;
          }) ||
            tactics.find((tactic) => {
              const isCountryTactic = tactic.region !== 'global';
              const hasInputData =
                tactic.audience ||
                tactic.due ||
                tactic.from ||
                tactic.to ||
                tactic.responsibility ||
                tactic.budget;
              return isCountryTactic && hasInputData;
            }))
      ),
    [bigIdeas, showGlobalPlan, tactics]
  );

  const activeRow = competitiveAdvantageRows?.find(
    (r) => r.id === +strategicImperativeId
  );

  const activeObjectives = useMemo(
    () =>
      activeRow?.keyStatements
        ?.filter(
          (k) =>
            k.step === Step.DistinctiveCapabilities &&
            k.subStep === SubStep.StrategicObjectives
        )
        .filter((item) => {
          if (localCountryTacticsEnabled) {
            if (userRole.isCountryContributor) {
              return item.region === 'global' || item.region === user?.country;
            }

            return showGlobalPlan || item.region === 'global';
          }
          return item.region === 'global';
        }) || [],
    [
      activeRow?.keyStatements,
      localCountryTacticsEnabled,
      showGlobalPlan,
      user?.country,
      userRole.isCountryContributor,
    ]
  );

  const noGlobalBigIdeas = !bigIdeas?.length;
  const noCountryBigIdeas = !bigIdeas?.filter((idea) => {
    return idea.country !== 'global';
  }).length;

  const globalObjectives = useMemo(() => {
    return activeObjectives.filter((item) => item.region === 'global');
  }, [activeObjectives]);

  const countryObjectives = useMemo(() => {
    return activeObjectives.filter((item) => item.region !== 'global');
  }, [activeObjectives]);

  return (
    <>
      <CommercialStrategyNav
        drugId={drugId}
        strategyId={strategyId}
        subStep={SubStep.ThePlan}
        strategicImperativeId={+strategicImperativeId}
        countryTacticsEnabled={Boolean(localCountryTacticsEnabled)}
        setCountryTacticsEnabled={(val) => {
          if (!val) {
            setHideGlobalPlan(false);
          }
          setCountryTacticsEnabled(val);
        }}
        countryContributionsExist={Boolean(countryContributionsExist)}
      />
      <CommercialStrategyStepHeader
        substep={substep}
        imperatives={competitiveAdvantageRows}
        setKeyEventsSidebarOpen={setKeyEventsSidebarOpen}
      />

      <KeyEventsSidebar
        type={Step.CommercialStrategy}
        isOpen={keyEventsSidebarOpen}
        onClose={() => setKeyEventsSidebarOpen(false)}
        strategyId={Number(strategyId)}
        region={'global'}
        step={Step.CommercialStrategy}
        isGlobalContributor={userRole.isGlobalContributor}
        canEnable={false} 
      />

      <ErrorWrapper
        isLoading={
          strategyLoading ||
          bigIdeasLoading ||
          tacticsLoading ||
          competitiveAdvantageRowsLoading
        }
        errors={[
          strategyError,
          competitiveAdvantageRowsError,
          bigIdeasError,
          tacticsError,
        ]}
        dataMissing={false}
      >
        <PageWrapper>
          {localCountryTacticsEnabled && (
            <SpinOffsToggle
              disabled={false}
              value={showGlobalPlan}
              onChange={() => setHideGlobalPlan(!showGlobalPlan)}
              label={
                userRole.isCountryContributor
                  ? 'Show global plans'
                  : 'Show country plans'
              }
            />
          )}
          <PlanTable
            isDesktop={!smallTable}
            headerColumns={['Big idea', ...headerColumns]}
            headerTitle="Big ideas"
            emptyTitle="No big ideas yet"
            rows={({
              id,
              text,
              audience,
              from,
              to,
              due,
              responsibility,
              budget,
              collaboration,
              country,
              additionalCountries,
            }: any) => (
              <CountrySolutionRow
                country={country}
                isDesktop={!smallTable}
                currency={strategyData?.strategy?.currency[0] || '¥'}
                text={text}
                audience={audience}
                from={from}
                to={to}
                due={due}
                responsibility={responsibility}
                budget={budget}
                solutionTextOverride="Big Idea"
                actionsTextOverride="Audience"
                collaboration={collaboration}
                isCountryContributor={userRole.isCountryContributor}
                canEdit={
                  (country === 'global' && user?.role === Role.Lead) ||
                  (country === user?.country && user?.role === Role.Contributor)
                }
                additionalCountries={additionalCountries}
                globalPlanVisible={showGlobalPlan}
                updateRow={async (vals) => {
                  await bigIdeaDetailUpdate({
                    variables: {
                      id,
                      data: {
                        ...vals,
                      },
                    },
                  });
                }}
              />
            )}
            data={bigIdeas}
            EmptySubHeading={
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <BigIdeasEmptyState
                  drugId={drugId}
                  strategyId={strategyId}
                  showGlobalPlan={showGlobalPlan}
                  noGlobalBigIdeas={noGlobalBigIdeas}
                  noCountryBigIdeas={noCountryBigIdeas}
                  localCountryTacticsEnabled={Boolean(
                    localCountryTacticsEnabled
                  )}
                  userRole={userRole}
                />
              </div>
            }
          />
          {!globalObjectives.length ||
          (!globalObjectives.length &&
            countryObjectives.length &&
            !showGlobalPlan) ? (
            <ThePlanPageEmptyStates
              showGlobalPlan={showGlobalPlan}
              drugId={drugId}
              strategyId={strategyId}
              strategicImperativeId={strategicImperativeId}
              countryObjectivesExist={Boolean(countryObjectives?.length)}
              userRole={userRole}
              localCountryTacticsEnabled={Boolean(localCountryTacticsEnabled)}
            />
          ) : (
            <ObjectiveTables
              activeObjectives={activeObjectives}
              tactics={tactics}
              user={user}
              smallTable={smallTable}
              strategyData={strategyData}
              commercialTacticDetailUpdate={commercialTacticDetailUpdate}
              userRole={userRole}
              showGlobalPlan={showGlobalPlan}
              drugId={drugId}
              strategyId={strategyId}
              strategicImperativeId={strategicImperativeId}
              localCountryTacticsEnabled={localCountryTacticsEnabled}
            />
          )}
        </PageWrapper>
      </ErrorWrapper>
      <Tooltip id="commercialStrategyThePlanTooltip" effect="float" multiline />
    </>
  );
};
