import { AccessStrategyPrioritiseSupportingMessageFragment } from 'data/graphql/generated';

export default function calculateAverageMessageRatings(
  supportingMessages: AccessStrategyPrioritiseSupportingMessageFragment[]
) {
  let importanceTotal = 0;
  let dataRobustnessTotal = 0;
  let ratedMessageCount = 0;

  for (const message of supportingMessages) {
    const { x, y } = message;
    if (!x || !y) continue;

    importanceTotal += !!y ? 100 - y : 0;
    dataRobustnessTotal += !!x ? x : 0;
    ratedMessageCount++;
  }

  return {
    averageScoreImportance: importanceTotal / ratedMessageCount || 0,
    averageScoreDataRobustness: dataRobustnessTotal / ratedMessageCount || 0,
  };
}
