import { colors } from 'constants/index';
import styled from 'styled-components/macro';
import { ActionTypeEnum } from '../types';

export const VerticalLine = styled.div`
  width: 1px;
  background: ${colors.white50};
  height: 100%;
  margin: 0 5px;
`;

export const PatientJourneyNav = styled.div`
  display: flex;
  margin-right: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
  width: 100%;

  @media screen and (max-width: 945px) {
    margin-top: 20px;
  }
`;

export const Processing = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: block;
  cursor: wait;
  z-index: 999999;
`;

export const ActionType = styled.div<{ active: boolean; type: ActionTypeEnum }>`
  width: 40px;
  height: 40px;
  background-color: ${({ active }) =>
    active ? colors.purple80a : 'transparent'};
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 5px;
  background-image: ${({ type }) =>
    `url(${
      type === ActionTypeEnum.DRAG
        ? '/patient-journey/Hand.png'
        : '/patient-journey/Pointer.png'
    })
      `};
  background-size: 30px;
  background-position: center;
  background-repeat: no-repeat;

  &:hover {
    background-color: ${({ active }) =>
      active ? colors.purple80a : colors.purple30a};
  }
`;
