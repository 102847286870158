import { colors } from 'constants/index';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Caption } from './TextStyles';
import styled from 'styled-components';
import { clamp } from 'lodash';

const CharacterCount = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
`;

interface Props {
  size?: string;
  limit: number;
  count: number;
  color: (() => string) | string;
  grow?: boolean;
  showCount?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export const CharacterLimitProgressDonut = ({
  size,
  color,
  limit,
  count,
  grow,
  showCount,
  className,
  style,
}: Props) => {
  const [strokeColor, setStrokeColor] = useState('transparent');
  const [viewboxWidth, setViewboxWidth] = useState(0);

  const wrapperRef = useRef<HTMLDivElement>(null!);
  const circleRef = useRef<SVGCircleElement>(null!);

  const stroke = 2;

  const radius =
    Number(wrapperRef.current?.offsetWidth) / 2 - stroke * 2 || 242;
  const circumference = radius * 2 * Math.PI;

  const progress = clamp((count / limit) * 100, 0, 100);

  useEffect(() => {
    if (typeof color === 'string') {
      setStrokeColor(color);
    }
    if (color instanceof Function) {
      setStrokeColor(color());
    }
  }, [color]);

  const circleWidth = circleRef.current?.getBBox().width;

  useLayoutEffect(() => {
    if (circleRef.current?.getBBox().width) {
      setViewboxWidth(circleWidth + stroke || 0);
    }
  }, [circleWidth]);

  return (
    <div
      className={className}
      ref={wrapperRef}
      style={{
        width: size || '',
        height: size || '',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 'none',
        position: 'relative',
        transform: `scale(${grow ? 1 : 0.56})`,
        transition: 'transform 0.3s',
        ...style,
      }}
    >
      {showCount && (
        <CharacterCount>
          <Caption color={colors.greyDark}>{(count - limit) * -1}</Caption>
        </CharacterCount>
      )}

      <svg
        preserveAspectRatio={'xMinYMin meet'}
        viewBox={`0 0 ${viewboxWidth} ${viewboxWidth}`}
        width="100%"
        height="100%"
      >
        <circle
          stroke={colors.greyMedium}
          fill="transparent"
          strokeWidth={stroke}
          r={radius}
          cx={'50%'}
          cy={'50%'}
        />
        <circle
          ref={circleRef}
          stroke={strokeColor}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference + ' ' + circumference}
          strokeDashoffset={circumference - (progress / 100) * circumference}
          style={{
            transformOrigin: 'center',
            transform: 'rotate(-90deg)',
          }}
          r={radius}
          cx={'50%'}
          cy={'50%'}
        />
      </svg>
    </div>
  );
};
