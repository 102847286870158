import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import { TextAreaInput } from 'components/shared/TextAreaInput';
import { colors } from 'constants/colors';
import { useToastContext } from 'contexts/ToastContext';
import {
  Collaboration,
  RatingStar,
  RatingDollar,
  ButtonRound,
  BodySmall,
} from 'components/shared';
import {
  EndpointTargetFragment,
  useEndpointTargetUpdateMutation,
  useEndpointTargetDeleteMutation,
} from 'data/graphql/generated';
import { useWidth } from 'hooks/useWidth';
import { isTouchDevice } from 'utils/isTouchDevice';
import { fadeFromPurpleBGCSS } from 'utils/keyframes';

const GroupItemMobile = styled.div`
  position: relative;
  padding: 10px 15px 65px 15px;

  @media (min-width: 945px) {
    display: none;
  }
`;

const GroupItemDesktop = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 10px 15px 10px 15px;

  @media (max-width: 945px) {
    display: none;
  }
`;

const GroupItem = styled.div`
  position: relative;
  border-bottom: 1px solid rgba(232, 232, 233, 1);

  &.originalIdea::before {
    ${fadeFromPurpleBGCSS}
  }
`;

const Column = styled.div`
  align-items: center;
`;

export const ColumnTitle = styled(Column)`
  width: ${(147 / 845) * 100}%;

  @media (min-width: 1200px) {
    width: ${(380 / 1360) * 100}%;
    margin-right: ${(15 / 1360) * 100}%;
  }
`;

export const ColumnMeasurement = styled(Column)`
  width: ${(147 / 845) * 100}%;

  @media (min-width: 1200px) {
    width: ${(380 / 1360) * 100}%;
    margin-right: ${(15 / 1360) * 100}%;
  }
`;

export const ColumnFeasibility = styled(Column)`
  width: ${(124 / 845) * 100}%;
  margin-right: ${(14 / 845) * 100}%;

  @media (min-width: 1200px) {
    width: ${(139 / 1360) * 100}%;
    margin-right: ${(20 / 1360) * 100}%;
  }
`;

export const ColumnImpact = styled(Column)`
  width: ${(124 / 845) * 100}%;
  margin-right: ${(14 / 845) * 100}%;

  @media (min-width: 1200px) {
    width: ${(139 / 1360) * 100}%;
    margin-right: ${(20 / 1360) * 100}%;
  }
`;

export const ColumnCost = styled(Column)`
  width: ${(80 / 845) * 100}%;

  @media (min-width: 1200px) {
    width: ${(95 / 1360) * 100}%;
  }
`;

export const ColumnCollaboration = styled(Column)`
  width: ${(103 / 845) * 100}%;

  @media (min-width: 1200px) {
    width: ${(148 / 1360) * 100}%;
  }
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;

const Content = styled.div<{ align: 'top' | 'center' }>`
  align-self: ${({ align }) => (align === 'top' ? 'flex-start' : 'center')};
`;

export const DeleteConfirmWrapper = styled.div`
  display: block;
  border: 1px solid ${colors.black30};
  width: 188px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: ${colors.white};
  right: 0px;
  z-index: 9;
  border-radius: 5px;
`;

export const DeleteConfirmCancel = styled.div`
  font-size: 14px;
  text-align: center;
  color: ${colors.black};
  width: 50%;
  display: inline-block;
  font-weight: 500;
  margin-top: 5px;
  cursor: pointer;
`;

export const DeleteConfirmConfirm = styled.div`
  font-size: 14px;
  text-align: center;
  color: ${colors.red};
  width: 50%;
  display: inline-block;
  font-weight: 500;
  margin-top: 5px;
  cursor: pointer;
`;

const FieldTitle = styled(BodySmall)`
  color: ${colors.greyDark};
  font-weight: 400;
  display: inline-block;
  margin-bottom: 2px;
`;

export const TableRow: React.FC<{
  endpointTarget: EndpointTargetFragment;
  refetch(): Promise<void>;
  autoFocus?: boolean;
}> = ({ endpointTarget, refetch, autoFocus }) => {
  const isTouch = isTouchDevice();
  const [showTrashBtn, setShowTrashBtn] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

  const [localEndpointTarget, setLocalEndpointTarget] = useState(
    endpointTarget
  );
  const width = useWidth();

  const showMobileView = width < 945;

  useEffect(() => {
    setLocalEndpointTarget(endpointTarget);
  }, [endpointTarget]);

  const [createToast, removeToast] = useToastContext();

  const [updateEndpointTarget] = useEndpointTargetUpdateMutation();
  const [deleteEndpointTarget] = useEndpointTargetDeleteMutation();

  async function updateEndpoint(data: {
    title?: string;
    measurement?: string;
    feasibility?: number;
    impact?: number;
    cost?: number;
  }) {
    const { id } = createToast('', 'Saving');
    await updateEndpointTarget({
      variables: {
        id: endpointTarget.id,
        data,
      },
    });
    removeToast(id);
  }

  async function deleteEndpoint() {
    await deleteEndpointTarget({
      variables: {
        id: endpointTarget.id,
      },
    });
    await refetch();
    setShowDeleteConfirm(false);
  }
  const { title, measurement, cost, feasibility, impact } = localEndpointTarget;

  return (
    <GroupItem
      id={!!endpointTarget.id ? 'endpoint_' + endpointTarget.id : ''}
      onMouseEnter={() => setShowTrashBtn(true)}
      onMouseLeave={() => setShowTrashBtn(false)}
      data-cy="evidence-table-row"
    >
      {showMobileView ? (
        <GroupItemMobile>
          <div style={{ height: 40 }}>
            <div style={{ position: 'absolute', top: 15, right: 0 }}>
              <ButtonRound
                level="secondary"
                iconName="Trash"
                size="small"
                onClick={() => setShowDeleteConfirm(true)}
              />
              {showDeleteConfirm ? (
                <DeleteConfirmWrapper>
                  <DeleteConfirmCancel
                    onClick={() => setShowDeleteConfirm(false)}
                  >
                    Cancel
                  </DeleteConfirmCancel>
                  <DeleteConfirmConfirm onClick={deleteEndpoint}>
                    Delete row
                  </DeleteConfirmConfirm>
                </DeleteConfirmWrapper>
              ) : null}
            </div>
          </div>

          <div style={{ marginTop: 5 }}>
            <FieldTitle>Endpoint target</FieldTitle>
            <TextAreaInput
              autoFocus={!!autoFocus}
              $borderless
              $table
              $small
              placeholder="Endpoint target"
              style={{ margin: '0px 0px 0px -1px', lineHeight: '20px' }}
              value={title}
              onBlur={() => updateEndpoint({ title })}
              onChange={async (e) => {
                const target = e.target as HTMLTextAreaElement;
                setLocalEndpointTarget({
                  ...localEndpointTarget,
                  title: target.value,
                });
              }}
            />
          </div>
          <div style={{ marginTop: 15 }}>
            <FieldTitle>Measurement</FieldTitle>
            <TextAreaInput
              $borderless
              $table
              $small
              placeholder="Measurement"
              style={{ margin: '0px 0px 0px -1px', lineHeight: '20px' }}
              value={measurement}
              onBlur={() => updateEndpoint({ measurement })}
              onChange={async (e) => {
                const target = e.target as HTMLTextAreaElement;
                setLocalEndpointTarget({
                  ...localEndpointTarget,
                  measurement: target.value,
                });
              }}
            />
          </div>
          <>
            <div style={{ display: 'inline-block', width: 154, marginTop: 15 }}>
              <FieldTitle>Feasibility</FieldTitle>
              <RatingStar
                rating={feasibility}
                onUpdate={(val) => {
                  setLocalEndpointTarget({
                    ...localEndpointTarget,
                    feasibility: val,
                  });
                  updateEndpoint({ feasibility: val });
                }}
                style={{ marginTop: 5 }}
              />
            </div>
            <div style={{ display: 'inline-block', width: 154, marginTop: 15 }}>
              <FieldTitle>Impact</FieldTitle>
              <RatingStar
                rating={impact}
                onUpdate={(val) => {
                  setLocalEndpointTarget({
                    ...localEndpointTarget,
                    impact: val,
                  });
                  updateEndpoint({ impact: val });
                }}
                style={{ marginTop: 5 }}
              />
            </div>
            <div style={{ display: 'inline-block', width: 154, marginTop: 15 }}>
              <FieldTitle>Cost</FieldTitle>
              <RatingDollar
                rating={cost}
                onUpdate={(val) => {
                  setLocalEndpointTarget({
                    ...localEndpointTarget,
                    cost: val,
                  });
                  updateEndpoint({ cost: val });
                }}
                style={{ marginTop: 5 }}
              />
            </div>
          </>
          <div style={{ position: 'absolute', bottom: 15, right: 0 }}>
            <Collaboration
              collaboration={endpointTarget.collaboration}
              style={{ zIndex: 4 }}
            />
          </div>
        </GroupItemMobile>
      ) : (
        <GroupItemDesktop>
          <ColumnTitle>
            <Content align="top">
              <div style={{ paddingRight: 20 }}>
                <TextAreaInput
                  autoFocus={!!autoFocus}
                  $borderless
                  $table
                  $small
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                  placeholder="Endpoint target"
                  style={{ margin: 0, lineHeight: '20px' }}
                  value={title}
                  onBlur={() => {
                    if (title.trim() === '' && measurement.trim() === '') {
                      return deleteEndpoint();
                    } else if (title.trim() !== '') return updateEndpoint({ title })

                    return updateEndpoint({ title: "" })
                  }}
                  onChange={async (e) => {
                    const target = e.target as HTMLTextAreaElement;
                    setLocalEndpointTarget({
                      ...localEndpointTarget,
                      title: target.value,
                    });
                  }}
                />
              </div>
            </Content>
          </ColumnTitle>
          <ColumnMeasurement>
            <Content align="top">
              <div style={{ paddingRight: 18 }}>
                <TextAreaInput
                  $borderless
                  $table
                  $small
                  placeholder="Measurement"
                  style={{ margin: 0, lineHeight: '20px' }}
                  value={measurement}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                  onBlur={() => {
                    if (title.trim() === '' && measurement.trim() === '') {
                      return deleteEndpoint();
                    } else if (measurement.trim() !== '') return updateEndpoint({ measurement })

                    updateEndpoint({ measurement: "" })
                  }}
                  onChange={async (e) => {
                    const target = e.target as HTMLTextAreaElement;
                    setLocalEndpointTarget({
                      ...localEndpointTarget,
                      measurement: target.value,
                    });
                  }}
                />
              </div>
            </Content>
          </ColumnMeasurement>
          <ColumnFeasibility>
            <Content align="center">
              <RatingStar
                rating={feasibility}
                onUpdate={(val) => {
                  setLocalEndpointTarget({
                    ...localEndpointTarget,
                    feasibility: val,
                  });
                  updateEndpoint({ feasibility: val });
                }}
                style={{ marginTop: 5 }}
              />
            </Content>
          </ColumnFeasibility>
          <ColumnImpact>
            <Content align="center">
              <RatingStar
                rating={impact}
                onUpdate={(val) => {
                  setLocalEndpointTarget({
                    ...localEndpointTarget,
                    impact: val,
                  });
                  updateEndpoint({ impact: val });
                }}
                style={{ marginTop: 5 }}
              />
            </Content>
          </ColumnImpact>
          <ColumnCost>
            <Content align="center">
              <RatingDollar
                rating={cost}
                onUpdate={(val) => {
                  setLocalEndpointTarget({
                    ...localEndpointTarget,
                    cost: val,
                  });
                  updateEndpoint({ cost: val });
                }}
                style={{ marginTop: 5 }}
              />
            </Content>
          </ColumnCost>
          <ColumnCollaboration>
            <Content align="center">
              <Wrapper>
                <Collaboration
                  collaboration={endpointTarget.collaboration}
                  style={{ zIndex: 4 }}
                />
              </Wrapper>
            </Content>
          </ColumnCollaboration>

          {isTouch || showTrashBtn ? (
            <div
              data-cy="evidence-row-delete-btn"
              style={{ position: 'absolute', top: 10, right: 0 }}
            >
              <ButtonRound
                level="secondary"
                iconName="Trash"
                size="small"
                onClick={() => setShowDeleteConfirm(true)}
              />
            </div>
          ) : null}
          {showDeleteConfirm ? (
            <DeleteConfirmWrapper>
              <DeleteConfirmCancel onClick={() => setShowDeleteConfirm(false)}>
                Cancel
              </DeleteConfirmCancel>
              <DeleteConfirmConfirm
                data-cy="evidence-row-confirm-delete"
                onClick={deleteEndpoint}
              >
                Delete row
              </DeleteConfirmConfirm>
            </DeleteConfirmWrapper>
          ) : null}
        </GroupItemDesktop>
      )}
    </GroupItem>
  );
};
