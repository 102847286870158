import React from 'react';
import styled from 'styled-components/macro';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

import { ValueText } from 'components/shared/EditableTextarea';
import { colors } from 'constants/colors';
import { device } from 'utils/breakpoints';

interface EditableNumberInputProps {
  name: string;
  initialValue?: number | null;
  placeholderText?: string;
  disabled?: boolean;
  onBlur?: (value: number | null, resetValue?: () => void) => void;
}

const StyledInput = styled(NumberFormat)`
  background: transparent;
  height: 22px;
  display: block;
  padding: 0;
  width: 100%;
  resize: none;
  line-height: 20px;
  border-radius: 2px;
  border: 0.5px solid transparent;
  outline: none;
  font-size: 14px;
  color: #141427;
  transition: border 0.3s;

  :hover {
    border: 0.5px solid #696975;
  }

  @media ${device.tabletMax} {
    min-width: 127px;
  }
`;

export const EditableNumberInput: React.FC<EditableNumberInputProps> = ({
  name,
  initialValue,
  placeholderText = '',
  disabled = false,
  onBlur,
}) => {
  const defaultValue: number | undefined =
    initialValue === null ? undefined : initialValue;
  const [value, setValue] = React.useState<number | undefined>(defaultValue);

  const resetValue = () => {
    setValue(defaultValue);
  };

  const validateInput = ({ value }: NumberFormatValues) => {
    if (value === '') {
      return true;
    }

    const parsedValue = parseInt(value);
    return parsedValue >= 0 && parsedValue <= 100;
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      const { value } = e.target;

      if (value === '') {
        onBlur(null, resetValue);
      }

      const trimmedValue = value.replace('%', '');
      const parsedValue = parseInt(trimmedValue);
      onBlur(parsedValue, resetValue);
    }
  };

  if (disabled && value) {
    return <ValueText>{value}</ValueText>;
  }

  if (disabled && !value) {
    return <ValueText color={colors.greyDark}>{placeholderText}</ValueText>;
  }

  return (
    <StyledInput
      name={name}
      max={100}
      suffix="%"
      placeholder={placeholderText}
      allowNegative={false}
      isAllowed={validateInput}
      onBlur={handleOnBlur}
      value={value}
      onChange={(e) => setValue(parseInt(e.target.value) || undefined)}
    />
  );
};
