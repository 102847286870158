import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled, { } from 'styled-components/macro';
import Textarea from 'react-expanding-textarea';
import { throttle } from 'lodash';

import {
  ButtonRound,
  Icon,
  ButtonLabel,
} from 'components/shared';
import { colors } from 'constants/colors';
import { useIsMounted } from 'hooks/useIsMounted';
import { CardType } from 'types';
import { BodyNormal } from 'components/shared';
import {
  PostItCardFragment,
  Role,
} from 'data/graphql/generated';
import { postItCardWidths } from 'constants/index';
import useMobile from 'hooks/useMobile';
import { verifyUserRole } from 'utils/verifyUserRole';
import { DeleteConfirmation, DeleteConfirmationFooter } from './Insight';

const Wrapper = styled.div<{
  color: string;
  cardType: CardType;
  disabled: boolean;
}>`
  user-select: none;
  background: ${({ color }) => color};
  vertical-align: top;
  width: 100%;

  display: inline-block;

  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  // position: relative;

  // @media (max-width: 480px) {
  //   width: ${postItCardWidths.min}px;
  // }
`;

const Content = styled.div<{
  focused: boolean;
  color: string;
  cardType: CardType;
  disabled: boolean;
}>`
  display: inline-flex;
  padding: 10px;
  border: 0.5px solid ${({ focused, color }) => (focused ? colors.blue : color)};
  height: auto;

  pointer-events: ${({ disabled }) => disabled && 'none'};
`;

const IconsEditDelete = styled.div<{ visible: boolean }>`
  position: absolute;
  top: -22px;
  right: -12px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const DeleteIcon = styled(ButtonRound)`
  margin-bottom: 5px;
`;

const TextareaWrapper = styled.div`
  display: flex;
  // vertical-align: middle;
  // height: 160px;
  // width: 200px;
  // position: relative;
  width: 100%;
`;

const StyledTextarea = styled(Textarea) <{
  textsize: number;
  lineheight: number;
  $height: number;
}>`
  font-family: ABCFavorit;
  font-style: normal;
  font-weight: 400;
  background: transparent;
  user-select: text;
  font-size: ${({ textsize }) => textsize}px;
  line-height: ${({ lineheight }) => lineheight}px;
  border: none;
  resize: none;
  width: 100%;
  margin-left: 10px;
  // max-width: 200px;
  max-width: 100%;
  color: ${colors.black};
  height: ${({ $height }) => $height + 'px !important'};
  padding: 0;

  &:disabled {
    color: ${colors.black};
  }

  &:focus {
    outline: none;
  }
`;

const CardText = styled.span<{
  textsize: number;
  lineheight: number;
}>`
  display: flex;
  font-family: ABCFavorit;
  font-style: normal;
  font-weight: 400;
  background: transparent;
  user-select: none;
  font-size: ${({ textsize }) => textsize}px;
  line-height: ${({ lineheight }) => lineheight}px;
  border: none;
  resize: none;
  max-width: 500px;
  color: ${colors.black};

  display: block;
  word-break: break-word;
`;

const CardIconWrapper = styled.div`
  display: inline-block;
  padding: 7px 0 0 5px;
  width: 25px;
  height: 27px;
`;

function getTypeSize(
  length: number,
  isMobile: boolean
): { fontSize: number; lineHeight: number } {
  // NOTE ^maxim
  // If you're changing this make sure to
  // test on an actually iOS device since
  // it renders very differently to on a
  // browser on MacOS
  if (isMobile) {
    // large
    if (length < 16) {
      return { fontSize: 26, lineHeight: 32 };
    }
    // medium
    if (length < 44) {
      return { fontSize: 16, lineHeight: 20 };
    }
    // small
    if (length < 60) {
      return { fontSize: 13, lineHeight: 20 };
    }
    return { fontSize: 10, lineHeight: 18 };
  }

  // large
  if (length < 35) {
    return { fontSize: 26, lineHeight: 32 };
  }
  // medium
  if (length < 80) {
    return { fontSize: 16, lineHeight: 20 };
  }
  // small
  return { fontSize: 14, lineHeight: 20 };
}

interface Props {
  tooltipUID: string;
  card: PostItCardFragment;
  groupId: number;
  userId: number;
  updateCard(card: PostItCardFragment): void;
  removeCard(cardId: number): void;
  dragDisabled: boolean;
  onFocus(): void;
  onBlur(): void;
  onMouseDown(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  disableCollaboration?: boolean;
  userRole: Role | null;
  uploading: boolean;
  errMsg: { id: number; message: string };
  setErrMsg: React.Dispatch<
    React.SetStateAction<{
      id: number;
      message: string;
      cardHasImage: boolean;
    }>
  >;
  editingDisabled?: boolean;
  cardTitles: PostItCardFragment[];
  refetch(): void;
}

export const OutcomeCard: React.FC<Props> = ({
  card,
  tooltipUID,
  groupId,
  userId,
  removeCard,
  updateCard,
  refetch,
  cardTitles,
  dragDisabled,
  onBlur,
  onFocus,
  onMouseDown,
  disableCollaboration = false,
  userRole,
  uploading,
  errMsg,
  setErrMsg,
  editingDisabled = false,
}) => {
  const isMobile = useMobile();
  const isMounted = useIsMounted();
  const [title, setTitle] = useState(card.title || '');
  const [focused, setFocused] = useState<boolean>(false);
  const [iconsEditDeleteVisible, setIconsEditDeleteVisible] = useState<boolean>(
    false
  );
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);


  const color = colors.white;
  const editDeleteButtonsRef = useRef<HTMLDivElement>(null);

  // NOTE this editing local state is too stop apollo overwritting
  // textarea state when user is typing
  const [isEditing, setIsEditing] = useState(false);

  // eslint-disable-next-line
  const throttleSave = useCallback(
    throttle((val: PostItCardFragment) => {
      updateCard(val);
    }, 1000),
    []
  );

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const styledTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const [textAreaHeight] = useState(35);

  useEffect(() => {
    if (!isEditing) setTitle(card.title);
  }, [card.title, isEditing]);

  const { fontSize, lineHeight } = getTypeSize(title?.length, isMobile);

  useEffect(() => {
    // const fixTextSize = () => {
    //   const textBoxHeight = Number(
    //     textAreaRef?.current?.getBoundingClientRect().height
    //   );
    //   setTextAreaHeight(textBoxHeight);
    // };
    // fixTextSize();
    // window.addEventListener('resize', fixTextSize);
    // return () => {
    //   window.removeEventListener('resize', fixTextSize);
    // };
  }, [title, fontSize]);

  useEffect(() => {
    if (styledTextAreaRef.current && title.length === 0) {
      styledTextAreaRef.current.focus();
    }
  }, [title]);

  const isOwner = card.user?.id === userId;
  const { isLead } = verifyUserRole(userRole);
  const characterlimit = 102;
  const showTyping = card.typing && !isEditing;

  const checkExists = async (text: string) => {
    const currentCard = cardTitles.find((item) => item.title === text)?.id || null;

    if (!currentCard) return false;

    const updatedCardGroup = cardTitles.filter((item) => item.id !== currentCard);

    const exists = updatedCardGroup.some((item) => item.title === text && item.id !== card.id);

    return exists;
  };



  // TODO: code cleanup on this component

  return (
    <Wrapper
      disabled={editingDisabled}
      cardType={card.type}
      onMouseDown={(e) => {
        onMouseDown(e);
      }}
      color={color}
      // style={{ height: "33px", overflow: "hidden" }}
      className='outcome-card__wrapper'
    >
      <Content
        disabled={editingDisabled || showTyping}
        focused={focused}
        color={color}
        cardType={card.type}
        style={{ width: "100%" }}
      >
        <CardIconWrapper>
          <Icon name="Grip" color={colors.black} size={30} />
        </CardIconWrapper>
        {card.type !== 'image' && (
          <TextareaWrapper>
            {(!isOwner && typeof userRole === 'string' && !isLead) ? (
              <CardText
                ref={textAreaRef}
                textsize={14}
                lineheight={lineHeight}
              >
                {card.title}
              </CardText>
            ) : (
              <>
                {/* This CardText allows us to measure the height of the text.
                The text area updates it's height too late, so we can't use that component */}
                {/* <CardText
                  ref={textAreaRef}
                  style={{
                    position: 'absolute',
                    pointerEvents: 'none',
                    visibility: 'hidden',
                    padding: 2,
                    minHeight: 30,
                  }}
                  textsize={14}
                  lineheight={20}
                >
                  {title}
                </CardText> */}
                <StyledTextarea
                  ref={styledTextAreaRef}
                  // $height={isMobile ? 150 : textAreaHeight}
                  $height={textAreaHeight}
                  value={title}
                  disabled={!isLead}
                  onFocus={() => {
                    setIsEditing(true);
                    setFocused(true);
                    setIconsEditDeleteVisible(true);
                    updateCard({ ...card, typing: true, typingUserId: userId });
                    onFocus();
                  }}
                  onBlur={async () => {
                    if (!isLead) return

                    const exists = await checkExists(title);
                    const hasSameTitle = cardTitles.map((item) => item.title)?.includes(title);
                    const whitespace = title.match(/^\s+/)?.[0];

                    onBlur();
                    setFocused(false);
                    setIsEditing(false);

                    // remove card if has white space or empty title
                    if ((!title && !card.title) || whitespace) {
                      removeCard(card.id);
                      setIconsEditDeleteVisible(false);

                      return;
                      //
                    } else if (exists || hasSameTitle) {
                      if (exists || !title || !card.title) {
                        removeCard(card.id);
                        return refetch();
                      }

                      updateCard({
                        ...card,
                        title: card.title,
                        typing: false,
                        typingUserId: null,
                      });

                      setTimeout(() => {
                        if (isMounted.current) {
                          setIconsEditDeleteVisible(false);
                        }
                      }, 200);

                      setIsEditing(false);
                      refetch();

                      return;
                    }

                    // TODO: modify logic to remove card if outcome title is the same with other outcomes
                    if (exists) {
                      removeCard(card.id);
                      return refetch();
                    }

                    setTimeout(() => {
                      if (isMounted.current) {
                        setIconsEditDeleteVisible(false);
                      }
                    }, 200);

                    // cancel any current debounced saving states
                    throttleSave.cancel();

                    updateCard({
                      ...card,
                      title: title || card.title,
                      typing: false,
                      typingUserId: null,
                    });
                    refetch();
                    setIsEditing(false);
                  }}
                  // @ts-ignore
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    const val = e.target.value.replace('\n', '');
                    setTitle(val);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e?.currentTarget?.blur();
                    }
                  }}
                  maxLength={characterlimit}
                  textsize={14}
                  lineheight={lineHeight}
                />
              </>
            )}
          </TextareaWrapper>
        )}
      </Content>

      <DeleteConfirmation show={showDeleteConfirm} color={color} style={{
        height: 60,
        top: -7,
      }}>
        <div>
          <BodyNormal color={colors.black}>
            Delete this outcome?
          </BodyNormal>
        </div>
        <DeleteConfirmationFooter>
          <ButtonLabel
            onClick={() => {
              setShowDeleteConfirm(false)
            }}
          >
            Cancel
          </ButtonLabel>
          <ButtonLabel
            color={colors.red}
            onClick={async (e) => {
              e.stopPropagation();
              removeCard(card.id);
              setShowDeleteConfirm(false);
            }}
          >
            Delete
          </ButtonLabel>
        </DeleteConfirmationFooter>
      </DeleteConfirmation>
      <IconsEditDelete
        className="cypress-postitcard-delete-icon"
        ref={editDeleteButtonsRef}
        visible={iconsEditDeleteVisible}
      >
        <DeleteIcon
          level="secondary"
          iconName="Trash"
          size="small"
          onClick={() => setShowDeleteConfirm(true)}
        />
      </IconsEditDelete>
    </Wrapper>
  );
};
