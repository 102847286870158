import React, { useEffect, useState } from 'react';

type Props = {
  containerRef: React.MutableRefObject<HTMLElement | null>;
  dropdownRef: React.MutableRefObject<HTMLElement | null>;
  isActive: boolean;
};

export const useHandleDropdownBottomOverlap = (
  { containerRef, dropdownRef, isActive }: Props,
  deps: any[] = []
) => {
  const [translateYVal, setTranslateYVal] = useState<null | number>(null);
  useEffect(() => {
    if (!dropdownRef.current || !containerRef.current) {
      return;
    }

    const dropdown = dropdownRef.current.getBoundingClientRect();
    const container = containerRef.current.getBoundingClientRect();

    const touchingBottom =
      dropdown.height +
        // Use container top + height instead of dropdown because dropdown.top value changes when dropdown moves to above container
        // which could make the dropdown alternate positions incorrectly
        container.top +
        container.height >
      window.innerHeight;

    if (isActive) {
      if (touchingBottom) {
        setTranslateYVal(-container.height);
      } else {
        setTranslateYVal(null);
      }
    }

    // Line is diabled because react does not like spread dependacies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, dropdownRef, isActive, ...deps]);

  return translateYVal;
};
