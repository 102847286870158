import { DropzoneState } from 'react-dropzone';

export async function uploadFile(
  files: DropzoneState['acceptedFiles'],
  presignedPostData: any
) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    Object.keys(presignedPostData.fields).forEach((key) => {
      if (key === '__typename') return;
      formData.append(key.replaceAll('_', '-'), presignedPostData.fields[key]);
    });
    formData.append('file', files[0]);
    const xhr = new XMLHttpRequest();
    xhr.onprogress = (evt) => {
      // NOTE this doesn't work!
      if (evt.lengthComputable) {
        // const percentComplete = (evt.loaded / evt.total) * 100;
      }
    };
    xhr.open('POST', presignedPostData.url, true);
    xhr.send(formData);
    xhr.onload = function () {
      this.status === 204 ? resolve(undefined) : reject(this.responseText);
    };
  });
}
