import { useCallback } from 'react';
import {
  useKeyEventCreateMutation,
  useKeyEventUpdateMutation,
  useKeyEventDeleteMutation,
  KeyEventInput,
  KeyEventUpdateInput,
  KeyEventsDocument,
  KeyEventsQueryVariables,
  KeyEventsQuery,
  KeyEventFragment,
} from 'data/graphql/generated';

import { apolloDeleteHelper } from 'utils/apolloQueryHelpers';
import startOfToday from 'date-fns/startOfToday';

export const useCRUDKeyEvent = (
  refetchKeyEventsVars?: KeyEventsQueryVariables
) => {
  const [createKeyEvent] = useKeyEventCreateMutation();
  const [updateKeyEvent] = useKeyEventUpdateMutation();
  const [deleteKeyEvent] = useKeyEventDeleteMutation();

  const handleCreateKeyEvent = useCallback(
    async ({
      strategy,
      text,
      type,
      localUid,
      step,
      date,
      isGlobalContributor,
      createdAt,
    }: KeyEventInput & { isGlobalContributor: boolean }) => {
      const enabledForRequestRegion = !isGlobalContributor;
      const now = startOfToday().getTime();
      createKeyEvent({
        variables: {
          data: {
            localUid,
            strategy,
            text,
            type,
            step,
            date: new Date(+date).toISOString(),
            createdAt: new Date(+createdAt).toISOString(),
          },
        },
        optimisticResponse: {
          keyEventCreate: {
            __typename: 'KeyEvent',
            id: 0,
            localUid,
            createdAt,
            lastUpdated: now.toString(),
            strategyId: strategy,
            text,
            type,
            date,
            enabledForRequestRegion,
          },
        },
        update: (cache, { data }) => {
          if (!data?.keyEventCreate?.__typename) return;

          const cachedKeyEvents = cache.readQuery({
            query: KeyEventsDocument,
            variables: refetchKeyEventsVars,
          }) as KeyEventsQuery;

          const keyEventsWithNewAddition = [
            data.keyEventCreate,
            ...(cachedKeyEvents?.keyEvents?.items || []),
          ];
          cache.writeQuery({
            query: KeyEventsDocument,
            variables: refetchKeyEventsVars,
            data: {
              keyEvents: {
                __typename: 'KeyEventPayload',
                items: keyEventsWithNewAddition,
                total: keyEventsWithNewAddition?.length,
              },
            },
          });
        },
      });
    },
    [createKeyEvent, refetchKeyEventsVars]
  );

  const handleUpdateKeyEvent = useCallback(
    ({
      localUid,
      date,
      text,
      type,
      keyEvent,
      step,
    }: KeyEventUpdateInput & { keyEvent: KeyEventFragment }) => {
      updateKeyEvent({
        variables: {
          data: {
            localUid,
            date: date && new Date(+date).toISOString(),
            text,
            type,
            step,
          },
        },
        optimisticResponse: {
          keyEventUpdate: {
            ...keyEvent,
            text: text || keyEvent.text,
            date: date || keyEvent.date,
          },
        },

        update: (cache, { data }) => {
          cache.modify({
            id: cache.identify({
              __ref: `KeyEvent:{"localUid":${localUid}}`,
            }),
            fields: {
              text() {
                return text;
              },
              date() {
                return date;
              },
            },
          });
        },
      });
    },
    [updateKeyEvent]
  );

  const handleDeleteKeyEvent = useCallback(
    (localUid: string) => {
      deleteKeyEvent({
        variables: { localUid },
        optimisticResponse: {
          keyEventDelete: {
            __typename: 'KeyEvent',
            id: 0,
            localUid,
            createdAt: '',
            lastUpdated: '',
            strategyId: 0,
            text: '',
            type: '',
            date: '',
            enabledForRequestRegion: false,
          },
        },
        update: apolloDeleteHelper({
          responseField: 'keyEventDelete',
          query: KeyEventsDocument,
          queryVars: refetchKeyEventsVars,
          queryName: 'keyEvents',
        }),
      });
    },
    [deleteKeyEvent, refetchKeyEventsVars]
  );

  return {
    handleCreateKeyEvent,
    handleUpdateKeyEvent,
    handleDeleteKeyEvent,
  };
};
