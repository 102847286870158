import React, { useState } from 'react';
import { DraggablePin } from 'components/GridPages/DraggablePin';
import { DragObjectWithType, DropTargetMonitor } from 'react-dnd';
import useDesktop from 'hooks/useDesktop';
import useHandleCalloutHover from 'hooks/useHandleCalloutHover';
import {
  calculateDropCoordinates,
  DropzoneGrid,
  Wrapper as GridWrapper,
} from 'components/GridPages/DropzoneGrid';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import {
  AccessStrategyPrioritiseSupportingMessageFragment,
  AccessStrategyPrioritiseSupportingMessagesDocument,
  useAccessStrategyPrioritiseSupportingMessageUpdateMutation,
} from 'data/graphql/generated';
import { HoverCalloutWrapper } from 'components/GridPages/HoverCalloutWrapper';
import { DraggableCardType } from 'types';
import { apolloUpdateHelper } from 'utils/apolloQueryHelpers';
import { messsagesQueryVars } from './AccessStrategyPrioritisation';
import { SupportingMessageCallout } from './SupportingMessageCallout';

const Wrapper = styled.div`
  ${GridWrapper} {
    margin: 20px;
  }
  width: 100%;

  @media ${device.tabletMin} {
    ${GridWrapper} {
      margin: 20px 0;
    }
  }

  @media ${device.desktopMin} {
    height: 100%;

    ${GridWrapper} {
      margin: auto;
      width: fit-content;
      max-width: 100%;
    }
  }
`;

interface Props {
  setTouchPositioningView: React.Dispatch<React.SetStateAction<string | null>>;
  touchPositioningView: string | null;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  strategyId: string;
  supportingMessages: AccessStrategyPrioritiseSupportingMessageFragment[];
  permittedToEdit: boolean;
}

export default function AccessStrategyGrid({
  setTouchPositioningView,
  touchPositioningView,
  setSidebarOpen,
  strategyId,
  supportingMessages,
  permittedToEdit,
}: Props) {
  const [
    updateMessage,
  ] = useAccessStrategyPrioritiseSupportingMessageUpdateMutation();
  const isDesktop = useDesktop();
  const [hoveredPin, setHoveredPin] = useState<number>(-1);
  const placedMessages = supportingMessages?.filter((m) => !!m.x);

  // Handle callout card hover states
  const { showingCallout } = useHandleCalloutHover({ hoveredPin });

  async function updateMessagePosition({
    localUid,
    x,
    y,
    closeTouchView,
  }: {
    localUid: string;
    x: number;
    y: number;
    closeTouchView: () => void;
  }) {
    try {
      const messagetoUpdate = supportingMessages.find(
        (m) => m.localUid === localUid
      );

      if (!messagetoUpdate) throw new Error('Something went wrong');

      await updateMessage({
        variables: {
          id: messagetoUpdate.id,
          data: { x, y },
        },
        optimisticResponse: {
          accessStrategyPrioritiseSupportingMessageUpdate: {
            ...messagetoUpdate,
            x,
            y,
            id: messagetoUpdate.id,
            __typename: 'AccessStrategyPrioritiseSupportingMessage',
          },
        },
        update: apolloUpdateHelper({
          responseField: 'accessStrategyPrioritiseSupportingMessageUpdate',
          query: AccessStrategyPrioritiseSupportingMessagesDocument,
          queryVars: messsagesQueryVars(strategyId),
          queryName: 'accessStrategyPrioritiseSupportingMessages',
        }),
      });
    } catch (err) {
      alert('Something went wrong');
    } finally {
      closeTouchView();
    }
  }

  return (
    <Wrapper>
      <DropzoneGrid
        xAxisTitle="Data robustness"
        yAxisTitle="Importance"
        showSubLabels={false}
        setTouchPositioningView={setTouchPositioningView}
        touchPositioningView={touchPositioningView}
        setSidebarOpen={setSidebarOpen}
        gridClickHandler={(
          e: React.MouseEvent<HTMLDivElement, MouseEvent>,
          closeTouchView: () => void
        ) => {
          if (isDesktop || !touchPositioningView || !permittedToEdit) return;

          e.stopPropagation();

          const { clientX: clickXCoord, clientY: clickYCoord } = e;

          const grid = e.currentTarget as HTMLDivElement;
          const {
            height,
            width,
            x: gridX,
            y: gridY,
          } = grid.getBoundingClientRect();
          const xPosition = ((clickXCoord - gridX) / width) * 100;
          const yPosition = ((clickYCoord - gridY) / height) * 100;
          const [x, y] = [Math.round(xPosition), Math.round(yPosition)];

          // Update the tactic with its grid coordinates
          (async () =>
            await updateMessagePosition({
              localUid: touchPositioningView,
              x,
              y,
              closeTouchView,
            }))();
        }}
        gridDropHandler={(
          item: DragObjectWithType,
          monitor: DropTargetMonitor,
          closeTouchView: () => void
        ) => {
          const squareContainer = document.getElementById('square-container');
          if (!squareContainer) return;

          const coords = calculateDropCoordinates(monitor, squareContainer);
          if (!coords) return;

          const { data } = item as DraggableCardType;
          if (
            !('__typename' in data) ||
            data.__typename !== 'AccessStrategyPrioritiseSupportingMessage'
          )
            return;

          // Update the messsage with its grid coordinates
          updateMessagePosition({
            localUid: data.localUid,
            x: coords.x,
            y: coords.y,
            closeTouchView,
          });
        }}
      >
        {placedMessages.map((m) => {
          const onMouseOver = () => setHoveredPin(m.id);
          const onMouseOut = () => setHoveredPin(-1);

          return (
            <DraggablePin
              key={m.id}
              data={m}
              style={{
                top: `calc(${m.y}%)`,
                left: `calc(${m.x}%)`,
              }}
              showCallout={showingCallout === m.id}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              setTouchPositioningView={setTouchPositioningView}
              selected={touchPositioningView === m.localUid}
              removeFromGrid={() =>
                updateMessage({
                  variables: {
                    id: m.id,
                    data: { x: null, y: null },
                  },
                })
              }
              disableDrag={!permittedToEdit}
            >
              <HoverCalloutWrapper
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                pinID={m.id}
              >
                <SupportingMessageCallout
                  data={m}
                  permittedToEdit={permittedToEdit}
                />
              </HoverCalloutWrapper>
            </DraggablePin>
          );
        })}
      </DropzoneGrid>
    </Wrapper>
  );
}
