import React, { useState } from 'react';
import styled from 'styled-components';

import {
  Collaboration,
  Comment as CommentType,
  User,
} from 'data/graphql/generated';
import { BodySmall, CountryFlag, Icon, Subtitle2, Toggle } from './shared';
import { colors } from 'constants/colors';
import { useAuthContext } from 'contexts/AuthContext';
import { device } from 'utils/breakpoints';
import { Comment, CommentRefetchQueryVarsType } from './Comment';
import { CommentForm } from './CommentForm';

const Wrapper = styled.div`
  margin-top: 40px;
`;

const CommentsTitle = styled(Subtitle2)`
  display: block;
  margin-bottom: 12px;

  @media ${device.tabletMin} {
    display: none;
  }
`;

const CommentCreate = styled.div`
  margin-bottom: 10px;
  position: relative;
  padding-left: 35px;
`;

const CountryFlagWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 10px;
`;

const EmptyState = styled.div`
  text-align: center;
  border: 1px solid ${colors.black30};
  pointer-events: none;
  z-index: 0;
  padding: 25px 0px 25px 0px;
  img {
    display: none;
  }

  @media ${device.tabletMin} {
    padding: 45px 0px 45px 0px;
    img {
      display: block;
    }
  }
`;

interface Props {
  collaboration?: Collaboration;
  queryVars: CommentRefetchQueryVarsType;
  className?: string;
}

export const Comments: React.FC<Props> = ({
  collaboration,
  queryVars,
  className,
}) => {
  const [{ user }] = useAuthContext();
  const [showResolved, setShowResolved] = useState(true);

  const collaborationComments =
    collaboration?.comments.filter((c) => {
      return !c?.replyToId;
    }) || [];

  const comments = showResolved
    ? collaborationComments
    : collaborationComments.filter((c) => !c?.resolved);

  return (
    <Wrapper className={className}>
      <CommentsTitle>Comments</CommentsTitle>

      <div
        style={{
          marginBottom: 10,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Toggle
          disabled={false}
          value={showResolved}
          onChange={() => {
            setShowResolved(!showResolved);
          }}
          label="Show resolved comments"
          textAlign="right"
        />
      </div>

      <CommentCreate>
        <CountryFlagWrapper>
          <CountryFlag size="sm" user={(user as unknown) as User} />
        </CountryFlagWrapper>
        {collaboration?.id ? (
          <CommentForm
            collaborationId={collaboration?.id}
            queryVars={queryVars}
          />
        ) : null}
      </CommentCreate>

      <div>
        {comments.map((c) => (
          <Comment
            key={c?.id}
            comment={c as CommentType}
            collaborationId={collaboration?.id}
            queryVars={queryVars}
          />
        ))}

        {comments.length === 0 ? (
          <EmptyState>
            <Icon
              name="CommentEmptyState"
              size={115}
              height={115}
              color="initial"
              style={{ margin: '0 auto' }}
            />
            <Subtitle2 color={colors.greyDark}>Nothing to see here.</Subtitle2>
            <BodySmall color={colors.greyDark}>
              {showResolved
                ? 'All the comments have been resolved'
                : 'Why not add a comment and collaborate'}
            </BodySmall>
          </EmptyState>
        ) : null}
      </div>
    </Wrapper>
  );
};
