import React, { useEffect, useState } from 'react';
import {
  UserDocument,
  UsersDocument,
  UserGeneralUpdateMutation,
  useUsersQuery,
  useUserDeleteMutation,
  useUserGeneralUpdateMutation,
  useProductsQuery,
  Sort,
} from '../data/graphql/generated';
import { Navbar } from '../components/Navbar';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { ButtonPill } from 'components/shared';
import { ModalForm } from 'components/ModalForm';
import FormTextInput from 'components/shared/FormTextInput';
import {
  apolloUpdateHelper,
  apolloDeleteHelper
} from 'utils/apolloQueryHelpers';
import { Table } from 'components/shared/Table';
import { FormDropdown } from 'components/shared/FormDropdown';

const PageWrapper = styled.div`
  padding: 80px 15px 0 15px;

  @media ${device.tabletMax} {
    padding-top: 65px;
  }
`;

export const Users: React.FC = () => {
  const { data: userData, refetch } = useUsersQuery({
    variables: {
      orderBy: {
        id: Sort.Asc
      }
    },
    fetchPolicy: 'network-only',
  });

  const { data: productData } = useProductsQuery({
    fetchPolicy: 'network-only',
  });

  const [updateGeneralUser] = useUserGeneralUpdateMutation({
    update: apolloUpdateHelper({
      responseField: 'usersUpdate',
      query: UsersDocument,
      queryName: 'users',
      queryVars: {},
    }),
  });

  const [deleteUser] = useUserDeleteMutation({
    update: apolloDeleteHelper({
      responseField: 'userDelete',
      query: UserDocument,
      queryName: 'users',
      queryVars: {},
    }),
  });

  const handleDelete = async (id: number) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this user?');
    if (!isConfirmed) {
      return;
    }
    
    await deleteUser({
      variables: { id },
    });

    refetch();
  };

  const [modalState, setModalState] = useState<Pick<
    ModalProps,
    'type' | 'heading'| 'id' | 'name' | "productData"
  > | null>(null);

  const columns = ['ID', 'Name', 'Country', 'Product', 'User Level', 'Actions'];

  const renderRow = (user: any) => (
    <tr key={user.id}>
      <td  style={{paddingLeft: 10}}>{user.id}</td>
      <td style={{margin: 20}}>{user.name}</td>
      <td>{user.country}</td>
      <td>{user.product && user.product.productName}</td>
      <td>{user.role}</td>
      <td style={{display: 'flex', paddingBottom: 10}}>
        <ButtonPill
          iconName="Edit"
          level="secondary"
          text="Edit"
          onClick={() => {
            setModalState({
              type: 'edit',
              heading: 'Update user',
              name: user.name,
              id: user.id,
            });
          }}
        />
        <ButtonPill
        iconName="Trash"
        color="red"
          level="secondary"
          text="Delete"
          onClick={()=>handleDelete(user.id)}
        />
      </td>
    </tr>
  );

  
  return (
    <PageWrapper>
      <Modal
        handleClose={() => {
          setModalState(null);
        }}
        visible={!!modalState}
        heading={modalState?.heading}
        name={modalState?.name}
        id={modalState?.id}
        productData={productData}
        type={modalState?.type}
        onUpdate={async (name: string, productId: number, id: number) => {
            const { data } = await updateGeneralUser({
              variables: { data: { name, productId }, id },
            });
            refetch();

            return data;
        }}
      />
      <Navbar
        disableSecondary
        include={['userLink', 'productLink']}
        exclude={[
          'drugLink',
          'filesLink',
          'strategyLink',
          'notifications',
          'navItems',
          'navContent',
        ]}
      />

      {userData?.users?.items && 
      <Table
        cols={columns}
        rows={renderRow}
        data={userData?.users?.items
          ?.filter((item) => item !== null)
          .map((item) => ({
            ...item,
            id: item?.id,
          })) || []}
        emptyStateText={{
          header: 'No users available',
          subHeading: 'Please add a user to get started.',
        }}
        isDesktop={true} 
      />
      }
    </PageWrapper>
  );
};

interface ModalProps {
  type: 'create' | 'edit' | undefined;
  handleClose(): void;
  visible: boolean;
  heading?: string;
  name?: string;
  productData?: any;
  id?: number;
  onUpdate(
    name: string,
    productId: number,
    id: number
  ): Promise<UserGeneralUpdateMutation | null | undefined>;
}

function Modal({
  handleClose,
  visible,
  heading,
  name,
  id,
  onUpdate,
  type,
  productData
}: ModalProps) {
  const [textValue, setTextValue] = useState(name || '');

  const [productIdData, setProduct] = useState(0);
  
  const [responseError, setResponseError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputTouched, setInputTouched] = useState(false);

  const [isDropdownActive, setIsDropdownActive] = React.useState(false);
  const toggleDropdown = () => {
    setIsDropdownActive(!isDropdownActive);
  };
  const [selectedOption, setSelectedOption] = React.useState<
    { id: string; text: string } | undefined
  >();

  const setSelectedItem = (value: { id: string; text: string }) => {
    setProduct(parseInt(value.id));
    setSelectedOption(value);
  };

  useEffect(() => {
    setTextValue(name || '');
  }, [name]);

  useEffect(() => {
    setResponseError('');

    return () => {
      setInputTouched(false);
      setIsSubmitting(false);
      setTextValue('');
      setResponseError('');
    };
  }, [visible]);

  const items = productData?.products?.items.map((item: any) => ({
    id: item.id,
    text: item.productName
  })) || [{
    id: 0,
    text: "Select Product"
  }];

  return (
    <ModalForm
      handleClose={() => {
        handleClose();
      }}
      visible={visible}
      heading={heading}
    >
      <FormTextInput
        name="name"
        type="text"
        onChange={(e) => {
          setTextValue(e.target.value);
        }}
        onBlur={() => {
          setInputTouched(true);
        }}
        value={textValue}
        title="User name"
        errorMessage={
          responseError ||
          (!textValue && inputTouched ? 'Name cannot be blank.' : '')
        }
      />

      <FormDropdown
        name="Product"
        title="Product"
        onClick={toggleDropdown}
        options={items}
        placeholder={
          false ? 'No Products' : 'Choose Product'
        }
        disabled={false}
        selectedItem={selectedOption}
        dropdownActive={isDropdownActive}
        setSelectedItem={setSelectedItem}
        setDropdownActive={setIsDropdownActive}
      />
     
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 20,
          gap: 15,
        }}
      >
        <ButtonPill
          level="secondary"
          width="180px"
          text="Cancel"
          onClick={() => {
            setResponseError('');
            handleClose();
          }}
        />
        <ButtonPill
          className="cypress-submit"
          disabled={isSubmitting || !textValue}
          type="submit"
          width="180px"
          text={type === 'create' ? 'Create user' : 'Update user'}
          onClick={async () => {
            if (type) {
              setIsSubmitting(true);
              const trimmedTextValue = textValue.trim();
              if (!trimmedTextValue) {
                setResponseError('Name cannot be blank.');
                setTextValue('');
                setIsSubmitting(false);

                return;
              }

              try {
                if (type === 'edit' && typeof id === 'number') {
                  await onUpdate(trimmedTextValue, productIdData, id);
                }
              } catch (error) {

                if (error instanceof Error) {
                  setResponseError(error.message);
                }
                setIsSubmitting(false);
                return;
              }

              setIsSubmitting(false);
              handleClose();
            }
          }}
        />
      </div>
    </ModalForm>
  );
}