import { colors } from 'constants/colors';
import { Stakeholder } from 'data/graphql/generated';

export function getStakeholderColor(stakeholder: Stakeholder): string {
  switch (stakeholder) {
    case Stakeholder.Patient:
      return colors.purple10;
    case Stakeholder.Healthcare:
      return colors.green10;
    case Stakeholder.Payor:
      return colors.blue10;
    case Stakeholder.Provider:
      return colors.pink10;
    default:
      return colors.black05;
  }
}
