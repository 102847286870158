import React from 'react';
import styled from 'styled-components/macro';
import { colors } from 'constants/colors';
import { ButtonRound } from '.';

const StyledDeleteButton = styled(ButtonRound)`
  border-radius: 50%;
  background: ${colors.cream};
`;

interface Props {
  className?: string;
  onClick?: () => void;
  ref?:
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLDivElement>
    | null
    | undefined;
}

export const DeleteIcon: React.FC<Props> = React.forwardRef(
  ({ className, onClick }, ref) => (
    <StyledDeleteButton
      innerRef={ref}
      iconName="Trash"
      level="secondary"
      size="small"
      className={className}
      onClick={onClick}
    />
  )
);
