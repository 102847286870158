import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ModalForm } from 'components/ModalForm';
import { ModalButton, ModalButtonContainer } from 'containers/AdminCompany';
import { 
  useProductsQuery, 
  useDrugProductsCreateMutation,
  useDrugQuery
} from 'data/graphql/generated';
import { CheckCircle } from 'components/shared';

const Wrapper = styled(ModalForm)`
  label[for='email'] {
    margin-top: 0px;
  }

  .CTAWrapper {
    position: relative;
  }
`;

const CheckboxList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const CheckboxListItem = styled.li`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 10px 5px;
  border-top: 1px solid #E8E8E9;

  &:hover {
    background-color: #f0f0f0; // Change this color to whatever you prefer
  }
  &:last-child {
    border-bottom: 1px solid #E8E8E9;
  }
`;

const Label = styled.label`
  font-size: 16px;
  margin-left: 10px;
`;

interface Props {
  handleClose(): void;
  visible: boolean;
  heading: string;
  message: string;
  drugId: number | null;
}

export const AddProductModal = ({
  handleClose,
  visible,
  heading,
  message,
  drugId,
}: Props) => {
  const {
    data: drugData,
  } = useDrugQuery({
    variables: { id: Number(drugId) },
  });
  const [drugProductsCreate] = useDrugProductsCreateMutation();
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);

  const { data: productData } = useProductsQuery({
    fetchPolicy: 'network-only',
  });

  // Extract productIds attached to the drug
  useEffect(()=> {
    if (!drugData?.drug?.ProductOnDrugs) return;
    const productIds = drugData?.drug?.ProductOnDrugs?.map(pod => pod.productId);
    setSelectedProducts(productIds)
  }, [drugData])

  useEffect(() => {
    if (productData?.products?.items) {
      const allProductIds = productData.products.items
      .filter(pod => pod !== null) // Ensure pod is not null
      .map(pod => pod!.id);
      if (selectedProducts.includes(1) && !allProductIds.every(id => selectedProducts.includes(id))) {
        setSelectedProducts(allProductIds);
      }
    }
  }, [productData, selectedProducts]);
    
  if (!visible || !drugId) {
    return null;
  }

  const handleCheckboxChange = (productId: number) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(productId)
        ? prevSelected.filter((id) => id !== productId)
        : [...prevSelected, productId]
    );

    if (productId === 1 && !selectedProducts.includes(1) && productData?.products?.items) {
      const allProductIds = productData.products.items
        .filter(pod => pod !== null) // Ensure pod is not null
        .map(pod => pod!.id)
      setSelectedProducts(allProductIds)
    } else {
      if (productId === 1 ) {
        setSelectedProducts([])
      }
    }
  };

  const saveProduct = async () => {
    try {
      let data = selectedProducts;
      if (selectedProducts.includes(1)) {
        data = [1]
      }
      await drugProductsCreate({
        variables: {
          drugId,
          productIds: data,
        },
      });
    } catch (error) {
      console.error('Error assigning products:', error);
    }

    handleClose();
  };


  return (
    <Wrapper
      handleClose={() => {
        handleClose();
      }}
      visible={visible}
      heading={heading}
      message={message}
    >
      <CheckboxList>
      {productData?.products && productData?.products.items.map((product: any) => {
        const isSelected = selectedProducts.some((p: any) => p=== product.id);
        return (
          <CheckboxListItem
            key={product.id}
            onClick={() => handleCheckboxChange(product.id)}
          >
            
            <CheckCircle complete={isSelected ? true: false} />
            <Label>{product.productName}</Label>
          </CheckboxListItem>
        );
      })}
    </CheckboxList>

      <ModalButtonContainer>
        <ModalButton
          onClick={() => {
            handleClose();
          }}
          text="Cancel"
          level="secondary"
        />
        <ModalButton
          className={'cypress-send-invite'}
          type="submit"
          onClick={saveProduct}
          text={'Save'}
        />
      </ModalButtonContainer>
      
    </Wrapper>
  );
};

