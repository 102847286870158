import { colors } from 'constants/index';
import { Stakeholder } from 'data/graphql/generated';
import { useRef } from 'react';
import styled from 'styled-components';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { DropdownItem } from './DropdownItem';
import { useLocation } from 'react-router-dom';

const DropdownWrapper = styled.div<{ show: boolean }>`
  width: 214px;
  height: 100%;
  border-radius: 5px;
  background: ${colors.cream};
  border: 0.5px solid ${colors.black30};
  padding: 5px 6px 5px 5px;
  display: flex;
  flex-direction: column;
  > * + * {
    margin-top: 5px;
  }
  pointer-events: ${({ show }) => (show ? 'all' : 'none')};
`;

interface Props {
  updateStakeholder(stakeholder: Stakeholder): void;
  show: boolean;
  setShow(v: boolean): void;
  stakeholder: Stakeholder;
  id: string;
}

export const Dropdown = ({
  updateStakeholder,
  stakeholder,
  show,
  setShow,
  id,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const urlContains = location.pathname.includes('1_2') && location.pathname.includes('result');

  useClickOutsideComponent(ref, (event) => {
    show && setShow(false);
  });

  return (
    <DropdownWrapper show={show} ref={ref} className={id}>
      <DropdownItem
        active={stakeholder === Stakeholder.Patient}
        onClick={() => {
          setShow(false);
          updateStakeholder(Stakeholder.Patient);
        }}
        stakeholder={Stakeholder.Patient}
      />
      <DropdownItem
        active={stakeholder === Stakeholder.Healthcare}
        onClick={() => {
          setShow(false);
          updateStakeholder(Stakeholder.Healthcare);
        }}
        stakeholder={Stakeholder.Healthcare}
      />
      <DropdownItem
        active={stakeholder === Stakeholder.Payor}
        onClick={() => {
          setShow(false);
          updateStakeholder(Stakeholder.Payor);
        }}
        stakeholder={Stakeholder.Payor}
      />
      {!urlContains && 
        <>
          <DropdownItem
            active={stakeholder === Stakeholder.Provider}
            onClick={() => {
              setShow(false);
              updateStakeholder(Stakeholder.Provider);
            }}
            stakeholder={Stakeholder.Provider}
          />
          <DropdownItem
            active={stakeholder === Stakeholder.Policymaker}
            onClick={() => {
              setShow(false);
              updateStakeholder(Stakeholder.Policymaker);
            }}
            stakeholder={Stakeholder.Policymaker}
          /> 
        </> 
      }
    </DropdownWrapper>
  );
};
