import { BodySmall } from 'components/shared';
import { colors } from 'constants/colors';
import { CriticalMetricGoalUpdateInput } from 'data/graphql/generated';
import { GoalTargetFieldConstants } from '../../../../shared-util-goals-constants/src';
import { EditableNumberInput } from '../components/EditableNumberInput';

interface BaseGoalTargetFieldProps {
  editEnabled: boolean;
  initialValue?: number | null;
  onBlur: (target: number | null, resetValue?: () => void) => void;
}

const MobileGoalTargetField = ({
  editEnabled,
  initialValue,
  onBlur,
}: BaseGoalTargetFieldProps) => {
  return (
    <>
      <BodySmall color={colors.greyDark}>
        {GoalTargetFieldConstants.FIELD_LABEL}
      </BodySmall>
      <EditableNumberInput
        name={GoalTargetFieldConstants.FIELD_NAME}
        disabled={!editEnabled}
        placeholderText={GoalTargetFieldConstants.PLACEHOLDER_TEXT}
        initialValue={initialValue}
        onBlur={onBlur}
      />
    </>
  );
};

const DesktopGoalTargetField = ({
  editEnabled,
  initialValue,
  onBlur,
}: BaseGoalTargetFieldProps) => {
  return (
    <EditableNumberInput
      name={GoalTargetFieldConstants.FIELD_NAME}
      disabled={!editEnabled}
      placeholderText={GoalTargetFieldConstants.PLACEHOLDER_TEXT}
      initialValue={initialValue}
      onBlur={onBlur}
    />
  );
};

interface GoalTargetFieldProps
  extends Omit<BaseGoalTargetFieldProps, 'onBlur'> {
  isDesktop?: boolean;
  canUpdate: (value: string | number | null) => boolean;
  onUpdate: (data: CriticalMetricGoalUpdateInput) => Promise<void>;
}

export const GoalTargetField = ({
  editEnabled,
  initialValue,
  isDesktop = false,
  canUpdate,
  onUpdate,
}: GoalTargetFieldProps) => {
  const handleOnBlur = (target: number | null, resetValue?: () => void) => {
    if (canUpdate(target)) {
      return onUpdate({ target });
    }

    if (resetValue) {
      return resetValue();
    }
  };

  if (isDesktop) {
    return (
      <DesktopGoalTargetField
        editEnabled={editEnabled}
        initialValue={initialValue}
        onBlur={handleOnBlur}
      />
    );
  }

  return (
    <MobileGoalTargetField
      editEnabled={editEnabled}
      initialValue={initialValue}
      onBlur={handleOnBlur}
    />
  );
};
