import { addMonths, differenceInMonths } from 'date-fns';
import {
  BigIdeaDetailFragment,
  CommercialTacticDetailFragment,
  CompetitiveAdvantageRowFragment,
} from 'data/graphql/generated';
import { StrategicImperatives, Totals } from '../types';

export function getSums(
  competitiveAdvantageRows: CompetitiveAdvantageRowFragment[],
  tactics: CommercialTacticDetailFragment[],
  bigIdeaDetails: BigIdeaDetailFragment[],
  localCountryTacticsEnabled: boolean
) {
  const totals: Totals = {
    total: 0,
    strategicImperatives: {},
    years: {},
  };

  totals.strategicImperatives = competitiveAdvantageRows.reduce(
    (acc: StrategicImperatives, val) => {
      acc[val.id] = { ...val, total: 0, items: [] };
      return acc;
    },
    {}
  );

  const removeCountries = (
    detail: CommercialTacticDetailFragment | BigIdeaDetailFragment
  ): boolean => {
    const country = 'country' in detail ? detail.country : detail.region;

    if (!localCountryTacticsEnabled) {
      return country === 'global';
    }
    return true;
  };

  return [...tactics, ...bigIdeaDetails]
    .filter(removeCountries)
    .reduce((acc, val) => {
      // update total
      acc.total = acc.total + (val?.budget ? +val?.budget : 0);

      if (
        val.competitiveAdvantageRowId &&
        acc.strategicImperatives[val.competitiveAdvantageRowId]
      ) {
        const totalBudget = val?.budget ? +val?.budget : 0;
        // update strategicImperative total
        acc.strategicImperatives[
          val.competitiveAdvantageRowId
        ].total += totalBudget;

        const toDate = new Date(Number(val.to));
        const fromDate = new Date(Number(val.from));

        // update year total
        const totalMonths = differenceInMonths(toDate, fromDate) + 1;
        const monthlyBudget =
          totalMonths === 0 ? totalBudget : totalBudget / totalMonths;

        // Update year totals
        if (totalBudget > 0) {
          // Add month budget to years
          if (val.to && val.from) {
            for (const monthInt of Array.from(
              { length: totalMonths },
              (_, i) => i
            )) {
              const monthDate = addMonths(fromDate, monthInt);
              const year = monthDate.getFullYear();
              acc.years[year] = (acc.years[year] || 0) + monthlyBudget;
            }
          } else if (val.from) {
            // If no toDate then add whole budget to fromDate's year
            const year = fromDate.getFullYear();
            acc.years[year] = (acc.years[year] || 0) + totalBudget;
          }
        }

        const itemsArr =
          acc.strategicImperatives[val.competitiveAdvantageRowId].items;

        const existingInstance = itemsArr.find(
          (item) =>
            item.__typename === val.__typename &&
            item.competitiveAdvantageRowId === val.competitiveAdvantageRowId &&
            ('bigIdea' in val
              ? item.parentId === val.bigIdea.id
              : item.parentId === val.commercialTacticId)
        );

        const country = 'country' in val ? val?.country : val?.region;

        if (existingInstance) {
          existingInstance.budget.push({
            country,
            value: +val.budget || 0,
          });
          existingInstance.totalBudget += +val.budget;
        } else {
          itemsArr.push({
            id: val.id,
            title: val?.text,
            parentId:
              'bigIdea' in val ? val.bigIdea.id : val.commercialTacticId,
            competitiveAdvantageRowId: val.competitiveAdvantageRowId,
            __typename: val.__typename,
            budget: [
              {
                country,
                value: +val.budget || 0,
              },
            ],
            totalBudget: +val.budget,
          });
        }
      }
      return acc;
    }, totals);
}
