import {
  BodySmall,
  ButtonLabel,
  ButtonPill,
  Caption,
  CountryFlag,
  Icon,
  Subtitle2,
} from 'components/shared';
import { colors } from 'constants/index';
import { useAuthContext } from 'contexts/AuthContext';
import {
  Alignment,
  CollaborationFragment,
  useAlignmentCreateMutation,
  useAlignmentDeleteMutation,
  User,
} from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';
import { formatCreatedDate } from 'utils/formatCreatedDate';
import { getUserTitleOrCountryAndName } from 'utils/getUserTitleOrCountryAndName';
import { sortByTime } from 'utils/sortByTime';

const NameDateWrapper = styled.div`
  display: flex;
  align-items: baseline;
  > p:first-child {
    margin-right: 5px;
  }

  > p:last-child {
    @media ${device.tabletMin} {
      white-space: nowrap;
    }
  }
`;

const EmptyState = styled.div`
  border: 1px solid ${colors.black30a};
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  margin-top: 11px;
  > * + * {
    margin-top: 5px;
  }
  @media ${device.tabletMax} {
    padding: 25px 0;
  }
`;

const ShowMoreDropdown = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const AlignedUsersWrapper = styled.div``;

const AlignedUsers = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 15px;
  > * + * {
    margin-top: 8px;
  }

  li {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 25px minmax(auto, max-content) auto;
    justify-items: flex-start;
    align-items: center;
    gap: 5px;

    ${Caption} {
      margin-left: auto;
    }

    @media ${device.tabletMax} {
      grid-auto-columns: 25px minmax(auto, max-content) max-content;
    }
  }

  @media ${device.tabletMax} {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  //Remove color transition from thumbs up
  > .alignment__header__align {
    button {
      * {
        transition: none;
      }
    }
  }
`;

interface Props {
  data: CollaborationFragment['alignments'] | undefined;
  collaborationId: number;
  className?: string;
}

export const Alignments = ({ data, collaborationId, className }: Props) => {
  const strategyId = +window.location.pathname.split('/')[4];

  const [submitting, setSubmitting] = useState(false);

  const [auth] = useAuthContext();

  const user = auth.user;

  const [createAlignment] = useAlignmentCreateMutation();

  const [deleteAlignment] = useAlignmentDeleteMutation();

  const [showAllAlignments, setShowAllAlignments] = useState(false);

  const isDesktop = useDesktop();

  const usersToShow = useCallback(() => {
    const users = [] as JSX.Element[];

    if (!data) {
      return users;
    }

    let idx = 0;
    const copyData = data.slice();
    const sortedData = sortByTime(copyData);

    for (const alignment of sortedData) {
      idx++;
      users.push(
        <li key={alignment?.user.id}>
          <CountryFlag size="sm" user={alignment?.user} disableTooltip />
          <NameDateWrapper>
            <BodySmall>
              {getUserTitleOrCountryAndName(alignment?.user)}
            </BodySmall>
            <Caption color={colors.greyDark}>
              {formatCreatedDate(Number(alignment?.createdAt))}
            </Caption>
          </NameDateWrapper>
        </li>
      );

      if (idx > 2 && !showAllAlignments && !isDesktop) {
        break;
      }
    }

    return users;
  }, [data, isDesktop, showAllAlignments]);

  if (!data) {
    return null;
  }

  const userAlignment = data.find((alignment) => {
    return alignment?.user.id === user?.id;
  });

  return (
    <Wrapper className={className}>
      <Header>
        <Subtitle2 color={colors.black}>Alignment</Subtitle2>
        <ButtonPill
          loading={submitting}
          className="alignment__header__align"
          level={userAlignment ? 'aligned' : 'align'}
          text={userAlignment ? 'Aligned' : 'Align'}
          iconName={userAlignment ? 'Thumbs up Active' : 'Thumbs up'}
          clickClassName="cypress-alignment-create"
          onClick={async () => {
            if (submitting) {
              return;
            }
            setSubmitting(true);
            if (userAlignment) {
              // It's using an optimistic we create on the client so it does not need to await a response
              deleteAlignment({
                variables: { id: userAlignment.id },
                optimisticResponse: {
                  alignmentDelete: {
                    ...userAlignment,
                  },
                },
                update: (cache, { data }) => {
                  cache.modify({
                    id: cache.identify({
                      __ref: `Collaboration:${data?.alignmentDelete.collaborationId}`,
                    }),
                    fields: {
                      alignmentCount(alignment) {
                        return alignment === 0 ? 0 : alignment - 1;
                      },
                      alignments(
                        alignments: Pick<
                          Alignment,
                          'id' | 'createdAt' | 'collaborationId'
                        >[],
                        { readField }
                      ) {
                        return alignments.filter((alignment) => {
                          const alignUser = readField(
                            'user',
                            alignment
                          ) as User;

                          return alignUser?.id === userAlignment.id;
                        });
                      },
                    },
                  });
                },
              });
              setSubmitting(false);
            } else {
              await createAlignment({
                variables: {
                  data: { collaborationId, strategyId },
                },
                update: (cache, { data }) => {
                  cache.modify({
                    id: cache.identify({
                      __ref: `Collaboration:${data?.alignmentCreate.collaborationId}`,
                    }),
                    fields: {
                      alignmentCount(alignment) {
                        return alignment + 1;
                      },
                      alignments(
                        alignments: Pick<
                          Alignment,
                          'id' | 'createdAt' | 'collaborationId'
                        >[]
                      ) {
                        return [...alignments, data?.alignmentCreate];
                      },
                    },
                  });
                },
              });

              setSubmitting(false);
            }
          }}
        />
      </Header>
      <AlignedUsersWrapper>
        {data.length < 1 ? (
          <EmptyState>
            {isDesktop ? (
              <Icon
                name="AlignEmptyState"
                size={115}
                height={115}
                color="initial"
              />
            ) : null}
            <Subtitle2 color={colors.greyDark}>No-one aligned yet</Subtitle2>
            <BodySmall color={colors.greyDark}>Why not be the first?</BodySmall>
          </EmptyState>
        ) : (
          <AlignedUsers>
            {usersToShow()}
            {data.length > 3 && !showAllAlignments && !isDesktop ? (
              <ShowMoreDropdown
                onClick={() => {
                  setShowAllAlignments(true);
                }}
              >
                <Icon name="Chevron-down" size={30} color={colors.greyDark} />
                <ButtonLabel color={colors.greyDark}>
                  Show {data.length - 3} more
                </ButtonLabel>
              </ShowMoreDropdown>
            ) : null}
          </AlignedUsers>
        )}
      </AlignedUsersWrapper>
    </Wrapper>
  );
};
