import React, { useEffect, useState } from 'react';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { ErrorModal } from 'components/ErrorModal';
import {
  usePatientJourneyQuery,
  Step,
  PatientJourneyState,
} from 'data/graphql/generated';
import { ErrorWrapper } from '../components/ErrorLoadingComponent';
import { polling } from 'constants/index';
import { Stage } from 'konva/types/Stage';
import {
  ActionTypeEnum,
  PatientJourneyContainerType,
  PatientJourneyPageURLParams,
} from 'components/PatientJourney/src/lib/types';
import { PatientJourneyContainer } from 'components/PatientJourney';
import { exportAsSvg } from 'components/PatientJourney/src/lib/utils';
import { ButtonPill } from 'components/shared';
import buildStrategyURL from 'utils/buildStrategyURL';
import { Navbar } from 'components/Navbar';
import { Observations } from 'components/Observations';
import {
  PatientJourneySecondNav,
  Processing,
} from 'components/PatientJourney/src';
import useDesktop from 'hooks/useDesktop';
import BlockSidebar from 'components/PatientJourney/src/lib/ui/BlockSidebar';
import { useKeyboardShortcuts } from 'components/PatientJourney/src/lib/hooks';
import ModeInfoModal from 'components/PatientJourney/src/lib/components/ModeInfoModal';
import { isTouchDevice } from 'utils/isTouchDevice';
import { useAuthContext } from 'contexts/AuthContext';
import usePatientJourney from 'hooks/usePatientJourney';
import { getErrorMessage } from 'utils/getErrorMessage';
import { usePatientJourneyDimensions } from 'hooks/usePatientJourneyDimensions';
import styled from 'styled-components';
import { useSetPatientJourneyURL } from 'utils/URLHelper/useSetPatientJourneyURL';

const PageWrapper = styled.div<{ $loading: boolean }>`
  // makes loading state visible
  padding-top: ${({ $loading }) => $loading && '100px'};
`;

const PatientJourneyLoadData = () => {
  const defaultPageState = PatientJourneyState.Current;
  const highLevelStageRef = React.useRef<null | Stage>(null);
  const isDesktop = useDesktop();

  const { canvasWidth, canvasHeight } = usePatientJourneyDimensions();

  const {
    drugId,
    strategyId,
    state,
  } = useParams<PatientJourneyPageURLParams>();

  const { path } = useRouteMatch();
  const history = useHistory();

  const [actionType, setActionType] = useState<
    PatientJourneyContainerType['actionType']
  >(isDesktop ? ActionTypeEnum.INTERACTIVE : ActionTypeEnum.DRAG);

  useKeyboardShortcuts({ setActionType });

  const isTouch = isTouchDevice();
  const [{ user }] = useAuthContext();

  const canShowModeInfoModal = () => {
    if (isTouch) return false;

    return !user?.patientJourneyModeModalDismissed;
  };
  const [deleteErrorModal, setDeleteErrorModal] = useState(false);
  const [stakeholderErrorModal, setStakeholderErrorModal] = useState(false);
  const [columnErrorModal, setColumnErrorModal] = useState(false);
  const [modeInfoModalVisible, setModeInfoModalVisible] = useState(false);
  const pageState =
    (state?.toUpperCase() as PatientJourneyState) || defaultPageState;

  const patientJourneyQueryVars = {
    strategyId: Number(strategyId),
    pageState,
  };

  const {
    data,
    loading,
    startPolling,
    stopPolling,
    error,
  } = usePatientJourneyQuery({
    variables: patientJourneyQueryVars,
    fetchPolicy: 'cache-and-network',
  });

  const setURl = useSetPatientJourneyURL();

  useEffect(() => {
    const urlPageState = pageState.toLowerCase() as Lowercase<PatientJourneyState>;

    if (!state) {
      // default url
      setURl({
        vars: {
          state: urlPageState,
        },
        method: 'replace',
      });
    }
  }, [pageState, setURl, state]);

  const {
    block: { createBlock, updateBlock, removeBlock },
    column: { updateColumn, columnTotalWidth, columnStartingPoints },
    connection: { createConnection, updateConnection, removeConnection },
    disableActions,
    setDisableActions,
    updateMany,
  } = usePatientJourney({
    patientJourneyQueryVars,
    data,
    pageState,
    onDeleteBlockError: (err) => {
      if (getErrorMessage(err) === 'DELETE_ERROR') {
        setDeleteErrorModal(true);
      }
    },
    onUpdateBlockError: (err) => {
      if (getErrorMessage(err) === 'STAKEHOLDER_ERROR') {
        setStakeholderErrorModal(true);
      }
      if (getErrorMessage(err) === 'COLUMN_ERROR') {
        setColumnErrorModal(true);
      }
    },
  });

  useEffect(() => {
    startPolling(polling.default);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  return (
    <PageWrapper $loading={loading}>
      <Navbar
        next={{
          title: 'Key trends Analysis',
          url: buildStrategyURL(drugId, strategyId, '1_4'),
        }}
        prev={{
          title: 'Competitive Landscape',
          url: buildStrategyURL(drugId, strategyId, '1_2'),
        }}
        stepNumber="1.3"
        title="Patient Journey"
        url={`/d/${drugId}/strategy/${strategyId}?nav=1`}
        navMenuChildren={
          <ButtonPill
            iconName="Export"
            text={'Export Patient Journey'}
            level="export"
            onClick={exportAsSvg(
              highLevelStageRef,
              columnTotalWidth,
              canvasWidth,
              canvasHeight,
              'Patient Journey'
            )}
          />
        }
      >
        <Observations step={Step.Patientjourney} />

        <PatientJourneySecondNav
          actionType={actionType}
          setActionType={setActionType}
          showModeInfoModal={() => {
            setModeInfoModalVisible(canShowModeInfoModal());
          }}
          stageRef={highLevelStageRef}
          columnTotalWidth={columnTotalWidth}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          pageState={pageState}
          setPageState={(state) => {
            const generatedPath = generatePath(path, {
              drugId,
              strategyId,
              state: state.toLowerCase(),
            });
            history.replace(generatedPath);
          }}
        />
      </Navbar>

      <ErrorWrapper isLoading={loading} errors={[error]} dataMissing={!data}>
        <ErrorModal
          visible={deleteErrorModal}
          handleClose={() => setDeleteErrorModal(false)}
          title="Cannot delete this block"
          text="Content in later steps of the strategy depends on the stakeholders in this block. Remove content and try again."
        />
        <ErrorModal
          visible={stakeholderErrorModal}
          handleClose={() => setStakeholderErrorModal(false)}
          title="Cannot remove this stakeholder"
          text="Content in later steps of the strategy depends on this stakeholder. Remove content and try again."
        />
        <ErrorModal
          visible={columnErrorModal}
          handleClose={() => setColumnErrorModal(false)}
          title="Cannot move this action to another stage"
          text="Content in later steps of the strategy depends on the stakeholders in this block at this stage. Remove content and try again."
        />

        <ModeInfoModal
          visible={modeInfoModalVisible}
          setVisible={setModeInfoModalVisible}
        />

        {disableActions ? <Processing /> : null}
        <PatientJourneyContainer
          highLevelStageRef={highLevelStageRef}
          strategyId={+strategyId}
          drugId={+drugId}
          dbShapes={data?.patientJourneyBlocks?.items}
          createBlock={createBlock}
          updateBlock={updateBlock}
          removeBlock={removeBlock}
          dbConnections={data?.patientJourneyConnections?.items}
          createConnection={createConnection}
          updateConnection={updateConnection}
          removeConnection={removeConnection}
          dbColumns={data?.patientJourneyColumns?.items}
          updateColumn={updateColumn}
          updateMany={updateMany}
          setDisableActions={setDisableActions}
          actionType={actionType}
          columnTotalWidth={columnTotalWidth}
          columnStartingPoints={columnStartingPoints}
        />

        <BlockSidebar
          onUpdate={updateBlock}
          blocks={data?.patientJourneyBlocks?.items}
          pageState={pageState}
        />
      </ErrorWrapper>
    </PageWrapper>
  );
};

export default PatientJourneyLoadData;
