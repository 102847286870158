import {
  blockKeyStakeholdersColors,
  blockKeyStakeholdersDisplayNames,
  blockKeyStakeholdersIconNames,
} from 'components/PatientJourney/constants';
import { BodySmall, ButtonLabel } from 'components/shared';
import { colors } from 'constants/index';
import { PatientJourneyState } from 'data/graphql/generated';
import React from 'react';
import styled from 'styled-components/macro';
import { KeyStakeholdersProps } from '../types';
import { SectionContainer } from './SectionContainer';
import Slider from './Slider';
import StakeholderRow from './StakeholderRow';
import { StakeholderIcon } from './StakeholderRow.style';

const StyledSlider = styled(Slider)`
  margin-top: 10px;
`;

const ReadOnlyStakeholderRow = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  > * {
    cursor: default;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const stakeholders = Object.entries(blockKeyStakeholdersDisplayNames);

const KeyStakeholders = ({
  starred,
  onClick,
  slider,
  readOnly = false,
  pageState,
}: KeyStakeholdersProps) => {
  const noneStarred = Object.values(starred).every((v) => v === false);
  if (readOnly) {
    return (
      <SectionContainer>
        <ListWrapper>
          <BodySmall color={colors.greyDark}>Key stakeholders</BodySmall>

          {noneStarred ? (
            <BodySmall>None identified yet</BodySmall>
          ) : (
            stakeholders.map(([_key, displayText]) => {
              const key = _key as keyof typeof blockKeyStakeholdersDisplayNames;

              if (!starred[key]) {
                return null;
              }

              return (
                <ReadOnlyStakeholderRow key={key}>
                  <StakeholderIcon
                    name={blockKeyStakeholdersIconNames[key]}
                    size={30}
                    color={blockKeyStakeholdersColors[key].stroke}
                  />
                  <ButtonLabel color={colors.greyDark}>
                    {displayText}
                  </ButtonLabel>
                </ReadOnlyStakeholderRow>
              );
            })
          )}
        </ListWrapper>
        {pageState === PatientJourneyState.Desired && !noneStarred && (
          <StyledSlider
            onMouseUp={slider.onUpdate}
            value={slider.addressingIssueRating}
            readOnly
          />
        )}
      </SectionContainer>
    );
  }

  return (
    <SectionContainer>
      <ListWrapper>
        <BodySmall color={colors.greyDark}>Key stakeholders</BodySmall>
        {stakeholders.map(([_key, displayText]) => {
          const key = _key as keyof typeof blockKeyStakeholdersDisplayNames;
          return (
            <StakeholderRow
              key={key}
              $key={key}
              starred={starred}
              onClick={onClick}
              displayText={displayText}
            />
          );
        })}
      </ListWrapper>
      {pageState === PatientJourneyState.Desired && !noneStarred && (
        <StyledSlider
          onMouseUp={slider.onUpdate}
          value={slider.addressingIssueRating}
        />
      )}
    </SectionContainer>
  );
};
export default KeyStakeholders;
