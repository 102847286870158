import { FetchResult } from '@apollo/client';
import {
  PatientJourneyBlockFragment,
  PatientJourneyBlockInput,
  PatientJourneyColumnFragment,
  PatientJourneyConnection,
  PatientJourneyConnectionInput,
  PatientJourneyConnectionFragment,
  usePatientJourneyBlockCreateMutation,
  usePatientJourneyConnectionCreateMutation,
  PatientJourneyColumn,
  PatientJourneyBlockUpdateInput,
  PatientJourneyBlockUpdateMutation,
  PatientJourneyState,
} from 'data/graphql/generated';
import { NonNull } from 'types';

type BlockType = Pick<
  PatientJourneyBlockFragment,
  'id' | 'x' | 'y' | 'height' | 'columnId'
> &
  Partial<Pick<PatientJourneyBlockFragment, 'text'>>;

export enum ActionTypeEnum {
  INTERACTIVE,
  DRAG,
}
export interface SharedPatientJourneyTypes {
  createBlock: (
    block: Omit<PatientJourneyBlockInput, 'strategy' | 'drug' | 'pageState'>
  ) => ReturnType<ReturnType<typeof usePatientJourneyBlockCreateMutation>[0]>;

  updateBlock: (
    block: PatientJourneyBlockFragment,
    updateData: NonNull<PatientJourneyBlockUpdateInput>
  ) => Promise<
    | FetchResult<
        PatientJourneyBlockUpdateMutation,
        Record<string, any>,
        Record<string, any>
      >
    | undefined
  >;

  removeBlock: (blockId: number) => Promise<void>;

  createConnection: (
    value: Omit<PatientJourneyConnectionInput, 'strategy'>
  ) => ReturnType<
    ReturnType<typeof usePatientJourneyConnectionCreateMutation>[0]
  >;
  updateConnection: (value: Partial<PatientJourneyConnection>) => Promise<void>;
  removeConnection: (value: number) => Promise<void>;

  updateMany: ({
    blocks,
    columns,
    connections,
  }: {
    blocks: BlockType[];
    columns: PatientJourneyColumnFragment[];
    connections: PatientJourneyConnectionFragment[];
  }) => Promise<void>;

  updateColumn(connection: Partial<PatientJourneyColumn>): void;

  newConnection: Partial<
    Pick<
      PatientJourneyConnection,
      'fromBlockId' | 'toSide' | 'toBlockId' | 'fromSide'
    >
  > & { path: number[] };

  updateBlockLocal: (
    value: Partial<PatientJourneyBlockFragment>
  ) => Promise<void>;
  actionType: ActionTypeEnum;
  columnTotalWidth: number;
  columnStartingPoints: number[];
  canvasWidth: number;
  canvasHeight: number;
  windowWidth: number;
  inRecapSidebar?: boolean;
  handleBlockIconClick?(blockId: number): void;
}

export interface PatientJourneyPageURLParams {
  drugId: string;
  strategyId: string;
  state: Lowercase<PatientJourneyState>;
}
