import { colors } from 'constants/index';
import { Text as TextType } from 'konva/types/shapes/Text';
import { useEffect, useRef, useState } from 'react';
import { Rect, Group, Text } from 'react-konva';

export const Tooltip: React.FC<{
  text: string;
  width?: number;
  visible?: boolean;
  y?: number;
  x?: number;
}> = ({ text, width, visible = false, y = -30, x }) => {
  const textRef = useRef<null | TextType>(null);

  const [textWidth, setTextWidth] = useState(width || 0);
  const xOffset = x ?? -(textWidth / 4);

  useEffect(() => {
    const textContainer = textRef.current;
    if (!textContainer) {
      return;
    }
    const size = textContainer.measureSize(text);
    setTextWidth(size.width);
  }, [text]);

  return (
    <Group x={xOffset} y={y} visible={visible}>
      <Rect
        fill={colors.black}
        opacity={0.9}
        width={textWidth + 14}
        height={25}
        cornerRadius={5}
      />
      <Text
        ref={textRef}
        x={7}
        y={7}
        text={text}
        fontFamily="ABCFavorit"
        fontSize={12}
        fontWeight={800}
        wrap="char"
        align="center"
        fill="#ffffff"
      />
    </Group>
  );
};
