import styled from 'styled-components/macro';
import { colors } from 'constants/colors';
import { Size } from '../types/Size';
import { getSize } from '../utils/getSize';

interface FlagIconProps {
  brandlead: boolean;
  size: Size;
  imageName: string;
}

export const FlagIcon = styled.div.attrs<FlagIconProps>(
  ({ brandlead, imageName }) => ({
    style: {
      backgroundImage: `url("${
        brandlead ? '/icons/LeadGlobe.svg' : imageName
      }")`,
      backgroundRepeat: 'no-repeat',
    },
  })
)<FlagIconProps>`
  border-radius: 50%;
  background-size: cover;
  width: ${({ size }) => getSize(size)}px;
  height: ${({ size }) => getSize(size)}px;
  border: 1px solid
    ${({ brandlead }) => (brandlead ? 'transparent' : colors.greyLight)};
`;
