import { CriticalMetricGoalTrackingFragment } from 'data/graphql/generated';
import { CountryConstants } from 'types';
import { groupByPeriod } from './groupByPeriod';

export const filterGlobalTrackingData = (
  data: CriticalMetricGoalTrackingFragment[]
) => {
  const globalData = data.filter(
    (item) => item.region === CountryConstants.GLOBAL
  );

  const trackings = groupByPeriod(globalData);

  return trackings;
};
