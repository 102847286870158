import { getCompetitiveAdvantageRowUrlParam } from './getCompetitiveAdvantageRowUrlParam';
import { getStrategyUrl } from './getStrategyUrl';

export const getCriticalMetricsTrackingUrl = (
  drugId: string,
  strategyId: string,
  competitiveAdvantageRowId?: string | number
) => {
  const baseUrl = getStrategyUrl(drugId, strategyId);
  const competitiveAdvantageRowUrlParam = getCompetitiveAdvantageRowUrlParam(
    competitiveAdvantageRowId
  );

  return `${baseUrl}/3_5/tracking${competitiveAdvantageRowUrlParam}`;
};
