import { PostItsEmpty } from 'components/PostItsEmpty';
import { BodySmall, ButtonPill, Subtitle2, Table } from 'components/shared';
import { TableDivHeaders } from 'components/shared/Table';
import { colors } from 'constants/index';
import {
  LocalDataType,
  PreviewAndDBDataSolutionType,
} from 'containers/RiskMitigationIdeas';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { BarRating } from './BarRating';
import { columnGridStyle, SolutionRow } from './SolutionRow';
import { Divider } from 'components/MedicalStrategy/MedicalTactics';
import {
  SolutionFragment,
  SuccessConditionFragment,
  SuccessConditionFragmentDoc,
  useSolutionDeleteMutation,
  useSuccessConditionUpdateMutation,
  useSolutionUpsertMutation,
} from 'data/graphql/generated';
import { uid } from 'uid';
import _ from 'lodash';

const StyledButtonPill = styled(ButtonPill)<{
  noSuccessConditions: boolean;
  sidebarOpen: boolean;
}>`
  button {
    background: transparent;
    padding: 0px;
    justify-content: start;
    border-radius: 5px;
    padding-right: 5px;
    width: auto;
    &:hover {
      background: ${colors.black05a};
    }
    p {
      margin-left: 10px;
    }
  }
  ${({ noSuccessConditions, sidebarOpen }) =>
    noSuccessConditions
      ? css``
      : css`
          width: 100%;
          display: flex;
          flex-direction: row;
          max-width: 944px;
          margin: 0 auto -5px auto;
          @media ${device.tabletMax} {
            max-width: 451px;
          }
          ${sidebarOpen
            ? ``
            : css`
                position: relative;
                @media (min-width: 1414px) {
                  height: 0px;
                  button {
                    position: absolute;
                    top: 5px;
                    left: -220px;
                  }
                }
              `}
        `}
`;

const RatingWrapper = styled.div`
  margin-top: 20px;
`;

const Title = styled.div``;

const Header = styled.div`
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  @media ${device.desktopMin} {
    margin-bottom: 30px;
  }
  @media ${device.tabletMax} {
    flex-direction: column;
    padding: 0;
  }
`;

const TablesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const StyledTable = styled(Table)`
  max-width: 944px;
  margin: 0 auto;
  ${TableDivHeaders} {
    ${columnGridStyle}
  }
  .Table__EmptyState {
    height: 72px;
  }
  @media ${device.tabletMax} {
    max-width: 451px;
    padding: 15px;
    .Table__Mobile__EmptyState {
      margin-top: 20px;
    }
  }
` as typeof Table;

interface Props {
  noSuccessConditions: boolean;
  riskGroups: LocalDataType;
  isDesktop: boolean;
  tempSolution: PreviewAndDBDataSolutionType | undefined;
  isLead: boolean;
  strategicImperativeId?: number;
  currency?: string;
  strategyId: string;
  noCompetitiveAdvantageRows: boolean;
  setTempSolution: React.Dispatch<
    React.SetStateAction<PreviewAndDBDataSolutionType | undefined>
  >;
  deleteSolution: ReturnType<typeof useSolutionDeleteMutation>[0];
  updateSuccessCondition: ReturnType<
    typeof useSuccessConditionUpdateMutation
  >[0];
  upsertSolution: ReturnType<typeof useSolutionUpsertMutation>[0];
  setAddingSolution: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  addingSolution: boolean;
  addPreview: (
    localUid: string,
    successConditionId: number,
    strategyId: number,
    competitiveAdvantageRowId: number
  ) => void;
  sidebarOpen: boolean;
}

export function RiskTables({
  setTempSolution,
  currency,
  noCompetitiveAdvantageRows,
  strategyId,
  strategicImperativeId,
  noSuccessConditions,
  riskGroups,
  isDesktop,
  updateSuccessCondition,
  tempSolution,
  isLead,
  deleteSolution,
  upsertSolution,
  setAddingSolution,
  addingSolution,
  addPreview,
  setShowAddModal,
  sidebarOpen,
}: Props) {
  if (!strategicImperativeId) return null;
  return (
    <TablesWrapper>
      {!noCompetitiveAdvantageRows && isLead && (
        <StyledButtonPill
          clickClassName="cypress-risk-mitigation-choose-risk-consider"
          iconName="Edit"
          text="Choose risks to consider"
          level="secondary"
          noSuccessConditions={noSuccessConditions}
          sidebarOpen={sidebarOpen}
          onClick={() => {
            setShowAddModal(true);
          }}
        />
      )}
      {noSuccessConditions ? (
        <PostItsEmpty
          title="No risks to mitigate for this strategic imperative"
          subtitle="Leads should shortlist drivers and barriers that introduce risk, then the team can add solutions to mitigate these risks."
        />
      ) : (
        riskGroups?.map((successCondition) => {
          const solutions = _.orderBy(
            successCondition.solutions,
            ['createdAt'],
            ['asc']
          );
          return (
            <StyledTable
              data={solutions}
              useDiv
              key={successCondition.id}
              keyField={'localUid'}
              header={
                <Header>
                  <Title>
                    <BodySmall color={colors.greyDark}>Risk</BodySmall>
                    <Subtitle2>{successCondition.text}</Subtitle2>
                  </Title>
                  <RatingWrapper>
                    <BarRating
                      readOnly={!isLead}
                      rating={successCondition.impactRating}
                      onChange={(rating) => {
                        updateSuccessCondition({
                          variables: {
                            id: successCondition.id,
                            data: { impactRating: rating },
                          },
                        });
                      }}
                    />
                  </RatingWrapper>
                </Header>
              }
              cols={['Solutions', 'Timing']}
              rows={(data, idx) => {
                const localUid = data.localUid;

                if (!localUid) return null;

                const id = data.id || Math.ceil(Math.random() * 20000);

                return (
                  <>
                    {!idx && !isDesktop ? <Divider /> : null}

                    <SolutionRow
                      autoFocus={tempSolution?.localUid === localUid}
                      data={data}
                      isDesktop={isDesktop}
                      isLead={isLead}
                      currency={currency || '¥ CNY'}
                      onDelete={async () => {
                        // Checks if it's in the cache (meaning it has been created on the DB)
                        if (!data.__typename) {
                          setTempSolution(undefined);
                        } else {
                          try {
                            await deleteSolution({
                              variables: { localUid },
                              optimisticResponse: {
                                solutionDelete: {
                                  ...data,
                                  id,
                                },
                              },
                              // THis needs to update success conditions query, so the typical query helpers cannot be used
                              update: (cache, { data }) => {
                                const cachedSuccessCondition = cache.readFragment(
                                  {
                                    id: `SuccessCondition:${successCondition.id}`,
                                    fragment: SuccessConditionFragmentDoc,
                                    fragmentName: 'successCondition',
                                  }
                                ) as SuccessConditionFragment & {
                                  solutions: SolutionFragment[];
                                };

                                const filteredSolutions = cachedSuccessCondition.solutions.filter(
                                  (solution) => solution.localUid !== localUid
                                );

                                cache.writeFragment({
                                  id: `SuccessCondition:${successCondition.id}`,
                                  fragment: SuccessConditionFragmentDoc,
                                  fragmentName: 'successCondition',
                                  data: {
                                    ...cachedSuccessCondition,
                                    solutions: filteredSolutions,
                                  },
                                });
                              },
                            });
                          } catch (error) {
                            console.error(error);
                            alert('Something went wrong');
                          }
                        }
                      }}
                      onUpdate={async (updatedData) => {
                        try {
                          await upsertSolution({
                            variables: {
                              data: {
                                competitiveAdvantageRow: +strategicImperativeId,
                                localUid,
                                strategyId: +strategyId,
                                successCondition: successCondition.id,
                                ...updatedData,
                              },
                            },
                            optimisticResponse: {
                              solutionUpsert: {
                                __typename: 'Solution',
                                ...data,
                                ...updatedData,
                                focusRationale: data.focusRationale || '',
                                collaboration: data?.collaboration || null,
                                id,
                              },
                            },
                            // THis needs to update success conditions query, so the typical query helpers cannot be used
                            update: (cache, { data }) => {
                              if (!data?.solutionUpsert.__typename) return;
                              const cachedSuccessCondition = cache.readFragment(
                                {
                                  id: `SuccessCondition:${successCondition.id}`,
                                  fragment: SuccessConditionFragmentDoc,
                                  fragmentName: 'successCondition',
                                }
                              ) as SuccessConditionFragment & {
                                solutions: SolutionFragment[];
                              };

                              const solutionExists = cachedSuccessCondition.solutions.findIndex(
                                (solution) => solution.localUid === localUid
                              );
                              if (solutionExists > -1) {
                                const solutions = cachedSuccessCondition.solutions.slice();
                                if (data?.solutionUpsert) {
                                  solutions[solutionExists] =
                                    data.solutionUpsert;

                                  cache.writeFragment({
                                    id: `SuccessCondition:${successCondition.id}`,
                                    fragment: SuccessConditionFragmentDoc,
                                    fragmentName: 'successCondition',
                                    data: {
                                      ...cachedSuccessCondition,
                                      solutions,
                                    },
                                  });
                                }
                              } else {
                                if (data?.solutionUpsert)
                                  cache.writeFragment({
                                    id: `SuccessCondition:${successCondition.id}`,
                                    fragment: SuccessConditionFragmentDoc,
                                    fragmentName: 'successCondition',
                                    data: {
                                      ...cachedSuccessCondition,
                                      solutions: [
                                        ...cachedSuccessCondition.solutions,
                                        data?.solutionUpsert,
                                      ],
                                    },
                                  });
                              }
                            },
                          });
                        } catch (error) {
                          console.error(error);
                          alert('Something went wrong');
                        }
                      }}
                    />
                  </>
                );
              }}
              emptyStateText={{
                header: 'No solutions yet',
              }}
              isDesktop={isDesktop}
              addButtonText="Add solution"
              addRow={async () => {
                setAddingSolution(true);
                const localUid = uid();

                addPreview(
                  localUid,
                  successCondition.id,
                  +strategyId,
                  +strategicImperativeId
                );
                setAddingSolution(false);
              }}
              isAddingRow={addingSolution}
            />
          );
        })
      )}
    </TablesWrapper>
  );
}
