import { Card } from 'components/CompetitiveAdvantage/Summary/Card';
import {
  BodySmall,
  ButtonLabel,
  Icon,
  Subtitle1,
  Subtitle2,
} from 'components/shared';
import { GradientBorderCard } from 'components/shared/GradientCard';
import {
  summaryBottomScrollBar,
  textScrollbar,
} from 'components/Strategy/scrollbar';
import { colors } from 'constants/colors';
import {
  KeyStatementFragment,
  StrategicQuestionFragment,
  SubStep,
  useStrategyUpdateMutation,
} from 'data/graphql/generated';
import { useWidth } from 'hooks/useWidth';
import { startCase } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { PropositionModal } from './PropositionModal';

const ScrollColumnsContainer = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  height: max-content;
  max-height: 100%;
`;

const TextContainer = styled.div`
  overflow: auto;
  ${textScrollbar}
`;

const CardContainer = styled.div<{ centreText?: boolean }>`
  width: 100%;
  height: 100%;
  max-width: 640px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: ${({ centreText }) => centreText && 'center'};

  > div {
    display: ${({ centreText }) => centreText && 'unset !important'};
  }
`;

const ScrollColumns = styled.section<{
  offsetTop: number;
}>`
  display: flex;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  padding: 0 20px;
  padding-bottom: 5px;
  height: ${({ offsetTop }) => `calc(100vh - ${offsetTop + 10}px)`};

  ${summaryBottomScrollBar}
`;

const TopSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 20px;
`;

const MessagesList = styled.ul`
  padding-inline-start: 25px;
  margin: 0;
`;

type Props = {
  strategicQuestions: StrategicQuestionFragment[] | undefined;
  keyStatements: KeyStatementFragment[] | undefined;
  isLead: boolean;
  strategyId: string;
  strategyCoreValueProposition: string;
};

export const Proposition = ({
  strategicQuestions,
  keyStatements,
  isLead,
  strategyId,
  strategyCoreValueProposition,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [strategyUpdate] = useStrategyUpdateMutation();
  const [savingProposition, setSavingProposition] = useState(false);
  const [coreValueProposition, setCoreValueProposition] = useState(
    strategyCoreValueProposition
  );
  const scrollContainer = useRef<HTMLDivElement>(null);

  const windowWidth = useWidth();

  useEffect(() => {
    setCoreValueProposition(strategyCoreValueProposition);
  }, [strategyCoreValueProposition]);

  const [sectionOffsetTop, setSectionOffsetTop] = useState(0);

  useEffect(() => {
    if (scrollContainer?.current) {
      setSectionOffsetTop(scrollContainer?.current?.offsetTop);
    }
  }, [windowWidth]);

  const validStrategicQuestions = strategicQuestions?.reduce((acc, curr) => {
    switch (curr.field) {
      case SubStep.DiseaseBurdenAndUnmetNeed:
        acc[0] = curr;
        return acc;
      case SubStep.ClinicalValue:
        acc[1] = curr;
        return acc;
      case SubStep.HumanisticValue:
        acc[2] = curr;
        return acc;
      case SubStep.EconomicValue:
        acc[3] = curr;
        return acc;
      default:
        return acc;
    }
  }, [] as StrategicQuestionFragment[]);

  const dbunText = validStrategicQuestions?.[0]?.text;

  return (
    <>
      {modalOpen && (
        <PropositionModal
          showModal={modalOpen}
          handleClose={() => setModalOpen(false)}
          loading={savingProposition}
          handleSubmit={async (val) => {
            setSavingProposition(true);

            try {
              await strategyUpdate({
                variables: {
                  id: +strategyId,
                  data: {
                    coreValueProposition: val,
                  },
                },
              });
            } catch (error) {
              alert('something went wrong');
            }
            setCoreValueProposition(val);
            setSavingProposition(false);

            setModalOpen(false);
          }}
          textValue={strategyCoreValueProposition}
        />
      )}
      <div style={{ width: '100%' }}>
        <TopSection>
          <GradientBorderCard
            style={{ width: '100%', maxHeight: '20vh', display: 'flex' }}
          >
            <CardContainer centreText={!dbunText}>
              {!!dbunText ? (
                <>
                  <BodySmall color={colors.greyDark}>
                    Disease Burden and Unmet Need
                  </BodySmall>
                  <TextContainer>
                    <Subtitle2>{dbunText}</Subtitle2>
                  </TextContainer>
                </>
              ) : (
                <Subtitle2 color={colors.greyDark}>
                  Disease Burden and Unmet Need not defined yet
                </Subtitle2>
              )}
            </CardContainer>
          </GradientBorderCard>

          <GradientBorderCard
            style={{ width: '100%', maxHeight: '20vh', display: 'flex' }}
          >
            <CardContainer centreText={!coreValueProposition}>
              {!!coreValueProposition ? (
                <>
                  <div style={{ display: 'flex', gap: 5 }}>
                    <BodySmall color={colors.greyDark}>
                      Core Value Proposition
                    </BodySmall>
                    {!!(isLead && coreValueProposition) && (
                      <Icon
                        style={{ cursor: 'pointer' }}
                        size={20}
                        name="Pencil"
                        color={colors.black}
                        onClick={() => {
                          setModalOpen(true);
                        }}
                      />
                    )}
                  </div>

                  <TextContainer>
                    <Subtitle2>{coreValueProposition}</Subtitle2>
                  </TextContainer>
                </>
              ) : (
                <>
                  <Subtitle2 color={colors.greyDark}>
                    Core Value Proposition not defined yet
                  </Subtitle2>
                  {isLead && <ButtonLabel
                    onClick={() => {
                      setModalOpen(true);
                    }}
                    color={colors.purple}
                  >
                    Add Core Value Proposition
                  </ButtonLabel>}
                </>
              )}
            </CardContainer>
          </GradientBorderCard>
        </TopSection>
        {!!validStrategicQuestions && (
          <>
            <Subtitle1
              style={{ marginBottom: 15, marginTop: 30, textAlign: 'center' }}
            >
              Value Platform
            </Subtitle1>

            <ScrollColumns ref={scrollContainer} offsetTop={sectionOffsetTop}>
              <ScrollColumnsContainer>
                {validStrategicQuestions.map((strategicQuestion) => {
                  if (
                    strategicQuestion.field ===
                    SubStep.DiseaseBurdenAndUnmetNeed
                  ) {
                    return null;
                  }
                  const title = startCase(strategicQuestion.field).replace(
                    / And /g,
                    ' and '
                  );

                  const messages = keyStatements?.filter((keyStatement) => {
                    return keyStatement.subStep === strategicQuestion.field;
                  });

                  return (
                    <Card
                      key={strategicQuestion.field}
                      style={{
                        flex: 1,
                        minWidth: 300,
                        flexDirection: 'column',
                        gap: 15,
                        padding: 15,
                        justifyContent: 'flex-start',
                        overflow: 'auto',
                        maxHeight: '100%',
                      }}
                    >
                      <div>
                        <BodySmall color={colors.greyDark}>{title}</BodySmall>
                        <Subtitle2>
                          {strategicQuestion.text || 'Not defined yet'}
                        </Subtitle2>
                      </div>
                      <div>
                        <BodySmall color={colors.greyDark}>
                          Supporting messages
                        </BodySmall>
                        {!!messages?.length ? (
                          <MessagesList>
                            {messages?.map((message, idx) => {
                              return (
                                <li key={idx}>
                                  <Subtitle2>{message.text}</Subtitle2>
                                </li>
                              );
                            })}
                          </MessagesList>
                        ) : (
                          <Subtitle2>Not defined yet</Subtitle2>
                        )}
                      </div>
                    </Card>
                  );
                })}
              </ScrollColumnsContainer>
            </ScrollColumns>
          </>
        )}
      </div>
    </>
  );
};
