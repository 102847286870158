import React from 'react';
import styled from 'styled-components/macro';
import { ButtonRound } from 'components/shared';

const StyledDeleteButton = styled(ButtonRound)<{ show: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  opacity: ${({ show }) => Number(show)};
  pointer-events: ${({ show }) => (show ? 'all' : 'none')};
`;

type Props = {
  onClick(): void;
  isVisible: boolean;
  deleteButtonRef: React.RefObject<HTMLDivElement>;
};

export const DeleteButton = ({
  onClick,
  isVisible,
  deleteButtonRef,
}: Props) => {
  return (
    <StyledDeleteButton
      className="cypress-observation-delete"
      innerRef={deleteButtonRef}
      iconName="Trash"
      level="secondary"
      size="small"
      tooltip=""
      onClick={onClick}
      show={isVisible}
    />
  );
};
