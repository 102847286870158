import {
  BodyNormal,
  ButtonPill,
  ModalWithDetail,
  Subtitle1,
} from 'components/shared';
import { ErrorContainer } from 'components/shared/FormField';
import FormTextInput, {
  ModalTextAreaInput,
} from 'components/shared/FormTextInput';
import { colors } from 'constants/index';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';

const ModalContent = styled.div`
  width: 100%;
  align-self: center;
  position: relative;
`;

const CTAWrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;
  width: 100%;
`;

const FormWrapper = styled.div`
  position: relative;
  width: 100%;
  textarea {
    width: 100%;
  }
`;

export const StyledFormTextInput = styled(FormTextInput)`
  display: block;
  margin-top: 30px;

  > ${ModalTextAreaInput} {
    padding-right: 45px;
    max-height: 90px;
  }

  ${ErrorContainer} {
    margin-top: 0px;
  }
`;

const ProgressBars = styled.ul`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 20px;

  width: 100%;
`;

const ProgressBar = styled.div<{ highlighted: boolean }>`
  height: 4px;
  background: ${({ highlighted }) =>
    highlighted ? colors.purple : colors.greyLight};
  transition: background 200ms ease;
`;

const SliderWindow = styled.div`
  width: 100%;
  position: relative;

  z-index: 1;
  transition: height 200ms ease;
`;

const SlidingContent = styled.div<{
  slide: number;
}>`
  width: 200%;
  position: absolute;
  left: 0;

  display: grid;
  grid-template-columns: 1fr 1fr;

  transition: all 200ms ease;
  transform: translateX(${({ slide }) => `-${50 * slide}%`});
`;

const Slide = styled.div<{ visible: boolean }>`
  width: 100%;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 200ms ease;
  height: fit-content;
`;

interface Props {
  modalSlideIdx: number | null;
  setModalSlideIdx?:
    | React.Dispatch<React.SetStateAction<number | null>>
    | ((val: number | null) => void);
  handleClose(): void;
  handleSubmit(rationale: string): Promise<any> | void;
  rationaleText: string;
  modalDetails?: Record<string, string>;
  showSingleSlide?: boolean;
  firstSlideContent?: React.ReactNode;
  disableNext?: boolean;
  className?: string;
  DetailComponent?: React.ReactNode;
}

export const RationaleModal = ({
  modalSlideIdx,
  setModalSlideIdx,
  handleClose,
  handleSubmit,
  modalDetails,
  rationaleText,
  showSingleSlide = true,
  firstSlideContent,
  disableNext = false,
  className,
  DetailComponent,
}: Props) => {
  const [rationaleTextState, setRationaleTextState] = useState(rationaleText);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const slide = modalSlideIdx;
  const slidingContentRef = useRef<HTMLDivElement | null>(null);
  const sliderWindowRef = useRef<HTMLDivElement | null>(null);

  // Set height of SlidingContent to enable smooth transitions between slide heights
  useEffect(() => {
    const incomingSlide = document.getElementById(`rationale-slide-${slide}`);
    const slidingContent = slidingContentRef.current;
    const slidingWindow = sliderWindowRef.current;
    if (!incomingSlide || !slidingContent || !slidingWindow) return;

    const incomingHeight = incomingSlide.offsetHeight;
    slidingContent.style.height = `${incomingHeight}px`;
    slidingWindow.style.height = `${incomingHeight}px`;
  }, [slide]);

  useEffect(() => {
    return () => {
      setErrorMessage('');
    };
  }, []);

  function closeModal() {
    setRationaleTextState(rationaleText);
    handleClose();
  }

  useEffect(() => {
    setRationaleTextState(rationaleText);
  }, [rationaleText]);

  return ReactDOM.createPortal(
    <ModalWithDetail
      visible={modalSlideIdx !== null}
      handleClose={closeModal}
      DetailComponent={DetailComponent}
      data={modalDetails}
      className={`${className} focus-rationale-modal cypress-focus-rationale-modal`}
    >
      <ModalContent>
        {!showSingleSlide && (
          <ProgressBars>
            <ProgressBar highlighted={slide === 0} />
            <ProgressBar highlighted={slide === 1} />
          </ProgressBars>
        )}

        <SliderWindow className="rationale__sliderWindow" ref={sliderWindowRef}>
          <SlidingContent slide={modalSlideIdx || 0} ref={slidingContentRef}>
            {/* Slide Zero (optional) */}
            <Slide visible={slide === 0} id={`rationale-slide-0`}>
              {firstSlideContent}
            </Slide>

            {/* Slide Two - focus rationale */}
            <Slide visible={slide === 1} id={`rationale-slide-1`}>
              <Subtitle1
                style={{
                  textAlign: showSingleSlide ? 'center' : 'left',
                  marginBottom: 10,
                }}
              >
                {!showSingleSlide && '2. '}Provide the rationale
              </Subtitle1>
              <BodyNormal
                style={{
                  textAlign: showSingleSlide ? 'center' : 'left',
                }}
              >
                Describe the reason to focus here.
              </BodyNormal>
              <FormWrapper>
                <StyledFormTextInput
                  className="cypress-rationale-modal-textarea"
                  errorMessage={errorMessage}
                  textArea
                  name="rationale"
                  type="text"
                  onChange={(e) => {
                    setRationaleTextState(e.target.value);
                  }}
                  value={rationaleTextState}
                  title={'Rationale (optional)'}
                />
              </FormWrapper>
            </Slide>
          </SlidingContent>
        </SliderWindow>

        <CTAWrapper>
          <ButtonPill
            level="secondary"
            width="180px"
            text={slide === 0 || showSingleSlide ? 'Cancel' : 'Back'}
            onClick={() => {
              if (slide === 0 || showSingleSlide) {
                closeModal();
              } else {
                modalSlideIdx &&
                  setModalSlideIdx &&
                  setModalSlideIdx(modalSlideIdx - 1);
              }
            }}
          />
          <ButtonPill
            loading={isSaving}
            disabled={disableNext || isSaving}
            type="submit"
            width="180px"
            text={!showSingleSlide && slide === 0 ? 'Next' : 'Save'}
            clickClassName="cypress-rationale-save"
            onClick={async () => {
              if (!showSingleSlide && slide === 0) {
                setModalSlideIdx && setModalSlideIdx(slide + 1);
              } else {
                setErrorMessage('');
                setIsSaving(true);
                try {
                  await handleSubmit(rationaleTextState);
                } catch (error: any) {
                  console.error(error);
                  setErrorMessage(error.message);
                }
                setIsSaving(false);
                handleClose();
              }
            }}
          />
        </CTAWrapper>
      </ModalContent>
    </ModalWithDetail>,
    document.body
  );
};
