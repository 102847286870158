import { SubStep } from 'data/graphql/generated';
import { getCriticalMetricsGoalUrl } from './getCriticalMetricsGoalUrl';
import { getCriticalMetricsLeadAndLagIndicatorsUrl } from './getCriticalMetricsLeadAndLagIndicatorsUrl';
import { getCriticalMetricsTrackingUrl } from './getCriticalMetricsTrackingUrl';

export const getCriticalMetricsUrlBySubStep = (
  subStep: SubStep,
  {
    drugId,
    strategyId,
    competitiveAdvantageRowId,
  }: {
    drugId: string;
    strategyId: string;
    competitiveAdvantageRowId?: string | number;
  }
) => {
  switch (subStep) {
    case SubStep.Goals:
      return getCriticalMetricsGoalUrl(
        drugId,
        strategyId,
        competitiveAdvantageRowId
      );
    case SubStep.Tracking:
      return getCriticalMetricsTrackingUrl(
        drugId,
        strategyId,
        competitiveAdvantageRowId
      );
    case SubStep.Indicators:
    default:
      return getCriticalMetricsLeadAndLagIndicatorsUrl(
        drugId,
        strategyId,
        competitiveAdvantageRowId
      );
  }
};
