export function sortByTime<T extends ({ createdAt: string } | null)[]>(
  list: T
) {
  return list.sort((a, b) => {
    if (a?.createdAt && b?.createdAt) {
      const aTime = Number(a.createdAt);
      const bTime = Number(b.createdAt);
      return aTime > bTime ? -1 : aTime < bTime ? 1 : 0;
    }
    return 0;
  });
}
