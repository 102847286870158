import { ModalLarge } from 'components/ModalLarge';
import {
  BodySmall,
  ButtonLabel,
  ButtonPill,
  Caption,
  Icon,
  PatientDefinitionImage,
  Subtitle1,
  Subtitle2,
  Tooltip,
} from 'components/shared';
import { colors } from 'constants/index';
import {
  LeveragePointFragment,
  PatientJourneyBlockFragment,
  StrategicPossibilityUpdateInput,
  User,
} from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import useMobile from 'hooks/useMobile';
import Konva from 'konva';
import { Stage } from 'konva/types/Stage';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { clamp } from 'utils/clamp';
import { LeveragePointTable } from './LeveragePointTable';

const StageNavButton = styled.button`
  // css resets
  border: none;
  background: transparent;
  padding: 0;

  display: flex;
  align-items: center;
  margin-left: -5px;
  width: fit-content;
  padding-right: 5px;
  border-radius: 5px;
  cursor: pointer;

  :hover {
    background: ${colors.black05a};
  }
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 40px 15px;
`;

const ModalPatientDefinitionWrapper = styled.div`
  padding-bottom: 10px;
  max-width: 346px;
  word-break: break-word;
  :not(:last-child) {
    border-bottom: 1px solid ${colors.greyLight};
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  border: 1px solid ${colors.black10a};
  border-radius: 5px;
  padding: 5px 15px;
`;

const ModalTitles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: center;
`;

const Wrapper = styled.div`
  padding: 15px;
  background: ${colors.white};
  max-width: 451px;
  margin: auto;
  width: 100%;

  @media ${device.tabletMin} {
    border-radius: 5px;
    border: 1px solid ${colors.black10};
  }

  @media ${device.desktopMin} {
    max-width: 1400px;
  }
`;

const LeveragePointHeader = styled.div`
  display: flex;

  justify-content: space-between;
  gap: 15px;
  padding-bottom: 15px;

  @media ${device.tabletMax} {
    flex-direction: column;
    gap: 5px;
  }

  @media ${device.desktopMin} {
    margin-left: 15px;
    margin-top: 15px;
  }
`;
const LeveragePointHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  max-width: 750px;
`;

interface Props {
  createStrategicPossibility(
    leveragePoint: LeveragePointFragment
  ): Promise<void>;
  updateStrategicPossibility(
    id: number,
    data: StrategicPossibilityUpdateInput
  ): Promise<void>;
  deleteStrategicPossibility(id: number): Promise<void>;
  leveragePoint: LeveragePointFragment;
  user?: User;
  stakeholderDefinitionId: number;
  patientJourneyBlocks: PatientJourneyBlockFragment[];
  sidebarStageRef: React.MutableRefObject<Stage | null>;
  setOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}
export const LeveragePoint: React.FC<Props> = ({
  createStrategicPossibility,
  updateStrategicPossibility,
  deleteStrategicPossibility,
  leveragePoint,
  user,
  stakeholderDefinitionId,
  patientJourneyBlocks,
  sidebarStageRef,
  setOpenSidebar,
}) => {
  const isDesktop = useDesktop();
  const isMobile = useMobile();
  const [addingStrategicPossibility, setAddingStrategicPossibility] = useState(
    false
  );
  const [autoFocus, setAutoFocus] = useState(false);
  const [showPatientDefinitionModal, setShowPatientDefinitionModal] = useState(
    false
  );

  const animateStage = (sidebarOpen: boolean) => {
    const stageId = `column-${leveragePoint.stageId}`;
    const stageBgId = `column-${leveragePoint.stageId}-bg`;

    if (sidebarStageRef?.current) {
      const allStageBGs = sidebarStageRef.current.find('.column-bg');
      const stage = sidebarStageRef.current.find(`#${stageId}`);
      const stageBg = sidebarStageRef.current.find(`#${stageBgId}`);

      const totalColumnWidths = Array.from(allStageBGs).reduce((acc, curr) => {
        return acc + curr.attrs.width;
      }, 0);

      const animate = () => {
        if (!sidebarStageRef?.current) {
          return;
        }

        // Animate BG color
        stageBg[0].to({
          fill: colors.purple20,
          duration: 0.2,
          easing: Konva.Easings['EaseOut'],
          onFinish: () => {
            stageBg[0].to({
              fill: colors.grey03,
              duration: 1,
              easing: Konva.Easings['EaseOut'],
            });
          },
        });

        const sidebarWidth = 555;

        const clampedXPos = clamp(
          -stage[0].attrs.x,
          -totalColumnWidths + sidebarWidth,
          2
        );

        // Move stage BG
        sidebarStageRef.current.to({
          x: clampedXPos,
          y: 2,
          duration: 0.3,
          easing: Konva.Easings['EaseOut'],
        });
      };

      const animateAfterThreeMilliseconds = () =>
        setTimeout(() => {
          animate();
        }, 300);

      if (!sidebarOpen) {
        // initial state
        sidebarStageRef.current.to({
          x: 2,
          y: 2,
          duration: 0,
        });

        // wait for sidebar to open then animate
        animateAfterThreeMilliseconds();
      } else {
        animate();
      }
    }
  };

  return (
    <Wrapper>
      <Tooltip
        id={`leveragePoint${leveragePoint.blockId}`}
        effect="float"
        place="right"
        multiline
      />

      <ModalLarge
        size={'small'}
        headerColor={isMobile ? colors.black05 : colors.cream}
        handleClose={() => {
          setShowPatientDefinitionModal(false);
        }}
        visible={showPatientDefinitionModal}
      >
        <ModalContentWrapper>
          <ModalTitles>
            <Subtitle1>Patient Definitions</Subtitle1>
            <Caption color={colors.greyDark}>
              Patient definitions at this leverage point
            </Caption>
          </ModalTitles>
          <ModalContent>
            {leveragePoint.stakeholderDefinitions.map((patientDefinition) => (
              <ModalPatientDefinitionWrapper key={patientDefinition.id}>
                <div
                  style={{
                    display: 'flex',
                    gap: 10,
                    minHeight: 40,
                    alignItems: 'center',
                    marginTop: 10,
                    textAlign: 'left',
                  }}
                >
                  <PatientDefinitionImage imageURL={patientDefinition.image} />
                  <BodySmall>{patientDefinition.title}</BodySmall>
                </div>
              </ModalPatientDefinitionWrapper>
            ))}
          </ModalContent>
        </ModalContentWrapper>
      </ModalLarge>

      <LeveragePointHeader>
        <LeveragePointHeaderText data-cy="Leverage-Point-Header-Text">
          <StageNavButton
            onClick={() => {
              setOpenSidebar((state) => {
                animateStage(state);
                return true;
              });
            }}
          >
            <Icon
              name="Stage"
              size={30}
              style={{ width: 30, height: 30 }}
              color={colors.purple}
            />
            <ButtonLabel color={colors.purple}>
              {leveragePoint?.stage}
            </ButtonLabel>
          </StageNavButton>
          <Subtitle2>{leveragePoint?.title}</Subtitle2>
        </LeveragePointHeaderText>
        <div
          style={{
            display: 'flex',
            gap: 5,
            height: 'fit-content',
          }}
        >
          {leveragePoint.stakeholderDefinitions.map((patientDefinition) => {
            return (
              <div
                key={patientDefinition.id}
                data-for={`leveragePoint${leveragePoint.blockId}`}
                data-tip={patientDefinition.title}
                onClick={() => {
                  if (!isDesktop) setShowPatientDefinitionModal(true);
                }}
              >
                <PatientDefinitionImage imageURL={patientDefinition.image} />
              </div>
            );
          })}
        </div>
      </LeveragePointHeader>
      <LeveragePointTable
        updateStrategicPossibility={updateStrategicPossibility}
        deleteStrategicPossibility={deleteStrategicPossibility}
        leveragePoint={leveragePoint}
        user={user}
        autoFocus={autoFocus}
        setAutoFocus={setAutoFocus}
      />

      <ButtonPill
        loading={addingStrategicPossibility}
        iconName="Plus"
        clickClassName="cypress-possibility-add"
        text="Add strategic possibility"
        level="secondary"
        onClick={async () => {
          setAddingStrategicPossibility(true);
          setAutoFocus(true);
          try {
            await createStrategicPossibility(leveragePoint);
          } catch (error) {
            return setAddingStrategicPossibility(false);
          }
          setAddingStrategicPossibility(false);
        }}
      />
    </Wrapper>
  );
};
