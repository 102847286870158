import { css } from 'styled-components';
import { device } from '../../utils/breakpoints';

export const columnSettings = css`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 160px 140px;
  padding: 0 15px;

  @media ${device.tabletMin} {
    grid-template-columns: 236px 140px;
  }

  @media ${device.desktopMin} {
    grid-template-columns: 478px 85px;
  }
`;
