import { colors } from 'constants/colors';
import {
  Stakeholder,
  StakeholderDefinitionFragment,
} from 'data/graphql/generated';
import styled, { css } from 'styled-components/macro';
import { BodyNormal, BodySmall, ButtonLabel } from './TextStyles';
import { Icon, IconContainer } from './Icon';
import { useHistory } from 'react-router';
import { useEffect } from 'react';

interface Props {
  stakeholderDefinitions: StakeholderDefinitionFragment[];
  selectedStakeholderDefinition?: StakeholderDefinitionFragment;
  refetchStakeholderDefinitions?: ()=> void;
  stakeholder: Stakeholder;
  drugId: string;
  strategyId: string;
  competitorId?: string;
  result?: boolean
  showEvaluation: boolean;
  page: '1_2' | '2_3';
  onClick?(s: StakeholderDefinitionFragment): void;
  viewSummary(): void;
  hideStakeholderDefinitionsEmptyText?: boolean;
}

export const Wrapper = styled.div`
  position: relative;
`;

const ImagesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 7px;
  align-items: center;

  ${IconContainer} {
    height: 40px;
    width: 40px;
  }
`;

const ImageBg = styled.div<{ selected: boolean }>`
  border-radius: 50%;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 9px;

  &:hover {
    cursor: pointer;
  }

  ${({ selected }) =>
    selected
      ? css`
          background-image: linear-gradient(
            134deg,
            ${colors.purple},
            ${colors.purplePink},
            ${colors.pink}
          );
        `
      : css`
          :hover {
            background-image: linear-gradient(
              134deg,
              ${colors.purple30},
              ${colors.purplePink30},
              ${colors.pink30}
            );
          }
        `}
`;

const BlankBg = styled.div`
  background: #edf2fd;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RoundedImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

const TextContainer = styled.div``;

export const StakeholderDefinitions = ({
  stakeholderDefinitions,
  selectedStakeholderDefinition,
  stakeholder,
  refetchStakeholderDefinitions,
  drugId,
  strategyId,
  competitorId,
  result,
  showEvaluation,
  page,
  onClick,
  viewSummary,
  hideStakeholderDefinitionsEmptyText,
}: Props) => {
  const history = useHistory();
  function handleClick(s: StakeholderDefinitionFragment) {
    if (onClick) {
      onClick(s);
    } else

      if (result) {
        history.push(
          `/d/${drugId}/strategy/${strategyId}/${page}/${stakeholder}/result/${s.id}`
        );
      } else {
        history.push(
          `/d/${drugId}/strategy/${strategyId}/${page}/${stakeholder}/${s.id}/${
            competitorId ? competitorId : ''
          }`
        );
      }
  }

  useEffect(() => {
    if (refetchStakeholderDefinitions) {
      refetchStakeholderDefinitions();
    }
    if (!selectedStakeholderDefinition && !hideStakeholderDefinitionsEmptyText && stakeholderDefinitions.length && refetchStakeholderDefinitions) {
      setTimeout(() => {
        history.push(
          `/d/${drugId}/strategy/${strategyId}/${page}/${stakeholder}/${stakeholderDefinitions[0].id}`
        );
      }, 300)
    }
  }, [selectedStakeholderDefinition, history, drugId, strategyId, page, stakeholder, hideStakeholderDefinitionsEmptyText,
    refetchStakeholderDefinitions, stakeholderDefinitions
  ]) 

  return (
    <Wrapper className="stakeholderDefinitions">
      <ImagesContainer>
        {stakeholderDefinitions.length ? (
          stakeholderDefinitions.map((s) => (
            <ImageBg
              data-cy={`cypress-stakeholder-definition-image__${s.title}`}
              key={s.id}
              selected={s.id === selectedStakeholderDefinition?.id}
              onClick={() => handleClick(s)}
            >
              <BlankBg>
                {s.image ? (
                  <RoundedImage src={s.image} alt={s.title} />
                ) : (
                  <Icon
                    name="Patient"
                    size={30}
                    color={colors.purple}
                    style={{
                      background: colors.purple10,
                      borderRadius: '50%',
                    }}
                  />
                )}
              </BlankBg>
            </ImageBg>
          ))
        ) : (
          <Icon
            name="Patient"
            size={30}
            color={colors.black}
            style={{
              background: colors.greyLight,
              borderRadius: '50%',
              marginRight: '9px',
              marginBottom: '9px',
            }}
          />
        )}
      </ImagesContainer>

      <TextContainer>
        {selectedStakeholderDefinition?.title && stakeholderDefinitions.length ? (
          <>
            {showEvaluation ? (
              <>
                <BodyNormal
                  color={colors.greyDark}
                  style={{ display: 'inline', marginRight: '5px' }}
                >
                  Target Population:
                </BodyNormal>
                <BodyNormal style={{ display: 'inline', marginRight: '5px' }}>
                  {selectedStakeholderDefinition?.title ||
                    'Undefined stakeholder'}
                </BodyNormal>
              </>
            ) : (
              <>
                <BodyNormal
                  color={colors.greyDark}
                  style={{ display: 'inline', marginRight: '5px' }}
                >
                  Target Population:
                </BodyNormal>

                <BodyNormal style={{ display: 'inline', marginRight: '5px' }}>
                  {selectedStakeholderDefinition?.title ||
                    'Undefined stakeholder'}
                </BodyNormal>
              </>
            )}
            {/* <ButtonLabel
              color={colors.purple}
              style={{ display: 'inline-block' }}
              onClick={() => viewSummary()}
            >
              View summary
            </ButtonLabel> */}
          </>
        ) : !hideStakeholderDefinitionsEmptyText ? (
          <>
            <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
              No stakeholder definitions. Add some in{' '}
            </BodySmall>
            <ButtonLabel
              onClick={() =>
                history.push(
                  `/d/${drugId}/strategy/${strategyId}/1_1/${stakeholder}`
                )
              }
              style={{ display: 'inline', cursor: 'pointer' }}
              color={colors.greyDark}
            >
              Key Stakeholders
            </ButtonLabel>
            <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
              {' '}
              for consideration.
            </BodySmall>
          </>
        ) : null}
      </TextContainer>
    </Wrapper>
  );
};
