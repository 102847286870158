import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { PatientJourneyContainer } from 'components/PatientJourney';
import { ActionTypeEnum } from 'components/PatientJourney/src/lib/types';
import { BodySmall, Icon } from 'components/shared';
import { CollapseIcon } from 'components/shared/ImperativeSummarySidebar';
import { ReusableLargeSidebar } from 'components/shared/ReusableLargeSidebar';
import { colors, polling } from 'constants/index';
import { useToastContext } from 'contexts/ToastContext';
import {
  PatientJourneyState,
  usePatientJourneyQuery,
} from 'data/graphql/generated';
import usePatientJourney from 'hooks/usePatientJourney';
import { Stage as KonvaStage } from 'konva/types/Stage';
import React, { ComponentProps, useCallback, useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { getPatientJourneyUrl } from 'utils/URLHelper/getPatientJourneyUrl';
import { EmptyState } from '../..';
import {
  StyledSidebar,
  Content,
  Header,
  PJourneyWrapper,
} from './PatientJourneyRecapSidebar.style';

interface Props extends ComponentProps<typeof ReusableLargeSidebar> {
  onClose(): void;
  sidebarStageRef: React.MutableRefObject<KonvaStage | null>;
}

export const PatientJourneyRecapSidebar = ({
  isOpen,
  onClose,
  sidebarStageRef,
}: Props) => {
  const { drugId, strategyId } = useParams<{
    drugId: string;
    strategyId: string;
  }>();

  const patientJourneyQueryVars = {
    strategyId: Number(strategyId),
    pageState: PatientJourneyState.Desired,
  };

  const {
    data,
    loading,
    startPolling,
    stopPolling,
    error,
  } = usePatientJourneyQuery({
    variables: patientJourneyQueryVars,
  });

  useEffect(() => {
    startPolling(polling.default);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const {
    block: { createBlock, updateBlock, removeBlock },
    column: { updateColumn, columnTotalWidth, columnStartingPoints },
    connection: { createConnection, updateConnection, removeConnection },
    setDisableActions,
    updateMany,
  } = usePatientJourney({
    patientJourneyQueryVars,
    data,
    pageState: PatientJourneyState.Desired,
  });

  const noBlocks = !loading && !data?.patientJourneyBlocks?.total;

  const [createToast, removeToast] = useToastContext();

  const history = useHistory();
  let { url } = useRouteMatch();

  const patientJourneyUrl = getPatientJourneyUrl(drugId, strategyId);
  const handleBlockIconClick = useCallback(
    (blockId: number) => {
      const { id: toastId, setCTAClick } = createToast(
        'Return to 2.4 Strategic Possibilities',
        'Return'
      );

      history.push({
        pathname: `${patientJourneyUrl}`,
        search: `sidebarBlockId=${blockId}`,
      });

      setCTAClick(() => {
        removeToast(toastId);

        history.push({
          pathname: `${url}`,
        });
      });
    },
    [createToast, history, patientJourneyUrl, removeToast, url]
  );

  return (
    <StyledSidebar className="patient-journey-sidebar" isOpen={isOpen}>
      <Content data-cy="patient-journey-recap-sidebar">
        <Header>
          <Icon
            name="Stage"
            size={30}
            style={{ width: 30, height: 30 }}
            color={colors.purple}
          />
          <BodySmall>Global Patient Journey - Desired State</BodySmall>

          <CollapseIcon
            onClick={onClose}
            name="Collapse right"
            size={30}
            color={colors.purple}
            style={{ marginLeft: 'auto' }}
          />
        </Header>

        <PJourneyWrapper>
          <ErrorWrapper
            isLoading={loading}
            errors={[error]}
            dataMissing={!data}
          >
            {noBlocks ? (
              <EmptyState drugId={drugId} strategyId={strategyId} />
            ) : (
              <PatientJourneyContainer
                highLevelStageRef={sidebarStageRef}
                isPreview
                inRecapSidebar
                strategyId={+strategyId}
                drugId={+drugId}
                dbShapes={data?.patientJourneyBlocks?.items}
                createBlock={createBlock}
                updateBlock={updateBlock}
                removeBlock={removeBlock}
                dbConnections={data?.patientJourneyConnections?.items}
                createConnection={createConnection}
                updateConnection={updateConnection}
                removeConnection={removeConnection}
                dbColumns={data?.patientJourneyColumns?.items}
                updateColumn={updateColumn}
                updateMany={updateMany}
                setDisableActions={setDisableActions}
                actionType={ActionTypeEnum.DRAG}
                columnTotalWidth={columnTotalWidth}
                columnStartingPoints={columnStartingPoints}
                handleBlockIconClick={handleBlockIconClick}
              />
            )}
          </ErrorWrapper>
        </PJourneyWrapper>
      </Content>
    </StyledSidebar>
  );
};
