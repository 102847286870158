import { PatientJourneyBlockFragment } from 'data/graphql/generated';
import { useEffect, useState, useRef, useCallback } from 'react';
import { Layer, Stage } from 'react-konva';
import { Stage as StageType } from 'konva/types/Stage';

import { BlockCard } from './BlockCard';
import {
  CollaborationContext,
  useCollaborationContext,
} from 'contexts/CollaborationContext';
import { changeCursor } from '../utils';
import { noop } from 'utils/noop';
import {
  PatientJourneyPageContext,
  usePatientJourneyPageContext,
} from 'contexts';

type Props = {
  block: PatientJourneyBlockFragment;
  onUpdate?(value: string): Promise<void>;
  readOnly?: boolean;
  isCollaborationPreview?: boolean;
};

export const BlockCardPreview = ({
  block,
  onUpdate,
  readOnly,
  isCollaborationPreview = false,
}: Props) => {
  const pageContextData = usePatientJourneyPageContext();

  const collaborationContextData = useCollaborationContext();

  const stageRef = useRef<StageType | null>(null);
  const [dimensions, setDimensions] = useState({ width: 300, height: 200 });
  const [showTextArea, setShowTextArea] = useState<number[]>([]);

  const handleChangeCursor = useCallback(
    (type) =>
      changeCursor({
        type,
        isDrag: false,
        isPreview: false,
        newConnectionLength: 0,
        stageRef,
      }),
    []
  );

  useEffect(() => {
    let isMounted = true;

    if (isMounted && stageRef.current) {
      const width = stageRef?.current?.attrs.container.offsetWidth;
      const height = stageRef?.current?.attrs.container.offsetHeight;
      setDimensions({ width, height });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Stage
      ref={stageRef}
      width={dimensions.width}
      height={block.height + 25 || 160}
    >
      <PatientJourneyPageContext.Provider value={pageContextData}>
        <CollaborationContext.Provider value={collaborationContextData}>
          <Layer>
            <BlockCard
              block={{
                ...block,
                x: (dimensions.width - 160) / 2,
                y: (dimensions.height - block.height) / 2,
              }}
              isDrag={false}
              forceEdit={!!showTextArea.length}
              setEditing={setShowTextArea}
              hideStepIcon={true}
              columnTotalWidth={0}
              updateMany={async ({ blocks }) => {
                const text = blocks[0].text;
                if (text) {
                  onUpdate?.(text);
                }
              }}
              setBlockBeingDeleted={noop}
              updateBlockLocal={noop}
              updateConnectionLocal={noop}
              changeCursor={handleChangeCursor}
              stageRef={stageRef}
              removeBlock={noop}
              showDotsForBlock={noop}
              hideDotsForBlock={noop}
              connections={[]}
              getColumnIdxForX={noop}
              columns={[]}
              setActiveColumn={noop}
              adjustFromScreenX={function (x: number): number {
                return 0;
              }}
              adjustFromScreenY={function (y: number): number {
                return 0;
              }}
              canEdit={!readOnly}
              isCollaborationPreview={isCollaborationPreview}
            />
          </Layer>
        </CollaborationContext.Provider>
      </PatientJourneyPageContext.Provider>
    </Stage>
  );
};
