import { SubStep } from 'data/graphql/generated';
import { generatePath, useHistory } from 'react-router-dom';

export const navigateToPico = (
  history: ReturnType<typeof useHistory>
) => (urlParams: {
  drugId: string;
  strategyId: string;
  originalPicoId?: number | undefined;
}) => {
  const path = generatePath(
    `/d/:drugId/strategy/:strategyId/4_1/${SubStep.Pico}`,
    {
      drugId: urlParams.drugId,
      strategyId: urlParams.strategyId,
    }
  );
  history.push({
    pathname: path,
    search: urlParams.originalPicoId
      ? `originalPicoId=${urlParams.originalPicoId}`
      : '',
  });
};
