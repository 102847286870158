import { lowerCase, upperFirst } from 'lodash';
import styled from 'styled-components/macro';

import { BodySmall } from 'components/shared';
import { colors } from 'constants/colors';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const GoalInputDropdownItem = ({ value }: { value: string }) => {
  return (
    <Container>
      <BodySmall color={colors.black}>{upperFirst(lowerCase(value))}</BodySmall>
    </Container>
  );
};
