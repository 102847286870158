import styled from 'styled-components';
import { colors } from '../../constants';
import { Icon } from './Icon';

export const DownloadButtonWrapper = styled.button`
  background: ${colors.cream};
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border: none;
`;

export const DownloadButton = ({ onClick }: { onClick: () => void }) => (
  <DownloadButtonWrapper onClick={onClick}>
    <Icon name="Download" size={18} color={colors.black} />
  </DownloadButtonWrapper>
);
