import {
  DatePickerDropdown,
  StyledDatePicker,
} from 'components/MedicalStrategy/DatePickerDropdown';
import { DeleteConfirmationWrapper } from 'components/RiskMitigation/SolutionRow';
import {
  BodySmall,
  ButtonLabel,
  ButtonRound,
  CountryFlag,
} from 'components/shared';
import { colors } from 'constants/colors';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Country } from 'types';
import { device } from 'utils/breakpoints';
import { isTouchDevice } from 'utils/isTouchDevice';

const StyledDatePickerDropdown = styled(DatePickerDropdown)`
  width: 105px;

  @media ${device.tabletMax} {
    ${StyledDatePicker} {
      left: -46px;
    }
  }
`;

const StyledDeleteButton = styled(ButtonRound)<{ visibility?: boolean }>`
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
  ${({ visibility }) => {
    if (visibility) {
      return css`
        visibility: visible;
        opacity: 1;
      `;
    }
  }}
`;

const MobileDeleteButton = styled(ButtonRound)`
  position: absolute;
  right: 0;
`;

const StyledDeleteConfirmationWrapper = styled(DeleteConfirmationWrapper)`
  @media ${device.mobile} {
    position: absolute;
    right: 0;
    top: 15px;
  }
  @media ${device.tabletMin} {
    right: 160px;
    bottom: 0px;
  }

  @media ${device.desktopMin} {
    right: 130px;
    bottom: 0px;
  }
`;

const MobileWrapper = styled.div`
  height: 133px;
  padding-top: 15px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
`;

export const archetypeCountryRowColumnGridStyle = css`
  display: grid;
  padding: 0px;
  gap: 0px;
  grid-template-columns:
    minmax(121px, 224px)
    minmax(125px, 160px)
    minmax(125px, 160px)
    30px;
`;

const Wrapper = styled.div`
  ${archetypeCountryRowColumnGridStyle}
  height:60px;
  align-items: center;

  &:hover {
    ${StyledDeleteButton} {
      visibility: visible;
      opacity: 1;
    }
  }
`;

interface Props {
  isLead: boolean;
  country: string;
  filingDate: number[];
  launchDate: number[];
  updateTiming: ({
    filingDate,
    launchDate,
  }: {
    filingDate?: number[];
    launchDate?: number[];
  }) => void;
  deleteRow: () => void;
  isMobile: boolean;
}

export const ArchetypeCountryRow = ({
  isLead,
  country,
  filingDate,
  launchDate,
  updateTiming,
  deleteRow,
  isMobile,
}: Props) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const isTouch = isTouchDevice();

  if (!!isMobile) {
    return (
      <MobileWrapper>
        {!!isLead &&
          (!showDeleteConfirm ? (
            <MobileDeleteButton
              level="secondary"
              iconName="Trash"
              size="small"
              onClick={() => {
                setShowDeleteConfirm(true);
              }}
              className="Row__deleteIcon"
            />
          ) : (
            <StyledDeleteConfirmationWrapper>
              <ButtonLabel
                color={colors.black}
                onClick={() => setShowDeleteConfirm(false)}
              >
                Cancel
              </ButtonLabel>
              <ButtonLabel
                color={colors.red}
                onClick={async () => {
                  deleteRow();
                }}
              >
                Remove
              </ButtonLabel>
            </StyledDeleteConfirmationWrapper>
          ))}
        <FlagAndLabel country={country} />

        <div style={{ display: 'flex', gap: 15 }}>
          <div>
            <BodySmall color={colors.greyDark} style={{ marginBottom: 10 }}>
              Filing date
            </BodySmall>
            <FilingDate filingDate={filingDate} updateTiming={updateTiming} />
          </div>
          <div>
            <BodySmall color={colors.greyDark} style={{ marginBottom: 10 }}>
              Launch date
            </BodySmall>
            <LaunchDate launchDate={launchDate} updateTiming={updateTiming} />
          </div>
        </div>
      </MobileWrapper>
    );
  }

  return (
    <Wrapper>
      <FlagAndLabel country={country} />

      <FilingDate filingDate={filingDate} updateTiming={updateTiming} />
      <LaunchDate launchDate={launchDate} updateTiming={updateTiming} />

      {!!isLead &&
        (!showDeleteConfirm ? (
          <StyledDeleteButton
            visibility={isTouch}
            level="secondary"
            iconName="Trash"
            size="small"
            onClick={() => {
              setShowDeleteConfirm(true);
            }}
            className="Row__deleteIcon"
          />
        ) : (
          <StyledDeleteConfirmationWrapper>
            <ButtonLabel
              color={colors.black}
              onClick={() => setShowDeleteConfirm(false)}
            >
              Cancel
            </ButtonLabel>
            <ButtonLabel
              color={colors.red}
              onClick={async () => {
                deleteRow();
              }}
            >
              Remove
            </ButtonLabel>
          </StyledDeleteConfirmationWrapper>
        ))}
    </Wrapper>
  );
};

function FilingDate({
  filingDate,
  updateTiming,
}: {
  filingDate: number[];
  updateTiming: ({ filingDate }: { filingDate?: number[] | undefined }) => void;
}) {
  return (
    <DatePickerDropdown
      style={{ width: '105px' }}
      placeholder="Filing Date"
      date={filingDate}
      startDate={[new Date().getMonth(), new Date().getFullYear()]}
      onUpdate={(date) => {
        if (!date) return;
        const values = [date.month, date.year];
        updateTiming({ filingDate: values });
      }}
    />
  );
}

function LaunchDate({
  launchDate,
  updateTiming,
}: {
  launchDate: number[];
  updateTiming: ({ launchDate }: { launchDate?: number[] | undefined }) => void;
}) {
  return (
    <StyledDatePickerDropdown
      style={{ width: '105px' }}
      placeholder="Launch Date"
      date={launchDate}
      startDate={[new Date().getMonth(), new Date().getFullYear()]}
      onUpdate={(date) => {
        if (!date) return;
        const values = [date.month, date.year];
        updateTiming({ launchDate: values });
      }}
    />
  );
}

function FlagAndLabel({ country }: { country: string }) {
  return (
    <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
      <CountryFlag size={20} country={country as Country} />
      <BodySmall
        style={{
          textTransform: 'capitalize',
        }}
      >
        {country}
      </BodySmall>
    </div>
  );
}
