import { StrategicPossibility } from 'data/graphql/generated';
import React from 'react';
import styled from 'styled-components/macro';
import { BodySmall } from '.';
import { GradientCard } from './GradientCard';

const Wrapper = styled.div``;

const ImperativeDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

interface Props {
  strategicImperative: Pick<
    StrategicPossibility,
    'name' | 'behaviourToDrive' | 'driverOfTheBehaviour'
  >;
}

export const ImperativeSummaryCard: React.FC<Props> = ({
  strategicImperative,
}) => {
  return (
    <Wrapper>
      <GradientCard>
        <ImperativeDetails>
          <BodySmall style={{ fontWeight: 500 }}>
            {strategicImperative.name}
          </BodySmall>
          {strategicImperative.behaviourToDrive && (
            <BodySmall>{strategicImperative.behaviourToDrive}</BodySmall>
          )}
          {strategicImperative.driverOfTheBehaviour && (
            <BodySmall>{strategicImperative.driverOfTheBehaviour}</BodySmall>
          )}
        </ImperativeDetails>
      </GradientCard>
    </Wrapper>
  );
};
