import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';

export const useNavigateTo = (initialUrlParams: {
  [x: string]: string | number | boolean | undefined;
}) => {
  const history = useHistory();
  const { path } = useRouteMatch();

  return (urlParams: {
    [x: string]: string | number | boolean | undefined;
  }) => {
    const params = { ...initialUrlParams, ...urlParams };
    const generatedPath = generatePath(path, params);

    return () => history.push(generatedPath);
  };
};
