import { ButtonsContainer } from 'components/CompetitiveLandscape/CreateUpdateCompetitorModal';
import { ModalForm } from 'components/ModalForm';
import { ButtonPill } from 'components/shared';
import { BulletPointList } from 'components/shared/BulletPointList';
import { SubStep } from 'data/graphql/generated';
import React, { useState } from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div``;

interface Props {
  substep?: SubStep;
  statement?: string[];
  closeModal(): void;
  visible: boolean;
  drugId?: number;
  strategyId?: number;
  title: string;
  subTitle: string;
  onUpdate(v: string[]): Promise<any>;
  placeholder: string;
  numbered?: boolean;
}
export const StatementModal: React.FC<Props> = ({
  statement,
  closeModal,
  visible,
  title,
  subTitle,
  onUpdate,
  placeholder,
  numbered,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [newListValue, setNewListValue] = useState(statement || []);

  // on clicking 'Save', update the data
  async function updateList() {
    if (!statement) return;
    setIsSaving(true);

    await onUpdate(newListValue);
    setIsSaving(false);

    closeModal();
  }

  if (!statement) return null;

  return (
    <Wrapper>
      <ModalForm
        heading={title}
        message={subTitle}
        handleClose={closeModal}
        visible={visible}
      >
        <BulletPointList
          numbered={numbered}
          extraRow
          statement={statement}
          onUpdate={(newSavedArray) => {
            setNewListValue(newSavedArray);
          }}
          placeholder={placeholder}
        />

        <ButtonsContainer>
          <ButtonPill onClick={closeModal} text="Cancel" level="secondary" />

          <ButtonPill
            type="submit"
            onClick={updateList}
            text="Save"
            loading={isSaving}
            disabled={false}
          />
        </ButtonsContainer>
      </ModalForm>
    </Wrapper>
  );
};
