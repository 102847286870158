import { Position } from 'types';

export function connectInLeastPoints(a: Position, b: Position): number[] {
  // console.log(a);
  // console.log(b);

  // Is vertical or hoziontal least distance
  if (Math.abs(a.x - b.x) > Math.abs(a.y - b.y)) {
    // x is larger so use y
    return [a.x, b.y];
  } else {
    // y is larger so use x
    return [b.x, a.y];
  }
}
