import { Size } from '../types/Size';

export function getSize(size: Size) {
  switch (size) {
    case 'sm':
      return 25;
    case 'lg':
      return 40;
    default:
      return size;
  }
}
