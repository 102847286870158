import { ButtonPill } from 'components/shared';
import { YellowHeaderModal } from 'components/shared/YellowHeaderModal';
import styled from 'styled-components/macro';

const CTAWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

type Props = {
  isVisible: boolean;
  onClose(): void;
  onConfirm(): void;
};

export const DeleteConfirmation = ({
  isVisible,
  onClose,
  onConfirm,
}: Props) => {
  return (
    <YellowHeaderModal
      className="key-event-delete-confirmation"
      handleClose={onClose}
      visible={isVisible}
      heading={'Event will be deleted for everyone'}
      message={
        'If you just want to hide it from your specific timeline, go to the summary tab > “Show events” and deselect it.'
      }
    >
      <CTAWrapper>
        <ButtonPill
          width="180px"
          level="secondary"
          text="Cancel"
          onClick={onClose}
        />
        <ButtonPill
          width="180px"
          level="danger"
          text="Delete"
          onClick={onConfirm}
        />
      </CTAWrapper>
    </YellowHeaderModal>
  );
};
