import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import { colors } from 'constants/colors';
import { BodySmall, EmptyState, Subtitle2 } from 'components/shared';
import { DragObjectWithType, DropTargetMonitor, useDrop } from 'react-dnd';
import { zIndex } from 'constants/zIndex';
import { DragNDropItems } from 'types';
import useDesktop from 'hooks/useDesktop';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { device } from 'utils/breakpoints';
import { Wrapper as GridWrapper } from 'components/GridPages/DropzoneGrid';
import { Wrapper as CalloutWrapper } from 'components/CommercialStrategy/TacticCallout';
import { dragNDropPinOffset } from 'constants/index';

const distanceGridEdgeToGridBorder = 5;

export function calculateDropCoordinates(
  monitor: DropTargetMonitor,
  squareContainer: HTMLElement
): { x: number; y: number } | undefined {
  const dropPoint = monitor.getClientOffset();
  const gridRect = squareContainer?.getBoundingClientRect();

  const xPosition =
    dropPoint?.x &&
    ((dropPoint?.x - dragNDropPinOffset - gridRect.x) / gridRect?.width) * 100;
  const yPosition =
    dropPoint?.y &&
    ((dropPoint?.y - dragNDropPinOffset - gridRect.y) / gridRect.height) * 100;
  if (!xPosition || !yPosition) return;

  return { x: Math.round(xPosition), y: Math.round(yPosition) };
}

export const Wrapper = styled.div`
  width: 100%;

  @media ${device.desktopMin} {
    height: 100%;
    width: auto;
    margin: auto;
  }
`;

const GridAndAxisLabels = styled.div<{ showSubLabels: boolean }>`
  display: grid;
  grid-template-columns: ${({ showSubLabels }) => showSubLabels && `31px auto`};
  grid-gap: 5px;

  @media ${device.desktopMin} {
    height: calc(100% - 50px);
    aspect-ratio: 1/1;
  }
`;

const SquareContainer = styled.div<{
  isOver: boolean;
  canDrop: boolean;
  borderColor: string;
}>`
  position: relative;
  margin: 4px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: calc(100% + ${2 * distanceGridEdgeToGridBorder}px);
    height: calc(100% + ${2 * distanceGridEdgeToGridBorder}px);
    margin: -${distanceGridEdgeToGridBorder}px 0 0 -${distanceGridEdgeToGridBorder}px;
    border-radius: 5px;
    border: 2px dashed
      ${({ isOver, canDrop, borderColor }) =>
        canDrop ? (isOver ? borderColor : colors.greyDark) : 'transparent'};
  }
`;

const FourSquares = styled.div<{ borderColor: string }>`
  width: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5px;
  background: ${colors.greyMedium};
  border-left: 2.5px solid ${({ borderColor }) => borderColor};
  border-bottom: 2.5px solid ${({ borderColor }) => borderColor};

  @media ${device.desktopMin} {
    aspect-ratio: 1/1;
  }
`;

const Square = styled.div<{ background: string }>`
  background: ${({ background }) => background};
  // this is a trick to maintain perfect squares as padding-top calculates based on the width of the element when given a percentage value
  padding-top: 100%;
`;

const RewardLabels = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const RiskLabels = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DragHereOverlay = styled(EmptyState)<{
  visible: boolean;
}>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  position: absolute;
  background: ${colors.white70};
  z-index: ${zIndex.gridOverlay};
`;

export const GridHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const TouchPositioningOverlay = styled.div`
  position: fixed;
  inset: 0;
  background: ${colors.black40};
  z-index: ${zIndex.touchPositioningOverlay};
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TouchPositioningContent = styled.div`
  height: 100%;
  width: 100%;
  max-width: 486px;

  padding: 0 15px 13% 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  ${GridWrapper} {
    width: 100%;
  }

  ${CalloutWrapper} {
    max-width: 515px;
    width: 100%;
    margin-bottom: 25px;
  }
`;

interface Props {
  yAxisTitle: string;
  xAxisTitle: string;
  setTouchPositioningView: React.Dispatch<React.SetStateAction<string | null>>;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  touchPositioningView?: string | null;
  children?: React.ReactNode;
  gridClickHandler: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    closeTouchView: () => void
  ) => void;
  gridDropHandler: (
    item: DragObjectWithType,
    monitor: DropTargetMonitor,
    closeTouchView: () => void
  ) => void;
  showSubLabels?: boolean;
  blockColors?: string[];
  borderColor?: string;
}

export const DropzoneGrid: React.FC<Props> = ({
  xAxisTitle,
  yAxisTitle,
  setTouchPositioningView,
  touchPositioningView,
  setSidebarOpen,
  children,
  gridClickHandler,
  gridDropHandler,
  showSubLabels = true,
  blockColors = [
    colors.purple10,
    colors.purple15,
    colors.purple05,
    colors.purple10,
  ],
  borderColor = colors.purple,
}) => {
  const fourSquaresRef = useRef<HTMLDivElement | null>(null);
  const isDesktop = useDesktop();

  const closeTouchView = () => {
    if (!touchPositioningView || isDesktop) return;

    setTouchPositioningView(null);
    setSidebarOpen(false);
  };

  useClickOutsideComponent(fourSquaresRef, closeTouchView, [
    'callout',
    'collaboration-modal',
    'square-container',

    'rationale-dropdown',
    'focus-rationale-modal',
  ]);

  const [{ canDrop, isOver, itemType }, dropZone] = useDrop(
    () => ({
      accept: [DragNDropItems.CARD, DragNDropItems.PIN],
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType(),
      }),
      drop: (item, monitor) => gridDropHandler(item, monitor, closeTouchView),
    }),
    [gridDropHandler, closeTouchView]
  );

  return (
    <Wrapper>
      <Subtitle2>{yAxisTitle}</Subtitle2>

      <GridAndAxisLabels showSubLabels={showSubLabels}>
        {showSubLabels && (
          <RewardLabels>
            <BodySmall color={colors.greyDark}>High</BodySmall>
            <BodySmall color={colors.greyDark}>Low</BodySmall>
          </RewardLabels>
        )}

        <SquareContainer
          id="square-container"
          className="square-container"
          ref={dropZone}
          onClick={(e) => gridClickHandler(e, closeTouchView)}
          isOver={isOver}
          canDrop={canDrop}
          borderColor={borderColor}
        >
          <DragHereOverlay
            visible={canDrop && !isOver && itemType === DragNDropItems.CARD}
          >
            <Subtitle2 color={colors.greyDark}>Drag Here</Subtitle2>
            <BodySmall color={colors.greyDark}>
              Drop the pin to rank it on the axes
            </BodySmall>
          </DragHereOverlay>
          <FourSquares ref={fourSquaresRef} borderColor={borderColor}>
            <Square background={blockColors[0]} />
            <Square background={blockColors[1]} />
            <Square background={blockColors[2]} />
            <Square background={blockColors[3]} />
          </FourSquares>
          {children}
        </SquareContainer>

        <div />

        {showSubLabels && (
          <RiskLabels>
            <BodySmall color={colors.greyDark}>High</BodySmall>
            <BodySmall color={colors.greyDark}>Low</BodySmall>
          </RiskLabels>
        )}
      </GridAndAxisLabels>

      <Subtitle2 style={{ textAlign: 'right' }}>{xAxisTitle}</Subtitle2>
    </Wrapper>
  );
};
