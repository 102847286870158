import { Heading3, Subtitle1 } from 'components/shared';

import React from 'react';
import styled from 'styled-components';
import { ContentBodyNormal, ContentWrapper, FlatOL } from './TermsOfService';

const FlatUL = styled.ul`
  padding-inline-start: 20px;
`;

interface Props {}

export const PrivacyPolicy = (props: Props) => {
  return (
    <ContentWrapper>
      <Heading3>Privacy Policy</Heading3>
      <section>
        <ContentBodyNormal>Last updated: 25 November 2021</ContentBodyNormal>
        <ContentBodyNormal>
          Claroo Ltd. and its affiliates (collectively,{' '}
          <strong>“Claroo”</strong>, “we”, “our”, and “us”) have a commitment to
          safeguard the privacy of information entrusted to us and value the
          trust of our existing and prospective business relations. The privacy
          principles set forth are intended to tell you how we collect, retain,
          and use personal information about you in accordance with applicable
          law.
        </ContentBodyNormal>
        <ContentBodyNormal>
          When we mention "Claroo", "we", "us" or "our" in these principles, we
          are referring to the relevant entity responsible for processing your
          data. Claroo is the data controller and responsible for deciding how
          we use and hold your personal information.
        </ContentBodyNormal>
        <ContentBodyNormal>
          We collect, use and are responsible for certain personal data about
          you. When we do so we are subject to the UK General Data Protection
          Regulation ("<strong>UK GDPR</strong>"), the EU General Data
          Protection Regulation ("<strong>EU GDPR</strong>") in relation to the
          goods and/or services (as the case may be) we offer to customers.
        </ContentBodyNormal>
        <ContentBodyNormal>
          You, our employees and any third parties can report any complaints or
          unethical behaviour in a confidential manner to{' '}
          <strong>
            <a href="mailto:whistleblowing@nmblr.co">
              whistleblowing@nmblr.co.
            </a>
          </strong>
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Collection and Use of Personal Information</Subtitle1>
        <ContentBodyNormal>
          We may process personal information that identifies you as an
          individual or that relates to an identifiable person (“
          <strong>Personal Information</strong>”) such as:
        </ContentBodyNormal>
        <FlatUL>
          <li>
            <ContentBodyNormal>
              Personal Details and Contact Information: Name; address; e-mail;
              telephone and fax details, signature and electronic signatures
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              Professional Affiliations: Business name, title, and address;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              Financial Information: Payment card number; bank account number
              and account details; tax returns; salary; assets and income;
              personal bankruptcy; credit history and credit score;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              Operational Data: Transactions, sales, purchases, uses, supplier
              information, credentials to online services and platforms, and
              electronic content produced by data subjects using company
              systems, including online interactive and voice communications,
              such as blog, chat, webcam use and network sessions;
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              Any further information required for credit verification,
              compliance/ethics checks, or customer due diligence;
            </ContentBodyNormal>
          </li>
        </FlatUL>
        <ContentBodyNormal>
          We may collect Personal Information in various ways, such as: when you
          interact with one of our websites; when you enter into a transaction
          or contractual arrangement with us; when you participate in our
          programs or activities; when you provide data at industry events and
          trade shows; when you visit our facilities or we visit you at your
          offices; when you contact our customers services; or in connection
          with your inquiries and communications with us.
        </ContentBodyNormal>
        <ContentBodyNormal>
          Additionally, the website operator may automatically collect or
          process Personal Information (such as your Internet domain or IP
          address) as part of the operation of the services or during the course
          of your activities on or use of the websites.
        </ContentBodyNormal>
        <ContentBodyNormal>
          It is important that the personal information we hold about you is
          accurate and current. Please keep us informed if your personal
          information changes during your relationship with us.
        </ContentBodyNormal>
        <ContentBodyNormal>
          We and our service providers use Personal Information for legitimate
          business purposes including:
        </ContentBodyNormal>
        <FlatUL>
          <li>
            <ContentBodyNormal>
              Providing the functionality of the services and fulfilling your
              requests.
            </ContentBodyNormal>
            <ul>
              <li>
                <ContentBodyNormal>
                  To respond to your inquiries and fulfill your requests, when
                  you contact us via one of our online contact forms or
                  otherwise, for example, when you send us questions,
                  suggestions, or when you request a quote for or other
                  information about our services.
                </ContentBodyNormal>
              </li>
              <li>
                <ContentBodyNormal>
                  To conclude and execute agreements with customers, suppliers
                  and business partners, including required screening activities
                  (e.g. for access to our premises or systems, or credit
                  verification, compliance/ethics checks or customer due
                  diligence.
                </ContentBodyNormal>
              </li>
              <li>
                <ContentBodyNormal>
                  To complete your transactions and provide you with related
                  customer service.
                </ContentBodyNormal>
              </li>
              <li>
                <ContentBodyNormal>
                  To send administrative information to you, such as changes to
                  our terms, conditions and policies.
                </ContentBodyNormal>
              </li>
              <li>
                <ContentBodyNormal>
                  To allow you to send messages to another person if you choose
                  to do so.
                </ContentBodyNormal>
              </li>
            </ul>
          </li>
        </FlatUL>
        <ContentBodyNormal>
          We will engage in these activities where we have a legitimate
          interest, to manage our contractual relationship with you, and/or to
          comply with a legal obligation.
        </ContentBodyNormal>
        <FlatUL>
          <li>
            <ContentBodyNormal>
              Providing you with marketing materials.
            </ContentBodyNormal>
          </li>
          <ul>
            <li>
              <ContentBodyNormal>
                To send you marketing related emails, with information about our
                services, new products and other news in relation to Claroo. We
                will only engage in this activity with your consent or where we
                have a legitimate interest.
              </ContentBodyNormal>
            </li>
          </ul>
        </FlatUL>
        <FlatUL>
          <li>
            <ContentBodyNormal>
              Analysis of Personal Information for business reporting and
              providing personalized services.
            </ContentBodyNormal>
          </li>
          <ul>
            <li>
              <ContentBodyNormal>
                To analyze or predict our users’ preferences in order to prepare
                aggregated trend reports on how our digital content is used, so
                we can improve our services.
              </ContentBodyNormal>
            </li>
            <li>
              <ContentBodyNormal>
                To better understand you, so that we can personalize our
                interactions with you and provide you with information and/or
                offers tailored to your interests.
              </ContentBodyNormal>
            </li>
            <li>
              <ContentBodyNormal>
                To better understand your preferences so that we can deliver
                content via our services that we believe will be relevant and
                interesting to you.
              </ContentBodyNormal>
            </li>
          </ul>
        </FlatUL>
        <ContentBodyNormal>
          We will provide personalized services either with your consent or
          because we have a legitimate interest.
        </ContentBodyNormal>
        <FlatUL>
          <li>
            <ContentBodyNormal>
              Accomplishing our business purposes.
            </ContentBodyNormal>
          </li>
          <ul>
            <li>
              <ContentBodyNormal>
                For data analysis, for example, to improve the efficiency of our
                services;
              </ContentBodyNormal>
            </li>
            <li>
              <ContentBodyNormal>
                For audits, to verify that our internal processes function as
                intended and are compliant with legal, regulatory or contractual
                requirements;
              </ContentBodyNormal>
            </li>
            <li>
              <ContentBodyNormal>
                For fraud and security monitoring purposes, for example, to
                detect and prevent cyber attacks or attempts to commit identity
                theft;
              </ContentBodyNormal>
            </li>
            <li>
              <ContentBodyNormal>
                For developing new products and services;
              </ContentBodyNormal>
            </li>
            <li>
              <ContentBodyNormal>
                For enhancing, improving, or modifying our current products and
                services;
              </ContentBodyNormal>
            </li>
            <li>
              <ContentBodyNormal>
                For identifying usage trends, for example, understanding which
                parts of our services are of most interest to users;
              </ContentBodyNormal>
            </li>
            <li>
              <ContentBodyNormal>
                For determining the effectiveness of our promotional campaigns,
                so that we can adapt our campaigns to the needs and interests of
                our users;and
              </ContentBodyNormal>
            </li>
            <li>
              <ContentBodyNormal>
                For operating and expanding our business activities, for
                example, understanding which parts of our services are of most
                interest to our users so we can focus our energies on meeting
                our users’ interests.
              </ContentBodyNormal>
            </li>
          </ul>
        </FlatUL>
        <ContentBodyNormal>
          We engage in these activities to manage our contractual relationship
          with you, to comply with a legal obligation, and/or because we have a
          legitimate interest.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>
          How Personal Information is shared with third parties
        </Subtitle1>
        <ContentBodyNormal>
          We may disclose Personal Information to third parties assisting us in
          carrying out a transaction or providing services for you (such as
          packaging, sending and delivering purchases and information to you,
          clearing credit card transactions, or performing statistical analyses
          of our services). We may also disclose your Personal Information to
          our third party service providers who provide services such as website
          hosting, data analysis, payment processing, order fulfillment,
          information technology and related infrastructure provision, customer
          service, email delivery, auditing, HR services &amp; payroll, and
          other services.
        </ContentBodyNormal>
        <ContentBodyNormal>
          We will not sell, trade, or disclose your Personal Information to
          third parties that want to market products to you.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>Other uses and disclosures</Subtitle1>
        <ContentBodyNormal>
          We may use or disclose Personal Information as we believe to be
          necessary or appropriate, especially when we have a legal obligation
          or legitimate interest to do so: (a) under applicable law, including
          laws outside your country of residence; (b) to comply with legal
          process; (c) to respond to requests from public and government
          authorities including public and government authorities outside your
          country of residence; (d) to enforce our terms and conditions or in
          response to activities that are unlawful or a violation of any
          relevant terms and conditions, or applicable rules, for use of the
          Website; (e) to protect our operations or those of any of our
          affiliates; (f) to protect our rights, privacy, safety or property,
          and/or that of our affiliates, you or others; and (g) to allow us to
          pursue available remedies or limit the damages that we may sustain.
        </ContentBodyNormal>
        <ContentBodyNormal>
          In addition, we have a legitimate interest to use, disclose or
          transfer your Personal Information to a third party in the event of
          any reorganization, merger, sale, joint venture, assignment, transfer
          or other disposition of all or any portion of our business, assets or
          stock (including in connection with any bankruptcy or similar
          proceedings). Such third parties may include, for example, an
          acquiring entity and its advisors.
        </ContentBodyNormal>
      </section>

      <section>
        <Subtitle1>
          Other information we may collect, including ‘cookies’
        </Subtitle1>
        <ContentBodyNormal>
          “<strong>Other Information</strong>” is any information that does not
          reveal your specific identity or does not directly relate to an
          individual, such as:
        </ContentBodyNormal>
        <FlatUL>
          <li>
            <ContentBodyNormal>
              Browser and device information
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>App usage data</ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              Information collected through cookies, pixel tags and other
              technologies
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              Demographic information and other information provided by you that
              does not reveal your specific identity,
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              Information that has been aggregated in a manner that it no longer
              reveals your specific identity
            </ContentBodyNormal>
          </li>
        </FlatUL>
      </section>

      <section>
        <Subtitle1>Collection of Other Information</Subtitle1>
        <ContentBodyNormal>
          We and our service providers may collect Other Information in a
          variety of ways, including:
        </ContentBodyNormal>
        <FlatUL>
          <li>
            <ContentBodyNormal>
              <strong>Through your browser or device:</strong> Certain
              information is collected by most browsers or automatically through
              your device, such as your Media Access Control (MAC) address,
              computer type (Windows or Mac), screen resolution, operating
              system name and version, device manufacturer and model, language,
              Internet browser type and version and the name and version of the
              services (such as the app) you are using. We use this information
              to ensure that the services function properly.
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              <strong>Use of cookies:</strong> We may use "cookies" in
              connection with operation of its websites.
            </ContentBodyNormal>
          </li>
        </FlatUL>
      </section>
      <section>
        <Subtitle1>Security measures</Subtitle1>
        <ContentBodyNormal>
          Claroo has implemented adequate technological and operational security
          processes to protect your personally identifiable information from
          loss, misuse, alteration, or unintentional destruction. While no
          security measure can guarantee against compromise, Claroo regularly
          reviews and updates its security measures in an effort to provide
          appropriate security for all information held by Claroo. If you have
          reason to believe that your interaction with us is no longer secure,
          please immediately notify us in accordance with the “Contacting Us”
          section below.
        </ContentBodyNormal>
      </section>
      <section>
        <Subtitle1>Change of purpose</Subtitle1>
        <ContentBodyNormal>
          We will only use your Personal Information for the purposes for which
          we collected it, unless we reasonably consider that we need to use it
          for another reason and that reason is compatible with the original
          purpose. If you wish to get an explanation as to how the processing
          for the new purpose is compatible with the original purpose, please
          contact us.
        </ContentBodyNormal>
        <ContentBodyNormal>
          If we need to use your personal data for an unrelated purpose, we will
          notify you and we will explain the legal basis which allows us to do
          so and to the extent required by applicable law, seek your consent.
        </ContentBodyNormal>
      </section>
      <section>
        <Subtitle1>Modifications to this Privacy POLICY</Subtitle1>
        <ContentBodyNormal>
          Claroo reserves the right to change, modify, or update this Privacy
          Policy at any time. The “LAST UPDATED” legend at the top of this
          Privacy Policy indicates when this Privacy Policy was last revised.
          Any changes will become effective when we post the revised Privacy
          Policy on the services. Your use of the services following these
          changes means that you accept the revised Privacy Policy.
        </ContentBodyNormal>
      </section>
      <section>
        <Subtitle1>Your rights</Subtitle1>
        <ContentBodyNormal>
          To the extent a specific element of our processing of your Personal
          Information relies upon your consent, you may withdraw your consent at
          any time with future effect. A withdrawal of your consent will not
          affect the lawfulness of the processing prior to the consent
          withdrawal.
        </ContentBodyNormal>
        <ContentBodyNormal>
          Claroo wants to maintain only accurate information about users. You
          have the right to request that we rectify inaccurate Personal
          Information concerning you. Depending on the purposes of the
          processing, you may have the right to have incomplete personal
          information completed, including by means of providing a supplementary
          statement.
        </ContentBodyNormal>
        <ContentBodyNormal>
          You have the right to obtain from us confirmation as to whether or not
          Personal Information concerning you is being processed, and, where
          that is the case, to request access to your Personal Information. This
          access information includes, among others, the purposes of the
          processing, the categories of Personal Information concerned, and the
          recipients or categories of recipient to whom the Personal Information
          has been or will be disclosed. However, this is not an absolute right
          and the interests of other individuals may restrict your right of
          access. For your protection, we may only implement requests with
          respect to the Personal Information associated with the particular
          email address that you use to send us your request, and we may need to
          verify your identity before implementing your request.
        </ContentBodyNormal>
        <ContentBodyNormal>
          Under certain circumstances, you have the right to ask us to erase
          Personal Information concerning you and we may be obliged to erase
          such Personal Information. Please note that we may need to retain
          certain information for recordkeeping purposes and/or to complete any
          transactions that you began prior to requesting a change or deletion
          (e.g., when you make a purchase or enter a promotion, you may not be
          able to change or delete the Personal Information provided until after
          the completion of such purchase or promotion).
        </ContentBodyNormal>
        <ContentBodyNormal>Under certain circumstances:</ContentBodyNormal>
        <FlatOL>
          <li>
            <ContentBodyNormal>
              a) you have the right to ask us to restrict the processing of your
              Personal Information. If so, the applicable Personal Information
              will be marked and may only be processed by us for certain
              purposes.
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              b) you have the right to ask us to provide you with your Personal
              Information, in a structured, commonly used and machine-readable
              format and you have the right to transmit that data to another
              entity without hindrance from us.
            </ContentBodyNormal>
          </li>
          <li>
            <ContentBodyNormal>
              c) you can object on grounds of relating to your particular
              situation at any time to the processing of your Personal
              Information by us and require us to stop processing your Personal
              Information. The right to object may especially apply if we
              collect and process your Personal Information for profiling
              purposes in order to better understand your interests in our
              products and services or for direct marketing. If you have a right
              to object and you exercise this right, we will no longer process
              your Personal Information for those purposes. You may exercise
              this right by contacting us. The right to object may, in
              particular, not exist if the processing of your Personal
              Information is necessary to take steps prior to entering into a
              contract or to perform a contract already concluded.
            </ContentBodyNormal>
          </li>
        </FlatOL>
      </section>
      <section>
        <Subtitle1>Data retention</Subtitle1>
        <ContentBodyNormal>
          Claroo will not retain your Personal Information longer than is
          necessary and permitted in light of the purpose(s) for which it was
          obtained. The criteria used to determine our retention periods
          include: (i) the length of time we have an ongoing relationship with
          you; (ii) whether there is a legal obligation to which we are subject;
          or (iii) whether retention is advisable in light of our legal position
          (such as in regard to applicable statutes of limitations, litigation
          or regulatory investigations).
        </ContentBodyNormal>
      </section>
      <section>
        <Subtitle1>Jurisdiction and cross-border transfer</Subtitle1>
        <ContentBodyNormal>
          Your Personal Information may be collected or processed by, and
          transferred to, processing facilities in North America (including
          Canada), Europe, Southeast Asia, Latin America and the United Kingdom
          and in other countries and such region(s) thereof, as applicable,
          where Claroo does business. Your Personal Information may be stored
          and processed in any country where we have facilities or in which we
          engage service providers, and by using the services you consent to the
          transfer of information to countries outside of your country of
          residence, including the United States, which may have data protection
          rules that are different from those of your country.
        </ContentBodyNormal>
      </section>
      <section>
        <Subtitle1>Linked Websites</Subtitle1>
        <ContentBodyNormal>
          We may provide links to third party sites. Since we do not control
          these websites, we encourage you to review the privacy policy posted
          on these (and all) third party sites. Claroo is not responsible for
          the privacy policy or practices of sites and services controlled by
          companies or organizations other than Claroo. The inclusion of a link
          on the services does not imply endorsement of the linked site or
          service by us or by our affiliates.
        </ContentBodyNormal>
        <ContentBodyNormal>
          In addition, we are not responsible for the information collection,
          use, disclosure or security policies or practices of other
          organizations, such as Facebook, Apple, Google, Microsoft, RIM or any
          other app developer, app provider, social media platform provider,
          operating system provider, wireless service provider or device
          manufacturer, including with respect to any Personal Information you
          disclose to other organizations through or in connection with the apps
          or our social media pages.
        </ContentBodyNormal>
      </section>
      <section>
        <Subtitle1>Contact information</Subtitle1>
        <ContentBodyNormal>
          If you have any questions about this Privacy policy, please contact us
          using this{' '}
          <strong>
            e-mail: <a href="mailto:janice@nmblr.co">janice@nmblr.co</a>
          </strong>{' '}
          or this{' '}
          <strong>
            phone number: <a href="tel:+447789997454">+447789997454</a>
          </strong>
        </ContentBodyNormal>
        <ContentBodyNormal>
          Because email communications are not always secure, please do not
          include credit card or other sensitive information in your emails to
          us.
        </ContentBodyNormal>
        <ContentBodyNormal>
          If you have a complaint about the way in which we process your
          Personal Information, please contact us as referenced above and we
          will endeavor to address your concerns. You can also contact the
          competent data protection supervisory authority, details of which we
          can provide upon request.
        </ContentBodyNormal>
      </section>
    </ContentWrapper>
  );
};
