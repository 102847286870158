import { colors, polling } from 'constants/index';
import { Subtitle2, ButtonPill, Icon } from 'components/shared';
import { ReusableLargeSidebar } from 'components/shared/ReusableLargeSidebar';
import {
  Wrapper,
  Header,
  CreateButtonWrapper,
  ContentWrapper,
} from './KeyEventsSidebar.styles';
import KeyEvent from './components/KeyEventContainer';
import { EmptyState } from './ui/EmptyState';
import {
  useCRUDKeyEvent,
  useGetKeyEvents,
  useCreateDeleteKeyEventsStep,
} from 'components/4-2-medical-strategy/shared/data-access-key-events-sidebar/src';
import { KeyEventFragment, Sort, Step } from 'data/graphql/generated';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { uid } from 'uid';
import { Loading } from 'components/Loading';
import { Flipped, Flipper } from 'react-flip-toolkit';
import orderBy from 'lodash/orderBy';
import startOfToday from 'date-fns/startOfToday';

type Props = {
  isOpen: boolean;
  onClose(): void;
  region?: string;
  type?: string;
  step?: Step;
  strategyId: number;
  canEnable?: boolean;
  isGlobalContributor: boolean;
};

export const KeyEventsSidebar = ({
  isOpen,
  onClose,
  region,
  type,
  step,
  strategyId,
  canEnable = true,
  isGlobalContributor,
}: Props) => {
  const [previewKeyEvent, setPreviewKeyEvent] = useState<
    KeyEventFragment | undefined
  >(undefined);

  const { keyEvents, loading, startPolling, stopPolling } = useGetKeyEvents({
    where: {
      strategyId,
      type
    },
    enabledConditions: {
      region,
      step,
    },
  });


  useEffect(() => {
    startPolling(polling.default);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const sortedFetchedKeyEvents = useMemo(
    () => orderBy(keyEvents, ['date', 'createdAt'], ['asc', 'desc']),
    [keyEvents]
  );

  const flipKey = useMemo(() => {
    const eventsWithPreview = [
      previewKeyEvent,
      ...sortedFetchedKeyEvents,
    ].filter(Boolean);

    const deDupedEventsWithPreview = Array.from(new Set(eventsWithPreview));

    const sortedArray = orderBy(
      deDupedEventsWithPreview,
      ['date', 'createdAt'],
      ['asc', 'desc']
    );

    return sortedArray.map((v) => v?.localUid).join('');
  }, [previewKeyEvent, sortedFetchedKeyEvents]);

  const {
    handleCreateKeyEvent,
    handleDeleteKeyEvent,
    handleUpdateKeyEvent,
  } = useCRUDKeyEvent({
    where: {
      strategyId,
      type
    },
    enabledConditions: {
      region,
      step,
    },
    orderBy: { date: Sort.Asc },
  });

  const addPreview = () => {
    setPreviewKeyEvent(() => {
      const date = startOfToday().getTime().toString();
      const now = new Date().getTime().toString();
      const localUid = uid();
      const previewKeyEvent: KeyEventFragment = {
        id: 0,
        localUid: localUid,
        createdAt: now,
        lastUpdated: now,
        strategyId,
        text: '',
        type: '',
        date,
      };

      return previewKeyEvent;
    });
  };

  const deleteKeyEvent = useCallback(
    (id: number, localUid: string) => {
      const isPreview = id === 0;
      if (isPreview) {
        setPreviewKeyEvent(undefined);
      }

      if (id) {
        handleDeleteKeyEvent(localUid);
      }
    },
    [handleDeleteKeyEvent]
  );

  const handleAddKeyEvent = useCallback(
    ({
      localUid,
      strategy,
      text,
      type,
      step,
      date,
      createdAt,
    }: Omit<
      Parameters<typeof handleCreateKeyEvent>['0'],
      'region' | 'isGlobalContributor'
    >) => {
      handleCreateKeyEvent({
        localUid,
        strategy,
        text,
        type,
        step,
        date,
        isGlobalContributor,
        createdAt,
      });
      setPreviewKeyEvent(undefined);
    },
    [handleCreateKeyEvent, isGlobalContributor]
  );

  const { toggleKeyEventStep } = useCreateDeleteKeyEventsStep();

  const [topMargin, setTopMargin] = useState(false);

  const ContentStates = useCallback(() => {
    if (loading) {
      return <Loading />;
    }

    return <EmptyState />;
  }, [loading]);

  return (
    <ReusableLargeSidebar
      onClickOutside={onClose}
      isOpen={isOpen}
      heightOffset={50}
      ignoreClickClasses={['key-event-delete-confirmation']}
    >
      <Wrapper>
        <Header>
          <Icon
            size={30}
            name="Calendar"
            style={{ marginRight: 5 }}
            color={colors.purple}
          />
          <Subtitle2 style={{ marginRight: 'auto' }}>Key Events</Subtitle2>

          <Icon
            name="Collapse right"
            size={30}
            color={colors.purple}
            style={{ cursor: 'pointer' }}
            onClick={onClose}
          />
        </Header>
        <CreateButtonWrapper>
          <ButtonPill
            iconName="Plus"
            text="Add key event"
            onClick={addPreview}
          />
        </CreateButtonWrapper>
        <ContentWrapper
          topMargin={topMargin}
          onScroll={(e) => {
            const div = e.target as HTMLDivElement;
            const position = div.scrollTop;

            if (position) {
              setTopMargin(true);
            } else setTopMargin(false);
          }}
        >
          <Flipper className="KeyEvent_Sidebar__Flipper" flipKey={flipKey}>
            {!previewKeyEvent && !keyEvents.length && <ContentStates />}
            {previewKeyEvent && (
              <Flipped
                key={previewKeyEvent.localUid}
                flipId={previewKeyEvent.localUid}
              >
                <div>
                  <KeyEvent
                    key={previewKeyEvent.localUid}
                    keyEvent={previewKeyEvent}
                    id={previewKeyEvent.id}
                    date={previewKeyEvent.date}
                    text={previewKeyEvent.text}
                    type={type}
                    strategyId={strategyId}
                    localUid={previewKeyEvent.localUid}
                    onCreate={handleAddKeyEvent}
                    onDelete={deleteKeyEvent}
                    onUpdate={handleUpdateKeyEvent}
                    toggleEnabledStatus={toggleKeyEventStep}
                    currentStep={step}
                    canEnable={canEnable}
                    region={region}
                    isPreview={true}
                  />
                </div>
              </Flipped>
            )}
            {sortedFetchedKeyEvents.map(
              ({ id, localUid, date, text, strategyId }, idx, arr) => (
                <Flipped key={localUid} flipId={localUid}>
                  <div>
                    <KeyEvent
                      keyEvent={arr[idx]}
                      id={id}
                      date={date}
                      text={text}
                      type={Step.MedicalStrategy}
                      strategyId={strategyId}
                      localUid={localUid}
                      onCreate={handleAddKeyEvent}
                      onDelete={deleteKeyEvent}
                      onUpdate={handleUpdateKeyEvent}
                      toggleEnabledStatus={toggleKeyEventStep}
                      currentStep={step}
                      canEnable={canEnable}
                      region={region}
                      isPreview={false}
                    />
                  </div>
                </Flipped>
              )
            )}
          </Flipper>
        </ContentWrapper>
      </Wrapper>
    </ReusableLargeSidebar>
  );
};
