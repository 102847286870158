import React, { useContext, useState } from 'react';
import { createContext } from 'react';

export const CollaborationContext = createContext<[number | undefined, any]>([
  undefined,
  () => {},
]);

export const CollaborationProvider: React.FC = ({ children }) => {
  const [collaboration, setCollaboration] = useState<number | undefined>(
    undefined
  );

  return (
    <CollaborationContext.Provider value={[collaboration, setCollaboration]}>
      {children}
    </CollaborationContext.Provider>
  );
};

export function useCollaborationContext() {
  return useContext(CollaborationContext);
}
