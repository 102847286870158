import { ArchetypeCountryFragment, UserFragment } from 'data/graphql/generated';
import { sortBy } from 'lodash';

// Order archetypeCountries alphabetically. Place the user's own archetypeCountry first, if their archetypeCountry is in the list
export default function orderArchetypeCountries(
  archetypeCountries: ArchetypeCountryFragment[] | undefined,
  user: UserFragment | undefined
): ArchetypeCountryFragment[] {
  if (!user || !archetypeCountries || !archetypeCountries.length) return [];

  const sortedAlphabetically = sortBy(archetypeCountries, 'country');

  // if user's own country in list, move it to the front
  const ownUserCountryFirst: ArchetypeCountryFragment[] = sortedAlphabetically.reduce(
    (list: ArchetypeCountryFragment[], curr: ArchetypeCountryFragment) => {
      if (curr.country === user.country) {
        list.unshift(curr);
      } else {
        list.push(curr);
      }

      return list;
    },
    []
  );

  return ownUserCountryFirst;
}
