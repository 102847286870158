import { useCallback } from 'react';
import { FetchResult } from '@apollo/client';
import { useParams } from 'react-router-dom';

import {
  Step,
  SubStep,
  CriticalMetricGoalsDocument,
  useCriticalMetricGoalDeleteMutation,
  CriticalMetricGoalDeleteMutation,
} from 'data/graphql/generated';
import { CriticalMetricsParams } from '../../../../../shared/shared-data-access-critical-metrics/src';

export type DeleteCriticalMetricGoalResponse = FetchResult<
  CriticalMetricGoalDeleteMutation,
  Record<string, any>,
  Record<string, any>
>;

export type DeleteCriticalMetricGoalFn = (
  id: number
) => Promise<DeleteCriticalMetricGoalResponse>;

export const useDeleteCriticalMetricGoal = () => {
  const {
    strategyId,
    competitiveAdvantageRowUrlParam,
  } = useParams<CriticalMetricsParams>();

  const [deleteRow] = useCriticalMetricGoalDeleteMutation();

  const handleDeleteRow = useCallback<DeleteCriticalMetricGoalFn>(
    async (id) => {
      return await deleteRow({
        variables: { id },
        refetchQueries: [
          {
            query: CriticalMetricGoalsDocument,
            variables: {
              where: {
                strategyId: +strategyId,
                competitiveAdvantageRowId: +competitiveAdvantageRowUrlParam,
                step: Step.CriticalMetrics,
                subStep: SubStep.Goals,
              },
            },
            fetchPolicy: 'cache-only',
          },
        ],
      });
    },
    [strategyId, competitiveAdvantageRowUrlParam, deleteRow]
  );

  return handleDeleteRow;
};
