import { SummaryTimeline } from 'components/Timeline/SummaryTimelineRestyled';
import {
  Sort,
  useBigIdeaDetailsQuery,
  useCommercialTacticDetailsQuery,
  useCompetitiveAdvantageRowsQuery,
  useSolutionsQuery,
  useTacticsQuery,
  useStrategyQuery,
} from 'data/graphql/generated';
import { orderBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import {
  dateStringToMonthYear,
  monthYearToDateTimestamp,
} from 'utils/dateStringToMonthYear';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { polling } from 'constants/index';

const Wrapper = styled.div`
  padding: 0 15px;
  width: 100%;
  height: 100%;
  @media ${device.mobile} {
    padding: 0;
  }
`;

type Props = {
  strategyId: number;
};

export const BubbleSummaryFourContent = ({ strategyId }: Props) => {
  const {
    data: strategyData,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
    loading: strategyLoading,
    error: strategyError,
  } = useStrategyQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'network-only',
  });
  const currency = strategyData?.strategy?.currency[0] || '¥';

  const {
    data: competitiveAdvantageRowsData,
    error: competitiveAdvantageRowsError,
    loading: competitiveAdvantageRowsLoading,
    startPolling: startCompetitiveAdvantageRowsPolling,
    stopPolling: stopCompetitiveAdvantageRowsPolling,
  } = useCompetitiveAdvantageRowsQuery({
    variables: {
      orderBy: {
        idx: Sort.Desc,
      },
      where: {
        strategyId: +strategyId,
        focus: true,
      },
      include: {
        strategicPossibility: true,
        successConditions: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  const tacticsQueryVars = {
    where: {
      strategyId: +strategyId,
      focused: true,
      region: 'global',
    },
    orderBy: {
      id: Sort.Asc,
    },
  };
  const {
    data: tacticsData,
    error: tacticsError,
    loading: tacticsLoading,
    startPolling: startTacticsPolling,
    stopPolling: stopTacticsPolling,
  } = useTacticsQuery({
    variables: {
      ...tacticsQueryVars,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const {
    data: bigIdeaDetailsData,
    error: bigIdeaDetailsError,
    loading: bigIdeaDetailsLoading,
    startPolling: bigIdeaDetailsStartPolling,
    stopPolling: bigIdeaDetailsStopPolling,
  } = useBigIdeaDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderBy: {
        createdAt: Sort.Desc,
      },
      where: {
        strategyId: +strategyId,
      },
    },
  });

  const commercialTacticsQueryVars = {
    where: {
      strategyId: +strategyId,
    },
    orderBy: {
      createdAt: Sort.Desc,
    },
  };

  const {
    data: commercialTacticsData,
    error: commercialTacticsError,
    loading: commercialTacticsLoading,
    startPolling: startCommercialTacticsPolling,
    stopPolling: stopCommercialTacticsPolling,
  } = useCommercialTacticDetailsQuery({
    variables: commercialTacticsQueryVars,
    fetchPolicy: 'network-only',
  });

  const {
    data: solutionsData,
    startPolling: startSolutionsPolling,
    stopPolling: stopSolutionsPolling,
    loading: solutionsLoading,
    error: solutionsError,
  } = useSolutionsQuery({
    variables: {
      where: { strategyId: +strategyId, focused: true },
      include: { successCondition: true },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    startStrategyPolling(polling.default);
    startCompetitiveAdvantageRowsPolling(polling.default);
    startTacticsPolling(polling.default);
    bigIdeaDetailsStartPolling(polling.default);
    startCommercialTacticsPolling(polling.default);
    startSolutionsPolling(polling.default);

    return () => {
      stopStrategyPolling();
      stopCompetitiveAdvantageRowsPolling();
      stopTacticsPolling();
      bigIdeaDetailsStopPolling();
      stopCommercialTacticsPolling();
      stopSolutionsPolling();
    };
  }, [
    bigIdeaDetailsStartPolling,
    bigIdeaDetailsStopPolling,
    startCommercialTacticsPolling,
    startCompetitiveAdvantageRowsPolling,
    startSolutionsPolling,
    startStrategyPolling,
    startTacticsPolling,
    stopCommercialTacticsPolling,
    stopCompetitiveAdvantageRowsPolling,
    stopSolutionsPolling,
    stopStrategyPolling,
    stopTacticsPolling,
  ]);

  const tacticsForTimeline = useMemo(
    () =>
      [
        ...(bigIdeaDetailsData?.bigIdeaDetails?.items || []),
        ...(commercialTacticsData?.commercialTacticDetails?.items || []),
        ...(tacticsData?.tactics?.items || []),
        ...(solutionsData?.solutions?.items || []),
      ].map((tactic) => {
        let timingStart =
          'from' in tactic
            ? dateStringToMonthYear(tactic.from)
            : 'timingStart' in tactic
            ? tactic.timingStart
            : [];

        let timingEnd =
          'to' in tactic
            ? dateStringToMonthYear(tactic.to)
            : 'timingEnd' in tactic
            ? tactic.timingEnd
            : [];

        let dueDate =
          'due' in tactic
            ? dateStringToMonthYear(tactic.due)
            : 'dueDate' in tactic
            ? tactic.dueDate
            : [];

        let tacticText =
          'tacticText' in tactic
            ? tactic.tacticText
            : 'text' in tactic
            ? tactic.text
            : 'solutionText' in tactic
            ? tactic.solutionText
            : '';

        return {
          id: tactic.id,
          dueDate,
          timingEnd,
          timingStart,
          competitiveAdvantageRowId: tactic.competitiveAdvantageRowId as number,
          focused: true,
          tacticText,
          prependText: getPrependText(tactic.__typename),
        };
      }),
    [
      bigIdeaDetailsData?.bigIdeaDetails?.items,
      commercialTacticsData?.commercialTacticDetails?.items,
      solutionsData?.solutions?.items,
      tacticsData?.tactics?.items,
    ]
  );

  const rows = useMemo(() => {
    const imperativeBudgetTotals = [
      ...(bigIdeaDetailsData?.bigIdeaDetails?.items || []),
      ...(commercialTacticsData?.commercialTacticDetails?.items || []),
      ...(tacticsData?.tactics?.items || []),
      ...(solutionsData?.solutions?.items || []),
    ].reduce((acc, curr) => {
      let timingStart =
        'from' in curr
          ? curr.from
          : 'timingStart' in curr
          ? monthYearToDateTimestamp(curr.timingStart)
          : null;

      if (
        !!curr.competitiveAdvantageRowId &&
        typeof curr?.budget !== 'undefined'
      ) {
        if (curr.competitiveAdvantageRowId in acc) {
          acc[curr?.competitiveAdvantageRowId].budget += +(curr?.budget || 0);

          const timing = acc[curr?.competitiveAdvantageRowId].timing;

          if (
            (!!timingStart && timing === null) ||
            (!!timingStart && timing !== null && +timingStart < timing)
          ) {
            acc[curr?.competitiveAdvantageRowId].timing = +timingStart;
          }
        } else {
          acc[curr?.competitiveAdvantageRowId] = {
            budget: +(curr?.budget || 0),
            timing: !!timingStart ? +timingStart : null,
          };
        }
      }
      return acc;
    }, {} as Record<number, { budget: number; timing: number | null }>);

    // Sort imperatives by earliest timing
    return orderBy(
      (competitiveAdvantageRowsData?.competitiveAdvantageRows?.items || []).map(
        (row) => {
          return {
            id: row.id,
            __typename: row.__typename,
            strategicPossibility: {
              name: row.strategicPossibility?.name || '',
            },
            budget: imperativeBudgetTotals[row.id]?.budget,
            timing: imperativeBudgetTotals[row.id]?.timing,
            title: row.text,
          };
        }
      ),
      ['timing']
    );
  }, [
    bigIdeaDetailsData?.bigIdeaDetails?.items,
    commercialTacticsData?.commercialTacticDetails?.items,
    competitiveAdvantageRowsData?.competitiveAdvantageRows?.items,
    solutionsData?.solutions?.items,
    tacticsData?.tactics?.items,
  ]);

  return (
    <Wrapper>
      <ErrorWrapper
        errors={[
          strategyError,
          competitiveAdvantageRowsError,
          tacticsError,
          bigIdeaDetailsError,
          commercialTacticsError,
          solutionsError,
        ]}
        isLoading={
          strategyLoading ||
          competitiveAdvantageRowsLoading ||
          tacticsLoading ||
          bigIdeaDetailsLoading ||
          commercialTacticsLoading ||
          solutionsLoading
        }
        dataMissing={
          !strategyData ||
          !competitiveAdvantageRowsData ||
          !tacticsData ||
          !bigIdeaDetailsData ||
          !commercialTacticsData ||
          !solutionsData
        }
      >
        <SummaryTimeline
          strategyId={strategyId.toString()}
          currency={currency}
          tactics={tacticsForTimeline}
          rows={rows}
          emptyStateText="No tactics, solutions or big ideas planned yet"
        />
      </ErrorWrapper>
    </Wrapper>
  );
};

const getPrependText = (prependText: string | undefined) => {
  switch (prependText) {
    case 'Solution':
      return 'Risk mitigation solution: ';
    case 'Tactic':
      return 'Medical tactic: ';
    case 'CommercialTacticDetail':
      return 'Commercial tactic: ';
    case 'BigIdeaDetail':
      return 'Big idea: ';

    default:
      return '';
  }
};
