import { zIndex } from 'constants/index';
import React from 'react';
import styled from 'styled-components/macro';
import { PageNav } from './PageNav';

const Wrapper = styled.nav`
  position: fixed;
  z-index: ${zIndex.secondaryNav};
  top: 50px;
  left: 0px;
  right: 0px;
  text-align: left;
  background: radial-gradient(
    100% 57600% at 0% 100%,
    #1f0741 0%,
    #49129b 29.54%,
    #4f72c9 66%,
    #bc2eaa 100%
  );

  &::before {
    content: '';
    background: url(/noise.png) repeat;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    opacity: 0.3;
    pointer-events: none;
    z-index: 2;
  }
`;

const Content = styled.div`
  position: relative;
  min-height: 60px;
  padding: 15px;
  z-index: 3;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (min-width: 945px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 15px;
  }
`;

const CurrentPageWrapper = styled.div`
  display: block;
  width: 100%;
  @media screen and (min-width: 945px) {
    display: none;
  }
`;

export const PassThrough = styled.div`
  display: block;
  width: 100%;
  /* @media screen and (max-width: 944px) {
    display: none;
  } */
`;

interface Props {
  title?: string;
  url?: string;
  prev: { url: string; title: string };
  next: { url: string; title: string };
  hideArrows?: boolean;
  pageNavChildren?: React.ReactNode;
  stepNumber?: string;
  secondaryNavRef?: React.RefObject<HTMLElement>;
}

export const SecondaryNavbar: React.FC<Props> = ({
  title,
  url,
  children,
  prev,
  next,
  hideArrows,
  pageNavChildren,
  stepNumber,
  secondaryNavRef,
}) => {
  return (
    <Wrapper ref={secondaryNavRef} className={`main__secondaryNav`}>
      <Content>
        <CurrentPageWrapper>
          <PageNav
            hideArrows={hideArrows}
            prev={prev}
            next={next}
            title={title}
            url={url}
            stepNumber={stepNumber}
          >
            {pageNavChildren}
          </PageNav>
        </CurrentPageWrapper>
        <PassThrough>{children}</PassThrough>
      </Content>
    </Wrapper>
  );
};
