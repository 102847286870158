import { format } from 'date-fns';

import { downloadURI } from './downloadURI';

export function exportAsSvg(
  stageRef: any,
  columnTotalWidth: number,
  canvasWidth: number,
  canvasHeight: number,
  prefix: string
) {
  return () => {
    // set height/width to show all the data
    stageRef.current.absolutePosition({
      x: 2,
      y: 2,
    });
    stageRef.current.width(columnTotalWidth);
    stageRef.current.height(canvasHeight);
    stageRef.current.batchDraw();

    // export data
    const dataURL = stageRef.current.toDataURL({
      pixelRatio: 2,
    });
    downloadURI(dataURL, `${prefix}_${format(new Date(), 'dMMMuuu_HHmm')}.png`);

    // reset width/height
    stageRef.current.width(canvasWidth);
    stageRef.current.height(canvasHeight);
    stageRef.current.batchDraw();
  };
}
