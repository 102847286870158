import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import {
  AccessStrategyPrioritiseSupportingMessageFragment,
  LongTermSolutionFragment,
} from 'data/graphql/generated';
import { colors } from 'constants/colors';
import {
  BodyNormal,
  ButtonLabel,
  Caption,
  Collaboration,
  Icon,
  TooltipWrapper,
} from 'components/shared';
import { TextAreaInput } from 'components/shared/TextAreaInput';
import { DeleteIcon } from 'components/shared/DeleteIcon';
import {
  DeleteConfirmation,
  DeleteConfirmationBody,
  DeleteConfirmationFooter,
} from 'components/Insight';
import useDesktop from 'hooks/useDesktop';
import { Loading, Wrapper as LoadingWrapper } from 'components/Loading';
import { CommercialTacticFragmentWithGlobalFocusedStatus } from '../../types';

const Wrapper = styled.div<{ isSaving: boolean; disableDrag?: boolean }>`
  background: ${colors.white};
  border-radius: 4px;
  padding: 15px;
  cursor: grab;
  cursor: ${({ disableDrag }) => (disableDrag ? 'initial' : 'grab')};
  position: relative;
  pointer-events: ${({ isSaving }) => isSaving && 'none'};
`;

const PinAndTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 30px;
`;

const StyledTextArea = styled(TextAreaInput)<{
  $disableEdit?: boolean;
  $disableDrag?: boolean;
}>`
  font-size: 14px;
  cursor: ${({ $disableEdit, $disableDrag }) =>
    !$disableDrag ? 'grab' : $disableEdit ? 'initial' : 'grab'};
`;

const SavingOverlay = styled.div`
  background: ${colors.white};
  opacity: 0.8;
  position: absolute;
  inset: 10px;
  pointer-events: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${LoadingWrapper} {
    margin: 0;
  }
`;

type ItemType = 'tactic' | 'solution' | 'supporting message';

interface Props {
  itemData:
    | CommercialTacticFragmentWithGlobalFocusedStatus
    | LongTermSolutionFragment
    | AccessStrategyPrioritiseSupportingMessageFragment
    | { localUid: string };
  isPreview: boolean;
  deletePreview(): void;
  setTouchPositioningView: React.Dispatch<React.SetStateAction<string | null>>;
  handleCreate: (text: string) => void;
  handleUpdate: (text: string) => void;
  handleDelete: () => void;
  itemType: ItemType;
  previewUID: string | null;
  setPreviewUID: React.Dispatch<React.SetStateAction<string | null>>;
  disableEdit?: boolean;
  disableDrag?: boolean;
}

export const SidebarCard: React.FC<Props> = ({
  itemData,
  isPreview,
  deletePreview,
  setTouchPositioningView,  
  handleCreate,
  handleDelete,
  handleUpdate,
  itemType,
  setPreviewUID,
  disableEdit,
  disableDrag,
}) => {
  const [data, setData] = useState(
    itemData as
      | CommercialTacticFragmentWithGlobalFocusedStatus
      | LongTermSolutionFragment
      | AccessStrategyPrioritiseSupportingMessageFragment
  );
  const [text, setText] = useState(
    !isPreview && 'text' in data
      ? data?.text
      : 'keyStatement' in data
      ? data.keyStatement.text
      : ''
  );
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const deleteButtonRef = useRef<HTMLDivElement | null>(null);
  const textareaRef = useRef<HTMLDivElement | null>(null);
  const [textValid, setTextValid] = useState(true);
  const isDesktop = useDesktop();
  const [isSaving, setIsSaving] = useState(false);

  // Update data, prevent memory leak if component unmounted
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setData(itemData as CommercialTacticFragmentWithGlobalFocusedStatus);
      if ('id' in itemData) setIsSaving(false);
    }

    return () => {
      isMounted = false;
    };
  }, [itemData]);

  const isRegionCommercialTactic =
    '__typename' in itemData &&
    itemData.__typename === 'CommercialTactic' &&
    !itemData.isGlobalTactic &&
    !itemData.isGlobalLocalTactic;

  return (
    <Wrapper isSaving={isSaving} disableDrag={disableDrag}>
      {showConfirmDelete && (
        <DeleteConfirmation show={true}>
          <div>
            <BodyNormal color={colors.black}>
              Delete this {itemType}?
            </BodyNormal>
          </div>
          <DeleteConfirmationBody>
            <BodyNormal color={colors.black60}>
              Any discussion and files will be lost.
            </BodyNormal>
          </DeleteConfirmationBody>
          <DeleteConfirmationFooter>
            <ButtonLabel
              onClick={() => {
                setShowConfirmDelete(false);
                setShowDeleteButton(false);
                textareaRef?.current?.focus();
              }}
            >
              Cancel
            </ButtonLabel>
            <ButtonLabel
              color={colors.red}
              onClick={async (e) => {
                e.stopPropagation();
                if (isPreview) {
                  deletePreview();
                  return;
                }

                try {
                  await handleDelete();
                } catch (err) {
                  alert('Something went wrong');
                }
              }}
            >
              Delete
            </ButtonLabel>
          </DeleteConfirmationFooter>
        </DeleteConfirmation>
      )}

      <PinAndTitle
        onClick={(e) => {
          e.stopPropagation();

          return !isDesktop ? setTouchPositioningView(data?.localUid) : null;
        }}
      >
        {isRegionCommercialTactic ? (
          <Icon name="BluePin" size={30} color={colors.blue10a} />
        ) : (
          <Icon name="Pin" size={30} color={colors.purple10} />
        )}

        {!disableDrag && (
          <Caption color={colors.greyDark}>
            {isDesktop ? 'Drag to grid' : 'Tap to position'}
          </Caption>
        )}
      </PinAndTitle>
      <StyledTextArea
        $disableEdit={disableEdit}
        $disableDrag={disableDrag}
        disabled={disableEdit}
        $invalid={!textValid}
        $borderless
        ref={textareaRef}
        autoFocus={isPreview}
        rows={1}
        value={text}
        onChange={async (e) => {
          const target = e.target as HTMLTextAreaElement;
          setText(target.value);
          if (!target.value.length) {
            setTextValid(false);
          } else {
            setTextValid(true);
          }
        }}
        onFocus={(e) => setShowDeleteButton(true)}
        onBlur={async (e) => {
          // Unless user clicked the delete button, hide the delete button
          const deleteBtn = deleteButtonRef.current;
          const newFocusedElement = e.relatedTarget as Node | null;
          if (newFocusedElement) {
            if (!deleteBtn?.contains(newFocusedElement)) {
              setShowDeleteButton(false);
            } else {
              return;
            }
          }

          const trimmed = text?.trim() || '';

          if (isPreview) {
            // if it has text, save it
            if (!!trimmed.length) {
              // save it
              try {
                setIsSaving(true);
                await handleCreate(trimmed);
              } catch (error) {
                alert('Something went wrong');
                setIsSaving(false);
              } finally {
                setPreviewUID(null);
              }
            } else {
              deletePreview();
            }
          } else {
            // not a isPreview
            if (!!trimmed.length) {
              // update it
              handleUpdate(trimmed);
            } else {
              // user deleted text and blurred -> restore the previous text
              !isPreview && setText('text' in data ? data?.text : '');
            }
          }

          setTextValid(true);
          setShowDeleteButton(false);
        }}
      />
      <Footer>
        {showDeleteButton && !showConfirmDelete ? (
          <TooltipWrapper text={`Delete ${itemType}`} effect="solid">
            <DeleteIcon
              onClick={() => setShowConfirmDelete(true)}
              className="cypress-sidebar-card-delete-icon"
              ref={deleteButtonRef}
            />
          </TooltipWrapper>
        ) : (
          <div />
        )}
        {data && 'collaboration' in data && (
          <Collaboration collaboration={data.collaboration} />
        )}
      </Footer>
      {isSaving && (
        <SavingOverlay>
          <Loading text={`Saving ${itemType}...`} />
        </SavingOverlay>
      )}
    </Wrapper>
  );
};
