import {
  blockKeyStakeholdersColors,
  blockKeyStakeholdersDisplayNames,
  blockKeyStakeholdersIconNames,
} from 'components/PatientJourney/constants';
import { ButtonLabel } from 'components/shared';
import React from 'react';
import { KeyStakeholdersProps } from '../types';
import {
  StakeholderRowContainer,
  StarIcon,
  StakeholderIcon,
} from './StakeholderRow.style';

type Props = {
  $key: keyof typeof blockKeyStakeholdersDisplayNames;
  starred: KeyStakeholdersProps['starred'];
  onClick: KeyStakeholdersProps['onClick'];
  displayText: string;
};

function StakeholderRow({ $key, starred, onClick, displayText }: Props) {
  return (
    <StakeholderRowContainer
      key={$key}
      stakeholderKey={$key}
      starred={starred[$key]}
      data-cy="stakeholder-row"
    >
      <StarIcon
        className={`star-icon-${starred[$key]}`}
        name="Star"
        size={20}
        stakeholderKey={$key}
        height={20}
        starred={starred[$key]}
        onClick={() => {
          onClick({ [$key]: !starred[$key] });
        }}
      />
      <StakeholderIcon
        name={blockKeyStakeholdersIconNames[$key]}
        size={30}
        color={blockKeyStakeholdersColors[$key].stroke}
      />

      <ButtonLabel>{displayText}</ButtonLabel>
    </StakeholderRowContainer>
  );
}

export default StakeholderRow;
