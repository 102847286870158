import { useMemo } from 'react';
import {
  Step,
  SubStep,
  useCriticalMetricGoalsQuery,
} from 'data/graphql/generated';

export const useGetCriticalMetricGoals = (
  initialStrategyId: string,
  competitiveAdvantageRowUrlParam: string,
  isFocused?: boolean
) => {
  const strategyId = useMemo(() => +initialStrategyId, [initialStrategyId]);
  const competitiveAdvantageRowId = useMemo(
    () => +competitiveAdvantageRowUrlParam,
    [competitiveAdvantageRowUrlParam]
  );

  const {
    data,
    error,
    loading,
    startPolling,
    stopPolling,
  } = useCriticalMetricGoalsQuery({
    variables: {
      where: {
        strategyId: strategyId,
        competitiveAdvantageRowId: competitiveAdvantageRowId,
        step: Step.CriticalMetrics,
        subStep: SubStep.Goals,
        isFocused: isFocused,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const goals = useMemo(() => {
    return data?.criticalMetricGoals?.items || [];
  }, [data?.criticalMetricGoals]);

  return {
    goals,
    error,
    loading,
    startPolling,
    stopPolling,
  };
};
