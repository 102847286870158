import { BulletPointWithText } from 'components/Positioning/Statement';
import { BodySmall } from 'components/shared';
import { colors } from 'constants/index';

export const getFullStatement = (
  title: string,
  text: string[],
  drugName: string
) => {
  switch (title) {
    case "Who it's for":
      return (
        <BodySmall>{`${drugName} is for ${
          text[0] || 'the brand target'
        } who ${text[1] || 'want to overcome, or achieve something'}`}</BodySmall>
      );
    case 'What it is':
      return <BodySmall>{`${drugName} ${text}`}</BodySmall>;
    case 'Benefits':
      return (
        <BodySmall>{`${drugName} ${
          text[0] || 'provides a functional benefit'
        } so that ${text[1] || 'an emotional benefit.'}`}</BodySmall>
      );
    case 'Reasons to Believe':
      return !!text.length ? (
        text.map((value, idx) => (
          <BulletPointWithText color={colors.black} text={value} />
        ))
      ) : (
        <BulletPointWithText
          color={colors.black}
          text={'A key reason to believe'}
        />
      );
    case 'Supporting messages':
      return !!text.length ? (
        text.map((value, idx) => (
          <BulletPointWithText
            idx={idx + 1}
            color={colors.black}
            text={value}
            numbered
          />
        ))
      ) : (
        <BulletPointWithText
          idx={1}
          color={colors.black}
          text={'A supporting message'}
          numbered
        />
      );

    default:
      return null;
  }
};
