import React, { FC, useEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components/macro';

import { colors, zIndex } from '../constants';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Background = styled.div`
  display: block;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: ${zIndex.overlay};
  background: rgba(20, 20, 39, 0.4);
  opacity: 0;
  animation: ${fadeIn} 0.3s;
  animation-fill-mode: forwards;
`;

type Size = 'small' | 'medium' | 'large';

export const Content = styled.div<{ padding: boolean; size: Size }>`
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: 120px auto 0px auto;
  opacity: 0;
  animation: ${fadeIn} 0.3s;
  animation-fill-mode: forwards;
  background: ${colors.white};
  z-index: ${zIndex.dialog};
  width: 90%;
  max-width: 456px;
  box-shadow: 0px 10px 20px 0px rgb(0, 0, 0 / 10%),
    0px 1px 5px 0px rgb(0, 0, 0 / 5%);
  border-radius: 5px;
  overflow: hidden;
`;

interface Props {
  handleClose: () => void;
  visible: boolean;
  size?: Size;
  padding?: boolean;
  className?: string;
  onClick?(): void;
}

export const Modal: FC<Props> = ({
  handleClose,
  visible,
  children,
  size = 'small',
  padding = false,
  className,
  onClick = () => {},
}) => {
  // Keyboard shortcuts
  const keydownListener = useCallback(
    (keydownEvent) => {
      if (!visible) return;
      const { key } = keydownEvent;

      if (key === 'Escape') handleClose();
    },
    [visible, handleClose]
  );

  // Bind keyboard shortuts
  useEffect(() => {
    window.addEventListener('keydown', keydownListener, true);
    return () => window.removeEventListener('keydown', keydownListener, true);
  }, [keydownListener]);

  if (!visible) return null;

  return (
    <>
      <Background
        onClick={(e: React.MouseEvent) => {
          if (e.target === e.currentTarget) {
            handleClose();
          }
        }}
      />

      <Content
        onClick={onClick}
        className={className}
        size={size}
        padding={padding}
      >
        {children}
      </Content>
    </>
  );
};
