import { BodySmall, Caption } from '../../shared';

interface StrategyDefinitionHeaderTitleProps {
  title: string;
  isDesktop?: boolean;
}

export const StrategyDefinitionHeaderTitle = ({
  title,
  isDesktop = false,
}: StrategyDefinitionHeaderTitleProps) => {
  if (isDesktop) {
    return <BodySmall>{title}</BodySmall>;
  }

  return <Caption>{title}</Caption>;
};
