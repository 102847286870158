import React from 'react';
import { BodySmall, Subtitle2 } from 'components/shared';

import styled from 'styled-components/macro';
import { device, size } from 'utils/breakpoints';
import { colors } from 'constants/colors';

const EmptyImage = styled.img`
  margin: 0 auto;
  width: 115px;
  height: 115px;
`;

const Container = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.black30};
  text-align: center;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
  height: 255px;

  @media ${device.tabletMin} {
    padding-top: 32px;
  }

  @media ${device.desktopMin} {
    padding-top: 77px;
    height: 345px;
  }

  @media (min-width: ${size.desktop}) {
    padding-top: 87px;
    height: 345px;
  }
`;

interface Props {
  title: string;
  subtitle?: string;
  children?: any;
  style?: React.CSSProperties;
  className?: string;
}

export const PostItsEmpty: React.FC<Props> = ({
  title,
  subtitle,
  children,
  style,
  className,
}) => {
  return (
    <Container style={style} className={className}>
      <EmptyImage src="/Generic-Empty-State.svg" />
      <Subtitle2 color={colors.greyDark}>{title}</Subtitle2>
      {children ? (
        children
      ) : (
        <BodySmall color={colors.greyDark}>{subtitle}</BodySmall>
      )}
    </Container>
  );
};
