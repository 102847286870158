import { colors } from 'constants/index';
import React, { useState } from 'react';
import { Circle, Group, Path } from 'react-konva';
import { Cursor } from 'types';

interface Props {
  xPosition: number;
  yPosition: number;
  changeCursor: (cursor: Cursor) => void;
  onClick(): void;
  onEnter?(): void;
  onLeave?(): void;
}

export const DistributionIcon = ({
  xPosition,
  yPosition,
  changeCursor,
  onClick,
  onEnter,
  onLeave,
}: Props) => {
  const [addDistributionHover, setAddDistributionHover] = useState(false);

  return (
    <Group
      x={xPosition}
      y={yPosition}
      onTap={onClick}
      onClick={onClick}
      onMouseEnter={() => {
        changeCursor('pointer');
        setAddDistributionHover(true);
        onEnter?.();
      }}
      onMouseLeave={() => {
        changeCursor('default');
        setAddDistributionHover(false);
        onLeave?.();
      }}
    >
      <Circle
        radius={12.5}
        fill={addDistributionHover ? '#F8F2FF' : colors.white}
        stroke={addDistributionHover ? '#B879FF' : colors.black20}
        strokeWidth={1.5}
      />
      <Group x={-10} y={-10}>
        <Path
          linejoin="round"
          lineCap="round"
          data="M3.33268 10L11.666 10"
          stroke={addDistributionHover ? colors.purple : colors.black70}
        />
        <Path
          linejoin="round"
          lineCap="round"
          data="M3.33268 5L16.666 5"
          stroke={addDistributionHover ? colors.purple : colors.black70}
        />
        <Path
          linejoin="round"
          lineCap="round"
          data="M3.33268 15H6.66602"
          stroke={addDistributionHover ? colors.purple : colors.black70}
        />
      </Group>
    </Group>
  );
};
