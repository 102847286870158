import React, { useContext, useState } from 'react';
import { createContext } from 'react';
import { Stakeholder } from 'data/graphql/generated';

const StakeholderContext = createContext<[Stakeholder, any]>([
  Stakeholder.Patient,
  () => {},
]);

export const StakeholderProvider: React.FC = ({ children }) => {
  const [stakeholder, setStakeholder] = useState<Stakeholder>(
    Stakeholder.Patient
  );

  return (
    <StakeholderContext.Provider value={[stakeholder, setStakeholder]}>
      {children}
    </StakeholderContext.Provider>
  );
};

export function useStakeholderContext(stakeholder?: String) {
  const context = useContext(StakeholderContext);
  if (stakeholder) context[1](stakeholder);
  return context;
}
