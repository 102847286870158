import { Content, ModalLarge, Scroll } from 'components/ModalLarge';
import {
  BodySmall,
  ButtonPill,
  Caption,
  CountryFlag,
  Subtitle1,
  Subtitle2,
  Table,
} from 'components/shared';
import FormCountryDropdownSearch, {
  CountryDropdownListWrapper,
  DropdownOptionText,
} from 'components/shared/FormCountryDropdownSearch';
import { TableDivHeaders } from 'components/shared/Table';
import { defaultArchetypes, colors } from 'constants/index';
import {
  ArchetypeFragment,
  useArchetypeCountryUpsertMutation,
  useArchetypeCountryDeleteMutation,
  ArchetypeFragmentDoc,
  ArchetypeCountryFragment,
} from 'data/graphql/generated';
import useMobile from 'hooks/useMobile';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Country } from 'types';
import { uid } from 'uid';
import { device } from 'utils/breakpoints';
import {
  ArchetypeCountryRow,
  archetypeCountryRowColumnGridStyle,
} from './ArchetypeCountryRow';
import { InfoIcon } from './InfoIcon';

const StyledModal = styled(ModalLarge)`
  @media ${device.tabletMax} {
    ${Scroll} {
      padding-top: 50px;
      ${Content} {
        height: calc(100vh - 50px);
      }
    }
  }
`;

const StyledFormCountryDropdownSearch = styled(FormCountryDropdownSearch)`
  width: 100%;
  .country_dropdown__chevron {
    z-index: 0 !important;
  }
  ${CountryDropdownListWrapper} {
    @media ${device.tabletMax} {
      width: 100%;
    }
  }
` as typeof FormCountryDropdownSearch;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;
  gap: 10px;
`;

const CTAWrapper = styled.div`
  display: flex;
  margin-top: 20;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  row-gap: 0;
  position: relative;
`;

const StyledTable = styled(Table)`
  width: 664px;
  max-width: 664px;
  padding: 15px;

  ${TableDivHeaders} {
    ${archetypeCountryRowColumnGridStyle}
    margin-top: 30px;
  }

  > div {
    max-width: calc(100% - 30px);
    width: calc(100% - 30px);
    margin: 0 auto;
  }

  .Table__EmptyState {
    width: calc(100% - 15px);
    margin-right: auto;
    margin-left: 0;
  }

  @media ${device.tabletMax} {
    width: 100%;
    padding: 30px 0;

    .Table__Mobile__EmptyState {
      margin-top: 20px;
    }

    > div {
      max-width: calc(100% - 30px);
      width: calc(100% - 30px);
      min-width: 421px;
      margin: 0 auto;
    }
  }

  @media ${device.mobile} {
    border: none;
    border-radius: 0px;
    max-width: 100%;

    padding: 15px;

    > div {
      max-width: calc(100% - 30px);
      width: 100%;
      min-width: auto;
      margin: 0 auto;
    }
  }

  .table__footer__wrapper {
    display: none;
  }
` as typeof Table;

interface Props {
  isLead: boolean;
  archetype: ArchetypeFragment;
  countries?: string[];
  collaboratingCountries?: string[];
}

export const ArchetypeTable = ({
  isLead,
  archetype,
  countries,
  collaboratingCountries,
}: Props) => {
  const isMobile = useMobile();
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Country | ''>('');
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [upsertArchetypeCountry] = useArchetypeCountryUpsertMutation();
  const [deleteArchetypeCountry] = useArchetypeCountryDeleteMutation();
  const [dropDownKey, setDropDownKey] = useState(0);

  return (
    <>
      <StyledModal
        size="small"
        headerColor={colors.cream}
        handleClose={() => {
          setShowDescriptionModal(false);
        }}
        visible={showDescriptionModal}
      >
        <StyledModalContent>
          <Subtitle1>{archetype.stakeholderDefinition.title}</Subtitle1>

          <BodySmall>
            {getDescription(archetype.stakeholderDefinition.title)}
          </BodySmall>
        </StyledModalContent>
      </StyledModal>
      <StyledTable
        key={archetype.id}
        showTopMobileDivider
        isDesktop={!isMobile}
        useDiv
        header={
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 5,
                minHeight: 30,
              }}
            >
              <Subtitle2>{archetype.stakeholderDefinition.title}</Subtitle2>
              {!!showInfo(archetype.stakeholderDefinition.title) && (
                <InfoIcon
                  onClick={() => {
                    setShowDescriptionModal(true);
                  }}
                />
              )}
            </div>
            <div>
              <CTAWrapper>
                <div style={{ display: 'flex', width: 376 }}>
                  <StyledFormCountryDropdownSearch
                    key={dropDownKey}
                    title="Country"
                    name="country"
                    placeholder="Choose country"
                    onClick={() => {
                      setShowDropdown(true);
                    }}
                    dropdownActive={showDropdown}
                    selectedItem={selectedItem}
                    setSelectedItem={(item) => {
                      setSelectedItem(item);
                    }}
                    setDropdownActive={setShowDropdown}
                    options={countries as Country[]}
                    dropDownItem={(option, idx) => {
                      if (!option) return null;
                      return (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            alignItems: 'center',
                            padding: '0 10px',
                          }}
                        >
                          <div style={{ display: 'flex' }}>
                            <CountryFlag
                              disableTooltip
                              size={20}
                              country={option}
                            />

                            <DropdownOptionText>{option}</DropdownOptionText>
                          </div>
                          {!collaboratingCountries?.includes(option) && (
                            <Caption color={colors.greyDark}>
                              Not a collaborator
                            </Caption>
                          )}
                        </div>
                      );
                    }}
                  />
                </div>

                <ButtonPill
                  disabled={!selectedItem}
                  text="Add"
                  level="secondary"
                  onClick={() => {
                    if (!selectedItem) return;
                    const localUid = uid();
                    const id = Math.ceil(Math.random() * 20000);

                    upsertArchetypeCountry({
                      variables: {
                        data: {
                          archetypeId: archetype.id,
                          country: selectedItem,
                          localUid,
                          strategyId: archetype.strategyId,
                        },
                      },
                      optimisticResponse: {
                        archetypeCountryUpsert: {
                          __typename: 'ArchetypeCountry',
                          archetypeId: archetype.id,
                          country: selectedItem,
                          localUid,
                          strategyId: archetype.strategyId,
                          id,
                          filingDate: [],
                          launchDate: [],
                          lastUpdated: new Date().toISOString(),
                          createdAt: new Date().toISOString(),
                        },
                      },
                      update: (cache, { data }) => {
                        if (!data?.archetypeCountryUpsert.__typename) return;

                        const cachedArchetype = cache.readFragment({
                          id: `Archetype:${archetype.id}`,
                          fragment: ArchetypeFragmentDoc,
                          fragmentName: 'archetype',
                        }) as ArchetypeFragment & {
                          archetypeCountries: ArchetypeCountryFragment[];
                        };

                        if (data?.archetypeCountryUpsert) {
                          cache.writeFragment({
                            id: `Archetype:${archetype.id}`,
                            fragment: ArchetypeFragmentDoc,
                            fragmentName: 'archetype',
                            data: {
                              ...cachedArchetype,
                              archetypeCountries: [
                                ...cachedArchetype.archetypeCountries,
                                data?.archetypeCountryUpsert,
                              ],
                            },
                          });
                        }
                      },
                    });

                    setSelectedItem('');
                    // Resets the dropdown and gives it a placeholder
                    setDropDownKey(Math.floor(Math.random() * 999));
                  }}
                />
              </CTAWrapper>
            </div>
          </div>
        }
        cols={['Country', 'Filling date', 'Launch date']}
        rows={(data) => (
          <ArchetypeCountryRow
            isMobile={isMobile}
            country={data.country}
            filingDate={data.filingDate}
            launchDate={data.launchDate}
            isLead={isLead}
            deleteRow={() =>
              deleteArchetypeCountry({
                variables: { localUid: data.localUid },
                optimisticResponse: {
                  archetypeCountryDelete: {
                    ...data,
                  },
                },
                update: (cache, { data }) => {
                  const cachedArchetype = cache.readFragment({
                    id: `Archetype:${archetype.id}`,
                    fragment: ArchetypeFragmentDoc,
                    fragmentName: 'archetype',
                  }) as ArchetypeFragment & {
                    archetypeCountries: ArchetypeCountryFragment[];
                  };

                  const filteredArchetypeCountries = cachedArchetype.archetypeCountries.filter(
                    (country) =>
                      country.localUid !== data?.archetypeCountryDelete.localUid
                  );

                  cache.writeFragment({
                    id: `Archetype:${archetype.id}`,
                    fragment: ArchetypeFragmentDoc,
                    fragmentName: 'archetype',
                    data: {
                      ...cachedArchetype,
                      archetypeCountries: filteredArchetypeCountries,
                    },
                  });
                },
              })
            }
            updateTiming={({ filingDate, launchDate }) => {
              upsertArchetypeCountry({
                variables: {
                  data: {
                    archetypeId: archetype.id,
                    country: data.country,
                    localUid: data.localUid,
                    strategyId: archetype.strategyId,
                    filingDate,
                    launchDate,
                  },
                },

                optimisticResponse: {
                  archetypeCountryUpsert: {
                    ...data,
                  },
                },
                update: (cache, { data }) => {
                  const cachedArchetype = cache.readFragment({
                    id: `Archetype:${archetype.id}`,
                    fragment: ArchetypeFragmentDoc,
                    fragmentName: 'archetype',
                  }) as ArchetypeFragment & {
                    archetypeCountries: ArchetypeCountryFragment[];
                  };

                  const archetypeCountryInCache = cachedArchetype.archetypeCountries.findIndex(
                    (archetypeCountry) =>
                      archetypeCountry.localUid ===
                      data?.archetypeCountryUpsert.localUid
                  );
                  if (archetypeCountryInCache > -1) {
                    const archetypeCountries = cachedArchetype.archetypeCountries.slice();
                    if (data?.archetypeCountryUpsert) {
                      archetypeCountries[archetypeCountryInCache] =
                        data.archetypeCountryUpsert;

                      cache.writeFragment({
                        id: `Archetype:${archetype.id}`,
                        fragment: ArchetypeFragmentDoc,
                        fragmentName: 'archetype',
                        data: {
                          ...cachedArchetype,
                          archetypeCountries,
                        },
                      });
                    }
                  }
                },
              });
            }}
          />
        )}
        data={archetype?.archetypeCountries}
        emptyStateText={{
          header: 'No countries assigned to archetype',
        }}
        isAddingRow={false}
      />
    </>
  );
};

function showInfo(title: string) {
  return [
    defaultArchetypes.ClinicalDifferentiation,
    defaultArchetypes.HealthEconomic,
    defaultArchetypes.FreeMarketCompetition,
    defaultArchetypes.SelfPay,
    defaultArchetypes.BudgetDriven,
  ].includes(title);
}

function getDescription(title: string) {
  const presetDescriptions = {
    [defaultArchetypes.ClinicalDifferentiation.toLocaleLowerCase()]: 'Payers compare clinical evidence for similar products to identify the additional clinical benefit of the new product. The higher the clinical benefit over standard of care enabling higher prices',
    [defaultArchetypes.HealthEconomic.toLocaleLowerCase()]: 'Payers make decisions based on value for money of a new intervention in terms of both their costs and clinical outcomes. Value for money is formally evaluated using health economic models, such as cost effectiveness, cost minimisation or cost utility.',
    [defaultArchetypes.FreeMarketCompetition.toLocaleLowerCase()]: 'Price is based on market considerations with limited regulation. Extensive negotiation and discounting with multiple organisations is required by both government and private payers to gain attractive formulary positioning. ',
    [defaultArchetypes.SelfPay.toLocaleLowerCase()]: 'Treatment is predominantly paid for by the patient. In some Countries, even with universal health coverage, there is a large private and out-of-pocket health expenditure.',
    [defaultArchetypes.BudgetDriven.toLocaleLowerCase()]: 'Price and reimbursement is primarily determined by budget impact at either national, regional or local level. Budget impact analyses are used to estimate the financial consequences of a new treatment within a specific healthcare setting given inevitable resource constraints.',
  };

  return presetDescriptions[
    title.toLocaleLowerCase() as keyof typeof presetDescriptions
  ];
}
