import React from 'react';
import styled from 'styled-components/macro';
import { BigIdeaDetailFragment } from 'data/graphql/generated';
import { BodySmall, BodySmallStyle } from 'components/shared';
import { colors } from 'constants/colors';
import { thousandSeparator } from 'utils/thousandSeparator';
import { formatTimestamp } from 'utils/formatTimestamp';

const ListContainer = styled.div`
  p {
    ${BodySmallStyle}
  }
`;

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 40px;
  width: -webkit-fill-available;
  margin-bottom: -30px;
`;

const Content = styled.div`
  background: ${colors.white};
  border-radius: 5px;
  padding: 15px;
  width: -webkit-fill-available;
  margin: -5px;
`;

const TextWrapper = styled.div`
  margin-bottom: 15px;
  ul {
    margin-top: 2px;
    padding-top: 0px;
    padding-left: 25px;
  }
  li {
    padding-top: 0px;
  }
  li p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

interface Props {
  data: Pick<
    BigIdeaDetailFragment,
    | 'audience'
    | 'budget'
    | 'due'
    | 'from'
    | 'to'
    | 'text'
    | 'responsibility'
    | 'user'
  >;
  currency: string;
  heading?: string;
  audienceHeading?: string;
}

export const BigIdeaDetail: React.FC<Props> = ({
  data,
  currency,
  heading,
  audienceHeading,
}) => {
  return (
    <Wrapper>
      <Content>
        <TextWrapper>
          <BodySmall style={{ color: colors.greyDark }}>
            {heading || 'Big Idea'}
          </BodySmall>
          <BodySmall>{data.text ? data.text : 'Not identified yet'}</BodySmall>
        </TextWrapper>
        <TextWrapper>
          <BodySmall style={{ color: colors.greyDark }}>
            {audienceHeading || 'Audience'}
          </BodySmall>

          {data.audience ? (
            <ListContainer
              dangerouslySetInnerHTML={{ __html: data.audience }}
            />
          ) : (
            <BodySmall>Not identified yet</BodySmall>
          )}
        </TextWrapper>
        <TextWrapper>
          <BodySmall style={{ color: colors.greyDark }}>Timing</BodySmall>
          <BodySmall>
            {data.from && data.to ? (
              <>
                {formatTimestamp(data.from)}
                {' - '}
                {data.to ? formatTimestamp(data.to) : ''}
              </>
            ) : (
              'Not identified yet'
            )}
          </BodySmall>
        </TextWrapper>
        <TextWrapper>
          <BodySmall style={{ color: colors.greyDark }}>Due</BodySmall>
          <BodySmall>
            {data.due ? <>{formatTimestamp(data.due)}</> : 'Not identified yet'}
          </BodySmall>
        </TextWrapper>
        <TextWrapper>
          <BodySmall style={{ color: colors.greyDark }}>
            Responsibility
          </BodySmall>
          <BodySmall>
            {data.responsibility ? data.responsibility : 'Not identified yet'}
          </BodySmall>
        </TextWrapper>
        <TextWrapper>
          <BodySmall style={{ color: colors.greyDark }}>Budget</BodySmall>
          <BodySmall>
            {data.budget ? (
              <>
                {currency.charAt(0)}
                {thousandSeparator(data.budget)}
              </>
            ) : (
              'Not identified yet'
            )}
          </BodySmall>
        </TextWrapper>
      </Content>
    </Wrapper>
  );
};
