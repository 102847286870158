import { BodySmall } from 'components/shared';
import React from 'react';
import styled from 'styled-components/macro';

const List = styled.ul`
  padding: 0;
  margin: 0;
`;
const PointWithText = styled.li`
  display: flex;
  list-style-type: none;
  margin-left: 5px;
`;

const BulletPoint = styled(BodySmall)`
  margin-right: 10px;
  font-size: 10px;
`;

interface Props {
  listData?: string[] | null;
}

export const BulletPoints: React.FC<Props> = ({ listData }) => {
  return (
    <List>
      {!!listData && listData.some((item) => !!item) ? (
        listData.map((text, idx) => (
          <PointWithText key={idx}>
            <BulletPoint>•</BulletPoint>
            <BodySmall>{text}</BodySmall>
          </PointWithText>
        ))
      ) : (
        <BodySmall>None identified yet</BodySmall>
      )}
    </List>
  );
};
