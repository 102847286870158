import { Icon } from 'components/shared';
import { colors } from 'constants/colors';
import { zIndex } from 'constants/zIndex';
import useDesktop from 'hooks/useDesktop';
import React, { CSSProperties, useState } from 'react';
import { XYCoord, useDrag } from 'react-dnd';
import { createPortal } from 'react-dom';
import styled from 'styled-components/macro';
import {
  CommercialTacticFragmentWithGlobalFocusedStatus,
  DragNDropItems,
} from 'types';
import { device } from 'utils/breakpoints';
import { PinDragPreview } from '../shared/PinDragPreview';

const TickWrapper = styled.div`
  overflow: hidden;
  width: 12px;

  display: flex;
  justify-content: center;
`;

const InsidePin = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
`;

const PinWrapper = styled.div`
  position: relative;
`;

const Wrapper = styled.div<{ isDragging: boolean; disableDrag?: boolean }>`
  position: absolute;
  cursor: ${({ disableDrag }) => (disableDrag ? 'initial' : 'grab')};
  opacity: ${({ isDragging }) => (isDragging ? '0.2' : '1')};
  z-index: ${zIndex.draggablePinSmallerScreens};

  @media ${device.desktopMin} {
    z-index: ${zIndex.draggablePin};
  }
`;

const PinIcon = styled(Icon)<{ $selected: boolean; isRegionTactic: boolean }>`
  color: ${({ $selected, isRegionTactic }) => {
    const pinColor = isRegionTactic ? colors.blue10a : colors.purple10;
    return $selected ? colors.white : pinColor;
  }};

  &:hover {
    color: ${colors.white};
  }
`;

function coordinatesWithinThreshold(distanceMoved: XYCoord | null) {
  if (!distanceMoved) return;

  const withinBounds = (coord: number) => coord > -10 && coord < 10;
  const { x, y } = distanceMoved as XYCoord;

  return withinBounds(x) && withinBounds(y);
}

interface Props {
  data: CommercialTacticFragmentWithGlobalFocusedStatus;

  style: CSSProperties;
  showCallout: boolean;
  selected: boolean;
  onMouseOver: React.MouseEventHandler<HTMLDivElement> | undefined;
  onMouseOut: React.MouseEventHandler<HTMLDivElement> | undefined;
  setTouchPositioningView?: React.Dispatch<React.SetStateAction<string | null>>;
  removeFromGrid: () => void;
  children: React.ReactNode;
  disableDrag?: boolean;
}

/**
 * This is similar to the DraggablePin component, but it is used for the grids with regional variants.
 */
export const CountryDraggablePin: React.FC<Props> = ({
  data,
  onMouseOut,
  onMouseOver,
  showCallout,
  style,
  setTouchPositioningView,
  selected,
  removeFromGrid,
  children,
  disableDrag,
}) => {
  const isDesktop = useDesktop();
  const [distanceMoved, setDistanceMoved] = useState<XYCoord | null>(null);

  const [{ isDragging }, draggable] = useDrag(
    () => ({
      item: { type: DragNDropItems.PIN, data },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const res = monitor.getDropResult();

        // prevents a bug where the pin removes itself from the grid if dragged only a short distance
        if (!res && !coordinatesWithinThreshold(distanceMoved))
          removeFromGrid();
      },
    }),
    [distanceMoved]
  );

  const isGlobalFocused = data?.isGlobalFocused;
  const isLocalFocused = data?.isLocalFocused;
  const isRegionTactic = !data?.isGlobalTactic && !data?.isGlobalLocalTactic;

  return (
    <>
      {isDragging && (
        <PinDragPreview
          isBeingDragged={isDragging}
          setDistanceMoved={setDistanceMoved}
          isCountryPin={isRegionTactic}
        />
      )}
      <Wrapper
        className="draggable-pin"
        ref={disableDrag ? null : draggable}
        isDragging={isDragging}
        style={style}
        onMouseOut={(e) => !isDragging && onMouseOut && onMouseOut(e)}
        onMouseOver={onMouseOver}
        id={`draggable-pin-${data.id}`}
        onClick={(e) => {
          e.stopPropagation();

          return setTouchPositioningView && !isDesktop
            ? setTouchPositioningView(data.localUid)
            : null;
        }}
        disableDrag={disableDrag}
      >
        <PinWrapper>
          <PinIcon
            name={isRegionTactic ? 'BluePin' : 'Pin'}
            size={30}
            $selected={selected}
            isRegionTactic={isRegionTactic}
          />

          {(isGlobalFocused || isLocalFocused) && (
            <InsidePin>
              {isGlobalFocused && (
                <TickWrapper>
                  <Icon name="GradientTick" size={30} />
                </TickWrapper>
              )}
              {isLocalFocused && (
                <TickWrapper
                  style={{
                    marginLeft: isGlobalFocused ? -2 : 0,
                  }}
                >
                  <Icon name="GradientBlueTick" size={30} />
                </TickWrapper>
              )}
            </InsidePin>
          )}
        </PinWrapper>

        {!isDragging &&
          isDesktop &&
          showCallout &&
          createPortal(children, document.body)}
      </Wrapper>
    </>
  );
};
