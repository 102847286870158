import React from 'react';
import styled from 'styled-components/macro';
import { BodyNormal, StepHeaderBar, Subtitle2 } from 'components/shared';
import { device } from 'utils/breakpoints';
import { colors } from 'constants/colors';
import { SubStep } from 'data/graphql/generated';
import { AccessStrategySubStep } from 'types';

export const StyledStepHeaderBar = styled(StepHeaderBar)`
  margin-top: 160px;
  padding: 15px;
  min-height: unset;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${device.desktopMin} {
    margin-top: 120px;
  }
`;

export const StepHeader = styled(StepHeaderBar)`
  margin-top: 0;
  display: block;
  padding: 15px 20px 0px;
  border-bottom: 0.5px solid ${colors.greyMedium};
  min-height: auto;
  overflow: visible;
`;

function getTitle(substep: SubStep) {
  switch (substep) {
    case SubStep.Prioritisation:
      return 'Supporting messages for each core value message';
    case SubStep.Gaps:
      return 'Decide which supporting messages require additional evidence';
    case SubStep.Evidence:
      return 'Add evidence generation ideas to address the gaps';
    case SubStep.Pico:
      return 'Use the PICO framework to describe how you might gather the evidence you need';
    case SubStep.WinningActions:
      return 'Suggest tactics to drive supporting messages';
    default:
      return '';
  }
}

function getBody(substep: SubStep, permittedToEdit: boolean) {
  switch (substep) {
    case SubStep.Prioritisation:
      return permittedToEdit
        ? 'Position the supporting message pins to show relative importance and the data robustness of supporting evidence.'
        : 'Supporting message pins show relative importance and the data robustness of supporting evidence.';
    case SubStep.Gaps:
      return "Discuss the importance of available evidence for each message. The lead will make the final decision.";
    case SubStep.Evidence:
      return 'Review the stimulus to bring through evidence generation ideas from 2.3 or add new ones';
    case SubStep.Pico:
      return 'Refer to the stimulus and add suggestions. The Lead will decide which PICO frameworks will be focused on.';
    case SubStep.WinningActions:
      return 'View tactic examples and add suggestions. The Lead will confirm which tactics will be focused on. ';
    default:
      return '';
  }
}

interface Props {
  substep: AccessStrategySubStep;
  className?: string;
  permittedToEdit?: boolean;
  children?: React.ReactNode;
  innerRef?: React.MutableRefObject<HTMLDivElement | null>;
}

export default function AccessStrategyStepHeader({
  substep,
  className,
  permittedToEdit = false,
  children,
  innerRef,
}: Props) {
  return (
    <StyledStepHeaderBar ref={innerRef} className={className}>
      {children || (
        <>
          <Subtitle2 style={{ marginBottom: 5 }}>{getTitle(substep)}</Subtitle2>
          <BodyNormal color={colors.greyDark}>
            {getBody(substep, permittedToEdit)}
          </BodyNormal>
        </>
      )}
    </StyledStepHeaderBar>
  );
}
