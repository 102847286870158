import React from 'react';
import styled from 'styled-components';

import { ExcludedFeature } from 'types';
import { Navbar } from './Navbar';
import { colors } from 'constants/colors';
import { device } from 'utils/breakpoints';
import { BodySmall, Icon, Subtitle2 } from './shared';
import { zIndex } from 'constants/zIndex';

interface Props {
  errorType: 'generic' | 'unpermitted';
}

// NOTE this renders over the top of the existing navbar
// so we can show the normal navbar while loading
const PageWrapper = styled.div`
  padding: 0 15px;
  height: 100vh;
  width: 100vw;
  position: fixed;
  background: ${colors.black05};
  z-index: ${zIndex.errorPage};
`;

const MessageContainer = styled.div`
  margin: 80px auto 0;
  background: ${colors.white};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 15px;
  border: 1px solid ${colors.black10};
  max-width: 914px;

  @media ${device.desktopMin} {
    max-width: 898px;
  }
`;

export default function ErrorPage({ errorType }: Props) {
  const title =
    errorType === 'generic'
      ? 'Sorry, something went wrong'
      : 'You don’t have permission to view this page';

  const body =
    errorType === 'generic'
      ? 'Try refreshing the page or going back. If the issue persists, please contact support.'
      : 'Ask a Lead to add you as a collaborator.';

  const exclude: ExcludedFeature[] =
    errorType === 'generic'
      ? ['notifications', 'drugLink', 'strategyLink', 'profile']
      : ['drugLink', 'strategyLink'];

  return (
    <PageWrapper>
      <Navbar disableSecondary exclude={exclude} />
      <MessageContainer>
        <Icon
          name="GenericEmptyState"
          size={115}
          style={{ marginBottom: 5, height: 115 }}
        />
        <Subtitle2 color={colors.greyDark} style={{ marginBottom: 5 }}>
          {title}
        </Subtitle2>
        <BodySmall color={colors.greyDark}>{body}</BodySmall>
      </MessageContainer>
    </PageWrapper>
  );
}
