import startOfDay from 'date-fns/startOfDay';

export function dateStringToMonthYear(
  value: string | number | undefined | null
): number[] {
  if (!value) return [];
  const date = new Date(Number(value));

  return [date.getMonth(), date.getFullYear()];
}

export function monthYearToDateTimestamp(
  value: number[] | undefined | null
): number | undefined {
  if (!value) return;
  const date = startOfDay(new Date(value[1], value[0], 1)).getTime();

  return date;
}

/**
 * Convert date string to timestamp or null
 */
export const getTimestamp = (val: string | null | undefined) => {
  if (val) {
    if (isNaN(+val)) {
      return new Date(val).getTime() + '';
    } else {
      return new Date(+val).getTime() + '';
    }
  }
  return val;
};

/**
 * Convert timestamp to ISO format for Prisma
 */
export const getISOString = (val: string | null | undefined) => {
  if (val) {
    if (isNaN(+val)) {
      return new Date(val).toISOString() + '';
    } else {
      return new Date(+val).toISOString() + '';
    }
  }
  return val;
};
