import React from 'react';
import styled from 'styled-components/macro';
import {
  SuccessConditionFragment,
  SuccessConditionType,
} from 'data/graphql/generated';

import { device } from 'utils/breakpoints';
import { colors } from 'constants/colors';
import { average } from 'utils/average';
import { BodySmall, Icon, ScorePill, Subtitle2 } from 'components/shared';

const Container = styled.div`
  padding: 15px;
  width: 100%;
  max-width: 469px;
  display: block;
  border-radius: 5px;
  background: ${colors.white};
  border: 1px solid ${colors.black30};
  position: relative;
`;

const Score = styled.div<{ value: number }>`
  position: absolute;
  top: 18px;
  right: 15px;
`;

const Types = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  margin-bottom: 15px;
  min-height: 25px;
`;

const Type = styled.div<{ type: SuccessConditionType; isActive: boolean }>`
  display: flex;
  flex-direction: row;
  margin-right: 12px;

  @media ${device.tabletMax} {
    display: ${({ isActive, type }) => {
      if (type === SuccessConditionType.None) return 'none';
      if (!isActive) return 'none';
      return 'flex';
    }};
  }
`;

const TypeText = styled(BodySmall)<{ isActive: boolean }>`
  margin-left: 5px;
  color: ${({ isActive }) => (isActive ? colors.black : colors.greyMedium)};
`;

interface Props {
  successCondition: SuccessConditionFragment;
}

export const SuccessConditionPreview: React.FC<Props> = ({
  successCondition,
}) => {
  const averageScore = average(
    successCondition.ratings.map((rating) => rating.score)
  );

  return (
    <Container>
      <Types>
        {[
          SuccessConditionType.Driver,
          SuccessConditionType.Barrier,
          SuccessConditionType.None,
        ].map((type) => {
          const isActive = successCondition.type === SuccessConditionType[type];

          return (
            <Type key={type} isActive={isActive} type={type}>
              <Icon
                name={isActive ? 'GradientTick' : 'Tick'}
                style={{ height: 18, marginLeft: -4 }}
                size={20}
                color={colors.greyLight}
              />
              <TypeText isActive={isActive}>{type}</TypeText>
            </Type>
          );
        })}
      </Types>
      <Score value={averageScore}>
        <ScorePill value={averageScore} />
      </Score>
      <Subtitle2
        style={{
          lineBreak: 'anywhere',
        }}
      >
        {successCondition.text}
      </Subtitle2>
    </Container>
  );
};
