import ReactDOM from 'react-dom';
import { ErrorModal } from 'components/ErrorModal';

interface FieldLockedModalProps {
  isVisible: boolean;
  onClose(): void;
}

export const FieldLockedModal = ({
  isVisible,
  onClose,
}: FieldLockedModalProps) => {
  const handleClose = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <ErrorModal
      title={'Field locked'}
      text={`This goal has been tracked based on this field and so it cannot be changed.`}
      visible={isVisible}
      handleClose={handleClose}
    />,
    document.body
  );
};
