import { HurdlesAndGapsConstants } from './HurdlesAndGapsConstants';

export const HurdlesAndGapsAreasToExploreIdAndTitles = [
  HurdlesAndGapsConstants.POLICIES_AND_GUIDELINES,
  HurdlesAndGapsConstants.DISEASE_AND_THERAPY,
  HurdlesAndGapsConstants.MEDICAL_AND_CLINICAL_PRACTICE,
  HurdlesAndGapsConstants.PRODUCT,
];

export const HurdlesAndGapsAreaToExploreTitles: string[] = [
  HurdlesAndGapsConstants.POLICIES_AND_GUIDELINES.title,
  HurdlesAndGapsConstants.DISEASE_AND_THERAPY.title,
  HurdlesAndGapsConstants.MEDICAL_AND_CLINICAL_PRACTICE.title,
  HurdlesAndGapsConstants.PRODUCT.title,
];
