import { Icon, ButtonLabel, DatePicker } from 'components/shared';
import { colors } from 'constants/colors';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useLayoutEffect,
} from 'react';
import styled from 'styled-components/macro';
import {
  dateStringToMonthYear,
  monthYearToDateTimestamp,
} from 'utils/dateStringToMonthYear';
import { formatTimestamp } from 'utils/formatTimestamp';
import { DeleteButton } from '../ui/DeleteButton';
import { DeleteConfirmation } from '../ui/DeleteConfirmation';
import {
  DateDisplay,
  DateDisplayWrapper,
  Header,
  StyledCheckCircle,
  TextareaWrapper,
  Wrapper,
} from './KeyEvent.styles';

const DateDisplayWithIcon = styled.div`
  display: flex;
`;

export const StyledDatePicker = styled(DatePicker)<{
  showAbove: boolean;
}>`
  position: absolute;
  z-index: 1;

  transform: ${({ showAbove }) =>
    showAbove ? `translateY(calc(-100% + ${30}px))` : 'unset'};
`;

type Props = {
  onDelete?(): void;
  onUpdate?({ text, date }: { text?: string; date?: string }): void;
  toggleEnabledStatus?(): void;
  text: string;
  date: string;
  enabled: boolean;
  autoFocus?: boolean;
  onClickOutside?(text: string): void;
  canEnable: boolean;
  region: string | undefined;
  localTactic: boolean;
  isPreview?: boolean;
};

const KeyEvent = ({
  text,
  date,
  enabled,
  onDelete,
  onUpdate,
  toggleEnabledStatus,
  autoFocus,
  onClickOutside,
  canEnable,
  region,
  localTactic,
  isPreview = false,
}: Props) => {
  const [textValue, setTextValue] = useState(text || '');

  useEffect(() => {
    setTextValue(text);
  }, [text]);

  const [dateValue, setDateValue] = useState(date || '');

  useEffect(() => {
    setDateValue(date);
  }, [date]);

  const wrapperRef = useRef(null);
  useClickOutsideComponent(wrapperRef, () => {
    onClickOutside?.(textValue);
  });

  const datePickerRef = useRef<HTMLDivElement | null>(null);
  useClickOutsideComponent(datePickerRef, () => {
    setShowDatePicker(false);
  });

  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const deleteButtonRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const formattedDateForCalender = useMemo(() => {
    const d = dateStringToMonthYear(dateValue);
    return {
      month: d[0],
      year: d[1],
    };
  }, [dateValue]);

  const calendarLabel = useMemo(() => formatTimestamp(dateValue), [dateValue]);

  const [showDatePickerAbove, setShowDatePickerAbove] = useState(false);

  // Alter the position of the date dropdown so it is not shown beyond the bounds of the container
  useLayoutEffect(() => {
    const dateDisplay = headerRef.current;
    const datePicker = datePickerRef.current;
    if (!dateDisplay || !datePicker) return;
    const datePickerHeight = datePicker.offsetHeight;
    const { y } = dateDisplay.getBoundingClientRect();

    setShowDatePickerAbove(y + datePickerHeight >= window.innerHeight);
  }, [showDatePicker]);

  return (
    <Wrapper ref={wrapperRef}>
      <DeleteConfirmation
        isVisible={showDeleteConfirmation}
        onClose={() => {
          setShowDeleteConfirmation(false);
        }}
        onConfirm={() => onDelete?.()}
      />
      <DeleteButton
        deleteButtonRef={deleteButtonRef}
        onClick={() => {
          setShowDeleteConfirmation(true);
          setShowDeleteButton(false);
        }}
        isVisible={showDeleteButton}
      />

      <Header ref={headerRef}>
        <DateDisplayWrapper>
          <DateDisplayWithIcon
            onClick={(e) => {
              setShowDatePicker(true);
            }}
          >
            <DateDisplay>
              <Icon name="Calendar" size={30} color={colors.greyDark} />
              <ButtonLabel>{calendarLabel}</ButtonLabel>
            </DateDisplay>
            <Icon name="Chevron-down" size={30} color={colors.greyDark} />
          </DateDisplayWithIcon>

          <StyledDatePicker
            innerRef={datePickerRef}
            showAbove={showDatePickerAbove}
            date={formattedDateForCalender}
            visible={showDatePicker}
            onUpdate={(date) => {
              if (!date) {
                return;
              }
              const timestamp = monthYearToDateTimestamp([
                date.month,
                date.year,
              ]);
              if (timestamp) {
                // update on server if we also have a text value
                if (textValue) {
                  onUpdate?.({
                    date: String(timestamp),

                    text: textValue,
                  });
                }
                setDateValue(String(timestamp));
              }
              setShowDatePicker(false);
            }}
          />
        </DateDisplayWrapper>
        {canEnable && (
          <StyledCheckCircle
            isDisabled={isPreview}
            onClick={toggleEnabledStatus}
            complete={isPreview || enabled}
            region={region}
            localTactic={localTactic}
          />
        )}
      </Header>

      <TextareaWrapper
        $text={!!text}
        $textValue={!!textValue}
        autoFocus={autoFocus}
        rows={1}
        value={textValue}
        onChange={(e) => {
          const target = e.target as HTMLTextAreaElement;
          setTextValue(target.value);
        }}
        onFocus={() => {
          setShowDeleteButton(true);
        }}
        onBlur={(e) => {
          const target = e.target as HTMLTextAreaElement;

          const deleteBtn = deleteButtonRef.current;

          const newFocusedElement = e.relatedTarget as Node | null;
          const clickedOnDeleteBtn = deleteBtn?.contains(newFocusedElement);
          if (!(newFocusedElement && clickedOnDeleteBtn)) {
            // Did not click on delete btn
            setShowDeleteButton(false);

            if (!target.value) {
              // reset value to previous
              setTextValue(text);
            } else {
              onUpdate?.({
                text: target.value,
                date: dateValue,
              });
            }
          }
        }}
      />
    </Wrapper>
  );
};

export default KeyEvent;
