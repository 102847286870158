import { PostItsEmpty } from 'components/PostItsEmpty';
import { BodySmall } from 'components/shared';
import { colors } from 'constants/colors';
import { kebabCase } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';

const StyledPostItsEmpty = styled(PostItsEmpty)`
  width: 100%;

  @media ${device.mobile} {
    border: none;
    border-radius: 0px;
    max-width: 100%;
  }
`;

type Props = {
  drugId: string;
  strategyId: string;
  activeSubstep: string;
};

const EmptyState = ({ drugId, strategyId, activeSubstep }: Props) => {
  return (
    <StyledPostItsEmpty
      title={'No Core Value Message yet'}
      subtitle={
        'A Lead should finalise the Core Value Message before you can work on Supporting Messages '
      }
    >
      <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
        A Lead should finalise the{' '}
      </BodySmall>
      <Link
        to={`/d/${drugId}/strategy/${strategyId}/3_2/${kebabCase(
          activeSubstep
        )}`}
        style={{
          color: colors.greyDark,
          display: 'inline',
          fontSize: 14,
          fontWeight: 500,
        }}
      >
        Core Value Message{' '}
      </Link>
      <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
        {' '}
        before you can work on Supporting Messages
      </BodySmall>
    </StyledPostItsEmpty>
  );
};

export default EmptyState;
