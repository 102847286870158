import { Role, User } from 'data/graphql/generated';
import { Country, CountryConstants, CountryGlobal } from 'types';

export function getUniqueRegions(
  users: User[],
  includeGlobal: true
): CountryGlobal[];
export function getUniqueRegions(
  users: User[],
  includeGlobal: false
): Country[];
export function getUniqueRegions(users: User[], includeGlobal: boolean) {
  const userRegions = users.reduce((regions, user) => {
    if (user.role === Role.Lead) return regions;

    if (user.country && user.country !== 'global / regional')
      regions.add(user.country as Country);

    return regions;
  }, new Set<Country>([]));

  if (includeGlobal) {
    const regions = Array.from(userRegions);
    return [...regions, CountryConstants.GLOBAL];
  } else {
    return Array.from(userRegions);
  }
}
